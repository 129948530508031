import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'
import {
  IncentiveCampaign,
  IncentiveCampaignDTO,
  IncentiveCampaignListRequestFilter,
  IncentiveCampaignListRequestSorting,
} from './types'

const basicManagers = createBasicManagers<
  IncentiveCampaignListRequestFilter,
  IncentiveCampaignListRequestSorting,
  IncentiveCampaign,
  IncentiveCampaignDTO,
  IncentiveCampaignDTO
>(endpoints.incentiveCampaign)

export default {
  ...basicManagers,
  getItemsByIds: createItemsByIds(false, basicManagers.getItem),
} as const
