import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CustomSelectStyles, SimpleSelect } from '@agro-club/frontend-shared'
import { theme } from 'theme/theme'
import { AvailablilityColorsLevel } from 'modules/domain/productSettings/types'
import AuthSelectors from 'modules/domain/auth/selectors'

export const dot = (colorId: string) => ({
  alignItems: 'center',
  display: 'flex',

  ...(!!colorId && {
    ':before': {
      backgroundColor: theme.color.availabilityColors[colorId],
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
})

type AvailabilityColorSelectProps = {
  defaultValue?: string
  testId?: string
  onChange?: (id: AvailablilityColorsLevel) => void
}

export const aviabilitiyLevels = ['very_low', 'low', 'medium', 'high']

const AvailabilityColorSelect: React.VFC<AvailabilityColorSelectProps> = ({
  defaultValue,
  testId,
  onChange,
  ...props
}) => {
  const { t } = useTranslation('cards')
  const [value, setValue] = React.useState<string | number | undefined>(defaultValue)
  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const colourStyles: CustomSelectStyles = {
    option: (styles, { data }) => ({ ...styles, ...dot(data.id) }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.id) }),
  }

  const avialabilityColorsOptions = aviabilitiyLevels.map(level => ({
    id: level,
    title: t(`availabilityColors.${level}`),
  }))

  const handleChange = (id: NonNullable<AvailablilityColorsLevel>) => {
    onChange?.(id ?? null)
    setValue(id)
  }

  return (
    <div data-test-id={testId || `availability-color-select`}>
      <SimpleSelect
        value={value}
        options={avialabilityColorsOptions}
        onChange={handleChange}
        customStyles={colourStyles}
        isClearable={isAdmin}
        {...props}
      />
    </div>
  )
}

export default AvailabilityColorSelect
