import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  ProductOptions,
  ProductOptionsListRequestSorting,
  ProductOptionsListRequestFilter,
  ProductOptionsDTO,
} from './types'
import { ListResponse } from 'types/api'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getList = (
  filter: ProductOptionsListRequestFilter,
  sorting: ProductOptionsListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<ProductOptions>>(endpoints.productOptions(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getListAll = () => {
  return apiClient.get<ListResponse<ProductOptions>>(endpoints.productOptions(), { page: 1, page_size: 1000 })
}

export const getItem = (id: string) => {
  return apiClient.get<ProductOptions>(endpoints.productOptions(id))
}

export const addItem = (dto: ProductOptionsDTO) => {
  return apiClient.post<ProductOptions>(endpoints.productOptions(), dto)
}

export const updateItem = (id: string, dto: Partial<ProductOptionsDTO>) => {
  return apiClient.put<ProductOptions>(endpoints.productOptions(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<ProductOptions>(endpoints.productOptions(id))
}

export const getItemsByIds = createItemsByIds(false, getItem)
