import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SimpleSelect, Switch } from '@agro-club/frontend-shared'

import AuthSelectors from 'modules/domain/auth/selectors'
import { FarmerOrder } from 'types/farmerOrder'
import { ROLES } from 'types/entities'

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content auto;
  margin-top: -8px;
  height: 40px;
`

const Label = styled.span`
  margin-right: 8px;
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`

const SwitchStyled = styled(Switch)`
  width: auto;
`

type CancelSwitchProps = {
  canceledByRole: FarmerOrder['interaction']['canceled_by_role']
  canceledByRoleDefault?: ROLES | null
  disabled: boolean
  onChange: (field: string, value: unknown) => void
}

const CancelSwitch: React.FC<CancelSwitchProps> = ({
  canceledByRole,
  canceledByRoleDefault = ROLES.AGRO_ADMIN,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation(['farmerOrder', 'company'])
  const role = useSelector(AuthSelectors.role)

  const handleChangeSwitch = useCallback(
    (isOn: boolean) => {
      onChange('canceled_by_role', isOn ? canceledByRoleDefault : null)
    },
    [onChange, canceledByRoleDefault],
  )

  const handleChangeLabel = useCallback(() => {
    if (!disabled) {
      onChange('canceled_by_role', canceledByRole ? null : canceledByRoleDefault)
    }
  }, [onChange, canceledByRole, canceledByRoleDefault, disabled])

  const handleChangeSelect = useCallback(
    (value: string) => {
      onChange('canceled_by_role', value)
    },
    [onChange],
  )

  const roles = useMemo(() => {
    const distributorRoles = [
      { id: ROLES.DISTRIBUTOR_HEAD, title: t('status.canceledByRole.distributorHead') },
      { id: ROLES.DISTRIBUTOR_MANAGER, title: t('status.canceledByRole.distributorManager') },
    ]
    switch (true) {
      case !disabled && role === ROLES.PRODUCER_HEAD:
        return [{ id: ROLES.PRODUCER_HEAD, title: t('status.canceledByRole.producerHead') }, ...distributorRoles]
      case !disabled && role === ROLES.PRODUCER_MANAGER:
        return [{ id: ROLES.PRODUCER_MANAGER, title: t('status.canceledByRole.producerManager') }, ...distributorRoles]
      default:
        return [
          { id: ROLES.AGRO_ADMIN, title: t('status.canceledByRole.agro') },
          { id: ROLES.PRODUCER_HEAD, title: t('status.canceledByRole.producerHead') },
          { id: ROLES.PRODUCER_MANAGER, title: t('status.canceledByRole.producerManager') },
          ...distributorRoles,
          { id: ROLES.USER, title: t('status.canceledByRole.farmer') },
        ]
    }
  }, [t, disabled, role])

  return (
    <Container data-test-id={'cancel-order-switch'}>
      <SwitchStyled on={canceledByRole !== null} onClick={handleChangeSwitch} disabled={disabled} />
      <Label onClick={handleChangeLabel}>{canceledByRole ? t('status.canceledBy') : t('status.canceled')}</Label>
      {!!canceledByRole && (
        <SimpleSelect
          onChange={handleChangeSelect}
          value={canceledByRole || canceledByRoleDefault}
          options={roles}
          isDisabled={disabled}
        />
      )}
    </Container>
  )
}

export default CancelSwitch
