import { Progress, SelectWithPagination, SelectWithPaginationProps } from '@agro-club/frontend-shared'
import { apiClient } from 'modules/utils/httpClient'
import React, { useEffect, useRef, useState } from 'react'
import { SingleValue } from 'react-select'
import { Dict } from 'types/generics'
import { MapFn, useEntitySelectState, UseEntitySelectStateProps } from './useEntitySelectState'

export type EntitySelectProps<
  EntityType,
  EntityTypeMapped = EntityType,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
> = Omit<
  SelectWithPaginationProps<Map extends undefined ? EntityType : EntityTypeMapped>,
  | 'progress'
  | 'options'
  | 'value'
  | 'defaultValue'
  | 'onUpdateRequested'
  | 'total'
  | 'pageSize'
  | 'onChange'
  | 'getOptionValue'
  | 'getOptionLabel'
> & {
  value?: string
  fullValue?: SingleValue<Map extends undefined ? EntityType : EntityTypeMapped>
  pageSize?: number
  onChange?: (
    value: string | undefined,
    original: SingleValue<Map extends undefined ? EntityType : EntityTypeMapped>,
    dict: Dict<Map extends undefined ? EntityType : EntityTypeMapped>,
  ) => void
  onLoadDefault?: (item: EntityType, newDict: Dict<Map extends undefined ? EntityType : EntityTypeMapped>) => void
  onLoadList?: UseEntitySelectStateProps<EntityType, any, any, EntityTypeMapped, Map>['onLoadList']
  updateDeps?: unknown[]
  progress?: Progress
  showLabel?: boolean
}

export type InternalEntitySelectProps<
  EntityType,
  ListRequestFilter extends {},
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined
> = EntitySelectProps<EntityType, EntityTypeMapped, Map> & {
  getEntityById?: (id: string) => Promise<EntityType>
  getOptionValue: SelectWithPaginationProps<Map extends undefined ? EntityType : EntityTypeMapped>['getOptionValue']
  getOptionLabel: SelectWithPaginationProps<Map extends undefined ? EntityType : EntityTypeMapped>['getOptionLabel']
} & UseEntitySelectStateProps<EntityType, ListRequestFilter, ListRequestSorting, EntityTypeMapped, Map>

export const EntitySelect = <
  EntityType,
  ListRequestFilter extends {},
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined
>({
  pageSize = 25,
  onChange,
  value: valueFromProps,
  fullValue,
  getEntityList,
  getEntityById,
  filter,
  sort,
  getOptionValue,
  getOptionLabel,
  preventFetch,
  isDisabled,
  searchParamKey,
  getMappedOption,
  onLoadDefault,
  onLoadList,
  updateDeps,
  progress,
  cancelRequest = apiClient.cancelRequest,
  ...props
}: InternalEntitySelectProps<EntityType, ListRequestFilter, ListRequestSorting, EntityTypeMapped, Map>) => {
  const [progressById, setProgressById] = useState(Progress.IDLE)
  const [value, setValue] = useState<SingleValue<Map extends undefined ? EntityType : EntityTypeMapped> | undefined>(
    fullValue,
  )
  const updatedFromChange = useRef(false)

  const { dict, setDict, ...selectProps } = useEntitySelectState({
    getEntityList,
    filter,
    sort,
    pageSize,
    preventFetch: preventFetch || isDisabled,
    getOptionValue,
    searchParamKey,
    getMappedOption,
    onLoadList,
    updateDeps,
    cancelRequest,
  })

  const handleChange = (newValue: SingleValue<Map extends undefined ? EntityType : EntityTypeMapped>) => {
    onChange?.(newValue ? getOptionValue(newValue) : undefined, newValue, dict)
    setValue(newValue)
    updatedFromChange.current = true
  }

  useEffect(() => {
    let mounted = true
    if (updatedFromChange.current) {
      updatedFromChange.current = false
      return
    }

    if (!valueFromProps) {
      return setValue(undefined)
    }

    if (!value || valueFromProps !== getOptionValue(value)) {
      if (dict[valueFromProps]) {
        return setValue(dict[valueFromProps])
      }
      if (getEntityById) {
        setProgressById(Progress.WORK)
        const promise = getEntityById(valueFromProps)
          .then(entity => {
            if (mounted) {
              const newOption = (getMappedOption ? getMappedOption(entity) : entity) as Map extends undefined
                ? EntityType
                : EntityTypeMapped
              setValue(newOption)
              const newDict = { ...dict, [getOptionValue(newOption)]: newOption }
              setDict(newDict)
              setProgressById(Progress.SUCCESS)
              onLoadDefault?.(entity, newDict)
            }
          })
          .catch(() => {
            if (mounted) {
              setValue(undefined)
              setProgressById(Progress.ERROR)
            }
          })

        return () => {
          mounted = false
          cancelRequest(promise)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromProps])

  useEffect(() => {
    fullValue && !valueFromProps && setValue(fullValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullValue])

  return (
    <SelectWithPagination
      isSearchable
      {...selectProps}
      isDisabled={isDisabled}
      progress={
        progress === Progress.WORK || selectProps.progress === Progress.WORK || progressById === Progress.WORK
          ? Progress.WORK
          : selectProps.progress
      }
      clearDeps={[filter, sort, pageSize]}
      pageSize={pageSize}
      onChange={handleChange}
      value={value}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}
