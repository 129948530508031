import React, { useMemo } from 'react'
import { CustomSelectStyles, Progress, SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { useTranslation } from 'react-i18next'
import { Status } from 'types/entities'
import { PackageTypes } from 'modules/domain/packageTypes/types'

export type LocalPackageTypeSelectProps = Omit<SimpleSelectProps, 'options'> & {
  packageTypes: PackageTypes[]
  filter?: (item: PackageTypes) => boolean
  customStyles?: CustomSelectStyles
}

export const LocalPackageTypeSelect: React.FC<LocalPackageTypeSelectProps> = ({
  value,
  packageTypes,
  filter,
  progress,
  customStyles,
  ...otherProps
}) => {
  const { t } = useTranslation('storefront')
  const { pick } = useLangPicker()
  const options = useMemo(() => {
    let filteredList = packageTypes
    if (filter) {
      filteredList = packageTypes.filter(filter)
    }

    return filteredList
      .filter(item => item.status === Status.Active)
      .map(({ id, title, title_i18n }) => ({
        id,
        title: title_i18n ? pick(title_i18n) : title,
      }))
  }, [filter, packageTypes, pick])

  const isLoading = useMemo(() => !value && (progress === Progress.WORK || progress === Progress.ERROR), [
    progress,
    value,
  ])

  return (
    <div data-test-id={'package-type-select'}>
      <SimpleSelect
        value={value}
        options={options}
        placeholder={t('skuForm.labels.packageType')}
        errorText={t('validation:field_required')}
        required
        isSearchable
        isLoading={isLoading}
        customStyles={customStyles}
        {...otherProps}
      />
    </div>
  )
}
