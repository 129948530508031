import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import FarmerOrderSkuRoutes from './routes'
import FarmerOrderSkuTable from 'views/pages/FarmerOrderSku/FarmerOrderSkuTable'
import FarmerOrderSkuAdd from './FarmerOrderSkuAdd'
import FarmerOrderSkuEdit from './FarmerOrderSkuEdit'
import { FormFields } from 'views/pages/FarmerOrderSku/FarmerOrderSkuDetailsForm'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateActionAccessString } from 'modules/utils/generateStringHelpers'
import { Actions, FeatureFlagModifiers, Sections } from 'types/entities'

const FarmerOrderSkuPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormFields | undefined>()
  const checkFeatureFlag = useFeatureFlags()
  const isCreatePermitted = !checkFeatureFlag(
    generateActionAccessString(Sections.FarmerOrders, Actions.Create, FeatureFlagModifiers.Restricted),
  )

  return (
    <Switch>
      <Route path={FarmerOrderSkuRoutes.List} exact>
        <FarmerOrderSkuTable />
      </Route>
      <Route path={FarmerOrderSkuRoutes.Edit} exact>
        <FarmerOrderSkuEdit onUpdateInitialState={setInitialState} />
      </Route>
      {isCreatePermitted && (
        <Route path={FarmerOrderSkuRoutes.Add} exact>
          <FarmerOrderSkuAdd onUpdateInitialState={setInitialState} initialState={initialState} />
        </Route>
      )}
      <Redirect to={FarmerOrderSkuRoutes.List} />
    </Switch>
  )
}

export default FarmerOrderSkuPage
