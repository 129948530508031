import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  Company,
  CompanyConfigDTO,
  CompanyDTO,
  CompanyListRequestFilter,
  CompanyListRequestSorting,
  CompanyRelation,
} from 'modules/domain/company/types'
import { ListResponse } from 'types/api'
import { CompanyType } from 'types/entities'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getCompanyList = (
  filter: CompanyListRequestFilter,
  sorting: CompanyListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Company>>(endpoints.companies(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getProducersList = (search?: string) => {
  return apiClient.get<ListResponse<Company>>(endpoints.companies(), {
    search,
    company_type: CompanyType.Producer,
    page_size: 1000, // TODO get rid of this
  })
}

export const getSellersList = (search?: string) => {
  return apiClient.get<ListResponse<Company>>(endpoints.companies(), {
    search,
    is_seller: true,
    page_size: 1000, // TODO get rid of this
  })
}

export const getDistributorsList = (search?: string) => {
  return apiClient.get<ListResponse<Company>>(endpoints.companies(), {
    search,
    company_type: CompanyType.Distributor,
    page_size: 1500, // TODO get rid of this
  })
}

export const getCompany = (id: string) => {
  return apiClient.get<Company>(endpoints.companies(id))
}

export const addCompany = (user: CompanyDTO) => {
  return apiClient.post<Company>(endpoints.companies(), user)
}

export const updateCompany = (id: string, user: Partial<CompanyDTO>) => {
  return apiClient.put<Company>(endpoints.companies(id), user)
}

export const removeCompany = (id: string) => {
  return apiClient.delete<Company>(endpoints.companies(id))
}

export const getDistributorBranchCompanies = (head_distributor_id: string) => {
  return apiClient.get<ListResponse<Company>>(endpoints.branchCompanies(head_distributor_id))
}

export const updateDistributorBranchCompanies = (head_distributor_id: string, branches: CompanyRelation[]) => {
  return apiClient.put(endpoints.branchCompanies(head_distributor_id), {
    branch_companies: branches,
  })
}

export const addCompanyConfig = (config: CompanyConfigDTO) => {
  return apiClient.post<CompanyConfigDTO>(endpoints.companyConfig(), config)
}

export const updateCompanyConfig = (config_id: string, config: CompanyConfigDTO) => {
  return apiClient.put<CompanyConfigDTO>(endpoints.companyConfig(config_id), config)
}

export const removeCompanyConfig = (config_id: string) => {
  return apiClient.delete<CompanyConfigDTO>(endpoints.companyConfig(config_id))
}

export const getInventoryExchangeProducersList = (
  filter: CompanyListRequestFilter,
  sorting: CompanyListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Company>>(endpoints.inventoryExchangeProducersSuggest(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getInventoryExchangeDistibutorsList = (
  filter: CompanyListRequestFilter,
  sorting: CompanyListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Company>>(endpoints.inventoryExchangeDistributorsSuggest(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getSellersListForDistributorOrderSku = () => {
  return apiClient.get<ListResponse<Company>>(endpoints.distributorOrderSkuSellersForFilter())
}

export const getItemsByIds = createItemsByIds(false, getCompany)
