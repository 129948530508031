import { Progress } from 'modules/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '@agro-club/frontend-shared'

type Props = {
  submitBtnText?: string
  cancelBtnText?: string
  onSubmit: () => void
  onCancel?: () => void
  submitProgress?: Progress
  submitDisabled?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};

  & > * {
    margin-right: 16px;
  }
`

const Footer: React.FC<Props> = ({
  submitBtnText,
  cancelBtnText,
  onSubmit,
  onCancel,
  submitProgress,
  submitDisabled,
}) => {
  const { t } = useTranslation('common')
  return (
    <Wrapper>
      <Button intent={'primary'} onClick={onSubmit} filled={true} progress={submitProgress} disabled={submitDisabled}>
        {submitBtnText ? submitBtnText : t('submit')}
      </Button>
      {onCancel && (
        <Button intent={'cancel'} filled={false} onClick={onCancel}>
          {cancelBtnText ? cancelBtnText : t('cancel')}
        </Button>
      )}
    </Wrapper>
  )
}

export default Footer
