import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { ListResponse } from 'types/api'
import {
  FarmerTarget,
  FarmerTargetCreateDTO,
  FarmerTargetState,
  TargetListRequestFilter,
  TargetListRequestSorting,
  TargetUpdateDTO,
} from '../common/types'

const initialState: FarmerTargetState = {
  ...BasicEntityState,
  updateCounter: 0,
  totalFarmerOrders: 0,
  totalFarmerOrdersPreviousYear: 0,
  totalTargetValues: 0,
  totalRetailerTargets: 0,
}

type FarmerMeta = {
  total_target_values: number
  total_farmer_orders: number
  total_farmer_orders_previous_year: number
}

class FarmerTargetReducer extends BasicEntityImmerReducer<
  FarmerTarget,
  TargetListRequestFilter,
  TargetListRequestSorting,
  FarmerTargetCreateDTO,
  TargetUpdateDTO,
  FarmerTargetState
> {
  updateOccurred() {
    this.draftState.updateCounter += 1
  }
  listRequestSucceed(response: ListResponse<FarmerTarget, FarmerMeta>) {
    super.listRequestSucceed(response)
    const { total_target_values, total_farmer_orders, total_farmer_orders_previous_year } = response.meta || {}
    this.draftState.totalTargetValues = total_target_values || 0
    this.draftState.totalFarmerOrders = total_farmer_orders || 0
    this.draftState.totalFarmerOrdersPreviousYear = total_farmer_orders_previous_year || 0
  }
}

export const FarmerTargetActions = createActionCreators(FarmerTargetReducer)
export default FarmerTargetActions
export const reducer = createReducerFunction(FarmerTargetReducer, initialState)
