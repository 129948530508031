import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FarmerOrderFilterStatus } from 'types/farmerOrder'
import { ROLES } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'

export const agroStatuses = [
  FarmerOrderFilterStatus.New,
  FarmerOrderFilterStatus.NewAgro,
  FarmerOrderFilterStatus.ConfirmedByAgroClub,
  FarmerOrderFilterStatus.CanceledByAgroClub,
  FarmerOrderFilterStatus.AwaitingProducerConfirmation,
  FarmerOrderFilterStatus.ConfirmedByProducer,
  FarmerOrderFilterStatus.CanceledByProducer,
  FarmerOrderFilterStatus.AwaitingDistributorSelection,
  FarmerOrderFilterStatus.AwaitingDistributorConfirmation,
  FarmerOrderFilterStatus.ConfirmedByDistributor,
  FarmerOrderFilterStatus.RejectedByDistributor,
  FarmerOrderFilterStatus.Changed,
  FarmerOrderFilterStatus.CanceledByFarmer,
  FarmerOrderFilterStatus.Deleted,
]

export const producerStatuses = [
  FarmerOrderFilterStatus.NewProducer,
  FarmerOrderFilterStatus.AwaitingProducerConfirmation,
  FarmerOrderFilterStatus.ConfirmedByProducer,
  FarmerOrderFilterStatus.CanceledByProducer,
  FarmerOrderFilterStatus.AwaitingDistributorSelection,
  FarmerOrderFilterStatus.AwaitingDistributorConfirmation,
  FarmerOrderFilterStatus.ConfirmedByDistributor,
  FarmerOrderFilterStatus.RejectedByDistributor,
  FarmerOrderFilterStatus.Changed,
  FarmerOrderFilterStatus.CanceledByFarmer,
]

export const distributorStatuses = [
  FarmerOrderFilterStatus.New,
  FarmerOrderFilterStatus.ConfirmedByDistributor,
  FarmerOrderFilterStatus.RejectedByDistributor,
  FarmerOrderFilterStatus.Changed,
  FarmerOrderFilterStatus.CanceledByFarmer,
  FarmerOrderFilterStatus.AwaitingFarmerConfirmation,
]

export type FarmerOrderStatusSelectProps = Omit<SimpleSelectProps, 'value' | 'options'> & {
  value?: string[] | null
}

const FarmerOrderStatusSelect: React.FC<FarmerOrderStatusSelectProps> = ({ value, ...props }) => {
  const { t } = useTranslation(['farmerOrder'])
  const role = useSelector(AuthSelectors.role)
  const optionsByRole: FarmerOrderFilterStatus[] = useMemo(() => {
    let result: FarmerOrderFilterStatus[] = []
    switch (role) {
      case ROLES.AGRO_ADMIN:
        result = agroStatuses
        break
      case ROLES.PRODUCER_HEAD:
      case ROLES.PRODUCER_MANAGER:
        result = producerStatuses
        break
      case ROLES.DISTRIBUTOR_HEAD:
      case ROLES.DISTRIBUTOR_MANAGER:
        result = distributorStatuses
    }
    return result
  }, [role])

  const selected = Array.isArray(value) ? value[0] : undefined

  const options = useMemo(() => {
    return [
      { id: undefined, title: t(`status.any`) },
      ...optionsByRole.map(item => ({ id: item, title: t(`status.full.${item}`) })),
    ]
  }, [optionsByRole, t])

  return (
    <div data-test-id="farmer-order-status-select">
      <SimpleSelect {...props} value={selected} options={options} placeholder={t('status.any')} />
    </div>
  )
}

export default FarmerOrderStatusSelect
