import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { FccOrderItem } from 'types/fccOrder'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.fccOrder.items
const ids = (state: AppGlobalState) => state.fccOrder.ids
const item = (state: AppGlobalState, id: string): FccOrderItem | undefined => state.fccOrder.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<FccOrderItem> =>
  state.fccOrder.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.fccOrder.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.fccOrder.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.fccOrder.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.fccOrder.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.fccOrder.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }

  const result: FccOrderItem[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.fccOrder.filter
const sorting = (state: AppGlobalState) => state.fccOrder.sorting
const page = (state: AppGlobalState) => state.fccOrder.page
const total = (state: AppGlobalState) => state.fccOrder.total
const totalCost = (state: AppGlobalState) => state.fccOrder.totalCost
const totalQty = (state: AppGlobalState) => state.fccOrder.totalQty
const totalFinalQty = (state: AppGlobalState) => state.fccOrder.totalFinalQty
const updateProgress = (state: AppGlobalState) => state.fccOrder.updateProgress
const updateError = (state: AppGlobalState) => state.fccOrder.updateError
const hasNext = (state: AppGlobalState) => state.fccOrder.total > state.fccOrder.ids.length
const pageSize = (state: AppGlobalState) => state.fccOrder.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.fccOrder.total / state.fccOrder.pageSize)

const itemsForUpdate = (state: AppGlobalState) => state.fccOrder.itemsForUpdate
const isChanged = (state: AppGlobalState) => Boolean(Object.keys(state.fccOrder.itemsForUpdate).length)

const FccOrderSelectors = {
  filter,
  sorting,
  page,
  total,
  totalCost,
  totalQty,
  totalFinalQty,
  updateProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  updateError,
  itemsForUpdate,
  isChanged,
}

export default FccOrderSelectors
