import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { ListResponse } from 'types/api'
import {
  RetailerTargetSku,
  RetailerTargetSkuCreateDTO,
  RetailerTargetSkuState,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  TargetSkuUpdateDTO,
} from '../common/types'

const initialState: RetailerTargetSkuState = {
  ...BasicEntityState,
  updateCounter: 0,
  totalFarmerOrdersSku: 0,
  totalFarmerOrdersSkuPreviousYear: 0,
  totalTargetValues: 0,
  totalRetailerOrdersSku: 0,
}

type RetailerMeta = {
  total_target_values: number
  total_farmer_orders_sku: number
  total_farmer_orders_sku_previous_year: number
  total_retailer_orders_sku: number
}
class RetailerTargetSkuReducer extends BasicEntityImmerReducer<
  RetailerTargetSku,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  RetailerTargetSkuCreateDTO,
  TargetSkuUpdateDTO,
  RetailerTargetSkuState
> {
  updateOccurred() {
    this.draftState.updateCounter += 1
  }
  listRequestSucceed(response: ListResponse<RetailerTargetSku, RetailerMeta>) {
    super.listRequestSucceed(response)
    const {
      total_target_values,
      total_farmer_orders_sku,
      total_farmer_orders_sku_previous_year,
      total_retailer_orders_sku,
    } = response.meta || {}
    this.draftState.totalTargetValues = total_target_values || 0
    this.draftState.totalFarmerOrdersSku = total_farmer_orders_sku || 0
    this.draftState.totalFarmerOrdersSkuPreviousYear = total_farmer_orders_sku_previous_year || 0
    this.draftState.totalRetailerOrdersSku = total_retailer_orders_sku || 0
  }
}

export const RetailerTargetSkuActions = createActionCreators(RetailerTargetSkuReducer)
export default RetailerTargetSkuActions
export const reducer = createReducerFunction(RetailerTargetSkuReducer, initialState)
