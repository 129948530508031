import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { ListResponse } from 'types/api'
import {
  FarmerTargetSku,
  FarmerTargetSkuCreateDTO,
  FarmerTargetSkuState,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  TargetSkuUpdateDTO,
} from '../common/types'

const initialState: FarmerTargetSkuState = {
  ...BasicEntityState,
  updateCounter: 0,
  totalFarmerOrdersSku: 0,
  totalFarmerOrdersSkuPreviousYear: 0,
  totalTargetValues: 0,
  totalRetailerTargets: 0,
}

type FarmerMeta = {
  total_target_values: number
  total_farmer_orders_sku: number
  total_farmer_orders_sku_previous_year: number
}

class FarmerTargetSkuReducer extends BasicEntityImmerReducer<
  FarmerTargetSku,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  FarmerTargetSkuCreateDTO,
  TargetSkuUpdateDTO,
  FarmerTargetSkuState
> {
  updateOccurred() {
    this.draftState.updateCounter += 1
  }
  listRequestSucceed(response: ListResponse<FarmerTargetSku, FarmerMeta>) {
    super.listRequestSucceed(response)
    const { total_target_values, total_farmer_orders_sku, total_farmer_orders_sku_previous_year } = response.meta || {}
    this.draftState.totalTargetValues = total_target_values || 0
    this.draftState.totalFarmerOrdersSku = total_farmer_orders_sku || 0
    this.draftState.totalFarmerOrdersSkuPreviousYear = total_farmer_orders_sku_previous_year || 0
  }
}

export const FarmerTargetSkuActions = createActionCreators(FarmerTargetSkuReducer)
export default FarmerTargetSkuActions
export const reducer = createReducerFunction(FarmerTargetSkuReducer, initialState)
