import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import TargetRoutes from 'views/pages/Target2Sku/routes'
import actions from './duck'
import managers from './managers'
import selectors from './selectors'

const sagas = createBasicSagas({
  selectors,
  actions,
  managers,
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, TargetRoutes.TerritoryList, { page })
  },
  onAddItemSucceed: function*() {
    yield put(actions.updateOccurred())
  },
  onRemoveItemSucceed: function*() {
    yield put(actions.updateOccurred())
  },
})

const allSaga = function*() {
  yield all([
    takeLatest(actions.itemRequested.type, sagas.fetchItem),

    takeLatest(actions.listRequested.type, sagas.fetchList),
    takeLatest(actions.listUpdateRequested.type, sagas.fetchList),
    takeLatest(actions.filterUpdated.type, sagas.fetchList),
    takeLatest(actions.sortingUpdated.type, sagas.fetchList),
    takeLatest(actions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.addRequested.type, sagas.addItem),
    takeLatest(actions.addSucceed.type, sagas.fetchList),
    takeLatest(actions.removeSucceed.type, sagas.fetchList),

    takeLatest(actions.listRequestedNext.type, sagas.fetchListNext),

    takeLatest(actions.updateRequested.type, sagas.updateItem),
    takeLatest(actions.removeRequested.type, sagas.removeItem),
  ])
}

export default allSaga
