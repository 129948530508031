export const DEVELOPMENT = process.env.NODE_ENV === 'development'

const getEnv = (key: string) => {
  if (DEVELOPMENT) {
    return process.env[key]
  } else {
    return window[key]
  }
}
export const REACT_APP_REDUX_LOGGER = process.env.REACT_APP_REDUX_LOGGER
export const REACT_APP_REDUX_LOGGER_HIDE_ACTIONS = process.env.REACT_APP_REDUX_LOGGER_HIDE_ACTIONS
export const REACT_APP_API_BASE_URL = getEnv('REACT_APP_API_BASE_URL') || ''
export const REACT_APP_PRINT_API_BASE_URL = getEnv('REACT_APP_PRINT_API_BASE_URL') || ''
export const REACT_APP_OCTOPUS_API_BASE_URL = getEnv('REACT_APP_OCTOPUS_API_BASE_URL') || ''
export const SENTRY_DSN = getEnv('REACT_APP_SENTRY_DSN') || null
export const SEGMENT_WRITE_KEY = getEnv('REACT_APP_SEGMENT_WRITE_KEY') || ''
export const GTM_ID = getEnv('REACT_APP_GTM_ID') || ''
export const ZENDESK_KEY = getEnv('ZENDESK_KEY') || ''
export const INTERCOM_APP_ID = getEnv('INTERCOM_APP_ID') || ''

export default {
  SEGMENT_WRITE_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_PRINT_API_BASE_URL,
  REACT_APP_OCTOPUS_API_BASE_URL,
  REACT_APP_REDUX_LOGGER,
  SENTRY_DSN,
  DEVELOPMENT,
  REACT_APP_REDUX_LOGGER_HIDE_ACTIONS,
  GTM_ID,
  ZENDESK_KEY,
  INTERCOM_APP_ID,
}
