import { useAction } from '@agro-club/frontend-shared'
import { Entity } from 'modules/domain/types'
import { ResourceHook } from 'modules/types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SelectorsForNuclearHooks } from './createBasicSelectors'

export const createItemByIdHook = <EntityType extends Partial<Entity>>(
  selectors: SelectorsForNuclearHooks<EntityType>,
  fetchActionType: (id: string) => any,
): ResourceHook<EntityType, [string | undefined]> => id => {
  const meta = useSelector(selectors.meta(id))
  const item = useSelector(selectors.item(id))
  const fetchAction = useAction(fetchActionType, id)

  useEffect(() => {
    if ((!item || item.id !== id) && id !== 'false' && id) {
      fetchAction()
    }
  }, [fetchAction, id, item])

  return [meta.fetchProgress, item]
}
