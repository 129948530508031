import { IconBin, PopoverWithBtns, useAction } from '@agro-club/frontend-shared'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'
import { useTerritoryAllocationList } from 'modules/domain/allocation/territoryAllocation/hooks'
import { Allocation } from 'modules/domain/allocation/common/types'
import TerritoryAllocationActions from 'modules/domain/allocation/territoryAllocation/duck'
import TerritoryAllocationSelectors from 'modules/domain/allocation/territoryAllocation/selectors'
import styled from 'styled-components'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import { AllocationForm } from '../Allocation/AllocationForm'

const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
`

export const RemoveBtn = styled(IconBin)`
  fill: ${props => props.theme.color.secondary300};
  cursor: pointer;
  transition: 0.3s fill;

  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`

const TrashBinCell: React.FC<CellProps<Allocation, string>> = ({ value }) => {
  const { t } = useTranslation(['allocation'])
  const removeAllocation = useAction(TerritoryAllocationActions.removeRequested)
  return (
    <CenteredCell onClick={e => e.stopPropagation()}>
      <PopoverWithBtns onSubmit={() => removeAllocation(value)} text={t('removeText')}>
        <RemoveBtn onClick={() => removeAllocation(value)} />
      </PopoverWithBtns>
    </CenteredCell>
  )
}

const AllocationTerritoryTable = () => {
  const { t } = useTranslation('allocation')
  const [progress, data = []] = useTerritoryAllocationList()

  const total = useSelector(TerritoryAllocationSelectors.total)
  const page = useSelector(TerritoryAllocationSelectors.page)
  const pages = useSelector(TerritoryAllocationSelectors.pages)
  const pageSize = useSelector(TerritoryAllocationSelectors.pageSize)

  const listRequested = useAction(TerritoryAllocationActions.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer.internal_name',
        width: 130,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'product.category.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'product.subcategory.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.productQty'),
        accessor: 'quantity',
        width: '100px',
      },
      {
        Header: t('list.tableHeaders.allocatedQty'),
        accessor: 'allocated_quantity',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.orderedQty'),
        accessor: 'ordered_quantity',
        width: 'auto',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: TrashBinCell,
        width: 100,
      },
    ],
    [t],
  )

  return (
    <CompoundedTableEditable
      FormComponent={AllocationForm}
      columns={columns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={fetchNextItems}
      pages={pages}
      newTitle={t('list.tableButtons.new')}
    />
  )
}

export default AllocationTerritoryTable
