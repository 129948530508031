import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import InventoryOutTransferRequestActions from './duck'
import InventoryOutSelectors from './selectors'
import { InventoryTransferRequest } from '../inventoryInTransferRequest/types'
import * as managers from '../inventoryInTransferRequest/managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(InventoryOutSelectors.page)
    const filter = yield select(InventoryOutSelectors.filter)

    yield put(InventoryOutTransferRequestActions.filtersUpdatedWithPersistentStorage(filter))

    const sorting = yield select(InventoryOutSelectors.sorting)
    const pageSize = yield select(InventoryOutSelectors.pageSize)

    let response: ListResponse<InventoryTransferRequest> = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(InventoryOutTransferRequestActions.listRequestSucceed(data, total_count, page))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryOutTransferRequestActions.listRequestFailed(type, detail))
  }
}

export const updateItem = function*({
  payload: [id, dto],
}: ReturnType<typeof InventoryOutTransferRequestActions.updateRequested>) {
  try {
    const transferRequest: InventoryTransferRequest = yield call(managers.updateItem, id, dto)
    yield put(InventoryOutTransferRequestActions.updateSucceed(transferRequest))
    yield put(InventoryOutTransferRequestActions.listRequested({}))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(InventoryOutTransferRequestActions.updateFailed(id, errType))
  }
}

const InventoryTransferRequestSaga = function*() {
  yield all([
    takeLatest(InventoryOutTransferRequestActions.listRequested.type, fetchList),
    takeLatest(InventoryOutTransferRequestActions.filterUpdated.type, fetchList),
    takeLatest(InventoryOutTransferRequestActions.sortingUpdated.type, fetchList),
    takeLatest(InventoryOutTransferRequestActions.filterHasBeenReset.type, fetchList),
    takeLatest(InventoryOutTransferRequestActions.sortingHasBeenReset.type, fetchList),
    takeLatest(InventoryOutTransferRequestActions.updateRequested.type, updateItem),
  ])
}

export default InventoryTransferRequestSaga
