import React from 'react'
import UpTakeRoutes from './routes'

import { Switch, Route } from 'react-router-dom'
import UpTakeList from './UpTakeList'
import UpTakeItem from './UpTakeItem'

const UpTakePage: React.FC = () => {
  return (
    <Switch>
      <Route path={UpTakeRoutes.List} exact>
        <UpTakeList />
      </Route>
      <Route path={UpTakeRoutes.Item}>
        <UpTakeItem />
      </Route>
    </Switch>
  )
}

export default UpTakePage
