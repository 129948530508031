import { makeRefreshTokenAndRetry, TokensPair } from '@agro-club/frontend-shared'
import axios from 'axios'
import {
  clearRefreshToken,
  clearToken,
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from 'modules/domain/auth/repository'
import { endpoints } from 'modules/endpoints'
import { authClient } from './httpClient'
import { history } from 'modules/store'

const refreshToken = async (token: string): Promise<TokensPair> => {
  const response = await authClient.post<any>(endpoints.refreshToken(), { token })
  return {
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
  }
}

export const refreshTokenAndRetry = makeRefreshTokenAndRetry({
  getRefreshToken,
  refreshTokenPair: refreshToken,
  saveRefreshToken,
  saveAccessToken,
  clearAccessToken: clearToken,
  clearRefreshToken,
  client: axios,
  onFailedRetry: () => {
    history.push('/auth/login')
  },
})
