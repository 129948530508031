import React from 'react'
import { useTranslation } from 'react-i18next'
import { StatusIcon } from '@agro-club/frontend-shared'
import { NotificationsListEntryStatus } from 'modules/domain/notificationsList/types'

const Status: React.FC<{ status: NotificationsListEntryStatus }> = ({ status }) => {
  const { t } = useTranslation('notificationsList')
  const mapIconStatus = (status: NotificationsListEntryStatus) => {
    switch (status) {
      case NotificationsListEntryStatus.SENT:
        return 'active'
      case NotificationsListEntryStatus.DELIVERED:
        return 'inactive'
      case NotificationsListEntryStatus.FAILED:
        return 'deleted'
    }
  }

  const mapStatusI18n = (status: NotificationsListEntryStatus) => {
    switch (status) {
      case NotificationsListEntryStatus.FAILED:
        return t('status.failed')
      case NotificationsListEntryStatus.SENT:
        return t('status.sent')
      default:
        return status
    }
  }

  return (
    <div>
      <StatusIcon status={mapIconStatus(status)} />
      {mapStatusI18n(status)}
    </div>
  )
}

export default Status
