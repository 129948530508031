import managers from 'modules/domain/storefront/managers'
import { SkuOption, StorefrontItemType, StorefrontSkuFilterSuggestItem } from 'modules/domain/storefront/types'
import React, { useMemo } from 'react'
import { Status } from 'types/entities'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type SkuSelectProps = Omit<
  EntitySelectProps<StorefrontSkuFilterSuggestItem>,
  'getOptionLabel' | 'getOptionValue'
> & {
  status?: Status | Status[]
  sellerId?: string
  productId?: string | string[]
  type?: StorefrontItemType
}

export const getSkuValue = (sku: StorefrontSkuFilterSuggestItem) => sku.id
export const getSkuLabel = (sku: StorefrontSkuFilterSuggestItem) => {
  return `${sku.category_title ? sku.category_title + ' / ' : ''}${sku.product_title ? sku.product_title + ' / ' : ''}${
    sku.sku_id
  }`
}

export const getSkuMappedOption = (sku: StorefrontSkuFilterSuggestItem) => {
  const opts = sku.options
  delete sku.options
  return { ...sku, skuOptions: opts as SkuOption[] }
}

export const SkuSelect: React.VFC<SkuSelectProps> = ({ status, sellerId, productId, type, ...props }) => {
  const filter = useMemo(
    () => ({
      status,
      seller_id: sellerId,
      product_id: typeof productId === 'string' ? productId : undefined,
      product_ids: Array.isArray(productId) ? productId : undefined,
      sku_type: type,
    }),
    [status, sellerId, productId, type],
  )

  return (
    <SelectTestWrapper data-test-id="sku-select">
      <EntitySelect
        getEntityById={managers.getSelectItemsById}
        getEntityList={managers.getSelectItems}
        getOptionLabel={getSkuLabel}
        getOptionValue={getSkuValue}
        getMappedOption={getSkuMappedOption}
        filter={filter}
        {...props}
      />
    </SelectTestWrapper>
  )
}
