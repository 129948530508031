import managers from 'modules/domain/storefront/managers'
import { StorefrontItemType, StorefrontSkuFilterSuggestItem } from 'modules/domain/storefront/types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Status } from 'types/entities'
import { EntityMultiSelect, EntityMultiSelectProps } from '../EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { getSkuLabel, getSkuMappedOption, getSkuValue } from './SkuSelect'
import { theme } from 'theme/theme'
import { CustomSelectStyles } from '@agro-club/frontend-shared'
import { ProductOptionType } from 'modules/domain/productOptions/types'

export const Limit = styled.div`
  position: relative;
  right: 0;
  top: 16px;
  font-size: 14px;
  text-align: right;
`

export const dot = (colorId: string) => ({
  alignItems: 'center',
  display: 'flex',

  ...(!!colorId && {
    ':before': {
      backgroundColor: theme.color.statusColors[colorId],
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }),
})

export type SkuMultiSelectProps = Omit<
  EntityMultiSelectProps<StorefrontSkuFilterSuggestItem>,
  'getOptionLabel' | 'getOptionValue'
> & {
  status?: Status | Status[]
  sellerId?: string
  productId?: string | string[]
  type?: StorefrontItemType
  limit?: number
  showSkuInfo?: boolean
  showSkuStatus?: boolean
}

export const getSkuLabelWithInfo = (sku: StorefrontSkuFilterSuggestItem) => {
  const { category_title, product_title, sku_id, package_title, skuOptions = [] } = { ...sku }
  const seedTreatmentOption = skuOptions?.find(o => o.type === ProductOptionType.SEED_TREATMENT)
  const seedTreatment = seedTreatmentOption ? ` / ${seedTreatmentOption?.title}` : ''

  return `${category_title ? category_title + ' / ' : ''}${
    product_title ? product_title + ' / ' : ''
  }${sku_id} / ${package_title}${seedTreatment}`
}

export const SkuMultiSelect: React.VFC<SkuMultiSelectProps> = ({
  status,
  sellerId,
  productId,
  type,
  limit,
  showSkuInfo,
  showSkuStatus,
  ...props
}) => {
  const filter = useMemo(
    () => ({
      status,
      seller_id: sellerId,
      product_id: typeof productId === 'string' ? productId : undefined,
      product_ids: Array.isArray(productId) ? productId : undefined,
      sku_type: type,
    }),
    [status, sellerId, productId, type],
  )

  const colourStyles: CustomSelectStyles | undefined = showSkuStatus
    ? {
        option: (styles, { data }) => ({ ...styles, ...dot(data.status) }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.status) }),
      }
    : undefined

  return (
    <SelectTestWrapper data-test-id="sku-multi-select">
      {limit && <Limit>{`${!!props.values ? props.values.length : 0} / ${limit}`}</Limit>}
      <EntityMultiSelect
        getEntityByIds={managers.getSelectItemsByIds}
        getEntityList={managers.getSelectItems}
        filter={filter}
        getOptionLabel={showSkuInfo ? getSkuLabelWithInfo : getSkuLabel}
        getOptionValue={getSkuValue}
        getMappedOption={getSkuMappedOption}
        disableSearchClearOnSelect
        customStyles={colourStyles}
        {...props}
      />
    </SelectTestWrapper>
  )
}
