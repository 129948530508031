import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/company/managers'
import { CheckboxTreeSelect, CheckboxTreeSelectProps } from '@agro-club/frontend-shared'
import { Company } from 'modules/domain/company/types'

const useDistributorsOptions = () => makeCancellableResourceListHook(makeCancelable(managers.getDistributorsList))()

const DistributorsMultiSelect: React.FC<Omit<CheckboxTreeSelectProps, 'options' | 'filter'> & {
  filter?: (company: Company) => boolean
}> = ({ filter, ...selectProps }) => {
  // Don't refresh distributors options using server API
  // because of missing full text search.
  const [, distributors = []] = useDistributorsOptions()
  const { t } = useTranslation(['common'])

  const options = useMemo(() => {
    const filtered = filter ? distributors.filter(filter) : distributors
    return filtered.map(distributor => ({
      id: distributor.id,
      title: distributor.internal_name,
      hasChildren: !!distributor.has_branch_company,
      parent: distributor.head_company_relation?.company_id,
    }))
  }, [distributors, filter])

  return <CheckboxTreeSelect {...selectProps} options={options} placeholder={t('all')} />
}

export default DistributorsMultiSelect
