import { endpoints } from 'modules/endpoints'
import {
  ReconciliationSku,
  ReconciliationDTO,
  ReconciliationGroupFilter,
  ReconciliationGroupSorting,
  ReconciliationList,
  ReconciliationRetailer,
  ReconciliationManufacturer,
  ReconciliationSeasonFilter,
  RetailerStatusUpdateResponse,
  ReconciliationSkuFilter,
  ReconciliationSkuAddResponse,
} from './types'
import { ListResponse } from 'types/api'
import { mapReconciliationItem } from 'modules/domain/reconciliation/mapper'
import { octopusApiClient } from 'modules/utils/httpClient'
import { createItemsByIds } from '../../utils/helpers/createItemsByIds'
import { Season } from '../season/types'

export const getRetailers = (filter, sorting, page, pageSize) => {
  return octopusApiClient.get<ListResponse<ReconciliationRetailer>>(endpoints.reconciliationRetailers(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getSkus = (filter: ReconciliationSkuFilter, sorting, page, pageSize) => {
  const { manufacturer_id, retailer_id, ...restFilter } = { ...filter }

  return octopusApiClient.get<ListResponse<ReconciliationSku>>(
    endpoints.reconciliationSkuList(manufacturer_id, retailer_id),
    {
      ...restFilter,
      ...sorting,
      page: page,
      page_size: pageSize,
    },
  )
}

export const getRetailerById = async (id: string) => {
  return await octopusApiClient.get<ReconciliationRetailer>(endpoints.reconciliationRetailers(id))
}

export const getRetailersByIds = createItemsByIds(true, id =>
  octopusApiClient.get<ListResponse<ReconciliationRetailer>>(endpoints.reconciliationRetailers(), { id }),
)

export const getManufacturer = (filter, sorting, page, pageSize) => {
  return octopusApiClient.get<ListResponse<ReconciliationManufacturer>>(endpoints.reconciliationManufacturers(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getSeasons = (filter: ReconciliationSeasonFilter, sorting, page, pageSize) => {
  return octopusApiClient.get<ListResponse<Season>>(endpoints.reconciliationSeasons(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getManufacturersByIds = createItemsByIds(true, id =>
  octopusApiClient.get<ListResponse<ReconciliationManufacturer>>(endpoints.reconciliationRetailers(), { id }),
)

export const getGroups = async (
  filter: ReconciliationGroupFilter,
  sorting: ReconciliationGroupSorting,
  page: number,
  pageSize: number,
): Promise<ListResponse<ReconciliationList>> => {
  return await octopusApiClient.get<ListResponse<ReconciliationList>>(endpoints.reconciliationList(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const updateItem = async (
  manufacturerId: string,
  retailerId: string,
  skuId: string,
  dto: Partial<ReconciliationDTO>,
) => {
  return await octopusApiClient.patch<ReconciliationSku>(
    endpoints.reconciliationSkuItem(manufacturerId, retailerId, skuId),
    dto,
  )
}

export const getSkuById = async (manufacturerId: string, retailerId: string, skuId: string) => {
  return await octopusApiClient.get<ReconciliationSku>(
    endpoints.reconciliationSkuItem(manufacturerId, retailerId, skuId),
  )
}

export const updateTargetGroupComment = async ({
  key,
  comment,
}: {
  key: {
    retailer_id: string
    manufacturer_id: string
  }
  comment?: string
}) => {
  const res = await octopusApiClient.put<ReconciliationSku>(
    endpoints.reconciliationComment(key.manufacturer_id, key.retailer_id),
    {
      comment,
    },
  )
  return mapReconciliationItem(res, `${key.manufacturer_id}-${key.retailer_id}`)
}

export const updateRetailerStatus = async (manufacturerId: string, retailerId: string, isForced: boolean) => {
  return await octopusApiClient.put<RetailerStatusUpdateResponse>(
    endpoints.reconciliationRetailerStatus(manufacturerId, retailerId),
    {
      is_forced: isForced,
    },
  )
}

export const updateSku = async (manufacturerId: string, retailerId: string, sku_id: string, isForced: boolean) => {
  return await octopusApiClient.put<ReconciliationSkuAddResponse>(
    endpoints.reconciliationSkuItemAdd(manufacturerId, retailerId, sku_id),
    {
      is_forced: isForced,
    },
  )
}
