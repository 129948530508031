import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { CollectionState, CountryPhoneCode, Region, RegionPayload } from './types'

const initialState: CollectionState = {
  regions: [],
  regionsFetchProgress: Progress.IDLE,
  phoneCodes: [],
  phoneCodesFetchProgress: Progress.IDLE,
}

class CollectionReducer extends ImmerReducer<CollectionState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  regionsRequested(params: RegionPayload) {
    this.draftState.regionsFetchProgress = Progress.WORK
  }

  regionsRequestSucceed(regions: Region[]) {
    this.draftState.regions = regions
    this.draftState.regionsFetchProgress = Progress.SUCCESS
  }

  regionsRequestFailed() {
    this.draftState.regionsFetchProgress = Progress.ERROR
  }

  phoneCodesRequested() {
    this.draftState.phoneCodesFetchProgress = Progress.WORK
  }
  phoneCodesRequestSucceed(phoneCodes: CountryPhoneCode[]) {
    this.draftState.phoneCodesFetchProgress = Progress.SUCCESS
    this.draftState.phoneCodes = phoneCodes
  }
  phoneCodesRequestFailed() {
    this.draftState.phoneCodesFetchProgress = Progress.ERROR
  }
}

export const CollectionActions = createActionCreators(CollectionReducer)
export default CollectionActions
export const reducer = createReducerFunction(CollectionReducer, initialState)
