import { all, call, debounce, put, takeLatest } from 'redux-saga/effects'
import { ConfigActions } from './duck'
import { WhiteLabelConfig } from './types'
import * as managers from './managers'
import { RequestError } from 'modules/errors'
import { getLanguage, saveLanguage } from './repository'
import { DEFAULT_LANGUANGE } from 'modules/constants'
import { getI18nInstance } from 'modules/sagaEffects'

export const initConfig = function*({ payload: domain }: ReturnType<typeof ConfigActions.configRequested>) {
  try {
    const config: WhiteLabelConfig = yield call(managers.fetchConfig, domain)
    yield put(ConfigActions.languageInit(config.default_lang))
    yield put(ConfigActions.configRequestSucceed(config))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ConfigActions.configRequestFailed(errType))
  }
}

export const initLanguage = function*({ payload: configLang }: ReturnType<typeof ConfigActions.languageInit>) {
  try {
    const savedLang = yield call(getLanguage)
    const language = savedLang || configLang || DEFAULT_LANGUANGE
    const i18n = yield getI18nInstance()

    if (!savedLang) {
      yield call(saveLanguage, language)
    }
    i18n.changeLanguage(language)
    yield put(ConfigActions.languageInitSucceed(language))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Failed to save language')
    yield put(ConfigActions.languageInitFailed())
  }
}

export const changeLanguage = function*({ payload: lang }: ReturnType<typeof ConfigActions.languageChanged>) {
  yield call(saveLanguage, lang)
}

const ConfigSaga = function*() {
  yield all([
    debounce(10, ConfigActions.configRequested.type, initConfig),
    takeLatest(ConfigActions.languageInit.type, initLanguage),
    takeLatest(ConfigActions.languageChanged.type, changeLanguage),
  ])
}

export default ConfigSaga
