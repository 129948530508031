import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import AuthActions from './duck'
import { fetchCurrentUser, logout, obtainToken, resetPassword, sendResetEmail, updateCurrentUser } from './managers'
import AuthSelectors from './selectors'
import {
  clearProducer,
  clearRefreshToken,
  clearToken,
  getAccessToken,
  getRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from './repository'
import Routes from 'views/pages/Auth/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import i18n from 'i18n'
import { UserProfile } from 'modules/domain/auth/types'
import { isAgro, ROLES, Status } from 'types/entities'
import { boot as intercomBoot, shutdown } from 'intercom/boot'
import analytics from 'analytics/initAnalytics'
import ConfigSelectors from '../config/selectors'
import { WhiteLabelConfig } from '../config/types'
import { CANTERRA_COMPANY_NAME, CANTERRA_ID } from 'modules/constants'

export const getLocation = function*() {
  const location = yield select(AuthSelectors.location)

  const search: string = yield select(AuthSelectors.search)
  const state = yield select(AuthSelectors.locationState)

  const mainPath = DashboardRoutes.Dashboard
  const signInPath = Routes.Login
  const fromPath = state && state.from ? state.from : mainPath

  if (location === signInPath) {
    return {
      pathname: DashboardRoutes.Dashboard,
      from: fromPath,
    }
  }

  return {
    pathname: location,
    from: fromPath,
    search,
  }
}

export const initSaga = function*() {
  const { pathname } = yield call(getLocation)
  try {
    const token = yield call(getAccessToken)

    if (!token) {
      yield put(AuthActions.initFailed())
      if (pathname !== Routes.SetNewPassword) {
        return yield put(replace(Routes.Login, { from: pathname }))
      }
    } else {
      const profile: UserProfile = yield call(fetchCurrentUser)
      if (profile.status !== Status.Active || profile.role === ROLES.USER) {
        yield put(AuthActions.initFailed())
        return yield put(replace(Routes.Login, { from: pathname }))
      }

      yield put(AuthActions.initSucceed(profile))
    }
  } catch (e) {
    yield call(clearToken)
    yield put(AuthActions.initFailed())
    return yield put(replace(Routes.Login, { from: pathname }))
  }
}

export const loginSaga = function*({ payload }: ReturnType<typeof AuthActions.tokenRequested>) {
  try {
    const result = yield call(obtainToken, payload)
    const { from } = yield call(getLocation)

    yield put(AuthActions.tokenRequestSucceed(result))
    const profile: UserProfile = yield call(fetchCurrentUser)
    if (profile.status !== 'active') {
      yield put(AuthActions.tokenRequestFailed({ message: i18n.t('errors:wrong_credentials') }))
      return
    }
    if (profile.role === ROLES.USER) {
      yield put(AuthActions.tokenRequestFailed({ message: i18n.t('errors:wrong_credentials') }))
      return
    }
    const config: WhiteLabelConfig = yield select(ConfigSelectors.config)
    if (config?.third_party_keys?.intercom_app_id) {
      const fullName = `${profile.first_name} ${profile.second_name || ''} ${profile.last_name || ''}`
      const userName = fullName.trim().replace('  ', ' ')
      const isAdmin = isAgro(profile.role)
      const companyId = isAdmin ? CANTERRA_ID : String(profile?.company_id) || ''
      const companyName = isAdmin ? CANTERRA_COMPANY_NAME : profile?.company?.official_name || ''

      intercomBoot({
        userId: profile.id,
        email: profile.email,
        name: userName,
        app_id: config.third_party_keys.intercom_app_id,
        company: {
          id: companyId,
          name: companyName,
        },
      })
    }
    analytics.track('signin_succeed')
    yield put(AuthActions.initSucceed(profile))
    yield put(push(from))
  } catch (e) {
    yield put(AuthActions.tokenRequestFailed({ message: i18n.t('errors:wrong_credentials') }))
  }
}

export const resetPasswordSaga = function*({ payload }: ReturnType<typeof AuthActions.resetPasswordRequested>) {
  try {
    yield call(sendResetEmail, payload.email)
    yield put(AuthActions.resetPasswordRequestSucceed())
    if (payload.redirect) {
      yield put(push(payload.redirect))
    }
  } catch (e) {
    yield put(AuthActions.resetPasswordRequestFailed(e))
  }
}

export const setNewPasswordSaga = function*({ payload }: ReturnType<typeof AuthActions.setNewPasswordRequested>) {
  try {
    yield call(resetPassword, payload.password, payload.token)
    yield put(AuthActions.setNewPasswordSucceed())
  } catch (e) {
    yield put(AuthActions.setNewPasswordFailed())
  }
}

export const updateTokensSaga = function*({ payload }: ReturnType<typeof AuthActions.tokenRequestSucceed>) {
  yield call(saveAccessToken, payload.accessToken)
  yield call(saveRefreshToken, payload.refreshToken)
}

export const updateUserProfile = function*({ payload }: ReturnType<typeof AuthActions.userUpdateRequested>) {
  try {
    const profile = yield call(updateCurrentUser, payload)
    yield put(AuthActions.userUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.userUpdateFailed())
  }
}

export const signOutSaga = function*() {
  const token = yield call(getRefreshToken)
  yield call(logout, token)
  yield call(clearProducer)
  yield call(clearToken)
  yield call(clearRefreshToken)
  yield put(push(Routes.Login))
  yield call(shutdown)
  yield call(() => window.location.reload)
}

export const initAnalytics = function*({ payload: profile }: ReturnType<typeof AuthActions.initSucceed>) {
  const isAdmin = isAgro(profile.role)

  try {
    const fullName = `${profile.first_name} ${profile.second_name || ''} ${profile.last_name || ''}`

    yield analytics.identify(profile.id, {
      user_name: fullName,
      user_email: profile.email,
      user_phone: profile.phone_number,
      user_role: profile.role,
      company_id: profile.company_id,
      company_name: profile.company?.official_name,
      company_type: profile.company?.company_type,
    })
    const config: WhiteLabelConfig = yield select(ConfigSelectors.config)

    // Store this data for GTM (Intercom integration)
    if (window.dataLayer && config?.third_party_keys?.intercom_app_id) {
      const companyId = isAdmin ? CANTERRA_ID : String(profile?.company_id) || ''
      const companyName = isAdmin ? CANTERRA_COMPANY_NAME : profile?.company?.official_name || ''

      intercomBoot({
        userId: profile.id,
        email: profile.email,
        name: fullName,
        app_id: config.third_party_keys.intercom_app_id,
        company: {
          id: companyId,
          name: companyName,
        },
      })
      window.dataLayer.push({
        userId: profile.id,
        userEmail: profile.email,
        userPhone: profile.phone_number,
        userName: fullName,
      })

      if (profile.company_id) {
        window.dataLayer.push({
          companyId: profile.company_id,
          companyName: profile.company?.official_name,
          companyType: profile.company?.company_type,
        })
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed to initialize analytics variables')
  }
}

export const AuthSaga = function*(): Generator {
  yield all([
    takeLatest(AuthActions.init.type, initSaga),
    takeLatest(AuthActions.tokenRequested.type, loginSaga),
    takeLatest(AuthActions.tokenRequestSucceed.type, updateTokensSaga),
    takeLatest(AuthActions.resetPasswordRequested.type, resetPasswordSaga),
    takeLatest(AuthActions.userUpdateRequested.type, updateUserProfile),
    takeLatest(AuthActions.signOutRequested.type, signOutSaga),
    takeLatest(AuthActions.setNewPasswordRequested.type, setNewPasswordSaga),
    takeLatest(AuthActions.initSucceed.type, initAnalytics),
  ])
}

export default AuthSaga
