import { Badge, BadgeListRequestFilter, BadgeActionWithUrls, BadgeListRequestSorting } from 'modules/domain/badge/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'

export const getBadgesList = (
  filter: BadgeListRequestFilter,
  sorting: BadgeListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Badge>>(endpoints.badge(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Badges list fetch error!', err)
    throw err
  }
}

export const getCrmBadgesList = (search?: string, pageSize?: number) => {
  try {
    return apiClient.get<ListResponse<Badge>>(endpoints.badge(), {
      ...(search && { search }),
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Crm badge list fetch error!', err)
    throw err
  }
}

export const getBadge = (id: string) => {
  try {
    return apiClient.get<Badge>(endpoints.badge(id))
  } catch (err) {
    console.error('Badge fetch error!', err)
    throw err
  }
}

export const addBadge = (badge: BadgeActionWithUrls) => {
  try {
    return apiClient.post<Badge>(endpoints.badge(), badge)
  } catch (err) {
    console.error('Badge create error!', err)
    throw err
  }
}

export const updateBadge = (id: string, badge: BadgeActionWithUrls) => {
  return apiClient.put<Badge>(endpoints.badge(id), badge)
}

export const removeBadge = (id: string) => {
  return apiClient.delete(endpoints.badge(id))
}
