import { Company } from 'modules/domain/company/types'
import { FEATURE_FLAGS_ROLES, ROLES } from 'types/entities'

const checkCompanyFeatureFlags = (
  company: Company | null | undefined,
  flagName: string,
  permission?: ROLES | null,
): boolean => {
  if (!company) return false

  const flagsObj = company?.config?.seller_config?.feature_flags
  const flagPermissions = flagsObj?.[flagName]
  if (!flagPermissions) {
    return false
  }
  if (permission) {
    return !!flagPermissions[permission] || !!flagPermissions[FEATURE_FLAGS_ROLES.ALL]
  }
  // if permission to check is not specified find any "true" permission
  for (const perm in flagPermissions) {
    if (flagPermissions[perm]) {
      return true
    }
  }
  return false
}

export default checkCompanyFeatureFlags
