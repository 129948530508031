import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  FarmerOptionInTarget,
  FarmerOptionInTargetListRequestFilter,
  FarmerOptionInTargetListRequestSorting,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: FarmerOptionInTargetListRequestFilter,
  sorting: FarmerOptionInTargetListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<FarmerOptionInTarget>>(endpoints.farmerOptionInTarget(), {
    ...filter,
    ...sorting,
    page,
    page_size: pageSize,
  })
}
