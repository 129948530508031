import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { usePromocodeByIdOnce } from 'modules/domain/promocode/hooks'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import PromocodeRoutes from 'views/pages/Promocode/routes'
import PromocodeActions from 'modules/domain/promocode/duck'
import PromocodeDetailsForm, { FormProps } from 'views/pages/Promocode/PromocodeDetailsForm/PromocodeDetailsForm'
import { PromocodeType } from 'modules/domain/promocode/types'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const PromocodeEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { t } = useTranslation(['promocode', 'common'])
  const [progress, item] = usePromocodeByIdOnce(params.id)
  const meta = useSelector(PromocodeSelectors.meta(params.id))
  const push = useHistoryPush()
  const page = useSelector(PromocodeSelectors.page)
  const removeProgress = useSelector(PromocodeSelectors.removeProgress)
  const goBack = () => push({ route: PromocodeRoutes.List, query: { page } })
  const removeAction = useAction(PromocodeActions.removeRequested, params.id)
  const submitAction = useAction(PromocodeActions.updateRequested)

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const handleFormSubmit = (formProps: FormProps) => {
    const { type, legal_text_i18n, prompt_i18n } = formProps.params
    switch (type) {
      case PromocodeType.WithComment:
        submitAction(params.id, { ...formProps, params: { type, prompt_i18n: prompt_i18n || null } })
        break
      case PromocodeType.WithLegalText:
        submitAction(params.id, { ...formProps, params: { type, legal_text_i18n: legal_text_i18n || null } })
        break
      case PromocodeType.Simple:
        submitAction(params.id, { ...formProps, params: { type } })
    }
  }

  if (progress === Progress.WORK || !item) {
    return loading()
  }

  if (progress === Progress.ERROR || removeProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.headingEdit')} />
          <Id>{`ID ${params.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <PromocodeDetailsForm
          onSubmit={handleFormSubmit}
          onCancel={goBack}
          onRemove={removeAction}
          initialValues={item}
          removeProgress={removeProgress}
          progress={meta.updateProgress}
          errorText={meta.updateErrorDetail}
          editing
        />
      </Layout.Content>
    </>
  )
}

export default PromocodeEdit
