import { Button, SectionContainer } from '@agro-club/frontend-shared'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 354px auto;
`

export const Column = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`
export const DatePickerWrapper = styled.div`
  width: 220px;
`

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px;
`

export const PrintButton = styled(Button)`
  margin: 0 0 0 auto;
  align-self: flex-end;
  min-width: 150px;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.color.accentDestructive};
  font-size: 14px;
  font-weight: 400;
`

export const SeasonWrap = styled.div<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const DeclarationTypeWrap = styled.div<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalButton = styled(Button)`
  width: 184px;
  height: 48px;
  margin-top: 30px;

  &:first-child {
    margin-right: 25px;
  }

  &:last-child {
    margin-left: 25px;
  }
`

export const ConfirmPopupMessageWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
  & > a {
    color: ${({ theme }) => theme.color.primary600};
    text-decoration: none;
  }
`

export const Container = styled(SectionContainer)<{ isAllowed?: boolean }>`
  min-width: ${props => props.theme.size.minOrderListWidth}px;
  width: fit-content;
  transition: 0.3s all;
  position: relative;
  ${(props: { isAllowed?: boolean }) =>
    props.isAllowed
      ? ''
      : css`
          opacity: 0.8;
          cursor: not-allowed;
        `}
`
