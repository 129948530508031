import { BasicSelect, Dict, Progress } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { SkuOption, StorefrontSku } from 'modules/domain/storefront/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DictWithKey } from 'types/generics'
import { LocalPackageTypeSelect } from 'views/components/PackageTypeSelect/LocalPackageTypeSelect'

const SkuOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

type SelectState = {
  type: ProductOptionType
  value: SkuOption | null
}

//Sort by type alphabetically
const sortState = (state: SelectState[]) => state.sort((a, b) => a.type.localeCompare(b.type))

const skuOptionsToSelectState = (skuOptions: SkuOption[]): SelectState[] => {
  return sortState(
    skuOptions.map(item => ({
      type: item.type,
      value: item,
    })),
  )
}

const filterSkuByState = (states: SelectState[], skus: StorefrontSku[]) => {
  return skus.filter(sku => {
    return sku.params.options.every(option => {
      const state = states.find(state => state.type === option.type)
      return option.option_id === state?.value?.option_id || !state?.value
    })
  })
}

//Same as previous, but empty options are not allowed and returns only one sku
const findExactSkuByState = (states: SelectState[], skus: StorefrontSku[]) => {
  return skus.find(sku => {
    return sku.params.options.every(option => {
      const state = states.find(state => state.type === option.type)
      return option.option_id === state?.value?.option_id
    })
  })
}

const getFilteredSkus = (availableSku: StorefrontSku[], packageType = '') =>
  availableSku.filter(sku => sku.params.package_id === packageType)

const getSkusOptionTypes = (skus: StorefrontSku[]) => {
  return [...new Set(skus.map(({ params }) => params.options.map(option => option.type)).flat())]
}

const getInitialState = (skus: StorefrontSku[]) =>
  sortState(
    getSkusOptionTypes(skus).map(type => ({
      type: type as ProductOptionType,
      value: null,
    })),
  )

const prepareSelectStateToNewPossibleSkus = (prevState: SelectState[], newPossibleSkus: StorefrontSku[]) => {
  const newStates: SelectState[] = []

  newPossibleSkus.forEach(sku => {
    sku.params.options.forEach(option => {
      const index = prevState.findIndex(state => state.type === option.type)
      const newStateIndex = newStates.findIndex(state => state.type === option.type)

      //if prevState has this type
      if (index !== -1) {
        // if prevState of this type of option has different value it should be cleared
        if (prevState[index].value?.option_id !== option.option_id) {
          if (newStateIndex === -1) newStates.push({ type: option.type, value: null })
          else newStates[newStateIndex].value = null
        } else if (newStateIndex === -1) {
          newStates.push(prevState[index])
        }
        //if prevState has not this type, but in newPossibleSkus it is
      } else {
        if (newStateIndex === -1) {
          newStates.push({
            type: option.type,
            value: null,
          })
        } else {
          newStates[newStateIndex].value = null
        }
      }
    })
  })

  return sortState(newStates)
}

export const SkuOptionsMatcher: React.VFC<{
  sku?: StorefrontSku
  packageType?: string
  availablePackageTypes?: PackageTypes[]
  availableSku: StorefrontSku[]
  progress: Progress
  onSkuMatched: (value?: StorefrontSku) => void
  onPackageTypeChange: (value?: string) => void
  onOptionChange?: (value?: any[]) => void
  disabled?: boolean
  optionsRequired?: boolean
  showErrors?: boolean
}> = ({
  onPackageTypeChange,
  onOptionChange,
  packageType,
  sku,
  availableSku,
  availablePackageTypes = [],
  progress,
  onSkuMatched,
  disabled,
  showErrors,
  optionsRequired,
}) => {
  const { t } = useTranslation(['productOptions', 'storefront'])
  const { pick } = useLangPicker()

  const [optionIdToSkus, optionsByType] = useMemo(() => {
    const optionsByType: DictWithKey<SkuOption[], ProductOptionType> = {}
    const optionsByPackage: Dict<SkuOption> = {}
    const optionIdToSkus: Dict<StorefrontSku[]> = {}

    getFilteredSkus(availableSku, packageType).forEach(sku => {
      sku.params.options.forEach(option => {
        const dictOptions = optionsByType[option.type]
        optionsByPackage[option.option_id] = option
        optionIdToSkus[option.option_id] = optionIdToSkus[option.option_id]
          ? [...optionIdToSkus[option.option_id], sku]
          : [sku]
        if (!dictOptions) return (optionsByType[option.type] = [option])
        if (!dictOptions.some(opt => opt.option_id === option.option_id)) dictOptions.push(option)
      })
    })

    return [optionIdToSkus, optionsByType]
  }, [availableSku, packageType])

  const [selectsState, setSelectsState] = useState(
    sku?.params.options.length
      ? skuOptionsToSelectState(sku.params.options)
      : getInitialState(getFilteredSkus(availableSku, packageType)),
  )

  const handleOptionChange = (idx: number) => (value: SkuOption | null) => {
    if (!packageType || selectsState[idx].value?.option_id === value?.option_id) return

    let newSelectsState = [...selectsState]
    newSelectsState[idx].value = value

    if (value) {
      let eligibleSkus = filterSkuByState(newSelectsState, optionIdToSkus[value.option_id])

      //if current state is invalid
      if (eligibleSkus.length === 0) {
        newSelectsState = prepareSelectStateToNewPossibleSkus(newSelectsState, optionIdToSkus[value.option_id])
        eligibleSkus = filterSkuByState(newSelectsState, optionIdToSkus[value.option_id])
        //if current state is valid (sometimes some options should be added or deleted)
      } else {
        newSelectsState = prepareSelectStateToNewPossibleSkus(newSelectsState, eligibleSkus)
      }

      if (eligibleSkus.length === 1) {
        //if there is no sku we can match it partially
        if (!sku?.id) {
          onSkuMatched(eligibleSkus[0])
          newSelectsState = skuOptionsToSelectState(eligibleSkus[0].params.options)
        } else {
          //if sku is already selected, we should match only exact sku options to prevent mistakes when user didn't notice that sku is changed
          const exactSkuMatch = findExactSkuByState(newSelectsState, eligibleSkus)
          if (exactSkuMatch) onSkuMatched(exactSkuMatch)
          else onSkuMatched()
        }
      } else onSkuMatched()
    }

    setSelectsState(newSelectsState)
  }

  const handlePackageTypeChange = (value?: string) => {
    const skus = getFilteredSkus(availableSku, value)
    const newSelectsState = getInitialState(skus)
    onPackageTypeChange(value)

    if (value && skus.length === 1) {
      onSkuMatched(skus[0])
      return setSelectsState(skuOptionsToSelectState(skus[0].params.options))
    }
    setSelectsState(newSelectsState)
  }

  useEffect(() => {
    if (availablePackageTypes.length === 1 && packageType !== availablePackageTypes[0].id)
      handlePackageTypeChange(availablePackageTypes[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePackageTypes])

  useEffect(() => {
    onOptionChange?.(selectsState)
  }, [onOptionChange, selectsState])

  return (
    <SkuOptionsWrapper>
      <LocalPackageTypeSelect
        value={packageType}
        invalid={showErrors && !packageType}
        onChange={handlePackageTypeChange}
        progress={progress}
        packageTypes={availablePackageTypes}
        isLoading={progress === Progress.WORK}
        isDisabled={disabled}
        variant="small"
        isClearable
        minWidth="180px"
        menuMaxHeight="250px"
        maxWidth="250px"
        wrapOptionsText
        wrapValueText
      />
      {!!packageType &&
        selectsState.map((state, idx) => (
          <BasicSelect
            data-test-id={`sku-option-select-${state.type}`}
            key={state.type}
            getOptionLabel={opt => (opt.title_i18n ? pick(opt.title_i18n) : `${opt.title}`)}
            getOptionValue={opt => opt.option_id}
            onChange={handleOptionChange(idx)}
            placeholder={t(`types.${state.type}`)}
            options={optionsByType[state.type]}
            value={state.value}
            isSearchable
            invalid={!state.value}
            progress={progress}
            isClearable
            menuMaxHeight="250px"
            maxWidth="250px"
            minWidth="180px"
            variant={'small'}
            wrapOptionsText
            wrapValueText
            required={showErrors && optionsRequired}
            isDisabled={disabled || progress === Progress.WORK}
          />
        ))}
    </SkuOptionsWrapper>
  )
}
