import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HouseholdDetailsForm, { FormData } from './HouseholdDetailsForm/HouseholdDetailsForm'
import HouseholdSelectors from 'modules/domain/household/selectors'
import HouseholdsRoutes from 'views/pages/Household/routes'
import HouseholdActions from 'modules/domain/household/duck'
import { useParams } from 'react-router-dom'
import { useHousehold } from 'modules/domain/household/hooks'
import { Progress } from 'modules/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import * as Styled from './styled'

const HouseholdEdit: React.FC = () => {
  const { t } = useTranslation('household')
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const householdUpdateAction = useAction(HouseholdActions.updateRequested)
  const householdRemoveAction = useAction(HouseholdActions.removeRequested, params.id)
  const page = useSelector(HouseholdSelectors.page)

  const handleSubmit = useCallback(
    (fields: FormData) => {
      householdUpdateAction(params.id, {
        name: fields.name,
        senior_partner_id: fields.senior_partner_id,
        partners_ids: fields.partners_ids,
        comment: fields.comment,
      })
    },
    [householdUpdateAction, params.id],
  )

  const [fetchProgress, household] = useHousehold(params.id)

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const meta = useSelector(state => HouseholdSelectors.meta(state, params.id))

  const goBack = () => push({ route: HouseholdsRoutes.List, query: { page } })
  const progress = useSelector(HouseholdSelectors.updateProgress)
  const removeProgress = useSelector(HouseholdSelectors.removeProgress)

  useHelmet({ title: household?.slug || household?.name || t('editHouseholdMetaTitle') })

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !household) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={household.name} />
          <Styled.HouseholdId>{`ID ${household.id}`}</Styled.HouseholdId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <HouseholdDetailsForm
          onCancel={goBack}
          onSubmit={handleSubmit}
          onRemove={householdRemoveAction}
          progress={progress}
          removeProgress={removeProgress}
          id={household.id}
          initialValues={household}
        />
      </Layout.Content>
    </>
  )
}

export default HouseholdEdit
