import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import CompanyTypeFilter from 'views/components/TableFilters/CompanyTypeFilter'
import CountryFilter from 'views/components/TableFilters/CountryFilter'
import { useTranslation } from 'react-i18next'
import { CompanyType } from 'types/entities'
import { Company, CompanyListRequestFilter } from 'modules/domain/company/types'
import { useSelector } from 'react-redux'
import { useCompanyList } from 'modules/domain/company/hooks'
import CompanyActions from 'modules/domain/company/duck'
import CompanySelectors from 'modules/domain/company/selectors'
import { generatePath, useHistory } from 'react-router-dom'
import CompaniesRoutes from 'views/pages/Company/routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import {
  AdvancedHeadCell,
  Button,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import { TableFilter } from 'views/components/TableFilters/TableFilters'

const NameCell: React.FC<CellProps<Company>> = ({ cell, column, row }) => {
  return (
    <div>
      <StatusIcon status={row.values.is_active ? 'active' : 'inactive'} />
      <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
    </div>
  )
}

const NameHeaderCell: React.FC = () => {
  const { t } = useTranslation('company')
  return (
    <div>
      <span>{t('list.tableHeaders.internalName')}</span>
    </div>
  )
}

const CountryCell: React.FC<CellProps<Company>> = ({ cell, column }) => {
  const { t } = useTranslation('common')
  return (
    <div key={column.id}>
      <TComponents.Flag code={cell.value} />
      {cell.value ? t(`country.full.${cell.value}`) : ''}
    </div>
  )
}

const TypeCell: React.FC<CellProps<Company>> = ({ cell, column }) => {
  const { t } = useTranslation('company')
  return <div key={column.id}>{t(`type.${cell.value}`)}</div>
}

const CompaniesSortableHeadCell = AdvancedHeadCell<keyof Company>()

const CompanyList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['company', 'labels'])
  const [progress, data = []] = useCompanyList({})
  const filterUpdated = useAction(CompanyActions.filterUpdated)
  const sortingUpdated = useAction(CompanyActions.sortingUpdated)
  const listRequested = useAction(CompanyActions.listRequested)
  const filterValue = useSelector(CompanySelectors.filter)
  const total = useSelector(CompanySelectors.total)
  const page = useSelector(CompanySelectors.page)
  const pages = useSelector(CompanySelectors.pages)
  const pageSize = useSelector(CompanySelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CompanySelectors.sorting)
  const { company_type, country, is_active } = filterValue

  const allColumns = React.useMemo(
    () => [
      {
        Header: NameHeaderCell,
        accessor: 'internal_name' as const,
        Cell: NameCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.officialName'),
        accessor: 'official_name' as const,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.id'),
        accessor: 'id' as const,
      },
      {
        Header: t('list.tableHeaders.type'),
        accessor: 'company_type' as const,
        Cell: TypeCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.country'),
        accessor: 'country' as const,
        Cell: CountryCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.city'),
        accessor: 'city' as const,
        sortable: false,
      },
      {
        Header: 'is_active',
        accessor: 'is_active' as const,
        hidden: true,
      },
    ],
    [t],
  )

  const hiddenColumns = useMemo(() => ['is_active'], [])

  const { columns, rows, prepareRow } = useTable<Company>({
    columns: allColumns,
    data,
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<CompanyListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const filterCompanyTypes = useMemo(() => [CompanyType.Producer, CompanyType.Distributor], [])
  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const statusFilterOptions = useMemo(
    () => [
      {
        value: true,
        title: t('status.active'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: false,
        title: t('status.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const [progressCSV, downloadCSV] = useDownload(endpoints.companies('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const { scrollRef } = usePersistentScroll('companyList')
  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <CompanyTypeFilter
          filterValue={{ company_type }}
          handleFilterChange={handleFilterChange}
          types={filterCompanyTypes}
        />
        <CountryFilter filterValue={{ country }} handleFilterChange={handleFilterChange} />
        <TableFilter<CompanyListRequestFilter, 'is_active', boolean, true>
          handleFilterChange={handleFilterChange}
          title={t('labels:status')}
          options={statusFilterOptions}
          filterValue={{ is_active }}
          disableMulti
        />
        <AlignWrapper horizontalAlign="right" verticalAlign="bottom">
          <Button onClick={downloadCSV} intent={'primary'} filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <CompaniesSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Company}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                  top={'88px'}
                >
                  {column.render('Header')}
                </CompaniesSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(CompaniesRoutes.Edit, { id: row.original.id }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CompanyList
