import { useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import AuthSelectors from 'modules/domain/auth/selectors'
import CropTargetSkuActions from 'modules/domain/target2sku/cropTarget/duck'
import { useCropTargetSkuList } from 'modules/domain/target2sku/cropTarget/hooks'
import CropTargetSkuSelectors from 'modules/domain/target2sku/cropTarget/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isProducer } from 'types/entities'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import { CropTargetSkuForm } from '../forms/CropTargetSkuForm'
import { BolderCell, TertiaryCell, TitleCell, TitleI18nCell } from './common'
import { TextTertiary } from '../styles'
import useCompareStrings from 'hooks/useCompareStrings'

export const CropTargetSkuTable: React.FC = () => {
  const { t } = useTranslation('target2')
  const { pick } = useLangPicker()

  const [progress, data = []] = useCropTargetSkuList()

  const total = useSelector(CropTargetSkuSelectors.total)
  const page = useSelector(CropTargetSkuSelectors.page)
  const pages = useSelector(CropTargetSkuSelectors.pages)
  const pageSize = useSelector(CropTargetSkuSelectors.pageSize)
  const targetsTotal = useSelector(CropTargetSkuSelectors.targetsTotal)
  const retailerTargetsTotal = useSelector(CropTargetSkuSelectors.retailerTargetsTotal)
  const farmerOrdersTotal = useSelector(CropTargetSkuSelectors.farmerOrdersTotal)
  const previousYear = useSelector(CropTargetSkuSelectors.previousYear)

  const listRequested = useAction(CropTargetSkuActions.listRequested)

  const { scrollRef } = usePersistentScroll('cropTargetSkuList')

  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)
  const compareStrings = useCompareStrings()

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.manufacturer'),
        accessor: 'manufacturer' as const,
        width: 193,
        hidden: producer,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory',
        width: 193,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season',
        width: 193,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: `subcategory` as const,
        width: 160,
        Cell: TitleI18nCell(pick),
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.territoryTarget'),
        accessor: 'target_value' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.retailerTarget'),
        accessor: 'retailer_target' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
        Cell: BolderCell,
      },
      {
        Header: t('list.tableHeaders.farmerOrders'),
        accessor: 'farmer_orders_sku' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: <TextTertiary>{t('list.tableHeaders.farmerPreviousYear')}</TextTertiary>,
        accessor: 'farmer_orders_sku_previous_year' as const,
        width: 76,
        Cell: TertiaryCell,
        headerAlign: 'right',
        cellAlign: 'right',
      },
    ],
    [t, producer, compareStrings, pick],
  )

  const metaColumns = useMemo(
    () => ({
      farmer_orders_sku: farmerOrdersTotal,
      target_value: targetsTotal,
      retailer_target: retailerTargetsTotal,
      farmer_orders_sku_previous_year: <TextTertiary>{previousYear}</TextTertiary>,
    }),
    [targetsTotal, retailerTargetsTotal, farmerOrdersTotal, previousYear],
  )

  const canEdit = usePermissions({ capability: CAPABILITY.CROP_TARGETS, permission: PERMISSION.CRU })

  return (
    <CompoundedTableEditable
      FormComponent={CropTargetSkuForm}
      columns={columns}
      metaColumns={metaColumns}
      data={data}
      ref={scrollRef}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => {
        listRequested({ page: num })
      }}
      pages={pages}
      readonly={!canEdit}
      newTitle={t('form.new')}
      isSortable
      defaultSortField="territory"
    />
  )
}

export default CropTargetSkuTable
