import { CustomSelectStyles } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { StorefrontCardForSelect, StorefrontCardRequestFilterSuggest } from 'modules/domain/storefrontCard/types'
import React, { useMemo } from 'react'
import { Status } from 'types/entities'
import { dot } from 'views/pages/ProductSettings/AvailabilityColorSelect'
import managers from 'modules/domain/storefrontCard/managers'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { getProductNameWithCategory } from 'modules/domain/product/helpers'
import { getCardMeta } from 'modules/domain/storefrontCard/helpers'
import styled from 'styled-components'

export const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export type CardSelectProps = Omit<EntitySelectProps<StorefrontCardForSelect>, 'getOptionLabel' | 'getOptionValue'> & {
  status?: Status | Status[]
  skuStatus?: Status | Status[]
  sellerId?: string
  productId?: string | string[]
  availableFor?: StorefrontCardRequestFilterSuggest['available_for']
  showAvailability?: boolean
}

export const getCardValue = (card: StorefrontCardForSelect) => card.id

const defaultSort = { sort_field: 'subcategory' }

export const CardSelect: React.VFC<CardSelectProps> = ({
  status,
  skuStatus,
  sellerId,
  productId,
  availableFor,
  showAvailability,
  ...props
}) => {
  const { pick } = useLangPicker()

  const filter: StorefrontCardRequestFilterSuggest = useMemo(
    () => ({
      status,
      sku_status: skuStatus,
      seller_id: sellerId,
      product_id: productId,
      available_for: availableFor,
    }),
    [status, skuStatus, sellerId, productId, availableFor],
  )

  const getFormattedCardLabel = (card: StorefrontCardForSelect) => {
    const label = getProductNameWithCategory(
      pick(card.title_i18n),
      pick(card.category?.title_i18n),
      pick(card.subcategory?.title_i18n),
    )
    const meta = getCardMeta(card)

    return (
      <OptionLabel>
        <div>{label}</div>
        <div>{meta}</div>
      </OptionLabel>
    )
  }

  const getCardLabel = (card: StorefrontCardForSelect) =>
    getProductNameWithCategory(
      pick(card.title_i18n),
      pick(card.category?.title_i18n),
      pick(card.subcategory?.title_i18n),
    )

  const colourStyles: CustomSelectStyles | undefined = showAvailability
    ? {
        option: (styles, { data }) => ({ ...styles, ...dot(data.availability) }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.availability) }),
      }
    : undefined

  return (
    <SelectTestWrapper data-test-id={'card-select'}>
      <EntitySelect
        getEntityList={managers.getListForFilter}
        getOptionLabel={getCardLabel}
        formatOptionLabel={getFormattedCardLabel}
        getOptionValue={getCardValue}
        filter={filter}
        customStyles={colourStyles}
        sort={defaultSort}
        {...props}
      />
    </SelectTestWrapper>
  )
}
