import { FormikHook, IconCross, IconPlus, Input, SimpleSelect, SimpleSelectOption } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { useAllProductOptionsList } from 'modules/domain/productOptions/hooks'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { SkuOption, SkuOptionParams } from 'modules/domain/storefront/types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Dict } from 'types/generics'
import * as Styled from './styled'

export type OptionsFormProps = {
  options: SkuOption[]
}

type SkuOptionSelectType = SimpleSelectOption & {
  is_stackable: boolean
}

type SelectState = {
  value?: string
  options: SkuOptionSelectType[]
  type: ProductOptionType
  params: SkuOptionParams
  isStackable: boolean
}

const SkuOptionSelect = styled.div`
  position: relative;
`

const AddOption = styled(IconPlus)`
  position: absolute;
  top: -4px;
  right: 20px;
  fill: ${props => props.theme.color.primary500};
  cursor: pointer;
  z-index: 1;
`

const RemoveOption = styled(IconCross)`
  position: absolute;
  top: 1px;
  right: 0;
  fill: ${props => props.theme.color.accentDestruct500};
  cursor: pointer;
  z-index: 1;
`

const PercentageOption = styled.div`
  margin-top: 8px;
`

const stateToSkuOption = (group: SelectState): SkuOption => ({
  type: group.type,
  params: group.params,
  option_id: group.value || '',
  is_stackable: group.isStackable,
})

const StorefrontOptionsForm: React.FC<{
  useFormik: FormikHook
  options: SkuOption[]
}> = ({ useFormik, options = [] }) => {
  const { t } = useTranslation(['productOptions', 'storefront'])
  const { pick } = useLangPicker()
  const [, productOptionsList = []] = useAllProductOptionsList()
  const formik = useFormik<OptionsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      options,
    },
    enableReinitialize: true,
  })

  const optionsByType = useMemo(() => {
    const result: Dict<SkuOptionSelectType[]> = {}

    productOptionsList.forEach(opt => {
      const mapped = {
        id: opt.id,
        title: pick(opt?.title_i18n),
        is_stackable: !!opt?.is_stackable,
      }
      if (result[opt.type]) return result[opt.type].push(mapped)
      result[opt.type] = [mapped]
    })
    return result
  }, [pick, productOptionsList])

  const initialSelectsState = useMemo(() => {
    if (!options.length) return []
    return options.map(value => ({
      type: value.type,
      options: optionsByType[value.type],
      value: value ? value.option_id : undefined,
      params: value ? value.params : {},
      isStackable: value.is_stackable ?? false,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [selectsState, setSelectsState] = useState<SelectState[]>(initialSelectsState)

  const productOptionsTypes = useMemo(
    () =>
      Object.values(ProductOptionType).map(type => ({
        id: type,
        title: t(`types.${type}`),
      })),
    [t],
  )

  const handleOptionRemove = idx => {
    setSelectsState(prev => {
      const newState = prev.slice()
      newState.splice(idx, 1)
      return newState
    })
    const newFormikVal = formik.values.options.slice()
    newFormikVal.splice(idx, 1)
    formik.setFieldValue(`options`, newFormikVal)
  }

  const handleOptionAdd = (type: ProductOptionType) => {
    setSelectsState(prev => [
      ...prev,
      {
        type,
        options: optionsByType[type],
        value: undefined,
        params: {},
        isStackable: false,
      },
    ])
  }

  const handleStateUpdate = useCallback(
    (index: number, newState: Partial<SelectState>) => {
      const newSelectState = { ...selectsState[index], ...newState }
      setSelectsState(prev => {
        const newState = [...prev]
        newState[index] = newSelectState
        return newState
      })
      formik.setFieldValue(`options[${index}]`, {
        ...formik.values.options[index],
        ...stateToSkuOption(newSelectState),
      })
    },
    [formik, selectsState],
  )

  const handlePercentageChange = useCallback(
    (idx: number, percentage?: string) => {
      if (percentage === undefined) return
      handleStateUpdate(idx, { params: { ...selectsState[idx].params, percentage: parseFloat(percentage) } })
    },
    [handleStateUpdate, selectsState],
  )

  const getCanAddMore = (index: number, type: ProductOptionType) => {
    return selectsState.length - 1 === index ? true : selectsState[index + 1].type !== type
  }

  return (
    <Styled.Grid1Col>
      <SimpleSelect
        data-test-id="product-options-type-select"
        label={t('storefront:skuForm.labels.addOption')}
        placeholder=""
        options={productOptionsTypes}
        isOptionDisabled={option => !!selectsState.find(state => state.type === option.id)}
        onChange={handleOptionAdd}
      />
      {selectsState.map((state, index) => {
        const isStackable = false
        return (
          <SkuOptionSelect
            data-test-id={`sku-option-select-${state.type}${isStackable ? `-${index}` : ''}`}
            key={`${state.type}${isStackable ? `-${index}` : ''}`}
          >
            <RemoveOption data-test-id="remove-option" onClick={() => handleOptionRemove(index)} />
            {isStackable && getCanAddMore(index, state.type) && (
              <AddOption onClick={() => handleOptionAdd(state.type)} data-test-id="add-option" />
            )}
            <SimpleSelect
              label={t(`types.${state.type}`)}
              placeholder={''}
              isSearchable
              isClearable
              menuMaxHeight="250px"
              menuMinWidth="100%"
              // maxWidth="250px"
              value={state.value}
              options={state.options}
              isOptionDisabled={
                isStackable
                  ? value => {
                      return !!selectsState.find(state => state.value === value.id)
                    }
                  : undefined
              }
              onChange={(value: string, _, original) => {
                handleStateUpdate(index, { value, isStackable: original?.is_stackable ?? false })
              }}
            />
            {isStackable && (
              <PercentageOption>
                <Input
                  onChange={e => handlePercentageChange(index, e.target.value)}
                  value={state.params?.percentage}
                  label={t('storefront:skuForm.labels.percentage')}
                  type={'number'}
                  disabled={!state.value}
                  min={0}
                  max={100}
                />
              </PercentageOption>
            )}
          </SkuOptionSelect>
        )
      })}
    </Styled.Grid1Col>
  )
}

StorefrontOptionsForm.displayName = 'StorefrontOptionsForm'

export default StorefrontOptionsForm
