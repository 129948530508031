import { Button } from '@agro-club/frontend-shared'
import { DistributorConfirmationStatus } from 'modules/domain/farmerOrder/types'
import { Progress } from 'modules/types'
import { FarmerOrder, FarmerOrderStatus } from 'types/farmerOrder'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CAPABILITY, PERMISSION, useOrderUpdatePermissions, usePermissions } from 'modules/permissions/permissions'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ROLES } from 'types/entities'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import StickyFooterDeleteBtn from '../StickyFormControls/StickyFooterDeleteBtn'
import StickyFooterRestoreBtn from '../StickyFormControls/StickyFooterRestoreBtn'
import StickyFooterBtn from '../StickyFooterBtn/StickyFooterBtn'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'

type ControlButtonsProps = {
  onSave: () => void
  onCancel: () => void
  onDuplicate: () => void
  onRemove?: () => void
  onRestore?: () => void
  onDistributorConfirmation?: (status: DistributorConfirmationStatus) => void

  progress?: Progress
  removeProgress?: Progress

  orderId?: string
  orderStatus?: FarmerOrderStatus
  orderSlug?: string
  isFormDirty: boolean
  isFormValid: boolean
  revisionMismatch?: boolean
  interaction: FarmerOrder['interaction']
  isUpdatePermitted: boolean
  isRestorePermitted: boolean
  editingDateHasExpired: boolean
  isEditable?: boolean
  saveDisabled?: boolean
  duplicateButtonDisabled?: boolean
  distributorConfirmButtonDisabled?: boolean
}

const ControlButtons: React.FC<ControlButtonsProps> = ({
  onDuplicate,
  onCancel,
  onSave,
  onRemove,
  onRestore,
  onDistributorConfirmation,
  progress,
  removeProgress,
  orderId,
  orderStatus,
  orderSlug,
  isFormDirty,
  revisionMismatch,
  interaction,
  isUpdatePermitted,
  isRestorePermitted,
  editingDateHasExpired,
  isEditable = true,
  saveDisabled = false,
  duplicateButtonDisabled = false,
  distributorConfirmButtonDisabled = false,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common'])
  const [isCreatePermitted, isRemovePermitted] = usePermissions([
    { capability: CAPABILITY.ORDERS, permission: PERMISSION.C },
    { capability: CAPABILITY.ORDERS, permission: PERMISSION.D },
  ])
  const updatePermissions = useOrderUpdatePermissions()
  const role = useSelector(AuthSelectors.role)
  const { confirmed_by_distributor, canceled_by_role } = interaction
  const isDistributor = !!role && [ROLES.DISTRIBUTOR_MANAGER, ROLES.DISTRIBUTOR_HEAD].includes(role)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const saveButtonDisabled = !isFormDirty || revisionMismatch || !isUpdatePermitted || isFilesUploading || saveDisabled
  const distributorConfirmDisabled =
    confirmed_by_distributor === 'confirmed' || !!canceled_by_role || distributorConfirmButtonDisabled
  const distributorRejectDisabled = confirmed_by_distributor !== 'unset' || !!canceled_by_role
  const editingDisabled = isDistributor && confirmed_by_distributor !== 'confirmed'
  const showDuplicateButton = !editingDisabled && isCreatePermitted && !editingDateHasExpired

  const saveButton = !editingDisabled && (
    <Button
      intent={'primary'}
      filled
      disabled={saveButtonDisabled || !isEditable}
      onClick={onSave}
      progress={progress}
      data-test-id={'save-button'}
    >
      {t('common:save')}
    </Button>
  )
  const cancelButton = !editingDisabled && (
    <StickyFooterBtn
      buttonText={t('common:dontSaveChanges')}
      intent={'cancel'}
      heading={t('common:cancelEditingHeader')}
      text={t('common:cancelEditingText')}
      onSubmit={onCancel}
      disabled={!isEditable}
      testId={'cancel-button'}
    />
  )
  const distributorConfirmButton = !distributorConfirmDisabled &&
    isDistributor &&
    updatePermissions.orderApprove &&
    !!onDistributorConfirmation && (
      <Button
        intent={'action'}
        filled
        onClick={() => onDistributorConfirmation('confirmed')}
        progress={progress}
        disabled={!isEditable || isFilesUploading}
        data-test-id="distributor-confirmation-button"
      >
        {t('confirmOrder')}
      </Button>
    )
  const distributorRejectButton = !distributorRejectDisabled &&
    isDistributor &&
    updatePermissions.orderApprove &&
    !!onDistributorConfirmation && (
      <Button
        intent={'danger'}
        filled
        onClick={() => onDistributorConfirmation('rejected')}
        progress={progress}
        disabled={!isEditable || isFilesUploading}
        data-test-id="distributor-rejection-button"
      >
        {t('cancelOrder')}
      </Button>
    )
  const duplicateButton = showDuplicateButton && (
    <Button
      intent={'primary'}
      onClick={onDuplicate}
      disabled={revisionMismatch || !isEditable || isFilesUploading || duplicateButtonDisabled}
      progress={progress}
      data-test-id={'save-and-duplicate-button'}
    >
      {isFormDirty ? t('form.saveAndDuplicate') : t('form.createDuplicate')}
    </Button>
  )

  const removeButton = !!onRemove && isRemovePermitted && !!orderId && orderStatus !== FarmerOrderStatus.Deleted && (
    <StickyFooterDeleteBtn
      onRemove={onRemove}
      removeProgress={removeProgress}
      popoverText={t('form.removeText', { id: orderSlug })}
      disabled={!isEditable}
    />
  )

  const restoreButton = !!onRestore && isRestorePermitted && !!orderId && orderStatus === FarmerOrderStatus.Deleted && (
    <StickyFooterRestoreBtn onRestore={onRestore} progress={progress} />
  )

  return (
    <>
      {saveButton}
      {duplicateButton}
      {cancelButton}
      {saveButton || duplicateButton || cancelButton ? (
        <StickyFooterLayout.ActionButtonsWrapper>
          {distributorConfirmButton}
          {distributorRejectButton}
        </StickyFooterLayout.ActionButtonsWrapper>
      ) : (
        <>
          {distributorConfirmButton}
          {distributorRejectButton}
        </>
      )}
      {removeButton}
      {restoreButton}
    </>
  )
}

export default ControlButtons
