import React from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/category/managers'
import { CategorySelectOption, CategorySelectOptionsRequestFilter } from 'modules/domain/category/types'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { useFilterMemo } from 'hooks/useFilterMemo'

export type CategorySelectProps = EntitySelectProps<CategorySelectOption> & {
  filter?: CategorySelectOptionsRequestFilter
}

export const getCategoryValue = (category: CategorySelectOption) => category.id
export const getCategoryLabel = (category: CategorySelectOption) => category.title

export const SubCategorySelect: React.VFC<CategorySelectProps> = ({ pageSize = 50, placeholder, filter, ...props }) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useFilterMemo<CategorySelectOptionsRequestFilter>(filter)

  return (
    <SelectTestWrapper data-test-id={'subcategory-select'}>
      <EntitySelect
        placeholder={placeholder || t('placeholders.subcategory')}
        getEntityList={managers.getSubcategorySelectOptions}
        getEntityById={managers.getItem}
        filter={memoizedFilter}
        getOptionLabel={getCategoryLabel}
        getOptionValue={getCategoryValue}
        pageSize={pageSize}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}

export default SubCategorySelect
