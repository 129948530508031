import * as managers from 'modules/domain/reconciliation/managers'
import { ReconciliationRetailer, ReconciliationCompanyFilter } from 'modules/domain/reconciliation/types'
import React from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import styled from 'styled-components'
import { useFilterMemo } from 'hooks/useFilterMemo'

const Wrapper = styled(SelectTestWrapper)`
  min-width: 300px;
`

export const getRetailerLabel = (item: ReconciliationRetailer) => item.title
export const getRetailerValue = (item: ReconciliationRetailer) => item.id

export type RetailerSelectProps = Omit<
  EntitySelectProps<ReconciliationRetailer>,
  'getOptionLabel' | 'getOptionValue'
> & {
  filter?: ReconciliationCompanyFilter
}

export const RetailerSelectManually: React.FC<RetailerSelectProps> = ({ filter, ...props }) => {
  const memoizedFilter = useFilterMemo<ReconciliationCompanyFilter>(filter)

  return (
    <Wrapper data-test-id="retailer-select-manually">
      <EntitySelect
        filter={memoizedFilter}
        getEntityById={managers.getRetailerById}
        getEntityList={managers.getRetailers}
        searchParamKey="title"
        getOptionLabel={getRetailerLabel}
        getOptionValue={getRetailerValue}
        {...props}
        menuMaxHeight="220px"
      />
    </Wrapper>
  )
}
