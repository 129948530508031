import { Progress, useDebounce } from '@agro-club/frontend-shared'
import { arrToDict } from 'modules/utils/helpers'
import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useMemo, useState } from 'react'
import { ListResponse } from 'types/api'
import { Dict } from 'types/generics'

export type MapFn<EntityType, EntityTypeMapped> = (entity: EntityType) => EntityTypeMapped

export type UseEntitySelectStateProps<
  EntityType,
  ListRequestFilter extends { search?: string },
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
> = {
  getEntityList: (
    filter: ListRequestFilter,
    sorting: ListRequestSorting | {},
    page: number,
    pageSize: number,
  ) => Promise<ListResponse<EntityType>>
  getOptionValue: (entity: Map extends undefined ? EntityType : EntityTypeMapped) => string
  filter?: ListRequestFilter
  sort?: ListRequestSorting | {}
  pageSize?: number
  preventFetch?: boolean
  updateDeps?: unknown[]
  searchParamKey?: string
  getMappedOption?: Map
  onLoadList?: (
    list: ListResponse<EntityType>,
    totalDict: Dict<Map extends undefined ? EntityType : EntityTypeMapped>,
  ) => void
  cancelRequest?: typeof apiClient.cancelRequest
}

const emptyObj = {}

export const useEntitySelectState = <
  EntityType,
  ListRequestFilter extends { search?: string },
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
>({
  getEntityList,
  filter: filterFromProps,
  sort = emptyObj,
  pageSize = 25,
  getOptionValue,
  preventFetch = false,
  searchParamKey = 'search',
  updateDeps = [],
  getMappedOption,
  onLoadList,
  cancelRequest = apiClient.cancelRequest,
}: UseEntitySelectStateProps<EntityType, ListRequestFilter, ListRequestSorting, EntityTypeMapped, Map>) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const debouncedSearch: string = useDebounce(search, 200)
  const filter = useMemo(
    () =>
      ({
        [searchParamKey]: debouncedSearch || null,
        ...filterFromProps,
      } as ListRequestFilter),
    [searchParamKey, debouncedSearch, filterFromProps],
  )
  const [progress, setProgress] = useState(Progress.IDLE)
  const [newOptions, setNewOptions] = useState<(Map extends undefined ? EntityType : EntityTypeMapped)[]>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let mounted = true
    if (preventFetch) return
    setProgress(Progress.WORK)
    const promise = getEntityList(filter, sort, page, pageSize)
      .then(response => {
        if (!Array.isArray(response.data)) {
          throw new Error('Fetched data in select is not an array in useEntitySelectState')
        }
        const data = response.data
        if (mounted) {
          const newOptions = ((getMappedOption
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              data.map(getMappedOption!)
            : data) as unknown) as (Map extends undefined ? EntityType : EntityTypeMapped)[]
          setNewOptions(newOptions)
          const newDict = { ...dict, ...arrToDict(newOptions, getOptionValue) }
          setDict(newDict)
          setTotal(response.total_count)
          onLoadList?.(response, newDict)
          setProgress(Progress.SUCCESS)
        }
      })
      .catch(() => {
        mounted && setProgress(Progress.ERROR)
      })

    return () => {
      mounted = false
      cancelRequest(promise)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter, sort, preventFetch, ...updateDeps])

  const [dict, setDict] = useState<Dict<Map extends undefined ? EntityType : EntityTypeMapped>>({})

  const onUpdateRequested = (search: string, page: number) => {
    setPage(page)
    setSearch(search)
  }

  return {
    progress,
    newOptions,
    filter,
    total,
    onUpdateRequested,
    dict,
    setDict,
  }
}
