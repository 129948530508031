import { LocalizedValue } from '@agro-club/frontend-shared'
import { useMemo, useState } from 'react'
import { AvailableLanguages } from 'types/entities'

export const useLangState = (i18nFields?: LocalizedValue | LocalizedValue[]) => {
  const [languages, setLanguages] = useState(() => {
    if (!i18nFields) return ['en']
    const result = new Set(['en'])
    const checkLangs = (field: LocalizedValue) => {
      Object.keys(field).forEach(key => {
        if (!result.has(key) && field[key]) result.add(key)
      })
    }
    if (Array.isArray(i18nFields)) {
      i18nFields.forEach(field => {
        checkLangs(field)
      })
    } else {
      checkLangs(i18nFields)
    }

    return [...result]
  })

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  return {
    languages,
    setLanguages,
    isLangActive,
    handleLangChange,
  }
}
