import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Product } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.product.items
const ids = (state: AppGlobalState) => state.product.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (id?: string) => (state: AppGlobalState): Product | undefined => (id ? state.product.items[id] : undefined)
const meta = (id?: string) => (state: AppGlobalState): EntityMetadata<Product> =>
  (id && state.product.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.product.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.product.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.product.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.product.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.product.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Product[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.product.filter
const sorting = (state: AppGlobalState) => state.product.sorting
const page = (state: AppGlobalState) => state.product.page
const total = (state: AppGlobalState) => state.product.total
const updateProgress = (state: AppGlobalState) => state.product.updateProgress
const removeProgress = (state: AppGlobalState) => state.product.removeProgress
const addProgress = (state: AppGlobalState) => state.product.addProgress
const hasNext = (state: AppGlobalState) => state.product.total > state.product.ids.length
const pageSize = (state: AppGlobalState) => state.product.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.product.total / state.product.pageSize)
const itemSelected = (state: AppGlobalState, id: string) => state.product.selectedIds[id] || false
const selectedIds = (state: AppGlobalState) => state.product.selectedIds

const ProductSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  itemSelected,
  selectedIds,
}

export default ProductSelectors
