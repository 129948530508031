import { createActionCreators, createReducerFunction } from 'immer-reducer'
import {
  ProductsAvailability,
  ProductsAvailabilityDTO,
  ProductsAvailabilityListRequestFilter,
  ProductsAvailabilityListRequestSorting,
  ProductsAvailabilityState,
} from './types'
import { Progress } from 'modules/types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'

const initialState: ProductsAvailabilityState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchErrorDetail: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  listFetchNextErrorDetail: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  itemFetchErrorDetail: null,
  addProgress: Progress.IDLE,
  addError: null,
  addErrorDetail: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  updateErrorDetail: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  removeErrorDetail: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class ProductsAvailabilityReducer extends BasicEntityImmerReducer<
  ProductsAvailability,
  ProductsAvailabilityListRequestFilter,
  ProductsAvailabilityListRequestSorting,
  ProductsAvailabilityDTO,
  ProductsAvailabilityDTO,
  ProductsAvailabilityState
> {}

export const ProductsAvailabilityActions = createActionCreators(ProductsAvailabilityReducer)
export default ProductsAvailabilityActions
export const reducer = createReducerFunction(ProductsAvailabilityReducer, initialState)
