import React from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/company/managers'
import { Company, CompanyListRequestFilter } from 'modules/domain/company/types'
import { SingleValue } from 'react-select'
import { CompanyType } from 'types/entities'
import { Dict } from 'types/generics'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { useFilterMemo } from 'hooks/useFilterMemo'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type CompanySelectProps = Omit<EntitySelectProps<Company>, 'onChange'> & {
  companyType?: CompanyType
  filter?: CompanyListRequestFilter
  onChange?: (newValue: string | undefined, company: SingleValue<Company>, headCompany: SingleValue<Company>) => void
  showLabel?: boolean
  autoSelectOneValue?: boolean
}

export const getCompanyLabel = (company: Company) => company.internal_name
export const getCompanyValue = (company: Company) => company.id

export const CompanySelect: React.VFC<CompanySelectProps> = ({
  companyType,
  onChange,
  placeholder,
  filter,
  showLabel,
  autoSelectOneValue,
  ...props
}) => {
  const { t } = useTranslation('CompanySelect')
  const memoizedFilter = useFilterMemo<CompanyListRequestFilter>({
    company_type: companyType,
    ...filter,
  })

  const handleChange = (newValue: string | undefined, company: SingleValue<Company>, dict?: Dict<Company>) => {
    onChange?.(
      newValue,
      company,
      company?.head_company_relation?.company_id && dict?.[company.head_company_relation.company_id]
        ? dict[company.head_company_relation.company_id]
        : null,
    )
  }

  const companyTypeNormalized = companyType || 'company'

  return (
    <SelectTestWrapper data-test-id={`${companyTypeNormalized}-select`}>
      <EntitySelect
        getEntityList={managers.getCompanyList}
        getEntityById={managers.getCompany}
        filter={memoizedFilter}
        getOptionLabel={getCompanyLabel}
        getOptionValue={getCompanyValue}
        onChange={handleChange}
        onLoadList={list => {
          if (autoSelectOneValue && list.data.length === 1) handleChange(list.data[0].id, list.data[0])
        }}
        placeholder={placeholder || t(`${companyTypeNormalized}Placeholder`)}
        label={showLabel ? t(`${companyTypeNormalized}Label`) : ''}
        {...props}
      />
    </SelectTestWrapper>
  )
}
