import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('retailerTarget')

export const RetailerTargetSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.retailerTarget.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.retailerTarget.totalTargetValues,
  retailerOrdersTotal: (state: AppGlobalState) => state.retailerTarget.totalRetailerOrders,
  farmerOrdersTotal: (state: AppGlobalState) => state.retailerTarget.totalFarmerOrders,
  previousYear: (state: AppGlobalState) => state.retailerTarget.totalFarmerOrdersPreviousYear,
}

export default RetailerTargetSelectors
