import React from 'react'

import { Switch, Route } from 'react-router-dom'
import PromocodeList from 'views/pages/Promocode/PromocodeList'
import PromocodeEdit from 'views/pages/Promocode/PromocodeEdit'
import PromocodeAdd from 'views/pages/Promocode/PromocodeAdd'
import PromocodeRoutes from './routes'

const PromocodePage: React.FC = () => {
  return (
    <Switch>
      <Route path={PromocodeRoutes.List} exact>
        <PromocodeList />
      </Route>
      <Route path={PromocodeRoutes.Edit} exact>
        <PromocodeEdit />
      </Route>
      <Route path={PromocodeRoutes.Add} exact>
        <PromocodeAdd />
      </Route>
    </Switch>
  )
}

export default PromocodePage
