export const getProductNameWithCategory = (title: string, category?: string, subcategory?: string) => {
  let resultName = title

  if (subcategory) {
    resultName = `${subcategory} / ` + resultName
  }

  if (category) {
    resultName = `${category} / ` + resultName
  }

  return resultName
}
