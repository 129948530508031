import React from 'react'
import CategoryActions from 'modules/domain/category/duck'
import { useSelector } from 'react-redux'
import CategorySelectors from 'modules/domain/category/selectors'
import CategoryDetailsForm, {
  FormProps,
} from 'views/pages/Categories/CategoryPage/CategoryDetailsForm/CategoryDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import CategoryRoutes from './routes'

const CategoryAdd: React.FC = () => {
  const { t } = useTranslation('category')
  const submitAction = useAction(CategoryActions.addRequested)
  const addProgress = useSelector(CategorySelectors.addProgress)

  const handleFormSubmit = (props: FormProps) => {
    submitAction({
      country: props.country,
      parent_id: props.parent_id || null,
      seller_ids: props.seller_ids,
      slug: props.slug,
      title_i18n: props.title,
      description_i18n: props.description,
      discount_text_i18n: props.discountText,
      files: props.files,
    })
  }

  const page = useSelector(CategorySelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: CategoryRoutes.CategoryList })
  }

  useHelmet({ title: t('addCategoryMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CategoryDetailsForm progress={addProgress} onCancel={goBack} onSubmit={handleFormSubmit} />
      </Layout.Content>
    </>
  )
}

export default CategoryAdd
