import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import styled from 'styled-components'
import {
  Button,
  FormField,
  Modal,
  NumberInput as NumberInputComponent,
  TextArea,
  useAction,
} from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import { InventoryItem } from 'modules/domain/inventoryExchange/types'
import InventoryExchangeActions from 'modules/domain/inventoryExchange/duck'
import { useSelector } from 'react-redux'
import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import AuthSelectors from 'modules/domain/auth/selectors'

const ModalContent = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`

const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

const Title = styled.p`
  margin: 3px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};
`

const NumberInput = styled(NumberInputComponent)`
  & > input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`

type TrasferModalFormProps = {
  inventoryItemId: string
  quantity: number
  comment?: string
}

type TransferModalProps = {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  heading?: string
  rowData: InventoryItem
}

const TransferModal: React.FC<TransferModalProps> = ({ heading, rowData, isOpen, setIsOpen }) => {
  const { t } = useTranslation('inventoryExchangeModal')
  const { pick } = useLangPicker()
  const profile = useSelector(AuthSelectors.profile)
  const transferProgress = useSelector(InventoryExchangeSelectors.transferProgress)
  const [prevState, setPrevState] = useState(transferProgress)
  const handleModalFormSubmit = useAction(InventoryExchangeActions.transferRequested)

  useEffect(() => {
    if (transferProgress === Progress.SUCCESS && prevState !== transferProgress) {
      setIsOpen(false)
    }
    setPrevState(transferProgress)
  }, [transferProgress, setIsOpen, prevState])

  const formik = useFormik<TrasferModalFormProps>({
    enableReinitialize: true,
    initialValues: {
      inventoryItemId: rowData.itemId,
      quantity: 0,
      comment: '',
    },
    onSubmit: () => {
      handleModalFormSubmit({
        inventory_id: formik.values.inventoryItemId,
        retailer_id: profile?.company_id ?? '',
        comment: formik.values.comment ?? '',
        quantity: formik.values.quantity,
      })
    },
  })
  const price = rowData.price ?? rowData.sku.price
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} heading={heading} shouldCloseOnEsc={true}>
      <ModalContent>
        <div>
          <Title>{`${t('labels.producer')}: ${rowData.sku?.product?.producer?.official_name}`}</Title>
          <Title>{`${t('labels.distributor')}: ${rowData.retailer.internal_name}`}</Title>
          <Title>{`${t('labels.product')}: ${pick(rowData.sku?.product?.title_i18n) ||
            rowData.sku?.product?.title}`}</Title>
          <Title>{`${t('labels.packageType')}: ${rowData?.sku?.params?.package_title}`}</Title>
          <Title>{`${t('labels.sku')}: ${rowData.sku.sku_id}`}</Title>
          <Title>{`${t('labels.quantity')}: ${rowData.total_qty}`}</Title>
          <Title>{`${t('labels.price')}: ${price ?? t('labels.noPriceLabel')}`}</Title>
        </div>
        <div>
          <FormField
            label={t('labels.unitsRequested')}
            render={() => (
              <NumberInput
                {...formik.getFieldProps('quantity')}
                onChange={value => formik.setFieldValue('quantity', value)}
                size="medium"
                min={0}
              />
            )}
          />
        </div>
        <TextArea label={t('labels.comment')} {...formik.getFieldProps('comment')} />
      </ModalContent>

      <ModalFooter>
        <Button
          disabled={!formik.dirty}
          intent={'primary'}
          filled
          type={'submit'}
          progress={transferProgress}
          onClick={() => formik.submitForm()}
        >
          {t('labels.sendRequest')}
        </Button>
        <Button onClick={() => setIsOpen(false)} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TransferModal
