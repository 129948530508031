import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Modal, TextArea, useAction, PopoverWithBtns } from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import { useSelector } from 'react-redux'
import { InventoryTransferRequest, TransferRequestStatuses } from 'modules/domain/inventoryInTransferRequest/types'
import InventoryInTransferRequestActions from 'modules/domain/inventoryInTransferRequest/duck'
import InventoryInTransferRequestSelectors from 'modules/domain/inventoryInTransferRequest/selectors'
import RequestModalInfo, { ModalContent } from './RequestModalInfo'
import { getList } from 'modules/domain/inventoryExchange/managers'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, Sections } from 'types/entities'

const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

type RequestModalProps = {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  rowData: InventoryTransferRequest
}

export type ChangeableRequestData = {
  quantity: number | string
  rejectComment?: string
  status?: TransferRequestStatuses
}

const InRequestModal: React.VFC<RequestModalProps> = ({ rowData, isOpen, setIsOpen }) => {
  const { t } = useTranslation('inventoryTransferRequestModal')
  const [quantityAvailable, setQuantityAvailable] = useState('Loading')
  const [requestData, setRequestData] = useState<ChangeableRequestData>({
    quantity: rowData.quantity,
    status: rowData.status,
  })

  useEffect(() => {
    getList(
      {
        retailer_id: [rowData.company_from.id],
        sku_id: rowData.sku.id,
      },
      {},
      1,
      1,
    ).then(inventory => {
      setQuantityAvailable(inventory.data?.[0].total_qty.toString())
    })
  }, [rowData.company_from.id, rowData.sku.id])
  const data = {
    ...rowData,
    quantityAvailable,
  }
  const updateProgress = useSelector(InventoryInTransferRequestSelectors.updateProgress)
  const [prevState, setPrevState] = useState(updateProgress)
  const updateRequest = useAction(InventoryInTransferRequestActions.updateRequested)

  useEffect(() => {
    if (updateProgress === Progress.SUCCESS && prevState !== updateProgress) {
      setIsOpen(false)
    }
    setPrevState(updateProgress)
  }, [updateProgress, setIsOpen, prevState])

  const approve = () => {
    setRequestData({ ...requestData, status: TransferRequestStatuses.approved })
    updateRequest(rowData.id, { status: TransferRequestStatuses.approved })
  }
  const reject = (withComment = false) => {
    setRequestData({ ...requestData, status: TransferRequestStatuses.rejected })
    updateRequest(rowData.id, {
      status: TransferRequestStatuses.rejected,
      ...(requestData.quantity !== +rowData.quantity && { quantity: requestData.quantity }),
      ...(withComment && requestData.rejectComment && { reject_comment: requestData.rejectComment }),
    })
  }
  const onRejectCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestData({ ...requestData, rejectComment: e.target.value })
  }

  const checkFeatureFlag = useFeatureFlags()

  const isDemoFeaturesEnabled = checkFeatureFlag(
    generateCrmSectionAccessString(Sections.InventoryExchange, FeatureFlagModifiers.DemoMode),
  )

  const isEditable = isDemoFeaturesEnabled && rowData.status === TransferRequestStatuses.active

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      heading={t('modalInHeading')}
      shouldCloseOnEsc={true}
    >
      <ModalContent>
        <RequestModalInfo
          rowData={data}
          changeableRequestData={requestData}
          onChangeRequestData={setRequestData}
          isEditable={isEditable}
        />
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <TextArea label={t('labels.comment')} value={rowData.comment} disabled onChange={() => {}} />
      </ModalContent>

      <ModalFooter>
        <Button
          disabled={!isEditable}
          intent={'primary'}
          progress={requestData.status === TransferRequestStatuses.approved ? updateProgress : Progress.IDLE}
          onClick={approve}
        >
          {t('labels.approve')}
        </Button>
        <PopoverWithBtns
          onSubmit={() => reject(true)}
          onCancel={reject}
          heading={t('labels.leaveComment')}
          cancelIntent="danger"
          submitIntent="primary"
          bodyContent={<TextArea onChange={onRejectCommentChange} value={requestData.rejectComment} />}
          submitText={t('common:yes')}
          cancelText={t('common:no')}
        >
          <Button
            disabled={!isEditable}
            intent={'danger'}
            progress={requestData.status === TransferRequestStatuses.rejected ? updateProgress : Progress.IDLE}
          >
            {t('labels.reject')}
          </Button>
        </PopoverWithBtns>
        <Button onClick={() => setIsOpen(false)} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default InRequestModal
