import { push } from 'connected-react-router'
import { RequestError } from 'modules/errors'
import { getFilesUrls, mapFilesToDto, updateLocationQuery } from 'modules/sagaHelpers'
import { generatePath } from 'react-router-dom'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ListResponse } from 'types/api'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import StorefrontCardActions from './duck'
import managers from './managers'
import StorefrontCardSelectors from './selectors'
import { StorefrontCard, StorefrontCardActionData } from './types'

export const fetchList = function*(props) {
  try {
    let currentPage = yield select(StorefrontCardSelectors.page)
    const filter = yield select(StorefrontCardSelectors.filter)
    const sorting = yield select(StorefrontCardSelectors.sorting)
    const pageSize =
      props.payload.pageSize !== undefined ? props.payload.pageSize : yield select(StorefrontCardSelectors.pageSize)
    const endpoint = managers.getList
    let response: ListResponse<StorefrontCard> = yield call(endpoint, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(endpoint, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, total_count, page } = response
    yield put(StorefrontCardActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, StorefrontRoutes.CardsList, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(StorefrontCardSelectors.page)
    const filter = yield select(StorefrontCardSelectors.filter)
    const sorting = yield select(StorefrontCardSelectors.sorting)
    const pageSize = yield select(StorefrontCardSelectors.pageSize)

    const { data, total_count }: { data: StorefrontCard[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(StorefrontCardActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof StorefrontCardActions.itemRequested>) {
  try {
    const item: StorefrontCard = yield call(managers.getItem, id)
    yield put(StorefrontCardActions.itemRequestSucceed(item))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.itemRequestFailed(id, errType))
  }
}

export const prepareDTO = function*(dto: Partial<StorefrontCardActionData>) {
  const { images: imagesMeta = [], files = [], ...rest } = dto
  const imagesMap = yield mapFilesToDto(imagesMeta)
  const images = imagesMap.map(image => image.url)
  const productInfoFileUrls = yield all(files.map(file => call(getFilesUrls, file.url_i18n)))
  const filesWithUrls = files.map((file, idx) => ({
    title_i18n: file.title_i18n,
    url_i18n: productInfoFileUrls[idx],
  }))

  return {
    ...rest,
    images,
    files: filesWithUrls,
  }
}

export const addItem = function*({ payload }: ReturnType<typeof StorefrontCardActions.addRequested>) {
  try {
    const card: StorefrontCard = yield call(managers.addItem, yield prepareDTO(payload))

    yield put(StorefrontCardActions.addSucceed(card))
    const path = `${generatePath(StorefrontRoutes.CardsEdit, { id: card.id })}`
    yield put(push(path))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof StorefrontCardActions.updateRequested>) {
  try {
    const card: StorefrontCard = yield call(managers.updateItem, id, yield prepareDTO(dto))

    yield put(StorefrontCardActions.updateSucceed(card))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof StorefrontCardActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(StorefrontCardActions.removeSucceed(payload))
    yield put(push(StorefrontRoutes.CardsList))
    yield put(StorefrontCardActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(StorefrontCardActions.removeFailed(payload, errType))
  }
}

const StorefrontCardSaga = function*() {
  yield all([
    takeLatest(StorefrontCardActions.itemRequested.type, fetchItem),
    takeLatest(StorefrontCardActions.listRequested.type, fetchList),
    takeLatest(StorefrontCardActions.filterUpdated.type, fetchList),
    takeLatest(StorefrontCardActions.sortingUpdated.type, fetchList),
    takeLatest(StorefrontCardActions.filterHasBeenReset.type, fetchList),
    takeLatest(StorefrontCardActions.sortingHasBeenReset.type, fetchList),
    takeLatest(StorefrontCardActions.listRequestedNext.type, fetchListNext),
    takeLatest(StorefrontCardActions.addRequested.type, addItem),
    takeLatest(StorefrontCardActions.updateRequested.type, updateItem),
    takeLatest(StorefrontCardActions.removeRequested.type, removeItem),
  ])
}

export default StorefrontCardSaga
