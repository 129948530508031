import React, { useCallback } from 'react'
import * as Styled from 'views/pages/Product/ProductList/styles'
import StatusFilter from 'views/components/TableFilters/StatusFilter'
import { useTranslation } from 'react-i18next'
import { CellProps, useRowSelect, useTable } from 'react-table'
import StorefrontCardActions from 'modules/domain/storefrontCard/duck'
import StorefrontCardSelectors from 'modules/domain/storefrontCard/selectors'
import { useStorefrontCardList } from 'modules/domain/storefrontCard/hooks'
import { useSelector } from 'react-redux'
import { StorefrontCard, StorefrontCardListRequestFilter } from 'modules/domain/storefrontCard/types'
import { generatePath } from 'react-router-dom'
import Routes from '../../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'

import {
  AdvancedHeadCell,
  Table,
  TableHeadRow,
  TableHead,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  useHistoryPush,
} from '@agro-club/frontend-shared'
import { Status } from 'types/entities'

const IdColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('cards')
  return (
    <div>
      <Styled.DefaultStatus />
      <span>{t('list.tableHeaders.id')}</span>
    </div>
  )
}

const IdCell: React.FC<CellProps<StorefrontCard>> = ({ cell, column, row }) => {
  return (
    <Styled.IdCell key={column.id}>
      <Styled.Status status={row.values.status} />
      {`${cell.value || ''}`}
    </Styled.IdCell>
  )
}

const TitleCell: React.FC<CellProps<StorefrontCard>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  return <TComponents.SemiBoldText key={column.id}>{pick(cell.value)}</TComponents.SemiBoldText>
}

const SellerCell: React.FC<CellProps<StorefrontCard>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value || ''}</TComponents.SemiBoldText>
}

const CategoryCell: React.FC<CellProps<StorefrontCard>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  return <TComponents.SemiBoldText key={column.id}>{pick(cell.value?.title_i18n)}</TComponents.SemiBoldText>
}

const SortableHeadCell = AdvancedHeadCell<keyof StorefrontCard>()

const StorefrontCardTable = () => {
  const push = useHistoryPush()
  const { t } = useTranslation(['cards', 'common', 'labels'])
  const visibleColumns = React.useMemo(
    () => [
      {
        Header: IdColumnHeaderCell,
        accessor: 'id' as const,
        Cell: IdCell,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title_i18n' as const,
        Cell: TitleCell,
      },
      {
        Header: t('list.tableHeaders.seller'),
        accessor: 'seller_internal_name' as const,
        Cell: SellerCell,
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'category' as const,
        Cell: CategoryCell,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'subcategory' as const,
        Cell: CategoryCell,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof StorefrontCard)[] = React.useMemo(() => ['status'], [])

  const columnsAll = React.useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])
  const [progress, data = []] = useStorefrontCardList({ status: Status.Active })
  const { columns, rows, prepareRow } = useTable<StorefrontCard>(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      columns: columnsAll,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(StorefrontCardActions.filterUpdated)
  const filterCleared = useAction(StorefrontCardActions.filterHasBeenReset)
  const sortingUpdated = useAction(StorefrontCardActions.sortingUpdated)
  const listRequested = useAction(StorefrontCardActions.listRequested)
  const filterValue = useSelector(StorefrontCardSelectors.filter)
  const page = useSelector(StorefrontCardSelectors.page)
  const total = useSelector(StorefrontCardSelectors.total)
  const pageSize = useSelector(StorefrontCardSelectors.pageSize)
  const pages = useSelector(StorefrontCardSelectors.pages)
  const { status } = filterValue

  const { sort_field, sort_reversed } = useSelector(StorefrontCardSelectors.sorting)

  const handleFilterChange = (newFilterValue: Partial<StorefrontCardListRequestFilter>) => {
    filterUpdated({ ...filterValue, ...newFilterValue })
  }

  const handleClearFilters = () => {
    filterCleared()
  }

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('storefrontCardList')

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <StatusFilter filterValue={{ status }} handleFilterChange={handleFilterChange} data-test-id="status-filter" />
        <Styled.Spacer />
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof StorefrontCard}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                selected={row.isSelected}
                onClick={() => {
                  push({
                    route: generatePath(Routes.CardsEdit, { id: row.original.id.toString() }),
                  })
                }}
                data-test-id="storefront-cards-table-row"
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'selection'}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default StorefrontCardTable
