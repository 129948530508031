import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  InventoryItem,
  InventoryExchangeListRequestSorting,
  InventoryExchangeListRequestFilter,
  InventoryTransferRequestShort,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: InventoryExchangeListRequestFilter,
  sorting: InventoryExchangeListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<InventoryItem>>(endpoints.inventoryExchange(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (retailerId: string, skuId: string) => {
  return apiClient.get<InventoryItem>(endpoints.inventoryTotalItem(retailerId, skuId))
}

export const updateItem = (retailerId: string, skuId: string, body: { delivery_enabled?: boolean; price?: string }) => {
  return apiClient.put<InventoryItem>(endpoints.inventoryTotalItem(retailerId, skuId), body)
}

export const requestTrasferItem = (dto: InventoryTransferRequestShort) => {
  return apiClient.post<InventoryTransferRequestShort>(endpoints.inventoryExchangeTransferRequest(), dto)
}

export const removeItemTotal = (id: string) => {
  return apiClient.delete<InventoryItem>(endpoints.inventoryExchange(id))
}
