import { helpersColor, IconCrossCircle } from '@agro-club/frontend-shared'
import React, { useState } from 'react'
import styled from 'styled-components'

const UnstyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: inherit;
`

const Remove = styled(UnstyledButton)`
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;

  &:focus {
    opacity: 1;
  }
`

const Wrapper = styled(UnstyledButton)<{ active: boolean }>`
  width: max-content;
  position: relative;
  padding: 8px;
  margin: -8px;
  background-color: ${({ theme, active }) =>
    active ? helpersColor.alphaColor(theme.color.accentDestructive, 0.1) : 'transparent'};
  border-radius: 4px;
  transition: background-color 0.1s ease-in;

  &:hover ${Remove} {
    opacity: 1;
  }
`

export type DestructibleBlockProps = React.HTMLAttributes<HTMLButtonElement> & {
  onRemove?: () => void
  children?: React.ReactNode
}

export const DestructibleBlock: React.FC<DestructibleBlockProps> = ({ onRemove, children, ...props }) => {
  const [active, setActive] = useState(false)

  const handleActive = () => setActive(true)
  const handleNotActive = () => setActive(false)

  return (
    <Wrapper active={active} tabIndex={-1} {...props}>
      <Remove
        onFocus={handleActive}
        onBlur={handleNotActive}
        onMouseEnter={handleActive}
        onMouseLeave={handleNotActive}
        onClick={onRemove}
        data-test-id={'remove-button'}
      >
        <IconCrossCircle />
      </Remove>
      {children}
    </Wrapper>
  )
}
