import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuFarmer = {
  id: string
  first_name: string
  last_name: string
  farm_name?: string
}

type TargetSkuFarmerFilter = {
  manufacturer_id?: string
  retailer_id?: string
  name?: string
}

type TargetSkuProductSelectProps = EntitySelectProps<TargetSkuFarmer> & {
  manufacturerId?: string
  retailerId?: string
  fakeFarmerFirstName?: string
  fakeFarmerLastName?: string
  scope?: TargetSkuTypes | 'default'
}

const getListForm = createGetList<TargetSkuFarmer, TargetSkuFarmerFilter>(endpoints.target2sku('farmers'))
const getListFarmerFilter = createGetList<TargetSkuFarmer, TargetSkuFarmerFilter>(
  endpoints.target2sku('farmer/farmers'),
)
const getListCropFilter = createGetList<TargetSkuFarmer, TargetSkuFarmerFilter>(
  endpoints.target2sku('territory-crop/farmers'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Crop]: getListCropFilter,
}

const getLabel = (item: TargetSkuFarmer) => `${item.first_name} ${item.last_name}`

const getValue = (item: TargetSkuFarmer) => item.id

export const TargetSkuFarmerSelect: React.VFC<TargetSkuProductSelectProps> = ({
  manufacturerId,
  retailerId,
  isDisabled,
  fakeFarmerFirstName,
  fakeFarmerLastName,
  onChange,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, retailer_id: retailerId }), [
    manufacturerId,
    retailerId,
  ])

  const fakeValue = useMemo(() => {
    return fakeFarmerFirstName && fakeFarmerLastName
      ? {
          id: 'fake',
          first_name: fakeFarmerFirstName,
          last_name: fakeFarmerLastName,
        }
      : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeFarmerFirstName, fakeFarmerLastName])

  const testId = 'target-farmer-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      onChange={onChange}
      searchParamKey="name"
      data-test-id={testId}
      {...props}
    />
  )
}
