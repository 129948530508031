import { SectionBody, SectionTitle } from '@agro-club/frontend-shared'
import {
  isPromocodeWithComment,
  isPromocodeWithLegalText,
  Promocode,
  PromocodeWithComment,
} from 'modules/domain/promocode/types'
import { clone } from 'ramda'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PromocodeField, { PromocodeFieldProps } from './PromocodeField'
import { PromocodeList, PromocodeListProps } from './PromocodeList'

export type OrderSkuPromocodesProps = Omit<PromocodeListProps, 'onRemove' | 'onCommentChange'> &
  Omit<PromocodeFieldProps, 'onApply'> & {
    onPromocodesChange: (promocodes: Promocode[]) => void
    onSelectPromocode: (code: string) => void
  }

const findByCode = code => item => item.code === code

export const OrderSkuPromocodes: React.VFC<OrderSkuPromocodesProps> = ({
  className,
  companyId,
  disabled,
  promocodes,
  onPromocodesChange,
  onSelectPromocode,
  ...listProps
}) => {
  const { t } = useTranslation('discount')

  const handlePromocodeApply = useCallback(
    (code: string, promocode: Promocode) => {
      const promocodesClone = clone(promocodes)
      promocodesClone.push({ ...promocode, code })
      onPromocodesChange(promocodesClone)

      if (isPromocodeWithComment(promocode) || isPromocodeWithLegalText(promocode)) {
        onSelectPromocode(code)
      }
    },
    [onPromocodesChange, onSelectPromocode, promocodes],
  )

  const handlePromocodeRemove = useCallback(
    code => {
      const promocodesClone = clone(promocodes)
      const index = promocodes.findIndex(findByCode(code))
      if (index !== -1) {
        promocodesClone.splice(index, 1)
      }
      onPromocodesChange(promocodesClone)
    },
    [onPromocodesChange, promocodes],
  )

  const handlePromocodeCommentChange = useCallback(
    (code, comment) => {
      const promocodesClone = clone(promocodes)
      const index = promocodesClone.findIndex(findByCode(code))
      if (index !== -1) {
        const editedPromocode = promocodesClone[index] as PromocodeWithComment
        editedPromocode.comment = comment
      }
      onPromocodesChange(promocodesClone)
    },
    [onPromocodesChange, promocodes],
  )

  return (
    <>
      <SectionTitle>{t('promocodes')}</SectionTitle>
      <SectionBody>
        <PromocodeField
          className={className}
          companyId={companyId}
          onApply={handlePromocodeApply}
          disabled={disabled}
          promocodes={promocodes}
        />
        <PromocodeList
          promocodes={promocodes}
          onRemove={handlePromocodeRemove}
          onCommentChange={handlePromocodeCommentChange}
          onSelectPromocode={onSelectPromocode}
          {...listProps}
        />
      </SectionBody>
    </>
  )
}
