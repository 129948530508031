import { useAction } from '@agro-club/frontend-shared'
import { useIncentiveCampaignList } from 'modules/domain/incentiveCampaign/hooks'
import IncentiveCampaignActions from 'modules/domain/incentiveCampaign/duck'
import IncentiveCampaignSelectors from 'modules/domain/incentiveCampaign/selectors'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'
import { IncentiveCampaign } from 'modules/domain/incentiveCampaign/types'
import { generatePath } from 'modules/utils/helpers/generatePath'
import IncentiveCampaignRoutes from '../../IncentiveCampaign/routes'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'

const IncentiveCampaignTable = () => {
  const history = useHistory()
  const { t } = useTranslation('incentiveCampaign')
  const [progress, data = []] = useIncentiveCampaignList()

  const total = useSelector(IncentiveCampaignSelectors.total)
  const page = useSelector(IncentiveCampaignSelectors.page)
  const pages = useSelector(IncentiveCampaignSelectors.pages)
  const pageSize = useSelector(IncentiveCampaignSelectors.pageSize)

  const listRequested = useAction(IncentiveCampaignActions.listRequested)

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title',
        width: 300,
      },
      {
        Header: t('list.tableHeaders.seller'),
        accessor: 'seller.internal_name',
        width: 'auto',
      },
    ],
    [t],
  )

  const handleRowClick = useCallback((row: Row<IncentiveCampaign>) => {
    history.push(generatePath(IncentiveCampaignRoutes.Edit, { id: row.original.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CompoundedTable
      columns={columns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => {
        listRequested({ page: num })
      }}
      pages={pages}
      onRowClick={handleRowClick}
    />
  )
}

export default IncentiveCampaignTable
