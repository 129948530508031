import { ProductLimit } from 'modules/domain/allocation/common/types'

export const getProductMinQty = (
  productCurrentQty: number,
  productIntitialQty: number,
  isAdmin: boolean,
  mode: 'create' | 'edit',
  productId?: string,
  productAllocationLimit?: ProductLimit,
  minQuantity?: number,
) => {
  if (!productId) return 0
  const minQty = minQuantity || 1
  if (!productAllocationLimit) return minQty
  if (!isAdmin && productAllocationLimit.is_limit_exceeded) return 0

  if (mode === 'create') {
    if (
      productAllocationLimit.allowed_quantity - productCurrentQty === 0 ||
      productAllocationLimit.allowed_quantity - productCurrentQty + minQty < 0
    )
      return 0

    if (productAllocationLimit.allowed_quantity - productCurrentQty + minQty >= 0) return minQty
  } else {
    if (
      productAllocationLimit.allowed_quantity - (productCurrentQty - productIntitialQty) === 0 ||
      productAllocationLimit.allowed_quantity - (productCurrentQty + minQty - productIntitialQty) < 0
    )
      return 0

    if (productAllocationLimit.allowed_quantity - (productCurrentQty + minQty - productIntitialQty) >= 0) return minQty
  }

  return minQty
}
