import { AppGlobalState } from '../../types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const ReturnDeclarationSkuSelectors = {
  ...createBasicSelectors('returnDeclarationSku'),
  printProgress: (state: AppGlobalState) => state.returnDeclarationSku.printProgress,
  declarationCountFetchProgress: (state: AppGlobalState) => state.returnDeclarationSku.declarationCountFetchProgress,
  declarationCountFetchError: (state: AppGlobalState) => state.returnDeclarationSku.declarationCountFetchError,
  declarationCount: (state: AppGlobalState) => state.returnDeclarationSku.declarationCount,
}

export default ReturnDeclarationSkuSelectors
