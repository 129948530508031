import {
  StorefrontCard,
  StorefrontCardDTO,
  StorefrontCardForSelect,
  StorefrontCardListRequestFilter,
  StorefrontCardListRequestSorting,
  StorefrontCardRequestFilterSuggest,
} from 'modules/domain/storefrontCard/types'
import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

export default {
  ...createBasicManagers<
    StorefrontCardListRequestFilter,
    StorefrontCardListRequestSorting,
    StorefrontCard,
    StorefrontCardDTO,
    StorefrontCardDTO
  >(endpoints.storefrontCards),
  getListOptions: (debouncedSearch: any, filter: StorefrontCardListRequestFilter, pageSize: number) => {
    return apiClient.get<ListResponse<StorefrontCard>>(endpoints.storefrontCards(), {
      ...debouncedSearch,
      ...filter,
      page_size: pageSize,
    })
  },
  getListForFilter: async (filter: StorefrontCardRequestFilterSuggest, sort: StorefrontCardListRequestSorting) => {
    return apiClient.get<ListResponse<StorefrontCardForSelect>>(endpoints.filterSuggestCard(), {
      ...filter,
      ...sort,
    })
  },
  getListConditional: (
    debouncedSearch: any,
    filter: StorefrontCardListRequestFilter,
    pageSize: number,
    condition: boolean,
  ) => {
    return condition
      ? apiClient.get<ListResponse<StorefrontCard>>(endpoints.storefrontCards(), {
          ...debouncedSearch,
          ...filter,
          page_size: pageSize,
        })
      : Promise.resolve({ data: [], total_count: 0, page: 1 })
  },
}
