import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import AuthSelectors from 'modules/domain/auth/selectors'

import DistributorOrderSkuActions from 'modules/domain/distributorOrderSku/duck'
import { useDistributorOrderSkuById } from 'modules/domain/distributorOrderSku/hooks'
import DistributorOrderSkuSelectors from 'modules/domain/distributorOrderSku/selectors'
import { Progress } from 'modules/types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { DistributorOrderStatus } from 'types/distributorOrder'
import { isAgro } from 'types/entities'
import * as uuid from 'uuid'
import FarmerOrderStatusColored from 'views/components/FarmerOrderStatusColored/FarmerOrderStatusColored'
import { FileData } from 'views/components/FileManager/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import DistributorOrderSkuDetailsForm, {
  DistributorOrderSkuDetailsFormFields,
} from './components/DistributorOrderSkuDetailsForm'
import Routes from './routes'

const Subtitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.secondary200};
`

const DistributorOrderEdit: React.VFC<{ onUpdateInitialState(props: DistributorOrderSkuDetailsFormFields): void }> = ({
  onUpdateInitialState,
}) => {
  const { t } = useTranslation('distributorOrder')

  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const page = useSelector(DistributorOrderSkuSelectors.page)
  const meta = useSelector(DistributorOrderSkuSelectors.meta(params.id))
  const role = useSelector(AuthSelectors.role)
  const isAdmin = isAgro(role)

  const goBack = useCallback(() => push({ route: Routes.List, query: { page } }), [page, push])
  const updateOrder = useAction(DistributorOrderSkuActions.updateRequested)
  const removeOrder = useAction(DistributorOrderSkuActions.removeRequested, params.id)

  const [progress, order] = useDistributorOrderSkuById(params.id)

  useHelmet({ title: t('editPageTitle', { id: order ? order.slug : '' }) })

  const files = useMemo(() => {
    if (!order || !order?.files?.length) return []
    return order.files.map((item: FileData) => ({
      file: item.url,
      kind: 'current' as const,
      id: uuid.v4(),
      error: null,
    }))
  }, [order])

  const justRedirect = useCallback(() => {
    push({ route: Routes.Add })
  }, [push])

  const handleSubmit = useCallback(
    (values: DistributorOrderSkuDetailsFormFields, options: { duplicate: boolean; dirty: boolean }) => {
      const update = (duplicate: boolean) => {
        if (order) {
          updateOrder(order.id, values, duplicate)
        }
      }

      if (!options.duplicate) {
        update(false)
      } else {
        onUpdateInitialState(values)
        if (options.dirty) {
          update(true)
        } else {
          justRedirect()
        }
      }
    },
    [justRedirect, onUpdateInitialState, order, updateOrder],
  )

  const handleProducerConfirmation = useCallback(() => {
    if (order) {
      updateOrder(order.id, {
        interaction: {
          ...order.interaction,
          confirmed_by_producer: true,
        },
      })
    }
  }, [order, updateOrder])

  const handleCancelOrder = useCallback(() => {
    if (order) {
      updateOrder(order.id, {
        status: DistributorOrderStatus.Canceled,
      })
    }
  }, [order, updateOrder])

  const handleUncancelOrder = () => {
    if (order && order.status === DistributorOrderStatus.Canceled) {
      updateOrder(order.id, {
        status: DistributorOrderStatus.Changed,
      })
    }
  }

  const handleRestore = useCallback(() => {
    if (order) {
      updateOrder(order.id, {
        status: DistributorOrderStatus.Changed,
      })
    }
  }, [order, updateOrder])

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (progress === Progress.WORK || !order) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('editPageTitle', { id: order.slug })} />
          <FarmerOrderStatusColored
            status={order.status}
            dangerouslySetInnerHTML={{ __html: t(`status.${order.status}`) }}
          />
          <Subtitle>{`${t('editPageSeasonSubtitle')}: ${order?.season?.title}`}</Subtitle>
          {isAdmin && <Subtitle>ID: {order.id}</Subtitle>}
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DistributorOrderSkuDetailsForm
          mode={'edit'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onRemove={removeOrder}
          onRestore={handleRestore}
          onProducerConfirmation={handleProducerConfirmation}
          onCancelOrder={handleCancelOrder}
          onUncancelOrder={handleUncancelOrder}
          order={order}
          removeProgress={meta.removeProgress}
          progress={meta.updateProgress}
          initialValues={{
            distributor_id: order.distributor_id || '',
            billing_company_id: order.billing_company_id || '',
            purchase_number: order.purchase_number,
            producer_id: order.producer_id,
            seller_id: order.seller_id,
            status: order.status,
            comment: order.comment,
            sku_items: order.sku_items,
            interaction: order.interaction,
            order_date: order.order_date,
            desired_delivery_date: order.desired_delivery_date,
            delivery_date: order.delivery_date,
            season_id: order.season_id,
            shipping_date: order.shipping_date,
            external_order_id: order.external_order_id,
            files,
            promocodes: order.promocodes,
            admin_comment: order.admin_comment,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default DistributorOrderEdit
