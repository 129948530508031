import { useAction, useHistoryPush } from '@agro-club/frontend-shared'
import StorefrontProductActions from 'modules/domain/storefrontProduct/duck'
import StorefrontProductSelectors from 'modules/domain/storefrontProduct/selectors'
import { StorefrontProductDTO } from 'modules/domain/storefrontProduct/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import StorefrontRoutes from '../routes'
import StorefrontProductDetailsForm from './components/StorefrontProductDetailsForm'

const StorefrontProductAdd: React.FC<{
  initialState?: StorefrontProductDTO | undefined
  onUpdateInitialState(props: StorefrontProductDTO): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('product')
  const push = useHistoryPush()
  const addAction = useAction(StorefrontProductActions.addRequested)
  const addProgress = useSelector(StorefrontProductSelectors.addProgress)
  const page = useSelector(StorefrontProductSelectors.page)

  const handleSubmit = (form: StorefrontProductDTO) => {
    onUpdateInitialState(form)
    addAction(form)
  }

  const goBack = () => {
    push({ route: StorefrontRoutes.ProductsList, query: { page } })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontProductDetailsForm
          progress={addProgress}
          mode={'create'}
          onSubmit={handleSubmit}
          onCancel={goBack}
          initialValues={initialState}
        />
      </Layout.Content>
    </>
  )
}

export default StorefrontProductAdd
