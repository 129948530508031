import styled, { StyledProps } from 'styled-components'
import { FilePicker, Checkbox as CheckboxComponent } from '@agro-club/frontend-shared'

export const Input240 = styled.div`
  max-width: 240px;
`
export const DescriptionSection = styled.div`
  display: grid;
  grid-gap: 16px 40px;
  grid-template-columns: minmax(min-content, 500px) max-content;
`
export const DescriptionBlock = styled.div`
  display: grid;
  grid-gap: 16px;
`
export const AddressSection = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 240px) minmax(min-content, 240px);
  grid-gap: 16px;
`
export const RegionSection = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 500px) max-content;
`

export const GridArea = styled.div<StyledProps<{ area: 'iban' | 'swift' | 'currency' | 'sin' }>>`
  grid-area: ${props => props.area};
`
export const LogoFilePicker = styled(FilePicker)`
  padding-top: 103px;
`
export const LogoWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
`
export const LogoLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${props => props.theme.color.primary600};
`
export const LogoDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.color.secondary200};
  cursor: pointer;
`

export const AddRegionButton = styled.div<StyledProps<{}>>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  transition: 0.2s opacity ease;
  margin-top: 16px;
  &:hover {
    opacity: 0.8;
  }
`
export const ControlsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
`

export const HorizontalRow = styled.div`
  display: flex;
  flex-direction: row;
  & > :first-child {
    margin-right: 16px;
  }
`

export const ConfigTabsWrapper = styled.div`
  & > div {
    padding: 0;
  }
`

export const ChooseLanguageBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: repeat(4, min-content);
`

export const ErrorStyled = styled.div<StyledProps<{}>>`
  margin-top: 15px;
  line-height: 16px;
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`
