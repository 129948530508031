import { push } from 'connected-react-router'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { generatePath } from 'modules/utils/helpers/generatePath'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import { StorefrontProductActions } from './duck'
import managers from './managers'
import StorefrontProductSelectors from './selectors'

const sagas = createBasicSagas({
  managers,
  selectors: StorefrontProductSelectors,
  actions: StorefrontProductActions,
  onAddItemSucceed: function*(_, item) {
    yield put(push(generatePath(StorefrontRoutes.ProductsEdit, { id: item.id })))
  },
  onUpdateItemSucceed: function*(duplicate) {
    if (duplicate) {
      yield put(push(StorefrontRoutes.ProductsAdd))
    }
  },
  onRemoveItemSucceed: function*() {
    yield put(push(StorefrontRoutes.ProductsList))
  },
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, StorefrontRoutes.ProductsList, { page })
  },
})

const StorefrontProductSaga = function*() {
  yield all([
    takeLatest(StorefrontProductActions.itemRequested.type, sagas.fetchItem),
    takeLatest(StorefrontProductActions.listRequested.type, sagas.fetchList),
    takeLatest(StorefrontProductActions.filterUpdated.type, sagas.fetchList),
    takeLatest(StorefrontProductActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(StorefrontProductActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(StorefrontProductActions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(StorefrontProductActions.listRequestedNext.type, sagas.fetchListNext),
    takeLatest(StorefrontProductActions.addRequested.type, sagas.addItem),
    takeLatest(StorefrontProductActions.updateRequested.type, sagas.updateItem),
    takeLatest(StorefrontProductActions.removeRequested.type, sagas.removeItem),
  ])
}

export default StorefrontProductSaga
