import {
  Button,
  Checkbox,
  FormComponents,
  Input,
  Progress,
  SectionBody,
  SectionContainer,
  SwitchButton,
  Tabs,
  TextArea,
  useFormManager,
} from '@agro-club/frontend-shared'
import * as Styled from 'views/pages/Company/CompanyDetailsForm/styled'
import { createLocalizedValue, isLocalizedValueEmpty } from 'helpers/localization'
import { CompanyConfig, HowItWorksItem } from 'modules/domain/company/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CompanyType,
  FeatureFlagPermission,
  FeatureFlags,
  LocalizedValue,
  FEATURE_FLAGS_ROLES,
  AvailableLanguages,
  availableLanguagesList,
} from 'types/entities'
import * as Yup from 'yup'
import CompanyHowItWorksForm from './CompanyHowItWorksForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { useFormik } from 'formik'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import FeatureFlagForm, { rolesMap } from 'views/pages/Company/CompanyDetailsForm/FeatureFlagsForm/FeatureFlagsForm'

export type SellerConfigFormFields = {
  feature_flags: FeatureFlags
  truck_title_i18n: LocalizedValue
  truck_description_i18n: LocalizedValue
  truck_learn_more_url: string
  order_now_hint_i18n: LocalizedValue
  out_of_stock_info_i18n: LocalizedValue
  out_of_stock_info_email: string
  default_distributor_id: string | null
  has_exclusive_goods: boolean
  dashboard_text_i18n: LocalizedValue
  return_declaration_date?: string
}

export type RetailerConfigFormFields = {}

export type FeatureFlagInput = {
  name: string
  value: FeatureFlagPermission
}

export type FormManagerProps = {
  sellerConfig: SellerConfigFormFields
  retailerConfig: RetailerConfigFormFields
  howItWorks: { items: HowItWorksItem[] }
}

const CompanyConfigForm: React.FC<{
  onSubmit?(values: FormManagerProps): void
  onCancel(): void
  setTab: React.Dispatch<React.SetStateAction<string>>
  setCompanyType: (id: string | number | boolean) => void
  companyType: CompanyType
  config?: CompanyConfig | null
  progress?: Progress
  companyTypes: {
    id: CompanyType
    title: string
  }[]
  configTabs: {
    title: string
    value: string
  }[]
  tab: string
}> = ({ onSubmit, onCancel, setTab, setCompanyType, companyType, config, progress, companyTypes, configTabs, tab }) => {
  const { t } = useTranslation(['company', 'validation'])
  const { register, dirty, bind, submitAll } = useFormManager<FormManagerProps>()

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [
        config?.seller_config?.truck_info?.title_i18n[lang],
        config?.seller_config?.truck_info?.description_i18n[lang],
        config?.seller_config?.order_now_hint_i18n[lang],
        config?.seller_config?.out_of_stock_info?.text_i18n[lang],
        config?.seller_config?.dashboard_text_i18n?.[lang],
      ].some(Boolean)
    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }
    return result
  })

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const truck_info = config?.seller_config?.truck_info
  const howItWorksConfig: HowItWorksItem[] = config?.seller_config?.how_it_works || []

  const sellerConfigValidationSchema = useMemo(() => {
    const i18nValue = Yup.object(createLocalizedValue(_ => Yup.string()))
    return Yup.object({
      truck_title_i18n: i18nValue,
      truck_description_i18n: i18nValue,
      truck_learn_more_url: Yup.string().url(t('validation:invalid_url')),
      order_now_hint_i18n: i18nValue,
      default_distributor_id: Yup.string(),
      out_of_stock_info_i18n: i18nValue,
      dashboard_text_i18n: i18nValue,
      out_of_stock_info_email: Yup.string().email(),
      has_exclusive_goods: Yup.boolean(),
      return_declaration_date: Yup.string(),
    })
  }, [t])

  const retailerConfigValidationSchema = useMemo(() => {
    return Yup.object({})
  }, [])

  const sellerConfig = useFormik<SellerConfigFormFields>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      feature_flags: (config?.seller_config?.feature_flags as FeatureFlags) || {},
      truck_title_i18n: (truck_info?.title_i18n as LocalizedValue) || '',
      truck_description_i18n: (truck_info?.description_i18n as LocalizedValue) || '',
      truck_learn_more_url: truck_info?.learn_more_url || '',
      order_now_hint_i18n: (config?.seller_config?.order_now_hint_i18n as LocalizedValue) || '',
      out_of_stock_info_i18n: config?.seller_config?.out_of_stock_info?.text_i18n ?? {},
      out_of_stock_info_email: config?.seller_config?.out_of_stock_info?.email ?? '',
      default_distributor_id: config?.seller_config?.default_distributor_id || '',
      has_exclusive_goods: config?.seller_config?.has_exclusive_goods || false,
      dashboard_text_i18n: (config?.seller_config?.dashboard_text_i18n as LocalizedValue) ?? {},
      return_declaration_date: config?.seller_config?.return_declaration_date || '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: sellerConfigValidationSchema,
  })
  register('sellerConfig', sellerConfig)

  const retailerConfig = useFormik<RetailerConfigFormFields>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: { ...config?.retailer_config },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: retailerConfigValidationSchema,
  })
  register('retailerConfig', retailerConfig)

  const featureFlags = useMemo(
    () =>
      Object.entries(sellerConfig.values.feature_flags).map(flag => ({
        name: flag[0],
        value: flag[1],
      })),
    [sellerConfig.values.feature_flags],
  )

  const preparedFeatureFlags = useMemo(() => {
    const featureFlagsValues = sellerConfig.values.feature_flags

    const featureFlags = Object.entries(featureFlagsValues).reduce((acc, flag) => {
      let role: FeatureFlagPermission
      const roleName = flag[0]
      const roleValue = flag[1]

      if (!!roleValue[FEATURE_FLAGS_ROLES.ALL]) {
        role = {
          [FEATURE_FLAGS_ROLES.ALL]: true,
        }
      } else {
        role = {
          ...rolesMap,
          ...roleValue,
        }
      }

      return {
        ...acc,
        [roleName]: {
          ...role,
        },
      }
    }, {})

    return featureFlags
  }, [sellerConfig.values.feature_flags])

  useEffect(() => {
    sellerConfig.validateForm()
    retailerConfig.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerConfigValidationSchema, retailerConfigValidationSchema])

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }

      let howItWorks: HowItWorksItem[] = []

      if (companyType === CompanyType.Producer) {
        howItWorks = forms.howItWorks.items.filter(
          item => !isLocalizedValueEmpty(item.subtitle_i18n) && !isLocalizedValueEmpty(item.title_i18n) && item.emoji,
        )
      }

      onSubmit &&
        onSubmit({
          sellerConfig: { ...forms.sellerConfig, feature_flags: preparedFeatureFlags },
          retailerConfig: forms.retailerConfig,
          howItWorks: { items: howItWorks },
        })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmitFeatureFlags = (flags: FeatureFlagInput[]) => {
    const featureFlags = flags.reduce(
      (acc, flag) => ({
        ...acc,
        [flag.name]: {
          ...rolesMap,
          ...flag.value,
        },
      }),
      {},
    )

    sellerConfig.setFieldValue('feature_flags', featureFlags)
  }

  const sellerConfigValidationErrorNotification = useValidationErrorNotification(
    sellerConfig.submitCount,
    sellerConfig.isValid,
  )

  const retailerConfigValidationErrorNotification = useValidationErrorNotification(
    retailerConfig.submitCount,
    retailerConfig.isValid,
  )

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <FormComponents.FormSection title={t('form.companyType')}>
              <SwitchButton
                options={companyTypes}
                value={companyType}
                onChange={setCompanyType}
                testId={'company-type-switch'}
              />
            </FormComponents.FormSection>

            <Styled.ConfigTabsWrapper>
              <Tabs options={configTabs} active={tab} onChange={setTab} data-test-id="company-config-tabs" />
            </Styled.ConfigTabsWrapper>

            {companyType === CompanyType.Producer ? (
              <>
                <Styled.DescriptionSection>
                  <Styled.ChooseLanguageBlock>
                    {availableLanguagesList.map((lang, idx: number) => (
                      <Checkbox
                        label={t(`common:langNames.${lang}`)}
                        isChecked={isLangActive(lang)}
                        value={lang}
                        onChange={handleLangChange}
                        key={lang}
                        testId={`company-config-lang-${idx}`}
                      />
                    ))}
                  </Styled.ChooseLanguageBlock>
                  {!languages.length && <Styled.ErrorStyled>{t('validation:language_required')}</Styled.ErrorStyled>}
                </Styled.DescriptionSection>
                <FormComponents.FormSection title={t('form.dashboardPage')}>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        const title = sellerConfig.getFieldProps(`dashboard_text_i18n[${lang}]`)
                        const meta = sellerConfig.getFieldMeta(`dashboard_text_i18n[${lang}]`)
                        return (
                          <TextArea
                            {...sellerConfig.getFieldProps(title)}
                            key={lang}
                            label={`${t('form.dashboardText')} (${t(`common:langNames.${lang}`)})`}
                            invalid={meta.touched && !!meta.error}
                            errorText={meta.error}
                            data-test-id={`company-config-dashboard-text-${lang}`}
                            required={lang === 'en'}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                <Styled.DescriptionSection>
                  <FormComponents.FormSection title={t('form.truckInfo')}>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          const title = sellerConfig.getFieldProps(`truck_title_i18n[${lang}]`)
                          const meta = sellerConfig.getFieldMeta(`truck_title_i18n[${lang}]`)
                          return (
                            <Input
                              {...sellerConfig.getFieldProps(title)}
                              key={lang}
                              label={`${t('form.truckTitle')} (${t(`common:langNames.${lang}`)})`}
                              invalid={meta.touched && !!meta.error}
                              errorText={meta.error}
                              data-test-id={`company-config-truck-title-${lang}`}
                              required={lang === 'en'}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          const title = sellerConfig.getFieldProps(`truck_description_i18n[${lang}]`)
                          const meta = sellerConfig.getFieldMeta(`truck_description_i18n[${lang}]`)
                          return (
                            <TextArea
                              {...sellerConfig.getFieldProps(title)}
                              key={lang}
                              invalid={meta.touched && !!meta.error}
                              errorText={meta.error}
                              label={`${t('form.truckDescription')} (${t(`common:langNames.${lang}`)})`}
                              data-test-id={`company-config-truck-description-${lang}`}
                              limit={200}
                              required={lang === 'en'}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          const title = sellerConfig.getFieldProps(`order_now_hint_i18n[${lang}]`)
                          const meta = sellerConfig.getFieldMeta(`order_now_hint_i18n[${lang}]`)
                          return (
                            <Input
                              {...sellerConfig.getFieldProps(title)}
                              key={lang}
                              label={`${t('form.orderNowHint')} (${t(`common:langNames.${lang}`)})`}
                              invalid={meta.touched && !!meta.error}
                              errorText={meta.error}
                              data-test-id={`company-config-order-now-hint-${lang}`}
                              required={lang === 'en'}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          const title = sellerConfig.getFieldProps(`out_of_stock_info_i18n[${lang}]`)
                          const meta = sellerConfig.getFieldMeta(`out_of_stock_info_i18n[${lang}]`)
                          return (
                            <Input
                              {...sellerConfig.getFieldProps(title)}
                              key={lang}
                              label={`${t('form.outOfStockInfoText')} (${t(`common:langNames.${lang}`)})`}
                              invalid={meta.touched && !!meta.error}
                              errorText={meta.error}
                              data-test-id={`company-config-out-of-stock-info-text-${lang}`}
                              required={lang === 'en'}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                    <Input
                      type="email"
                      label={t('form.outOfStockInfoEmail')}
                      {...sellerConfig.getFieldProps('out_of_stock_info_email')}
                      invalid={
                        sellerConfig.touched.out_of_stock_info_email && !!sellerConfig.errors.out_of_stock_info_email
                      }
                      errorText={sellerConfig.errors.out_of_stock_info_email}
                      data-test-id={'company-config-out-of-stock-info-email'}
                    />
                    <Input
                      label={t('form.truckLearnMoreUrl')}
                      {...sellerConfig.getFieldProps('truck_learn_more_url')}
                      invalid={sellerConfig.touched.truck_learn_more_url && !!sellerConfig.errors.truck_learn_more_url}
                      errorText={sellerConfig.errors.truck_learn_more_url}
                      data-test-id={'company-config-truck-learn-more-url'}
                    />
                    <Input
                      label={t('form.defaultDistributorId')}
                      {...sellerConfig.getFieldProps('default_distributor_id')}
                      invalid={
                        sellerConfig.touched.default_distributor_id && !!sellerConfig.errors.default_distributor_id
                      }
                      errorText={sellerConfig.errors.default_distributor_id}
                      data-test-id={'company-config-default-distributor-id'}
                    />
                    <Input
                      label={t('form.returnDeclarationDate')}
                      {...sellerConfig.getFieldProps('return_declaration_date')}
                      invalid={
                        sellerConfig.touched.return_declaration_date && !!sellerConfig.errors.return_declaration_date
                      }
                      errorText={sellerConfig.errors.return_declaration_date}
                      data-test-id={'company-config-return-declaration-date'}
                    />
                    <Checkbox
                      label={t('form.hasExclusiveGoods')}
                      isChecked={sellerConfig.values.has_exclusive_goods}
                      onChange={(_, isChecked) => {
                        sellerConfig.setFieldValue('has_exclusive_goods', isChecked)
                      }}
                      testId={'company-config-has-exclusive-goods'}
                    />
                  </FormComponents.FormSection>
                </Styled.DescriptionSection>
                <FormComponents.FormSection title={t('form.howItWorks')}>
                  <CompanyHowItWorksForm items={howItWorksConfig} useFormik={bind('howItWorks')} />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.featureFlags')}>
                  <FeatureFlagForm items={featureFlags} onSubmit={handleSubmitFeatureFlags} />
                </FormComponents.FormSection>
              </>
            ) : null}
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          data-test-id={'submit-config-button'}
          filled={true}
          progress={progress}
          disabled={!dirty || progress === Progress.WORK}
          onClick={submit}
          intent={'primary'}
        >
          {`${t('common:save')} ${t('tabs.config')}`}
        </Button>
        <Button onClick={onCancel} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
        {sellerConfigValidationErrorNotification}
        {retailerConfigValidationErrorNotification}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default CompanyConfigForm
