import { createActionCreators, createReducerFunction } from 'immer-reducer'
import {
  ProductSettings,
  ProductSettingsListRequestFilter,
  ProductSettingsListRequestSorting,
  ProductSettingsState,
  ProductSettingsDTO,
} from 'modules/domain/productSettings/types'
import { BasicEntityState } from 'modules/constants'

import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'

const initialState: ProductSettingsState = BasicEntityState
class ProductSettingsReducer extends BasicEntityImmerReducer<
  ProductSettings,
  ProductSettingsListRequestFilter,
  ProductSettingsListRequestSorting,
  ProductSettingsDTO,
  ProductSettingsDTO,
  ProductSettingsState
> {}

export const ProductSettingsActions = createActionCreators(ProductSettingsReducer)
export default ProductSettingsActions
export const reducer = createReducerFunction(ProductSettingsReducer, initialState)
