import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'
import { CommentEntryDTO } from './types'

const getList = (collectionName, objId) =>
  apiClient.get<ListResponse<CommentEntryDTO>>(endpoints.comments(collectionName, objId))

const addItem = (collectionName, objId, comment) =>
  apiClient.post<CommentEntryDTO>(endpoints.comments(collectionName, objId), { comment })

const removeItem = (collectionName, objId, commentId) =>
  apiClient.delete<CommentEntryDTO>(endpoints.comments(collectionName, `${objId}/${commentId}`))

const updateItem = (collectionName, objId, commentId, comment) =>
  apiClient.put<CommentEntryDTO>(endpoints.comments(collectionName, `${objId}/${commentId}`), { comment })

export default {
  getList,
  addItem,
  removeItem,
  updateItem,
}
