import styled from 'styled-components'
import { IconBin } from '@agro-club/frontend-shared'

export const Wrapper = styled.div`
  padding-top: 16px;
  display: grid;
  grid-template-columns: 496px;
  grid-gap: 48px;
`

export const SelectWrapper = styled.div`
  max-width: 300px;
`

export const EmptyText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.secondary200};
`

export const AddButton = styled.button`
  border: none;
  background-color: ${props => props.theme.color.primary50};
  color: ${props => props.theme.color.primary600};
  align-self: flex-start;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  margin-top: 40px;
  justify-self: start;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s background-color, 0.3s box-shadow;

  &:hover {
    background-color: ${props => props.theme.color.primary100};
  }

  &:focus {
    outline: none;
    box-shadow: ${props => props.theme.shadow.focus};
  }
`

export const ListWrapper = styled.div`
  display: grid;
  width: 496px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 24px;
  margin-top: 24px;
`

export const MemberWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 25px;
  border: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  border-radius: 8px;
  padding: 12px;
  position: relative;
`

export const MemberSenior = styled.div`
  position: absolute;
  background-color: ${props => props.theme.color.accentApproving};
  border-radius: 6px;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  top: -3px;
  color: ${props => props.theme.color.onPrimaryLight};
  right: 12px;
  transform: translateY(-50%);
`

export const MemberInner = styled.div``

export const MemberMainText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
`

export const MemberAdditionalText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
`

export const MemberAddress = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-top: 8px;
`

export const MemberRemoveBtn = styled(IconBin)`
  align-self: end;
  justify-self: end;
  cursor: pointer;
`
