import { SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'
import { Company } from 'modules/domain/company/types'
import React, { useMemo } from 'react'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type CompanySelectNoFetchProps = Omit<SimpleSelectProps, 'getOptionLabel' | 'getOptionValue' | 'options'> & {
  options?: Company[]
  'data-test-id'?: string
}

export const CompanySelectNoFetch: React.VFC<CompanySelectNoFetchProps> = ({ options = [], ...props }) => {
  const optionsMapped = useMemo(
    () =>
      options.map(option => ({
        title: option.official_name,
        ...option,
      })),
    [options],
  )

  return (
    <SelectTestWrapper data-test-id={props['data-test-id'] || `company-no-fetch-select`}>
      <SimpleSelect options={optionsMapped} {...props} />
    </SelectTestWrapper>
  )
}
