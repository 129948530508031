import React from 'react'
import { Progress } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.color.accentDestructive};
  font-size: 14px;
  font-weight: 400;
`

const ProgressContainer: React.FC<{ progress: Progress; errorMessage: string }> = ({
  progress,
  errorMessage,
  children,
}) => {
  if ([Progress.IDLE, Progress.WORK].includes(progress)) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout size={'medium'} />
      </SpinnerWrapper>
    )
  }

  if (progress === 'ERROR') {
    return <ErrorMessage>{errorMessage}</ErrorMessage>
  }

  return <>{children}</>
}

export default ProgressContainer
