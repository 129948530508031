import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import PromoInfoActions from './duck'
import PromoInfoSelectors from './selectors'
import { PromoInfo } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
// import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import PromoInfoRoutes from 'views/pages/PromoInfo/routes'
import { generatePath } from 'react-router-dom'

export const fetchList = function*() {
  try {
    let currentPage = yield select(PromoInfoSelectors.page)
    const filter = yield select(PromoInfoSelectors.filter)
    const sorting = yield select(PromoInfoSelectors.sorting)
    const pageSize = yield select(PromoInfoSelectors.pageSize)

    let response: ListResponse<PromoInfo> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(PromoInfoActions.listRequestSucceed(data, total_count, page))

    // yield call(updateLocationQuery, PromoInfoRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PromoInfoActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(PromoInfoSelectors.page)
    const filter = yield select(PromoInfoSelectors.filter)
    const sorting = yield select(PromoInfoSelectors.sorting)
    const pageSize = yield select(PromoInfoSelectors.pageSize)
    const { data, total_count }: { data: PromoInfo[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(PromoInfoActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PromoInfoActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof PromoInfoActions.itemRequested>) {
  try {
    const item: PromoInfo = yield call(managers.getItem, id)
    yield put(PromoInfoActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PromoInfoActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof PromoInfoActions.addRequested>) {
  try {
    const item: PromoInfo = yield call(managers.addItem, dto)
    yield put(PromoInfoActions.addSucceed(item))
    yield put(push(generatePath(PromoInfoRoutes.PromoInfoEdit, { id: item.id })))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(PromoInfoActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof PromoInfoActions.updateRequested>) {
  try {
    const item: PromoInfo = yield call(managers.updateItem, id, dto)
    yield put(PromoInfoActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(PromoInfoActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof PromoInfoActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(PromoInfoActions.removeSucceed(payload))
    yield put(push(PromoInfoRoutes.PromoInfoList))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PromoInfoActions.removeFailed(payload, type, detail))
  }
}

const PromoInfoSaga = function*() {
  yield all([
    takeLatest(PromoInfoActions.itemRequested.type, fetchItem),
    takeLatest(PromoInfoActions.listRequested.type, fetchList),
    takeLatest(PromoInfoActions.filterUpdated.type, fetchList),
    takeLatest(PromoInfoActions.sortingUpdated.type, fetchList),
    takeLatest(PromoInfoActions.filterHasBeenReset.type, fetchList),
    takeLatest(PromoInfoActions.sortingHasBeenReset.type, fetchList),

    takeLatest(PromoInfoActions.listRequestedNext.type, fetchListNext),

    takeLatest(PromoInfoActions.addRequested.type, addItem),
    takeLatest(PromoInfoActions.updateRequested.type, updateItem),
    takeLatest(PromoInfoActions.removeRequested.type, removeItem),
  ])
}

export default PromoInfoSaga
