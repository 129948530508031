import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { Territory } from 'modules/domain/territory/types'
import managers from 'modules/domain/territory/managers'

export type TerritorySelectProps = EntitySelectProps<Territory> & {
  parentId?: string
  companyId?: string
  producerId?: string
}

export const getTerritoryLabel = (item: Territory) => item.title

export const getTerritoryValue = (item: Territory) => item.id

export const TerritorySelect: React.VFC<TerritorySelectProps> = ({ companyId, parentId, producerId, ...props }) => {
  const filter = useMemo(() => ({ company_id: companyId, parent_id: parentId, producer_id: producerId }), [
    companyId,
    parentId,
    producerId,
  ])

  return (
    <SelectTestWrapper data-test-id="territory-select">
      <EntitySelect
        filter={filter}
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getTerritoryLabel}
        getOptionValue={getTerritoryValue}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}
