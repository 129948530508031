import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { createItemsByIdsOnceHook } from 'modules/utils/helpers/createItemsByIdsOnceHook'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { User } from 'modules/domain/user/types'
import { Progress, ResourceHook } from 'modules/types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as managers from './managers'

export const useUserList: ResourceHook<User[], void[]> = () => {
  const progress = useSelector(UserSelectors.listFetchProgress)
  const list = useSelector(UserSelectors.userList)
  const page = usePageQuery()
  const fetchAction = useAction(UserActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useUser: ResourceHook<User, [string]> = (id: string) => {
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const user = useSelector(state => UserSelectors.user(state, id))
  const fetchAction = useAction(UserActions.itemRequested, id)

  useDidMount(() => {
    if (!user || user.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, user]
}

export const useUserById: ResourceHook<User, [string]> = (id: string) => {
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const item = useSelector(state => UserSelectors.user(state, id))
  const fetchAction = useAction(UserActions.itemRequested, id)

  useEffect(() => {
    if (id && (!item || id !== item.id)) {
      fetchAction()
    }
  }, [item, fetchAction, id])

  return [meta.fetchProgress, item]
}

export const useUsersByIds = createItemsByIdsOnceHook(true, (ids: string[]) =>
  managers.getCrmUsersSuggestions(
    {
      ids,
    },
    {},
    1,
    ids.length,
  ),
)

export const useCustomersOptions = makeCancellableResourceListHook(makeCancelable(managers.getCustomersList))
export const useCrmUsersOptions = makeCancellableResourceListHook(makeCancelable(managers.getCrmUsersList))
