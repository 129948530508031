import { useEffect, useRef } from 'react'

export const useIntercom = (intercomAppId?: string) => {
  const isInitialized = useRef(false)

  useEffect(() => {
    if (!intercomAppId || isInitialized.current || typeof window === 'undefined') {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const script = document.createElement('script')
    script.innerHTML = `
      (function(){
        const APP_ID = '${intercomAppId}'
        window.intercomSettings = {
          app_id: APP_ID
        };
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      })()
      `
    const lastHeadNode = document.head.childNodes.length - 1
    document.head.insertBefore(script, document.head.childNodes[lastHeadNode])
    isInitialized.current = true
  }, [intercomAppId])
}
