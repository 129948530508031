import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import {
  InventoryTransferRequestFilter,
  InventoryTransferRequestSorting,
  InventoryTransferRequestState,
  InventoryTransferRequest,
  TransferRequestDTO,
  TransferRequestStatuses,
} from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { FetchError, UpdateError } from 'modules/domain/types'

const initialState: InventoryTransferRequestState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  requestsCountProgress: Progress.IDLE,
  inRequestsCount: 0,

  filter: {
    status: [TransferRequestStatuses.active, TransferRequestStatuses.approved, TransferRequestStatuses.rejected],
  },
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class InventoryTransferRequestReducer extends ImmerReducer<InventoryTransferRequestState> {
  listRequested(params: {
    filter?: InventoryTransferRequestFilter
    sorting?: InventoryTransferRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: InventoryTransferRequest[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  filterUpdated(filter: InventoryTransferRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: InventoryTransferRequestFilter) {
    this.draftState.filter = { ...this.draftState.filter, ...filter }
  }

  sortingUpdated(sorting: InventoryTransferRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }
  updateRequested(id: string, _dto: TransferRequestDTO) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
  }
  updateSucceed(request: InventoryTransferRequest) {
    this.draftState.items[request.id] = request
    this.draftState.meta[request.id].updateProgress = Progress.SUCCESS
    this.draftState.updateProgress = Progress.SUCCESS
  }
  updateFailed(id: string, error: UpdateError) {
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.updateProgress = Progress.ERROR
  }
  requestsCountRequested() {
    this.draftState.requestsCountProgress = Progress.WORK
  }
  requestsCountRequestSucceed(count: number) {
    this.draftState.requestsCountProgress = Progress.SUCCESS
    this.draftState.inRequestsCount = count
  }
  requestsCountRequestError() {
    this.draftState.requestsCountProgress = Progress.ERROR
  }
}

export const InventoryInTransferRequestActions = createActionCreators(InventoryTransferRequestReducer)
export default InventoryInTransferRequestActions
export const reducer = createReducerFunction(InventoryTransferRequestReducer, initialState)
