import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/reconciliation/managers'
import { CheckboxTreeSelect, CheckboxTreeSelectProps } from '@agro-club/frontend-shared'
import { ReconciliationCompanyFilter } from 'modules/domain/reconciliation/types'

const useDistributorsOptions = makeCancellableResourceListHook(makeCancelable(managers.getRetailers))

const DistributorsMultiSelect: React.FC<Omit<CheckboxTreeSelectProps, 'options' | 'filter'> & {
  filter?: ReconciliationCompanyFilter
  selected?: string[]
}> = ({ filter, selected, ...selectProps }) => {
  const [, distributors = []] = useDistributorsOptions(filter, undefined, 1, 2000)
  const { t } = useTranslation(['common'])

  const options = useMemo(() => {
    return distributors.map(distributor => ({
      id: distributor.id,
      title: distributor.title,
      hasChildren: !!distributor.has_branch_company,
      parent: distributor.head_company_id,
    }))
  }, [distributors])

  return <CheckboxTreeSelect {...selectProps} options={options} selected={selected} placeholder={t('all')} />
}

export default DistributorsMultiSelect
