import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import { useAction } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import ChangeLogSelector from 'modules/domain/changeLog/selectors'
import ChangeLogAction from 'modules/domain/changeLog/duck'
import { Row } from 'react-table'
import { generatePath } from 'modules/utils/helpers/generatePath'
import { useHistory } from 'react-router-dom'
import { ChangeLogEntry } from 'modules/domain/changeLog/types'
import ChangeLogRoutes from '../routes'
import { useChangeLogList } from 'modules/domain/changeLog/hooks'
import { Author, LastUpdateCell } from './ChangeLogListTableCell'

const ChangeLogListTable: React.FC = () => {
  const { t } = useTranslation('changeLog')
  const history = useHistory()
  const [progress, data = []] = useChangeLogList()

  const allColumns = useMemo(
    () => [
      { Header: t('headers.entity'), cellAlign: 'left', accessor: 'title' as const },
      { Header: t('headers.objectId'), cellAlign: 'left', accessor: 'obj_id' as const },
      { Header: t('headers.operation'), cellAlign: 'left', accessor: 'operation' as const },
      { Header: t('headers.author'), cellAlign: 'left', accessor: 'user' as const, Cell: Author },
      { Header: t('headers.lastUpdate'), cellAlign: 'left', accessor: 'updated_at' as const, Cell: LastUpdateCell },
    ],
    [t],
  )

  const total = useSelector(ChangeLogSelector.total)
  const pages = useSelector(ChangeLogSelector.pages)
  const pageSize = useSelector(ChangeLogSelector.pageSize)
  const page = useSelector(ChangeLogSelector.page)
  const listRequested = useAction(ChangeLogAction.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleRowClick = useCallback((row: Row<ChangeLogEntry>) => {
    history.push(generatePath(ChangeLogRoutes.Edit, { id: row.original.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CompoundedTable
      columns={allColumns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={fetchNextItems}
      pages={pages}
      onRowClick={handleRowClick}
    />
  )
}

export default ChangeLogListTable
