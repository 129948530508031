import { useSelector } from 'react-redux'
import BadgeSelectors from 'modules/domain/badge/selectors'
import { Progress, ResourceHook } from 'modules/types'
import BadgeActions from 'modules/domain/badge/duck'
import { Badge } from 'modules/domain/badge/types'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from './managers'

export const useBadgeList: ResourceHook<Badge[], void[]> = () => {
  const progress = useSelector(BadgeSelectors.listFetchProgress)
  const list = useSelector(BadgeSelectors.badgeList)
  const page = usePageQuery()
  const fetchAction = useAction(BadgeActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useBadge: ResourceHook<Badge, [string]> = (id: string) => {
  const meta = useSelector(state => BadgeSelectors.meta(state, id))
  const badge = useSelector(state => BadgeSelectors.badge(state, id))
  const fetchAction = useAction(BadgeActions.itemRequested, id)

  useDidMount(() => {
    if (!badge || badge.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, badge]
}

export const useBadgeById: ResourceHook<Badge, [string]> = (id: string) => {
  const meta = useSelector(state => BadgeSelectors.meta(state, id))
  const item = useSelector(state => BadgeSelectors.badge(state, id))
  const fetchAction = useAction(BadgeActions.itemRequested, id)

  useEffect(() => {
    if (!item || id !== item.id) {
      fetchAction()
    }
  }, [item, fetchAction, id])

  return [meta.fetchProgress, item]
}

export const useCrmBadgesOptions = makeCancellableResourceListHook(makeCancelable(managers.getCrmBadgesList))
