import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PackageTypesSelectors from 'modules/domain/packageTypes/selectors'
import PackageTypesActions from 'modules/domain/packageTypes/duck'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { usePackageTypesById } from 'modules/domain/packageTypes/hooks'
import { Progress } from 'modules/types'
import StorefrontPackageTypesDetailsForm from './StorefrontPackageTypesDetailsForm'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useTranslation } from 'react-i18next'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'
import StorefrontRoutes from '../routes'
import useLangPicker from 'hooks/useLangPicker'
import * as Styled from './styled'
import { PackageTypesDTO } from 'modules/domain/packageTypes/types'

const StorefrontPackageTypesEdit: React.FC = () => {
  const { t } = useTranslation('packageTypes')
  const { pick } = useLangPicker()
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const updateAction = useAction(PackageTypesActions.updateRequested)
  const removeAction = useAction(PackageTypesActions.removeRequested)
  const page = useSelector(PackageTypesSelectors.page)
  const meta = useSelector(state => PackageTypesSelectors.meta(state, params.id))
  const [fetchProgress, item] = usePackageTypesById(params.id)

  const handleSubmit = (props: PackageTypesDTO) => {
    updateAction(params.id, props)
  }

  const handleRemove = () => {
    removeAction(params.id)
  }

  const goBack = () => {
    push({ route: StorefrontRoutes.PackageTypesList, query: { page } })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !item) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={pick(item.title_i18n) || ''} />
          <Styled.Id>{`ID ${item.id}`}</Styled.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontPackageTypesDetailsForm
          progress={meta.updateProgress}
          errorText={meta.updateErrorDetail}
          removeProgress={meta.removeProgress}
          mode={'edit'}
          onRemove={handleRemove}
          onSubmit={handleSubmit}
          onCancel={goBack}
          initialValues={item}
        />
      </Layout.Content>
    </>
  )
}

export default StorefrontPackageTypesEdit
