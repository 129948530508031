import { AppGlobalState, Progress } from '../../types'
import { ROLES } from 'types/entities'

const getInitProgress = (state: AppGlobalState): Progress => state.auth.initProgress
const getError = (state: AppGlobalState): string => state.auth.error
const getResetPasswordError = (state: AppGlobalState): string => state.auth.resetPasswordError
const getResetPasswordProgress = (state: AppGlobalState) => state.auth.resetPasswordProgress
const getResetEmail = (state: AppGlobalState): string => state.auth.email
const location = (state: AppGlobalState): string => `${state.router.location.pathname}`
const profile = (state: AppGlobalState) => state.auth.profile
const role = (state: AppGlobalState) => (state.auth.profile ? state.auth.profile.role : null)
const isAdmin = (state: AppGlobalState) => (state.auth.profile ? state.auth.profile.role === ROLES.AGRO_ADMIN : false)
const userCompany = (state: AppGlobalState) => (state.auth.profile ? state.auth.profile.company : null)
const userCompanyId = (state: AppGlobalState) => (state.auth.profile ? state.auth.profile.company_id : null)

const profileUpdateProgress = (state: AppGlobalState) => state.auth.profileUpdateProgress
const setNewPasswordProgress = (state: AppGlobalState) => state.auth.setNewPasswordProgress
const tokenRequestProgress = (state: AppGlobalState) => state.auth.tokenRequestProgress
const isAuthenticated = (state: AppGlobalState) => state.auth.isAuthenticated
const search = (state: AppGlobalState): string => state.router.location.search
const locationState = (state: AppGlobalState) => state.router.location.state as { from: string } | undefined

const AuthSelectors = {
  search,
  locationState,
  getInitProgress,
  getError,
  getResetPasswordError,
  getResetPasswordProgress,
  getResetEmail,
  location,
  profile,
  profileUpdateProgress,
  setNewPasswordProgress,
  tokenRequestProgress,
  role,
  userCompany,
  userCompanyId,
  isAdmin,
  isAuthenticated,
}

export default AuthSelectors
