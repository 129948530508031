import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import inventoryInTransferSelectors from 'modules/domain/inventoryInTransferRequest/selectors'
import { useInventoryAllTransferRequestList } from 'modules/domain/inventoryInTransferRequest/hooks'
import TransferRequestList, { PageTypes } from './TransferRequestList'
import { InventoryInTransferRequestActions } from 'modules/domain/inventoryInTransferRequest/duck'

const TransferAllRequestsTable: React.VFC = () => {
  const { t } = useTranslation('inventorySKU')
  useHelmet({ title: t('inventoryMetaTitle') })
  // inRequests and TransferInRequestList never visible  at same time,
  // we can reuse TransferIn actions and selectors here
  const [progress, data = []] = useInventoryAllTransferRequestList()
  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Title title={t('inventoryTransferRequest:title')} />
        </Header.TabsRoot>
      </Layout.Header>
      <Layout.Content>
        <TransferRequestList
          selectors={inventoryInTransferSelectors}
          actions={InventoryInTransferRequestActions}
          pageType={PageTypes.allRequests}
          progress={progress}
          data={data}
        />
      </Layout.Content>
    </>
  )
}

export default TransferAllRequestsTable
