import React from 'react'
import Media from 'views/ui/Media/Media'
import { useFileObjectUrl } from '@agro-club/frontend-shared'
import * as Styled from './styled'
import Loader from './Loader'

const PreviewOpenButton: React.FC<{ url: string }> = ({ url }) => {
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Styled.PreviewOpenButton />
    </a>
  ) : null
}

const ImagePreview: React.FC<{
  url?: string | File
  uploading?: boolean
  percent?: number
  error?: boolean
  onRemove(): void
}> = ({ url, uploading, percent = 0, error = false, onRemove }) => {
  const { url: fileUrl } = useFileObjectUrl(url)

  return (
    <Styled.PreviewContainer>
      <Styled.ButtonsContainer>
        <PreviewOpenButton url={fileUrl as string} />
        <Styled.RemoveButton onClick={onRemove} />
      </Styled.ButtonsContainer>
      <Media url={url} />
      {uploading ? <Loader percent={percent} /> : null}
      {error ? <Styled.ErrorContainer /> : null}
    </Styled.PreviewContainer>
  )
}

export default ImagePreview
