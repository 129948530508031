import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Progress } from 'modules/types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { FetchError } from '../../types'
import {
  Allocation,
  AllocationDTO,
  AllocationListRequestSorting,
  AllocationRequestFilter,
  ProductLimit,
  ProductLimitRequestFilter,
} from '../common/types'
import { RetailerAllocationEntityState } from './types'

const initialState: RetailerAllocationEntityState = {
  items: {},
  meta: {},
  ids: [],

  filter: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
  sorting: {},

  productsLimitForFarmerOrders: [],
  productsLimitForRetailerOrders: [],

  itemFetchProgress: Progress.IDLE,
  itemFetchErrorDetail: null,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  addErrorDetail: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  updateErrorDetail: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  removeErrorDetail: null,
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchErrorDetail: null,
  productsLimitForFarmerOrdersFetchProgress: Progress.IDLE,
  productsLimitForFarmerOrdersFetchError: null,
  productsLimitForRetailerOrdersFetchProgress: Progress.IDLE,
  productsLimitForRetailerOrdersFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  listFetchNextErrorDetail: null,
}

class RetailerAllocationReducer extends BasicEntityImmerReducer<
  Allocation,
  AllocationRequestFilter,
  AllocationListRequestSorting,
  AllocationDTO,
  AllocationDTO,
  RetailerAllocationEntityState
> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  farmerOrdersProductsLimitRequested(filter: ProductLimitRequestFilter) {
    this.draftState.productsLimitForFarmerOrdersFetchProgress = Progress.WORK
    this.draftState.productsLimitForFarmerOrdersFetchError = null
  }

  farmerOrdersProductsLimitRequestSucceed(productsLimit: ProductLimit[]) {
    this.draftState.productsLimitForFarmerOrdersFetchProgress = Progress.SUCCESS
    this.draftState.productsLimitForFarmerOrders = productsLimit
  }

  farmerOrdersProductsLimitRequestFailed(error: FetchError) {
    this.draftState.productsLimitForFarmerOrdersFetchProgress = Progress.ERROR
    this.draftState.productsLimitForFarmerOrdersFetchError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retailerOrdersProductsLimitRequested(filter: ProductLimitRequestFilter) {
    this.draftState.productsLimitForRetailerOrdersFetchProgress = Progress.WORK
    this.draftState.productsLimitForRetailerOrdersFetchError = null
  }

  retailerOrdersProductsLimitRequestSucceed(productsLimit: ProductLimit[]) {
    this.draftState.productsLimitForRetailerOrdersFetchProgress = Progress.SUCCESS
    this.draftState.productsLimitForRetailerOrders = productsLimit
  }

  retailerOrdersProductsLimitRequestFailed(error: FetchError) {
    this.draftState.productsLimitForRetailerOrdersFetchProgress = Progress.ERROR
    this.draftState.productsLimitForRetailerOrdersFetchError = error
  }

  clearProductsLimitsForFarmerOrders() {
    this.draftState.productsLimitForFarmerOrders = []
  }

  clearProductsLimitsForRetailerOrders() {
    this.draftState.productsLimitForRetailerOrders = []
  }
}

export const RetailerAllocationActions = createActionCreators(RetailerAllocationReducer)
export default RetailerAllocationActions
export const reducer = createReducerFunction(RetailerAllocationReducer, initialState)
