import React from 'react'
import { Progress } from 'modules/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useTranslation } from 'react-i18next'
import { Button } from '@agro-club/frontend-shared'
import StickyFooterDeleteBtn from './StickyFooterDeleteBtn'
import StickyFooterBtn from '../StickyFooterBtn/StickyFooterBtn'

const StickyFooterDefaultControls: React.FC<{
  onRemove?(): void
  onSave(): void
  onCancel(): void
  saveProgress?: Progress
  isSaveDisabled: boolean
  removeProgress?: Progress
  popoverText?: string
}> = ({ popoverText, saveProgress, removeProgress, isSaveDisabled, onRemove, onSave, onCancel }) => {
  const { t } = useTranslation(['common'])
  return (
    <StickyFooterLayout.ButtonsFooter>
      <Button
        onClick={onSave}
        intent={'primary'}
        filled
        progress={saveProgress}
        disabled={isSaveDisabled}
        data-test-id={'save-button'}
      >
        {t('common:save')}
      </Button>
      <StickyFooterBtn
        heading={t('common:cancelEditingHeader')}
        text={t('common:cancelEditingText')}
        onSubmit={onCancel}
        buttonText={t('common:dontSaveChanges')}
        intent={'cancel'}
      />
      {!!onRemove && (
        <StickyFooterDeleteBtn onRemove={onRemove} removeProgress={removeProgress} popoverText={popoverText ?? ''} />
      )}
    </StickyFooterLayout.ButtonsFooter>
  )
}

export default StickyFooterDefaultControls
