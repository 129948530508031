import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerritoryList } from 'modules/domain/territory/hooks'
import { useSelector } from 'react-redux'
import TerritorySelectors from 'modules/domain/territory/selectors'
import TerritoryActions from 'modules/domain/territory/duck'
import { useTable } from 'react-table'
import { Territory, TerritoryListRequestFilter } from 'modules/domain/territory/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import TerritoryRoutes from 'views/pages/Territory/routes'
import { Filter } from 'views/components/TableFilters/TableFilters'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyType } from 'types/entities'

const TerritoryTable: React.FC = () => {
  const { t } = useTranslation(['territory', 'labels'])
  const push = useHistoryPush()
  const [progress, data = []] = useTerritoryList()
  const total = useSelector(TerritorySelectors.total)
  const page = useSelector(TerritorySelectors.page)
  const pages = useSelector(TerritorySelectors.pages)
  const pageSize = useSelector(TerritorySelectors.pageSize)
  const filter = useSelector(TerritorySelectors.filter)

  const listRequested = useAction(TerritoryActions.listRequested)
  const filterUpdated = useAction(TerritoryActions.filterUpdated)

  const { scrollRef } = usePersistentScroll('territoryTable')

  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.id'),
        accessor: 'id' as const,
        sortable: false,
      },
      {
        Header: t('fields.title'),
        accessor: 'title' as const,
        sortable: false,
      },
      {
        Header: t('fields.company_name'),
        accessor: 'company_official_name' as const,
        sortable: false,
      },
      {
        Header: t('fields.external_id'),
        accessor: 'external_id' as const,
        sortable: false,
      },
      {
        Header: t('fields.parent_id'),
        accessor: 'parent_id' as const,
        sortable: false,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<Territory>({
    columns: allColumns,
    data,
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleFilterChange = useCallback(
    (newValue: Partial<TerritoryListRequestFilter>) => {
      filterUpdated({ ...filter, ...newValue })
    },
    [filter, filterUpdated],
  )

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <Filter title={t('labels:producer')}>
          <CompanySelect
            variant="small"
            onChange={val => handleFilterChange({ producer_id: val })}
            value={filter.producer_id}
            companyType={CompanyType.Producer}
            isClearable
            isSearchable
          />
        </Filter>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: TerritoryRoutes.Item, params: { id: row.original.id } })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default TerritoryTable
