import React, { useCallback, useState } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { FormikProvider } from 'formik'
import { AvailableLanguages, availableLanguagesList, LocalizedValue, Status } from 'types/entities'
import { Checkbox, SectionBody, SectionContainer, useFormManager, FormComponents } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'
import * as Yup from 'yup'
import ContentForm, { ContentFormProps } from './ContentForm'
import { PromoInfoType } from 'modules/domain/promoInfo/types'
import ParamsForm from 'views/pages/PromoInfo/PromoInfoDetailsForm/ParamsForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { createLocalizedValue } from 'helpers/localization'
import StylesForm from './StylesForm'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'

export type FormProps = {
  status: Status
  message: {
    title: LocalizedValue
    description: LocalizedValue
  }
  title: LocalizedValue
  description: LocalizedValue
  type: PromoInfoType
  producers?: string[]
  categories?: string[]
  styles?: {
    startColor: string
    endColor: string
    primaryColor: string
    secondaryColor: string
  }
}

type LangData = Required<LocalizedValue<ContentFormProps>>
type FormikManagerData = LangData & {
  common: { status: Status }
  styles: { startColor: string; endColor: string; primaryColor: string; secondaryColor: string }
  params: { categories: string[]; producers: string[]; type: PromoInfoType }
}

const PromoInfoDetailsForm: React.FC<{
  onSubmit(form: FormProps): void
  onRemove?: () => void
  initialValues?: Partial<FormProps>
  progress?: Progress
  removeProgress?: Progress
  editing?: boolean
  onCancel(): void
}> = ({
  onSubmit,
  onRemove,
  progress,
  removeProgress,
  onCancel,
  initialValues = {
    status: Status.Active,
    type: PromoInfoType.CatalogProducer,
  },
}) => {
  const { t } = useTranslation(['promoInfo', 'common'])
  const { bind, submitAll, dirty, valid } = useFormManager<FormikManagerData>()

  const validationSchema = Yup.object({
    title: Yup.object(createLocalizedValue(() => Yup.string())),
    description: Yup.object(createLocalizedValue(() => Yup.string())),
  })

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const formik = bind('common')({ initialValues, onSubmit: () => {}, validationSchema, enableReinitialize: true })
  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.title?.[lang], initialValues?.title?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })

    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const handleLangChange = useCallback(
    (lang: AvailableLanguages, on: boolean) => {
      const values = new Set(languages)
      if (on) {
        values.add(lang)
      } else {
        values.delete(lang)
      }
      setLanguages([...values])
    },
    [languages, setLanguages],
  )

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)
      onSubmit({
        status: forms.common.status,
        type: forms.params.type,
        producers: forms.params.producers,
        categories: forms.params.categories,
        title: createLocalizedValue(lang => langProp(lang, forms[lang]?.title) as string),
        description: createLocalizedValue(lang => langProp(lang, forms[lang]?.description) as string),
        message: {
          title: createLocalizedValue(lang => langProp(lang, forms[lang]?.messageTitle) as string),
          description: createLocalizedValue(lang => langProp(lang, forms[lang]?.messageDescription) as string),
        },
        styles: { ...forms.styles },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  useValidationErrorNotification(formik.submitCount, valid)

  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Styled.Wrapper>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.labels.params')}>
                  <ParamsForm
                    producers={initialValues.producers || []}
                    categories={initialValues.categories || []}
                    type={initialValues.type || PromoInfoType.CartDiscount}
                    useFormik={bind('params')}
                  />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.description')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang)}
                          value={lang}
                          onChange={handleLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <ContentForm
                            key={lang}
                            lang={lang}
                            title={initialValues.title}
                            description={initialValues.description}
                            messageTitle={initialValues.message?.title}
                            messageDescription={initialValues.message?.description}
                            useFormik={bind(lang as keyof FormikManagerData)}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.styles')}>
                  <StylesForm
                    startColor={initialValues.styles?.startColor || ''}
                    endColor={initialValues.styles?.endColor || ''}
                    primaryColor={initialValues.styles?.primaryColor || ''}
                    secondaryColor={initialValues.styles?.secondaryColor || ''}
                    primaryText={formik.values.title && languages.length ? formik.values?.title[languages[0]] : ''}
                    secondaryText={
                      formik.values.description && languages.length ? formik.values?.description[languages[0]] : ''
                    }
                    useFormik={bind('styles')}
                  />
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
          </Styled.Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterDefaultControls
          onRemove={onRemove}
          onSave={submit}
          onCancel={onCancel}
          popoverText={t('form.removeText', { title: initialValues.title })}
          saveProgress={progress}
          isSaveDisabled={!dirty || progress === Progress.WORK}
          removeProgress={removeProgress}
        />
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default PromoInfoDetailsForm
