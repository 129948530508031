import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import FarmerOrderSkuActions from './duck'
import FarmerOrderSkuSelectors from './selectors'
import { FarmerOrderSku } from 'types/farmerOrderSku'
import managers from './managers'
import FarmerOrderSkuRoutes from 'views/pages/FarmerOrderSku/routes'
import { ticker, updateLocationQuery } from 'modules/sagaHelpers'
import { push } from 'connected-react-router'
import { RequestError } from 'modules/errors'
import { FarmerOrderSkuListRequestFilter, FarmerOrderSkuListResponse } from 'modules/domain/farmerOrderSku/types'
import { isAgro, isDistributor, isProducer } from 'types/entities'
import { COUNTERS_FETCH_DELAY_MS } from 'modules/constants'
import * as uuid from 'uuid'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'
import { waitUntilFileLoaded } from '../uploadManager/sagas'
import { FileData, FileItem } from 'views/components/FileManager/types'
import { FarmerOrderStatus } from 'types/farmerOrder'
import { generatePath } from 'modules/utils/helpers/generatePath'
import AuthSelectors from '../auth/selectors'
import { CAPABILITY, isPermitted, PERMISSION } from 'modules/permissions/permissions'
import ModalActions from '../modal/duck'
import { AddRetailerOrderSuggestionContent } from 'views/components/AddRetailerOrderSuggestionContent/AddRetailerOrderSuggestionContent'

const prepareOrder = (order: FarmerOrderSku): FarmerOrderSku => {
  order.sku_items.forEach(item => (item.key = uuid.v4()))
  return order
}

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(FarmerOrderSkuSelectors.page)
    const filter = yield select(FarmerOrderSkuSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(FarmerOrderSkuActions.filtersUpdatedWithPersistentStorage(filterUpdated))

    const sorting = yield select(FarmerOrderSkuSelectors.sorting)
    const pageSize = yield select(FarmerOrderSkuSelectors.pageSize)
    let response: FarmerOrderSkuListResponse = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage || 1,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }
    const { data, page, total_count } = response
    yield put(
      FarmerOrderSkuActions.listRequestSucceed(
        data,
        total_count,
        response.meta.total_qty,
        response.meta.total_final_qty,
        response.meta.total_delivered_qty,
        response.meta.total_standard_qty,
        page,
      ),
    )

    if (currentPage) {
      yield call(updateLocationQuery, FarmerOrderSkuRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.listRequestFailed(errType))
  }
}

export const fetchListUpdate = function*() {
  try {
    const currentPage = yield select(FarmerOrderSkuSelectors.page)
    const filter = yield select(FarmerOrderSkuSelectors.filter)
    const sorting = yield select(FarmerOrderSkuSelectors.sorting)
    const pageSize = yield select(FarmerOrderSkuSelectors.pageSize)
    const response: FarmerOrderSkuListResponse = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage || 1,
      pageSize,
    )
    const {
      data,
      total_count,
      meta: { total_qty, total_final_qty, total_delivered_qty, total_standard_qty },
    } = response
    yield put(
      FarmerOrderSkuActions.listUpdateRequestSucceed(
        data,
        total_count,
        total_qty,
        total_final_qty,
        total_delivered_qty,
        total_standard_qty,
      ),
    )
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.listUpdateRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(FarmerOrderSkuSelectors.page)
    const filter = yield select(FarmerOrderSkuSelectors.filter)
    const sorting = yield select(FarmerOrderSkuSelectors.sorting)
    const pageSize = yield select(FarmerOrderSkuSelectors.pageSize)
    const { data, total_count }: { data: FarmerOrderSku[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(FarmerOrderSkuActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof FarmerOrderSkuActions.itemRequested>) {
  try {
    const order: FarmerOrderSku = yield call(managers.getItem, id)
    yield put(FarmerOrderSkuActions.itemRequestSucceed(prepareOrder(order)))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.itemRequestFailed(id, errType))
  }
}

const openAddRetailerOrderSuggest = function*({
  payload: [order, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderSkuActions.openAddRetailerOrderSuggestion>) {
  const oldOrder: FarmerOrderSku | undefined = yield select(FarmerOrderSkuSelectors.item, order.id)

  const role = yield select(AuthSelectors.role)

  const isChangedDistributorConfirmation =
    (!oldOrder || oldOrder?.interaction.confirmed_by_distributor !== 'confirmed') &&
    order.interaction?.confirmed_by_distributor === 'confirmed'
  const isModalShowPermitted = isPermitted(role, CAPABILITY.DISTRIBUTOR_ORDERS_SKU, PERMISSION.CR)

  const showModal = isChangedDistributorConfirmation && isAddRetailerOrderSuggestionEnabled && isModalShowPermitted
  if (showModal) {
    yield put(
      ModalActions.open({
        contentProps: {
          farmerOrder: order,
        },
        content: AddRetailerOrderSuggestionContent,
      }),
    )
  }
}

export const addItem = function*({
  payload: [dto, duplicate, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderSkuActions.addRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const contenders = filesMeta.filter(file => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const files = fileItems.filter(item => typeof item === 'string').map(url => ({ url })) as FileData[]
    const order: FarmerOrderSku = yield call(managers.addItem, { ...rest, files })
    const prepared = prepareOrder(order)
    yield put(FarmerOrderSkuActions.openAddRetailerOrderSuggestion(prepared, isAddRetailerOrderSuggestionEnabled))
    yield put(FarmerOrderSkuActions.addSucceed(prepared))
    if (!duplicate) {
      yield put(
        push(
          generatePath(FarmerOrderSkuRoutes.Edit, {
            id: order.id,
          }),
        ),
      )
      // yield put(push(generatePath(FarmerOrderSkuRoutes.Edit, { id: order.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.addFailed(errType))
  }
}
export const updateItem = function*({
  payload: [id, dto, duplicate, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderSkuActions.updateRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const contenders = filesMeta.filter((file: FileItem) => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const files = fileItems.filter(item => typeof item === 'string').map(url => ({ url })) as FileData[]
    const order: FarmerOrderSku = yield call(managers.updateItem, id, { ...rest, files })
    const prepared = prepareOrder(order)
    yield put(FarmerOrderSkuActions.openAddRetailerOrderSuggestion(prepared, isAddRetailerOrderSuggestionEnabled))
    yield put(FarmerOrderSkuActions.updateSucceed(prepared))
    if (duplicate) {
      yield put(push(FarmerOrderSkuRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof FarmerOrderSkuActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(FarmerOrderSkuActions.removeSucceed(payload))
    yield put(FarmerOrderSkuActions.resetRemoveProgress())

    const pagePath = `${generatePath(FarmerOrderSkuRoutes.List)}`
    yield put(push(pagePath))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderSkuActions.removeFailed(payload, errType))
  }
}

export const newOrdersCountFetcher = ticker(function*({ role }) {
  try {
    const params: FarmerOrderSkuListRequestFilter = {
      status: FarmerOrderStatus.New,
    }
    // only unapproved new orders qty for agroclub users
    if (isAgro(role)) {
      params.confirmed_by_agroclub = false
    } else if (isProducer(role)) {
      // only awaiting producer confirmation orders for producer users
      params.confirmed_by_agroclub = true
      params.confirmed_by_producer = false
    } else if (isDistributor(role)) {
      // only awaiting distributor confirmation orders for distributor users
      params.confirmed_by_agroclub = true
      params.confirmed_by_producer = true
      params.confirmed_by_distributor = 'unset' as const
    }
    const count = yield call(managers.ordersCount, params)
    yield put(FarmerOrderSkuActions.newOrdersCountRequestSucceed(count))
  } catch (err) {
    yield put(FarmerOrderSkuActions.newOrdersCountRequestError())
    const errType = err instanceof RequestError ? err.type : 'unknown'
    if (['forbidden_error', 'unauthorized_error'].includes(errType)) {
      return false
    }
  }
  return true
}, COUNTERS_FETCH_DELAY_MS)

const FarmerOrderSkuSaga = function*() {
  yield all([
    takeLatest(FarmerOrderSkuActions.itemRequested.type, fetchItem),
    takeLatest(FarmerOrderSkuActions.listRequested.type, fetchList),
    takeLatest(FarmerOrderSkuActions.filterUpdated.type, fetchList),
    takeLatest(FarmerOrderSkuActions.sortingUpdated.type, fetchList),
    takeLatest(FarmerOrderSkuActions.filterHasBeenReset.type, fetchList),
    takeLatest(FarmerOrderSkuActions.sortingHasBeenReset.type, fetchList),
    takeLatest(FarmerOrderSkuActions.openAddRetailerOrderSuggestion.type, openAddRetailerOrderSuggest),

    takeLatest(FarmerOrderSkuActions.listRequestedNext.type, fetchListNext),
    takeLatest(FarmerOrderSkuActions.listUpdateRequested.type, fetchListUpdate),

    takeLatest(FarmerOrderSkuActions.addRequested.type, addItem),
    takeLatest(FarmerOrderSkuActions.updateRequested.type, updateItem),
    takeLatest(FarmerOrderSkuActions.removeRequested.type, removeItem),

    // todo uncomment when farmer order (sku) section and menu item will be opened
    // https://agroclub.atlassian.net/browse/ENT-2340
    // fork(newOrdersCountFetcher),
  ])
}

export default FarmerOrderSkuSaga
