import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { octopusApiClient } from 'modules/utils/httpClient'
import {
  TargetListRequestFilter,
  TargetListRequestSorting,
  FarmerTarget,
  FarmerTargetCreateDTO,
  TargetUpdateDTO,
} from '../common/types'

export default createBasicManagers<
  TargetListRequestFilter,
  TargetListRequestSorting,
  FarmerTarget,
  FarmerTargetCreateDTO,
  TargetUpdateDTO
>(endpoints.farmerTarget, octopusApiClient)
