import React, { useCallback, useMemo } from 'react'
import * as companyManagers from 'modules/domain/company/managers'
import { CompanyType, ROLES } from 'types/entities'
import * as Styled from './styled'
import { Checkbox, CheckboxSelect, FormikHook, Label, Switch } from '@agro-club/frontend-shared'
import { arrToDict, makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import { Company } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { UsersSelect } from './UsersSelect'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

const useProducersOptions = makeCancellableResourceListHook(makeCancelable(companyManagers.getProducersList))
const useDistributorsOptions = makeCancellableResourceListHook(makeCancelable(companyManagers.getDistributorsList))

export type PermissionsFormProps = {
  roles: string
  company_ids: string[]
  user_ids: string[]
  producer_id: string
}

type CompaniesSelectProps = {
  company_ids: string[]
  onChange: (val: string[]) => void
  filter?: (company: Company) => boolean
}

const CompaniesSelect: React.FC<CompaniesSelectProps> = ({ company_ids, onChange, filter }) => {
  const { t } = useTranslation('report')
  const [, distributors = []] = useDistributorsOptions()
  const [, producers = []] = useProducersOptions()

  const companiesDict = useMemo(() => ({ ...arrToDict(distributors), ...arrToDict(producers) }), [
    distributors,
    producers,
  ])

  const [options, branches] = useMemo(() => {
    const branches: { [key: string]: string[] } = {}
    const notBranchesIds: string[] = []

    let ordered: Company[] = []
    for (const d of [...distributors, ...producers]) {
      if (d.head_company_relation) {
        if (branches[d.head_company_relation.company_id]) {
          branches[d.head_company_relation.company_id].push(d.id)
        } else {
          branches[d.head_company_relation.company_id] = [d.id]
        }
      } else {
        notBranchesIds.push(d.id)
      }
    }

    for (const id of notBranchesIds) {
      ordered.push(companiesDict[id])
      if (branches[id]) {
        ordered = ordered.concat(branches[id].map(id => companiesDict[id]))
      }
    }

    if (filter) {
      ordered = ordered.filter(filter)
    }

    const options = ordered.map(company => ({
      id: company.id,
      title: company.internal_name,
      nestedLevel: company.head_company_relation ? ('1' as const) : ('0' as const),
    }))
    return [options, branches]
  }, [distributors, producers, companiesDict, filter])

  const handleChange = useCallback(
    (id: string) => {
      const set = new Set(company_ids)
      set.has(id) ? set.delete(id) : set.add(id)

      if (companiesDict[id].has_branch_company && branches[id]?.length) {
        branches[id].forEach((branchId: string) => {
          if (set.has(id)) {
            set.add(branchId)
          } else {
            set.delete(branchId)
          }
        })
      }
      onChange([...set])
    },
    [branches, companiesDict, company_ids, onChange],
  )

  const handleClear = useCallback(() => {
    onChange([])
  }, [onChange])

  return (
    <CheckboxSelect
      options={options}
      selected={company_ids}
      onChange={handleChange}
      customStyles={Styled.customSelectStyles}
      label={t('form.labels.onlyCompanies')}
      onClear={handleClear}
      noneSelected={t('form.labels.noneSelected')}
      isSearchable
      singleSelected={company_ids.length === 1 ? companiesDict[company_ids[0]]?.internal_name : undefined}
    />
  )
}

const PermissionsForm: React.FC<{
  roles?: ROLES[]
  companies?: string[]
  users?: string[]
  is_private?: boolean
  producer_id?: string
  useFormik: FormikHook
}> = ({ roles = [], companies = [], is_private = false, users = [], producer_id, useFormik }) => {
  const { t, i18n } = useTranslation(['report', 'permission', 'role'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        producer_id: Yup.string().required(i18n.t('validation:field_required')),
      }),
    [i18n],
  )
  const formik = useFormik({
    initialValues: {
      roles,
      companies,
      users,
      is_private,
      producer_id,
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
  })

  const handleChange = useCallback(
    value => {
      let newValue: ROLES[]
      if (formik.values.roles.includes(value)) {
        newValue = formik.values.roles.filter(r => r !== value)
      } else {
        newValue = [...formik.values.roles, value]
      }

      formik.setFieldValue('roles', newValue)
    },
    [formik],
  )

  const handleProducerChange = useCallback(
    (id?: string) => {
      formik.setFieldValue('producer_id', id)
      formik.setFieldValue('companies', [])
      formik.setFieldValue('users', [])
    },
    [formik],
  )

  const rolesArr = useMemo(
    () => Object.values(ROLES).filter(item => !item.includes('farmer') && !item.includes('user')),
    [],
  )

  return (
    <Styled.Wrapper>
      <Label>{t('form.labels.roles')}</Label>
      <Styled.PermissionsGrid>
        {rolesArr.map((role, idx) => {
          const roleAndPermission = role?.split(':')

          return (
            <Checkbox
              key={idx}
              label={
                t(`role:${roleAndPermission[0]}`) +
                (roleAndPermission[1] ? ` ${t(`permission:${roleAndPermission[1]}`)}` : '')
              }
              value={role}
              isChecked={formik.values.roles.includes(role)}
              onChange={handleChange}
              testId={role}
            />
          )
        })}
      </Styled.PermissionsGrid>
      <Styled.SelectWrapper data-test-id={'producer-wrapper'} visible={true}>
        <CompanySelect
          label={t('form.labels.producer')}
          onChange={handleProducerChange}
          value={formik.values.producer_id}
          companyType={CompanyType.Producer}
          isClearable
          isSearchable
          invalid={formik.touched.producer_id && !!formik.errors.producer_id}
          errorText={formik.errors.producer_id}
          required
        />
      </Styled.SelectWrapper>
      <Switch
        on={formik.values.is_private}
        label={t('form.labels.isPrivate')}
        testId="is_privateSelect"
        onClick={val => formik.setFieldValue('is_private', val)}
      />
      <Styled.SelectWrapper visible={formik.values.is_private} data-test-id="allowCompanies">
        {formik.values.is_private && (
          <CompaniesSelect
            onChange={val => formik.setFieldValue('companies', val)}
            company_ids={formik.values.companies}
            filter={company =>
              !formik.values.producer_id
                ? true
                : !!company.producers_relations.includes(formik.values.producer_id || '')
            }
          />
        )}
      </Styled.SelectWrapper>
      <Styled.SelectWrapper visible={formik.values.is_private} data-test-id="allowUsers">
        <UsersSelect onChange={val => formik.setFieldValue('users', val)} values={formik.values.users} />
      </Styled.SelectWrapper>
    </Styled.Wrapper>
  )
}

export default PermissionsForm
