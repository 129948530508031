import {
  Actions,
  Buttons,
  Columns,
  FeatureFlagModifiers,
  FeatureFlagTypes,
  FieldLocation,
  FieldNames,
  Filters,
  Sections,
  Tables,
} from 'types/entities'

export const generateCrmTableColumnAccessString = (
  table: Tables,
  column: Columns,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.TableColumn}-${table}-${column}-${modifier}`
}

export const generateFieldModifierString = (
  location: FieldLocation,
  fieldName: FieldNames,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Field}-${location}-${fieldName}-${modifier}`
}

export const generateCustomFeatureFlag = (location: string, featureName: string) => {
  return `${FeatureFlagTypes.Custom}-${location}-${featureName}`
}

export const generateActionAccessString = (
  section: Sections,
  action: Actions,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Action}-${section}-${action}-${modifier}`
}

export const generateCrmFilterAccessString = (
  section: Sections,
  filter: Filters,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Filter}-${section}-${filter}-${modifier}`
}

export const generateCrmFieldAccessString = (
  section: string,
  fieldName: string,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Field}-${section}-${fieldName}-${modifier}`
}

export const generateCrmSectionAccessString = (section: Sections, modifier: FeatureFlagModifiers): string => {
  return `${FeatureFlagTypes.CrmSection}-${section}-${modifier}`
}

export const generateCrmButtonAccessString = (
  section: Sections,
  button: Buttons,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Button}-${section}-${button}-${modifier}`
}
