export enum ValidationError {
  FILE_TOO_LARGE = 1,
  MAX_WIDTH_EXCEEDED,
  MAX_HEIGHT_EXCEEDED,
  UNKNOWN,
}

export type ValidationResult = Promise<ValidationError | null>

export const imageFileValidator = async (
  file: File,
  maxWidth = 500,
  maxHeight = 500,
  maxFileSize = 3e6,
): Promise<ValidationError | null> => {
  const read = (): Promise<string> => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    return new Promise((resolve, reject) => {
      reader.onload = e => {
        resolve(e.target?.result as string)
      }
      reader.onerror = () => reject(new Error('File read error'))
      reader.onabort = () => reject(new Error('File read cancelled'))
    })
  }

  const validate = (src: string): ValidationResult => {
    const image = new Image()
    image.src = src

    return new Promise((resolve, reject) => {
      image.onload = () => {
        if (image.width > maxWidth) {
          resolve(ValidationError.MAX_WIDTH_EXCEEDED)
          return
        }
        if (image.height > maxHeight) {
          resolve(ValidationError.MAX_HEIGHT_EXCEEDED)
          return
        }
        resolve(null)
      }
      image.onerror = () => reject(new Error('Image read error'))
      image.onabort = () => reject(new Error('Image read cancelled'))
    })
  }

  try {
    if (file.size > maxFileSize) {
      return ValidationError.FILE_TOO_LARGE
    }
    const url = await read()
    return validate(url)
  } catch (err) {
    console.error(err)
    return ValidationError.UNKNOWN
  }
}
