import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  IncentiveProgram,
  IncentiveProgramListRequestSorting,
  IncentiveProgramListRequestFilter,
  IncentiveProgramDTO,
  SkuCalculateDiscountsRequest,
  SkuCalculateDiscountsResponse,
} from './types'

import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { Promocode } from '../promocode/types'

export default {
  ...createBasicManagers<
    IncentiveProgramListRequestFilter,
    IncentiveProgramListRequestSorting,
    IncentiveProgram,
    IncentiveProgramDTO,
    IncentiveProgramDTO
  >(endpoints.incentiveProgram),
  calculateDiscount: (dto: SkuCalculateDiscountsRequest) => {
    return apiClient.post<SkuCalculateDiscountsResponse>(endpoints.incentiveProgram('calculate'), dto)
  },
  checkPromocode: (params: { code: string; company_id: string; applied_promocodes?: string[] }) => {
    return apiClient.get<Promocode>(endpoints.incentiveProgram('promocode/find'), params)
  },
} as const
