import { StatusIcon } from '@agro-club/frontend-shared'
import styled from 'styled-components'

export const NameCell = styled.div`
  font-weight: 600;
  display: flex;
  align-items: flex-start;
`

export const Status = styled(StatusIcon)`
  margin-right: 8px;
  margin-left: 2px;
  margin-top: 6px;
`
