import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import ChangeLogSelectors from './selectors'
import ChangeLogActions from './duck'
import managers from './managers'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'

export const useChangeLogList = createItemListOnceHook(ChangeLogSelectors, ChangeLogActions.listRequested)
export const useChangeLogByIdOnce = createItemByIdOnceHook(ChangeLogSelectors, ChangeLogActions.itemRequested)
export const useChangeLogListPreventable = makeCancellableResourceListHook(makeCancelable(managers.getList), true)
