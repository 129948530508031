import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { handleApiErrors } from './handleApiErrors'
import { getAccessToken } from 'modules/domain/auth/repository'
import { stringify } from 'query-string'
import env from 'env'
import { Sentry } from 'sentry'
import i18n from 'i18n'
import { helpersDownload } from '@agro-club/frontend-shared'
import { refreshTokenAndRetry } from './refreshAndRetry'
import HttpStatuses from 'http-status-codes'
import { Severity } from '@sentry/types'
import { noop } from 'helpers/noop'

const sentryErrorStatuses = new Set([
  HttpStatuses.INTERNAL_SERVER_ERROR,
  HttpStatuses.NOT_IMPLEMENTED,
  HttpStatuses.BAD_GATEWAY,
])

const sentryWarningStatuses = new Set([HttpStatuses.BAD_REQUEST, HttpStatuses.CONFLICT])

axios.interceptors.request.use(
  config => {
    Sentry.handleAxiosRequest(config)
    return config
  },
  error => {
    Sentry.handleAxiosError(error, Severity.Error)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    Sentry.handleAxiosResponse(response)
    return response
  },
  error => {
    const status = error.response?.status
    if (error.response.config.url === '/phone_codes' || error.response.config.url === '/countries')
      Sentry.handleAxiosError(error, Severity.Error)
    if (sentryErrorStatuses.has(status)) Sentry.handleAxiosError(error, Severity.Error)
    if (sentryWarningStatuses.has(status)) Sentry.handleAxiosError(error, Severity.Warning)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status !== HttpStatuses.UNAUTHORIZED || error.response?.config.url?.includes('/auth/'))
      throw error
    return refreshTokenAndRetry(error)
  },
)

export type EmitterToken<T extends unknown> =
  | {
      type: 'progress'
      total: number
      loaded: number
      percent: number
    }
  | { type: 'success'; result: T }
  | { type: 'error'; error: AxiosError }

export type ParamType = string | number | boolean | undefined | null
export type UrlParams = { [index: string]: ParamType | ParamType[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = Record<string, any> | FormData
type RequestExtraOptions<T> = {
  sagaEmitter?: (token: EmitterToken<T>) => unknown
  multipart?: boolean
}
export type RequestOptions<T> = AxiosRequestConfig & RequestExtraOptions<T>
export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const formHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
}

const bodyToForm = (params: RequestBody): FormData => {
  return !(params instanceof FormData)
    ? Object.keys(params)
        .filter(key => Boolean(params[key]))
        .reduce((form, key) => {
          form.append(key, params[key])
          return form
        }, new FormData())
    : params
}

const cancelTokenMap = new WeakMap<Promise<unknown>, (message?: string) => void>()

export function performRequest<T extends unknown>(
  method: Method,
  lang: string,
  baseURL: string,
  url: string,
  params: UrlParams | null,
  _body: RequestBody | null,
  options: RequestOptions<T> = {},
  returnFullResponse = false,
): Promise<T> {
  const sagaEmitter = options.sagaEmitter ? options.sagaEmitter : noop
  const cancelToken = axios.CancelToken.source()
  const body = _body ? (options.multipart ? bodyToForm(_body) : _body) : undefined
  const headers = options.multipart ? formHeaders : defaultHeaders
  const token = getAccessToken()

  const request = axios({
    headers: {
      ...options.headers,
      ...headers,
      Authorization: token ? `Token ${token}` : '',
      'Accept-Language': lang,
    },
    method,
    url,
    params,
    baseURL,
    data: body,
    onUploadProgress: ({ total, loaded }) =>
      sagaEmitter({ type: 'progress', total, loaded, percent: Math.round((loaded * 100) / total) }),
    cancelToken: cancelToken.token,
    responseType: options.responseType || 'json',
    paramsSerializer: params => stringify(params, { skipNull: true, skipEmptyString: true }),
  })
    .then(res => {
      if (res.config.responseType === 'blob') {
        helpersDownload.downloadBlob(res.data, res.headers)
      }
      sagaEmitter({ type: 'success', result: res.data })

      return returnFullResponse ? res : res.data
    })
    .catch(err => {
      sagaEmitter({ type: 'error', error: err })
      return handleApiErrors(err)
    })

  cancelTokenMap.set(request, () => cancelToken.cancel())
  return request
}

export const cancel = (request?: Promise<unknown> | null) => {
  if (!request) {
    return
  }
  const entry = cancelTokenMap.get(request)
  if (entry) {
    entry('cancelled')
  }
}

export function makeCancelable<T extends (...args: any[]) => any>(
  manager: T,
): (...args: Parameters<T>) => [ReturnType<T>, () => void] {
  let promise: ReturnType<T>
  return (...args) => {
    promise = manager(...args)
    return [promise, () => cancel(promise)]
  }
}

export function makeHttpClient(envName: string) {
  const baseURL = env[envName]
  if (!baseURL && envName !== 'REACT_APP_OCTOPUS_API_BASE_URL') {
    console.error(`${envName} is missing`)
  }

  let lang = i18n.language || 'en'
  i18n.on('languageChanged', lng => {
    lang = lng
  })

  return {
    get<T>(path: string, params?: UrlParams, options?: RequestOptions<T>) {
      return performRequest<T>('get', lang, baseURL, path, params || null, null, options)
    },
    post<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('post', lang, baseURL, path, null, body || null, options)
    },
    put<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('put', lang, baseURL, path, null, body || null, options)
    },
    delete<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('delete', lang, baseURL, path, null, body || null, options)
    },
    patch<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('patch', lang, baseURL, path, null, body || null, options)
    },
    cancelRequest(req: Promise<unknown>) {
      const cancelHandler = cancelTokenMap.get(req)
      cancelHandler && cancelHandler()
    },
  }
}

export const axiosClient = (config: AxiosRequestConfig) => axios(config)
export const apiClient = makeHttpClient('REACT_APP_API_BASE_URL')
export const octopusApiClient = makeHttpClient('REACT_APP_OCTOPUS_API_BASE_URL')
export const authClient = makeHttpClient('REACT_APP_API_BASE_URL')

export default makeHttpClient
