import { endpoints } from 'modules/endpoints'
import {
  ProductSettings,
  ProductSettingsListRequestSorting,
  ProductSettingsListRequestFilter,
  ProductSettingsDTO,
} from 'modules/domain/productSettings/types'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'

export default {
  ...createBasicManagers<
    ProductSettingsListRequestFilter,
    ProductSettingsListRequestSorting,
    ProductSettings,
    ProductSettingsDTO,
    ProductSettingsDTO
  >(endpoints.cardsForProductSettings),
}
