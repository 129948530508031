import React, { memo, useCallback, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikHook, Input, TextArea } from '@agro-club/frontend-shared'
import * as Styled from './styled'

export type ContentFormProps = {
  title: string
  description: string

  messageTitle: string
  messageDescription: string
}

const ContentForm: React.FC<{
  lang: keyof LocalizedValue
  title?: LocalizedValue
  description?: LocalizedValue
  messageTitle?: LocalizedValue
  messageDescription?: LocalizedValue
  useFormik: FormikHook
}> = memo(({ lang, useFormik, title = {}, description = {}, messageTitle = {}, messageDescription = {} }) => {
  const { t } = useTranslation(['promoInfo', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().required(t('validation:field_required')),
        description: Yup.string(),
        messageTitle: Yup.string().required(t('validation:field_required')),
        messageDescription: Yup.string(),
      }),
    [t],
  )
  const formik = useFormik<ContentFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      title: title[lang] || '',
      description: description[lang] || '',
      messageTitle: messageTitle[lang] || '',
      messageDescription: messageDescription[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })

  const handleTitleChange = useCallback(
    e => {
      formik.setFieldValue('title', e.target.value)
    },
    [formik],
  )

  return (
    <Styled.VerticalInputsContainer>
      <Input
        {...formik.getFieldProps('title')}
        invalid={formik.touched.title && !!formik.errors.title}
        errorText={formik.errors.title}
        label={t('form.labels.title', { lang: `(${lang})` })}
        placeholder={t('form.placeholders.title')}
        onChange={handleTitleChange}
        data-lang={lang}
        required
      />
      <TextArea
        {...formik.getFieldProps('description')}
        invalid={formik.touched.description && !!formik.errors.description}
        errorText={formik.errors.description}
        label={t('form.labels.description', { lang: `(${lang})` })}
        placeholder={t('form.placeholders.description')}
        data-lang={lang}
      />
      <Input
        {...formik.getFieldProps('messageTitle')}
        invalid={formik.touched.messageTitle && !!formik.errors.messageTitle}
        errorText={formik.errors.messageTitle}
        label={t('form.labels.messageTitle', { lang: `(${lang})` })}
        placeholder={t('form.placeholders.messageTitle')}
        data-lang={lang}
        required
      />
      <TextArea
        {...formik.getFieldProps('messageDescription')}
        invalid={formik.touched.messageDescription && !!formik.errors.messageDescription}
        errorText={formik.errors.messageDescription}
        label={t('form.labels.messageDescription', { lang: `(${lang})` })}
        placeholder={t('form.placeholders.messageDescription')}
        data-lang={lang}
      />
    </Styled.VerticalInputsContainer>
  )
})
ContentForm.displayName = 'ContentForm'

export default ContentForm
