import { createDictFromArrayHandler } from 'helpers/createDictFromArrayHandler'
import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { FetchError, UpdateError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { FccOrderItem } from 'types/fccOrder'
import { Dict } from 'types/generics'
import {
  FccOrderListRequestFilter,
  FccOrderListRequestSorting,
  FccOrderState,
  FccOrdersUpdateResponse,
  FccOrderUpdateDTO,
} from './types'

const initialState: FccOrderState = {
  items: {},
  itemsForUpdate: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  listUpdateProgress: Progress.IDLE,
  listUpdateError: null,

  totalCost: '0.00',
  totalQty: 0,
  totalFinalQty: 0,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

const fccItemsArrayToDict = createDictFromArrayHandler<FccOrderItem>('id')

class FccOrderReducer extends ImmerReducer<FccOrderState> {
  listRequested(params: { filter?: FccOrderListRequestFilter; sorting?: FccOrderListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(
    list: FccOrderItem[],
    total: number,
    totalCost: string,
    totalQty: number,
    totalFinalQty: number,
    page: number,
  ) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.totalCost = totalCost
    this.draftState.totalQty = totalQty
    this.draftState.totalFinalQty = totalFinalQty
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }
  listUpdateRequested() {
    this.draftState.listUpdateProgress = Progress.WORK
    this.draftState.listUpdateError = null
  }
  listUpdateRequestSucceed(
    list: FccOrderItem[],
    total: number,
    totalCost: string,
    totalQty: number,
    totalFinalQty: number,
  ) {
    this.draftState.listUpdateProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.totalCost = totalCost
    this.draftState.totalQty = totalQty
    this.draftState.totalFinalQty = totalFinalQty
  }
  listUpdateRequestFailed(error: UpdateError) {
    this.draftState.listUpdateProgress = Progress.ERROR
    this.draftState.listUpdateError = error
  }

  updateItem(id: string, dto: Partial<FccOrderUpdateDTO>, _duplicate: boolean) {
    const item = this.draftState.items[id]
    if (item && ((dto.price && Number(dto.price) !== Number(item.price)) || !dto.price)) {
      this.draftState.itemsForUpdate[id] = { ...item, ...dto }
    }
  }

  updateItemsRequested(_dict: Dict<FccOrderItem>) {
    this.draftState.updateProgress = Progress.WORK
    Object.keys(this.state.itemsForUpdate).forEach(id => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.WORK
        this.draftState.meta[id].updateError = null
      }
    })
  }

  updateItemsSucceed(response: FccOrdersUpdateResponse) {
    this.draftState.items = { ...this.state.items, ...fccItemsArrayToDict(response.updated) }
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.itemsForUpdate = {}
    response.updated.forEach(({ id }) => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.SUCCESS
        this.draftState.meta[id].updateError = null
      }
    })
    if (response.not_updated.length) {
      this.draftState.updateProgress = Progress.ERROR
      this.draftState.itemsForUpdate = fccItemsArrayToDict(response.not_updated)
      response.not_updated.forEach(({ id }) => {
        if (this.draftState.meta[id]) {
          this.draftState.meta[id].updateProgress = Progress.ERROR
          this.draftState.meta[id].updateError = null
        }
      })
    }
  }

  updateItemsFailed(error: UpdateError) {
    this.draftState.updateProgress = Progress.ERROR
    Object.keys(this.state.itemsForUpdate).forEach(id => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.ERROR
        this.draftState.meta[id].updateError = error
      }
    })
  }

  filterUpdated(filter: FccOrderListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: FccOrderListRequestFilter) {
    this.draftState.filter = { ...this.draftState.filter, ...filter }
  }

  sortingUpdated(sorting: FccOrderListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: FccOrderItem[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  clearItemsForUpdate() {
    this.draftState.itemsForUpdate = {}
  }
}

export const FccFarmerOrderActions = createActionCreators(FccOrderReducer)
export default FccFarmerOrderActions
export const reducer = createReducerFunction(FccOrderReducer, initialState)
