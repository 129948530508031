import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { Territory, TerritoryDTO, TerritoryListRequestFilter, TerritoryListRequestSorting } from './types'

export default createBasicManagers<
  TerritoryListRequestFilter,
  TerritoryListRequestSorting,
  Territory,
  TerritoryDTO,
  TerritoryDTO
>(endpoints.territory)
