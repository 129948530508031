import React from 'react'
import { useSelector } from 'react-redux'
import PackageTypesSelectors from 'modules/domain/packageTypes/selectors'
import PackageTypesActions from 'modules/domain/packageTypes/duck'
import * as Header from 'views/ui/Header/Header'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import StorefrontPackageTypesDetailsForm from 'views/pages/Storefront/StorefrontPackageTypes/StorefrontPackageTypesDetailsForm'
import { useTranslation } from 'react-i18next'
import StorefrontRoutes from '../routes'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'
import { PackageTypesDTO } from 'modules/domain/packageTypes/types'

const StorefrontPackageTypesAdd: React.FC = () => {
  const { t } = useTranslation('packageTypes')
  const push = useHistoryPush()
  const addAction = useAction(PackageTypesActions.addRequested)
  const addProgress = useSelector(PackageTypesSelectors.addProgress)
  const page = useSelector(PackageTypesSelectors.page)

  const handleSubmit = (form: PackageTypesDTO) => {
    addAction(form)
  }

  const goBack = () => {
    push({ route: StorefrontRoutes.PackageTypesList, query: { page } })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontPackageTypesDetailsForm
          progress={addProgress}
          mode={'create'}
          onSubmit={handleSubmit}
          onCancel={goBack}
        />
      </Layout.Content>
    </>
  )
}

export default StorefrontPackageTypesAdd
