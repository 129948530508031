import React from 'react'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { ProductsAvailabilityDetailsForm, ProductsAvailabilityFormFields } from './ProductsAvailabilityDetailsForm'
import ProductsAvailabilitySelectors from 'modules/domain/productsAvailability/selectors'
import ProductsAvailabilityActions from 'modules/domain/productsAvailability/duck'
import ProductsAvailabilityRoutes from './routes'

const ProductsAvailabilityAdd: React.FC = () => {
  const { t } = useTranslation('productsAvailability')
  const submitAction = useAction(ProductsAvailabilityActions.addRequested)
  const addProgress = useSelector(ProductsAvailabilitySelectors.addProgress)

  const handleFormSubmit = (props: ProductsAvailabilityFormFields) => {
    submitAction(props)
  }

  const page = useSelector(ProductsAvailabilitySelectors.page)
  const push = useHistoryPush()
  const goBack = () => push({ query: { page }, route: ProductsAvailabilityRoutes.List })

  useHelmet({ title: t('addMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductsAvailabilityDetailsForm
          progress={addProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          initialValues={{
            is_available: true,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ProductsAvailabilityAdd
