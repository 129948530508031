import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import {
  BranchCompany,
  Company,
  CompanyActionData,
  CompanyConfigDTO,
  CompanyListRequestFilter,
  CompanyListRequestSorting,
  CompanyRelation,
  CompanyState,
} from 'modules/domain/company/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddConfigError, AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'

export const initialState: CompanyState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  addConfigProgress: Progress.IDLE,
  addConfigError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  branchCompanies: {},
}

const mapBranchCompaniesToPermissions = (companies: BranchCompany[]): CompanyRelation[] => {
  return companies.map(company => {
    return {
      company_id: company.id,
      ...(company.head_company_relation
        ? {
            is_allowed_order_create: company.head_company_relation.is_allowed_order_create,
            is_allowed_order_edit: company.head_company_relation.is_allowed_order_edit,
            is_allowed_distributor_order_create: company.head_company_relation.is_allowed_distributor_order_create,
            is_allowed_distributor_order_edit: company.head_company_relation.is_allowed_distributor_order_edit,
          }
        : {}),
    }
  })
}

class CompanyReducer extends ImmerReducer<CompanyState> {
  listRequested(params: {
    filter?: CompanyListRequestFilter
    sorting?: CompanyListRequestSorting
    page?: number
    pageSize?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
    this.draftState.pageSize = typeof params.pageSize === 'undefined' ? this.draftState.pageSize : params.pageSize
  }
  listRequestSucceed(list: Company[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(company: Company) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[company.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[company.id].fetchError = null
    this.draftState.items[company.id] = company
  }
  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  branchCompaniesRequested(id: string) {
    this.draftState.branchCompanies[id] = {
      fetchProgress: Progress.WORK,
      fetchError: null,
      companies: [],
      permissions: [],
      ids: [],
    }
  }

  branchCompaniesRequestSucceed(id: string, companies: BranchCompany[]) {
    this.draftState.branchCompanies[id].companies = companies
    this.draftState.branchCompanies[id].permissions = mapBranchCompaniesToPermissions(companies)
    this.draftState.branchCompanies[id].fetchProgress = Progress.SUCCESS
    this.draftState.branchCompanies[id].ids = getIds(companies)
  }
  branchCompaniesRequestFailed(id: string, error: FetchError) {
    this.draftState.branchCompanies[id].fetchProgress = Progress.ERROR
    this.draftState.branchCompanies[id].fetchError = error
  }

  addRequested(_params: { dto: CompanyActionData; branches?: CompanyRelation[] }) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
  }

  addSucceed(company: Company) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[company.id] = company
    this.draftState.meta[company.id] = {
      id: company.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }
  addFailed(error: AddError) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
  }

  addConfigRequested(_params: { dto: CompanyConfigDTO }) {
    this.draftState.addConfigProgress = Progress.WORK
    this.draftState.addConfigError = null
  }
  addConfigSucceed(config: CompanyConfigDTO) {
    this.draftState.addConfigProgress = Progress.SUCCESS
    this.draftState.items[config.company_id].config = config
  }
  addConfigFailed(error: AddConfigError) {
    this.draftState.addConfigProgress = Progress.ERROR
    this.draftState.addConfigError = error
  }

  updateRequested(params: { id: string; dto: Partial<CompanyActionData>; branches?: CompanyRelation[] }) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[params.id].updateProgress = Progress.WORK
    this.draftState.meta[params.id].updateError = null
  }

  updateSucceed(company: Company) {
    this.draftState.items[company.id] = company
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[company.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[company.id].updateError = null
  }
  updateFailed(id: string, error: UpdateError) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
  }

  filterUpdated(filter: CompanyListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: CompanyListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: Company[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.listFetchError = null
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
  }
  resetRemoveProgress() {
    this.draftState.removeProgress = Progress.IDLE
  }

  updateConfigRequested(params: { dto: CompanyConfigDTO; config_id: string }) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[params.dto.company_id].updateProgress = Progress.WORK
    this.draftState.meta[params.dto.company_id].updateError = null
  }
  updateConfigSucceed(config: CompanyConfigDTO) {
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.items[config.company_id].config = config
    this.draftState.meta[config.company_id].updateProgress = Progress.SUCCESS
    this.draftState.meta[config.company_id].updateError = null
  }
  updateConfigFailed(id: string, error: UpdateError) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
  }
}

export const CompanyActions = createActionCreators(CompanyReducer)
export default CompanyActions
export const reducer = createReducerFunction(CompanyReducer, initialState)
