import React, { useEffect, useMemo, useRef } from 'react'
import { DocumentItemStatus } from 'modules/domain/document/types'
import useComposedResourceHooks from 'hooks/useComposedResourceHooks'
import { useDocument, useUserDocuments } from 'modules/domain/document/hooks'
import { useSelector } from 'react-redux'
import DocumentSelectors from 'modules/domain/document/selectors'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import * as Styled from './styled'
import { DocumentStatus } from 'views/ui/DocumentStatus/DocumentStatus'
import { useRole } from 'modules/permissions/permissions'
import { isAgro, isDistributor } from 'types/entities'
import { Spinner, Spinnerable, Tooltip } from '@agro-club/frontend-shared'

const ListItem: React.FC<{ selected?: boolean }> = ({ selected, children, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  return (
    <Styled.ListItemStyled data-selected={selected} ref={ref} {...rest}>
      {children}
    </Styled.ListItemStyled>
  )
}

const FarmerDocumentItem: React.FC<{
  documentId: string
  onUploadClick?: (documentId: string, documentStatus: DocumentItemStatus, userId?: string) => void
  onPreviewClick?: (documentId: string, documentStatus: DocumentItemStatus, userId?: string) => void
  onDownloadClick?: (docId: string, documentStatus: DocumentItemStatus, fileName?: string) => void
  onPrintClick?: (docId: string, documentStatus: DocumentItemStatus) => void
  onInfoClick?: (docId: string) => void
  onSendEmailClick?: (docId: string) => void
  userId?: string
  selected?: boolean
}> = ({
  documentId,
  onUploadClick,
  onDownloadClick,
  onPrintClick,
  onSendEmailClick,
  onPreviewClick,
  onInfoClick,
  userId,
  selected,
}) => {
  const currentUserRole = useRole()
  const [progress, doc, userDocs] = useComposedResourceHooks([useDocument, [documentId]], [useUserDocuments, [userId]])
  const foundDoc = userDocs?.find(item => item.id === doc?.id)
  const meta = useSelector(state => DocumentSelectors.meta(state, documentId, userId))
  const { t } = useTranslation(['farmerOrder', 'document'])
  const statusTexts = useMemo<Record<NonNullable<DocumentItemStatus>, string>>(
    () => ({
      need_sign: t('document:status.need_sign'),
      signed: t('document:status.signed'),
      envelope_created: t('document:status.envelope_created'),
      processing: t('document:status.processing'),
      not_send: t('document:status.not_send'),
    }),
    [t],
  )

  if (progress !== Progress.SUCCESS || !doc) {
    return (
      <ListItem selected={selected}>
        <div style={{ flexGrow: 0 }}>
          <Spinner size={'small'} />
        </div>
      </ListItem>
    )
  }

  const statusText = foundDoc?.status ? statusTexts[foundDoc.status] : userId ? statusTexts.not_send : ''
  const status: DocumentItemStatus = foundDoc?.status ? foundDoc.status : userId ? 'not_send' : null

  const handlePrintClick = () => {
    onPrintClick?.(documentId, foundDoc?.status || null)
  }
  const handleDownloadClick = () => {
    onDownloadClick?.(documentId, foundDoc?.status || null, doc.name)
  }
  const handlePreviewClick = () => {
    onPreviewClick?.(documentId, foundDoc?.status || null, foundDoc?.status === 'signed' ? userId : undefined)
  }

  const handleUploadClick = () => {
    onUploadClick?.(documentId, foundDoc?.status || null, userId)
  }

  const handleSendEmailClick = () => {
    onSendEmailClick?.(documentId)
  }

  const handleInfoClick = () => {
    onInfoClick?.(documentId)
  }

  return (
    <ListItem selected={selected} data-test-id={`farmer-document-${documentId}`}>
      <Styled.DocumentTitle>{doc.name}</Styled.DocumentTitle>
      {foundDoc && (!foundDoc.status || foundDoc.status !== 'signed') ? (
        <Tooltip
          visibleOnHover={true}
          temporary={true}
          tooltipContent={<Styled.TooltipContent>{t('document:actions.sendSignDocument')}</Styled.TooltipContent>}
        >
          <Spinnerable progress={meta.signRequestProgress} size={'small'}>
            <Styled.IconSendEmailStyled onClick={handleSendEmailClick} />
          </Spinnerable>
        </Tooltip>
      ) : null}
      {onUploadClick && isAgro(currentUserRole) && foundDoc?.status !== 'signed' ? (
        <Tooltip
          visibleOnHover={true}
          temporary={true}
          tooltipContent={<Styled.TooltipContent>{t('document:actions.uploadDocument')}</Styled.TooltipContent>}
        >
          <Styled.IconUploadStyled onClick={handleUploadClick} />
        </Tooltip>
      ) : null}
      {onPrintClick ? (
        <Tooltip
          visibleOnHover={true}
          temporary={true}
          tooltipContent={<Styled.TooltipContent>{t('common:print')}</Styled.TooltipContent>}
        >
          <Spinnerable progress={meta.printProgress} size={'small'}>
            <Styled.IconPrintStyled onClick={handlePrintClick} />
          </Spinnerable>
        </Tooltip>
      ) : null}
      {onDownloadClick ? (
        <Tooltip visibleOnHover={true} tooltipContent={t('common:download')} temporary={true}>
          <Spinnerable progress={meta.downloadProgress} size={'small'}>
            <Styled.IconDownloadStyled onClick={handleDownloadClick} />
          </Spinnerable>
        </Tooltip>
      ) : null}
      {onPreviewClick ? (
        <Tooltip
          visibleOnHover={true}
          tooltipContent={<Styled.TooltipContent>{t('common:preview')}</Styled.TooltipContent>}
          temporary={true}
        >
          <Styled.IconDocumentStyled onClick={handlePreviewClick} />
        </Tooltip>
      ) : null}

      {onInfoClick && isDistributor(currentUserRole) ? (
        <Tooltip
          visibleOnHover={true}
          tooltipContent={<Styled.TooltipContent>{t('common:instruction')}</Styled.TooltipContent>}
          temporary={true}
        >
          <Styled.IconQuestionStyled onClick={handleInfoClick} />
        </Tooltip>
      ) : null}

      {userId && status ? (
        <DocumentStatus data-test-id={'document-status'} status={status}>
          {statusText}
        </DocumentStatus>
      ) : null}
    </ListItem>
  )
}

export default FarmerDocumentItem
