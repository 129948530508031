import { IconBin, PopoverWithBtns, useAction } from '@agro-club/frontend-shared'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import RetailerAllocationSelectors from 'modules/domain/allocation/retailerAllocation/selectors'
import { useRetailerAllocationList } from 'modules/domain/allocation/retailerAllocation/hooks'
import { Allocation } from 'modules/domain/allocation/common/types'
import styled from 'styled-components'
import { AllocationForm } from '../Allocation/AllocationForm'
import { Territory } from 'modules/domain/territory/types'

const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
`

export const RemoveBtn = styled(IconBin)`
  fill: ${props => props.theme.color.secondary300};
  cursor: pointer;
  transition: 0.3s fill;

  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`

const TrashBinCell: React.FC<CellProps<Allocation, string>> = ({ value }) => {
  const { t } = useTranslation(['allocation'])
  const removeAllocation = useAction(RetailerAllocationActions.removeRequested)
  return (
    <CenteredCell onClick={e => e.stopPropagation()}>
      <PopoverWithBtns onSubmit={() => removeAllocation(value)} text={t('removeText')}>
        <RemoveBtn />
      </PopoverWithBtns>
    </CenteredCell>
  )
}

const UnallocatedQtyCell: React.FC<CellProps<Allocation, string>> = ({ row }) => {
  if (!row.original.allocated_quantity || !row.values.territory_limit) return null

  return (
    <CenteredCell onClick={e => e.stopPropagation()}>
      {row.values.territory_limit - row.original.allocated_quantity}
    </CenteredCell>
  )
}

const TerritoriesCell: React.FC<CellProps<Allocation, Territory[]>> = ({ cell }) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      {cell.value?.map(item => {
        return <div key={item.id}>{item.title}</div>
      })}
    </div>
  )
}

const AllocationRetailerTable = () => {
  const { t } = useTranslation('allocation')
  const [progress, data = []] = useRetailerAllocationList()

  const total = useSelector(RetailerAllocationSelectors.total)
  const page = useSelector(RetailerAllocationSelectors.page)
  const pages = useSelector(RetailerAllocationSelectors.pages)
  const pageSize = useSelector(RetailerAllocationSelectors.pageSize)

  const listRequested = useAction(RetailerAllocationActions.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer.internal_name',
        width: 130,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'distributor.territories',
        width: 'auto',
        Cell: TerritoriesCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor.internal_name',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'product.category.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'product.subcategory.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product.title',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.productQty'),
        accessor: 'quantity',
        width: '100px',
      },
      {
        Header: t('list.tableHeaders.unallocatedQty'),
        width: 'auto',
        Cell: UnallocatedQtyCell,
      },
      {
        Header: t('list.tableHeaders.orderedQty'),
        accessor: 'ordered_quantity',
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.territoryLimit'),
        accessor: 'territory_limit',
        width: 'auto',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: TrashBinCell,
        width: 100,
      },
    ],
    [t],
  )

  return (
    <CompoundedTableEditable
      FormComponent={AllocationForm}
      columns={columns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={fetchNextItems}
      pages={pages}
      newTitle={t('list.tableButtons.new')}
    />
  )
}

export default AllocationRetailerTable
