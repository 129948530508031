import React, { useMemo } from 'react'
import { useDocumentList } from 'modules/domain/document/hooks'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormikContext } from 'formik'
import { OriginFormProps } from 'views/pages/Product/ProductDetailsForm/OriginForm'
import styled from 'styled-components'
import { FormikHook, SimpleSelect } from '@agro-club/frontend-shared'

export type RequiredDocumentsFormProps = {
  docusignDocumentId?: string
}

const Wrapper = styled.div`
  width: 320px;
`

const useDocuments = () => {
  const [progress, rawList = []] = useDocumentList()
  const list = useMemo(() => rawList.map(item => ({ id: item.id, title: item.name })), [rawList])
  return [progress, list] as const
}

const DocumentSelect: React.FC = () => {
  const { t } = useTranslation('product')
  const formik = useFormikContext<OriginFormProps>()
  const [progress, list] = useDocuments()

  return (
    <div data-test-id={'document-select'}>
      <SimpleSelect
        menuPlacement={'top'}
        value={formik.values.docusignDocumentId}
        progress={progress}
        options={list}
        onChange={v => {
          formik.setFieldValue('docusignDocumentId', v)
        }}
        label={t('form.document')}
        isSearchable={false}
      />
    </div>
  )
}

const RequiredDocumentsForm: React.FC<{
  useFormik: FormikHook
  docusignDocumentId?: string
}> = ({ useFormik, docusignDocumentId }) => {
  const formik = useFormik<RequiredDocumentsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      docusignDocumentId: docusignDocumentId,
    },
    enableReinitialize: true,
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <DocumentSelect />
      </Wrapper>
    </FormikProvider>
  )
}

export default RequiredDocumentsForm
