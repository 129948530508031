import React, { useMemo, useState } from 'react'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { FieldInputProps, useFormik } from 'formik'
import { AvailableLanguages, availableLanguagesList, CompanyType, LocalizedValue } from 'types/entities'
import * as Yup from 'yup'
import styled from 'styled-components'
import {
  Checkbox,
  Chip,
  Input,
  TextArea,
  SectionContainer,
  SectionBody,
  Switch,
  SwitchButton,
  FormComponents,
} from '@agro-club/frontend-shared'
import { Promocode, PromocodeType, PromocodeWithComment, PromocodeWithLegalText } from 'modules/domain/promocode/types'
import i18n from 'i18n'
import { FieldMetaProps } from 'formik/dist/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { createLocalizedValue } from 'helpers/localization'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

const usePromocodeTypesList = () => {
  const { t } = useTranslation('promocode')
  return useMemo(
    () => [
      {
        id: PromocodeType.Simple,
        title: t('type.simple'),
      },
      {
        id: PromocodeType.WithComment,
        title: t('type.withComment'),
      },
      {
        id: PromocodeType.WithLegalText,
        title: t('type.withLegalText'),
      },
    ],
    [t],
  )
}

const BlockFormSection = styled(FormComponents.FormSection)`
  max-width: 300px;
  margin-bottom: 16px;
`

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`

const LanguagesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: max-content max-content max-content max-content;
  align-items: center;
  margin-top: 16px;
`

const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`

type TextBlockProps = {
  detectLang: (lang: AvailableLanguages) => boolean
  resetLang: (lang: AvailableLanguages) => void
  getFieldProps: (lang: AvailableLanguages) => FieldInputProps<string>
  getFieldMeta: (lang: AvailableLanguages) => FieldMetaProps<string>
  setFieldTouched: (lang: AvailableLanguages) => void
  getTextareaLabel: (lang: AvailableLanguages) => string
}

const TextBlock: React.FC<TextBlockProps> = ({
  detectLang,
  resetLang,
  getFieldProps,
  getTextareaLabel,
  setFieldTouched,
  getFieldMeta,
}) => {
  const { t } = useTranslation(['promocode', 'common'])
  const [languages, setLanguages] = useState(() => {
    const langs = availableLanguagesList.filter(item => detectLang(item))
    if (!langs.length) {
      return ['en']
    }

    return langs
  })

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
      resetLang(lang)
    }
    setLanguages([...values])
  }
  return (
    <React.Fragment>
      <LanguagesBlock>
        {availableLanguagesList.map(lang => (
          <Checkbox
            label={t(`languages.${lang}.label`)}
            isChecked={isLangActive(lang)}
            value={lang}
            key={lang}
            onChange={handleLangChange}
          />
        ))}
      </LanguagesBlock>
      <VerticalFormsContainer>
        {availableLanguagesList.map(item => {
          if (isLangActive(item)) {
            return (
              <TextArea
                {...getFieldProps(item)}
                key={item}
                value={getFieldProps(item).value || undefined}
                errorText={getFieldMeta(item).error}
                invalid={getFieldMeta(item).touched && !!getFieldMeta(item).error}
                label={getTextareaLabel(item)}
                onBlur={() => setFieldTouched(item)}
                data-test-id={`textarea-${item}`}
                required={item === 'en'}
              />
            )
          }

          return null
        })}
      </VerticalFormsContainer>
    </React.Fragment>
  )
}

export type FormProps = Pick<Promocode, 'company_id' | 'code' | 'is_stackable' | 'has_discount' | 'is_active'> & {
  params: {
    type: PromocodeType
    legal_text_i18n?: LocalizedValue | null
    prompt_i18n?: LocalizedValue | null
  }
  legal_text_i18n?: LocalizedValue | null
  prompt_i18n?: LocalizedValue | null
}

type PromocodeDetailsFormProps = {
  id?: string
  onSubmit(form: FormProps): void
  onCancel(): void
  onRemove?: () => void
  initialValues?: FormProps
  progress: Progress
  removeProgress?: Progress
  editing?: boolean
  errorText?: string | null
}

const PromocodeDetailsForm: React.FC<PromocodeDetailsFormProps> = ({
  onSubmit,
  onCancel,
  onRemove,
  initialValues,
  progress,
  editing,
  removeProgress,
  errorText,
}) => {
  const { t } = useTranslation(['promocode', 'common'])
  const [type, setType] = useState(PromocodeType.Simple)
  const promocodeTypes = usePromocodeTypesList()
  const validationSchema = useMemo(() => {
    const typeRelatedParams: { prompt_i18n?: any; legal_text_i18n?: any } = {}
    if (type === PromocodeType.WithComment) {
      typeRelatedParams.prompt_i18n = Yup.object({
        en: Yup.string().required(i18n.t('validation:field_required')),
      }).required()
    } else if (type === PromocodeType.WithLegalText) {
      typeRelatedParams.legal_text_i18n = Yup.object({
        en: Yup.string().required(i18n.t('validation:field_required')),
      }).required()
    }
    return Yup.object({
      code: Yup.string().required(i18n.t('validation:field_required')),
      company_id: Yup.string().required(i18n.t('validation:field_required')),
      params: Yup.object({
        type: Yup.string().oneOf(['simple', 'with_comment_and_prompt', 'with_legal_text']),
        ...typeRelatedParams,
      }),
    })
  }, [type])

  const handleSubmit = async (formProps: Partial<FormProps>) => {
    if (!formik.isValid) {
      return
    }
    const data = {
      code: formProps.code,
      is_stackable: formProps.is_stackable,
      is_active: formProps.is_active,
      company_id: formProps.company_id,
      params: formProps.params,
    }
    onSubmit(data as FormProps)
  }

  const initial = useMemo(
    () =>
      initialValues || {
        code: '',
        is_stackable: true,
        is_active: true,
        company_id: undefined,
        params: {
          type: PromocodeType.Simple,
          prompt_i18n: createLocalizedValue(() => ''),
          legal_text_i18n: createLocalizedValue(() => ''),
        },
      },
    [initialValues],
  )

  const formik = useFormik<Partial<FormProps>>({
    initialValues: initial,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const isEditWarning = progress === Progress.ERROR && errorText

  let content: React.ReactNode = null
  switch (formik.values.params?.type) {
    case 'with_comment_and_prompt':
      const paramsWithComment = formik.values.params as PromocodeWithComment['params']
      content = (
        <TextBlock
          detectLang={lang => !!paramsWithComment.prompt_i18n && !!paramsWithComment.prompt_i18n[lang]}
          resetLang={lang => formik.setFieldValue(`prompt_i18n.${lang}`, undefined)}
          getFieldProps={lang => formik.getFieldProps(`params.prompt_i18n.${lang}`)}
          getTextareaLabel={lang => t(`languages.${lang}.placeholder`)}
          setFieldTouched={lang => formik.setFieldTouched(`params.prompt_i18n.${lang}`)}
          getFieldMeta={lang => formik.getFieldMeta(`params.prompt_i18n.${lang}`)}
          key={'comment'}
        />
      )
      break
    case 'with_legal_text':
      const paramsWithLegalText = formik.values.params as PromocodeWithLegalText['params']
      content = (
        <TextBlock
          detectLang={lang => !!paramsWithLegalText.legal_text_i18n && !!paramsWithLegalText.legal_text_i18n[lang]}
          resetLang={lang => formik.setFieldValue(`legal_text_i18n.${lang}`, undefined)}
          getFieldProps={lang => formik.getFieldProps(`params.legal_text_i18n.${lang}`)}
          getTextareaLabel={lang => t(`languages.${lang}.legalText`)}
          setFieldTouched={lang => formik.setFieldTouched(`params.legal_text_i18n.${lang}`)}
          getFieldMeta={lang => formik.getFieldMeta(`params.prompt_i18n.${lang}`)}
          key={'legalText'}
        />
      )
      break
    default:
      content = null
  }

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Wrapper>
              <BlockFormSection title={t('form.company')}>
                <CompanySelect
                  name={'producer_id'}
                  label={t('form.producer')}
                  onChange={value => formik.setFieldValue('company_id', value)}
                  value={formik.values.company_id}
                  companyType={CompanyType.Producer}
                  isClearable
                  isSearchable
                  invalid={formik.touched.company_id && !!formik.errors.company_id}
                  errorText={formik.errors.company_id}
                  required
                  isDisabled={editing}
                  onMenuClose={() => {
                    formik.setFieldTouched('company_id')
                  }}
                />
              </BlockFormSection>
              <BlockFormSection title={t('form.promocode')}>
                <Input
                  {...formik.getFieldProps('code')}
                  label={t('form.code')}
                  errorText={formik.errors.code}
                  invalid={formik.touched.code && !!formik.errors.code}
                  data-test-id={'code-input'}
                  required
                />
                <Switch
                  on={!!formik.values.is_stackable}
                  label={t('fields.isStackable')}
                  onClick={val => formik.setFieldValue('is_stackable', val)}
                />
                <Switch
                  on={!!formik.values.is_active}
                  label={t('fields.isActive')}
                  onClick={val => formik.setFieldValue('is_active', val)}
                />
                {initialValues?.has_discount && (
                  <Chip active={true} color={'orange'}>
                    {t('fields.hasDiscount')}
                  </Chip>
                )}
              </BlockFormSection>
              <SwitchButton
                options={promocodeTypes}
                value={formik.values.params?.type}
                onChange={(value: PromocodeType) => {
                  formik.setFieldValue('params.type', value)
                  setType(value)
                }}
              />
              {content}
            </Wrapper>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterDefaultControls
        onRemove={onRemove}
        onSave={formik.submitForm}
        onCancel={onCancel}
        saveProgress={progress}
        isSaveDisabled={!formik.dirty || progress === Progress.WORK}
        removeProgress={removeProgress}
        popoverText={t('form.removeText')}
      />
      {isEditWarning ? (
        <StickyFooterLayout.NotificationWarning>
          <div>{errorText}</div>
        </StickyFooterLayout.NotificationWarning>
      ) : null}
    </StickyFooterLayout.Wrapper>
  )
}

export default PromocodeDetailsForm
