import React from 'react'

import { Switch } from 'react-router-dom'
import Route from 'views/components/Route/Route'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import ReconciliationListPage from './ReconciliationListPage'
import ReconciliationGroupsPage from './ReconciliationGroupsPage'
import { REACT_APP_OCTOPUS_API_BASE_URL } from 'env'

const ReconciliationPage: React.FC = () => {
  if (REACT_APP_OCTOPUS_API_BASE_URL.length) {
    return (
      <Switch>
        <Route path={ReconciliationRoutes.Reconciliation}>
          <ReconciliationListPage />
        </Route>
        <Route path={ReconciliationRoutes.Groups}>
          <ReconciliationGroupsPage />
        </Route>
      </Switch>
    )
  }
  return (
    <>
      Sorry, we can not find the Octopus Base URL. If you want to see Reconciliation Page, you need to add the URL to
      ENV.
    </>
  )
}

export default ReconciliationPage
