import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Farmer, FarmerData } from 'modules/domain/farmer/types'
import { EntityMetadata } from 'modules/domain/types'

const farmersDict = (state: AppGlobalState) => state.farmer.items
const ids = (state: AppGlobalState) => state.farmer.ids
const farmer = (state: AppGlobalState, id: string): Farmer | undefined => state.farmer.items[id]
const farmerByPhone = (state: AppGlobalState, phone: string): FarmerData | undefined =>
  Object.values(state.farmer.items).find(
    (farmer: Farmer) => farmer.phone_number === phone || farmer.additional_phone === phone,
  )
const meta = (state: AppGlobalState, id: string): EntityMetadata<Farmer> =>
  state.farmer.meta[id] ||
  state.farmer.meta[
    Object.values(state.farmer.items).find(farmer => farmer.phone_number === id || farmer.additional_phone === id)
      ?.id || ''
  ] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const getMetaByPhone = (state: AppGlobalState, phone: string) => {
  const metadata = meta(state, phone)
  if (metadata) {
    return metadata
  }
  const farmerData = farmerByPhone(state, phone)
  if (farmerData) {
    return meta(state, farmerData.id)
  }

  return {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id: phone,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
}

const itemFetchProgress = (state: AppGlobalState) => state.farmer.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.farmer.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.farmer.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.farmer.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.farmer.listFetchError
const farmerList = createSelector(farmersDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Farmer[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.farmer.filter
const sorting = (state: AppGlobalState) => state.farmer.sorting
const page = (state: AppGlobalState) => state.farmer.page
const total = (state: AppGlobalState) => state.farmer.total
const updateProgress = (state: AppGlobalState) => state.farmer.updateProgress
const metaFetchProgress = (state: AppGlobalState) => state.farmer.itemMetaDataFetchProgress
const updateError = (state: AppGlobalState) => state.farmer.updateError
const updateErrorDetail = (state: AppGlobalState) => state.farmer.updateErrorDetail
const removeProgress = (state: AppGlobalState) => state.farmer.removeProgress
const addProgress = (state: AppGlobalState) => state.farmer.addProgress
const addError = (state: AppGlobalState) => state.farmer.addError
const addErrorDetail = (state: AppGlobalState) => state.farmer.addErrorDetail
const hasNext = (state: AppGlobalState) => state.farmer.total > state.farmer.ids.length
const pageSize = (state: AppGlobalState) => state.farmer.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.farmer.total / state.farmer.pageSize)

const waitingAgreementCount = (state: AppGlobalState) => state.farmer.farmersWaitingAgreementCount
const addProductToFarmerCartProgress = (state: AppGlobalState) => state.farmer.addProductToCartProgress

const ordersTotalCountFetchProgress = (state: AppGlobalState) => state.farmer.ordersTotalCountFetchProgress

const FarmerSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  updateError,
  updateErrorDetail,
  farmersDict,
  ids,
  farmer,
  farmerByPhone,
  meta,
  getMetaByPhone,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  farmerList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  waitingAgreementCount,
  metaFetchProgress,
  addProductToFarmerCartProgress,
  ordersTotalCountFetchProgress,
}

export default FarmerSelectors
