import styled from 'styled-components'

export const ErrorText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export const DocumentModalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  padding: 10px;
  overflow-y: auto;
`
