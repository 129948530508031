import { ROLES } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'

export enum PERMISSION {
  NONE = 'NONE',
  C = 'C',
  R = 'R',
  U = 'U',
  D = 'D',
  CR = 'CR',
  RU = 'RU',
  CRUD = 'CRUD',
  CRU = 'CRU',
  UD = 'UD',
}

export enum CAPABILITY {
  USERS = 'USERS',
  FARMERS = 'FARMERS',
  ORDERS = 'ORDERS',
  SKU_ORDERS = 'SKU_ORDERS',
  DISTRIBUTOR_ORDERS = 'DISTRIBUTOR_ORDERS',
  DISTRIBUTOR_ORDERS_SKU = 'DISTRIBUTOR_ORDERS_SKU',
  RETURN_DECLARATIONS = 'RETURN_DECLARATIONS',
  RETURN_DECLARATIONS_SKU = 'RETURN_DECLARATIONS_SKU',
  PRODUCTS = 'PRODUCTS',
  BADGES = 'BADGES',
  PRODUCT_OPTIONS = 'PRODUCT_OPTIONS',
  COMPANIES = 'COMPANIES',
  CATEGORIES = 'CATEGORIES',
  REPORTS = 'REPORTS',
  MAILING_LIST = 'MAILING_LIST',
  CALL_BACK_REQUEST = 'CALL_BACK_REQUEST',
  UPTAKE = 'UPTAKE',
  TERRITORY = 'TERRITORY',
  REPORTS_MANAGEMENT = 'REPORTS_MANAGEMENT',
  PROMOCODES = 'PROMOCODES',
  INCENTIVE_PROGRAMS = 'INCENTIVE_PROGRAMS',
  PROMO_INFO = 'PROMO_INFO',
  FCC_ORDERS = 'FCC_ORDERS',
  DOCUMENTS = 'DOCUMENTS',
  HOUSEHOLDS = 'HOUSEHOLDS',
  BUSINESS_PLANNING = 'BUSINESS_PLANNING',
  LICENSES = 'LICENSES',
  RECONCILIATION = 'RECONCILIATION',
  SEASONS = 'SEASONS',
  INVENTORY = 'INVENTORY',
  INVENTORY_SKU = 'INVENTORY_SKU',
  INVENTORY_EXCHANGE = 'INVENTORY_EXCHANGE',
  INVENTORY_REQUESTS = 'INVENTORY_REQUESTS',
  INVENTORY_IN_REQUESTS = 'INVENTORY_IN_REQUESTS',
  INVENTORY_OUT_REQUESTS = 'INVENTORY_OUT_REQUESTS',
  FARMER_PLANS = 'FARMER_PLANS',
  SALES_TEAM_PLANS = 'SALES_TEAM_PLANS',
  MARKETING_CAMPAIGNS = 'MARKETING_CAMPAIGNS',
  STOREFRONT = 'STOREFRONT',
  ALLOCATION = 'ALLOCATION',
  PROGRAM_SUMMARY = 'PROGRAM_SUMMARY',
  TERRITORY_TARGETS = 'TERRITORY_TARGETS',
  FARMER_TARGETS = 'FARMER_TARGETS',
  RETAILER_TARGETS = 'RETAILER_TARGETS',
  CROP_TARGETS = 'CROP_TARGETS',
  PRODUCTS_AVAILABILITY = 'PRODUCTS_AVAILABILITY',
  PRODUCT_SETTINGS = 'PRODUCT_SETTINGS',
  CHANGE_LOG = 'CHANGE_LOG',
}

type OrderUpdateCapabilities = {
  distributorSelection: boolean
  status: boolean
  deliveryDetails: boolean
  items: boolean
  comment: boolean
  promocode: boolean
  orderApprove: boolean
  creditOfferAccept: boolean
  date: boolean
}

export type CapabilitiesType = {
  [CAPABILITY.USERS]: PERMISSION
  [CAPABILITY.FARMERS]: PERMISSION
  [CAPABILITY.ORDERS]: PERMISSION
  [CAPABILITY.SKU_ORDERS]: PERMISSION
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION
  [CAPABILITY.PRODUCTS]: PERMISSION
  [CAPABILITY.BADGES]: PERMISSION
  [CAPABILITY.PRODUCT_OPTIONS]: PERMISSION
  [CAPABILITY.COMPANIES]: PERMISSION
  [CAPABILITY.CATEGORIES]: PERMISSION
  [CAPABILITY.REPORTS]: PERMISSION
  [CAPABILITY.MAILING_LIST]: PERMISSION
  [CAPABILITY.CALL_BACK_REQUEST]: PERMISSION
  [CAPABILITY.UPTAKE]: PERMISSION
  [CAPABILITY.TERRITORY]: PERMISSION
  [CAPABILITY.REPORTS_MANAGEMENT]: PERMISSION
  [CAPABILITY.PROMOCODES]: PERMISSION
  [CAPABILITY.INCENTIVE_PROGRAMS]: PERMISSION
  [CAPABILITY.PROMO_INFO]: PERMISSION
  [CAPABILITY.FCC_ORDERS]: PERMISSION
  [CAPABILITY.DOCUMENTS]: PERMISSION
  [CAPABILITY.HOUSEHOLDS]: PERMISSION
  [CAPABILITY.BUSINESS_PLANNING]: PERMISSION
  [CAPABILITY.LICENSES]: PERMISSION
  [CAPABILITY.RECONCILIATION]: PERMISSION
  [CAPABILITY.SEASONS]: PERMISSION
  [CAPABILITY.INVENTORY]: PERMISSION
  [CAPABILITY.INVENTORY_SKU]: PERMISSION
  [CAPABILITY.INVENTORY_EXCHANGE]: PERMISSION
  [CAPABILITY.INVENTORY_REQUESTS]: PERMISSION
  [CAPABILITY.INVENTORY_IN_REQUESTS]: PERMISSION
  [CAPABILITY.INVENTORY_OUT_REQUESTS]: PERMISSION
  [CAPABILITY.FARMER_PLANS]: PERMISSION
  [CAPABILITY.SALES_TEAM_PLANS]: PERMISSION
  [CAPABILITY.MARKETING_CAMPAIGNS]: PERMISSION
  [CAPABILITY.STOREFRONT]: PERMISSION
  [CAPABILITY.ALLOCATION]: PERMISSION
  [CAPABILITY.PROGRAM_SUMMARY]: PERMISSION
  [CAPABILITY.PRODUCTS_AVAILABILITY]: PERMISSION
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION
  [CAPABILITY.CHANGE_LOG]: PERMISSION
  ORDERS_UPDATE: OrderUpdateCapabilities
}

export type PermissionsType = {
  [key in ROLES]: CapabilitiesType
}

const NON_ADMIN_PERMISSIONS = {
  [CAPABILITY.USERS]: PERMISSION.NONE,
  [CAPABILITY.FARMERS]: PERMISSION.NONE,
  [CAPABILITY.ORDERS]: PERMISSION.RU,
  [CAPABILITY.SKU_ORDERS]: PERMISSION.RU,
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION.RU,
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION.RU,
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION.NONE,
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION.NONE,
  [CAPABILITY.FCC_ORDERS]: PERMISSION.RU,
  [CAPABILITY.PRODUCTS]: PERMISSION.NONE,
  [CAPABILITY.BADGES]: PERMISSION.NONE,
  [CAPABILITY.PRODUCT_OPTIONS]: PERMISSION.NONE,
  [CAPABILITY.CATEGORIES]: PERMISSION.NONE,
  [CAPABILITY.COMPANIES]: PERMISSION.NONE,
  [CAPABILITY.REPORTS]: PERMISSION.R,
  [CAPABILITY.MAILING_LIST]: PERMISSION.NONE,
  [CAPABILITY.CALL_BACK_REQUEST]: PERMISSION.NONE,
  [CAPABILITY.UPTAKE]: PERMISSION.NONE,
  [CAPABILITY.TERRITORY]: PERMISSION.NONE,
  [CAPABILITY.REPORTS_MANAGEMENT]: PERMISSION.NONE,
  [CAPABILITY.PROMOCODES]: PERMISSION.NONE,
  [CAPABILITY.PROMO_INFO]: PERMISSION.NONE,
  [CAPABILITY.INCENTIVE_PROGRAMS]: PERMISSION.NONE,
  [CAPABILITY.DOCUMENTS]: PERMISSION.NONE,
  [CAPABILITY.HOUSEHOLDS]: PERMISSION.NONE,
  [CAPABILITY.BUSINESS_PLANNING]: PERMISSION.CRUD,
  [CAPABILITY.LICENSES]: PERMISSION.NONE,
  [CAPABILITY.RECONCILIATION]: PERMISSION.NONE,
  [CAPABILITY.SEASONS]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY]: PERMISSION.CRU,
  [CAPABILITY.INVENTORY_SKU]: PERMISSION.CRU,
  [CAPABILITY.INVENTORY_EXCHANGE]: PERMISSION.CRU,
  [CAPABILITY.INVENTORY_REQUESTS]: PERMISSION.CRU,
  [CAPABILITY.INVENTORY_OUT_REQUESTS]: PERMISSION.CRU,
  [CAPABILITY.INVENTORY_IN_REQUESTS]: PERMISSION.CRU,
  [CAPABILITY.FARMER_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.SALES_TEAM_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.MARKETING_CAMPAIGNS]: PERMISSION.CRUD,
  [CAPABILITY.STOREFRONT]: PERMISSION.NONE,
  [CAPABILITY.ALLOCATION]: PERMISSION.NONE,
  [CAPABILITY.PROGRAM_SUMMARY]: PERMISSION.NONE,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.PRODUCTS_AVAILABILITY]: PERMISSION.NONE,
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION.NONE,
}

const USER_PERMISSIONS = {
  [CAPABILITY.USERS]: PERMISSION.NONE,
  [CAPABILITY.FARMERS]: PERMISSION.NONE,
  [CAPABILITY.ORDERS]: PERMISSION.NONE,
  [CAPABILITY.SKU_ORDERS]: PERMISSION.NONE,
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION.NONE,
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION.NONE,
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION.NONE,
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION.NONE,
  [CAPABILITY.FCC_ORDERS]: PERMISSION.NONE,
  [CAPABILITY.PRODUCTS]: PERMISSION.NONE,
  [CAPABILITY.PRODUCT_OPTIONS]: PERMISSION.NONE,
  [CAPABILITY.BADGES]: PERMISSION.NONE,
  [CAPABILITY.CATEGORIES]: PERMISSION.NONE,
  [CAPABILITY.COMPANIES]: PERMISSION.NONE,
  [CAPABILITY.REPORTS]: PERMISSION.NONE,
  [CAPABILITY.MAILING_LIST]: PERMISSION.NONE,
  [CAPABILITY.CALL_BACK_REQUEST]: PERMISSION.NONE,
  [CAPABILITY.UPTAKE]: PERMISSION.NONE,
  [CAPABILITY.TERRITORY]: PERMISSION.NONE,
  [CAPABILITY.REPORTS_MANAGEMENT]: PERMISSION.NONE,
  [CAPABILITY.PROMOCODES]: PERMISSION.NONE,
  [CAPABILITY.PROMO_INFO]: PERMISSION.NONE,
  [CAPABILITY.INCENTIVE_PROGRAMS]: PERMISSION.NONE,

  [CAPABILITY.DOCUMENTS]: PERMISSION.NONE,
  [CAPABILITY.HOUSEHOLDS]: PERMISSION.NONE,
  [CAPABILITY.BUSINESS_PLANNING]: PERMISSION.NONE,
  [CAPABILITY.LICENSES]: PERMISSION.NONE,
  [CAPABILITY.RECONCILIATION]: PERMISSION.NONE,
  [CAPABILITY.SEASONS]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY_SKU]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY_EXCHANGE]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY_REQUESTS]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY_IN_REQUESTS]: PERMISSION.NONE,
  [CAPABILITY.INVENTORY_OUT_REQUESTS]: PERMISSION.NONE,
  [CAPABILITY.FARMER_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.SALES_TEAM_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.MARKETING_CAMPAIGNS]: PERMISSION.CRUD,
  [CAPABILITY.STOREFRONT]: PERMISSION.NONE,
  [CAPABILITY.ALLOCATION]: PERMISSION.NONE,
  [CAPABILITY.PROGRAM_SUMMARY]: PERMISSION.NONE,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.NONE,
  [CAPABILITY.PRODUCTS_AVAILABILITY]: PERMISSION.NONE,
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION.NONE,
  [CAPABILITY.CHANGE_LOG]: PERMISSION.NONE,

  ORDERS_UPDATE: {
    distributorSelection: false,
    status: false,
    deliveryDetails: false,
    items: false,
    comment: false,
    orderApprove: false,
    promocode: false,
    creditOfferAccept: false,
    date: false,
  },
}

const AGRO_MANAGER_PERMISSIONS = {
  ...NON_ADMIN_PERMISSIONS,
  [CAPABILITY.ORDERS]: PERMISSION.CRUD,
  [CAPABILITY.SKU_ORDERS]: PERMISSION.CRUD,
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION.CRUD,
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION.CRUD,
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION.CRUD,
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION.CRUD,
  [CAPABILITY.MAILING_LIST]: PERMISSION.CRUD,
  [CAPABILITY.CALL_BACK_REQUEST]: PERMISSION.CRUD,
  [CAPABILITY.UPTAKE]: PERMISSION.CRUD,
  [CAPABILITY.TERRITORY]: PERMISSION.CRUD,
  [CAPABILITY.PROMO_INFO]: PERMISSION.CRUD,
  [CAPABILITY.INCENTIVE_PROGRAMS]: PERMISSION.CRUD,
  [CAPABILITY.DOCUMENTS]: PERMISSION.CRUD,
  [CAPABILITY.BUSINESS_PLANNING]: PERMISSION.CRUD,
  [CAPABILITY.RECONCILIATION]: PERMISSION.CRUD,
  [CAPABILITY.SEASONS]: PERMISSION.CRUD,
  [CAPABILITY.INVENTORY]: PERMISSION.CRUD,
  [CAPABILITY.INVENTORY_SKU]: PERMISSION.CRUD,
  [CAPABILITY.INVENTORY_EXCHANGE]: PERMISSION.CRUD,
  [CAPABILITY.FARMER_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.SALES_TEAM_PLANS]: PERMISSION.CRUD,
  [CAPABILITY.MARKETING_CAMPAIGNS]: PERMISSION.CRUD,
  [CAPABILITY.STOREFRONT]: PERMISSION.CRUD,
  [CAPABILITY.ALLOCATION]: PERMISSION.CRUD,
  [CAPABILITY.PROGRAM_SUMMARY]: PERMISSION.CRUD,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCTS_AVAILABILITY]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION.CRUD,
  [CAPABILITY.CHANGE_LOG]: PERMISSION.NONE,
  ORDERS_UPDATE: {
    distributorSelection: true,
    status: true,
    deliveryDetails: true,
    items: true,
    comment: true,
    orderApprove: true,
    promocode: true,
    creditOfferAccept: true,
    date: true,
  },
}

const AGRO_ADMIN_PERMISSIONS = {
  ...AGRO_MANAGER_PERMISSIONS,
  [CAPABILITY.USERS]: PERMISSION.CRUD,
  [CAPABILITY.FARMERS]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCTS]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCT_OPTIONS]: PERMISSION.CRUD,
  [CAPABILITY.BADGES]: PERMISSION.CRUD,
  [CAPABILITY.CATEGORIES]: PERMISSION.CRUD,
  [CAPABILITY.COMPANIES]: PERMISSION.CRUD,
  [CAPABILITY.REPORTS]: PERMISSION.CRUD,
  [CAPABILITY.REPORTS_MANAGEMENT]: PERMISSION.CRUD,
  [CAPABILITY.PROMOCODES]: PERMISSION.CRUD,
  [CAPABILITY.INCENTIVE_PROGRAMS]: PERMISSION.CRUD,
  [CAPABILITY.HOUSEHOLDS]: PERMISSION.CRUD,
  [CAPABILITY.LICENSES]: PERMISSION.CRUD,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION.CRUD,
  [CAPABILITY.CHANGE_LOG]: PERMISSION.CRUD,
}

const PRODUCER_MANAGER_PERMISSIONS = {
  ...NON_ADMIN_PERMISSIONS,
  [CAPABILITY.ORDERS]: PERMISSION.CRU,
  [CAPABILITY.SKU_ORDERS]: PERMISSION.CRU,
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION.CRU,
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION.CRU,
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION.CRUD,
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION.CRUD,
  [CAPABILITY.FCC_ORDERS]: PERMISSION.R,
  [CAPABILITY.ALLOCATION]: PERMISSION.CRUD,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.R,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.R,
  [CAPABILITY.PRODUCTS_AVAILABILITY]: PERMISSION.CRUD,
  [CAPABILITY.PRODUCT_SETTINGS]: PERMISSION.CRUD,
  [CAPABILITY.CHANGE_LOG]: PERMISSION.NONE,
  [CAPABILITY.RECONCILIATION]: PERMISSION.R,

  ORDERS_UPDATE: {
    distributorSelection: true,
    status: false,
    deliveryDetails: true,
    items: true,
    comment: true,
    orderApprove: false,
    promocode: true,
    creditOfferAccept: true,
    date: true,
  },
}

const PRODUCER_HEAD_PERMISSIONS = {
  ...PRODUCER_MANAGER_PERMISSIONS,
  [CAPABILITY.TERRITORY_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.CROP_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.FCC_ORDERS]: PERMISSION.CRU,
  [CAPABILITY.RECONCILIATION]: PERMISSION.CRU,
}

const DISTRIBUTOR_MANAGER_PERMISSIONS = {
  ...NON_ADMIN_PERMISSIONS,
  [CAPABILITY.ORDERS]: PERMISSION.CRU,
  [CAPABILITY.SKU_ORDERS]: PERMISSION.CRU,
  [CAPABILITY.DISTRIBUTOR_ORDERS]: PERMISSION.CR,
  [CAPABILITY.DISTRIBUTOR_ORDERS_SKU]: PERMISSION.CR,
  [CAPABILITY.RETURN_DECLARATIONS]: PERMISSION.CRU,
  [CAPABILITY.RETURN_DECLARATIONS_SKU]: PERMISSION.CRU,
  [CAPABILITY.ALLOCATION]: PERMISSION.R,
  [CAPABILITY.FARMER_TARGETS]: PERMISSION.CRUD,
  [CAPABILITY.CHANGE_LOG]: PERMISSION.NONE,
  [CAPABILITY.RECONCILIATION]: PERMISSION.R,

  ORDERS_UPDATE: {
    distributorSelection: false,
    status: false,
    deliveryDetails: false,
    items: true,
    comment: true,
    orderApprove: true,
    promocode: true,
    creditOfferAccept: true,
    date: false,
  },
}

const DISTRIBUTOR_HEAD_PERMISSIONS = {
  ...DISTRIBUTOR_MANAGER_PERMISSIONS,
  [CAPABILITY.RETAILER_TARGETS]: PERMISSION.R,
}

const PERMISSIONS: PermissionsType = {
  [ROLES.USER]: USER_PERMISSIONS,
  [ROLES.FARMER]: USER_PERMISSIONS, // deprecated
  [ROLES.AGRO_MANAGER]: AGRO_MANAGER_PERMISSIONS,
  [ROLES.AGRO_ADMIN]: AGRO_ADMIN_PERMISSIONS,
  [ROLES.PRODUCER_HEAD]: PRODUCER_HEAD_PERMISSIONS,
  [ROLES.PRODUCER_MANAGER]: PRODUCER_MANAGER_PERMISSIONS,
  [ROLES.DISTRIBUTOR_HEAD]: DISTRIBUTOR_HEAD_PERMISSIONS,
  [ROLES.DISTRIBUTOR_MANAGER]: DISTRIBUTOR_MANAGER_PERMISSIONS,
}

type PermissionProps = { capability: CAPABILITY; permission: PERMISSION }

type UsePermissionProps = PermissionProps | PermissionProps[]

type UsePermissionReturnType = boolean | boolean[]

type BranchDistributorPermissions = {
  is_allowed_order_create: boolean
  is_allowed_order_edit: boolean
  is_allowed_distributor_order_edit: boolean
  is_allowed_distributor_order_create: boolean
}

export const isPermitted = (
  role: ROLES | null,
  capability?: CAPABILITY,
  permission?: PERMISSION,
  branchPermissions?: BranchDistributorPermissions | null,
): boolean => {
  if (!role || !capability || !permission) {
    return false
  }

  const farmerOrdersCapability = capability === CAPABILITY.ORDERS || capability === CAPABILITY.SKU_ORDERS
  const distributorOrdersCapability =
    capability === CAPABILITY.DISTRIBUTOR_ORDERS || capability === CAPABILITY.DISTRIBUTOR_ORDERS_SKU

  if (branchPermissions) {
    const {
      is_allowed_order_create,
      is_allowed_order_edit,
      is_allowed_distributor_order_create,
      is_allowed_distributor_order_edit,
    } = { ...branchPermissions }

    if (farmerOrdersCapability) {
      if ([PERMISSION.CRU].includes(permission)) {
        return is_allowed_order_create && is_allowed_order_edit
      } else if ([PERMISSION.C].includes(permission)) {
        return is_allowed_order_create
      } else if ([PERMISSION.U].includes(permission)) {
        return is_allowed_order_edit
      }
    }

    if (distributorOrdersCapability) {
      if ([PERMISSION.CRU].includes(permission)) {
        return is_allowed_distributor_order_create && is_allowed_distributor_order_edit
      } else if ([PERMISSION.C].includes(permission)) {
        return is_allowed_distributor_order_create
      } else if ([PERMISSION.U].includes(permission)) {
        return is_allowed_distributor_order_edit
      }
    }
  }

  return PERMISSIONS[role][capability].includes(permission)
}

const useBranchDistributorPermissions = (): BranchDistributorPermissions | null => {
  const userCompany = useSelector(AuthSelectors.userCompany)
  const permissions = !!userCompany && userCompany.head_company_relation

  if (!permissions) {
    return null
  }
  return {
    is_allowed_order_create: !!permissions.is_allowed_order_create,
    is_allowed_order_edit: !!permissions.is_allowed_order_edit,
    is_allowed_distributor_order_edit: !!permissions.is_allowed_distributor_order_edit,
    is_allowed_distributor_order_create: !!permissions.is_allowed_distributor_order_create,
  }
}

export const useIsPermitted = () => {
  const branchDistributorPermissions = useBranchDistributorPermissions()
  return (role: ROLES | null, capability?: CAPABILITY, permission?: PERMISSION) => {
    return isPermitted(role, capability, permission, branchDistributorPermissions)
  }
}

export const useRole = () => {
  return useSelector(AuthSelectors.role)
}

export function usePermissions(permissions: PermissionProps): boolean
export function usePermissions(permissions: PermissionProps[]): boolean[]
export function usePermissions(permissions: UsePermissionProps): UsePermissionReturnType {
  const role = useSelector(AuthSelectors.role)
  const branchDistributorPermissions = useBranchDistributorPermissions()

  if (!role) {
    return false
  }

  if (Array.isArray(permissions)) {
    return permissions.map(({ capability, permission }) => {
      return isPermitted(role, capability, permission, branchDistributorPermissions)
    })
  }

  const { capability, permission } = permissions
  return isPermitted(role, capability, permission, branchDistributorPermissions)
}

export const useOrderUpdatePermissions = (): OrderUpdateCapabilities => {
  const role = useSelector(AuthSelectors.role)
  const branchDistributorPermissions = useBranchDistributorPermissions()
  const userCompany = useSelector(AuthSelectors.userCompany)
  const profile = useSelector(AuthSelectors.profile)

  if (!role || (branchDistributorPermissions && !branchDistributorPermissions.is_allowed_order_edit)) {
    return {
      comment: false,
      deliveryDetails: false,
      distributorSelection: false,
      orderApprove: false,
      items: false,
      status: false,
      promocode: false,
      creditOfferAccept: false,
      date: false,
    }
  }

  if ((userCompany && userCompany.has_branch_company) || profile?.related_companies?.length) {
    return {
      ...PERMISSIONS[role].ORDERS_UPDATE,
      distributorSelection: true,
    }
  }

  return PERMISSIONS[role].ORDERS_UPDATE
}
