enum InventoryExchangeRoutes {
  Total = '/inventory-exchange/total',
  TotalDetails = '/inventory-exchange/total/:id',
  TransactionsList = '/inventory-exchange',
  Add = '/inventory-exchange/add',
  Edit = '/inventory-exchange/edit/:id',
  TransferAllRequests = '/inventory-exchange/requests',
  InRequests = '/inventory-exchange/in-requests',
  OutRequests = '/inventory-exchange/out-requests',
}

export default InventoryExchangeRoutes
