import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { AddButton, SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import CompanyList from './CompanyList/CompanyList'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CompanyRoutes from './routes'
import CompanyActions from 'modules/domain/company/duck'
import CompanySelectors from 'modules/domain/company/selectors'
import { useSelector } from 'react-redux'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const CompanyTable = () => {
  const filter = useSelector(CompanySelectors.filter)
  const filterUpdated = useAction(CompanyActions.filterUpdated)
  const { t } = useTranslation('company')

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  useHelmet({ title: t('companiesMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={CompanyRoutes.Add} />
          </Header.Title>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filter.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CompanyList />
      </Layout.Content>
    </>
  )
}

export default CompanyTable
