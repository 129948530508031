import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import StorefrontCardActions from 'modules/domain/storefrontCard/duck'
import StorefrontCardSelectors from 'modules/domain/storefrontCard/selectors'
import { StorefrontCardActionData } from 'modules/domain/storefrontCard/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import Routes from 'views/pages/Storefront/routes'
import StorefrontCardDetailForm from 'views/pages/Storefront/StorefrontCard/components/StorefrontCardDetailForm'
import * as Header from 'views/ui/Header/Header'

export const StorefrontCardAdd: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation('cards')
  const addProgress = useSelector(StorefrontCardSelectors.addProgress)
  const addAction = useAction(StorefrontCardActions.addRequested)
  const page = useSelector(StorefrontCardSelectors.page)
  const goBack = () => push({ route: Routes.CardsList, query: { page } })
  useHelmet({ title: t('cardMetaTitle') })

  const handleSubmit = (form: StorefrontCardActionData) => {
    addAction(form)
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontCardDetailForm onSubmit={handleSubmit} onCancel={goBack} progress={addProgress} />
      </Layout.Content>
    </>
  )
}

export default StorefrontCardAdd
