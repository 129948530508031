import { useAction } from '@agro-club/frontend-shared'
import ProgramSummaryActions from 'modules/domain/programSummary/duck'
import ProgramSummarySelector from 'modules/domain/programSummary/selectors'
import { ProgramSummaryListFilter } from 'modules/domain/programSummary/types'
import { PersistentFiltersFieldsType } from 'modules/types'
import { getAllPersistentFilters } from 'modules/utils/helpers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { CategorySelect } from './CategorySelect'
import { FarmerSelect } from './FarmerSelect'
import { ProductSelect } from './ProductSelect'
import { RetailerSelect } from './RetailerSelect'
import { SeasonSelect } from './SeasonSelect'

const ProgramSummaryListFilters: React.FC = () => {
  const { t } = useTranslation('programSummary')
  const filterValue = useSelector(ProgramSummarySelector.filter)
  const filterUpdateAction = useAction(ProgramSummaryActions.filterUpdated)

  const handleFilterChange = useCallback(
    (newFilter: Partial<ProgramSummaryListFilter>) => {
      filterUpdateAction({ ...filterValue, ...newFilter })
    },
    [filterUpdateAction, filterValue],
  )

  const gFilters = getAllPersistentFilters('program_summary') || {}

  const getFilterValue = (filterItemName: keyof PersistentFiltersFieldsType['program_summary']) => {
    return filterValue[filterItemName]
      ? filterValue[filterItemName]
      : gFilters[filterItemName]
      ? gFilters[filterItemName]
      : []
  }

  return (
    <Filters>
      <RetailerSelect
        label={t('labels:retailer')}
        values={getFilterValue('ps_retailers_ids')}
        placeholder={t('form.placeholders.retailer')}
        onChange={val => handleFilterChange({ ps_retailers_ids: val })}
        variant="small"
      />
      <FarmerSelect
        label={t('labels.farmer')}
        values={getFilterValue('ps_farmers_ids')}
        placeholder={t('form.placeholders.farmer')}
        onChange={val => handleFilterChange({ ps_farmers_ids: val })}
        variant="small"
      />
      <ProductSelect
        label={t('labels.product')}
        values={getFilterValue('ps_products_ids')}
        placeholder={t('form.placeholders.product')}
        onChange={val => handleFilterChange({ ps_products_ids: val })}
        variant="small"
      />
      <CategorySelect
        label={t('labels.crop')}
        values={getFilterValue('ps_categories_ids')}
        placeholder={t('form.placeholders.crop')}
        onChange={val => handleFilterChange({ ps_categories_ids: val })}
        variant="small"
      />
      <SeasonSelect
        label={t('labels.seasons')}
        values={getFilterValue('ps_seasons_ids')}
        placeholder={t('form.placeholders.seasons')}
        onChange={val => handleFilterChange({ ps_seasons_ids: val })}
        variant="small"
      />
    </Filters>
  )
}

export default ProgramSummaryListFilters
