import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import ReconciliationActions from './duck'
import ReconciliationSelectors from './selectors'
import { ReconciliationList, ReconciliationGroupFilter } from './types'
import { useAction, useDidMount, useDidMountWithProgress, usePageQuery } from '@agro-club/frontend-shared'

export const useReconciliationGroups: ResourceHook<ReconciliationList[], [string?, string?]> = (
  manufacturer_id?: string,
  retailer_id?: string,
) => {
  const actualProgress = useSelector(ReconciliationSelectors.groupsFetchProgress)
  const list = useSelector(ReconciliationSelectors.list)
  const page = usePageQuery()

  const filter: ReconciliationGroupFilter = {
    retailer_id: retailer_id ? [retailer_id] : [],
    manufacturer_id: manufacturer_id ? [manufacturer_id] : [],
  }

  const fetchAction = useAction(ReconciliationActions.groupsRequested, page ? { filter, page } : { filter })

  const progress = useDidMountWithProgress(fetchAction, actualProgress)

  return [progress, list]
}

export const useReconciliationById: ResourceHook<ReconciliationList, [string, string]> = (
  manufacturer_id: string,
  retailer_id: string,
) => {
  const id = `${manufacturer_id}-${retailer_id}`
  const group = useSelector(state => ReconciliationSelectors.group(state, id))

  const page = usePageQuery()
  const filter: ReconciliationGroupFilter = {
    retailer_id: retailer_id ? [retailer_id] : [],
    manufacturer_id: manufacturer_id ? [manufacturer_id] : [],
  }
  const fetchAction = useAction(ReconciliationActions.groupsRequested, page ? { filter, page } : { filter })

  useDidMount(() => {
    if (!group) {
      fetchAction()
    }
  })
  const actualProgress = useSelector(ReconciliationSelectors.groupsFetchProgress)
  return [actualProgress, group]
}
