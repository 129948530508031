import styled from 'styled-components'
import { Button } from '@agro-club/frontend-shared'
import { Link } from 'react-router-dom'

export const HintText = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  white-space: pre-line;

  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const ErrorWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
  bottom: -28px;
  width: calc(100% + 60px);
  left: -30px;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  background-color: #eaaa00;

  &:active {
    background-color: #eaaa00;
  }
  &:focus {
    background-color: #eaaa00;
  }
  &:hover {
    background-color: #eab627;
  }
`

export const LinkButton = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  outline: none;
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  text-decoration: none;

  &:focus {
    box-shadow: ${({ theme }) => theme.shadow.focus};
  }
`
