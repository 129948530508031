import { FetchError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'

export type UseEditLayoutProps = {
  id: string
  fetchProgress: Progress
  data?: unknown
  fetchError?: FetchError | null
  entityName: string
  goBack: () => void
}

export const useEditLayout = ({
  id,
  fetchProgress,
  data,
  fetchError,
  entityName,
  goBack,
}: UseEditLayoutProps): ReactElement<any, any> | null => {
  const { t } = useTranslation('common')
  const loading = <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle', { entityName })} />
  const errorUnknown = <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (fetchError === 'not_found') {
      return error404
    }
    return errorUnknown
  }

  if (fetchProgress === Progress.WORK && !data) {
    return loading
  }

  return null
}
