export const boot = (opts: {
  userId: string
  email: string
  app_id: string
  name?: string
  company?: { id: string; name: string }
}) => {
  try {
    const settings = {
      app_id: opts.app_id,
      email: opts.email,
      name: opts.name || '',
      user_id: opts.userId,
      created_at: Date.now(),
      company: opts.company,
    }

    window.intercomSettings = settings
    window.Intercom('boot', settings)
  } catch (err) {
    console.error('Failed to boot intercom', err)
  }
}

export const shutdown = () => {
  try {
    window.Intercom('shutdown')
  } catch (err) {
    console.error('Failed to shutdown intercom', err)
  }
}
