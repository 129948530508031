import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'
import useLangPicker from 'hooks/useLangPicker'
import StorefrontCardActions from 'modules/domain/storefrontCard/duck'
import { useStorefrontCard } from 'modules/domain/storefrontCard/hooks'
import StorefrontCardSelectors from 'modules/domain/storefrontCard/selectors'
import { StorefrontCardActionData } from 'modules/domain/storefrontCard/types'
import { Progress } from 'modules/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as uuid from 'uuid'
import { FileItem } from 'views/components/FileManager/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import Routes from 'views/pages/Storefront/routes'
import StorefrontCardDetailForm from 'views/pages/Storefront/StorefrontCard/components/StorefrontCardDetailForm'
import * as Header from 'views/ui/Header/Header'
import { makeCurrentFile } from '../helpers'
import * as Styled from './styled'

const StorefrontCardEdit: React.FC = () => {
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const meta = useSelector(state => StorefrontCardSelectors.meta(state, params.id))
  const updateAction = useAction(StorefrontCardActions.updateRequested)
  const removeAction = useAction(StorefrontCardActions.removeRequested, params.id)
  const page = useSelector(StorefrontCardSelectors.page)
  const { t } = useTranslation('cards')
  const { pick } = useLangPicker()

  const [fetchProgress, card] = useStorefrontCard(params.id)
  const handleSubmit = (form: StorefrontCardActionData) => {
    updateAction(params.id, form)
  }

  useHelmet({ title: card ? card.title : t('cardMetaTitle') })

  const data = useMemo(() => {
    if (!card) {
      return null
    }
    return {
      ...card,
      images: card.images.map(src => ({
        file: src,
        kind: 'current' as const,
        id: uuid.v4(),
        error: null,
      })),
      files: card.files?.map(file => ({
        title_i18n: file.title_i18n,
        url_i18n: createLocalizedValue(lang =>
          file.url_i18n[lang] ? [makeCurrentFile(file.url_i18n[lang]) as FileItem] : [],
        ),
      })),
    }
  }, [card])

  const goBack = () => push({ route: Routes.CardsList, query: { page } })
  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.WORK) {
    return loading()
  }

  if (fetchProgress === Progress.ERROR || !data || !card) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (!data || !card) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={pick(card.title_i18n) || ''} />
          <Styled.Id>{`ID ${card.id}`}</Styled.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontCardDetailForm
          editing
          onSubmit={handleSubmit}
          onCancel={goBack}
          onRemove={removeAction}
          progress={meta.updateProgress}
          initialValues={data}
        />
      </Layout.Content>
    </>
  )
}

export default StorefrontCardEdit
