import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { FormikContext, useFormik } from 'formik'
import { FormFields } from 'views/pages/Farmer/FarmerDetailsForm/types'
import { FeatureFlagModifiers, FieldLocation, FieldNames, ROLES, Status } from 'types/entities'
import Footer from './Footer'
import FarmerActions from 'modules/domain/farmer/duck'
import { Farmer, FarmerDTO } from 'modules/domain/farmer/types'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { useSelector } from 'react-redux'
import { Progress } from 'modules/types'
import { AddError, UpdateError } from 'modules/domain/types'
import FarmerForm from 'views/pages/Farmer/FarmerDetailsForm/FarmerForm'
import { useAction } from '@agro-club/frontend-shared'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import CollectionSelectors from 'modules/domain/collection/selectors'

export type FormData = Omit<FormFields, 'partnership'>

type CustomerFormProps = {
  phone: string
  countryCode: CountryCode
  onCancel: () => void
  onSubmit: (dto?: FarmerDTO) => void
  progress: Progress
  isAdded?: boolean
  error?: UpdateError | AddError | null
  title: string
  farmer?: Farmer
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  phone,
  countryCode,
  onCancel,
  onSubmit,
  progress,
  error,
  title,
  farmer,
}) => {
  const defaultCountry = useDetectedCountry()
  const { t } = useTranslation(['farmer', 'common', 'validation', 'farmerOrder', 'labels'])
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)

  const checkFeatureFlag = useFeatureFlags()

  const isEmailOptionalByFeatureFlag = checkFeatureFlag(
    generateFieldModifierString(FieldLocation.FarmerOrders, FieldNames.FarmerEmail, FeatureFlagModifiers.NotRequired),
  )

  const [emailOptionalByAdmin, setEmailOptional] = useState(false)

  const submit = useCallback(
    fields => {
      const dto: FarmerDTO = {
        first_name: fields.firstName,
        second_name: fields.middleName,
        last_name: fields.lastName,
        status: Status.Active,
        phone_number: fields.phone,
        country: fields.countryCode,
        regions: [],
        farm_name: fields.farmName,
        self_pickup: fields.selfPickup,
        legal_address: fields.legal_address,
        delivery_address: fields.delivery_address,
        does_not_have_email: fields.does_not_have_email || isEmailOptionalByFeatureFlag || emailOptionalByAdmin,
        role: ROLES.USER,
        comment: fields.comment,
        additional_phone: fields.additionalPhone,
      }

      if (!fields.does_not_have_email && !isEmailOptionalByFeatureFlag && !emailOptionalByAdmin) {
        dto['email'] = fields.email
      }

      onSubmit(dto)
    },
    [onSubmit, isEmailOptionalByFeatureFlag, emailOptionalByAdmin],
  )

  const handleChangeEmailOptional = useCallback(value => {
    setEmailOptional(value)
    formik.setFieldValue('does_not_have_email', value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = useMemo(() => {
    const email =
      isEmailOptionalByFeatureFlag || emailOptionalByAdmin
        ? Yup.string().email(t('validation:email_invalid'))
        : Yup.string()
            .required(t('validation:field_required'))
            .email(t('validation:email_invalid'))
    const addressSchema = Yup.object().shape({
      address: Yup.string().required(t('validation:field_required')),
      city: Yup.string().required(t('validation:field_required')),
      country: Yup.string().required(t('validation:field_required')),
      postal_code: Yup.string().required(t('validation:field_required')),
      region_id: Yup.string().required(t('validation:field_required')),
    })
    return Yup.object().shape({
      firstName: Yup.string().required(t('validation:field_required')),
      lastName: Yup.string().required(t('validation:field_required')),
      middleName: Yup.string(),
      email,
      phone: Yup.string()
        .required(t('validation:field_required'))
        .test('is-valid-number', t('validation:invalid_phone_number'), value => isValidPhoneNumber(value || '')),
      additionalPhone: Yup.string().test('is-valid-number', t('validation:invalid_phone_number'), value =>
        !value || phoneCodes.some(item => '+' + item.phone_prefix === value) ? true : isValidPhoneNumber(value || ''),
      ),
      countryCode: Yup.string().required(),
      legal_address: addressSchema,
      delivery_address: addressSchema,
    })
  }, [t, isEmailOptionalByFeatureFlag, emailOptionalByAdmin, phoneCodes])

  const addressInitialValues = {
    retailer_regions_ids: farmer?.retailer_regions_ids,
    legal_address: farmer?.legal_address || {
      country: countryCode || defaultCountry,
      delivery_date: undefined,
      address: undefined,
      city: undefined,
      postal_code: undefined,
      region_id: undefined,
    },
    delivery_address: farmer?.delivery_address || {
      country: countryCode || defaultCountry,
      delivery_date: undefined,
      address: undefined,
      city: undefined,
      postal_code: undefined,
      region_id: undefined,
    },
  }

  const formik = useFormik<FormData>({
    initialValues: {
      phone,
      firstName: farmer?.first_name || '',
      lastName: farmer?.last_name || '',
      middleName: farmer?.second_name || '',
      email: farmer?.email || '',
      regions: farmer?.regions || [],
      countryCode: countryCode || defaultCountry,
      farmName: farmer?.farm_name || '',
      selfPickup: farmer?.self_pickup || false,
      legal_address: farmer?.legal_address || addressInitialValues.legal_address,
      delivery_address: farmer?.delivery_address || addressInitialValues.delivery_address,
      comment: farmer?.comment || '',
      additionalPhone: farmer?.additional_phone,
    },
    onSubmit: submit,
    validationSchema,
    enableReinitialize: true,
  })

  const [confirmAccuracy, setConfirmAccuracy] = useState(false)

  // const handleChangeEmailOptional = useCallback(value => {
  //   setEmailOptional(value)
  //   formik.setFieldValue('does_not_have_email', value)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const handleChangeConfirmAccuracy = useCallback(() => {
    setConfirmAccuracy(state => !state)
  }, [])

  useEffect(() => {
    // React to validationSchema change
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSchema])

  useEffect(() => {
    if (error === 'email_exists') {
      formik.setFieldError('email', t('errors.emailTaken'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const confirmAccuracyLabelText = t('form.labels.confirmAccuracy', {
    privacyLink: `<a href="${t('common:links.privacyPolicy')}" target=_blank>${t('labels:privacyPolicy')}</a>`,
    termsLink: `<a href="${t('common:links.termsOfUse')}" target=_blank>${t('labels:termsOfUse')}</a>`,
  })

  const handleSubmit = async () => {
    try {
      await formik.submitForm()
      if (!formik.isValid) {
        return
      }
      formik.submitForm
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <>
      <Styled.Wrapper data-test-id={'modal-add-customer'}>
        <Styled.Title>{title}</Styled.Title>
        <FormikContext.Provider value={formik}>
          <Styled.FormContentBody>
            <FarmerForm
              mode={'create'}
              inOrderCreationMode
              countryCodeFromPhone={countryCode}
              isEmailOptional={isEmailOptionalByFeatureFlag || emailOptionalByAdmin}
              handleChangeEmailOptional={handleChangeEmailOptional}
            />
          </Styled.FormContentBody>
        </FormikContext.Provider>
        <Styled.ConfirmAccuracyWrapper>
          <Styled.Checkbox onChange={handleChangeConfirmAccuracy} isChecked={confirmAccuracy} />
          <Styled.ConfirmAccuracyLabel dangerouslySetInnerHTML={{ __html: confirmAccuracyLabelText }} />
        </Styled.ConfirmAccuracyWrapper>
      </Styled.Wrapper>
      <Footer
        onSubmit={handleSubmit}
        submitDisabled={!formik.dirty || !confirmAccuracy}
        onCancel={onCancel}
        submitProgress={progress}
      />
    </>
  )
}
export const AddCustomerForm: React.FC<{
  phone: string
  countryCode: CountryCode
  onCancel: () => void
  onSelect: (customer: Farmer) => void
}> = ({ phone, countryCode, onCancel, onSelect }) => {
  const { t } = useTranslation(['farmer', 'common', 'validation', 'farmerOrder', 'labels'])
  const submitAction = useAction(FarmerActions.addByRetailerRequested)
  const submitProgress = useSelector(FarmerSelectors.addProgress)
  const addedFarmer = useSelector(state => FarmerSelectors.farmerByPhone(state, phone))
  const addError = useSelector(FarmerSelectors.addError)

  useEffect(() => {
    if (submitProgress === Progress.SUCCESS && addedFarmer) {
      onSelect(addedFarmer)
    }
  }, [addedFarmer, onSelect, submitProgress])

  return (
    <CustomerForm
      phone={phone}
      countryCode={countryCode}
      onCancel={onCancel}
      onSubmit={submitAction}
      error={addError}
      progress={submitProgress}
      isAdded={!!addedFarmer}
      title={t('farmerOrder:form.sectionHeaders.addNewCustomer')}
    />
  )
}

export const FulfillCustomerForm: React.FC<{
  id: string
  phone: string
  onCancel: () => void
  onSelect: (customer: Farmer) => void
  farmer: Farmer
  countryCode: CountryCode
}> = ({ id, onCancel, onSelect, phone, farmer, countryCode }) => {
  const { t } = useTranslation(['farmer', 'common', 'validation', 'farmerOrder', 'labels'])
  const updateAction = useAction(FarmerActions.updateRequested)
  const updateProgress = useSelector(FarmerSelectors.updateProgress)
  const updateError = useSelector(FarmerSelectors.updateError)
  const selectedFarmer = useSelector(state => FarmerSelectors.farmer(state, id))
  const progressRef = useRef(updateProgress)

  useEffect(() => {
    if (updateProgress === Progress.SUCCESS && progressRef.current !== Progress.SUCCESS && selectedFarmer) {
      onSelect(selectedFarmer)
    }
    progressRef.current = updateProgress
  }, [selectedFarmer, onSelect, updateProgress])

  const handleSubmit = useCallback(
    (dto?: FarmerDTO) => {
      updateAction(id, dto)
    },
    [id, updateAction],
  )

  return (
    <CustomerForm
      phone={phone}
      countryCode={countryCode}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      error={updateError}
      progress={updateProgress}
      title={t('farmerOrder:form.sectionHeaders.fulfillCustomer')}
      farmer={farmer}
    />
  )
}
