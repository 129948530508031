import React from 'react'
import PromoInfoDetailsForm, { FormProps } from 'views/pages/PromoInfo/PromoInfoDetailsForm/PromoInfoDetailsForm'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useParams, useHistory } from 'react-router-dom'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import PromoInfoRoutes from 'views/pages/PromoInfo/routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { usePromoInfo } from 'modules/domain/promoInfo/hooks'
import PromoInfoActions from 'modules/domain/promoInfo/duck'
import PromoInfoSelectors from 'modules/domain/promoInfo/selectors'
import { useAction, useHelmet } from '@agro-club/frontend-shared'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PromoInfoEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [fetchProgress, promoInfo] = usePromoInfo(params.id)
  const submitAction = useAction(PromoInfoActions.updateRequested)
  const updateProgress = useSelector(PromoInfoSelectors.updateProgress)
  const removeAction = useAction(PromoInfoActions.removeRequested, params.id)
  const removeProgress = useSelector(PromoInfoSelectors.removeProgress)
  const { t } = useTranslation('promoInfo')

  useHelmet({ title: promoInfo ? promoInfo.title : t('promoInfosMetaTitle') })

  if (fetchProgress === Progress.WORK) {
    return (
      <SpinnerWrap>
        <SpinnerLayout />
      </SpinnerWrap>
    )
  }

  if (fetchProgress === Progress.ERROR || !promoInfo) {
    return <ErrorLayout />
  }

  const handleFormSubmit = (props: FormProps) => {
    submitAction(promoInfo.id, {
      title_i18n: props.title,
      description_i18n: props.description,
      message: { title_i18n: props.message.title, description_i18n: props.message.description },
      producer_ids: props.producers,
      category_ids: props.categories,
      status: props.status,
      type: props.type,
      styles: props.styles,
    })
  }

  const goBack = () => {
    history.push(PromoInfoRoutes.PromoInfoList)
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={promoInfo.title || promoInfo.id} />
          <Id>{`ID ${promoInfo.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <PromoInfoDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          editing={true}
          onRemove={removeAction}
          removeProgress={removeProgress}
          initialValues={{
            title: promoInfo.title_i18n,
            description: promoInfo.description_i18n,
            message: {
              title: promoInfo.message.title_i18n,
              description: promoInfo.message.description_i18n,
            },
            styles: promoInfo.styles,
            type: promoInfo.type,
            status: promoInfo.status,
            producers: promoInfo.producer_ids,
            categories: promoInfo.category_ids,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default PromoInfoEdit
