import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from './routes'

import FarmerTable from 'views/pages/Farmer/FarmerTable'
import FarmerEdit from './FarmerEdit'
import FarmerAdd from './FarmerAdd'

const FarmerPage = () => {
  return (
    <Switch>
      <Route path={Routes.List} exact={true}>
        <FarmerTable />
      </Route>
      <Route path={Routes.Edit} exact={true}>
        <FarmerEdit />
      </Route>
      <Route path={Routes.Add} exact={true}>
        <FarmerAdd />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  )
}

export default FarmerPage
