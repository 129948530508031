import React, { FC } from 'react'
import { Spinner } from '@agro-club/frontend-shared'
import { useConfig } from 'modules/domain/config/hooks'
import { Progress } from 'modules/types'
type PropsType = {
  size?: 'small' | 'medium' | 'big'
  className?: string
  filled?: boolean
}
const SpinnerLayout: FC<PropsType> = ({ size, className, filled }: PropsType) => {
  const [progress, config] = useConfig(window.location.hostname)

  return progress === Progress.SUCCESS || progress === Progress.ERROR ? (
    <Spinner spinnerImageUrl={config?.images?.spinner_url} size={size} className={className} filled={filled} />
  ) : null
}

export default SpinnerLayout
