import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useBranchCompaniesById, useCompanyById } from 'modules/domain/company/hooks'
import CompanyActions from 'modules/domain/company/duck'
import CompanySelectors from 'modules/domain/company/selectors'
import { Progress } from 'modules/types'
import CompanyDetailsForm, { FormFields } from './CompanyDetailsForm/CompanyDetailsForm'
import { FormManagerProps as ConfigFormFields } from 'views/pages/Company/CompanyDetailsForm/CompanyConfigForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import CompaniesRoutes from 'views/pages/Company/routes'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { CompanyRelation } from 'modules/domain/company/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const CompanyId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const CompanyEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const [fetchProgress, company] = useCompanyById(params.id)
  const [, branchCompanies] = useBranchCompaniesById(params.id)

  const companyRemoveAction = useAction(CompanyActions.removeRequested, params.id)
  const companyUpdateAction = useAction(CompanyActions.updateRequested)
  const companyAddConfigAction = useAction(CompanyActions.addConfigRequested)
  const companyUpdateConfigAction = useAction(CompanyActions.updateConfigRequested)

  const meta = useSelector(CompanySelectors.meta(params.id))

  const { t } = useTranslation('company')

  const handleSubmit = useCallback(
    (formFields: FormFields, branches?: CompanyRelation[]) => {
      companyUpdateAction({
        id: params.id,
        dto: {
          country: formFields.country,
          company_type: formFields.companyType,
          description: formFields.description,
          address: formFields.address,
          bank_details: formFields.swift,
          business_id: formFields.iban,
          currency: formFields.currency,
          internal_name: formFields.internalName,
          official_name: formFields.officialName,
          account_number: formFields.accountNumber,
          slug: formFields.slug,
          short_description: formFields.shortDescription,
          postal_code: formFields.postal_code,
          regions_ids: formFields.regions_ids,
          images: formFields.images,
          is_active: formFields.is_active,
          is_seller: formFields.is_seller,
          is_supplier: formFields.is_supplier,
          is_visible: formFields.is_visible,
          sku_orders: formFields.sku_orders,
          producers_relations: formFields.producers_relations,
          rank: formFields.rank,
          territories_ids: formFields.territories,
          email: formFields.email,
          secondary_email: formFields.secondary_email,
          phone_number: formFields.phone_number,
          city: formFields.city,
          taurus_account_number: formFields.taurus_account_number,
          yara_account_number: formFields.yara_account_number,
          comment: formFields.comment,
          legal_address: formFields.legal_address,
          physical_address: formFields.physical_address,
          contacts: formFields.contacts,
        },
        branches,
      })
    },
    [companyUpdateAction, params.id],
  )

  const handleSubmitConfig = useCallback(
    (formFields: ConfigFormFields) => {
      const payload = {
        dto: {
          company_id: params.id,
          seller_config: {
            feature_flags: formFields.sellerConfig.feature_flags,
            how_it_works: formFields.howItWorks.items,
            truck_info: {
              title_i18n: formFields.sellerConfig.truck_title_i18n,
              description_i18n: formFields.sellerConfig.truck_description_i18n,
              learn_more_url: formFields.sellerConfig.truck_learn_more_url,
            },
            out_of_stock_info: {
              text_i18n: formFields.sellerConfig.out_of_stock_info_i18n,
              email: formFields.sellerConfig.out_of_stock_info_email,
            },
            order_now_hint_i18n: formFields.sellerConfig.order_now_hint_i18n,
            default_distributor_id: formFields.sellerConfig.default_distributor_id || null,
            has_exclusive_goods: formFields.sellerConfig.has_exclusive_goods,
            dashboard_text_i18n: formFields.sellerConfig.dashboard_text_i18n,
            return_declaration_date: formFields.sellerConfig.return_declaration_date ?? '',
          },
          retailer_config: {},
        },
      }

      if (company?.config) {
        const updatePayload = {
          ...payload,
          config_id: company.config.id as string,
        }

        companyUpdateConfigAction(updatePayload)
      } else {
        companyAddConfigAction(payload)
      }
    },
    [companyUpdateConfigAction, companyAddConfigAction, company?.config, params.id],
  )

  const push = useHistoryPush()
  const page = useSelector(CompanySelectors.page)
  const goBack = () => push({ route: CompaniesRoutes.List, query: { page } })

  useHelmet({ title: company ? company.official_name : t('companiesMetaTitle') })

  const data = useMemo(() => {
    if (!company) {
      return null
    }
    return {
      currency: company.currency,
      country: company.country,
      shortDescription: company.short_description,
      description: company.description,
      internalName: company.internal_name,
      officialName: company.official_name,
      accountNumber: company.account_number,
      slug: company.slug,
      companyType: company.company_type,
      postal_code: company.postal_code,
      swift: company.bank_details,
      iban: company.business_id,
      address: company.address,
      city: company.city,
      regions: company.regions || [],
      legal_address: company.legal_address,
      physical_address: company.physical_address,
      regions_ids: company.regions_ids || [],
      is_active: company.is_active,
      is_seller: company.is_seller,
      is_visible: company.is_visible,
      is_supplier: company.is_supplier,
      sku_orders: company.sku_orders,
      rank: company.rank,
      images: company.logo_url
        ? [
            {
              id: uuid.v4(),
              file: company.logo_url,
              kind: 'current' as const,
              error: null,
            },
          ]
        : [],
      producers_relations: company.producers_relations,
      territories: company.territories_ids || [],
      email: company.email,
      secondary_email: company.secondary_email,
      phone_number: company.phone_number,
      region_id: company.region_id,
      taurus_account_number: company.taurus_account_number,
      yara_account_number: company.yara_account_number,
      comment: company.comment,
      contacts: company.contacts || [],
    }
  }, [company])

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const errorUnknown = () => <ItemGenericError title={t('errors.unknownErrorTitle')} id={params.id} onBack={goBack} />
  const error404 = () => <Item404 title={t('errors.notFoundTitle')} id={params.id} onBack={goBack} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (!company || !data) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={company.official_name} />
          <CompanyId>{`ID ${company.id}`}</CompanyId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CompanyDetailsForm
          initialValues={data}
          config={company.config}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onSubmitConfig={handleSubmitConfig}
          onRemove={companyRemoveAction}
          progress={meta.updateProgress}
          removeProgress={meta.removeProgress}
          headCompanyRelation={company.head_company_relation}
          branchCompanies={branchCompanies?.permissions || []}
          editing
        />
      </Layout.Content>
    </>
  )
}

export default CompanyEdit
