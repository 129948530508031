import { ResourceHook } from 'modules/types'
import { Region, RegionListRequestFilter } from './types'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import CollectionSelectors from './selectors'
import CollectionActions from './duck'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/collection/managers'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useRegions: ResourceHook<Region[]> = (filter: RegionListRequestFilter) => {
  const progress = useSelector(CollectionSelectors.getRegionsFetchProgress)
  const regions = useSelector(CollectionSelectors.getRegions)
  const page = usePageQuery()
  const params: { filter?: RegionListRequestFilter; page?: number; pageSize?: number } = {}

  if (filter) params.filter = filter
  if (page) params.page = page
  const fetchAction = useAction(CollectionActions.regionsRequested)

  useDidMount(() => fetchAction(params))

  return [progress, regions]
}

export const useRegionsSearch = (regions: Region[] | undefined) => {
  const [search, setSearch] = useState('')

  const filteredRegions = useMemo(
    () => (search && regions ? regions.filter(opt => !opt.parent_id || opt.title.includes(search)) : regions),
    [regions, search],
  )

  return { search, setSearch, filteredRegions }
}

export const useRegionSelection = (regionsOptions: Region[] | undefined, checkedRegions: Region[]) => {
  const checkedRegionsIds = useMemo(() => checkedRegions.map(item => item.id), [checkedRegions])
  const [checked, setChecked] = useState<string[]>([])

  useEffect(() => {
    setChecked(checkedRegionsIds.map(c => c))
  }, [checkedRegionsIds])

  const onChange = useCallback(
    (id: string) => {
      if (!regionsOptions) return

      const regionToCheck = regionsOptions.find(opt => opt.id === id)
      if (!regionToCheck) return

      if (!regionToCheck.parent_id && regionsOptions.find(opt => opt.parent_id === regionToCheck.id)) {
        const childrenIds = regionsOptions.filter(opt => opt.parent_id === regionToCheck.id).map(opt => opt.id)
        const checkedChildCount = childrenIds.reduce((acc, cid) => (checked.includes(cid) ? ++acc : acc), 0)
        const isAllChecked = checkedChildCount === childrenIds.length

        if (isAllChecked) {
          setChecked(checked.filter(cid => !childrenIds.includes(cid)))
        } else {
          setChecked(checked.concat(childrenIds))
        }
      } else {
        if (checked.includes(id)) {
          setChecked(checked.filter(cid => cid !== id))
        } else {
          setChecked([...checked, id])
        }
      }
    },
    [checked, regionsOptions],
  )

  const checkAll = useCallback(() => {
    if (!regionsOptions) return
    const allOptionsIds = regionsOptions.filter(opt => opt.parent_id).map(opt => opt.id)
    setChecked(allOptionsIds)
  }, [regionsOptions])

  const clear = useCallback(() => {
    if (!regionsOptions) return
    const allOptionsIds = regionsOptions.filter(opt => opt.parent_id).map(opt => opt.id)
    setChecked(checked.filter(cid => !allOptionsIds.includes(cid)))
  }, [regionsOptions, checked])

  const options = useMemo(
    () =>
      regionsOptions &&
      regionsOptions
        .filter(opt => !opt.parent_id)
        .map(option => {
          return {
            ...option,
            options: regionsOptions
              .filter(opt => option.id === opt.parent_id)
              .map(opt => ({
                ...opt,
                isChecked: checked.includes(opt.id),
              })),
          }
        }),
    [checked, regionsOptions],
  )

  return { options, onChange, clear, checkAll, checked }
}

export const useRegionsOptions = makeCancellableResourceListHook(makeCancelable(managers.fetchRegions))
export const useTimezonesOptions = makeCancellableResourceListHook(makeCancelable(managers.fetchTimezones))
