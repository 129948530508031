import React, { useCallback } from 'react'
import UserDetailsForm, { FormData } from './UserDetailsForm/UserDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import UserRoutes from 'views/pages/User/routes'
import { CompanyType } from 'types/entities'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const UserAdd: React.FC = () => {
  const { t } = useTranslation('user')
  const push = useHistoryPush()
  const userAddAction = useAction(UserActions.addRequested)
  const page = useSelector(UserSelectors.page)

  const handleSubmit = useCallback(
    (fields: Required<FormData>) => {
      userAddAction(
        {
          email: fields.email,
          company_id:
            fields.companyType === CompanyType.Producer
              ? fields.company
              : fields.companyType === CompanyType.Distributor
              ? fields.distributor
              : null,
          related_companies: fields.relatedCompanies,
          first_name: fields.firstName,
          second_name: fields.middleName,
          last_name: fields.lastName,
          canterra_id: fields.canterraId,
          status: fields.status,
          password: fields.password as string, // TODO fix
          phone_number: fields.phone,
          role: fields.role,
          company_type: fields.companyType,
          country: fields.countryCode, // TODO fix
          regions: [],
          regions_ids: fields.regions_ids,
          farm_name: fields.farmName,
          self_pickup: fields.selfPickup,
          partnership: fields.partnership,
          legal_address: fields.legal_address,
          delivery_address: fields.delivery_address,
          territories_ids: fields.territories,
          comment: fields.comment,
        },
        false,
      )
    },
    [userAddAction],
  )

  const goBack = () => push({ route: UserRoutes.List, query: { page } })
  const progress = useSelector(UserSelectors.addProgress)
  const error = useSelector(UserSelectors.addError)

  useHelmet({ title: t('addUserMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserDetailsForm onCancel={goBack} error={error} mode={'create'} onSubmit={handleSubmit} progress={progress} />
      </Layout.Content>
    </>
  )
}

export default UserAdd
