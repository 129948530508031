import React, { useCallback, useMemo, useState } from 'react'
import { Household } from 'modules/domain/household/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import {
  Input,
  SectionBody,
  SectionContainer,
  SimpleSelect,
  useAction,
  FormComponents,
} from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as Styled from './styles'
import SearchFarmerModal from 'views/components/SearchFarmerModal/SearchFarmerModal'
import { Farmer } from 'modules/domain/farmer/types'
import styled from 'styled-components'
import { LinkButton } from 'views/pages/Auth/styled'
import { generatePath } from 'react-router'
import FarmerOrderRoutes from 'views/pages/FarmerOrder/routes'
import { IconLinkOpener, TextArea } from '@agro-club/frontend-shared'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'

export type FormData = Omit<Household, 'id'>

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 300px max-content;
  grid-gap: 16px;
`

const Link = styled(LinkButton)`
  align-self: end;
  line-height: 40px;
  display: flex;
  align-items: center;

  svg {
    fill: ${props => props.theme.color.primary500};
    margin-left: 4px;
  }
`

type FormProps = {
  initialValues?: FormData
  onCancel: () => void
  onSubmit: (data: FormData) => void
  progress: Progress
  removeProgress?: Progress
  onRemove?: () => void
  id?: string
}

type HouseholdMemberProps = {
  farmer: Farmer
  onRemove: (id: string) => void
  senior?: string
}

const HouseholdMember: React.FC<HouseholdMemberProps> = ({ farmer, onRemove, senior }) => {
  const { t } = useTranslation('household')
  const legalAddress = [
    farmer?.legal_address?.country,
    farmer?.legal_address?.city,
    farmer?.legal_address?.address,
    farmer?.legal_address?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')
  return (
    <Styled.MemberWrapper>
      <Styled.MemberInner>
        {senior === farmer.id && <Styled.MemberSenior>{t('form.seniorPartner')}</Styled.MemberSenior>}
        {!!farmer.full_name && <Styled.MemberMainText>{farmer.full_name}</Styled.MemberMainText>}
        {!!farmer.farm_name && <Styled.MemberAdditionalText>{farmer.farm_name}</Styled.MemberAdditionalText>}
        {!!farmer.phone_number && <Styled.MemberAdditionalText>{farmer.phone_number}</Styled.MemberAdditionalText>}
        {!!farmer.email && <Styled.MemberAdditionalText>{farmer.email}</Styled.MemberAdditionalText>}
        {!!legalAddress.length && <Styled.MemberAddress>{legalAddress}</Styled.MemberAddress>}
      </Styled.MemberInner>
      <Styled.MemberRemoveBtn onClick={() => onRemove(farmer.id)} />
    </Styled.MemberWrapper>
  )
}

const HouseholdDetailsForm: React.FC<FormProps> = ({
  onCancel,
  onSubmit,
  progress,
  onRemove,
  removeProgress,
  id,
  initialValues: {
    partners_ids = [],
    name = '',
    senior_partner_id = '',
    partners = [],
    senior_partner,
    comment = '',
  } = {},
}) => {
  const { t } = useTranslation(['household', 'common', 'validation'])
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false)
  const filterUpdated = useAction(FarmerOrderActions.filterUpdated)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t('validation:field_required')),
        senior_partner_id: Yup.string().required(t('validation:field_required')),
        partners_ids: Yup.array(Yup.string()).min(1, t('validation:field_required')),
      }),
    [t],
  )

  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    onSubmit(formik.values)
  }

  const formik = useFormik<FormData>({
    initialValues: {
      name,
      partners_ids,
      senior_partner_id,
      partners,
      senior_partner,
      comment: comment || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const options = useMemo(() => {
    if (!formik.values.partners?.length) {
      return []
    }
    return formik.values.partners.map(partner => ({
      id: partner.id,
      title: partner.full_name || partner.email || partner.phone_number,
    }))
  }, [formik.values.partners])

  const handleChangeSeniorPartner = useCallback(
    (id: string) => {
      formik.setFieldValue('senior_partner_id', id)
    },
    [formik],
  )

  const handleRemovePartner = useCallback(
    (id: string) => {
      const index = formik.values.partners_ids.indexOf(id)
      if (index !== -1) {
        const ids = [...formik.values.partners_ids]
        ids.splice(index, 1)
        formik.setFieldValue('partners_ids', ids)

        if (formik.values.senior_partner_id === id) {
          formik.setFieldValue('senior_partner_id', ids.length ? ids[0] : undefined)
        }

        if (formik.values.partners && formik.values.partners[index]?.id === id) {
          const partners = [...formik.values.partners]
          partners.splice(index, 1)
          formik.setFieldValue('partners', partners)
        }
      }
    },
    [formik],
  )

  const handleParticipantAdd = useCallback(
    (id: string, farmer: Farmer) => {
      if (formik.values.partners_ids.indexOf(id) === -1) {
        if (!formik.values.senior_partner_id) {
          formik.setFieldValue('senior_partner_id', id)
        }

        if (!formik.values.name && !formik.values.partners_ids.length && farmer.farm_name) {
          formik.setFieldValue('name', farmer.farm_name)
        }

        formik.setFieldValue('partners', [...(formik.values.partners as Farmer[]), farmer])
        formik.setFieldValue('partners_ids', [...formik.values.partners_ids, id])
      }
    },
    [formik],
  )

  const handleCheckIfItemDisabled = useCallback(
    farmer => {
      return formik.values.partners_ids.includes(farmer.id) ? t('form.alreadySelected') : false
    },
    [formik.values.partners_ids, t],
  )

  const handleOrdersLinkClick = useCallback(() => {
    filterUpdated({ household_id: id })
  }, [filterUpdated, id])

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Styled.Wrapper>
              <Wrapper>
                <Input
                  label={t('form.name')}
                  {...formik.getFieldProps('name')}
                  errorText={formik.errors.name}
                  invalid={formik.touched.name && !!formik.errors.name}
                  required
                />
                {id ? (
                  <Link to={generatePath(FarmerOrderRoutes.List)} onClick={handleOrdersLinkClick}>
                    {t('form.ordersLink')} <IconLinkOpener />
                  </Link>
                ) : (
                  <div />
                )}
                <TextArea label={t('form.comment')} {...formik.getFieldProps('comment')} />
              </Wrapper>
              <FormComponents.FormSection title={t('form.participants')}>
                <div>
                  {options.length ? (
                    <Styled.SelectWrapper>
                      <SimpleSelect
                        value={formik.values.senior_partner_id}
                        options={options}
                        onChange={handleChangeSeniorPartner}
                        isDisabled={!options.length}
                        placeholder={t('form.selectSenior')}
                        label={t('form.seniorPartner')}
                      />
                    </Styled.SelectWrapper>
                  ) : (
                    <Styled.EmptyText>{t('form.emptyText')}</Styled.EmptyText>
                  )}
                  <Styled.ListWrapper>
                    {formik.values.partners?.map(partner => (
                      <HouseholdMember
                        key={partner.id}
                        farmer={partner}
                        onRemove={handleRemovePartner}
                        senior={formik.values.senior_partner_id}
                      />
                    ))}
                  </Styled.ListWrapper>
                  <Styled.AddButton onClick={() => setSearchModalIsOpen(true)}>
                    {t('form.addParticipant')}
                  </Styled.AddButton>
                </div>
              </FormComponents.FormSection>
              <SearchFarmerModal
                isOpen={searchModalIsOpen}
                onClose={() => setSearchModalIsOpen(false)}
                onSelect={handleParticipantAdd}
                checkIfDisabled={handleCheckIfItemDisabled}
              />
            </Styled.Wrapper>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterDefaultControls
        onRemove={onRemove}
        onSave={formik.submitForm}
        onCancel={onCancel}
        saveProgress={progress}
        isSaveDisabled={!formik.dirty || progress === Progress.WORK}
        removeProgress={removeProgress}
        popoverText={t('form.removeText', { name })}
      />
    </StickyFooterLayout.Wrapper>
  )
}

export default HouseholdDetailsForm
