import React, { CSSProperties } from 'react'
import { Chip, ChipColor } from '@agro-club/frontend-shared'
import * as Styled from './styles'
import { StrictRecord } from 'types/generics'

type FilterValue<TOption, TDisableMulti = false> = TDisableMulti extends true
  ? undefined | TOption
  : undefined | TOption | TOption[]

export function getMultiOptionsFilterValue<TOption>(
  value: TOption,
  filterValue: FilterValue<TOption>,
): FilterValue<TOption> {
  if (!filterValue) {
    return value
  }

  if (filterValue === value) {
    return undefined
  }

  const newFilterValue = Array.isArray(filterValue) ? [...filterValue] : [filterValue]

  if (newFilterValue.includes(value)) {
    newFilterValue.splice(newFilterValue.indexOf(value), 1)
    return newFilterValue.length > 1 ? newFilterValue : newFilterValue[0]
  } else {
    newFilterValue.push(value)
    return newFilterValue
  }
}

export function isOptionActive(value: unknown, filterValue?: unknown | unknown[]): boolean {
  if (typeof filterValue !== 'undefined') {
    return Array.isArray(filterValue) ? filterValue.includes(value) : filterValue === value
  }

  return false
}

export type TableFilterVariant = 'small' | 'default'

export const Filter: React.FC<{
  title: string
  className?: string
  customStyle?: CSSProperties
  variant?: TableFilterVariant
  'data-test-id'?: string
}> = ({ children, title, className, customStyle, variant, 'data-test-id': testId }) => {
  return (
    <Styled.Filter className={className} style={customStyle} data-test-id={testId}>
      <Styled.FilterTitle variant={variant}>{title}</Styled.FilterTitle>
      <Styled.FilterOptions>{children}</Styled.FilterOptions>
    </Styled.Filter>
  )
}

type FilterValueObj<TShape, TField extends keyof TShape, TOption, TDisableMulti = false> = StrictRecord<
  TShape,
  TField,
  FilterValue<TOption, TDisableMulti>
>

export type FilterOption<TOption> = {
  value: TOption
  title: string | React.ReactElement
  bullet?: boolean
  color?: ChipColor
}
export type FilterProps<TShape, TField extends keyof TShape, TOption, TDisableMulti> = {
  handleFilterChange: (filter: FilterValueObj<TShape, TField, TOption, TDisableMulti>) => void
  title: string
  options: FilterOption<TOption>[]
  filterValue: FilterValueObj<TShape, TField, TOption, TDisableMulti>
  disableMulti?: TDisableMulti
  customStyles?: CSSProperties
  variant?: TableFilterVariant
  'data-test-id'?: string
}

export function TableFilter<TShape, TField extends keyof TShape, TOption, TDisableMulti>({
  handleFilterChange,
  options,
  title,
  filterValue,
  customStyles,
  disableMulti,
  variant,
  'data-test-id': dataTestId,
}: FilterProps<TShape, TField, TOption, TDisableMulti>) {
  const [field, value] = Object.entries(filterValue)[0] as [TField, FilterValue<TOption, TDisableMulti>]
  const onClick = (newValue: TOption) => {
    let newFilterValue
    if (!disableMulti) {
      newFilterValue = getMultiOptionsFilterValue<TOption>(newValue, value)
    } else {
      newFilterValue = value === newValue ? undefined : newValue
    }
    handleFilterChange({ [field]: newFilterValue } as FilterValueObj<TShape, TField, TOption, TDisableMulti>)
  }

  return (
    <Filter title={title} customStyle={customStyles} variant={variant} data-test-id={dataTestId}>
      {options.map(option => (
        <Chip
          value={(option.value as unknown) as string}
          onClick={onClick}
          active={isOptionActive(option.value, value)}
          key={`${option.value}`}
          color={option.color}
          bullet={option.bullet}
          data-test-id={option.value}
        >
          {option.title}
        </Chip>
      ))}
    </Filter>
  )
}
