import {
  Button,
  Checkbox,
  FormComponents,
  helpersSlug,
  SectionBody,
  SectionContainer,
  useFormManager,
} from '@agro-club/frontend-shared'
import { FormikProvider, useFormik } from 'formik'
import { createArrayHandler } from 'helpers/createArrayHandler'
import { createLocalizedValue } from 'helpers/localization'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { CountryCode } from 'libphonenumber-js'
import { DEFAULT_COUNTRY } from 'modules/constants'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { Progress } from 'modules/types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AvailableLanguages, availableLanguagesList, LocalizedValue } from 'types/entities'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import { FileItem } from 'views/components/FileManager/types'
import ImageForm from 'views/components/ImageForm/ImageForm'
import SlugForm from 'views/components/SlugForm/SlugForm'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import * as Yup from 'yup'
import AboutForm, { AboutFormProps } from './AboutForm'
import * as Styled from './styled'

export type FormProps = {
  parent_id: string | null
  seller_ids: string[]
  country: CountryCode
  slug?: string
  title: LocalizedValue
  description?: LocalizedValue
  discountText?: LocalizedValue
  files: FileItem[]
}

export type FilesFormProps = {
  files: FileItem[]
}

type LangData = Required<LocalizedValue<AboutFormProps>>
type FormikManagerData = LangData & {
  slug: { slug: string }
  settings: {
    parent_id: string | null
    country: CountryCode
  }
  files: FilesFormProps
}

const validationSchema = Yup.object({
  country: Yup.string(),
  parent_id: Yup.string().nullable(),
})

const CategoryDetailsForm: React.FC<{
  onSubmit(form: FormProps): void
  initialValues?: Partial<FormProps>
  progress?: Progress
  editing?: boolean
  onCancel(): void
}> = ({ onSubmit, progress, onCancel, initialValues = {}, editing = false }) => {
  const { t } = useTranslation(['category', 'common'])
  const { bind, submitAll, dirty, slots, valid } = useFormManager<FormikManagerData>()
  const [submitCount, setSubmitCount] = useState(0)
  const formik = useFormik<Partial<FormProps>>({
    initialValues: {
      country: initialValues.country || DEFAULT_COUNTRY,
      parent_id: initialValues?.parent_id,
      seller_ids: initialValues?.seller_ids,
      slug: initialValues?.slug || '',
    },
    validationSchema,
    onSubmit: formProps => {
      onSubmit(formProps as FormProps)
    },
  })
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.title?.[lang], initialValues?.description?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })

    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      setSubmitCount(submitCount + 1)
      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)
      onSubmit({
        country: formik.values.country || 'CA',
        parent_id: formik.values.parent_id || null,
        seller_ids: formik.values.seller_ids || [],
        slug: forms.slug.slug,
        files: forms.files.files,
        discountText: createLocalizedValue(lang => langProp(lang, forms[lang]?.discountText) as string),
        description: createLocalizedValue(lang => langProp(lang, forms[lang]?.description) as string),
        title: createLocalizedValue(lang => langProp(lang, forms[lang]?.title) as string),
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSellerChange = useCallback(
    v => formik.setFieldValue('seller_ids', v),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLangChange = useCallback(
    createArrayHandler(languages, setLanguages),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languages],
  )

  useValidationErrorNotification(submitCount, valid)
  const isSaveBtnDisabled = (!dirty && !formik.dirty) || isFilesUploading || progress === Progress.WORK
  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <SectionContainer>
            <SectionBody>
              <Styled.Wrapper>
                <Styled.SettingsBlock title={t('form.settingsTitle')}>
                  <CategorySelect
                    value={formik.values.parent_id as string}
                    onChange={v => formik.setFieldValue('parent_id', v)}
                    invalid={formik.touched.parent_id && !!formik.errors.parent_id}
                    errorText={formik.errors.parent_id}
                    label={t('form.parentCategory')}
                  />
                  <CompanyMultiSelect
                    isSearchable
                    isClearable
                    filter={{ is_seller: true }}
                    label={t('form.sellers')}
                    placeholder={t('form.placeholders.sellers')}
                    values={formik.values.seller_ids}
                    onChange={handleSellerChange}
                  />
                  <CountrySelect
                    label={t('labels:country')}
                    value={formik.values.country}
                    onChange={v => formik.setFieldValue('country', v)}
                  />
                </Styled.SettingsBlock>
                <FormComponents.FormSection title={t('form.title')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang)}
                          value={lang}
                          onChange={handleLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <AboutForm
                            key={lang}
                            lang={lang}
                            title={initialValues.title}
                            description={initialValues.description}
                            discountText={initialValues.discountText}
                            useFormik={bind(lang)}
                            onTitleChange={val => {
                              if (!editing && lang === 'en') {
                                slots['slug'].setFieldValue('slug', helpersSlug.slugify(val))
                              }
                            }}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.imagesTitle')}>
                  <ImageForm useFormik={bind('files')} files={initialValues.files} />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.slug')}>
                  <SlugForm useFormik={bind('slug')} initialValue={initialValues.slug} disabled={editing} />
                </FormComponents.FormSection>
              </Styled.Wrapper>
            </SectionBody>
          </SectionContainer>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            filled={true}
            progress={progress}
            disabled={isSaveBtnDisabled}
            onClick={submit}
            intent={'primary'}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default CategoryDetailsForm
