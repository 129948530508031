import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { IncentiveCampaign } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.incentiveCampaign.items
const ids = (state: AppGlobalState) => state.incentiveCampaign.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (id?: string) => (state: AppGlobalState): IncentiveCampaign | undefined =>
  id ? state.incentiveCampaign.items[id] : undefined
const meta = (id?: string) => (state: AppGlobalState): EntityMetadata<IncentiveCampaign> =>
  id && state.incentiveCampaign.meta[id]
    ? state.incentiveCampaign.meta[id]
    : {
        fetchError: null,
        fetchProgress: Progress.IDLE,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }
const itemFetchProgress = (state: AppGlobalState) => state.incentiveCampaign.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.incentiveCampaign.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.incentiveCampaign.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.incentiveCampaign.listFetchErrorDetail
const listFetchNextProgress = (state: AppGlobalState) => state.incentiveCampaign.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.incentiveCampaign.listFetchNextError
const listFetchNextErrorDetail = (state: AppGlobalState) => state.incentiveCampaign.listFetchNextErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: IncentiveCampaign[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.incentiveCampaign.filter
const sorting = (state: AppGlobalState) => state.incentiveCampaign.sorting
const page = (state: AppGlobalState) => state.incentiveCampaign.page
const total = (state: AppGlobalState) => state.incentiveCampaign.total
const updateProgress = (state: AppGlobalState) => state.incentiveCampaign.updateProgress
const removeProgress = (state: AppGlobalState) => state.incentiveCampaign.removeProgress
const addProgress = (state: AppGlobalState) => state.incentiveCampaign.addProgress
const hasNext = (state: AppGlobalState) => state.incentiveCampaign.total > state.incentiveCampaign.ids.length
const pageSize = (state: AppGlobalState) => state.incentiveCampaign.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.incentiveCampaign.total / state.incentiveCampaign.pageSize)

const IncentiveCampaignSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  listFetchNextErrorDetail,
  pages,
}

export default IncentiveCampaignSelectors
