import { Progress, ResourceHook } from 'modules/types'
import { useCombinedProgress } from 'hooks/useCombinedProgress'

type Hook<T extends ResourceHook<unknown>> = [T, Parameters<T>]
type HookData<T extends ResourceHook<unknown>> = ReturnType<T>[1]

function useComposedResourceHooks<R1 extends ResourceHook<unknown>>(
  hook1: Hook<R1> | R1,
): readonly [Progress, HookData<R1>]
function useComposedResourceHooks<R1 extends ResourceHook<unknown>, R2 extends ResourceHook<unknown>>(
  hook1: Hook<R1> | R1,
  hook2: Hook<R2> | R2,
): readonly [Progress, HookData<R1>, HookData<R2>]
function useComposedResourceHooks<
  R1 extends ResourceHook<unknown>,
  R2 extends ResourceHook<unknown>,
  R3 extends ResourceHook<unknown>
>(
  hook1: Hook<R1> | R1,
  hook2: Hook<R2> | R2,
  hook3: Hook<R3> | R3,
): readonly [Progress, HookData<R1>, HookData<R2>, HookData<R3>]
function useComposedResourceHooks<
  R1 extends ResourceHook<unknown>,
  R2 extends ResourceHook<unknown>,
  R3 extends ResourceHook<unknown>,
  R4 extends ResourceHook<unknown>
>(
  hook1: Hook<R1> | R1,
  hook2: Hook<R2> | R2,
  hook3: Hook<R4> | R4,
  hook4: Hook<R4> | R4,
): readonly [Progress, HookData<R1>, HookData<R2>, HookData<R3>, HookData<R4>]
function useComposedResourceHooks<
  R1 extends ResourceHook<unknown>,
  R2 extends ResourceHook<unknown>,
  R3 extends ResourceHook<unknown>,
  R4 extends ResourceHook<unknown>,
  R5 extends ResourceHook<unknown>
>(
  hook1: Hook<R1> | R1,
  hook2: Hook<R2> | R2,
  hook3: Hook<R4> | R4,
  hook4: Hook<R4> | R4,
  hook5: Hook<R5> | R5,
): readonly [Progress, HookData<R1>, HookData<R2>, HookData<R3>, HookData<R4>, HookData<R5>]
function useComposedResourceHooks<
  R1 extends ResourceHook<unknown>,
  R2 extends ResourceHook<unknown>,
  R3 extends ResourceHook<unknown>,
  R4 extends ResourceHook<unknown>,
  R5 extends ResourceHook<unknown>,
  R6 extends ResourceHook<unknown>
>(
  hook1: Hook<R1> | R1,
  hook2: Hook<R2> | R2,
  hook3: Hook<R4> | R4,
  hook4: Hook<R4> | R4,
  hook5: Hook<R5> | R5,
  hook6: Hook<R6> | R6,
): readonly [Progress, HookData<R1>, HookData<R2>, HookData<R3>, HookData<R4>, HookData<R5>, HookData<R6>]
/* if you need more arguments, please do it by yourself */

function useComposedResourceHooks(
  ...args: (Hook<ResourceHook<unknown>> | ResourceHook<unknown>)[]
): readonly [Progress, ...unknown[]] {
  const result: unknown[] = []
  const progressList: Progress[] = []
  for (const fn of args) {
    if (Array.isArray(fn)) {
      const [callable, args] = fn
      const [progress, data] = callable(...args)
      result.push(data)
      progressList.push(progress)
    } else {
      const [progress, data] = fn()
      result.push(data)
      progressList.push(progress)
    }
  }

  const resultProgress = useCombinedProgress(progressList)

  return [resultProgress, ...result]
}

export default useComposedResourceHooks
