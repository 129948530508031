import * as managers from 'modules/domain/company/managers'
import { Company, CompanyListRequestFilter } from 'modules/domain/company/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { CompanyType } from 'types/entities'
import { Dict } from 'types/generics'
import { EntityMultiSelect, EntityMultiSelectProps } from '../EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { getCompanyLabel, getCompanyValue } from './CompanySelect'
import { useFilterMemo } from 'hooks/useFilterMemo'

export type CompanyMultiSelectProps = Omit<EntityMultiSelectProps<Company>, 'getOptionLabel' | 'getOptionValue'> & {
  companyType?: CompanyType
  filter?: CompanyListRequestFilter
  onChange?: (newValues: string[], companies: MultiValue<Company>, headCompany: MultiValue<Company>) => void
  showLabel?: boolean
}

export const CompanyMultiSelect: React.VFC<CompanyMultiSelectProps> = ({
  companyType,
  onChange,
  placeholder,
  showLabel,
  filter,
  ...props
}) => {
  const { t } = useTranslation('CompanySelect')
  const memoizedFilter = useFilterMemo<CompanyListRequestFilter>({
    company_type: companyType,
    ...filter,
  })

  const handleChange = (newValues: string[], companies: MultiValue<Company>, dict: Dict<Company>) => {
    onChange?.(
      newValues,
      companies,
      companies
        .map(company =>
          company?.head_company_relation?.company_id && dict[company.head_company_relation.company_id]
            ? dict[company.head_company_relation.company_id]
            : null,
        )
        .filter(Boolean) as MultiValue<Company>,
    )
  }

  const companyTypeNormalized = companyType || 'company'

  return (
    <SelectTestWrapper data-test-id={`${companyType || 'company'}-multi-select`}>
      <EntityMultiSelect
        getEntityList={managers.getCompanyList}
        getEntityByIds={managers.getItemsByIds}
        filter={memoizedFilter}
        getOptionLabel={getCompanyLabel}
        getOptionValue={getCompanyValue}
        onChange={handleChange}
        placeholder={placeholder || t(`${companyTypeNormalized}Placeholder`)}
        label={showLabel ? t(`${companyTypeNormalized}Label`) : ''}
        {...props}
      />
    </SelectTestWrapper>
  )
}
