import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
import TargetSkuRoutes from './routes'
import TargetSkuList from './TargetSkuList'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'

const TargetSkuPage: React.FC = () => {
  const [showTerritoryTab, showRetailerTab, showFarmerTab, showCropTab] = usePermissions([
    { capability: CAPABILITY.TERRITORY_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.RETAILER_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.FARMER_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.CROP_TARGETS, permission: PERMISSION.R },
  ])
  return (
    <Switch>
      {showTerritoryTab && (
        <Route path={[TargetSkuRoutes.TerritoryList, TargetSkuRoutes.DefaultList]} exact={true}>
          <Redirect to={TargetSkuRoutes.TerritoryList} />
          <TargetSkuList targetType={TargetSkuTypes.Territory} />
        </Route>
      )}

      {showRetailerTab && (
        <Route
          path={[TargetSkuRoutes.TerritoryList, TargetSkuRoutes.RetailerList, TargetSkuRoutes.DefaultList]}
          exact={true}
        >
          <Redirect to={TargetSkuRoutes.RetailerList} />
          <TargetSkuList targetType={TargetSkuTypes.Retailer} />
        </Route>
      )}

      {showFarmerTab && (
        <Route
          path={[
            TargetSkuRoutes.TerritoryList,
            TargetSkuRoutes.RetailerList,
            TargetSkuRoutes.FarmerList,
            TargetSkuRoutes.DefaultList,
          ]}
          exact={true}
        >
          <Redirect to={TargetSkuRoutes.FarmerList} />
          <TargetSkuList targetType={TargetSkuTypes.Farmer} />
        </Route>
      )}
      {showCropTab && (
        <Route path={[TargetSkuRoutes.CropList, TargetSkuRoutes.DefaultList]} exact={true}>
          <Redirect to={TargetSkuRoutes.CropList} />
          <TargetSkuList targetType={TargetSkuTypes.Crop} />
        </Route>
      )}
    </Switch>
  )
}

export default TargetSkuPage
