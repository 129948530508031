import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DatePicker,
  Input,
  TextArea,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useAction,
  SimpleSelect,
  FormComponents,
} from '@agro-club/frontend-shared'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import i18n from 'i18n'
import { Progress } from 'modules/types'
import { CAPABILITY, PERMISSION, usePermissions, useRole } from 'modules/permissions/permissions'
import {
  Actions,
  CompanyType,
  isDistributor,
  isProducersManager,
  isUsersCompanyDistributor,
  isUsersCompanyProducer,
  Sections,
  FeatureFlagModifiers,
} from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Company } from 'modules/domain/company/types'
import CompanyRoutes from 'views/pages/Company/routes'
import { useCompanyById } from 'modules/domain/company/hooks'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import ReturnDeclarationSkuActions from 'modules/domain/returnDeclarationSku/duck'
import ReturnDeclarationSkuSelectors from 'modules/domain/returnDeclarationSku/selectors'
import { useSeasonList } from 'modules/domain/season/hooks'
import { useConfig } from 'modules/domain/config/hooks'
import SeasonSelectors from 'modules/domain/season/selectors'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormat from 'hooks/useDateFormat'
import * as Styled from 'views/components/ReturnDeclarationDetailsForm/styled'
import { generateActionAccessString, generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import CompanyInfoPreview from 'views/components/CompanyInfoPreview/CompanyInfoPreview'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { PreviewContainer } from 'views/components/PreviewContainer/PreviewContainer'
import { ConfirmPopupModal } from 'views/components/ReturnDeclarationDetailsForm/ConfirmPopupModal'
import { useReturnDeclarationSkuCounter } from 'modules/domain/returnDeclarationSku/hooks'
import ReturnSkuItemsReadyOnly from './ReturnSkuItemsReadOnly'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'
import { ReturnDeclarationSku, ReturnDeclarationSkuItem } from 'modules/domain/returnDeclarationSku/types'
import ReturnSkuItems from './ReturnSkuItems'
import useLangPicker from 'hooks/useLangPicker'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { noop } from 'helpers/noop'
import ProgressContainer from 'views/components/ProgressContainer/ProgressContainer'
import { clone, remove } from 'ramda'
import { StorefrontCardForSelect } from 'modules/domain/storefrontCard/types'
import * as uuid from 'uuid'
import { fakeSku } from 'modules/domain/storefront/helpers'
import { SkuOption, StorefrontSku } from 'modules/domain/storefront/types'
import { getStandardUnitsQty } from 'views/components/OrderSkuCardItems/helpers'

type ConfirmPopupPropsType = {
  handleCloseModal: () => void
  handleSubmit?: () => void
  isOpen: boolean
  error: string
}

const ConfirmPopup: FC<ConfirmPopupPropsType> = ({
  handleCloseModal,
  handleSubmit,
  isOpen,
  error,
}: ConfirmPopupPropsType) => {
  const { t } = useTranslation('returnDeclaration')

  if (error) {
    return (
      <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={t('modal.error')}>
        <Styled.ModalButton intent={'primary'} filled onClick={handleCloseModal} data-test-id={'modal-error-button'}>
          {t('modal.ok')}
        </Styled.ModalButton>
      </ConfirmPopupModal>
    )
  } else {
    return (
      <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={t('modal.confirm')}>
        <Styled.ModalButton intent={'cancel'} filled onClick={handleCloseModal}>
          {t('modal.cancel')}
        </Styled.ModalButton>
        <Styled.ModalButton
          data-test-id={'modal-submit-button'}
          intent={'primary'}
          filled
          onClick={() => {
            handleSubmit?.()
            handleCloseModal()
          }}
        >
          {t('modal.submit')}
        </Styled.ModalButton>
      </ConfirmPopupModal>
    )
  }
}

type InformationPopupPropsType = {
  handleCloseModal: () => void
  seasonName: string
  isOpen: boolean
}

const InformationPopup: FC<InformationPopupPropsType> = ({ seasonName, handleCloseModal, isOpen }) => {
  const { t } = useTranslation('returnDeclaration')
  const [, config] = useConfig()

  const phone = config?.contacts?.phone?.value
  const messageText = t('modal.limited', {
    phoneLink: `<a href="tel:${phone}" target=_blank>${phone}</a>`,
    seasonName,
  })

  return (
    <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={messageText}>
      <Styled.ModalButton intent={'primary'} filled onClick={handleCloseModal} data-test-id={'modal-ok-button'}>
        {t('modal.ok')}
      </Styled.ModalButton>
    </ConfirmPopupModal>
  )
}

export type FormFields = {
  distributor_id: string
  status: ReturnDeclarationStatus
  sku_items?: ReturnDeclarationSkuItem[]
  type?: ReturnDeclarationType
  pickup_note?: string
  declaration_date?: string
  season_id?: string
  comment?: string
  seller_id?: string | null
}

type Props = {
  mode: 'edit' | 'create'
  onSubmit(values: FormFields, options: { dirty: boolean; duplicate: boolean }): void
  onChangeStatusToSubmit?(): void
  onCancel(): void
  onProducerConfirmation?: () => void
  onCancelDeclaration?: () => void
  onRemove?: () => void
  progress?: Progress
  removeProgress?: Progress
  initialValues: FormFields
  returnDeclaration?: ReturnDeclarationSku
}

const ReturnDeclarationSkuDetailsForm: React.FC<Props> = ({
  returnDeclaration,
  initialValues,
  onRemove,
  onChangeStatusToSubmit,
  onCancel,
  onSubmit,
  removeProgress,
  progress,
  mode,
}) => {
  const checkFeatureFlag = useFeatureFlags()
  const { t } = useTranslation(['returnDeclaration', 'common', 'company', 'labels'])
  const { pick } = useLangPicker()
  const [isRemovePermitted, isUpdatePermitted] = usePermissions([
    { capability: CAPABILITY.RETURN_DECLARATIONS_SKU, permission: PERMISSION.D },
    { capability: CAPABILITY.RETURN_DECLARATIONS_SKU, permission: PERMISSION.U },
  ])
  const dateFormat = useDateFormat({ withTime: true })
  const role = useRole()
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const isEditRestricted =
    mode === 'edit' &&
    checkFeatureFlag(
      generateActionAccessString(Sections.ReturnDeclarations, Actions.Update, FeatureFlagModifiers.Restricted),
    )

  const [, seasons] = useSeasonList()
  const currentSeason = useSelector(SeasonSelectors.currentSeason)
  const seasonId = isDistributor(role) ? currentSeason?.id : initialValues.season_id

  const [selectedDistributor, setSelectedDistributor] = useState<Company | null>(
    returnDeclaration?.distributor || (isUsersCompanyDistributor(userCompany) && userCompany) || null,
  )

  const [headCompanyProgress, headCompany] = useCompanyById(selectedDistributor?.head_company_relation?.company_id)
  const [headDistributor, setHeadDistributor] = useState<Company | null>(headCompany || null)

  const [isOpen, setIsOpen] = useState(false)
  const [showInformationPopup, setShowInformationPopup] = useState(false)

  const [skuMatchingErrors, setSkuMatchingErrors] = useState<boolean[]>([])

  const [nothingToReturn, setNothingToReturn] = useState<boolean>(
    initialValues.status === ReturnDeclarationStatus.NothingToDeclare || false,
  )

  const initialSellerId =
    initialValues.seller_id ||
    (isUsersCompanyProducer(userCompany) && userCompany?.id) ||
    (isUsersCompanyDistributor(userCompany) &&
      userCompany?.producers_relations?.length === 1 &&
      userCompany.producers_relations[0]) ||
    undefined

  const currentDate = useMemo(() => new Date().toISOString(), [])

  const printReturnDeclarationAction = useAction(ReturnDeclarationSkuActions.printRequested)
  const printDeclarationProgress = useSelector(ReturnDeclarationSkuSelectors.printProgress)

  const validationSchema = useMemo(() => {
    if (nothingToReturn) return Yup.object({})

    return Yup.object({
      distributor_id: Yup.string().required(i18n.t('validation:field_required')),
      seller_id: Yup.string().required(i18n.t('validation:field_required')),
      declaration_date: Yup.string().required(i18n.t('validation:field_required')),
      status: Yup.string(),
      comment: Yup.string(),
      season_id: Yup.string().required(i18n.t('validation:field_required')),
      type: Yup.string().required(i18n.t('validation:field_required')),
      sku_items: Yup.array(
        Yup.object({
          lot_number: Yup.string().required(i18n.t('validation:field_required_short')),
        }),
      ).min(1, i18n.t('validation:oneProductMinRequired')),
    })
  }, [nothingToReturn])

  const formik = useFormik<FormFields>({
    initialValues: {
      seller_id: initialSellerId,
      distributor_id: initialValues.distributor_id || (isUsersCompanyDistributor(userCompany) && userCompany?.id) || '',
      status: initialValues.status,
      comment: initialValues.comment || '',
      pickup_note: initialValues.pickup_note,
      declaration_date: initialValues.declaration_date || currentDate,
      sku_items: initialValues?.sku_items || [],
      season_id: seasonId,
      type: initialValues.type,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: noop,
  })

  useEffect(() => {
    setHeadDistributor(headCompany ?? null)
  }, [selectedDistributor?.head_company_relation?.company_id, headCompany, formik.values.seller_id])

  const [, producer] = useCompanyById(formik.values.seller_id as string)

  const seasonName = useMemo(() => {
    let name = ''
    seasons?.map(season => {
      if (season.id === formik.values.season_id) name = season.title
    })
    return name
  }, [formik.values.season_id, seasons])

  const { count, countProgress, error } = useReturnDeclarationSkuCounter({
    distributor_id: formik.values.distributor_id,
    season_id: formik.values.season_id,
    status: 'submitted',
    type: ReturnDeclarationType.EndOfSeason,
  })

  const isSeasonDeclarationExists = useMemo(
    () => mode === 'create' && countProgress === Progress.SUCCESS && count && count > 0 && !isAdmin,
    [count, countProgress, isAdmin, mode],
  )

  useEffect(() => {
    if (isSeasonDeclarationExists) {
      setShowInformationPopup(true)
    }
  }, [countProgress, isSeasonDeclarationExists, mode])

  const handlePrintDeclaration = async () => {
    if (!formik.isValid) return

    printReturnDeclarationAction({
      pickUpLocation: formik.values.pickup_note || '',
      dealerName: selectedDistributor?.official_name || '',
      date: producer?.config?.seller_config?.return_declaration_date || '',
      isSku: true,
      products: (formik.values.sku_items || initialValues.sku_items || []).map(item => {
        const seedTreatment = item.sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
        const packageTitle = item.sku?.params.package?.title || ''
        const qty = Number(item.quantity) || 0
        const standardUnits = getStandardUnitsQty(qty, item.sku?.product?.units, item.sku?.params.package)

        return {
          variety: pick(item.product_card?.title_i18n) || '',
          seedTreatment: seedTreatment?.title || '',
          lot: item.lot_number || '',
          count: qty,
          unitSize: pick(item.sku?.product?.units.singular_i18n) || '',
          packageTitle,
          standardUnits,
        }
      }),
    })
  }

  const shouldShowConfirmSubmit = !!(isDistributor(role) && userCompany)

  const handleChangeStatusToSubmit = async () => {
    await formik.submitForm()

    if (!formik.isValid) return
    if (isSeasonDeclarationExists) return setShowInformationPopup(true)
    if (shouldShowConfirmSubmit) return setIsOpen(true)

    onChangeStatusToSubmit?.()
  }

  const allSkuMatched = useMemo(() => {
    const skuItems = formik.values.sku_items || []
    const errors: boolean[] = []
    let isAllMatched = true

    skuItems.forEach((item: ReturnDeclarationSkuItem) => {
      const skuMatched = !!item.sku_id
      if (!skuMatched) isAllMatched = false
      errors.push(skuMatched)
    })

    setSkuMatchingErrors(errors)
    return isAllMatched
  }, [formik.values.sku_items])

  const cardSelectError = useMemo(
    () =>
      formik.touched.sku_items && formik.errors.sku_items && typeof formik.errors.sku_items === 'string'
        ? formik.errors.sku_items
        : null,
    [formik.errors.sku_items, formik.touched.sku_items],
  )

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()
      if (!formik.isValid || !allSkuMatched) return

      if (duplicate) {
        const skuItems = formik.values.sku_items || []
        skuItems.map(item => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...rest } = { ...item }
          return rest
        })
        formik.setFieldValue('sku_items', skuItems)
      }

      onSubmit(formik.values, {
        dirty: formik.dirty,
        duplicate,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = async () => {
    try {
      if (isSeasonDeclarationExists) {
        setShowInformationPopup(true)
        return
      }

      await formik.submitForm()
      if (!formik.isValid) return

      submit(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleNothingToReturn = () => {
    if (formik.values.status === ReturnDeclarationStatus.NothingToDeclare) {
      formik.setFieldValue('status', ReturnDeclarationStatus.New)
    } else {
      formik.setFieldValue('sku_items', [])
      formik.setFieldValue('status', ReturnDeclarationStatus.NothingToDeclare)
    }
    setNothingToReturn(!nothingToReturn)
  }

  const isSubmitted = returnDeclaration?.status === ReturnDeclarationStatus.Submitted
  const isSellerChangeAllowed = !returnDeclaration

  const onDistributorChange = useCallback(
    (value?: string, distributor?: Company | null) => {
      formik.setFieldValue('distributor_id', value)
      setSelectedDistributor(distributor || null)
    },
    [formik],
  )

  const onProducerChange = useCallback(
    (id?: string) => {
      formik.setFieldValue('seller_id', id)
      const producerSeasons = (seasons ?? []).filter(season => season.company_id === id)
      const currentSeason = producerSeasons?.find(season => season.is_current)
      const defaultSeason = producerSeasons?.find(season => season.is_default_for_return_declarations)
      const selectedSeason = defaultSeason || currentSeason

      formik.setFieldValue('season_id', selectedSeason?.id)

      if (id !== formik.values.seller_id) {
        formik.setFieldValue('distributor_id', null)
        setSelectedDistributor(null)
      }
    },
    [seasons, formik],
  )

  const handleCardSelect = (id: string | undefined, card: StorefrontCardForSelect | null) => {
    const skuItems = clone(formik.values.sku_items) || []

    if (card) {
      const item: ReturnDeclarationSkuItem = {
        product_card_id: id || '',
        product_card: card,
        quantity: '1',
        key: uuid.v4(),
      }

      skuItems.unshift(item)
      formik.setFieldValue('sku_items', skuItems)
    } else {
      console.warn("Couldn't find card with provided id")
    }
  }

  const declarationTypeOptions = useMemo(() => {
    return [
      {
        id: ReturnDeclarationType.EndOfSeason,
        title: t('type.endOfSeason'),
      },
      {
        id: ReturnDeclarationType.InSeason,
        title: t('type.inSeason'),
      },
    ]
  }, [t])

  useValidationErrorNotification(formik.submitCount, formik.isValid)
  const isSubmitPermitted =
    !!returnDeclaration &&
    returnDeclaration.status === ReturnDeclarationStatus.New &&
    (isDistributor(role) || isProducersManager(role) || isAdmin)

  const isReturnNothingPermitted =
    !!(
      !isEditRestricted &&
      !returnDeclaration &&
      formik.values.seller_id &&
      formik.values.distributor_id &&
      (isDistributor(role) || isAdmin)
    ) || isAdmin

  const handlePackageTypeChange = useCallback(
    (idx, value: string) => {
      const items = clone(formik.values.sku_items) || []
      if (idx !== -1) {
        if (!items[idx].sku) {
          items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              package_id: value,
              product_id: items[idx].product_card?.product_id,
            },
          }
        } else {
          ;(items[idx].sku as StorefrontSku).params.package_id = value
          items[idx].sku_id = undefined
        }
      }
      formik.setFieldValue('sku_items', items)
    },
    [formik],
  )
  const handleOptionsChange = useCallback(
    (idx, value: SkuOption[]) => {
      const items = clone(formik.values.sku_items) || []
      if (idx !== -1) {
        if (!items[idx].sku) {
          items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              options: value,
              product_id: items[idx].product_card?.product_id,
            },
          }
        } else {
          ;(items[idx].sku as StorefrontSku).params.options = value
        }
      }
      formik.setFieldValue('sku_items', items)
    },
    [formik],
  )

  const handleSkuMatched = useCallback(
    (idx, value?: StorefrontSku) => {
      if (idx === -1) return
      const items = clone(formik.values.sku_items) || []

      if (value) {
        const numberMinQty = Number(value.min_qty || 1)
        const numberMaxQty = Number(value.max_qty)
        const numberItemQty = Number(items[idx].quantity)

        if (
          (items[idx].sku_id !== value?.id && !items[idx].quantity) ||
          numberItemQty < numberMinQty ||
          (value.max_qty && numberItemQty > numberMaxQty)
        ) {
          items[idx].quantity = value.default_qty || value.min_qty || '1'
        }

        items[idx].sku = value
        items[idx].sku_id = value?.id
      } else {
        items[idx].sku = undefined
        items[idx].sku_id = undefined
      }

      formik.setFieldValue('sku_items', items)
    },
    [formik],
  )

  const handleRemove = useCallback(
    (idx: number) => {
      const items = clone(formik.values.sku_items) || []
      formik.setFieldValue('sku_items', remove(idx, 1, items))
    },
    [formik],
  )

  const handleQtyInputChange = useCallback(
    (idx: number, value: number) => {
      const update = (index: number, value: number) => {
        const items = clone(formik.values.sku_items) || []
        if (items[index]) items[index].quantity = String(value)
        formik.setFieldValue('sku_items', items)
      }

      if (!Number.isNaN(value)) {
        update(idx, value)
      } else {
        update(idx, 0)
      }
    },
    [formik],
  )

  return (
    <>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Styled.Wrapper>
            <Styled.Column>
              <SectionContainer>
                <SectionBody>
                  <FormComponents.FormSection title={t('form.sectionHeaders.producer')}>
                    {isSellerChangeAllowed ? (
                      <CompanySelect
                        label={t('form.labels.producer')}
                        onChange={onProducerChange}
                        value={formik.values.seller_id as string}
                        companyType={CompanyType.Producer}
                        isClearable
                        isSearchable
                        invalid={formik.touched.seller_id && !!formik.errors.seller_id}
                        errorText={formik.errors.seller_id}
                        required
                        isDisabled={
                          isEditRestricted || (!!userCompany && userCompany.company_type === CompanyType.Producer)
                        }
                        filter={{
                          feature_flags: generateCrmSectionAccessString(
                            Sections.ReturnDeclarations,
                            FeatureFlagModifiers.Enabled,
                          ),
                        }}
                      />
                    ) : (
                      <CompanyInfoPreview
                        company={returnDeclaration?.seller}
                        footer={`ID ${returnDeclaration?.seller?.id || ''}`}
                        link={
                          isAdmin && returnDeclaration && returnDeclaration.seller
                            ? generatePath(CompanyRoutes.Edit, { id: returnDeclaration.seller?.id })
                            : undefined
                        }
                      />
                    )}
                    <Styled.SeasonWrap
                      disabled={(isDistributor(role) && isSubmitted) || (isDistributor(role) && mode === 'edit')}
                    >
                      <SeasonSelect
                        companyId={formik.values.seller_id as string}
                        label={t('form.sectionHeaders.season')}
                        placeholder={t('form.placeholders.season')}
                        value={formik.values.season_id}
                        onChange={val => formik.setFieldValue('season_id', val)}
                        isDisabled={isEditRestricted || isDistributor(role)}
                        invalid={formik.touched.season_id && !!formik.errors.season_id}
                        errorText={formik.errors.season_id}
                        required
                      />
                    </Styled.SeasonWrap>
                    <Styled.DeclarationTypeWrap data-test-id={'declaration-type-select'} disabled={isDistributor(role)}>
                      <SimpleSelect
                        label={t('form.sectionHeaders.type')}
                        placeholder={t('form.placeholders.type')}
                        value={formik.values.type}
                        options={declarationTypeOptions}
                        onChange={val => formik.setFieldValue('type', val)}
                        isDisabled={isEditRestricted || isDistributor(role)}
                        invalid={formik.touched.type && !!formik.errors.type}
                        errorText={formik.errors.type}
                        required
                      />
                    </Styled.DeclarationTypeWrap>
                    <CompanySelect
                      label={t('form.sectionHeaders.distributor')}
                      onChange={onDistributorChange}
                      onMenuClose={() => formik.setFieldTouched('distributor_id')}
                      value={formik.values.distributor_id}
                      isDisabled={!formik.values.seller_id}
                      companyType={CompanyType.Distributor}
                      filter={{ for_producer_id: formik.values.seller_id }}
                      invalid={!!formik.submitCount && !!formik.errors.distributor_id}
                      errorText={formik.errors.distributor_id}
                      isClearable
                      required
                      blurInputOnSelect
                    />
                    {!!selectedDistributor && <CompanyInfoPreview company={selectedDistributor} />}
                    {headCompanyProgress === Progress.SUCCESS && (
                      <FormComponents.FormSection title={t('form.sectionHeaders.billingCompany')}>
                        <PreviewContainer.WrapperCompact>
                          <ProgressContainer
                            progress={headCompanyProgress}
                            errorMessage={t('errors.headDistributorFetchError')}
                          >
                            <PreviewContainer.Header>{headDistributor?.internal_name || ''}</PreviewContainer.Header>
                            <PreviewContainer.Subheader>
                              {headDistributor?.short_description || ''}
                            </PreviewContainer.Subheader>
                          </ProgressContainer>
                        </PreviewContainer.WrapperCompact>
                      </FormComponents.FormSection>
                    )}
                    <Input
                      label={t('form.sectionHeaders.pickUp')}
                      {...formik.getFieldProps('pickup_note')}
                      disabled={
                        isEditRestricted ||
                        !isUpdatePermitted ||
                        (isDistributor(role) && isSubmitted) ||
                        formik.values.status === ReturnDeclarationStatus.NothingToDeclare
                      }
                    />
                    <Styled.DatePickerWrapper data-test-id={'declaration-date'}>
                      <DatePicker
                        onChange={value => {
                          formik.setFieldValue('declaration_date', value)
                          formik.setFieldTouched('declaration_date')
                        }}
                        date={formik.getFieldProps('declaration_date').value}
                        invalid={formik.touched.declaration_date && !!formik.errors.declaration_date}
                        errorText={formik.errors.declaration_date}
                        label={t('form.labels.date')}
                        disabled={isEditRestricted || !isAdmin}
                        required
                        format={dateFormat}
                      />
                    </Styled.DatePickerWrapper>
                  </FormComponents.FormSection>
                </SectionBody>
              </SectionContainer>
            </Styled.Column>
            <Styled.Column>
              {isEditRestricted ||
              (isDistributor(role) && returnDeclaration?.status === ReturnDeclarationStatus.Submitted) ? (
                <ReturnSkuItemsReadyOnly sku_items={initialValues.sku_items}>
                  <Styled.ButtonsRow>
                    <Styled.PrintButton
                      intent={'primary'}
                      progress={printDeclarationProgress}
                      filled={true}
                      onClick={() => handlePrintDeclaration()}
                    >
                      {t('form.labels.download')}
                    </Styled.PrintButton>
                  </Styled.ButtonsRow>
                </ReturnSkuItemsReadyOnly>
              ) : (
                <FormikProvider value={formik}>
                  <Styled.Container isAllowed={!!formik.values.seller_id}>
                    <ReturnSkuItems
                      skuItems={formik.values.sku_items}
                      isAllowed={!!formik.values.seller_id}
                      nothingToReturn={formik.values.status === ReturnDeclarationStatus.NothingToDeclare}
                      sellerId={formik.values.seller_id || undefined}
                      skuMatchingErrors={skuMatchingErrors}
                      onCardSelect={handleCardSelect}
                      cardSelectError={cardSelectError}
                      onPackageTypeChange={handlePackageTypeChange}
                      onOptionsChange={handleOptionsChange}
                      onQtyInputChange={handleQtyInputChange}
                      onSkuMatched={handleSkuMatched}
                      onRemove={handleRemove}
                    ></ReturnSkuItems>
                    <Styled.ButtonsRow>
                      <Button intent={'warning'} onClick={handleNothingToReturn} disabled={!isReturnNothingPermitted}>
                        {!nothingToReturn ? t('form.labels.nothingToReturn') : t('form.labels.ableToReturn')}
                      </Button>
                      <Styled.PrintButton
                        intent={'primary'}
                        progress={printDeclarationProgress}
                        filled={true}
                        onClick={() => handlePrintDeclaration()}
                        disabled={!formik.values.sku_items?.length || !formik.isValid}
                      >
                        {t('form.labels.download')}
                      </Styled.PrintButton>
                    </Styled.ButtonsRow>
                  </Styled.Container>
                </FormikProvider>
              )}
              <SectionContainer>
                <SectionTitle>{t('form.sectionHeaders.comment')}</SectionTitle>
                <SectionBody>
                  <TextArea
                    {...formik.getFieldProps('comment')}
                    placeholder={t('form.placeholders.comment')}
                    disabled={isEditRestricted || !isUpdatePermitted || (isDistributor(role) && isSubmitted)}
                    data-test-id={'comment-textarea'}
                  />
                </SectionBody>
              </SectionContainer>
            </Styled.Column>
          </Styled.Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            intent={'primary'}
            filled
            disabled={progress === Progress.WORK || isEditRestricted || !formik.dirty}
            onClick={handleSubmit}
            progress={progress}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
          <Button intent={'cancel'} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          {isSubmitPermitted && (
            <Button
              data-test-id={'submit-button'}
              intent={'action'}
              filled
              onClick={handleChangeStatusToSubmit}
              progress={progress}
              disabled={returnDeclaration?.status !== ReturnDeclarationStatus.New}
            >
              {t('common:submit')}
            </Button>
          )}
          {!!onRemove && isRemovePermitted && !!returnDeclaration && (
            <StickyFooterDeleteBtn
              onRemove={onRemove}
              removeProgress={removeProgress}
              popoverText={t('form.removeText', { id: returnDeclaration.slug })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
      {shouldShowConfirmSubmit && isOpen && (
        <ConfirmPopup
          error={error}
          handleCloseModal={() => setIsOpen(false)}
          handleSubmit={onChangeStatusToSubmit}
          isOpen={isOpen}
        />
      )}
      {showInformationPopup && (
        <InformationPopup
          seasonName={seasonName}
          handleCloseModal={() => {
            setShowInformationPopup(false)
          }}
          isOpen={showInformationPopup}
        />
      )}
    </>
  )
}

export default ReturnDeclarationSkuDetailsForm
