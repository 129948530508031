import * as managers from 'modules/domain/product/managers'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { makeCancelable } from 'modules/utils/httpClient'
import { StorefrontProductActions } from './duck'
import { default as StorefrontProductSelectors } from './selectors'

export const useStorefrontProductList = createItemListOnceHook(
  StorefrontProductSelectors,
  StorefrontProductActions.listRequested,
)

export const useStorefrontProductById = createItemByIdHook(
  StorefrontProductSelectors,
  StorefrontProductActions.itemRequested,
)

export const useStorefrontProductByIdOnce = createItemByIdOnceHook(
  StorefrontProductSelectors,
  StorefrontProductActions.itemRequested,
)

export const useProductsOptions = makeCancellableResourceListHook(makeCancelable(managers.getListOptions))
export const useProducts = makeCancellableResourceListHook(makeCancelable(managers.getList), true)
export const useProductsConditional = makeCancellableResourceListHook(makeCancelable(managers.getListConditional))
