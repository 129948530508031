import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Household, HouseholdListRequestSorting, HouseholdListRequestFilter, HouseholdDTO } from './types'
import { ListResponse } from 'types/api'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getList = (
  filter: HouseholdListRequestFilter,
  sorting: HouseholdListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Household>>(endpoints.household(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<Household>(endpoints.household(id))
}

export const addItem = (dto: HouseholdDTO) => {
  return apiClient.post<Household>(endpoints.household(), dto)
}

export const updateItem = (id: string, dto: Partial<HouseholdDTO>) => {
  return apiClient.put<Household>(endpoints.household(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<Household>(endpoints.household(id))
}

export const getListOptions = (filter: HouseholdListRequestFilter) => {
  return apiClient.get<ListResponse<Household>>(endpoints.household(), {
    ...filter,
  })
}

export const getItemsByIds = createItemsByIds(false, getItem)
