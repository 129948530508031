import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ProductActions from './duck'
import ProductSelectors from './selectors'
import { Product, ProductFileItem } from './types'
import * as managers from './managers'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import ProductRoutes from 'views/pages/Product/routes'
import { ListResponse } from 'types/api'
import { getFilesUrls, updateLocationQuery } from 'modules/sagaHelpers'
import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'
import { AvailableLanguages, LocalizedValue } from 'types/entities'
import { RequestError } from 'modules/errors'
import { createLocalizedValue } from 'helpers/localization'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(ProductSelectors.page)
    const filter = yield select(ProductSelectors.filter)
    const persistentFilters = getPersistentFilter('producer_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }
    yield put(ProductActions.filtersUpdatedWithPersistentStorage(filterUpdated))

    const sorting = yield select(ProductSelectors.sorting)
    const pageSize =
      props.payload.pageSize !== undefined ? props.payload.pageSize : yield select(ProductSelectors.pageSize)
    let response: ListResponse<Product> = yield call(managers.getList, filterUpdated, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, total_count, page } = response
    yield put(ProductActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, ProductRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ProductSelectors.page)
    const filter = yield select(ProductSelectors.filter)
    const sorting = yield select(ProductSelectors.sorting)
    const pageSize = yield select(ProductSelectors.pageSize)

    const { data, total_count }: { data: Product[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ProductActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ProductActions.itemRequested>) {
  try {
    const product: Product = yield call(managers.getItem, id)
    yield put(ProductActions.itemRequestSucceed(product))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.itemRequestFailed(id, errType))
  }
}

export const pickPdfUrlValue = (value: LocalizedValue<ProductFileItem> = {}) => {
  const pick = (lang: AvailableLanguages) => {
    const file = value[lang]
    if (!file) {
      return null
    }
    switch (file.kind) {
      case 'current':
        if (typeof file.file === 'string') {
          return file.file
        }
        return null
      case 'added':
        return call(waitUntilFileLoaded, file.id)
    }
    return null
  }
  return createLocalizedValue(lang => pick(lang) || undefined)
}

export const addItem = function*({ payload: [dto, duplicate] }: ReturnType<typeof ProductActions.addRequested>) {
  try {
    const { images = [], files, ...rest } = dto
    const contenders = images.filter(image => image.kind === 'current' || image.kind === 'added')
    const imageFileItems: (string | File | null)[] = yield all(
      contenders.map(image => {
        switch (image.kind) {
          case 'current':
            return image.file
          case 'added':
            return call(waitUntilFileLoaded, image.id)
        }
        return null
      }),
    )
    const imagesItems: string[] = imageFileItems.filter(img => typeof img === 'string') as string[]
    const productInfoFileUrls = yield all(files.map(file => call(getFilesUrls, file.url_i18n)))
    const filesWithUrls = files.map((file, idx) => ({
      title_i18n: file.title_i18n,
      url_i18n: productInfoFileUrls[idx],
    }))

    const product: Product = yield call(managers.addItem, {
      ...rest,
      images: imagesItems,
      files: [...filesWithUrls],
    })
    yield put(ProductActions.addSucceed(product))
    if (!duplicate) {
      yield put(push(generatePath(ProductRoutes.Edit, { id: product.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.addFailed(errType))
  }
}
export const updateItem = function*({
  payload: [id, dto, duplicate],
}: ReturnType<typeof ProductActions.updateRequested>) {
  try {
    const { images = [], files = [], ...rest } = dto
    const contenders = images.filter(image => image.kind === 'current' || image.kind === 'added')
    const imageFileItems: (string | File | null)[] = yield all(
      contenders.map(image => {
        switch (image.kind) {
          case 'current':
            return image.file
          case 'added':
            return call(waitUntilFileLoaded, image.id)
        }
        return null
      }),
    )
    const imagesItems: string[] = imageFileItems.filter(img => typeof img === 'string') as string[]
    const productInfoFileUrls = yield all(files.map(file => call(getFilesUrls, file.url_i18n)))
    const filesWithUrls = files.map((file, idx) => ({
      title_i18n: file.title_i18n,
      url_i18n: productInfoFileUrls[idx],
    }))

    const product: Product = yield call(managers.updateItem, id, {
      ...rest,
      images: imagesItems,
      files: [...filesWithUrls],
    })
    yield put(ProductActions.updateSucceed(product))
    if (duplicate) {
      yield put(push(ProductRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof ProductActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(ProductActions.removeSucceed(payload))
    yield put(push(ProductRoutes.List))
    yield put(ProductActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.removeFailed(payload, errType))
  }
}

const ProductSaga = function*() {
  yield all([
    takeLatest(ProductActions.itemRequested.type, fetchItem),
    takeLatest(ProductActions.listRequested.type, fetchList),
    takeLatest(ProductActions.filterUpdated.type, fetchList),
    takeLatest(ProductActions.sortingUpdated.type, fetchList),
    takeLatest(ProductActions.filterHasBeenReset.type, fetchList),
    takeLatest(ProductActions.sortingHasBeenReset.type, fetchList),
    takeLatest(ProductActions.listRequestedNext.type, fetchListNext),
    takeLatest(ProductActions.addRequested.type, addItem),
    takeLatest(ProductActions.updateRequested.type, updateItem),
    takeLatest(ProductActions.removeRequested.type, removeItem),
  ])
}

export default ProductSaga
