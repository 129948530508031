import React, { useCallback, useMemo, useState } from 'react'
import * as Styled from './styled'
import { FormField, FormikHook, RangeDatePicker } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FieldMetaProps } from 'formik/dist/types'
import { PercentageTierRule } from 'modules/domain/incentiveProgram/types'
import { isValid, parseISO } from 'date-fns'
import { Label } from 'views/pages/Company/CompanyDetailsForm/components/BranchesStyles'
import styled from 'styled-components'
import useDateFormat from 'hooks/useDateFormat'

export type PercentageDiscountTierRule = Partial<Omit<PercentageTierRule, 'type'>>

type PercentageTiersRulesFormProps = {
  tiers_rules: PercentageDiscountTierRule[]
}

const WrapperDataPicker = styled.div`
  & > ${Label} {
    margin-bottom: 8px;
  }
`

const PercentageTiersRulesForm: React.VFC<{
  useFormik: FormikHook
  tiers_rules: PercentageDiscountTierRule[]
}> = ({ tiers_rules, useFormik }) => {
  const { t } = useTranslation('incentiveProgram')
  const dateFormat = useDateFormat({ isYearShort: true })

  const validationSchema = useMemo(() => {
    return Yup.object({
      tiers_rules: Yup.array(
        Yup.object({
          percent: Yup.string().required(t('validation:field_required')),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<PercentageTiersRulesFormProps>({
    initialValues: {
      tiers_rules,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('tiers_rules', remove(idx, 1, formik.values.tiers_rules))
    },
    [formik],
  )

  const handleAddClick = useCallback(() => {
    const prevTier =
      formik.values.tiers_rules.length > 0 ? formik.values.tiers_rules[formik.values.tiers_rules.length - 1] : undefined
    formik.setFieldValue('tiers_rules', [
      ...formik.values.tiers_rules,
      {
        start_date: prevTier?.start_date,
        end_date: prevTier?.end_date,
        min_qty: prevTier?.max_qty ?? 1,
        max_qty: 0,
        percent: '',
      },
    ])
  }, [formik])

  const handleDateRangeChange = React.useCallback(
    (idx, [start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)

      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        formik.setFieldValue(`tiers_rules[${idx}].start_date`, start)
        formik.setFieldValue(`tiers_rules[${idx}].end_date`, end)
      }
    },
    [formik],
  )

  const handleInputChange = useCallback(
    (field, value) => {
      formik.setFieldValue(field, value)
      formik.setFieldTouched(field)
    },
    [formik],
  )

  return (
    <Styled.ParamsContainer>
      {formik.values.tiers_rules.map((_, idx) => {
        const minKey = `tiers_rules[${idx}].min_qty`
        const maxKey = `tiers_rules[${idx}].max_qty`
        const percentKey = `tiers_rules[${idx}].percent`

        const prevMin = idx > 0 ? formik.values.tiers_rules[idx - 1].max_qty : 1

        const getFieldMeta = (field: string): FieldMetaProps<PercentageTiersRulesFormProps> =>
          formik.getFieldMeta(`tiers_rules[${idx}].${field}`)

        return (
          <Styled.Container key={idx}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <Styled.TierTitle>{`${t('form.labels.tierTitle')} ${idx + 1}`}</Styled.TierTitle>
            <WrapperDataPicker>
              <Label>{t('form.labels.datePeriod')}</Label>
              <RangeDatePicker
                start={formik.values.tiers_rules[idx].start_date || ''}
                end={formik.values.tiers_rules[idx].end_date || ''}
                onChange={(datespan: [string, string]) => handleDateRangeChange(idx, datespan)}
                format={dateFormat}
              />
            </WrapperDataPicker>
            <FormField
              invalid={getFieldMeta('percent').touched && !!getFieldMeta('percent').error}
              errorText={getFieldMeta('percent').error}
              label={t('form.labels.percent')}
              render={_ => (
                <Styled.PercentInput
                  data-test-id={`discount-percent-${idx}`}
                  {...formik.getFieldProps(percentKey)}
                  value={formik.values.tiers_rules[idx].percent || ''}
                  placeholder={t('form.placeholders.percent')}
                  onChange={(_, val) => handleInputChange(percentKey, val)}
                />
              )}
              required
            />
            <div />
            <FormField
              invalid={getFieldMeta('min_qty').touched && !!getFieldMeta('min_qty').error}
              errorText={getFieldMeta('min_qty').error}
              label={t('form.labels.minQty')}
              render={_ => (
                <Styled.NumberInput
                  {...formik.getFieldProps(minKey)}
                  testId={`discount-min-qty-${idx}`}
                  size={'medium'}
                  onChange={value => handleInputChange(minKey, value)}
                  min={prevMin}
                />
              )}
            />
            <FormField
              invalid={getFieldMeta('max_qty').touched && !!getFieldMeta('max_qty').error}
              errorText={getFieldMeta('max_qty').error}
              label={t('form.labels.maxQty')}
              render={_ => (
                <Styled.NumberInput
                  {...formik.getFieldProps(maxKey)}
                  testId={`discount-max-qty-${idx}`}
                  size={'medium'}
                  onChange={value => handleInputChange(maxKey, value)}
                  min={0}
                />
              )}
            />
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <div data-test-id={'discount-add-tier'}>
        <Styled.AddButton onClick={handleAddClick} type={'button'}>
          {t('form.addMoreTiers')}
        </Styled.AddButton>
      </div>
    </Styled.ParamsContainer>
  )
}

export default PercentageTiersRulesForm
