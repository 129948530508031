import {
  HistoricQtyTierRule,
  PercentageTierRule,
  PromocodeTierRule,
  QtyTierRule,
  SkuCalculatedTier,
} from 'modules/domain/incentiveProgram/types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Currency } from 'types/entities'
import { isHistoricTier, isPercentageTier, isPromocodeTier, isQtyTier } from '../helpers'
import { PercentageRule } from './PercentageRule'
import { QuantityRule } from './QuantityRule'
import { HistoricQtyRule } from './HistoricQtyRule'
import { PromocodeRule } from './PromocodeRule'

export const DiscountRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const DiscountLabel = styled.div`
  margin-right: 16px;
  color: ${({ theme }) => theme.color.secondary200};
  font-size: 14px;
`

export const DiscountItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 4px;
`

export type OrderSkuQuantityDiscountsProps = {
  tiers: SkuCalculatedTier<QtyTierRule | PercentageTierRule | HistoricQtyTierRule | PromocodeTierRule>[]
  title?: string
  currency?: Currency
  singular?: string
  plural?: string
}

export type MonetaryRuleProps<RuleType> = {
  currency?: Currency
  tier: SkuCalculatedTier<RuleType>
  idx: number
  singular?: string
  plural?: string
}

export const OrderSkuMonetaryDiscount: React.FC<OrderSkuQuantityDiscountsProps> = ({ tiers, title, ...rest }) => {
  const sortedItems = useMemo(() => {
    return tiers.sort(
      (prev, next) => parseFloat(prev.tier_rule.amount || '0') - parseFloat(next.tier_rule.amount || '0'),
    )
  }, [tiers])

  if (!tiers.length) return null

  return (
    <DiscountRowWrapper>
      {title && <DiscountLabel>{title}</DiscountLabel>}
      <DiscountItemsContainer>
        {sortedItems.map((tier, idx) => {
          if (isQtyTier(tier)) {
            return <QuantityRule {...rest} key={idx} tier={tier} idx={idx} />
          }
          if (isPercentageTier(tier)) {
            return <PercentageRule {...rest} key={idx} tier={tier} idx={idx} />
          }
          if (isHistoricTier(tier)) {
            return <HistoricQtyRule {...rest} key={idx} tier={tier} idx={idx} />
          }
          if (isPromocodeTier(tier)) {
            return <PromocodeRule {...rest} key={idx} tier={tier} idx={idx} />
          }
        })}
      </DiscountItemsContainer>
    </DiscountRowWrapper>
  )
}
