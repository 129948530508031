import React from 'react'
import ReportActions from 'modules/domain/report/duck'
import { useSelector } from 'react-redux'
import ReportSelectors from 'modules/domain/category/selectors'
import ReportDetailsForm, { FormProps } from 'views/pages/Reports/ReportDetailsForm/ReportDetailsForm'
import ReportRoutes from 'views/pages/Reports/routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const ReportAdd: React.FC = () => {
  const { t } = useTranslation('report')
  const submitAction = useAction(ReportActions.addRequested)
  const addProgress = useSelector(ReportSelectors.addProgress)

  const handleFormSubmit = (props: FormProps) => {
    submitAction({
      slug: props.slug || '',
      title_i18n: props.title,
      roles: props.roles,
      companies: props.companies || [],
      users: props.users || [],
      is_private: props.is_private,
      producer_id: props.producer_id,
      url: props.url,
      params: props.params,
      message_i18n: props.message,
      is_warning: props.is_warning,
      is_active: props.is_active,
      order: props.order,
      max_age: props.max_age,
      auto_refresh_time: props.auto_refresh_time,
    })
  }

  const page = useSelector(ReportSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: ReportRoutes.ReportList })
  }

  useHelmet({ title: t('addReportMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReportDetailsForm
          progress={addProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          initialValues={{
            max_age: 900,
            auto_refresh_time: 900,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ReportAdd
