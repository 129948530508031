import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, useTable } from 'react-table'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  SimpleSelect,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
  CustomSelectStyles,
} from '@agro-club/frontend-shared'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import { Company } from 'modules/domain/company/types'

import InventoryRoutes from 'views/pages/InventoryExchange/routes'
import InventoryTransactionSelectors from 'modules/domain/inventoryExchangeTransaction/selectors'
import InventoryTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'
import {
  InventoryExchangeTransaction,
  InventoryExchangeTransactionListRequestFilter,
} from 'modules/domain/inventoryExchangeTransaction/types'
import { useInventoryTransactionList } from 'modules/domain/inventoryExchangeTransaction/hooks'
import { StorefrontSku } from 'modules/domain/storefront/types'
import {
  useInventoryExchangeDistributorsOptions,
  useInventoryExchangeProducersOptions,
} from 'modules/domain/company/hooks'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 970px;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  min-width: 250px;
  flex-grow: 1;
  justify-content: space-between;
`

const Comment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const OwnerNameCell: React.FC<CellProps<InventoryExchangeTransaction, string>> = ({ cell, column }) => {
  return <div key={column.id}>{cell.value}</div>
}

const ProducerCell: React.FC<CellProps<InventoryExchangeTransaction, StorefrontSku>> = ({ cell, column }) => {
  return (
    <TComponents.SemiBoldText key={column.id}>
      {cell.value?.product?.producer?.internal_name || ''}
    </TComponents.SemiBoldText>
  )
}

const DistributorCell: React.FC<CellProps<InventoryExchangeTransaction, Company>> = ({ cell, column }) => {
  return <div key={column.id}>{cell.value?.internal_name || ''}</div>
}

const CategoryCell: React.FC<CellProps<InventoryExchangeTransaction, StorefrontSku>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  let text = ''
  const category = pick(cell.value?.product?.category?.title_i18n)
  const subCategory = pick(cell.value?.product?.subcategory?.title_i18n)
  if (category && subCategory) {
    text = `${category} / ${subCategory}`
  } else if (category) {
    text = category
  } else if (subCategory) {
    text = subCategory
  }
  return <div key={column.id}>{text}</div>
}

const ProductCell: React.FC<CellProps<InventoryExchangeTransaction, StorefrontSku>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  let val = ''
  if (cell.value) {
    val = `${pick(cell.value?.product?.title_i18n)}`
  }
  return <div key={column.id}>{val}</div>
}

const SKUCell: React.FC<CellProps<InventoryExchangeTransaction, StorefrontSku>> = ({ cell, column }) => {
  let val = ''
  if (cell.value) {
    val = cell.value?.sku_id
  }
  return <div key={column.id}>{val}</div>
}

const QuantityCell: React.FC<CellProps<InventoryExchangeTransaction, number>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
}

/* const RecordTypeCell: React.FC<CellProps<InventoryExchangeTransaction, string>> = ({ cell, column }) => {
  return <div key={column.id}>{cell.value}</div>
} */

const CreatedDateCell: React.FC<CellProps<InventoryExchangeTransaction, string>> = ({ cell, column }) => {
  const getFormatedDate = useDateFormatFn({ timeFormat: 'HH:mm:ss' })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      {getFormatedDate(cell.value)}
    </div>
  )
}

const CommentCell: React.FC<CellProps<InventoryExchangeTransaction, string>> = ({ cell, column }) => {
  return (
    <Comment key={column.id} title={cell.value}>
      {cell.value}
    </Comment>
  )
}

const InventoryTransactionList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('inventorySKUTransaction')
  const [progress, data = []] = useInventoryTransactionList()
  const listRequested = useAction(InventoryTransactionActions.listRequested)
  const filterValue = useSelector(InventoryTransactionSelectors.filter)
  const filterUpdated = useAction(InventoryTransactionActions.filterUpdated)
  const total = useSelector(InventoryTransactionSelectors.total)
  const pages = useSelector(InventoryTransactionSelectors.pages)
  const page = useSelector(InventoryTransactionSelectors.page)
  const pageSize = useSelector(InventoryTransactionSelectors.pageSize)

  const [producersProgress, producers] = useInventoryExchangeProducersOptions()
  const producersOptions = useMemo(() => {
    if (!producers) {
      return []
    }
    return producers.map(i => ({ id: i.id, title: i.official_name ?? '' }))
  }, [producers])

  const [distributorsProgress, distributors] = useInventoryExchangeDistributorsOptions()
  const distributorsOptions = useMemo(() => {
    if (!distributors) {
      return []
    }
    return distributors.map(d => ({
      id: d.id,
      title: d.official_name ?? '',
      hasChildren: !!d.has_branch_company,
      parent: d.head_company_relation?.company_id,
    }))
  }, [distributors])

  const visibleColumns = React.useMemo(
    () => [
      {
        id: 'producer',
        Header: t('tableHeaders.manufacturer'),
        accessor: 'sku' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('tableHeaders.distributor'),
        accessor: 'retailer' as const,
        Cell: DistributorCell,
      },
      {
        id: 'category',
        Header: t('tableHeaders.category'),
        accessor: 'sku' as const,
        Cell: CategoryCell,
      },
      {
        id: 'product',
        Header: t('tableHeaders.product'),
        accessor: 'sku' as const,
        Cell: ProductCell,
      },
      {
        Header: t('tableHeaders.sku'),
        accessor: 'sku' as const,
        Cell: SKUCell,
      },
      {
        Header: t('tableHeaders.quantity'),
        accessor: 'quantity' as const,
        Cell: QuantityCell,
      },
      {
        Header: t('tableHeaders.comments'),
        accessor: 'comment' as const,
        Cell: CommentCell,
      },
      {
        Header: t('tableHeaders.createdBy'),
        accessor: 'owner_name' as const,
        Cell: OwnerNameCell,
      },
      {
        Header: t('tableHeaders.createdAt'),
        accessor: 'created_at' as const,
        Cell: CreatedDateCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<InventoryExchangeTransaction>({
    columns: visibleColumns,
    data,
    initialState: {},
  })

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<InventoryExchangeTransactionListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('inventoryTransactionList')

  const renderRows = () => {
    if (progress !== Progress.SUCCESS) {
      return null
    }
    return rows.map(row => {
      prepareRow(row)
      const { key, ...props } = row.getRowProps()
      return (
        <TableBodyRow
          key={key}
          {...props}
          onClick={() => {
            history.push(generatePath(InventoryRoutes.Edit, { id: row.original.id.toString() }))
          }}
        >
          {row.cells.map(cell => {
            const { key, ...props } = cell.getCellProps()
            return (
              <TableBodyCell key={key} {...cell.column} {...props}>
                {cell.render('Cell')}
              </TableBodyCell>
            )
          })}
        </TableBodyRow>
      )
    })
  }

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('tableHeaders.manufacturer')}>
            <SimpleSelect
              customStyles={customSelectStyles}
              onChange={val => handleFilterChange({ producer_id: val ? val.toString() : null })}
              progress={producersProgress}
              value={filterValue.producer_id}
              isClearable
              options={producersOptions}
            />
          </Filter>
          <Filter title={t('labels:retailer')}>
            <SimpleSelect
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              customStyles={customSelectStyles}
              onChange={val => handleFilterChange({ retailer_id: val ? val.toString() : undefined })}
              progress={distributorsProgress}
              value={filterValue.retailer_id}
              isClearable
              options={distributorsOptions}
            />
          </Filter>
          <Filter title={t('labels:product')}>
            <ProductsSelect
              customStyles={customSelectStyles}
              onChange={val => handleFilterChange({ product_id: val })}
              value={filterValue.product_id}
              scope="sku/inventory"
              isClearable
              isFilterSuggest
            />
          </Filter>
        </FiltersWrapper>
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell key={column.id} {...column}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {renderRows()}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default InventoryTransactionList
