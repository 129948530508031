import { octopusApiClient } from 'modules/utils/httpClient'
import { Row } from 'react-table'
import { ListResponse } from 'types/api'

export const getOriginal = <T extends Object>(row: Row<T>): T => row.original
export const createGetList = <EntityType, Filter>(endpoint: string) => (
  filter: Filter,
  sort: any,
  page: number,
  pageSize: number,
) =>
  octopusApiClient.get<ListResponse<EntityType>>(endpoint, {
    ...filter,
    ...sort,
    page,
    page_size: pageSize,
  })
