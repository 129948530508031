import React, { useCallback, useMemo, useState } from 'react'

import * as Styled from './styled'
import { Currency, FormField, FormikHook } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FieldMetaProps } from 'formik/dist/types'
import { QtyTierRule } from 'modules/domain/incentiveProgram/types'
import { useCompanyById } from 'modules/domain/company/hooks'

export type QtyDiscountTierRule = Partial<Omit<QtyTierRule, 'type'>>

type QtyTiersRulesFormProps = {
  tiers_rules: QtyDiscountTierRule[]
}

const QtyTiersRulesForm: React.VFC<{
  useFormik: FormikHook
  producerId: string
  tiers_rules: QtyDiscountTierRule[]
}> = ({ tiers_rules, producerId, useFormik }) => {
  const { t } = useTranslation('incentiveProgram')
  const [, producer] = useCompanyById(producerId)

  const validationSchema = useMemo(() => {
    return Yup.object({
      tiers_rules: Yup.array(
        Yup.object({
          min_qty: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
          amount: Yup.string().required(t('validation:field_required')),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<QtyTiersRulesFormProps>({
    initialValues: {
      tiers_rules,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('tiers_rules', remove(idx, 1, formik.values.tiers_rules))
    },
    [formik],
  )

  const handleAddClick = useCallback(() => {
    const prevTier =
      formik.values.tiers_rules.length > 0 ? formik.values.tiers_rules[formik.values.tiers_rules.length - 1] : undefined
    formik.setFieldValue('tiers_rules', [
      ...formik.values.tiers_rules,
      {
        min_qty: prevTier?.max_qty ?? 1,
        max_qty: 0,
        amount: '',
      },
    ])
  }, [formik])

  const handleNumberInputChange = useCallback(
    (field, value) => {
      formik.setFieldValue(field, value)
      formik.setFieldTouched(field)
    },
    [formik],
  )

  return (
    <Styled.ParamsContainer data-test-id={'params-form'}>
      {formik.values.tiers_rules.map((_, idx) => {
        const minKey = `tiers_rules[${idx}].min_qty`
        const maxKey = `tiers_rules[${idx}].max_qty`
        const amountKey = `tiers_rules[${idx}].amount`
        const prevMin = idx > 0 ? formik.values.tiers_rules[idx - 1].max_qty : 1

        const getFieldMeta = (field: string): FieldMetaProps<QtyTiersRulesFormProps> =>
          formik.getFieldMeta(`tiers_rules[${idx}].${field}`)

        return (
          <Styled.Container key={idx} data-test-id={`qty-rule-tier-row-${idx}`}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <FormField
              invalid={getFieldMeta('min_qty').touched && !!getFieldMeta('min_qty').error}
              errorText={getFieldMeta('min_qty').error}
              label={t('form.labels.minQty')}
              render={_ => (
                <Styled.NumberInput
                  {...formik.getFieldProps(minKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(minKey, value)}
                  min={prevMin}
                  testId="min-qty"
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('max_qty').touched && !!getFieldMeta('max_qty').error}
              errorText={getFieldMeta('max_qty').error}
              label={t('form.labels.maxQty')}
              render={_ => (
                <Styled.NumberInput
                  {...formik.getFieldProps(maxKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(maxKey, value)}
                  min={0}
                  testId="max-qty"
                />
              )}
            />
            <FormField
              invalid={getFieldMeta('amount').touched && !!getFieldMeta('amount').error}
              errorText={getFieldMeta('amount').error}
              label={t('form.labels.amount')}
              render={_ => (
                <Styled.CurrencyInput
                  {...formik.getFieldProps(amountKey)}
                  value={formik.values.tiers_rules[idx].amount || ''}
                  placeholder={t('form.placeholders.amount')}
                  currency={producer?.currency || Currency.CAD}
                  onChange={val => formik.setFieldValue(amountKey, val)}
                  data-test-id="amount"
                />
              )}
              required
            />
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <Styled.AddButton onClick={handleAddClick} type={'button'} data-test-id="add-tier">
        {t('form.addMoreTiers')}
      </Styled.AddButton>
    </Styled.ParamsContainer>
  )
}

export default QtyTiersRulesForm
