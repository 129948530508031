import styled from 'styled-components'
import React from 'react'
import { IconLinkOpener } from '@agro-club/frontend-shared'
import { Link as RouterLink } from 'react-router-dom'

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  gap: 8px;

  svg {
    fill: ${props => props.theme.color.secondary100};
    width: 16px;
    height: 16px;

    &:hover {
      fill: ${props => props.theme.color.secondary300};
    }
  }
`

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.color.secondary100};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px;
  display: grid;
  grid-template-rows: auto;
  grid-auto-rows: min-content;
  grid-template-areas:
    'body'
    'footer';
`

const WrapperCompact = styled.div`
  border: 1px solid ${props => props.theme.color.secondary100};
  border-radius: 8px;
  padding: 8px 12px;
`

const Footer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  grid-area: footer;
  color: ${props => props.theme.color.secondary300};
  margin-top: 16px;
`

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  grid-area: header;
`

const Subheader = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  grid-area: subheader;
`

const Text = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const Link = styled.a`
  display: inline-block;
  width: min-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
`

const Container: React.FC<{
  footer?: React.ReactNode
  to?: string
  className?: string
  testId?: string
  onClick?: () => void
}> = ({ footer, children, to, className, onClick, testId }) => {
  return (
    <Wrapper className={className} onClick={onClick} data-test-id={testId}>
      <Body>
        {to && (
          <Actions>
            <RouterLink to={to} data-test-id={'customer-link'}>
              <IconLinkOpener />
            </RouterLink>
          </Actions>
        )}
        {children}
      </Body>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  )
}

export const PreviewContainer = {
  Container,
  Wrapper,
  WrapperCompact,
  Footer,
  Body,
  Header,
  Subheader,
  Text,
  Link,
  Actions,
}
