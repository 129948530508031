import { Entity } from 'modules/domain/types'
import { ListResponse } from 'types/api'
import { apiClient } from '../httpClient'

export type CreateBasicManagersReturnType<
  FilterType extends {},
  SortType extends {},
  EntityType extends Entity,
  CreateDtoType extends Partial<Entity>,
  UpdateDtoType extends Partial<Entity>
> = {
  getList: (filter: FilterType, sorting: SortType, page: number, pageSize: number) => Promise<ListResponse<EntityType>>
  getItem: (id: string) => Promise<EntityType>
  addItem: (dto: CreateDtoType) => Promise<EntityType>
  updateItem: (id: string, dto: Partial<UpdateDtoType>) => Promise<EntityType>
  removeItem: (id: string) => Promise<EntityType>
}

export const createBasicManagers = <
  FilterType extends {},
  SortType extends {},
  EntityType extends Entity,
  CreateDtoType,
  UpdateDtoType
>(
  endpoint: (arg?: string | number) => string,
  client: typeof apiClient = apiClient,
): CreateBasicManagersReturnType<FilterType, SortType, EntityType, CreateDtoType, UpdateDtoType> => {
  return {
    getList: (filter, sorting, page, pageSize) =>
      client.get(endpoint(), {
        ...filter,
        ...sorting,
        page: page,
        page_size: pageSize,
      }),
    getItem: id => client.get(endpoint(id)),
    addItem: dto => client.post(endpoint(), dto),
    updateItem: (id, dto) => client.put(endpoint(id), dto),
    removeItem: id => client.delete(endpoint(id)),
  }
}
