import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProductsAvailabilityRoutes from './routes'
import ProductsAvailabilityList from './ProductsAvailabilityList'
import ProductsAvailabilityAdd from './ProductsAvailabilityAdd'
import ProductsAvailabilityEdit from './ProductsAvailabilityEdit'

const ProductsAvailabilityPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path={ProductsAvailabilityRoutes.List}>
        <ProductsAvailabilityList />
      </Route>
      <Route exact path={ProductsAvailabilityRoutes.Add}>
        <ProductsAvailabilityAdd />
      </Route>
      <Route exact path={ProductsAvailabilityRoutes.Edit}>
        <ProductsAvailabilityEdit />
      </Route>
    </Switch>
  )
}

export default ProductsAvailabilityPage
