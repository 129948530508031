import React from 'react'
import { Progress } from 'modules/types'
import styled, { StyledProps } from 'styled-components'
import StickyFooterBtn from '../StickyFooterBtn/StickyFooterBtn'
import { useTranslation } from 'react-i18next'

export const ButtonWrapper = styled.div<StyledProps<{ marginRightRequired?: boolean }>>`
  margin-left: auto;
  margin-right: ${prop => (prop.marginRightRequired ? '65px;' : '')};
`

const StickyFooterRestoreBtn: React.FC<{
  progress?: Progress
  onRestore(): void
}> = ({ progress, onRestore }) => {
  const { t } = useTranslation('common')

  return (
    <ButtonWrapper marginRightRequired={!!window.Intercom}>
      <StickyFooterBtn
        onSubmit={onRestore}
        heading={t('common:restoreOrderHeader')}
        text={t('common:restoreOrderText')}
        buttonText={t('common:restore')}
        intent={'primary'}
        filled
        testId={'restore-button'}
        progress={progress}
      />
    </ButtonWrapper>
  )
}

export default StickyFooterRestoreBtn
