import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxSelect, FormikHook, useDebounce } from '@agro-club/frontend-shared'
import { useCrmBadgesOptions } from 'modules/domain/badge/hooks'
import { Badge } from 'modules/domain/badge/types'
import * as Styled from './styled'
import BadgeCard from './BadgeCard'
import BadgeOption from './BadgeOption'

const BadgeSelect: React.VFC<{ useFormik: FormikHook; badgeIds: string[]; enrichedBadges: Badge[] }> = ({
  useFormik,
  badgeIds = [],
  enrichedBadges = [],
  ...props
}) => {
  const { t } = useTranslation('product')
  const [searchedValue, setSearchedValue] = useState('')
  const debouncedSearch = useDebounce(searchedValue, 400)
  const [progress, badgeList = []] = useCrmBadgesOptions(debouncedSearch, 500)
  const [selectedBadges, setSelectedBadges] = useState<Badge[]>(enrichedBadges)

  const formik = useFormik({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
    initialValues: {
      badge_ids: badgeIds,
    },
  })

  const options = useMemo(
    () =>
      badgeList?.map(badge => ({
        id: badge.id,
        title: badge.name,
        labelToRender: <BadgeOption badge={badge} />,
      })),
    [badgeList],
  )

  const handleChange = (id: string) => {
    const newBadges = selectedBadges.filter(badge => id !== badge?.id)
    if (newBadges.length === selectedBadges.length) {
      const addedBadge = badgeList?.find(badge => id === badge.id)
      if (!addedBadge) {
        console.error("Badge with such id doesn't exists")
        return
      }
      newBadges.push(addedBadge)
    }
    setSelectedBadges(newBadges)
    formik.setFieldValue(
      'badge_ids',
      newBadges.map(badge => badge.id),
    )
  }

  return (
    <>
      <Styled.FormContainer data-test-id="badge-list">
        <Styled.VerticalFormsContainer>
          <CheckboxSelect
            progress={progress}
            options={options ?? []}
            selected={formik.values.badge_ids}
            onChange={handleChange}
            placeholder={t('form.selectBadges')}
            isSearchable
            onSearchChange={(val: string) => setSearchedValue(val)}
            onMenuClose={() => setSearchedValue('')}
            {...props}
          />
        </Styled.VerticalFormsContainer>
        {!!selectedBadges.length && (
          <Styled.LabeledContainerStyled label={t('form.selectedBadges')}>
            <Styled.BadgeCardsContainer>
              {selectedBadges.map((badge, index) => (
                <BadgeCard badge={badge} index={index} onDelete={handleChange} key={badge?.id} />
              ))}
            </Styled.BadgeCardsContainer>
          </Styled.LabeledContainerStyled>
        )}
      </Styled.FormContainer>
    </>
  )
}

export default BadgeSelect
