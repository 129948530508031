import React from 'react'
import styled, { StyledProps } from 'styled-components'

export const FormContentBody = styled.form`
  display: grid;
  grid-gap: 24px;
  padding: 16px 24px;
`

export const FormContentFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.08);
  padding: 0 24px;
`

export const FormContentWrapper = styled.div<StyledProps<{}>>`
  margin: 16px 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.onPrimaryLight};
`

export const FormButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.color.onPrimaryLight};

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

const SectionBody = styled.div`
  display: grid;
  grid-gap: 24px;
`
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const SectionTitle = styled.h3`
  margin: 0 0 16px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary200};
`

const LabeledContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`
const LabelStyled = styled.div`
  color: ${props => props.theme.color.onPrimaryDark};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
  margin-bottom: 8px;
`
export const LabeledContainer: React.FC<{ label: string; className?: string }> = ({ children, label, className }) => {
  return (
    <LabeledContainerStyled className={className}>
      <LabelStyled>{label}</LabelStyled>
      {children}
    </LabeledContainerStyled>
  )
}

export const FormSection: React.FC<{ title: string; className?: string; testId?: string }> = ({
  title,
  className,
  children,
  testId,
}) => (
  <SectionWrapper className={className} data-test-id={testId}>
    <SectionTitle>{title}</SectionTitle>
    <SectionBody>{children}</SectionBody>
  </SectionWrapper>
)
