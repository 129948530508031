import React from 'react'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import EntityFilter from './EntityFilter'
import OperationTypeFilter from './OperationTypeFilter'
import PeriodFilter from './PeriodFilter'

const ChangeLogListFilters: React.FC = () => {
  return (
    <Filters>
      <PeriodFilter />
      <OperationTypeFilter />
      <EntityFilter />
    </Filters>
  )
}

export default ChangeLogListFilters
