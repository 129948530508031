import React from 'react'
import { useTranslation } from 'react-i18next'
import * as targetFarmerManagers from 'modules/domain/farmerOptionInTarget/managers'
import * as orderFarmerManagers from 'modules/domain/farmerOptionInFarmerOrders/managers'
import * as farmerManagers from 'modules/domain/farmer/managers'

import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { useFilterMemo } from 'hooks/useFilterMemo'
import {
  FarmerOptionInTarget,
  FarmerOptionInTargetListRequestFilter,
  FarmerOptionInTargetListRequestSorting,
} from 'modules/domain/farmerOptionInTarget/types'
import {
  FarmerOptionInFarmerOrdersListRequestFilter,
  FarmerOptionInFarmerOrdersListRequestSorting,
} from 'modules/domain/farmerOptionInFarmerOrders/types'

export type FarmerSelectProps = EntitySelectProps<FarmerOptionInTarget> & {
  filter?: FarmerOptionInTargetListRequestFilter | FarmerOptionInFarmerOrdersListRequestFilter
  sort?: FarmerOptionInTargetListRequestSorting | FarmerOptionInFarmerOrdersListRequestSorting
  scope?: 'orders' | 'targets'
  preventFetch?: boolean
}

export const getFarmerLabel = (farmer: FarmerOptionInTarget) => {
  const { full_name, farm_name, email, phone_number, id } = { ...farmer }
  const farmerName = full_name || email || phone_number || id
  const farmName = farm_name ? ` (${farm_name})` : ''
  return `${farmerName}${farmName}`
}

export const getFarmerValue = (farmer: FarmerOptionInTarget) => farmer.id

export const FarmersSelect: React.VFC<FarmerSelectProps> = ({
  scope = 'orders',
  placeholder,
  filter,
  sort,
  preventFetch,
  ...props
}) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useFilterMemo<any>(filter)
  const memoizedSort = useFilterMemo<any>(sort)

  return (
    <SelectTestWrapper data-test-id={'farmer-select'}>
      <EntitySelect
        placeholder={placeholder || t('placeholders.farmer')}
        getEntityList={scope === 'targets' ? targetFarmerManagers.getList : orderFarmerManagers.getList}
        getEntityById={farmerManagers.getFarmer}
        filter={memoizedFilter}
        sort={memoizedSort}
        getOptionLabel={getFarmerLabel}
        getOptionValue={getFarmerValue}
        preventFetch={preventFetch}
        {...props}
      />
    </SelectTestWrapper>
  )
}
