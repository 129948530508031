import React from 'react'
import { PromocodeTierRule } from 'modules/domain/incentiveProgram/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useTranslation } from 'react-i18next'
import { MonetaryRuleProps } from './OrderSkuMonetaryDiscount'
import { DiscountChip } from './styles'

export const PromocodeRule: React.FC<MonetaryRuleProps<PromocodeTierRule>> = ({
  singular,
  plural,
  currency,
  tier,
  idx,
}) => {
  const { t } = useTranslation('discount')
  const { min_qty, max_qty, amount = '0' } = tier.tier_rule
  const discount = getPrettyPrice(parseFloat(amount), currency)
  const amountStr = `${min_qty} ${max_qty ? `- ${max_qty}` : '+'}`

  return (
    <DiscountChip key={idx} active={tier.is_applied} color={'orange'}>
      {t('tierRuleTemplate', { discount, amount: amountStr, singular, plural })}
    </DiscountChip>
  )
}
