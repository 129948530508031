import React, { useMemo, useRef } from 'react'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { AdvancedSearchField, useAction, useHelmet } from '@agro-club/frontend-shared'
import ChangeLogListTable from './ChangeLogListTable'
import ChangeLogListFilters from './ChangeLogListFilters'
import styled from 'styled-components'
import ChangeLogActions from 'modules/domain/changeLog/duck'
import { useSelector } from 'react-redux'
import ChangeLogSelector from 'modules/domain/changeLog/selectors'
import { ChangeLogSearchOption } from 'modules/domain/changeLog/types'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`
const DEFAULT_SEARCH_OPTION = 'obj_id'

const ChangeLogList: React.FC = () => {
  const { t } = useTranslation('changeLog')
  useHelmet({ title: t('metaTitle') })
  const filterUpdated = useAction(ChangeLogActions.filterUpdated)
  const filterValue = useSelector(ChangeLogSelector.filter)
  const prevOption = useRef(DEFAULT_SEARCH_OPTION)

  const searchOptions: Array<{
    id: ChangeLogSearchOption
    title: string
    placeholder: string
  }> = useMemo(() => {
    const arr: Array<ChangeLogSearchOption> = ['obj_id', 'author']
    return arr.map(item => ({
      id: item,
      title: t(`list.searchOptions.${item}.title`),
      placeholder: t(`list.searchOptions.${item}.placeholder`),
    }))
  }, [t])

  const handleSearchChange = React.useCallback(
    (search: string, option: string) => {
      if (!(option !== prevOption.current && !search)) {
        const newFilterValue = { ...filterValue }
        newFilterValue[option] = search.length ? search : undefined
        filterUpdated({ ...newFilterValue })
      }
      prevOption.current = option
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterUpdated],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')} />
          <SearchInputWrapper>
            <AdvancedSearchField
              options={searchOptions}
              onChange={handleSearchChange}
              defaultOption={DEFAULT_SEARCH_OPTION}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <ChangeLogListFilters />
          <ChangeLogListTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ChangeLogList
