import styled, { StyledProps } from 'styled-components'
import { DistributorOrderStatus } from 'types/distributorOrder'

type StatusProps = StyledProps<{ status: DistributorOrderStatus }>
const DistributorOrderStatusColored = styled.span<StatusProps>`
  color: ${({ status, theme }: StatusProps) => {
    switch (status) {
      case DistributorOrderStatus.Canceled:
        return theme.color.accentDestructive
      case DistributorOrderStatus.Confirmed:
        return theme.color.primary700
      case DistributorOrderStatus.Changed:
        return theme.color.accentNotify
      case DistributorOrderStatus.Deleted:
        return theme.color.accentDestructive
      case DistributorOrderStatus.New:
        return theme.color.primary600
    }
  }};
`

export default DistributorOrderStatusColored
