import { Button, IconLinkOpener, IconPencil, Switch } from '@agro-club/frontend-shared'
import { useFarmerByPhone } from 'modules/domain/farmer/hooks'
import { FarmerData } from 'modules/domain/farmer/types'
import { zip } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'

const PartnersBlock = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const Partner = styled.div`
  margin-top: 6px;
  margin-left: 6px;
  color: ${props => props.theme.color.onPrimaryDark};
`

const Value = styled.span`
  color: ${props => props.theme.color.onPrimaryDark};
`

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content auto;
  margin-top: 8px;
`

const Label = styled.span<{ disabled?: boolean }>`
  font-size: 12px;
  flex-grow: 1;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const SwitchStyled = styled(Switch)`
  width: auto;
`

const EditButton = styled(Button)`
  height: 13px;
  svg {
    height: 13px;
    width: 13px;
  }
`

const CustomerInfo: React.FC<{
  customer?: FarmerData | null
  creditOfferProvided: boolean
  creditOfferAccepted?: boolean
  onCreditOfferAcceptedChange?: (isAccepted: boolean) => void
  onEditClick?: () => void
  link?: string
  hideFarmerInfoChecking: boolean
  isEditable?: boolean
}> = ({
  customer,
  creditOfferProvided = false,
  creditOfferAccepted = false,
  link,
  onEditClick,
  onCreditOfferAcceptedChange,
  hideFarmerInfoChecking,
  isEditable = true,
}) => {
  const { t } = useTranslation(['user', 'farmerOrder', 'common'])
  const [, farmer] = useFarmerByPhone(customer?.phone_number || '')
  const { first_name, last_name, id, phone_number, email, partnership, prefix } = customer || {}

  const hideInfo = useMemo(() => !!hideFarmerInfoChecking && !farmer?.ordersCount, [
    hideFarmerInfoChecking,
    farmer?.ordersCount,
  ])

  const legalAddress = [
    customer?.legal_address?.country,
    customer?.legal_address?.city,
    customer?.legal_address?.address,
    customer?.legal_address?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const getName = ({
    first_name,
    last_name,
    placeholder = '-',
  }: {
    first_name?: string
    last_name?: string
    placeholder?: string
  }) => (first_name || last_name ? [first_name, last_name].filter(Boolean).join(' ') : placeholder)

  const handleCreditOfferAcceptedChange = useCallback(() => {
    onCreditOfferAcceptedChange && onCreditOfferAcceptedChange(!creditOfferAccepted)
  }, [creditOfferAccepted, onCreditOfferAcceptedChange])

  return (
    <PreviewContainer.Wrapper>
      <PreviewContainer.Body>
        <PreviewContainer.Header>{getName({ first_name, last_name })}</PreviewContainer.Header>
        {!!phone_number && <PreviewContainer.Subheader>{phone_number}</PreviewContainer.Subheader>}
        <PreviewContainer.Actions>
          {isEditable && onEditClick && (
            <EditButton variant="no-style" onClick={onEditClick}>
              <IconPencil />
            </EditButton>
          )}
          {!!link && (
            <Link to={link} data-test-id={'customer-link'}>
              <IconLinkOpener />
            </Link>
          )}
        </PreviewContainer.Actions>
        {!hideInfo && (
          <div data-test-id="customer-info">
            {!!email && <PreviewContainer.Link href={`mailto:${email}`}>{email}</PreviewContainer.Link>}
            {!!partnership?.cheque_payee_name && (
              <PreviewContainer.Text style={{ marginTop: '6px' }}>
                {t('partnership.chequePayeeName')}
                {': '}
                <Value>{partnership?.cheque_payee_name}</Value>
              </PreviewContainer.Text>
            )}
            {!!partnership?.partners?.length && (
              <PartnersBlock>
                <div>{t('partnership.partners')}:</div>
                <div>
                  {partnership.partners.map((partner, index) => {
                    const { farm_name, first_name, phone_number, last_name } = partner
                    const name = getName({ first_name, last_name, placeholder: '' })
                    return <Partner key={index}>{[name, phone_number, farm_name].filter(Boolean).join(', ')}</Partner>
                  })}
                </div>
              </PartnersBlock>
            )}
            {!!legalAddress.length && (
              <PreviewContainer.Text style={{ marginTop: '16px', display: 'block' }}>
                {t('farmerOrder:legalAddress')}
                <Value>{legalAddress}</Value>
              </PreviewContainer.Text>
            )}
          </div>
        )}
        {creditOfferProvided && (
          <Container data-test-id="credit-offer-switch">
            <SwitchStyled
              on={creditOfferAccepted}
              onClick={handleCreditOfferAcceptedChange}
              disabled={!onCreditOfferAcceptedChange || !isEditable}
              size={'small'}
            />
            <Label onClick={handleCreditOfferAcceptedChange} disabled={!onCreditOfferAcceptedChange}>
              {t('farmerOrder:creditOfferAccepted')}
            </Label>
          </Container>
        )}
      </PreviewContainer.Body>
      {!hideInfo && (
        <PreviewContainer.Footer data-test-id="customer-info-id">
          {zip(['ID', 'Slug_ID'], [id, prefix])
            .filter(([, id]) => !!id)
            .map(arr => arr.join(': '))
            .join(', ')}
        </PreviewContainer.Footer>
      )}
    </PreviewContainer.Wrapper>
  )
}

export default CustomerInfo
