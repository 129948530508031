import styled, { css, StyledProps } from 'styled-components'
import { DocumentItemStatus } from 'modules/domain/document/types'

const statusCss = (props: StyledProps<{ status: DocumentItemStatus }>) => {
  let bgColor = 'none'
  let color = '#000'
  switch (props.status) {
    case 'need_sign':
    case 'envelope_created':
    case null:
      bgColor = props.theme.color.accentNotify
      color = props.theme.color.onPrimaryLight
      break
    case 'signed':
      bgColor = props.theme.color.backgroundApproved
      color = props.theme.color.accentApproving
      break
    case 'processing':
      bgColor = props.theme.color.primary500
      color = props.theme.color.onPrimaryLight
      break
    case 'not_send':
      bgColor = props.theme.color.backgroundSecondary
      color = props.theme.color.onPrimaryDark
      break
  }
  return css`
    color: ${color};
    background-color: ${bgColor};
  `
}
export const DocumentStatus = styled.div<{ status: DocumentItemStatus }>`
  min-width: 80px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  padding: 0 8px;
  text-align: center;
  ${statusCss}
`
