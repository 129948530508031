import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { call, all, takeLatest, put, select } from 'redux-saga/effects'
import ProgramSummarySelectors from '../programSummary/selectors'
import managers from '../programSummary/managers'
import ProgramSummaryRoutes from 'views/pages/ProgramSummary/routes'
import { ListResponse } from 'types/api'
import { ProgramDTO, ProgramSummaryListEntry } from './types'
import { getAllPersistentFilters, savePersistentFilter } from '../../utils/helpers'
import ProgramSummaryActions from '../programSummary/duck'
import { RequestError } from 'modules/errors'

const sagas = createBasicSagas({
  selectors: ProgramSummarySelectors,
  actions: ProgramSummaryActions,
  managers,
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, ProgramSummaryRoutes.List, { page })
  },
})

export const fetchList = function*(props) {
  try {
    let currentPage = yield select(ProgramSummarySelectors.page)
    const filter = yield select(ProgramSummarySelectors.filter)
    !props.payload?.filter && savePersistentFilter(props.payload, 'program_summary')
    const persistentFilters = getAllPersistentFilters('program_summary')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }
    yield put(ProgramSummaryActions.filtersUpdatedWithPersistentStorage(filterUpdated))
    const sorting = yield select(ProgramSummarySelectors.sorting)
    const pageSize = yield select(ProgramSummarySelectors.pageSize)
    let response: ListResponse<ProgramSummaryListEntry> = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage,
      pageSize,
    )
    if (currentPage > 1 && response.total_count === 0) {
      currentPage = 1
      response = yield call(managers.getList, filterUpdated, sorting, currentPage, pageSize)
    }
    yield put(ProgramSummaryActions.listRequestSucceed(response))
    if (currentPage) {
      yield call(updateLocationQuery, ProgramSummaryRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProgramSummaryActions.listRequestFailed(errType))
  }
}

export const fetchPrograms = function*(props) {
  try {
    const response: ListResponse<ProgramDTO> = yield call(managers.getPrograms, props.payload)
    const { data } = response
    yield put(ProgramSummaryActions.programRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProgramSummaryActions.programRequestFailed(errType))
  }
}

export const batchUpdate = function*({ payload }: ReturnType<typeof ProgramSummaryActions.batchUpdateRequested>) {
  try {
    const items: ProgramDTO[] = yield all(
      payload.map((dto: ProgramDTO) =>
        call(managers.updateProgram, dto.ps_id, dto.program_title, dto.product_id, {
          qualified_bags: dto.qualified_bags,
          notes: dto.notes,
          qualified: dto.qualified,
        }),
      ),
    )
    yield put(ProgramSummaryActions.batchUpdateSucceed(items))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ProgramSummaryActions.batchUpdateError(type, detail))
  }
}

const ProgramSummarySaga = function*() {
  yield all([
    takeLatest(ProgramSummaryActions.itemRequested.type, sagas.fetchItem),
    takeLatest(ProgramSummaryActions.listRequested.type, fetchList),
    takeLatest(ProgramSummaryActions.filterUpdated.type, fetchList),
    takeLatest(ProgramSummaryActions.programRequested.type, fetchPrograms),
    takeLatest(ProgramSummaryActions.batchUpdateRequested.type, batchUpdate),
  ])
}

export default ProgramSummarySaga
