import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import * as Styled from './styled'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import AuthActions from 'modules/domain/auth/duck'
import useAnalyticsSafe from 'hooks/useAnalyticsSafe'
import { useAction } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { getRoleWithPermission } from 'modules/domain/farmer/helpers'

const ProfileButton: React.FC = memo(() => {
  const { track } = useAnalyticsSafe()
  const profileFetchProgress = useSelector(AuthSelectors.getInitProgress)
  const profile = useSelector(AuthSelectors.profile)
  const companyName = profile?.company?.internal_name
  const roleName = getRoleWithPermission(profile?.role)
  const logoutAction = useAction(AuthActions.signOutRequested)

  if (profileFetchProgress === Progress.WORK) {
    return (
      <div style={{ margin: 'auto', width: 'fit-content', padding: '10px' }}>
        <SpinnerLayout />
      </div>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.UserInfoWrapper>
        <Styled.TruncateWrapper>{`${profile?.first_name || ''} ${profile?.last_name || ''}`}</Styled.TruncateWrapper>
        {!!companyName && <Styled.TruncateWrapper>{companyName}</Styled.TruncateWrapper>}
        {roleName && <Styled.TruncateWrapper>{`${roleName}`}</Styled.TruncateWrapper>}
      </Styled.UserInfoWrapper>
      <Styled.LogoutButton
        data-test-id="logout-button"
        onClick={() => {
          track('LogOut')
          logoutAction()
        }}
      >
        <Styled.StyledExit data-test-id="exit" />
      </Styled.LogoutButton>
    </Styled.Wrapper>
  )
})

ProfileButton.displayName = 'ProfileButton'

export default ProfileButton
