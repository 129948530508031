import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'

import SeasonDetailsForm, { SeasonFormInputs } from 'views/pages/Season/SeasonDetailsForm/SeasonDetailsForm'
import SeasonRoutes from 'views/pages/Season/routes'
import SeasonSelectors from 'modules/domain/season/selectors'
import SeasonActions from 'modules/domain/season/duck'

const SeasonAdd: React.FC = () => {
  const { t } = useTranslation('season')
  const submitAction = useAction(SeasonActions.addRequested)
  const page = useSelector(SeasonSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: SeasonRoutes.List })
  }

  const handleFormSubmit = (props: SeasonFormInputs) => {
    submitAction({
      company_id: props.company_id,
      title: props.title,
      start_date: props.start_date,
      enable_final_qty: props.enable_final_qty,
      enable_delivered_qty: props.enable_delivered_qty,
      stop_taking_orders: props.stop_taking_orders,
      is_season_closed: props.is_season_closed,
      is_default_for_return_declarations: props.is_default_for_return_declarations,
      show_retailer_order_suggestion: props.show_retailer_order_suggestion,
    })
  }

  useHelmet({ title: t('addSeasonMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <SeasonDetailsForm onSubmit={handleFormSubmit} onCancel={goBack} />
      </Layout.Content>
    </>
  )
}

export default SeasonAdd
