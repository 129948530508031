import { MultiSelectWithPagination, MultiSelectWithPaginationProps, Progress } from '@agro-club/frontend-shared'
import { arrToDict } from 'modules/utils/helpers'
import { apiClient } from 'modules/utils/httpClient'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MultiValue } from 'react-select'
import { Dict } from 'types/generics'
import { MapFn, useEntitySelectState, UseEntitySelectStateProps } from './useEntitySelectState'

export type EntityMultiSelectProps<
  EntityType,
  EntityTypeMapped = EntityType,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
> = Omit<
  MultiSelectWithPaginationProps<Map extends undefined ? EntityType : EntityTypeMapped>,
  | 'progress'
  | 'options'
  | 'value'
  | 'defaultValue'
  | 'onUpdateRequested'
  | 'total'
  | 'pageSize'
  | 'onChange'
  | 'getOptionValue'
  | 'getOptionLabel'
> & {
  values?: string | string[]
  pageSize?: number
  onChange?: (
    values: string[],
    original: MultiValue<Map extends undefined ? EntityType : EntityTypeMapped>,
    dict: Dict<Map extends undefined ? EntityType : EntityTypeMapped>,
  ) => void
  onLoadDefaults?: (items: EntityType[], newDict: Dict<Map extends undefined ? EntityType : EntityTypeMapped>) => void
  onLoadList?: UseEntitySelectStateProps<EntityType, any, any, EntityTypeMapped, Map>['onLoadList']
  updateDeps?: unknown[]
  progress?: Progress
  showLabel?: boolean
}

type InternalEntityMultiSelectProps<
  EntityType,
  ListRequestFilter extends Object,
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
> = EntityMultiSelectProps<EntityType, EntityTypeMapped, Map> & {
  getEntityByIds?: (ids: string[]) => Promise<EntityType[]>
  getOptionValue: MultiSelectWithPaginationProps<
    Map extends undefined ? EntityType : EntityTypeMapped
  >['getOptionValue']
  getOptionLabel: MultiSelectWithPaginationProps<
    Map extends undefined ? EntityType : EntityTypeMapped
  >['getOptionLabel']
} & UseEntitySelectStateProps<EntityType, ListRequestFilter, ListRequestSorting, EntityTypeMapped, Map>

export const EntityMultiSelect = <
  EntityType,
  ListRequestFilter extends Object,
  ListRequestSorting extends Object,
  EntityTypeMapped,
  Map extends MapFn<EntityType, EntityTypeMapped> | undefined = undefined
>({
  pageSize = 25,
  onChange,
  values: valuesFromProps = [],
  getEntityList,
  getEntityByIds,
  filter,
  sort,
  preventFetch,
  isDisabled,
  getOptionValue,
  getOptionLabel,
  getMappedOption,
  onLoadDefaults,
  updateDeps,
  searchParamKey,
  progress,
  cancelRequest = apiClient.cancelRequest,

  ...props
}: InternalEntityMultiSelectProps<EntityType, ListRequestFilter, ListRequestSorting, EntityTypeMapped, Map>) => {
  const [progressByIds, setProgressByIds] = useState(Progress.IDLE)
  const valueFromPropsNormalized = useMemo(
    () => (Array.isArray(valuesFromProps) ? valuesFromProps : [valuesFromProps]),
    [valuesFromProps],
  )
  const [value, setValue] = useState<MultiValue<Map extends undefined ? EntityType : EntityTypeMapped>>([])
  const updatedFromChange = useRef(false)

  const { dict, setDict, ...selectProps } = useEntitySelectState({
    getEntityList,
    filter,
    sort,
    pageSize,
    preventFetch: preventFetch || isDisabled,
    getOptionValue,
    getMappedOption,
    updateDeps,
    searchParamKey,
  })

  const handleChange = (newValues: MultiValue<Map extends undefined ? EntityType : EntityTypeMapped>) => {
    onChange?.(newValues.map(getOptionValue), newValues, dict)
    setValue(newValues)
    updatedFromChange.current = true
  }

  useEffect(() => {
    let mounted = true
    if (Array.isArray(valuesFromProps) && valuesFromProps.length === 0 && value.length !== 0) {
      return setValue([])
    }

    if (updatedFromChange.current) {
      updatedFromChange.current = false
      return
    }

    const valueFromPropsHasDiffLength = valuesFromProps.length !== value.length
    const valueFromPropsContainsDiffId = () => {
      const valueIds = value.map(getOptionValue)
      return valueFromPropsNormalized.some(val => !valueIds.includes(val))
    }

    const needToUpdateValueState = valueFromPropsHasDiffLength || valueFromPropsContainsDiffId()

    if (needToUpdateValueState) {
      const cachedValues: (Map extends undefined ? EntityType : EntityTypeMapped)[] = []
      const needToFetchIds = valueFromPropsNormalized.filter(id => {
        if (dict[id]) cachedValues.push(dict[id])
        return !dict[id]
      })
      if (needToFetchIds.length && getEntityByIds) {
        setProgressByIds(Progress.WORK)
        const promise = getEntityByIds(needToFetchIds)
          .then(entities => {
            if (mounted) {
              const newOptions = entities.map(
                entity =>
                  (getMappedOption ? getMappedOption(entity) : entity) as Map extends undefined
                    ? EntityType
                    : EntityTypeMapped,
              )
              setValue([...cachedValues, ...newOptions])
              const newDict = { ...dict, ...arrToDict(newOptions, getOptionValue) }
              setDict(newDict)
              setProgressByIds(Progress.SUCCESS)
              onLoadDefaults?.(entities, newDict)
            }
          })
          .catch(() => {
            if (mounted) {
              setValue(cachedValues)
              setProgressByIds(Progress.ERROR)
            }
          })
        return () => {
          mounted = false
          cancelRequest(promise)
        }
      } else {
        setValue(cachedValues)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromPropsNormalized])

  return (
    <MultiSelectWithPagination
      isSearchable
      {...selectProps}
      isDisabled={isDisabled}
      {...props}
      progress={
        progress === Progress.WORK || selectProps.progress === Progress.WORK || progressByIds === Progress.WORK
          ? Progress.WORK
          : selectProps.progress
      }
      clearDeps={[filter, sort, pageSize]}
      pageSize={pageSize}
      value={value}
      onChange={handleChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  )
}
