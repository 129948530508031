import React from 'react'
import { ProgressCircle } from '@agro-club/frontend-shared'
import { clamp } from 'ramda'
import * as Styled from './styled'

const Loader: React.FC<{ percent: number }> = ({ percent }) => {
  return (
    <Styled.LoaderContainer>
      <ProgressCircle radius={30} stroke={6} percent={clamp(0, 100, percent)} />
    </Styled.LoaderContainer>
  )
}

export default Loader
