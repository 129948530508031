import React from 'react'
import { BasicSelect, useAction } from '@agro-club/frontend-shared'
import { Filter as FilterComponent } from '../../../components/TableFilters/TableFilters'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import managers from 'modules/domain/changeLog/managers'
import { ChangeLogFilter } from 'modules/domain/changeLog/types'
import { useSelector } from 'react-redux'
import ChangeLogSelector from 'modules/domain/changeLog/selectors'
import ChangeLogActions from 'modules/domain/changeLog/duck'
import { useTranslation } from 'react-i18next'

const EntityFilter: React.FC = () => {
  const { t } = useTranslation('changeLog')
  const collectionsListHook = makeCancellableResourceListHook(makeCancelable(managers.getCollectionNames))
  const [progress, collections = []] = collectionsListHook()

  const filterValue = useSelector(ChangeLogSelector.filter)
  const filterUpdated = useAction(ChangeLogActions.filterUpdated)
  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ChangeLogFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const getLabel = (item: any) => item.title
  const getValue = (item: any) => item.collection
  return (
    <FilterComponent title={t('headers.entity')}>
      <BasicSelect
        value={collections.find(e => e.collection === filterValue?.collection)}
        onChange={val => handleFilterChange({ collection: val?.collection })}
        placeholder={t('placeholders.entity')}
        width={'200px'}
        variant={'small'}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        options={collections}
        progress={progress}
        isClearable
      />
    </FilterComponent>
  )
}

export default EntityFilter
