import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import * as Styled from './styled'
import Routes from './routes'
import { Input, useAction, useHelmet, useKey, useQuery } from '@agro-club/frontend-shared'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputStyled = styled(Input)`
  margin-bottom: 24px;
`

type FormFields = {
  password: string
  passwordConfirmation: string
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const StyledLink = styled(Styled.LinkButton)`
  margin-bottom: 10px;
  margin-top: 78px;
`

const ResultMessage: React.FC<{ text: string; isError?: boolean }> = ({ text, isError }) => {
  const { t } = useTranslation('auth')

  return (
    <MessageWrapper data-test-id={'reset-succeed'}>
      <Styled.HintText>{text}</Styled.HintText>
      {!isError && (
        <StyledLink to={Routes.Login} data-test-id={'back-to-login-link'}>
          {t('back_to_login')}
        </StyledLink>
      )}
    </MessageWrapper>
  )
}

const SetNewPasswordForm = () => {
  const { t } = useTranslation(['auth', 'common', 'errors'])
  const searchParams = useQuery()
  const resetPasswordToken = searchParams.get('t') || ''

  const submitAction = useAction(AuthActions.setNewPasswordRequested)
  const setNewPasswordProgress = useSelector(AuthSelectors.setNewPasswordProgress)

  useHelmet({ title: t('setNewPasswordMetaTitle') })

  const onSubmit = useCallback(
    (values: FormFields) => {
      submitAction({ password: values.password, token: resetPasswordToken })
    },
    [submitAction, resetPasswordToken],
  )

  const validationSchema = useMemo(() => {
    return Yup.object({
      password: Yup.string()
        .min(6, t('validation:password_length_warning'))
        .required(t('validation:password_required')),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t('validation:passwordShouldBeTheSame'))
        .min(6, t('validation:password_length_warning'))
        .required(t('validation:password_required')),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema,
    onSubmit,
  })

  const inputRef = useRef<HTMLInputElement>(null)
  useKey(inputRef, 'keydown', () => {
    if (formik.isValid && formik.dirty) {
      formik.submitForm()
    }
  })

  if (!resetPasswordToken) {
    return <ResultMessage text={t('resetTokenMissing')} isError={true} />
  } else if (setNewPasswordProgress === Progress.WORK) {
    return <SpinnerLayout />
  } else if (setNewPasswordProgress === Progress.ERROR) {
    return <ResultMessage text={t('errors:somethingWrong')} isError={true} />
  } else if (setNewPasswordProgress === Progress.SUCCESS) {
    return <ResultMessage text={t('passwordUpdateSucceed')} />
  }

  return (
    <FormWrapper
      onSubmit={e => {
        e.preventDefault()
        formik.submitForm()
      }}
    >
      <InputStyled
        label={t('common:password')}
        placeholder={t('passwordPlaceholder')}
        {...formik.getFieldProps('password')}
        invalid={formik.touched.password && !!formik.errors.password}
        errorText={formik.errors.password}
        type={'password'}
      />

      <InputStyled
        ref={inputRef}
        label={t('confirmPassword')}
        placeholder={t('passwordPlaceholder')}
        {...formik.getFieldProps('passwordConfirmation')}
        invalid={formik.touched.passwordConfirmation && !!formik.errors.passwordConfirmation}
        errorText={formik.errors.passwordConfirmation}
        type={'password'}
      />
      <Styled.SubmitButton filled={true} disabled={!formik.isValid || !formik.dirty} intent={'primary'} type={'submit'}>
        {t('common:submit')}
      </Styled.SubmitButton>
    </FormWrapper>
  )
}

export default SetNewPasswordForm
