import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'
import managers from 'modules/domain/season/managers'
import { Season, SeasonListRequestFilter } from 'modules/domain/season/types'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type SeasonSelectProps = EntitySelectProps<Season> & {
  companyId?: string | string[]
  useDefaultCurrentSeason?: boolean
  isCurrentSeasons?: boolean
  getMappedOption?: (season: Season) => Season
}

export const getSeasonLabel = (item: Season) => item.title

export const getSeasonValue = (item: Season) => item.id

const seasonSort = {
  sort_field: 'title',
}

export const SeasonSelect: React.VFC<SeasonSelectProps> = ({
  companyId,
  isCurrentSeasons,
  getMappedOption,
  useDefaultCurrentSeason,
  value,
  onChange,
  ...props
}) => {
  const filter: SeasonListRequestFilter = useMemo(() => ({ company_id: companyId, is_current: isCurrentSeasons }), [
    companyId,
    isCurrentSeasons,
  ])

  const [currentSeasonProgress, currentSeason] = useCurrentSeasonOnce(filter, !useDefaultCurrentSeason || !!value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectValue = useMemo(() => (useDefaultCurrentSeason && !value && currentSeason ? currentSeason : undefined), [
    currentSeason,
  ])

  return (
    <SelectTestWrapper data-test-id="season-select">
      <EntitySelect
        filter={filter}
        sort={seasonSort}
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getSeasonLabel}
        getOptionValue={getSeasonValue}
        getMappedOption={getMappedOption}
        searchParamKey="title"
        value={value}
        progress={useDefaultCurrentSeason && !value ? currentSeasonProgress : undefined}
        onChange={onChange}
        fullValue={selectValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
