import { useEffect } from 'react'

export const useHotkeys = (listenedKey: string, callback: () => void, useMetaKeys = true) => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.code === listenedKey && (e.ctrlKey || e.metaKey || !useMetaKeys)) {
        callback()
      }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [listenedKey, callback, useMetaKeys])
}
