import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { EntityMetadata } from 'modules/domain/types'
import { InventoryTransferRequest } from '../inventoryInTransferRequest/types'

const dict = (state: AppGlobalState) => state.inventoryOutTransferRequest.items
const ids = (state: AppGlobalState) => state.inventoryOutTransferRequest.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): InventoryTransferRequest | undefined =>
  state.inventoryOutTransferRequest.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<InventoryTransferRequest> =>
  state.inventoryOutTransferRequest.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.inventoryOutTransferRequest.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.inventoryOutTransferRequest.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.inventoryOutTransferRequest.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.inventoryOutTransferRequest.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: InventoryTransferRequest[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.inventoryOutTransferRequest.filter
const sorting = (state: AppGlobalState) => state.inventoryOutTransferRequest.sorting
const page = (state: AppGlobalState) => state.inventoryOutTransferRequest.page
const total = (state: AppGlobalState) => state.inventoryOutTransferRequest.total
const hasNext = (state: AppGlobalState) =>
  state.inventoryOutTransferRequest.total > state.inventoryOutTransferRequest.ids.length
const pageSize = (state: AppGlobalState) => state.inventoryOutTransferRequest.pageSize
const pages = (state: AppGlobalState) =>
  Math.ceil(state.inventoryOutTransferRequest.total / state.inventoryOutTransferRequest.pageSize)
const updateProgress = (state: AppGlobalState) => state.inventoryOutTransferRequest.updateProgress

const InventoryOutTransferRequestSelectors = {
  filter,
  sorting,
  page,
  total,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,
  updateProgress,
}

export default InventoryOutTransferRequestSelectors
