import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FormField, FormikHook, NumberInput } from '@agro-club/frontend-shared'

import * as Styled from './styled'
import { GiftTierRule } from 'modules/domain/discountRule/types'
import { FieldMetaProps } from 'formik/dist/types'
import { Product } from 'modules/domain/product/types'
import ProductNoFetchMultiSelect from 'views/components/ProductNoFetchMultiSelect/ProductNoFetchMultiSelect'

type DiscountTierRule = Partial<Omit<GiftTierRule, 'type'>>

type GiftTiersRulesFormProps = {
  tiers_rules: DiscountTierRule[]
}

const GiftTiersRulesForm: React.FC<{
  gifts: Product[]
  useFormik: FormikHook
  producerId: string
  tiers_rules: DiscountTierRule[]
}> = ({ gifts, tiers_rules, producerId, useFormik }) => {
  const { t } = useTranslation('discountRules')
  const initialRender = useRef(true)

  const validationSchema = useMemo(() => {
    return Yup.object({
      tiers_rules: Yup.array(
        Yup.object({
          min_qty: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
          limit: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<GiftTiersRulesFormProps>({
    initialValues: {
      tiers_rules,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('tiers_rules', remove(idx, 1, formik.values.tiers_rules))
    },
    [formik],
  )

  const handleAddClick = useCallback(() => {
    formik.setFieldValue('tiers_rules', [...formik.values.tiers_rules, { min_qty: 1, limit: 1 }])
  }, [formik])

  const handleGiftRemove = useCallback(
    (giftId: string, giftListKey: string) => {
      formik.setFieldValue(
        giftListKey,
        formik.getFieldProps(giftListKey).value?.filter(id => id !== giftId),
      )
    },
    [formik],
  )

  const handleNumberInputChange = useCallback(
    (field, value) => {
      formik.setFieldValue(field, value)
      formik.setFieldTouched(field)
    },
    [formik],
  )

  const hangleGiftChange = useCallback(
    (giftId: string, giftListKey: string) => {
      if (formik.getFieldProps(giftListKey).value?.includes(giftId)) {
        handleGiftRemove(giftId, giftListKey)
      } else {
        formik.setFieldValue(giftListKey, [...(formik.getFieldProps(giftListKey).value || []), giftId])
      }
    },
    [formik, handleGiftRemove],
  )

  useEffect(() => {
    if (!initialRender.current) {
      // need to erase existing tiers when the producer (seller) changes
      formik.setFieldValue('tiers_rules', [])
    } else {
      initialRender.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producerId])

  return (
    <Styled.ParamsContainer>
      {formik.values.tiers_rules.map((_, idx) => {
        const minKey = `tiers_rules[${idx}].min_qty`
        const maxKey = `tiers_rules[${idx}].max_qty`
        const limitKey = `tiers_rules[${idx}].limit`
        const giftListKey = `tiers_rules[${idx}].available_gifts`

        const getFieldMeta = (field: string): FieldMetaProps<GiftTiersRulesFormProps> =>
          formik.getFieldMeta(`tiers_rules[${idx}].${field}`)

        return (
          <Styled.Container key={idx}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <Styled.TierTitle>{`${t('form.labels.tierTitle')} ${idx + 1}`}</Styled.TierTitle>
            <FormField
              invalid={getFieldMeta('min_qty').touched && !!getFieldMeta('min_qty').error}
              errorText={getFieldMeta('min_qty').error}
              label={t('form.labels.minQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(minKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(minKey, value)}
                  min={1}
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('max_qty').touched && !!getFieldMeta('max_qty').error}
              errorText={getFieldMeta('max_qty').error}
              label={t('form.labels.maxQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(maxKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(maxKey, value)}
                  min={0}
                />
              )}
            />
            <FormField
              invalid={getFieldMeta('limit').touched && !!getFieldMeta('limit').error}
              errorText={getFieldMeta('limit').error}
              label={t('form.labels.limit')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(limitKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(limitKey, value)}
                  min={1}
                />
              )}
              required
            />
            <Styled.GiftSelectWrapper>
              <ProductNoFetchMultiSelect
                products={gifts}
                values={formik.getFieldProps(giftListKey).value}
                onChange={giftId => hangleGiftChange(giftId, giftListKey)}
                onRemove={giftId => handleGiftRemove(giftId, giftListKey)}
                label={t('form.labels.availableGifts')}
                placeholder={t('form.placeholders.availableGifts')}
              />
            </Styled.GiftSelectWrapper>
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <Styled.AddButton onClick={handleAddClick} type={'button'}>
        {t('form.addMoreTiers')}
      </Styled.AddButton>
    </Styled.ParamsContainer>
  )
}

export default GiftTiersRulesForm
