import styled, { StyledProps } from 'styled-components'
import { TableFilterVariant } from './TableFilters'

type ThemedProps = StyledProps<{}>

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterTitle = styled.div<StyledProps<{ variant?: TableFilterVariant }>>`
  color: ${({ theme }: ThemedProps) => theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: ${props => (props.variant === 'small' ? '12px' : '14px')};
  line-height: ${props => (props.variant === 'small' ? '16px' : '20px')};
`

export const Filter = styled.div`
  flex-shrink: 0;
`

export const FilterOptions = styled.div`
  display: flex;

  > button {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`
