import React, { useMemo, useCallback, useState } from 'react'
import { Progress } from 'modules/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useTranslation } from 'react-i18next'
import {
  Button,
  helpersObject,
  Input,
  SectionBody,
  SectionContainer,
  SimpleSelect,
  FormComponents,
  Checkbox,
  Switch,
} from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import i18n from 'i18n'
import { createLocalizedValue } from 'helpers/localization'
import { AvailableLanguages, LocalizedValue, availableLanguagesList } from 'types/entities'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'

export type FormProps = {
  title_i18n?: LocalizedValue
  type: string
  is_stackable: boolean
}

const optionsTypes = Object.values(ProductOptionType).map(item => ({
  id: item,
  title: i18n.t(`productOptions:types.${item}`),
}))

const MainFormContainer = styled.div`
  max-width: 300px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 1fr;
`

const LabeledContainerStyled = styled(FormComponents.LabeledContainer)`
  position: relative;
  padding-bottom: 16px;
`

const ChooseLanguageBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

const ErrorStyled = styled.div<StyledProps<{}>>`
  line-height: 16px;
  margin-top: 15px;
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

const ProductOptionsDetailsForm: React.FC<{
  mode: 'edit' | 'create'
  initialValues?: FormProps
  onRemove?(): void
  onSubmit(value: FormProps, options?: { dirty?: boolean }): void
  onCancel(): void
  progress?: Progress
  errorText?: string
  removeProgress?: Progress
}> = ({ progress, errorText, removeProgress, onRemove, onSubmit, onCancel, initialValues }) => {
  const { t } = useTranslation(['productOptions', 'common', 'validation'])

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) => [initialValues?.title_i18n?.[lang]].some(Boolean)
    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }
    return result
  })

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const validationSchema = useMemo(() => {
    const i18nValue = Yup.object(createLocalizedValue(_ => Yup.string()))

    return Yup.object({
      title_i18n: i18nValue,
      type: Yup.string()
        .required(t('validation:field_required'))
        .oneOf(Object.values(ProductOptionType)),
      is_stackable: Yup.boolean(),
    })
  }, [t])

  const formik = useFormik({
    initialValues: {
      title_i18n: (initialValues?.title_i18n as LocalizedValue) || '',
      type: initialValues?.type || '',
      is_stackable: initialValues?.is_stackable || false,
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleSubmit = useCallback(async () => {
    try {
      await formik.submitForm()
      if (!formik.isValid) return

      onSubmit(helpersObject.nonEmptyFields(formik.values))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }, [formik, onSubmit])

  const isEditWarning = progress === Progress.ERROR && errorText
  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <MainFormContainer data-test-id="product-options-form-container">
              <FormComponents.FormSection title={t('form.title')}>
                <LabeledContainerStyled label={t('fields.title')}>
                  <ChooseLanguageBlock>
                    {availableLanguagesList.map((lang, idx: number) => (
                      <Checkbox
                        label={t(`common:langNames.${lang}`)}
                        isChecked={isLangActive(lang)}
                        value={lang}
                        onChange={handleLangChange}
                        key={lang}
                        testId={`product-option-lang-${idx}`}
                      />
                    ))}
                  </ChooseLanguageBlock>
                  {!languages.length && <ErrorStyled>{t('validation:language_required')}</ErrorStyled>}
                </LabeledContainerStyled>
                <VerticalFormsContainer>
                  {availableLanguagesList.map(lang => {
                    if (isLangActive(lang)) {
                      const title = formik.getFieldProps(`title_i18n[${lang}]`)
                      const meta = formik.getFieldMeta(`title_i18n[${lang}]`)
                      return (
                        <Input
                          {...formik.getFieldProps(title)}
                          key={lang}
                          invalid={meta.touched && !!meta.error}
                          errorText={meta.error}
                          label={t(`common:langNames.${lang}`)}
                          required={lang === 'en'}
                        />
                      )
                    }
                    return null
                  })}
                </VerticalFormsContainer>
                <SimpleSelect
                  label={t('fields.type')}
                  value={formik.values.type}
                  options={optionsTypes}
                  onChange={val => formik.setFieldValue('type', val)}
                  invalid={formik.touched.type && !!formik.errors.type}
                  errorText={formik.errors.type}
                  isDisabled={!optionsTypes.length}
                  required
                  menuPlacement="top"
                />
                <Switch
                  on={formik.values.is_stackable}
                  label={t('fields.isStackable')}
                  onClick={val => formik.setFieldValue('is_stackable', val)}
                />
              </FormComponents.FormSection>
            </MainFormContainer>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          onClick={handleSubmit}
          intent={'primary'}
          filled
          progress={progress}
          disabled={!formik.dirty || progress === Progress.WORK}
          data-test-id={'save-button'}
        >
          {t('common:save')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />
        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues?.title_i18n || '' })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
      {isEditWarning ? (
        <StickyFooterLayout.NotificationWarning>
          <div>{errorText}</div>
        </StickyFooterLayout.NotificationWarning>
      ) : null}
    </StickyFooterLayout.Wrapper>
  )
}

export default ProductOptionsDetailsForm
