import React, { useState } from 'react'

import { Badge } from 'modules/domain/badge/types'
import * as Styled from './styled'

type Props = {
  badge: Badge
  index: number
  onDelete: (id: string) => void
}

const BadgeCard: React.VFC<Props> = ({ badge, index, onDelete }) => {
  const [hoveredBadge, setHoveredBadge] = useState<number | null>(null)

  return (
    <Styled.BadgeCard key={badge?.id} data-test-id={badge?.id}>
      <span>{badge?.name}</span>
      <Styled.BadgeImageOption
        src={badge?.urls.x1}
        alt={badge?.name}
        title={badge?.name}
        srcSet={`${badge?.urls.x1} 1x, ${badge?.urls.x2} 2x, ${badge?.urls.x3} 3x`}
      />
      <Styled.RemoveIcon
        onMouseEnter={() => setHoveredBadge(index)}
        onMouseLeave={() => setHoveredBadge(null)}
        onClick={() => onDelete(badge?.id)}
        data-test-id={`remove-button-${badge?.id}`}
      />
      {hoveredBadge !== null && hoveredBadge === index ? <Styled.RemoveOverlay /> : null}
    </Styled.BadgeCard>
  )
}

export default BadgeCard
