import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import { Household } from 'modules/domain/household/types'
import HouseholdSelectors from 'modules/domain/household/selectors'
import HouseholdActions from 'modules/domain/household/duck'
import { useHouseholdList } from 'modules/domain/household/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from './routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  AdvancedHeadCell,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'

const PartnersCell: React.FC<CellProps<Household>> = ({ value }) => {
  return <div>{value.map(partner => partner.full_name).join(', ')}</div>
}

const HouseholdsSortableHeadCell = AdvancedHeadCell<keyof Household>()

const HouseholdList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['household', 'labels'])
  const [progress, data] = useHouseholdList()
  const sortingUpdated = useAction(HouseholdActions.sortingUpdated)
  const listRequested = useAction(HouseholdActions.listRequested)
  const total = useSelector(HouseholdSelectors.total)
  const pages = useSelector(HouseholdSelectors.pages)
  const page = useSelector(HouseholdSelectors.page)
  const pageSize = useSelector(HouseholdSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(HouseholdSelectors.sorting)

  const columnsAll = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.householdName'),
        accessor: 'name' as const,
      },
      {
        Header: t('list.tableHeaders.householdId'),
        accessor: 'id' as const,
      },
      {
        Header: t('list.tableHeaders.participants'),
        accessor: 'partners' as const,
        Cell: PartnersCell,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<Household>({
    columns: columnsAll,
    data: data as Household[],
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('householdList')

  return (
    <TComponents.Wrapper noFilters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <HouseholdsSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Household}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </HouseholdsSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={columnsAll.length}
            loading={<TComponents.Spinner />}
          >
            <div>{t('list.emptyMsg')}</div>
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default HouseholdList
