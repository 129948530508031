import managers from 'modules/domain/programSummary/managers'
import { ProgramSummaryProduct } from 'modules/domain/programSummary/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import useLangPicker from 'hooks/useLangPicker'

export const getProductLabel = (item: ProgramSummaryProduct, pick) => pick(item.product_title)

export const getProductValue = (item: ProgramSummaryProduct) => item.product_id

export const ProductSelect: React.VFC<EntityMultiSelectProps<ProgramSummaryProduct>> = ({ ...props }) => {
  const { pick } = useLangPicker(lang => `title_${lang}`)
  return (
    <SelectTestWrapper data-test-id="product-select">
      <EntityMultiSelect
        getEntityByIds={managers.getProductsByIds}
        getEntityList={managers.getProducts}
        searchParamKey="title"
        getOptionLabel={item => getProductLabel(item, pick)}
        getOptionValue={getProductValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
