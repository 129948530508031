import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import { FarmerOrderSku } from 'types/farmerOrderSku'
import { FarmerCreateOrderSkuDTO, FarmerOrderSkuListRequestFilter, FarmerOrderSkuListRequestSorting } from './types'

export default {
  ...createBasicManagers<
    FarmerOrderSkuListRequestFilter,
    FarmerOrderSkuListRequestSorting,
    FarmerOrderSku,
    FarmerCreateOrderSkuDTO,
    FarmerCreateOrderSkuDTO
  >(endpoints.farmerOrderSku),
  ordersCount: async (filter: FarmerOrderSkuListRequestFilter = {}) => {
    const response = await apiClient.get<ListResponse<FarmerOrderSku>>(endpoints.farmerOrderSku('count'), {
      ...filter,
      page_size: 1,
    })
    return response
  },
} as const
