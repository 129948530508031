import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { createGetList } from './common'

export type TargetSkuSeason = {
  id: string
  title: string
}

type TargetSkuSeasonFilter = {
  manufacturer_id?: string
  title?: string
}

type TargetSkuSeasonSelectProps = EntityMultiSelectProps<TargetSkuSeason> & {
  valueLabel?: string
  manufacturerId?: string
  scope?: TargetSkuTypes
}

const getListTerritoryFilter = createGetList<TargetSkuSeason, TargetSkuSeasonFilter>(
  endpoints.target2sku('territory/seasons'),
)

const getListRetailerFilter = createGetList<TargetSkuSeason, TargetSkuSeasonFilter>(
  endpoints.target2sku('retailer/seasons'),
)

const getListFarmerFilter = createGetList<TargetSkuSeason, TargetSkuSeasonFilter>(
  endpoints.target2sku('farmer/seasons'),
)

const getListCropFilter = createGetList<TargetSkuSeason, TargetSkuSeasonFilter>(
  endpoints.target2sku('territory-crop/seasons'),
)

const scopeManagerMap = {
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Retailer]: getListRetailerFilter,
  [TargetSkuTypes.Territory]: getListTerritoryFilter,
  [TargetSkuTypes.Crop]: getListCropFilter,
}

const getLabel = (item: TargetSkuSeason) => item.title

const getValue = (item: TargetSkuSeason) => item.id

export const TargetSkuSeasonSelect: React.VFC<TargetSkuSeasonSelectProps> = ({
  manufacturerId,
  scope = 'territory',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId }), [manufacturerId])

  return (
    <EntityMultiSelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      searchParamKey="title"
      disableTags
      hideSelectedOptions={false}
      data-test-id="target-season-multi-select"
      {...props}
    />
  )
}
