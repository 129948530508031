import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormText, Progress, SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { useUserById } from 'modules/domain/user/hooks'
import { useFarmerById } from 'modules/domain/farmer/hooks'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isAgro } from 'types/entities'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useChangeLogListPreventable } from 'modules/domain/changeLog/hooks'
import { ChangeLogFilter } from 'modules/domain/changeLog/types'
import styled from 'styled-components'

type OrderHistoryCardProps = {
  orderId: string
  createdById?: string
  confirmedByProducerId?: string
  confirmedByDistributorId?: string
  confirmedByAgroClubId?: string
  createdDate?: string
  orderSource?: string
}

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
`

const OrderHistoryCard: React.VFC<OrderHistoryCardProps> = ({
  orderId = '',
  createdById = '',
  confirmedByProducerId = '',
  confirmedByDistributorId = '',
  confirmedByAgroClubId = '',
  createdDate,
  orderSource,
}) => {
  const { t } = useTranslation('common')
  const getFormatedDate = useDateFormatFn({ withTime: true })
  const role = useSelector(AuthSelectors.role)

  const [filter] = useState<ChangeLogFilter>({ obj_id: orderId })
  const [changelogProgress, changelogData = []] = useChangeLogListPreventable(false, filter)
  const lastModifiedRecord = changelogData.length ? changelogData[0] : null
  const [crmUserProgress, crmUser] = useUserById(createdById)
  const [userProgress, user] = useFarmerById(createdById)
  const [confirmedByProducerProgress, confirmedByProducer] = useUserById(confirmedByProducerId)
  const [confirmedByDistributorProgress, confirmedByDistributor] = useUserById(confirmedByDistributorId)
  const [confirmedByAgroClubProgress, confirmedByAgroClub] = useUserById(confirmedByAgroClubId)

  const createdBy = crmUser || user

  const isLoading = [
    crmUserProgress,
    userProgress,
    confirmedByProducerProgress,
    confirmedByDistributorProgress,
    confirmedByAgroClubProgress,
  ].includes(Progress.WORK)

  if (isLoading) return <SpinnerLayout />

  return (
    <SectionContainer>
      <SectionBody>
        {createdBy && (
          <FormText label={t('createdBy')}>{`${createdBy.full_name} / ${t(
            `common:roles.${createdBy.role}`,
          )}`}</FormText>
        )}
        {createdDate && <FormText label={t('createdAt')}>{getFormatedDate(createdDate)}</FormText>}
        {orderSource && <FormText label={t('orderSource')}>{t(orderSource)}</FormText>}
        {confirmedByProducer && (
          <FormText label={t('confirmedByProducer')}>{`${confirmedByProducer.full_name} / ${t(
            `common:roles.${confirmedByProducer.role}`,
          )}`}</FormText>
        )}
        {confirmedByDistributor && (
          <FormText label={t('confirmedByDistributor')}>{`${confirmedByDistributor.full_name} / ${t(
            `common:roles.${confirmedByDistributor.role}`,
          )}`}</FormText>
        )}
        {confirmedByAgroClub && (
          <FormText label={t('confirmedByAgroClub')}>
            {isAgro(role)
              ? `${confirmedByAgroClub.full_name} / ${t(`common:roles.${confirmedByAgroClub.role}`)}`
              : 'AgroClub'}
          </FormText>
        )}
        {lastModifiedRecord && (
          <FormText label={t('lastModified')}>
            {changelogProgress === Progress.SUCCESS && (
              <FormSection>
                <section>{lastModifiedRecord?.user?.full_name}</section>
                <section>{getFormatedDate(lastModifiedRecord?.created_at)}</section>
              </FormSection>
            )}
          </FormText>
        )}
      </SectionBody>
    </SectionContainer>
  )
}

export default React.memo(OrderHistoryCard)
