import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from './routes'

import UserTable from 'views/pages/User/UserTable'
import UserEdit from './UserEdit'
import UserAdd from './UserAdd'
import UserProfile from './UserProfile'

const UserPage = () => {
  return (
    <Switch>
      <Route path={Routes.List} exact={true}>
        <UserTable />
      </Route>
      <Route path={Routes.Edit} exact={true}>
        <UserEdit />
      </Route>
      <Route path={Routes.Add} exact={true}>
        <UserAdd />
      </Route>
      <Route path={Routes.Profile} exact={true}>
        <UserProfile />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  )
}

export default UserPage
