import React from 'react'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { Button, RemovePopover } from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'

export const RemoveButton = styled(Button)<StyledProps<{ marginRightRequired?: boolean }>>`
  margin-left: auto;
  margin-right: ${prop => (prop.marginRightRequired ? '65px;' : '')};
`

const StickyFooterDeleteBtn: React.FC<{
  onRemove(): void
  popoverText: string
  removeProgress?: Progress
  disabled?: boolean
}> = ({ popoverText, removeProgress, onRemove, disabled }) => {
  const { t } = useTranslation(['common'])
  const marginRightRequired = !!window.Intercom
  return (
    <RemovePopover onRemove={onRemove} text={popoverText}>
      <RemoveButton
        progress={removeProgress}
        intent={'danger'}
        marginRightRequired={marginRightRequired}
        data-test-id="remove-button"
        disabled={disabled}
      >
        {t('common:delete')}
      </RemoveButton>
    </RemovePopover>
  )
}

export default StickyFooterDeleteBtn
