import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddButton, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'

import InventoryRoutes from './routes'

type HeaderProps = {
  activeTab: 'Total' | 'Transactions'
}
const InventoryHeader: React.FC<HeaderProps> = ({ activeTab }) => {
  const push = useHistoryPush()
  const { t } = useTranslation('inventorySKU')

  return (
    <Layout.Header>
      <Header.TabsRoot>
        <Header.Tab
          title={t('title')}
          isActive={activeTab === 'Total'}
          onClick={() => push({ route: InventoryRoutes.Total })}
        >
          <AddButton to={InventoryRoutes.Add} />
        </Header.Tab>
        <Header.Tab
          title={t('inventorySKUTransaction:title')}
          isActive={activeTab === 'Transactions'}
          onClick={() => push({ route: InventoryRoutes.TransactionsList })}
        />
      </Header.TabsRoot>
    </Layout.Header>
  )
}

export default InventoryHeader
