import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Routes from './routes'
import HouseholdActions from 'modules/domain/household/duck'
import HouseholdSelectors from 'modules/domain/household/selectors'
import { useSelector } from 'react-redux'
import HouseholdsList from './HouseholdsList'
import FarmerRoutes from 'views/pages/Farmer/routes'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const StyledHeader = styled(Layout.Header)`
  border-bottom: none;
`

const HouseholdTable = () => {
  const filter = useSelector(HouseholdSelectors.filter)
  const filterUpdated = useAction(HouseholdActions.filterUpdated)
  const { t } = useTranslation(['household, farmer'])
  const push = useHistoryPush()

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  const metaTitle = t('household:householdsMetaTitle')

  useHelmet({ title: metaTitle })

  return (
    <>
      <StyledHeader>
        <Header.TabsRoot>
          <Header.Tab title={t('farmer:list.heading')} onClick={() => push({ route: FarmerRoutes.List })}>
            <AddButton to={FarmerRoutes.Add} />
          </Header.Tab>
          <Header.Tab title={t('household:list.heading')} onClick={() => push({ route: Routes.List })} isActive>
            <AddButton to={Routes.Add} />
          </Header.Tab>
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput
            onChange={handleSearchChange}
            value={filter.search}
            placeholder={t('household:list.searchPlaceholder')}
          />
        </SearchInputWrapper>
      </StyledHeader>
      <Layout.Content>
        <HouseholdsList />
      </Layout.Content>
    </>
  )
}

export default HouseholdTable
