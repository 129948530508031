import React, { memo } from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { FormikHook, Switch } from '@agro-club/frontend-shared'

export type StatusFormProps = {
  active: boolean
}

const StatusForm: React.FC<{
  active?: boolean
  useFormik: FormikHook
}> = memo(({ active = true, useFormik }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const formik = useFormik<StatusFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      active,
    },
    enableReinitialize: true,
  })
  return (
    <Styled.SwitchWrapper>
      <Switch
        label={t('form.status')}
        on={formik.values.active}
        onClick={value => {
          formik.setFieldValue('active', value)
        }}
        testId="status-switch"
      >
        {formik.values.active ? t('form.active') : t('form.inactive')}
      </Switch>
    </Styled.SwitchWrapper>
  )
})

StatusForm.displayName = 'StatusForm'

export default StatusForm
