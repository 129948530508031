import React, { useCallback, useState } from 'react'
import {
  Button,
  Modal,
  TextArea,
  useAction,
  useFormManager,
  useHelmet,
  useHistoryPush,
} from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ReconciliationListTable from './ReconciliationListTable/ReconciliationListTable'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import { useParams } from 'react-router-dom'
import ReconciliationActions from 'modules/domain/reconciliation/duck'
import { ReconciliationSku, ReconciliationDTO, ReconciliationGroupComment } from 'modules/domain/reconciliation/types'
import { FormikValues, useFormik } from 'formik'
import { useReconciliationById } from 'modules/domain/reconciliation/hooks'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isAgro, Sections } from 'types/entities'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { SkuSelectManually } from './SkuSelectManually'
import ReconciliationSelectors from 'modules/domain/reconciliation/selectors'
import { noop } from 'helpers/noop'

const StyledTextArea = styled(TextArea)`
  width: 50%;
  margin-top: 8px;
  min-height: 80px;
`

const AddVarietyButton = styled(Button)`
  margin-top: 16px;
`

const SkuModal = styled(Modal)`
  min-height: 330px;
`

const AddSkuButton = styled(Button)`
  margin-top: 24px;
`

const ReconciliationListPage: React.FC = () => {
  const push = useHistoryPush()
  const params = useParams<{ manufacturer_id: string; retailer_id: string }>()
  const { t } = useTranslation('reconciliation')
  const updateTargetGroupComment = useAction(ReconciliationActions.updateTargetGroupComment)
  const role = useSelector(AuthSelectors.role)
  const filter = useSelector(ReconciliationSelectors.groupsFilter)
  const isAdmin = isAgro(role)
  const checkFeatureFlag = useFeatureFlags()
  const isEditable = isAdmin || checkFeatureFlag(generateCustomFeatureFlag(Sections.Reconciliations, 'editingAllowed'))
  const [progress, group] = useReconciliationById(params.manufacturer_id, params.retailer_id)
  const sku = group?.sku || []

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [skuId, setSkuId] = useState<string | undefined>(undefined)

  useHelmet({ title: t('retailerMetaTitle') })

  const goBack = () => push({ route: ReconciliationRoutes.Groups })
  const batchUpdateAction = useAction(ReconciliationActions.batchUpdateRequested)
  const updateSkuAction = useAction(ReconciliationActions.updateSkuRequested)

  const formManager = useFormManager<Record<string, ReconciliationSku | ReconciliationGroupComment>>()

  const formik = useFormik<{
    key: {
      retailer_id: string
      manufacturer_id: string
    }
    groupComment: string
  }>({
    initialValues: {
      key: {
        retailer_id: group?.retailer.id || '',
        manufacturer_id: group?.manufacturer.id || '',
      },
      groupComment: group?.retailer.comment || '',
    },
    enableReinitialize: true,
    onSubmit: noop,
  })

  formManager.register('commentData', formik)

  const handleSubmit = async () => {
    const [valid] = await formManager.submitAll()
    if (!valid) {
      return
    }

    // TODO fix useFormManager type
    const changedRows: ReconciliationDTO[] = formManager
      .map(v => v)
      .filter(
        (v): v is { values: ReconciliationSku | ReconciliationGroupComment } =>
          (v as { dirty: boolean }).dirty &&
          !((v as FormikValues).values as ReconciliationGroupComment).groupComment &&
          !((v as FormikValues).values as ReconciliationGroupComment).key?.retailer_id,
      )
      .map(v => {
        const { id, inv, adj, transfers, comment } = v.values as ReconciliationSku
        return {
          id: `${params.manufacturer_id}-${params.retailer_id}`,
          retailer_id: params.retailer_id,
          manufacturer_id: params.manufacturer_id,
          sku_id: id,
          beginning_inv: inv.beginning,
          ending_inv: inv.ending,
          returns: transfers.returns,
          transfers_in: transfers.in,
          transfers_out: transfers.out,
          dist_adj: adj.dist,
          comment: comment,
        }
      })

    if (changedRows.length) {
      batchUpdateAction(changedRows)
    }

    if ((formik.dirty && !formik.values.groupComment) || formik.values.groupComment) {
      updateTargetGroupComment({
        key: {
          retailer_id: formik.values.key.retailer_id,
          manufacturer_id: formik.values.key.manufacturer_id,
        },
        comment: formik.values.groupComment,
      })
    }
  }

  const handleReset = () => {
    formManager.reset()
  }

  const handleSkuChange = useCallback(
    (skuId?: string) => {
      updateSkuAction({
        manufacturer_id: formik.values.key.manufacturer_id,
        retailer_id: formik.values.key.retailer_id,
        sku_id: skuId as string,
        isForced: true,
      })

      setIsModalOpen(false)
      setSkuId(undefined)
    },
    [updateSkuAction, formik.values.key.manufacturer_id, formik.values.key.retailer_id],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact title={t('retailerTitle', { company: group?.retailer.title })} />
          <StyledTextArea
            {...formik.getFieldProps('groupComment')}
            value={formik.values.groupComment || ''}
            disabled={!isEditable}
          />
          <AddVarietyButton
            intent="primary"
            data-test-id="add-variety-button"
            size="medium"
            onClick={() => setIsModalOpen(true)}
          >
            {t('addVariety')}
          </AddVarietyButton>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReconciliationListTable
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          formManager={formManager}
          progress={progress}
          data={sku}
          manufacturerId={group?.manufacturer.id as string}
          retailerId={group?.retailer.id as string}
        />
      </Layout.Content>
      <SkuModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size={'medium'}>
        <SkuSelectManually
          label={t('labels:sku')}
          placeholder={t('form.placeholders.sku')}
          filter={{
            manufacturer_id: formik.values.key.manufacturer_id,
            retailer_id: formik.values.key.retailer_id,
            season_id: filter.season_id || '',
            to_forced: true,
          }}
          onChange={(id?: string) => setSkuId(id)}
          variant="small"
        />
        <AddSkuButton onClick={() => handleSkuChange(skuId)} intent={'primary'} filled disabled={!skuId}>
          {t('addSku')}
        </AddSkuButton>
      </SkuModal>
    </>
  )
}

export default ReconciliationListPage
