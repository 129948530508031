import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import FccOrderActions from './duck'
import FccOrderSelectors from './selectors'
import { FccOrderItem } from 'types/fccOrder'
import * as managers from './managers'
import FarmerOrderRoutes from 'views/pages/FccOrder/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import { FccListResponse, FccOrdersUpdateDTO, FccOrdersUpdateResponse } from './types'
import { changePersistentFilters, getPersistentFilter } from 'modules/utils/helpers'

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(FccOrderSelectors.page)
    const filter = yield select(FccOrderSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(FccOrderActions.filtersUpdatedWithPersistentStorage(filterUpdated))
    const sorting = yield select(FccOrderSelectors.sorting)
    const pageSize = yield select(FccOrderSelectors.pageSize)
    let response: FccListResponse = yield call(managers.getList, filterUpdated, sorting, currentPage || 1, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }
    const {
      data,
      page,
      total_count,
      meta: { total_cost, total_qty, total_final_qty },
    } = response
    yield put(FccOrderActions.listRequestSucceed(data, total_count, total_cost, total_qty, total_final_qty, page))

    if (currentPage) {
      yield call(updateLocationQuery, FarmerOrderRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FccOrderActions.listRequestFailed(errType))
  }
}

export const fetchListUpdate = function*() {
  try {
    const currentPage = yield select(FccOrderSelectors.page)
    const filter = yield select(FccOrderSelectors.filter)
    const sorting = yield select(FccOrderSelectors.sorting)
    const pageSize = yield select(FccOrderSelectors.pageSize)
    const response: FccListResponse = yield call(managers.getList, filter, sorting, currentPage || 1, pageSize)
    const {
      data,
      total_count,
      meta: { total_cost, total_qty, total_final_qty },
    } = response
    yield put(FccOrderActions.listUpdateRequestSucceed(data, total_count, total_cost, total_qty, total_final_qty))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FccOrderActions.listUpdateRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(FccOrderSelectors.page)
    const filter = yield select(FccOrderSelectors.filter)
    const sorting = yield select(FccOrderSelectors.sorting)
    const pageSize = yield select(FccOrderSelectors.pageSize)
    const { data, total_count }: { data: FccOrderItem[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(FccOrderActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FccOrderActions.listRequestNextFailed(errType))
  }
}

export const updateItems = function*({ payload: items }: ReturnType<typeof FccOrderActions.updateItemsRequested>) {
  try {
    const request: FccOrdersUpdateDTO[] = Object.values(items).map(item => {
      const notNullPrice = item.price === null ? '0.00' : item.price
      return {
        order_id: item.order_id || '',
        item_id: item.id,
        price: notNullPrice,
        seed_treatment_id: item.seed_treatment_id,
        credit_facility: item.credit_facility,
      }
    })
    const response: FccOrdersUpdateResponse = yield call(managers.updateItems, request)
    yield put(FccOrderActions.updateItemsSucceed(response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FccOrderActions.updateItemsFailed(errType))
  }
}

const OrderSaga = function*() {
  yield all([
    takeLatest(FccOrderActions.listRequested.type, fetchList),
    takeLatest(FccOrderActions.filterUpdated.type, fetchList),
    takeLatest(FccOrderActions.sortingUpdated.type, fetchList),
    takeLatest(FccOrderActions.filterHasBeenReset.type, fetchList),
    takeLatest(FccOrderActions.sortingHasBeenReset.type, fetchList),
    takeLatest(FccOrderActions.listRequestedNext.type, fetchListNext),
    takeLatest(FccOrderActions.listUpdateRequested.type, fetchListUpdate),
    takeLatest(FccOrderActions.updateItemsRequested.type, updateItems),
  ])
}

export default OrderSaga
