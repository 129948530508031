import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import SeasonActions from './duck'
import SeasonSelectors from './selectors'
import { Season } from './types'
import managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import SeasonRoutes from 'views/pages/Season/routes'

export const fetchList = function*() {
  try {
    let currentPage = yield select(SeasonSelectors.page)
    const filter = yield select(SeasonSelectors.filter)
    const sorting = yield select(SeasonSelectors.sorting)
    const pageSize = yield select(SeasonSelectors.pageSize)

    let response: ListResponse<Season> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(SeasonActions.listRequestSucceed(data, total_count, page))

    // yield call(updateLocationQuery, SeasonRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(SeasonActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(SeasonSelectors.page)
    const filter = yield select(SeasonSelectors.filter)
    const sorting = yield select(SeasonSelectors.sorting)
    const pageSize = yield select(SeasonSelectors.pageSize)
    const { data, total_count }: { data: Season[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(SeasonActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(SeasonActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof SeasonActions.itemRequested>) {
  try {
    const item: Season = yield call(managers.getItem, id)
    yield put(SeasonActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(SeasonActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof SeasonActions.addRequested>) {
  try {
    const item: Season = yield call(managers.addItem, dto)
    yield put(SeasonActions.addSucceed(item))
    yield put(push(generatePath(SeasonRoutes.Edit, { id: item.id })))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(SeasonActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof SeasonActions.updateRequested>) {
  try {
    const item: Season = yield call(managers.updateItem, id, dto)
    yield put(SeasonActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(SeasonActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof SeasonActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(SeasonActions.removeSucceed(payload))
    yield put(push(SeasonRoutes.List))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(SeasonActions.removeFailed(payload, type, detail))
  }
}

const SeasonSaga = function*() {
  yield all([
    takeLatest(SeasonActions.itemRequested.type, fetchItem),
    takeLatest(SeasonActions.listRequested.type, fetchList),
    takeLatest(SeasonActions.filterUpdated.type, fetchList),
    takeLatest(SeasonActions.sortingUpdated.type, fetchList),
    takeLatest(SeasonActions.filterHasBeenReset.type, fetchList),
    takeLatest(SeasonActions.sortingHasBeenReset.type, fetchList),

    takeLatest(SeasonActions.listRequestedNext.type, fetchListNext),

    takeLatest(SeasonActions.addRequested.type, addItem),
    takeLatest(SeasonActions.updateRequested.type, updateItem),
    takeLatest(SeasonActions.removeRequested.type, removeItem),
  ])
}

export default SeasonSaga
