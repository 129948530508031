export const saveLanguage = (lng: string): void => localStorage.setItem('lang', lng)
export const getLanguage = (): string | null => localStorage.getItem('lang')

if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.getLanguage = getLanguage
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.saveLanguage = saveLanguage
}
