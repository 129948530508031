import { FormComponents } from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'

export const ContentBody = styled(FormComponents.FormContentBody)`
  max-width: 725px;
`

export const SearchInputWrapper = styled.div`
  width: 50%;
  padding: 18px 24px;
`

export const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

export const MainFormContainer = styled.div`
  max-width: 300px;
`

export const LabeledContainerStyled = styled(FormComponents.LabeledContainer)`
  position: relative;
  padding-bottom: 16px;
`

export const ChooseLanguageBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

export const ErrorStyled = styled.div<StyledProps<{}>>`
  line-height: 16px;
  margin-top: 15px;
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 1fr;
`

export const IdCell = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
`

export const Status = styled.span<StyledProps<{ status?: string }>>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  flex-shrink: 0;
  display: inline-block;
  background-color: ${({ theme, status }: StyledProps<{ status?: string }>) => {
    switch (status) {
      case 'active':
        return theme.color.accentApproving
      case 'inactive':
        return theme.color.accentNotify
      case 'deleted':
        return theme.color.accentDestructive
    }
  }};
`

export const DefaultStatus = styled(Status)`
  background-color: transparent;
  border: 1px solid;
  margin-left: 0;
`
