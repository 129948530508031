import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { SignUpState } from 'modules/domain/signup/types'

const initialState: SignUpState = {}

class SignUpReducer extends ImmerReducer<SignUpState> {
  noop() {}
}

export const SignupActions = createActionCreators(SignUpReducer)
export default SignupActions
export const reducer = createReducerFunction(SignUpReducer, initialState)
