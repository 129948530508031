import { useFilterMemo } from 'hooks/useFilterMemo'
import * as managers from 'modules/domain/user/managers'
import { User, UserShortListRequestFilter } from 'modules/domain/user/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'

type UsersSelectProps = EntityMultiSelectProps<User> & { filter?: UserShortListRequestFilter }

const pageSize = 50

const getUserLabel = (user: User) => user.full_name || ''

const getUserValue = (user: User) => user.id

export const UsersSelect: React.FC<UsersSelectProps> = ({ values = [], filter, ...props }) => {
  const { t } = useTranslation('report')
  const memoizedFilter = useFilterMemo<UserShortListRequestFilter>(filter)

  return (
    <SelectTestWrapper data-test-id="user-multi-select">
      <EntityMultiSelect
        values={values}
        filter={memoizedFilter}
        getEntityList={managers.getCrmUsersSuggestions}
        getEntityByIds={managers.getItemsByIds}
        pageSize={pageSize}
        getOptionLabel={getUserLabel}
        getOptionValue={getUserValue}
        placeholder={values.length > 0 ? t('form.labels.addUser') : t('form.labels.noneSelected')}
        label={t('form.labels.onlyUsers')}
        {...props}
      />
    </SelectTestWrapper>
  )
}
