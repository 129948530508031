import React, { useMemo } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DocumentSelectors from 'modules/domain/document/selectors'
import DocumentRoutes from 'views/pages/Document/routes'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { Progress } from 'modules/types'
import { useDocument, useDocumentFileUrl } from 'modules/domain/document/hooks'
import { useTranslation } from 'react-i18next'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import PdfViewer from 'views/ui/PdfViewer/PdfViewer'
import styled from 'styled-components'
import {
  Button,
  FormText,
  Input,
  SectionBody,
  SectionContainer,
  TextArea,
  useAction,
  useHistoryPush,
  FormComponents,
} from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import DocumentActions from 'modules/domain/document/duck'
import * as Yup from 'yup'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const Form = styled(FormComponents.FormSection)`
  width: 50%;
`

const PdfPreviewErrorContainer = styled.div`
  color: ${props => props.theme.color.accentDestructive};
`

const DocumentPreviewLoadContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 24px;
  gap: 24px;
  place-items: center;
`

const DocumentPreviewLoadText = styled.div`
  color: ${props => props.theme.color.secondary300};
`

const DocumentPreview: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation('document')
  const item = useDocumentFileUrl(id)

  if (item.progress === Progress.WORK || item.progress === Progress.IDLE) {
    return (
      <DocumentPreviewLoadContainer>
        <DocumentPreviewLoadText>{t('item.pdfPreviewLoadingText')}</DocumentPreviewLoadText>
        <SpinnerLayout />
      </DocumentPreviewLoadContainer>
    )
  }

  if (item.progress !== Progress.SUCCESS) {
    return <PdfPreviewErrorContainer>{t('item.errors.pdf.generic')}</PdfPreviewErrorContainer>
  }

  return <PdfViewer url={item.url} />
}

type EditableFields = {
  title: string
  text: string
  description: string
}

const DocumentDetailsForm: React.FC<{
  id: string
  name: string
  initialValues?: EditableFields
  onCancel(): void
  onSubmit(values: EditableFields): void
  progress?: Progress
}> = ({ id, name, onSubmit, onCancel, progress, initialValues: { title = '', text = '', description = '' } = {} }) => {
  const { t } = useTranslation('document')
  const validationSchema = useMemo(() => {
    return Yup.object({
      title: Yup.string().required(),
      text: Yup.string().required(),
      description: Yup.string().required(),
    })
  }, [])
  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    onSubmit(formik.values)
  }
  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      title,
      text,
      description,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Form title={'info'}>
              <FormText label={'id'}>{id}</FormText>
              <FormText label={t('fields.name')}>{name}</FormText>
              <Input
                {...formik.getFieldProps('title')}
                invalid={formik.touched.title && !!formik.errors.title}
                label={t('fields.legalTitle')}
                data-test-id={'title'}
              />
              <TextArea
                {...formik.getFieldProps('text')}
                invalid={formik.touched.text && !!formik.errors.text}
                label={t('fields.legalText')}
                data-test-id={'text'}
              />
              <TextArea
                {...formik.getFieldProps('description')}
                invalid={formik.touched.description && !!formik.errors.description}
                label={t('fields.legalDescription')}
                data-test-id={'description'}
              />
            </Form>
            <FormComponents.FormSection title={'preview'}>
              <DocumentPreview id={id} />
            </FormComponents.FormSection>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          intent={'primary'}
          filled={true}
          data-test-id={'save-button'}
          onClick={formik.submitForm}
          progress={progress}
          disabled={!formik.dirty || progress === Progress.WORK}
        >
          {t('common:save')}
        </Button>
        <Button onClick={onCancel} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

const DocumentItem: React.FC = () => {
  const { t } = useTranslation('document')
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const page = useSelector(DocumentSelectors.page)
  const goBack = () => push({ route: DocumentRoutes.List, query: { page } })

  const [progress, item] = useDocument(params.id)
  const meta = useSelector(state => DocumentSelectors.meta(state, params.id))
  const updateAction = useAction(DocumentActions.updateRequested)

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (progress === Progress.WORK) {
    return loading()
  }

  if (progress === Progress.ERROR || !item) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DocumentDetailsForm
          progress={meta.updateProgress}
          onSubmit={legalNotes => {
            updateAction(item.id, { legal_notes: legalNotes })
          }}
          onCancel={goBack}
          id={item.id}
          name={item.name}
          initialValues={item.legal_notes || undefined}
        />
      </Layout.Content>
    </>
  )
}

export default DocumentItem
