import React, { useCallback, useMemo } from 'react'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAction } from '@agro-club/frontend-shared'
import ReconciliationSelectors from 'modules/domain/reconciliation/selectors'
import ReconciliationActions from 'modules/domain/reconciliation/duck'
import { ReconciliationGroupFilter } from 'modules/domain/reconciliation/types'
import { FilterOption, TableFilterVariant, TableFilter } from 'views/components/TableFilters/TableFilters'
import { isAgro } from 'types/entities'
import { getAllPersistentFilters } from 'modules/utils/helpers'
import { ManufacturerSelect } from './ManufacturerSelect'
import AuthSelectors from 'modules/domain/auth/selectors'
import { SeasonSelect } from './SeasonSelect'
import { Season } from 'modules/domain/season/types'
import DistributorsMultiSelect from './DistributorsMultiSelect'

const StatusFilter: React.FC<{
  handleFilterChange: (filter: { is_reconciled?: boolean }) => void
  filterValue: { is_reconciled?: boolean }
  variant?: TableFilterVariant
}> = ({ handleFilterChange, filterValue, variant }) => {
  const { t } = useTranslation('reconciliation')
  const options: FilterOption<boolean>[] = useMemo(
    () => [
      {
        value: true,
        title: t('filter.reconciled'),
        bullet: true,
        color: 'green',
      },
      {
        value: false,
        title: t('filter.not_reconciled'),
        bullet: true,
        color: 'orange',
      },
    ],
    [t],
  )

  return (
    <TableFilter
      variant={variant}
      title={t('filter.statusLabel')}
      filterValue={filterValue}
      handleFilterChange={handleFilterChange}
      options={options}
      disableMulti
    />
  )
}

const ReconciliationGroupsFilters: React.FC = () => {
  const { t } = useTranslation('reconciliation')
  const role = useSelector(AuthSelectors.role)
  const filterUpdateAction = useAction(ReconciliationActions.groupsFilterUpdated)
  const filterValue = useSelector(ReconciliationSelectors.groupsFilter)

  const handleFilterChange = useCallback(
    (newFilter: Partial<ReconciliationGroupFilter>) => {
      filterUpdateAction({ ...filterValue, ...newFilter })
    },
    [filterUpdateAction, filterValue],
  )

  const selectedRetailers = useMemo(() => {
    const gFilters = getAllPersistentFilters()
    return filterValue.retailer_id ? filterValue.retailer_id : gFilters?.retailer_id ? gFilters.retailer_id : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.retailer_id, filterValue?.retailer_id?.length])

  const handleLoadList = useCallback(
    list => {
      if (!Array.isArray(list.data) || !list.data.length) return
      const currentSeason = list.data.find((season: Season) => season.is_current)
      if (currentSeason) {
        handleFilterChange({ season_id: currentSeason.id })
      }
    },
    [handleFilterChange],
  )

  return (
    <Filters>
      <StatusFilter
        variant={'small'}
        filterValue={{ is_reconciled: filterValue.is_reconciled }}
        handleFilterChange={handleFilterChange}
      />
      <SeasonSelect
        label={t('labels:season')}
        placeholder={t('form.placeholders.season')}
        value={filterValue.season_id}
        onLoadList={handleLoadList}
        onChange={val => handleFilterChange({ season_id: val })}
        variant="small"
      />
      <div data-test-id="retailer-multiselect">
        <DistributorsMultiSelect
          label={t('labels:retailer')}
          placeholder={t('form.placeholders.retailer')}
          selected={[...new Set(selectedRetailers)]}
          filter={{ season_id: filterValue.season_id }}
          onChange={val => handleFilterChange({ retailer_id: val })}
        />
      </div>
      {isAgro(role) && (
        <ManufacturerSelect
          label={t('labels:producer')}
          placeholder={t('form.placeholders.manufacturer')}
          values={filterValue.manufacturer_id}
          filter={{ season_id: filterValue.season_id }}
          onChange={val => handleFilterChange({ manufacturer_id: val })}
          variant="small"
        />
      )}
    </Filters>
  )
}

export default ReconciliationGroupsFilters
