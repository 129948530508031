import {
  SectionBody,
  SectionFooter,
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTitle,
  WizardData,
  OptionalNumber,
} from '@agro-club/frontend-shared'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import { Company } from 'modules/domain/company/types'
import { DistributorOrderSku, DistributorOrderSkuItem } from 'modules/domain/distributorOrderSku/types'
import { AvailableFor, SkuOption, StorefrontItem, StorefrontSku } from 'modules/domain/storefront/types'

import { StorefrontCard, StorefrontCardForSelect } from 'modules/domain/storefrontCard/types'
import { useRole } from 'modules/permissions/permissions'
import { generateCustomFeatureFlag, generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CustomFeatureName,
  FeatureFlagModifiers,
  FieldLocation,
  FieldNames,
  isAgro,
  ROLES,
  Sections,
  Status,
} from 'types/entities'
import { FarmerOrderSku, FarmerOrderSkuItem } from 'types/farmerOrderSku'
import * as Styled from 'views/components/OrderProductItems/styles'
import { CardSelect } from '../CardSelect/CardSelect'
import { OrderSkuCardRow, OrderSkuCardRowProps } from './OrderSkuCardRow'
import { useProductsLimit } from 'modules/domain/allocation/hooks'
import { isNil } from 'ramda'
import { getIniqItemsWithInitialQty, getUniqItemInitialQty, getUniqItemQty, getUniqItems } from './helpers'
import { getProductMinQty } from 'modules/domain/allocation/helpers'
import styled from 'styled-components'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { aviabilitiyLevels } from 'views/pages/ProductSettings/AvailabilityColorSelect'

const SwitchSkusStatus = styled.span`
  position: absolute;
  top: 26px;
  right: 24px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.primary600};
  user-select: none;
`
const AvailabilityColorWrapper = styled.div`
  display: flex;
  grid-gap: 24px;
`
const CardSelectWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`

const AvailabilityColor = styled.p<{ colorLevel: string }>`
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  display: flex;
  margin: 0;

  &:before {
    background: ${({ theme, colorLevel }) => theme.color.availabilityColors[colorLevel]};
    border-radius: 10px;
    content: ' ';
    display: block;
    margin-right: 8px;
    height: 8px;
    width: 8px;
  }
`

export type OrderSkuCardItemsProps = {
  onChange: (
    idx: number,
    qty: number,
    card?: StorefrontCardForSelect,
    sku?: StorefrontSku,
    wizard_comment?: string,
    wizard_data?: WizardData<any>,
  ) => void
  onRemove: (idx: number) => void
  order?: FarmerOrderSku | DistributorOrderSku
  isAllowed: boolean
  sku_items?: FarmerOrderSkuItem[] | DistributorOrderSkuItem[]
  producer?: Company | null
  totalSavings?: number
  invalid?: boolean
  errorText?: string
  mode: 'create' | 'edit'
  total?: string
  netTotal?: string
  showComment: boolean
  distributorId?: string
  initialProducerId?: string
  onMenuClose?: () => void
  onFinalQtyChange?: (idx: number, qty: number) => void
  onShippedQtyChange?: (idx: number, qty: number) => void
  onDeliveredQtyChange?: (idx: number, qty: number) => void
  onPackageTypeChange?: (idx: number, value: string) => void
  onOptionsChange?: (idx: number, value: SkuOption[]) => void
  onSkuMatched?: (idx: number, value?: StorefrontSku) => void
  onCommentChange?: (idx: number, comment: string) => void
  seasonId?: string
  setIsProductQtyMoreThanLimitAfterDuplicate?: (isQtyMoreThanLimit: boolean) => void
  setIsProductQtyMoreThanLimit?: (isQtyMoreThanLimit: boolean) => void
  initialItems?: FarmerOrderSkuItem[]
  showShippedQty?: boolean
} & Pick<
  OrderSkuCardRowProps,
  'documents' | 'onRequiredDocumentClick' | 'context' | 'skuMatchingErrors' | 'showErrors' | 'showComment'
>

const OrderSkuCardItems: React.FC<OrderSkuCardItemsProps> = ({
  isAllowed,
  order,
  onChange,
  onRemove,
  sku_items = [],
  producer,
  onFinalQtyChange,
  onShippedQtyChange,
  onDeliveredQtyChange,
  onPackageTypeChange,
  onOptionsChange,
  onSkuMatched,
  onMenuClose,
  onCommentChange,
  invalid,
  errorText,
  children,
  mode,
  total,
  netTotal,
  showComment,
  distributorId,
  initialProducerId,
  context,
  seasonId,
  setIsProductQtyMoreThanLimitAfterDuplicate,
  setIsProductQtyMoreThanLimit,
  initialItems = [],
  showShippedQty = false,
  ...rowProps
}) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'cards'])
  const role = useRole()
  const isAdmin = isAgro(role)
  const uniqItems = getUniqItems(sku_items)
  const uniqItemsWithInitialQty = getIniqItemsWithInitialQty(initialItems)
  const [showInactiveSkus, setShowInactiveSkus] = useState<boolean>(false)
  const checkFeatureFlag = useFeatureFlags()
  const cardAndSkuStatus = useMemo(() => (showInactiveSkus ? [Status.Active, Status.Inactive] : Status.Active), [
    showInactiveSkus,
  ])

  const productsFromItems = useMemo(
    () =>
      sku_items.map(item => {
        const card = item.card || item.sku_card
        return {
          product_id: card?.product_id as string,
        }
      }),
    [sku_items],
  )

  const params = useMemo(
    () => ({
      distributorId: distributorId || '',
      seasonId: seasonId || '',
      products: productsFromItems,
    }),
    [distributorId, seasonId, productsFromItems],
  )

  const [, productsLimit = []] = useProductsLimit(context, params)

  useEffect(() => {
    if (productsLimit) {
      const productQtyMoreThanLimit = productsLimit.find(
        item => !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity,
      )
      setIsProductQtyMoreThanLimit?.((mode === 'edit' && productQtyMoreThanLimit?.is_limit_exceeded) || false)

      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLimit])

  const showUnits = useMemo(
    () =>
      checkCompanyFeatureFlags(
        producer,
        generateFieldModifierString(FieldLocation.OrderList, FieldNames.Units, FeatureFlagModifiers.Enabled),
        role,
      ),
    [role, producer],
  )

  const [skuMap, setSkuMap] = useState<Map<string, StorefrontItem[]>>(new Map<string, StorefrontItem[]>())

  const handleFetchSkuList = useCallback(
    (key: string, skus: StorefrontItem[]) => {
      const updatedMap = skuMap?.set(key, skus)
      setSkuMap(updatedMap)
    },
    [skuMap],
  )

  const itemsQty = sku_items.length

  const isSkuEditingExpired = (sku?: StorefrontSku) => {
    const expirationDate = sku?.out_of_stock_date
    const isSkuEditingExpired = !!expirationDate && new Date() >= new Date(expirationDate)
    const notPermittedForRole =
      !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER, ROLES.PRODUCER_MANAGER].includes(role)

    return isSkuEditingExpired && notPermittedForRole
  }

  const isCardEditingAllowed = useCallback(
    (card: StorefrontCard | StorefrontCardForSelect | null): boolean => {
      const { allow_farmer_orders, allow_retail_orders } = { ...card?.out_of_stock_flags }
      const isAllowed =
        isAdmin ||
        !card?.is_out_of_stock ||
        (card?.is_out_of_stock && context === 'farmer' ? allow_farmer_orders : allow_retail_orders)

      return !!isAllowed
    },
    [context, isAdmin],
  )

  const handleCardSelect = useCallback(
    (_, card: StorefrontCardForSelect | null) => {
      const { product_id: productId, min_qty } = { ...card }

      const minQty = OptionalNumber(min_qty) || 0

      if (!isCardEditingAllowed(card)) return

      const isProductQtyMoreThanLimit = productsLimit.find(
        item =>
          !isNil(item.allowed_quantity) &&
          ((productId && getUniqItemQty(uniqItems, productId) + minQty > item.allowed_quantity) ||
            getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity),
      )

      const productAllocationLimit = productsLimit.find(
        item => !isNil(item.allowed_quantity) && item.product_id === productId,
      )

      const productCurrentQty = productId ? getUniqItemQty(uniqItems, productId) : 0
      const productIntitialQty = productId ? getUniqItemInitialQty(uniqItemsWithInitialQty, productId) : 0

      const productMinQty = getProductMinQty(
        productCurrentQty,
        productIntitialQty,
        isAdmin,
        mode,
        productId,
        productAllocationLimit,
        minQty,
      )

      setIsProductQtyMoreThanLimit?.(!!isProductQtyMoreThanLimit)
      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) &&
            ((productCurrentQty + minQty) * 2 > item.allowed_quantity ||
              getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity),
        ),
      )

      card && onChange(itemsQty || 0, !isNil(productMinQty) ? productMinQty : 1, card)
    },
    [
      isAdmin,
      isCardEditingAllowed,
      itemsQty,
      mode,
      onChange,
      productsLimit,
      setIsProductQtyMoreThanLimit,
      setIsProductQtyMoreThanLimitAfterDuplicate,
      uniqItems,
      uniqItemsWithInitialQty,
    ],
  )

  const handleQtyInputChange = (idx: number) => (value: number) => {
    const item = sku_items[idx]
    const qtyNumber = Number(item.quantity)
    const card = item.card || item.sku_card
    const productId = card?.product_id as string
    const currentProductCommonQty = getUniqItemQty(uniqItems, productId)

    uniqItems[productId].quantity =
      qtyNumber - value > 0
        ? currentProductCommonQty - (qtyNumber - value)
        : currentProductCommonQty + (value - qtyNumber)

    setIsProductQtyMoreThanLimitAfterDuplicate?.(
      !!productsLimit.find(
        product =>
          !isNil(product.allowed_quantity) &&
          getUniqItemQty(uniqItems, product.product_id) * 2 > product.allowed_quantity,
      ),
    )

    if (!Number.isNaN(value)) {
      onChange(idx, value)
    } else {
      onChange(idx, 0)
    }
  }

  const handleFinalQtyInputChange = useCallback(
    (idx: number) => (value: number) => {
      if (!Number.isNaN(value)) {
        onFinalQtyChange?.(idx, value)
      }
    },
    [onFinalQtyChange],
  )

  const handleShippedQtyInputChange = useCallback(
    (idx: number) => (value: number) => {
      if (!Number.isNaN(value)) {
        onShippedQtyChange?.(idx, value)
      }
    },
    [onShippedQtyChange],
  )

  const handleDeliveredQtyInputChange = (idx: number) => (value: number) => {
    if (!Number.isNaN(value)) {
      onDeliveredQtyChange?.(idx, value)
    }
  }

  const handlePackageTypeChange = (idx: number) => (value: string) => {
    onPackageTypeChange?.(idx, value)
  }

  const handleOptionsChange = (idx: number) => (value: SkuOption[]) => {
    onOptionsChange?.(idx, value)
  }

  const handleSkuMatched = (idx: number) => (value?: StorefrontSku) => {
    onSkuMatched?.(idx, value)
  }

  const handleRemove = useCallback(
    (idx: number) => () => {
      onRemove(idx)
      const orderItem = sku_items[idx]
      const qtyNumber = Number(orderItem.quantity)
      const card = orderItem.card || orderItem.sku_card
      const productId = card?.product_id as string
      const currentProductCommonQty = getUniqItemQty(uniqItems, productId)

      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) &&
            ((currentProductCommonQty - qtyNumber) * 2 > item.allowed_quantity ||
              (item.product_id !== productId &&
                getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity)),
        ),
      )
    },
    [onRemove, productsLimit, setIsProductQtyMoreThanLimitAfterDuplicate, sku_items, uniqItems],
  )

  const checkRemoveButtonVisibility = (item: FarmerOrderSkuItem) => {
    let canRemove = false
    const canEditCard = isCardEditingAllowed(item.sku_card as StorefrontCard)
    const canEditSku = !isSkuEditingExpired(item.sku)

    if (mode === 'create' || (mode === 'edit' && canEditCard && canEditSku)) {
      canRemove = true
    }

    return canRemove
  }

  const handleCommentChange = idx => (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onCommentChange && onCommentChange(idx, e.target.value)

  const showPrices = useMemo(() => !!sku_items?.length && sku_items.some(item => !!item.sku?.price), [sku_items])
  const showDeliveredQty = order?.season?.enable_delivered_qty
  const showFinalQty = !!order?.season?.enable_final_qty

  const netTotalJSX = netTotal && (
    <Styled.Total>
      {t('form.netTotal')} <Styled.TotalValue>{netTotal}</Styled.TotalValue>
    </Styled.Total>
  )

  const showAvailabilityDotsRetailer = generateCustomFeatureFlag(
    Sections.RetailerOrders,
    CustomFeatureName.AvailabilityDotsVisible,
  )

  const showAvailabilityDotsFarmer = generateCustomFeatureFlag(
    Sections.FarmerOrders,
    CustomFeatureName.AvailabilityDotsVisible,
  )

  const showAvailabilityDots =
    isAdmin || checkFeatureFlag(context === 'farmer' ? showAvailabilityDotsFarmer : showAvailabilityDotsRetailer)

  return (
    <Styled.Container isAllowed={isAllowed}>
      <Styled.Content isAllowed={isAllowed}>
        <SectionTitle>{t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
        {isAdmin && (
          <SwitchSkusStatus
            data-test-id="switch-skus-status-button"
            onClick={() => setShowInactiveSkus(!showInactiveSkus)}
          >
            {showInactiveSkus ? t('hideInactive') : t('showInactive')}
          </SwitchSkusStatus>
        )}
        <SectionBody>
          <CardSelectWrapper>
            {showAvailabilityDots && (
              <AvailabilityColorWrapper>
                <Styled.SmallText>{t('cards:list.tableHeaders.availability')}:</Styled.SmallText>
                {aviabilitiyLevels.map(level => (
                  <AvailabilityColor key={level} colorLevel={level}>
                    {t(`cards:availabilityColors.${level}`)}
                  </AvailabilityColor>
                ))}
              </AvailabilityColorWrapper>
            )}
            <CardSelect
              isDisabled={!(producer?.id || initialProducerId)}
              availableFor={context === 'farmer' ? AvailableFor.FarmerOrder : AvailableFor.DistributorOrder}
              isSearchable
              required
              onChange={handleCardSelect}
              onMenuClose={onMenuClose}
              label={t('form.labels.product')}
              placeholder={t('form.placeholders.addProduct')}
              invalid={invalid}
              errorText={errorText}
              sellerId={producer?.id || initialProducerId}
              status={cardAndSkuStatus}
              skuStatus={cardAndSkuStatus}
              showAvailability={showAvailabilityDots}
              controlShouldRenderValue={false}
              isOptionDisabled={card => !isAdmin && !isCardEditingAllowed(card)}
            />
          </CardSelectWrapper>
          {!!sku_items.length && (
            <SectionTable data-test-id={'sku-items'}>
              <SectionTableHead>
                <SectionTableHeadRow>
                  <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                  {showPrices && <SectionTableHeadCell>{t('form.tableHeaders.price')}</SectionTableHeadCell>}
                  <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
                  {showDeliveredQty && onDeliveredQtyChange && (
                    <SectionTableHeadCell textAlign={'center'}>
                      {t('form.tableHeaders.deliveredQty')}
                    </SectionTableHeadCell>
                  )}
                  {showFinalQty && onFinalQtyChange && context === 'farmer' && (
                    <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.finalQty')}</SectionTableHeadCell>
                  )}
                  {showShippedQty && context === 'distributor' && (
                    <SectionTableHeadCell textAlign={'center'}>
                      {t('form.tableHeaders.shippedQty')}
                    </SectionTableHeadCell>
                  )}
                  {showComment && (
                    <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.comment')}</SectionTableHeadCell>
                  )}
                  <SectionTableHeadCell />
                </SectionTableHeadRow>
              </SectionTableHead>
              <SectionTableBody>
                {sku_items.map((item, idx) => {
                  const sellerId = producer?.id || initialProducerId
                  const card = (item.card || item.sku_card) as StorefrontCard
                  const productId = card?.product_id as string
                  const itemCommonQty = getUniqItemQty(uniqItems, productId)
                  const itemCommonInitialQty = getUniqItemInitialQty(uniqItemsWithInitialQty, productId)
                  const isQtyInputDisabled = !item.sku_id
                  const cardEditingAllowed = isCardEditingAllowed(card)

                  return (
                    <OrderSkuCardRow
                      {...rowProps}
                      context={context}
                      key={item.key}
                      mode={mode}
                      isSkuEditingExpired={isSkuEditingExpired}
                      isCardEditingAllowed={cardEditingAllowed}
                      showRemoveButton={checkRemoveButtonVisibility(item)}
                      onSkuMatched={handleSkuMatched(idx)}
                      onOptionsChange={handleOptionsChange(idx)}
                      onQtyInputChange={handleQtyInputChange(idx)}
                      onDeliveredQtyChange={handleDeliveredQtyInputChange(idx)}
                      onPackageTypeChange={handlePackageTypeChange(idx)}
                      onFinalQtyChange={handleFinalQtyInputChange(idx)}
                      onShippedQtyChange={handleShippedQtyInputChange(idx)}
                      onCommentChange={handleCommentChange(idx)}
                      onRemove={handleRemove(idx)}
                      itemCommonQty={itemCommonQty}
                      itemCommonInitialQty={itemCommonInitialQty}
                      item={item}
                      rowIndex={idx}
                      sellerId={sellerId}
                      skus={skuMap?.get(`${sellerId}-${card?.id}`)}
                      onFetchSkuList={handleFetchSkuList}
                      showUnits={showUnits}
                      visibilityConfig={{
                        showPrices,
                        showComment,
                        showUnits,
                        showDeliveredQty,
                        showFinalQty,
                        showInactiveSkus,
                        showShippedQty,
                      }}
                      isAdmin={isAdmin}
                      productsLimit={productsLimit}
                      isQtyInputDisabled={isQtyInputDisabled}
                    />
                  )
                })}
              </SectionTableBody>
            </SectionTable>
          )}
        </SectionBody>
        {showPrices && !!total && (
          <SectionFooter>
            <Styled.TotalWrap>
              <Styled.Total>
                {t('form.total')} <Styled.TotalValue>{total}</Styled.TotalValue>
              </Styled.Total>
              {netTotalJSX}
            </Styled.TotalWrap>
          </SectionFooter>
        )}
      </Styled.Content>
      {children}
      {!isAllowed && <Styled.Overlay>{t('form.addProductsOverlayText')}</Styled.Overlay>}
    </Styled.Container>
  )
}

export default OrderSkuCardItems
