import {
  Button,
  DatePicker,
  Snackbar,
  FormComponents,
  Input,
  SectionBody,
  SectionContainer,
  SectionTitle,
  TextArea,
  useAction,
} from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormat from 'hooks/useDateFormat'
import useDownload from 'hooks/useDownload'
import useHasOutOfStockDateExpired from 'hooks/useHasOutOfStockDateExpired'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useCompanyById } from 'modules/domain/company/hooks'
import { Company } from 'modules/domain/company/types'
import {
  DistributorOrderSku,
  DistributorOrderSkuActionData,
  DistributorOrderSkuItem,
} from 'modules/domain/distributorOrderSku/types'
import { useUserDocumentsNoCache } from 'modules/domain/document/hooks'
import { IncentiveProgramScope } from 'modules/domain/incentiveProgram/types'
import { Promocode } from 'modules/domain/promocode/types'
import { StorefrontItemType, StorefrontSku } from 'modules/domain/storefront/types'
import { StorefrontCardForSelect } from 'modules/domain/storefrontCard/types'
import { endpoints } from 'modules/endpoints'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import { Progress } from 'modules/types'
import {
  generateCrmButtonAccessString,
  generateCrmFieldAccessString,
  generateCustomFeatureFlag,
  generateFieldModifierString,
} from 'modules/utils/generateStringHelpers'
import { clone, remove } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { DistributorOrderStatus } from 'types/distributorOrder'
import {
  Buttons,
  CompanyType,
  CustomFeatureName,
  FeatureFlagModifiers,
  FieldLocation,
  FieldNames,
  isAgro,
  isDistributor,
  isProducer,
  isProducersHead,
  isProducersManager,
  isUsersCompanyDistributor,
  isUsersCompanyProducer,
  Sections,
  Status,
} from 'types/entities'
import * as uuid from 'uuid'
import CompanyInfoPreview from 'views/components/CompanyInfoPreview/CompanyInfoPreview'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'
import { OrderSkuDiscounts } from 'views/components/OrderSkuDiscounts/OrderSkuDiscounts'
import { OrderSkuCardItemsManager } from 'views/components/OrderSkuCardItems/OrderSkuCardItemsManager'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CompanyRoutes from 'views/pages/Company/routes'
import * as Yup from 'yup'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import { ModalWithButtonContent } from 'views/components/ModalWithButtons/ModalWithButtonsContent'
import ModalActions from 'modules/domain/modal/duck'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import OrderHistoryCard from 'views/components/OrderHistoryCard/OrderHistoryCard'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { Season } from 'modules/domain/season/types'
import { PreviewContainer } from 'views/components/PreviewContainer/PreviewContainer'
import StickyFooterRestoreBtn from 'views/components/StickyFormControls/StickyFooterRestoreBtn'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 354px auto;
`

const Column = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`

const DatePickerWrapper = styled.div`
  width: 220px;
`

const DownloadButtonWrapper = styled.div`
  display: flex;
  padding-right: 8px;
`

export const CommentFormSection = styled(FormComponents.FormSection)`
  padding: 24px 0 6px 24px;
  margin: 0;
  border-bottom: none !important;

  & > h3 {
    margin-bottom: 0;
  }
`

export type DistributorOrderSkuDetailsFormFields = DistributorOrderSkuActionData

export type DistributorOrderSkuDetailsFormProps = {
  mode: 'create' | 'edit'
  onSubmit(values: DistributorOrderSkuDetailsFormFields, options: { dirty: boolean; duplicate: boolean }): void
  onCancel(): void
  onProducerConfirmation?: () => void
  onCancelOrder?: () => void
  onRemove?: () => void
  onRestore?: () => void
  onComplete?: () => void
  progress?: Progress
  removeProgress?: Progress
  initialValues: DistributorOrderSkuDetailsFormFields
  order?: DistributorOrderSku
  onUncancelOrder?: () => void
}

const fakeSku: StorefrontSku = {
  id: '',
  type: StorefrontItemType.Product,
  sku_id: '',
  seller_id: '',
  status: Status.Active,
  special_prices: [],
  params: {
    type: 'product',
    product_id: '',
    package_id: '',
    package_capacity: 0,
    options: [],
  },
  is_out_of_stock: false,
  price_type: 'not_specified',
  available_for: [],
}

const orderNumberEnabledFlag = generateCrmFieldAccessString(
  Sections.RetailerOrders,
  'orderNumber',
  FeatureFlagModifiers.Enabled,
)

const shippingDateEnabledFlag = generateCrmFieldAccessString(
  Sections.RetailerOrders,
  'shippingDate',
  FeatureFlagModifiers.Enabled,
)

const deliveryDatesEnabledFlag = generateCrmFieldAccessString(
  Sections.Orders,
  'desiredDeliveryDates',
  FeatureFlagModifiers.Enabled,
)

const DownloadXlsxButtonFlag = generateCrmButtonAccessString(
  Sections.RetailerOrders,
  Buttons.DownloadXlsx,
  FeatureFlagModifiers.Enabled,
)

const ProductCommentFlag = generateCrmFieldAccessString(
  Sections.RetailerOrders,
  'productComment',
  FeatureFlagModifiers.Enabled,
)

const ShowShippedQtyFlag = generateCustomFeatureFlag(Sections.RetailerSkuOrders, CustomFeatureName.ShowShippedQty)

const RestrictUpdateAfterSaveFlag = generateCustomFeatureFlag(Sections.RetailerOrders, 'restrictUpdateAfterSave')

const UpdateRestrictedFlag = generateCustomFeatureFlag(Sections.RetailerOrders, 'restrictUpdateRetailerOrders')

const UpdateAllowedForCertainCompaniesFlag = generateCustomFeatureFlag(
  Sections.RetailerOrders,
  'allowUpdateRetailerOrdersForCertainCompanies',
)

export const DistributorOrderSkuDetailsForm: React.VFC<DistributorOrderSkuDetailsFormProps> = ({
  mode,
  order,
  initialValues,
  onRemove,
  onRestore,
  onCancel,
  onSubmit,
  onCancelOrder,
  onProducerConfirmation,
  onComplete,
  removeProgress,
  progress,
  onUncancelOrder,
}) => {
  const { t } = useTranslation(['distributorOrder', 'common', 'company', 'labels'])
  const [isRemovePermitted, isUpdatePermitted] = usePermissions([
    { capability: CAPABILITY.DISTRIBUTOR_ORDERS_SKU, permission: PERMISSION.D },
    { capability: CAPABILITY.DISTRIBUTOR_ORDERS_SKU, permission: PERMISSION.U },
  ])

  const role = useSelector(AuthSelectors.role)
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const isProducerHead = isProducersHead(role)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const [currentProducer, setCurrentProducer] = useState<Company | undefined>(order?.producer)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)
  const [seasons, setSeasons] = useState<Season[]>([])
  const [selectedDistributor, setSelectedDistributor] = useState<Company | null>(
    order?.distributor || (isUsersCompanyDistributor(userCompany) && userCompany) || null,
  )
  const [headCompanyProgress, headCompany] = useCompanyById(selectedDistributor?.head_company_relation?.company_id)
  const [headDistributor, setHeadDistributor] = useState<Company | null>(headCompany || null)

  const producerManagerCanEdit = isProducersManager(role)
    ? [DistributorOrderStatus.New, DistributorOrderStatus.Changed].includes(order?.status as DistributorOrderStatus)
    : true

  const [deliveryDatesEnabled, setDeliveryDatesEnabled] = useState(false)
  const [shippingDateEnabled, setShippingDateEnabled] = useState(false)
  const [orderNumberEnabled, setOrderNumberEnabled] = useState(false)
  const [selectedPromocode, setSelectedPromocode] = useState<string>()

  const openModal = useAction(ModalActions.open)
  const [productQtyIsNullError, setProductQtyIsNullError] = useState(false)
  const clearProductsLimitsForRetailerOrders = useAction(RetailerAllocationActions.clearProductsLimitsForRetailerOrders)

  const [skuMatchingErrors, setSkuMatchingErrors] = useState<boolean[]>([])

  const checkFeatureFlag = useFeatureFlags()
  const dateFormat = useDateFormat({ withTime: true })

  const isHistoryCardVisible = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerOrders, 'historyCardVisible'),
  )

  const isUpdateAfterSaveRestricted = checkFeatureFlag(RestrictUpdateAfterSaveFlag)
  const isUpdateRestricted = checkFeatureFlag(UpdateRestrictedFlag)
  const isUpdateAllowedForCertainCompanies = checkFeatureFlag(UpdateAllowedForCertainCompaniesFlag)

  const isDownloadXlsxEnabled = useMemo(() => checkCompanyFeatureFlags(currentProducer, DownloadXlsxButtonFlag, role), [
    currentProducer,
    role,
  ])

  const isCompletedButtonEnabled =
    checkFeatureFlag(
      generateCrmButtonAccessString(Sections.RetailerOrders, Buttons.Completed, FeatureFlagModifiers.Enabled),
    ) || isAdmin

  const isCompletedButtonDisabled = !isAdmin && !isProducer(role)

  const showComment = isAdmin || checkFeatureFlag(ProductCommentFlag)
  const showShippedQty = checkCompanyFeatureFlags(currentProducer, ShowShippedQtyFlag, role)

  const isAttachmentsVisible = !checkFeatureFlag(
    generateFieldModifierString(FieldLocation.SkuOrders, FieldNames.Attachments, FeatureFlagModifiers.Hidden),
  )

  const checkProducerFeatureFlags = useCallback(
    (producer?: Company | null): void => {
      if (!producer) {
        return
      }

      setOrderNumberEnabled(checkCompanyFeatureFlags(producer, orderNumberEnabledFlag, role))
      setShippingDateEnabled(checkCompanyFeatureFlags(producer, shippingDateEnabledFlag, role))
      setDeliveryDatesEnabled(checkCompanyFeatureFlags(producer, deliveryDatesEnabledFlag, role))
    },
    [role],
  )

  const [isSeasonClosed, setIsSeasonClosed] = useState(false)

  const seasonClosedEditable = isSeasonClosed ? isAdmin : true

  const isEditable =
    !order ||
    (seasonClosedEditable &&
      producerManagerCanEdit &&
      !isUpdateAfterSaveRestricted &&
      !isUpdateRestricted &&
      isUpdatePermitted)

  const isEditableForCertainCompanies =
    isUpdateAllowedForCertainCompanies &&
    [DistributorOrderStatus.New, DistributorOrderStatus.Changed, DistributorOrderStatus.Confirmed].includes(
      order?.status as DistributorOrderStatus,
    )

  useEffect(() => {
    checkProducerFeatureFlags(currentProducer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProducer])

  const initialProducerId = useMemo(() => {
    if (initialValues.producer_id) return initialValues.producer_id
    if (initialValues.seller_id) return initialValues.seller_id
    if (isUsersCompanyProducer(userCompany) && userCompany) return userCompany.id
    if (
      isUsersCompanyDistributor(userCompany) &&
      userCompany?.producers_relations?.length === 1 &&
      userCompany.producers_relations[0]
    )
      return userCompany.producers_relations[0]
  }, [initialValues.producer_id, initialValues.seller_id, userCompany])

  const currentDate = useMemo(() => {
    return new Date().toISOString()
  }, [])

  const validationSchema = useMemo(() => {
    return Yup.object({
      distributor_id: Yup.string().required(t('validation:field_required')),
      producer_id: Yup.string().required(t('validation:field_required')),
      sku_items: Yup.array().min(1, t('validation:oneProductCardMinRequired')),
      order_date: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(t('validation:field_required')),
      status: Yup.string(),
      comment: Yup.string(),
    })
  }, [t])

  const formik = useFormik<DistributorOrderSkuDetailsFormFields>({
    initialValues: {
      ...initialValues,
      producer_id: initialProducerId,
      seller_id: initialProducerId,
      distributor_id:
        initialValues.distributor_id || (isUsersCompanyDistributor(userCompany) ? userCompany?.id ?? '' : ''),
      comment: initialValues.comment || '',
      sku_items: initialValues.sku_items || [],
      billing_company_id: initialValues.billing_company_id || userCompany?.id,
      interaction: initialValues.interaction || {
        confirmed_by_producer: false,
        canceled_by_role: null,
      },
      order_date: initialValues.order_date || currentDate,
      promocodes: initialValues.promocodes,
      admin_comment: initialValues.admin_comment || '',
    },
    validationSchema,
    enableReinitialize: true,
    // used custom submit handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  useEffect(() => {
    setHeadDistributor(headCompany ?? null)
  }, [selectedDistributor?.head_company_relation?.company_id, headCompany])

  const [, userDocuments = []] = useUserDocumentsNoCache(formik.values.owner_id)
  const editingDateHasExpired = useHasOutOfStockDateExpired(formik.values.sku_items)

  const handleFilesChange = useCallback(
    (sku_items: FileItem[]) => {
      formik.setFieldValue('files', sku_items)
    },
    [formik],
  )

  const handlePromocodesChange = useCallback(
    (promocodes: Promocode[]) => {
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()

      if (!formik.isValid || !allSkuMatched || (mode === 'create' && editingDateHasExpired)) {
        return
      }

      const values = {
        ...formik.values,
        seller_id: formik.values.seller_id,
        sku_items: formik.values.sku_items.filter(item => item.quantity),
      }

      if (!values.sku_items.length) {
        setProductQtyIsNullError(true)
        return
      }

      onSubmit(formik.values, {
        dirty: formik.dirty,
        duplicate,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const isProducerChangeAllowed = !order

  const onProducerChange = useCallback(
    (id: string | undefined, producer: Company | null) => {
      formik.resetForm()
      setCurrentProducer(producer || undefined)

      if (id !== formik.values.producer_id) {
        formik.setFieldValue('distributor_id', null)
        setSelectedDistributor(null)
      }

      checkProducerFeatureFlags(producer)
      const producerCurrentSeason = seasons.find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)
      formik.setFieldValue('producer_id', id)
      formik.setFieldValue('seller_id', id)
    },
    [checkProducerFeatureFlags, formik, seasons],
  )

  const onLoadSeasons = (seasons: Season[]) => {
    setSeasons(seasons)
    if (!formik.values.season_id) {
      const producerCurrentSeason = seasons.find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)
    }
  }

  const changeSeason = useCallback(
    (val: string) => {
      formik.setFieldValue('season_id', val)
      formik.setFieldValue('sku_items', [])
      formik.setFieldTouched('sku_items', false)
      clearProductsLimitsForRetailerOrders()
    },
    [clearProductsLimitsForRetailerOrders, formik],
  )

  const handleSeasonChange = useCallback(
    (val?: string | null, original?: Season | null) => {
      setIsSeasonClosed(original?.is_season_closed ?? false)
      if (!formik.values.season_id || !formik.values.sku_items.length) {
        changeSeason(val as string)
      } else {
        openModal({
          contentProps: {},
          content: (
            <ModalWithButtonContent
              modalContent={t('modalText')}
              allowButtonText={t('common:yes')}
              cancelButtonText={t('common:no')}
              handlleAllowButtonClick={() => changeSeason(val as string)}
            />
          ),
        })
      }
    },
    [changeSeason, formik.values.season_id, formik.values.sku_items.length, openModal, t],
  )

  const isCancelOrderDisabled = useMemo(() => {
    return formik.values.status === DistributorOrderStatus.Canceled || editingDateHasExpired
  }, [editingDateHasExpired, formik.values.status])

  const onDistributorChange = useCallback(
    (value: string | undefined, distributor: Company | null) => {
      formik.setFieldValue('distributor_id', value)
      setSelectedDistributor(distributor || null)

      if (!formik.values.billing_company_id) {
        formik.setFieldValue('billing_company_id', value)
      }
    },
    [formik],
  )

  const shippingAddress = [
    t(`common:country.full.${selectedDistributor?.country}`),
    selectedDistributor?.city,
    selectedDistributor?.address,
    selectedDistributor?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const billingAddress = [
    t(`common:country.full.${headDistributor?.country}`),
    headDistributor?.city,
    headDistributor?.address,
    headDistributor?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const confirmationPermitted = (isProducer(role) || isAdmin) && !!order && !isUpdateAfterSaveRestricted
  const cancellationPermitted =
    !!order && !isUpdateAfterSaveRestricted && order.status !== DistributorOrderStatus.Canceled
  const uncancellationPermitted =
    (isAdmin ||
      checkFeatureFlag(
        generateCrmButtonAccessString(Sections.RetailerOrders, Buttons.Uncancel, FeatureFlagModifiers.Enabled),
      )) &&
    !!order &&
    order.status === DistributorOrderStatus.Canceled

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  const canRemoveOrder = useMemo(() => {
    return isRemovePermitted && !editingDateHasExpired
  }, [isRemovePermitted, editingDateHasExpired])

  const removingPermitted = useMemo(
    () => !!order && canRemoveOrder && order.status !== DistributorOrderStatus.Deleted,
    [canRemoveOrder, order],
  )

  const restorePermitted = useMemo(() => isAdmin && !!order && order.status === DistributorOrderStatus.Deleted, [
    isAdmin,
    order,
  ])

  const [progressXLSX, downloadXLSX] = useDownload(endpoints.distributorOrder('download/xlsx'), { order_id: order?.id })

  const isDisabledField = !isEditableForCertainCompanies && !isEditable
  const isSaveBtnDisabled = !formik.dirty || isDisabledField || isFilesUploading || progress === Progress.WORK
  const canAddFiles = formik.values.files.length || isEditable || isEditableForCertainCompanies
  const isNotEditableCertainFields = !isEditable || isEditableForCertainCompanies

  const handleCardChange = useCallback(
    (index, qty: number, card?: StorefrontCardForSelect, sku?: StorefrontSku, wizard_comment?: string) => {
      const sku_items = clone(formik.values.sku_items)
      const stringQty = String(qty)
      if (card) {
        const newItem: DistributorOrderSkuItem = {
          card,
          card_id: card.id,
          quantity: stringQty,
          sku_id: sku?.id,
          credit_facility: undefined,
          sku,
          key: uuid.v4(),
          wizard_comment,
        }
        sku_items.unshift(newItem)
      } else {
        sku_items[index].quantity = stringQty
      }
      formik.setFieldValue('sku_items', sku_items)
      if (productQtyIsNullError && sku_items.find(item => item.quantity)) {
        setProductQtyIsNullError(false)
      }
    },
    [formik, productQtyIsNullError],
  )

  const handleCardRemove = useCallback(
    (index: number) => {
      if (formik.values.sku_items[index]) {
        formik.setFieldValue('sku_items', remove(index, 1, formik.values.sku_items))
      }
    },
    [formik],
  )

  const handleFinalQtyChange = useCallback(
    (idx, value: number) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].final_qty = String(value)
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleShippedQtyChange = useCallback(
    (idx, value) => {
      if (!isAdmin) return

      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].shipped_qty = value
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik, isAdmin],
  )

  const handleDeliveredQtyChange = useCallback(
    (idx, value: number) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].delivered_qty = String(value)
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handlePackageTypeChange = useCallback(
    (idx, value: string) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        if (!sku_items[idx].sku) {
          sku_items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              package_id: value,
              product_id: sku_items[idx].card?.product_id,
            },
          }
        } else {
          ;(sku_items[idx].sku as StorefrontSku).params.package_id = value
          sku_items[idx].sku_id = undefined
        }
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleOptionsChange = useCallback(
    (idx, value) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        if (!sku_items[idx].sku) {
          sku_items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              options: value,
              product_id: sku_items[idx].card?.product_id,
            },
          }
        } else {
          ;(sku_items[idx].sku as StorefrontSku).params.options = value
        }
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleSkuMatched = useCallback(
    (idx, value?: StorefrontSku) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].sku = value
        sku_items[idx].sku_id = value?.id
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleCommentChange = useCallback(
    (idx, value: string) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].comment = value
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const allSkuMatched = useMemo(() => {
    const sku_items = clone(formik.values.sku_items)
    const errors: boolean[] = []
    let isAllMatched = true

    sku_items.forEach((item: DistributorOrderSkuItem) => {
      const skuMatched = !!item.sku_id
      if (!skuMatched) {
        isAllMatched = false
      }
      errors.push(skuMatched)
    })

    setSkuMatchingErrors(errors)
    return isAllMatched
  }, [formik.values.sku_items])

  const [totalSum, setTotalSum] = useState('')

  const [actualTotalNet, setActualTotalNet] = useState('')

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <Column>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('labels:supplier')}>
                  {isProducerChangeAllowed ? (
                    <CompanySelect
                      label={t('form.labels.producer')}
                      onChange={onProducerChange}
                      invalid={formik.touched.producer_id && !!formik.errors.producer_id}
                      value={formik.values.producer_id}
                      errorText={formik.errors.producer_id}
                      isDisabled={!!userCompany && userCompany.company_type === CompanyType.Producer}
                      required
                      onMenuClose={() => formik.setFieldTouched('producer_id')}
                      companyType={CompanyType.Producer}
                      filter={{ is_seller: true }}
                      onLoadList={list => {
                        const producerId =
                          userCompany &&
                          ((userCompany.company_type === CompanyType.Distributor &&
                            userCompany.producers_relations.length > 0 &&
                            userCompany.producers_relations[0]) ||
                            (userCompany.company_type === CompanyType.Producer && userCompany.id))
                        const producer = list.data.find(item => item.id === producerId)
                        if (producer) {
                          setCurrentProducer(producer)
                        }
                      }}
                    />
                  ) : (
                    <CompanyInfoPreview
                      company={order?.producer}
                      footer={`ID ${order?.producer?.id || ''}`}
                      link={
                        isAdmin && order && order.producer
                          ? generatePath(CompanyRoutes.Edit, { id: order.producer?.id })
                          : undefined
                      }
                    />
                  )}
                  <SeasonSelect
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    isDisabled={!isEditable || !formik.values.producer_id}
                    value={formik.values.season_id}
                    onChange={handleSeasonChange}
                    companyId={formik.values.producer_id}
                    required
                    isOptionDisabled={option => (option.is_season_closed ? !isAdmin && !isProducerHead : false)}
                    onLoadList={(_, seasons) => {
                      onLoadSeasons(Object.values(seasons))
                    }}
                  />
                  <CompanySelect
                    label={t('form.sectionHeaders.distributor')}
                    placeholder={t('form.placeholders.distributor')}
                    onChange={onDistributorChange}
                    onMenuClose={() => formik.setFieldTouched('distributor_id')}
                    value={formik.values.distributor_id}
                    isDisabled={!formik.values.producer_id || !isEditable}
                    invalid={!!formik.submitCount && !!formik.errors.distributor_id}
                    errorText={formik.errors.distributor_id}
                    isClearable
                    required
                    companyType={CompanyType.Distributor}
                    filter={{ for_producer_id: formik.values.producer_id || formik.values.seller_id }}
                  />
                  {!!selectedDistributor && (
                    <CompanyInfoPreview company={selectedDistributor} testId={'distributor-preview'} />
                  )}
                  {headCompanyProgress === Progress.SUCCESS && (
                    <FormComponents.FormSection title={t('form.sectionHeaders.billingCompany')}>
                      <PreviewContainer.WrapperCompact>
                        <PreviewContainer.Header>{headDistributor?.internal_name || ''}</PreviewContainer.Header>
                        <PreviewContainer.Subheader>
                          {headDistributor?.short_description || ''}
                        </PreviewContainer.Subheader>
                        <PreviewContainer.Text>{billingAddress}</PreviewContainer.Text>
                      </PreviewContainer.WrapperCompact>
                    </FormComponents.FormSection>
                  )}
                  {!!selectedDistributor && selectedDistributor.head_company_relation && (
                    <FormComponents.FormSection title={t('form.sectionHeaders.shippingAddress')}>
                      <PreviewContainer.WrapperCompact>
                        <PreviewContainer.Subheader>{shippingAddress}</PreviewContainer.Subheader>
                      </PreviewContainer.WrapperCompact>
                    </FormComponents.FormSection>
                  )}
                  {!!selectedDistributor && !selectedDistributor.head_company_relation && (
                    <>
                      <FormComponents.FormSection title={t('form.sectionHeaders.billingAndShippingAddress')}>
                        <PreviewContainer.WrapperCompact>
                          <PreviewContainer.Subheader>{shippingAddress}</PreviewContainer.Subheader>
                        </PreviewContainer.WrapperCompact>
                      </FormComponents.FormSection>
                    </>
                  )}
                  <Input
                    label={t('form.sectionHeaders.purchaseNumber')}
                    {...formik.getFieldProps('purchase_number')}
                    disabled={isNotEditableCertainFields}
                  />
                  {orderNumberEnabled && (
                    <Input
                      label={t('form.sectionHeaders.orderNumber')}
                      {...formik.getFieldProps('external_order_id')}
                      disabled={isNotEditableCertainFields}
                    />
                  )}
                  <DatePickerWrapper data-test-id={'order-date'}>
                    <DatePicker
                      onChange={value => {
                        formik.setFieldValue('order_date', value)
                        formik.setFieldTouched('order_date')
                      }}
                      date={formik.getFieldProps('order_date').value}
                      invalid={formik.touched.order_date && !!formik.errors.order_date}
                      errorText={formik.errors.order_date}
                      label={t('form.labels.orderDate')}
                      disabled={!isAdmin}
                      required
                      format={dateFormat}
                    />
                  </DatePickerWrapper>
                  {deliveryDatesEnabled ? (
                    <>
                      <DatePickerWrapper data-test-id={'desired-delivery-date'}>
                        <DatePicker
                          onChange={value => {
                            formik.setFieldValue('desired_delivery_date', value)
                            formik.setFieldTouched('desired_delivery_date')
                          }}
                          date={formik.getFieldProps('desired_delivery_date').value}
                          invalid={formik.touched.order_date && !!formik.errors.order_date}
                          errorText={formik.errors.order_date}
                          label={t('form.labels.desiredDeliveryDate')}
                          disabled={isDisabledField}
                          format={dateFormat}
                        />
                      </DatePickerWrapper>
                      <DatePickerWrapper data-test-id={'delivery-date'}>
                        <DatePicker
                          onChange={value => {
                            formik.setFieldValue('delivery_date', value)
                            formik.setFieldTouched('delivery_date')
                          }}
                          date={formik.getFieldProps('delivery_date').value}
                          invalid={formik.touched.order_date && !!formik.errors.order_date}
                          errorText={formik.errors.order_date}
                          label={t('form.labels.deliveryDate')}
                          disabled={isDisabledField}
                          format={dateFormat}
                        />
                      </DatePickerWrapper>
                    </>
                  ) : null}
                  {shippingDateEnabled && (
                    <DatePickerWrapper data-test-id={'shipping-date'}>
                      <DatePicker
                        onChange={value => {
                          formik.setFieldValue('shipping_date', value)
                          formik.setFieldTouched('shipping_date')
                        }}
                        date={formik.getFieldProps('shipping_date').value}
                        invalid={formik.touched.shipping_date && !!formik.errors.shipping_date}
                        errorText={formik.errors.shipping_date}
                        label={t('form.labels.shippingDate')}
                        disabled={isDisabledField || isDistributor(role)}
                        format={dateFormat}
                      />
                    </DatePickerWrapper>
                  )}
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            {order && (isAgro(role) || isHistoryCardVisible) && (
              <OrderHistoryCard
                orderId={order.id}
                createdById={order.author_id}
                orderSource={order.order_source}
                createdDate={order.created_at}
                confirmedByProducerId={order.last_confirmed_id}
              />
            )}
          </Column>
          <Column>
            <OrderSkuCardItemsManager
              context={'distributor'}
              order={order}
              onChange={handleCardChange}
              onRemove={handleCardRemove}
              onMenuClose={() => formik.setFieldTouched('sku_items')}
              isAllowed={!!formik.values.producer_id || !!formik.values.seller_id}
              sku_items={formik.values.sku_items}
              documents={userDocuments}
              producer={currentProducer}
              onFinalQtyChange={handleFinalQtyChange}
              onShippedQtyChange={handleShippedQtyChange}
              onDeliveredQtyChange={handleDeliveredQtyChange}
              onPackageTypeChange={handlePackageTypeChange}
              onOptionsChange={handleOptionsChange}
              onSkuMatched={handleSkuMatched}
              onCommentChange={handleCommentChange}
              invalid={formik.touched.sku_items && !!formik.errors.sku_items}
              errorText={formik.errors.sku_items as string}
              mode={mode}
              total={totalSum}
              netTotal={actualTotalNet}
              showComment={showComment}
              skuMatchingErrors={skuMatchingErrors}
              showErrors={formik.submitCount >= 1}
              readOnly={!isEditable}
              initialProducerId={initialProducerId}
              distributorId={formik.values.distributor_id as string}
              seasonId={formik.values.season_id}
              showShippedQty={showShippedQty}
            />
            <OrderSkuDiscounts
              sellerId={formik.values.producer_id}
              seasonId={formik.values.season_id}
              entries={formik.values.sku_items}
              scope={IncentiveProgramScope.DistributorOrder}
              onActualTotalNetChange={setActualTotalNet}
              onTotalSumChange={setTotalSum}
              orderDate={formik.values.order_date}
              promocodes={formik.values.promocodes}
              selectedPromocode={selectedPromocode}
              onSelectPromocode={setSelectedPromocode}
              onPromocodesChange={handlePromocodesChange}
              isPromocodeDisabled={!isEditable}
            />
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.comments')}</SectionTitle>
              <CommentFormSection title={t('form.sectionHeaders.comment')} />
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('comment')}
                  placeholder={t('form.placeholders.comment')}
                  disabled={isDisabledField}
                  data-test-id={'comment-textarea'}
                />
              </SectionBody>
              {isAdmin && (
                <>
                  <CommentFormSection title={t('form.sectionHeaders.adminComment')} />
                  <SectionBody>
                    <TextArea
                      {...formik.getFieldProps('admin_comment')}
                      placeholder={t('form.placeholders.adminComment')}
                      data-test-id={'admin-comment-textarea'}
                    />
                  </SectionBody>
                </>
              )}
            </SectionContainer>
            {isAttachmentsVisible && canAddFiles && (
              <SectionContainer>
                <SectionTitle>{t('form.sectionHeaders.attachedFiles')}</SectionTitle>
                <SectionBody>
                  <FileManager items={formik.values.files} onChange={handleFilesChange} disabled={isDisabledField} />
                </SectionBody>
              </SectionContainer>
            )}
          </Column>
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          intent={'primary'}
          filled
          disabled={isSaveBtnDisabled}
          onClick={handleSubmit}
          progress={progress}
          data-test-id={'save-button'}
        >
          {t('common:save')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
          disabled={isDisabledField}
        />
        <StickyFooterLayout.ActionButtonsWrapper>
          {confirmationPermitted && (
            <Button
              intent={'action'}
              filled
              onClick={onProducerConfirmation}
              progress={progress}
              disabled={
                formik.values.interaction.confirmed_by_producer ||
                order?.status === DistributorOrderStatus.Canceled ||
                isDisabledField ||
                isFilesUploading
              }
              data-test-id={'confirmation-button'}
            >
              {isAdmin ? t('confirmOrderForProducer') : t('confirmOrder')}
            </Button>
          )}
          {cancellationPermitted && (
            <StickyFooterBtn
              heading={t('form.cancelOrderHeader')}
              text={t('form.cancelOrderText')}
              onSubmit={onCancelOrder}
              buttonText={t('cancelOrder')}
              intent={'danger'}
              filled
              progress={progress}
              disabled={isCancelOrderDisabled || isDisabledField || isFilesUploading}
              testId="cancel-button"
            />
          )}
          {uncancellationPermitted && (
            <StickyFooterBtn
              heading={t('form.uncancelOrderHeader')}
              text={t('form.uncancelOrderText')}
              onSubmit={onUncancelOrder}
              buttonText={t('uncancelOrder')}
              intent={'danger'}
              filled
              progress={progress}
              disabled={isDisabledField || isFilesUploading}
            />
          )}
        </StickyFooterLayout.ActionButtonsWrapper>
        {!!order && isDownloadXlsxEnabled && (
          <DownloadButtonWrapper>
            <Button intent="primary" filled onClick={downloadXLSX} progress={progressXLSX}>
              {t('labels:downloadXlsx')}
            </Button>
          </DownloadButtonWrapper>
        )}
        {isCompletedButtonEnabled && order?.status === DistributorOrderStatus.Confirmed && (
          <Button intent={'action'} onClick={onComplete} progress={progress} disabled={isCompletedButtonDisabled}>
            {t('labels:completed')}
          </Button>
        )}

        {productQtyIsNullError && <Snackbar>{t('productQtyIsNullError')}</Snackbar>}
        {removingPermitted && !!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { id: order?.slug })}
          />
        )}
        {restorePermitted && !!onRestore && <StickyFooterRestoreBtn onRestore={onRestore} progress={progress} />}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default DistributorOrderSkuDetailsForm
