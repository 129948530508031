import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import PromoInfoActions from './duck'
import PromoInfoSelectors from './selectors'
import { PromoInfo } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const usePromoInfoList: ResourceHook<PromoInfo[], void[]> = () => {
  const progress = useSelector(PromoInfoSelectors.listFetchProgress)
  const list = useSelector(PromoInfoSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(PromoInfoActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const usePromoInfo: ResourceHook<PromoInfo, [string]> = (id: string) => {
  const meta = useSelector(state => PromoInfoSelectors.meta(state, id))
  const item = useSelector(state => PromoInfoSelectors.item(state, id))
  const fetchAction = useAction(PromoInfoActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const usePromoInfoById: ResourceHook<PromoInfo, [string]> = (id: string) => {
  const meta = useSelector(state => PromoInfoSelectors.meta(state, id))
  const item = useSelector(state => PromoInfoSelectors.item(state, id))
  const fetchAction = useAction(PromoInfoActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
