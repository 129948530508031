import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'

import { Input, useAction, useHelmet } from '@agro-club/frontend-shared'
import { SubmitButton, ErrorWrapper, LinkButton } from './styled'
import { useTranslation } from 'react-i18next'
import { useAnalytics } from 'use-analytics'
import Routes from './routes'
import AnalyticsEvents from './events'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

const StyledLink = styled(LinkButton)`
  align-self: flex-end;
  margin-bottom: 20px;
`

const InputStyled = styled(Input)`
  margin-bottom: 24px;
` as typeof Input

type FormFields = {
  email: string
  password: string
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation(['auth', 'common'])
  const { track } = useAnalytics()

  const submitAction = useAction(AuthActions.tokenRequested)
  const error = useSelector(AuthSelectors.getError)
  const progress = useSelector(AuthSelectors.tokenRequestProgress)

  useHelmet({ title: t('metaTitle') })

  const validationSchema = useMemo(() => {
    return Yup.object({
      email: Yup.string()
        .email(t('validation:email_invalid'))
        .required(t('validation:email_required')),
      password: Yup.string()
        .min(6, t('validation:password_length_warning'))
        .required(t('validation:password_required')),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: submitAction,
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSubmit = useCallback(() => {
    if (formik.isValid && formik.dirty) {
      formik.submitForm()
    }
  }, [formik])

  return (
    <FormWrapper
      onSubmit={e => {
        e.preventDefault()
        track(AnalyticsEvents.SigninTap, { value: formik.values.email })
        handleSubmit()
      }}
    >
      <InputStyled
        label={t('common:email')}
        placeholder={'example@mail.com'}
        type={'email'}
        {...formik.getFieldProps('email')}
        invalid={formik.touched.email && !!formik.errors.email}
        errorText={formik.errors.email}
      />
      <InputStyled
        ref={inputRef}
        label={t('common:password')}
        {...formik.getFieldProps('password')}
        type={'password'}
        invalid={formik.touched.password && !!formik.errors.password}
        errorText={formik.errors.password}
      />
      <StyledLink
        data-test-id={'forgot-password'}
        to={Routes.ResetPassword}
        onClick={() => track(AnalyticsEvents.ForgotPasswordTap)}
      >
        {t('auth:forgotPassword')}
      </StyledLink>
      <SubmitButton
        type={'submit'}
        intent={'primary'}
        filled={true}
        disabled={!formik.isValid || !formik.dirty}
        progress={progress}
      >
        {t('auth:signIn')}
      </SubmitButton>
      <ErrorWrapper>{error}</ErrorWrapper>
    </FormWrapper>
  )
}

export default LoginForm
