import React from 'react'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useHistoryPush } from '@agro-club/frontend-shared'
import AllocationTerritoryRoutes from '../AllocationTerritory/routes'
import AllocationRetailerRoutes from '../AllocationRetailer/routes'

export type AllocationHeaderProps = {
  activeTab: 'retailer' | 'territories'
}

const AllocationHeader: React.VFC<AllocationHeaderProps> = ({ activeTab }) => {
  const push = useHistoryPush()
  const { t } = useTranslation('allocation')

  return (
    <>
      <Header.RootWithSmallTabs>
        <Header.Title title={t('title')} />
        <Header.SmallTabsContainer>
          <Header.SmallTab
            isActive={activeTab === 'territories'}
            onClick={() => push({ route: AllocationTerritoryRoutes.List })}
          >
            {t('allocationTerritoryTitle')}
          </Header.SmallTab>
          <Header.SmallTab
            isActive={activeTab === 'retailer'}
            onClick={() => push({ route: AllocationRetailerRoutes.List })}
          >
            {t('allocationRetailerTitle')}
          </Header.SmallTab>
        </Header.SmallTabsContainer>
      </Header.RootWithSmallTabs>
    </>
  )
}

export default AllocationHeader
