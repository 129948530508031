import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import { useAction } from '@agro-club/frontend-shared'
import { useProgramSummaryList } from 'modules/domain/programSummary/hooks'
import { CompanyNameCell, FarmerNameCell, LastUpdateCell, PriceCell, ProgramsCell } from './ProgramSummaryListTableCell'
import { useSelector } from 'react-redux'
import ProgramSummarySelector from 'modules/domain/programSummary/selectors'
import ProgramSummaryAction from 'modules/domain/programSummary/duck'
import ProgramSummaryRoutes from '../routes'
import { Row } from 'react-table'
import { generatePath } from 'modules/utils/helpers/generatePath'
import { useHistory } from 'react-router-dom'
import { ProgramSummaryListEntry } from 'modules/domain/programSummary/types'

const ProgramSummaryListTable: React.FC = () => {
  const { t } = useTranslation('programSummary')
  const history = useHistory()
  const [progress, data = []] = useProgramSummaryList()

  const allColumns = useMemo(
    () => [
      { Header: t('headers.retailer'), cellAlign: 'left', Cell: CompanyNameCell },
      { Header: t('headers.farmer'), cellAlign: 'left', Cell: FarmerNameCell },
      { Header: t('headers.prevBalance'), cellAlign: 'left', accessor: 'prev_balance' as const, Cell: PriceCell },
      { Header: t('headers.preBate'), cellAlign: 'left', accessor: 'pre_bate' as const, Cell: PriceCell },
      {
        Header: t('headers.prevFinalPayment'),
        cellAlign: 'left',
        accessor: 'prev_final_payment' as const,
        Cell: PriceCell,
      },
      { Header: t('headers.totalRebate'), cellAlign: 'left', accessor: 'total_rebate' as const, Cell: PriceCell },
      {
        Header: t('headers.netTotalRebate'),
        cellAlign: 'left',
        accessor: 'net_total_rebate' as const,
        Cell: PriceCell,
      },
      { Header: t('headers.lastUpdate'), cellAlign: 'left', Cell: LastUpdateCell },
      { Header: t('headers.programs'), cellAlign: 'left', accessor: 'programs' as const, Cell: ProgramsCell },
    ],
    [t],
  )

  const total = useSelector(ProgramSummarySelector.total)
  const pages = useSelector(ProgramSummarySelector.pages)
  const pageSize = useSelector(ProgramSummarySelector.pageSize)
  const page = useSelector(ProgramSummarySelector.page)
  const listRequested = useAction(ProgramSummaryAction.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleRowClick = useCallback((row: Row<ProgramSummaryListEntry>) => {
    history.push(generatePath(ProgramSummaryRoutes.Edit, { id: row.original.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CompoundedTable
      columns={allColumns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={fetchNextItems}
      pages={pages}
      onRowClick={handleRowClick}
    />
  )
}

export default ProgramSummaryListTable
