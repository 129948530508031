import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleSelectProps, SimpleSelect } from '@agro-club/frontend-shared'
import { FarmStatus } from 'modules/domain/farmer/types'

const FarmStatusSelect: React.FC<Omit<SimpleSelectProps, 'options'>> = ({ ...selectProps }) => {
  const { t } = useTranslation('farmer')
  const options = useMemo(() => {
    return Object.keys(FarmStatus).map(e => {
      return { id: FarmStatus[e], title: t(`farmStatus.${FarmStatus[e]}`) }
    })
  }, [t])

  return <SimpleSelect {...selectProps} options={options} isClearable />
}

export default FarmStatusSelect
