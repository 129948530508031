import { StatusIcon, useAction } from '@agro-club/frontend-shared'
import { useIncentiveProgramList } from 'modules/domain/incentiveProgram/hooks'
import IncentiveProgramActions from 'modules/domain/incentiveProgram/duck'
import IncentiveProgramSelectors from 'modules/domain/incentiveProgram/selectors'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CellProps, Row } from 'react-table'
import {
  IncentiveProgram,
  IncentiveProgramScope,
  IncentiveProgramStatus,
  IncentiveProgramTypes,
} from 'modules/domain/incentiveProgram/types'
import { generatePath } from 'modules/utils/helpers/generatePath'
import IncentiveProgramRoutes from '../routes'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import i18n from 'i18n'

type IncentiveProgramItem = Partial<IncentiveProgram>

export const IncentiveProgramTypeNames = {
  [IncentiveProgramTypes.Promocode]: i18n.t('discountRules:types.promocode'),
  [IncentiveProgramTypes.Quantity]: i18n.t('discountRules:types.qty'),
  [IncentiveProgramTypes.Gift]: i18n.t('discountRules:types.gift'),
  [IncentiveProgramTypes.Percentage]: i18n.t('discountRules:types.percentage'),
}

const IncentiveProgramTypeCell: React.VFC<CellProps<IncentiveProgramItem>> = ({ cell }) => {
  const type = Array.isArray(cell.value) && cell.value.length ? cell.value[0].type : ''
  return type in IncentiveProgramTypeNames ? IncentiveProgramTypeNames[type] : 'None'
}

export const IncentiveProgramScopeNames = {
  [IncentiveProgramScope.FarmerOrders]: i18n.t('incentiveProgram:scopes.farmer'),
  [IncentiveProgramScope.DistributorOrder]: i18n.t('incentiveProgram:scopes.distributor'),
}

const ScopeCell: React.VFC<CellProps<IncentiveProgramItem, IncentiveProgramScope[]>> = ({ cell }) => {
  return <>{cell.value.map(scope => IncentiveProgramScopeNames[scope]).join(', ')}</>
}

const NameCell: React.VFC<CellProps<IncentiveProgramItem>> = ({ cell, column, row }) => {
  return (
    <div>
      <StatusIcon
        status={
          row.original.status === IncentiveProgramStatus.Active
            ? 'active'
            : row.original.status === IncentiveProgramStatus.Inactive
            ? 'inactive'
            : 'deleted'
        }
      />
      <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
    </div>
  )
}

const IncentiveProgramTable = () => {
  const history = useHistory()
  const { t } = useTranslation('incentiveProgram')
  const [progress, data = []] = useIncentiveProgramList()

  const total = useSelector(IncentiveProgramSelectors.total)
  const page = useSelector(IncentiveProgramSelectors.page)
  const pages = useSelector(IncentiveProgramSelectors.pages)
  const pageSize = useSelector(IncentiveProgramSelectors.pageSize)

  const listRequested = useAction(IncentiveProgramActions.listRequested)

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
        Cell: NameCell,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
      {
        Header: t('list.tableHeaders.campaign'),
        accessor: 'incentive_campaign.title' as const,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season.title' as const,
      },
      {
        Header: t('form.discountFor'),
        accessor: 'scopes' as const,
        Cell: ScopeCell,
      },
      {
        Header: t('list.tableHeaders.type'),
        accessor: 'tiers_rules' as const,
        id: 'type',
        Cell: IncentiveProgramTypeCell,
      },
    ],
    [t],
  )

  const handleRowClick = useCallback((row: Row<IncentiveProgram>) => {
    history.push(generatePath(IncentiveProgramRoutes.Edit, { id: row.original.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CompoundedTable
      columns={allColumns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => {
        listRequested({ page: num })
      }}
      pages={pages}
      onRowClick={handleRowClick}
    />
  )
}

export default IncentiveProgramTable
