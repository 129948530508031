import React, { useCallback, useMemo } from 'react'
import { EMPTY_ARRAY, FilePicker, FilePickerNewFile, useAction } from '@agro-club/frontend-shared'
import * as uuid from 'uuid'
import { MediaPlaceholder } from 'views/ui/Media/Media'
import { useSelector } from 'react-redux'
import UploadManagerActions from 'modules/domain/uploadManager/duck'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { imageFileValidator, ValidationError } from 'helpers/imageFileValidator'
import { remove, move } from 'ramda'
import { FileItem, MimeType } from './types'
import AddHint from './Addhint'
import PdfPreview from './PdfPreview'
import ImagePreview from './ImagePreview'
import { isImageByUrl, isImageByMimeType, isPdfByUrl, isPdfByMimeType } from './helpers'
import { PREVIEW_WIDTH, PREVIEW_HEIGHT, ACCEPTED_MIME_TYPES } from './constants'
import * as Styled from './styled'

const UploadingItem: React.FC<{ file: File; onRemove(): void; id: string }> = ({ file, onRemove, id }) => {
  const uploadingFile = useSelector(UploadManagerSelectors.file(id))
  const cancelUploadAction = useAction(UploadManagerActions.fileUploadCancelled)

  const handleRemove = () => {
    onRemove()
    cancelUploadAction(id)
  }

  if (isImageByMimeType(file.type)) {
    return (
      <ImagePreview
        url={file}
        uploading={uploadingFile?.status === 'uploading'}
        error={uploadingFile?.status === 'error'}
        percent={uploadingFile?.percent}
        onRemove={handleRemove}
      />
    )
  }

  if (isPdfByMimeType(file.type)) {
    return (
      <PdfPreview
        url={file}
        width={PREVIEW_WIDTH}
        height={PREVIEW_HEIGHT}
        percent={uploadingFile?.percent}
        uploading={uploadingFile?.status === 'uploading'}
        error={uploadingFile?.status === 'error'}
        onRemove={onRemove}
      />
    )
  }

  return null
}

const FileManager: React.VFC<{
  items?: FileItem[]
  onChange: (items: FileItem[]) => void
  limit?: number
  accept?: string
  disabled?: boolean
}> = ({ items = EMPTY_ARRAY, onChange, limit = 5, accept = ACCEPTED_MIME_TYPES, disabled }) => {
  const startUploadAction = useAction(UploadManagerActions.fileUploadStarted)
  const renderItem = (item: FileItem | undefined, idx: number) => {
    if (!item) return null
    switch (true) {
      case item.kind === 'current' && typeof item.file === 'string': {
        const onRemove = () => {
          onChange(
            items.map((item, i) => {
              if (i === idx) {
                return {
                  ...item,
                  kind: 'removed',
                }
              }
              return item
            }),
          )
        }
        if (isImageByUrl(item.file as string)) {
          return <ImagePreview url={item.file} onRemove={onRemove} />
        } else if (isPdfByUrl(item.file as string)) {
          return <PdfPreview url={item.file} onRemove={onRemove} />
        }
        return null
      }
      case item.kind === 'added' && item.file instanceof File: {
        const onRemove = () => {
          if (!items) return
          onChange(
            remove(
              items.findIndex(i => i === item),
              1,
              items,
            ),
          )
        }
        return (
          <UploadingItem
            key={`uploading-preview-${item.id}`}
            file={item.file as File}
            onRemove={onRemove}
            id={item.id}
          />
        )
      }
      case item.kind === 'invalid' && item.file instanceof File: {
        const onRemove = () => {
          onChange(
            remove(
              items.findIndex(i => i === item),
              1,
              items,
            ),
          )
        }
        return <ImagePreview key={`invalid-preview-${item.id}`} url={item.file} onRemove={onRemove} error={true} />
      }
      default:
        return null
    }
  }

  const itemsLeft = useMemo(() => {
    return limit - items.filter(item => item.kind !== 'removed').length
  }, [items, limit])

  const fileValidator = (file: File) => {
    if (file.type === MimeType.PDF) {
      return Promise.resolve(null)
    }
    return imageFileValidator(file, 2000, 2000, 3e6)
  }

  const handleChange = useCallback(
    (files?: FilePickerNewFile[]) => {
      if (!files) return

      if (itemsLeft > 0) {
        onChange([
          ...items,
          ...files.slice(0, itemsLeft).map(item => {
            const id = uuid.v4()
            if (item.valid) {
              startUploadAction(id, item.file)
            }
            return {
              file: item.file,
              kind: item.valid ? ('added' as const) : ('invalid' as const),
              id,
              error: item.error as ValidationError,
            }
          }),
        ])
      }
    },
    [items, itemsLeft, onChange, startUploadAction],
  )

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }
      onChange(move(result.source.index, result.destination.index, items))
    },
    [items, onChange],
  )

  return (
    <FilePicker
      addAllowed={items.filter(item => item.kind !== 'removed').length < limit && !disabled}
      multiple
      appendMode
      accept={accept}
      onChange={handleChange}
      fileValidator={fileValidator}
      render={({ onClick, addAllowed }) => (
        <>
          <Styled.Wrapper>
            <Styled.ItemsWrapper>
              {addAllowed ? <MediaPlaceholder onClick={onClick} /> : null}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <Styled.DropContainer
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      itemsLeft={itemsLeft}
                      {...provided.droppableProps}
                    >
                      {items?.map((item, idx) =>
                        item.kind !== 'removed' ? (
                          <Draggable key={item.id} draggableId={item.id} index={idx}>
                            {provided => {
                              return (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  {renderItem(item, idx)}
                                </div>
                              )
                            }}
                          </Draggable>
                        ) : null,
                      )}
                      {provided.placeholder}
                    </Styled.DropContainer>
                  )}
                </Droppable>
              </DragDropContext>
            </Styled.ItemsWrapper>
          </Styled.Wrapper>
          {addAllowed && !disabled ? <AddHint onClick={onClick} limit={limit} /> : null}
        </>
      )}
    />
  )
}

export default FileManager
