import { TableBody } from '@agro-club/frontend-shared'
import React from 'react'
import styled, { css } from 'styled-components'

export const StyledTableHead = styled<React.FC<{ hasMetaHead?: boolean; className?: string }>>(TableBody)`
  position: relative;
  top: -8px;

  //safari workaround since it doesn't support relative position on tr
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      & td {
        position: relative;
        top: -8px;
      }
    }
  }

  ${({ hasMetaHead }) =>
    hasMetaHead &&
    css`
      top: -20px;
    `}
`
