import styled from 'styled-components'
import { IconExit } from '@agro-club/frontend-shared'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Montserrat;
  font-style: normal;
  line-height: 20px;
  margin-right: 16px;
`

export const TruncateWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
`

export const UserInfoWrapper = styled.div`
  padding: 20px 16px 20px 0;
  & > :first-child {
    font-weight: 600;
    font-size: 14px;
  }

  & > :nth-child(2),
  & > :nth-child(3) {
    font-weight: 500;
    font-size: 12px;
    color: ${props => props.theme.color.onPrimaryDark};
    opacity: 0.48;
  }

  align-items: center;
`

export const LogoutButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledExit = styled(IconExit)`
  margin: auto;
  width: 18px;
  height: 18px;
  transition: fill 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    fill: ${props => props.theme.color.primary600};
  }
`
