import { Currency, ROLES } from 'types/entities'
import { Company } from 'modules/domain/company/types'
import { ProductEntry } from './entities'
import { Season } from 'modules/domain/season/types'
import { FileData } from 'views/components/FileManager/types'
import { Promocode } from 'modules/domain/promocode/types'

export enum DistributorOrderStatus {
  New = 'new',
  Changed = 'changed',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Completed = 'completed',
  Deleted = 'deleted',
}

// TODO this Enum should be updated on backend as well
export enum DistributorOrderFilterStatus {
  New = 'new',
  Canceled = 'canceled',
  Changed = 'changed',
  Deleted = 'deleted',
  Confirmed = 'confirmed',
  Completed = 'completed',
}

export type DistributorOrderItem = ProductEntry & {
  discount?: number
  // only for React list key purpose
  key: string
  comment?: string
}

export type DistributorOrder = {
  id: string
  currency: Currency
  status: DistributorOrderStatus
  total_discount?: number
  author_id?: string
  order_source?: string
  last_confirmed_id?: string
  region_id: string | null
  producer_id?: string
  owner_id: string
  producer: Company | null
  distributor?: Company | null
  distributor_id?: string
  billing_company_id?: string
  billing_company?: Company
  purchase_number?: string
  items: DistributorOrderItem[]
  created_at?: string
  order_date?: string
  desired_delivery_date?: string
  delivery_date?: string
  discount?: number
  comment?: string
  slug: string
  interaction: {
    confirmed_by_producer: boolean
    canceled_by_role: ROLES | null
  }
  season_id: string
  season?: Omit<Season, 'is_current'>
  shipping_date?: string
  external_order_id?: string
  files: FileData[]
  total_net?: string
  promocodes: Promocode[]
}
