import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { Progress } from 'modules/types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import {
  NotificationsListEntry,
  NotificationsListRequestFilter,
  NotificationsListRequestSorting,
  NotificationsListState,
} from './types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { UpdateError } from '../types'

const initialState: NotificationsListState = {
  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  removeErrorDetail: null,
  listFetchErrorDetail: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  statuses: [],
  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
  addErrorDetail: null,
  updateErrorDetail: null,
}

class NotificationsListReducer extends BasicEntityImmerReducer<
  NotificationsListEntry,
  NotificationsListRequestFilter,
  NotificationsListRequestSorting,
  {},
  {},
  NotificationsListState
> {
  notificationResendRequested(id: string, _email: string) {
    this.draftState.updateProgress = Progress.WORK
    const meta = {
      id,
      updateProgress: Progress.WORK,
    }
    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
    }
  }
  notificationResendSucceed(item: NotificationsListEntry) {
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }
  notificationResendFailed(id: string, error: UpdateError, errorDetail?: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateErrorDetail = errorDetail || null
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
  }
}

export const NotificationsListActions = createActionCreators(NotificationsListReducer)
export default NotificationsListActions
export const reducer = createReducerFunction(NotificationsListReducer, initialState)
