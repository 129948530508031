import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import TerritoryTable from 'views/pages/Territory/TerritoryTable/TerritoryTable'
import { AddButton, SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import TerritorySelectors from 'modules/domain/territory/selectors'
import TerritoryActions from 'modules/domain/territory/duck'
import styled from 'styled-components'
import TerritoryRoutes from 'views/pages/Territory/routes'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const TerritoryList: React.FC = () => {
  const { t } = useTranslation('territory')
  const filter = useSelector(TerritorySelectors.filter)
  const updateFilterAction = useAction(TerritoryActions.filterUpdated)

  const handleSearchChange = useCallback(
    (search?: string) => {
      updateFilterAction({ title: search })
    },
    [updateFilterAction],
  )

  useHelmet({ title: t('metaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={TerritoryRoutes.Add} />
          </Header.Title>
          <SearchInputWrapper>
            <SearchInput onChange={handleSearchChange} value={filter.title} placeholder={t('list.searchPlaceholder')} />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TerritoryTable />
      </Layout.Content>
    </>
  )
}

export default TerritoryList
