import { FormikHook, SimpleSelect, SwitchButton, useDebounce } from '@agro-club/frontend-shared'
import { useCategorySelectOptions } from 'modules/domain/category/hooks'
import { CategorySelectOptionsRequestFilter } from 'modules/domain/category/types'
import { PromoInfoType } from 'modules/domain/promoInfo/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyType } from 'types/entities'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'
import * as Yup from 'yup'
import * as Styled from './styled'

type ParamsFormProps = {
  categories: string[]
  producers: string[]
  type: PromoInfoType
}

const useCategoryList = (filter: CategorySelectOptionsRequestFilter) => {
  const debouncedFilter = useDebounce(filter, 100)
  const [progress, list = []] = useCategorySelectOptions(debouncedFilter, undefined, 1, 250)
  return [progress, list] as const
}

const CategorySelect: React.VFC<{
  onAdd: (id: string) => void
  onRemove: (id: string) => void
  values: string[]
  invalid?: boolean
  errorText?: string
  companyIds?: string[]
}> = ({ values, invalid, errorText, onAdd, onRemove, companyIds }) => {
  const { t } = useTranslation(['promoInfo', 'labels'])
  const [categoryFilter, setCategoryFilter] = useState<CategorySelectOptionsRequestFilter>({
    title: '',
    seller_ids: companyIds,
    only_children: true,
  })

  useEffect(() => {
    setCategoryFilter(filter => ({ ...filter, seller_ids: companyIds }))
  }, [companyIds])
  const [, options] = useCategoryList(categoryFilter)
  const selectedCategories = useMemo(() => options.filter(o => values.includes(o.id)), [values, options])
  return (
    <Styled.CategoryWrapper data-test-id={'subcategory-select'}>
      <SimpleSelect
        value={null}
        onChange={(v: string) => {
          onAdd(v)
        }}
        invalid={invalid}
        errorText={errorText}
        options={options}
        label={t('labels:category')}
        placeholder={t('promoInfo:form.placeholders.selectCategory')}
        isSearchable={true}
        onSearchChange={(v: string) => setCategoryFilter({ ...categoryFilter, title: v })}
        required
      />
      <Styled.TagList list={selectedCategories} onRemove={tag => onRemove && onRemove(tag.id)} />
    </Styled.CategoryWrapper>
  )
}

const ParamsForm: React.FC<ParamsFormProps & {
  useFormik: FormikHook
}> = ({ producers, categories, type, useFormik }) => {
  const { t } = useTranslation(['promoInfo', 'validation'])
  const [selectedType, setSelectedType] = useState(type)
  const validationSchema = useMemo(() => {
    return Yup.object({
      categories: Yup.array(Yup.string()).min(
        selectedType === PromoInfoType.CatalogCategory ? 1 : 0,
        t('validation:field_required'),
      ),
      producers: Yup.array(Yup.string()),
      type: Yup.string().oneOf(Object.values(PromoInfoType)),
    })
  }, [selectedType, t])
  const formik = useFormik<ParamsFormProps>({
    initialValues: {
      categories,
      producers,
      type,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })

  const add = useCallback(
    (id: string, collection: keyof Pick<ParamsFormProps, 'producers' | 'categories'>) => {
      if (!formik.values[collection]?.includes(id)) {
        formik.setFieldValue(collection, [...formik.values[collection], id])
      }
    },
    [formik],
  )

  const remove = useCallback(
    (id: string, collection: keyof Pick<ParamsFormProps, 'producers' | 'categories'>) => {
      formik.setFieldValue(
        collection,
        formik.values[collection].filter(itemId => itemId !== id),
      )
    },
    [formik],
  )

  const typeOptions = useMemo(
    () => [
      {
        id: PromoInfoType.CartDiscount,
        title: t('form.labels.cartDiscount'),
      },
      {
        id: PromoInfoType.CatalogCategory,
        title: t('form.labels.catalogCategory'),
      },
      // {
      //   id: PromoInfoType.CatalogSubcategory,
      //   title: t('form.labels.catalogSubcategory'),
      // },
      {
        id: PromoInfoType.CatalogProducer,
        title: t('form.labels.catalogProducer'),
      },
    ],
    [t],
  )

  return (
    <Styled.ParamsContainer>
      <SwitchButton
        options={typeOptions}
        value={formik.values.type}
        onChange={(id: PromoInfoType) => {
          formik.setFieldValue('type', id)
          setSelectedType(id)
        }}
      />
      <Styled.SelectsWrapper>
        <CompanyMultiSelect
          isSearchable
          isClearable
          companyType={CompanyType.Producer}
          label={t('form.labels.producer')}
          values={formik.values.producers}
          onChange={v => formik.setFieldValue('producers', v)}
        />
        {[PromoInfoType.CatalogSubcategory, PromoInfoType.CatalogCategory].includes(formik.values.type) && (
          <CategorySelect
            companyIds={formik.values.producers}
            onAdd={id => add(id, 'categories')}
            values={formik.values.categories}
            onRemove={id => remove(id, 'categories')}
            errorText={formik.errors.categories as string}
            invalid={formik.touched.categories && !!formik.errors.categories}
          />
        )}
      </Styled.SelectsWrapper>
    </Styled.ParamsContainer>
  )
}

export default ParamsForm
