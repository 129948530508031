import React, { useState } from 'react'
import { Button, useAction, useFormManager, useHistoryPush } from '@agro-club/frontend-shared'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ProgramSummaryRoutes from '../routes'
import { useProgramSummaryListItem, useProgramSummaryPrograms } from 'modules/domain/programSummary/hooks'
import { useParams } from 'react-router-dom'
import CustomPageTitle from './CustomPageTitle'
import Program from './Program'
import { useTranslation } from 'react-i18next'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { ProgramDTO } from 'modules/domain/programSummary/types'

import ProgramSummaryActions from 'modules/domain/programSummary/duck'
import { Progress } from 'modules/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { groupBy } from 'modules/utils/helpers'

const ProgramSummaryDetail: React.FC = () => {
  const { t } = useTranslation('programSummary')
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const goBack = () => push({ route: ProgramSummaryRoutes.List })
  const formManager = useFormManager<Record<string, ProgramDTO[]>>()
  const [progressSummary, summary] = useProgramSummaryListItem(params.id)
  const [progressPrograms, programs] = useProgramSummaryPrograms(params.id)
  const batchUpdateAction = useAction(ProgramSummaryActions.batchUpdateRequested)

  const [activeProgram, setActiveProgram] = useState('')

  const handleReset = () => formManager.reset()
  const handleSubmit = async () => {
    const [valid] = await formManager.submitAll()
    if (!valid) {
      return
    }
    const changedItems: ProgramDTO[][] = formManager
      .map(v => v)
      .filter((v): v is { values: ProgramDTO[] } => (v as { dirty: boolean }).dirty)
      .map(v => v.values)
    changedItems.forEach(item => {
      return batchUpdateAction(Object.values(item))
    })
  }

  const getPrograms = () => {
    const groupByProgramTitle = groupBy(programs, 'program_title')
    const keys = Object.keys(groupByProgramTitle)
    return keys.map(key => {
      const programProducts: ProgramDTO[] = groupByProgramTitle[key]
      return (
        <Program
          key={key}
          programName={key}
          formManager={formManager}
          programProducts={programProducts}
          active={key === activeProgram}
          onClick={key => setActiveProgram(key)}
        />
      )
    })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  if (progressSummary === Progress.WORK || progressPrograms === Progress.WORK) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title="">
            <CustomPageTitle summary={summary} />
          </Header.Title>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>{getPrograms()}</Layout.Content>
      <StickyFooterLayout.ButtonsFooter>
        <Button disabled={!formManager.dirty} intent={'primary'} onClick={handleSubmit} data-test-id={'save-button'}>
          {t('common:save')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={handleReset}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />
      </StickyFooterLayout.ButtonsFooter>
    </>
  )
}

export default ProgramSummaryDetail
