import React, { useCallback, useMemo, useState } from 'react'
import { DragItemType, MenuOptionType, StorefrontBundle, StorefrontSku } from 'modules/domain/storefront/types'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { StorefrontListContext, useBundleItemDrag, useItemDrag } from 'views/pages/Storefront/hooks'
import { NonDraggable } from './StorefrontList'
import { Checkbox, PrefixInput, SimpleSelect } from '@agro-club/frontend-shared'
import * as Styled from './styled'
import RowMenu from './StorefrontRowMenu'
import { Status } from 'types/entities'
import { SkuIdBold } from './styled'
import { noop } from 'helpers/noop'

const BundleItem: React.FC<{ item: StorefrontSku }> = ({ item }) => {
  const [ref, dragProps] = useBundleItemDrag(item.id)

  return (
    <Styled.BundleRowWrapper ref={ref} isDragging={dragProps.isDragging}>
      <div>
        <Styled.NameText>CS2600 CR-T</Styled.NameText>
        <NonDraggable>
          <SkuIdBold>{item.sku_id}</SkuIdBold>
        </NonDraggable>
      </div>
      <div>
        <SimpleSelect variant="small" options={[]} onChange={noop} />
      </div>
      <Styled.ProductPriceContainer>
        <NonDraggable>
          <PrefixInput value={item.price ?? 0} prefix={'$'} inputSize={'small'} />{' '}
        </NonDraggable>
      </Styled.ProductPriceContainer>
      <Styled.ProductRemoveCell>
        <Styled.ProductRemoveButton>Remove</Styled.ProductRemoveButton>
      </Styled.ProductRemoveCell>
    </Styled.BundleRowWrapper>
  )
}

const Bundle: React.FC<{
  onMoveRow: (bundleId: string, from: number, to: number) => void
  onAddRow: (bundleId: string, productId: string) => void
  onChangeRowMenuOption: (option: MenuOptionType) => void
} & StorefrontBundle> = ({ id, sku_id, price, status, items, onMoveRow, onAddRow, onChangeRowMenuOption }) => {
  const { t } = useTranslation('storefront')
  const [isItemsVisible, setItemsVisibility] = useState(false)
  const [ref, dragProps] = useItemDrag(DragItemType.Bundle, id)

  const itemsCount = useMemo(() => {
    return items.length === 1 ? `1 ${t('list.item')}` : `${items.length} ${t('list.items')}`
  }, [t, items])

  const [dropCollected, dropRef] = useDrop(() => ({
    accept: [DragItemType.BundleItem, DragItemType.Product],
    collect: monitor => {
      const draggedItem: { id: string } | null = monitor.getItem()
      let alreadyAdded = false

      if (draggedItem) {
        alreadyAdded = items.some(item => item.id === draggedItem.id)
      }

      return {
        hoverOverWithProduct: monitor.isOver() && monitor.getItemType() === DragItemType.Product,
        alreadyAdded,
      }
    },
    canDrop: (draggingItem: { id: string }) => items.findIndex(item => item.id === draggingItem.id) === -1,
    drop: (item: { id: string }, monitor) => {
      if (monitor.getItemType() === DragItemType.Product) {
        onAddRow(id, item.id)
      }
    },
  }))

  const moveRow = (from: number, to: number) => {
    onMoveRow(id, from, to)
  }

  const findIndex = (id: number | string) => items.findIndex(item => item.id === id)

  const handleBundleItemsToggle = useCallback(() => {
    setItemsVisibility(!isItemsVisible)
  }, [isItemsVisible])

  const menuOptions = useMemo(() => {
    return [
      {
        id: MenuOptionType.Edit,
        title: t('rowMenu.edit'),
      },
      {
        id: status === Status.Deleted ? MenuOptionType.Restore : MenuOptionType.Remove,
        title: status === Status.Deleted ? t('rowMenu.restore') : t('rowMenu.remove'),
      },
    ]
  }, [status, t])

  return (
    <Styled.BundleWrapper ref={ref} isDragging={dragProps.isDragging}>
      <Styled.BundleHeadingRow>
        <Styled.SkuContainer>
          <NonDraggable>
            <Styled.NameText>
              Bundle “Canola Starter Pack 2021” <Styled.ItemsCount>{itemsCount}</Styled.ItemsCount>
            </Styled.NameText>
          </NonDraggable>
          <Styled.ControlsRow>
            <NonDraggable>
              <SkuIdBold>{sku_id}</SkuIdBold>
            </NonDraggable>
            <Styled.ToggleButton onClick={handleBundleItemsToggle}>
              {isItemsVisible ? t('list.bundleItemsHide') : t('list.bundleItemsShow')}
            </Styled.ToggleButton>
          </Styled.ControlsRow>
        </Styled.SkuContainer>
        <Styled.ProductPriceContainer>
          <NonDraggable>
            <PrefixInput value={price} prefix={'$'} inputSize={'small'} />
          </NonDraggable>
        </Styled.ProductPriceContainer>
        <Styled.ProductAvailabilityContainer>
          <Checkbox onChange={noop} label={t('skuForm.labels.farmerOrder')} isChecked={false} />
          <Checkbox onChange={noop} label={t('skuForm.labels.retailerOrder')} isChecked={false} />
        </Styled.ProductAvailabilityContainer>
        <Styled.ProductInStockContainer>
          <Styled.StyledSwitch on={true} onClick={noop} disabled />
          <RowMenu onChange={onChangeRowMenuOption} options={menuOptions} />
        </Styled.ProductInStockContainer>
      </Styled.BundleHeadingRow>
      {isItemsVisible && (
        <StorefrontListContext.Provider value={{ moveRow, findIndex }}>
          <Styled.BundleItemsContainer ref={dropRef}>
            {items.map(item => (
              <BundleItem key={item.id} item={item} />
            ))}
            <Styled.BundleRowDropZone show={dropCollected.hoverOverWithProduct}>
              {dropCollected.alreadyAdded ? t('dnd.itemAlreadyAdded') : t('dnd.dropBundleItem')}
            </Styled.BundleRowDropZone>
          </Styled.BundleItemsContainer>
        </StorefrontListContext.Provider>
      )}
    </Styled.BundleWrapper>
  )
}

export default Bundle
