import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import SeasonRoutes from './routes'
import SeasonAdd from './SeasonAdd'
import SeasonEdit from './SeasonEdit'
import SeasonList from './SeasonList'

const ProductPage: React.FC = () => {
  return (
    <Switch>
      <Route path={SeasonRoutes.List} exact={true}>
        <SeasonList />
      </Route>
      <Route path={SeasonRoutes.Edit} exact={true}>
        <SeasonEdit />
      </Route>
      <Route path={SeasonRoutes.Add} exact={true}>
        <SeasonAdd />
      </Route>
      <Redirect to={SeasonRoutes.List} />
    </Switch>
  )
}

export default ProductPage
