import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import IncentiveCampaignActions from './duck'
import IncentiveCampaignSelectors from './selectors'
import { IncentiveCampaign } from './types'
import managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import IncentiveCampaignRoutes from 'views/pages/IncentiveCampaign/IncentiveCampaign/routes'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'

export const fetchList = function*() {
  try {
    let currentPage = yield select(IncentiveCampaignSelectors.page)
    const filter = yield select(IncentiveCampaignSelectors.filter)
    const sorting = yield select(IncentiveCampaignSelectors.sorting)
    const pageSize = yield select(IncentiveCampaignSelectors.pageSize)

    let response: ListResponse<IncentiveCampaign> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(IncentiveCampaignActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, IncentiveCampaignRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(IncentiveCampaignSelectors.page)
    const filter = yield select(IncentiveCampaignSelectors.filter)
    const sorting = yield select(IncentiveCampaignSelectors.sorting)
    const pageSize = yield select(IncentiveCampaignSelectors.pageSize)
    const { data, total_count }: { data: IncentiveCampaign[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(IncentiveCampaignActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof IncentiveCampaignActions.itemRequested>) {
  try {
    const item: IncentiveCampaign = yield call(managers.getItem, id)
    yield put(IncentiveCampaignActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof IncentiveCampaignActions.addRequested>) {
  try {
    const item: IncentiveCampaign = yield call(managers.addItem, dto)
    yield put(IncentiveCampaignActions.addSucceed(item))
    yield put(push(generatePath(IncentiveCampaignRoutes.Edit, { id: item.id })))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function*({
  payload: [id, dto],
}: ReturnType<typeof IncentiveCampaignActions.updateRequested>) {
  try {
    const item: IncentiveCampaign = yield call(managers.updateItem, id, dto)
    yield put(IncentiveCampaignActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof IncentiveCampaignActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(IncentiveCampaignActions.removeSucceed(payload))
    yield put(push(IncentiveCampaignRoutes.List))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(IncentiveCampaignActions.removeFailed(payload, type, detail))
  }
}

const IncentiveCampaignSaga = function*() {
  yield all([
    takeLatest(IncentiveCampaignActions.itemRequested.type, fetchItem),
    takeLatest(IncentiveCampaignActions.listRequested.type, fetchList),
    takeLatest(IncentiveCampaignActions.filterUpdated.type, fetchList),
    takeLatest(IncentiveCampaignActions.sortingUpdated.type, fetchList),
    takeLatest(IncentiveCampaignActions.filterHasBeenReset.type, fetchList),
    takeLatest(IncentiveCampaignActions.sortingHasBeenReset.type, fetchList),

    takeLatest(IncentiveCampaignActions.listRequestedNext.type, fetchListNext),

    takeLatest(IncentiveCampaignActions.addRequested.type, addItem),
    takeLatest(IncentiveCampaignActions.updateRequested.type, updateItem),
    takeLatest(IncentiveCampaignActions.removeRequested.type, removeItem),
  ])
}

export default IncentiveCampaignSaga
