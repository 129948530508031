import { ActionCreators } from 'immer-reducer'
import SnackbarActions from 'modules/domain/snackbar/duck'
import { Entity } from 'modules/domain/types'
import { RequestError } from 'modules/errors'
import { PersistentFiltersFieldsType } from 'modules/types'
import { call, Effect, put, select } from 'redux-saga/effects'
import { ListResponse } from 'types/api'
import { changePersistentFilters, getAllPersistentFilters } from '../helpers'
import { BasicEntityImmerReducer } from './BasicEntityImmerReducer'
import { CreateBasicManagersReturnType } from './createBasicManagers'
import { CreateBasicSelectorsReturnType } from './createBasicSelectors'

interface ClassToType<T> extends Function {
  new (...args: any[]): T
}

export type CreateBasicSagasProps<
  EntityType extends Entity,
  FilterType extends {},
  SortType extends {},
  CreateDtoType extends Partial<Entity>,
  UpdateDtoType extends Partial<Entity>,
  CreateActionDataType extends Partial<Entity> | undefined = undefined,
  UpdateActionDataType extends Partial<Entity> | undefined = undefined,
  //For internal use only
  Actions extends ActionCreators<
    ClassToType<
      BasicEntityImmerReducer<
        EntityType,
        FilterType,
        SortType,
        CreateActionDataType extends Partial<Entity> ? CreateActionDataType : CreateDtoType,
        UpdateActionDataType extends Partial<Entity> ? UpdateActionDataType : UpdateDtoType
      >
    >
  > = ActionCreators<
    ClassToType<
      BasicEntityImmerReducer<
        EntityType,
        FilterType,
        SortType,
        CreateActionDataType extends Partial<Entity> ? CreateActionDataType : CreateDtoType,
        UpdateActionDataType extends Partial<Entity> ? UpdateActionDataType : UpdateDtoType
      >
    >
  >
> = {
  managers: CreateBasicManagersReturnType<FilterType, SortType, EntityType, CreateDtoType, UpdateDtoType>
  selectors: CreateBasicSelectorsReturnType<EntityType, FilterType, SortType>
  actions: Actions
  onListRequestSucceed?: (...args: Parameters<Actions['listRequestSucceed']>) => Generator<Effect<any>> | void
  onAddItemSucceed?: (duplicate?: boolean, ...args: Parameters<Actions['addSucceed']>) => Generator<Effect<any>> | void
  onUpdateItemSucceed?: (
    duplicate?: boolean,
    ...args: Parameters<Actions['updateSucceed']>
  ) => Generator<Effect<any>> | void
  onRemoveItemSucceed?: (...args: Parameters<Actions['removeRequested']>) => Generator<Effect<any>> | void
  usePersistentStorage?: keyof PersistentFiltersFieldsType | boolean
}

export const createBasicSagas = <
  EntityType extends Entity,
  FilterType extends {},
  SortType extends {},
  CreateDtoType,
  UpdateDtoType,
  CreateActionDataType = undefined,
  UpdateActionDataType = undefined
>({
  managers,
  selectors,
  actions,
  onListRequestSucceed,
  onAddItemSucceed,
  onUpdateItemSucceed,
  onRemoveItemSucceed,
  usePersistentStorage,
}: CreateBasicSagasProps<
  EntityType,
  FilterType,
  SortType,
  CreateDtoType,
  UpdateDtoType,
  CreateActionDataType,
  UpdateActionDataType
>) => {
  const persistentFilterScope = usePersistentStorage
    ? typeof usePersistentStorage === 'boolean'
      ? 'global'
      : usePersistentStorage
    : undefined

  const fetchList = function*(props) {
    try {
      let currentPage = yield select(selectors.page)
      let filter = yield select(selectors.filter)
      const sorting = yield select(selectors.sorting)
      const pageSize = yield select(selectors.pageSize)

      if (persistentFilterScope) {
        changePersistentFilters(props.type, props.payload)
        const persistentFilters = getAllPersistentFilters(persistentFilterScope)
        filter = {
          ...persistentFilters,
          ...filter,
        }
        yield put(actions.filtersUpdatedWithPersistentStorage(filter))
      }

      let response: ListResponse<EntityType> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
      const pages = Math.ceil(response.total_count / pageSize)
      if (pages !== 0 && pages < currentPage) {
        response = yield call(managers.getList, filter, sorting, pages, pageSize)
        currentPage = pages
      }

      yield put(actions.listRequestSucceed(response))
      if (onListRequestSucceed) yield call(onListRequestSucceed, response)
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)

      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))

      yield put(actions.listRequestFailed(type, detail))
    }
  }

  const fetchListNext = function*() {
    try {
      const page = yield select(selectors.page)
      const filter = yield select(selectors.filter)
      const sorting = yield select(selectors.sorting)
      const pageSize = yield select(selectors.pageSize)
      const response: ListResponse<EntityType> = yield call(managers.getList, filter, sorting, page, pageSize)
      const { data, total_count } = response
      yield put(actions.listRequestNextSucceed(data, total_count, response))
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)

      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))

      yield put(actions.listRequestNextFailed(type, detail))
    }
  }

  const fetchItem = function*({ payload: id }: ReturnType<typeof actions.itemRequested>) {
    try {
      const entity: EntityType = yield call(managers.getItem, id)
      yield put(actions.itemRequestSucceed(entity))
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)

      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))
      yield put(actions.itemRequestFailed(id, type, detail))
    }
  }

  const addItem = function*({ payload }: ReturnType<typeof actions.addRequested>) {
    try {
      let duplicate = false
      let dto

      if (Array.isArray(payload)) {
        dto = payload[0]
        duplicate = payload[1]
      } else {
        dto = payload
      }

      const item: EntityType = yield call(managers.addItem, dto)
      yield put(actions.addSucceed(item))
      if (onAddItemSucceed) yield call(onAddItemSucceed, duplicate, item)
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)
      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))
      yield put(actions.addFailed(type, detail))
    }
  }
  const updateItem = function*({ payload: [id, dto, duplicate] }: ReturnType<typeof actions.updateRequested>) {
    try {
      const item: EntityType = yield call(managers.updateItem, id, dto)
      yield put(actions.updateSucceed(id, item))

      if (onUpdateItemSucceed) yield call(onUpdateItemSucceed, duplicate, id, item)
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)
      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))
      yield put(actions.updateFailed(id, type, detail))
    }
  }

  const removeItem = function*({ payload }: ReturnType<typeof actions.removeRequested>) {
    try {
      yield call(managers.removeItem, payload)
      yield put(actions.removeSucceed(payload))
      if (onRemoveItemSucceed) yield call(onRemoveItemSucceed, payload)
    } catch (err) {
      const { type, detail } = RequestError.parseError(err)
      yield put(SnackbarActions.open({ content: detail, severity: 'error' }))
      yield put(actions.removeFailed(payload, type, detail))
    }
  }

  return {
    fetchList,
    fetchListNext,
    fetchItem,
    addItem,
    updateItem,
    removeItem,
  }
}
