import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/components/OrderProductItems/styles'
import {
  SectionBody,
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTitle,
} from '@agro-club/frontend-shared'
import { ReturnDeclarationSkuItem } from 'modules/domain/returnDeclarationSku/types'
import { CardSelect } from 'views/components/CardSelect/CardSelect'
import { StorefrontCardForSelect } from 'modules/domain/storefrontCard/types'
import { AvailableFor, SkuOption, StorefrontItem, StorefrontSku } from 'modules/domain/storefront/types'
import { ReturnSkuCardRow } from './ReturnSkuCardRow'
import { isAgro, Status } from 'types/entities'
import styled from 'styled-components'
import { useRole } from 'modules/permissions/permissions'

export type ReturnItemsSkuFormProps = {
  sku_items: ReturnDeclarationSkuItem[]
}

const SwitchSkusStatus = styled.span`
  position: absolute;
  top: 26px;
  right: 24px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.primary600};
  user-select: none;
`

const ReturnSkuItems: React.FC<{
  isAllowed: boolean
  skuMatchingErrors: boolean[]
  cardSelectError: string | null
  title?: string
  skuItems?: ReturnDeclarationSkuItem[]
  nothingToReturn?: boolean
  sellerId?: string
  onMenuClose?: () => void
  onCardSelect: (id: string | undefined, card: StorefrontCardForSelect | null) => void
  onPackageTypeChange: (idx: number, value: string) => void
  onOptionsChange: (idx: number, value: SkuOption[]) => void
  onQtyInputChange: (idx: number, value: number) => void
  onSkuMatched: (idx: number, value?: StorefrontSku) => void
  onRemove: (idx: number) => void
}> = ({
  isAllowed,
  skuMatchingErrors,
  cardSelectError,
  title,
  skuItems = [],
  nothingToReturn = false,
  sellerId,
  onMenuClose,
  onCardSelect,
  onPackageTypeChange,
  onOptionsChange,
  onQtyInputChange,
  onSkuMatched,
  onRemove,
  children,
}) => {
  const { t } = useTranslation(['farmerOrder', 'returnDeclaration', 'common'])
  const role = useRole()
  const isAdmin = isAgro(role)
  const [showInactiveSkus, setShowInactiveSkus] = useState<boolean>(false)
  const cardAndSkuStatus = useMemo(() => (showInactiveSkus ? [Status.Active, Status.Inactive] : Status.Active), [
    showInactiveSkus,
  ])
  const [skuMap, setSkuMap] = useState<Map<string, StorefrontItem[]>>(new Map<string, StorefrontItem[]>())

  const handleFetchSkuList = useCallback(
    (key: string, skus: StorefrontItem[]) => {
      const updatedMap = skuMap?.set(key, skus)
      setSkuMap(updatedMap)
    },
    [skuMap],
  )

  return (
    <>
      <Styled.Content isAllowed={isAllowed}>
        <SectionTitle>{title || t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
        {isAdmin && (
          <SwitchSkusStatus
            data-test-id="switch-skus-status-button"
            onClick={() => setShowInactiveSkus(!showInactiveSkus)}
          >
            {showInactiveSkus ? t('hideInactive') : t('showInactive')}
          </SwitchSkusStatus>
        )}
        <SectionBody>
          <CardSelect
            isDisabled={!sellerId}
            availableFor={AvailableFor.DistributorOrder}
            isSearchable
            required
            onChange={onCardSelect}
            onMenuClose={onMenuClose}
            label={t('form.labels.product')}
            placeholder={t('form.placeholders.addProduct')}
            invalid={cardSelectError !== null}
            errorText={cardSelectError || ''}
            sellerId={sellerId}
            status={cardAndSkuStatus}
            skuStatus={cardAndSkuStatus}
            controlShouldRenderValue={false}
          />
          {skuItems.length !== 0 ? (
            <SectionTable data-test-id={'product-items'}>
              <SectionTableHead>
                <SectionTableHeadRow>
                  <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                  <SectionTableHeadCell>{t('returnDeclaration:form.labels.lotNumber')}</SectionTableHeadCell>
                  <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
                  <SectionTableHeadCell />
                </SectionTableHeadRow>
              </SectionTableHead>
              <SectionTableBody>
                {skuItems.map((item: ReturnDeclarationSkuItem, idx: number) => {
                  return (
                    <ReturnSkuCardRow
                      key={item.key || item.id}
                      onSkuMatched={value => onSkuMatched(idx, value)}
                      onOptionsChange={value => onOptionsChange(idx, value)}
                      onPackageTypeChange={value => onPackageTypeChange(idx, value)}
                      onQtyInputChange={value => onQtyInputChange(idx, value)}
                      onRemove={() => onRemove(idx)}
                      item={item}
                      rowIndex={idx}
                      sellerId={sellerId}
                      skus={skuMap?.get(`${sellerId}-${item.product_card_id}`)}
                      onFetchSkuList={handleFetchSkuList}
                      skuMatchingErrors={skuMatchingErrors}
                      showInactiveSkus={showInactiveSkus}
                    />
                  )
                })}
              </SectionTableBody>
            </SectionTable>
          ) : null}
        </SectionBody>
      </Styled.Content>
      {children}
      {!isAllowed && <Styled.Overlay>{t('form.addProductsOverlayText')}</Styled.Overlay>}
      {nothingToReturn && <Styled.Overlay>{t('returnDeclaration:form.nothingToReturn')}</Styled.Overlay>}
    </>
  )
}

export default ReturnSkuItems
