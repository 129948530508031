import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/User/UserDetailsForm/types'
import { Progress } from 'modules/types'
import * as Styled from 'views/pages/User/UserDetailsForm/styled'
import {
  Button,
  Snackbar,
  helpersPassword,
  IconMagicWand,
  IconRefresh,
  Input,
  Spinner,
  useAction,
  useBoolean,
} from '@agro-club/frontend-shared'
import UserActions from 'modules/domain/user/duck'
import { useParams } from 'react-router-dom'
import UserSelectors from 'modules/domain/user/selectors'

const PasswordBlock: React.FC<{ onResetPassword?(): void; required: boolean; isEditableUserRoleAdmin?: boolean }> = ({
  onResetPassword,
  required,
  isEditableUserRoleAdmin,
}) => {
  const { t } = useTranslation(['user', 'common'])
  const params = useParams<{ id: string }>()
  const resetProgress = useSelector(AuthSelectors.getResetPasswordProgress)
  const changePasswordAction = useAction(UserActions.changePasswordRequested)
  const changePasswordProgess = useSelector(UserSelectors.changePasswordProgress)
  const resetChangePasswordProgress = useAction(UserActions.resetChangePasswordProgress)
  const isChangePasswordProgressInProcess = changePasswordProgess === Progress.WORK

  const formik = useFormikContext<FormFields>()
  const [passwordVisible, setPasswordVisible] = useBoolean(false)

  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const handleClick = () => {
    formik.setFieldValue('password', helpersPassword.pwgen())
    setPasswordVisible(true)
  }

  const handleChangePassword = () => {
    changePasswordAction(params.id, formik.values.password)
    formik.setFieldValue('password', '')
  }

  const resetButton =
    onResetPassword &&
    (resetProgress === Progress.SUCCESS ? (
      <Styled.ResetPasswordLinkSent data-test-id={'reset-password-link-sent-text'}>
        {t('form.resetPasswordLinkSent', { email: formik.initialValues.email })}
      </Styled.ResetPasswordLinkSent>
    ) : (
      <Styled.PasswordButton onClick={onResetPassword}>
        {resetProgress === Progress.IDLE ? (
          <>
            <IconRefresh />
            {t('form.resetPassword')}
          </>
        ) : resetProgress === Progress.WORK ? (
          <Spinner size={'small'} />
        ) : (
          <Styled.ResetPasswordFailed>{t('form.resetPasswordFailed')}</Styled.ResetPasswordFailed>
        )}
      </Styled.PasswordButton>
    ))

  const changePasswordBlock = (
    <>
      {isAdmin && resetButton && !isEditableUserRoleAdmin && (
        <>
          {changePasswordProgess === Progress.SUCCESS ? (
            <Styled.ChangePasswordInput>
              <Styled.ResetPasswordLinkSent>{t('form.changePasswordSucceed')}</Styled.ResetPasswordLinkSent>
            </Styled.ChangePasswordInput>
          ) : (
            <>
              <Styled.ChangePasswordInput>
                <Input
                  label={t('common:password')}
                  {...formik.getFieldProps('password')}
                  style={{ gridArea: 'password' }}
                  invalid={formik.touched.password && !!formik.errors.password}
                  errorText={formik.errors.password}
                  autoComplete={'new-password'}
                  type={'password'}
                  passwordVisible={passwordVisible}
                  onPasswordVisibleChange={val => setPasswordVisible(val)}
                />
              </Styled.ChangePasswordInput>
              <Styled.ChangePasswordButton>
                <Styled.PasswordButton>
                  <Button
                    intent={'primary'}
                    onClick={handleChangePassword}
                    disabled={!formik.values.password || isChangePasswordProgressInProcess || !!formik.errors.password}
                  >
                    {t('common:changePassword')}
                  </Button>
                </Styled.PasswordButton>
              </Styled.ChangePasswordButton>
            </>
          )}
        </>
      )}
    </>
  )

  return (
    <>
      {changePasswordBlock}
      <Styled.PasswordArea>
        {!resetButton ? (
          <Input
            label={t('common:password')}
            {...formik.getFieldProps('password')}
            style={{ gridArea: 'password' }}
            invalid={formik.touched.password && !!formik.errors.password}
            errorText={formik.errors.password}
            autoComplete={'new-password'}
            type={'password'}
            passwordVisible={passwordVisible}
            onPasswordVisibleChange={val => setPasswordVisible(val)}
            required={required}
          />
        ) : (
          resetButton
        )}
      </Styled.PasswordArea>
      <Styled.PasswordSettingsArea>
        {!resetButton && (
          <Styled.PasswordButton onClick={handleClick}>
            <IconMagicWand />
            {t('form.generatePassword')}
          </Styled.PasswordButton>
        )}
      </Styled.PasswordSettingsArea>
      {changePasswordProgess === Progress.ERROR && (
        <Snackbar onClose={() => resetChangePasswordProgress()}>{t('ChangePasswordErrorNotification:text')}</Snackbar>
      )}
    </>
  )
}

export default PasswordBlock
