import { CompanyType, Permissions, ROLES } from 'types/entities'

export const getRoleName = (role: ROLES): CompanyType | null => {
  if (role === ROLES.USER) {
    return CompanyType.User
  }
  const roleName = role.split(':')[0]
  if (!roleName) {
    return null
  }

  return roleName as CompanyType
}
export const getPermissionLevel = (role: ROLES): Permissions | null => {
  const permission = role.split(':')[1]
  if (!permission) {
    return null
  }

  return permission as Permissions
}
