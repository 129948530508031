import { useAction } from '@agro-club/frontend-shared'
import SnackbarActions from 'modules/domain/snackbar/duck'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

function useValidationErrorNotification(formSubmitCount: number, isFormValid: boolean, errorDetail?: string | null) {
  const { t } = useTranslation('ValidationErrorNotification')
  const submitCount = useRef(formSubmitCount)
  const valid = useRef(isFormValid)
  const openSnackbar = useAction(SnackbarActions.open)
  const closeSnackbar = useAction(SnackbarActions.close)
  const [errorDetailState, setErrorDetailState] = useState('')

  useEffect(() => {
    if (formSubmitCount && submitCount.current !== formSubmitCount && !isFormValid) {
      openSnackbar({
        content: errorDetailState || t('text'),
      })
      valid.current = false
      submitCount.current = formSubmitCount

      const firstInvalidField = document.querySelector('[data-field-invalid="true"]')
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }

    if (isFormValid && !valid.current) {
      closeSnackbar()
      valid.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitCount, isFormValid])

  useEffect(() => {
    if (errorDetail && submitCount.current !== formSubmitCount && isFormValid) {
      submitCount.current = formSubmitCount
      setErrorDetailState(errorDetail)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDetail])
}

export default useValidationErrorNotification
