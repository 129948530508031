import { ImmerReducer } from 'immer-reducer'
import { AddError, Entity, EntityState, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { castDraft } from 'immer'
import { ListResponse } from 'types/api'
export class BasicEntityImmerReducer<
  EntityType extends Entity,
  EntityListFilter,
  EntityListSorting,
  EntityAddDTO,
  EntityEditDTO,
  State extends EntityState<EntityType, EntityListFilter, EntityListSorting> = EntityState<
    EntityType,
    EntityListFilter,
    EntityListSorting
  >
> extends ImmerReducer<State> {
  listUpdateRequested() {}
  listRequested(params: { filter?: EntityListFilter; sorting?: EntityListSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = null
    this.draftState.listFetchError = null
    this.draftState.filter = castDraft(params.filter) || this.draftState.filter
    this.draftState.sorting = castDraft(params.sorting) || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(response: ListResponse<EntityType>) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = castDraft(arrToDict(response.data))
    this.draftState.meta = arrToDict(
      response.data.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(response.data)
    this.draftState.total = response.total_count
    this.draftState.page = response.page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail || null
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: EntityType) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.items[item.id] = castDraft(item)
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.itemFetchErrorDetail = errorDetail || null
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  addRequested(_: EntityAddDTO, _duplicate: boolean) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = null
  }

  addSucceed(item: EntityType) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = castDraft(item)
    this.draftState.addError = null
    this.draftState.addErrorDetail = null
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail || null
  }

  updateRequested(id: string, _: EntityEditDTO, _duplicate: boolean) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id] = {
      ...this.draftState.meta[id],
      updateProgress: Progress.WORK,
      updateError: null,
      updateErrorDetail: undefined,
    }
  }
  updateSucceed(id: string, item: EntityType) {
    this.draftState.items[id] = { ...this.draftState.items[id], ...item }
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[id] = {
      ...this.draftState.meta[id],
      updateProgress: Progress.SUCCESS,
      updateError: null,
      updateErrorDetail: undefined,
    }
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorDetail?: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id] = {
      ...this.draftState.meta[id],
      updateProgress: Progress.ERROR,
      updateError: error,
      updateErrorDetail: errorDetail,
    }
    this.draftState.updateErrorDetail = errorDetail || null
  }

  filterUpdated(filter: EntityListFilter) {
    this.draftState.filter = castDraft(filter)
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: EntityListFilter) {
    this.draftState.filter = castDraft({ ...this.state.filter, ...filter })
  }

  sortingUpdated(sorting: EntityListSorting) {
    this.draftState.sorting = castDraft(sorting)
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = castDraft<Partial<EntityListFilter>>({})
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = castDraft<Partial<EntityListSorting>>({})
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: EntityType[], total: number, _response: ListResponse<any>) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...castDraft(arrToDict(list)) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestNextFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
    this.draftState.listFetchNextErrorDetail = errorDetail || null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.removeErrorDetail = errorDetail || null
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
  }
}
