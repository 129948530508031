import {
  IconWarning,
  ProductWizardType,
  ProcoteWizardData,
  Progress,
  SectionTableBodyCell,
  SectionTableBodyRow,
  TextArea,
  OptionalNumber,
  NumberInput,
} from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { DistributorOrderSkuItem } from 'modules/domain/distributorOrderSku/types'
import { DocumentItem } from 'modules/domain/document/types'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { useCardSkus } from 'modules/domain/storefront/hooks'
import { AvailableFor, SkuOption, StorefrontItem, StorefrontSku } from 'modules/domain/storefront/types'
import { arrToDict, getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import * as Styled from 'views/components/OrderProductItems/styles'
import { DuckFootWizardData } from '../ProductWizards/ProductWizardDuckFoot/DuckFootWizardData'
import { SkuOptionsMatcher } from './SkuOptionsMatcher'
import { ProductLimit } from 'modules/domain/allocation/common/types'
import {
  useMaxQty,
  useProductAllocatedLimit,
  useProductQtyAllocatedChecks,
  useQty,
} from 'modules/domain/allocation/hooks'
import { Status } from 'types/entities'
import { getStandardUnitsQty } from './helpers'
import { StorefrontCard } from 'modules/domain/storefrontCard/types'
import { OutOfStock } from 'modules/domain/storefrontCard/helpers'

export type OrderSkuCardRowProps = {
  item: FarmerOrderSkuItem | DistributorOrderSkuItem
  rowIndex: number
  sellerId?: string
  context: 'farmer' | 'distributor'
  documents?: DocumentItem[]
  onRequiredDocumentClick?: (id: string) => void
  mode: 'create' | 'edit'
  isSkuEditingExpired: (sku?: StorefrontSku) => boolean
  isCardEditingAllowed: boolean
  onFinalQtyChange: (qty: number) => void
  onShippedQtyChange: (qty: number) => void
  onQtyInputChange: (qty: number) => void
  onDeliveredQtyChange: (qty: number) => void
  onPackageTypeChange: (value: string) => void
  onOptionsChange: (value: SkuOption[]) => void
  onSkuMatched: (value?: StorefrontSku) => void
  onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onRemove: () => void
  skuMatchingErrors: boolean[]
  showErrors?: boolean
  showFinalQty?: boolean
  showDeliveredQty?: boolean
  showComment?: boolean
  showRemoveButton: boolean
  showFormattedQuantity?: boolean
  hasPrices?: boolean
  showUnits?: boolean
  skus?: StorefrontItem[]
  onFetchSkuList?: (key: string, skus: StorefrontItem[]) => void
  itemCommonQty: number
  itemCommonInitialQty: number
  visibilityConfig: {
    showDeliveredQty?: boolean
    showFinalQty?: boolean
    showShippedQty?: boolean
    showUnits: boolean
    showComment: boolean
    showPrices: boolean
    showInactiveSkus: boolean
  }
  isAdmin: boolean
  productsLimit: ProductLimit[]
  isQtyInputDisabled: boolean
}

const noWrapStyle: React.CSSProperties = { whiteSpace: 'nowrap' }

const WizardInfo = styled.div`
  margin-top: 8px;
`

const SkuOptionsMatcherWrapper = styled.div`
  margin-top: 16px;
`

export const OrderSkuCardRow: React.VFC<OrderSkuCardRowProps> = ({
  item,
  rowIndex,
  sellerId,
  context,
  documents,
  onRequiredDocumentClick,
  mode,
  isSkuEditingExpired,
  isCardEditingAllowed,
  skuMatchingErrors,
  showErrors,
  showRemoveButton,
  onQtyInputChange,
  onFinalQtyChange,
  onShippedQtyChange,
  onDeliveredQtyChange,
  onPackageTypeChange,
  onOptionsChange,
  onSkuMatched,
  onCommentChange,
  onRemove,
  skus: skusFromProps = [],
  onFetchSkuList,
  itemCommonQty,
  itemCommonInitialQty,
  visibilityConfig: { showPrices, showComment, showDeliveredQty, showFinalQty, showInactiveSkus, showShippedQty },
  isAdmin,
  productsLimit,
  isQtyInputDisabled,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common'])
  const { pick } = useLangPicker()
  const docId = item.sku?.product?.docusign_document_id
  const needDocument =
    context === 'distributor'
      ? false
      : !documents?.length && docId
      ? true
      : docId && documents?.find(doc => doc.id === docId && doc.status !== 'signed')
  const [packageType, setPackageType] = useState(item.sku?.params.package_id)
  const handleDocWarningClick = () => onRequiredDocumentClick && docId && onRequiredDocumentClick(docId)

  const productAllocatedLimit = useProductAllocatedLimit(item, productsLimit)
  const {
    isProductQtyMoreThanLimit,
    isProductDifferenceQtyMoreThanLimit,
    isProductQtyEqualLimit,
  } = useProductQtyAllocatedChecks(item, itemCommonQty, itemCommonInitialQty, mode, productsLimit)

  const maxProductQty = useMaxQty(item, mode, itemCommonQty, itemCommonInitialQty, isAdmin, productsLimit)

  const itemQty = OptionalNumber(item.quantity)
  const itemMinQty = context === 'distributor' && isAdmin ? 0 : OptionalNumber(item.sku?.min_qty)
  const itemDeliveredQty = OptionalNumber(item.delivered_qty) || 0
  const itemFinalQty = OptionalNumber(item.final_qty) || 0
  const itemShippedQty = OptionalNumber(item.shipped_qty) || 0

  const qty = useQty(
    mode,
    itemQty,
    isAdmin,
    isProductDifferenceQtyMoreThanLimit,
    isProductQtyMoreThanLimit,
    productAllocatedLimit,
  )

  if (qty !== itemQty) onQtyInputChange(qty)

  const showAllocationLimitWarning =
    (mode === 'create' && isProductQtyMoreThanLimit) ||
    (mode === 'edit' && productAllocatedLimit?.is_limit_exceeded) ||
    isProductDifferenceQtyMoreThanLimit

  const card = (item.card || item.sku_card) as StorefrontCard
  const cardTitle = `${pick(card?.subcategory?.title_i18n)} / ${pick(card?.title_i18n)}`
  const skuIsOutOfStock = isSkuEditingExpired(item.sku)
  const skuFilter = useMemo(
    () => ({
      status: [Status.Active, Status.Inactive],
    }),
    [],
  )

  const [skuListProgress, skuList] = useCardSkus(!!skusFromProps.length, card.id, skuFilter)

  const skus = useMemo(() => {
    let data = skusFromProps.length ? skusFromProps : skuList

    if (!showInactiveSkus) {
      data = data.filter(sku => sku.status === Status.Active || sku.sku_id === item.sku?.sku_id)
    }

    if (!isAdmin || (isAdmin && !showInactiveSkus)) {
      const scope = context === 'distributor' ? AvailableFor.DistributorOrder : AvailableFor.FarmerOrder
      data = data.filter(sku => sku.available_for?.includes(scope) || sku.sku_id === item.sku?.sku_id)
    }

    return data
  }, [context, isAdmin, item.sku, showInactiveSkus, skuList, skusFromProps])

  useEffect(() => {
    if (skuListProgress === Progress.SUCCESS && skuList.length && !skusFromProps.length) {
      const cardId = item.card?.id || item.card_id
      const key = `${sellerId}-${cardId}`
      onFetchSkuList?.(key, skuList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId, skuList, skuListProgress, skusFromProps.length, item.card?.id, item.card_id, context])

  const packageTypes = useMemo(() => {
    const result: PackageTypes[] = []
    skus.forEach(sku => {
      const founded = result.find(p => p?.id === sku.params.package_id)
      if (!founded) result.push((sku as StorefrontSku).params.package as PackageTypes)
    })

    return result
  }, [skus])

  const packageTypeDict = useMemo(() => arrToDict(packageTypes), [packageTypes])

  const handlePackageTypeChange = useCallback(
    value => {
      onPackageTypeChange(value)
      setPackageType(value)
      if (item.sku?.params.options?.length) {
        onOptionsChange([])
      }
    },
    [item.sku?.params.options?.length, onOptionsChange, onPackageTypeChange],
  )

  const getFormattedQty = useCallback(
    (qty: number) => {
      const packageTypeObj = packageType ? packageTypeDict[packageType] : undefined
      return getStandardUnitsQty(qty, item.sku?.product?.units, packageTypeObj)
    },
    [packageType, packageTypeDict, item.sku?.product?.units],
  )

  return (
    <SectionTableBodyRow data-test-id={`product-row-${rowIndex}`}>
      <SectionTableBodyCell>
        <Styled.CardTitle>
          {cardTitle}
          <OutOfStock>{card.is_out_of_stock && t('cards:form.labels.outOfStock')}</OutOfStock>
          <WizardInfo>
            {item.wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
            {item.wizard_data?.type === ProductWizardType.DuckFoot && item.wizard_comment && (
              <DuckFootWizardData note={item.wizard_comment} />
            )}
          </WizardInfo>
          <SkuOptionsMatcherWrapper>
            <SkuOptionsMatcher
              availableSku={skus as StorefrontSku[]}
              availablePackageTypes={packageTypes}
              progress={skuListProgress}
              packageType={packageType}
              sku={item.sku}
              onSkuMatched={onSkuMatched}
              onPackageTypeChange={handlePackageTypeChange}
              optionsRequired={!skuMatchingErrors[rowIndex]}
              showErrors={showErrors}
              disabled={(!isCardEditingAllowed || skuIsOutOfStock) && mode === 'edit'}
            />
            {skuIsOutOfStock && (
              <Styled.OutOfStock data-test-id="out-of-stock-sku">{t('form.errors.outOfStock')}</Styled.OutOfStock>
            )}
          </SkuOptionsMatcherWrapper>
        </Styled.CardTitle>
        {needDocument ? (
          <Styled.ContractWarning onClick={handleDocWarningClick} data-test-id={'sku-row-contract-warning'}>
            <IconWarning /> {t('contractWarningText')}
          </Styled.ContractWarning>
        ) : null}
        {showAllocationLimitWarning && (
          <Styled.Warning data-test-id={'product-limit-row-warning'}>
            <IconWarning /> {t('productWarningText')}
          </Styled.Warning>
        )}
      </SectionTableBodyCell>
      {showPrices && (
        <SectionTableBodyCell style={noWrapStyle}>
          {getPrettyPrice(Number(item.sku?.price), item.sku?.product?.currency)}
        </SectionTableBodyCell>
      )}
      <SectionTableBodyCell style={noWrapStyle}>
        <Styled.OrderQtyCell>
          <NumberInput
            value={qty}
            min={itemMinQty}
            max={maxProductQty}
            onChange={onQtyInputChange}
            size={'small'}
            testId={'qty-input'}
            disabled={!isCardEditingAllowed || skuIsOutOfStock || isQtyInputDisabled}
            showNothing={isQtyInputDisabled}
            inputStep={0.1}
          />
          {!isAdmin && isProductQtyEqualLimit && (
            <Styled.MaxQtyHint data-test-id="reached-qty-limit-message">
              {t('allocation:reachedQtyLimit')}
            </Styled.MaxQtyHint>
          )}
        </Styled.OrderQtyCell>
        <Styled.FormattedQty>{!isQtyInputDisabled && getFormattedQty(itemQty)}</Styled.FormattedQty>
      </SectionTableBodyCell>
      {showDeliveredQty && onDeliveredQtyChange && (
        <SectionTableBodyCell style={noWrapStyle}>
          <Styled.QtyCell>
            <NumberInput
              value={itemDeliveredQty}
              min={0}
              onChange={onDeliveredQtyChange}
              size={'small'}
              testId={'delivered-qty-input'}
              disabled={!isCardEditingAllowed || skuIsOutOfStock}
              inputStep={0.1}
            />
          </Styled.QtyCell>
          {!!item.delivered_qty && <Styled.FormattedQty>{getFormattedQty(itemDeliveredQty)}</Styled.FormattedQty>}
        </SectionTableBodyCell>
      )}
      {showFinalQty && onFinalQtyChange && context === 'farmer' && (
        <SectionTableBodyCell style={noWrapStyle}>
          <Styled.QtyCell>
            <NumberInput
              value={itemFinalQty}
              min={0}
              onChange={onFinalQtyChange}
              size={'small'}
              testId={'final-qty-input'}
              inputStep={0.1}
            />
          </Styled.QtyCell>
          {!!item.final_qty && <Styled.FormattedQty>{getFormattedQty(itemFinalQty)}</Styled.FormattedQty>}
        </SectionTableBodyCell>
      )}
      {showShippedQty && onShippedQtyChange && context === 'distributor' && (
        <SectionTableBodyCell style={noWrapStyle}>
          <Styled.QtyCell>
            {isAdmin ? (
              <NumberInput
                value={itemShippedQty}
                min={0}
                onChange={onShippedQtyChange}
                size={'small'}
                testId={'shipped-qty-input'}
                inputStep={0.1}
              />
            ) : (
              itemShippedQty
            )}
          </Styled.QtyCell>
          {!!item.shipped_qty && <Styled.FormattedQty>{getFormattedQty(itemShippedQty)}</Styled.FormattedQty>}
        </SectionTableBodyCell>
      )}
      {showComment && (
        <SectionTableBodyCell style={{ minWidth: '152px' }}>
          <TextArea value={item.comment} onChange={onCommentChange} />
        </SectionTableBodyCell>
      )}
      <SectionTableBodyCell>
        {showRemoveButton && (
          <span data-test-id={'remove-product'}>
            <Styled.RemoveIcon onClick={onRemove} />
          </span>
        )}
      </SectionTableBodyCell>
    </SectionTableBodyRow>
  )
}
