import React, { memo, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import * as Styled from 'views/pages/Product/ProductDetailsForm/styled'
import { FormikHook, Input } from '@agro-club/frontend-shared'

export type ProductUnitsFormProps = {
  singular?: string
  plural?: string
}

const ProductUnitsForm: React.FC<{
  lang: keyof LocalizedValue
  singular?: LocalizedValue
  plural?: LocalizedValue
  useFormik: FormikHook
  disableRequired?: boolean
}> = memo(({ lang, useFormik, singular = {}, plural = {}, disableRequired }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        singular: Yup.string().required(t('validation:field_required')),
        plural: Yup.string(),
      }),
    [t],
  )
  const formik = useFormik<ProductUnitsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      singular: singular[lang] || '',
      plural: plural[lang] || '',
    },
    validationSchema: disableRequired ? undefined : validationSchema,
    enableReinitialize: true,
  })

  return (
    <Styled.VerticalInputsContainer data-test-id={`units-form-${lang}`}>
      <Input
        {...formik.getFieldProps('singular')}
        invalid={formik.touched.singular && !!formik.errors.singular}
        errorText={formik.errors.singular}
        label={t('form.unitsSingularTitle', { lang })}
        required={!disableRequired}
        title={`1 ${formik.values.singular}`}
        data-test-id="singular"
      />
      <Input
        {...formik.getFieldProps('plural')}
        invalid={formik.touched.plural && !!formik.errors.plural}
        errorText={formik.errors.plural}
        label={t('form.unitsPluralTitle', { lang })}
        required={!disableRequired}
        title={`12 ${formik.values.plural}`}
        data-test-id="plural"
      />
    </Styled.VerticalInputsContainer>
  )
})

export default ProductUnitsForm
