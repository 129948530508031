import React, { memo } from 'react'

import { Badge } from 'modules/domain/badge/types'
import * as Styled from './styled'

type Props = {
  badge: Badge
}

const BadgeOption: React.VFC<Props> = ({ badge }) => (
  <Styled.BadgeOption data-test-id={badge.id}>
    <span>{badge.name}</span>
    <Styled.BadgeImageOption
      src={badge.urls.x1}
      alt={badge.name}
      title={badge.name}
      srcSet={`${badge.urls.x1} 1x, ${badge.urls.x2} 2x, ${badge.urls.x3} 3x`}
    />
  </Styled.BadgeOption>
)

export default memo(BadgeOption)
