import makeHttpClient, { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  ReturnDeclarationListRequestSorting,
  ReturnDeclarationListRequestFilter,
  ReturnDeclarationDTO,
  PrintDeclarationDto,
  DeclarationCountDto,
} from './types'
import { ListResponse } from 'types/api'
import { ReturnDeclaration } from 'types/returnDeclaration'
import { helpersDownload } from '@agro-club/frontend-shared'

export const getList = (
  filter: ReturnDeclarationListRequestFilter,
  sorting: ReturnDeclarationListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<ReturnDeclaration>>(endpoints.returnDeclaration(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<ReturnDeclaration>(endpoints.returnDeclaration(id))
}

export const addItem = (dto: ReturnDeclarationDTO) => {
  return apiClient.post<ReturnDeclaration>(endpoints.returnDeclaration(), {
    ...dto,
    // Omit "product" field. This field is used by return declaration form, to lazy to fix that now
    items: dto.items.map(({ product: _, ...rest }) => rest),
  })
}

export const updateItem = (id: string, dto: Partial<ReturnDeclarationDTO>) => {
  return apiClient.put<ReturnDeclaration>(endpoints.returnDeclaration(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<ReturnDeclaration>(endpoints.returnDeclaration(id))
}

export const printDeclaration = async (dto: PrintDeclarationDto) => {
  const printApiClient = makeHttpClient('REACT_APP_PRINT_API_BASE_URL')
  const res = await printApiClient.post<ArrayBuffer>('/return-declaration/print', dto, {
    responseType: 'arraybuffer',
  })

  helpersDownload.downloadBlob(new Blob([res], { type: 'application/pdf' }))
  return res
}

export const getCount = (params: Omit<DeclarationCountDto, 'trigger'>) => {
  return apiClient.get<ListResponse<ReturnDeclaration>>(endpoints.returnDeclarationCount(), params)
}
