import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallBackRequestStatus } from 'modules/domain/callBackRequest/types'
import styled from 'styled-components'

const StatusColored = styled.span<{ status: CallBackRequestStatus }>`
  color: ${({ status, theme }) => {
    switch (status) {
      case CallBackRequestStatus.NEW:
        return theme.color.accentNotify
      case CallBackRequestStatus.DONE:
        return theme.color.accentApproving
    }
  }};
`

const Status: React.FC<{ status: CallBackRequestStatus }> = ({ status }) => {
  const { t } = useTranslation('callBackRequest')

  const mapStatusI18n = (status: CallBackRequestStatus) => {
    switch (status) {
      case CallBackRequestStatus.NEW:
        return t('status.new')
      case CallBackRequestStatus.DONE:
        return t('status.done')
      default:
        return status
    }
  }

  return <StatusColored status={status}>{mapStatusI18n(status)}</StatusColored>
}

export default Status
