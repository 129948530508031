import React, { useMemo } from 'react'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useParams, useHistory } from 'react-router-dom'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet } from '@agro-club/frontend-shared'
import DiscountRuleRoutes from './routes'
import { useDiscountRuleById } from 'modules/domain/discountRule/hooks'
import DiscountRuleActions from 'modules/domain/discountRule/duck'
import DiscountRuleSelectors from 'modules/domain/discountRule/selectors'
import DiscountRuleDetailsForm, { FormProps } from './DiscountRuleDetailsForm/DiscountRuleDetailsForm'
import * as uuid from 'uuid'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DiscountRuleEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [fetchProgress, discountRule] = useDiscountRuleById(params.id)
  const submitAction = useAction(DiscountRuleActions.updateRequested)
  const updateProgress = useSelector(DiscountRuleSelectors.updateProgress)
  const removeAction = useAction(DiscountRuleActions.removeRequested, params.id)
  const removeProgress = useSelector(DiscountRuleSelectors.removeProgress)
  const { t } = useTranslation('report')

  useHelmet({ title: discountRule ? discountRule.title : t('discountRulesMetaTitle') })

  const files = useMemo(() => {
    if (!discountRule || !discountRule?.link_url_i18n) return {}
    const linkUrlKeys = Object.keys(discountRule.link_url_i18n)

    const filesWithUrls = {}
    for (let i = 0; i < linkUrlKeys.length; i++) {
      const key = linkUrlKeys[i]
      const fileUrl = discountRule.link_url_i18n[key]
      if (fileUrl) {
        filesWithUrls[key] = [
          {
            file: fileUrl,
            kind: 'current' as const,
            id: uuid.v4(),
            error: null,
          },
        ]
      }
    }
    return filesWithUrls
  }, [discountRule])

  if (fetchProgress === Progress.WORK) {
    return (
      <SpinnerWrap>
        <SpinnerLayout />
      </SpinnerWrap>
    )
  }

  if (fetchProgress === Progress.ERROR || !discountRule) {
    return <ErrorLayout />
  }

  const handleFormSubmit = (props: FormProps) => {
    submitAction(discountRule.id, props)
  }

  const goBack = () => {
    history.push(DiscountRuleRoutes.List)
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={discountRule.title} />
          <Id>{`ID ${discountRule.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DiscountRuleDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          editing={true}
          onRemove={removeAction}
          removeProgress={removeProgress}
          initialValues={{
            ...discountRule,
            type: discountRule.tiers_rules[0].type,
            link_label_i18n: discountRule.link_label_i18n,
            link_url_i18n: { ...files },
          }}
        />
      </Layout.Content>
    </>
  )
}

export default DiscountRuleEdit
