import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ReconciliationActions from './duck'
import ReconciliationSelectors from './selectors'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ReconciliationSku, ReconciliationList, ReconciliationSkuAddResponse } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import { getPersistentFilter, savePersistentFilter } from 'modules/utils/helpers'

export const fetchGroups = function*(props) {
  try {
    let currentPage = yield select(ReconciliationSelectors.groupsPage)
    const filter = yield select(ReconciliationSelectors.groupsFilter)
    !props.payload?.filter && savePersistentFilter(props.payload)
    const seasonFilter = getPersistentFilter('season_id')
    const retailerFilter = getPersistentFilter('retailer_id')
    const filterUpdated = {
      ...filter,
      ...seasonFilter,
      ...retailerFilter,
    }
    yield put(ReconciliationActions.filtersUpdatedWithPersistentStorage(filterUpdated))
    const sorting = yield select(ReconciliationSelectors.groupsSorting)
    const pageSize = yield select(ReconciliationSelectors.groupsPageSize)
    let response: ListResponse<ReconciliationList> = yield call(
      managers.getGroups,
      filterUpdated,
      sorting,
      currentPage,
      pageSize,
    )
    if (currentPage > 1 && response.total_count === 0) {
      currentPage = 1
      response = yield call(managers.getGroups, filterUpdated, sorting, currentPage, pageSize)
    }
    const { data, page, total_count } = response
    const mappedData = data.map(item => ({ ...item, id: `${item.manufacturer.id}-${item.retailer.id}` }))
    yield put(ReconciliationActions.groupsRequestSucceed(mappedData, total_count, page))
    if (currentPage) {
      yield call(updateLocationQuery, ReconciliationRoutes.Groups, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReconciliationActions.groupsRequestFailed(errType))
  }
}

export const fetchGroupsNext = function*() {
  try {
    const page = yield select(ReconciliationSelectors.groupsPage)
    const filter = yield select(ReconciliationSelectors.groupsFilter)
    const sorting = yield select(ReconciliationSelectors.groupsSorting)
    const pageSize = yield select(ReconciliationSelectors.groupsPageSize)
    const { data, total_count }: { data: ReconciliationList[]; total_count: number } = yield call(
      managers.getGroups,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ReconciliationActions.groupsRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReconciliationActions.groupsRequestNextFailed(type, detail))
  }
}

export const batchUpdate = function*({ payload }: ReturnType<typeof ReconciliationActions.batchUpdateRequested>) {
  try {
    let groupId = ''
    const items: ReconciliationSku[] = yield all(
      payload.map(dto => {
        groupId = `${dto.manufacturer_id}-${dto.retailer_id}`
        return call(managers.updateItem, dto.manufacturer_id, dto.retailer_id, dto.sku_id, {
          beginning_inv: dto.beginning_inv,
          ending_inv: dto.ending_inv,
          returns: dto.returns,
          transfers_in: dto.transfers_in,
          transfers_out: dto.transfers_out,
          dist_adj: dto.dist_adj,
          comment: dto.comment,
        })
      }),
    )
    yield put(ReconciliationActions.batchUpdateSucceed(items, groupId))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReconciliationActions.batchUpdateError(type, detail))
  }
}

export const updateTargetGroupComment = function*({
  payload,
}: ReturnType<typeof ReconciliationActions.updateTargetGroupComment>) {
  try {
    const comment = yield call(managers.updateTargetGroupComment, payload)
    yield put(
      ReconciliationActions.saveTargetGroupComment({
        ...payload,
        groupComment: payload.comment,
      }),
    )
    yield put(ReconciliationActions.updateTargetGroupCommentSuceed(comment))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReconciliationActions.updateTargetGroupCommentFailed(type, detail))
  }
}

export const updateRetailerStatus = function*({
  payload,
}: ReturnType<typeof ReconciliationActions.retailerStatusUpdateRequested>) {
  try {
    const { manufacturer_id, retailer_id, isForced } = payload
    yield call(managers.updateRetailerStatus, manufacturer_id, retailer_id, isForced)
    yield put(ReconciliationActions.retailerStatusUpdateSucceed())
    const filter = yield select(ReconciliationSelectors.groupsFilter)
    const retailerId = isForced ? [retailer_id] : []
    yield put(ReconciliationActions.groupsFilterUpdated({ ...filter, retailer_id: retailerId }))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReconciliationActions.retailerStatusUpdateError(type, detail))
  }
}

export const updateSku = function*({ payload }: ReturnType<typeof ReconciliationActions.updateSkuRequested>) {
  try {
    const { manufacturer_id, retailer_id, sku_id, isForced } = payload
    const sku: ReconciliationSkuAddResponse = yield call(
      managers.updateSku,
      manufacturer_id,
      retailer_id,
      sku_id,
      isForced,
    )
    const groupId = `${manufacturer_id}-${retailer_id}`
    yield put(ReconciliationActions.updateSkuSucceed(sku, groupId))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReconciliationActions.updateSkuError(type, detail))
  }
}

const ReconciliationSaga = function*() {
  yield all([
    takeLatest(ReconciliationActions.filterUpdated.type, fetchGroups),
    takeLatest(ReconciliationActions.sortingUpdated.type, fetchGroups),
    takeLatest(ReconciliationActions.filterHasBeenReset.type, fetchGroups),
    takeLatest(ReconciliationActions.sortingHasBeenReset.type, fetchGroups),
    takeLatest(ReconciliationActions.groupsRequested.type, fetchGroups),
    takeLatest(ReconciliationActions.groupsFilterUpdated.type, fetchGroups),
    takeLatest(ReconciliationActions.groupsRequestedNext.type, fetchGroupsNext),
    takeLatest(ReconciliationActions.batchUpdateRequested.type, batchUpdate),
    takeLatest(ReconciliationActions.updateTargetGroupComment.type, updateTargetGroupComment),
    takeLatest(ReconciliationActions.retailerStatusUpdateRequested.type, updateRetailerStatus),
    takeLatest(ReconciliationActions.updateSkuRequested.type, updateSku),
  ])
}

export default ReconciliationSaga
