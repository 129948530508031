import React from 'react'
import ReportDetailsForm, { FormProps } from 'views/pages/Reports/ReportDetailsForm/ReportDetailsForm'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useParams, useHistory } from 'react-router-dom'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import ReportsRoutes from 'views/pages/Reports/routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useReport } from 'modules/domain/report/hooks'
import ReportActions from 'modules/domain/report/duck'
import ReportSelectors from 'modules/domain/report/selectors'
import { useAction, useHelmet } from '@agro-club/frontend-shared'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ReportEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [fetchProgress, report] = useReport(params.id)
  const submitAction = useAction(ReportActions.updateRequested)
  const updateProgress = useSelector(ReportSelectors.updateProgress)
  const removeAction = useAction(ReportActions.removeRequested, params.id)
  const removeProgress = useSelector(ReportSelectors.removeProgress)
  const { t } = useTranslation('report')

  useHelmet({ title: report ? report.title : t('reportsMetaTitle') })

  if (fetchProgress === Progress.WORK) {
    return (
      <SpinnerWrap>
        <SpinnerLayout />
      </SpinnerWrap>
    )
  }

  if (fetchProgress === Progress.ERROR || !report) {
    return <ErrorLayout />
  }

  const handleFormSubmit = (props: FormProps) => {
    submitAction(report.id, {
      title_i18n: props.title,
      message_i18n: props.message,
      slug: props.slug,
      url: props.url,
      producer_id: props.producer_id,
      is_warning: props.is_warning,
      is_active: props.is_active,
      roles: props.roles,
      is_private: props.is_private,
      companies: props.companies,
      users: props.users,
      params: props.params,
      order: props.order,
      max_age: props.max_age,
      auto_refresh_time: props.auto_refresh_time,
    })
  }

  const goBack = () => {
    history.push(ReportsRoutes.ReportList)
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={report.title} />
          <Id>{`ID ${report.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReportDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          editing={true}
          onRemove={removeAction}
          removeProgress={removeProgress}
          initialValues={{
            title: report.title_i18n,
            message: report.message_i18n,
            slug: report.slug,
            url: report.url,
            roles: report.roles,
            params: report.params,
            is_warning: report.is_warning,
            is_active: report.is_active,
            companies: report.companies,
            users: report.users,
            is_private: report.is_private,
            order: report.order,
            producer_id: report.producer_id,
            max_age: report.max_age,
            auto_refresh_time: report.auto_refresh_time,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ReportEdit
