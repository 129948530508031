import React, { useMemo } from 'react'
import { HowItWorksItem } from 'modules/domain/company/types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormikHook, IconCrossCircle, Input, TextArea } from '@agro-club/frontend-shared'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { createLocalizedValue } from 'helpers/localization'

type Item = HowItWorksItem
export type CompanyHowItWorksFormProps = {
  items: Item[]
}

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: max-content max-content;
  justify-content: start;
  margin-bottom: 24px;
`

const ItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 16px;
  grid-template-areas:
    'emoji title'
    'subtitle subtitle';
  width: 350px;
  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

const EmojiContainer = styled.div`
  grid-area: emoji;
  width: 40px;
`

const TitleContainer = styled.div`
  grid-area: title;
`

const SubTitleContainer = styled.div`
  grid-area: subtitle;
`

const AddButton = styled.div`
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary500};
  cursor: pointer;
  justify-self: start;
`

// TODO add more langs
const langs = ['en'] as const

const CompanyHowItWorksForm: React.FC<{
  useFormik: FormikHook
  items: Item[]
}> = ({ useFormik, items }) => {
  const { t } = useTranslation(['company', 'validation'])

  const validationSchema = useMemo(() => {
    const i18nValue = Yup.object(
      createLocalizedValue(lang =>
        lang === 'en' ? Yup.string().required(t('validation:field_required')) : Yup.string(),
      ),
    )
    return Yup.object({
      items: Yup.array(
        Yup.object({
          title_i18n: i18nValue,
          subtitle_i18n: i18nValue,
          emoji: Yup.string().required(t('validation:emoji_required')),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<CompanyHowItWorksFormProps>({
    validationSchema,
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      items,
    },
  })

  const handleAddRow = () => {
    formik.setFieldValue('items', [
      ...formik.values.items,
      {
        emoji: '',
        title_i18n: createLocalizedValue(() => ''),
        subtitle_i18n: createLocalizedValue(() => ''),
      },
    ])
  }

  const handleItemRemove = (index: number) => {
    formik.setFieldValue('items', remove(index, 1, formik.values.items))
  }

  return (
    <Wrapper data-test-id={'how-it-works-form'}>
      {formik.values.items.map((item, idx) => {
        const emojiMeta = formik.getFieldMeta(`items[${idx}].emoji`)
        return (
          <ItemWrapper key={idx} data-test-id={'how-it-works-form-item'}>
            <RemoveIcon data-test-id={'remove-button'} onClick={() => handleItemRemove(idx)} />
            <EmojiContainer>
              <Input
                label={t('form.howItWorksEmoji')}
                {...formik.getFieldProps(`items[${idx}].emoji`)}
                invalid={emojiMeta.touched && !!emojiMeta.error}
                errorText={emojiMeta.error}
              />
            </EmojiContainer>
            {langs.map(lang => {
              const titleKey = `items[${idx}].title_i18n[${lang}]`
              const subtitleKey = `items[${idx}].subtitle_i18n[${lang}]`

              const titleMeta = formik.getFieldMeta(titleKey)
              const subTitleMeta = formik.getFieldMeta(subtitleKey)

              return [
                <TitleContainer key={titleKey}>
                  <Input
                    label={t('form.howItWorksTitle')}
                    {...formik.getFieldProps(titleKey)}
                    invalid={titleMeta.touched && !!titleMeta.error}
                    errorText={titleMeta.error}
                  />
                </TitleContainer>,
                <SubTitleContainer key={subtitleKey}>
                  <TextArea
                    limit={200}
                    label={t('form.howItWorksSubTitle')}
                    {...formik.getFieldProps(subtitleKey)}
                    invalid={subTitleMeta.touched && !!subTitleMeta.error}
                    errorText={subTitleMeta.error}
                  />
                </SubTitleContainer>,
              ]
            })}
          </ItemWrapper>
        )
      })}
      <AddButton onClick={handleAddRow} data-test-id={'add-row'}>
        {formik.values.items.length === 0 ? t('form.addHowItWorksRow') : t('form.addHowItWorksOneMoreRow')}
      </AddButton>
    </Wrapper>
  )
}

export default CompanyHowItWorksForm
