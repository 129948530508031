import React, { useState } from 'react'
import { Modal, Segment, useAction } from '@agro-club/frontend-shared'
import { AddToFarmerCartDTO, FarmerData } from 'modules/domain/farmer/types'
import { StorefrontCard } from 'modules/domain/storefrontCard/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FarmerActions from 'modules/domain/farmer/duck'
import SearchFarmerByPhone from 'views/pages/ProductSettings/SearchFarmerByPhone'
import SearchFarmerById from 'views/pages/ProductSettings/SearchFarmerById'
import AddToCartForm from 'views/pages/ProductSettings/AddToCartForm'

const ModalHeader = styled.h2`
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
`

export const SearchModal = styled(Modal)`
  min-width: 400px;
  min-height: 350px;
  padding: 0 !important;
`

export const Content = styled.div`
  width: 100%;
  padding: 16px 24px 0;
`

export const SectionWrapper = styled.div`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`

enum FarmerSearchType {
  Id = 'id',
  Phone = 'phone',
}

export type AddToCartModalState = {
  isOpen: boolean
  card?: StorefrontCard
  sellerId?: string
}

export type AddToCartModalProps = {
  modalState: AddToCartModalState
  onClose: () => void
}

const AddToFarmerCartModal: React.FC<AddToCartModalProps> = ({ modalState, onClose }) => {
  const { t } = useTranslation(['cards', 'common', 'farmer'])
  const [phone, setPhone] = useState('')
  const [farmerId, setFarmerId] = useState('')
  const [farmerSearchType, setFarmerSearchType] = useState<FarmerSearchType>(FarmerSearchType.Id)
  const [farmer, setFarmer] = useState<FarmerData | null>(null)

  const addToCart = useAction(FarmerActions.addProductToFarmerCartRequested)
  const clearAddToCartProgress = useAction(FarmerActions.addProductToFarmerCartClearProgress)

  const onSearchTypeChange = (value: FarmerSearchType) => {
    setFarmerSearchType(value)
    handleClear()
  }

  const handleAddToCart = (quantity: number, sku_id: string) => {
    if (!modalState.card || !farmer) return

    const payload: AddToFarmerCartDTO = {
      farmer_id: farmer.id,
      product_card_id: modalState.card.id,
      quantity,
      sku_id,
      added_from_crm_options: {
        max_qty: quantity,
      },
    }

    addToCart(payload)
  }

  const handleClose = () => {
    handleClear()
    onClose()
  }

  const handleClear = () => {
    setPhone('')
    setFarmerId('')
    setFarmer(null)
    clearAddToCartProgress()
  }

  return (
    <SearchModal isOpen={modalState.isOpen} onClose={handleClose} size={'responsive'}>
      {!farmer ? (
        <>
          <Content>
            <ModalHeader>{t('farmer:addProductToCart.modalHeader')}</ModalHeader>
            <Segment
              label={t('farmer:addProductToCart.searchTypeLabel')}
              items={[
                { id: FarmerSearchType.Phone, title: t('farmer:addProductToCart.farmerPhone') },
                { id: FarmerSearchType.Id, title: t('farmer:addProductToCart.farmerId') },
              ]}
              value={farmerSearchType}
              onChange={onSearchTypeChange}
            />
          </Content>
          {farmerSearchType === FarmerSearchType.Id && (
            <SectionWrapper>
              <SearchFarmerById farmerId={farmerId} onSubmit={setFarmerId} onFarmerFound={setFarmer} />
            </SectionWrapper>
          )}
          {farmerSearchType === FarmerSearchType.Phone && (
            <SectionWrapper>
              <SearchFarmerByPhone phone={phone} onSubmit={setPhone} onFarmerFound={setFarmer} />
            </SectionWrapper>
          )}
        </>
      ) : (
        <AddToCartForm
          farmer={farmer}
          card={modalState.card}
          onSubmit={handleAddToCart}
          onBack={handleClear}
          onClose={handleClose}
        />
      )}
    </SearchModal>
  )
}

export default AddToFarmerCartModal
