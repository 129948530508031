import React, { useCallback } from 'react'
import UserDetailsForm, { FormData } from './UserDetailsForm/UserDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import AuthActions from 'modules/domain/auth/duck'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import UserRoutes from './routes'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet } from '@agro-club/frontend-shared'

const UserId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserProfile: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('user')
  const profile = useSelector(AuthSelectors.profile)
  const getInitProgress = useSelector(AuthSelectors.getInitProgress)
  const profileUpdateProgress = useSelector(AuthSelectors.profileUpdateProgress)
  const updateProfileAction = useAction(AuthActions.userUpdateRequested)
  const handleSubmit = useCallback(
    (data: FormData) => {
      updateProfileAction({
        password: data.password || undefined,
        role: data.role,
        phone_number: data.phone,
        status: data.status,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        second_name: data.middleName,
        country: data.countryCode,
        // TODO: add this fields 👇🏼
        // id,
        // company_id,
        // company_type,
      })
    },
    [updateProfileAction],
  )

  useHelmet({ title: t('profileMetaTitle') })

  const goBack = () => history.push(UserRoutes.List)

  if (getInitProgress === Progress.ERROR) {
    return <ErrorLayout />
  }

  if (getInitProgress === Progress.WORK || !profile) {
    return (
      <SpinnerWrap>
        <SpinnerLayout />
      </SpinnerWrap>
    )
  }

  const name = [profile.last_name, profile.first_name, profile.second_name].filter(Boolean).join(' ')
  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={name} />
          <UserId>{`ID ${profile.id}`}</UserId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserDetailsForm
          onCancel={goBack}
          initialValues={{
            companyType: profile.company_type,
            company: profile.company_id,
            status: profile.status,
            lastName: profile.last_name || '',
            middleName: profile.second_name || '',
            firstName: profile.first_name || '',
            email: profile.email || '',
            role: profile.role,
            phone: profile.phone_number || '',
            distributor: profile.company_id,
            countryCode: profile.country,
            territories: [],
            comment: profile.comment || '',
          }}
          progress={profileUpdateProgress}
          mode={'edit'}
          onSubmit={handleSubmit}
        />
      </Layout.Content>
    </>
  )
}

export default UserProfile
