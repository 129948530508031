import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('farmerTarget')

export const FarmerTargetSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.farmerTarget.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.farmerTarget.totalTargetValues,
  farmerOrdersTotal: (state: AppGlobalState) => state.farmerTarget.totalFarmerOrders,
  previousYear: (state: AppGlobalState) => state.farmerTarget.totalFarmerOrdersPreviousYear,
}

export default FarmerTargetSelectors
