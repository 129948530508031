import { ErrorInfo } from 'react'
import { Middleware, Action } from 'redux'
import env from 'env'
import AuthActions from './modules/domain/auth/duck'
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { AppGlobalState } from 'modules/types'
import { Severity } from '@sentry/types'
import jsCookie from 'js-cookie'

function isInitSucceedAction(action: Action): action is ReturnType<typeof AuthActions.initSucceed> {
  return action.type === AuthActions.initSucceed.type
}

class SentryAdapter {
  sentryInstance: typeof import('@sentry/browser') | null

  constructor() {
    this.sentryInstance = null
  }

  async init() {
    if (env.SENTRY_DSN) {
      this.sentryInstance = await import('@sentry/browser')
      this.sentryInstance.init({ dsn: env.SENTRY_DSN })
    }
  }

  handleAxiosRequest(config: AxiosRequestConfig) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        type: 'initiated',
        url: config.url,
        params: config.params,
        data: config.data,
      },
    })
  }

  handleAxiosError(error: AxiosError, level: Severity = Severity.Error) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: error.config.url,
        method: error.config.method,
        type: 'error',
        error: {
          message: error.message,
          name: error.name,
          data: error.response?.data,
        },
      },
    })

    this.sentryInstance.withScope(scope => {
      const sentry = this.sentryInstance
      if (!sentry) {
        return
      }

      const httpStatus = error.response?.status.toString() || 'unknown'
      const apiHandle = error.config.url as string
      scope.setTag('kind', 'apiRequest')
      scope.setTag('apiHandle', apiHandle)
      scope.setTag('httpStatus', httpStatus)
      scope.setLevel(level)
      scope.setExtras({
        'HTTP status': httpStatus,
        'API handle': apiHandle,
        Method: error.config.method,
        Query: error.config.params,
        Data: error.config.data,
      })

      error.name = 'Request error'
      error.message = `${error.config.method} ${apiHandle} ${error.message}`
      sentry.captureException(error, {
        fingerprint: [apiHandle],
      })
    })
  }

  handleAxiosResponse(response: AxiosResponse) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: response.config.url,
        method: response.config.method,
        type: 'response',
      },
    })
  }

  handleReactException(error: Error, errorInfo: ErrorInfo, storeSnapshot: AppGlobalState) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.withScope(scope => {
      scope.setExtra('Component stack', errorInfo.componentStack)
      scope.setExtra('Redux store snapshot', storeSnapshot)
      if (this.sentryInstance) {
        this.sentryInstance.captureException(error)
      }
    })
  }

  handleReduxAction(action: Action) {
    if (!this.sentryInstance) {
      return
    }
    if (isInitSucceedAction(action)) {
      const countryCookie = jsCookie.get('cf-ipcountry')
      const country = action.payload.country || 'unknown'
      const ipCountry = countryCookie || 'unknown'
      this.sentryInstance.setUser({
        email: action.payload.email,
        phone: action.payload.phone_number,
        country,
        ipCountry,
      })
      this.sentryInstance.withScope(scope => {
        scope.setTag('country', country)
        scope.setTag('ipCountry', ipCountry)
      })
    }
    this.sentryInstance.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    })
  }
}

const Singleton = new SentryAdapter()

export const middleware: Middleware = _store => next => action => {
  Singleton.handleReduxAction(action)
  return next(action)
}

export const Sentry = Singleton
