import React from 'react'
import { TransferRequestStatuses } from 'modules/domain/inventoryInTransferRequest/types'
import styled, { StyledProps } from 'styled-components'

type StatusProps = StyledProps<{ status: TransferRequestStatuses }>
const StatusColored = styled.span<StatusProps>`
  color: ${({ status, theme }: StatusProps) => {
    switch (status) {
      case TransferRequestStatuses.active:
        return theme.color.primary700
      case TransferRequestStatuses.rejected:
        return theme.color.accentDestruct800
      case TransferRequestStatuses.approved:
        return theme.color.accentApprove800
    }
  }};
`

const RequestStatusColored: React.VFC<{ status: TransferRequestStatuses }> = ({ status }) => {
  let statusString = 'New'
  if (status === TransferRequestStatuses.approved) {
    statusString = 'Approved'
  } else if (status === TransferRequestStatuses.rejected) {
    statusString = 'Rejected'
  } else if (status === TransferRequestStatuses.deleted) {
    statusString = 'Deleted'
  }
  return <StatusColored status={status}> {statusString} </StatusColored>
}
export default RequestStatusColored
