import { push } from 'connected-react-router'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { generatePath } from 'react-router-dom'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import TerritoryRoutes from 'views/pages/Territory/routes'
import actions from './duck'
import managers from './managers'
import selectors from './selectors'

const sagas = createBasicSagas({
  selectors,
  actions,
  managers,
  onAddItemSucceed: function*(duplicate, item) {
    if (!duplicate) {
      yield put(push(generatePath(TerritoryRoutes.Item, { id: item.id })))
    }
  },
  onRemoveItemSucceed: function*() {
    yield put(push(TerritoryRoutes.List))
  },
  onUpdateItemSucceed: function*(duplicate) {
    if (duplicate) {
      yield put(push(TerritoryRoutes.Add))
    }
  },
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, TerritoryRoutes.List, { page })
  },
})

const TerritorySaga = function*() {
  yield all([
    takeLatest(actions.itemRequested.type, sagas.fetchItem),
    takeLatest(actions.listRequested.type, sagas.fetchList),
    takeLatest(actions.filterUpdated.type, sagas.fetchList),
    takeLatest(actions.sortingUpdated.type, sagas.fetchList),
    takeLatest(actions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.sortingHasBeenReset.type, sagas.fetchList),

    takeLatest(actions.listRequestedNext.type, sagas.fetchListNext),

    takeLatest(actions.addRequested.type, sagas.addItem),
    takeLatest(actions.updateRequested.type, sagas.updateItem),
    takeLatest(actions.removeRequested.type, sagas.removeItem),
  ])
}

export default TerritorySaga
