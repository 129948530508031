import { FormikHook, Input } from '@agro-club/frontend-shared'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { Company, SellerRelatedContacts } from 'modules/domain/company/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dict } from 'types/generics'
import { CompanySelectNoFetch } from 'views/components/CompanySelect/CompanySelectNoFetch'
import { DestructibleBlock } from 'views/components/DestructibleBlock/DestructibleBlock'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import * as Yup from 'yup'
import { AddressSection } from './styled'

export type CompanyContactProps = {
  relatedManufacturers?: Company[]
  useFormik: FormikHook
  onRemove?: () => void
  alreadyPicked?: Dict<boolean>
  initialValues?: SellerRelatedContacts
  index: number
}

export type CompanyContactFormProps = SellerRelatedContacts

const defaultValues = {
  phone_number: '',
  email: '',
  seller_id: '',
  secondary_email: '',
}

const CompanyContactForm: React.FC<CompanyContactProps> = ({
  relatedManufacturers,
  useFormik,
  onRemove,
  alreadyPicked,
  initialValues,
  index,
}) => {
  const { t } = useTranslation(['company', 'common', 'validation'])

  const formik = useFormik({
    initialValues: {
      ...defaultValues,
      ...initialValues,
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema: Yup.object({
      phone_number: Yup.string()
        .nullable()
        .test('is-valid-number', t('validation:invalid_phone_number'), value => {
          return isValidPhoneNumber(value || '')
        }),
      email: Yup.string().email(t('validation:email_invalid')),
      secondary_email: Yup.string()
        .email(t('validation:email_invalid'))
        .nullable(),
      seller_id: Yup.string().required(t('validation:required')),
    }),
  })

  return (
    <DestructibleBlock onRemove={onRemove}>
      <AddressSection>
        <Input
          {...formik.getFieldProps(`email`)}
          invalid={formik.touched.email && !!formik.errors.email}
          label={t('form.email')}
          errorText={formik.errors.email}
          data-test-id={`email-${index}`}
        />
        <Input
          {...formik.getFieldProps(`contacts[${index}].secondary_email`)}
          invalid={formik.touched.secondary_email && !!formik.errors.secondary_email}
          label={t('form.secondary_email')}
          errorText={formik.errors.secondary_email}
          data-test-id={`secondary-email-${index}`}
        />
        <PhoneInput
          label={t('common:phone')}
          name={'phone'}
          invalid={formik.touched.phone_number && !!formik.errors.phone_number}
          errorText={formik.errors.phone_number}
          phoneNumber={formik.values.phone_number}
          onChange={value => {
            formik.setFieldValue(`phone_number`, value)
          }}
          onBlur={() => {
            formik.setFieldTouched(`phone_number`, true)
          }}
          testId={`phone-${index}`}
        />
        <CompanySelectNoFetch
          label={t('form.manufacturer')}
          placeholder=""
          value={formik.values.seller_id}
          options={relatedManufacturers}
          filterOption={option => !alreadyPicked || alreadyPicked[option.value] === undefined}
          onChange={value => formik.setFieldValue(`seller_id`, value)}
          data-test-id={`seller-${index}`}
          hideSelectedOptions
        />
      </AddressSection>
    </DestructibleBlock>
  )
}
export default CompanyContactForm
