import { Button, IconCheck, IconCross, TableBodyCell } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CompoundedTableEditableRowProps } from './CompoundedTableEditableRow'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
`
const PrimaryButtons = styled.div`
  display: flex;
  gap: 12px;
`

export type CompoundedTableEditableCellProps<DataItemType extends Object, FormItemType extends Object> = Pick<
  CompoundedTableEditableRowProps<DataItemType, FormItemType>,
  'totalColumns' | 'getFormValuesFromRow' | 'row' | 'FormComponent'
> & {
  onClose?: () => void
  onCreate?: () => void
  onUpdate?: () => void
  mode: 'edit' | 'create'
} & Omit<React.HTMLAttributes<HTMLTableCellElement>, 'onSubmit'>

export const CompoundedTableEditableCell = <DataItemType extends Object, FormItemType extends Object>({
  totalColumns,
  getFormValuesFromRow,
  row,
  FormComponent,
  onClose,
  mode,
  onUpdate,
  onCreate,
  ...props
}: CompoundedTableEditableCellProps<DataItemType, FormItemType>) => {
  const { t } = useTranslation('CompoundedTableEditable')

  const handleSubmit = (onSubmit: () => void) => () => {
    if (mode === 'edit') {
      onUpdate?.()
    } else {
      onCreate?.()
    }
    onSubmit()
  }

  return (
    <TableBodyCell colSpan={totalColumns} {...props} data-test-id="table-form">
      <FormComponent
        entity={row && getFormValuesFromRow(row)}
        Buttons={({
          onSubmit,
          onRemove,
          updateText,
          createText,
          submitProgress,
          removeProgress,
          isSubmitDisabled,
          isRemoveDisabled,
          removeText,
        }) => (
          <ButtonsWrapper>
            <PrimaryButtons>
              <Button
                progress={submitProgress}
                disabled={isSubmitDisabled}
                intent="primary"
                size="small"
                filled
                onClick={handleSubmit(onSubmit)}
                data-test-id="submit-button"
              >
                <IconCheck />
                {mode === 'create' ? createText || t('defaultCreateTitle') : updateText || t('defaultUpdateTitle')}
              </Button>
              <Button data-test-id="cancel-button" intent="secondary" size="small" variant="filled" onClick={onClose}>
                <IconCross />
              </Button>
            </PrimaryButtons>
            {onRemove && (
              <Button
                progress={removeProgress}
                intent="danger"
                size="small"
                onClick={onRemove}
                disabled={isRemoveDisabled}
                data-test-id="remove-button"
              >
                {removeText || t('defaultRemoveTitle')}
              </Button>
            )}
          </ButtonsWrapper>
        )}
      />
    </TableBodyCell>
  )
}
