import { Input, Progress, SectionBody, SectionContainer, TextArea } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import { IncentiveCampaign } from 'modules/domain/incentiveCampaign/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import * as Yup from 'yup'

export type IncentiveCampaignDetailsFormikProps = Omit<IncentiveCampaign, 'id'>

export type IncentiveCampaignDetailsFormProps = {
  onSubmit(form: IncentiveCampaignDetailsFormikProps): void
  onRemove?: () => void
  initialValues?: Partial<IncentiveCampaignDetailsFormikProps>
  progress?: Progress
  removeProgress?: Progress
  onCancel(): void
}

const basicInitialValues: IncentiveCampaignDetailsFormikProps = {
  title: '',
  description: '',
  seller_id: '',
}

const Wrapper = styled.div`
  max-width: 510px;
`

export const IncentiveCampaignDetailsForm: React.VFC<IncentiveCampaignDetailsFormProps> = ({
  initialValues = basicInitialValues,
  onSubmit,
  progress,
  removeProgress,
  onRemove,
  onCancel,
}) => {
  const { t } = useTranslation(['incentiveCampaign', 'common'])

  const validationSchema = useMemo(() => {
    return Yup.object({
      title: Yup.string().required(t('validation:field_required')),
      seller_id: Yup.string().required(t('validation:field_required')),
    })
  }, [t])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const formik = useFormik({
    initialValues: { ...basicInitialValues, ...initialValues },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  })

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <SectionContainer>
            <SectionBody>
              <Input
                label={t('form.labels.title')}
                placeholder={t('form.placeholders.title')}
                {...formik.getFieldProps('title')}
                invalid={formik.touched.title && !!formik.errors.title}
                errorText={formik.errors.title}
                required
              />
              <CompanySelect
                label={t('form.labels.seller')}
                placeholder={t('form.placeholders.seller')}
                value={formik.values.seller_id}
                filter={{ is_seller: true }}
                onChange={id => formik.setFieldValue('seller_id', id)}
                required
              />
              <TextArea
                label={t('form.labels.description')}
                placeholder={t('form.placeholders.description')}
                {...formik.getFieldProps('description')}
                invalid={formik.touched.description && !!formik.errors.description}
                errorText={formik.errors.description}
                limit={1000}
              />
            </SectionBody>
          </SectionContainer>
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterDefaultControls
        onRemove={onRemove}
        onSave={formik.handleSubmit}
        onCancel={onCancel}
        saveProgress={progress}
        isSaveDisabled={!formik.dirty}
        removeProgress={removeProgress}
        popoverText={t('form.removeText', { title: initialValues.title })}
      />
    </StickyFooterLayout.Wrapper>
  )
}
