import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '@agro-club/frontend-shared'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { DeclarationSkuCountDto } from './types'
import ReturnDeclarationSkuSelectors from './selectors'
import ReturnDeclarationSkuActions from './duck'

export const useReturnDeclarationSkuList = createItemListOnceHook(
  ReturnDeclarationSkuSelectors,
  ReturnDeclarationSkuActions.listRequested,
)

export const useReturnDeclarationSkuByIdOnce = createItemByIdOnceHook(
  ReturnDeclarationSkuSelectors,
  ReturnDeclarationSkuActions.itemRequested,
)

export const useReturnDeclarationSkuCounter = ({ distributor_id, season_id, type, status }: DeclarationSkuCountDto) => {
  const countProgress = useSelector(ReturnDeclarationSkuSelectors.declarationCountFetchProgress)
  const error = useSelector(ReturnDeclarationSkuSelectors.declarationCountFetchError)
  const count = useSelector(ReturnDeclarationSkuSelectors.declarationCount)
  const fetchAction = useAction(ReturnDeclarationSkuActions.countRequested, { distributor_id, season_id, type, status })

  useEffect(() => {
    if (distributor_id && season_id && type && status) {
      fetchAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor_id, season_id, type, status])

  return { count, countProgress, error }
}
