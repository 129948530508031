import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import MailingListActions from './duck'
import MailingListSelectors from './selectors'
import { MailingListEntry } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import MailingListRoutes from 'views/pages/MailingList/routes'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(MailingListSelectors.page)
    const filter = yield select(MailingListSelectors.filter)
    const sorting = yield select(MailingListSelectors.sorting)
    const pageSize = yield select(MailingListSelectors.pageSize)

    let response: ListResponse<MailingListEntry> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(MailingListActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, MailingListRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(MailingListSelectors.page)
    const filter = yield select(MailingListSelectors.filter)
    const sorting = yield select(MailingListSelectors.sorting)
    const pageSize = yield select(MailingListSelectors.pageSize)
    const { data, total_count }: { data: MailingListEntry[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(MailingListActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof MailingListActions.itemRequested>) {
  try {
    const company: MailingListEntry = yield call(managers.getItem, id)
    yield put(MailingListActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof MailingListActions.addRequested>) {
  try {
    const company: MailingListEntry = yield call(managers.addItem, dto)
    yield put(MailingListActions.addSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof MailingListActions.updateRequested>) {
  try {
    const company: MailingListEntry = yield call(managers.updateItem, id, dto)
    yield put(MailingListActions.updateSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof MailingListActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(MailingListActions.removeSucceed(payload))
    const page = yield select(MailingListSelectors.page)
    yield call(updateLocationQuery, MailingListRoutes.List, { page })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(MailingListActions.removeFailed(payload, errType))
  }
}

const MailingListSaga = function*() {
  yield all([
    takeLatest(MailingListActions.itemRequested.type, fetchItem),
    takeLatest(MailingListActions.listRequested.type, fetchList),
    takeLatest(MailingListActions.filterUpdated.type, fetchList),
    takeLatest(MailingListActions.sortingUpdated.type, fetchList),
    takeLatest(MailingListActions.filterHasBeenReset.type, fetchList),
    takeLatest(MailingListActions.sortingHasBeenReset.type, fetchList),

    takeLatest(MailingListActions.listRequestedNext.type, fetchListNext),

    takeLatest(MailingListActions.addRequested.type, addItem),
    takeLatest(MailingListActions.updateRequested.type, updateItem),
    takeLatest(MailingListActions.removeRequested.type, removeItem),
  ])
}

export default MailingListSaga
