import React, { useMemo, useState } from 'react'
import { CellProps, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'

import {
  IconCrossCircle,
  IconPencil,
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  TableBodyCell,
  TableBodyRow,
} from '@agro-club/frontend-shared'
import styled, { css } from 'styled-components'
import { CommentEntry } from 'modules/domain/comments/types'
import useDateFormatFn from 'hooks/useDateFormatFn'
import OrderCancellationReasonModal from 'views/components/OrderCancellationReasonModal/OrderCancellationReasonModal'
import { ROLES } from 'types/entities'
import { useRole } from 'modules/permissions/permissions'

const SectionTableStyled = styled(SectionTable)`
  border-spacing: 0 4px;
`
const iconCss = css`
  cursor: pointer;
  fill: ${props => props.theme.color.secondary200};
  vertical-align: middle;
  height: 14px;
  &:hover {
    opacity: 0.7;
  }
`
const SwitchShowMore = styled.span`
  margin-left: 10px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.primary600};
  user-select: none;
`

const RemoveIcon = styled(IconCrossCircle)`
  ${iconCss}
`
const EditIcon = styled(IconPencil)`
  ${iconCss}
`
const IconWrapper = styled.span`
  margin: 4px;
`

type Props = {
  value: CommentEntry[]
  onChange: (val: CommentEntry[]) => void
}

const Comment: React.FC<CellProps<CommentEntry>> = ({ row }) => {
  const { t } = useTranslation('farmerOrder')
  const [isOpen, setIsOpen] = useState(false)
  const maxVisibleLength = 90
  const comment = row.original.comment || ''
  const isLongComment = comment.length > maxVisibleLength
  return (
    <span>
      {isLongComment ? `${comment.slice(0, maxVisibleLength)}...` : comment}
      {isLongComment && <SwitchShowMore onClick={() => setIsOpen(true)}>{t('form.showMore')}</SwitchShowMore>}
      <OrderCancellationReasonModal
        isOpen={isOpen}
        isShowMod={true}
        onClose={() => setIsOpen(false)}
        value={row.original.comment}
      />
    </span>
  )
}

export const Author: React.FC<CellProps<CommentEntry>> = ({ row }) => {
  const first_name = row.original.author?.full_name || ''
  return <span>{first_name}</span>
}

export const LastUpdateCell: React.FC<CellProps<CommentEntry>> = ({ row }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })
  return <span>{getFormatedDate(row.original.updated_at)}</span>
}

const Controls: React.FC<CellProps<CommentEntry> & {
  onDeleteComment: (item: CommentEntry) => void
  onUpdateComment: (id: string | undefined, comment: string) => void
}> = ({ row, onDeleteComment, onUpdateComment }) => {
  const role = useRole()
  const isAdmin = !!role && [ROLES.AGRO_ADMIN, ROLES.AGRO_MANAGER].includes(role)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <IconWrapper data-test-id={'edit-comment'}>
        <EditIcon onClick={() => setIsOpen(true)} />
      </IconWrapper>
      {isAdmin && (
        <IconWrapper data-test-id={'remove-comment'}>
          <RemoveIcon onClick={() => onDeleteComment(row.original)} />
        </IconWrapper>
      )}
      <OrderCancellationReasonModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onChangeComment={e => onUpdateComment(row.original.id, e)}
        value={row.original.comment}
      />
    </>
  )
}

const CommentsBlock: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('farmerOrder')
  const onDeleteComment = (item: CommentEntry) => {
    if (item?.id) {
      onChange(value.filter(e => e.id !== item.id))
    } else {
      onChange(value.filter(e => e.id))
    }
  }
  const onUpdateComment = (id: string, comment: string) => {
    const newValue = [...value]
    const itemIndex = newValue.findIndex(e => e?.id == id)
    newValue[itemIndex] = { ...newValue[itemIndex], comment }
    onChange(newValue)
  }
  const allColumns = useMemo(
    () => [
      { width: 800, Header: t('form.sectionHeaders.comment'), cellAlign: 'left', Cell: Comment },
      { width: 300, Header: t('form.sectionHeaders.author'), cellAlign: 'left', Cell: Author },
      { width: 300, Header: t('form.sectionHeaders.lastUpdate'), cellAlign: 'left', Cell: LastUpdateCell },
      { width: 140, accessor: 'none', cellAlign: 'right', Cell: props => <Controls {...props} /> },
    ],
    [t],
  )
  const { columns, rows, prepareRow } = useTable<any>({
    columns: allColumns,
    data: value,
  })
  return (
    <SectionTableStyled>
      <SectionTableHead>
        <SectionTableHeadRow>
          {columns.map(column => {
            return <SectionTableHeadCell key={column.id}>{column.render('Header')}</SectionTableHeadCell>
          })}
        </SectionTableHeadRow>
      </SectionTableHead>
      <SectionTableBody>
        {rows.map(row => {
          prepareRow(row)
          const { key, ...props } = row.getRowProps()
          return (
            <TableBodyRow key={key} {...props}>
              {row.cells.map(cell => {
                const { key, ...props } = cell.getCellProps()
                return (
                  <TableBodyCell {...cell.column} key={key} {...props}>
                    {cell.render('Cell', { onDeleteComment, onUpdateComment })}
                  </TableBodyCell>
                )
              })}
            </TableBodyRow>
          )
        })}
      </SectionTableBody>
    </SectionTableStyled>
  )
}

export default CommentsBlock
