import styled from 'styled-components'

export const TextTertiary = styled.div`
color: ${({ theme }) => theme.color.textTertiary};
}`
export const TextBolder = styled.div`
font-weight: 600;
}`
export const FormWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 14px;
`
