import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { Company } from '../company/types'
import { StorefrontSku } from 'modules/domain/storefront/types'

export type InventoryExchangeTransactionState = {
  items: Dict<InventoryExchangeTransaction>
  meta: Dict<EntityMetadata<InventoryExchangeTransaction>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  listFetchNextErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof InventoryExchangeTransaction, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: InventoryExchangeTransactionListRequestFilter
  sorting: InventoryExchangeTransactionListRequestSorting
  page: number
  total: number
  pageSize: number
}

export type InventoryExchangeTransaction = {
  id: string
  producer_id?: string
  producer?: Company
  retailer_id: string
  retailer?: Company
  sku?: StorefrontSku
  sku_id?: string
  quantity: number
  comment?: string

  record_type?: string
  owner_id?: string
  parent_id?: string
  created_at?: string
  owner_name?: string
}

export type InventoryExchangeTransactionListRequestFilter = {
  search?: string
  retailer_id?: string
  product_id?: string
  producer_id?: string | null
}

export type InventoryExchangeTransactionListRequestSorting = {
  sort_field?: keyof InventoryExchangeTransaction
  sort_reversed?: boolean
}

export type InventoryExchangeTransactionDTO = {
  id?: string
  retailer_id: string
  sku_id: string
  quantity: number
  comment?: string

  record_type?: string
  owner_id?: string
  parent_id?: string
}

export enum RequestSource {
  DETAILS_FORM,
  MODAL,
}
