import * as uuid from 'uuid'

export const makeCurrentFile = (src?: string) => {
  if (!src) {
    return undefined
  }

  return {
    file: src,
    kind: 'current' as const,
    id: uuid.v4(),
    error: null,
  }
}
