import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAction, useHelmet } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { Progress } from 'modules/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'

import SeasonDetailsForm, { SeasonFormInputs } from 'views/pages/Season/SeasonDetailsForm/SeasonDetailsForm'
import SeasonRoutes from 'views/pages/Season/routes'
import SeasonSelectors from 'modules/domain/season/selectors'
import SeasonActions from 'modules/domain/season/duck'
import { useSeason } from 'modules/domain/season/hooks'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SeasonEdit: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('season')
  const params = useParams<{ id: string }>()
  const meta = useSelector(SeasonSelectors.meta(params.id))
  const submitAction = useAction(SeasonActions.updateRequested)
  const removeAction = useAction(SeasonActions.removeRequested, params.id)

  const [fetchProgress, season] = useSeason(params.id)

  useHelmet({ title: season ? season.title : t('seasonsMetaTitle') })

  const goBack = () => {
    history.push(SeasonRoutes.List)
  }

  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />

  if (fetchProgress === Progress.WORK || !season) {
    return loading()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFormSubmit = (props: SeasonFormInputs) => {
    submitAction(season.id, {
      company_id: props.company_id,
      title: props.title,
      start_date: props.start_date,
      enable_final_qty: props.enable_final_qty,
      enable_delivered_qty: props.enable_delivered_qty,
      stop_taking_orders: props.stop_taking_orders,
      is_season_closed: props.is_season_closed,
      is_default_for_return_declarations: props.is_default_for_return_declarations,
      show_retailer_order_suggestion: props.show_retailer_order_suggestion,
    })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={season.title} />
          <Id>{`ID ${season.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <SeasonDetailsForm
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          onRemove={removeAction}
          editing
          initialValues={{
            company_id: season.company_id,
            title: season.title,
            start_date: season.start_date,
            enable_final_qty: season.enable_final_qty,
            enable_delivered_qty: season.enable_delivered_qty,
            stop_taking_orders: season.stop_taking_orders,
            is_season_closed: season.is_season_closed,
            is_default_for_return_declarations: season.is_default_for_return_declarations,
            show_retailer_order_suggestion: season.show_retailer_order_suggestion,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default SeasonEdit
