import { ProductUnits } from 'modules/domain/product/types'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import i18n from 'i18n'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { isNil } from 'ramda'

export const getUniqItem = (uniqItems: {}, product_id: string) => {
  return uniqItems[product_id]
}

export const getUniqItemQty = (uniqItems: {}, product_id: string): number => {
  return Number(getUniqItem(uniqItems, product_id)?.quantity)
}

export const getUniqItemInitialQty = (uniqItems: {}, product_id: string) => {
  return Number(getUniqItem(uniqItems, product_id)?.initialQuantity)
}

export const getUniqItems = (items: FarmerOrderSkuItem[]) => {
  const uniq = {}

  items?.forEach(item => {
    const card = item.card || item.sku_card
    const productId = card?.product_id as string
    const currentProductKeyExists = uniq[productId]
    const qtyNumber = Number(item.quantity)

    if (currentProductKeyExists) {
      uniq[productId].quantity += qtyNumber
    } else {
      uniq[productId] = {
        product_id: productId,
        quantity: qtyNumber,
      }
    }
  })

  return uniq
}

export const getIniqItemsWithInitialQty = (items: FarmerOrderSkuItem[]) => {
  const uniq = {}

  items?.forEach(item => {
    const card = item.card || item.sku_card
    const productId = card?.product_id as string
    const currentProductKeyExists = uniq[productId]
    const qtyNumber = Number(item.quantity)

    if (currentProductKeyExists) {
      uniq[productId].quantity += qtyNumber
    } else {
      uniq[productId] = {
        product_id: productId,
        initialQuantity: qtyNumber,
      }
    }
  })

  return uniq
}

export const getStandardUnitsQty = (qty?: number, units?: ProductUnits, packageType?: PackageTypes) => {
  if (isNil(qty)) return ''

  const singularUnit = units?.singular_i18n[i18n.language]
  const pluralUnit = units?.plural_i18n[i18n.language]
  const calculated = parseFloat(((packageType?.conversion_rate || 1) * qty).toFixed(1))
  const baseUnit = calculated === 1 ? singularUnit : pluralUnit

  return `${calculated} ${baseUnit || 'units'}`
}
