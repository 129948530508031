import { EntityState } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { LocalizedValue } from 'types/entities'

export type PromocodeBase = {
  id: string
  company_id: string
  code: string
  is_stackable: boolean
  is_active: boolean
  has_discount?: boolean
  company_name?: string
}

export enum PromocodeType {
  Simple = 'simple',
  WithComment = 'with_comment_and_prompt',
  WithLegalText = 'with_legal_text',
}

export type PromocodeSimple = PromocodeBase & {
  params: {
    type: PromocodeType.Simple
  }
}

export type PromocodeWithComment = PromocodeBase & {
  comment: string | null
  params: {
    type: PromocodeType.WithComment
    prompt_i18n: LocalizedValue | null
    prompt?: string | null
  }
}

export type PromocodeWithLegalText = PromocodeBase & {
  params: {
    type: PromocodeType.WithLegalText
    legal_text_i18n: LocalizedValue | null
    legal_text?: string | null
  }
}

export type Promocode = PromocodeSimple | PromocodeWithComment | PromocodeWithLegalText

export type PromocodeDTO = Omit<Promocode, 'id' | 'has_discount'> & { id?: string }
export type PromocodeSimpleDTO = PromocodeDTO & {
  params: {
    type: PromocodeType.Simple
  }
}
export type PromocodeWithCommentDTO = PromocodeDTO & {
  params: {
    type: PromocodeType.WithComment
    prompt_i18n: LocalizedValue | null
  }
}
export type PromocodeWithLegalTextDTO = PromocodeDTO & {
  params: {
    type: PromocodeType.WithLegalText
    legal_text_i18n: LocalizedValue | null
  }
}

export type PromocodeRequestFilter = {
  search?: string
  is_active?: boolean
}

export type PromocodeRequestSorting = {
  sort_field?: keyof Promocode
  sort_reversed?: boolean
}

export type PromocodeState = EntityState<Promocode, PromocodeRequestFilter, PromocodeRequestSorting>

export function isPromocodeSimple(promocode: Promocode): promocode is PromocodeSimple {
  return (promocode as PromocodeSimple).params.type === 'simple'
}

export function isPromocodeWithComment(promocode: Promocode): promocode is PromocodeWithComment {
  return (promocode as PromocodeWithComment).params?.type === 'with_comment_and_prompt'
}

export function isPromocodeWithLegalText(promocode: Promocode): promocode is PromocodeWithLegalText {
  return (promocode as PromocodeWithLegalText).params?.type === 'with_legal_text'
}

export type PromocodeListProps = {
  promocodes: Promocode[]
  onRemove: (id: string) => void
  onCommentChange: (code: string, comment: string) => void
  selected?: string
  onSelect: (code: string) => void
}

export type PromocodeProps = {
  className?: string
  company_id?: string | null
  promocodes: { code: string; has_discount?: boolean }[]
  onApply: (code: string, promocode: Promocode) => void
  disabled?: boolean
}

export type State = {
  progress: Progress
  isCodeValid?: boolean
  promocode: string
}
export type Action =
  | {
      type: 'type'
      payload: string
    }
  | { type: 'apply:request' }
  | { type: 'apply:success' }
  | { type: 'apply:error' }
  | { type: 'reset' }
