import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { Progress } from 'modules/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'

import InventoryExchange from 'views/pages/InventoryExchange/routes'
import InventoryDetailsForm from 'views/pages/InventoryExchange/InventoryDetailsForm/InventoryDetailsForm'
import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import InventoryExchangeTransactionSelectors from 'modules/domain/inventoryExchangeTransaction/selectors'
import { useInventoryTransaction } from 'modules/domain/inventoryExchangeTransaction/hooks'
import InventoryTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const InventoryTransactionEdit: React.FC = () => {
  const { t } = useTranslation('inventoryTransaction')
  const page = useSelector(InventoryExchangeSelectors.page)
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const meta = useSelector(state => InventoryExchangeTransactionSelectors.meta(state, params.id))

  const [fetchProgress, transaction] = useInventoryTransaction(params.id)
  const onRemove = useAction(InventoryTransactionActions.removeRequested, params.id)
  // the page is read only
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = () => {}

  useHelmet({ title: t('editInventoryTransactionMetaTitle') })

  const goBack = () => {
    push({ query: { page }, route: InventoryExchange.TransactionsList })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !transaction) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={t('form.edit')} />
          <Id>{`ID ${transaction.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <InventoryDetailsForm
          mode={'read'}
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          onRemove={onRemove}
          initialValues={{
            producer_id: transaction.sku?.product?.producer_id || '',
            retailer_id: transaction.retailer_id || '',
            category: transaction.sku?.product?.category_id,
            subcategory: transaction.sku?.product?.subcategory_id,
            product_id: transaction.sku?.params.product_id || '',
            package_id: transaction.sku?.params?.package_id || '',
            sku_id: transaction.sku?.id || '',
            quantity: transaction.quantity || 0,
            comment: transaction.comment || '',
            // TODO will be used in future
            record_type: transaction.record_type || '',
            created_at: transaction.created_at || '',
            owner_name: transaction.owner_name || '',
            parent_id: transaction.parent_id || '',
          }}
        />
      </Layout.Content>
    </>
  )
}

export default InventoryTransactionEdit
