import React from 'react'
import IncentiveProgramActions from 'modules/domain/incentiveProgram/duck'
import { useSelector } from 'react-redux'
import IncentiveProgramSelectors from 'modules/domain/category/selectors'
import IncentiveProgramDetailsForm, {
  IncentiveProgramFormProps,
} from './components/IncentiveProgramDetailsForm/IncentiveProgramDetailsForm'
import IncentiveProgramRoutes from './routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const IncentiveProgramAdd: React.VFC = () => {
  const { t } = useTranslation('incentiveProgram')
  const submitAction = useAction(IncentiveProgramActions.addRequested)
  const addProgress = useSelector(IncentiveProgramSelectors.addProgress)

  const handleFormSubmit = (props: IncentiveProgramFormProps) => {
    submitAction(props)
  }

  const page = useSelector(IncentiveProgramSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: IncentiveProgramRoutes.List })
  }

  useHelmet({ title: t('addIncentiveProgramMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <IncentiveProgramDetailsForm progress={addProgress} onCancel={goBack} onSubmit={handleFormSubmit} />
      </Layout.Content>
    </>
  )
}

export default IncentiveProgramAdd
