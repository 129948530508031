import React, { useMemo } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FormikHook, Input } from '@agro-club/frontend-shared'

const InputStyled = styled(Input)`
  max-width: 320px;
`

const SlugForm: React.FC<{
  useFormik: FormikHook
  initialValue?: string
  disabled: boolean
  showLabel?: boolean
}> = ({ useFormik, initialValue = '', disabled, showLabel = false }) => {
  const { t } = useTranslation(['incentiveProgram', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        slug: Yup.string().matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('validation:slugFormatMismatched')),
      }),
    [t],
  )

  const formik = useFormik<{ slug: string }>({
    initialValues: {
      slug: initialValue,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
    validationSchema,
  })

  return (
    <InputStyled
      {...formik.getFieldProps('slug')}
      invalid={formik.touched.slug && !!formik.errors.slug}
      placeholder={t('form.placeholders.slug')}
      label={showLabel ? t('form.labels.slug') : undefined}
      errorText={formik.errors.slug}
      disabled={disabled}
      data-test-id="slug"
    />
  )
}

export default SlugForm
