import styled, { css, keyframes } from 'styled-components'
import {
  controlStyle,
  IconArrowBack,
  IconArrowLeftBold,
  IconMinus,
  IconPlus,
  CustomSelectStyles,
  IconSearch,
  IconCross,
} from '@agro-club/frontend-shared'

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export const SidebarContainer = styled.div`
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  border-top: 1px solid ${props => props.theme.color.outlineNeutral};
  border-right: 1px solid ${props => props.theme.color.outlineNeutral};
  background-color: ${props => props.theme.color.onPrimaryLight};
`

export const ScrollableContainer = styled.div`
  height: calc(100% - 161px);
  border-top: 1px solid ${props => props.theme.color.outlineNeutral};
  overflow-y: scroll;
  background-color: ${props => props.theme.color.onPrimaryLight};
`

export const SidebarItems = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchBlock = styled.div`
  padding: 20px 24px;
`

export const CollapsableBlockWrapper = styled.div`
  border-bottom: 0.5px solid ${props => props.theme.color.outlineNeutral};
  overflow-y: hidden;

  &:last-child {
    border-bottom: 0 none;
  }
`

export const CollapsableBlockTitleWrapper = styled.div`
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const CollapsableBlockTitle = styled.div`
  line-height: 24px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary300};
`

export const CollapsableMinusIcon = styled(IconMinus)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  fill: ${props => props.theme.color.secondary200};
`

export const CollapsablePlusIcon = styled(IconPlus)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  fill: ${props => props.theme.color.secondary200};
`

export const CollapsableBlockContent = styled.div<{ collapsed: boolean }>`
  display: ${props => (props.collapsed ? 'none' : 'block')};
  overflow-y: hidden;
`

export const ListWrapper = styled.div`
  display: flex;
  padding: 8px;
  gap: 4px;

  flex-direction: column;
  overflow-y: hidden;
`

export const IconButton = styled.button`
  display: flex;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: none;
  border: none;

  &:hover {
    background-color: ${props => props.theme.color.primary100};
    cursor: pointer;
  }
`

export const ItemWrapper = styled.div<{ isDragging: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
  flex-direction: row;
  background-color: ${props => props.theme.color.onPrimaryLight};
  cursor: ${props => (props.isDragging ? 'grabbing' : 'grab')};
  opacity: ${props => (props.isDragging ? '0' : '1')};
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    background-color: ${props => props.theme.color.primary25};
    ${IconButton} {
      opacity: 1;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${props => props.theme.color.primary300};
    `}
`

export const ItemTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

export const ItemDescription = styled.div`
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const ItemInner = styled.div`
  display: flex;
  width: 226px;
  flex-direction: column;
`

export const ItemArrowIcon = styled(IconArrowLeftBold)`
  width: 8px;
  height: 12px;
  transform: rotate(180deg);
  fill: ${props => props.theme.color.primary600};
`

export const ProducerFilterBlock = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid ${props => props.theme.color.outlineNeutral};
`

export const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '200px',
  }),
}

export const NoData = styled.div`
  text-align: center;
  padding: 20px 24px;
  color: ${props => props.theme.color.secondary200};
`

export const SidebarWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const Collapse = styled(IconArrowBack)`
  position: absolute;
  right: 4px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
  fill: ${props => props.theme.color.primary300};

  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`

export const Expand = styled(IconArrowBack)`
  position: absolute;
  right: -26px;
  top: 10px;
  transform: rotate(180deg);
  z-index: 2;
  cursor: pointer;
  fill: ${props => props.theme.color.primary300};

  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`

export const ItemIconSearch = styled(IconSearch)`
  fill: ${props => props.theme.color.primary600};
`

export const ItemIconCross = styled(IconCross)`
  fill: ${props => props.theme.color.primary600};
`

export const IconButtonWrapper = styled.div`
  right: 16px;
`
