import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import CategoryActions from './duck'
import CategorySelectors from './selectors'
import { Category } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import CategoryRoutes from 'views/pages/Categories/CategoryPage/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(CategorySelectors.page)
    const filter = yield select(CategorySelectors.filter)
    const sorting = yield select(CategorySelectors.sorting)
    const pageSize = yield select(CategorySelectors.pageSize)

    let response: ListResponse<Category> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(CategoryActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, CategoryRoutes.CategoryList, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(CategorySelectors.page)
    const filter = yield select(CategorySelectors.filter)
    const sorting = yield select(CategorySelectors.sorting)
    const pageSize = yield select(CategorySelectors.pageSize)
    const { data, total_count }: { data: Category[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(CategoryActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof CategoryActions.itemRequested>) {
  try {
    const company: Category = yield call(managers.getItem, id)
    yield put(CategoryActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof CategoryActions.addRequested>) {
  try {
    const { files = [], ...rest } = dto
    const contenders = files.filter(file => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const images: string[] = fileItems.filter(img => typeof img === 'string') as string[]
    const category: Category = yield call(managers.addItem, { ...rest, images })
    yield put(CategoryActions.addSucceed(category))
    yield put(push(generatePath(CategoryRoutes.CategoryEdit, { id: category.id })))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof CategoryActions.updateRequested>) {
  try {
    const { files = [], ...rest } = dto
    const contenders = files.filter(file => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const images: string[] = fileItems.filter(file => typeof file === 'string') as string[]
    const company: Category = yield call(managers.updateItem, id, { ...rest, images })
    yield put(CategoryActions.updateSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof CategoryActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(CategoryActions.removeSucceed(payload))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.removeFailed(payload, errType))
  }
}

export const fetchSubcategoryList = function*({
  payload: [companyId, parentId],
}: ReturnType<typeof CategoryActions.subcategoryListRequested>) {
  try {
    const subcategoryList = yield call(managers.getCompanySubCategories, companyId, parentId)
    yield put(CategoryActions.subcategoryListRequestSucceed(subcategoryList))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CategoryActions.subcategoryListRequestFailed(errType))
  }
}

const CategorySaga = function*() {
  yield all([
    takeLatest(CategoryActions.itemRequested.type, fetchItem),
    takeLatest(CategoryActions.listRequested.type, fetchList),
    takeLatest(CategoryActions.filterUpdated.type, fetchList),
    takeLatest(CategoryActions.sortingUpdated.type, fetchList),
    takeLatest(CategoryActions.filterHasBeenReset.type, fetchList),
    takeLatest(CategoryActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CategoryActions.listRequestedNext.type, fetchListNext),

    takeLatest(CategoryActions.addRequested.type, addItem),
    takeLatest(CategoryActions.updateRequested.type, updateItem),
    takeLatest(CategoryActions.removeRequested.type, removeItem),
    takeLatest(CategoryActions.subcategoryListRequested.type, fetchSubcategoryList),
  ])
}

export default CategorySaga
