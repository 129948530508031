import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { LocalizedValue, Status } from 'types/entities'

export type PromoInfoState = {
  items: Dict<PromoInfo>
  meta: Dict<EntityMetadata<PromoInfo>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  listFetchNextErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof PromoInfo, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: PromoInfoListRequestFilter
  sorting: PromoInfoListRequestSorting
  page: number
  total: number
  pageSize: number
}

export enum PromoInfoType {
  CatalogProducer = 'catalog_producer',
  CatalogCategory = 'catalog_category',
  CatalogSubcategory = 'catalog_subcategory',
  CartDiscount = 'cart_discount',
}

export type PromoInfoMessage = {
  title?: string
  description?: string
  title_i18n: LocalizedValue
  description_i18n: LocalizedValue
}

export type PromoInfo = {
  id: string
  producer_list: string[]
  title?: string
  producer_ids?: string[]
  category_ids?: string[]
  category_list: string[]
  status: Status
  description?: string
  title_i18n: LocalizedValue
  description_i18n: LocalizedValue
  type: PromoInfoType
  message: PromoInfoMessage
  styles?: {
    startColor: string
    endColor: string
    primaryColor: string
    secondaryColor: string
  }
}

export type PromoInfoListRequestFilter = {
  search?: string
  status?: Status
}

export type PromoInfoListRequestSorting = {
  sort_field?: keyof PromoInfo
  sort_reversed?: boolean
}

export type PromoInfoDTO = Omit<PromoInfo, 'id' | 'title' | 'description' | 'producer_list' | 'category_list'> & {
  id?: string
}
