import React from 'react'
import styled from 'styled-components'
import Routes from './routes'
import { HintText, LinkButton } from './styled'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/frontend-shared'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const StyledLink = styled(LinkButton)`
  margin-bottom: 10px;
  margin-top: 78px;
`

const ResetSucceed = () => {
  const { t } = useTranslation('auth')
  const email = useSelector(AuthSelectors.getResetEmail)
  useHelmet({ title: t('resetPasswordMetaTitle') })
  return (
    <Wrapper data-test-id={'reset-succeed'}>
      <HintText>{t('reset_succeed', { email })}</HintText>
      <StyledLink data-test-id={'back-to-login-link'} to={Routes.Login}>
        {t('back_to_login')}
      </StyledLink>
    </Wrapper>
  )
}

export default ResetSucceed
