import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter as Router } from 'connected-react-router'

import store from './modules'
import { history } from 'modules/store'
import GlobalStyle from 'views/globalStyles'
import RootComponent from 'views/RootComponent'
import { Sentry } from './sentry'
import analytics from 'analytics/initAnalytics'
import { AnalyticsProvider } from 'use-analytics'
import '@agro-club/frontend-shared/dist/esm/style.css'
import { HelmetContext, HelmetProps, useGTM } from '@agro-club/frontend-shared'
import env from 'env'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme/theme'

const GTM: React.FC = () => {
  useGTM(env.GTM_ID)
  return null
}

class App extends React.Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.handleReactException(error, errorInfo, store.getState())
  }

  state = {
    helmet: { favicons: {} },
    updateHelmet: (props: HelmetProps) =>
      this.setState({ helmet: { ...props, favicons: props.favicons ? props.favicons : this.state.helmet.favicons } }),
  }

  render() {
    return (
      <Provider store={store}>
        <GTM />
        <HelmetContext.Provider value={{ helmet: this.state.helmet, updateHelmet: this.state.updateHelmet }}>
          <AnalyticsProvider instance={analytics}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Router history={history}>
                <RootComponent />
              </Router>
            </ThemeProvider>
          </AnalyticsProvider>
        </HelmetContext.Provider>
      </Provider>
    )
  }
}

export default App
