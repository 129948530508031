import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ROLES } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { DistributorOrderFilterStatus } from 'types/distributorOrder'
import { SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'

export const agroStatuses = [
  DistributorOrderFilterStatus.New,
  DistributorOrderFilterStatus.Changed,
  DistributorOrderFilterStatus.Canceled,
  DistributorOrderFilterStatus.Deleted,
  DistributorOrderFilterStatus.Confirmed,
  DistributorOrderFilterStatus.Completed,
]

export const producerStatuses = [
  DistributorOrderFilterStatus.New,
  DistributorOrderFilterStatus.Changed,
  DistributorOrderFilterStatus.Canceled,
  DistributorOrderFilterStatus.Confirmed,
  DistributorOrderFilterStatus.Completed,
]

export const distributorStatuses = [
  DistributorOrderFilterStatus.New,
  DistributorOrderFilterStatus.Changed,
  DistributorOrderFilterStatus.Canceled,
  DistributorOrderFilterStatus.Confirmed,
  DistributorOrderFilterStatus.Completed,
]

export type DistributorOrderStatusSelectProps = Omit<SimpleSelectProps, 'options'>

const DistributorOrderStatusSelect: React.VFC<DistributorOrderStatusSelectProps> = ({ value, onChange, ...props }) => {
  const { t } = useTranslation(['distributorOrder'])
  const role = useSelector(AuthSelectors.role)
  const optionsByRole: DistributorOrderFilterStatus[] = useMemo(() => {
    let result: DistributorOrderFilterStatus[] = []
    switch (role) {
      case ROLES.AGRO_ADMIN:
        result = agroStatuses
        break
      case ROLES.PRODUCER_HEAD:
      case ROLES.PRODUCER_MANAGER:
        result = producerStatuses
        break
      case ROLES.DISTRIBUTOR_HEAD:
      case ROLES.DISTRIBUTOR_MANAGER:
        result = distributorStatuses
    }
    return result
  }, [role])

  const selected = Array.isArray(value) ? value[0] : value

  const options = useMemo(() => {
    return [
      { id: 'undefined', title: t(`status.any`) },
      ...optionsByRole.map(item => ({ id: item, title: t(`status.full.${item}`) })),
    ]
  }, [optionsByRole, t])

  return (
    <div data-test-id="farmer-order-status-select">
      <SimpleSelect
        {...props}
        value={selected}
        options={options}
        placeholder={t('status.any')}
        onChange={value => onChange(value === 'undefined' ? undefined : value)}
      />
    </div>
  )
}

export default DistributorOrderStatusSelect
