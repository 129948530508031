import React, { useCallback, useMemo } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Progress, useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import ReconciliationSelectors from 'modules/domain/reconciliation/selectors'
import ReconciliationActions from 'modules/domain/reconciliation/duck'
import { useReconciliationGroups } from 'modules/domain/reconciliation/hooks'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import ReconciliationGroupsFilters from './ReconciliationGroupsFilters'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import { GroupCommentCell, InteractionCell, RetailerNameCell, RowsCell, StatusCell } from './ReconciliationTabelCells'
import { Row } from 'react-table'
import { generatePath } from 'modules/utils/helpers/generatePath'
import { ReconciliationList } from 'modules/domain/reconciliation/types'
import { useHistory } from 'react-router-dom'

const ReconciliationGroupsTable: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('reconciliation')
  const [progress, data = []] = useReconciliationGroups()
  const total = useSelector(ReconciliationSelectors.groupsTotal)
  const page = useSelector(ReconciliationSelectors.groupsPage)
  const pages = useSelector(ReconciliationSelectors.groupsPages)
  const pageSize = useSelector(ReconciliationSelectors.groupsPageSize)
  const listRequested = useAction(ReconciliationActions.groupsRequested)
  const updateRetailerStatusAction = useAction(ReconciliationActions.retailerStatusUpdateRequested)
  const handleRetailerDelete = (producerId: string, retailerId: string) => {
    updateRetailerStatusAction({ manufacturer_id: producerId, retailer_id: retailerId, isForced: false })
  }

  const allColumns = useMemo(
    () => [
      { width: 100, Header: t('headers.status'), Cell: StatusCell },
      {
        Header: t('headers.retailer'),
        Cell: RetailerNameCell,
      },
      { Header: t('headers.comment'), Cell: GroupCommentCell },
      { accessor: 'sku' as const, Header: t('headers.rows'), Cell: RowsCell },
      {
        width: '3%',
        accessor: 'is_forced' as const,
        Header: t('headers.interaction'),
        Cell: props => <InteractionCell onDelete={handleRetailerDelete} {...props} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleRowClick = useCallback((row: Row<ReconciliationList>) => {
    history.push(
      generatePath(ReconciliationRoutes.Reconciliation, {
        manufacturer_id: row.original.manufacturer.id,
        retailer_id: row.original.retailer.id,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { scrollRef } = usePersistentScroll('reconciliationGroupsTable', progress === Progress.SUCCESS)

  return (
    <TComponents.Wrapper>
      <ReconciliationGroupsFilters />
      <CompoundedTable
        columns={allColumns}
        data={data}
        progress={progress}
        total={total}
        currentPage={page}
        pageSize={pageSize}
        onSetPage={fetchNextItems}
        pages={pages}
        onRowClick={handleRowClick}
        ref={scrollRef}
      />
    </TComponents.Wrapper>
  )
}

export default ReconciliationGroupsTable
