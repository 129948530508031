import { defaultTheme } from '@agro-club/frontend-shared'

export const theme = {
  ...defaultTheme,
  size: {
    ...defaultTheme.size,
    minPageWidth: 1280,
    minBarWidth: 70,
    minOrderListWidth: 738,
  },
  color: {
    ...defaultTheme.color,
    availabilityColors: {
      very_low: '#D11922',
      low: '#FF8A00',
      medium: '#FFCC00',
      high: '#34C759',
    },
    statusColors: {
      active: '#34C759',
      inactive: '#FF8A00',
      deleted: '#D11922',
    },
  },
}
