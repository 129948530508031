import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { FetchError } from 'modules/domain/types'
import { ConfigState, WhiteLabelConfig } from './types'
import { DEFAULT_LANGUANGE } from 'modules/constants'

const initialState: ConfigState = {
  config: {},
  language: DEFAULT_LANGUANGE,
  configFetchError: null,
  configFetchProgress: Progress.IDLE,

  languageInitProgress: Progress.IDLE,
}

class ConfigReducer extends ImmerReducer<ConfigState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  configRequested(_domain: string) {
    this.draftState.configFetchProgress = Progress.WORK
    this.draftState.configFetchError = null
  }
  configRequestSucceed(config: WhiteLabelConfig) {
    this.draftState.configFetchProgress = Progress.SUCCESS
    this.draftState.configFetchError = null
    this.draftState.config = config
  }
  configRequestFailed(error: FetchError) {
    this.draftState.configFetchProgress = Progress.ERROR
    this.draftState.configFetchError = error
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  languageInit(_configLang?: string) {
    this.draftState.languageInitProgress = Progress.WORK
  }
  languageInitSucceed(lang: string) {
    this.draftState.language = lang
    this.draftState.languageInitProgress = Progress.SUCCESS
  }
  languageInitFailed() {
    this.draftState.languageInitProgress = Progress.ERROR
  }

  languageChanged(lang: string) {
    this.draftState.language = lang
  }
}

export const ConfigActions = createActionCreators(ConfigReducer)
export default ConfigActions
export const reducer = createReducerFunction(ConfigReducer, initialState)
