import styled, { keyframes, StyledProps } from 'styled-components'
import {
  IconCrossCircle,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  helpersColor,
  CurrencyInput as CurrencyInputComponent,
  Input,
  CustomSelectStyles,
} from '@agro-club/frontend-shared'

export const VerticalInputsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 256px 256px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 16px;
`

export const LanguagesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

export const PermissionsGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 16px;

  & > * {
    text-transform: capitalize;
  }
`

export const SelectWrapper = styled.div<StyledProps<{ visible?: boolean }>>`
  display: block;
  visibility: ${({ visible = true }) => (visible ? 'visible' : 'collapse')};
  max-width: 500px;
`

export const ParamsContainer = styled.div`
  display: grid;
  grid-gap: 28px;
  width: fit-content;
`

export const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

export const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, auto);
  grid-gap: 16px;

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

const removeOverlayAnimation = keyframes`
  to {
    opacity: 1;
  }
`
export const RemoveOverlay = styled.div`
  border-radius: 5px;
  pointer-events: none;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  position: absolute;
  left: -5px;
  top: -5px;
  background-color: ${props => helpersColor.alphaColor(props.theme.color.accentDestructive, 0.2)};
  opacity: 0;
  animation: ${removeOverlayAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`

export const AddButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
  outline: none;
  display: inline-flex;
  border: 0;
  background: none;
  width: auto;
  transition: opacity 0.2s ease-in;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const Error = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -5px;
`
export const LanguagesContainer = styled.div`
  position: relative;
  padding-bottom: 16px;
`

export const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '200px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    maxHeight: '60vh',
    width: '100%',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

export const CurrencyInput = styled(CurrencyInputComponent)`
  min-width: 150px;
`

export const PercentInput = styled(Input)`
  min-width: 150px;
  & > input {
    height: auto;
    padding: 5px 8px;
  }
`

export const TierTitle = styled.h4`
  margin: 0;
  grid-column: 1 / 4;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

export const GiftSelectWrapper = styled.div`
  grid-column: 1 / 4;
`

export const VerticalLinkContainer = styled.div`
  display: grid;
  grid-gap: 20px;
`
