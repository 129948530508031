import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  InventoryTransferRequestSorting,
  InventoryTransferRequestFilter,
  InventoryTransferRequest,
  InventoryTransferRequestCountFilter,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: InventoryTransferRequestFilter,
  sorting: InventoryTransferRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<InventoryTransferRequest>>(endpoints.inventoryTransferRequests(), {
    ...filter,
    ...{ sort_field: 'created_at', sort_reversed: 1, ...sorting },
    page: page,
    page_size: pageSize,
  })
}
export const updateItem = (id: string, dto: Partial<InventoryTransferRequest>) => {
  return apiClient.put<InventoryTransferRequest>(endpoints.inventoryTransferRequests(id), dto)
}

export const requestTrasferCount = async (filter: InventoryTransferRequestCountFilter) => {
  const response = await apiClient.get<InventoryTransferRequestCountFilter>(
    endpoints.inventoryExchangeTransferRequest('count'),
    filter,
  )

  return response
}
