import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import InventoryOutTransferRequestSelectors from 'modules/domain/inventoryOutTransferRequest/selectors'
import { useInventoryOutTransferRequestList } from 'modules/domain/inventoryOutTransferRequest/hooks'
import TransferRequestList, { PageTypes } from './TransferRequestList'
import { InventoryOutTransferRequestActions } from 'modules/domain/inventoryOutTransferRequest/duck'

const TransferOutRequestsTable: React.VFC = () => {
  const { t } = useTranslation('inventorySKU')
  useHelmet({ title: t('inventoryMetaTitle') })

  const [progress, data = []] = useInventoryOutTransferRequestList()

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Title title={t('inventoryTransferRequest:outRequests')} />
        </Header.TabsRoot>
      </Layout.Header>
      <Layout.Content>
        <TransferRequestList
          selectors={InventoryOutTransferRequestSelectors}
          actions={InventoryOutTransferRequestActions}
          pageType={PageTypes.outRequests}
          progress={progress}
          data={data}
        />
      </Layout.Content>
    </>
  )
}

export default TransferOutRequestsTable
