import React, { useCallback, useMemo } from 'react'
import { FormikHook, Input } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Label } from 'views/pages/Company/CompanyDetailsForm/components/BranchesStyles'
import { LocalizedValue } from 'types/entities'
import * as Styled from './styled'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'

export type URLFormProps = {
  link_label: string
  link_url: FileItem[]
}

const URLForm: React.FC<{
  lang: keyof LocalizedValue
  link_label?: LocalizedValue
  link_url?: LocalizedValue<FileItem[]>
  useFormik: FormikHook
}> = ({ lang, link_label = {}, link_url = {}, useFormik }) => {
  const { t } = useTranslation(['discountRules', 'common'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        link_label: Yup.string(),
      }),
    [],
  )

  const formik = useFormik<URLFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      link_label: link_label[lang] || '',
      link_url: link_url[lang] || [],
    },
    validationSchema,
    enableReinitialize: true,
  })

  const handleFilesChange = useCallback(
    (items: FileItem[]) => {
      formik.setFieldValue('link_url', items)
    },
    [formik],
  )

  return (
    <Styled.VerticalLinkContainer>
      <Input
        {...formik.getFieldProps('link_label')}
        label={t('form.labels.urlTitle', { lang: `(${lang})` })}
        invalid={formik.touched.link_label && !!formik.errors.link_label}
        errorText={formik.errors.link_label}
        limit={30}
      />
      <div>
        <Label>{t('form.labels.urlLink', { lang: `(${lang})` })}</Label>
        <FileManager items={formik.values.link_url || []} onChange={handleFilesChange} limit={1} />
      </div>
    </Styled.VerticalLinkContainer>
  )
}

export default URLForm
