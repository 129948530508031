import React, { useCallback, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { usePackageTypesList } from 'modules/domain/packageTypes/hooks'
import { useSelector } from 'react-redux'
import { CellProps, useTable } from 'react-table'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import PackageTypesSelectors from 'modules/domain/packageTypes/selectors'
import PackageTypesActions from 'modules/domain/packageTypes/duck'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import { PackageTypes, PackageTypesListRequestFilter } from 'modules/domain/packageTypes/types'
import StatusFilter from 'views/components/TableFilters/StatusFilter'
import * as Styled from './styled'

const IdColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('packageTypes')
  return (
    <div>
      <Styled.DefaultStatus />
      <span>{t('packageTypes:fields.id')}</span>
    </div>
  )
}

const IdCell: React.FC<CellProps<PackageTypes>> = ({ cell, column, row }) => {
  return (
    <Styled.IdCell key={column.id}>
      <Styled.Status status={row.original.status} />
      {`${cell.value || ''}`}
    </Styled.IdCell>
  )
}

const StorefrontPackageTypesList: React.FC = () => {
  const { t } = useTranslation(['packageTypes', 'labels'])
  const push = useHistoryPush()
  const [progress, data = []] = usePackageTypesList()
  const total = useSelector(PackageTypesSelectors.total)
  const page = useSelector(PackageTypesSelectors.page)
  const pages = useSelector(PackageTypesSelectors.pages)
  const pageSize = useSelector(PackageTypesSelectors.pageSize)

  const filter = useSelector(PackageTypesSelectors.filter)
  const { status } = filter
  const filterUpdated = useAction(PackageTypesActions.filterUpdated)

  const listRequested = useAction(PackageTypesActions.listRequested)
  const { scrollRef } = usePersistentScroll('packageTypesTable')

  const allColumns = useMemo(
    () => [
      {
        Header: IdColumnHeaderCell,
        accessor: 'id' as const,
        sortable: false,
        Cell: IdCell,
      },
      {
        Header: t('packageTypes:fields.title'),
        accessor: 'title' as const,
        sortable: false,
      },
      {
        Header: t('packageTypes:fields.conversionRate'),
        accessor: 'conversion_rate' as const,
        sortable: false,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<PackageTypes>({
    columns: allColumns,
    data,
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleFilterChange = (newFilterValue: Partial<PackageTypesListRequestFilter>) => {
    filterUpdated({ ...filter, ...newFilterValue })
  }

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <StatusFilter filterValue={{ status }} handleFilterChange={handleFilterChange} />
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
        data-test-id="package-types-table"
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: StorefrontRoutes.PackageTypesEdit, params: { id: row.original.id } })
                }}
                data-test-id="package-types-row"
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default StorefrontPackageTypesList
