import React, { useMemo, useState } from 'react'
import { Input, TagList } from '@agro-club/frontend-shared'

const InputWithTags: React.FC<{
  items: string[]
  onAdd: (val: string) => void
  onRemove: (id: string) => void
  label?: string
  placeholder?: string
  required?: boolean
}> = ({ items, onAdd, onRemove, label = '', placeholder = '', required }) => {
  const [text, setText] = useState('')
  const tagsList = useMemo(() => items.map((item: string, idx: number) => ({ id: `${idx}`, title: item })), [items])
  return (
    <>
      <Input
        value={text}
        onChange={(_, val) => setText(val)}
        label={label}
        placeholder={placeholder}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onAdd(text)
            setText('')
          }
        }}
        required={required}
      />
      <TagList list={tagsList} onRemove={tag => onRemove(tag.id)} />
    </>
  )
}

export default InputWithTags
