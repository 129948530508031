import { Dict } from 'types/generics'
import { GlobalFiltersFieldsType, Progress } from 'modules/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { ReturnDeclaration, ReturnDeclarationStatus } from 'types/returnDeclaration'

export type ReturnDeclarationState = {
  items: Dict<ReturnDeclaration>
  meta: Dict<EntityMetadata<ReturnDeclaration>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  listFetchNextErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof ReturnDeclaration, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string
  printProgress: Progress
  printError: any
  declarationCountFetchProgress: Progress
  declarationCountFetchError: any
  declarationCount: number | null

  filter: ReturnDeclarationListRequestFilter
  sorting: ReturnDeclarationListRequestSorting
  page: number
  total: number
  pageSize: number
}

export type ReturnDeclarationListRequestFilter = {
  search?: string
  status?: ReturnDeclarationStatus
  end_date?: string
  start_date?: string
  distributor_id?: string[]
  type?: ReturnDeclarationType
} & Pick<GlobalFiltersFieldsType, 'season_id'>

export type ReturnDeclarationListRequestSorting = {
  sort_field?: keyof ReturnDeclaration
  sort_reversed?: boolean
}

export type ReturnDeclarationDTO = Omit<ReturnDeclaration, 'id' | 'producer' | 'owner_id' | 'slug' | 'season'> & {
  id?: string
}

export type PrintDeclarationDto = {
  dealerName?: string
  pickUpLocation: string
  date: string
  products: {
    variety: string
    seedTreatment: string
    lot: string
    count: number
    unitSize: string
  }[]
}

// todo this in unused type
export type CreateReturnDeclarationItem = {
  product_id: string
  quantity: number
}

// todo this in unused type
export type CreateReturnDeclarationDTO = Pick<
  ReturnDeclaration,
  'status' | 'producer_id' | 'distributor_id' | 'comment'
> & { items: ReturnDeclarationDTO[] }

export enum ReturnDeclarationType {
  InSeason = 'in_season',
  EndOfSeason = 'end_of_season',
}

export type DeclarationCountDto = {
  distributor_id?: string
  season_id?: string
  type?: ReturnDeclarationType
  status?: 'submitted' | 'new' | 'deleted' | 'nothing_to_declare'
}
