import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Currency } from 'types/entities'
import styled from 'styled-components'
import { getPrettyPrice } from 'modules/utils/helpers'
import {
  SectionBody,
  SectionTable,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTableBody,
  SectionTitle,
  SectionFooter,
  ProductWizardType,
  ProcoteWizardData,
  OptionalNumber,
} from '@agro-club/frontend-shared'
import * as Styled from 'views/components/OrderProductItems/styles'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import useLangPicker from 'hooks/useLangPicker'
import { DuckFootWizardData } from '../ProductWizards/ProductWizardDuckFoot/DuckFootWizardData'
import { isNil } from 'ramda'
import { getStandardUnitsQty, getUniqItemQty, getUniqItems } from './helpers'
import { useProductsLimit } from 'modules/domain/allocation/hooks'
import ReadOnlyOptions from '../ReadOnlyOptions/ReadOnlyOptions'

export const Label = styled.span`
  color: ${props => props.theme.color.onPrimaryDark};
`

export type OrderCardItemsReadOnlyProps = {
  sku_items: FarmerOrderSkuItem[]
  currency?: Currency
  showFinalQty?: boolean
  showShippedQty?: boolean
  orderTotal?: number
  total?: string
  netTotal?: string
  distributorId?: string
  seasonId?: string
  mode?: 'create' | 'edit'
  context: 'farmer' | 'distributor'
  setIsProductQtyMoreThanLimit?: (isQtyMoreThanLimit: boolean) => void
}

const OrderSkuCardItemsReadOnly: React.FC<OrderCardItemsReadOnlyProps> = ({
  sku_items,
  total,
  netTotal,
  children,
  showFinalQty = false,
  showShippedQty = false,
  distributorId,
  seasonId,
  mode,
  context,
  setIsProductQtyMoreThanLimit,
}) => {
  const { t } = useTranslation(['farmerOrder', 'packageTypes', 'common'])
  const { pick } = useLangPicker()

  const hasPrices = useMemo(() => !!sku_items?.length && sku_items.some(item => !!item.sku?.price), [sku_items])
  const netTotalJSX = netTotal && (
    <Styled.Total>
      {t('form.netTotal')} <Styled.TotalValue>{netTotal}</Styled.TotalValue>
    </Styled.Total>
  )

  const uniqItems = getUniqItems(sku_items)

  const productsFromItems = useMemo(() => {
    return sku_items.map(item => ({
      product_id: item.sku_card?.product_id as string,
    }))
  }, [sku_items])

  const params = useMemo(
    () => ({
      distributorId: distributorId || '',
      seasonId: seasonId || '',
      products: productsFromItems,
    }),
    [distributorId, seasonId, productsFromItems],
  )

  const [, productsLimit = []] = useProductsLimit(context, params)
  const productWithLimit = useMemo(
    () =>
      productsLimit.find(
        item => !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity,
      ),
    [productsLimit, uniqItems],
  )

  useEffect(() => {
    if (productsLimit) {
      setIsProductQtyMoreThanLimit?.((mode === 'edit' && productWithLimit?.is_limit_exceeded) || false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLimit])

  return (
    <Styled.Container>
      <SectionTitle>{t('form.sectionHeaders.orders')}</SectionTitle>
      <SectionBody>
        <SectionTable>
          <SectionTableHead>
            <SectionTableHeadRow>
              <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
              {hasPrices && <SectionTableHeadCell>{t('form.tableHeaders.price')}</SectionTableHeadCell>}
              <SectionTableHeadCell>{t('form.tableHeaders.options')}</SectionTableHeadCell>
              <SectionTableHeadCell style={{ textAlign: 'center' }}>
                {t('form.tableHeaders.quantity')}
              </SectionTableHeadCell>
              {showFinalQty && (
                <SectionTableHeadCell style={{ textAlign: 'center' }}>
                  {t('form.tableHeaders.finalQty')}
                </SectionTableHeadCell>
              )}
              {showShippedQty && context === 'distributor' && (
                <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.shippedQty')}</SectionTableHeadCell>
              )}
              <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.comment')}</SectionTableHeadCell>
            </SectionTableHeadRow>
          </SectionTableHead>
          <SectionTableBody>
            {sku_items.map(item => {
              const cardTitle = item.card
                ? `${item.card?.subcategory?.title} / ${item.card?.title}`
                : `${item.sku_card?.subcategory?.title} / ${pick(item.sku_card?.title_i18n)}`
              const packageItem = item.sku?.params.package
              const itemQty = OptionalNumber(item.quantity)
              const standardUnitsVol = getStandardUnitsQty(itemQty, item.sku?.product?.units, packageItem)
              const standardUnitsFinalVol = getStandardUnitsQty(itemQty, item.sku?.product?.units, packageItem)

              const standardUnitsShippedVol = getStandardUnitsQty(
                OptionalNumber(item.shipped_qty),
                item.sku?.product?.units,
                packageItem,
              )

              return (
                <SectionTableBodyRow key={item.id}>
                  <SectionTableBodyCell>
                    <Styled.CardTitle>{cardTitle}</Styled.CardTitle>
                  </SectionTableBodyCell>
                  {hasPrices && (
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                      {getPrettyPrice(Number(item.sku?.price), item.sku?.product?.currency)}
                    </SectionTableBodyCell>
                  )}
                  <SectionTableBodyCell width={300}>
                    <Styled.PackageType>
                      <b>{t('packageTypes:labels.package')}: </b>
                      {`${pick(packageItem?.title_i18n)}`}
                    </Styled.PackageType>
                    <ReadOnlyOptions options={item.sku?.params.options} />
                    {item.wizard_data?.type == ProductWizardType.Procote && (
                      <ProcoteWizardData data={item.wizard_data} />
                    )}
                    {item.wizard_data?.type == ProductWizardType.DuckFoot && item.wizard_comment && (
                      <DuckFootWizardData note={item.wizard_comment} />
                    )}
                  </SectionTableBodyCell>
                  <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {item.quantity}
                    <Styled.FormattedQty>{standardUnitsVol}</Styled.FormattedQty>
                  </SectionTableBodyCell>
                  {showFinalQty && (
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {item.final_qty || '-'}
                      <Styled.FormattedQty>{standardUnitsFinalVol}</Styled.FormattedQty>
                    </SectionTableBodyCell>
                  )}
                  {showShippedQty && (
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {OptionalNumber(item.shipped_qty || '').toFixed(1)}
                      <Styled.FormattedQty>{standardUnitsShippedVol}</Styled.FormattedQty>
                    </SectionTableBodyCell>
                  )}
                  <SectionTableBodyCell>{item.comment || ''}</SectionTableBodyCell>
                </SectionTableBodyRow>
              )
            })}
          </SectionTableBody>
        </SectionTable>
        {hasPrices && !!total && (
          <SectionFooter>
            <Styled.TotalWrap>
              <Styled.Total>
                {t('form.total')} <Styled.TotalValue>{total}</Styled.TotalValue>
              </Styled.Total>
              {netTotalJSX}
            </Styled.TotalWrap>
          </SectionFooter>
        )}
      </SectionBody>
      {children}
    </Styled.Container>
  )
}

export default OrderSkuCardItemsReadOnly
