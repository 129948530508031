import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { FilterProps, TableFilter } from 'views/components/TableFilters/TableFilters'
import { Status } from 'types/entities'
import { ChipColor } from '@agro-club/frontend-shared'

const AVAILABLE_STATUSES: { status: Status; color: ChipColor }[] = [
  {
    status: Status.Active,
    color: 'green',
  },
  {
    status: Status.Inactive,
    color: 'orange',
  },
  {
    status: Status.Deleted,
    color: 'red',
  },
]

type StatusFilterProps<TShape, TField extends keyof TShape, TDisableMulti> = Omit<
  FilterProps<TShape, TField, Status, TDisableMulti>,
  'options' | 'title'
>

export default function StatusFilter<TShape, TField extends keyof TShape, TDisableMulti>(
  props: StatusFilterProps<TShape, TField, TDisableMulti>,
) {
  const { t } = useTranslation(['common', 'labels'])

  const options = useMemo(
    () =>
      AVAILABLE_STATUSES.map(status => ({
        value: status.status,
        title: t(`statuses.${status.status}`),
        bullet: true,
        color: status.color,
      })),
    [t],
  )

  return <TableFilter options={options} title={t('labels:status')} {...props} />
}
