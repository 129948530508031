import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import ChangeLogSelectors from './selectors'
import { all, takeLatest } from 'redux-saga/effects'
import managers from './managers'
import ChangeLogActions from './duck'

const sagas = createBasicSagas({
  selectors: ChangeLogSelectors,
  actions: ChangeLogActions,
  managers,
})

const ChangeLogSaga = function*() {
  yield all([
    takeLatest(ChangeLogActions.itemRequested.type, sagas.fetchItem),
    takeLatest(ChangeLogActions.listRequested.type, sagas.fetchList),
    takeLatest(ChangeLogActions.filterUpdated.type, sagas.fetchList),
  ])
}

export default ChangeLogSaga
