import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ReturnDeclarationActions from './duck'
import ReturnDeclarationSelectors from './selectors'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import ReturnDeclarationRoutes from 'views/pages/ReturnDeclaration/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ReturnDeclaration } from 'types/returnDeclaration'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(ReturnDeclarationSelectors.page)
    const filter = yield select(ReturnDeclarationSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(ReturnDeclarationActions.filtersUpdatedWithPersistentStorage(filterUpdated))
    const sorting = yield select(ReturnDeclarationSelectors.sorting)
    const pageSize = yield select(ReturnDeclarationSelectors.pageSize)

    let response: ListResponse<ReturnDeclaration> = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ReturnDeclarationActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, ReturnDeclarationRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ReturnDeclarationSelectors.page)
    const filter = yield select(ReturnDeclarationSelectors.filter)
    const sorting = yield select(ReturnDeclarationSelectors.sorting)
    const pageSize = yield select(ReturnDeclarationSelectors.pageSize)
    const { data, total_count }: { data: ReturnDeclaration[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ReturnDeclarationActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ReturnDeclarationActions.itemRequested>) {
  try {
    const item: ReturnDeclaration = yield call(managers.getItem, id)
    yield put(ReturnDeclarationActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({
  payload: [dto, duplicate],
}: ReturnType<typeof ReturnDeclarationActions.addRequested>) {
  try {
    const item: ReturnDeclaration = yield call(managers.addItem, { ...dto, pickup_address: dto.pickup_note })
    yield put(ReturnDeclarationActions.addSucceed(item))
    if (!duplicate) {
      yield put(push(generatePath(ReturnDeclarationRoutes.Edit, { id: item.id })))
    }
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function*({
  payload: [id, dto],
}: ReturnType<typeof ReturnDeclarationActions.updateRequested>) {
  try {
    const item: ReturnDeclaration = yield call(managers.updateItem, id, { ...dto, pickup_address: dto.pickup_note })
    yield put(ReturnDeclarationActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof ReturnDeclarationActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(ReturnDeclarationActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.removeFailed(payload, type, detail))
  }
}

export const printDeclaration = function*({ payload }: ReturnType<typeof ReturnDeclarationActions.printRequested>) {
  try {
    yield call(managers.printDeclaration, payload)
    yield put(ReturnDeclarationActions.printSucceed())
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.printFailed(type))
  }
}

export const fetchDeclarationCount = function*({
  payload,
}: ReturnType<typeof ReturnDeclarationActions.countRequested>) {
  try {
    const declarationCount = yield call(managers.getCount, payload)
    yield put(ReturnDeclarationActions.countRequestedSucceed(declarationCount))
  } catch (err) {
    const { errors } = RequestError.parseError(err)
    yield put(ReturnDeclarationActions.countRequestedFailed(errors))
  }
}

const ReturnDeclarationSaga = function*() {
  yield all([
    takeLatest(ReturnDeclarationActions.itemRequested.type, fetchItem),
    takeLatest(ReturnDeclarationActions.listRequested.type, fetchList),
    takeLatest(ReturnDeclarationActions.filterUpdated.type, fetchList),
    takeLatest(ReturnDeclarationActions.sortingUpdated.type, fetchList),
    takeLatest(ReturnDeclarationActions.filterHasBeenReset.type, fetchList),
    takeLatest(ReturnDeclarationActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ReturnDeclarationActions.listRequestedNext.type, fetchListNext),

    takeLatest(ReturnDeclarationActions.addRequested.type, addItem),
    takeLatest(ReturnDeclarationActions.updateRequested.type, updateItem),
    takeLatest(ReturnDeclarationActions.removeRequested.type, removeItem),
    takeLatest(ReturnDeclarationActions.printRequested.type, printDeclaration),
    takeLatest(ReturnDeclarationActions.countRequested.type, fetchDeclarationCount),
  ])
}

export default ReturnDeclarationSaga
