import { Entity, EntityMetadata, EntityState, FetchError } from 'modules/domain/types'
import { AppGlobalState, Progress } from 'modules/types'
import { createSelector } from 'reselect'
import { Dict } from 'types/generics'

type State<EntityType extends Entity, EntityListFilter, EntityListSorting, Key extends keyof AppGlobalState> = {
  [key in Key]: EntityState<EntityType, EntityListFilter, EntityListSorting>
}

export type SelectorWithId<ReturnType, StateType = any> = (id?: string) => (state: StateType) => ReturnType

export type BasicSelector<ReturnType, StateType = any> = (state: StateType) => ReturnType

export type SelectorsForListHooks<EntityType extends Partial<Entity>, EntityListFilter> = {
  filter: BasicSelector<EntityListFilter>
  list: BasicSelector<EntityType[]>
  listFetchProgress: BasicSelector<Progress>
  page: BasicSelector<number>
  pageSize: BasicSelector<number>
}

export type SelectorsForNuclearHooks<EntityType extends Partial<Entity>> = {
  meta: SelectorWithId<EntityMetadata<EntityType | undefined>>
  item: SelectorWithId<EntityType | undefined>
}

export type CreateBasicSelectorsReturnType<
  EntityType extends Entity,
  EntityListFilter,
  EntityListSorting,
  Key extends keyof AppGlobalState = keyof AppGlobalState,
  StateType extends State<EntityType, EntityListFilter, EntityListSorting, Key> = State<
    EntityType,
    EntityListFilter,
    EntityListSorting,
    Key
  >
> = {
  filter: BasicSelector<Partial<EntityListFilter>, StateType>
  sorting: BasicSelector<Partial<EntityListSorting>, StateType>
  page: BasicSelector<number, StateType>
  pageSize: BasicSelector<number, StateType>
  total: BasicSelector<number, StateType>
  updateProgress: BasicSelector<Progress, StateType>
  updateErrorDetail: BasicSelector<string | null, StateType>
  removeProgress: BasicSelector<Progress, StateType>
  removeErrorDetail: BasicSelector<string | null, StateType>
  addProgress: BasicSelector<Progress, StateType>
  addErrorDetail: BasicSelector<string | null, StateType>
  item: SelectorWithId<EntityType | undefined, StateType>
  meta: SelectorWithId<EntityMetadata<EntityType>, StateType>
  itemFetchProgress: BasicSelector<Progress, StateType>
  listFetchProgress: BasicSelector<Progress, StateType>
  listFetchError: BasicSelector<FetchError | null, StateType>
  itemFetchErrorDetail: BasicSelector<string | null, StateType>
  list: BasicSelector<EntityType[], StateType>
  dict: BasicSelector<Dict<EntityType>, StateType>
  ids: BasicSelector<string[], StateType>
  hasNext: BasicSelector<boolean, StateType>
  listFetchNextProgress: BasicSelector<Progress, StateType>
  listFetchNextError: BasicSelector<FetchError | null, StateType>
  listFetchNextErrorDetail: BasicSelector<string | null, StateType>
  pages: BasicSelector<number, StateType>
}

export const createBasicSelectors = <
  Key extends keyof AppGlobalState = keyof AppGlobalState,
  EntityType extends Entity = AppGlobalState[Key] extends EntityState<infer T, any, any>
    ? T extends Entity
      ? T
      : never
    : never,
  EntityListFilter = AppGlobalState[Key] extends EntityState<any, infer T, any> ? T : never,
  EntityListSorting = AppGlobalState[Key] extends EntityState<any, any, infer T> ? T : never
>(
  stateKey: Key,
): CreateBasicSelectorsReturnType<EntityType, EntityListFilter, EntityListSorting> => {
  const dict = state => state[stateKey].items
  const ids = state => state[stateKey].ids
  const listFetchProgress = state => state[stateKey].listFetchProgress

  return {
    filter: state => state[stateKey].filter,
    sorting: state => state[stateKey].sorting,
    page: state => state[stateKey].page,
    total: state => state[stateKey].total,
    updateProgress: state => state[stateKey].updateProgress,
    updateErrorDetail: state => state[stateKey].updateErrorDetail,
    removeProgress: state => state[stateKey].removeProgress,
    removeErrorDetail: state => state[stateKey].removeErrorDetail,
    addProgress: state => state[stateKey].addProgress,
    addErrorDetail: state => state[stateKey].addErrorDetail,
    hasNext: state => state[stateKey].total > state[stateKey].ids.length,
    pageSize: state => state[stateKey].pageSize,
    pages: state => Math.ceil(state[stateKey].total / state[stateKey].pageSize),
    dict,
    ids,
    item: id => state => (id && state[stateKey].items[id] ? state[stateKey].items[id] : undefined),
    meta: id => state =>
      id && state[stateKey].meta[id]
        ? state[stateKey].meta[id]
        : {
            fetchError: null,
            fetchProgress: Progress.IDLE,
            id,
            removeError: null,
            removeProgress: Progress.IDLE,
            updateError: null,
            updateProgress: Progress.IDLE,
          },
    itemFetchProgress: state => state[stateKey].itemFetchProgress,
    listFetchProgress,
    listFetchError: state => state[stateKey].listFetchError,
    listFetchNextProgress: state => state[stateKey].listFetchNextProgress,
    listFetchNextError: state => state[stateKey].listFetchNextError,
    listFetchNextErrorDetail: state => state[stateKey].listFetchNextErrorDetail,
    itemFetchErrorDetail: state => state[stateKey].itemFetchErrorDetail,
    list: createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
      if (progress === Progress.WORK) {
        return []
      }
      const result: unknown[] = []
      for (const id of ids) {
        result.push(dict[id])
      }
      return result as EntityType[]
    }),
  }
}
