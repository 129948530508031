import { ProgramDTO, ProgramSummaryListFilter, ProgramSummaryListFilterDTO } from './types'

export const mapProgramSummaryFilter = (filter: ProgramSummaryListFilter): ProgramSummaryListFilterDTO => ({
  season_id: filter?.ps_seasons_ids,
  retailer_id: filter?.ps_retailers_ids,
  farmer_id: filter?.ps_farmers_ids,
  category_id: filter?.ps_categories_ids,
  product_id: filter?.ps_products_ids,
})

export const mapProgramSummaryProgram = (raw: ProgramDTO): ProgramDTO => ({
  ...raw,
  id: raw.id + raw.program_title,
  ps_id: raw.id,
})
