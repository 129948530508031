import React from 'react'
import OrderSkuCardItems, { OrderSkuCardItemsProps } from './OrderSkuCardItems'
import OrderSkuCardItemsReadOnly, { OrderCardItemsReadOnlyProps } from './OrderSkuCardItemsReadOnly'

export type OrderSkuCardItemsManagerProps = OrderSkuCardItemsProps &
  Pick<OrderCardItemsReadOnlyProps, 'showFinalQty'> & {
    readOnly?: boolean
    initialProducerId?: string
    showShippedQty?: boolean
  }

export const OrderSkuCardItemsManager: React.VFC<OrderSkuCardItemsManagerProps> = ({
  showFinalQty,
  showShippedQty,
  readOnly,
  order,
  ...otherProps
}) => {
  return !readOnly ? (
    <OrderSkuCardItems order={order} showShippedQty={showShippedQty} {...otherProps} />
  ) : (
    <OrderSkuCardItemsReadOnly
      sku_items={order?.sku_items ?? []}
      showFinalQty={showFinalQty}
      showShippedQty={showShippedQty}
      currency={order?.currency}
      orderTotal={order && 'total' in order ? Number(order.total) : undefined}
      {...otherProps}
    />
  )
}
