import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramSummaryListEntry } from 'modules/domain/programSummary/types'
import styled from 'styled-components'

const TooltipWrapper = styled.div`
  width: 500px;
`
const TooltipBlockWrapper = styled.div`
  margin: 32px;
`
const TooltipBlockItemWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-content: center;
`
const TooltipBlockTitle = styled(TooltipBlockItemWrapper)`
  font-weight: 600;
  margin-bottom: 16px;
`
const TooltipBlockItemTitle = styled.div`
  color: ${props => props.theme.color.secondary200};
  font-weight: 500;
  width: 220px;
`
const TooltipBlockItemContent = styled.div`
  font-weight: 500;
  width: 280px;
`

type Props = {
  summary?: ProgramSummaryListEntry
}
const FarmerTooltip: React.FC<Props> = ({ summary }) => {
  const { t } = useTranslation('programSummary')
  const farmerData = summary?.farmer_data
  const farmerName = `${farmerData?.first_name} ${farmerData?.last_name}`
  const tooltipData = [
    {
      header: t('labels.personalData'),
      rows: [
        {
          title: t('labels.name'),
          value: farmerName,
        },
        {
          title: t('labels.phone'),
          value: farmerData?.phone_number,
        },
        {
          title: t('labels.email'),
          value: farmerData?.email,
        },
        {
          title: t('labels.chequePayeeName'),
          value: farmerData?.cheque_name,
        },
      ],
    },
    {
      header: t('labels.farmData'),
      rows: [
        {
          title: t('labels.farm'),
          value: farmerData?.farm_name,
        },
        {
          title: t('labels.household'),
          value: summary?.household?.name,
        },
        {
          title: t('labels.householdId'),
          value: summary?.household?.id,
        },
      ],
    },
    {
      header: t('labels.addressData'),
      rows: [
        {
          title: t('labels.address'),
          value: farmerData?.address,
        },
        {
          title: t('labels.city'),
          value: farmerData?.city,
        },
        {
          title: t('labels.province'),
          value: farmerData?.state,
        },
        {
          title: t('labels.postal_code'),
          value: farmerData?.postal_code,
        },
      ],
    },
  ]
  return (
    <TooltipWrapper>
      {tooltipData.map((item, i) => (
        <TooltipBlockWrapper key={i}>
          <TooltipBlockTitle>{item.header}</TooltipBlockTitle>
          {item.rows.map((row, j) => (
            <TooltipBlockItemWrapper key={j}>
              <TooltipBlockItemTitle>{row.title}</TooltipBlockItemTitle>
              <TooltipBlockItemContent>{row.value}</TooltipBlockItemContent>
            </TooltipBlockItemWrapper>
          ))}
        </TooltipBlockWrapper>
      ))}
    </TooltipWrapper>
  )
}

export default FarmerTooltip
