import React, { useCallback } from 'react'
import FarmerDetailsForm, { FormData } from './FarmerDetailsForm/FarmerDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useFarmer } from 'modules/domain/farmer/hooks'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import FarmerActions from 'modules/domain/farmer/duck'
import Routes from './routes'
import { useTranslation } from 'react-i18next'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { zip } from 'ramda'
import { useHouseholdById } from 'modules/domain/household/hooks'
import { generatePath } from 'react-router'
import HouseholdsRoutes from 'views/pages/Household/routes'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { PreviewContainer } from 'views/components/PreviewContainer/PreviewContainer'

const FarmerId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const StyledDataPreviewLinkContainer = styled(PreviewContainer.Container)`
  display: block;
  max-width: 300px;
`

const HeaderInner = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 36px;
`

const FarmerEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const updateFarmerAction = useAction(FarmerActions.updateRequested)
  const removeFarmerAction = useAction(FarmerActions.removeRequested)
  const page = useSelector(FarmerSelectors.page)
  const { t } = useTranslation('farmer')

  const handleSubmit = useCallback(
    (data: FormData) => {
      updateFarmerAction(params.id, {
        phone_number: data.phone,
        status: data.status,
        farm_status: data.farm_status,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        second_name: data.middleName,
        canterra_id: data.canterraId,
        country: data.countryCode,
        regions_ids: data.regions ? data.regions.map(item => item.id) : [],
        farm_name: data.farmName,
        self_pickup: data.selfPickup,
        partnership: data.partnership,
        legal_address: data.legal_address,
        delivery_address: data.delivery_address,
        paper_agreement: data.paper_agreement,
        does_not_have_email: data.does_not_have_email,
        csua_info: data.csua_info,
        retailer_regions_ids: data.retailer_regions_ids,
        comment: data.comment,
        additional_phone: data.additionalPhone,
        timezone: data.timezone,
      })
    },
    [params.id, updateFarmerAction],
  )

  const handleRemove = () => {
    removeFarmerAction(params.id)
  }

  const [fetchProgress, farmer] = useFarmer(params.id)
  const [, household] = useHouseholdById(farmer?.household_id)

  const meta = useSelector(state => FarmerSelectors.meta(state, params.id))

  const goBack = () => push({ route: Routes.List, query: { page } })

  useHelmet({ title: t('farmersMetaTitle') })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !farmer) {
    return loading()
  }

  const name = [farmer.first_name, farmer.last_name, farmer.second_name].filter(Boolean).join(' ')

  const idString = zip(['ID', 'Slug_ID'], [farmer.id, farmer.prefix])
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <HeaderInner>
            <div>
              <Header.Title size={'small'} compact={true} title={name} />
              <FarmerId>{idString}</FarmerId>
            </div>
            {!!household && (
              <StyledDataPreviewLinkContainer to={generatePath(HouseholdsRoutes.Edit, { id: household.id })}>
                <PreviewContainer.Header>{household.name}</PreviewContainer.Header>
                {!!household.senior_partner && (
                  <PreviewContainer.Subheader>
                    {t('form.householdSenior', {
                      name:
                        household.senior_partner.full_name ||
                        household.senior_partner.email ||
                        household.senior_partner.phone_number,
                    })}
                  </PreviewContainer.Subheader>
                )}
              </StyledDataPreviewLinkContainer>
            )}
          </HeaderInner>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FarmerDetailsForm
          onCancel={goBack}
          onRemove={handleRemove}
          mode={'edit'}
          progress={meta.updateProgress}
          userId={params.id}
          household={household}
          initialValues={{
            status: farmer.status,
            farm_status: farmer.farm_status,
            lastName: farmer.last_name || '',
            middleName: farmer.second_name || '',
            firstName: farmer.first_name || '',
            email: farmer.email || '',
            phone: farmer.phone_number || '',
            countryCode: farmer.country,
            regions: farmer.regions || [],
            canterraId: farmer.canterra_id,
            farmName: farmer.farm_name,
            selfPickup: farmer.self_pickup,
            partnership: farmer.partnership,
            legal_address: farmer.legal_address,
            delivery_address: farmer.delivery_address,
            paper_agreement: farmer.paper_agreement,
            csua_info: farmer.csua_info,
            retailer_regions_ids: farmer.retailer_regions_ids,
            comment: farmer.comment,
            additionalPhone: farmer.additional_phone || '',
            timezone: farmer.timezone,
          }}
          onSubmit={handleSubmit}
        />
      </Layout.Content>
    </>
  )
}

export default FarmerEdit
