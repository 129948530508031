import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import InventoryInTransferRequestActions from './duck'
import InventoryInSelectors from './selectors'
import { InventoryTransferRequest } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import { ticker } from 'modules/sagaHelpers'
import { COUNTERS_FETCH_DELAY_MS } from 'modules/constants'
import { TransferRequestStatuses } from '../inventoryInTransferRequest/types'
import AuthSelectors from '../auth/selectors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(InventoryInSelectors.page)
    const filter = yield select(InventoryInSelectors.filter)

    yield put(InventoryInTransferRequestActions.filtersUpdatedWithPersistentStorage(filter))

    const sorting = yield select(InventoryInSelectors.sorting)
    const pageSize = yield select(InventoryInSelectors.pageSize)

    let response: ListResponse<InventoryTransferRequest> = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(InventoryInTransferRequestActions.listRequestSucceed(data, total_count, page))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryInTransferRequestActions.listRequestFailed(type, detail))
  }
}

export const updateItem = function*({
  payload: [id, dto],
}: ReturnType<typeof InventoryInTransferRequestActions.updateRequested>) {
  try {
    const transferRequest: InventoryTransferRequest = yield call(managers.updateItem, id, dto)
    yield put(InventoryInTransferRequestActions.updateSucceed(transferRequest))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(InventoryInTransferRequestActions.updateFailed(id, errType))
  }
}

export const requestCountFetcher = ticker(function*() {
  try {
    const userCompany = yield select(AuthSelectors.userCompany)
    const count = yield call(managers.requestTrasferCount, {
      status: TransferRequestStatuses.active,
      retailer_from: userCompany?.id,
    })
    yield put(InventoryInTransferRequestActions.requestsCountRequestSucceed(count))
  } catch (err) {
    console.error(err)
    yield put(InventoryInTransferRequestActions.requestsCountRequestError())
    const errType = err instanceof RequestError ? err.type : 'unknown'
    if (['forbidden_error', 'unauthorized_error'].includes(errType)) {
      return false
    }
  }

  return true
}, COUNTERS_FETCH_DELAY_MS)

const InventoryTransferRequestSaga = function*() {
  yield all([
    takeLatest(InventoryInTransferRequestActions.listRequested.type, fetchList),
    takeLatest(InventoryInTransferRequestActions.filterUpdated.type, fetchList),
    takeLatest(InventoryInTransferRequestActions.sortingUpdated.type, fetchList),
    takeLatest(InventoryInTransferRequestActions.filterHasBeenReset.type, fetchList),
    takeLatest(InventoryInTransferRequestActions.sortingHasBeenReset.type, fetchList),
    takeLatest(InventoryInTransferRequestActions.updateRequested.type, updateItem),

    fork(requestCountFetcher),
  ])
}

export default InventoryTransferRequestSaga
