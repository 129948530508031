import MultiMediaSelect from 'views/ui/MultiMediaSelect/MultiMediaSelect'
import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationError } from 'helpers/imageFileValidator'
import styled from 'styled-components'
import { FormikHook } from '@agro-club/frontend-shared'
import * as Yup from 'yup'
import { FileItem } from '../FileManager/types'

const GalleryHintWrapper = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.color.secondary200};
`

export const GalleryLink = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
`

const GalleryHint: React.FC<{ onClick(): void; render?: () => React.ReactNode }> = ({ onClick, render }) => {
  const { t } = useTranslation('ImageForm')
  return (
    <GalleryHintWrapper onClick={onClick}>
      {render ? (
        render()
      ) : (
        <>
          <GalleryLink>{t('addMedia')}</GalleryLink>
          {t('addMediaDescription1')}
          <br />
          {t('addMediaDescription2')}
          <br />
          {t('addMediaDescription3')}
        </>
      )}
    </GalleryHintWrapper>
  )
}

export type ImageFormItem = FileItem
export type ImageFormProps = {
  files: FileItem[]
}
const ImageForm: React.FC<{
  useFormik: FormikHook
  files?: FileItem[]
  limit?: number
  accept?: string
  renderGalleryHint?: () => React.ReactNode
  atLeastOneFileValidation?: boolean
  title?: string
}> = memo(({ useFormik, files = [], limit, accept, renderGalleryHint, atLeastOneFileValidation, title }) => {
  const { t } = useTranslation('validation')
  const validate = useCallback(
    (values: { files: FileItem[] }) => {
      const errors: string[] = new Array(values.files.length)
      let hasError = false
      values.files.forEach((file, i) => {
        if (file.error) {
          hasError = true
          let errorText = ''
          switch (file.error) {
            case ValidationError.FILE_TOO_LARGE:
              errorText = t('fileSizeToLarge')
              break
            case ValidationError.MAX_HEIGHT_EXCEEDED: {
              errorText = t('fileHeightToLarge')
              break
            }
            case ValidationError.MAX_WIDTH_EXCEEDED: {
              errorText = t('fileWidthToLarge')
              break
            }
            case ValidationError.UNKNOWN:
            default:
              errorText = t('fileUnknownError')
          }
          errors[i] = errorText
        }
      })

      if (hasError) {
        return {
          files: errors,
        }
      }
    },
    [t],
  )

  const validationSchema = useMemo(
    () =>
      Yup.object({
        files: Yup.array().test(
          'isFileAdded',
          t('addAtLeastOneFile'),
          fileList => !!fileList?.some(file => file?.kind === 'added' || file?.kind === 'current'),
        ),
      }),
    [t],
  )

  const formik = useFormik<ImageFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      files,
    },
    enableReinitialize: true,
    validate,
    ...(atLeastOneFileValidation && { validationSchema }),
  })

  const handleChange = files => {
    formik.setFieldTouched('files')
    formik.setFieldValue('files', files)
  }

  return (
    <MultiMediaSelect
      limit={limit}
      items={formik.values.files}
      onChange={handleChange}
      render={props => (props.addAllowed ? <GalleryHint onClick={props.onClick} render={renderGalleryHint} /> : null)}
      accept={accept}
      title={title}
    />
  )
})

export default ImageForm
