import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { CallBackRequest } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.callBackRequest.items
const ids = (state: AppGlobalState) => state.callBackRequest.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): CallBackRequest | undefined => state.callBackRequest.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<CallBackRequest> =>
  state.callBackRequest.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.callBackRequest.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.callBackRequest.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.callBackRequest.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.callBackRequest.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.callBackRequest.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: CallBackRequest[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.callBackRequest.filter
const sorting = (state: AppGlobalState) => state.callBackRequest.sorting
const page = (state: AppGlobalState) => state.callBackRequest.page
const total = (state: AppGlobalState) => state.callBackRequest.total
const updateProgress = (state: AppGlobalState) => state.callBackRequest.updateProgress
const removeProgress = (state: AppGlobalState) => state.callBackRequest.removeProgress
const addProgress = (state: AppGlobalState) => state.callBackRequest.addProgress
const hasNext = (state: AppGlobalState) => state.callBackRequest.total > state.callBackRequest.ids.length
const pageSize = (state: AppGlobalState) => state.callBackRequest.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.callBackRequest.total / state.callBackRequest.pageSize)
const pendingFetchProgress = (state: AppGlobalState) => state.callBackRequest.pendingCallsCountFetchProgress
const pendingCount = (state: AppGlobalState) => state.callBackRequest.pendingCallsCount

const CallBackRequestSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  pendingFetchProgress,
  pendingCount,
}

export default CallBackRequestSelectors
