import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Sections } from 'types/entities'

import { DistributorOrderSkuDetailsFormFields } from './components/DistributorOrderSkuDetailsForm'
import DistributorOrderSkuAdd from './DistributorOrderSkuAdd'
import DistributorOrderSkuEdit from './DistributorOrderSkuEdit'
import DistributorOrderSkuTable from './DistributorOrderSkuTable'
import DistributorOrderSkuRoutes from './routes'

const DistributorOrderSkuPage: React.VFC = () => {
  const [initialState, setInitialState] = useState<DistributorOrderSkuDetailsFormFields | undefined>()
  const checkFeatureFlag = useFeatureFlags()
  const isAddOrderRestricted = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerSkuOrders, 'restrictAddRetailerOrders'),
  )

  return (
    <Switch>
      <Route path={DistributorOrderSkuRoutes.List} exact={true}>
        <DistributorOrderSkuTable />
      </Route>
      <Route path={DistributorOrderSkuRoutes.Edit} exact={true}>
        <DistributorOrderSkuEdit onUpdateInitialState={setInitialState} />
      </Route>
      {!isAddOrderRestricted && (
        <Route path={DistributorOrderSkuRoutes.Add} exact={true}>
          <DistributorOrderSkuAdd onUpdateInitialState={setInitialState} initialState={initialState} />
        </Route>
      )}
      <Redirect to={DistributorOrderSkuRoutes.List} />
    </Switch>
  )
}

export default DistributorOrderSkuPage
