import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import DistributorOrderActions from './duck'
import DistributorOrderSkuSelectors from './selectors'

export const useDistributorOrderSkuList = createItemListOnceHook(
  DistributorOrderSkuSelectors,
  DistributorOrderActions.listRequested,
)

export const useDistributorOrderSkuById = createItemByIdOnceHook(
  DistributorOrderSkuSelectors,
  DistributorOrderActions.itemRequested,
)
