import React from 'react'
import { FormText, FormComponents, SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import useDateFormatFn from 'hooks/useDateFormatFn'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

import { Progress } from 'modules/types'
import { FarmerMetaData } from 'modules/domain/farmer/types'

type FarmerMetaCardProps = {
  metadata?: FarmerMetaData
  progress: Progress
}

const FarmerMetaCard: React.VFC<FarmerMetaCardProps> = ({ metadata, progress }) => {
  const { t } = useTranslation('common')
  const getFormatedDate = useDateFormatFn({ withTime: true })

  const createdBy = metadata?.created_by_user?.full_name
  const createdAt = metadata?.created_at
  const updatedBy = metadata?.updated_by_user?.full_name
  const updatedAt = metadata?.updated_at

  return (
    <SectionContainer>
      <SectionBody>
        <FormComponents.FormSection title={t('metaData')}>
          {progress !== Progress.WORK ? (
            <>
              {createdBy && <FormText label={t('createdBy')}>{createdBy}</FormText>}
              {createdAt && <FormText label={t('createdAt')}>{getFormatedDate(createdAt)}</FormText>}
              {updatedBy && <FormText label={t('updatedBy')}>{updatedBy}</FormText>}
              {updatedAt && <FormText label={t('updatedAt')}>{getFormatedDate(updatedAt)}</FormText>}
            </>
          ) : (
            <SpinnerLayout />
          )}
        </FormComponents.FormSection>
      </SectionBody>
    </SectionContainer>
  )
}

export default React.memo(FarmerMetaCard)
