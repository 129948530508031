import styled, { StyledProps } from 'styled-components'
import { DistributorOrderStatus } from 'types/distributorOrder'
import { FarmerOrderStatus } from 'types/farmerOrder'

type StatusProps = StyledProps<{ status: FarmerOrderStatus | DistributorOrderStatus }>
const FarmerOrderStatusColored = styled.span<StatusProps>`
  color: ${({ status, theme }: StatusProps) => {
    switch (status) {
      case FarmerOrderStatus.Canceled:
        return theme.color.accentDestructive
      case FarmerOrderStatus.Confirmed:
        return theme.color.primary700
      case FarmerOrderStatus.Changed:
        return theme.color.accentNotify
      case FarmerOrderStatus.Deleted:
        return theme.color.accentDestructive
      case FarmerOrderStatus.New:
        return theme.color.primary600
      case FarmerOrderStatus.Completed:
        return theme.color.accentApproving
      case DistributorOrderStatus.Canceled:
        return theme.color.accentDestructive
      case DistributorOrderStatus.Confirmed:
        return theme.color.primary700
      case DistributorOrderStatus.Changed:
        return theme.color.accentNotify
      case DistributorOrderStatus.Deleted:
        return theme.color.accentDestructive
      case DistributorOrderStatus.New:
        return theme.color.primary600
      case DistributorOrderStatus.Completed:
        return theme.color.accentApproving
    }
  }};
`

export default FarmerOrderStatusColored
