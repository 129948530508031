import React, { useMemo, useState } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import { format, parseISO } from 'date-fns'

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`

import {
  Button,
  FormComponents,
  FormText,
  SectionBody,
  SectionContainer,
  useAction,
  useHistoryPush,
} from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useNotificationsListItem } from 'modules/domain/notificationsList/hooks'
import Status from './Status'
import NotificationsListRoutes from './routes'
import NotificationsListSelectors from 'modules/domain/notificationsList/selectors'
import FormTextWithDefault from 'views/components/FormTextWithDefault/FormTextWithDefault'
import NotificationsListActions from 'modules/domain/notificationsList/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled, { StyledProps } from 'styled-components'
import EmailModal from 'views/components/EmailModal/EmailModal'

const NotificationItem: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { t } = useTranslation(['notificationsList', 'common'])
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const [progress, item] = useNotificationsListItem(params.id)
  const push = useHistoryPush()
  const page = useSelector(NotificationsListSelectors.page)
  const goBack = () => push({ route: NotificationsListRoutes.List, query: { page } })
  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />
  const notificationResend = useAction(NotificationsListActions.notificationResendRequested)
  const resendProgress = useSelector(NotificationsListSelectors?.updateProgress)
  const resendingAllowed = useMemo(() => isAdmin && !!item?.parent_id, [item])
  const [showEmailModel, setShowEmailModel] = useState(false)

  if (progress === Progress.WORK) {
    return loading()
  }

  if (progress === Progress.ERROR || !item) {
    return errorUnknown()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.heading', { address_to: item.address_to })} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StickyFooterLayout.Wrapper>
          <StickyFooterLayout.Body>
            <Wrapper>
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <FormComponents.FormSection title={t('labels.info')}>
                      <FormTextWithDefault label={'id'} value={item.id} />
                      <FormTextWithDefault label={t('fields.authorId')} value={item.author_id} />
                      <FormTextWithDefault label={t('fields.externalId')} value={item.external_id} />
                      <FormTextWithDefault label={t('fields.addressTo')} value={item.address_to} />
                      <FormTextWithDefault label={t('fields.templateName')} value={item.template_name} />
                      <FormTextWithDefault
                        label={t('fields.communicationChannel')}
                        value={item.communication_channel}
                      />
                      <FormTextWithDefault label={t('fields.type')} value={item.type} />
                    </FormComponents.FormSection>
                  </SectionBody>
                </SectionContainer>
              </Column>
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <FormComponents.FormSection title={t('labels.status')}>
                      <FormText label={t('fields.status')}>
                        <Status status={item.status} />
                      </FormText>
                      <FormText label={t('fields.delivered')}>
                        {item.delivered ? t('fields.delivered') : t('fields.notDelivered')}
                      </FormText>
                      {resendingAllowed && (
                        <Button
                          intent={'primary'}
                          filled
                          progress={resendProgress}
                          onClick={() => setShowEmailModel(true)}
                        >
                          {t('form.resend')}
                        </Button>
                      )}
                      <EmailModal
                        isOpen={showEmailModel}
                        onClose={() => setShowEmailModel(false)}
                        resend={email => notificationResend(item.id, email)}
                      />
                    </FormComponents.FormSection>
                  </SectionBody>
                </SectionContainer>
                <SectionContainer>
                  <SectionBody>
                    <FormComponents.FormSection title={t('labels.meta')}>
                      <FormText label={t('fields.dateSend')}>
                        {format(parseISO(item.date_send), 'yyyy.MM.dd / HH:mm')}
                      </FormText>
                      <FormText label={t('fields.createdAt')}>
                        {format(parseISO(item.created_at), 'yyyy.MM.dd / HH:mm')}
                      </FormText>
                      <FormText label={t('fields.updatedAt')}>
                        {format(parseISO(item.updated_at), 'yyyy.MM.dd / HH:mm')}
                      </FormText>
                    </FormComponents.FormSection>
                  </SectionBody>
                </SectionContainer>
              </Column>
            </Wrapper>
          </StickyFooterLayout.Body>
        </StickyFooterLayout.Wrapper>
      </Layout.Content>
    </>
  )
}

export default NotificationItem
