import styled, { StyledProps } from 'styled-components'
import { IconBin, Button, Checkbox as CheckboxComponent, GridArea } from '@agro-club/frontend-shared'

export const PersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 725px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'country ... ...'
    'firstname lastname middlename'
    'phone email .'
    'canterraid timezone ...'
    'changePasswordInput passwordButton passwordButton'
    'password password-settings password-settings';
`

export const CompanyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 725px;
  grid-gap: 16px;
`

export const UserPersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 725px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'country ... ...'
    'firstname lastname middlename'
    'phone email .'
    'check-payee-name canterraid ...';
`

export const TimezoneArea = styled.div`
  grid-area: timezone;
`

export const CountryArea = styled.div`
  grid-area: country;
`
export const NameArea = styled.div`
  grid-area: firstname;
`
export const LastNameArea = styled.div`
  grid-area: lastname;
`
export const MiddleNameArea = styled.div`
  grid-area: middlename;
`
export const PhoneNumberArea = styled.div`
  grid-area: phone;
`
export const EmailArea = styled.div`
  grid-area: email;
`
export const PasswordArea = styled.div`
  grid-area: password;
`

export const CanterraIdArea = styled.div`
  grid-area: canterraid;
`

export const CheckPayeeNameArea = styled.div`
  grid-area: check-payee-name;
`

export const PasswordSettingsArea = styled.div`
  grid-area: password-settings;
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  margin-left: 10px;
`
export const PermissionsWrapper = styled.div`
  width: 200px;
`

export const RemoveButton = styled(Button)`
  margin-left: auto;
`
export const PasswordButton = styled.div<StyledProps<{}>>`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 20px;
  cursor: pointer;
  color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  transition: 0.2s opacity ease;
  &:hover {
    opacity: 0.8;
  }
`

export const AddRegionButton = styled.div<StyledProps<{}>>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  transition: 0.2s opacity ease;
  margin-top: 16px;
  &:hover {
    opacity: 0.8;
  }
`

export const SelectWrapper = styled.div`
  width: 240px;
`

export const ResetPasswordLinkSent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const ResetPasswordFailed = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.accentDestructive};
`

export const PartnershipBlock = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
`

export const PartnerBlock = styled.div`
  display: grid;
  grid-template-columns: 230px 230px 180px 180px 30px;
  grid-gap: 16px;
  align-items: center;
`

export const PartnershipButton = styled.button`
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary500};
  cursor: pointer;
  justify-self: start;

  &:focus {
    outline: none;
  }
`

export const UserOtherDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 725px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'password password-settings password-settings';
  margin-top: -16px;
  margin-bottom: 24px;
`
export const RemoveBtn = styled(IconBin)`
  cursor: pointer;
  margin-top: 16px;
  align-self: end;
  margin-bottom: 12px;
  transition: 0.3s fill;

  &:hover {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const Area = GridArea.ofType<'address' | 'city' | 'province' | 'country' | 'zip' | 'date'>()
export const AddressContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'address address'
    'city province'
    'country zip'
    'date .';
`
export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const ChangePasswordInput = styled.div`
  grid-area: changePasswordInput;
`

export const ChangePasswordButton = styled.div`
  grid-area: passwordButton;
  display: flex;
  align-items: end;
`

export const CommentBlock = styled.div`
  width: 480px;
`
