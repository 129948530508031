import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import FarmerActions from 'modules/domain/farmer/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import FarmerSelectors from './selectors'
import {
  Farmer,
  FarmerData,
  FarmerMetaData,
  FarmerOrderCountRequestFilter,
  PaperAgreementStatus,
} from 'modules/domain/farmer/types'
import FarmerRoutes from 'views/pages/Farmer/routes'
import { ListResponse } from 'types/api'
import { ticker, updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import { COUNTERS_FETCH_DELAY_MS } from 'modules/constants'
import { CAPABILITY, isPermitted, PERMISSION } from 'modules/permissions/permissions'
import AuthSelectors from '../auth/selectors'
import { Company } from 'modules/domain/company/types'
import { FarmerOrderStatus } from 'types/farmerOrder'

export const fetchList = function*() {
  try {
    let currentPage = yield select(FarmerSelectors.page)
    const filter = yield select(FarmerSelectors.filter)
    const sorting = yield select(FarmerSelectors.sorting)
    const pageSize = yield select(FarmerSelectors.pageSize)

    let response: ListResponse<Farmer> = yield call(managers.getFarmersList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getFarmersList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(FarmerActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, FarmerRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(FarmerSelectors.page)
    const filter = yield select(FarmerSelectors.filter)
    const sorting = yield select(FarmerSelectors.sorting)
    const pageSize = yield select(FarmerSelectors.pageSize)
    const { data, total_count }: { data: Farmer[]; total_count: number } = yield call(
      managers.getFarmersList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(FarmerActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerActions.listRequestNextFailed(errType))
  }
}

export const fetchFarmer = function*({ payload: id }: ReturnType<typeof FarmerActions.itemRequested>) {
  try {
    const farmer: Farmer = yield call(managers.getFarmer, id)
    yield put(FarmerActions.itemRequestSucceed(farmer))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerActions.itemRequestFailed(id, errType))
  }
}

export const fetchFarmerMetaData = function*(props) {
  try {
    let id
    if (Array.isArray(props.payload)) {
      id = props.payload[0]
    } else {
      id = props.payload.id
    }
    const farmerMetaData: FarmerMetaData = yield call(managers.getFarmerMetaData, id)
    yield put(FarmerActions.itemMetaDataRequestSucceed(farmerMetaData))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerActions.itemMetaDataRequestFailed(errType))
  }
}

export const fetchFarmerByPhone = function*({ payload: phone }: ReturnType<typeof FarmerActions.itemRequestedByPhone>) {
  try {
    const farmer: ListResponse<Farmer> = yield call(managers.getFarmerByPhone, phone)
    if (!farmer.data.length) {
      throw new Error('not_found')
    }
    const userCompany: Company = yield select(AuthSelectors.userCompany)
    const farmerData: FarmerData = farmer.data[0]

    if (userCompany) {
      farmerData.ordersCount = yield fetchFarmerOrdersCount(farmerData.id, userCompany)
    }

    yield put(FarmerActions.itemRequestByPhoneSucceed(farmerData, phone))
  } catch (err) {
    yield put(FarmerActions.itemRequestByPhoneFailed(phone))
  }
}

export const fetchFarmerOrdersCount = function*(farmerId: string, userCompany: Company) {
  const ordersCountFilter: FarmerOrderCountRequestFilter = {
    owner_id: farmerId,
    distributor_id: userCompany.id,
    producer_id: userCompany.producers_relations,
    status: FarmerOrderStatus.Confirmed,
  }
  return yield call(managers.farmerOrdersCount, ordersCountFilter)
}

export const fetchFarmerOrdersTotalCount = function*({
  payload: farmerId,
}: ReturnType<typeof FarmerActions.ordersTotalCountFetchRequested>) {
  try {
    const dto: FarmerOrderCountRequestFilter = { owner_id: farmerId }
    const orderTotalCount: number = yield call(managers.farmerOrdersCount, dto)
    const orderSkuTotalCount: number = yield call(managers.farmerOrdersSkuCount, dto)
    const count = (orderTotalCount || 0) + (orderSkuTotalCount || 0)

    yield put(FarmerActions.ordersTotalCountFetchSucceed(farmerId, count))
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    yield put(FarmerActions.ordersTotalCountFetchFailed(errType))
  }
}

export const addFarmer = function*({ payload: dto }: ReturnType<typeof FarmerActions.addRequested>) {
  try {
    const farmer: Farmer = yield call(managers.addFarmer, dto)
    yield put(FarmerActions.addSucceed(farmer))

    yield put(push(generatePath(FarmerRoutes.Edit, { id: farmer.id })))
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(FarmerActions.addFailed(errType, detail))
  }
}
export const updateFarmer = function*({ payload: [id, dto] }: ReturnType<typeof FarmerActions.updateRequested>) {
  try {
    const farmer: Farmer = yield call(managers.updateFarmer, id, dto)
    yield put(FarmerActions.updateSucceed(farmer))
  } catch (e) {
    const type = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(FarmerActions.updateFailed(id, type, detail))
  }
}

export const removeFarmer = function*({ payload }: ReturnType<typeof FarmerActions.removeRequested>) {
  try {
    yield call(managers.removeFarmer, payload)
    yield put(FarmerActions.removeSucceed(payload))
    const currentPage = yield select(FarmerSelectors.page)
    yield put(push(FarmerRoutes.List, { page: currentPage }))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerActions.removeFailed(payload, errType))
  }
}

export const addFarmerByRetailer = function*({
  payload: dto,
}: ReturnType<typeof FarmerActions.addByRetailerRequested>) {
  try {
    const farmer: Farmer = yield call(managers.addFarmerByRetailer, dto)
    yield put(FarmerActions.addSucceed(farmer))
  } catch (e) {
    const type = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(FarmerActions.addFailed(type, detail))
  }
}

export const farmersWaitingAgreementCountFetcher = ticker(function*({ role }) {
  if (!isPermitted(role, CAPABILITY.FARMERS, PERMISSION.R)) {
    return false
  }

  try {
    const count = yield call(managers.farmersCount, { paper_agreement: PaperAgreementStatus.Needed })
    yield put(FarmerActions.farmersWaitingAgreementCountRequestSucceed(count))
  } catch (err) {
    console.error(err)
    yield put(FarmerActions.farmersWaitingAgreementCountRequestError())
    const errType = err instanceof RequestError ? err.type : 'unknown'
    if (['forbidden_error', 'unauthorized_error'].includes(errType)) {
      return false
    }
  }

  return true
}, COUNTERS_FETCH_DELAY_MS)

export const addProductToFarmerCart = function*({
  payload,
}: ReturnType<typeof FarmerActions.addProductToFarmerCartRequested>) {
  try {
    yield call(managers.addProductToFarmerCart, payload)
    yield put(FarmerActions.addProductToFarmerCartSucceed())
  } catch (err) {
    yield put(FarmerActions.addProductToFarmerCartFailed())
  }
}

const FarmersSaga = function*() {
  yield all([
    takeLatest(FarmerActions.itemRequested.type, fetchFarmer),
    takeLatest(FarmerActions.itemMetaDataRequested.type, fetchFarmerMetaData),
    takeLatest(FarmerActions.itemRequestedByPhone.type, fetchFarmerByPhone),

    takeLatest(FarmerActions.listRequested.type, fetchList),
    takeLatest(FarmerActions.filterUpdated.type, fetchList),
    takeLatest(FarmerActions.sortingUpdated.type, fetchList),
    takeLatest(FarmerActions.filterHasBeenReset.type, fetchList),
    takeLatest(FarmerActions.sortingHasBeenReset.type, fetchList),

    takeLatest(FarmerActions.listRequestedNext.type, fetchListNext),

    takeLatest(FarmerActions.addRequested.type, addFarmer),
    takeLatest(FarmerActions.addByRetailerRequested.type, addFarmerByRetailer),
    takeLatest(FarmerActions.updateRequested.type, updateFarmer),
    takeLatest(FarmerActions.updateSucceed.type, fetchFarmerMetaData),
    takeLatest(FarmerActions.removeRequested.type, removeFarmer),
    takeLatest(FarmerActions.addProductToFarmerCartRequested.type, addProductToFarmerCart),
    takeLatest(FarmerActions.ordersTotalCountFetchRequested.type, fetchFarmerOrdersTotalCount),

    fork(farmersWaitingAgreementCountFetcher),
  ])
}

export default FarmersSaga
