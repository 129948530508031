import { useHelmet } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import UpTakeTable from './UpTakeTable/UpTakeTable'

const UpTakeList: React.FC = () => {
  const { t } = useTranslation('uptake')

  useHelmet({ title: t('metaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UpTakeTable />
      </Layout.Content>
    </>
  )
}

export default UpTakeList
