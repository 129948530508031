import styled, { StyledProps } from 'styled-components'
import { TagList as TagListComponent } from '@agro-club/frontend-shared'

export const VerticalInputsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 16px 48px;
  grid-template-columns: 1fr 1fr 1fr;
`

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 16px;
`

export const LanguagesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

export const PermissionsGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 16px;

  & > * {
    text-transform: capitalize;
  }
`

export const ParamsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: fit-content;
`

export const SelectsWrapper = styled.div`
  max-width: 500px;
`

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const TagList = styled(TagListComponent)`
  display: flex;
  margin-top: 16px;
`
export const Error = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -5px;
`
export const LanguagesContainer = styled.div`
  position: relative;
  padding-bottom: 16px;
`
