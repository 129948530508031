import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { User } from 'modules/domain/user/types'
import { EntityMetadata } from 'modules/domain/types'

const usersDict = (state: AppGlobalState) => state.user.items
const ids = (state: AppGlobalState) => state.user.ids
const user = (state: AppGlobalState, id: string): User | undefined => state.user.items[id]
const userByPhone = (state: AppGlobalState, phone: string): User | undefined =>
  Object.values(state.user.items).find(u => u.phone_number === phone)
const meta = (state: AppGlobalState, id: string): EntityMetadata<User> =>
  state.user.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.user.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.user.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.user.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.user.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.user.listFetchError
const userList = createSelector(usersDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: User[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.user.filter
const sorting = (state: AppGlobalState) => state.user.sorting
const page = (state: AppGlobalState) => state.user.page
const total = (state: AppGlobalState) => state.user.total
const updateProgress = (state: AppGlobalState) => state.user.updateProgress
const removeProgress = (state: AppGlobalState) => state.user.removeProgress
const addProgress = (state: AppGlobalState) => state.user.addProgress
const addError = (state: AppGlobalState) => state.user.addError
const hasNext = (state: AppGlobalState) => state.user.total > state.user.ids.length
const pageSize = (state: AppGlobalState) => state.user.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.user.total / state.user.pageSize)
const changePasswordProgress = (state: AppGlobalState) => state.user.changePasswordProgress

const UserSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  usersDict,
  ids,
  user,
  userByPhone,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  userList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  changePasswordProgress,
}

export default UserSelectors
