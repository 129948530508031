import { push } from 'connected-react-router'
import PromocodeActions from 'modules/domain/promocode/duck'
import managers from 'modules/domain/promocode/managers'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { generatePath } from 'react-router-dom'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import PromocodeRoutes from 'views/pages/Promocode/routes'

const sagas = createBasicSagas({
  selectors: PromocodeSelectors,
  actions: PromocodeActions,
  managers,
  onAddItemSucceed: function*(_, item) {
    yield put(push(generatePath(PromocodeRoutes.Edit, { id: item.id })))
  },
  onRemoveItemSucceed: function*() {
    yield put(push(PromocodeRoutes.List))
  },
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, PromocodeRoutes.List, { page })
  },
})

const PromocodeSaga = function*() {
  yield all([
    takeLatest(PromocodeActions.itemRequested.type, sagas.fetchItem),
    takeLatest(PromocodeActions.listRequested.type, sagas.fetchList),
    takeLatest(PromocodeActions.filterUpdated.type, sagas.fetchList),
    takeLatest(PromocodeActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(PromocodeActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(PromocodeActions.sortingHasBeenReset.type, sagas.fetchList),

    takeLatest(PromocodeActions.listRequestedNext.type, sagas.fetchListNext),

    takeLatest(PromocodeActions.addRequested.type, sagas.addItem),
    takeLatest(PromocodeActions.updateRequested.type, sagas.updateItem),
    takeLatest(PromocodeActions.removeRequested.type, sagas.removeItem),
  ])
}

export default PromocodeSaga
