import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { octopusApiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import {
  ProgramDTO,
  ProgramSummaryCategory,
  ProgramSummaryFarmer,
  ProgramSummaryListEntry,
  ProgramSummaryListFilter,
  ProgramSummaryListSorting,
  ProgramSummaryProduct,
  ProgramSummaryRetailer,
  ProgramSummarySeason,
} from './types'
import { mapProgramSummaryFilter } from './mapper'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export default {
  ...createBasicManagers<
    ProgramSummaryListFilter,
    ProgramSummaryListSorting,
    ProgramSummaryListEntry,
    ProgramSummaryListEntry,
    ProgramSummaryListEntry
  >(endpoints.programSummaryList, octopusApiClient),
  getList: async (filter, sorting, page, pageSize): Promise<ListResponse<ProgramSummaryListEntry>> => {
    return await octopusApiClient.get<ListResponse<ProgramSummaryListEntry>>(endpoints.programSummaryList(), {
      ...mapProgramSummaryFilter(filter),
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getPrograms: id => {
    return octopusApiClient.get<ListResponse<ProgramDTO>>(endpoints.programSummaryPrograms(id))
  },
  updateProgram: async (ps_id: string, program_title: string, product_id: string, dto: Partial<ProgramDTO>) => {
    return await octopusApiClient.patch<ProgramDTO>(
      endpoints.programSummaryProgramProduct(ps_id, program_title, product_id),
      dto,
    )
  },
  getRetailers: (filter, sorting, page, pageSize) => {
    return octopusApiClient.get<ListResponse<ProgramSummaryRetailer>>(endpoints.programSummaryRetailers(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getRetailersByIds: createItemsByIds(true, id =>
    octopusApiClient.get<ListResponse<ProgramSummaryRetailer>>(endpoints.programSummaryRetailers(), { id }),
  ),
  getFarmers: (filter, sorting, page, pageSize) => {
    return octopusApiClient.get<ListResponse<ProgramSummaryFarmer>>(endpoints.programSummaryFarmers(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getFarmersByIds: createItemsByIds(true, id =>
    octopusApiClient.get<ListResponse<ProgramSummaryFarmer>>(endpoints.programSummaryFarmers(), { id }),
  ),
  getProducts: (filter, sorting, page, pageSize) => {
    return octopusApiClient.get<ListResponse<ProgramSummaryProduct>>(endpoints.programSummaryProducts(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getProductsByIds: createItemsByIds(true, id =>
    octopusApiClient.get<ListResponse<ProgramSummaryProduct>>(endpoints.programSummaryProducts(), { id }),
  ),
  getSeasons: (filter, sorting, page, pageSize) => {
    return octopusApiClient.get<ListResponse<ProgramSummarySeason>>(endpoints.programSummarySeasons(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getSeasonsByIds: createItemsByIds(true, id =>
    octopusApiClient.get<ListResponse<ProgramSummarySeason>>(endpoints.programSummarySeasons(), { id }),
  ),
  getCategories: (filter, sorting, page, pageSize) => {
    return octopusApiClient.get<ListResponse<ProgramSummaryCategory>>(endpoints.programSummaryCategories(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  },
  getCategoriesByIds: createItemsByIds(true, id =>
    octopusApiClient.get<ListResponse<ProgramSummaryCategory>>(endpoints.programSummaryCategories(), { id }),
  ),
} as const
