import React, { useCallback, useMemo } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import { generatePath, useHistory } from 'react-router-dom'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'

import { PromoInfo, PromoInfoType } from 'modules/domain/promoInfo/types'
import { usePromoInfoList } from 'modules/domain/promoInfo/hooks'
import PromoInfoSelectors from 'modules/domain/promoInfo/selectors'
import PromoInfoActions from 'modules/domain/promoInfo/duck'
import PromoInfoRoutes from './routes'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  AddButton,
  useHelmet,
} from '@agro-club/frontend-shared'

type PromoInfoItem = Omit<PromoInfo, 'producer_list'> & {
  producer_list: string
  category_list: string[]
}

export const CategoryListCell: React.FC<CellProps<PromoInfoItem>> = ({ cell, row }) => {
  const values = [...new Set(cell.value)] as string[]

  if (row?.original?.type === PromoInfoType.CatalogProducer) {
    return <></>
  }

  return (
    <>
      {values.map(c => (
        <div key={c}>{c}</div>
      ))}
    </>
  )
}

const ReportList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('promoInfo')
  const [progress, data = []] = usePromoInfoList()

  const total = useSelector(PromoInfoSelectors.total)
  const page = useSelector(PromoInfoSelectors.page)
  const pages = useSelector(PromoInfoSelectors.pages)
  const pageSize = useSelector(PromoInfoSelectors.pageSize)

  const listRequested = useAction(PromoInfoActions.listRequested)
  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const mappedData = useMemo(
    () =>
      data.map((item: PromoInfo) => ({
        id: item.id,
        producer_list: Array.isArray(item.producer_list) ? item.producer_list.join(', ') : item.producer_list,
        category_list: item.category_list.filter(c => !c.includes('')).map(c => c[0]),
        title: item.title,
        type: item.type,
      })),
    [data],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.company'),
        accessor: 'producer_list' as const,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
      },
      {
        Header: t('list.tableHeaders.type'),
        accessor: 'type' as const,
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'category_list' as const,
        Cell: CategoryListCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<Partial<PromoInfoItem>>({
    columns: allColumns,
    data: mappedData,
  })

  useHelmet({ title: t('promoInfoMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={PromoInfoRoutes.PromoInfoAdd} />
          </Header.Title>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper noFilters>
          <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={fetchNextItems}>
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(PromoInfoRoutes.PromoInfoEdit, { id: row.original.id?.toString() }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ReportList
