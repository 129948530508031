import React, { useCallback, useMemo } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import PromocodeRoutes from 'views/pages/Promocode/routes'
import {
  isPromocodeSimple,
  isPromocodeWithComment,
  isPromocodeWithLegalText,
  Promocode,
  PromocodeRequestFilter,
} from 'modules/domain/promocode/types'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import PromocodeActions from 'modules/domain/promocode/duck'
import { usePromocodeList } from 'modules/domain/promocode/hooks'
import { FilterOption, TableFilter } from 'views/components/TableFilters/TableFilters'
import {
  Button,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'

const CodeCell: React.FC<CellProps<Promocode>> = ({ cell, column, row }) => {
  return (
    <div>
      <StatusIcon status={row.values.is_active ? 'active' : 'inactive'} />
      <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
    </div>
  )
}

const CodeHeaderCell: React.FC = () => {
  const { t } = useTranslation('promocode')
  return (
    <div>
      <StatusIcon />
      <span>{t('fields.code')}</span>
    </div>
  )
}

const TypeCell: React.FC<CellProps<Promocode>> = ({ row }) => {
  const { t } = useTranslation('promocode')

  switch (true) {
    case isPromocodeSimple(row.values as Promocode):
      return t(`type.simple`)
    case isPromocodeWithComment(row.values as Promocode):
      return t(`type.withComment`)
    case isPromocodeWithLegalText(row.values as Promocode):
      return t(`type.withLegalText`)
  }

  return null
}

const StatusFilter: React.FC<{
  handleFilterChange: (filter: { is_active?: boolean }) => void
  filterValue: { is_active?: boolean }
}> = ({ handleFilterChange, filterValue }) => {
  const { t } = useTranslation('promocode')
  const options: FilterOption<boolean>[] = useMemo(
    () => [
      {
        value: true,
        title: t('filter.active'),
        bullet: true,
        color: 'green',
      },
      {
        value: false,
        title: t('filter.inactive'),
        bullet: true,
        color: 'orange',
      },
    ],
    [t],
  )
  return (
    <TableFilter
      title={t('filter.statusTitle')}
      filterValue={filterValue}
      handleFilterChange={handleFilterChange}
      options={options}
      disableMulti
    />
  )
}

const PromocodeTable: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation(['promocode', 'labels'])
  const [progress, data = []] = usePromocodeList()
  const total = useSelector(PromocodeSelectors.total)
  const page = useSelector(PromocodeSelectors.page)
  const pages = useSelector(PromocodeSelectors.pages)
  const pageSize = useSelector(PromocodeSelectors.pageSize)
  const filterUpdateAction = useAction(PromocodeActions.filterUpdated)
  const filterValue = useSelector(PromocodeSelectors.filter)
  const listRequested = useAction(PromocodeActions.listRequested)
  const { scrollRef } = usePersistentScroll('promocode')

  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.company'),
        accessor: 'company_name' as const,
      },
      {
        Header: CodeHeaderCell,
        accessor: 'code' as const,
        Cell: CodeCell,
      },
      {
        Header: t('fields.type'),
        accessor: 'params' as const,
        Cell: TypeCell,
      },
      {
        Header: 'is_active',
        accessor: 'is_active' as const,
        hidden: true,
      },
    ],
    [t],
  )
  const hiddenColumns = useMemo(() => ['is_active'], [])
  const { columns, rows, prepareRow } = useTable<Promocode>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data,
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleFilterChange = useCallback(
    (newValue: Partial<PromocodeRequestFilter>) => {
      filterUpdateAction({ ...filterValue, ...newValue })
    },
    [filterUpdateAction, filterValue],
  )

  const [progressCSV, downloadCSV] = useDownload(endpoints.promocode('download/csv'), { ...filterValue })

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <StatusFilter filterValue={{ is_active: filterValue.is_active }} handleFilterChange={handleFilterChange} />
        <AlignWrapper horizontalAlign="right" verticalAlign="bottom">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell key={column.id} hidden={column.hidden}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: PromocodeRoutes.Edit, params: { id: row.original.id.toString() } })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default PromocodeTable
