import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import CategoryActions from './duck'
import CategorySelectors from './selectors'
import { Category } from './types'
import * as managers from './managers'
import { makeCancelable } from 'modules/utils/httpClient'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { useEffect } from 'react'
import { useAction, useDidMount } from '@agro-club/frontend-shared'

export const useCategoryList: ResourceHook<Category[], void[]> = () => {
  const progress = useSelector(CategorySelectors.listFetchProgress)
  const list = useSelector(CategorySelectors.list)
  const fetchAction = useAction(CategoryActions.listRequested, {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCategory: ResourceHook<Category, [string]> = (id: string) => {
  const progress = useSelector(CategorySelectors.itemFetchProgress)
  const item = useSelector(state => CategorySelectors.item(state, id))
  const fetchAction = useAction(CategoryActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [progress, item]
}

export const useCategoryById: ResourceHook<Category, [string]> = (id: string) => {
  const meta = useSelector(state => CategorySelectors.meta(state, id))
  const item = useSelector(state => CategorySelectors.item(state, id))
  const fetchAction = useAction(CategoryActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useCategorySelectOptions = makeCancellableResourceListHook(
  makeCancelable(managers.getCategorySelectOptions),
)
export const useSubcategorySelectOptions = makeCancellableResourceListHook(
  makeCancelable(managers.getSubcategorySelectOptions),
)
export const useCategoryOptions = makeCancellableResourceListHook(makeCancelable(managers.getList))
