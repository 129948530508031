import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ROLES } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ReturnDeclarationListRequestFilter } from 'modules/domain/returnDeclaration/types'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'
import { SimpleSelect, CustomSelectStyles } from '@agro-club/frontend-shared'
import { ReturnDeclarationSkuListRequestFilter } from 'modules/domain/returnDeclarationSku/types'

export enum FilterStatus {
  New = 'new',
  Submitted = 'submitted',
  Deleted = 'deleted',
}

const agroStatuses = [FilterStatus.New, FilterStatus.Submitted, FilterStatus.Deleted]

const producerStatuses = [FilterStatus.New, FilterStatus.Submitted]

const distributorStatuses = [FilterStatus.New, FilterStatus.Submitted]

const filters = {
  any: {},
  [FilterStatus.Deleted]: {
    status: ReturnDeclarationStatus.Deleted,
  },
  [FilterStatus.New]: {
    status: ReturnDeclarationStatus.New,
  },
  [FilterStatus.Submitted]: {
    status: ReturnDeclarationStatus.Submitted,
  },
}

const filterToFilterStatus = (value: any, availableOptions: FilterStatus[]): FilterStatus | null => {
  const compare = ([key, filterValue]: [string, any]) => {
    const isFilterKeysEq = Object.entries(filterValue).every(([k, v]) => value[k] === v)
    if (isFilterKeysEq) {
      return availableOptions.includes(key as FilterStatus)
    }
    return false
  }

  const entry = Object.entries(filters)
    .sort(([, a], [, z]) => Object.keys(z).length - Object.keys(a).length)
    .find(compare)

  if (entry) {
    return entry[0] as FilterStatus
  }
  return null
}

type Props = {
  label?: string
  onChange: (
    filter: Partial<ReturnDeclarationListRequestFilter> | Partial<ReturnDeclarationSkuListRequestFilter>,
  ) => void
  value: Partial<ReturnDeclarationListRequestFilter>
  placeholder?: string
  customStyles?: CustomSelectStyles
  isDisabled?: boolean
}

const ReturnDeclarationStatusSelect: React.FC<Props> = ({ label, onChange, value, customStyles, isDisabled }) => {
  const { t } = useTranslation(['returnDeclaration'])
  const role = useSelector(AuthSelectors.role)
  const optionsByRole: FilterStatus[] = useMemo(() => {
    let result: FilterStatus[] = []
    switch (role) {
      case ROLES.AGRO_ADMIN:
        result = agroStatuses
        break
      case ROLES.PRODUCER_HEAD:
      case ROLES.PRODUCER_MANAGER:
        result = producerStatuses
        break
      case ROLES.DISTRIBUTOR_HEAD:
      case ROLES.DISTRIBUTOR_MANAGER:
        result = distributorStatuses
    }
    return result
  }, [role])
  const selected = useMemo(() => filterToFilterStatus(value, optionsByRole), [optionsByRole, value])

  const statusOptions = useMemo(() => {
    return [
      { id: 'any', title: t(`status.any`) },
      ...optionsByRole.map(item => ({ id: item, title: t(`status.full.${item}`) })),
    ]
  }, [optionsByRole, t])

  const handleStatusChange = useCallback(
    (value: FilterStatus | 'any') => {
      onChange(filters[value])
    },
    [onChange],
  )

  return (
    <SimpleSelect
      label={label}
      onChange={handleStatusChange}
      options={statusOptions}
      value={selected}
      placeholder={t('status.any')}
      customStyles={customStyles}
      isDisabled={isDisabled}
      variant="small"
    />
  )
}

export default ReturnDeclarationStatusSelect
