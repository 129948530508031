import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import DocumentActions from './duck'
import DocumentSelectors from './selectors'
import { DocumentFileState, DocumentItem } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useDocumentList: ResourceHook<DocumentItem[], void[]> = () => {
  const progress = useSelector(DocumentSelectors.listFetchProgress)
  const list = useSelector(DocumentSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(DocumentActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list] as const
}

export const useDocument: ResourceHook<DocumentItem, [string]> = (id: string) => {
  const meta = useSelector(state => DocumentSelectors.meta(state, id))
  const item = useSelector(state => DocumentSelectors.item(state, id))
  const fetchAction = useAction(DocumentActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item] as const
}

export const useDocumentById: ResourceHook<DocumentItem, [string]> = (id: string) => {
  const meta = useSelector(state => DocumentSelectors.meta(state, id))
  const item = useSelector(state => DocumentSelectors.item(state, id))
  const fetchAction = useAction(DocumentActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item] as const
}

export const useDocumentNoCache: ResourceHook<DocumentItem, [string]> = (id: string) => {
  const meta = useSelector(state => DocumentSelectors.meta(state, id))
  const item = useSelector(state => DocumentSelectors.item(state, id))
  const fetchAction = useAction(DocumentActions.itemRequested, id)

  useEffect(() => {
    if (id) {
      fetchAction()
    }
  }, [id, fetchAction])

  return [meta.fetchProgress, item] as const
}

export const useDocumentFileUrl = (id: string): DocumentFileState => {
  const item = useSelector(state => DocumentSelectors.itemFileUrl(state, id))
  const fetchAction = useAction(DocumentActions.itemFileUrlRequested, id)
  useEffect(() => {
    if (id) {
      fetchAction()
    }
  }, [id, fetchAction])

  return item
}

export const useSignedDocumentUrl = (documentId: string, userId: string): DocumentFileState => {
  const item = useSelector(state => DocumentSelectors.signedFileUrl(state, documentId, userId))
  const fetchAction = useAction(DocumentActions.signedFileRequested, documentId, userId)
  useEffect(() => {
    fetchAction()
  }, [userId, documentId, fetchAction])

  return item
}

export const useUserDocuments: ResourceHook<DocumentItem[], [string | undefined]> = (userId?: string) => {
  const list = useSelector(state => (userId ? DocumentSelectors.userDocuments(state, userId) : []))
  const meta = useSelector(state =>
    userId ? DocumentSelectors.userDocumentsListMeta(state, userId) : { fetchProgress: Progress.SUCCESS },
  )
  const fetchAction = useAction(DocumentActions.userDocumentsRequested, userId)
  useEffect(() => {
    if (userId && !list && meta.fetchProgress === Progress.IDLE) {
      fetchAction()
    }
  }, [fetchAction, list, meta.fetchProgress, userId])

  return [meta.fetchProgress, list] as const
}

export const useUserDocumentsNoCache = (userId?: string): [Progress, DocumentItem[] | undefined, () => void] => {
  const list = useSelector(state => (userId ? DocumentSelectors.userDocuments(state, userId) : []))
  const meta = useSelector(state =>
    userId ? DocumentSelectors.userDocumentsListMeta(state, userId) : { fetchProgress: Progress.SUCCESS },
  )
  const fetchAction = useAction(DocumentActions.userDocumentsRequested, userId)
  useEffect(() => {
    if (userId) {
      fetchAction()
    }
  }, [fetchAction, userId])

  return [meta.fetchProgress, list, fetchAction]
}
