import styled, { keyframes } from 'styled-components'
import { helpersColor, IconCrossCircle, FormComponents } from '@agro-club/frontend-shared'

export const LabeledContainerStyled = styled(FormComponents.LabeledContainer)`
  position: relative;
  padding-bottom: 16px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 1fr;
`

export const FormContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`

export const BadgeCardsContainer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`
const removeOverlayAnimation = keyframes`
  to {
    opacity: 1;
  }
`
export const RemoveOverlay = styled.div`
  position: absolute;
  left: -5px;
  top: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 5px;
  pointer-events: none;
  background-color: ${props => helpersColor.alphaColor(props.theme.color.accentDestructive, 0.2)};
  opacity: 0;
  animation: ${removeOverlayAnimation};
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`

export const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

export const BadgeCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 7px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 15px;

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export const BadgeOption = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;
  bottom: 7px;
`

export const BadgeImageOption = styled.img`
  height: 35px;
  max-width: 100px;
`
