import { AddButton, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Header from 'views/ui/Header/Header'
import IncentiveCampaignRoutes from '../IncentiveCampaign/routes'
import IncentiveProgramRoutes from '../IncentiveProgram/routes'

export type IncentiveCampaignListHeaderProps = {
  tab: 'incentiveCampaign' | 'incentiveProgram'
}

export const IncentiveCampaignListHeader: React.VFC<IncentiveCampaignListHeaderProps> = ({ tab }) => {
  const push = useHistoryPush()
  const { t } = useTranslation(['incentiveCampaign', 'incentiveProgram'])

  useHelmet({ title: t(`${tab}:${tab}MetaTitle`) })

  return (
    <Header.TabsRoot>
      <Header.Tab
        title={t('incentiveProgram:list.heading')}
        isActive={tab === 'incentiveProgram'}
        onClick={() => push({ route: IncentiveProgramRoutes.List })}
      >
        <AddButton to={IncentiveProgramRoutes.Add} />
      </Header.Tab>
      <Header.Tab
        title={t('incentiveCampaign:list.heading')}
        isActive={tab === 'incentiveCampaign'}
        onClick={() => push({ route: IncentiveCampaignRoutes.List })}
      >
        <AddButton to={IncentiveCampaignRoutes.Add} />
      </Header.Tab>
    </Header.TabsRoot>
  )
}
