import React, { useCallback, useMemo } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ReportRoutes from 'views/pages/Reports/routes'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import { useReportList } from 'modules/domain/report/hooks'
import { generatePath, useHistory } from 'react-router-dom'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import ReportSelectors from 'modules/domain/report/selectors'
import { useSelector } from 'react-redux'
import ReportActions from 'modules/domain/report/duck'
import { Report, ReportListRequestFilter } from 'modules/domain/report/types'
import { CompanyType, ROLES } from 'types/entities'
import { Filter, FilterOption, TableFilter } from 'views/components/TableFilters/TableFilters'
import RoleSelect from 'views/components/RoleSelect/RoleSelect'
import {
  AddButton,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHelmet,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

type ReportItem = Partial<Report>

type Role = 'agro' | 'producer' | 'distributor'

const RoleWrapperStyled = styled.div`
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
`

const RoleStyled = styled.span`
  padding: 4px 6px;
  border-radius: 4px;
  margin: 4px;
  white-space: nowrap;
  color: ${props => props.theme.color.onPrimaryLight};
  background-color: ${(props: StyledProps<{ role: Role }>) => {
    switch (props.role) {
      case 'agro':
        return props.theme.color.primary500
      case 'distributor':
        return props.theme.color.accentApproving
      case 'producer':
        return props.theme.color.accentNotify
    }
  }};
`

const FilterWrapper = styled.div`
  min-width: 240px;
`

const RolesCell: React.FC<CellProps<ReportItem>> = ({ value }) => {
  const { t } = useTranslation(['role', 'permission'])
  if (!value) return null

  return (
    <RoleWrapperStyled>
      {value.map((item: ROLES) => {
        const [role, permission] = item.split(':')
        return (
          <RoleStyled key={item} role={role as Role}>{`${t(`role:${role}`)} ${t(
            `permission:${permission}`,
          )}`}</RoleStyled>
        )
      })}
    </RoleWrapperStyled>
  )
}

type FilterValue = Record<string, boolean | undefined>

const ChipFilter: React.FC<{
  handleFilterChange: (filter: { is_active?: boolean }) => void
  filterValue: FilterValue
  firstLabel: string
  secondLabel: string
  title: string
}> = ({ handleFilterChange, filterValue, firstLabel, secondLabel, title }) => {
  const options: FilterOption<boolean>[] = useMemo(
    () => [
      {
        value: true,
        title: firstLabel,
        bullet: true,
        color: 'green',
      },
      {
        value: false,
        title: secondLabel,
        bullet: true,
        color: 'orange',
      },
    ],
    [firstLabel, secondLabel],
  )
  return (
    <TableFilter
      title={title}
      filterValue={filterValue}
      handleFilterChange={handleFilterChange}
      options={options}
      disableMulti
    />
  )
}

const SlugCell: React.FC<CellProps<Report>> = ({ cell, column, row }) => {
  return (
    <div>
      <StatusIcon status={row.values.is_active ? 'active' : 'inactive'} />
      <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
    </div>
  )
}

const SlugHeaderCell: React.FC = () => {
  const { t } = useTranslation('report')
  return (
    <div>
      <StatusIcon />
      <span>{t('list.tableHeaders.slug')}</span>
    </div>
  )
}

const ReportList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['report', 'common'])
  const [progress, data = []] = useReportList()

  const total = useSelector(ReportSelectors.total)
  const page = useSelector(ReportSelectors.page)
  const pages = useSelector(ReportSelectors.pages)
  const pageSize = useSelector(ReportSelectors.pageSize)
  const filterValue = useSelector(ReportSelectors.filter)

  // TODO remove client-side filter
  const listRequested = useAction(ReportActions.listRequested)
  const filterUpdateAction = useAction(ReportActions.filterUpdated)
  const { scrollRef } = usePersistentScroll('reportList')
  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const mappedData = useMemo(
    () =>
      data.map(item => ({
        slug: item.slug,
        producer_official_name: item.producer_official_name,
        id: item.id,
        title: item.title,
        roles: item.roles,
        is_active: item.is_active,
      })),
    [data],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: SlugHeaderCell,
        accessor: 'slug' as const,
        Cell: SlugCell,
      },
      {
        Header: t('list.tableHeaders.manufacturer'),
        accessor: 'producer_official_name' as const,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
      },
      {
        Header: t('list.tableHeaders.roles'),
        accessor: 'roles' as const,
        Cell: RolesCell,
      },
      {
        Header: 'is_active',
        accessor: 'is_active' as const,
        hidden: true,
      },
      {
        Header: 'id',
        accessor: 'id' as const,
        hidden: true,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof Report)[] = useMemo(() => ['is_active', 'id'], [])
  const { columns, rows, prepareRow } = useTable<ReportItem>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data: mappedData,
  })

  useHelmet({ title: t('reportsMetaTitle') })

  const handleActiveFilterChange = useCallback(
    (newValue: Partial<ReportListRequestFilter>) => {
      filterUpdateAction({ ...filterValue, ...newValue })
    },
    [filterUpdateAction, filterValue],
  )

  const handleCompanyFilterChange = useCallback(
    (newValue?: string | ROLES, _?, key?: string) => {
      filterUpdateAction({
        ...filterValue,
        [key as string]: [newValue],
      })
    },
    [filterUpdateAction, filterValue],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={ReportRoutes.ReportAdd} />
          </Header.Title>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <TComponents.Filters>
            <ChipFilter
              title={t('filter.statusTitle')}
              firstLabel={t('report:active')}
              secondLabel={t('report:inactive')}
              filterValue={{ is_active: filterValue.is_active }}
              handleFilterChange={handleActiveFilterChange}
            />
            <ChipFilter
              title={t('filter.privacyTitle')}
              firstLabel={t('report:private')}
              secondLabel={t('report:common')}
              filterValue={{ is_private: filterValue.is_private }}
              handleFilterChange={handleActiveFilterChange}
            />
            <Filter title={t('labels:permissions')}>
              <RoleSelect isClearable onChange={handleCompanyFilterChange} value={filterValue.roles?.[0] as ROLES} />
            </Filter>
            <Filter title={t('form.labels.producer')}>
              <FilterWrapper>
                <CompanySelect
                  onChange={val => handleActiveFilterChange({ producer_id: val })}
                  value={filterValue.producer_id}
                  companyType={CompanyType.Producer}
                  isClearable
                  isSearchable
                />
              </FilterWrapper>
            </Filter>
          </TComponents.Filters>
          <Table
            total={total}
            pages={pages}
            pageSize={pageSize}
            currentPage={page}
            onSetPage={fetchNextItems}
            ref={scrollRef}
          >
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id} hidden={column.hidden}>
                    {column.render('Header')}
                  </TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(ReportRoutes.ReportEdit, { id: row.original.id }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ReportList
