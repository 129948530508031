import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { IconCompany, IconUser, Tooltip } from '@agro-club/frontend-shared'
import { ProgramSummaryListEntry } from 'modules/domain/programSummary/types'
import { portalMount } from 'views/components/MainMenu/MainMenu'
import { theme } from 'theme/theme'
import FarmerTooltip from './FarmerTooltip'
import RetailerTooltip from './RetailerTooltip'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const SubtitleWrapper = styled.div`
  color: ${props => props.theme.color.secondary200};
  font-weight: 500;
  margin: 6px;
`
const TitleItem = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 0 6px;
`
const NameBreaker = styled(TitleItem)`
  color: ${props => props.theme.color.secondary200};
`
const CustomIconUser = styled(IconUser)`
  fill: ${props => props.theme.color.primary600};
  width: 14px;
`
const CustomIconCompany = styled(IconCompany)`
  fill: ${props => props.theme.color.primary600};
  width: 14px;
`
type Props = {
  summary?: ProgramSummaryListEntry
}
const CustomPageTitle: React.FC<Props> = ({ summary }) => {
  const farmerName = `${summary?.farmer_data.first_name} ${summary?.farmer_data.last_name}`
  const retailerName = summary?.retailer_title.official_name
  const householdName = summary?.household?.name

  const [coords, setCoords] = useState({ top: 0, left: 0 })
  const [content, setContent] = useState(null)

  const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLElement>, content) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const widthWith = e.currentTarget.clientWidth
    // positioning the tooltip in the center of an element
    setCoords({
      left: widthWith / 2 + rect.x,
      top: rect.y + rect.height / 2 + 10,
    })
    setContent(content)
  }, [])

  return (
    <div>
      <TitleWrapper>
        <TitleWrapper
          onMouseLeave={() => {
            setCoords({ top: 0, left: 0 })
            setContent(null)
          }}
          onMouseEnter={e => handleMouseEnter(e, <FarmerTooltip summary={summary} />)}
        >
          <TitleItem>{farmerName}</TitleItem>
          <CustomIconUser />
        </TitleWrapper>
        <NameBreaker>/</NameBreaker>
        <TitleWrapper
          onMouseLeave={() => {
            setCoords({ top: 0, left: 0 })
            setContent(null)
          }}
          onMouseEnter={e => handleMouseEnter(e, <RetailerTooltip summary={summary} />)}
        >
          <TitleItem>{retailerName}</TitleItem>
          <CustomIconCompany />
        </TitleWrapper>
      </TitleWrapper>
      <SubtitleWrapper>{householdName}</SubtitleWrapper>
      {content && (
        <Tooltip
          visibleOnHover
          position="bottom"
          tooltipContent={content}
          temporary={false}
          style={{
            backgroundColor: theme.color.onPrimaryLight,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
            opacity: 1,
          }}
          portalProps={{
            coords,
            mount: portalMount as HTMLElement,
          }}
        />
      )}
    </div>
  )
}
export default CustomPageTitle
