import React from 'react'
import { SkuOption } from 'modules/domain/storefront/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ProductOption = styled.div`
  line-height: 20px;
  font-size: 14px;
`

const ReadOnlyOptions: React.FC<{ options?: SkuOption[] }> = ({ options }) => {
  const { t } = useTranslation('productOptions')

  if (!options || !options.length) return null

  return (
    <>
      {options
        .filter(o => !!o.title)
        .map((o: SkuOption) => (
          <ProductOption key={o.option_id}>
            <b>{t(`types.${o.type}`)}:</b> {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
          </ProductOption>
        ))}
    </>
  )
}

export default ReadOnlyOptions
