import styled from 'styled-components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCompanyById } from 'modules/domain/company/hooks'
import { arrToDict, makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/collection/managers'
import { Progress } from 'modules/types'
import { remove } from 'ramda'
import { FormikHook, SimpleSelect, TagList } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`

const SelectWrapper = styled.div`
  max-width: 350px;
`

const StyledTagList = styled(TagList)`
  width: 600px;
`

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 350px;
`

export type CompanyTerritoryFormProps = {
  territories: string[]
}
const CompanyTerritoryForm: React.FC<{
  useFormik: FormikHook
  companyId: string
  territories: string[]
}> = ({ companyId, territories: _territories, useFormik }) => {
  const { t } = useTranslation('company')
  const [progress, producer] = useCompanyById(companyId)
  const terrListHook = useMemo(() => makeCancellableResourceListHook(makeCancelable(managers.fetchTerritories)), [])
  const hookArgs = useMemo(() => ({ company_id: companyId, page_size: 1000 }), [companyId])
  const [, territoryList] = terrListHook(hookArgs)

  const territoryDict = useMemo(() => arrToDict(territoryList || []), [territoryList])

  const territories = useMemo(() => {
    return _territories.filter(terr => {
      const entry = territoryDict[terr]
      if (entry) {
        return entry.company_id === companyId
      }
      return false
    })
  }, [_territories, companyId, territoryDict])

  const formik = useFormik<CompanyTerritoryFormProps>({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      territories,
    },
  })

  const territorySelectOptions = useMemo(() => {
    if (territoryList) {
      const filtered = territoryList.filter(
        terr => !formik.values.territories.includes(terr.id) && terr.company_id === companyId,
      )
      return filtered.map(terr => ({ id: terr.id, title: terr.title }))
    }
    return []
  }, [formik.values.territories, companyId, territoryList])

  const territoryTags = useMemo(() => {
    if (territoryList) {
      return territoryList.filter(terr => formik.values.territories.includes(terr.id))
    }
    return []
  }, [formik.values.territories, territoryList])

  if (progress !== Progress.SUCCESS || !producer) {
    return (
      <SpinnerContainer>
        <SpinnerLayout />
      </SpinnerContainer>
    )
  }

  return (
    <Wrapper>
      <SelectWrapper data-test-id={'territories-select'}>
        <SimpleSelect
          label={producer.internal_name}
          options={territorySelectOptions}
          value={null}
          onChange={(val: string) => {
            const set = new Set(formik.values.territories)
            set.add(val)
            formik.setFieldValue('territories', [...set])
          }}
          placeholder={t('form.selectTerritory')}
        />
      </SelectWrapper>
      <StyledTagList
        list={territoryTags}
        onRemove={({ id }) => {
          const i = formik.values.territories.indexOf(id)
          if (-1 !== i) {
            formik.setFieldValue('territories', remove(i, 1, formik.values.territories))
          }
        }}
      />
    </Wrapper>
  )
}
export default CompanyTerritoryForm
