import React, { useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as uuid from 'uuid'
import { useAction, useHelmet } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { Progress } from 'modules/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'

import { useLicense } from 'modules/domain/license/hooks'
import LicenseRoutes from 'views/pages/License/routes'
import LicenseDetailsForm, { LicenseFormInputs } from 'views/pages/License/LicenseDetailsForm/LicenseDetailsForm'
import LicenseSelectors from 'modules/domain/license/selectors'
import LicenseActions from 'modules/domain/license/duck'
import { makeFeatureFlagsArray, makeFeatureFlagsObject } from './featureFlagsMapper'
import { LocalizedValue } from 'types/entities'
import { FileItem } from 'views/components/FileManager/types'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const LicenseEdit: React.FC<{ onUpdateInitialState(props: LicenseFormInputs): void }> = ({ onUpdateInitialState }) => {
  const history = useHistory()
  const { t } = useTranslation('license')
  const params = useParams<{ id: string }>()
  const meta = useSelector(state => LicenseSelectors.meta(state, params.id))
  const submitAction = useAction(LicenseActions.updateRequested)
  const removeAction = useAction(LicenseActions.removeRequested, params.id)

  const [fetchProgress, license] = useLicense(params.id)

  useHelmet({ title: license ? license.title : t('licensesMetaTitle') })

  const legalDocsFiles = useMemo(() => {
    if (
      !license ||
      (!license?.legal_docs?.cookies_policy &&
        !license?.legal_docs?.privacy_policy &&
        !license?.legal_docs?.user_agreement)
    )
      return {}

    const { cookies_policy, privacy_policy, user_agreement } = license?.legal_docs

    const getLinks = (legalDoc?: LocalizedValue<FileItem[] | string>) => {
      if (!legalDoc) return undefined

      const linkUrlKeys = Object.keys(legalDoc)

      const filesWithUrls = {}
      for (let i = 0; i < linkUrlKeys.length; i++) {
        const key = linkUrlKeys[i]
        const fileUrl = legalDoc[key]
        if (fileUrl) {
          filesWithUrls[key] = [
            {
              file: fileUrl,
              kind: 'current' as const,
              id: uuid.v4(),
              error: null,
            },
          ]
        }
      }
      return filesWithUrls
    }

    return {
      cookies_policy: getLinks(cookies_policy),
      privacy_policy: getLinks(privacy_policy),
      user_agreement: getLinks(user_agreement),
    }
  }, [license])

  const goBack = () => {
    history.push(LicenseRoutes.List)
  }

  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const initialValues = useMemo(() => {
    if (!license) {
      return undefined
    }

    const makeCurrentFile = (src?: string) => {
      if (!src) {
        return undefined
      }

      return {
        id: uuid.v4(),
        file: src,
        kind: 'current' as const,
        error: null,
      }
    }

    return {
      title: license.title,
      company_id: license.company_id,
      company_ids: license.company_ids,
      default_lang: license.default_lang,
      default_country_code: license.default_country_code,
      available_countries: license.available_countries,
      copyright: license.copyright,
      company_website_url: license.company_website_url,

      og_title: license.og_config?.og_title,
      og_description: license.og_config?.og_description,

      feature_flags: makeFeatureFlagsArray(license?.feature_flags),

      zendesk_key: license.third_party_keys?.zendesk_key,
      intercom_app_id: license.third_party_keys?.intercom_app_id,
      gtm_ids: license.third_party_keys?.gtm_ids,
      phone_codes: license.phone_codes,

      logo_file: makeCurrentFile(license.logo_url),
      footer_logo_file: makeCurrentFile(license.footer_logo_url),
      favicon_ico_file: makeCurrentFile(license.favicons?.ico),
      favicon_svg_file: makeCurrentFile(license.favicons?.svg),
      favicon_png_192_file: makeCurrentFile(license.favicons?.png_192),
      favicon_png_512_file: makeCurrentFile(license.favicons?.png_512),
      favicon_png_apple_touch_file: makeCurrentFile(license.favicons?.png_apple_touch),
      og_image_file: makeCurrentFile(license.og_config?.og_image),
      domains: license.domains,
      spinner_file: makeCurrentFile(license.images?.spinner_url),
      on_maintenance: license.on_maintenance,
      mobile_apps_on_maintenance: license.mobile_apps?.on_maintenance ?? false,
      phone_title: license.contacts?.phone?.title,
      phone_value: license.contacts?.phone?.value,
      email_value: license.contacts?.email?.value,
      email_title: license.contacts?.email?.title,
      user_agreement: legalDocsFiles.user_agreement,
      privacy_policy: legalDocsFiles.privacy_policy,
      cookies_policy: legalDocsFiles.cookies_policy,
      google_play: license.mobile_apps?.links?.google_play,
      app_store: license.mobile_apps?.links?.app_store,
    }
  }, [license, legalDocsFiles])

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />

  if (fetchProgress === Progress.WORK || !license) {
    return loading()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFormSubmit = (props: LicenseFormInputs, options: { duplicate: boolean; dirty: boolean }) => {
    const justRedirect = () => {
      history.push(LicenseRoutes.Add)
    }

    const update = (duplicate: boolean) => {
      submitAction(
        license.id,
        {
          company_ids: props.company_ids,
          title: props.title,
          default_lang: props.default_lang,
          default_country_code: props.default_country_code,
          available_countries: props.available_countries,
          company_website_url: props.company_website_url,
          copyright: props.copyright,
          logo_file: props.logo_file,
          footer_logo_file: props.footer_logo_file,
          favicon_files: {
            ico: props.favicon_ico_file,
            svg: props.favicon_svg_file,
            png_192: props.favicon_png_192_file,
            png_512: props.favicon_png_512_file,
            png_apple_touch: props.favicon_png_apple_touch_file,
          },
          og_config: {
            og_title: props.og_title,
            og_description: props.og_description,
            og_image_file: props.og_image_file,
          },
          colors: {},
          feature_flags: makeFeatureFlagsObject(props.feature_flags),
          third_party_keys: {
            ...license.third_party_keys,
            zendesk_key: props.zendesk_key,
            intercom_app_id: props.intercom_app_id,
            gtm_ids: props.gtm_ids,
          },
          phone_codes: props.phone_codes,
          domains: props.domains,
          images: {
            spinner_file: props.spinner_file,
          },
          on_maintenance: props.on_maintenance,
          contacts: {
            email:
              props.email_title && props.email_value
                ? {
                    title: props.email_title,
                    value: props.email_value,
                  }
                : null,
            phone:
              props.phone_title && props.phone_value
                ? {
                    title: props.phone_title,
                    value: props.phone_value,
                  }
                : null,
          },
          legal_docs: {
            cookies_policy: props.cookies_policy,
            privacy_policy: props.privacy_policy,
            user_agreement: props.user_agreement,
          },
          mobile_apps: {
            links: {
              google_play: props.google_play,
              app_store: props.app_store,
            },
            on_maintenance: props.mobile_apps_on_maintenance,
          },
        },
        duplicate,
      )
    }

    if (!options.duplicate) {
      update(false)
    } else {
      onUpdateInitialState(props)
      if (options.dirty) {
        update(true)
      } else {
        justRedirect()
      }
    }
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={license.title} />
          <Id>{`ID ${license.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <LicenseDetailsForm
          mode={'edit'}
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          onRemove={removeAction}
          initialValues={initialValues}
        />
      </Layout.Content>
    </>
  )
}

export default LicenseEdit
