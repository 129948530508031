import { useSelector } from 'react-redux'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { Progress, ResourceHook } from 'modules/types'
import FarmerActions from 'modules/domain/farmer/duck'
import { Farmer, FarmerData, FarmerMetaData } from 'modules/domain/farmer/types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from './managers'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useFarmerList: ResourceHook<Farmer[], void[]> = () => {
  const progress = useSelector(FarmerSelectors.listFetchProgress)
  const list = useSelector(FarmerSelectors.farmerList)
  const page = usePageQuery()
  const fetchAction = useAction(FarmerActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useFarmer: ResourceHook<Farmer, [string]> = (id: string) => {
  const meta = useSelector(state => FarmerSelectors.meta(state, id))
  const farmer = useSelector(state => FarmerSelectors.farmer(state, id))
  const fetchAction = useAction(FarmerActions.itemRequested, id)

  useDidMount(() => {
    if (!farmer || farmer.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, farmer]
}

export const useFarmerMetaData: ResourceHook<FarmerMetaData, [string]> = (id: string) => {
  const progress = useSelector(FarmerSelectors.metaFetchProgress)
  const farmer = useSelector(state => FarmerSelectors.farmer(state, id))
  const fetchAction = useAction(FarmerActions.itemMetaDataRequested, id, farmer)

  useDidMount(() => {
    if (!farmer?.metadata) {
      fetchAction()
    }
  })

  return [progress, farmer?.metadata]
}

export const useFarmerById: ResourceHook<Farmer, [string]> = (id: string) => {
  const meta = useSelector(state => FarmerSelectors.meta(state, id))
  const item = useSelector(state => FarmerSelectors.farmer(state, id))
  const fetchAction = useAction(FarmerActions.itemRequested, id)

  useEffect(() => {
    if (id && (!item || id !== item.id)) {
      fetchAction()
    }
  }, [item, fetchAction, id])

  return [meta.fetchProgress, item]
}

export const useFarmerByPhone: ResourceHook<FarmerData, [string]> = (phone: string) => {
  const meta = useSelector(state => FarmerSelectors.getMetaByPhone(state, phone))
  const item = useSelector(state => FarmerSelectors.farmerByPhone(state, phone))
  const fetchAction = useAction(FarmerActions.itemRequestedByPhone, phone)

  useEffect(() => {
    if (!phone) return
    if (!item || (phone !== item.phone_number && phone !== item.additional_phone)) {
      fetchAction()
    }
  }, [item, fetchAction, phone])

  return [meta.fetchProgress, item]
}

export const useFarmerOrdersTotalCount: ResourceHook<number, [string]> = (farmerId: string) => {
  const progress = useSelector(FarmerSelectors.ordersTotalCountFetchProgress)
  const farmer = useSelector(state => FarmerSelectors.farmer(state, farmerId))
  const fetchAction = useAction(FarmerActions.ordersTotalCountFetchRequested, farmerId)

  useDidMount(() => {
    if (farmer?.ordersTotalCount === undefined) {
      fetchAction()
    }
  })

  return [progress, farmer?.ordersTotalCount]
}

export const useCustomersOptions = makeCancellableResourceListHook(makeCancelable(managers.getCustomersList))
