import React, { useEffect, useMemo } from 'react'
import { useFarmerById } from 'modules/domain/farmer/hooks'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FarmerData } from 'modules/domain/farmer/types'
import styled from 'styled-components'
import { Button, Input } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

export const IdInputWrapper = styled.div`
  max-width: 240px;
  padding: 2px 0 20px 0;
`

export const FarmerNotFound = styled.div`
  text-align: center;
  color: ${props => props.theme.color.secondary200};
`

export const Content = styled.div`
  width: 100%;
  padding: 16px 24px 0;
`

export const SubmitIdButton = styled(Button)`
  margin-bottom: 16px;
`

export const Spinner = styled(SpinnerLayout)`
  margin: 50px auto;
`

export type SearchByIdFormProps = {
  id: string
}

const SearchFarmerById: React.FC<{
  farmerId: string
  onSubmit: (farmerId: string) => void
  onFarmerFound: (farmer: FarmerData | null) => void
}> = ({ farmerId, onSubmit, onFarmerFound }) => {
  const { t } = useTranslation(['common', 'farmer'])
  const [progress, farmer] = useFarmerById(farmerId)

  useEffect(() => {
    onFarmerFound(farmer ?? null)
  }, [farmer, onFarmerFound])

  const validationSchema = useMemo(() => Yup.object({ id: Yup.string().required(t('validation:field_required')) }), [t])

  const formik = useFormik<SearchByIdFormProps>({
    initialValues: {
      id: farmerId,
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleSubmit = (id: string) => {
    formik.submitForm()

    if (formik.isValid) {
      onSubmit(id)
    }
  }

  return (
    <>
      <Content>
        <IdInputWrapper>
          <Input
            value={formik.values.id}
            onChange={e => formik.setFieldValue('id', e.target.value)}
            label={t('farmer:addProductToCart.farmerId')}
            invalid={formik.touched.id && !!formik.errors.id}
            errorText={formik.errors.id}
            required
          />
        </IdInputWrapper>
        <SubmitIdButton intent={'primary'} filled={true} onClick={() => handleSubmit(formik.values.id)}>
          {t('common:next')}
        </SubmitIdButton>
      </Content>
      {farmerId && [Progress.IDLE, Progress.WORK].includes(progress) && <Spinner />}
      {progress === Progress.ERROR && <FarmerNotFound>{t('farmer:errors.notFoundTitle')}</FarmerNotFound>}
    </>
  )
}

export default SearchFarmerById
