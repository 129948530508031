import { useEffect, useRef } from 'react'

export default function useDebouncedEffect(cb: () => void, delay: number, deps: unknown[]) {
  const firstRender = useRef(true)
  useEffect(() => {
    if (!firstRender.current) {
      const timeout = setTimeout(cb, delay)
      return () => {
        clearTimeout(timeout)
      }
    }
    firstRender.current = false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
