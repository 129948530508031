import { AppGlobalState } from 'modules/types'

export const getRegionsFetchProgress = (state: AppGlobalState) => state.collection.regionsFetchProgress
export const getRegions = (state: AppGlobalState) => state.collection.regions
export const phoneCodesFetchProgress = (state: AppGlobalState) => state.collection.phoneCodesFetchProgress
export const phoneCodes = (state: AppGlobalState) => state.collection.phoneCodes
const CollectionSelectors = {
  getRegions,
  getRegionsFetchProgress,
  phoneCodes,
  phoneCodesFetchProgress,
}

export default CollectionSelectors
