import React, { useCallback } from 'react'
import { Company, CompanyRelation } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'
import * as Styled from './BranchesStyles'
import { clone } from 'ramda'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/company/managers'
import { Switch } from '@agro-club/frontend-shared'
import { CompanyType } from 'types/entities'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'

type Permission =
  | 'is_allowed_order_edit'
  | 'is_allowed_order_create'
  | 'is_allowed_distributor_order_edit'
  | 'is_allowed_distributor_order_create'

type PermissionBlockProps = {
  permission: Permission
  isPermitted: boolean
  onChange: (permission: Permission, isPermitted: boolean) => void
}

const useDistributorsOptions = makeCancellableResourceListHook(makeCancelable(managers.getDistributorsList))

const PermissionBlock: React.FC<PermissionBlockProps> = ({ permission, isPermitted, onChange }) => {
  const updateItem = useCallback(
    (permission: Permission, permitted: boolean) => {
      onChange(permission, permitted)
    },
    [onChange],
  )

  const handleChange = (isOn: boolean) => {
    updateItem(permission, isOn)
  }

  return (
    <div>
      <Switch on={isPermitted} onClick={handleChange} />
    </div>
  )
}

const BranchItem: React.FC<{
  name?: string
  company?: Company
  branch: CompanyRelation
  onChange: (id: string, relation: CompanyRelation) => void
  onRemove: (id: string) => void
}> = ({ branch, onChange, onRemove, name }) => {
  const updateItem = useCallback(
    (permission: Permission, isPermitted: boolean) => {
      const newBranch = clone(branch)
      newBranch[permission] = isPermitted
      onChange(branch.company_id, newBranch)
    },
    [branch, onChange],
  )

  const removeItem = useCallback(() => {
    onRemove(branch.company_id)
  }, [branch, onRemove])

  return (
    <React.Fragment key={branch.company_id}>
      <Styled.BranchName>{name}</Styled.BranchName>
      <PermissionBlock
        permission={'is_allowed_order_edit'}
        isPermitted={!!branch.is_allowed_order_edit}
        onChange={updateItem}
      />
      <PermissionBlock
        permission={'is_allowed_order_create'}
        isPermitted={!!branch.is_allowed_order_create}
        onChange={updateItem}
      />
      <PermissionBlock
        permission={'is_allowed_distributor_order_edit'}
        isPermitted={!!branch.is_allowed_distributor_order_edit}
        onChange={updateItem}
      />
      <PermissionBlock
        permission={'is_allowed_distributor_order_create'}
        isPermitted={!!branch.is_allowed_distributor_order_create}
        onChange={updateItem}
      />
      <Styled.RemoveBtn onClick={removeItem} />
    </React.Fragment>
  )
}

type BranchesProps = {
  branches: CompanyRelation[]
  headCompanyId?: string
  onChange: (id: string, relation: CompanyRelation) => void
  onRemove: (id: string) => void
}

const Branches: React.FC<BranchesProps> = ({ branches, onChange, onRemove, headCompanyId }) => {
  const { t } = useTranslation('company')
  const [, distributors = []] = useDistributorsOptions()

  const handleSelect = (value: string[]) => {
    const newBranchId = value[value.length - 1]

    onChange(newBranchId, {
      company_id: newBranchId,
      is_allowed_order_edit: false,
      is_allowed_order_create: false,
      is_allowed_distributor_order_edit: false,
      is_allowed_distributor_order_create: false,
    })
  }

  return (
    <Styled.BranchWrapper data-test-id={'branches-select'}>
      <Styled.AddWrapper>
        <CompanyMultiSelect
          isClearable
          isSearchable
          values={branches.map(item => item.company_id)}
          companyType={CompanyType.Distributor}
          placeholder={t('form.selectDistributor')}
          onChange={handleSelect}
          filter={{ has_branch_company: false, has_head_company: false, exclude_companies: headCompanyId }}
          disableTags
        />
      </Styled.AddWrapper>
      {!!branches.length && (
        <Styled.BranchList>
          <Styled.Label>{t('form.branchRetailer')}</Styled.Label>
          <Styled.Label>{t('form.farmerOrderManagement')}</Styled.Label>
          <Styled.Label>{t('form.farmerOrderCreation')}</Styled.Label>
          <Styled.Label>{t('form.retailerOrderManagement')}</Styled.Label>
          <Styled.Label>{t('form.retailerOrderCreation')}</Styled.Label>
          <div />
          {branches.map(branch => {
            const company = distributors.find(company => company.id === branch.company_id)
            return (
              <BranchItem
                key={branch.company_id}
                branch={branch}
                name={company ? company.internal_name : ''}
                onChange={onChange}
                onRemove={onRemove}
              />
            )
          })}
        </Styled.BranchList>
      )}
    </Styled.BranchWrapper>
  )
}

export default Branches
