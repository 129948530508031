import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import PackageTypesActions from './duck'
import PackageTypesSelectors from './selectors'
import { PackageTypes } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { createItemsByIdsHook } from 'modules/utils/helpers/createItemsByIdsHook'
import { getItem } from './managers'

export const usePackageTypesList: ResourceHook<PackageTypes[], void[]> = () => {
  const progress = useSelector(PackageTypesSelectors.listFetchProgress)
  const list = useSelector(PackageTypesSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(PackageTypesActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useAllPackageTypesList: ResourceHook<PackageTypes[], void[]> = () => {
  const progress = useSelector(PackageTypesSelectors.listAllFetchProgress)
  const list = useSelector(PackageTypesSelectors.listAll)
  const fetchAction = useAction(PackageTypesActions.listAllRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const usePackageTypes: ResourceHook<PackageTypes, [string]> = (id: string) => {
  const meta = useSelector(state => PackageTypesSelectors.meta(state, id))
  const item = useSelector(state => PackageTypesSelectors.item(state, id))
  const fetchAction = useAction(PackageTypesActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const usePackageTypesById: ResourceHook<PackageTypes, [string]> = (id: string) => {
  const meta = useSelector(state => PackageTypesSelectors.meta(state, id))
  const item = useSelector(state => PackageTypesSelectors.item(state, id))
  const fetchAction = useAction(PackageTypesActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const usePackageTypesByIds = createItemsByIdsHook(false, getItem)
