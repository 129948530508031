import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import Routes from './routes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReturnDeclarationSkuDetailsForm, {
  FormFields,
} from 'views/pages/ReturnDeclarationSku/ReturnDeclarationSkuDetailsForm/ReturnDeclarationSkuDetailsForm'
import ReturnDeclarationSkuActions from 'modules/domain/returnDeclarationSku/duck'
import ReturnDeclarationSkuSelectors from 'modules/domain/returnDeclarationSku/selectors'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isDistributor } from 'types/entities'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'

const ReturnDeclarationSkuAdd: React.FC<{
  initialState?: FormFields
}> = ({ initialState }) => {
  const { t } = useTranslation('returnDeclaration')
  const push = useHistoryPush()
  const page = useSelector(ReturnDeclarationSkuSelectors.page)
  const addProgress = useSelector(ReturnDeclarationSkuSelectors.addProgress)
  const goBack = useCallback(() => push({ route: Routes.List, query: page ? { page } : undefined }), [page, push])
  const addDeclaration = useAction(ReturnDeclarationSkuActions.addRequested)
  const role = useSelector(AuthSelectors.role)

  useHelmet({ title: t('addPageTitle') })

  const handleSubmit = useCallback(
    (values: FormFields, options: { duplicate: boolean; dirty: boolean }) => {
      addDeclaration(values, options.duplicate)
    },
    [addDeclaration],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('addPageTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReturnDeclarationSkuDetailsForm
          mode={'create'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          progress={addProgress}
          initialValues={{
            status: initialState?.status || ReturnDeclarationStatus.New,
            pickup_note: initialState?.pickup_note,
            distributor_id: initialState?.distributor_id || '',
            seller_id: initialState?.seller_id,
            comment: initialState?.comment,
            sku_items: initialState?.sku_items || [],
            season_id: initialState?.season_id,
            type: isDistributor(role) ? ReturnDeclarationType.EndOfSeason : initialState?.type,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ReturnDeclarationSkuAdd
