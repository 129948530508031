import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import {
  Allocation,
  AllocationDTO,
  AllocationListRequestSorting,
  AllocationRequestFilter,
  ProductLimitRequestFilter,
  ProductsLimitResponse,
} from '../common/types'

export const retailerManagers = {
  ...createBasicManagers<
    AllocationRequestFilter,
    AllocationListRequestSorting,
    Allocation,
    AllocationDTO,
    AllocationDTO
  >(endpoints.retailerAllocation),
  farmerOrdersProductLimit: (dto: ProductLimitRequestFilter) => {
    return apiClient.post<ProductsLimitResponse>(
      endpoints.retailerAllocation('check-products-limits-for-farmer-orders'),
      dto,
    )
  },
  retailerOrdersProductLimit: (dto: ProductLimitRequestFilter) => {
    return apiClient.post<ProductsLimitResponse>(
      endpoints.retailerAllocation('check-products-limits-for-distributor-orders'),
      dto,
    )
  },
}
