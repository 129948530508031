import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import ProductOptionsActions from './duck'
import ProductOptionsSelectors from './selectors'
import { ProductOptions } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import * as managers from './managers'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import { createItemsByIdsOnceHook } from 'modules/utils/helpers/createItemsByIdsOnceHook'
import { createItemsByIdsHook } from 'modules/utils/helpers/createItemsByIdsHook'

export const useProductOptionsList: ResourceHook<ProductOptions[], void[]> = () => {
  const progress = useSelector(ProductOptionsSelectors.listFetchProgress)
  const list = useSelector(ProductOptionsSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(ProductOptionsActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useAllProductOptionsList: ResourceHook<ProductOptions[], void[]> = () => {
  const progress = useSelector(ProductOptionsSelectors.listAllFetchProgress)
  const list = useSelector(ProductOptionsSelectors.listAll)
  const fetchAction = useAction(ProductOptionsActions.listAllRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useProductOptions: ResourceHook<ProductOptions, [string]> = (id: string) => {
  const meta = useSelector(state => ProductOptionsSelectors.meta(state, id))
  const item = useSelector(state => ProductOptionsSelectors.item(state, id))
  const fetchAction = useAction(ProductOptionsActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useProductOptionsById: ResourceHook<ProductOptions, [string | undefined]> = (id?: string) => {
  const meta = useSelector(state => ProductOptionsSelectors.meta(state, id))
  const item = useSelector(state => ProductOptionsSelectors.item(state, id))
  const fetchAction = useAction(ProductOptionsActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useProductOptionsWithoutRedux = makeCancellableResourceListHook(makeCancelable(managers.getList))

export const useProductOptionsByIdsOnce = createItemsByIdsOnceHook(false, managers.getItem)
export const useProductOptionsByIds = createItemsByIdsHook(false, managers.getItem)
