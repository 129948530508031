import React from 'react'
import { TextBolder, TextTertiary } from '../styles'

export const TitleCell = ({ value }) => {
  return value?.title ?? null
}

export const TitleI18nCell = (pick: (prop?: any) => string) => ({ value }) => {
  return pick(value?.title ?? '')
}
export const BolderCell = ({ value }) => <TextBolder>{value}</TextBolder>

export const TertiaryCell = ({ value }) => <TextTertiary>{value}</TextTertiary>
