import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, Input, SectionBody, SectionContainer, Switch, Tooltip } from '@agro-club/frontend-shared'

import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { Progress } from 'modules/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as Styled from './styled'
import useDateFormat from 'hooks/useDateFormat'
import { CompanyType } from 'types/entities'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

export type SeasonFormInputs = {
  company_id: string
  title: string
  start_date: string
  enable_final_qty?: boolean
  enable_delivered_qty?: boolean
  stop_taking_orders?: boolean
  is_season_closed?: boolean
  is_default_for_return_declarations?: boolean
  show_retailer_order_suggestion?: boolean
}

type SeasonFormProps = {
  initialValues?: Partial<SeasonFormInputs>
  editing?: boolean
  progress?: Progress
  removeProgress?: Progress
  onSubmit(form: SeasonFormInputs): void
  onCancel(): void
  onRemove?(): void
}

const SeasonDetailsForm: React.FC<SeasonFormProps> = ({
  initialValues = {
    company_id: undefined,
    title: undefined,
    start_date: undefined,
    enable_final_qty: false,
    enable_delivered_qty: false,
    stop_taking_orders: false,
    is_season_closed: false,
    is_default_for_return_declarations: false,
    show_retailer_order_suggestion: false,
  },
  editing = false,
  progress,
  removeProgress,
  onSubmit,
  onCancel,
  // ENT-477 delete season option is temporarily hidden
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRemove,
}) => {
  const { t } = useTranslation(['season', 'common'])
  const dateFormat = useDateFormat({ withTime: true })

  const handleSubmit = (inputs: Partial<SeasonFormInputs>) => {
    if (!formik.isValid) {
      return
    }
    const data: SeasonFormInputs = {
      company_id: inputs.company_id || '',
      title: inputs.title || '',
      start_date: inputs.start_date || '',
      enable_final_qty: inputs.enable_final_qty,
      enable_delivered_qty: inputs.enable_delivered_qty,
      stop_taking_orders: inputs.stop_taking_orders,
      is_season_closed: inputs.is_season_closed,
      is_default_for_return_declarations: inputs.is_default_for_return_declarations,
      show_retailer_order_suggestion: inputs.show_retailer_order_suggestion,
    }
    onSubmit(data)
  }

  const validationSchema = Yup.object({
    company_id: Yup.string().required(t('validation:field_required')),
    title: Yup.string().required(t('validation:field_required')),
    start_date: Yup.string().required(t('validation:field_required')),
  })

  const formik = useFormik<Partial<SeasonFormInputs>>({
    initialValues: initialValues || {},
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Styled.Wrapper>
              <Styled.FormSection title={t('form.sections.mainSettings')}>
                <CompanySelect
                  label={t('form.labels.producer')}
                  onChange={(id?: string) => {
                    formik.setFieldValue('company_id', id)
                  }}
                  value={formik.values.company_id}
                  companyType={CompanyType.Producer}
                  isClearable
                  isSearchable
                  onMenuClose={() => formik.setFieldTouched('company_id')}
                  invalid={formik.touched.company_id && !!formik.errors.company_id}
                  errorText={formik.errors.company_id}
                  isDisabled={editing}
                  required
                />
                <Input
                  {...formik.getFieldProps('title')}
                  invalid={formik.touched.title && !!formik.errors.title}
                  label={t('form.labels.title')}
                  errorText={formik.errors.title}
                  required
                />
                <DatePicker
                  onChange={value => {
                    formik.setFieldValue('start_date', value)
                    formik.setFieldTouched('start_date')
                  }}
                  date={formik.getFieldProps('start_date').value}
                  invalid={formik.touched.start_date && !!formik.errors.start_date}
                  errorText={formik.errors.start_date}
                  label={t('form.labels.startDate')}
                  required
                  format={dateFormat}
                />
              </Styled.FormSection>
              <Styled.SwitcherWrapper>
                <Switch
                  on={formik.getFieldProps('is_season_closed').value}
                  onClick={value => {
                    formik.setFieldValue('is_season_closed', value)
                  }}
                  label={t('form.labels.isSeasonClosed')}
                />
                <Tooltip
                  visibleOnHover
                  temporary
                  tooltipContent={
                    <Styled.TooltipContent>{t('form.tooltip.seasonClosedDescription')}</Styled.TooltipContent>
                  }
                  position="right"
                >
                  <Styled.IconQuestionStyled />
                </Tooltip>
              </Styled.SwitcherWrapper>
              <Switch
                on={formik.getFieldProps('is_default_for_return_declarations').value}
                onClick={value => {
                  formik.setFieldValue('is_default_for_return_declarations', value)
                }}
                label={t('form.labels.isDefaultForReturnDeclarations')}
              />
              <Switch
                on={formik.getFieldProps('enable_final_qty').value}
                onClick={value => {
                  formik.setFieldValue('enable_final_qty', value)
                }}
                label={t('form.labels.enableFinalQty')}
              />
              <Switch
                on={formik.getFieldProps('enable_delivered_qty').value}
                onClick={value => {
                  formik.setFieldValue('enable_delivered_qty', value)
                }}
                label={t('form.labels.enableDeliveredQty')}
              />
              <Switch
                on={formik.getFieldProps('stop_taking_orders').value}
                onClick={value => {
                  formik.setFieldValue('stop_taking_orders', value)
                }}
                label={t('form.labels.stopTakingOrders')}
              />
              <Switch
                on={formik.getFieldProps('show_retailer_order_suggestion').value}
                onClick={value => {
                  formik.setFieldValue('show_retailer_order_suggestion', value)
                }}
                label={t('form.labels.showRetailerOrderSuggestion')}
              />
            </Styled.Wrapper>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterDefaultControls
        //onRemove={onRemove}  // ENT-477 delete season option is temporarily hidden
        onSave={formik.submitForm}
        onCancel={onCancel}
        saveProgress={progress}
        isSaveDisabled={!formik.dirty || progress === Progress.WORK}
        removeProgress={removeProgress}
        popoverText={t('form.removeText', { title: initialValues.title })}
      />
    </StickyFooterLayout.Wrapper>
  )
}

export default SeasonDetailsForm
