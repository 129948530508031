import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import PackageTypesActions from 'modules/domain/packageTypes/duck'
import PackageTypesSelectors from 'modules/domain/packageTypes/selectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import StorefrontPackageTypesList from 'views/pages/Storefront/StorefrontPackageTypes/StorefrontPackageTypesList'
import { StorefrontHeader } from '../components/StorefrontHeader'
import * as Styled from './styled'

const StorefrontPackageTypesTable: React.FC = () => {
  const { t } = useTranslation(['storefront', 'packageTypes'])
  useHelmet({ title: t('packageTypes:metaTitle') })

  const filterUpdated = useAction(PackageTypesActions.filterUpdated)
  const filterValue = useSelector(PackageTypesSelectors.filter)

  const onSearchChange = (val: string) => {
    filterUpdated({ ...filterValue, search: val })
  }

  return (
    <>
      <Layout.Header>
        <StorefrontHeader tab={'packageTypes'} />

        <Styled.SearchInputWrapper>
          <SearchInput
            onChange={onSearchChange}
            value={filterValue.search}
            placeholder={t('cards:searchPlaceholder')}
            data-test-id="card-search"
          />
        </Styled.SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <StorefrontPackageTypesList />
      </Layout.Content>
    </>
  )
}

export default StorefrontPackageTypesTable
