import React, { useEffect, useRef, useState } from 'react'
import { Prompt, PromptProps } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

type AllowTransitionCallback = (arg: boolean) => void

export type UsePreventNavigationProps = Omit<PromptProps, 'message'>

/**
 * needs to be pasted in createBrowserHistory in order to usePreventNavigationNotification work
 */
export const getUserConfirmation = (dialogKey: string, cb: AllowTransitionCallback) => {
  const dialogTrigger = window[Symbol.for(dialogKey)]

  if (dialogTrigger) {
    return dialogTrigger(cb)
  }

  cb(true)
}

/**
 * Not prevents navigation itself, prompt component needs to be mounted!
 */
export const usePreventNavigation = (props: UsePreventNavigationProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const allowTransitionCallback = useRef<AllowTransitionCallback | null>(null)
  const trigger = useRef(Symbol.for(`__PreventNavigationDialog_${uuid()}`))

  const show = (cb: AllowTransitionCallback) => {
    cb(false)
    allowTransitionCallback.current = cb
    setIsOpen(true)
  }

  const allowTransition = () => {
    if (allowTransitionCallback.current) {
      allowTransitionCallback.current(true)
    }
  }

  const close = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const symbol = trigger.current
    window[symbol] = show

    return () => {
      delete window[symbol]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isOpen,
    allowTransition,
    close,
    prompt: <Prompt message={Symbol.keyFor(trigger.current) || ''} {...props} />,
  }
}
