import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { CountryCode } from 'libphonenumber-js'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'

export type CallBackRequestState = {
  items: Dict<CallBackRequest>
  meta: Dict<EntityMetadata<CallBackRequest>>
  ids: string[]

  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null

  filter: CallBackRequestListRequestFilter
  sorting: CallBackRequestListRequestSorting
  page: number
  total: number
  pageSize: number

  pendingCallsCount: number
  pendingCallsCountFetchProgress: Progress
}

export enum CallBackRequestStatus {
  NEW = 'new',
  DONE = 'done',
}

export type CallBackRequest = {
  id: string
  phone: string
  status: CallBackRequestStatus
  comment?: string
  region?: CountryCode
  created_at: string
  updated_at: string
}

export type CallBackRequestListRequestFilter = {
  search?: string
  status?: CallBackRequestStatus
}

export type CallBackRequestListRequestSorting = {
  sort_field?: keyof CallBackRequest
  sort_reversed?: boolean
}

export type CallBackRequestDTO = Omit<CallBackRequest, 'id'> & { id?: string }
