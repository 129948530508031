import { Progress } from '@agro-club/frontend-shared'
import { ResourceHook } from 'modules/types'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { useEffect, useRef, useState } from 'react'
import IncentiveProgramActions from './duck'
import managers from './managers'
import IncentiveProgramSelectors from './selectors'
import { SkuCalculateDiscountsRequest, SkuCalculateDiscountsResponse } from './types'

export const useIncentiveProgramList = createItemListOnceHook(
  IncentiveProgramSelectors,
  IncentiveProgramActions.listRequested,
)

export const useIncentiveProgram = createItemByIdOnceHook(
  IncentiveProgramSelectors,
  IncentiveProgramActions.itemRequested,
)

export const useIncentiveProgramById = createItemByIdHook(
  IncentiveProgramSelectors,
  IncentiveProgramActions.itemRequested,
)

export const useCalculatedDiscounts: ResourceHook<
  SkuCalculateDiscountsResponse,
  [SkuCalculateDiscountsRequest]
> = params => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [calculatedDiscountDto, setCalculatedDiscountDto] = useState<SkuCalculateDiscountsResponse>()
  const timerId = useRef(0)

  useEffect(() => {
    if (!params.seller_id || !params.season_id || !params.entries || !params.entries.length) {
      return
    }

    const fetch = async (params: SkuCalculateDiscountsRequest) => {
      setProgress(Progress.WORK)
      try {
        const response = await managers.calculateDiscount(params)
        setCalculatedDiscountDto(response)
        setProgress(Progress.SUCCESS)
      } catch (e) {
        setProgress(Progress.ERROR)
      }
    }

    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    timerId.current = window.setTimeout(() => {
      timerId.current = 0
      fetch(params)
    }, 300)
  }, [params])

  return [progress, calculatedDiscountDto]
}

export const useIncentivePromocodeValidation = (company_id?: string | null) => {
  if (!company_id) {
    return () => {
      return null
    }
  }

  return async (code: string, applied_promocodes?: string[]) => {
    return await managers.checkPromocode({ code, company_id, applied_promocodes })
  }
}
