import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { Progress } from 'modules/types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import {
  ProgramDTO,
  ProgramSummaryListEntry,
  ProgramSummaryListFilter,
  ProgramSummaryListSorting,
  ProgramSummaryState,
} from './types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { FetchError, UpdateError } from '../types'
import { mapProgramSummaryProgram } from './mapper'

const initialState: ProgramSummaryState = {
  items: {},
  meta: {},
  ids: [],
  programs: [],
  programsFetchProgress: Progress.IDLE,
  programsFetchError: null,
  batchUpdateError: null,
  batchUpdateProgress: Progress.IDLE,
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  removeErrorDetail: null,
  addErrorDetail: null,
  updateErrorDetail: null,
  listFetchErrorDetail: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  pageSize: LIST_PAGE_SIZE,
  filter: {},
  sorting: {},
  page: 1,
  total: 0,
}

class ProgramSummaryReducer extends BasicEntityImmerReducer<
  ProgramSummaryListEntry,
  ProgramSummaryListFilter,
  ProgramSummaryListSorting,
  {},
  {},
  ProgramSummaryState
> {
  programRequested(id: string) {
    delete this.draftState.programs[id]
    this.draftState.programsFetchProgress = Progress.WORK
    this.draftState.programsFetchError = null
    this.draftState.programsFetchErrorDetail = undefined
  }
  programRequestSucceed(list: ProgramDTO[]) {
    this.draftState.programsFetchProgress = Progress.SUCCESS
    this.draftState.programsFetchError = null
    this.draftState.programsFetchErrorDetail = undefined
    // this.draftState.programs = arrToDict(list.map(mapProgramSummaryProgram))
    this.draftState.programs = list.map(mapProgramSummaryProgram)
  }
  programRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.programsFetchProgress = Progress.ERROR
    this.draftState.programsFetchError = error
    this.draftState.programsFetchErrorDetail = errorDetail
  }
  batchUpdateRequested(_dtos: ProgramDTO[]) {
    this.draftState.batchUpdateProgress = Progress.WORK
  }
  batchUpdateSucceed(items: ProgramDTO[]) {
    this.draftState.batchUpdateProgress = Progress.SUCCESS
    items.forEach((item: ProgramDTO) => {
      const updatedItem = mapProgramSummaryProgram(item)
      const index = this.draftState.programs.findIndex(e => e.id === updatedItem.id)
      this.draftState.programs[index] = {
        ...this.draftState.programs[index],
        ...updatedItem,
      }
    })
  }
  batchUpdateError(error: UpdateError, errorDetail?: string) {
    this.draftState.batchUpdateProgress = Progress.ERROR
    this.draftState.batchUpdateError = error
    this.draftState.batchUpdateErrorDetail = errorDetail
  }
}

export const ProgramSummaryActions = createActionCreators(ProgramSummaryReducer)
export default ProgramSummaryActions
export const reducer = createReducerFunction(ProgramSummaryReducer, initialState)
