import { useSelector } from 'react-redux'
import { ROLES } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { FarmerOrderItem } from 'types/farmerOrder'
import { DistributorOrderItem } from 'types/distributorOrder'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import { DistributorOrderSkuItem } from 'modules/domain/distributorOrderSku/types'

export const isSkuItem = (
  item: FarmerOrderItem | DistributorOrderItem | FarmerOrderSkuItem,
): item is FarmerOrderSkuItem => {
  return (item as FarmerOrderSkuItem).sku_id !== undefined
}

const useHasOutOfStockDateExpired = (
  items: FarmerOrderItem[] | DistributorOrderItem[] | FarmerOrderSkuItem[] | DistributorOrderSkuItem[],
): boolean => {
  const role = useSelector(AuthSelectors.role)
  const notPermittedForRole =
    !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER, ROLES.PRODUCER_MANAGER].includes(role)

  const hasExpired = items.some((item: FarmerOrderItem | DistributorOrderItem | FarmerOrderSkuItem) => {
    const expirationDate = isSkuItem(item) ? item.sku?.out_of_stock_date : item.product?.out_of_stock_date
    return !!expirationDate && new Date() >= new Date(expirationDate)
  })

  return hasExpired && notPermittedForRole
}

export default useHasOutOfStockDateExpired
