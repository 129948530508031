import managers from 'modules/domain/promocode/managers'
import { Promocode } from 'modules/domain/promocode/types'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type PromocodeSelectProps = EntitySelectProps<Promocode> & {
  isActive?: boolean
  companyId?: string
  promocode?: string
}

export const getPromocodeLabel = (item: Promocode) => item.code

export const PromocodeSelect: React.VFC<PromocodeSelectProps> = ({ isActive, companyId, promocode, ...props }) => {
  const filter = useMemo(() => ({ is_active: isActive, company_id: companyId }), [companyId, isActive])
  const value = useMemo(
    () =>
      promocode
        ? {
            code: promocode,
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promocode],
  )

  return (
    <SelectTestWrapper data-test-id={`promocode-select`}>
      <EntitySelect
        //I don't want to explain to typescript that I'm doing the right thing
        fullValue={value as any}
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        filter={filter}
        getOptionLabel={getPromocodeLabel}
        getOptionValue={getPromocodeLabel}
        {...props}
      />
    </SelectTestWrapper>
  )
}
