import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'
import { AppGlobalState } from '../../../types'

const retailerProductsLimit = (state: AppGlobalState) => state.territoryAllocation.retailerProductsLimit
const retailerProductLimitFetchProgress = (state: AppGlobalState) =>
  state.territoryAllocation.retailerProductLimitFetchProgress

const TerritoryAllocationSelectors = {
  ...createBasicSelectors('territoryAllocation'),
  retailerProductsLimit,
  retailerProductLimitFetchProgress,
}

export default TerritoryAllocationSelectors
