import { useRef } from 'react'

export default function useDebouncedFunction(func: () => void, delay: number) {
  const ref = useRef<NodeJS.Timeout | null>(null)

  return () => {
    ref.current && clearTimeout(ref.current)
    ref.current = setTimeout(() => func(), delay)
  }
}
