import React from 'react'

import { Switch, Route } from 'react-router-dom'
import MarketingCampaignsRoutes from './routes'
import UnderMaintenancePage from '../UnderMaintenance/UnderMaintenancePage'

const MarketingCampaignsPage: React.FC = () => {
  return (
    <Switch>
      <Route path={MarketingCampaignsRoutes.List}>
        <UnderMaintenancePage />
      </Route>
    </Switch>
  )
}

export default MarketingCampaignsPage
