import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  DistributorOrderListRequestSorting,
  DistributorOrderListRequestFilter,
  DistributorCreateOrderDTO,
  DistributorOrderUpdateDTO,
} from './types'
import { ListResponse } from 'types/api'
import { DistributorOrder } from 'types/distributorOrder'

export const getList = (
  filter: DistributorOrderListRequestFilter,
  sorting: DistributorOrderListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<DistributorOrder>>(endpoints.distributorOrder(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<DistributorOrder>(endpoints.distributorOrder(id))
}

export const addItem = (dto: DistributorCreateOrderDTO) => {
  return apiClient.post<DistributorOrder>(endpoints.distributorOrder(), dto)
}

export const updateItem = (id: string, dto: Partial<DistributorOrderUpdateDTO>) => {
  return apiClient.put<DistributorOrder>(endpoints.distributorOrder(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<DistributorOrder>(endpoints.distributorOrder(id))
}

export const ordersCount = async (filter: DistributorOrderListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<DistributorOrder>>(endpoints.distributorOrder('count'), {
    ...filter,
    page_size: 1,
  })
  return response
}
