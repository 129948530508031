import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  InventoryExchangeTransaction,
  InventoryExchangeTransactionListRequestSorting,
  InventoryExchangeTransactionListRequestFilter,
  InventoryExchangeTransactionDTO,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: InventoryExchangeTransactionListRequestFilter,
  sorting: InventoryExchangeTransactionListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<InventoryExchangeTransaction>>(endpoints.inventoryExchangeTransaction(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<InventoryExchangeTransaction>(endpoints.inventoryExchangeTransaction(id))
}

export const addItem = (dto: InventoryExchangeTransactionDTO) => {
  return apiClient.post<InventoryExchangeTransaction>(endpoints.inventoryExchangeTransaction(), dto)
}

export const updateItem = (id: string, dto: Partial<InventoryExchangeTransactionDTO>) => {
  return apiClient.put<InventoryExchangeTransaction>(endpoints.inventoryExchangeTransaction(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<InventoryExchangeTransaction>(endpoints.inventoryExchangeTransaction(id))
}
