import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { CommentsState, CommentEntryDTO, CommentEntry } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Progress } from 'modules/types'
import { castDraft } from 'immer'

const initialState: CommentsState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchErrorDetail: null,
  listFetchError: null,
  syncProgress: Progress.IDLE,
  syncErrorDetail: null,
  syncError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextErrorDetail: null,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchErrorDetail: null,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addErrorDetail: null,
  addError: null,
  updateProgress: Progress.IDLE,
  updateErrorDetail: null,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeErrorDetail: null,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class CommentsReducer extends ImmerReducer<CommentsState> {
  listRequested(_params: { collectionName: string; objId: string }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = null
    this.draftState.listFetchError = null
  }
  listRequestSucceed(response: Array<CommentEntryDTO>) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = castDraft(arrToDict(response))
    this.draftState.meta = arrToDict(
      response.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(response)
  }

  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail || null
  }

  addItemRequested(_params: { collectionName: string; objId: string; comment: string }) {
    this.draftState.addProgress = Progress.WORK
  }

  addItemSucceed(item: CommentEntryDTO) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.meta[item.id]
    this.draftState.items[item.id] = castDraft(item)
  }

  addItemFailed(error: UpdateError, errorDetail?: string) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail || null
  }

  removeRequested(item: CommentEntryDTO) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[item.id].removeProgress = Progress.WORK
    this.draftState.meta[item.id].removeError = null
  }

  removeSucceed(item: CommentEntryDTO) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[item.id]
    delete this.draftState.meta[item.id]
    const i = this.draftState.ids.findIndex(e => e === item.id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.removeErrorDetail = errorDetail || null
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
  }

  batchCommentsSyncRequested(_params: {
    collectionName: string
    objId: string
    newComments: CommentEntry[]
    oldComments: CommentEntryDTO[]
  }) {
    this.draftState.syncProgress = Progress.WORK
  }

  batchCommentsSyncSucceed(_params: { collectionName: string; objId: string }) {
    this.draftState.syncProgress = Progress.SUCCESS
  }

  batchCommentsSyncFailed(error: RemoveError, errorDetail?: string) {
    this.draftState.syncProgress = Progress.ERROR
    this.draftState.syncErrorDetail = errorDetail || null
  }
}

export const CommentsActions = createActionCreators(CommentsReducer)
export default CommentsActions
export const reducer = createReducerFunction(CommentsReducer, initialState)
