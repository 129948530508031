import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import InventoryExchangeTransactionActions from './duck'
import InventoryExchangeTransactionSelectors from './selectors'
import { InventoryExchangeTransaction } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useInventoryTransactionList: ResourceHook<InventoryExchangeTransaction[], void[]> = () => {
  const progress = useSelector(InventoryExchangeTransactionSelectors.listFetchProgress)
  const list = useSelector(InventoryExchangeTransactionSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(InventoryExchangeTransactionActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useInventoryTransaction: ResourceHook<InventoryExchangeTransaction, [string]> = (id: string) => {
  const meta = useSelector(state => InventoryExchangeTransactionSelectors.meta(state, id))
  const item = useSelector(state => InventoryExchangeTransactionSelectors.item(state, id))
  const fetchAction = useAction(InventoryExchangeTransactionActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useInventoryTransactionById: ResourceHook<InventoryExchangeTransaction, [string]> = (id: string) => {
  const meta = useSelector(state => InventoryExchangeTransactionSelectors.meta(state, id))
  const item = useSelector(state => InventoryExchangeTransactionSelectors.item(state, id))
  const fetchAction = useAction(InventoryExchangeTransactionActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
