import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import CollectionSelectors from 'modules/domain/collection/selectors'
import LicenseActions from 'modules/domain/license/duck'
import LicenseSelectors from 'modules/domain/license/selectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import LicenseDetailsForm, { LicenseFormInputs } from 'views/pages/License/LicenseDetailsForm/LicenseDetailsForm'
import LicenseRoutes from 'views/pages/License/routes'
import * as Header from 'views/ui/Header/Header'
import { makeFeatureFlagsObject } from './featureFlagsMapper'

const LicenseAdd: React.FC<{
  initialState?: LicenseFormInputs
  onUpdateInitialState(props: LicenseFormInputs): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('license')
  const submitAction = useAction(LicenseActions.addRequested)
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  // const addProgress = useSelector(LicenseSelectors.addProgress)

  const handleFormSubmit = (props: LicenseFormInputs, options: { duplicate: boolean; dirty: boolean }) => {
    if (options.duplicate) {
      onUpdateInitialState(props)
    }

    submitAction(
      {
        company_ids: props.company_ids,
        title: props.title,
        default_lang: props.default_lang,
        available_countries: props.available_countries,
        default_country_code: props.default_country_code,
        company_website_url: props.company_website_url,
        copyright: props.copyright,
        logo_file: props.logo_file,
        footer_logo_file: props.footer_logo_file,
        favicon_files: {
          ico: props.favicon_ico_file,
          svg: props.favicon_svg_file,
          png_192: props.favicon_png_192_file,
          png_512: props.favicon_png_512_file,
          png_apple_touch: props.favicon_png_apple_touch_file,
        },
        og_config: {
          og_title: props.og_title,
          og_description: props.og_description,
          og_image_file: props.og_image_file,
        },
        colors: {},
        feature_flags: makeFeatureFlagsObject(props.feature_flags),
        third_party_keys: {
          intercom_app_id: props.intercom_app_id,
          gtm_ids: props.gtm_ids,
          zendesk_key: props.zendesk_key,
        },
        phone_codes: props.phone_codes,
        domains: props.domains,
        images: {
          spinner_file: props.spinner_file,
        },
        on_maintenance: props.on_maintenance,
        legal_docs: {
          cookies_policy: props.cookies_policy,
          privacy_policy: props.privacy_policy,
          user_agreement: props.user_agreement,
        },
        mobile_apps: {
          links: {
            google_play: props.google_play,
            app_store: props.app_store,
          },
        },
      },
      options.duplicate,
    )
  }

  const page = useSelector(LicenseSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: LicenseRoutes.List })
  }

  useHelmet({ title: t('addLicenseMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <LicenseDetailsForm
          mode={'create'}
          onSubmit={handleFormSubmit}
          onCancel={goBack}
          initialValues={
            initialState || {
              phone_codes: phoneCodes,
            }
          }
        />
      </Layout.Content>
    </>
  )
}

export default LicenseAdd
