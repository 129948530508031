import React, { memo, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import * as Styled from 'views/pages/Product/ProductDetailsForm/styled'
import { FormikHook, Input, TextArea } from '@agro-club/frontend-shared'

export type AboutFormProps = {
  title: string
  titleMeta: string
  shortDescription: string
  description: string
  descriptionMeta: string
  trademarkLanguage: string
  videoUrl?: string
  discountText?: string
}

const AboutForm: React.FC<{
  lang: keyof LocalizedValue
  title?: LocalizedValue
  titleMeta?: LocalizedValue
  description?: LocalizedValue
  descriptionMeta?: LocalizedValue
  shortDescription?: LocalizedValue
  trademarkLanguage?: LocalizedValue
  videoUrl?: LocalizedValue
  discountText?: LocalizedValue
  useFormik: FormikHook
  setSlug?: (val: string) => void
}> = memo(
  ({
    lang,
    useFormik,
    title = {},
    titleMeta = {},
    description = {},
    descriptionMeta = {},
    shortDescription = {},
    trademarkLanguage = {},
    discountText = {},
    videoUrl = {},
    setSlug,
  }) => {
    const { t } = useTranslation(['product', 'common', 'validation'])
    const validationSchema = useMemo(
      () =>
        Yup.object({
          title: Yup.string().required(t('validation:field_required')),
          titleMeta: Yup.string(),
          description: Yup.string().required(t('validation:field_required')),
          descriptionMeta: Yup.string(),
          shortDescription: Yup.string().required(t('validation:field_required')),
          trademarkLanguage: Yup.string(),
          discountText: Yup.string(),
          videoUrl: Yup.string().url(t('validation:invalid_url')),
        }),
      [t],
    )
    const formik = useFormik<AboutFormProps>({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
      initialValues: {
        title: title[lang] || '',
        titleMeta: titleMeta[lang] || '',
        shortDescription: shortDescription[lang] || '',
        description: description[lang] || '',
        descriptionMeta: descriptionMeta[lang] || '',
        trademarkLanguage: trademarkLanguage[lang] || '',
        discountText: discountText[lang] || '',
        videoUrl: videoUrl[lang] || '',
      },
      validationSchema,
      enableReinitialize: true,
    })
    return (
      <Styled.VerticalInputsContainer>
        <Input
          {...formik.getFieldProps('title')}
          invalid={formik.touched.title && !!formik.errors.title}
          errorText={formik.errors.title}
          label={t('form.title', { lang })}
          limit={50}
          onChange={e => {
            formik.setFieldValue('title', e.target.value)
            setSlug && setSlug(e.target.value)
          }}
          data-test-id={`title-${lang}`}
          required
        />
        <Input
          {...formik.getFieldProps('titleMeta')}
          invalid={formik.touched.titleMeta && !!formik.errors.titleMeta}
          errorText={formik.errors.titleMeta}
          label={t('form.titleMeta', { lang })}
          data-test-id={`titleMeta-${lang}`}
          limit={50}
        />
        <TextArea
          {...formik.getFieldProps('shortDescription')}
          invalid={formik.touched.shortDescription && !!formik.errors.shortDescription}
          errorText={formik.errors.shortDescription}
          label={t('form.shortDescription', { lang })}
          limit={200}
          data-test-id={`shortDescription-${lang}`}
          required
        />
        <TextArea
          {...formik.getFieldProps('description')}
          invalid={formik.touched.description && !!formik.errors.description}
          errorText={formik.errors.description}
          label={t('form.description', { lang })}
          data-test-id={`description-${lang}`}
          limit={1000}
          required
        />
        <TextArea
          {...formik.getFieldProps('descriptionMeta')}
          invalid={formik.touched.descriptionMeta && !!formik.errors.descriptionMeta}
          errorText={formik.errors.descriptionMeta}
          label={t('form.descriptionMeta', { lang })}
          data-test-id={`descriptionMeta-${lang}`}
          limit={1000}
        />
        <TextArea
          {...formik.getFieldProps('trademarkLanguage')}
          invalid={formik.touched.trademarkLanguage && !!formik.errors.trademarkLanguage}
          errorText={formik.errors.trademarkLanguage}
          label={t('form.trademarkLanguage', { lang })}
          data-test-id={`trademarkLanguage-${lang}`}
          limit={1000}
        />
        <Input
          {...formik.getFieldProps('discountText')}
          invalid={formik.touched.discountText && !!formik.errors.discountText}
          errorText={formik.errors.discountText}
          label={t('form.discountText', { lang })}
          data-test-id={`discountText-${lang}`}
          limit={30}
        />
        <Input
          {...formik.getFieldProps('videoUrl')}
          invalid={formik.touched.videoUrl && !!formik.errors.videoUrl}
          errorText={formik.errors.videoUrl}
          label={t('form.videoUrl', { lang })}
          data-test-id={`videoUrl-${lang}`}
        />
      </Styled.VerticalInputsContainer>
    )
  },
)
AboutForm.displayName = 'AboutForm'

export default AboutForm
