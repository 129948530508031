import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import BadgeActions from 'modules/domain/badge/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import BadgeSelectors from './selectors'
import { Badge } from 'modules/domain/badge/types'
import BadgeRoutes from 'views/pages/Badge/routes'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import { pickFileUrl } from './helpers'

export const fetchList = function*() {
  try {
    let currentPage = yield select(BadgeSelectors.page)
    const filter = yield select(BadgeSelectors.filter)
    const sorting = yield select(BadgeSelectors.sorting)
    const pageSize = yield select(BadgeSelectors.pageSize)

    let response: ListResponse<Badge> = yield call(managers.getBadgesList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getBadgesList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(BadgeActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, BadgeRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(BadgeSelectors.page)
    const filter = yield select(BadgeSelectors.filter)
    const sorting = yield select(BadgeSelectors.sorting)
    const pageSize = yield select(BadgeSelectors.pageSize)
    const { data, total_count }: { data: Badge[]; total_count: number } = yield call(
      managers.getBadgesList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(BadgeActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.listRequestNextFailed(errType))
  }
}

export const fetchBadge = function*({ payload: id }: ReturnType<typeof BadgeActions.itemRequested>) {
  try {
    const badge: Badge = yield call(managers.getBadge, id)
    yield put(BadgeActions.itemRequestSucceed(badge))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.itemRequestFailed(id, errType))
  }
}

export const addBadge = function*({ payload: dto }: ReturnType<typeof BadgeActions.addRequested>) {
  try {
    const {
      urls: { x1, x2, x3 },
      ...rest
    } = dto

    const imageUrls = yield all({
      x1: pickFileUrl(x1),
      x2: pickFileUrl(x2),
      x3: pickFileUrl(x3),
    })

    const resultBadgeDto = {
      ...rest,
      urls: { ...imageUrls },
    }

    const badge: Badge = yield call(managers.addBadge, resultBadgeDto)
    yield put(BadgeActions.addSucceed(badge))
    yield put(push(generatePath(BadgeRoutes.Edit, { id: badge.id })))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.addFailed(errType))
  }
}
export const updateBadge = function*({ payload: [id, dto] }: ReturnType<typeof BadgeActions.updateRequested>) {
  try {
    const {
      urls: { x1, x2, x3 },
      ...rest
    } = dto

    const imageUrls = yield all({
      x1: pickFileUrl(x1),
      x2: pickFileUrl(x2),
      x3: pickFileUrl(x3),
    })

    const resultBadgeDto = {
      ...rest,
      urls: { ...imageUrls },
    }
    const badge: Badge = yield call(managers.updateBadge, id, resultBadgeDto)
    yield put(BadgeActions.updateSucceed(badge))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.updateFailed(id, errType))
  }
}

export const removeBadge = function*({ payload }: ReturnType<typeof BadgeActions.removeRequested>) {
  try {
    yield call(managers.removeBadge, payload)
    yield put(BadgeActions.removeSucceed(payload))
    yield put(push(BadgeRoutes.List))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(BadgeActions.removeFailed(payload, errType))
  }
}

const BadgeSagas = function*() {
  yield all([
    takeLatest(BadgeActions.itemRequested.type, fetchBadge),

    takeLatest(BadgeActions.listRequested.type, fetchList),
    takeLatest(BadgeActions.filterUpdated.type, fetchList),
    takeLatest(BadgeActions.sortingUpdated.type, fetchList),
    takeLatest(BadgeActions.filterHasBeenReset.type, fetchList),
    takeLatest(BadgeActions.sortingHasBeenReset.type, fetchList),

    takeLatest(BadgeActions.listRequestedNext.type, fetchListNext),

    takeLatest(BadgeActions.addRequested.type, addBadge),
    takeLatest(BadgeActions.updateRequested.type, updateBadge),
    takeLatest(BadgeActions.removeRequested.type, removeBadge),
  ])
}

export default BadgeSagas
