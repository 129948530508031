import React from 'react'
import {
  Input,
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  Switch,
  TableBodyCell,
  TableBodyRow,
} from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'
import History from './History'
import { ProgramDTO } from 'modules/domain/programSummary/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Currency } from 'types/entities'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import useLangPicker from 'hooks/useLangPicker'

const SectionTableStyled = styled(SectionTable)`
  border-spacing: 0 4px;
  margin-top: 26px;
  padding: 4px 48px;
  min-width: 1000px;
`

const SectionTableHeadRowStyled = styled(SectionTableHeadRow)`
  background-color: ${props => props.theme.color.backgroundSecondary};
`
const ProductsCellStyled = styled(TableBodyCell)`
  border: none;
  vertical-align: baseline;
`
const ProgramCellStyled = styled(TableBodyCell)`
  vertical-align: baseline;
  padding-left: 24px;
  padding-top: 24px;
  cursor: pointer;
  border: none;
  box-shadow: ${(props: StyledProps<{ active?: boolean }>) =>
    props.active ? '6px 4px 8px rgba(39, 45, 51, 0.04)' : ''};
`
const ProgramName = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`
const ProductName = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`
const QTYInputStyled = styled(Input)`
  width: 70px;
`
const CommentInputStyled = styled(Input)`
  width: 300px;
`
const SwitchStyled = styled(Switch)`
  width: auto;
`

const CommonCells: React.FC<{ program: ProgramDTO; index: number; formik }> = ({ program, index, formik }) => {
  const { pick } = useLangPicker(lang => `title_${lang}`)
  return (
    <>
      <ProductsCellStyled>{pick(program.product_title.sku_category_title)}</ProductsCellStyled>
      <ProductsCellStyled>
        <ProductName>{pick(program.product_title)}</ProductName>
      </ProductsCellStyled>
      <ProductsCellStyled>{program.bags_by_farmer}</ProductsCellStyled>
      <ProductsCellStyled>{program.bags_by_household}</ProductsCellStyled>
      <ProductsCellStyled>
        <QTYInputStyled inputSize={'small'} type="number" {...formik.getFieldProps(`${index}.qualified_bags`)} />
      </ProductsCellStyled>
      <ProductsCellStyled>{getPrettyPrice(program.reward, Currency.USD)}</ProductsCellStyled>
      <ProductsCellStyled>{getPrettyPrice(program.net_reward, Currency.USD)}</ProductsCellStyled>
    </>
  )
}

const FirstRow: React.FC<{ program: ProgramDTO; index: number; formik }> = ({ program, index, formik }) => {
  return (
    <TableBodyRow>
      <ProductsCellStyled>{getPrettyPrice(program.pre_bate, Currency.USD)}</ProductsCellStyled>
      <ProductsCellStyled>{program.tier}</ProductsCellStyled>
      <CommonCells program={program} index={index} formik={formik} />
    </TableBodyRow>
  )
}

const Row: React.FC<{ program: ProgramDTO; index: number; formik }> = ({ program, index, formik }) => {
  return (
    <TableBodyRow>
      <ProductsCellStyled></ProductsCellStyled>
      <ProductsCellStyled></ProductsCellStyled>
      <CommonCells program={program} index={index} formik={formik} />
    </TableBodyRow>
  )
}

type Props = {
  programName: string
  programProducts: Array<ProgramDTO>
  onClick: (id: string) => void
  active: boolean
  formManager: {
    dirty: boolean
    bind: (slotName: string) => typeof useFormik
  }
}
const Program: React.FC<Props> = ({ programProducts, programName, onClick, active, formManager }) => {
  const { t } = useTranslation('programSummary')

  const useFormik = formManager.bind(programName)
  const formik = useFormik<ProgramDTO[]>({
    initialValues: programProducts,
    enableReinitialize: true,
    onSubmit() {},
  })

  const handleStatusChange = value => {
    programProducts.forEach((_val, index) => formik.setFieldValue(`${index}.qualified`, value))
  }

  const handleNoteChange = (_e, value) => {
    programProducts.forEach((_val, index) => formik.setFieldValue(`${index}.notes`, value))
  }

  return (
    <>
      <SectionTableStyled onClick={() => onClick(programName)}>
        <SectionTableBody>
          <TableBodyRow>
            <ProgramCellStyled active={active}>
              <ProgramName>
                <SwitchStyled onClick={handleStatusChange} on={formik.values[0].qualified} />
                {programName}
              </ProgramName>
              <CommentInputStyled
                inputSize={'small'}
                placeholder={t('form.placeholders.addComment')}
                {...formik.getFieldProps('0.notes')}
                onChange={handleNoteChange}
              />
            </ProgramCellStyled>

            <ProgramCellStyled active={active}>
              <SectionTable>
                <SectionTableHead>
                  <SectionTableHeadRowStyled>
                    <SectionTableHeadCell>{t('headers.preBate')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.tier')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.category')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.product')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.qtyByFarmer')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.qtyByHousehold')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.qualifiedQTY')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.reward')}</SectionTableHeadCell>
                    <SectionTableHeadCell>{t('headers.netReward')}</SectionTableHeadCell>
                  </SectionTableHeadRowStyled>
                </SectionTableHead>
                <SectionTableBody>
                  {programProducts.map((program, index) => {
                    if (index === 0) {
                      return <FirstRow key={index} index={index} program={program} formik={formik} />
                    } else {
                      return <Row key={index} index={index} program={program} formik={formik} />
                    }
                  })}
                </SectionTableBody>
              </SectionTable>
            </ProgramCellStyled>
          </TableBodyRow>
        </SectionTableBody>
      </SectionTableStyled>
      {active && <History programProducts={programProducts} />}
    </>
  )
}

export default Program
