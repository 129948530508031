import { createCheckFeatureFlag } from 'hooks/featureFlags/createCheckFeatureFlag'
import ConfigSelectors from 'modules/domain/config/selectors'
import { useSelector } from 'react-redux'

const useWLFeatureFlags = () => {
  const config = useSelector(ConfigSelectors.config)
  const flags = config?.feature_flags

  return createCheckFeatureFlag(flags)
}

export default useWLFeatureFlags
