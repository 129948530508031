import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/collection/managers'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { Region, RegionListRequestFilter } from 'modules/domain/collection/types'

export type RegionSelectProps = EntitySelectProps<Region> & {
  country?: RegionListRequestFilter['country']
  parentId?: RegionListRequestFilter['parent_id']
  testId?: string
}

export const getRegionLabel = (region: Region) => region.title
export const getRegionValue = (region: Region) => region.id

export const RegionsSelect: React.VFC<RegionSelectProps> = ({ testId, country, parentId, placeholder, ...props }) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useMemo(
    () => ({
      country,
      parent_id: parentId,
    }),
    [country, parentId],
  )

  return (
    <SelectTestWrapper data-test-id={testId || 'region-select'}>
      <EntitySelect
        placeholder={placeholder || t('placeholders.region')}
        getEntityList={managers.fetchRegions}
        getEntityById={managers.getRegionById}
        filter={memoizedFilter}
        getOptionLabel={getRegionLabel}
        getOptionValue={getRegionValue}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}
