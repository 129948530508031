import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Badge } from 'modules/domain/badge/types'
import { EntityMetadata } from 'modules/domain/types'

const badgeDicts = (state: AppGlobalState) => state.badge.items
const ids = (state: AppGlobalState) => state.badge.ids
const badge = (state: AppGlobalState, id: string): Badge | undefined => state.badge.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Badge> =>
  state.badge.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.badge.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.badge.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.badge.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.badge.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.badge.listFetchError
const badgeList = createSelector(badgeDicts, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Badge[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.badge.filter
const sorting = (state: AppGlobalState) => state.badge.sorting
const page = (state: AppGlobalState) => state.badge.page
const total = (state: AppGlobalState) => state.badge.total
const updateProgress = (state: AppGlobalState) => state.badge.updateProgress
const removeProgress = (state: AppGlobalState) => state.badge.removeProgress
const addProgress = (state: AppGlobalState) => state.badge.addProgress
const addError = (state: AppGlobalState) => state.badge.addError
const hasNext = (state: AppGlobalState) => state.badge.total > state.badge.ids.length
const pageSize = (state: AppGlobalState) => state.badge.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.badge.total / state.badge.pageSize)

const BadgeSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  badgeDicts,
  ids,
  badge,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  badgeList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default BadgeSelectors
