import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { PackageTypes } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.packageTypes.items
const ids = (state: AppGlobalState) => state.packageTypes.ids

const allDict = (state: AppGlobalState) => state.packageTypes.allItems
const allIds = (state: AppGlobalState) => state.packageTypes.allIds

const item = (state: AppGlobalState, id: string): PackageTypes | undefined => state.packageTypes.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<PackageTypes> =>
  state.packageTypes.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.packageTypes.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.packageTypes.listFetchProgress

const listAllFetchProgress = (state: AppGlobalState) => state.packageTypes.listAllFetchProgress
const listAllFetchError = (state: AppGlobalState) => state.packageTypes.listAllFetchError

const listFetchError = (state: AppGlobalState) => state.packageTypes.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.packageTypes.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.packageTypes.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: PackageTypes[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const listAll = createSelector(allDict, allIds, listAllFetchProgress, (allDict, allIds, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: PackageTypes[] = []
  for (const id of allIds) {
    result.push(allDict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.packageTypes.filter
const sorting = (state: AppGlobalState) => state.packageTypes.sorting
const page = (state: AppGlobalState) => state.packageTypes.page
const total = (state: AppGlobalState) => state.packageTypes.total
const updateProgress = (state: AppGlobalState) => state.packageTypes.updateProgress
const removeProgress = (state: AppGlobalState) => state.packageTypes.removeProgress
const addProgress = (state: AppGlobalState) => state.packageTypes.addProgress
const hasNext = (state: AppGlobalState) => state.packageTypes.total > state.packageTypes.ids.length
const pageSize = (state: AppGlobalState) => state.packageTypes.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.packageTypes.total / state.packageTypes.pageSize)

const PackageTypesSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  listAll,
  listAllFetchProgress,
  listAllFetchError,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default PackageTypesSelectors
