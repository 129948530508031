import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import StorefrontProductActions from 'modules/domain/storefrontProduct/duck'
import StorefrontProductSelectors from 'modules/domain/storefrontProduct/selectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { StorefrontHeader } from '../components/StorefrontHeader'
import * as Styled from '../StorefrontCard/styled'
import StorefrontProductTable from './components/StorefrontProductTable'

export const StorefrontProductList: React.FC = () => {
  const { t } = useTranslation('product')

  useHelmet({ title: t('productsMetaTitle') })

  const filterUpdated = useAction(StorefrontProductActions.filterUpdated)
  const filterValue = useSelector(StorefrontProductSelectors.filter)

  const onSearchChange = (val: string) => {
    filterUpdated({ ...filterValue, search: val })
  }

  return (
    <>
      <Layout.Header>
        <StorefrontHeader tab={'products'} />
        <Styled.SearchInputWrapper>
          <SearchInput
            onChange={onSearchChange}
            value={filterValue.search}
            placeholder={t('list.searchPlaceholder')}
            data-test-id="product-search"
          />
        </Styled.SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <StorefrontProductTable />
      </Layout.Content>
    </>
  )
}

export default StorefrontProductList
