import { AdvancedHeadCell, TableHead, TableHeadCell, TableHeadRow } from '@agro-club/frontend-shared'
import React, { useMemo } from 'react'
import { ColumnInstance } from 'react-table'
import styled from 'styled-components'
import { DictWithKey } from 'types/generics'

export type CompoundedTableHeadProps<DataItemType extends Object> = {
  columns: ColumnInstance<any>[]
  metaColumns?: DictWithKey<React.ReactNode, keyof DataItemType>
  onSortUpdated?: ({
    sort_field,
    sort_reversed,
  }: {
    sort_field?: keyof DataItemType
    sort_reversed?: boolean | undefined
  }) => void
  sortField?: keyof DataItemType
  sortReversed?: boolean | undefined
  onHeadCellClick?: (column: ColumnInstance<any>) => void
  sortedColumn?: string
}

const SortableHeadCell = AdvancedHeadCell<any>()

const MetaTableHead = styled(TableHead)`
  position: sticky;
  top: 64px;
  height: 32px;

  &::before {
    height: 32px;
    margin-top: -16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
`
const MetaHeadCell = styled.th`
  vertical-align: middle;
  color: #858585;
  padding: 0 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: ${({ align }) => align};

  //safari workaround since it doesn't support relative position on tr
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      & {
        position: relative;
        top: -22px !important;
      }
    }
  }
`

const MetaHeadRow = styled.tr`
  position: relative;
  z-index: 2;
  top: -28px !important;
`

const StyledTableHead = styled(TableHead)`
  & th {
    font-size: 10px;
    line-height: 12px;
  }
`

export const CompoundedTableHead = <DataItemType extends Object>({
  columns,
  metaColumns,
  onSortUpdated,
  sortField,
  sortReversed,
  onHeadCellClick,
  sortedColumn = '',
}: CompoundedTableHeadProps<DataItemType>) => {
  const hasMeta = !!metaColumns

  const metaColumnsComponent = useMemo(() => {
    if (!hasMeta || !metaColumns) return null

    return (
      <MetaTableHead>
        <MetaHeadRow>
          {columns
            .filter(column => !column.hidden)
            .map(column => {
              if (typeof metaColumns[column.id] !== 'undefined') {
                return (
                  <MetaHeadCell key={column.id} align={column.headerAlign || 'left'}>
                    {metaColumns[column.id]}
                  </MetaHeadCell>
                )
              }
              return <td key={column.id} align={column.headerAlign || 'left'} />
            })}
        </MetaHeadRow>
      </MetaTableHead>
    )
  }, [columns, hasMeta, metaColumns])

  const columnsComponent = useMemo(
    () =>
      columns.map(column =>
        onSortUpdated ? (
          <SortableHeadCell
            key={column.id}
            {...column}
            sortField={sortField}
            sortDesc={sortReversed}
            onChange={onSortUpdated}
            onClick={() => onHeadCellClick?.(column)}
            isSorted={column.id === sortedColumn}
            isSortDesc={column.isSortedDesc}
            sortable
          >
            {column.render('Header')}
          </SortableHeadCell>
        ) : (
          <TableHeadCell
            key={column.id}
            {...column}
            onClick={() => onHeadCellClick?.(column)}
            isSorted={column.id === sortedColumn}
            isSortDesc={column.isSortedDesc}
            sortable
          >
            {column.render('Header')}
          </TableHeadCell>
        ),
      ),
    [columns, onHeadCellClick, onSortUpdated, sortField, sortReversed, sortedColumn],
  )

  return (
    <>
      <StyledTableHead>
        <TableHeadRow>{columnsComponent}</TableHeadRow>
      </StyledTableHead>
      {metaColumnsComponent}
    </>
  )
}
