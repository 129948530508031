import React, { useEffect, useRef, useState } from 'react'
import * as pdfjs from 'pdfjs-dist'
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/display/api'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const ErrorContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const Canvas = styled.canvas`
  width: 100%;
  &:not(:first-child) {
    margin-top: 12px;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PageRenderer: React.FC<{ page: PDFPageProxy }> = ({ page }) => {
  const { t } = useTranslation('PdfViewer')
  const ref = useRef<HTMLCanvasElement>(null)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setError(null)
    const canvas = ref.current
    if (!canvas) {
      return
    }

    const viewport = page.getViewport({ scale: 1.5 })
    const context = canvas.getContext('2d')

    if (!context) {
      throw new Error('Canvas context init error')
    }

    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    page.render(renderContext).promise.catch(err => setError(err))
  }, [page])

  if (error) {
    return <ErrorContainer>{t('errors.page.generic')}</ErrorContainer>
  }

  return <Canvas ref={ref} />
}

const PdfViewer: React.FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation('pdfViewer')
  const [pages, setPages] = useState<PDFPageProxy[]>([])
  const [pdfInstance, setPdfInstance] = useState<PDFDocumentProxy | null>(null)
  const [error, setError] = useState<Error | null>(null)
  useEffect(() => {
    setError(null)
    pdfjs
      .getDocument(url)
      .promise.then(pdf => {
        setPdfInstance(pdf)
        const pages: Promise<PDFPageProxy>[] = []
        for (let i = 0; i < pdf.numPages; i++) {
          pages.push(pdf.getPage(i + 1))
        }
        return Promise.all(pages)
      })
      .then(pages => setPages(pages))
      .catch(err => {
        setError(err)
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }, [url])

  useEffect(() => {
    if (pdfInstance) {
      return () => pdfInstance.destroy()
    }
  }, [pdfInstance])

  if (error) {
    return (
      <Container>
        <ErrorContainer>{t('errors.document.generic')}</ErrorContainer>
      </Container>
    )
  }

  return (
    <Container>
      {pages.map((page, idx) => (
        <PageRenderer page={page} key={idx} />
      ))}
    </Container>
  )
}

export default PdfViewer
