import styled, { keyframes } from 'styled-components'
import {
  IconDocument,
  IconDownload,
  IconPrint,
  IconSendEmail,
  IconUpload,
  IconQuestion,
} from '@agro-club/frontend-shared'

const selectedFadeOutAnimation = keyframes`
  from {
    background-color: rgba(3, 123, 255, 0.3);
  }

  to {
    background-color: rgba(255, 255, 255, 0);
  }
`
export const ListItemStyled = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;

  &[data-selected='true'] {
    animation: ${selectedFadeOutAnimation} 3s linear;
  }

  > * {
    flex-shrink: 1;
  }

  > *:first-child {
    flex-grow: 1;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`

export const DocumentTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: relative;
`
export const IconDocumentStyled = styled(IconDocument)`
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`
export const IconDownloadStyled = styled(IconDownload)`
  display: block;
  cursor: pointer;
  width: 20px;
  height: 16px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export const IconPrintStyled = styled(IconPrint)`
  display: block;
  cursor: pointer;
  width: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export const IconSendEmailStyled = styled(IconSendEmail)`
  display: block;
  cursor: pointer;
  width: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export const IconUploadStyled = styled(IconUpload)`
  display: block;
  cursor: pointer;
  width: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export const IconQuestionStyled = styled(IconQuestion)`
  display: block;
  cursor: pointer;
  width: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export const TooltipContent = styled.div`
  max-width: 200px;
  width: max-content;
  text-align: center;
`
