import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuManufacturer = {
  id: string
  title: string
}

type TargetSkuManufacturerFilter = {
  retailer_id?: string
  title?: string
}

type TargetSkuProductSelectProps = EntitySelectProps<TargetSkuManufacturer> & {
  retailerId?: string
  valueLabel?: string
  scope?: TargetSkuTypes | 'default'
}

const getListForm = createGetList<TargetSkuManufacturer, TargetSkuManufacturerFilter>(
  endpoints.target2sku('manufacturers'),
)
const getListFarmerFilter = createGetList<TargetSkuManufacturer, TargetSkuManufacturerFilter>(
  endpoints.target2sku('farmer/manufacturers'),
)
const getListTerritoryFilter = createGetList<TargetSkuManufacturer, TargetSkuManufacturerFilter>(
  endpoints.target2sku('territory/manufacturers'),
)
const getListRetailerFilter = createGetList<TargetSkuManufacturer, TargetSkuManufacturerFilter>(
  endpoints.target2sku('retailer/manufacturers'),
)
const getListCropFilter = createGetList<TargetSkuManufacturer, TargetSkuManufacturerFilter>(
  endpoints.target2sku('territory-crop/manufacturers'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Retailer]: getListRetailerFilter,
  [TargetSkuTypes.Territory]: getListTerritoryFilter,
  [TargetSkuTypes.Crop]: getListCropFilter,
}

const getLabel = (item: TargetSkuManufacturer) => item.title

const getValue = (item: TargetSkuManufacturer) => item.id

export const TargetSkuManufacturerSelect: React.VFC<TargetSkuProductSelectProps> = ({
  retailerId,
  isDisabled,
  valueLabel,
  onChange,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ retailer_id: retailerId }), [retailerId])
  const fakeValue = useMemo(() => (valueLabel ? { title: valueLabel, id: 'fake' } : undefined), [valueLabel])

  const testId = 'target-manufacturer-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      onChange={onChange}
      searchParamKey="title"
      data-test-id={testId}
      {...props}
    />
  )
}
