import { Dict } from 'types/generics'
import { generatePath as generatePathRouter } from 'react-router'

export const generatePath = (path: string, params?: Dict<string | number>, query?: Dict<string | number>) => {
  const basicPath = generatePathRouter(path, params)
  if (!query) return basicPath
  const entries = Object.entries(query)
  if (!entries.length) return basicPath
  return basicPath + `?${entries.reduce((acc, entry, index) => acc + `${index ? '&' : ''}${entry[0]}=${entry[1]}`, '')}`
}
