import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldMetaProps } from 'formik/dist/types'
import { remove } from 'ramda'
import styled, { keyframes } from 'styled-components'
import * as Yup from 'yup'
import { FormikHook, IconCrossCircle, Input, Switch } from '@agro-club/frontend-shared'

import { FeatureFlagInput } from './LicenseDetailsForm'

const AddButton = styled.button`
  display: inline-flex;
  width: auto;
  border: none;
  outline: none;

  background: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};

  transition: opacity 0.2s ease-in;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;

  opacity: 0;
  transition: opacity 0.1s ease-in;
  cursor: pointer;
`

const removeOverlayAnimation = keyframes`
  to {
    opacity: 0.2;
  }
`

const RemoveOverlay = styled.div`
  position: absolute;
  left: -5px;
  top: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 5px;

  background-color: ${props => props.theme.color.accentDestructive};
  opacity: 0;
  pointer-events: none;

  animation: ${removeOverlayAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`

const FlagListContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: fit-content;
`

const FlagContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 400px 72px;
  grid-gap: 16px;
  align-items: start;

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

const FeatureSwitch = styled(Switch)`
  top: 50%;
`

type FeatureFlagFormProps = {
  featureFlags: FeatureFlagInput[]
}

const FeatureFlagForm: React.FC<{
  featureFlags: FeatureFlagInput[]
  useFormik: FormikHook
}> = ({ featureFlags, useFormik }) => {
  const { t } = useTranslation('license')
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const validationSchema = useMemo(
    () =>
      Yup.object({
        featureFlags: Yup.array(
          Yup.object({
            name: Yup.string().required(t('validation:field_required')),
          }),
        ),
      }),
    [t],
  )

  const formik = useFormik<FeatureFlagFormProps>({
    initialValues: {
      featureFlags,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })

  const handleAddClick = useCallback(() => {
    formik.setFieldValue('featureFlags', [
      ...formik.values.featureFlags,
      {
        name: '',
        value: true,
      },
    ])
  }, [formik])

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('featureFlags', remove(idx, 1, formik.values.featureFlags))
    },
    [formik],
  )

  return (
    <FlagListContainer>
      {formik.values.featureFlags.map((flag, idx) => {
        const nameKey = `featureFlags[${idx}].name`
        const valueKey = `featureFlags[${idx}].value`
        const getFieldMeta = (field: string): FieldMetaProps<FeatureFlagFormProps> =>
          formik.getFieldMeta(`featureFlags[${idx}].${field}`)

        return (
          <FlagContainer key={idx}>
            <RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <Input
              {...formik.getFieldProps(nameKey)}
              label={t('form.labels.featureFlagName')}
              invalid={getFieldMeta('name').touched && !!getFieldMeta('name').error}
              errorText={getFieldMeta('name').error}
              value={flag.name.trim()}
              required
            />
            <FeatureSwitch
              on={formik.getFieldProps(valueKey).value}
              onClick={value => formik.setFieldValue(valueKey, value)}
            />
            {hoveredIndex !== null && hoveredIndex === idx ? <RemoveOverlay /> : null}
          </FlagContainer>
        )
      })}

      <AddButton onClick={handleAddClick} type={'button'}>
        {t('form.addFeatureFlag')}
      </AddButton>
    </FlagListContainer>
  )
}

export default FeatureFlagForm
