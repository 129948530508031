import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Company } from 'modules/domain/company/types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.company.items
const ids = (state: AppGlobalState) => state.company.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (id?: string) => (state: AppGlobalState): Company | undefined => (id ? state.company.items[id] : undefined)
const meta = (id?: string) => (state: AppGlobalState): EntityMetadata<Company> =>
  (id && state.company.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.company.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.company.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.company.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.company.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.company.listFetchNextError
const companyList = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Company[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.company.filter
const sorting = (state: AppGlobalState) => state.company.sorting
const page = (state: AppGlobalState) => state.company.page
const total = (state: AppGlobalState) => state.company.total
const updateProgress = (state: AppGlobalState) => state.company.updateProgress
const removeProgress = (state: AppGlobalState) => state.company.removeProgress
const addProgress = (state: AppGlobalState) => state.company.addProgress
const hasNext = (state: AppGlobalState) => state.company.total > state.company.ids.length
const pageSize = (state: AppGlobalState) => state.company.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.company.total / state.company.pageSize)
const branchCompaniesById = (state: AppGlobalState, id: string) =>
  state.company.branchCompanies[id] ? state.company.branchCompanies[id].companies : []
const branchCompaniesPermissionsById = (state: AppGlobalState, id: string) =>
  state.company.branchCompanies[id] ? state.company.branchCompanies[id].permissions : []
const branchCompaniesFetchProgressById = (state: AppGlobalState, id: string) =>
  state.company.branchCompanies[id]?.fetchProgress
const branchCompaniesIdsById = (state: AppGlobalState, id: string) =>
  state.company.branchCompanies[id] ? state.company.branchCompanies[id].ids : []
const config = (state: AppGlobalState, id: string) => state.company.items[id].config

const CompanySelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  companyList,
  dict,
  ids,
  pageSize,
  hasNext,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  branchCompaniesById,
  branchCompaniesFetchProgressById,
  branchCompaniesIdsById,
  branchCompaniesPermissionsById,
  config,
}

export default CompanySelectors
