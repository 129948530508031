import { Button, Modal, Pagination, Progress, useAction, useBoolean } from '@agro-club/frontend-shared'
import React, { memo, useCallback, useMemo, useState } from 'react'
import {
  StorefrontBundle,
  StorefrontSku,
  DragItemType,
  StorefrontItemType,
  DropItemType,
  MenuOptionType,
  StorefrontItem,
} from 'modules/domain/storefront/types'
import { ClearButton } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDrop } from 'react-dnd'
import { StorefrontListContext } from 'views/pages/Storefront/hooks'
import { move } from 'ramda'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { useStorefrontList } from 'modules/domain/storefront/hooks'
import StorefrontActions from 'modules/domain/storefront/duck'
import { useSelector } from 'react-redux'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { useAllPackageTypesList } from 'modules/domain/packageTypes/hooks'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useCombinedProgress } from 'hooks/useCombinedProgress'
import Bundle from 'views/pages/Storefront/Storefront/StorefrontList/StorefrontBundle'
import Sku from 'views/pages/Storefront/Storefront/StorefrontList/StorefrontSku'
import { useAllProductOptionsList } from 'modules/domain/productOptions/hooks'
import { Status } from 'types/entities'
import styled from 'styled-components'

const ListWrapper = styled.div`
  height: auto;

  padding: 12px 24px;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 8px;
`

export const NonDraggable: React.FC = ({ children }) => (
  <div draggable onDragStart={e => e.preventDefault()}>
    {children}
  </div>
)

const StorefrontList: React.FC<{
  onSkuAdd: (productId: string) => void
  onSkuEdit: (id: string) => void
}> = memo(({ onSkuAdd, onSkuEdit }) => {
  const { t } = useTranslation('storefront')
  const [packageTypesProgress, packageTypes = []] = useAllPackageTypesList()
  const filter = useSelector(StorefrontSelectors.filter)
  const { seller_id } = filter
  const [skuProgress, list = []] = useStorefrontList()
  const progress = useCombinedProgress([packageTypesProgress, skuProgress])
  const [, productOptionsList = []] = useAllProductOptionsList()

  const listRequested = useAction(StorefrontActions.listRequested)
  const removeAction = useAction(StorefrontActions.removeRequested)
  const updateAction = useAction(StorefrontActions.updateRequested)
  const filterCleared = useAction(StorefrontActions.filterHasBeenReset)

  const total = useSelector(StorefrontSelectors.total)
  const page = useSelector(StorefrontSelectors.page)
  const pages = useSelector(StorefrontSelectors.pages)
  const pageSize = useSelector(StorefrontSelectors.pageSize)

  const [isRemoveModalOpen, openRemoveModal, closeRemoveModal] = useBoolean(false)
  const [removeData, setRemoveData] = useState<{ id: string; sku: string } | undefined>(undefined)
  const isFilterApplied = useMemo(() => Object.values(filter).some(Boolean), [filter])
  const displayPagination = useMemo(() => seller_id && Math.ceil(total / pageSize) > 1, [pageSize, seller_id, total])

  const [collectedDropProps, dropRef] = useDrop(
    () => ({
      accept: [DragItemType.Sidebar],
      collect: monitor => {
        return {
          canDrop: monitor.canDrop(),
          hoverOver: monitor.isOver({ shallow: true }),
          itemType: monitor.getItemType(),
        }
      },
      drop: (item: DropItemType) => {
        onSkuAdd(item.productId)
      },
    }),
    [list],
  )

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  /** Resort items in list */
  const moveRow = useCallback((_dragIndex: number, _hoverIndex: number) => {
    // todo update list action
    // setData(move(dragIndex, hoverIndex, list))
    // console.log(`Resort items in list: idx ${dragIndex} => ${hoverIndex}`)
  }, [])

  /* Resort items in bundle */
  const moveBundleRow = useCallback(
    (bundleId: string, dragIndex: number, hoverIndex: number) => {
      const idx = list.findIndex(item => item.id === bundleId)
      const bundle = list[idx]
      if (!bundle || bundle.type !== StorefrontItemType.Bundle) {
        return
      }
      const updatedBundle = { ...bundle, items: move(dragIndex, hoverIndex, bundle.items) }
      const newList = [...list]
      newList[idx] = updatedBundle
      // todo update list action
      // setData(newList)
      // console.log(`Resort items in bundle ${bundleId}`)
    },
    [list],
  )

  /** Add new item to bundle */
  const addBundleRow = useCallback(
    (bundleId, productId) => {
      const bundleIndex = list.findIndex(item => item.id === bundleId)
      const productIndex = list.findIndex(item => item.id === productId)

      if ([bundleId, productId].some(i => i === -1)) {
        console.warn(
          `Couldn't find bundle row or product item with specified id, productId ${productId}, bundleId ${bundleId}`,
        )
        return
      }

      const newItems = [...list]
      const bundle = newItems[bundleIndex]
      const product = list[productIndex]

      if (bundle.type === StorefrontItemType.Bundle && product.type === StorefrontItemType.Product) {
        // todo update bundle action
        // bundle.items = [...bundle.items, product]
      }
    },
    [list],
  )

  const findIndex = useCallback(id => list.findIndex(v => v.id === id), [list])

  /** Show text information if dropping is available */
  const showDropInfo = useMemo(() => collectedDropProps.canDrop && collectedDropProps.hoverOver && !!seller_id, [
    collectedDropProps.canDrop,
    collectedDropProps.hoverOver,
    seller_id,
  ])

  const restoreSku = useCallback(
    (id: string) => {
      const sku = list.find(sku => sku.id === id)
      if (!sku) return
      updateAction(id, { ...(sku as StorefrontSku), status: Status.Active })
    },
    [list, updateAction],
  )

  const handleChangeRowMenuOption = useCallback(
    (option: MenuOptionType, id: string, sku: string) => {
      switch (option) {
        case MenuOptionType.Edit:
          onSkuEdit(id)
          break
        case MenuOptionType.Remove:
          setRemoveData({ id, sku })
          openRemoveModal()
          break
        case MenuOptionType.Restore:
          restoreSku(id)
          break
        default:
          break
      }
    },
    [onSkuEdit, restoreSku, openRemoveModal],
  )

  const handleRemoveModalClose = () => {
    setRemoveData(undefined)
    closeRemoveModal()
  }

  const handleRemoveConfirm = () => {
    removeAction(removeData?.id)
    setRemoveData(undefined)
    closeRemoveModal()
  }

  return (
    <Styled.ListWrapper>
      <StorefrontListContext.Provider value={{ moveRow, findIndex }}>
        <Styled.Inner ref={dropRef} data-test-id="sku-list">
          <Styled.HeaderRow>
            <Styled.HeaderCell>
              <Styled.DefaultStatus />
            </Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.sku')}</Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.options')}</Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.package')}</Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.price')}</Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.availableFor')}</Styled.HeaderCell>
            <Styled.HeaderCell>{t('columns.inStock')}</Styled.HeaderCell>
          </Styled.HeaderRow>
          <ListWrapper>
            {!seller_id ? (
              <Styled.NoData>
                <Styled.EmptyMessage>{t('list.noSelectedSellerMsg')}</Styled.EmptyMessage>
              </Styled.NoData>
            ) : progress !== Progress.SUCCESS ? (
              <Styled.SpinnerWrapper>
                <SpinnerLayout />
              </Styled.SpinnerWrapper>
            ) : !list.length ? (
              <Styled.NoData>
                <Styled.EmptyMessage>
                  {isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}
                </Styled.EmptyMessage>
                {isFilterApplied && skuProgress !== Progress.WORK && (
                  <ClearButton intent={'cancel'} size={'small'} onClick={filterCleared}>
                    {t('list.resetAllFilters')}
                  </ClearButton>
                )}
              </Styled.NoData>
            ) : (
              list.map((item: StorefrontItem) => {
                if (item.params.type === StorefrontItemType.Bundle.toLowerCase()) {
                  return (
                    <Bundle
                      {...(item as StorefrontBundle)}
                      key={item.id}
                      onMoveRow={moveBundleRow}
                      onAddRow={addBundleRow}
                      onChangeRowMenuOption={option => handleChangeRowMenuOption(option, item.id, item.sku_id)}
                    />
                  )
                }
                if (item.params.type === StorefrontItemType.Product.toLowerCase()) {
                  const packageItem = packageTypes.find(t => t.id === item.params.package_id)

                  return (
                    <Sku
                      {...(item as StorefrontSku)}
                      key={item.id}
                      packageItem={packageItem}
                      onChangeRowMenuOption={option => handleChangeRowMenuOption(option, item.id, item.sku_id)}
                      productOptionsList={productOptionsList}
                    />
                  )
                }
                return null
              })
            )}
          </ListWrapper>
        </Styled.Inner>
      </StorefrontListContext.Provider>
      <Styled.DropInfoContainer show={showDropInfo}>
        {t('dnd.dragAndDrop')}
        <br />
        {t('dnd.productHere')}
      </Styled.DropInfoContainer>
      <Modal heading={t('modal.removeItemHeading')} isOpen={isRemoveModalOpen} onClose={handleRemoveModalClose}>
        <Styled.ModalContent>{t('modal.removalText', { sku: removeData?.sku })}</Styled.ModalContent>
        <Styled.ModalFooter>
          <Button filled intent={'primary'} type={'submit'} onClick={handleRemoveConfirm}>
            {t('modal.confirmRemovalText')}
          </Button>
          <Button onClick={closeRemoveModal} intent={'cancel'}>
            {t('common:cancel')}
          </Button>
        </Styled.ModalFooter>
      </Modal>
      {displayPagination && (
        <Styled.PaginationWrapper>
          <Pagination total={pages} current={page} onClick={fetchNextItems} />
        </Styled.PaginationWrapper>
      )}
    </Styled.ListWrapper>
  )
})

export default StorefrontList
