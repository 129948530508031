import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CategoryAdd from './CategoryAdd'
import CategoryEdit from './CategoryEdit'
import CategoryList from './CategoryList'
import CategoryRoutes from './routes'

const CategoryPage: React.FC = () => {
  return (
    <Switch>
      <Route path={CategoryRoutes.CategoryAdd}>
        <CategoryAdd />
      </Route>
      <Route path={CategoryRoutes.CategoryEdit}>
        <CategoryEdit />
      </Route>
      <Route path={CategoryRoutes.CategoryList}>
        <CategoryList />
      </Route>
    </Switch>
  )
}

export default CategoryPage
