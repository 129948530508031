import { FilterProps, TableFilter } from 'views/components/TableFilters/TableFilters'
import { CompanyType } from 'types/entities'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type CompanyTypeFilterProps<TShape, TField extends keyof TShape, TDisableMulti> = Omit<
  FilterProps<TShape, TField, CompanyType, TDisableMulti>,
  'options' | 'title'
> & {
  types?: CompanyType[]
}

export default function CompanyTypeFilter<TShape, TField extends keyof TShape, TDisableMulti>({
  types,
  ...props
}: CompanyTypeFilterProps<TShape, TField, TDisableMulti>) {
  const { t } = useTranslation('company')
  const { t: tLabels } = useTranslation('labels')
  const typesOptions = useMemo(() => {
    return types || [CompanyType.Agro, CompanyType.Producer, CompanyType.Distributor]
  }, [types])

  const options = useMemo(
    () =>
      typesOptions.map(item => ({
        value: item,
        title: t(`type.${item}`, { count: 5 }),
      })),
    [t, typesOptions],
  )

  return <TableFilter {...props} options={options} title={tLabels('type')} />
}
