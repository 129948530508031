import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { REACT_APP_REDUX_LOGGER, DEVELOPMENT, REACT_APP_REDUX_LOGGER_HIDE_ACTIONS } from 'env'
import { createLogger } from 'redux-logger'

import rootReducerCb from './rootReducer'
import rootSaga from './rootSaga'
import { middleware as sentryMiddleware } from 'sentry'
import analyticsInstance from 'analytics/initAnalytics'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import FarmerOrderSkuActions from 'modules/domain/farmerOrderSku/duck'
import DistributorOrderActions from 'modules/domain/distributorOrder/duck'
import i18n from 'i18n'
import { getUserConfirmation } from 'hooks/usePreventNavigation'
import AuthActions from './domain/auth/duck'

export const history = createBrowserHistory({
  getUserConfirmation,
})

const sagaMiddleware = createSagaMiddleware({
  context: {
    analyticsInstance,
    i18n,
  },
})
// const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose

const hiddenActions = [
  FarmerOrderActions.newOrdersCountRequested.type,
  FarmerOrderActions.newOrdersCountRequestSucceed.type,
  FarmerOrderSkuActions.newOrdersCountRequested.type,
  FarmerOrderSkuActions.newOrdersCountRequestSucceed.type,
  DistributorOrderActions.newOrdersCountRequested.type,
  DistributorOrderActions.newOrdersCountRequestSucceed.type,
] as const

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never
const hideActions = (
  _: unknown,
  action: {
    type: ElementType<typeof hiddenActions>
  },
) => !hiddenActions.includes(action.type)

const middlewares = [sentryMiddleware, sagaMiddleware, routerMiddleware(history)]

if (REACT_APP_REDUX_LOGGER) {
  middlewares.push(
    createLogger({
      collapsed: true,
      logErrors: false,
      diff: true,
      predicate: REACT_APP_REDUX_LOGGER_HIDE_ACTIONS ? hideActions : undefined,
    }),
  )
}

const appReducer = rootReducerCb(history)

const rootReducer = (state, action) => {
  if (action.type === AuthActions.signOutRequested.type) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  DEVELOPMENT ? composeWithDevTools(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares),
)

sagaMiddleware.run(rootSaga)

export default store
