import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import TerritoryAllocationActions from './duck'
import TerritoryAllocationSelectors from './selectors'

export const useTerritoryAllocationById = createItemByIdHook(
  TerritoryAllocationSelectors,
  TerritoryAllocationActions.itemRequested,
)

export const useTerritoryAllocationList = createItemListOnceHook(
  TerritoryAllocationSelectors,
  TerritoryAllocationActions.listRequested,
)
