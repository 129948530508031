import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { AuthState, ProfileDTO, TokensPair, UserProfile } from './types'
import { Progress } from 'modules/types'

export type Credentials = {
  email: string
  password: string
}

export type TokenRequestFailedPayload = {
  message: string
}

export type ResetPasswordRequestedPayload = { email: string; redirect?: string }
export type ResetPasswordRequestFailedPayload = { message: string }
export type SetNewPasswordRequestedPayload = { password: string; token: string }

const initialState: AuthState = {
  isAuthenticated: false,
  error: '',
  resetPasswordError: '',
  resetPasswordProgress: Progress.IDLE,
  email: '',

  profile: undefined,
  initProgress: Progress.IDLE,
  profileUpdateProgress: Progress.IDLE,
  setNewPasswordProgress: Progress.IDLE,
  tokenRequestProgress: Progress.IDLE,
}

class AuthReducer extends ImmerReducer<AuthState> {
  init() {
    this.draftState.initProgress = Progress.WORK
  }
  initSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.initProgress = Progress.SUCCESS
    this.draftState.isAuthenticated = true
  }

  initFailed() {
    this.draftState.initProgress = Progress.ERROR
    this.draftState.isAuthenticated = false
  }

  tokenRequested(_payload: Credentials) {
    this.draftState.tokenRequestProgress = Progress.WORK
  }
  tokenRequestSucceed(_payload: TokensPair) {
    this.draftState.tokenRequestProgress = Progress.SUCCESS
  }
  tokenRequestFailed(payload: TokenRequestFailedPayload) {
    this.draftState.error = payload.message
    this.draftState.tokenRequestProgress = Progress.ERROR
  }

  resetPasswordRequested(payload: ResetPasswordRequestedPayload) {
    this.draftState.resetPasswordProgress = Progress.WORK
    this.draftState.email = payload.email
  }
  resetPasswordRequestFailed(payload: ResetPasswordRequestFailedPayload) {
    this.draftState.resetPasswordProgress = Progress.ERROR
    this.draftState.resetPasswordError = payload.message
  }
  resetPasswordRequestSucceed() {
    this.draftState.resetPasswordProgress = Progress.SUCCESS
  }
  resetPasswordRequestClean() {
    this.draftState.resetPasswordProgress = Progress.IDLE
  }

  setNewPasswordRequested(_payload: SetNewPasswordRequestedPayload) {
    this.draftState.setNewPasswordProgress = Progress.WORK
  }
  setNewPasswordSucceed() {
    this.draftState.setNewPasswordProgress = Progress.SUCCESS
  }
  setNewPasswordFailed() {
    this.draftState.setNewPasswordProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userUpdateRequested(profile: Partial<ProfileDTO>) {
    this.draftState.profileUpdateProgress = Progress.WORK
  }

  userUpdateSucceed(profile: UserProfile) {
    this.draftState.profileUpdateProgress = Progress.SUCCESS
    this.draftState.profile = profile
  }

  userUpdateFailed() {
    this.draftState.profileUpdateProgress = Progress.ERROR
  }

  signOutRequested() {
    this.draftState.isAuthenticated = false
    this.draftState.initProgress = Progress.ERROR
  }
}

export const AuthActions = createActionCreators(AuthReducer)
export default AuthActions
export const reducer = createReducerFunction(AuthReducer, initialState)
