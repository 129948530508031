/**
 * Creates a handler for array data - multiselects, multiple checkboxes, etc.
 **/
export const createArrayHandler = <ValueType extends string | number>(
  currentValues: ValueType[],
  setter: (value: ValueType[]) => void,
) => (value: ValueType) => {
  const set = new Set<ValueType>(currentValues)
  set.has(value) ? set.delete(value) : set.add(value)
  setter([...set])
}
