import { isEmpty } from 'ramda'
import { Dict } from 'types/generics'

export const createCheckFeatureFlag = (flags?: Dict<Boolean>) => (
  featureFlag: string[] | string,
  some?: boolean,
): boolean => {
  if (!flags) return false
  const featureFlagsKeys = Object.keys(flags)

  const testFlag = (flag: string) => {
    const flagKey = featureFlagsKeys.find(access => access === flag)
    if (!flagKey) return false

    return !!(flags && flags[flagKey])
  }

  if (Array.isArray(featureFlag)) {
    return isEmpty(featureFlag) || some
      ? featureFlag.some(flag => testFlag(flag))
      : featureFlag.every(flag => testFlag(flag))
  }

  return testFlag(featureFlag)
}
