import {
  Checkbox,
  FormComponents,
  helpersSlug,
  Input,
  RangeDatePicker,
  SectionBody,
  SectionContainer,
  SimpleSelect,
  useFormManager,
} from '@agro-club/frontend-shared'
import { isValid, parseISO } from 'date-fns'
import { FormikProvider } from 'formik'
import { createLocalizedValue } from 'helpers/localization'
import useDateFormat from 'hooks/useDateFormat'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import {
  IncentiveProgram,
  IncentiveProgramScope,
  IncentiveProgramStatus,
  IncentiveProgramTypes,
} from 'modules/domain/incentiveProgram/types'
import { Season } from 'modules/domain/season/types'
import { StorefrontItemType } from 'modules/domain/storefront/types'
import { Progress } from 'modules/types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AvailableLanguages, availableLanguagesList } from 'types/entities'
import { FileItem } from 'views/components/FileManager/types'
import { IncentiveCampaignSelect } from 'views/components/IncentiveCampaignSelect/IncentiveCampaignSelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { SkuMultiSelect } from 'views/components/SkuSelect/SkuMultiSelect'
import SlugForm from 'views/components/SlugForm/SlugForm'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { Label } from 'views/pages/Company/CompanyDetailsForm/components/BranchesStyles'
import HistoryQtyTiersRulesForm, {
  HistoryDiscountTierRule,
} from 'views/pages/DiscountRule/DiscountRuleDetailsForm/HistoryQtyTiersRulesForm'
import ProductUnitsForm from 'views/pages/Product/ProductDetailsForm/ProductUnitsForm'
import * as Yup from 'yup'
import { IncentiveProgramScopeSwitcher } from './IncentiveProgramScopesSwitcher'
import PercentageTiersRulesForm, { PercentageDiscountTierRule } from './PercentageTiersRulesForm'
import PromocodeTiersRulesForm, { PromocodeDiscountTierRule } from './PromocodeTiersRulesForm'
import QtyTiersRulesForm, { QtyDiscountTierRule } from './QtyTiersRulesForm'
import * as Styled from './styled'
import URLForm from './URLForm'

export type IncentiveProgramFormProps = Omit<
  IncentiveProgram,
  'description' | 'description_i18n' | 'rule_type' | 'company_name' | 'id'
> & {
  type: IncentiveProgramTypes
  noDateSpan: boolean
}

type FormikManagerData = {
  common: Pick<
    IncentiveProgram,
    'title' | 'status' | 'campaign_id' | 'sku_ids' | 'season_id' | 'end_date' | 'start_date' | 'scopes' | 'is_active'
  > & {
    type: IncentiveProgramTypes
  }
  slug: Pick<IncentiveProgram, 'slug'>
  tiers: Pick<IncentiveProgram, 'tiers_rules'>
}

export type IncentiveProgramDetailsFormProps = {
  onSubmit(form: IncentiveProgramFormProps): void
  onRemove?: () => void
  initialValues?: Partial<IncentiveProgramFormProps>
  progress?: Progress
  removeProgress?: Progress
  editing?: boolean
  onCancel(): void
}

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 510px;
`

const FormRow = styled.span`
  & > ${Label} {
    margin-bottom: 8px;
  }
`

const defaultQtyTierRule: QtyDiscountTierRule[] = [
  {
    min_qty: 1,
    amount: '',
  },
]

const defaultPromocodeTierRule: PromocodeDiscountTierRule[] = [
  {
    min_qty: 1,
    promocode: '',
  },
]

const defaultPercentageTierRule: PercentageDiscountTierRule[] = [
  {
    start_date: '',
    end_date: '',
    min_qty: 1,
    max_qty: 0,
    percent: '',
  },
]

const defaultHistoryQtyTierRule: HistoryDiscountTierRule[] = [
  {
    min_qty: 1,
    amount: '',
    min_qty_historic: 1,
  },
]

const IncentiveProgramDetailsForm: React.VFC<IncentiveProgramDetailsFormProps> = ({
  onSubmit,
  onRemove,
  progress,
  removeProgress,
  editing,
  onCancel,
  initialValues = {
    status: IncentiveProgramStatus.Active,
    scopes: [IncentiveProgramScope.FarmerOrders],
    noDateSpan: true,
  },
}) => {
  const { t } = useTranslation(['incentiveProgram', 'common'])
  const { bind, submitAll, dirty, valid, slots } = useFormManager<FormikManagerData>()

  const [seasonStartDate, setSeasonStartDate] = useState<string | null>()
  const [seasonEndDate, setSeasonEndDate] = useState<string | null>()
  const [sellerId, setSellerId] = useState(initialValues.incentive_campaign?.seller_id)
  const getFormatedDate = useDateFormatFn()
  const dateFormat = useDateFormat({ isYearShort: true })

  const validationSchema = useMemo(() => {
    const seasonRangeValidation = t('validation:seasonRangeOut', {
      ...(seasonStartDate && { start: getFormatedDate(seasonStartDate) }),
      ...(seasonEndDate && { end: getFormatedDate(seasonEndDate) }),
    })

    return Yup.object({
      title: Yup.string().required(t('validation:field_required')),
      campaign_id: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(t('validation:field_required')),
      sku_ids: Yup.array()
        .min(1)
        .required(t('validation:field_required')),
      type: Yup.string().required(t('validation:field_required')),
      ...(seasonEndDate && {
        end_date: Yup.date()
          .max(seasonEndDate, seasonRangeValidation)
          .nullable(),
      }),
      ...(seasonStartDate && {
        start_date: Yup.date()
          .min(seasonStartDate, seasonRangeValidation)
          .nullable(),
      }),
    })
  }, [t, seasonStartDate, getFormatedDate, seasonEndDate])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const formik = bind('common')({ initialValues, onSubmit: () => {}, validationSchema, enableReinitialize: true })

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()

      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: FileItem[]) =>
        languages.includes(lang) ? prop || '' : undefined

      onSubmit({
        title: forms.common.title || '',
        slug: forms.slug.slug,
        campaign_id: forms.common.campaign_id,
        type: forms.common.type,
        status: forms.common.status,
        sku_ids: forms.common.sku_ids,
        season_id: forms.common.season_id,
        end_date: forms.common.end_date,
        start_date: forms.common.start_date,
        units: {
          singular_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.singular),
          plural_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.plural),
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        tiers_rules: forms.tiers?.tiers_rules.map(tier => ({ ...tier, type: forms.common.type })),
        link_label_i18n: createLocalizedValue(lang => langProp(lang, forms[lang]?.link_label) as string),
        link_url_i18n: createLocalizedValue(lang => langProp(lang, forms[lang]?.link_url) as string),
        scopes: forms.common.scopes,
        is_active: forms.common.status === IncentiveProgramStatus.Active,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  useValidationErrorNotification(formik.submitCount, valid)

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.link_url_i18n?.[lang], initialValues?.link_label_i18n?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const setSeasonDateRanges = (season?: Season) => {
    if (!season || (!season.start_date && !season.end_date)) return
    setSeasonStartDate(season.start_date)
    setSeasonEndDate(season.end_date)
    handleDateRangeChange([season.start_date, season.end_date])
  }

  const calendarProps = useMemo(
    () => ({
      minDate: seasonStartDate ? parseISO(seasonStartDate) : undefined,
      maxDate: seasonEndDate ? parseISO(seasonEndDate) : undefined,
    }),
    [seasonStartDate, seasonEndDate],
  )

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const handleSkuChange = useCallback(
    (ids: string[]) => {
      formik.setFieldValue('sku_ids', ids)
    },
    [formik],
  )

  const handleDateRangeChange = ([start, end]) => {
    const parsedStartDate = parseISO(start)
    const parsedEndDate = parseISO(end)
    if (isValid(parsedStartDate)) {
      formik.setFieldValue('start_date', start)
    } else {
      formik.setFieldValue('start_date', undefined)
    }
    if (isValid(parsedEndDate)) {
      formik.setFieldValue('end_date', end)
    } else {
      formik.setFieldValue('end_date', undefined)
    }

    if (!start && !end) {
      formik.setFieldValue('noDateSpan', true)
    }
    if (start && end) {
      formik.setFieldValue('noDateSpan', false)
    }
  }

  const handleScopeChange = (scope: IncentiveProgramScope, on: boolean) => {
    const scopes = new Set(formik.values.scopes)
    on ? scopes.add(scope) : scopes.delete(scope)
    formik.setFieldValue('scopes', [...scopes])
  }

  const handleNoDateSpanChange = (_, isChecked) => {
    if (isChecked) {
      formik.setFieldValue('noDateSpan', true)
      formik.setFieldValue('start_date', null)
      formik.setFieldValue('end_date', null)
    } else {
      formik.setFieldValue('noDateSpan', false)
    }
  }

  const incentiveProgramTypes = useMemo(
    () => [
      {
        id: IncentiveProgramTypes.Quantity,
        title: t('types.qty'),
      },
      {
        id: IncentiveProgramTypes.Promocode,
        title: t('types.promocode'),
      },
      // TODO Add this types
      //{
      //  id: IncentiveProgramTypes.Gift,
      //  title: t('types.gift'),
      //},
      {
        id: IncentiveProgramTypes.Percentage,
        title: t('types.percentage'),
      },
      {
        id: IncentiveProgramTypes.HistoryQuantity,
        title: t('types.historyQty'),
      },
    ],
    [t],
  )

  const unitsJsx: React.ReactNode[] = []
  const urlJsx: React.ReactNode[] = []
  const checkboxJsx: React.ReactNode[] = []

  availableLanguagesList.forEach((lang, i) => {
    checkboxJsx[i] = (
      <Checkbox
        label={t(`common:langNames.${lang}`)}
        isChecked={isLangActive(lang)}
        value={lang}
        onChange={handleLangChange}
        key={lang}
      />
    )

    if (isLangActive(lang)) {
      unitsJsx[i] = (
        <ProductUnitsForm
          key={lang}
          lang={lang}
          plural={initialValues.units?.plural_i18n}
          singular={initialValues.units?.singular_i18n}
          useFormik={bind(`units:${lang}` as keyof FormikManagerData)}
          disableRequired
        />
      )
      urlJsx[i] = (
        <URLForm
          key={lang}
          lang={lang}
          link_label={initialValues.link_label_i18n}
          link_url={initialValues.link_url_i18n}
          useFormik={bind(lang as keyof FormikManagerData)}
        />
      )
    }
  })

  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.labels.description')}>
                  <Input
                    {...formik.getFieldProps('title')}
                    data-test-id={'discount-title'}
                    label={t('form.labels.title')}
                    invalid={formik.touched.title && !!formik.errors.title}
                    errorText={formik.errors.title}
                    placeholder={t('form.placeholders.title')}
                    onChange={(_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
                      formik.setFieldValue('title', value)
                      if (!editing) slots['slug'].setFieldValue('slug', helpersSlug.slugify(value))
                    }}
                    required
                  />
                  <SlugForm
                    showLabel={true}
                    useFormik={bind('slug')}
                    initialValue={initialValues.slug}
                    disabled={!!editing}
                  />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.config')}>
                  <IncentiveCampaignSelect
                    value={formik.values.campaign_id}
                    label={t('form.labels.campaign')}
                    invalid={formik.touched.campaign_id && !!formik.errors.campaign_id}
                    errorText={formik.errors.campaign_id}
                    placeholder={t('form.placeholders.campaign')}
                    onChange={(id, campaign) => {
                      formik.setFieldValue('campaign_id', id)
                      formik.setFieldValue('season_id', '')
                      formik.setFieldValue('start_date', '')
                      formik.setFieldValue('end_date', '')
                      formik.setFieldValue('sku_ids', [])
                      formik.setFieldTouched('campaign_id')
                      setSellerId(campaign?.seller_id)
                    }}
                    required
                  />
                  <SkuMultiSelect
                    values={formik.values.sku_ids}
                    label={t('form.labels.sku')}
                    placeholder={t('form.placeholders.sku')}
                    isDisabled={!sellerId}
                    sellerId={sellerId}
                    type={StorefrontItemType.Product}
                    onChange={handleSkuChange}
                    invalid={formik.touched.sku_ids && !!formik.errors.sku_ids}
                    errorText={formik.errors.sku_ids}
                    required
                    showSkuInfo
                    showSkuStatus
                  />
                  <Checkbox
                    isChecked={formik.getFieldProps('status').value === IncentiveProgramStatus.Active}
                    onChange={(_, isChecked) => {
                      isChecked
                        ? formik.setFieldValue('status', IncentiveProgramStatus.Active)
                        : formik.setFieldValue('status', IncentiveProgramStatus.Inactive)
                    }}
                    label={t('form.labels.isActive')}
                    testId={'incentive-program-status'}
                  />
                  <SeasonSelect
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    value={formik.values.season_id}
                    invalid={!!sellerId && formik.touched.season_id && !!formik.errors.season_id}
                    errorText={formik.errors.season_id}
                    onChange={(id, season) => {
                      formik.setFieldValue('season_id', id)
                      formik.setFieldTouched('season_id')
                      formik.setFieldValue('start_date', '')
                      formik.setFieldValue('end_date', '')
                      setSeasonDateRanges(season || undefined)
                    }}
                    isDisabled={!sellerId}
                    companyId={sellerId}
                    required
                  />
                  <Styled.VerticalFormsContainer>
                    <FormRow>
                      <Label>{t('form.labels.datePeriod')}</Label>
                      <RangeDatePicker
                        data-test-id="date-period"
                        calendarProps={calendarProps}
                        start={formik.values.start_date || ''}
                        end={formik.values.end_date || ''}
                        onChange={handleDateRangeChange}
                        errorText={formik.errors.start_date || formik.errors.end_date}
                        invalid={
                          !!(
                            (formik.errors.start_date && formik.touched.start_date) ||
                            (formik.errors.end_date && formik.touched.end_date)
                          )
                        }
                        format={dateFormat}
                      />
                    </FormRow>
                    <Styled.NoDateSpanContainer>
                      <Checkbox
                        isChecked={formik.values.noDateSpan}
                        onChange={handleNoDateSpanChange}
                        label={t('form.labels.noDateSpan')}
                      />
                    </Styled.NoDateSpanContainer>
                  </Styled.VerticalFormsContainer>
                  <IncentiveProgramScopeSwitcher scopes={formik.values?.scopes} onChange={handleScopeChange} />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.unitsSectionTitle')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>{checkboxJsx}</Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>{unitsJsx}</Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.urlSectionTitle')}>
                  <Styled.VerticalFormsContainer>{urlJsx}</Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                {sellerId && (
                  <FormComponents.FormSection title={t('form.labels.tiers')}>
                    <div data-test-id={'incentive-program-type'}>
                      <SimpleSelect
                        label={t('form.labels.type')}
                        placeholder={t('form.placeholders.type')}
                        options={incentiveProgramTypes}
                        value={formik.values.type}
                        invalid={formik.touched.type && !!formik.errors.type}
                        errorText={formik.errors.type}
                        onChange={val => {
                          formik.setFieldValue('type', val)
                          formik.setFieldTouched('type')
                        }}
                        required
                      />
                    </div>
                    {formik.values.type === IncentiveProgramTypes.Quantity ? (
                      <QtyTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || defaultQtyTierRule}
                        producerId={sellerId}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === IncentiveProgramTypes.Promocode ? (
                      <PromocodeTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || defaultPromocodeTierRule}
                        producerId={sellerId}
                        useFormik={bind('tiers')}
                      />
                    ) : //:
                    //formik.values.type === IncentiveProgramTypes.Gift ? (
                    //<GiftTiersRulesForm
                    //  tiers_rules={initialValues.tiers_rules || [{}]}
                    //  producerId={sellerId}
                    //  useFormik={bind('tiers')}
                    ///>
                    //)
                    formik.values.type === IncentiveProgramTypes.Percentage ? (
                      <PercentageTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || defaultPercentageTierRule}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === IncentiveProgramTypes.HistoryQuantity ? (
                      <HistoryQtyTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || defaultHistoryQtyTierRule}
                        producerId={sellerId}
                        useFormik={bind('tiers')}
                      />
                    ) : null}
                  </FormComponents.FormSection>
                )}
              </SectionBody>
            </SectionContainer>
          </Wrapper>
        </StickyFooterLayout.Body>
        {formik.values?.status !== IncentiveProgramStatus.Deleted && (
          <StickyFooterDefaultControls
            onRemove={onRemove}
            onSave={submit}
            onCancel={onCancel}
            saveProgress={progress}
            isSaveDisabled={!dirty}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues.title })}
          />
        )}
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default IncentiveProgramDetailsForm
