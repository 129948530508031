import React from 'react'
import PromoInfoActions from 'modules/domain/promoInfo/duck'
import { useSelector } from 'react-redux'
import PromoInfoSelectors from 'modules/domain/category/selectors'
import PromoInfoDetailsForm, { FormProps } from 'views/pages/PromoInfo/PromoInfoDetailsForm/PromoInfoDetailsForm'
import PromoInfoRoutes from 'views/pages/PromoInfo/routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const PromoInfoAdd: React.FC = () => {
  const { t } = useTranslation('promoInfo')
  const submitAction = useAction(PromoInfoActions.addRequested)
  const addProgress = useSelector(PromoInfoSelectors.addProgress)

  const handleFormSubmit = (props: FormProps) => {
    submitAction({
      title_i18n: props.title,
      description_i18n: props.description,
      message: {
        title_i18n: props.message.title,
        description_i18n: props.message.description,
      },
      status: props.status,
      type: props.type,
      producer_ids: props.producers,
      category_ids: props.categories,
      styles: props.styles,
    })
  }

  const page = useSelector(PromoInfoSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: PromoInfoRoutes.PromoInfoList })
  }

  useHelmet({ title: t('addPromoInfoMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <PromoInfoDetailsForm progress={addProgress} onCancel={goBack} onSubmit={handleFormSubmit} />
      </Layout.Content>
    </>
  )
}

export default PromoInfoAdd
