import React, { useCallback } from 'react'
import styled, { StyledProps } from 'styled-components'
import { IconCrossCircle, IconImage, useFileObjectUrl } from '@agro-club/frontend-shared'

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  opacity: 0;
  transition: 0.1s opacity ease-out;
  pointer-events: none;
`

const ImageIconStyled = styled(IconImage)`
  width: 48px;
  height: 48px;
`

const Wrapper = styled.div<StyledProps<{ url?: string }>>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: ${props => props.theme.color.onSurfaceLowEmphasys};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.onSurfaceLowEmphasys};
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  background-image: ${props => {
    if (props.url) {
      return `url(${props.url})`
    }
  }};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #000;
    transition: opacity 0.1s ease-in;
  }

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
      pointer-events: all;
    }
    &::before {
      opacity: 0.3;
    }
  }
`

const PlaceholderWrapper = styled(Wrapper)`
  cursor: pointer;
`

export const MediaPlaceholder: React.FC<{ onClick?(): void; className?: string }> = ({ onClick, className }) => (
  <PlaceholderWrapper onClick={onClick} className={className}>
    <ImageIconStyled />
  </PlaceholderWrapper>
)

const FilePreview: React.FC<{ file: File; onRemove?(): void }> = ({ file, onRemove }) => {
  const { url } = useFileObjectUrl(file)
  return <Wrapper url={url}>{onRemove && <RemoveIcon onClick={onRemove} />}</Wrapper>
}

const Media: React.FC<{ url?: string | File; onRemove?(url: string | File): void }> = ({ onRemove, url }) => {
  const removeHandler = useCallback(() => {
    if (url && onRemove) {
      onRemove(url)
    }
  }, [url, onRemove])
  if (url instanceof File) {
    return onRemove ? <FilePreview file={url} onRemove={removeHandler} /> : <FilePreview file={url} />
  }

  return <Wrapper url={url}>{onRemove && <RemoveIcon onClick={removeHandler} />}</Wrapper>
}

export default Media
