import { BasicSelect, LocalizedValue } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuProduct = {
  id: string
  title: LocalizedValue
}

type TargetSkuProductsFilter = {
  manufacturer_id?: string
  subcategory_id?: string
  title?: string
}

type TargetSkuProductSelectProps = EntitySelectProps<TargetSkuProduct> & {
  subcategoryId?: string
  manufacturerId?: string
  valueLabel?: string
  scope?: TargetSkuTypes | 'default'
}

const getListForm = createGetList<TargetSkuProduct, TargetSkuProductsFilter>(endpoints.target2sku('products'))

const getListTerritoryFilter = createGetList<TargetSkuProduct, TargetSkuProductsFilter>(
  endpoints.target2sku('territory/product'),
)

const getListRetailerFilter = createGetList<TargetSkuProduct, TargetSkuProductsFilter>(
  endpoints.target2sku('retailer/product'),
)

const getListFarmerFilter = createGetList<TargetSkuProduct, TargetSkuProductsFilter>(
  endpoints.target2sku('farmer/product'),
)

const getListCropFilter = createGetList<TargetSkuProduct, TargetSkuProductsFilter>(
  endpoints.target2sku('territory-crop/product'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Retailer]: getListRetailerFilter,
  [TargetSkuTypes.Territory]: getListTerritoryFilter,
  [TargetSkuTypes.Crop]: getListCropFilter,
}

const getValue = (item: TargetSkuProduct) => item.id

export const TargetSkuProductSelect: React.VFC<TargetSkuProductSelectProps> = ({
  subcategoryId,
  manufacturerId,
  isDisabled,
  valueLabel,
  onChange,
  scope = 'default',
  ...props
}) => {
  const { pick } = useLangPicker()

  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, subcategory_id: subcategoryId }), [
    manufacturerId,
    subcategoryId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: { en: valueLabel }, id: 'fake' } : undefined), [valueLabel])

  const getLabel = (item: TargetSkuProduct) => pick(item.title)

  const testId = 'target-product-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      onChange={onChange}
      searchParamKey="title"
      data-test-id={testId}
      {...props}
    />
  )
}
