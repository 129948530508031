import styled from 'styled-components'
import { IconCrossCircle, IconSettings, IconWarning } from '@agro-club/frontend-shared'

export const ConfigureFeatureFlagsButton = styled.div`
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary500};
  cursor: pointer;
`

export const IconSettingsStyled = styled(IconSettings)`
  position: relative;
  top: 3px;
  margin-right: 3px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.color.primary500};
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  margin-bottom: 16px;
  padding: 16px;
  overflow: auto;
`

export const FeatureFlagPermissions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 24px;
`

export const ModalFooter = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const Label = styled.span`
  line-height: 24px;
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
  cursor: pointer;
`

export const StyledButton = styled.div`
  margin-right: 16px;
  text-align: right;
  line-height: 20px;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary500};
  cursor: pointer;
`

export const RemoveButton = styled(IconCrossCircle)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  cursor: pointer;
`

export const FeatureFlagRow = styled.div`
  position: relative;
  margin-bottom: 16px;
  padding: 16px;

  &:hover {
    ${RemoveButton} {
      opacity: 1;
    }
  }
`

export const InputWrapper = styled.div`
  position: relative;
`

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 32px;
`

export const TooltipContent = styled.div`
  max-width: 80px;
  width: max-content;
  text-align: center;
`

export const IconWarningStyled = styled(IconWarning)`
  fill: ${({ theme }) => theme.color.accentNotify600};
`
