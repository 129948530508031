import React from 'react'
import { useHelmet } from '@agro-club/frontend-shared'
import { Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Routes from './routes'
import ProductSettingsTable from './ProductSettingsTable'

const ProductSettingsPage: React.FC = () => {
  const { t } = useTranslation(['storefront'])
  const metaTitle = t('metaTitle')
  useHelmet({ title: metaTitle })

  return (
    <Route path={Routes.List} exact={true}>
      <ProductSettingsTable />
    </Route>
  )
}

export default ProductSettingsPage
