import React from 'react'
import { useCategory } from 'modules/domain/category/hooks'
import { Progress } from 'modules/types'
import { useParams, useHistory } from 'react-router-dom'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import styled from 'styled-components'
import CategoryActions from 'modules/domain/category/duck'
import { useSelector } from 'react-redux'
import CategorySelectors from 'modules/domain/category/selectors'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet } from '@agro-club/frontend-shared'
import CategoryDetailsForm, { FormProps } from './CategoryDetailsForm/CategoryDetailsForm'
import CategoryRoutes from './routes'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const CategoryEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [fetchProgress, category] = useCategory(params.id)
  const meta = useSelector(state => CategorySelectors.meta(state, params.id))
  const submitAction = useAction(CategoryActions.updateRequested)
  const { t } = useTranslation('category')

  useHelmet({ title: category ? category.title : t('categoriesMetaTitle') })

  const goBack = () => {
    history.push(CategoryRoutes.CategoryList)
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !category) {
    return loading()
  }

  const handleFormSubmit = (props: FormProps) => {
    submitAction(category.id, {
      parent_id: props.parent_id,
      seller_ids: props.seller_ids,
      slug: props.slug,
      country: props.country,
      title_i18n: props.title,
      description_i18n: props.description,
      discount_text_i18n: props.discountText,
      files: props.files,
    })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={category.title} />
          <Id>{`ID ${category.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CategoryDetailsForm
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          editing={true}
          initialValues={{
            country: category.country,
            parent_id: category.parent_id,
            seller_ids: category.seller_ids,
            slug: category.slug,
            title: category.title_i18n,
            discountText: category.discount_text_i18n,
            description: category.description_i18n,
            files: category.images.map(src => ({
              file: src,
              kind: 'current' as const,
              id: uuid.v4(),
              error: null,
            })),
          }}
        />
      </Layout.Content>
    </>
  )
}

export default CategoryEdit
