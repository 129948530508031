import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import UserActions from 'modules/domain/user/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import UserSelectors from './selectors'
import { User } from 'modules/domain/user/types'
import UserRoutes from 'views/pages/User/routes'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(UserSelectors.page)
    const filter = yield select(UserSelectors.filter)
    const sorting = yield select(UserSelectors.sorting)
    const pageSize = yield select(UserSelectors.pageSize)

    let response: ListResponse<User> = yield call(managers.getUsersList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getUsersList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(UserActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, UserRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(UserSelectors.page)
    const filter = yield select(UserSelectors.filter)
    const sorting = yield select(UserSelectors.sorting)
    const pageSize = yield select(UserSelectors.pageSize)
    const { data, total_count }: { data: User[]; total_count: number } = yield call(
      managers.getUsersList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(UserActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.listRequestNextFailed(errType))
  }
}

export const fetchUser = function*({ payload: id }: ReturnType<typeof UserActions.itemRequested>) {
  try {
    const user: User = yield call(managers.getUser, id)
    yield put(UserActions.itemRequestSucceed(user))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.itemRequestFailed(id, errType))
  }
}

export const addUser = function*({
  payload: [dto, preventNavigation = false],
}: ReturnType<typeof UserActions.addRequested>) {
  try {
    const user: User = yield call(managers.addUser, dto)
    yield put(UserActions.addSucceed(user))
    if (!preventNavigation) {
      yield put(push(generatePath(UserRoutes.Edit, { id: user.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.addFailed(errType))
  }
}
export const updateUser = function*({ payload: [id, dto] }: ReturnType<typeof UserActions.updateRequested>) {
  try {
    const user: User = yield call(managers.updateUser, id, dto)
    yield put(UserActions.updateSucceed(user))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.updateFailed(id, errType))
  }
}

export const removeUser = function*({ payload }: ReturnType<typeof UserActions.removeRequested>) {
  try {
    yield call(managers.removeUser, payload)
    yield put(UserActions.removeSucceed(payload))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.removeFailed(payload, errType))
  }
}

export const changePassword = function*({
  payload: [user_id, password],
}: ReturnType<typeof UserActions.changePasswordRequested>) {
  try {
    yield call(managers.changePassword, user_id, password)
    yield put(UserActions.changePasswordSucceed())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.changePasswordFailed(errType))
  }
}

const UsersSaga = function*() {
  yield all([
    takeLatest(UserActions.itemRequested.type, fetchUser),
    takeLatest(UserActions.listRequested.type, fetchList),
    takeLatest(UserActions.filterUpdated.type, fetchList),
    takeLatest(UserActions.sortingUpdated.type, fetchList),
    takeLatest(UserActions.filterHasBeenReset.type, fetchList),
    takeLatest(UserActions.sortingHasBeenReset.type, fetchList),

    takeLatest(UserActions.listRequestedNext.type, fetchListNext),

    takeLatest(UserActions.addRequested.type, addUser),
    takeLatest(UserActions.updateRequested.type, updateUser),
    takeLatest(UserActions.removeRequested.type, removeUser),
    takeLatest(UserActions.changePasswordRequested.type, changePassword),
  ])
}

export default UsersSaga
