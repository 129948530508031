import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import UptakeActions from './duck'
import UptakeSelectors from './selectors'
import { Uptake } from './types'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useUptakeList: ResourceHook<Uptake[], void[]> = () => {
  const progress = useSelector(UptakeSelectors.listFetchProgress)
  const list = useSelector(UptakeSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(UptakeActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useUptakeItem: ResourceHook<Uptake, [string]> = (id: string) => {
  const progress = useSelector(UptakeSelectors.itemFetchProgress)
  const item = useSelector(state => UptakeSelectors.item(state, id))
  const fetchAction = useAction(UptakeActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [progress, item]
}

export const useUptakeItemById: ResourceHook<Uptake, [string]> = (id: string) => {
  const meta = useSelector(state => UptakeSelectors.meta(state, id))
  const item = useSelector(state => UptakeSelectors.item(state, id))
  const fetchAction = useAction(UptakeActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const usePendingUptakeCount: ResourceHook<number, void[]> = () => {
  const progress = useSelector(UptakeSelectors.pendingFetchProgress)
  const count = useSelector(UptakeSelectors.pendingCount)
  const fetchAction = useAction(UptakeActions.pendingCallCountRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })
  return [progress, count]
}
