import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import DistributorOrderSkuSelectors from 'modules/domain/distributorOrderSku/selectors'
import { useTranslation } from 'react-i18next'
import DistributorOrderSkuActions from 'modules/domain/distributorOrderSku/duck'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import DistributorOrderSkuDetailsForm, {
  DistributorOrderSkuDetailsFormFields,
} from './components/DistributorOrderSkuDetailsForm'
import Routes from './routes'

import { DistributorOrderStatus } from 'types/distributorOrder'
import { useHistory } from 'react-router'

const DistributorOrderSkuAdd: React.VFC<{
  initialState?: DistributorOrderSkuDetailsFormFields
  onUpdateInitialState(props: DistributorOrderSkuDetailsFormFields | undefined): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('distributorOrder')

  const history = useHistory()
  const push = useHistoryPush()
  const page = useSelector(DistributorOrderSkuSelectors.page)
  const goBack = useCallback(
    () => (history.length > 2 ? history.goBack() : push({ route: Routes.List, query: { page } })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.length, page, push],
  )

  const addProgress = useSelector(DistributorOrderSkuSelectors.addProgress)

  const addOrder = useAction(DistributorOrderSkuActions.addRequested)

  useHelmet({ title: t('addPageTitle') })

  const handleSubmit = useCallback(
    (values: DistributorOrderSkuDetailsFormFields, options: { duplicate: boolean; dirty: boolean }) => {
      onUpdateInitialState(undefined)
      addOrder(values, options.duplicate)
    },
    [addOrder, onUpdateInitialState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('addPageTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DistributorOrderSkuDetailsForm
          mode={'create'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          progress={addProgress}
          initialValues={{
            status: initialState?.status || DistributorOrderStatus.New,
            purchase_number: initialState?.purchase_number || '',
            distributor_id: initialState?.distributor_id || '',
            billing_company_id: initialState?.billing_company_id,
            producer_id: initialState?.producer_id,
            seller_id: initialState?.seller_id,
            comment: initialState?.comment,
            sku_items: initialState?.sku_items || [],
            interaction: {
              canceled_by_role: initialState?.interaction?.canceled_by_role,
              confirmed_by_producer: !!initialState?.interaction?.confirmed_by_producer,
            },
            season_id: initialState?.season_id || '',
            files: initialState?.files || [],
            admin_comment: initialState?.admin_comment,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default DistributorOrderSkuAdd
