import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from './routes'

import HouseholdTable from './HouseholdTable'
import HouseholdAdd from './HouseholdAdd'
import HouseholdEdit from './HouseholdEdit'

const HouseholdPage = () => {
  return (
    <Switch>
      <Route path={Routes.Add} exact={true}>
        <HouseholdAdd />
      </Route>
      <Route path={Routes.Edit} exact={true}>
        <HouseholdEdit />
      </Route>
      <Route path={Routes.List} exact={true}>
        <HouseholdTable />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  )
}

export default HouseholdPage
