import managers from 'modules/domain/incentiveCampaign/managers'
import { IncentiveCampaign } from 'modules/domain/incentiveCampaign/types'
import React from 'react'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type IncentiveCampaignSelectProps = EntitySelectProps<IncentiveCampaign>
export const getIncentiveCampaignLabel = (item: IncentiveCampaign) => item.title
export const getIncentiveCampaignValue = (item: IncentiveCampaign) => item.id

export const IncentiveCampaignSelect: React.VFC<IncentiveCampaignSelectProps> = props => {
  return (
    <SelectTestWrapper data-test-id="incentive-campaign-select">
      <EntitySelect
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getIncentiveCampaignLabel}
        getOptionValue={getIncentiveCampaignValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
