import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import CallBackRequestActions from './duck'
import CallBackRequestSelectors from './selectors'
import { CallBackRequest } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import CallBackRequestRoutes from 'views/pages/CallBackRequest/routes'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(CallBackRequestSelectors.page)
    const filter = yield select(CallBackRequestSelectors.filter)
    const sorting = yield select(CallBackRequestSelectors.sorting)
    const pageSize = yield select(CallBackRequestSelectors.pageSize)

    let response: ListResponse<CallBackRequest> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(CallBackRequestActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, CallBackRequestRoutes.List, { page: currentPage })
    yield put(CallBackRequestActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(CallBackRequestSelectors.page)
    const filter = yield select(CallBackRequestSelectors.filter)
    const sorting = yield select(CallBackRequestSelectors.sorting)
    const pageSize = yield select(CallBackRequestSelectors.pageSize)
    const { data, total_count }: { data: CallBackRequest[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(CallBackRequestActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof CallBackRequestActions.itemRequested>) {
  try {
    const company: CallBackRequest = yield call(managers.getItem, id)
    yield put(CallBackRequestActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof CallBackRequestActions.addRequested>) {
  try {
    const company: CallBackRequest = yield call(managers.addItem, dto)
    yield put(CallBackRequestActions.addSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof CallBackRequestActions.updateRequested>) {
  try {
    const company: CallBackRequest = yield call(managers.updateItem, id, dto)
    yield put(CallBackRequestActions.updateSucceed(company))
    yield put(CallBackRequestActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof CallBackRequestActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(CallBackRequestActions.removeSucceed(payload))
    yield put(CallBackRequestActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallBackRequestActions.removeFailed(payload, errType))
  }
}

export const pendingCount = function*(_actions: ReturnType<typeof CallBackRequestActions.pendingCallCountRequested>) {
  try {
    const count: number = yield call(managers.getPendingCount)
    yield put(CallBackRequestActions.pendingCallCountRequestSuccess(count))
  } catch (err) {
    yield put(CallBackRequestActions.pendingCallCountRequestError())
  }
}

const CallBackRequestSaga = function*() {
  yield all([
    takeLatest(CallBackRequestActions.itemRequested.type, fetchItem),
    takeLatest(CallBackRequestActions.listRequested.type, fetchList),
    takeLatest(CallBackRequestActions.filterUpdated.type, fetchList),
    takeLatest(CallBackRequestActions.sortingUpdated.type, fetchList),
    takeLatest(CallBackRequestActions.filterHasBeenReset.type, fetchList),
    takeLatest(CallBackRequestActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CallBackRequestActions.listRequestedNext.type, fetchListNext),

    takeLatest(CallBackRequestActions.addRequested.type, addItem),
    takeLatest(CallBackRequestActions.updateRequested.type, updateItem),
    takeLatest(CallBackRequestActions.removeRequested.type, removeItem),
    takeLatest(CallBackRequestActions.pendingCallCountRequested.type, pendingCount),
  ])
}

export default CallBackRequestSaga
