import React from 'react'
import { useParams } from 'react-router-dom'
import { useUptakeItem } from 'modules/domain/uptake/hooks'
import { Progress } from 'modules/types'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import {
  Button,
  Flag,
  FormText,
  SectionBody,
  SectionContainer,
  useAction,
  useHistoryPush,
  FormComponents,
} from '@agro-club/frontend-shared'
import UpTakeRoutes from 'views/pages/UpTake/routes'
import { useSelector } from 'react-redux'
import UptakeActions from 'modules/domain/uptake/duck'
import UptakeSelectors from 'modules/domain/uptake/selectors'
import { useTranslation } from 'react-i18next'
import { UptakeStatus } from 'modules/domain/uptake/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { RemoveButton } from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import useDateFormatFn from 'hooks/useDateFormatFn'

const UpTakeItem: React.FC = () => {
  const { t } = useTranslation(['uptake', 'common'])
  const params = useParams<{ id: string }>()
  const [progress, item] = useUptakeItem(params.id)
  const push = useHistoryPush()

  const getFormatedDate = useDateFormatFn({ withTime: true })

  const page = useSelector(UptakeSelectors.page)
  const goBack = () => push({ route: UpTakeRoutes.List, query: { page } })

  const removeAction = useAction(UptakeActions.removeRequested)
  const approveAction = useAction(UptakeActions.updateRequested, params.id, {
    status: UptakeStatus.DONE,
  })

  const meta = useSelector(state => UptakeSelectors.meta(state, params.id))

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (progress === Progress.WORK || !item) {
    return loading()
  }
  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StickyFooterLayout.Wrapper>
          <StickyFooterLayout.Body>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={'info'}>
                  <FormText label={'id'}>{item.id}</FormText>
                  <FormText label={t('fields.contact')}>{item.email_or_phone}</FormText>
                  <FormText label={t('fields.message')}>{item.message}</FormText>
                  <FormText label={t('fields.region')}>{item.region ? <Flag code={item.region} /> : null}</FormText>
                  <FormText label={t('fields.createdAt')}>{getFormatedDate(item.created_at)}</FormText>
                  <FormText label={t('fields.updatedAt')}>{getFormatedDate(item.updated_at)}</FormText>
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
          </StickyFooterLayout.Body>
          <StickyFooterLayout.ButtonsFooter>
            <Button intent={'primary'} filled onClick={() => approveAction()} progress={meta.updateProgress}>
              {t('approve')}
            </Button>
            <RemoveButton intent={'danger'} onClick={() => removeAction()} progress={meta.removeProgress}>
              {t('common:delete')}
            </RemoveButton>
          </StickyFooterLayout.ButtonsFooter>
        </StickyFooterLayout.Wrapper>
      </Layout.Content>
    </>
  )
}

export default UpTakeItem
