import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  DeclarationCountDto,
  PrintDeclarationDto,
  ReturnDeclarationDTO,
  ReturnDeclarationListRequestFilter,
  ReturnDeclarationListRequestSorting,
  ReturnDeclarationState,
} from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { ReturnDeclaration } from 'types/returnDeclaration'

const initialState: ReturnDeclarationState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  printProgress: Progress.IDLE,
  printError: null,
  declarationCountFetchProgress: Progress.IDLE,
  declarationCountFetchError: null,
  declarationCount: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class ReturnDeclarationReducer extends ImmerReducer<ReturnDeclarationState> {
  listRequested(params: {
    filter?: ReturnDeclarationListRequestFilter
    sorting?: ReturnDeclarationListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: ReturnDeclaration[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: ReturnDeclaration) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: ReturnDeclarationDTO, _duplicate: boolean) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }
  addSucceed(item: ReturnDeclaration) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<ReturnDeclarationDTO>, _duplicate: boolean) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }
  updateSucceed(item: ReturnDeclaration) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  filterUpdated(filter: ReturnDeclarationListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: ReturnDeclarationListRequestFilter) {
    this.draftState.filter = { ...this.draftState.filter, ...filter }
  }

  sortingUpdated(sorting: ReturnDeclarationListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchNextErrorDetail = undefined
  }

  listRequestNextSucceed(list: ReturnDeclaration[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.listFetchNextErrorDetail = undefined
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestNextFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
    this.draftState.listFetchNextErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  printRequested(_dto: PrintDeclarationDto) {
    this.draftState.printProgress = Progress.WORK
    this.draftState.printError = null
  }

  printSucceed() {
    this.draftState.printProgress = Progress.SUCCESS
    this.draftState.printError = null
  }
  printFailed(error: any) {
    this.draftState.printProgress = Progress.ERROR
    this.draftState.printError = error
  }
  countRequested(_dto: DeclarationCountDto) {
    this.draftState.declarationCountFetchProgress = Progress.WORK
    this.draftState.declarationCountFetchError = null
  }
  countRequestedSucceed(count: number) {
    this.draftState.declarationCountFetchProgress = Progress.SUCCESS
    this.draftState.declarationCountFetchError = null
    this.draftState.declarationCount = count
  }
  countRequestedFailed(error: any) {
    this.draftState.declarationCountFetchProgress = Progress.ERROR
    this.draftState.declarationCountFetchError = error
  }
  cleanDeclarationCounter() {
    this.draftState.declarationCountFetchProgress = Progress.IDLE
    this.draftState.declarationCount = null
  }
}

export const ReturnDeclarationActions = createActionCreators(ReturnDeclarationReducer)
export default ReturnDeclarationActions
export const reducer = createReducerFunction(ReturnDeclarationReducer, initialState)
