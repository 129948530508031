import { ListResponse } from 'types/api'

export type CertainGetItem<ItemType> = (id: string) => Promise<ItemType>
export type ListGetItem<ItemType> = (ids: string[]) => Promise<ListResponse<ItemType>>
export type ItemsByIds<ItemType> = (ids: string[]) => Promise<ItemType[]>

export function createItemsByIds<ItemType>(
  isMultipleIdsRequest: true,
  getItem: ListGetItem<ItemType>,
): ItemsByIds<ItemType>
export function createItemsByIds<ItemType>(
  isMultipleIdsRequest: false,
  getItem: CertainGetItem<ItemType>,
): ItemsByIds<ItemType>

/*
 * Creates function that fetches items by given ids
 * @param isMultipleIdsRequest - is getItem request can fetch array of ids?
 * @param getItem - item(s) request
 * @returns Promise<ItemType[]>
 */
export function createItemsByIds<ItemType, IsMultipleIdsRequest extends boolean>(
  isMultipleIdsRequest: IsMultipleIdsRequest,
  getItem: IsMultipleIdsRequest extends true ? ListGetItem<ItemType> : CertainGetItem<ItemType>,
): ItemsByIds<ItemType> {
  return async (ids: string[]) => {
    if (isMultipleIdsRequest) {
      try {
        const response = await (getItem as ListGetItem<ItemType>)(ids)
        return response.data
      } catch {
        return []
      }
    } else {
      return Promise.all([...ids.map(id => (getItem as CertainGetItem<ItemType>)(id))])
    }
  }
}
