import React from 'react'
import { Route, Switch } from 'react-router-dom'

import IncentiveCampaignList from './IncentiveCampaignList'
import IncentiveCampaignRoutes from './routes'
import IncentiveCampaignAdd from './IncentiveCampaignAdd'
import IncentiveCampaignEdit from './IncentiveCampaignEdit'

const IncentiveCampaignPage: React.VFC = () => {
  return (
    <Switch>
      <Route exact path={IncentiveCampaignRoutes.List}>
        <IncentiveCampaignList />
      </Route>
      <Route exact path={IncentiveCampaignRoutes.Add}>
        <IncentiveCampaignAdd />
      </Route>
      <Route exact path={IncentiveCampaignRoutes.Edit}>
        <IncentiveCampaignEdit />
      </Route>
    </Switch>
  )
}

export default IncentiveCampaignPage
