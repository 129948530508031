import { isValid, format } from 'date-fns'
import useDateFormat from './useDateFormat'

type TimeFormat = 'HH' | 'HH:mm' | 'HH:mm:ss'
type Options = { timeFormat?: TimeFormat; isYearShort?: boolean; withTime?: boolean }
type UseDateFormatFnResult = (date?: string | number) => string

export default function useDateFormatFn(options?: Options): UseDateFormatFnResult {
  const dateFormat = useDateFormat(options)

  const formatDate = (date?: string | number) => {
    const parsedDate = new Date(date || '')

    if (!date || !isValid(parsedDate)) return ''

    return format(parsedDate, dateFormat)
  }

  return formatDate
}
