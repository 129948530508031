import { Progress } from 'modules/types'
import { apiClient, RequestOptions, UrlParams } from 'modules/utils/httpClient'
import { useCallback, useState } from 'react'

export const useDownload = (
  path: string,
  params?: UrlParams,
  options?: RequestOptions<any>,
  client: typeof apiClient = apiClient,
): [Progress, () => Promise<any>] => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const callback = useCallback(async () => {
    setProgress(Progress.WORK)
    try {
      await client.get<any>(path, params, { ...options, responseType: 'blob' })
    } catch {
      setProgress(Progress.ERROR)
    } finally {
      setProgress(Progress.SUCCESS)
    }
  }, [client, options, params, path])
  return [progress, callback]
}

export default useDownload
