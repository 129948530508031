import {
  User,
  UserDTO,
  UserListRequestFilter,
  UserListRequestSorting,
  UserShortListRequestFilter,
} from 'modules/domain/user/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getUsersList = (
  filter: UserListRequestFilter,
  sorting: UserListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<User>>(endpoints.users(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
      show_simple_users: true,
    })
  } catch (err) {
    console.error('Users list fetch error!', err)
    throw err
  }
}

export const getCustomersList = (search?: string) => {
  try {
    return apiClient.get<ListResponse<User>>(endpoints.users(), {
      search,
      show_simple_users: true,
    })
  } catch (err) {
    console.error('Customers list fetch error!', err)
    throw err
  }
}

export const getCrmUsersList = (search?: string) => {
  try {
    return apiClient.get<ListResponse<User>>(endpoints.users(), {
      search,
      show_simple_users: false,
    })
  } catch (err) {
    console.error('Crm users list fetch error!', err)
    throw err
  }
}

export const getCrmUsersSuggestions = (
  filter: UserShortListRequestFilter,
  sorting: UserListRequestSorting,
  page: number,
  pageSize: number,
) => {
  try {
    if (!filter) {
      filter = {}
    }
    return apiClient.get<ListResponse<User>>(endpoints.crmUsersSuggest(), {
      page: page,
      page_size: pageSize,
      show_simple_users: false,
      sort_field: 'first_name',
      ...sorting,
      ...filter,
    })
  } catch (err) {
    console.error('Crm users list fetch error!', err)
    throw err
  }
}

export const getUser = (id: string) => {
  try {
    return apiClient.get<User>(endpoints.users(id))
  } catch (err) {
    console.error('User fetch error!', err)
    throw err
  }
}

export const addUser = (user: UserDTO) => {
  try {
    return apiClient.post<User>(endpoints.users(), user)
  } catch (err) {
    console.error('User create error!', err)
    throw err
  }
}

export const updateUser = (id: string, user: Partial<UserDTO>) => {
  return apiClient.put<User>(endpoints.users(id), user)
}

export const removeUser = (id: string) => {
  return apiClient.delete(endpoints.users(id))
}

export const changePassword = (user_id: string, password: string) => {
  return apiClient.put(endpoints.changePassword(user_id), {
    password,
  })
}

export const getItemsByIds = createItemsByIds(true, (ids: string[]) =>
  getCrmUsersSuggestions(
    {
      ids,
    },
    {},
    1,
    ids.length,
  ),
)
