import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import CompanyActions from 'modules/domain/company/duck'
import * as managers from 'modules/domain/company/managers'
import CompanySelectors from 'modules/domain/company/selectors'
import { BranchCompany, Company, CompanyListRequestFilter, CompanyRelation } from 'modules/domain/company/types'
import { Progress, ResourceHook } from 'modules/types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { makeCancelable } from 'modules/utils/httpClient'
import { useSelector } from 'react-redux'

export const useCompanyList: ResourceHook<Company[], [{ filter?: CompanyListRequestFilter; pageSize?: number }]> = ({
  filter,
  pageSize,
}: { filter?: CompanyListRequestFilter; pageSize?: number } = {}) => {
  const progress = useSelector(CompanySelectors.listFetchProgress)
  const list = useSelector(CompanySelectors.companyList)
  const page = usePageQuery()
  const params: { filter?: CompanyListRequestFilter; page?: number; pageSize?: number } = {}

  if (filter) params.filter = filter
  if (page) params.page = page
  if (pageSize !== undefined) params.pageSize = pageSize

  const fetchAction = useAction(CompanyActions.listRequested, params)

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useBranchCompaniesById: ResourceHook<
  {
    companies: BranchCompany[]
    permissions: CompanyRelation[]
    ids: string[]
  },
  [string]
> = (id: string) => {
  const branchCompaniesList = useSelector(state => CompanySelectors.branchCompaniesById(state, id))
  const branchCompaniesIdsList = useSelector(state => CompanySelectors.branchCompaniesIdsById(state, id))
  const branchCompaniesPermissions = useSelector(state => CompanySelectors.branchCompaniesPermissionsById(state, id))

  const fetchProgress = useSelector(state => CompanySelectors.branchCompaniesFetchProgressById(state, id))
  const fetchAction = useAction(CompanyActions.branchCompaniesRequested, id)

  useDidMount(() => {
    if (id) {
      fetchAction()
    }
  })

  return [
    fetchProgress,
    { companies: branchCompaniesList, ids: branchCompaniesIdsList, permissions: branchCompaniesPermissions },
  ]
}

export const useCompanyById = createItemByIdHook(CompanySelectors, CompanyActions.itemRequested)

export const useProducersOptions = makeCancellableResourceListHook(makeCancelable(managers.getProducersList))
export const useSellersOptions = makeCancellableResourceListHook(makeCancelable(managers.getSellersList))
export const useInventoryExchangeProducersOptions = makeCancellableResourceListHook(
  makeCancelable(managers.getInventoryExchangeProducersList),
)
export const useInventoryExchangeDistributorsOptions = makeCancellableResourceListHook(
  makeCancelable(managers.getInventoryExchangeDistibutorsList),
)
