import { CountryCode } from 'libphonenumber-js'
import jsCookie from 'js-cookie'
import { useSelector } from 'react-redux'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { DEFAULT_COUNTRY } from 'modules/constants'
import AuthSelectors from 'modules/domain/auth/selectors'

export const useDetectedCountry = () => {
  const cookieCountry = jsCookie.get('cf-ipcountry')
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const profile = useSelector(AuthSelectors.profile)
  const profileCountry = profile?.country

  if (profileCountry) {
    return profileCountry
  } else if (cookieCountry) {
    const country = cookieCountry.toUpperCase() as CountryCode

    if (phoneCodes.findIndex(item => item.id === country) !== -1) {
      return country
    }
  }

  return DEFAULT_COUNTRY
}
