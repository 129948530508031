import React, { useCallback, useMemo, memo } from 'react'
import { useTable } from 'react-table'
import { Badge, BadgeListRequestFilter } from 'modules/domain/badge/types'
import BadgeSelectors from 'modules/domain/badge/selectors'
import BadgeActions from 'modules/domain/badge/duck'
import { useBadgeList } from 'modules/domain/badge/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Status } from 'types/entities'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import {
  AdvancedHeadCell,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { TableFilter } from 'views/components/TableFilters/TableFilters'

const BadgeSortableHeadCell = AdvancedHeadCell<keyof Badge>()

const IdCell = ({ cell, column, row }) => (
  <div>
    <StatusIcon status={row.values.status} />
    <span key={column.id}>{cell.value}</span>
  </div>
)

const BadgeList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('badge')
  const [progress, data] = useBadgeList()
  const filterUpdated = useAction(BadgeActions.filterUpdated)
  const sortingUpdated = useAction(BadgeActions.sortingUpdated)
  const listRequested = useAction(BadgeActions.listRequested)
  const filterValue = useSelector(BadgeSelectors.filter)
  const total = useSelector(BadgeSelectors.total)
  const pages = useSelector(BadgeSelectors.pages)
  const page = useSelector(BadgeSelectors.page)
  const pageSize = useSelector(BadgeSelectors.pageSize)
  const { status } = filterValue
  const { sort_field, sort_reversed } = useSelector(BadgeSelectors.sorting)

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.id'),
        accessor: 'id' as const,
        id: 'id',
        Cell: IdCell,
      },
      {
        Header: t('list.tableHeaders.name'),
        accessor: 'name' as const,
        id: 'name',
      },
      {
        Header: t('list.tableHeaders.status'),
        accessor: 'status' as const,
        id: 'status',
        hidden: true,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<Badge>({
    columns: visibleColumns,
    data: data as Badge[],
    initialState: { hiddenColumns: ['status'] },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: BadgeListRequestFilter) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const statusFilterOptions = useMemo(
    () => [
      {
        value: Status.Active,
        title: t('statuses.active'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: Status.Inactive,
        title: t('statuses.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
      {
        value: Status.Deleted,
        title: t('statuses.deleted'),
        bullet: true,
        color: 'red' as const,
      },
    ],
    [t],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('badgeList')

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TableFilter
          handleFilterChange={handleFilterChange}
          title={t('form.status')}
          options={statusFilterOptions}
          filterValue={{ status }}
        />
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <BadgeSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Badge}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </BadgeSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default memo(BadgeList)
