import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('cropTargetSku')

export const CropTargetSkuSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.cropTargetSku.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.cropTargetSku.totalTargetValues,
  retailerTargetsTotal: (state: AppGlobalState) => state.cropTargetSku.totalRetailerTargets,
  farmerOrdersTotal: (state: AppGlobalState) => state.cropTargetSku.totalFarmerOrdersSku,
  previousYear: (state: AppGlobalState) => state.cropTargetSku.totalFarmerOrdersSkuPreviousYear,
}

export default CropTargetSkuSelectors
