import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import InventoryExchangeActions from './duck'
import InventoryExchangeSelectors from './selectors'
import { InventoryItem } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import { push } from 'connected-react-router'
import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'
import { generatePath } from 'react-router-dom'

export const fetchList = function*() {
  try {
    let currentPage = yield select(InventoryExchangeSelectors.page)
    const filter = yield select(InventoryExchangeSelectors.filter)

    yield put(InventoryExchangeActions.filtersUpdatedWithPersistentStorage(filter))

    const sorting = yield select(InventoryExchangeSelectors.sorting)
    const pageSize = yield select(InventoryExchangeSelectors.pageSize)

    let response: ListResponse<InventoryItem> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response

    const mappedData = data.map(item => ({ ...item, id: `${item.retailer_id}-${item.sku_id}`, itemId: item.id }))
    yield put(InventoryExchangeActions.listRequestSucceed(mappedData, total_count, page))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof InventoryExchangeActions.itemRequested>) {
  try {
    // TODO refactor me
    const [retailer_id, sku_id] = id.split('-')
    const item: InventoryItem = yield call(managers.getItem, retailer_id, sku_id)
    const updatedItem = { ...item, id: `${item.retailer_id}-${item.sku_id}`, itemId: item.id }
    yield put(InventoryExchangeActions.itemRequestSucceed(updatedItem))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeActions.itemRequestFailed(id, type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(InventoryExchangeSelectors.page)
    const filter = yield select(InventoryExchangeSelectors.filter)
    const sorting = yield select(InventoryExchangeSelectors.sorting)
    const pageSize = yield select(InventoryExchangeSelectors.pageSize)
    const { data, total_count }: { data: InventoryItem[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(InventoryExchangeActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeActions.listRequestNextFailed(type, detail))
  }
}

export const updateItem = function*({
  payload: [id, retailer_id, sku_id, dto],
}: ReturnType<typeof InventoryExchangeActions.updateRequested>) {
  try {
    const item = yield call(managers.updateItem, retailer_id, sku_id, dto)
    const updatedItem = { ...item, id: `${item.retailer_id}-${item.sku_id}`, itemId: item.id }
    yield put(InventoryExchangeActions.updateSucceed(id, updatedItem))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(InventoryExchangeActions.updateFailed(id, errType))
  }
}

export const transferItem = function*({ payload }: ReturnType<typeof InventoryExchangeActions.transferRequested>) {
  try {
    yield call(managers.requestTrasferItem, payload)
    yield put(InventoryExchangeActions.transferRequestSucceed())
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(InventoryExchangeActions.transferRequestFailed(type, detail, errors))
  }
}

export const removeItemTotal = function*({ payload }: ReturnType<typeof InventoryExchangeActions.removeRequested>) {
  try {
    const dict = yield select(InventoryExchangeSelectors.dict)
    yield call(managers.removeItemTotal, dict[payload].itemId)
    yield put(InventoryExchangeActions.removeSucceed(payload))
    yield put(InventoryExchangeActions.resetRemoveProgress())

    const pagePath = generatePath(InventoryExchangeRoutes.Total)
    yield put(push(pagePath))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(InventoryExchangeActions.removeFailed(payload, errType))
  }
}

const InventoryExchangeSaga = function*() {
  yield all([
    takeLatest(InventoryExchangeActions.itemRequested.type, fetchItem),
    takeLatest(InventoryExchangeActions.listRequested.type, fetchList),
    takeLatest(InventoryExchangeActions.filterUpdated.type, fetchList),
    takeLatest(InventoryExchangeActions.sortingUpdated.type, fetchList),
    takeLatest(InventoryExchangeActions.filterHasBeenReset.type, fetchList),
    takeLatest(InventoryExchangeActions.sortingHasBeenReset.type, fetchList),
    takeLatest(InventoryExchangeActions.listRequestedNext.type, fetchListNext),
    takeLatest(InventoryExchangeActions.updateRequested.type, updateItem),
    takeLatest(InventoryExchangeActions.transferRequested.type, transferItem),
    takeLatest(InventoryExchangeActions.removeRequested.type, removeItemTotal),
  ])
}

export default InventoryExchangeSaga
