import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import {
  Button,
  FormComponents,
  FormField,
  SectionBody,
  SectionContainer,
  TextArea,
  PrefixInput,
} from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import { InventoryItem } from 'modules/domain/inventoryExchange/types'
import { TotalQtyField } from './TotalQtyField'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import { isAdmin } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'

const FormSection = styled(FormComponents.FormSection)`
  max-width: 400px;
`

const Title = styled.span`
  margin: 3px 4px 3px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};
`
const InfoText = styled.span`
  margin: 3px 3px 3px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const TitleAndText = styled.div`
  display: flex;
`

export type InventoryFormFields = {
  comment?: string
  price?: string
}

export type InventoryFormProps = {
  comment?: string
  price?: string
}

type Props = {
  item: InventoryItem
  mode: 'read' | 'edit'
  progress?: Progress
  onCancel(): void
  onSubmit(values: InventoryFormFields): void
  onRemove(): void
}

const InventoryTotalDetailsForm: React.FC<Props> = ({ item, mode, progress, onCancel, onSubmit, onRemove }) => {
  const { t } = useTranslation('inventorySKU')
  const role = useSelector(AuthSelectors.role)

  const validationSchema = useMemo(() => {
    return Yup.object({
      quantity: Yup.number(),
    })
  }, [])

  const formik = useFormik<Partial<InventoryFormProps>>({
    initialValues: {
      comment: item.comment,
      price: item.price,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      onSubmit({
        comment: values.comment || '',
        price: values.price || '',
      })
    },
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  const readOnly = mode === 'read'

  const btnFooterJSX =
    (!readOnly && (
      <StickyFooterLayout.ButtonsFooter>
        <Button
          type={'submit'}
          intent={'primary'}
          filled
          disabled={readOnly || progress === Progress.WORK}
          progress={progress}
          data-test-id={'save-button'}
          onClick={() => formik.handleSubmit()}
        >
          {t('common:save')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />
      </StickyFooterLayout.ButtonsFooter>
    )) ||
    (isAdmin(role) && (
      <StickyFooterLayout.ButtonsFooter>
        <StickyFooterDeleteBtn onRemove={onRemove as () => void} popoverText={t('removeTotal')} />
      </StickyFooterLayout.ButtonsFooter>
    ))

  let qtyJSX: React.ReactNode = null
  if (readOnly) {
    qtyJSX = (
      <TitleAndText>
        <Title>{`${t('totalQty')}:`}</Title>
        <InfoText>{item.total_qty}</InfoText>
      </TitleAndText>
    )
  } else {
    qtyJSX = <FormField label={t('totalQty')} render={_ => <TotalQtyField item={item} />} />
  }

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <FormSection title="">
              <TitleAndText>
                <Title>{`${t('manufacturer')}:`}</Title>
                <InfoText>{item.sku?.product?.producer?.official_name}</InfoText>
              </TitleAndText>
              <TitleAndText>
                <Title>{`${t('distributor')}:`}</Title>
                <InfoText>{item.retailer.internal_name}</InfoText>
              </TitleAndText>
              <TitleAndText>
                <Title>{`${t('product')}:`}</Title>
                <InfoText>{item.sku?.product?.title}</InfoText>
              </TitleAndText>
              <TitleAndText>
                <Title>{`${t('packageType')}:`}</Title>
                <InfoText>{item?.sku?.params?.package_title}</InfoText>
              </TitleAndText>
              <TitleAndText>
                <Title>{`${t('sku')}:`}</Title>
                <InfoText>{item.sku?.sku_id}</InfoText>
              </TitleAndText>
              {qtyJSX}
              <FormField
                label={t('price')}
                render={_ => (
                  <PrefixInput
                    {...formik.getFieldProps('price')}
                    type="number"
                    prefix={'$'}
                    inputSize={'medium'}
                    disabled={readOnly}
                  />
                )}
              />
              <TextArea
                label={t('comment')}
                {...formik.getFieldProps('comment')}
                invalid={formik.touched.comment && !!formik.errors.comment}
                errorText={formik.errors.comment}
                disabled={readOnly}
                limit={1000}
              />
            </FormSection>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      {btnFooterJSX}
    </StickyFooterLayout.Wrapper>
  )
}

export default InventoryTotalDetailsForm
