import styled, { StyledProps } from 'styled-components'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'

type StatusProps = StyledProps<{ status: ReturnDeclarationStatus }>
const ReturnDeclarationStatusColored = styled.span<StatusProps>`
  color: ${({ status, theme }: StatusProps) => {
    switch (status) {
      case ReturnDeclarationStatus.Submitted:
        return theme.color.primary700
      case ReturnDeclarationStatus.Deleted:
        return theme.color.accentDestructive
      case ReturnDeclarationStatus.NothingToDeclare:
        return theme.color.accentNotify
      case ReturnDeclarationStatus.New:
        return theme.color.primary600
    }
  }};
`

export default ReturnDeclarationStatusColored
