import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import RetailerAllocationActions from './duck'
import RetailerAllocationSelectors from './selectors'

export const useRetailerAllocationById = createItemByIdHook(
  RetailerAllocationSelectors,
  RetailerAllocationActions.itemRequested,
)

export const useRetailerAllocationList = createItemListOnceHook(
  RetailerAllocationSelectors,
  RetailerAllocationActions.listRequested,
)
