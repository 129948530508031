enum StorefrontRoutes {
  List = '/storefront',
  CardsList = '/storefront/cards',
  CardsEdit = '/storefront/cards/edit/:id',
  CardsAdd = '/storefront/cards/add',
  PackageTypesList = '/storefront/package-types',
  PackageTypesEdit = '/storefront/package-types/edit/:id',
  PackageTypesAdd = '/storefront/package-types/add',
  ProductsList = '/storefront/products',
  ProductsEdit = '/storefront/products/edit/:id',
  ProductsAdd = '/storefront/products/add',
  OptionsList = '/storefront/products-options',
  OptionsEdit = '/storefront/products-options/edit/:id',
  OptionsAdd = '/storefront/products-options/add',
}

export default StorefrontRoutes
