import React from 'react'
import { useDocument, useDocumentFileUrl, useSignedDocumentUrl } from 'modules/domain/document/hooks'
import { DocumentFileState, DocumentItemStatus } from 'modules/domain/document/types'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import * as Styled from './styled'
import PdfViewer from 'views/ui/PdfViewer/PdfViewer'
import DocumentActions from 'modules/domain/document/duck'
import { useSelector } from 'react-redux'
import DocumentSelectors from 'modules/domain/document/selectors'
import { Button, Modal, useAction } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const ModalContent: React.FC<{ docDescriptor: DocumentFileState }> = ({ docDescriptor }) => {
  const { t } = useTranslation('farmerOrder')
  if (docDescriptor.progress === Progress.IDLE) {
    return null
  } else if (docDescriptor.progress === Progress.WORK) {
    return <SpinnerLayout />
  } else if (docDescriptor.progress !== Progress.SUCCESS) {
    return <Styled.ErrorText>{t('documentPreviewError')}</Styled.ErrorText>
  } else {
    return <PdfViewer url={docDescriptor.url} />
  }
}
const SignedDocumentModalContent: React.FC<{ userId: string; documentId: string }> = ({ userId, documentId }) => {
  const docDescriptor = useSignedDocumentUrl(documentId, userId)
  return <ModalContent docDescriptor={docDescriptor} />
}
const DocumentModalContent: React.FC<{ documentId: string }> = ({ documentId }) => {
  const docDescriptor = useDocumentFileUrl(documentId)
  return <ModalContent docDescriptor={docDescriptor} />
}

const FarmerDocumentPreviewModal: React.FC<{
  documentId: string
  status: DocumentItemStatus
  isOpen: boolean
  onClose(): void
  userId?: string
}> = ({ documentId, status, userId, isOpen, onClose }) => {
  const [, doc] = useDocument(documentId)
  const downloadAction = useAction(DocumentActions.documentDownloadRequested)
  const meta = useSelector(state => DocumentSelectors.meta(state, documentId, userId))
  const { t } = useTranslation('farmerOrder')

  const handleDownloadClick = () => {
    downloadAction({ documentId, userId, fileName: doc?.name })
  }

  let content
  if (userId && status === 'signed') {
    content = <SignedDocumentModalContent userId={userId} documentId={documentId} />
  } else {
    content = <DocumentModalContent documentId={documentId} />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'large'}>
      <Button
        progress={meta.downloadProgress}
        intent={'primary'}
        filled
        onClick={handleDownloadClick}
        style={{ alignSelf: 'flex-end', marginBottom: '8px' }}
      >
        {t('downloadDocument')}
      </Button>
      <Styled.DocumentModalInner>{content}</Styled.DocumentModalInner>
    </Modal>
  )
}

export default FarmerDocumentPreviewModal
