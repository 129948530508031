import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  ReconciliationSku,
  ReconciliationDTO,
  ReconciliationList,
  ReconciliationGroupComment,
  ReconciliationGroupFilter,
  ReconciliationGroupSorting,
  ReconciliationState,
  ReconciliationRetailerStatusUpdateDTO,
  ReconciliationSkuAddDTO,
  ReconciliationSkuAddResponse,
} from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { FetchError, UpdateError } from 'modules/domain/types'

const initialState: ReconciliationState = {
  ids: [],

  groups: {},
  groupsFetchProgress: Progress.IDLE,
  groupsFetchError: null,
  groupsFetchErrorDetail: '',

  groupsFetchNextProgress: Progress.IDLE,
  groupsFetchNextErrorDetail: '',
  groupsFetchNextError: null,

  groupsFilter: {},
  groupsSorting: {},
  groupsPage: 1,
  groupsTotal: 0,
  groupsPageSize: 10,

  saveTargetGroupCommentProgress: Progress.IDLE,
  saveTargetGroupCommentProgressError: null,
  saveTargetGroupCommentProgressErrorDetail: '',

  batchUpdateProgress: Progress.IDLE,
  batchUpdateError: null,

  retailerStatusUpdateProgress: Progress.IDLE,
  retailerStatusUpdateError: null,
  retailerStatusUpdateErrorDetail: '',

  updateSkuProgress: Progress.IDLE,
  updateSkuError: null,
  updateSkuErrorDetail: '',
}

class ReconciliationReducer extends ImmerReducer<ReconciliationState> {
  groupsRequested(params: { filter?: ReconciliationGroupFilter; sorting?: ReconciliationGroupSorting; page?: number }) {
    this.draftState.groupsFetchProgress = Progress.WORK
    this.draftState.groupsFetchErrorDetail = undefined
    this.draftState.groupsFetchError = null
    this.draftState.groupsFilter = params.filter || this.draftState.groupsFilter
    this.draftState.groupsSorting = params.sorting || this.draftState.groupsSorting
    this.draftState.groupsPage = typeof params.page === 'undefined' ? this.draftState.groupsPage : params.page
  }

  groupsRequestSucceed(list: ReconciliationList[], total: number, page: number) {
    this.draftState.groupsFetchProgress = Progress.SUCCESS
    this.draftState.groupsFetchErrorDetail = undefined
    this.draftState.groups = arrToDict(list)
    this.draftState.ids = getIds(list)
    this.draftState.groupsTotal = total
    this.draftState.groupsPage = page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  groupsRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.groupsFetchProgress = Progress.ERROR
    this.draftState.groupsFetchError = error
    this.draftState.groupsFetchErrorDetail = errorDetail
  }

  groupsRequestedNext(page: number) {
    this.draftState.groupsPage = page
    this.draftState.groupsFetchNextProgress = Progress.WORK
    this.draftState.groupsFetchNextErrorDetail = undefined
  }

  groupsRequestNextSucceed(list: ReconciliationList[], total: number) {
    this.draftState.groupsFetchNextProgress = Progress.SUCCESS
    this.draftState.groupsFetchNextErrorDetail = undefined
    this.draftState.groupsTotal = total
    this.draftState.groups = arrToDict(list)
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  groupsRequestNextFailed(error: FetchError, errorDetail?: string) {
    this.draftState.groupsFetchNextProgress = Progress.ERROR
    this.draftState.groupsFetchNextError = error
    this.draftState.groupsFetchNextErrorDetail = errorDetail
  }

  groupsFilterUpdated(filter: ReconciliationGroupFilter) {
    this.draftState.groupsFilter = filter
    this.draftState.groupsFetchProgress = Progress.WORK
  }

  batchUpdateRequested(_dtos: ReconciliationDTO[]) {
    this.draftState.batchUpdateProgress = Progress.WORK
  }
  batchUpdateSucceed(items: ReconciliationSku[], groupId: string) {
    this.draftState.batchUpdateProgress = Progress.SUCCESS
    items.forEach((item: ReconciliationSku) => {
      const indexSku = this.draftState.groups[groupId].sku.findIndex(s => s.id === item.id)
      this.draftState.groups[groupId].sku[indexSku] = item
    })
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  batchUpdateError(error: UpdateError, errorDetail?: string) {
    this.draftState.batchUpdateProgress = Progress.ERROR
    this.draftState.batchUpdateError = error
    this.draftState.batchUpdateErrorDetail = errorDetail
  }

  filterUpdated(filter: ReconciliationGroupFilter) {
    this.draftState.groupsFilter = filter
    this.draftState.groupsFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: ReconciliationGroupFilter) {
    this.draftState.groupsFilter = { ...this.draftState.groupsFilter, ...filter }
  }

  sortingUpdated(sorting: ReconciliationGroupSorting) {
    this.draftState.groupsSorting = sorting
    this.draftState.groupsFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.groupsFilter = {}
    this.draftState.groupsFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.groupsFilter = {}
    this.draftState.groupsFetchProgress = Progress.WORK
  }

  saveTargetGroupComment({ key, groupComment }: ReconciliationGroupComment) {
    this.draftState.saveTargetGroupCommentProgress = Progress.WORK
    const id = `${key.manufacturer_id}-${key.retailer_id}`
    this.draftState.groups[id].retailer.comment = groupComment || ''
  }

  updateTargetGroupComment({
    key,
    comment,
  }: {
    key: {
      retailer_id: string
      manufacturer_id: string
    }
    comment: string
  }) {
    const id = `${key.manufacturer_id}-${key.retailer_id}`
    this.draftState.groups[id].retailer.comment = comment
    this.draftState.saveTargetGroupCommentProgress = Progress.WORK
    this.draftState.saveTargetGroupCommentProgressErrorDetail = undefined
  }

  updateTargetGroupCommentSuceed({
    key,
    comment,
  }: {
    key: {
      retailer_id: string
      manufacturer_id: string
    }
    comment: string
  }) {
    this.draftState.saveTargetGroupCommentProgress = Progress.SUCCESS
    this.draftState.saveTargetGroupCommentProgressErrorDetail = undefined
    const id = `${key.manufacturer_id}-${key.retailer_id}`
    this.draftState.groups[id].retailer.comment = comment
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateTargetGroupCommentFailed(error: UpdateError, errorDetail?: string) {
    this.draftState.saveTargetGroupCommentProgress = Progress.ERROR
    this.draftState.saveTargetGroupCommentProgressError = error
    this.draftState.saveTargetGroupCommentProgressErrorDetail = errorDetail
  }

  retailerStatusUpdateRequested(_dto: ReconciliationRetailerStatusUpdateDTO) {
    this.draftState.retailerStatusUpdateProgress = Progress.WORK
  }
  retailerStatusUpdateSucceed() {
    this.draftState.retailerStatusUpdateProgress = Progress.SUCCESS
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  retailerStatusUpdateError(error: UpdateError, errorDetail?: string) {
    this.draftState.retailerStatusUpdateProgress = Progress.ERROR
    this.draftState.retailerStatusUpdateError = error
    this.draftState.retailerStatusUpdateErrorDetail = errorDetail
  }

  updateSkuRequested(_dto: ReconciliationSkuAddDTO) {
    this.draftState.updateSkuProgress = Progress.WORK
  }
  updateSkuSucceed(sku: ReconciliationSkuAddResponse, groupId: string) {
    this.draftState.updateSkuProgress = Progress.SUCCESS

    if (sku.is_forced) {
      this.draftState.groups[groupId].sku.unshift(sku as ReconciliationSku)
    } else {
      const idx = this.draftState.groups[groupId].sku.findIndex(s => s.id === sku.id)
      this.draftState.groups[groupId].sku.splice(idx, 1)
    }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateSkuError(error: UpdateError, errorDetail?: string) {
    this.draftState.updateSkuProgress = Progress.ERROR
    this.draftState.updateSkuError = error
    this.draftState.updateSkuErrorDetail = errorDetail
  }
}

export const ReconciliationActions = createActionCreators(ReconciliationReducer)
export default ReconciliationActions
export const reducer = createReducerFunction(ReconciliationReducer, initialState)
