import React, { useCallback, useState } from 'react'
import { CellProps, useRowSelect, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Styled from 'views/pages/Product/ProductList/styles'
import StatusFilter from 'views/components/TableFilters/StatusFilter'
import ProductSettingsSelectors from 'modules/domain/productSettings/selectors'
import { useProductSettingsList } from 'modules/domain/productSettings/hooks'
import {
  AvailablilityColorsLevel,
  ProductSettings,
  ProductSettingsListRequestFilter,
} from 'modules/domain/productSettings/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'

import {
  AdvancedHeadCell,
  Table,
  TableHeadRow,
  TableHead,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  Button,
  SearchInput as UISearchInput,
} from '@agro-club/frontend-shared'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyType } from 'types/entities'
import AvailabilityColorSelect from 'views/pages/ProductSettings/AvailabilityColorSelect'
import ProductSettingsActions from 'modules/domain/productSettings/duck'
import styled from 'styled-components'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import AddToFarmerCartModal, { AddToCartModalState } from 'views/pages/ProductSettings/AddToFarmerCartModal'

const IdColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('cards')
  return (
    <div>
      <Styled.DefaultStatus />
      <span>{t('list.tableHeaders.id')}</span>
    </div>
  )
}

const IdCell: React.FC<CellProps<ProductSettings>> = ({ cell, column, row }) => {
  return (
    <Styled.IdCell key={column.id}>
      <Styled.Status status={row.values.status} />
      {`${cell.value || ''}`}
    </Styled.IdCell>
  )
}

const TitleCell: React.FC<CellProps<ProductSettings>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  return <TComponents.SemiBoldText key={column.id}>{pick(cell.value)}</TComponents.SemiBoldText>
}

const SellerCell: React.FC<CellProps<ProductSettings>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value || ''}</TComponents.SemiBoldText>
}

const CategoryCell: React.FC<CellProps<ProductSettings>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  return <TComponents.SemiBoldText key={column.id}>{pick(cell.value?.title_i18n)}</TComponents.SemiBoldText>
}

const AvailabilityCell: React.FC<CellProps<ProductSettings>> = ({ cell, column }) => {
  const updateCard = useAction(ProductSettingsActions.updateRequested)

  const handleChange = (id: AvailablilityColorsLevel) => {
    updateCard(cell.row.original.id, {
      availability: id,
    })
  }

  return (
    <AvailabilityColorSelect
      key={column.id}
      defaultValue={cell.value}
      onChange={handleChange}
      testId={`availability-color-select-${cell.row.id}`}
    />
  )
}

const SortableHeadCell = AdvancedHeadCell<keyof ProductSettings>()

const AddToFarmerCartCell: React.FC<CellProps<ProductSettings> & {
  onAddToFarmerCartClick: (state: AddToCartModalState) => void
}> = ({ row, onAddToFarmerCartClick }) => {
  const { t } = useTranslation(['cards'])

  return (
    <Button
      onClick={() =>
        onAddToFarmerCartClick({
          isOpen: true,
          card: row.original,
          sellerId: row.original.seller_id,
        })
      }
      intent="primary"
    >
      {t('addToFarmerCart')}
    </Button>
  )
}

export const SearchInput = styled(UISearchInput)`
  width: 496px;
  margin-bottom: 32px;
  border: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`

export const Spinner = styled(SpinnerLayout)`
  margin: 50px auto;
`

const ProductSettingsList = () => {
  const { t } = useTranslation(['cards', 'common', 'farmer'])
  const [addToCartModalState, setAddToCartModalState] = useState<AddToCartModalState>({ isOpen: false })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: IdColumnHeaderCell,
        accessor: 'id' as const,
        Cell: IdCell,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title_i18n' as const,
        Cell: TitleCell,
      },
      {
        Header: t('list.tableHeaders.seller'),
        accessor: 'seller_internal_name' as const,
        Cell: SellerCell,
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'category' as const,
        Cell: CategoryCell,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'subcategory' as const,
        Cell: CategoryCell,
      },
      {
        Header: t('list.tableHeaders.addToCart'),
        Cell: props => <AddToFarmerCartCell onAddToFarmerCartClick={setAddToCartModalState} {...props} />,
      },
      {
        Header: t('list.tableHeaders.availability'),
        accessor: 'availability' as const,
        Cell: AvailabilityCell,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof ProductSettings)[] = React.useMemo(() => ['status'], [])

  const columnsAll = React.useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])
  const [progress, data = []] = useProductSettingsList()
  const { columns, rows, prepareRow } = useTable<ProductSettings>(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      columns: columnsAll,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(ProductSettingsActions.filterUpdated)
  const filterCleared = useAction(ProductSettingsActions.filterHasBeenReset)
  const sortingUpdated = useAction(ProductSettingsActions.sortingUpdated)
  const listRequested = useAction(ProductSettingsActions.listRequested)

  const filterValue = useSelector(ProductSettingsSelectors.filter)
  const page = useSelector(ProductSettingsSelectors.page)
  const total = useSelector(ProductSettingsSelectors.total)
  const pageSize = useSelector(ProductSettingsSelectors.pageSize)
  const pages = useSelector(ProductSettingsSelectors.pages)
  const { sort_field, sort_reversed } = useSelector(ProductSettingsSelectors.sorting)

  const { status, seller_id } = filterValue

  const handleFilterChange = (newFilterValue: Partial<ProductSettingsListRequestFilter>) => {
    filterUpdated({ ...filterValue, ...newFilterValue })
  }

  const handleModalClose = () => setAddToCartModalState({ isOpen: false })

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('productSettingsList')

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <StatusFilter filterValue={{ status }} handleFilterChange={handleFilterChange} data-test-id="status-filter" />
        <Filter title={t('SellerSelect:label')}>
          <CompanySelect
            companyType={CompanyType.Producer}
            onChange={val => handleFilterChange({ seller_id: val as string })}
            value={seller_id}
            variant="small"
            isClearable
            filter={{ is_seller: true }}
            isSearchable
            width="200px"
            placeholder={t('SellerSelect:placeholder')}
            data-test-id="seller-select"
          />
        </Filter>
        <Styled.Spacer />
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof ProductSettings}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow key={key} {...props} selected={row.isSelected} data-test-id="product-settings-table-row">
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'selection'}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={() => filterCleared()}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
          <AddToFarmerCartModal modalState={addToCartModalState} onClose={handleModalClose} />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default ProductSettingsList
