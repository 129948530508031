import styled, { css, keyframes, StyledProps } from 'styled-components'
import { IconCrossCircle, Input, SectionContainer, SimpleSelect } from '@agro-club/frontend-shared'

const iconCss = css`
  cursor: pointer;
  fill: ${props => props.theme.color.secondary200};
  vertical-align: middle;
  &:hover {
    opacity: 0.7;
  }
`

export const RemoveIcon = styled(IconCrossCircle)`
  ${iconCss}
`

export const FormattedQty = styled.div`
  margin-top: 8px;
  text-align: center;
`

export const QtyCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const OrderQtyCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-weight: 500;
  padding: 15px 24px 0 0;
`

export const Container = styled(SectionContainer)<{ isAllowed?: boolean }>`
  min-width: ${props => props.theme.size.minOrderListWidth}px;
  width: fit-content;
  transition: 0.3s all;
  position: relative;
  ${(props: { isAllowed?: boolean }) =>
    props.isAllowed
      ? ''
      : css`
          opacity: 0.8;
          cursor: not-allowed;
        `}
`

export const Content = styled.div<{ isAllowed: boolean }>`
  pointer-events: ${(props: { isAllowed: boolean }) => (props.isAllowed ? 'auto' : 'none')};
`

export const SeedTreatmentSelect = styled(SimpleSelect)`
  min-width: 120px;
  max-height: 32px;
`

export const SeedTreatment = styled.div``

export const CellWrapper = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Label = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 4px;
`

export const PackagingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const PackagingSwitchWrapper = styled.div`
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  white-space: nowrap;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }

  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
`

export const PackagingSwitchContainer = styled.div`
  max-width: 280px;
`

export const PackagingHint = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  margin-bottom: 4px;
`

export const PackagingInputWrapper = styled.div<StyledProps<{ show: boolean }>>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
`

export const PackagingLabel = styled.span<StyledProps<{}>>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};
  margin-right: 8px;
`

export const PackagingInput = styled(Input)`
  max-width: 64px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.onPrimaryLight};
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;

  input {
    height: 32px;
  }
`

export const IdContainer = styled.div`
  font-size: 12px;
`

export const Unavailable = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};
`

export const TotalWrap = styled.div`
  margin-left: auto;
  & > :last-child {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`

export const Total = styled.div`
  text-align: right;
  width: 100%;
`

export const TotalValue = styled.span`
  font-weight: bold;
`

export const ContractWarning = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 4px;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  &:hover {
    color: ${props => props.theme.color.primary600};
  }
  > svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    fill: ${props => props.theme.color.accentNotify};
  }
`

export const Warning = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 4px;
  cursor: default;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};

  > svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.color.accentNotify};
  }
`

export const LotInputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;

  & > :first-child {
    min-width: 50px;
    margin-right: 8px;
  }
`

export const ProductOptionsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`

export const AddProductError = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.color.accentDestructive};
`

export const CardTitle = styled.strong``

export const PackageType = styled.div`
  margin-top: 6px;
`

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export const ProductOption = styled.div`
  line-height: 20px;
  font-size: 14px;
`

export const OutOfStock = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.color.accentDestructive};
`

export const UnitsContainer = styled.div`
  text-align: center;
`

export const SmallText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.secondary300};
`

export const MaxQtyHint = styled.div`
  margin-top: 12px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  min-height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`
