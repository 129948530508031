import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import InventoryTotalList from './InventoryList'
import InventoryHeader from '../InventoryHeader'

const InventoryTotalTable: React.FC = () => {
  const { t } = useTranslation('inventoryTransferRequest')

  useHelmet({ title: t('inventoryMetaTitle') })

  return (
    <>
      <InventoryHeader activeTab="Total" />
      <Layout.Content>
        <InventoryTotalList />
      </Layout.Content>
    </>
  )
}

export default InventoryTotalTable
