import { useAction } from '@agro-club/frontend-shared'
import SnackbarActions from 'modules/domain/snackbar/duck'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

function useErrorNotification(errorDetail?: string | null) {
  const { t } = useTranslation('ValidationErrorNotification')
  const openSnackbar = useAction(SnackbarActions.open)
  const closeSnackbar = useAction(SnackbarActions.close)

  useEffect(() => {
    if (errorDetail) {
      openSnackbar({
        content: errorDetail || t('text'),
      })
    } else {
      closeSnackbar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDetail])
}

export default useErrorNotification
