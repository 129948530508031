import React from 'react'
import styled, { css, StyledProps } from 'styled-components'
import ProfileButton from 'views/components/ProfileButton/ProfileButton'
import { BackBtn } from '@agro-club/frontend-shared'

type Size = 'small' | 'large'

const RootStyled = styled.header`
  position: relative;
  display: flex;
  min-height: 112px;
  padding-left: 36px;
`

type TitleStyledProps = StyledProps<{ compact?: boolean }>

const TitleContainerStyled = styled.div<TitleStyledProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ compact }: TitleStyledProps) => (compact ? '10px' : '0px')};
`

const TitleStyled = styled.div<TitleStyledProps>`
  color: ${({ theme }: TitleStyledProps) => theme.color.onPrimaryDark};
  margin-right: 16px;

  ${({ compact }: TitleStyledProps) => ({
    fontSize: compact ? '18px' : '28px',
    fontWeight: compact ? 600 : 700,
    lineHeight: compact ? '28px' : '44px',
  })};
`

export const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

export const Title: React.FC<{
  title: string
  size?: Size
  compact?: boolean
  isActive?: boolean
  className?: string
  onClick?: () => void
}> = ({ children, title, compact, className, isActive, onClick }) => {
  return (
    <TitleContainerStyled className={className} onClick={() => !isActive && onClick && onClick()}>
      <TitleStyled compact={compact} data-test-id={title.toLowerCase().replace(' ', '_') + '_tab_test_id'}>
        {title}
      </TitleStyled>
      {children}
    </TitleContainerStyled>
  )
}

type HeaderProps = {
  onClickBack?: () => void
}

const InfoBlock = styled.div`
  margin-left: auto;
`

type ContentWrapperProps = StyledProps<{ withBackBtn?: boolean }>

const ContentWrapper = styled.div`
  z-index: 3;
  flex-grow: 1;
  padding: ${({ withBackBtn }: ContentWrapperProps) => (withBackBtn ? '18px 0' : '20px 0 14px 0')};
`

const SmallContentWrapper = styled(ContentWrapper)`
  padding: ${({ withBackBtn }: ContentWrapperProps) => (withBackBtn ? '18px 0 0 0' : '20px 0 0 0')};
`

export const Root: React.FC<HeaderProps> = ({ onClickBack, children }) => (
  <RootStyled>
    <ContentWrapper withBackBtn={!!onClickBack}>
      {!!onClickBack && <BackBtn onClick={onClickBack} />}
      {children}
    </ContentWrapper>
    <InfoBlock>
      <ProfileButton />
    </InfoBlock>
  </RootStyled>
)

export const RootWithSmallTabs: React.FC<HeaderProps> = ({ onClickBack, children }) => (
  <RootStyled>
    <SmallContentWrapper withBackBtn={!!onClickBack}>
      {!!onClickBack && <BackBtn onClick={onClickBack} />}
      {children}
    </SmallContentWrapper>
    <InfoBlock>
      <ProfileButton />
    </InfoBlock>
  </RootStyled>
)

export const TabsContainer = styled(RootStyled)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.outlineMid};
  padding-left: 24px;
  min-height: 81px;
`

export const SmallTabsContainer = styled.div`
  display: flex;
  margin-top: 21px;
`

export const TabsRoot: React.FC = ({ children }) => {
  return (
    <TabsContainer>
      {children}
      <InfoBlock>
        <ProfileButton />
      </InfoBlock>
    </TabsContainer>
  )
}

export const Tab = styled(Title)`
  margin-right: 48px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  opacity: 0.5;

  ${({ theme, isActive }) =>
    isActive &&
    css`
      border-color: ${theme.color.primary600};
      opacity: 1;
      cursor: auto;
    `}
`

export const SmallTab = styled.h5<{ isActive?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 8px;

  margin-right: 24px;

  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${({ theme, isActive }) =>
    isActive &&
    css`
      border-color: ${theme.color.primary600};
      color: ${theme.color.primary600};
      cursor: auto;
    `}
`
