import React, { memo, useCallback, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikHook, Input, TextArea } from '@agro-club/frontend-shared'
import * as Styled from './styled'

export type AboutFormProps = {
  title: string
  description: string
  discountText?: string
}

const AboutForm: React.FC<{
  lang: keyof LocalizedValue
  title?: LocalizedValue
  description?: LocalizedValue
  discountText?: LocalizedValue
  useFormik: FormikHook
  onTitleChange?: (val: string) => void
}> = memo(({ lang, useFormik, title = {}, description = {}, discountText = {}, onTitleChange }) => {
  const { t } = useTranslation(['category', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().required(t('validation:field_required')),
        discountText: Yup.string(),
      }),
    [t],
  )
  const formik = useFormik<AboutFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      title: title[lang] || '',
      description: description[lang] || '',
      discountText: discountText[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })

  const handleTitleChange = useCallback(
    e => {
      formik.setFieldValue('title', e.target.value)
      onTitleChange && onTitleChange(e.target.value)
    },
    [onTitleChange, formik],
  )

  return (
    <Styled.VerticalInputsContainer>
      <Input
        {...formik.getFieldProps('title')}
        invalid={formik.touched.title && !!formik.errors.title}
        errorText={formik.errors.title}
        label={t('form.labels.title', { lang })}
        onChange={handleTitleChange}
        data-test-id={`title-${lang}`}
        required
      />
      <TextArea
        {...formik.getFieldProps('description')}
        invalid={formik.touched.description && !!formik.errors.description}
        errorText={formik.errors.description}
        label={t('form.labels.description', { lang })}
        limit={1000}
        data-test-id={`description-${lang}`}
      />
      <Input
        {...formik.getFieldProps('discountText')}
        invalid={formik.touched.discountText && !!formik.errors.discountText}
        errorText={formik.errors.discountText}
        label={t('form.labels.discountText', { lang })}
        data-test-id={`discountText-${lang}`}
        limit={30}
      />
    </Styled.VerticalInputsContainer>
  )
})
AboutForm.displayName = 'AboutForm'

export default AboutForm
