import { DragItemType, DragItem } from 'modules/domain/storefront/types'
import React, { useContext, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const notImplementedFn = () => {
  throw new Error('Not implemented.')
}

export const StorefrontListContext = React.createContext<{
  moveRow: (dragIndex: number, hoverIndex: number) => void
  findIndex: (id: string) => number
}>({ moveRow: notImplementedFn, findIndex: notImplementedFn })

export const useBundleItemDrag = (id: string) => {
  const ref = useRef<HTMLDivElement>(null)
  const { moveRow, findIndex } = useContext(StorefrontListContext)

  const [collectedDragProps, dragRef] = useDrag({
    type: DragItemType.BundleItem,
    item: () => ({ id, type: DragItemType.BundleItem }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [collectedDropProps, dropRef] = useDrop({
    accept: [DragItemType.Product, DragItemType.BundleItem],
    canDrop: () => false,
    hover: (item: { id: string }, monitor) => {
      if (!ref.current) {
        return
      }

      if (monitor.getItemType() !== DragItemType.BundleItem) {
        return
      }

      if (item.id !== id) {
        const indices = [findIndex(item.id), findIndex(id)] as const
        if (indices.some(i => i === -1)) {
          throw new Error("Couldn't find bundle row item.")
        }
        moveRow(...indices)
      }
    },
    collect: _monitor => {
      return {}
    },
  })

  dragRef(dropRef(ref))

  return [
    ref,
    {
      ...collectedDragProps,
      ...collectedDropProps,
    },
  ] as const
}

export const useItemDrag = (itemType: DragItemType, id: string) => {
  const ref = useRef<HTMLDivElement>(null)
  const { moveRow, findIndex } = useContext(StorefrontListContext)

  const [collectedDragProps, dragRef] = useDrag({
    type: itemType,
    item: () => ({ id, type: itemType }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [collectedDropProps, dropRef] = useDrop({
    accept: [DragItemType.Product, DragItemType.Bundle],
    canDrop: () => false,
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return
      }

      if (item.id !== id) {
        const indices = [findIndex(item.id), findIndex(id)] as const
        if (indices.some(i => i === -1)) {
          throw new Error("Couldn't find row item.")
        }

        if (itemType === DragItemType.Product) {
          moveRow(...indices)
        }

        if (itemType === DragItemType.Bundle) {
          const [dragIndex, hoverIndex] = indices
          const hoverRect = ref.current.getBoundingClientRect()
          const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 }
          const y = clientOffset?.y - hoverRect.top

          // for better user experience if we hover over the Bundle element
          // swap elements only on the top/bottom edge of Bundle element
          const DELTA_PX = 40
          if (dragIndex < hoverIndex && hoverRect.height - DELTA_PX > y) {
            return
          }
          if (dragIndex > hoverIndex && DELTA_PX < y) {
            return
          }
          moveRow(...indices)
        }
      }
    },
    collect: _monitor => {
      return {}
    },
  })

  dragRef(dropRef(ref))

  return [
    ref,
    {
      ...collectedDragProps,
      ...collectedDropProps,
    },
  ] as const
}
