import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { ListResponse } from 'types/api'
import {
  RetailerTarget,
  RetailerTargetCreateDTO,
  RetailerTargetState,
  TargetListRequestFilter,
  TargetListRequestSorting,
  TargetUpdateDTO,
} from '../common/types'

const initialState: RetailerTargetState = {
  ...BasicEntityState,
  updateCounter: 0,
  totalFarmerOrders: 0,
  totalFarmerOrdersPreviousYear: 0,
  totalTargetValues: 0,
  totalRetailerOrders: 0,
}

type RetailerMeta = {
  total_target_values: number
  total_farmer_orders: number
  total_farmer_orders_previous_year: number
  total_retailer_orders: number
}
class RetailerTargetReducer extends BasicEntityImmerReducer<
  RetailerTarget,
  TargetListRequestFilter,
  TargetListRequestSorting,
  RetailerTargetCreateDTO,
  TargetUpdateDTO,
  RetailerTargetState
> {
  updateOccurred() {
    this.draftState.updateCounter += 1
  }
  listRequestSucceed(response: ListResponse<RetailerTarget, RetailerMeta>) {
    super.listRequestSucceed(response)
    const { total_target_values, total_farmer_orders, total_farmer_orders_previous_year, total_retailer_orders } =
      response.meta || {}
    this.draftState.totalTargetValues = total_target_values || 0
    this.draftState.totalFarmerOrders = total_farmer_orders || 0
    this.draftState.totalFarmerOrdersPreviousYear = total_farmer_orders_previous_year || 0
    this.draftState.totalRetailerOrders = total_retailer_orders || 0
  }
}

export const RetailerTargetActions = createActionCreators(RetailerTargetReducer)
export default RetailerTargetActions
export const reducer = createReducerFunction(RetailerTargetReducer, initialState)
