import React, { memo, useCallback, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikHook, Input, TextArea } from '@agro-club/frontend-shared'
import * as Styled from './styled'

export type AboutFormProps = {
  title: string
  message: string
}

const AboutForm: React.FC<{
  lang: keyof LocalizedValue
  title?: LocalizedValue
  message?: LocalizedValue
  useFormik: FormikHook
  onTitleChange?: (val: string) => void
}> = memo(({ lang, useFormik, title = {}, message = {}, onTitleChange }) => {
  const { t } = useTranslation(['report', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().required(t('validation:field_required')),
        message: Yup.string(),
      }),
    [t],
  )
  const formik = useFormik<AboutFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      title: title[lang] || '',
      message: message[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })

  const handleTitleChange = useCallback(
    e => {
      formik.setFieldValue('title', e.target.value)
      onTitleChange && onTitleChange(e.target.value)
    },
    [onTitleChange, formik],
  )

  return (
    <Styled.VerticalInputsContainer>
      <Input
        {...formik.getFieldProps('title')}
        invalid={formik.touched.title && !!formik.errors.title}
        errorText={formik.errors.title}
        label={t('form.labels.title', { lang: `(${lang})` })}
        onChange={handleTitleChange}
        data-lang={lang}
        required
      />
      <TextArea
        {...formik.getFieldProps('message')}
        invalid={formik.touched.message && !!formik.errors.message}
        errorText={formik.errors.message}
        label={t('form.labels.message', { lang: `(${lang})` })}
        placeholder={t('form.placeholders.message')}
        data-lang={lang}
      />
    </Styled.VerticalInputsContainer>
  )
})
AboutForm.displayName = 'AboutForm'

export default AboutForm
