import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { FarmerOrderSku } from 'types/farmerOrderSku'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.farmerOrderSku.items
const ids = (state: AppGlobalState) => state.farmerOrderSku.ids
const item = (state: AppGlobalState, id: string): FarmerOrderSku | undefined => state.farmerOrderSku.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<FarmerOrderSku> =>
  state.farmerOrderSku.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.farmerOrderSku.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.farmerOrderSku.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.farmerOrderSku.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.farmerOrderSku.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.farmerOrderSku.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: FarmerOrderSku[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.farmerOrderSku.filter
const sorting = (state: AppGlobalState) => state.farmerOrderSku.sorting
const page = (state: AppGlobalState) => state.farmerOrderSku.page
const total = (state: AppGlobalState) => state.farmerOrderSku.total
const totalQty = (state: AppGlobalState) => state.farmerOrderSku.totalQty
const totalDeliveredQty = (state: AppGlobalState) => state.farmerOrderSku.totalDeliveredQty
const totalStandardQty = (state: AppGlobalState) => state.farmerOrderSku.totalStandardQty
const totalFinalQty = (state: AppGlobalState) => state.farmerOrderSku.totalFinalQty
const updateProgress = (state: AppGlobalState) => state.farmerOrderSku.updateProgress
const updateError = (state: AppGlobalState) => state.farmerOrderSku.updateError
const removeProgress = (state: AppGlobalState) => state.farmerOrderSku.removeProgress
const addProgress = (state: AppGlobalState) => state.farmerOrderSku.addProgress
const hasNext = (state: AppGlobalState) => state.farmerOrderSku.total > state.farmerOrderSku.ids.length
const pageSize = (state: AppGlobalState) => state.farmerOrderSku.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.farmerOrderSku.total / state.farmerOrderSku.pageSize)
const newOrdersCount = (state: AppGlobalState) => state.farmerOrderSku.newOrdersCount
const newOrdersCountProgress = (state: AppGlobalState) => state.farmerOrderSku.newOrdersCountProgress

const FarmerOrderSkuSelectors = {
  filter,
  sorting,
  page,
  total,
  totalQty,
  totalDeliveredQty,
  totalFinalQty,
  totalStandardQty,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  newOrdersCount,
  newOrdersCountProgress,
  updateError,
}

export default FarmerOrderSkuSelectors
