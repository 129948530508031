import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

import { IconCross } from '@agro-club/frontend-shared'

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 8px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

const ModalStyled = styled(Modal)`
  position: relative;
  min-width: 480px;
  ${({ theme }) => ({
    background: theme.color.onPrimaryLight,
  })}
  border-radius: 8px;
  margin: 24px auto;
  outline: none;
  overflow: visible;
  height: auto;
`

type Props = {
  isOpen: boolean
  onClose: () => void
}

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

const RegionsModal: React.FC<Props> = ({ isOpen, onClose, children }) => (
  <ModalStyled
    style={{
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.48)',
        zIndex: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      content: {
        width: 'auto',
      },
    }}
    isOpen={isOpen}
    onAfterOpen={() => {
      window.document.body.style.overflow = 'hidden'
    }}
    onAfterClose={() => {
      window.document.body.style.overflow = 'unset'
    }}
    onRequestClose={onClose}
  >
    <CloseButton onClick={onClose}>
      <IconCross />
    </CloseButton>
    {children}
  </ModalStyled>
)

export default RegionsModal
