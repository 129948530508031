import { Progress, useDidMount } from '@agro-club/frontend-shared'
import { useState } from 'react'
import { ListResponse } from 'types/api'

export type CertainGetItem<ItemType> = (id: string) => Promise<ItemType>
export type ListGetItem<ItemType> = (ids: string[]) => Promise<ListResponse<ItemType>>
export type ItemsByIdsHook<ItemType> = (ids: string[]) => [Progress, ItemType[]]

export function createItemsByIdsOnceHook<ItemType>(
  isMultipleIdsRequest: true,
  getItem: ListGetItem<ItemType>,
): ItemsByIdsHook<ItemType>
export function createItemsByIdsOnceHook<ItemType>(
  isMultipleIdsRequest: false,
  getItem: CertainGetItem<ItemType>,
): ItemsByIdsHook<ItemType>

/*
 * Creates hook that fetches items by given ids once (on mount)
 * @param isMultipleIdsRequest - is getItem request can fetch array of ids?
 * @param getItem - item(s) request
 * @param dictSelector - optional, selector to dictionary of items that will be fetched, if not in there
 * @returns [Progress, ItemType]
 */
export function createItemsByIdsOnceHook<ItemType>(
  isMultipleIdsRequest: boolean,
  getItem: CertainGetItem<ItemType> | ListGetItem<ItemType>,
) {
  return (ids: string[]) => {
    const [progress, setProgress] = useState(Progress.IDLE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [items, setItems] = useState<ItemType[]>([])

    useDidMount(() => {
      if (ids.length) {
        setProgress(Progress.WORK)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error seems like ts bug because if isMultipleIdsRequest === true getItem arg IS array of strings
        const promise = isMultipleIdsRequest ? getItem(ids) : Promise.all(ids.map(id => getItem(id)))

        promise
          .then(response => setItems(response.data || response))
          .catch(() => {
            setProgress(Progress.ERROR)
          })
          .finally(() => {
            setProgress(Progress.SUCCESS)
          })
      }
    })

    return [progress, items]
  }
}
