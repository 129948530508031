import styled from 'styled-components'
import { Checkbox as CheckboxComponent, BackBtn as BackBtnComponent } from '@agro-club/frontend-shared'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 18px 24px;
  max-height: 600px;
  overflow: auto;
`

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 18px 24px;
  max-height: 600px;
`

export const PhoneFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 240px;
`

export const PhoneInputStyled = styled(PhoneInput)`
  margin-bottom: 20px;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 16px;

  color: ${props => props.theme.color.onPrimaryDark};
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  padding-bottom: 16px;
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Spinner = styled(SpinnerLayout)`
  margin: 50px auto;
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const BackBtn = styled(BackBtnComponent)`
  margin: 0 0 10px 24px;
`

export const Hint = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const ConfirmAccuracyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 480px;

  & > :first-child {
    margin-top: 4px;
  }
`

export const ConfirmAccuracyLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-left: 4px;

  white-space: pre-wrap;
  & > a {
    color: ${({ theme }) => theme.color.primary600};
    text-decoration: none;
  }
`

export const FormContentBody = styled.form`
  display: grid;
  grid-gap: 24px;
  margin-bottom: 24px;
`
