import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import {
  Button,
  Input,
  FormComponents,
  FormField,
  NumberInput as NumberInputComponent,
  SectionBody,
  SectionContainer,
  TextArea,
  SimpleSelect,
  PopoverWithBtns,
} from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CompanyType, isAdmin, isDistributor, isUsersCompanyDistributor, isUsersCompanyProducer } from 'types/entities'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'
import { useStorefrontListPreventable } from 'modules/domain/storefront/hooks'
import { StorefrontSku } from 'modules/domain/storefront/types'
import ConfirmCard, { ConfirmCardProps } from './ConfirmCard'
import { CategorySelectOption } from 'modules/domain/category/types'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'

const FormSection = styled(FormComponents.FormSection)`
  max-width: 400px;
`

const NumberInput = styled(NumberInputComponent)`
  & > input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`

export type InventoryFormFields = {
  producer_id?: string
  seller_id?: string
  retailer_id: string
  product_id: string
  package_id: string
  sku_id: string
  quantity: number
  comment?: string
  category: string
  subcategory: string

  record_type?: string
  owner_name?: string
  created_at?: string
  parent_id?: string
}

export type InventoryFormProps = {
  producer_id?: string
  seller_id?: string
  retailer_id: string
  product_id: string
  package_id: string
  sku_id: string
  quantity: number
  comment?: string
  category: string
  subcategory: string

  record_type?: string
  owner_name?: string
  created_at?: string
  parent_id?: string
}

type Props = {
  initialValues: Partial<InventoryFormFields>
  mode: 'read' | 'create'
  progress?: Progress
  onCancel(): void
  onSubmit(values: InventoryFormFields): void
  onRemove?(): void
}

const InventoryDetailsForm: React.FC<Props> = ({ initialValues, mode, progress, onCancel, onSubmit, onRemove }) => {
  const { t } = useTranslation('inventorySKUTransaction')
  const userCompany = useSelector(AuthSelectors.userCompany)
  const role = useSelector(AuthSelectors.role)

  const [skuFilter, setSkuFilter] = useState({})
  const [confirmData, setConfirmData] = useState<ConfirmCardProps>({})
  const [skuListProgress, skuList = []] = useStorefrontListPreventable(false, skuFilter /*, undefined, 1, 10000*/)

  const packageOptions = useMemo(() => {
    if (!skuList) return []

    const options: { id?: string; title?: string }[] = []
    skuList.forEach(sku => {
      const { params } = sku as StorefrontSku
      const founded = options.find(o => o.id === params.package_id)
      if (!founded) {
        options.push({ id: params?.package_id, title: params?.package_title })
      }
    })

    return options as any
  }, [skuList])

  const getFormatedDate = useDateFormatFn({ withTime: true })

  const initialSellerId =
    initialValues.seller_id ||
    (isUsersCompanyProducer(userCompany) && userCompany?.id) ||
    (isUsersCompanyDistributor(userCompany) &&
      userCompany?.producers_relations?.length &&
      userCompany.producers_relations[0]) ||
    undefined

  const validationSchema = useMemo(() => {
    return Yup.object({
      // producer_id: Yup.string().required(t('validation:field_required')),
      retailer_id: Yup.string().required(t('validation:field_required')),
      product_id: Yup.string().required(t('validation:field_required')),
      package_id: Yup.string().required(t('validation:field_required')),
      sku_id: Yup.string().required(t('validation:field_required')),
      quantity: Yup.number(),
    })
  }, [t])

  const createdAt = getFormatedDate(initialValues.created_at)
  const formik = useFormik<Partial<InventoryFormProps>>({
    initialValues: {
      seller_id: initialSellerId,
      // producer_id: initialValues.producer_id,
      retailer_id: initialValues.retailer_id,
      product_id: initialValues.product_id,
      package_id: initialValues.package_id,
      sku_id: initialValues.sku_id,
      quantity: initialValues.quantity,
      comment: initialValues.comment,
      // TODO will be used in future
      record_type: initialValues.record_type,
      parent_id: initialValues.parent_id,
      owner_name: initialValues.owner_name,
      created_at: createdAt,
      category: initialValues.category,
      subcategory: initialValues.subcategory,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      onSubmit({
        retailer_id: values.retailer_id || '',
        product_id: values.product_id || '',
        package_id: values.package_id || '',
        sku_id: values.sku_id || '',
        quantity: values.quantity || 0,
        comment: values.comment || '',
        category: values.category || '',
        subcategory: values.subcategory || '',
      })
    },
  })

  const skuOptions = useMemo(() => {
    if (!skuList) {
      return []
    }
    return skuList
      .filter(sku => sku.params?.package_id === formik.values.package_id)
      .map(sku => ({ id: sku.id, title: sku.sku_id }))
  }, [formik.values.package_id, skuList])

  useMemo(() => {
    if (formik.values.product_id) {
      setSkuFilter({
        product_id: formik.values.product_id,
      })
    }
  }, [formik.values.product_id])

  useEffect(() => {
    if (isDistributor(role)) {
      formik.setFieldValue('retailer_id', userCompany?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const categoryOnChange = (val?: string | null, category?: CategorySelectOption | null) => {
    setConfirmData({ ...confirmData, category: category?.title })
    formik.setFieldValue('category', val)
    formik.setFieldValue('product_id', undefined)
    formik.setFieldValue('subcategory', undefined)
  }

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  const readOnly = mode === 'read'

  const btnFooterJSX =
    (!readOnly && (
      <StickyFooterLayout.ButtonsFooter>
        <PopoverWithBtns
          onSubmit={formik.handleSubmit}
          heading={t('form.labels.confirmValue')}
          cancelIntent="danger"
          submitIntent="primary"
          bodyContent={<ConfirmCard {...confirmData} />}
          submitText={t('common:add')}
          cancelText={t('common:edit')}
          maxWidth={'500px'}
        >
          <Button
            type={'submit'}
            intent={'primary'}
            filled
            // the button is disabled in edit-mode, because there is no way to edit an existing transaction
            disabled={readOnly || progress === Progress.WORK}
            progress={progress}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
        </PopoverWithBtns>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />
      </StickyFooterLayout.ButtonsFooter>
    )) ||
    (isAdmin(role) && (
      <StickyFooterLayout.ButtonsFooter>
        <StickyFooterDeleteBtn onRemove={onRemove as () => void} popoverText={t('removeTransaction')} />
      </StickyFooterLayout.ButtonsFooter>
    ))
  const qtyLabel = formik.values.package_id
    ? t('form.labels.quantityWithEx', {
        packageType: packageOptions.find(p => p.id === formik.values.package_id)?.title,
      })
    : t('form.labels.quantity')

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <FormSection title={t('form.sectionHeaders.inventoryRecord')}>
              {!readOnly && !initialSellerId && (
                <CompanySelect
                  filter={{
                    is_seller: true,
                  }}
                  companyType={CompanyType.Producer}
                  isClearable
                  isSearchable
                  required
                  label={t('form.labels.producer')}
                  placeholder={t('form.placeholders.producer')}
                  onChange={value => {
                    formik.resetForm()
                    formik.setFieldValue('seller_id', value)
                  }}
                  onMenuClose={() => formik.setFieldTouched('seller_id')}
                  value={formik.values.seller_id}
                  invalid={formik.touched.seller_id && !!formik.errors.seller_id}
                  errorText={formik.errors.seller_id}
                  isDisabled={(!!userCompany && userCompany.company_type === CompanyType.Producer) || readOnly}
                  autoSelectOneValue
                />
              )}
              <CompanySelect
                required
                label={t('form.labels.retailer')}
                onChange={(val, retailer) => {
                  setConfirmData({ ...confirmData, retailer: retailer?.internal_name })
                  formik.setFieldValue('retailer_id', val)
                }}
                onMenuClose={() => formik.setFieldTouched('retailer_id')}
                filter={{
                  for_producer_id: formik.values.seller_id,
                }}
                value={formik.values.retailer_id}
                invalid={formik.touched.retailer_id && !!formik.errors.retailer_id}
                errorText={formik.errors.retailer_id}
                isDisabled={!formik.values.seller_id || readOnly}
                companyType={CompanyType.Distributor}
                isClearable
                isSearchable
              />
              <CategorySelect
                filter={{ seller_ids: formik.values.seller_id }}
                label={t('form.labels.category')}
                onChange={categoryOnChange}
                value={formik.values.category}
                invalid={formik.touched.category && !!formik.errors.category}
                errorText={formik.errors.category}
                isDisabled={!formik.values.seller_id || readOnly}
                onMenuClose={() => formik.setFieldTouched('category')}
              />
              <SubcategorySelect
                filter={{ parent_id: formik.values.category, seller_ids: formik.values.seller_id }}
                label={t('form.labels.subcategory')}
                onChange={(val, subcategory) => {
                  setConfirmData({ ...confirmData, subcategory: subcategory?.title })
                  formik.setFieldValue('subcategory', val)
                }}
                onMenuClose={() => formik.setFieldTouched('subcategory')}
                value={formik.values.subcategory}
                invalid={formik.touched.subcategory && !!formik.errors.subcategory}
                errorText={formik.errors.subcategory}
                isDisabled={!formik.values.category || readOnly}
              />
              <ProductsSelect
                required
                label={t('form.labels.product')}
                onChange={(val, product) => {
                  setConfirmData({ ...confirmData, product: product?.title })
                  formik.setFieldValue('product_id', val)
                }}
                onMenuClose={() => formik.setFieldTouched('product_id')}
                filter={{
                  seller_id: formik.values.seller_id, // producer_id
                  subcategory: formik.values.subcategory || undefined,
                  category: formik.values.category || undefined,
                }}
                value={formik.values.product_id}
                invalid={formik.touched.product_id && !!formik.errors.product_id}
                errorText={formik.errors.product_id}
                isDisabled={!formik.values.seller_id || readOnly}
                isClearable
                isSearchable
              />
              <SimpleSelect
                required
                label={t('form.labels.package')}
                onChange={val => {
                  const packageData = packageOptions.find(item => item?.id === val)
                  setConfirmData({ ...confirmData, packageType: packageData?.title })
                  formik.setFieldValue('package_id', val)
                }}
                onMenuClose={() => formik.setFieldTouched('package_id')}
                isSearchable
                value={formik.values.package_id}
                invalid={formik.touched.package_id && !!formik.errors.package_id}
                errorText={formik.errors.package_id}
                isDisabled={!formik.values.product_id || readOnly}
                options={packageOptions}
                progress={skuListProgress}
              />
              <SimpleSelect
                required
                label={t('form.labels.sku')}
                onChange={(val, sku) => {
                  setConfirmData({ ...confirmData, sku })
                  formik.setFieldValue('sku_id', val)
                }}
                onMenuClose={() => formik.setFieldTouched('sku_id')}
                isSearchable
                value={formik.values.sku_id}
                invalid={formik.touched.sku_id && !!formik.errors.sku_id}
                errorText={formik.errors.sku_id}
                isDisabled={!formik.values.package_id || readOnly}
                options={skuOptions}
                progress={skuListProgress}
              />
              <FormField
                label={qtyLabel}
                render={_ => (
                  <NumberInput
                    {...formik.getFieldProps('quantity')}
                    onChange={value => {
                      setConfirmData({ ...confirmData, quantity: value })
                      formik.setFieldValue('quantity', value)
                    }}
                    disabled={readOnly}
                    size="medium"
                    min={-100000}
                  />
                )}
              />
              <TextArea
                label={t('form.labels.comment')}
                {...formik.getFieldProps('comment')}
                invalid={formik.touched.comment && !!formik.errors.comment}
                errorText={formik.errors.comment}
                disabled={readOnly}
                limit={1000}
              />
            </FormSection>

            {readOnly && (
              <FormSection title={t('form.sectionHeaders.additionalInfo')}>
                {/* <Input label={t('form.labels.recordType')} {...formik.getFieldProps('record_type')} disabled /> */}
                <Input label={t('form.labels.parentId')} {...formik.getFieldProps('parent_id')} disabled />
                <Input label={t('form.labels.createdBy')} {...formik.getFieldProps('owner_name')} disabled />
                <Input label={t('form.labels.createdAt')} {...formik.getFieldProps('created_at')} disabled />
              </FormSection>
            )}
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      {btnFooterJSX}
    </StickyFooterLayout.Wrapper>
  )
}

export default InventoryDetailsForm
