import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Inner = styled.div`
  font-size: 40px;
`

const ErrorLayout: React.FC = () => {
  return (
    <Wrapper>
      <Inner>
        <span role="img" aria-label={'Crying cat'}>
          😿 Error 😿
        </span>
      </Inner>
    </Wrapper>
  )
}

export default ErrorLayout
