import React from 'react'
import { useTranslation } from 'react-i18next'
import { Company } from 'modules/domain/company/types'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'

type Props = {
  company?: Company | null
  footer?: string
  link?: string
  testId?: string
}

const CompanyInfoPreview: React.FC<Props> = ({ company, footer, link, testId }) => {
  const { t } = useTranslation(['common'])

  return (
    <PreviewContainer.Container footer={footer} to={link} testId={testId}>
      <PreviewContainer.Header>{company?.internal_name || ''}</PreviewContainer.Header>
      {!!company?.country && (
        <PreviewContainer.Text>{t(`common:country.full.${company.country}`)}</PreviewContainer.Text>
      )}
      {!!company?.phone_number && <PreviewContainer.Subheader>{company.phone_number}</PreviewContainer.Subheader>}
      {!!company?.email && (
        <PreviewContainer.Link href={`mailto:${company.email}`}>{company.email}</PreviewContainer.Link>
      )}
    </PreviewContainer.Container>
  )
}

export default CompanyInfoPreview
