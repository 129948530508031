import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ROLES } from 'types/entities'
import styled from 'styled-components'
import { SimpleSelect, CustomSelectStyles } from '@agro-club/frontend-shared'

const defaultRoles = [
  ROLES.AGRO_ADMIN,
  ROLES.AGRO_MANAGER,
  ROLES.PRODUCER_HEAD,
  ROLES.PRODUCER_MANAGER,
  ROLES.DISTRIBUTOR_HEAD,
  ROLES.DISTRIBUTOR_MANAGER,
]

type Props = {
  label?: string
  onChange: (role: ROLES, producer?: string, key?: string) => void
  value?: ROLES
  placeholder?: string
  customStyles?: CustomSelectStyles
  isDisabled?: boolean
  roles?: ROLES[]
  isClearable?: boolean
}

const Wrapper = styled.div`
  min-width: 240px;
`

const RoleSelect: React.FC<Props> = ({
  label,
  onChange,
  value,
  customStyles,
  isDisabled,
  roles = defaultRoles,
  isClearable,
}) => {
  const { t } = useTranslation(['role', 'permission', 'common'])

  const options = useMemo(() => {
    return roles.map(item => {
      const [role, permission] = item.split(':')
      return { id: item, title: `${t(`role:${role}`)} ${t(`permission:${permission}`)}` }
    })
  }, [roles, t])

  const handleStatusChange = useCallback(
    (value: ROLES) => {
      onChange(value, '', 'roles')
    },
    [onChange],
  )

  return (
    <Wrapper>
      <SimpleSelect
        label={label}
        onChange={handleStatusChange}
        options={options}
        value={value}
        placeholder={t('common:any')}
        customStyles={customStyles}
        isDisabled={isDisabled}
        isClearable={isClearable}
      />
    </Wrapper>
  )
}

export default RoleSelect
