import React, { useCallback, useState } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { IncentiveCampaignListHeader } from '../components/IncentiveCampaignListHeader'
import IncentiveCampaignTable from './components/IncentiveCampaignTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { SearchInput, useAction } from '@agro-club/frontend-shared'
import IncentiveCampaignActions from 'modules/domain/incentiveCampaign/duck'
import IncentiveCampaignSelectors from 'modules/domain/incentiveCampaign/selectors'
import { useSelector } from 'react-redux'
import { IncentiveCampaignListRequestFilter } from 'modules/domain/incentiveCampaign/types'
import { useTranslation } from 'react-i18next'
import useDebouncedEffect from 'hooks/useDebouncedEffect'
import { SearchInputWrapper } from 'views/pages/Storefront/Storefront/StorefrontList/styled'

const IncentiveCampaignList = () => {
  const { t } = useTranslation('incentiveCampaign')
  const filterValue = useSelector(IncentiveCampaignSelectors.filter)
  const filterUpdated = useAction(IncentiveCampaignActions.filterUpdated)
  const [search, setSearch] = useState(filterValue.search)

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<IncentiveCampaignListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  useDebouncedEffect(() => handleFilterChange({ search }), 300, [search])

  return (
    <>
      <Layout.Header>
        <IncentiveCampaignListHeader tab="incentiveCampaign" />
        <SearchInputWrapper data-test-id="search-wrapper">
          <SearchInput onChange={setSearch} value={search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
        <TComponents.Filters>
          <TComponents.SelectWrapper>
            <CompanySelect
              label={t('form.labels.seller')}
              placeholder={t('form.placeholders.seller')}
              value={filterValue.seller_id}
              onChange={val => handleFilterChange({ seller_id: val })}
              isClearable
              filter={{ is_seller: true }}
              variant="small"
            />
          </TComponents.SelectWrapper>
        </TComponents.Filters>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper noFilters>
          <IncentiveCampaignTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default IncentiveCampaignList
