import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('farmerTargetSku')

export const FarmerTargetSkuSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.farmerTargetSku.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.farmerTargetSku.totalTargetValues,
  farmerOrdersTotal: (state: AppGlobalState) => state.farmerTargetSku.totalFarmerOrdersSku,
  previousYear: (state: AppGlobalState) => state.farmerTargetSku.totalFarmerOrdersSkuPreviousYear,
}

export default FarmerTargetSkuSelectors
