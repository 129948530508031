import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuRetailer = {
  id: string
  title: string
}

type TargetSkuRetailerFilter = {
  manufacturer_id?: string
  title?: string
  territory_id?: string
}

type TargetSkuRetailerSelectProps = EntitySelectProps<TargetSkuRetailer> & {
  valueLabel?: string
  manufacturerId?: string
  territoryId?: string
  scope?: TargetSkuTypes | 'default'
}

const getListForm = createGetList<TargetSkuRetailer, TargetSkuRetailerFilter>(endpoints.target2sku('retailers'))

const getListRetailerFilter = createGetList<TargetSkuRetailer, TargetSkuRetailerFilter>(
  endpoints.target2sku('retailer/retailers'),
)

const getListFarmerFilter = createGetList<TargetSkuRetailer, TargetSkuRetailerFilter>(
  endpoints.target2sku('farmer/retailers'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Retailer]: getListRetailerFilter,
}

const getLabel = (item: TargetSkuRetailer) => item.title

const getValue = (item: TargetSkuRetailer) => item.id

export const TargetSkuRetailerSelect: React.VFC<TargetSkuRetailerSelectProps> = ({
  isDisabled,
  valueLabel,
  onChange,
  manufacturerId,
  territoryId,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, territory_id: territoryId }), [
    manufacturerId,
    territoryId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: valueLabel, id: 'fake' } : undefined), [valueLabel])

  const testId = 'target-retailer-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      onChange={onChange}
      searchParamKey="title"
      data-test-id={testId}
      {...props}
    />
  )
}
