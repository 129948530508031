import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  DiscountRule,
  DiscountRuleListRequestSorting,
  DiscountRuleListRequestFilter,
  DiscountRuleDTO,
  CalculatedDiscountResponse,
  CalculateParamsDTO,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: DiscountRuleListRequestFilter,
  sorting: DiscountRuleListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<DiscountRule>>(endpoints.discountRule(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<DiscountRule>(endpoints.discountRule(id))
}

export const addItem = (dto: DiscountRuleDTO) => {
  return apiClient.post<DiscountRule>(endpoints.discountRule(), dto)
}

export const updateItem = (id: string, dto: Partial<DiscountRuleDTO>) => {
  return apiClient.put<DiscountRule>(endpoints.discountRule(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<DiscountRule>(endpoints.discountRule(id))
}

export const calculateDiscount = (dto: CalculateParamsDTO) => {
  return apiClient.post<CalculatedDiscountResponse>(endpoints.discountRule('calculate'), dto)
}
