import * as managers from 'modules/domain/reconciliation/managers'
import { ReconciliationManufacturer, ReconciliationCompanyFilter } from 'modules/domain/reconciliation/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import styled from 'styled-components'

const Wrapper = styled(SelectTestWrapper)`
  min-width: 300px;
`

export const getManufacturerLabel = (item: ReconciliationManufacturer) => item.title
export const getManufacturerValue = (item: ReconciliationManufacturer) => item.id

export type ManufacturerSelectProps = Omit<
  EntityMultiSelectProps<ReconciliationManufacturer>,
  'getOptionLabel' | 'getOptionValue'
> & {
  filter?: ReconciliationCompanyFilter
}

export const ManufacturerSelect: React.FC<ManufacturerSelectProps> = ({ filter, ...props }) => {
  return (
    <Wrapper data-test-id="manufacturer-select">
      <EntityMultiSelect
        filter={filter}
        getEntityByIds={managers.getManufacturersByIds}
        getEntityList={managers.getManufacturer}
        searchParamKey="title"
        getOptionLabel={getManufacturerLabel}
        getOptionValue={getManufacturerValue}
        {...props}
      />
    </Wrapper>
  )
}
