import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import ReportActions from './duck'
import ReportSelectors from './selectors'
import { Report } from './types'
import { getSignedReportLink } from './managers'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useReportList: ResourceHook<Report[], void[]> = () => {
  const progress = useSelector(ReportSelectors.listFetchProgress)
  const list = useSelector(ReportSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(ReportActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useReport: ResourceHook<Report, [string]> = (id: string) => {
  const meta = useSelector(state => ReportSelectors.meta(state, id))
  const item = useSelector(state => ReportSelectors.item(state, id))
  const fetchAction = useAction(ReportActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useReportById: ResourceHook<Report, [string]> = (id: string) => {
  const meta = useSelector(state => ReportSelectors.meta(state, id))
  const item = useSelector(state => ReportSelectors.item(state, id))
  const fetchAction = useAction(ReportActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useSignedReportLink: ResourceHook<string, [Report | undefined, any | undefined]> = (
  report?: Report,
  refresh?: any,
) => {
  const [url, setUrl] = useState<string>()
  const [progress, setProgress] = useState(Progress.IDLE)

  const signLink = useCallback(async (id: string) => {
    try {
      setProgress(Progress.WORK)
      const response = await getSignedReportLink(id)
      setUrl(response.url)
      setProgress(Progress.SUCCESS)
    } catch (e) {
      setProgress(Progress.ERROR)
    }
  }, [])

  useEffect(() => {
    if (report && report.url) {
      signLink(report.id)
    } else {
      setUrl('')
    }
  }, [signLink, report, refresh])

  return [progress, url]
}
