import { useSelector } from 'react-redux'
import { CountryDateFormats } from 'modules/constants'
import AuthSelectors from 'modules/domain/auth/selectors'

type TimeFormat = 'HH' | 'HH:mm' | 'HH:mm:ss'
type Options = { timeFormat?: TimeFormat; isYearShort?: boolean; withTime?: boolean }

export default function useDateFormat(options?: Options): string {
  const userCompany = useSelector(AuthSelectors.userCompany)
  const profile = useSelector(AuthSelectors.profile)
  const companyCountry = userCompany?.country || profile?.country || 'RU'

  const timeFormat = options?.timeFormat || 'HH:mm'
  const isTimeVisible = options?.timeFormat || options?.withTime
  const resultTimeFormat = isTimeVisible ? ` / ${timeFormat}` : ''
  const dateFormat = options?.isYearShort
    ? CountryDateFormats[companyCountry].replace('yyyy', 'yy')
    : CountryDateFormats[companyCountry]

  const result = dateFormat + resultTimeFormat

  return result
}
