import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import PackageTypesActions from './duck'
import PackageTypesSelectors from './selectors'
import { PackageTypes } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import { RequestError } from 'modules/errors'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'

export const fetchList = function*() {
  try {
    let currentPage = yield select(PackageTypesSelectors.page)
    const filter = yield select(PackageTypesSelectors.filter)
    const sorting = yield select(PackageTypesSelectors.sorting)
    const pageSize = yield select(PackageTypesSelectors.pageSize)
    let response: ListResponse<PackageTypes> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(PackageTypesActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, StorefrontRoutes.PackageTypesList, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.listRequestFailed(errType))
  }
}

export const fetchAllList = function*() {
  try {
    const response: ListResponse<PackageTypes> = yield call(managers.getListAll)
    const { data } = response
    yield put(PackageTypesActions.listAllRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.listAllRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(PackageTypesSelectors.page)
    const filter = yield select(PackageTypesSelectors.filter)
    const sorting = yield select(PackageTypesSelectors.sorting)
    const pageSize = yield select(PackageTypesSelectors.pageSize)
    const { data, total_count }: { data: PackageTypes[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(PackageTypesActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof PackageTypesActions.itemRequested>) {
  try {
    const item: PackageTypes = yield call(managers.getItem, id)
    yield put(PackageTypesActions.itemRequestSucceed(item))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof PackageTypesActions.addRequested>) {
  try {
    const item: PackageTypes = yield call(managers.addItem, dto)
    yield put(PackageTypesActions.addSucceed(item))

    const pagePath = `${generatePath(StorefrontRoutes.PackageTypesEdit, {
      id: item.id,
    })}`
    yield put(push(pagePath))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.addFailed(errType))
  }
}

export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof PackageTypesActions.updateRequested>) {
  try {
    const item: PackageTypes = yield call(managers.updateItem, id, dto)
    yield put(PackageTypesActions.updateSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PackageTypesActions.updateFailed(id, type, detail))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof PackageTypesActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(PackageTypesActions.removeSucceed(payload))
    yield put(push(StorefrontRoutes.PackageTypesList))
    yield put(PackageTypesActions.resetRemoveProgress())

    const pagePath = `${generatePath(StorefrontRoutes.PackageTypesList)}`
    yield put(push(pagePath))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(PackageTypesActions.removeFailed(payload, errType))
  }
}

const PackageTypesSaga = function*() {
  yield all([
    takeLatest(PackageTypesActions.itemRequested.type, fetchItem),
    takeLatest(PackageTypesActions.listRequested.type, fetchList),
    takeLatest(PackageTypesActions.listAllRequested.type, fetchAllList),
    takeLatest(PackageTypesActions.filterUpdated.type, fetchList),
    takeLatest(PackageTypesActions.sortingUpdated.type, fetchList),
    takeLatest(PackageTypesActions.filterHasBeenReset.type, fetchList),
    takeLatest(PackageTypesActions.sortingHasBeenReset.type, fetchList),
    takeLatest(PackageTypesActions.listRequestedNext.type, fetchListNext),
    takeLatest(PackageTypesActions.addRequested.type, addItem),
    takeLatest(PackageTypesActions.updateRequested.type, updateItem),
    takeLatest(PackageTypesActions.removeRequested.type, removeItem),
  ])
}

export default PackageTypesSaga
