import React from 'react'
import { CellProps } from 'react-table'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { ChangeLogEntry } from 'modules/domain/changeLog/types'

export const Author: React.FC<CellProps<ChangeLogEntry>> = ({ row }) => {
  const first_name = row.original.user?.first_name || ''
  const email = row.original.user?.email || ''
  return <span>{`${first_name} ${first_name.length ? '/' : ''} ${email}`}</span>
}

export const LastUpdateCell: React.FC<CellProps<ChangeLogEntry>> = ({ row }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })
  return <span>{getFormatedDate(row.original.updated_at)}</span>
}
