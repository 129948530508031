import styled, { StyledProps } from 'styled-components'
import { BackBtn as BackBtnComponent, SearchInput as UISearchInput } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

export const Spinner = styled(SpinnerLayout)`
  margin: 50px auto;
`

export const BackBtn = styled(BackBtnComponent)`
  margin: 0 0 10px 0;
`

export const ModalHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  align-self: flex-start;
  margin-bottom: 40px;
`

export const SearchInput = styled(UISearchInput)`
  width: 496px;
  margin-bottom: 32px;
  border: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`

export const FarmersList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 24px;
  margin-top: 16px;
  max-height: 70vh;
  overflow-y: auto;
`

export const EmptySearchResult = styled.div`
  color: ${props => props.theme.color.secondary200};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 165px;
`

export const FarmerItem = styled.div`
  border: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px;
  display: block;
  transition: 0.3s border-color;
  position: relative;
  overflow: hidden;

  //pointer-events: ${(props: { disabled?: boolean }) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props: { disabled?: boolean }) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    border-color: ${(props: StyledProps<{ disabled?: boolean }>) =>
      props.disabled ? props.theme.color.onSurfaceLowEmphasys : props.theme.color.primary600};;
  }
`

export const AlreadySelected = styled.span`
  position: absolute;
  background: ${props => props.theme.color.backgroundSecondary};
  color: ${props => props.theme.color.onPrimaryDark};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  padding: 12px 8px;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  z-index: 1;
`
export const FormWrapper = styled.div`
  padding-top: 8px;
  width: 496px;
  min-height: 212px;
`
