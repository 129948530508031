import { createDictFromArrayHandler } from 'helpers/createDictFromArrayHandler'
import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { UpdateError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { Dict } from 'types/generics'
import {
  FccOrderSkuDTO,
  FccOrderSkuItem,
  FccOrderSkuListRequestFilter,
  FccOrderSkuListRequestSorting,
  FccOrderSkuState,
  FccOrderSkuUpdateDTO,
  FccOrdersSkuUpdateResponse,
} from './types'

const initialState: FccOrderSkuState = {
  ...BasicEntityState,
  itemsForUpdate: {},
  totalQty: 0,
  totalFinalQty: 0,
  totalCost: '0.00',
}

const fccItemsArrayToDict = createDictFromArrayHandler<FccOrderSkuItem>('id')

class FccOrderSkuReducer extends BasicEntityImmerReducer<
  FccOrderSkuItem,
  FccOrderSkuListRequestFilter,
  FccOrderSkuListRequestSorting,
  FccOrderSkuDTO,
  FccOrderSkuUpdateDTO,
  FccOrderSkuState
> {
  listRequestSucceed(response) {
    super.listRequestSucceed(response)
    const { total_qty, total_final_qty, total_cost } = response.meta
    this.draftState.totalCost = total_cost
    this.draftState.totalQty = total_qty
    this.draftState.totalFinalQty = total_final_qty
  }

  addToItemsForUpdate(id: string, dto: Partial<FccOrderSkuUpdateDTO>, _duplicate: boolean) {
    const item = this.state.itemsForUpdate[id] || this.state.items[id]

    if (item && ((dto.price && Number(dto.price) !== Number(item.price)) || !dto.price)) {
      this.draftState.itemsForUpdate[id] = { ...item, ...dto }
    }
  }

  updateItemsRequested(_dict: Dict<FccOrderSkuItem>) {
    this.draftState.updateProgress = Progress.WORK
    Object.keys(this.state.itemsForUpdate).forEach(id => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.WORK
        this.draftState.meta[id].updateError = null
      }
    })
  }

  updateItemsSucceed(response: FccOrdersSkuUpdateResponse) {
    this.draftState.items = { ...this.state.items, ...fccItemsArrayToDict(response.updated) }
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.itemsForUpdate = {}
    response.updated.forEach(({ id }) => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.SUCCESS
        this.draftState.meta[id].updateError = null
      }
    })
    if (response.not_updated.length) {
      this.draftState.updateProgress = Progress.ERROR
      this.draftState.itemsForUpdate = fccItemsArrayToDict(response.not_updated)
      response.not_updated.forEach(({ id }) => {
        if (this.draftState.meta[id]) {
          this.draftState.meta[id].updateProgress = Progress.ERROR
          this.draftState.meta[id].updateError = null
        }
      })
    }
  }

  updateItemsFailed(error: UpdateError, errorDetail: string | undefined) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateErrorDetail = errorDetail || null

    Object.keys(this.state.itemsForUpdate).forEach(id => {
      if (this.draftState.meta[id]) {
        this.draftState.meta[id].updateProgress = Progress.ERROR
        this.draftState.meta[id].updateError = error
        this.draftState.meta[id].updateErrorDetail = errorDetail || undefined
      }
    })
  }

  clearItemsForUpdate() {
    this.draftState.itemsForUpdate = {}
  }
}

export const FccOrderSkuActions = createActionCreators(FccOrderSkuReducer)
export default FccOrderSkuActions
export const reducer = createReducerFunction(FccOrderSkuReducer, initialState)
