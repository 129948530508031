import ConfigSelectors from 'modules/domain/config/selectors'
import { useFarmerByPhone } from 'modules/domain/farmer/hooks'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import PhoneForm from 'views/components/CustomerModal/PhoneForm'
import { FarmerData } from 'modules/domain/farmer/types'
import { Progress } from 'modules/types'

export const FarmerNotFound = styled.div`
  text-align: center;
  color: ${props => props.theme.color.secondary200};
`

export const Spinner = styled(SpinnerLayout)`
  margin: 50px auto;
`

const SearchByPhone: React.FC<{
  phone: string
  onSubmit: (phone: string) => void
  onFarmerFound: (farmer: FarmerData | null) => void
}> = ({ phone, onSubmit, onFarmerFound }) => {
  const { t } = useTranslation('farmer')
  const wlConfig = useSelector(ConfigSelectors.config)
  const phoneCodes = wlConfig?.phone_codes || []
  const [progress, farmer] = useFarmerByPhone(phone)

  useEffect(() => {
    onFarmerFound(farmer ?? null)
  }, [farmer, onFarmerFound])

  return (
    <>
      <PhoneForm onNext={onSubmit} phoneCodes={phoneCodes} showHeader={false} />
      {phone && [Progress.IDLE, Progress.WORK].includes(progress) && <Spinner />}
      {progress === Progress.ERROR && <FarmerNotFound>{t('errors.notFoundTitle')}</FarmerNotFound>}
    </>
  )
}

export default SearchByPhone
