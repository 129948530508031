import styled from 'styled-components'

export const InnerContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
`

export const UploadCompleteContainer = styled.div`
  display: grid;
  grid-gap: 28px;
  justify-items: center;
  color: ${props => props.theme.color.primary500};
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`

export const UploadErrorContainer = styled.div`
  display: grid;
  grid-gap: 28px;
  justify-items: center;
  color: ${props => props.theme.color.accentDestructive};
`
