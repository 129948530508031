import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { StorefrontCard } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.storefrontCard.items
const ids = (state: AppGlobalState) => state.storefrontCard.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id?: string): StorefrontCard | undefined =>
  id ? state.storefrontCard.items[id] : undefined
const meta = (state: AppGlobalState, id?: string): EntityMetadata<StorefrontCard> =>
  (id && state.storefrontCard.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.storefrontCard.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.storefrontCard.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.storefrontCard.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.storefrontCard.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.storefrontCard.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: StorefrontCard[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.storefrontCard.filter
const sorting = (state: AppGlobalState) => state.storefrontCard.sorting
const page = (state: AppGlobalState) => state.storefrontCard.page
const total = (state: AppGlobalState) => state.storefrontCard.total
const updateProgress = (state: AppGlobalState) => state.storefrontCard.updateProgress
const removeProgress = (state: AppGlobalState) => state.storefrontCard.removeProgress
const addProgress = (state: AppGlobalState) => state.storefrontCard.addProgress
const hasNext = (state: AppGlobalState) => state.storefrontCard.total > state.storefrontCard.ids.length
const pageSize = (state: AppGlobalState) => state.storefrontCard.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.storefrontCard.total / state.storefrontCard.pageSize)
const itemSelected = (state: AppGlobalState, id: string) => state.storefrontCard.selectedIds[id] || false
const selectedIds = (state: AppGlobalState) => state.storefrontCard.selectedIds

const StorefrontCardSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  itemSelected,
  selectedIds,
}

export default StorefrontCardSelectors
