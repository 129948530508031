import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import defaultLogo from 'assets/images/logo.svg'
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import ResetSucceed from './ResetSucceed'
import SetNewPasswordForm from './SetNewPasswordForm'
import Routes from './routes'
import { useConfig } from 'modules/domain/config/hooks'
import LanguageSelect from './LanguageRadio'
import { Progress } from 'modules/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 48px;

  background: ${({ theme }) => theme.color.primary25};
`

const LogoWrapper = styled.div`
  height: 64px;
`

const LogoStyled = styled.img`
  max-height: 64px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  width: 400px;
  padding: 8px 40px 40px 40px;
  margin-bottom: 40px;

  background: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`

const AuthPage = () => {
  const [progress, config] = useConfig(window.location.hostname)
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (progress === Progress.SUCCESS || progress === Progress.ERROR) {
      setLogo(config?.logo_url || defaultLogo)
    }
  }, [config, progress])

  const logoJSX = logo && <LogoStyled src={logo} alt={'logo'} />

  return (
    <Wrapper>
      <ContentWrapper>
        <LogoWrapper>{logoJSX}</LogoWrapper>
        <Switch>
          <Route path={Routes.Login} exact={true}>
            <LoginForm />
          </Route>
          <Route path={Routes.ResetPassword} exact={true}>
            <ResetPasswordForm />
          </Route>
          <Route path={Routes.ResetEmailSent} exact={true}>
            <ResetSucceed />
          </Route>
          <Route path={Routes.SetNewPassword} exact={true}>
            <SetNewPasswordForm />
          </Route>
          <Redirect to={Routes.Login} />
        </Switch>
      </ContentWrapper>
      <LanguageSelect />
    </Wrapper>
  )
}

export default AuthPage
