import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import actions from './duck'
import managers from './managers'
import selectors from './selectors'
import { generatePath } from 'modules/utils/helpers/generatePath'
import { push } from 'connected-react-router'
import ProductsAvailabilityRoutes from 'views/pages/ProductsAvailability/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'

const sagas = createBasicSagas({
  selectors,
  actions,
  managers,
  onAddItemSucceed: function*(_, item) {
    yield put(push(generatePath(ProductsAvailabilityRoutes.Edit, { id: item.id })))
  },
  onRemoveItemSucceed: function*() {
    yield put(push(ProductsAvailabilityRoutes.List))
  },
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, ProductsAvailabilityRoutes.List, { page })
  },
})

const allSaga = function*() {
  yield all([
    takeLatest(actions.itemRequested.type, sagas.fetchItem),
    takeLatest(actions.listRequested.type, sagas.fetchList),
    takeLatest(actions.filterUpdated.type, sagas.fetchList),
    takeLatest(actions.sortingUpdated.type, sagas.fetchList),
    takeLatest(actions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.addSucceed.type, sagas.fetchList),
    takeLatest(actions.listUpdateRequested.type, sagas.fetchList),
    takeLatest(actions.listRequestedNext.type, sagas.fetchListNext),
    takeLatest(actions.addRequested.type, sagas.addItem),
    takeLatest(actions.addRequested.type, sagas.fetchList),
    takeLatest(actions.updateRequested.type, sagas.updateItem),
    takeLatest(actions.updateRequested.type, sagas.fetchList),
    takeLatest(actions.removeRequested.type, sagas.removeItem),
    takeLatest(actions.removeRequested.type, sagas.fetchList),
  ])
}

export default allSaga
