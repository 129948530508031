import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import {
  InventoryItem,
  InventoryItemDTO,
  InventoryExchangeListRequestFilter,
  InventoryExchangeListRequestSorting,
  InventoryExchangeState,
  InventoryTransferRequestShort,
} from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { FetchError, RemoveError, TransferError, UpdateError } from 'modules/domain/types'
import { InventoryExchangeTransaction } from '../inventoryExchangeTransaction/types'

const initialState: InventoryExchangeState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  transferProgress: Progress.IDLE,
  transferError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  deliveryOptionProgress: Progress.IDLE,
  priceUpdateProgress: Progress.IDLE,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class InventoryExchangeReducer extends ImmerReducer<InventoryExchangeState> {
  listRequested(params: {
    filter?: InventoryExchangeListRequestFilter
    sorting?: InventoryExchangeListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: InventoryItem[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  filterUpdated(filter: InventoryExchangeListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: InventoryExchangeListRequestFilter) {
    this.draftState.filter = { ...this.draftState.filter, ...filter }
  }

  sortingUpdated(sorting: InventoryExchangeListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchNextErrorDetail = undefined
  }

  listRequestNextSucceed(list: InventoryItem[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.listFetchNextErrorDetail = undefined
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestNextFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
    this.draftState.listFetchNextErrorDetail = errorDetail
  }

  transactionAddSucceed(transaction: InventoryExchangeTransaction) {
    this.draftState.items[`${transaction.retailer_id}-${transaction.sku?.id}`].total_qty += transaction.quantity
  }
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: InventoryItem) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  transferRequested(_dto: InventoryTransferRequestShort) {
    this.draftState.transferProgress = Progress.WORK
    this.draftState.transferError = null
    this.draftState.transferErrorDetail = undefined
    this.draftState.transferErrorFields = undefined
  }
  transferRequestSucceed() {
    this.draftState.transferProgress = Progress.SUCCESS
    this.draftState.transferErrorDetail = undefined
    this.draftState.transferErrorFields = undefined
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  transferRequestFailed(error: TransferError, errorDetail?: string, errorFields?: Record<string, string>) {
    this.draftState.transferProgress = Progress.ERROR
    this.draftState.transferError = error
    this.draftState.transferErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.transferErrorFields = errorFields
  }

  updateRequested(id: string, _retailer_id: string, _sku_id: string, _dto: InventoryItemDTO) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
  }
  updateSucceed(id: string, item: InventoryItem) {
    this.draftState.items[id] = item
    this.draftState.meta[id].updateProgress = Progress.SUCCESS
    this.draftState.updateProgress = Progress.SUCCESS
  }
  updateFailed(id: string, error: UpdateError) {
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.updateProgress = Progress.ERROR
  }
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
  }
  resetRemoveProgress() {
    this.draftState.removeProgress = Progress.IDLE
  }
}

export const InventoryExchangeActions = createActionCreators(InventoryExchangeReducer)
export default InventoryExchangeActions
export const reducer = createReducerFunction(InventoryExchangeReducer, initialState)
