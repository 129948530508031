import React from 'react'
import * as managers from 'modules/domain/reconciliation/managers'
import seasonManagers from 'modules/domain/season/managers'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import styled from 'styled-components'
import { Season } from 'modules/domain/season/types'

const Wrapper = styled(SelectTestWrapper)`
  min-width: 300px;
`

export const getLabel = (item: Season) => item.title
export const getValue = (item: Season) => item.id

export const SeasonSelect: React.FC<EntitySelectProps<Season>> = ({ ...props }) => {
  return (
    <Wrapper data-test-id="season-select">
      <EntitySelect
        getEntityById={seasonManagers.getItem}
        getEntityList={managers.getSeasons}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        {...props}
      />
    </Wrapper>
  )
}
