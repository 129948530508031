import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TerritorySelectors from 'modules/domain/territory/selectors'
import TerritoryActions from 'modules/domain/territory/duck'
import styled from 'styled-components'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import TerritoryRoutes from 'views/pages/Territory/routes'
import { useTerritoryById } from 'modules/domain/territory/hooks'
import { Progress } from 'modules/types'
import TerritoryDetailsForm, { FormProps } from 'views/pages/Territory/TerritoryDetailsForm/TerritoryDetailsForm'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useTranslation } from 'react-i18next'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const TerritoryEdit: React.FC<{ onUpdateInitialState(props: FormProps): void }> = ({ onUpdateInitialState }) => {
  const { t } = useTranslation('territory')
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const updateAction = useAction(TerritoryActions.updateRequested)
  const removeAction = useAction(TerritoryActions.removeRequested)
  const page = useSelector(TerritorySelectors.page)
  const meta = useSelector(TerritorySelectors.meta(params.id))
  const [fetchProgress, item] = useTerritoryById(params.id)

  const handleSubmit = (props: FormProps, options: { dirty?: boolean; duplicate: boolean }) => {
    const justRedirect = () => {
      push({ route: TerritoryRoutes.Add })
    }
    const update = (duplicate = false) => {
      updateAction(params.id, props, duplicate)
    }

    if (!options.duplicate) {
      update(false)
    } else {
      onUpdateInitialState(props)
      if (options.dirty) {
        update(true)
      } else {
        justRedirect()
      }
    }
  }
  const handleRemove = () => {
    removeAction(params.id)
  }

  const data = useMemo(() => {
    if (!item) {
      return null
    }
    return {
      title: item?.title || '',
      company_id: item?.company_id || '',
      external_id: item?.external_id || '',
    }
  }, [item])

  const goBack = () => {
    push({ route: TerritoryRoutes.List, query: { page } })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !data || !item) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={item.title || ''} />
          <Id>{`ID ${item.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TerritoryDetailsForm
          progress={meta.updateProgress}
          errorText={meta.updateErrorDetail}
          removeProgress={meta.removeProgress}
          mode={'edit'}
          onRemove={handleRemove}
          onSubmit={handleSubmit}
          onCancel={goBack}
          initialValues={data}
        />
      </Layout.Content>
    </>
  )
}

export default TerritoryEdit
