import styled from 'styled-components'
import {
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  TableBodyCell,
  TableBodyRow,
  Button,
  PopoverWithBtns,
} from '@agro-club/frontend-shared'
import React, { useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import { ReconciliationList, ReconciliationSku } from 'modules/domain/reconciliation/types'
import { useTranslation } from 'react-i18next'
import useLangPicker from 'hooks/useLangPicker'

const SectionTableStyled = styled(SectionTable)`
  border-spacing: 0 4px;
`

const ProductCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  const { pick } = useLangPicker()
  return <span>{pick(row.original.title)}</span>
}

const CropCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  const { pick } = useLangPicker()
  return <span>{row.original.subcategory ? pick(row.original.subcategory?.title) : ''}</span>
}

export const StatusCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.retailer.status}</span>
}

export const RetailerNameCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.retailer.title}</span>
}

export const ManufacturerNameCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.manufacturer.title}</span>
}

export const GroupCommentCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.retailer.comment}</span>
}

const NetCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <div>{row.original.adj.net}</div>
}

const ShippedCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <div>{row.original.reconciliation.shipped}</div>
}

const GrowerCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <div>{row.original.reconciliation.grower}</div>
}

const DiffCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <div>{row.original.reconciliation.diff}</div>
}

export const RowsCell: React.FC<CellProps<ReconciliationList, ReconciliationSku[]>> = ({ value }) => {
  const { t } = useTranslation('reconciliation')

  const allColumns = useMemo(
    () => [
      { width: 200, Header: t('headers.product'), cellAlign: 'left', Cell: ProductCell },
      { Header: t('headers.crop'), cellAlign: 'left', Cell: CropCell },
      { Header: t('headers.shipped'), cellAlign: 'left', Cell: ShippedCell },
      { Header: t('headers.ordered'), cellAlign: 'left', Cell: GrowerCell },
      { Header: t('headers.net_adj'), cellAlign: 'left', Cell: NetCell },
      { Header: t('headers.diff'), cellAlign: 'left', Cell: DiffCell },
    ],
    [t],
  )

  const hiddenColumns = useMemo(() => [], [])

  const { columns, rows, prepareRow } = useTable<ReconciliationSku>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data: value,
  })

  return (
    <SectionTableStyled>
      <SectionTableHead>
        <SectionTableHeadRow>
          {columns.map(column => {
            return (
              <SectionTableHeadCell key={column.id} hidden={column.hidden}>
                {column.render('Header')}
              </SectionTableHeadCell>
            )
          })}
        </SectionTableHeadRow>
      </SectionTableHead>
      <SectionTableBody>
        {rows.map(row => {
          prepareRow(row)
          const { key, ...props } = row.getRowProps()
          return (
            <TableBodyRow key={key} {...props}>
              {row.cells.map(cell => {
                const { key, ...props } = cell.getCellProps()
                return (
                  <TableBodyCell {...cell.column} key={key} {...props}>
                    {cell.render('Cell')}
                  </TableBodyCell>
                )
              })}
            </TableBodyRow>
          )
        })}
      </SectionTableBody>
    </SectionTableStyled>
  )
}

export const InteractionCell: React.FC<CellProps<ReconciliationSku> & {
  onDelete: (producerId: string, retailerId: string) => void
}> = ({ cell, row, onDelete }) => {
  const { t } = useTranslation(['common', 'reconciliation'])

  if (!cell.value) return null
  const { manufacturer, retailer } = { ...row.original }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <PopoverWithBtns
        onSubmit={() => {
          onDelete(manufacturer.id, retailer.id)
        }}
        heading={t('reconciliation:removeGroupConfirmation')}
        submitText={t('yes')}
        cancelText={t('no')}
      >
        <Button intent="danger" data-test-id="remove-retailer-button" size="small">
          {t('delete')}
        </Button>
      </PopoverWithBtns>
    </div>
  )
}
