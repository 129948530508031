import { useFormik } from 'formik'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@agro-club/frontend-shared'
import * as Yup from 'yup'
import * as Styled from './styled'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import { useDetectedCountry } from '../../../hooks/useDetectedCountry'
import { CountryPhoneCode } from 'modules/domain/collection/types'
import { isValidSpainPhoneNumber } from './helpers'

type PhoneFormProps = {
  phone: string
  countryCode: CountryCode
}

type Props = {
  onNext: (val: string, code: CountryCode) => void
  phoneCodes: CountryPhoneCode[]
  showHeader?: boolean
}

const PhoneForm: React.FC<Props> = ({ onNext, phoneCodes, showHeader = true }) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'validation'])
  const defaultCountry = useDetectedCountry()
  const [countryCode, setCountryCode] = useState(defaultCountry)
  const validationSchema = useMemo(
    () =>
      Yup.object({
        phone: Yup.string()
          .required(t('validation:field_required'))
          .test('is-valid-number', t('validation:invalid_phone_number'), value => isValidPhoneNumber(value || ''))
          .test('is-valid-es-number', t('validation:invalid_phone_number'), value =>
            isValidSpainPhoneNumber(countryCode, value),
          ),
      }),
    [countryCode, t],
  )

  const formik = useFormik<PhoneFormProps>({
    initialValues: {
      phone: '',
      countryCode: defaultCountry,
    },
    validationSchema,
    onSubmit: values => {
      onNext(values.phone, values.countryCode)
    },
  })

  return (
    <Styled.PhoneWrapper data-test-id={'modal-phone-form'}>
      {showHeader && <Styled.Title>{t('form.sectionHeaders.selectCustomer')}</Styled.Title>}
      <Styled.PhoneFormWrapper>
        <Styled.PhoneInputStyled
          testId={'customer-search-phone'}
          label={t('form.labels.customerPhone')}
          invalid={formik.touched.phone && !!formik.errors.phone}
          errorText={formik.errors.phone}
          onChange={(phone, callingCode, nationalNumber, isValid, countryCode) => {
            formik.setFieldValue('phone', phone)
            const code = countryCode || defaultCountry
            formik.setFieldValue('countryCode', code)
            setCountryCode(code)
          }}
          phoneNumber={formik.values.phone}
          onBlur={() => {
            formik.setFieldTouched('phone', true)
          }}
          phoneCodes={phoneCodes}
        />
        <Button
          data-test-id={'next-button'}
          intent={'primary'}
          disabled={!!formik.errors.phone}
          filled={true}
          onClick={() => {
            formik.submitForm()
          }}
        >
          {t('common:next')}
        </Button>
      </Styled.PhoneFormWrapper>
    </Styled.PhoneWrapper>
  )
}

export default PhoneForm
