import React, { useState, useMemo, memo } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useFormik } from 'formik'
import {
  SectionContainer,
  useFormManager,
  Input,
  NumberInput,
  FormComponents,
  Switch,
  SectionBody,
} from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { BadgeActionWithFiles } from 'modules/domain/badge/types'
import * as Yup from 'yup'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { Status } from 'types/entities'
import ImageForm from 'views/components/ImageForm/ImageForm'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as Styled from './styled'
import { FileItem } from 'views/components/FileManager/types'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { useSelector } from 'react-redux'

export type FilesFormProps = {
  files: FileItem[]
}

export type FormikManagerData = {
  main: BadgeActionWithFiles
  x1: FilesFormProps
  x2: FilesFormProps
  x3: FilesFormProps
}

const BadgeDetailsForm: React.FC<{
  currentId?: string
  initialValues?: BadgeActionWithFiles
  progress?: Progress
  removeProgress?: Progress
  onSubmit(formFields: Partial<BadgeActionWithFiles>): void
  onCancel(): void
  onRemove?(): void
}> = ({ progress, initialValues, removeProgress, onSubmit, onCancel, onRemove }) => {
  const { t } = useTranslation(['badge', 'common'])
  const { register, bind, dirty, submitAll, map } = useFormManager<FormikManagerData>()
  const [submitCount, setSubmitCount] = useState(0)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t('form.requiredField')),
      }),
    [t],
  )

  const isFilesTouched = map(field => field.values.files && !!Object.values(field.touched).length).some(Boolean)
  const isFileAdded = map(field => field.values.files && !Object.values(field.errors).length).some(Boolean)

  const formik = useFormik<Partial<BadgeActionWithFiles>>({
    initialValues: {
      name: initialValues?.name || '',
      properties: initialValues?.properties,
      status: initialValues?.status,
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  })

  register('main', formik)

  const handleSubmit = async () => {
    try {
      const [, forms] = await submitAll()

      setSubmitCount(submitCount + 1)
      if (!formik.isValid || !isFileAdded) {
        return
      }

      onSubmit({
        ...formik.values,
        urls: {
          x1: forms.x1.files[0],
          x2: forms.x2.files[0],
          x3: forms.x3.files[0],
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleInputChange = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const handleWidthChange = value => {
    handleInputChange('properties.width', value)
  }

  const handleHeightChange = value => {
    handleInputChange('properties.height', value)
  }

  const handleStatusChange = value => {
    const status = value ? Status.Active : Status.Inactive
    formik.setFieldValue('status', status)
  }
  useValidationErrorNotification(submitCount, formik.isValid && isFileAdded)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Styled.ContentWrapper>
              <FormComponents.FormSection title={t('form.title')}>
                <Styled.Grid2Col>
                  <Input
                    {...formik.getFieldProps('name')}
                    label={t('form.title')}
                    invalid={formik.touched.name && !!formik.errors.name}
                    errorText={formik.errors.name}
                    required
                  />
                </Styled.Grid2Col>
              </FormComponents.FormSection>
              <FormComponents.FormSection title={t('form.badgeImages')}>
                <Styled.Grid3Col>
                  <Styled.LabeledContainerStyled label={'x1'}>
                    <ImageForm
                      useFormik={bind('x1')}
                      accept={'image/png, image/svg+xml, image/jpg'}
                      limit={1}
                      files={initialValues?.urls?.x1 ? [initialValues.urls.x1] : []}
                      atLeastOneFileValidation
                    />
                  </Styled.LabeledContainerStyled>
                  <Styled.LabeledContainerStyled label={'x2'}>
                    <ImageForm
                      useFormik={bind('x2')}
                      accept={'image/png, image/svg+xml, image/jpg'}
                      limit={1}
                      files={initialValues?.urls?.x2 ? [initialValues.urls.x2] : []}
                      atLeastOneFileValidation
                    />
                  </Styled.LabeledContainerStyled>
                  <Styled.LabeledContainerStyled label={'x3'}>
                    <ImageForm
                      useFormik={bind('x3')}
                      accept={'image/png, image/svg+xml, image/jpg'}
                      limit={1}
                      files={initialValues?.urls?.x3 ? [initialValues.urls.x3] : []}
                      atLeastOneFileValidation
                    />
                  </Styled.LabeledContainerStyled>
                </Styled.Grid3Col>
                {isFilesTouched && !isFileAdded && (
                  <Styled.ErrorMessage>{t('form.addAtLeastOneImage')}</Styled.ErrorMessage>
                )}
              </FormComponents.FormSection>
              <FormComponents.FormSection title={t('form.properties')}>
                <Styled.Grid3Col>
                  <Styled.LabeledContainerStyled label={t('form.width')}>
                    <NumberInput
                      {...formik.getFieldProps('properties.width')}
                      step={100}
                      min={0}
                      size="medium"
                      onChange={handleWidthChange}
                    />
                  </Styled.LabeledContainerStyled>
                  <Styled.LabeledContainerStyled label={t('form.height')}>
                    <NumberInput
                      {...formik.getFieldProps('properties.height')}
                      step={100}
                      min={0}
                      size="medium"
                      onChange={handleHeightChange}
                    />
                  </Styled.LabeledContainerStyled>
                </Styled.Grid3Col>
              </FormComponents.FormSection>
              <FormComponents.FormSection title={t('form.status')}>
                <Switch
                  {...formik.getFieldProps('status')}
                  on={formik.values.status === Status.Active}
                  onClick={handleStatusChange}
                >
                  {formik.values.status === Status.Active ? t('statuses.active') : t('statuses.inactive')}
                </Switch>
              </FormComponents.FormSection>
            </Styled.ContentWrapper>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      {formik.values?.status !== Status.Deleted && (
        <StickyFooterDefaultControls
          onRemove={onRemove}
          onSave={handleSubmit}
          onCancel={onCancel}
          saveProgress={progress}
          isSaveDisabled={!dirty || isFilesUploading || progress === Progress.WORK}
          removeProgress={removeProgress}
          popoverText={t('form.removeText', { name: initialValues?.name })}
        />
      )}
    </StickyFooterLayout.Wrapper>
  )
}

export default memo(BadgeDetailsForm)
