import { createSelector } from 'reselect'

import { AppGlobalState, Progress } from '../../types'
import { EntityMetadata } from 'modules/domain/types'
import { License } from './types'

const total = (state: AppGlobalState) => state.license.total
const page = (state: AppGlobalState) => state.license.page
const pages = (state: AppGlobalState) => Math.ceil(state.license.total / state.license.pageSize)
const pageSize = (state: AppGlobalState) => state.license.pageSize

const dict = (state: AppGlobalState) => state.license.items
const ids = (state: AppGlobalState) => state.license.ids
const listFetchProgress = (state: AppGlobalState) => state.license.listFetchProgress
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: License[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})
const filter = (state: AppGlobalState) => state.license.filter
const sorting = (state: AppGlobalState) => state.license.sorting

const item = (state: AppGlobalState, id: string): License | undefined => state.license.items[id]
const itemFetchProgress = (state: AppGlobalState) => state.license.itemFetchProgress

const addProgress = (state: AppGlobalState) => state.license.addProgress
const removeProgress = (state: AppGlobalState) => state.license.removeProgress

const meta = (state: AppGlobalState, id: string): EntityMetadata<License> =>
  state.license.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const LicenseSelectors = {
  filter,
  sorting,
  total,
  page,
  pages,
  pageSize,
  list,
  listFetchProgress,
  item,
  itemFetchProgress,
  addProgress,
  removeProgress,
  meta,
}

export default LicenseSelectors
