import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  Category,
  CategoryListRequestSorting,
  CategoryListRequestFilter,
  CategoryDTO,
  CategorySelectOption,
  CategorySelectOptionsRequestFilter,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: CategoryListRequestFilter,
  sorting: CategoryListRequestSorting,
  page: number,
  pageSize: number,
  companyId?: string,
) => {
  const endpoint = companyId ? endpoints.companyCategories(companyId) : endpoints.category()

  return apiClient.get<ListResponse<Category>>(endpoint, {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getSubcategoryList = async (
  filter: CategoryListRequestFilter,
  sorting: CategoryListRequestSorting,
  page: number,
  pageSize: number,
  companyId?: string,
) => {
  const endpoint = companyId ? endpoints.companyCategories(companyId) : endpoints.category()

  if (filter.parent_id)
    return apiClient.get<ListResponse<Category>>(endpoint, {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  return await { total_count: 0, page: 1, data: [] }
}

export const getCategorySelectOptions = (
  filter: CategorySelectOptionsRequestFilter,
  sorting: CategoryListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<CategorySelectOption>>(endpoints.selectSuggest('categories'), {
    only_parent: true,
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getSubcategorySelectOptions = (
  filter: CategorySelectOptionsRequestFilter,
  sorting: CategoryListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return getCategorySelectOptions({ only_children: true, only_parent: undefined, ...filter }, sorting, page, pageSize)
}

export const getCompanyCategories = (id: string) => {
  const endpoint = id ? endpoints.companyCategories(id) : endpoints.category()
  return apiClient.get<ListResponse<Category>>(endpoint)
}

export const getCompanySubCategories = (companyId: string, categoryParentId: string) => {
  const endpoint = endpoints.companyCategories(companyId)
  return apiClient.get<ListResponse<Category>>(endpoint, {
    parent_id: categoryParentId,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<Category>(endpoints.category(id))
}

export const addItem = (dto: CategoryDTO) => {
  return apiClient.post<Category>(endpoints.category(), dto)
}

export const updateItem = (id: string, dto: Partial<CategoryDTO>) => {
  return apiClient.put<Category>(endpoints.category(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<Category>(endpoints.category(id))
}
