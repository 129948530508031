import React from 'react'
import { Route, Switch } from 'react-router-dom'

import IncentiveProgramsRoutes from './routes'
import IncentiveProgramsAdd from './IncentiveProgramAdd'
import IncentiveProgramsEdit from './IncentiveProgramEdit'
import IncentiveProgramsList from './IncentiveProgramList'

const IncentiveCampaignPage: React.VFC = () => {
  return (
    <Switch>
      <Route exact path={IncentiveProgramsRoutes.Add}>
        <IncentiveProgramsAdd />
      </Route>
      <Route exact path={IncentiveProgramsRoutes.Edit}>
        <IncentiveProgramsEdit />
      </Route>
      <Route exact path={IncentiveProgramsRoutes.List}>
        <IncentiveProgramsList />
      </Route>
    </Switch>
  )
}

export default IncentiveCampaignPage
