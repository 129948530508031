import { Table, TableNoData } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Row, useTable, useSortBy } from 'react-table'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompoundedTableProps } from '../CompoundedTable'
import { CompoundedTableHead } from '../CompoundedTableHead'
import { StyledTableHead } from '../styles'
import { CompoundedTableEditableRowProps, CompoundedTableEditableRow } from './CompoundedTableEditableRow'

export type CompoundedTableEditableProps<DataItemType extends Object, FormItemType extends Object> = Omit<
  CompoundedTableProps<DataItemType>,
  'onRowClick'
> & {
  FormComponent: CompoundedTableEditableRowProps<DataItemType, FormItemType>['FormComponent']
  getFormValuesFromRow?: (row: Row<DataItemType>) => FormItemType
  newTitle?: React.ReactNode
  readonly?: boolean
  onCreate?: () => void
  onUpdate?: () => void
  isSortable?: boolean
  defaultSortField?: string
}

export const CompoundedTableEditable = React.forwardRef(
  <DataItemType extends Object, FormItemType extends Object>(
    {
      columns: columnsFromProps,
      metaColumns,
      hiddenColumns,
      data,
      progress = Progress.IDLE,
      isSortable,
      onSortUpdated,
      sortField,
      defaultSortField = '',
      sortReversed,
      FormComponent,
      getFormValuesFromRow = (row: Row<DataItemType>) => row.original as any,
      newTitle,
      readonly,
      onCreate,
      onUpdate,
      ...tableProps
    }: CompoundedTableEditableProps<DataItemType, FormItemType>,
    ref,
  ) => {
    const [openedForm, setOpenedForm] = useState<string | null>(null)

    const { columns, rows, prepareRow, setSortBy } = useTable(
      {
        columns: columnsFromProps,
        initialState: {
          hiddenColumns: hiddenColumns ?? [],
          sortBy: [
            {
              id: defaultSortField,
              desc: false,
            },
          ],
        },
        data,
      },
      useSortBy,
    ) as any

    const [sortedColumn, setSortedColumn] = useState('')

    useEffect(() => {
      setSortedColumn('')
    }, [columns])

    const rowComponent = useMemo(() => {
      if (!rows) return null
      return rows.map((row, idx) => {
        prepareRow(row)
        const { key, ...props } = row.getRowProps()
        return (
          <CompoundedTableEditableRow<DataItemType, FormItemType>
            key={key}
            row={row}
            {...props}
            idx={idx}
            onOpen={() => !readonly && setOpenedForm(row.original.id)}
            onClose={() => !readonly && setOpenedForm(null)}
            open={row.original.id === openedForm}
            totalColumns={columns.length}
            FormComponent={FormComponent}
            getFormValuesFromRow={getFormValuesFromRow}
            onUpdate={onUpdate}
          />
        )
      })
    }, [FormComponent, columns.length, getFormValuesFromRow, onUpdate, openedForm, prepareRow, readonly, rows])

    return (
      <Table data-test-id="table" {...tableProps} ref={ref}>
        <CompoundedTableHead
          columns={columns}
          metaColumns={metaColumns}
          onSortUpdated={onSortUpdated}
          sortField={sortField}
          sortReversed={sortReversed}
          onHeadCellClick={column => {
            if (!isSortable) return
            setSortBy([{ id: column.id, desc: !column.isSortedDesc && sortedColumn === column.id }])
            setSortedColumn(column.id)
          }}
          sortedColumn={sortedColumn}
        />
        <StyledTableHead hasMetaHead={!!metaColumns}>
          {!readonly && (
            <CompoundedTableEditableRow
              key={'new'}
              idx={'new'}
              onOpen={() => setOpenedForm('new')}
              onClose={() => setOpenedForm(null)}
              onCreate={onCreate}
              open={openedForm === 'new'}
              FormComponent={FormComponent}
              getFormValuesFromRow={getFormValuesFromRow}
              totalColumns={columns.length}
              newTitle={newTitle}
            />
          )}
          {rowComponent}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={columnsFromProps.length}
            loading={<TComponents.Spinner />}
          />
        </StyledTableHead>
      </Table>
    )
  },
) as <DataItemType extends Object, FormItemType extends Object>(
  props: CompoundedTableEditableProps<DataItemType, FormItemType> & { ref?: React.Ref<HTMLElement> },
) => React.ReactElement
