import { BasicSelect, BasicSelectProps, useDebounce } from '@agro-club/frontend-shared'
import { useTimezonesOptions } from 'modules/domain/collection/hooks'
import React, { useMemo, useState } from 'react'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

type TimezoneOption = {
  value: string
  label: string
}
export type TimezoneSelectProps = Omit<
  BasicSelectProps<TimezoneOption>,
  | 'options'
  | 'progress'
  | 'onSearchChange'
  | 'disableFilterOnSearchChange'
  | 'getOptionValue'
  | 'getOptionLabel'
  | 'value'
  | 'defaultValue'
  | 'onChange'
> & {
  value?: string
  onChange?: (value?: string) => void
}

const getTimezoneValue = (timezone: TimezoneOption) => timezone.value

//Seems like react-select doesn't support primitive options, so I'm converting them to objects
export const TimezoneSelect: React.VFC<TimezoneSelectProps> = ({ value, onChange, ...props }) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 200)
  const [progress, timezones = []] = useTimezonesOptions(debouncedSearch)
  const options = useMemo(() => timezones.map(timezone => ({ value: timezone, label: timezone })), [timezones])
  const valueMemo = useMemo(() => (value ? { value, label: value } : undefined), [value])
  return (
    <SelectTestWrapper data-test-id="timezone-select">
      <BasicSelect
        value={valueMemo}
        progress={progress}
        options={options}
        onSearchChange={setSearch}
        getOptionValue={getTimezoneValue}
        getOptionLabel={getTimezoneValue}
        onChange={newValue => onChange?.(newValue?.value)}
        {...props}
      />
    </SelectTestWrapper>
  )
}
