import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { EntityMetadata, FetchError, UpdateError } from 'modules/domain/types'
import { Company } from '../company/types'
import { StorefrontSku } from 'modules/domain/storefront/types'

export type InventoryTransferRequestState = {
  items: Dict<InventoryTransferRequest>
  meta: Dict<EntityMetadata<InventoryTransferRequest>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  updateProgress: Progress
  updateError: UpdateError | null
  requestsCountProgress?: Progress
  inRequestsCount?: number

  filter: InventoryTransferRequestFilter
  sorting: InventoryTransferRequestSorting
  page: number
  total: number
  pageSize: number
}

export enum TransferRequestStatuses {
  active = 'active',
  approved = 'approved',
  rejected = 'rejected',
  deleted = 'deleted',
}

export type InventoryTransferRequest = {
  id: string
  company_from: Company
  company_to: Company
  sku: StorefrontSku
  quantity: string | number
  quantityAvailable?: string
  price: string
  status: TransferRequestStatuses
  created_at: string
  comment?: string
  reject_comment?: string
}

export type TransferRequestDTO = {
  status?: TransferRequestStatuses
  quantity?: number | string
  reject_comment?: string
}

export type InventoryTransferRequestFilter = {
  search?: string
  retailer_from?: string[]
  retailer_to?: string[]
  product_id?: string
  product_options_id?: string[]
  producer_id?: string | null
  status?: string[]
}

export type InventoryTransferRequestSorting = {
  sort_field?: keyof InventoryTransferRequest
  sort_reversed?: boolean
}

export type InventoryTransferRequestCountFilter = {
  status?: TransferRequestStatuses
  product_id?: string
  sku_id?: string
  product_options_id?: string
  retailer_from?: string
  retailer_to?: string
  producer_id?: string
}
