import { Button, useAction, useHistoryPush, usePersistentScroll } from '@agro-club/frontend-shared'
import useDownload from 'hooks/useDownload'
import useLangPicker from 'hooks/useLangPicker'
import { ProductListRequestFilter } from 'modules/domain/product/types'
import StorefrontProductActions from 'modules/domain/storefrontProduct/duck'
import { useStorefrontProductList } from 'modules/domain/storefrontProduct/hooks'
import StorefrontProductSelectors from 'modules/domain/storefrontProduct/selectors'
import { StorefrontProduct } from 'modules/domain/storefrontProduct/types'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'modules/utils/helpers/generatePath'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'
import { CompanyType } from 'types/entities'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import StatusFilter from 'views/components/TableFilters/StatusFilter'
import * as Styled from 'views/pages/Product/ProductList/styles'
import StorefrontRoutes from '../../routes'

const IdColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('product')
  return (
    <div>
      <Styled.DefaultStatus />
      <span>{t('list.tableHeaders.id')}</span>
    </div>
  )
}

const IdCell: React.FC<CellProps<StorefrontProduct>> = ({ cell, column, row }) => {
  return (
    <Styled.IdCell key={column.id}>
      <Styled.Status status={row.original.status} />
      {`${cell.value || ''}`}
    </Styled.IdCell>
  )
}

const TitleCell: React.FC<CellProps<StorefrontProduct>> = ({ column, row }) => {
  const { pick } = useLangPicker()
  return <TComponents.SemiBoldText key={column.id}>{pick(row.original.title_i18n)}</TComponents.SemiBoldText>
}

const ProducerCell: React.FC<CellProps<StorefrontProduct>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value?.internal_name || ''}</TComponents.SemiBoldText>
}

export const StorefrontProductTable = () => {
  const { t } = useTranslation(['product', 'common', 'labels'])
  const visibleColumns = React.useMemo(
    () => [
      {
        Header: IdColumnHeaderCell,
        accessor: 'id' as const,
        Cell: IdCell,
        width: 260,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
        Cell: TitleCell,
        sortable: true,
        width: 'auto',
      },
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer' as const,
        Cell: ProducerCell,
        width: 250,
      },
    ],
    [t],
  )

  const filterUpdated = useAction(StorefrontProductActions.filterUpdated)
  const sortingUpdated = useAction(StorefrontProductActions.sortingUpdated)
  const listRequested = useAction(StorefrontProductActions.listRequested)
  const filterValue = useSelector(StorefrontProductSelectors.filter)
  const page = useSelector(StorefrontProductSelectors.page)
  const total = useSelector(StorefrontProductSelectors.total)
  const pageSize = useSelector(StorefrontProductSelectors.pageSize)
  const pages = useSelector(StorefrontProductSelectors.pages)
  const [progress, data = []] = useStorefrontProductList()

  const { status, producer_id } = filterValue
  const { sort_field, sort_reversed } = useSelector(StorefrontProductSelectors.sorting)

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ProductListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('productList')

  const [progressCSV, downloadCSV] = useDownload(endpoints.product('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })
  const push = useHistoryPush()

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <StatusFilter filterValue={{ status }} handleFilterChange={handleFilterChange} />
        <CompanySelect
          isClearable
          onChange={val => {
            handleFilterChange({ producer_id: val })
          }}
          value={producer_id}
          companyType={CompanyType.Producer}
          isSearchable
          variant="small"
          label={t('form.producer')}
          maxWidth="250px"
        />
        <AlignWrapper horizontalAlign="right" verticalAlign="bottom">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <CompoundedTable
        progress={progress}
        data={data}
        columns={visibleColumns}
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
        sortField={sort_field}
        sortReversed={sort_reversed}
        onSortUpdated={sort => {
          sortingUpdated(sort)
        }}
        onRowClick={row => {
          push({
            route: generatePath(StorefrontRoutes.ProductsEdit, { id: row.original.id.toString() }),
          })
        }}
      />
    </TComponents.Wrapper>
  )
}

export default StorefrontProductTable
