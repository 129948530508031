import useDateFormatFn from 'hooks/useDateFormatFn'
import { PercentageTierRule } from 'modules/domain/incentiveProgram/types'
import React from 'react'
import { MonetaryRuleProps } from './OrderSkuMonetaryDiscount'
import { DiscountChip } from './styles'

export const PercentageRule: React.FC<MonetaryRuleProps<PercentageTierRule>> = ({ tier, idx }) => {
  const getFormattedDate = useDateFormatFn()
  const { start_date, end_date, min_qty, max_qty, percent } = tier.tier_rule
  const startDate = getFormattedDate(start_date)
  const endDate = getFormattedDate(end_date)

  const maxQty = max_qty ? `- ${max_qty}` : ''
  const minQty = min_qty ? min_qty : '-'

  return (
    <DiscountChip key={idx} active={tier.is_applied} color={'orange'}>
      {`${percent}% | ${startDate} - ${endDate} | ${minQty} ${maxQty}`}
    </DiscountChip>
  )
}
