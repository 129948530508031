import React from 'react'
import ReportRoutes from 'views/pages/Reports/routes'
import ReportAdd from 'views/pages/Reports/ReportAdd'
import ReportEdit from 'views/pages/Reports/ReportEdit'
import ReportList from 'views/pages/Reports/ReportList'
import { Route, Switch } from 'react-router-dom'

const ReportPage: React.FC = () => {
  return (
    <Switch>
      <Route path={ReportRoutes.ReportAdd}>
        <ReportAdd />
      </Route>
      <Route path={ReportRoutes.ReportEdit}>
        <ReportEdit />
      </Route>
      <Route path={ReportRoutes.ReportList}>
        <ReportList />
      </Route>
    </Switch>
  )
}

export default ReportPage
