import { ReconciliationSku } from 'modules/domain/reconciliation/types'

export const calculateNetAdj = (item: ReconciliationSku) =>
  // paranoid check 🥲
  (item.inv.beginning || 0) -
  (item.transfers.returns || 0) +
  (item.transfers.in || 0) -
  (item.transfers.out || 0) -
  (item.inv.ending || 0) +
  (item.adj.dist || 0)
