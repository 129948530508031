import React from 'react'
import PromoInfoRoutes from 'views/pages/PromoInfo/routes'
import PromoInfoAdd from 'views/pages/PromoInfo/PromoInfoAdd'
import PromoInfoEdit from 'views/pages/PromoInfo/PromoInfoEdit'
import PromoInfoList from 'views/pages/PromoInfo/PromoInfoList'
import { Route, Switch } from 'react-router-dom'

const PromoInfoPage: React.FC = () => {
  return (
    <Switch>
      <Route path={PromoInfoRoutes.PromoInfoAdd}>
        <PromoInfoAdd />
      </Route>
      <Route path={PromoInfoRoutes.PromoInfoEdit}>
        <PromoInfoEdit />
      </Route>
      <Route path={PromoInfoRoutes.PromoInfoList}>
        <PromoInfoList />
      </Route>
    </Switch>
  )
}

export default PromoInfoPage
