import { createCheckFeatureFlag } from 'hooks/featureFlags/createCheckFeatureFlag'
import AuthSelectors from 'modules/domain/auth/selectors'
import { UserProfile } from 'modules/domain/auth/types'
import { useSelector } from 'react-redux'

const useFeatureFlags = () => {
  const profile = useSelector(AuthSelectors.profile) as UserProfile
  const flags = profile?.seller_config?.feature_flags

  return createCheckFeatureFlag(flags)
}

export default useFeatureFlags
