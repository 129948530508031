import { createSelector } from 'reselect'
import { AppGlobalState } from '../../types'
// import { createSelector } from 'reselect'

export const getFile = (id: string) => (state: AppGlobalState) => state.uploadManager.files[id]
export const getFiles = (state: AppGlobalState) => state.uploadManager.files

export const isFilesUploading = createSelector(getFiles, files =>
  Object.values(files).some(file => file.status === 'uploading'),
)

const UploadManagerSelectors = {
  file: getFile,
  files: getFiles,
  isFilesUploading,
}

export default UploadManagerSelectors
