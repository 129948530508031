import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { ListResponse } from 'types/api'
import {
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  TargetSkuUpdateDTO,
  TerritoryTargetSku,
  TerritoryTargetSkuCreateDTO,
  TerritoryTargetSkuState,
} from '../common/types'

const initialState: TerritoryTargetSkuState = {
  ...BasicEntityState,
  updateCounter: 0,
  totalFarmerOrdersSku: 0,
  totalFarmerOrdersSkuPreviousYear: 0,
  totalTargetValues: 0,
  totalRetailerTargets: 0,
}

type TerritoryMeta = {
  total_target_values: number
  total_farmer_orders_sku: number
  total_farmer_orders_sku_previous_year: number
  total_retailer_targets: number
}

class TerritoryTargetSkuReducer extends BasicEntityImmerReducer<
  TerritoryTargetSku,
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  TerritoryTargetSkuCreateDTO,
  TargetSkuUpdateDTO,
  TerritoryTargetSkuState
> {
  updateOccurred() {
    this.draftState.updateCounter += 1
  }
  listRequestSucceed(response: ListResponse<TerritoryTargetSku, TerritoryMeta>) {
    super.listRequestSucceed(response)
    const {
      total_target_values,
      total_farmer_orders_sku,
      total_farmer_orders_sku_previous_year,
      total_retailer_targets,
    } = response.meta || {}
    this.draftState.totalTargetValues = total_target_values || 0
    this.draftState.totalFarmerOrdersSku = total_farmer_orders_sku || 0
    this.draftState.totalFarmerOrdersSkuPreviousYear = total_farmer_orders_sku_previous_year || 0
    this.draftState.totalRetailerTargets = total_retailer_targets || 0
  }
}

export const TerritoryTargetSkuActions = createActionCreators(TerritoryTargetSkuReducer)
export default TerritoryTargetSkuActions
export const reducer = createReducerFunction(TerritoryTargetSkuReducer, initialState)
