import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  FarmerOptionInFarmerOrders,
  FarmerOptionInFarmerOrdersListRequestFilter,
  FarmerOptionInFarmerOrdersListRequestSorting,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: FarmerOptionInFarmerOrdersListRequestFilter,
  sorting: FarmerOptionInFarmerOrdersListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<FarmerOptionInFarmerOrders>>(endpoints.farmerOptionInFarmerOrders(), {
    ...filter,
    ...sorting,
    page,
    page_size: pageSize,
  })
}
