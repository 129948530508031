import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import UptakeActions from './duck'
import UptakeSelectors from './selectors'
import { Uptake } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import UpTakeRoutes from 'views/pages/UpTake/routes'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(UptakeSelectors.page)
    const filter = yield select(UptakeSelectors.filter)
    const sorting = yield select(UptakeSelectors.sorting)
    const pageSize = yield select(UptakeSelectors.pageSize)

    let response: ListResponse<Uptake> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(UptakeActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, UpTakeRoutes.List, { page: currentPage })
    yield put(UptakeActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(UptakeSelectors.page)
    const filter = yield select(UptakeSelectors.filter)
    const sorting = yield select(UptakeSelectors.sorting)
    const pageSize = yield select(UptakeSelectors.pageSize)
    const { data, total_count }: { data: Uptake[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(UptakeActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof UptakeActions.itemRequested>) {
  try {
    const company: Uptake = yield call(managers.getItem, id)
    yield put(UptakeActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof UptakeActions.addRequested>) {
  try {
    const company: Uptake = yield call(managers.addItem, dto)
    yield put(UptakeActions.addSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof UptakeActions.updateRequested>) {
  try {
    const company: Uptake = yield call(managers.updateItem, id, dto)
    yield put(UptakeActions.updateSucceed(company))
    yield put(UptakeActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof UptakeActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(UptakeActions.removeSucceed(payload))
    yield put(UptakeActions.pendingCallCountRequested())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UptakeActions.removeFailed(payload, errType))
  }
}

export const fetchPendingCount = function*(_action: ReturnType<typeof UptakeActions.pendingCallCountRequested>) {
  try {
    const count: number = yield call(managers.getPendingCount)
    yield put(UptakeActions.pendingCallCountRequestSuccess(count))
  } catch (err) {
    yield put(UptakeActions.pendingCallCountRequestError())
  }
}

const UptakeSaga = function*() {
  yield all([
    takeLatest(UptakeActions.itemRequested.type, fetchItem),
    takeLatest(UptakeActions.listRequested.type, fetchList),
    takeLatest(UptakeActions.filterUpdated.type, fetchList),
    takeLatest(UptakeActions.sortingUpdated.type, fetchList),
    takeLatest(UptakeActions.filterHasBeenReset.type, fetchList),
    takeLatest(UptakeActions.sortingHasBeenReset.type, fetchList),

    takeLatest(UptakeActions.listRequestedNext.type, fetchListNext),

    takeLatest(UptakeActions.addRequested.type, addItem),
    takeLatest(UptakeActions.updateRequested.type, updateItem),
    takeLatest(UptakeActions.removeRequested.type, removeItem),
    takeLatest(UptakeActions.pendingCallCountRequestSuccess.type, fetchPendingCount),
  ])
}

export default UptakeSaga
