import React, { useCallback, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { Promocode } from 'modules/domain/promocode/types'
import { Progress } from 'modules/types'
import { useIncentivePromocodeValidation } from 'modules/domain/incentiveProgram/hooks'

const PromocodeWrapper = styled.div`
  display: grid;
  grid-template-columns: 180px minmax(max-content, 90px);
  align-items: center;
  grid-gap: 8px;

  opacity: ${(props: { disabled: boolean }) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
`

export type PromocodeFieldProps = {
  className?: string
  companyId?: string | null
  promocodes: Promocode[]
  onApply: (code: string, promocode: Promocode) => void
  disabled?: boolean
}

const initialState = {
  progress: Progress.IDLE,
  isCodeValid: undefined,
  promocode: '',
}

type State = {
  progress: Progress
  isCodeValid?: boolean
  promocode: string
}
type Action =
  | {
      type: 'type'
      payload: string
    }
  | { type: 'apply:request' }
  | { type: 'apply:success' }
  | { type: 'apply:error' }
  | { type: 'reset' }

const promocodeFieldReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'apply:error':
      return {
        ...state,
        progress: Progress.ERROR,
        isCodeValid: false,
      }
    case 'apply:success': {
      return {
        ...state,
        progress: Progress.SUCCESS,
        isCodeValid: true,
        promocode: '',
      }
    }
    case 'apply:request': {
      return {
        ...state,
        progress: Progress.WORK,
        isCodeValid: undefined,
      }
    }
    case 'type': {
      return {
        ...state,
        promocode: action.payload,
        progress: Progress.IDLE,
        isCodeValid: undefined,
      }
    }
    case 'reset': {
      return { ...initialState }
    }
  }
  return state
}

const PromocodeField: React.VFC<PromocodeFieldProps> = ({ className, companyId, onApply, disabled, promocodes }) => {
  const { t } = useTranslation(['farmerOrder', 'common'])
  const checkPromocode = useIncentivePromocodeValidation(companyId)
  const [state, dispatch] = useReducer(promocodeFieldReducer, initialState)
  const promocodeCodes = useMemo(() => promocodes.map(p => p.code), [promocodes])

  const handleChange = useCallback(e => {
    dispatch({ type: 'type', payload: e.target.value || '' })
  }, [])

  const handleApply = useCallback(async () => {
    dispatch({ type: 'apply:request' })
    if (state.promocode && companyId) {
      try {
        const promocode = await checkPromocode(state.promocode, promocodeCodes)
        if (!promocode) {
          dispatch({ type: 'apply:error' })
        } else {
          onApply(promocode.code, promocode)
          dispatch({ type: 'apply:success' })
        }
      } catch (e) {
        dispatch({ type: 'apply:error' })
      }
    }
  }, [state, companyId, checkPromocode, onApply, promocodeCodes])

  if (!companyId) {
    return (
      <PromocodeWrapper className={className} disabled>
        <Input value={''} disabled name={'promocode'} placeholder={t('form.placeholders.promocode')} />
        <Button intent={'primary'} disabled>
          {t('common:apply')}
        </Button>
      </PromocodeWrapper>
    )
  }

  const isAlreadyApplied = promocodes.some(item => item.code.toLowerCase() === state.promocode?.toLowerCase())
  const isInvalid = (typeof state.isCodeValid !== 'undefined' && !state.isCodeValid) || isAlreadyApplied

  const errorText = isAlreadyApplied ? t('alreadyAppliedPromocode') : t('invalidPromocode')
  return (
    <PromocodeWrapper className={className} disabled={!companyId} data-test-id={'promocodes'}>
      <Input
        onChange={handleChange}
        value={state.promocode}
        disabled={disabled}
        invalid={isInvalid && !!state.promocode}
        name={'promocode'}
        placeholder={t('form.placeholders.promocode')}
        errorText={errorText}
        type={'text'}
      />
      <Button
        intent={'primary'}
        onClick={handleApply}
        disabled={!state.promocode || disabled || isInvalid}
        progress={state.progress}
      >
        {t('common:apply')}
      </Button>
    </PromocodeWrapper>
  )
}

export default PromocodeField
