import React, { useCallback, useMemo } from 'react'
import { Progress, SectionBody, SectionContainer, Switch } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import { ProductsAvailability, ProductsAvailabilityDTO } from 'modules/domain/productsAvailability/types'
import { useTranslation } from 'react-i18next'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import * as Yup from 'yup'
import { CompanyType, Status } from 'types/entities'
import { SkuSelect } from 'views/components/SkuSelect/SkuSelect'
import styled from 'styled-components'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { useSelector } from 'react-redux'
import ProductsAvailabilitySelectors from 'modules/domain/productsAvailability/selectors'

export type ProductsAvailabilityFormFields = Pick<
  ProductsAvailability,
  'seller_id' | 'distributor_id' | 'sku_id' | 'sku' | 'is_available'
>

export type ProductsAvailabilityDetailsFormProps = {
  initialValues?: Partial<ProductsAvailabilityFormFields>
  progress?: Progress
  removeProgress?: Progress
  onSubmit(form: ProductsAvailabilityDTO): void
  onRemove?: () => void
  onCancel(): void
}

const Wrapper = styled.div`
  max-width: 510px;
`

export const ProductsAvailabilityDetailsForm: React.FC<ProductsAvailabilityDetailsFormProps> = ({
  initialValues,
  progress,
  removeProgress,
  onSubmit,
  onRemove,
  onCancel,
}) => {
  const { t } = useTranslation(['productsAvailability', 'common'])
  const addProgress = useSelector(ProductsAvailabilitySelectors.addProgress)
  const addErrorDetail = useSelector(ProductsAvailabilitySelectors.addErrorDetail)

  const validationSchema = useMemo(() => {
    return Yup.object({
      seller_id: Yup.string().required(t('validation:field_required')),
      distributor_id: Yup.string().required(t('validation:field_required')),
      sku_id: Yup.string().required(t('validation:field_required')),
      is_available: Yup.boolean().required(t('validation:field_required')),
    })
  }, [t])

  const formik = useFormik({
    initialValues: {
      seller_id: initialValues?.seller_id || '',
      distributor_id: initialValues?.distributor_id || '',
      sku_id: initialValues?.sku_id || '',
      is_available: !!initialValues?.is_available,
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  })

  const handleSellerChange = useCallback(
    (id?: string) => {
      formik.resetForm()
      formik.setFieldValue('seller_id', id)
    },
    [formik],
  )

  useValidationErrorNotification(formik.submitCount, formik.isValid || addProgress === Progress.ERROR, addErrorDetail)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <SectionContainer>
            <SectionBody>
              <CompanySelect
                label={t('form.labels.seller')}
                placeholder={t('form.placeholders.seller')}
                value={formik.values.seller_id}
                companyType={CompanyType.Producer}
                filter={{ is_seller: true }}
                onChange={handleSellerChange}
                required
              />
              <CompanySelect
                label={t('form.labels.retailer')}
                placeholder={t('form.placeholders.retailer')}
                value={formik.values.distributor_id}
                filter={{ for_producer_id: formik.values.seller_id }}
                companyType={CompanyType.Distributor}
                onChange={id => formik.setFieldValue('distributor_id', id)}
                required
                isDisabled={!formik.values.seller_id}
              />
              <SkuSelect
                label={t('form.labels.sku')}
                status={Status.Active}
                value={formik.values.sku_id}
                sellerId={formik.values.seller_id}
                isDisabled={!formik.values.seller_id}
                onChange={id => formik.setFieldValue('sku_id', id)}
                required
              />
              <Switch
                label={t('form.labels.availability')}
                on={!!formik.values.is_available}
                onClick={value => formik.setFieldValue('is_available', value)}
                testId="availability-switch"
              />
            </SectionBody>
          </SectionContainer>
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterDefaultControls
        onRemove={onRemove}
        onSave={formik.handleSubmit}
        onCancel={onCancel}
        saveProgress={progress}
        isSaveDisabled={!formik.dirty}
        removeProgress={removeProgress}
        popoverText={t('form.removeText', { sku: initialValues?.sku?.sku_id })}
      />
    </StickyFooterLayout.Wrapper>
  )
}
