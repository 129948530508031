import { SearchInput, useAction } from '@agro-club/frontend-shared'
import NotificationsListActions from 'modules/domain/notificationsList/duck'
import NotificationsListSelectors from 'modules/domain/notificationsList/selectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import List from './List/List'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const NotificationsList = () => {
  const { t } = useTranslation('notificationsList')
  const filterUpdateAction = useAction(NotificationsListActions.filterUpdated)
  const filter = useSelector(NotificationsListSelectors.filter)

  const handleFilterByAddress = (value: string) => {
    filterUpdateAction({
      ...filter,
      search: value,
    })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
          <SearchInputWrapper>
            <SearchInput placeholder={t('list.searchPlaceholder')} onChange={handleFilterByAddress} />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <List />
      </Layout.Content>
    </>
  )
}

export default NotificationsList
