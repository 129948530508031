import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const WizardNoteWrap = styled.div`
  max-width: 250px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 4px;
  overflow-x: hidden;
`

const WizardNoteLabel = styled.div`
  min-width: auto;
  margin-right: 5px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-size: 12px;
`
const WizardNoteText = styled.div`
  font-weight: 500;
  cursor: pointer;
`

export const DuckFootWizardData = ({ note }: { note: string }) => {
  const { t } = useTranslation('farmerOrder')

  return (
    <WizardNoteWrap>
      <WizardNoteLabel>{t('note')}:</WizardNoteLabel>
      <WizardNoteText>{note}</WizardNoteText>
    </WizardNoteWrap>
  )
}
