import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import {
  CardSkusRequestFilter,
  StorefrontItem,
  StorefrontListRequestFilter,
  StorefrontListRequestSorting,
  StorefrontSku,
  StorefrontSkuDTO,
  StorefrontSkuFilterSuggestItem,
} from './types'

const basicManagers = createBasicManagers<
  StorefrontListRequestFilter,
  StorefrontListRequestSorting,
  StorefrontItem,
  StorefrontSkuDTO,
  StorefrontSkuDTO
>(endpoints.sku)

const getSelectItems = (
  filter: StorefrontListRequestFilter & { ids?: string | string[] },
  sorting: StorefrontListRequestSorting,
  page: number,
  pageSize: number,
): Promise<ListResponse<StorefrontSkuFilterSuggestItem>> =>
  apiClient.get(endpoints.filterSuggest('sku'), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })

const getCardSkus = (cardId: string, filter: CardSkusRequestFilter) =>
  apiClient.get<ListResponse<StorefrontSku>>(endpoints.cardSkus(cardId), filter)

export default {
  ...basicManagers,
  getItemsByIds: createItemsByIds(false, basicManagers.getItem),
  getSelectItems,
  getSelectItemsById: async (ids: string) => {
    return (await getSelectItems({ ids }, {}, 1, 1)).data[0]
  },
  getSelectItemsByIds: createItemsByIds(true, ids => getSelectItems({ ids }, {}, 1, 1000)),
  getCardSkus,
} as const
