import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from 'views/components/Route/Route'

import ReturnDeclarationSkuRoutes from './routes'
import ReturnDeclarationSkuTable from 'views/pages/ReturnDeclarationSku/ReturnDeclarationSkuTable'
import ReturnDeclarationSkuEdit from './ReturnDeclarationSkuEdit'
import ReturnDeclarationSkuAdd from './ReturnDeclarationSkuAdd'
import { CAPABILITY, PERMISSION } from 'modules/permissions/permissions'

const ReturnDeclarationSkuPage: React.FC = () => {
  return (
    <Switch>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS_SKU}
        permission={PERMISSION.R}
        path={ReturnDeclarationSkuRoutes.List}
        exact
      >
        <ReturnDeclarationSkuTable />
      </Route>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS_SKU}
        permission={PERMISSION.U}
        path={ReturnDeclarationSkuRoutes.Edit}
        exact
      >
        <ReturnDeclarationSkuEdit />
      </Route>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS_SKU}
        permission={PERMISSION.C}
        path={ReturnDeclarationSkuRoutes.Add}
        exact
      >
        <ReturnDeclarationSkuAdd />
      </Route>
      <Redirect to={ReturnDeclarationSkuRoutes.List} />
    </Switch>
  )
}

export default ReturnDeclarationSkuPage
