import { Button, IconPlus, TableBodyCell, TableBodyRow } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, TableRowProps } from 'react-table'
import { CompoundedTableEditableCell } from './CompoundedTableEditableCell'

export type CompoundedTableEditableForm<FormItemType extends Object> = React.ComponentType<{
  entity?: FormItemType
  Buttons: React.ComponentType<{
    onSubmit(): void
    onRemove?: () => void
    updateText?: string
    removeText?: string
    createText?: string
    submitProgress?: Progress
    isSubmitDisabled?: boolean
    isRemoveDisabled?: boolean
    removeProgress?: Progress
  }>
}>

export type CompoundedTableEditableRowProps<
  DataItemType extends Object,
  FormItemType extends Object
> = TableRowProps & {
  row?: Row<DataItemType>
  FormComponent: CompoundedTableEditableForm<FormItemType>
  idx?: number | string
  progress?: Progress
  totalColumns: number
  getFormValuesFromRow: (row: Row<DataItemType>) => FormItemType
  newTitle?: React.ReactNode
  onOpen?: () => void
  onCreate?: () => void
  onUpdate?: () => void

  onClose?: () => void
  open?: boolean
}

export const CompoundedTableEditableRow = <DataItemType extends Object, FormItemType extends Object>({
  row,
  idx,
  FormComponent,
  totalColumns,
  getFormValuesFromRow,
  newTitle,
  onOpen,
  onClose,
  onCreate,
  onUpdate,
  open,
  ...props
}: CompoundedTableEditableRowProps<DataItemType, FormItemType>) => {
  const { t } = useTranslation('CompoundedTableEditable')

  const cells = useMemo(() => {
    if (!row) return null
    return row.cells.map(cell => {
      const { key, ...props } = cell.getCellProps()
      return (
        <TableBodyCell key={key} {...props} {...cell.column}>
          {cell.render('Cell')}
        </TableBodyCell>
      )
    })
  }, [row])

  const onClickForRow = !open ? onOpen : undefined

  const editRow = () => (
    <TableBodyRow {...props} onClick={onClickForRow} data-test-id={`table-row-${idx}`}>
      {open ? (
        <CompoundedTableEditableCell<DataItemType, FormItemType>
          getFormValuesFromRow={getFormValuesFromRow}
          row={row}
          mode="edit"
          totalColumns={totalColumns}
          FormComponent={FormComponent}
          onClose={onClose}
          onUpdate={onUpdate}
        />
      ) : (
        cells
      )}
    </TableBodyRow>
  )

  const newRow = () => (
    <>
      {open ? (
        <TableBodyRow {...props} onClick={onClickForRow} data-test-id={`table-row-${idx || 0}`}>
          <CompoundedTableEditableCell<DataItemType, FormItemType>
            getFormValuesFromRow={getFormValuesFromRow}
            row={row}
            mode="create"
            totalColumns={totalColumns}
            FormComponent={FormComponent}
            onClose={onClose}
            onCreate={onCreate}
          />
        </TableBodyRow>
      ) : (
        <tr>
          <td colSpan={totalColumns}>
            <Button
              intent="primary"
              size="small"
              variant="text"
              onClick={onClickForRow}
              data-test-id="create-new-button"
            >
              <IconPlus />
              {newTitle || t('defaultNewTitle')}
            </Button>
          </td>
        </tr>
      )}
    </>
  )

  return row ? editRow() : newRow()
}
