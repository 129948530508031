import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import FarmerOrderSkuActions from './duck'
import FarmerOrderSkuSelectors from './selectors'
import { FarmerOrderSku } from 'types/farmerOrderSku'
import { useEffect, useState } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useFarmerOrderSkuList: ResourceHook<FarmerOrderSku[], void[]> = () => {
  const progress = useSelector(FarmerOrderSkuSelectors.listFetchProgress)
  const list = useSelector(FarmerOrderSkuSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(FarmerOrderSkuActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useFarmerOrderSku: ResourceHook<FarmerOrderSku, [string]> = (id: string) => {
  const [mut, setMut] = useState(true)
  const progress = useSelector(FarmerOrderSkuSelectors.itemFetchProgress)
  const order = useSelector(state => FarmerOrderSkuSelectors.item(state, id))
  const fetchAction = useAction(FarmerOrderSkuActions.itemRequested, id)

  useDidMount(() => {
    // temp hack till order documents will be fixed
    fetchAction()
    setMut(false)
  })

  if (mut) {
    return [Progress.WORK, undefined]
  }

  return [progress, order]
}

export const useFarmerOrderSkuById: ResourceHook<FarmerOrderSku, [string]> = (id: string) => {
  const meta = useSelector(state => FarmerOrderSkuSelectors.meta(state, id))
  const item = useSelector(state => FarmerOrderSkuSelectors.item(state, id))
  const fetchAction = useAction(FarmerOrderSkuActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
