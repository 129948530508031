import React, { useMemo } from 'react'
import { Progress } from 'modules/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useTranslation } from 'react-i18next'
import { Button, helpersObject, Input, SectionBody, SectionContainer, FormComponents } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import { CompanyType } from 'types/entities'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

export type FormProps = {
  title: string
  external_id?: string
  company_id: string
}

const MainFormContainer = styled.div`
  max-width: 300px;
`

const TerritoryDetailsForm: React.FC<{
  mode: 'edit' | 'create'
  initialValues?: FormProps
  onRemove?(): void
  onSubmit(value: FormProps, options: { duplicate: boolean; dirty?: boolean }): void
  onCancel(): void
  progress?: Progress
  errorText?: string
  removeProgress?: Progress
}> = ({ progress, errorText, removeProgress, onRemove, onSubmit, onCancel, mode, initialValues }) => {
  const { t } = useTranslation(['territory', 'common'])

  const validationSchema = useMemo(() => {
    return Yup.object({
      title: Yup.string().required(t('validation:field_required')),
      company_id: Yup.string().required(t('validation:field_required')),
    })
  }, [t])

  const formik = useFormik({
    initialValues: initialValues || {
      company_id: '',
      external_id: '',
      title: '',
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()
      if (!formik.isValid) {
        return
      }
      onSubmit(
        {
          ...helpersObject.nonEmptyFields(formik.values),
        },
        { duplicate },
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const handleDuplicate = () => {
    submit(true)
  }

  // prettier-ignore
  const isDuplicateAllowed = mode === 'edit'
    ? !formik.dirty
      ? true
      : formik.isValid
    : formik.isValid

  const isEditWarning = progress === Progress.ERROR && errorText

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <MainFormContainer>
              <FormComponents.FormSection title={t('form.mainFormTitle')}>
                <CompanySelect
                  label={t('form.labels.producer')}
                  onChange={value => formik.setFieldValue('company_id', value)}
                  value={formik.values.company_id}
                  companyType={CompanyType.Producer}
                  isClearable
                  isSearchable
                  invalid={formik.touched.company_id && !!formik.errors.company_id}
                  errorText={formik.errors.company_id}
                  required
                />
                <Input
                  {...formik.getFieldProps('title')}
                  invalid={formik.touched.title && !!formik.errors.title}
                  errorText={formik.errors.title}
                  label={t('fields.title')}
                  required
                />
                <Input
                  {...formik.getFieldProps('external_id')}
                  invalid={formik.touched.external_id && !!formik.errors.external_id}
                  errorText={formik.errors.external_id}
                  label={t('fields.external_id')}
                />
              </FormComponents.FormSection>
            </MainFormContainer>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          onClick={handleSubmit}
          intent={'primary'}
          filled
          progress={progress}
          disabled={!formik.dirty || progress === Progress.WORK}
          data-test-id={'save-button'}
        >
          {t('common:save')}
        </Button>
        <Button onClick={handleDuplicate} disabled={!isDuplicateAllowed} progress={progress} intent={'primary'}>
          {mode === 'edit'
            ? formik.dirty
              ? t('form.saveAndDuplicate')
              : t('form.createDuplicate')
            : t('form.saveAndDuplicate')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />
        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues?.title || '' })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
      {isEditWarning ? (
        <StickyFooterLayout.NotificationWarning>
          <div>{errorText}</div>
        </StickyFooterLayout.NotificationWarning>
      ) : null}
    </StickyFooterLayout.Wrapper>
  )
}

export default TerritoryDetailsForm
