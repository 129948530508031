import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import actions from './duck'
import selectors from './selectors'

export const useTerritoryList = createItemListOnceHook(selectors, actions.listRequested)

export const useTerritory = createItemByIdOnceHook(selectors, actions.itemRequested)

export const useTerritoryById = createItemByIdHook(selectors, actions.itemRequested)
