import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'
import { AppGlobalState } from '../../../types'

const productsLimitForFarmerOrders = (state: AppGlobalState) => state.retailerAllocation.productsLimitForFarmerOrders
const productsLimitForRetailerOrders = (state: AppGlobalState) =>
  state.retailerAllocation.productsLimitForRetailerOrders
const productsLimitForFarmerOrdersFetchProgress = (state: AppGlobalState) =>
  state.retailerAllocation.productsLimitForFarmerOrdersFetchProgress
const productsLimitForRetailerOrdersFetchProgress = (state: AppGlobalState) =>
  state.retailerAllocation.productsLimitForRetailerOrdersFetchProgress

const RetailerAllocationSelectors = {
  ...createBasicSelectors('retailerAllocation'),
  productsLimitForFarmerOrders,
  productsLimitForRetailerOrders,
  productsLimitForFarmerOrdersFetchProgress,
  productsLimitForRetailerOrdersFetchProgress,
}

export default RetailerAllocationSelectors
