import { debounce, put } from 'redux-saga/effects'
import actions from './duck'

const reset = function*() {
  yield put(actions.reset())
}

const ModalSaga = function*() {
  yield debounce(100, actions.close.type, reset)
}

export default ModalSaga
