import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import {
  Allocation,
  AllocationDTO,
  AllocationListRequestSorting,
  AllocationRequestFilter,
  ProductLimitRequestFilter,
  ProductsLimitResponse,
} from '../common/types'

export const territoryManagers = {
  ...createBasicManagers<
    AllocationRequestFilter,
    AllocationListRequestSorting,
    Allocation,
    AllocationDTO,
    AllocationDTO
  >(endpoints.territoryAllocation),
  territoryProductLimit: (dto: ProductLimitRequestFilter) => {
    return apiClient.post<ProductsLimitResponse>(endpoints.territoryAllocation('check-products-limits'), dto)
  },
}
