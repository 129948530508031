import { AddButton, useHistoryPush } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Header from 'views/ui/Header/Header'
import CategoryRoutes from '../CategoryPage/routes'

export const CategoryHeader: React.VFC<{ tab: 'category' }> = ({ tab }) => {
  const { t } = useTranslation('category')

  const push = useHistoryPush()

  return (
    <Header.TabsRoot>
      <Header.Tab
        title={t('list.heading')}
        isActive={tab === 'category'}
        onClick={() => push({ route: CategoryRoutes.CategoryList })}
      >
        <AddButton to={CategoryRoutes.CategoryAdd} />
      </Header.Tab>
    </Header.TabsRoot>
  )
}
