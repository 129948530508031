import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFormik, FormikProvider } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import {
  Button,
  Input,
  FormComponents,
  SectionBody,
  SectionContainer,
  SimpleSelect,
  Switch,
  TextArea,
  useFormManager,
  Checkbox,
} from '@agro-club/frontend-shared'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'

import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import ImageForm, { GalleryLink } from 'views/components/ImageForm/ImageForm'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import { Progress } from 'modules/types'
import CollectionSelectors from 'modules/domain/collection/selectors'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { AvailableLanguages, availableLanguagesList, LocalizedValue } from 'types/entities'
import { CountryPhoneCode } from 'modules/domain/collection/types'
import FeatureFlagForm from './FeatureFlagForm'
import InputWithTags from 'views/components/InputWithTags/InputWithTags'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import * as Styled from './styled'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import { createLocalizedValue } from 'helpers/localization'
import ContactsForm from './ContactsForm'
import { CountryMultiSelect } from 'views/components/CountrySelect/CountryMultiSelect'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import LegalDocsForm from './LegalDocsForm'
import { FileItem } from 'views/components/FileManager/types'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import { isLangActive } from 'modules/utils/helpers'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'

export type FeatureFlagInput = {
  name: string
  value: boolean
}

export type LicenseFormInputs = {
  company_ids: string[]
  title: string
  default_country_code?: CountryCode
  available_countries?: CountryCode[]
  default_lang?: string
  company_website_url?: string | null
  logo_file?: FileItem
  copyright?: string
  footer_logo_file?: FileItem
  favicon_ico_file?: FileItem
  favicon_svg_file?: FileItem
  favicon_png_192_file?: FileItem
  favicon_png_512_file?: FileItem
  favicon_png_apple_touch_file?: FileItem

  og_title?: string | null
  og_description?: string | null
  og_image_file?: FileItem

  feature_flags: FeatureFlagInput[]

  intercom_app_id?: string
  zendesk_key?: string
  gtm_ids?: string[]
  phone_codes?: CountryPhoneCode[]
  domains: string[]
  spinner_file?: FileItem
  on_maintenance?: boolean
  phone_title?: LocalizedValue
  phone_value?: string
  email_title?: LocalizedValue
  email_value?: string
  user_agreement?: LocalizedValue<FileItem[] | string>
  privacy_policy?: LocalizedValue<FileItem[] | string>
  cookies_policy?: LocalizedValue<FileItem[] | string>
  google_play?: string
  app_store?: string
  mobile_apps_on_maintenance?: boolean
}

type LicenseFormProps = {
  mode: 'edit' | 'create'
  initialValues?: Partial<LicenseFormInputs>
  progress?: Progress
  removeProgress?: Progress
  onSubmit(form: LicenseFormInputs, options: { dirty: boolean; duplicate: boolean }): void
  onCancel(): void
  onRemove?(): void
}

type FilesFormProps = {
  files: FileItem[]
}

type FormikManagerData = LicenseFormInputs & {
  // TODO-256 optional params?
  logo_file: FilesFormProps
  footer_logo_file: FilesFormProps
  favicon_ico_file: FilesFormProps
  favicon_svg_file: FilesFormProps
  favicon_png_192_file: FilesFormProps
  favicon_png_512_file: FilesFormProps
  favicon_png_apple_touch_file: FilesFormProps
  og_image_file: FilesFormProps
  feature_flags: { featureFlags: FeatureFlagInput[] }
  spinner_file: FilesFormProps
}

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

const FormSection = styled(FormComponents.FormSection)`
  max-width: 300px;
`

const LicenseDetailsForm: React.FC<LicenseFormProps> = ({
  mode,
  initialValues = {},
  progress,
  removeProgress,
  onSubmit,
  onCancel,
  onRemove,
}) => {
  const { t } = useTranslation(['license', 'common', 'validation'])
  const { bind, submitAll, dirty, valid } = useFormManager<FormikManagerData>()
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const detectedCountry = useDetectedCountry()
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  // TODO-256 validation
  const validationSchema = Yup.object({
    company_ids: Yup.array()
      .required(t('validation:field_required'))
      .min(1, t('validation:field_required')),
    title: Yup.string().required(t('validation:field_required')),
    phone_value: Yup.string().test('is-valid-number', t('validation:invalid_phone_number'), value =>
      !value || phoneCodes.some(item => '+' + item.phone_prefix === value) ? true : isValidPhoneNumber(value || ''),
    ),
  })

  const handleSubmit = () => {
    submit(false)
  }

  const formik = useFormik<Partial<LicenseFormInputs>>({
    initialValues: {
      available_countries: [detectedCountry],
      ...(initialValues || {}),
      title: initialValues.title || '',
      company_ids: initialValues.company_ids || [],
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const languages = availableLanguagesList.map(item => {
    return {
      id: item,
      title: t(`common:langNames.${item}`),
    }
  })

  const ImageFormHint: React.FC<{ translationNamespace: string }> = ({ translationNamespace }) => {
    const { t } = useTranslation('license')

    return (
      <>
        <GalleryLink>{t(`${translationNamespace}.addMedia`)}</GalleryLink>
        {t(`${translationNamespace}.addMediaDescription1`)}
        <br />
        {t(`${translationNamespace}.addMediaDescription2`)}
        <br />
        {t(`${translationNamespace}.addMediaDescription3`)}
      </>
    )
  }

  const handlePhoneCodeSwitchClick = (value, phoneCode) => {
    if (value) {
      formik.setFieldValue('phone_codes', [...(formik.values.phone_codes || []), phoneCode])
    } else {
      formik.setFieldValue(
        'phone_codes',
        (formik.values.phone_codes || []).filter(pc => pc.id !== phoneCode.id),
      )
    }
  }

  const handleMaintenanceCheckboxClick = (_, value) => {
    formik.setFieldValue('on_maintenance', value)
  }

  const submit = async (duplicate = false) => {
    try {
      const [valid, forms] = await submitAll()

      if (!valid) {
        return
      }

      const constactsLangProp = (lang: AvailableLanguages, prop?: string) =>
        contactsLangs.includes(lang) ? prop || '' : undefined

      const legalDocsLangProp = (lang: AvailableLanguages, prop?: FileItem[]) =>
        legalDocsLangs.includes(lang) ? prop || '' : undefined

      onSubmit(
        {
          company_ids: formik.values.company_ids || [],
          title: formik.values.title || '',
          company_website_url: formik.values.company_website_url || null,
          default_country_code: formik.values.default_country_code,
          available_countries: formik.values.available_countries,
          default_lang: formik.values.default_lang,
          logo_file: forms.logo_file.files[0],
          footer_logo_file: forms.footer_logo_file.files[0],
          copyright: formik.values.copyright,

          favicon_ico_file: forms.favicon_ico_file.files[0],
          favicon_svg_file: forms.favicon_svg_file.files[0],
          favicon_png_192_file: forms.favicon_png_192_file.files[0],
          favicon_png_512_file: forms.favicon_png_512_file.files[0],
          favicon_png_apple_touch_file: forms.favicon_png_apple_touch_file.files[0],

          og_title: formik.values.og_title || null,
          og_description: formik.values.og_description || null,
          og_image_file: forms.og_image_file.files[0],

          feature_flags: forms.feature_flags.featureFlags,
          zendesk_key: formik.values.zendesk_key,
          intercom_app_id: formik.values.intercom_app_id,
          gtm_ids: formik.values.gtm_ids || [],
          phone_codes: formik.values.phone_codes,
          domains: formik.values.domains || [],
          spinner_file: forms.spinner_file.files[0],
          on_maintenance: formik.values.on_maintenance,
          mobile_apps_on_maintenance: formik.values.mobile_apps_on_maintenance,
          email_title: createLocalizedValue(lang => constactsLangProp(lang, forms[lang]?.email_title) as string),
          email_value: formik.values.email_value || '',
          phone_title: createLocalizedValue(lang => constactsLangProp(lang, forms[lang]?.phone_title) as string),
          phone_value: formik.values.phone_value || '',
          cookies_policy: createLocalizedValue(
            lang => legalDocsLangProp(lang, forms[lang]?.cookies_policy) as FileItem[],
          ),
          privacy_policy: createLocalizedValue(
            lang => legalDocsLangProp(lang, forms[lang]?.privacy_policy) as FileItem[],
          ),
          user_agreement: createLocalizedValue(
            lang => legalDocsLangProp(lang, forms[lang]?.user_agreement) as FileItem[],
          ),
          google_play: formik.values.google_play,
          app_store: formik.values.app_store,
        },
        {
          dirty,
          duplicate,
        },
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const allValid = valid && formik.isValid

  useValidationErrorNotification(formik.submitCount, allValid)

  const handleDuplicate = () => {
    submit(true)
  }

  // prettier-ignore
  const isDuplicateAllowed = mode === 'edit' ? (!dirty && !formik.dirty ? true : allValid) : allValid

  const handleAvailableCountriesChange = (value: CountryCode) => {
    const values = formik.values.available_countries
    if (!values) return formik.setFieldValue('available_countries', [value])

    const set = new Set(values)
    if (set.has(value)) {
      set.delete(value)
    } else {
      set.add(value)
    }

    formik.setFieldValue('available_countries', [...set])
  }

  const [contactsLangs, setContactsLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.phone_title?.[lang], initialValues?.email_title?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const [legalDocsLangs, setLegalDocsLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [
        initialValues?.cookies_policy?.[lang],
        initialValues?.privacy_policy?.[lang],
        initialValues?.user_agreement?.[lang],
      ].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const handleContactsLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(contactsLangs)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setContactsLanguages([...values])
  }

  const handleLegalDocsLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(legalDocsLangs)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLegalDocsLanguages([...values])
  }

  const isSaveBtnDisabled = (!dirty && !formik.dirty) || isFilesUploading || progress === Progress.WORK

  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <SectionContainer>
            <SectionBody>
              <Wrapper>
                <FormSection title={t('form.sections.mainSettings')}>
                  <Input
                    {...formik.getFieldProps('title')}
                    invalid={formik.touched.title && !!formik.errors.title}
                    label={t('form.labels.title')}
                    errorText={formik.errors.title}
                    required
                  />
                  <CompanyMultiSelect
                    isSearchable
                    isClearable
                    filter={{ is_seller: true }}
                    required
                    label={t('form.labels.companyIds')}
                    placeholder={t('form.placeholders.companies')}
                    values={formik.values.company_ids}
                    errorText={formik.errors.company_ids}
                    onMenuClose={() => formik.setFieldTouched('company_ids')}
                    invalid={formik.touched.company_ids && !!formik.errors.company_ids}
                    onChange={v => formik.setFieldValue('company_ids', v)}
                  />
                  <CountrySelect
                    value={formik.values.default_country_code}
                    onChange={v => formik.setFieldValue('default_country_code', v)}
                    label={t('form.labels.defaultCountry')}
                  />
                  <CountryMultiSelect
                    values={formik.values.available_countries}
                    onChange={handleAvailableCountriesChange}
                    label={t('form.labels.availableCountries')}
                  />
                  <InputWithTags
                    items={formik.values.domains || []}
                    onAdd={val => formik.setFieldValue('domains', [...(formik.values.domains || []), val])}
                    onRemove={val => {
                      const newArr = formik.values.domains?.slice()
                      newArr?.splice(+val, 1) ?? []
                      formik.setFieldValue('domains', newArr)
                    }}
                    label={t('form.labels.domains')}
                    placeholder={t('form.placeholders.domains')}
                  />
                  <SimpleSelect
                    value={formik.values.default_lang}
                    onChange={v => {
                      formik.setFieldValue('default_lang', v)
                    }}
                    invalid={formik.touched.default_lang && !!formik.errors.default_lang}
                    errorText={formik.errors.default_lang}
                    options={languages}
                    label={t('form.labels.defaultLang')}
                  />
                  <Input
                    {...formik.getFieldProps('company_website_url')}
                    invalid={formik.touched.company_website_url && !!formik.errors.company_website_url}
                    label={t('form.labels.companyWebsite')}
                  />
                  <Input {...formik.getFieldProps('copyright')} label={t('form.labels.copyright')} />
                </FormSection>
                <FormSection title={t('form.sections.logoFiles')}>
                  <Styled.FilesContainer>
                    <ImageForm
                      useFormik={bind('logo_file')}
                      files={initialValues.logo_file ? [initialValues.logo_file] : []}
                      limit={1}
                      accept={'image/png, image/svg+xml'}
                      title={t(`LogoForm.mainLogoTitle`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="LogoForm" />}
                    />
                    <ImageForm
                      useFormik={bind('footer_logo_file')}
                      files={initialValues.footer_logo_file ? [initialValues.footer_logo_file] : []}
                      limit={1}
                      accept={'image/png, image/svg+xml'}
                      title={t(`FooterLogoForm.footerLogoTitle`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FooterLogoForm" />}
                    />
                  </Styled.FilesContainer>
                </FormSection>
                <FormSection title={t('form.sections.contacts')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang, contactsLangs)}
                          value={lang}
                          onChange={handleContactsLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <PhoneInput
                    label={t('common:phone')}
                    name={'phone'}
                    invalid={formik.touched.phone_value && !!formik.errors.phone_value}
                    errorText={formik.errors.phone_value}
                    phoneNumber={formik.values.phone_value}
                    onChange={phone => {
                      formik.setFieldValue('phone_value', phone)
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('phone_value', true)
                    }}
                  />
                  <Styled.EmailArea>
                    <Input
                      label={t('common:email')}
                      {...formik.getFieldProps('email_value')}
                      invalid={formik.touched.email_value && !!formik.errors.email_value}
                      errorText={formik.errors.email_value}
                    />
                  </Styled.EmailArea>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang, contactsLangs)) {
                        return (
                          <ContactsForm
                            key={lang}
                            lang={lang}
                            phone_title={initialValues.phone_title}
                            email_title={initialValues.email_title}
                            useFormik={bind(lang as keyof FormikManagerData)}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormSection>
                <FormSection title={t('form.sections.appLinks')}>
                  <Input label={t('form.labels.googlePlay')} {...formik.getFieldProps('google_play')} />
                  <Input label={t('form.labels.appStore')} {...formik.getFieldProps('app_store')} />
                </FormSection>
                <FormSection title={t('form.sections.licenseDetails')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang, legalDocsLangs)}
                          value={lang}
                          onChange={handleLegalDocsLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang, legalDocsLangs)) {
                        return (
                          <LegalDocsForm
                            key={lang}
                            lang={lang}
                            cookies_policy={initialValues.cookies_policy as LocalizedValue<FileItem[]>}
                            privacy_policy={initialValues.privacy_policy as LocalizedValue<FileItem[]>}
                            user_agreement={initialValues.user_agreement as LocalizedValue<FileItem[]>}
                            useFormik={bind(lang as keyof FormikManagerData)}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormSection>
                <FormSection title={t('form.sections.favicon')}>
                  <Styled.FaviconContainer>
                    <ImageForm
                      useFormik={bind('favicon_ico_file')}
                      files={initialValues.favicon_ico_file ? [initialValues.favicon_ico_file] : []}
                      limit={1}
                      accept={'image/x-icon'}
                      title={t(`FaviconIcoForm.icoTitle`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FaviconIcoForm" />}
                    />
                    <ImageForm
                      useFormik={bind('favicon_svg_file')}
                      files={initialValues.favicon_svg_file ? [initialValues.favicon_svg_file] : []}
                      limit={1}
                      accept={'image/svg+xml'}
                      title={t(`FaviconSvgForm.svgTitle`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FaviconSvgForm" />}
                    />
                    <ImageForm
                      useFormik={bind('favicon_png_192_file')}
                      files={initialValues.favicon_png_192_file ? [initialValues.favicon_png_192_file] : []}
                      limit={1}
                      accept={'image/png'}
                      title={t(`FaviconPng192Form.faviconPng192Title`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FaviconPng192Form" />}
                    />
                    <ImageForm
                      useFormik={bind('favicon_png_512_file')}
                      files={initialValues.favicon_png_512_file ? [initialValues.favicon_png_512_file] : []}
                      limit={1}
                      accept={'image/png'}
                      title={t(`FaviconPng512Form.faviconPng512Title`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FaviconPng512Form" />}
                    />
                    <ImageForm
                      useFormik={bind('favicon_png_apple_touch_file')}
                      files={
                        initialValues.favicon_png_apple_touch_file ? [initialValues.favicon_png_apple_touch_file] : []
                      }
                      limit={1}
                      accept={'image/png'}
                      title={t(`FaviconPngAppleTouchForm.appleTouchIconTitle`)}
                      renderGalleryHint={() => <ImageFormHint translationNamespace="FaviconPngAppleTouchForm" />}
                    />
                  </Styled.FaviconContainer>
                </FormSection>
                <FormSection title={t('form.sections.spinner')}>
                  <ImageForm
                    useFormik={bind('spinner_file')}
                    files={initialValues.spinner_file ? [initialValues.spinner_file] : []}
                    limit={1}
                    accept={'image/svg+xml'}
                    title={t(`SpinnerSvgForm.spinnerTitle`)}
                    renderGalleryHint={() => <ImageFormHint translationNamespace="SpinnerSvgForm" />}
                  />
                </FormSection>
                <FormSection title={t('form.sections.openGraph')}>
                  <Input
                    {...formik.getFieldProps('og_title')}
                    invalid={formik.touched.og_title && !!formik.errors.og_title}
                    label={t('form.labels.ogTitle')}
                  />
                  <TextArea
                    {...formik.getFieldProps('og_description')}
                    invalid={formik.touched.og_description && !!formik.errors.og_description}
                    label={t('form.labels.ogDescription')}
                    limit={500}
                  />
                  <ImageForm
                    useFormik={bind('og_image_file')}
                    files={initialValues.og_image_file ? [initialValues.og_image_file] : []}
                    limit={1}
                    accept={'image/png'}
                    title={t(`OgImageForm.ogTitle`)}
                    renderGalleryHint={() => <ImageFormHint translationNamespace="OgImageForm" />}
                  />
                </FormSection>
                <FormSection title={t('form.sections.phoneCodes')}>
                  {phoneCodes.map(phoneCode => (
                    <Switch
                      key={phoneCode.id}
                      on={formik.getFieldProps('phone_codes').value.find(pc => pc.id === phoneCode.id)}
                      onClick={value => handlePhoneCodeSwitchClick(value, phoneCode)}
                      label={phoneCode.id}
                    />
                  ))}
                </FormSection>
                <FormSection title={t('form.sections.thirdPartyKeys')}>
                  <Input
                    {...formik.getFieldProps('intercom_app_id')}
                    invalid={formik.touched.intercom_app_id && !!formik.errors.intercom_app_id}
                    label={t('form.labels.intercomAppId')}
                  />
                  <Input
                    {...formik.getFieldProps('zendesk_key')}
                    invalid={formik.touched.zendesk_key && !!formik.errors.zendesk_key}
                    label={t('form.labels.zendeskKey')}
                  />
                  <InputWithTags
                    items={formik.values.gtm_ids || []}
                    onAdd={val => formik.setFieldValue('gtm_ids', [...(formik.values.gtm_ids || []), val])}
                    onRemove={val => {
                      const newArr = formik.values.gtm_ids?.slice()
                      newArr?.splice(+val, 1) ?? []
                      formik.setFieldValue('gtm_ids', newArr)
                    }}
                    label={t('form.labels.gtmIds')}
                    placeholder={t('form.placeholders.gtmIds')}
                  />
                </FormSection>
                <FormSection title={t('form.sections.maintenance')}>
                  <Checkbox
                    isChecked={Boolean(formik.values.on_maintenance)}
                    onChange={handleMaintenanceCheckboxClick}
                    label={t('form.labels.maintenance')}
                  />
                  <Checkbox
                    isChecked={Boolean(formik.values.mobile_apps_on_maintenance)}
                    onChange={(_, value) => {
                      formik.setFieldValue('mobile_apps_on_maintenance', value)
                    }}
                    label={t('form.labels.mobile_maintenance')}
                  />
                </FormSection>
                <FormSection title={t('form.sections.features')}>
                  <FeatureFlagForm featureFlags={initialValues.feature_flags || []} useFormik={bind('feature_flags')} />
                </FormSection>
              </Wrapper>
            </SectionBody>
          </SectionContainer>
        </StickyFooterLayout.Body>

        <StickyFooterLayout.ButtonsFooter>
          <Button
            filled={true}
            progress={progress}
            disabled={isSaveBtnDisabled}
            onClick={formik.submitForm}
            intent={'primary'}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>

          <Button onClick={handleDuplicate} disabled={!isDuplicateAllowed} progress={progress} intent={'primary'}>
            {mode === 'edit'
              ? dirty || formik.dirty
                ? t('form.saveAndDuplicate')
                : t('form.createDuplicate')
              : t('form.saveAndDuplicate')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
          {onRemove && (
            <StickyFooterDeleteBtn
              onRemove={onRemove}
              removeProgress={removeProgress}
              popoverText={t('form.removeText', { title: initialValues.title })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default LicenseDetailsForm
