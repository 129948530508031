import { Currency } from 'types/entities'
import { Company } from 'modules/domain/company/types'
import { ProductEntry } from './entities'
import { Season } from 'modules/domain/season/types'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'

export enum ReturnDeclarationStatus {
  New = 'new',
  Submitted = 'submitted',
  NothingToDeclare = 'nothing_to_declare',
  Deleted = 'deleted',
}

export type ReturnDeclarationItem = ProductEntry & {
  // only for React list key purpose
  key: string
  lot_number?: string
}

export type ReturnDeclaration = {
  id: string
  owner_id: string
  producer: Company | null
  status: ReturnDeclarationStatus
  producer_id: string | null
  items: ReturnDeclarationItem[]
  slug: string
  type?: ReturnDeclarationType
  currency?: Currency
  region_id?: string | null
  distributor_id?: string | null
  pickup_note?: string
  pickup_address?: string
  distributor?: Company | null
  created_at?: string
  comment?: string
  revision?: string
  declaration_date?: string
  season?: Season
  season_id?: string
}
