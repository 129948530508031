import { Input, useAction } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CropTargetSku } from 'modules/domain/target2sku/common/types'
import CropTargetSkuActions from 'modules/domain/target2sku/cropTarget/duck'
import CropTargetSkuSelectors from 'modules/domain/target2sku/cropTarget/selectors'
import { Progress } from 'modules/types'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isAgro, isProducer } from 'types/entities'
import { CompoundedTableEditableForm } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditableRow'
import * as Yup from 'yup'
import { TargetSkuManufacturerSelect } from '../selects/TargetSkuManufacturerSelect'
import { TargetSkuSubcategorySelect } from '../selects/TargetSkuSubcategorySelect'
import { TargetSkuTerritorySelect } from '../selects/TargetSkuTerritorySelect'
import { FormWrapper } from '../styles'

export const CropTargetSkuForm: CompoundedTableEditableForm<CropTargetSku> = ({ Buttons, entity }) => {
  const { t } = useTranslation(['target2', 'validation'])

  const addRequested = useAction(CropTargetSkuActions.addRequested)
  const addProgress = useSelector(CropTargetSkuSelectors.addProgress)
  const addErrorDetail = useSelector(CropTargetSkuSelectors.addErrorDetail)

  const removeRequested = useAction(CropTargetSkuActions.removeRequested)
  const removeProgress = useSelector(CropTargetSkuSelectors.removeProgress)
  const removeErrorDetail = useSelector(CropTargetSkuSelectors.removeErrorDetail)

  const updateProgress = useSelector(CropTargetSkuSelectors.updateProgress)
  const meta = useSelector(CropTargetSkuSelectors.meta(entity?.id))

  const updateRequested = useAction(CropTargetSkuActions.updateRequested)
  const userCompany = useSelector(AuthSelectors.userCompany)

  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)
  const agro = isAgro(role)

  const validationSchema = useMemo(() => {
    return entity
      ? Yup.object({
          target_value: Yup.number().required(t('validation:field_required')),
        })
      : Yup.object({
          territory_id: Yup.string().required(t('validation:field_required')),
          manufacturer_id: Yup.string().required(t('validation:field_required')),
          target_value: Yup.number().required(t('validation:field_required')),
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, !!entity])

  // This is to prevent reinitialize on every render
  const initialValues = useMemo(
    () => ({
      territory_id: '',
      manufacturer_id: userCompany && producer ? userCompany.id : '',
      target_value: entity?.target_value ?? 0,
      subcategory_id: entity?.subcategory?.id ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity?.target_value],
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (entity?.id) {
        updateRequested(entity.id, { target_value: values.target_value })
      } else {
        addRequested(values, false)
      }
    },
  })

  const isEdit = !!entity

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && !entity) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress])

  // Reset form on update success
  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity?.target_value])

  useValidationErrorNotification(
    formik.submitCount,
    formik.isValid,
    removeErrorDetail || entity ? meta.updateErrorDetail : addErrorDetail,
  )

  const handleChangeManufacturer = val => {
    formik.setFieldValue('manufacturer_id', val)
    formik.setFieldValue('territory_id', '')
    formik.setFieldValue('subcategory_id', '')
  }

  return (
    <>
      <FormWrapper>
        {agro && (
          <TargetSkuManufacturerSelect
            required
            label={t('form.labels.manufacturer')}
            placeholder=""
            isDisabled={isEdit}
            value={formik.values.manufacturer_id}
            valueLabel={entity?.manufacturer?.title}
            onChange={handleChangeManufacturer}
            invalid={formik.touched.manufacturer_id && !!formik.errors.manufacturer_id}
          />
        )}
        <TargetSkuTerritorySelect
          required
          placeholder=""
          label={t('form.labels.territory')}
          isDisabled={isEdit || !formik.values.manufacturer_id}
          value={formik.values.territory_id}
          valueLabel={entity?.territory?.title}
          onChange={val => {
            formik.setFieldValue('territory_id', val)
          }}
          invalid={formik.touched.territory_id && !!formik.errors.territory_id}
          manufacturerId={formik.values.manufacturer_id}
        />
        {!isEdit && (
          <TargetSkuSubcategorySelect
            label={t('form.labels.subcategory')}
            placeholder=""
            value={formik.values.subcategory_id}
            onChange={val => {
              formik.setFieldValue('subcategory_id', val)
            }}
            manufacturerId={formik.values.manufacturer_id}
            isClearable
            isDisabled={!formik.values.manufacturer_id}
          />
        )}
        <Input
          required
          label={t('form.labels.target')}
          {...formik.getFieldProps('target_value')}
          invalid={formik.touched.target_value && !!formik.errors.target_value}
          type="number"
          min={0}
        />
      </FormWrapper>
      <Buttons
        onSubmit={formik.handleSubmit}
        onRemove={entity ? () => removeRequested(entity.id) : undefined}
        submitProgress={isEdit ? updateProgress : addProgress}
        removeProgress={removeProgress}
        createText={t('form.create')}
        removeText={t('form.delete')}
        updateText={t('form.update')}
        isSubmitDisabled={!formik.dirty}
      />
    </>
  )
}
