import { Status } from 'types/entities'
import { StorefrontItemType, StorefrontSku } from './types'

export const fakeSku: StorefrontSku = {
  id: '',
  type: StorefrontItemType.Product,
  sku_id: '',
  seller_id: '',
  status: Status.Active,
  special_prices: [],
  params: {
    type: 'product',
    product_id: '',
    package_id: '',
    package_capacity: 0,
    options: [],
  },
  is_out_of_stock: false,
  price_type: 'not_specified',
  available_for: [],
}
