import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import useDebouncedEffect from 'hooks/useDebouncedEffect'
import ProductOptionsActions from 'modules/domain/productOptions/duck'
import ProductOptionsSelectors from 'modules/domain/productOptions/selectors'
import { ProductOptionsListRequestFilter, ProductOptionType } from 'modules/domain/productOptions/types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { StorefrontHeader } from 'views/pages/Storefront/components/StorefrontHeader'
import { SearchInputWrapper } from 'views/pages/Storefront/Storefront/StorefrontList/styled'
import ProductOptionsTable from './ProductOptionsTable'

const ProductOptionsList: React.FC = () => {
  const { t } = useTranslation(['product', 'productOptions'])

  const filter = useSelector(ProductOptionsSelectors.filter)
  const filterUpdated = useAction(ProductOptionsActions.filterUpdated)

  const typeFilterOptions = useMemo(
    () => [
      {
        value: ProductOptionType.ACTIVE_INGREDIENT,
        title: t('productOptions:types.active_ingredient'),
      },
      {
        value: ProductOptionType.DELIVERY_OPTION,
        title: t('productOptions:types.delivery_option'),
      },
      {
        value: ProductOptionType.INOCULANT,
        title: t('productOptions:types.inoculant'),
      },
      {
        value: ProductOptionType.PRODUCT,
        title: t('productOptions:types.product'),
      },
      {
        value: ProductOptionType.SEED_TREATMENT,
        title: t('productOptions:types.seed_treatment'),
      },
    ],
    [t],
  )

  const handleFilterChange = (newValue: Partial<ProductOptionsListRequestFilter>) => {
    filterUpdated({ ...filter, ...newValue })
  }

  const [search, setSearch] = useState(filter.title)

  useDebouncedEffect(
    () => {
      handleFilterChange({ title: search })
    },
    300,
    [search],
  )

  useHelmet({ title: t('productOptions:productOptionsMetaTitle') })

  return (
    <TComponents.Wrapper>
      <Layout.Header>
        <StorefrontHeader tab={'productOptions'} />
        <SearchInputWrapper>
          <SearchInput onChange={setSearch} value={search} placeholder={t('productOptions:list.searchPlaceholder')} />
        </SearchInputWrapper>
        <TComponents.Filters>
          <TComponents.FiltersWrapper>
            <TableFilter
              title={t('productOptions:list.optionType')}
              options={typeFilterOptions}
              handleFilterChange={handleFilterChange}
              filterValue={{ type: filter.type }}
              disableMulti
            />
          </TComponents.FiltersWrapper>
        </TComponents.Filters>
      </Layout.Header>
      <Layout.Content>
        <ProductOptionsTable />
      </Layout.Content>
    </TComponents.Wrapper>
  )
}

export default ProductOptionsList
