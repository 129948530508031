import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Uptake, UptakeListRequestSorting, UptakeListRequestFilter, UptakeDTO } from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: UptakeListRequestFilter,
  sorting: UptakeListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Uptake>>(endpoints.uptake(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<Uptake>(endpoints.uptake(id))
}

export const addItem = (dto: UptakeDTO) => {
  return apiClient.post<Uptake>(endpoints.uptake(), dto)
}

export const updateItem = (id: string, dto: Partial<UptakeDTO>) => {
  return apiClient.put<Uptake>(endpoints.uptake(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<Uptake>(endpoints.uptake(id))
}

export const getPendingCount = async () => {
  const response = await apiClient.get<ListResponse<Uptake>>(endpoints.uptake(), { page_size: 1 })
  return response.total_count
}
