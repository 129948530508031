import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Checkbox } from '@agro-club/frontend-shared'
import { IncentiveProgramScope } from 'modules/domain/incentiveProgram/types'

const Title = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
  white-space: nowrap;
`
const Wrapper = styled.div`
  display: flex;
`

const Item = styled.div`
  &:last-child {
    margin-left: 20px;
  }
`

type PropsType = {
  onChange: (scope: IncentiveProgramScope, on: boolean) => void
  scopes?: IncentiveProgramScope[]
}
export const IncentiveProgramScopeSwitcher: React.VFC<PropsType> = ({ onChange, scopes }: PropsType) => {
  const { t } = useTranslation('incentiveProgram')
  const handleChangeDiscountScope = useCallback(
    (field: IncentiveProgramScope) => (_: unknown, isChecked: boolean) => {
      onChange(field, isChecked)
    },
    [onChange],
  )

  return (
    <div>
      <Title>{t('form.discountFor')}</Title>
      <Wrapper>
        <Item>
          <Checkbox
            label={t('form.labels.farmer')}
            isChecked={scopes?.includes(IncentiveProgramScope.FarmerOrders)}
            onChange={handleChangeDiscountScope(IncentiveProgramScope.FarmerOrders)}
            testId={'incentive-program-scope-farmer'}
          />
        </Item>
        <Item>
          <Checkbox
            label={t('form.labels.distributor')}
            isChecked={scopes?.includes(IncentiveProgramScope.DistributorOrder)}
            onChange={handleChangeDiscountScope(IncentiveProgramScope.DistributorOrder)}
            testId={'incentive-program-scope-distributor'}
          />
        </Item>
      </Wrapper>
    </div>
  )
}
