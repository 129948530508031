import { ProgramSummaryListEntry } from 'modules/domain/programSummary/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
const TooltipWrapper = styled.div`
  width: 500px;
`
const TooltipBlockWrapper = styled.div`
  margin: 32px;
`
const TooltipBlockItemWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-content: center;
`
const TooltipBlockItemTitle = styled.div`
  color: ${props => props.theme.color.secondary200};
  font-weight: 500;
  width: 220px;
`
const TooltipBlockItemContent = styled.div`
  font-weight: 500;
  width: 280px;
`
type Props = {
  summary?: ProgramSummaryListEntry
}
const RetailerTooltip: React.FC<Props> = ({ summary }) => {
  const { t } = useTranslation('programSummary')
  const retailerName = summary?.retailer_title.official_name
  const tooltipData = [
    {
      header: '',
      rows: [
        {
          title: t('labels.retailer'),
          value: retailerName,
        },
        {
          title: t('labels.territory'),
          value: summary?.territories.map(t => t.territory_title.title).join(', '),
        },
        {
          title: t('labels.agroclubId'),
          value: summary?.retailer_id,
        },
        {
          title: t('labels.growerReconciled'),
          value: summary?.reconciled?.status,
        },
      ],
    },
  ]
  return (
    <TooltipWrapper>
      {tooltipData.map((item, i) => (
        <TooltipBlockWrapper key={i}>
          {item.rows.map((row, j) => (
            <TooltipBlockItemWrapper key={j}>
              <TooltipBlockItemTitle>{row.title}</TooltipBlockItemTitle>
              <TooltipBlockItemContent>{row.value}</TooltipBlockItemContent>
            </TooltipBlockItemWrapper>
          ))}
        </TooltipBlockWrapper>
      ))}
    </TooltipWrapper>
  )
}
export default RetailerTooltip
