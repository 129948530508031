import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import {
  DistributorOrderSku,
  DistributorOrderSkuDTO,
  DistributorOrderSkuListRequestFilter,
  DistributorOrderSkuListRequestSorting,
} from './types'

export default {
  ...createBasicManagers<
    DistributorOrderSkuListRequestFilter,
    DistributorOrderSkuListRequestSorting,
    DistributorOrderSku,
    DistributorOrderSkuDTO,
    DistributorOrderSkuDTO
  >(endpoints.distributorOrderSku),
  ordersCount: async (filter: DistributorOrderSkuListRequestFilter = {}) => {
    const response = await apiClient.get<ListResponse<DistributorOrderSku>>(endpoints.distributorOrderSku('count'), {
      ...filter,
      page_size: 1,
    })
    return response
  },
} as const
