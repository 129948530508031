import { CustomSelectStyles, IconMoreVert, Input, Switch } from '@agro-club/frontend-shared'
import styled, { css, keyframes, StyledProps } from 'styled-components'
import { theme } from 'theme/theme'

export const customRowMenuStyles: CustomSelectStyles = {
  control: () => ({}),
  valueContainer: () => ({
    height: '0',
    background: 'transparent',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 0',
  }),
  menu: base => ({
    ...base,
    right: '8px',
    minWidth: '120px !important',
    zIndex: 10,
  }),
  option: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    height: '28px',
    minHeight: '28px',
  }),
}

const flash = keyframes`
  from {
    border-color: ${theme.color.secondary100};
    background-color: ${theme.color.backgroundSecondary};
  }
  25% {
    border-color: ${theme.color.primary600};
    background-color:  ${theme.color.primary8a};
  }
  50% {
    border-color: ${theme.color.secondary100};
    background-color: ${theme.color.backgroundSecondary};
  }
  75% {
    border-color: ${theme.color.primary600};
    background-color: ${theme.color.primary8a};
  }
  100% {
    border-color: ${theme.color.secondary100};
    background-color: ${theme.color.backgroundSecondary};
  }
`

export const AnimatedFilter = styled.div<{ animated: boolean }>`
  & [class$='control'] {
    animation: ${props =>
      props.animated
        ? css`
            ${flash} 1.5s linear
          `
        : ''};
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: 100%;
  overflow: hidden;
`

export const SearchInputWrapper = styled.div`
  width: 50%;
  padding: 18px 24px;
`

export const StorefrontFilters = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  padding: 12px 24px 16px;
  z-index: 2;
  gap: 16px;
  border-top: 1px solid ${props => props.theme.color.outlineNeutral};
  background-color: ${props => props.theme.color.onPrimaryLight};
`

export const StorefrontContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-height: 0;
  width: 100%;
  padding-bottom: 0;
`

export const baseRowCss = css`
  display: grid;
  grid-template-columns: 20px 150px minmax(150px, auto) 150px 116px 150px 80px;
  grid-gap: 16px;
`

export const HeaderCell = styled.div`
  display: flex;
  padding: 10px 0;
  border-right: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  align-items: center;
  line-height: 20px;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const ListWrapper = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
`

export const HeaderRow = styled.div`
  ${baseRowCss};
  position: sticky;
  top: 0;
  grid-template-columns: 20px 150px minmax(150px, auto) 150px 116px 150px 80px;
  padding-left: 13px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: ${props => props.theme.color.onPrimaryLight};
  font-weight: 500;
  z-index: 1;
  padding: 0 24px 0 36px;
`

export const Inner = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const DropInfoContainer = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'flex' : 'none')};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 64px;
  background-color: #000;
  font-size: 48px;
  opacity: 0.48;
  pointer-events: none;
  color: ${props => props.theme.color.onSurfaceLowEmphasys};
  z-index: 1;
`

export const ProductRowContainer = styled.div<{ selected?: boolean; isDragging?: boolean }>`
  ${baseRowCss};
  padding: 12px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.selected ? props.theme.color.primary600 : 'transparent')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  background-color: ${props => (props.selected ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
  opacity: ${props => (props.isDragging ? 0 : 1)};

  &:hover {
    border-color: ${props => props.theme.color.primary600};
    background-color: ${props => props.theme.color.primary25};
  }
`

export const NameText = styled.div`
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

export const ItemsCount = styled.span`
  margin-left: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const QtyWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const QtyInput = styled(Input)`
  width: 64px;
`

export const Unit = styled.div`
  margin-left: 8px;
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const ProductRemoveCell = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
`

export const ProductRemoveButton = styled.button`
  border: none;
  line-height: 24px;
  outline: none;
  background-color: transparent;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.secondary200};
`

export const BundleWrapper = styled.div<{ selected?: boolean; isDragging?: boolean }>`
  padding: 12px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.selected ? props.theme.color.primary600 : 'transparent')};
  background-color: ${props => (props.selected ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  opacity: ${props => (props.isDragging ? 0 : 1)};

  &:hover {
    border-color: ${props => props.theme.color.primary600};
    background-color: ${props => props.theme.color.primary25};
  }
`

export const BundleHeadingRow = styled.div`
  ${baseRowCss};
  grid-template-columns: auto 116px 150px 80px;
`

export const BundleItemsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`

export const BundleRowWrapper = styled.div<{ isDragging: boolean }>`
  ${baseRowCss};
  position: relative;
  padding-top: 24px;
  border-radius: 8px;
  grid-template-columns: 20px 150px minmax(150px, auto) 150px 116px 150px 80px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: ${props => (props.isDragging ? '0 4px 8px rgba(0, 0, 0, 0.04)' : 'none')};
  opacity: ${props => (props.isDragging ? 0 : 1)};

  &::before {
    display: ${props => (props.isDragging ? 'none' : 'block')};
    position: absolute;
    left: 0;
    top: 12px;
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.color.onSurfaceLowEmphasys};
    content: '';
  }
`

export const BundleRowDropZone = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'flex' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #000;
  opacity: 0.46;
  pointer-events: none;
  color: ${props => props.theme.color.onSurfaceLowEmphasys};
  font-size: 26px;
`

export const ToggleButton = styled.button`
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
  background: none;
  transition: opacity 0.2s ease-in;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const ControlsRow = styled.div`
  display: grid;
  max-width: 120px;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
`

export const RowOptionsButton = styled(IconMoreVert)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: 0.2s opacity ease;
  color: ${props => props.theme.color.secondary300};
`

export const RowMenuContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  align-self: end;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.primary50};

    svg > path {
      opacity: 0.8;
      fill: ${props => props.theme.color.primary600};
    }
  }
`

export const StyledSwitch = styled(Switch)`
  align-self: start;
  & button {
    margin-right: 0;
  }
`

export const ModalContent = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

export const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const PaginationWrapper = styled.div`
  align-self: self-end;
`

export const NoData = styled.div`
  text-align: center;
  padding: 40px;
  color: ${props => props.theme.color.secondary200};
`

export const EmptyMessage = styled.div``

export const Status = styled.span<StyledProps<{ status?: string }>>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  display: inline-block;
  background-color: ${({ theme, status }: StyledProps<{ status?: string }>) => {
    switch (status) {
      case 'active':
        return theme.color.accentApproving
      case 'inactive':
        return theme.color.accentNotify
      case 'deleted':
        return theme.color.accentDestructive
    }
  }};
`

export const DefaultStatus = styled(Status)`
  background-color: transparent;
  border: 1px solid;
  margin-left: 0;
`

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export const SkuIdBold = styled.span`
  font-weight: 600;
`

export const ProductPriceContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 8px;
`

export const SkuContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 6px;
  width: fit-content;
  line-height: 20px;
  font-size: 14px;
`

export const ProductAvailabilityContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`

export const ProductInStockContainer = styled.div`
  display: grid;
  grid-gap: 28px;
  justify-items: end;
`
