import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Category } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.category.items
const ids = (state: AppGlobalState) => state.category.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Category | undefined => state.category.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Category> =>
  state.category.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.category.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.category.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.category.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.category.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.category.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Category[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const subcategoryList = (state: AppGlobalState) => state.category.subcategoryList

const filter = (state: AppGlobalState) => state.category.filter
const sorting = (state: AppGlobalState) => state.category.sorting
const page = (state: AppGlobalState) => state.category.page
const total = (state: AppGlobalState) => state.category.total
const updateProgress = (state: AppGlobalState) => state.category.updateProgress
const removeProgress = (state: AppGlobalState) => state.category.removeProgress
const addProgress = (state: AppGlobalState) => state.category.addProgress
const hasNext = (state: AppGlobalState) => state.category.total > state.category.ids.length
const pageSize = (state: AppGlobalState) => state.category.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.category.total / state.category.pageSize)

const CategorySelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  subcategoryList,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default CategorySelectors
