import React, { useMemo, useState } from 'react'

import * as Styled from './styled'
import { Currency, FormField, FormikHook, NumberInput } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FieldMetaProps } from 'formik/dist/types'
import { HistoryQtyTierRule } from 'modules/domain/discountRule/types'
import { useCompanyById } from 'modules/domain/company/hooks'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'

export type HistoryDiscountTierRule = Partial<Omit<HistoryQtyTierRule, 'type'>>

type HistoryQtyTiersRulesFormProps = {
  tiers_rules: HistoryDiscountTierRule[]
}

const HistoryQtyTiersRulesForm: React.FC<{
  useFormik: FormikHook
  producerId: string
  tiers_rules: HistoryDiscountTierRule[]
}> = ({ tiers_rules, producerId, useFormik }) => {
  const { t } = useTranslation('discountRules')
  const [, producer] = useCompanyById(producerId)

  const validationSchema = useMemo(() => {
    return Yup.object({
      tiers_rules: Yup.array(
        Yup.object({
          min_qty: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
          amount: Yup.string().required(t('validation:field_required')),
          historic_season_id: Yup.string().required(t('validation:field_required')),
          min_qty_historic: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<HistoryQtyTiersRulesFormProps>({
    initialValues: {
      tiers_rules,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = (idx: number) => {
    formik.setFieldValue('tiers_rules', remove(idx, 1, formik.values.tiers_rules))
  }

  const handleAddClick = () => {
    formik.setFieldValue('tiers_rules', [...formik.values.tiers_rules, { min_qty: 1, amount: '', min_qty_historic: 1 }])
  }

  const handleNumberInputChange = (field, value) => {
    formik.setFieldValue(field, value)
    formik.setFieldTouched(field)
  }

  return (
    <Styled.ParamsContainer data-test-id={'params-form'}>
      {formik.values.tiers_rules.map((_, idx) => {
        const minKey = `tiers_rules[${idx}].min_qty`
        const maxKey = `tiers_rules[${idx}].max_qty`
        const amountKey = `tiers_rules[${idx}].amount`
        const historicMinKey = `tiers_rules[${idx}].min_qty_historic`
        const historicMaxKey = `tiers_rules[${idx}].max_qty_historic`
        const historicSeasonKey = `tiers_rules[${idx}].historic_season_id`

        const getFieldMeta = (field: string): FieldMetaProps<HistoryQtyTiersRulesFormProps> =>
          formik.getFieldMeta(`tiers_rules[${idx}].${field}`)

        return (
          <Styled.Container key={idx}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <Styled.TierTitle>{`${t('form.labels.tierTitle')} ${idx + 1}`}</Styled.TierTitle>
            <FormField
              invalid={getFieldMeta('min_qty').touched && !!getFieldMeta('min_qty').error}
              errorText={getFieldMeta('min_qty').error}
              label={t('form.labels.minQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(minKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(minKey, value)}
                  min={1}
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('max_qty').touched && !!getFieldMeta('max_qty').error}
              errorText={getFieldMeta('max_qty').error}
              label={t('form.labels.maxQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(maxKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(maxKey, value)}
                  min={0}
                />
              )}
            />
            <FormField
              invalid={getFieldMeta('amount').touched && !!getFieldMeta('amount').error}
              errorText={getFieldMeta('amount').error}
              label={t('form.labels.amount')}
              render={_ => (
                <Styled.CurrencyInput
                  {...formik.getFieldProps(amountKey)}
                  value={formik.values.tiers_rules[idx].amount || ''}
                  placeholder={t('form.placeholders.amount')}
                  currency={producer?.currency || Currency.CAD}
                  onChange={val => formik.setFieldValue(amountKey, val)}
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('min_qty_historic').touched && !!getFieldMeta('min_qty_historic').error}
              errorText={getFieldMeta('min_qty_historic').error}
              label={t('form.labels.historicMinQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(historicMinKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(historicMinKey, value)}
                  min={1}
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('max_qty_historic').touched && !!getFieldMeta('max_qty_historic').error}
              errorText={getFieldMeta('max_qty_historic').error}
              label={t('form.labels.historicMaxQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(historicMaxKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(historicMaxKey, value)}
                  min={0}
                />
              )}
            />
            <FormField
              invalid={getFieldMeta('historic_season_id').touched && !!getFieldMeta('historic_season_id').error}
              errorText={getFieldMeta('historic_season_id').error}
              label={t('form.labels.previousSeason')}
              render={_ => (
                <SeasonSelect
                  {...formik.getFieldProps(historicSeasonKey)}
                  onChange={value => handleNumberInputChange(historicSeasonKey, value)}
                  companyId={producerId}
                  isClearable
                />
              )}
              required
            />
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <Styled.AddButton onClick={handleAddClick} type={'button'}>
        {t('form.addMoreTiers')}
      </Styled.AddButton>
    </Styled.ParamsContainer>
  )
}

export default HistoryQtyTiersRulesForm
