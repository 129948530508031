import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'
import * as uuid from 'uuid'
import { FileItem } from 'views/components/FileManager/types'

export const pickFileUrl = (file?: FileItem) => {
  if (!file) {
    return null
  }
  switch (file.kind) {
    case 'current':
      if (typeof file.file === 'string') {
        return file.file
      }
      return null
    case 'added':
      return waitUntilFileLoaded(file.id)
  }
  return null
}

export const makeCurrentFile = (src: string | File): FileItem | undefined => {
  if (!src) {
    return undefined
  }

  return {
    id: uuid.v4(),
    file: src,
    kind: 'current' as const,
    error: null,
  }
}
