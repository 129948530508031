import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import PromocodeTable from './PromocodeTable/PromocodeTable'
import styled from 'styled-components'
import { AddButton, SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import PromocodeActions from 'modules/domain/promocode/duck'
import PromocodeRoutes from 'views/pages/Promocode/routes'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const PromocodeList: React.FC = () => {
  const { t } = useTranslation('promocode')
  const filter = useSelector(PromocodeSelectors.filter)
  const filterUpdated = useAction(PromocodeActions.filterUpdated)

  useHelmet({ title: t('metaTitle') })

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={PromocodeRoutes.Add} />
          </Header.Title>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filter.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <PromocodeTable />
      </Layout.Content>
    </>
  )
}

export default PromocodeList
