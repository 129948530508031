import { push } from 'connected-react-router'
import { COUNTERS_FETCH_DELAY_MS } from 'modules/constants'
import { FarmerOrderListRequestFilter, FarmerOrderListResponse } from 'modules/domain/farmerOrder/types'
import { RequestError } from 'modules/errors'
import { CAPABILITY, isPermitted, PERMISSION } from 'modules/permissions/permissions'
import { ticker, updateLocationQuery } from 'modules/sagaHelpers'
import { generatePath } from 'react-router-dom'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { isAgro, isDistributor, isProducer } from 'types/entities'
import { FarmerOrder, FarmerOrderStatus } from 'types/farmerOrder'
import * as uuid from 'uuid'
import { AddRetailerOrderSuggestionContent } from 'views/components/AddRetailerOrderSuggestionContent/AddRetailerOrderSuggestionContent'
import { FileData, FileItem } from 'views/components/FileManager/types'
import FarmerOrderRoutes from 'views/pages/FarmerOrder/routes'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'
import AuthSelectors from '../auth/selectors'
import ModalActions from '../modal/duck'
import { waitUntilFileLoaded } from '../uploadManager/sagas'
import FarmerOrderActions from './duck'
import * as managers from './managers'
import FarmerOrderSelectors from './selectors'

const prepareOrder = (order: FarmerOrder): FarmerOrder => {
  order.items.forEach(item => (item.key = uuid.v4()))
  return order
}

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(FarmerOrderSelectors.page)
    const filter = yield select(FarmerOrderSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(FarmerOrderActions.filtersUpdatedWithPersistentStorage(filterUpdated))

    const sorting = yield select(FarmerOrderSelectors.sorting)
    const pageSize = yield select(FarmerOrderSelectors.pageSize)
    let response: FarmerOrderListResponse = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage || 1,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }
    const { data, page, total_count } = response
    yield put(
      FarmerOrderActions.listRequestSucceed(
        data,
        total_count,
        response.meta.total_qty,
        response.meta.total_final_qty,
        response.meta.total_delivered_qty,
        page,
      ),
    )

    if (currentPage) {
      yield call(updateLocationQuery, FarmerOrderRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.listRequestFailed(errType))
  }
}

export const fetchListUpdate = function*() {
  try {
    const currentPage = yield select(FarmerOrderSelectors.page)
    const filter = yield select(FarmerOrderSelectors.filter)
    const sorting = yield select(FarmerOrderSelectors.sorting)
    const pageSize = yield select(FarmerOrderSelectors.pageSize)
    const response: FarmerOrderListResponse = yield call(managers.getList, filter, sorting, currentPage || 1, pageSize)
    const {
      data,
      total_count,
      meta: { total_qty, total_final_qty, total_delivered_qty },
    } = response
    yield put(
      FarmerOrderActions.listUpdateRequestSucceed(data, total_count, total_qty, total_final_qty, total_delivered_qty),
    )
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.listUpdateRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(FarmerOrderSelectors.page)
    const filter = yield select(FarmerOrderSelectors.filter)
    const sorting = yield select(FarmerOrderSelectors.sorting)
    const pageSize = yield select(FarmerOrderSelectors.pageSize)
    const { data, total_count }: { data: FarmerOrder[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(FarmerOrderActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof FarmerOrderActions.itemRequested>) {
  try {
    const order: FarmerOrder = yield call(managers.getItem, id)
    yield put(FarmerOrderActions.itemRequestSucceed(prepareOrder(order)))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.itemRequestFailed(id, errType))
  }
}

const openAddRetailerOrderSuggest = function*({
  payload: [order, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderActions.openAddRetailerOrderSuggestion>) {
  const oldOrder: FarmerOrder | undefined = yield select(FarmerOrderSelectors.item, order.id)

  const role = yield select(AuthSelectors.role)

  const isChangedDistributorConfirmation =
    (!oldOrder || oldOrder?.interaction.confirmed_by_distributor !== 'confirmed') &&
    order.interaction?.confirmed_by_distributor === 'confirmed'
  const isModalShowPermitted = isPermitted(role, CAPABILITY.DISTRIBUTOR_ORDERS, PERMISSION.CR)

  const showModal = isChangedDistributorConfirmation && isAddRetailerOrderSuggestionEnabled && isModalShowPermitted
  if (showModal) {
    yield put(
      ModalActions.open({
        contentProps: {
          farmerOrder: order,
        },
        content: AddRetailerOrderSuggestionContent,
      }),
    )
  }
}

export const addItem = function*({
  payload: [dto, duplicate, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderActions.addRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const contenders = filesMeta.filter(file => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const files = fileItems.filter(item => typeof item === 'string').map(url => ({ url })) as FileData[]
    const order: FarmerOrder = yield call(managers.addItem, { ...rest, files })
    const prepared = prepareOrder(order)
    yield put(FarmerOrderActions.openAddRetailerOrderSuggestion(prepared, isAddRetailerOrderSuggestionEnabled))
    yield put(FarmerOrderActions.addSucceed(prepared))

    if (!duplicate) {
      yield put(push(generatePath(FarmerOrderRoutes.Edit, { id: order.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.addFailed(errType))
  }
}

export const updateItem = function*({
  payload: [id, dto, duplicate, isAddRetailerOrderSuggestionEnabled],
}: ReturnType<typeof FarmerOrderActions.updateRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const contenders = filesMeta.filter((file: FileItem) => file.kind === 'current' || file.kind === 'added')
    const fileItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const files = fileItems.filter(item => typeof item === 'string').map(url => ({ url })) as FileData[]
    const order: FarmerOrder = yield call(managers.updateItem, id, { ...rest, files })
    const prepared = prepareOrder(order)
    yield put(FarmerOrderActions.openAddRetailerOrderSuggestion(prepared, isAddRetailerOrderSuggestionEnabled))
    yield put(FarmerOrderActions.updateSucceed(prepared))

    if (duplicate) {
      yield put(push(FarmerOrderRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof FarmerOrderActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(FarmerOrderActions.removeSucceed(payload))
    yield put(push(FarmerOrderRoutes.List))
    yield put(FarmerOrderActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(FarmerOrderActions.removeFailed(payload, errType))
  }
}

export const newOrdersCountFetcher = ticker(function*({ role }) {
  try {
    const params: FarmerOrderListRequestFilter = {
      status: FarmerOrderStatus.New,
    }
    // only unapproved new orders qty for agroclub users
    if (isAgro(role)) {
      params.confirmed_by_agroclub = false
    } else if (isProducer(role)) {
      // only awaiting producer confirmation orders for producer users
      params.confirmed_by_agroclub = true
      params.confirmed_by_producer = false
    } else if (isDistributor(role)) {
      // only awaiting distributor confirmation orders for distributor users
      params.confirmed_by_agroclub = true
      params.confirmed_by_producer = true
      params.confirmed_by_distributor = 'unset' as const
    }
    const count = yield call(managers.ordersCount, params)
    yield put(FarmerOrderActions.newOrdersCountRequestSucceed(count))
  } catch (err) {
    console.error(err)
    yield put(FarmerOrderActions.newOrdersCountRequestError())
    const errType = err instanceof RequestError ? err.type : 'unknown'
    if (['forbidden_error', 'unauthorized_error'].includes(errType)) {
      return false
    }
  }
  return true
}, COUNTERS_FETCH_DELAY_MS)

const OrderSaga = function*() {
  yield all([
    takeLatest(FarmerOrderActions.itemRequested.type, fetchItem),
    takeLatest(FarmerOrderActions.listRequested.type, fetchList),
    takeLatest(FarmerOrderActions.filterUpdated.type, fetchList),
    takeLatest(FarmerOrderActions.sortingUpdated.type, fetchList),
    takeLatest(FarmerOrderActions.filterHasBeenReset.type, fetchList),
    takeLatest(FarmerOrderActions.sortingHasBeenReset.type, fetchList),
    takeLatest(FarmerOrderActions.openAddRetailerOrderSuggestion.type, openAddRetailerOrderSuggest),

    takeLatest(FarmerOrderActions.listRequestedNext.type, fetchListNext),
    takeLatest(FarmerOrderActions.listUpdateRequested.type, fetchListUpdate),

    takeLatest(FarmerOrderActions.addRequested.type, addItem),
    takeLatest(FarmerOrderActions.updateRequested.type, updateItem),
    takeLatest(FarmerOrderActions.removeRequested.type, removeItem),

    fork(newOrdersCountFetcher),
  ])
}

export default OrderSaga
