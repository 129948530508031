import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import { Snackbar, Progress } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'

const UpdateErrorPopup: React.FC = () => {
  const { t } = useTranslation(['errors'])
  const updateProgress = useSelector(InventoryExchangeSelectors.deliveryOptionProgress)
  const [isVisible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(updateProgress === Progress.ERROR)
  }, [updateProgress])
  return isVisible ? (
    <Snackbar
      onClose={() => {
        setVisible(false)
      }}
    >
      {t('errors:unknown_error')}
    </Snackbar>
  ) : null
}

export default UpdateErrorPopup
