import React, { useEffect, useMemo, useState } from 'react'
import RegionsModal from 'views/components/PickRegionsForm/RegionsModal'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Input, Radio, RadioItem } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
`
const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 18px 24px;
  max-height: 600px;
`
const EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  margin-bottom: 32px;
`
const EmailInputStyled = styled(Input)`
  margin-bottom: 20px;
`
const EmailRadioStyled = styled(Radio)`
  margin-bottom: 20px;
`

type EmailModalProps = {
  isOpen: boolean
  resend: (email?: string) => void
  onClose: () => void
}
type EmailFormProps = {
  email?: string
}

const EmailModal: React.FC<EmailModalProps> = ({ isOpen, onClose, resend }) => {
  const { t } = useTranslation(['notificationsList', 'common'])
  const profile = useSelector(AuthSelectors.profile)
  const userEmailExist = !!profile?.email
  const [useCurrentEmail, setUseCurrentEmail] = useState(userEmailExist)

  useEffect(() => {
    if (useCurrentEmail) {
      formik.setFieldValue('email', profile?.email)
    }
  }, [useCurrentEmail])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email(t('validation:email_invalid'))
          .required(),
      }),
    [t],
  )

  const formik = useFormik<EmailFormProps>({
    initialValues: {
      email: profile?.email,
    },
    validationSchema,
    onSubmit: values => {
      resend(values.email)
      onClose()
    },
  })

  return (
    <RegionsModal isOpen={isOpen} onClose={onClose}>
      <EmailWrapper>
        <Title>{t('form.sectionHeaders.selectEmail')}</Title>
        <EmailFormWrapper>
          <EmailRadioStyled value={+useCurrentEmail} direction="row" onChange={e => setUseCurrentEmail(!!e)}>
            <RadioItem value={1} disabled={!userEmailExist} label={t('labels.sendToCurrentEmail')} />
            <RadioItem value={0} label={t('labels.sendToAnyEmail')} />
          </EmailRadioStyled>
          <EmailInputStyled
            {...formik.getFieldProps('email')}
            invalid={formik.touched.email && !!formik.errors.email}
            label={t('form.email')}
            disabled={useCurrentEmail}
            errorText={formik.errors.email}
            data-test-id={'email'}
          />
          <Button
            data-test-id={'next-button'}
            intent={'primary'}
            disabled={!!formik.errors.email}
            filled={true}
            onClick={() => {
              formik.submitForm()
            }}
          >
            {t('form.send')}
          </Button>
        </EmailFormWrapper>
      </EmailWrapper>
    </RegionsModal>
  )
}

export default EmailModal
