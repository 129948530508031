import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import {
  FarmersState,
  FarmerListRequestFilter,
  FarmerListRequestSorting,
  Farmer,
  FarmerDTO,
  FarmerData,
  FarmerMetaData,
  AddToFarmerCartDTO,
} from './types'

const initialState: FarmersState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  itemMetaDataFetchProgress: Progress.IDLE,
  itemMetaDataFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  addErrorDetail: '',
  updateProgress: Progress.IDLE,
  updateError: null,
  updateErrorDetail: '',
  removeProgress: Progress.IDLE,
  removeError: null,

  farmersWaitingAgreementCount: 0,
  farmersWaitingAgreementProgress: Progress.IDLE,

  ordersTotalCountFetchProgress: Progress.IDLE,
  ordersTotalCountFetchError: null,

  addProductToCartProgress: Progress.IDLE,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class FarmersReducer extends ImmerReducer<FarmersState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  listRequested(params: { filter?: FarmerListRequestFilter; sorting?: FarmerListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Farmer[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK
    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(farmer: Farmer) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.items[farmer.id] = farmer

    if (this.draftState.meta[farmer.id]) {
      this.draftState.meta[farmer.id].fetchProgress = Progress.SUCCESS
      this.draftState.meta[farmer.id].fetchError = null
    } else {
      this.draftState.meta[farmer.id] = {
        id: farmer.id,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    }
  }
  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  itemMetaDataRequested(_id: string, _farmer: FarmerData) {
    this.draftState.itemMetaDataFetchProgress = Progress.WORK
  }

  itemMetaDataRequestSucceed(metaData: FarmerMetaData) {
    this.draftState.itemMetaDataFetchProgress = Progress.SUCCESS
    this.draftState.items[metaData.obj_id].metadata = metaData
    this.draftState.itemMetaDataFetchError = null
  }

  itemMetaDataRequestFailed(error: FetchError) {
    this.draftState.itemMetaDataFetchProgress = Progress.ERROR
    this.draftState.itemMetaDataFetchError = error
  }

  ordersTotalCountFetchRequested(_farmerId: string) {
    this.draftState.ordersTotalCountFetchProgress = Progress.WORK
  }

  ordersTotalCountFetchSucceed(farmerId: string, count: number) {
    this.draftState.ordersTotalCountFetchProgress = Progress.SUCCESS

    this.draftState.items[farmerId] = {
      ...this.draftState.items[farmerId],
      ordersTotalCount: count,
    }

    this.draftState.ordersTotalCountFetchError = null
  }

  ordersTotalCountFetchFailed(error: FetchError) {
    this.draftState.ordersTotalCountFetchProgress = Progress.ERROR
    this.draftState.ordersTotalCountFetchError = error
  }

  itemRequestedByPhone(phone: string) {
    this.draftState.itemFetchProgress = Progress.WORK
    this.draftState.meta[phone] = {
      id: phone,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
      fetchProgress: Progress.IDLE,
      fetchError: null,
    }
  }

  itemRequestByPhoneSucceed(farmer: FarmerData, phone_number: string) {
    this.draftState.items[farmer.id] = farmer

    if (this.draftState.meta[farmer.id]) {
      this.draftState.meta[farmer.id].fetchProgress = Progress.SUCCESS
      this.draftState.meta[farmer.id].fetchError = null
    } else {
      this.draftState.meta[farmer.id] = {
        id: farmer.id,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    }

    const phone = this.draftState.meta[farmer.phone_number] ? farmer.phone_number : phone_number

    if (this.draftState.meta[farmer.phone_number] || this.draftState.meta[phone_number]) {
      this.draftState.meta[phone].fetchProgress = Progress.SUCCESS
      this.draftState.meta[phone].fetchError = null
    } else {
      this.draftState.meta[farmer.phone_number] = {
        id: farmer.phone_number,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    }
  }

  itemRequestByPhoneFailed(phone: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[phone] = {
      id: phone,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
      fetchProgress: Progress.ERROR,
      fetchError: null,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: FarmerDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
  }

  addSucceed(farmer: Farmer) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[farmer.id] = farmer
    this.draftState.meta[farmer.id] = {
      id: farmer.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }
  addFailed(type: AddError, detail: string | undefined) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = type

    if (detail) this.draftState.addErrorDetail = detail
  }

  addByRetailerRequested(_dto: FarmerDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, farmer: Partial<FarmerDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.itemMetaDataFetchProgress = Progress.WORK
  }
  updateSucceed(farmer: Farmer) {
    const metadata = this.draftState.items[farmer.id].metadata
    this.draftState.items[farmer.id] = {
      ...farmer,
      metadata,
    }
    this.draftState.meta[farmer.id].updateProgress = Progress.SUCCESS
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.itemMetaDataFetchProgress = Progress.SUCCESS
  }
  updateFailed(id: string, type: UpdateError, detail: string | undefined) {
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = type
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateError = type
    this.draftState.itemMetaDataFetchProgress = Progress.ERROR

    if (detail) {
      this.draftState.meta[id].updateErrorDetail = detail
      this.draftState.updateErrorDetail = detail
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = error
  }

  filterUpdated(filter: FarmerListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: FarmerListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: Farmer[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
  }

  farmersWaitingAgreementCountRequested() {
    this.draftState.farmersWaitingAgreementProgress = Progress.WORK
  }
  farmersWaitingAgreementCountRequestSucceed(count: number) {
    this.draftState.farmersWaitingAgreementProgress = Progress.SUCCESS
    this.draftState.farmersWaitingAgreementCount = count
  }
  farmersWaitingAgreementCountRequestError() {
    this.draftState.farmersWaitingAgreementProgress = Progress.ERROR
  }

  addProductToFarmerCartRequested(_payload: AddToFarmerCartDTO) {
    this.draftState.addProductToCartProgress = Progress.WORK
  }
  addProductToFarmerCartSucceed() {
    this.draftState.addProductToCartProgress = Progress.SUCCESS
  }
  addProductToFarmerCartFailed() {
    this.draftState.addProductToCartProgress = Progress.ERROR
  }
  addProductToFarmerCartClearProgress() {
    this.draftState.addProductToCartProgress = initialState.addProductToCartProgress
  }
}

export const FarmerActions = createActionCreators(FarmersReducer)
export default FarmerActions
export const reducer = createReducerFunction(FarmersReducer, initialState)
