import React, { useState } from 'react'
import { DocumentItemStatus } from 'modules/domain/document/types'
import * as Styled from './styled'
import DocumentActions from 'modules/domain/document/duck'
import FarmerDocumentItem from 'views/components/FarmerDocumentItem/FarmerDocumentItem'
import FarmerDocumentPreviewModal from 'views/components/FarmerDocumentPreviewModal/FarmerDocumentPreviewModal'
import { Modal, SectionBody, SectionContainer, useAction } from '@agro-club/frontend-shared'
import PdfViewer from 'views/ui/PdfViewer/PdfViewer'

const InstructionsModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'large'}>
      <Styled.DocumentModalInner>
        <PdfViewer url={'/Document_Submission_Instructions.pdf'} />
      </Styled.DocumentModalInner>
    </Modal>
  )
}

const OrderDocuments: React.FC<{ userId?: string; documentIds: string[]; selectedDocument?: string }> = ({
  userId,
  documentIds,
  selectedDocument,
}) => {
  const [farmerDocPopupState, setFarmerDocPopupState] = useState<{
    isOpen: boolean
    documentId: string
    status: DocumentItemStatus | null
  }>({ isOpen: false, documentId: '', status: null })
  const [instructionPopupState, setInstructionPopupState] = useState(false)
  const downloadAction = useAction(DocumentActions.documentDownloadRequested)
  const printAction = useAction(DocumentActions.documentPrintRequested)
  const sendEmailAction = useAction(DocumentActions.signRequestToUserEmailRequested)

  const handlePreviewClick = (documentId: string, status: DocumentItemStatus | null) => {
    setFarmerDocPopupState({ isOpen: true, documentId, status })
  }

  const handleDownloadClick = (documentId: string, status: DocumentItemStatus, fileName?: string) => {
    downloadAction({ userId, documentId, fileName })
  }

  const handlePrintClick = (documentId: string) => {
    printAction({ userId, documentId })
  }

  const handleSendEmailClick = (documentId: string) => {
    sendEmailAction(userId, documentId)
  }

  const handleInstructionsClick = () => {
    setInstructionPopupState(true)
  }

  return (
    <SectionContainer>
      <SectionBody>
        <Styled.ListWrapper>
          {documentIds.map(id => {
            return (
              <FarmerDocumentItem
                key={id}
                documentId={id}
                userId={userId}
                onPrintClick={handlePrintClick}
                onPreviewClick={handlePreviewClick}
                onDownloadClick={handleDownloadClick}
                onSendEmailClick={handleSendEmailClick}
                onInfoClick={handleInstructionsClick}
                selected={id === selectedDocument}
              />
            )
          })}
        </Styled.ListWrapper>
      </SectionBody>
      {farmerDocPopupState.isOpen ? (
        <FarmerDocumentPreviewModal
          {...farmerDocPopupState}
          userId={userId}
          onClose={() => setFarmerDocPopupState({ ...farmerDocPopupState, isOpen: false })}
        />
      ) : null}
      {instructionPopupState ? (
        <InstructionsModal isOpen={true} onClose={() => setInstructionPopupState(false)} />
      ) : null}
    </SectionContainer>
  )
}

export default OrderDocuments
