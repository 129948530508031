import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { Progress } from 'modules/types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { ChangeLogEntry, ChangeLogFilter, ChangeLogSorting, ChangeLogState } from './types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'

const initialState: ChangeLogState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchErrorDetail: null,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextErrorDetail: null,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchErrorDetail: null,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addErrorDetail: null,
  addError: null,
  updateProgress: Progress.IDLE,
  updateErrorDetail: null,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeErrorDetail: null,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class ChangeLogReducer extends BasicEntityImmerReducer<
  ChangeLogEntry,
  ChangeLogFilter,
  ChangeLogSorting,
  {},
  {},
  ChangeLogState
> {}

export const ChangeLogActions = createActionCreators(ChangeLogReducer)
export default ChangeLogActions
export const reducer = createReducerFunction(ChangeLogReducer, initialState)
