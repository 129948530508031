import { takeLatest, put } from 'redux-saga/effects'
import actions from './duck'

const reset = function*() {
  yield put(actions.reset())
}

const SnackbarSaga = function*() {
  yield takeLatest(actions.close.type, reset)
}

export default SnackbarSaga
