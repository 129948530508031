import React, { useMemo, useCallback } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable, useSortBy } from 'react-table'
import { format, parseISO } from 'date-fns'
import {
  SimpleSelect,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { useNotificationsList } from 'modules/domain/notificationsList/hooks'
import { NotificationsListEntry, NotificationsListEntryStatus } from 'modules/domain/notificationsList/types'
import { Filter } from 'views/components/TableFilters/TableFilters'
import Status from '../Status'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import NotificationsListSelectors from 'modules/domain/notificationsList/selectors'
import NotificationsListActions from 'modules/domain/notificationsList/duck'
import NotificationsListRoutes from '../routes'

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 240px;
`

const HeadCellWrapper = styled.div`
  color: gray;
`

const DateSendCell: React.FC<CellProps<NotificationsListEntry>> = ({ row, column }) => {
  return (
    <div key={column.id}>
      <div>{format(parseISO(row.values.date_send), 'yyyy.MM.dd / HH:mm')}</div>
    </div>
  )
}

const StatusCell: React.FC<CellProps<NotificationsListEntry>> = ({ row, column }) => {
  return (
    <div key={column.id}>
      <Status status={row.values.status} />
    </div>
  )
}

const options = Object.values(NotificationsListEntryStatus).map(status => ({ id: status, title: status }))

const NotificationsList: React.FC = () => {
  const { t } = useTranslation(['notificationsList', 'labels'])
  const push = useHistoryPush()
  const total = useSelector(NotificationsListSelectors.total)
  const page = useSelector(NotificationsListSelectors.page)
  const pageSize = useSelector(NotificationsListSelectors.pageSize)
  const filter = useSelector(NotificationsListSelectors.filter)
  const filterUpdateAction = useAction(NotificationsListActions.filterUpdated)
  const listRequested = useAction(NotificationsListActions.listRequested)
  const pages = useSelector(NotificationsListSelectors.pages)
  const [progress, data = []] = useNotificationsList()
  const { scrollRef } = usePersistentScroll('notificationsList')

  const handleFilterByStatus = useCallback(
    (value: string) => {
      filterUpdateAction({
        ...filter,
        status: value as NotificationsListEntryStatus,
      })
    },
    [filter, filterUpdateAction],
  )

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.status'),
        accessor: 'status' as const,
        sortable: false,
        Cell: StatusCell,
      },
      {
        Header: t('fields.type'),
        accessor: 'type' as const,
        sortable: false,
      },
      {
        Header: t('fields.communicationChannel'),
        accessor: 'communication_channel' as const,
        sortable: false,
      },
      {
        Header: t('fields.dateSend'),
        accessor: 'date_send' as const,
        sortable: true,
        Cell: DateSendCell,
      },
      {
        Header: t('fields.addressTo'),
        accessor: 'address_to' as const,
        sortable: false,
      },
    ],
    [t],
  )

  const initialState = useMemo(
    () => ({
      hiddenColumns: ['id'],
      sortBy: [
        {
          id: 'date_send',
          desc: false,
        },
      ],
    }),
    [],
  )

  const { columns, rows, prepareRow, setSortBy } = useTable<NotificationsListEntry>(
    {
      columns: allColumns,
      data,
      initialState,
    },
    useSortBy,
  )

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <Filter title={t('labels:status')}>
          <SelectWrapper data-test-id="notificationsList-select">
            <SimpleSelect isClearable onChange={handleFilterByStatus} value={filter.status} options={options} />
          </SelectWrapper>
        </Filter>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell
                  onClick={() => {
                    setSortBy([{ id: 'date_send', desc: !column.isSortedDesc }])
                  }}
                  isSortDesc={column.isSortedDesc}
                  sortable
                  isSorted={column.id === 'date_send'}
                  key={column.id}
                >
                  <HeadCellWrapper>{column.render('Header')}</HeadCellWrapper>
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody data-test-id="body">
          {rows.map((row, index) => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()

            return (
              <TableBodyRow
                data-test-id={`cell-${index}`}
                onClick={() => {
                  push({ route: NotificationsListRoutes.Item, params: { id: row.original.id.toString() } })
                }}
                key={key}
                {...props}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default NotificationsList
