import React, { useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { Progress } from 'modules/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'

import { useBadge } from 'modules/domain/badge/hooks'
import BadgeRoutes from 'views/pages/Badge/routes'
import BadgeDetailsForm from 'views/pages/Badge/BadgeDetailsForm/BadgeDetailsForm'
import BadgeSelectors from 'modules/domain/badge/selectors'
import BadgeActions from 'modules/domain/badge/duck'
import { makeCurrentFile } from 'modules/domain/badge/helpers'
import { BadgeActionWithFiles } from 'modules/domain/badge/types'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const BadgeEdit = () => {
  const push = useHistoryPush()
  const { t } = useTranslation('badge')
  const { id: currentBadgeId } = useParams<{ id: string }>()
  const meta = useSelector(state => BadgeSelectors.meta(state, currentBadgeId))
  const badgeEditAction = useAction(BadgeActions.updateRequested)
  const removeAction = useAction(BadgeActions.removeRequested, currentBadgeId)
  const page = useSelector(BadgeSelectors.page)
  const [fetchProgress, badge] = useBadge(currentBadgeId)

  useHelmet({ title: badge ? badge.name : t('badgesMetaTitle') })

  const goBack = useCallback(() => {
    push({ route: BadgeRoutes.List, query: { page } })
  }, [page, push])

  const error404 = () => <Item404 id={currentBadgeId} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => (
    <ItemGenericError id={currentBadgeId} onBack={goBack} title={t('errors.unknownErrorTitle')} />
  )

  const handleSubmit = useCallback(
    (fields: BadgeActionWithFiles) => {
      badgeEditAction(currentBadgeId, {
        name: fields.name,
        urls: fields.urls,
        status: fields.status,
        properties: fields.properties,
      })
    },
    [currentBadgeId, badgeEditAction],
  )

  const initialValues = useMemo(() => {
    if (!badge) {
      return undefined
    }

    return {
      ...badge,
      urls: {
        x1: makeCurrentFile(badge.urls.x1),
        x2: makeCurrentFile(badge.urls.x2),
        x3: makeCurrentFile(badge.urls.x3),
      },
    }
  }, [badge])

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  const loading = () => <ItemLoadingLayout id={currentBadgeId} onBack={goBack} />

  if (fetchProgress === Progress.WORK || !badge) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={badge.name} />
          <Id>{`ID ${badge.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <BadgeDetailsForm
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onRemove={removeAction}
          initialValues={initialValues}
        />
      </Layout.Content>
    </>
  )
}

export default BadgeEdit
