import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Progress } from 'modules/types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import {
  IncentiveProgram,
  IncentiveProgramDTO,
  IncentiveProgramListRequestFilter,
  IncentiveProgramListRequestSorting,
  IncentiveProgramState,
  IncentiveProgramStatus,
} from './types'

const initialState: IncentiveProgramState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {
    status: IncentiveProgramStatus.Active,
  },
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
  listFetchErrorDetail: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  removeErrorDetail: null,
  addErrorDetail: null,
  updateErrorDetail: null,
}

class IncentiveProgramReducer extends BasicEntityImmerReducer<
  IncentiveProgram,
  IncentiveProgramListRequestFilter,
  IncentiveProgramListRequestSorting,
  IncentiveProgramDTO,
  IncentiveProgramDTO,
  IncentiveProgramState
> {}

export const IncentiveProgramActions = createActionCreators(IncentiveProgramReducer)
export default IncentiveProgramActions
export const reducer = createReducerFunction(IncentiveProgramReducer, initialState)
