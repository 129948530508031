import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Report, ReportListRequestSorting, ReportListRequestFilter, ReportDTO } from './types'
import { ListResponse } from 'types/api'
import { ReportFields, ReportUrl } from '../report/types'

export const getList = (
  filter: ReportListRequestFilter,
  sorting: ReportListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Report>>(endpoints.report(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<Report>(endpoints.report(id))
}

export const addItem = (dto: ReportDTO) => {
  return apiClient.post<Report>(endpoints.report(), dto)
}

export const updateItem = (id: string, dto: Partial<ReportDTO>) => {
  return apiClient.put<Report>(endpoints.report(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<Report>(endpoints.report(id))
}

export const getReportFields = () => {
  return apiClient.get<ReportFields>(endpoints.reports('fields'))
}

export const getMyReports = (filter: ReportListRequestFilter) => {
  return apiClient.get<Report[]>(endpoints.reports('my'), filter)
}

export const getSignedReportLink = (id: string) => {
  return apiClient.get<ReportUrl>(endpoints.reports(`sign/${id}`))
}
