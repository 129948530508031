import { Company } from 'modules/domain/company/types'
import { SkuCalculateDiscountsResponse } from 'modules/domain/incentiveProgram/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useEffect, useMemo } from 'react'
import { SkuProductEntry } from 'types/entities'

export type UseTotalSumProps = {
  entries: SkuProductEntry[]
  seller?: Company
  calcResponse?: SkuCalculateDiscountsResponse
  onActualTotalNetChange: (actualTotalNet: string) => void
  onTotalSumChange: (totalSum: string) => void
}

export const useSkuTotalSum = ({
  entries,
  seller,
  calcResponse,
  onActualTotalNetChange,
  onTotalSumChange,
}: UseTotalSumProps) => {
  const total = useMemo(() => {
    if (!entries.length) return 0

    return entries.reduce((acc, item) => {
      if (item.sku?.price) {
        return acc + Number(item.sku?.price) * Number(item.quantity)
      }
      return acc
    }, 0)
  }, [entries])

  let discountTotal: number = parseFloat(String(calcResponse?.total)) || 0
  discountTotal = Number.isInteger(discountTotal) ? Number(discountTotal) : Number(discountTotal.toFixed(2))

  useEffect(() => {
    onTotalSumChange(getPrettyPrice(total, seller?.currency))
  }, [onTotalSumChange, seller?.currency, total])

  useEffect(() => {
    onActualTotalNetChange(getPrettyPrice(total - discountTotal, seller?.currency))
  }, [discountTotal, onActualTotalNetChange, seller?.currency, total])
}
