import managers from 'modules/domain/programSummary/managers'
import { ProgramSummarySeason } from 'modules/domain/programSummary/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'

export const getSeasonLabel = (item: ProgramSummarySeason) => item.season_title.title

export const getSeasonValue = (item: ProgramSummarySeason) => item.season_id

export const SeasonSelect: React.VFC<EntityMultiSelectProps<any>> = ({ ...props }) => {
  return (
    <SelectTestWrapper data-test-id="season-select">
      <EntityMultiSelect
        getEntityByIds={managers.getSeasonsByIds}
        getEntityList={managers.getSeasons}
        searchParamKey="title"
        getOptionLabel={getSeasonLabel}
        getOptionValue={getSeasonValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
