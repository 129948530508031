import managers from 'modules/domain/programSummary/managers'
import { ProgramSummaryRetailer } from 'modules/domain/programSummary/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'

export const getRetailerLabel = (item: ProgramSummaryRetailer) => item.retailer_title.official_name

export const getRetailerValue = (item: ProgramSummaryRetailer) => item.retailer_id

export const RetailerSelect: React.VFC<EntityMultiSelectProps<any>> = ({ ...props }) => {
  return (
    <SelectTestWrapper data-test-id="retailer-select">
      <EntityMultiSelect
        getEntityByIds={managers.getRetailersByIds}
        getEntityList={managers.getRetailers}
        searchParamKey="official_name"
        getOptionLabel={getRetailerLabel}
        getOptionValue={getRetailerValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
