import React, { useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import { Program, ProgramSummaryListEntry } from 'modules/domain/programSummary/types'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import {
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  TableBodyCell,
  TableBodyRow,
} from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Currency } from 'types/entities'

const SectionTableStyled = styled(SectionTable)`
  border-spacing: 0 4px;
`

export const CompanyNameCell: React.FC<CellProps<ProgramSummaryListEntry>> = ({ row }) => {
  return <span>{row.original.retailer_title.official_name}</span>
}

export const FarmerNameCell: React.FC<CellProps<ProgramSummaryListEntry>> = ({ row }) => {
  return <span>{`${row.original.farmer_data.first_name} ${row.original.farmer_data.last_name}`}</span>
}

export const LastUpdateCell: React.FC<CellProps<ProgramSummaryListEntry>> = ({ row }) => {
  return <span>{format(parseISO(row.original.last_update_dttm), 'yyyy.MM.dd / HH:mm')}</span>
}

const ProgramTitleCell: React.FC<CellProps<Program>> = ({ row }) => {
  return <span>{row.original.program_title}</span>
}

export const PriceCell: React.FC<CellProps<Program | ProgramSummaryListEntry, number>> = ({ value }) => {
  return <span>{getPrettyPrice(value, Currency.USD)}</span>
}

export const ProgramsCell: React.FC<CellProps<ProgramSummaryListEntry, Program[]>> = ({ value }) => {
  const { t } = useTranslation('programSummary')
  const allColumns = useMemo(
    () => [
      { Header: t('headers.programName'), cellAlign: 'left', Cell: ProgramTitleCell },
      { Header: t('headers.preBate'), cellAlign: 'left', accessor: 'pre_bate' as const, Cell: PriceCell },
      { Header: t('headers.reward'), cellAlign: 'left', accessor: 'reward' as const, Cell: PriceCell },
      { Header: t('headers.netReward'), cellAlign: 'left', accessor: 'net_reward' as const, Cell: PriceCell },
    ],
    [t],
  )

  const hiddenColumns = useMemo(() => [], [])

  const { columns, rows, prepareRow } = useTable<Program>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data: value,
  })
  return (
    <SectionTableStyled>
      <SectionTableHead>
        <SectionTableHeadRow>
          {columns.map(column => {
            return (
              <SectionTableHeadCell key={column.id} hidden={column.hidden}>
                {column.render('Header')}
              </SectionTableHeadCell>
            )
          })}
        </SectionTableHeadRow>
      </SectionTableHead>
      <SectionTableBody>
        {rows.map(row => {
          prepareRow(row)
          const { key, ...props } = row.getRowProps()
          return (
            <TableBodyRow key={key} {...props}>
              {row.cells.map(cell => {
                const { key, ...props } = cell.getCellProps()
                return (
                  <TableBodyCell {...cell.column} key={key} {...props}>
                    {cell.render('Cell')}
                  </TableBodyCell>
                )
              })}
            </TableBodyRow>
          )
        })}
      </SectionTableBody>
    </SectionTableStyled>
  )
}
