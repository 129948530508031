import React, { useMemo, useState } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { FormikProvider } from 'formik'
import { AvailableLanguages, availableLanguagesList, LocalizedValue, ROLES } from 'types/entities'
import {
  Button,
  Checkbox,
  helpersSlug,
  Input,
  SectionBody,
  SectionContainer,
  Switch,
  useFormManager,
  FormComponents,
} from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'
import * as Yup from 'yup'
import SlugForm from 'views/components/SlugForm/SlugForm'
import AboutForm, { AboutFormProps } from './AboutForm'
import PermissionsForm from './PermissionsForm'
import ParamsForm from './ParamsForm'
import { ReportParams } from 'modules/domain/report/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { createLocalizedValue } from 'helpers/localization'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'

export type FormProps = {
  slug?: string
  url: string
  is_active: boolean
  is_warning: boolean
  is_private: boolean
  message: LocalizedValue
  title: LocalizedValue
  roles: ROLES[]
  order: number
  companies?: string[]
  users?: string[]
  params: ReportParams[]
  producer_id: string
  max_age: number
  auto_refresh_time: number | null
}

type LangData = Required<LocalizedValue<AboutFormProps>>
type FormikManagerData = LangData & {
  slug: { slug: string }
  common: {
    url: string
    is_warning: boolean
    is_active: boolean
    order: number
    max_age: number
    auto_refresh_time: number | null
  }
  permissions: { roles: ROLES[]; is_private: boolean; companies: string[]; users: string[]; producer_id: string }
  params: { params: ReportParams[] }
}

const ReportDetailsForm: React.FC<{
  onSubmit(form: FormProps): void
  onRemove?: () => void
  initialValues?: Partial<FormProps>
  progress?: Progress
  removeProgress?: Progress
  editing?: boolean
  onCancel(): void
}> = ({
  onSubmit,
  onRemove,
  progress,
  removeProgress,
  onCancel,
  initialValues = {
    is_active: true,
    order: undefined,
  },
  editing = false,
}) => {
  const { t } = useTranslation(['report', 'common'])
  const { bind, submitAll, dirty, slots, valid } = useFormManager<FormikManagerData>()

  const validationSchema = useMemo(() => {
    return Yup.object({
      country: Yup.string(),
      parent_id: Yup.string().nullable(),
      url: Yup.string().url(t('validation:invalid_url')),
      order: Yup.string().required(t('validation:field_required')),
      max_age: Yup.number().required(t('validation:field_required')),
      auto_refresh_time: Yup.number().nullable(),
    })
  }, [t])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const formik = bind('common')({ initialValues, onSubmit: () => {}, validationSchema, enableReinitialize: true })
  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.title?.[lang], initialValues?.title?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)

      const isPrivate = !!(
        forms.permissions.is_private &&
        (forms.permissions.companies.length || forms.permissions.users.length)
      )

      onSubmit({
        url: forms.common.url || '',
        slug: forms.slug.slug,
        is_warning: forms.common.is_warning,
        is_active: forms.common.is_active,
        is_private: isPrivate,
        producer_id: forms.permissions.producer_id,
        companies: forms.permissions.is_private ? forms.permissions.companies : [],
        users: forms.permissions.is_private ? forms.permissions.users : [],
        order: forms.common.order,
        title: createLocalizedValue(lang => langProp(lang, forms[lang]?.title) as string),
        message: createLocalizedValue(lang => langProp(lang, forms[lang]?.message) as string),
        roles: forms.permissions.roles || [],
        params: forms.params.params || [],
        max_age: forms.common.max_age,
        auto_refresh_time: forms.common.auto_refresh_time || null,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
  useValidationErrorNotification(formik.submitCount, valid)
  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Styled.Wrapper>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.labels.description')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang)}
                          value={lang}
                          onChange={handleLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <AboutForm
                            lang={lang}
                            key={lang}
                            title={initialValues.title}
                            message={initialValues.message}
                            useFormik={bind(lang as keyof FormikManagerData)}
                            onTitleChange={val => {
                              if (!editing && lang === 'en')
                                slots['slug'].setFieldValue('slug', helpersSlug.slugify(val))
                            }}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.config')}>
                  <SlugForm
                    showLabel={true}
                    useFormik={bind('slug')}
                    initialValue={initialValues.slug}
                    disabled={false}
                  />
                  <Input
                    style={{ maxWidth: '320px' }}
                    {...formik.getFieldProps('url')}
                    invalid={formik.touched.url && !!formik.errors.url}
                    errorText={formik.errors.url}
                    label={t('form.labels.url')}
                  />
                  <Input
                    style={{ maxWidth: '320px' }}
                    {...formik.getFieldProps('order')}
                    invalid={formik.touched.order && !!formik.errors.order}
                    errorText={formik.errors.order}
                    label={t('form.labels.order')}
                    placeholder={t('form.placeholders.order')}
                    type={'number'}
                    required
                  />
                  <Input
                    style={{ maxWidth: '320px' }}
                    {...formik.getFieldProps('max_age')}
                    invalid={formik.touched.max_age && !!formik.errors.max_age}
                    errorText={formik.errors.max_age}
                    label={t('form.labels.maxAge')}
                    placeholder={t('form.placeholders.maxAge')}
                    type={'number'}
                    required
                  />
                  <Input
                    style={{ maxWidth: '320px' }}
                    {...formik.getFieldProps('auto_refresh_time')}
                    invalid={formik.touched.auto_refresh_time && !!formik.errors.auto_refresh_time}
                    errorText={formik.errors.auto_refresh_time}
                    label={t('form.labels.autoRefreshTime')}
                    placeholder={t('form.placeholders.autoRefreshTime')}
                    type={'number'}
                  />
                  <Styled.FormRow>
                    <Switch
                      on={formik.getFieldProps('is_active').value}
                      onClick={value => {
                        formik.setFieldValue('is_active', value)
                      }}
                      label={t('form.labels.isActive')}
                    />
                    <Switch
                      on={formik.getFieldProps('is_warning').value}
                      onClick={value => {
                        formik.setFieldValue('is_warning', value)
                      }}
                      label={t('form.labels.isWarning')}
                    />
                  </Styled.FormRow>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.params')}>
                  <ParamsForm params={initialValues.params || []} useFormik={bind('params')} />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.permissions')}>
                  <PermissionsForm
                    useFormik={bind('permissions')}
                    roles={initialValues.roles}
                    is_private={initialValues.is_private}
                    companies={initialValues.companies || []}
                    users={initialValues.users || []}
                    producer_id={initialValues.producer_id}
                  />
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
          </Styled.Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            filled={true}
            progress={progress}
            disabled={!dirty || progress === Progress.WORK}
            onClick={submit}
            intent={'primary'}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
          {onRemove && (
            <StickyFooterDeleteBtn
              onRemove={onRemove}
              removeProgress={removeProgress}
              popoverText={t('form.removeText', { title: initialValues.title })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default ReportDetailsForm
