import styled from 'styled-components'
import { FormComponents } from '@agro-club/frontend-shared'

export const ContentWrapper = styled.div`
  max-width: 725px;
  display: grid;
  grid-gap: 24px;
`

export const Grid3Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

export const Grid2Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const LabeledContainerStyled = styled(FormComponents.LabeledContainer)`
  position: relative;
  padding-bottom: 16px;
`

export const ErrorMessage = styled.div`
  color: ${props => props.theme.color.accentDestructive};
  font-size: 14px;
  font-weight: 400;
`
