import styled, { StyledProps } from 'styled-components'
import { FormComponents } from '@agro-club/frontend-shared'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

export const BlockFormSection = styled(FormComponents.FormSection)`
  max-width: 300px;
`

export const FaviconContainer = styled.div`
  display: grid;
  grid-template-columns: 256px 256px 256px 256px;
  row-gap: 24px;

  @media screen and (max-width: 1279px) {
    grid-template-columns: 256px 256px 256px;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: 256px 256px;
  }
`

export const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: 256px 256px;
  width: 300px;
  row-gap: 24px;
`

export const LanguagesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

export const Error = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -5px;
`
export const LanguagesContainer = styled.div`
  position: relative;
  padding-bottom: 16px;
`

export const TitleArea = styled.div`
  width: 300px;
`

export const EmailArea = styled.div`
  width: 300px;
`

export const VerticalFormsContainer = styled.div`
  display: flex;
  row-gap: 48px;
  grid-gap: 48px;
  flex-wrap: wrap;
  width: 1000px;

  @media screen and (max-width: 1328px) {
    width: 800px;
  }

  @media screen and (max-width: 1024px) {
    width: 500px;
  }
`

export const VerticalContainer = styled.div`
  display: grid;
  grid-gap: 20px;
`

export const ContactsContainer = styled.div`
  display: grid;
  grid-template-columns: 256px 256px;
  width: 300px;
  row-gap: 24px;
`

export const HorizontalLegalDocsContainer = styled.div`
  display: flex;
`

export const LegalDocContainer = styled.div`
  width: 148px;
  margin-right: 8px;
`

export const LegalDocInputContainer = styled.div`
  margin-top: 8px;
`

export const RadioButtonsContainer = styled.div`
  margin-top: 8px;
`
