import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { LocalizedValue } from 'types/entities'

export type ProductOptionsState = {
  items: Dict<ProductOptions>
  meta: Dict<EntityMetadata<ProductOptions>>
  ids: string[]

  allItems: Dict<ProductOptions>
  allIds: string[]

  listFetchProgress: Progress
  listFetchError: FetchError | null
  listAllFetchProgress: Progress
  listAllFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof ProductOptions, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null

  filter: ProductOptionsListRequestFilter
  sorting: ProductOptionsListRequestSorting
  page: number
  total: number
  pageSize: number
}

export type ProductOptions = {
  id: string
  title?: string
  title_i18n?: LocalizedValue
  type: string
  is_stackable: boolean
  params?: {
    [key: string]: unknown
  }
}

export type ProductOptionsData = {
  type: ProductOptionType
  option_ids: string[]
  required: boolean
}

export type ProductOptionsListRequestFilter = {
  type?: ProductOptionType
  title?: string
}

export type ProductOptionsListRequestSorting = {
  sort_field?: keyof ProductOptions
  sort_reversed?: boolean
}

export type ProductOptionsDTO = Omit<ProductOptions, 'id' | 'created_at' | 'updated_at'> & {
  id?: string
  title_i18n: LocalizedValue
  type: string
}

export enum ProductOptionType {
  INOCULANT = 'inoculant',
  DELIVERY_OPTION = 'delivery_option',
  SEED_TREATMENT = 'seed_treatment',
  PRODUCT = 'product',
  ACTIVE_INGREDIENT = 'active_ingredient',
}

export type ProductOptionsGrouped = {
  type: ProductOptionType
  required: boolean
  data: {
    id: string
    title: string
  }[]
  selectedId?: string
}
