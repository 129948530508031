import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { call, all, takeLatest, put, select } from 'redux-saga/effects'
import NotificationsListRoutes from 'views/pages/NotificationsList/routes'
import { RequestError } from 'modules/errors'
import SnackbarActions from 'modules/domain/snackbar/duck'
import NotificationsListActions from './duck'
import managers from './managers'
import NotificationsListSelectors from './selectors'

const sagas = createBasicSagas({
  selectors: NotificationsListSelectors,
  actions: NotificationsListActions,
  managers,
  onListRequestSucceed: function*(response) {
    yield call(updateLocationQuery, NotificationsListRoutes.List, { page: response.page })
  },
})

export const notificationResend = function*({
  payload: [id, email],
}: ReturnType<typeof NotificationsListActions.notificationResendRequested>) {
  try {
    const item = yield select(NotificationsListSelectors.item(id))
    yield call(managers.notificationResend, id, email)
    yield put(NotificationsListActions.notificationResendSucceed(item))
    yield put(SnackbarActions.open({ content: 'The notification was successfully resend', severity: 'success' }))
  } catch (err) {
    const { detail } = RequestError.parseError(err)
    yield put(SnackbarActions.open({ content: detail, severity: 'error' }))
    yield put(NotificationsListActions.notificationResendFailed(id, err, detail))
  }
}

const NotificationsListSaga = function*() {
  yield all([
    takeLatest(NotificationsListActions.itemRequested.type, sagas.fetchItem),
    takeLatest(NotificationsListActions.notificationResendRequested.type, notificationResend),
    takeLatest(NotificationsListActions.listRequested.type, sagas.fetchList),
    takeLatest(NotificationsListActions.filterUpdated.type, sagas.fetchList),
  ])
}

export default NotificationsListSaga
