import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import styled from 'styled-components'
import {
  Button,
  Modal,
  NumberInput as NumberInputComponent,
  Radio,
  RadioItem,
  TextArea,
} from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import { InventoryItem } from 'modules/domain/inventoryExchange/types'
import { InventoryExchangeTransactionDTO, RequestSource } from 'modules/domain/inventoryExchangeTransaction/types'

const ModalContent = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`

const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

const Title = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};
`

const NumberInput = styled(NumberInputComponent)`
  margin-top: 12px;
  & > input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`

enum AdjustmentType {
  ADD,
  SUBTRACT,
}

type InventoryModalFormProps = {
  sku_id: string
  retailer_id: string
  quantity: number
  comment?: string
}

type ModalProps = {
  isOpen: boolean
  heading?: string
  onClose: () => void
  onSubmit: (dto: InventoryExchangeTransactionDTO, requestSource: RequestSource) => void
  progress: Progress
  rowData: InventoryItem
}

const TransactionModal: React.FC<ModalProps> = ({ isOpen, heading, onClose, onSubmit, progress, rowData }) => {
  const { t } = useTranslation('inventoryModal')
  const { pick } = useLangPicker()
  const [adjustmentType, setAdjustmentType] = useState<AdjustmentType>(AdjustmentType.ADD)

  const onRadioChange = (value: AdjustmentType) => {
    setAdjustmentType(value)
  }

  const formik = useFormik<InventoryModalFormProps>({
    enableReinitialize: true,
    initialValues: {
      sku_id: rowData.sku_id,
      retailer_id: rowData.retailer_id,
      quantity: 0,
      comment: '',
    },
    onSubmit: () => {
      onSubmit(
        {
          retailer_id: formik.values.retailer_id,
          sku_id: formik.values.sku_id,
          quantity: adjustmentType === AdjustmentType.ADD ? formik.values.quantity : -formik.values.quantity,
          comment: formik.values.comment,
        },
        RequestSource.MODAL,
      )
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={heading} shouldCloseOnEsc={true}>
      <ModalContent>
        <div>
          <Title>{`${t('labels.distributor')}: ${rowData.retailer.internal_name}`}</Title>
          <Title>{`${t('labels.product')}: ${pick(rowData.sku?.product?.title_i18n) ||
            rowData.sku?.product?.title}`}</Title>
          <Title>{`${t('labels.sku')}: ${rowData.sku.sku_id}`}</Title>
        </div>
        <div>
          <Radio value={adjustmentType} direction="row" onChange={onRadioChange}>
            <RadioItem value={AdjustmentType.ADD} label={t('labels.add')} />
            <RadioItem value={AdjustmentType.SUBTRACT} label={t('labels.subtract')} />
          </Radio>
          <NumberInput
            {...formik.getFieldProps('quantity')}
            onChange={value => formik.setFieldValue('quantity', value)}
            size="medium"
            min={0}
          />
        </div>
        <TextArea label={t('labels.comment')} {...formik.getFieldProps('comment')} />
      </ModalContent>

      <ModalFooter>
        <Button
          disabled={!formik.dirty || progress === Progress.WORK}
          intent={'primary'}
          filled
          type={'submit'}
          progress={progress}
          onClick={() => formik.submitForm()}
        >
          {t('common:save')}
        </Button>
        <Button onClick={onClose} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TransactionModal
