import { FeatureFlags } from 'modules/domain/license/types'
import { FeatureFlagInput } from './LicenseDetailsForm/LicenseDetailsForm'

export const makeFeatureFlagsArray = (featureFlagsObj?: FeatureFlags) => {
  if (!featureFlagsObj) {
    return []
  }

  return Object.keys(featureFlagsObj).map(flag => ({
    name: flag,
    value: featureFlagsObj[flag],
  }))
}

export const makeFeatureFlagsObject = (featureFlagsArr: FeatureFlagInput[]) => {
  return featureFlagsArr.reduce((obj, item) => {
    obj[item.name] = item.value
    return obj
  }, {})
}
