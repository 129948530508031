import { Button, ButtonIntent, PopoverWithBtns } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StickyFooterBtn: React.FC<{
  heading: string
  progress?: Progress
  text: string
  buttonText: string
  intent: ButtonIntent
  onSubmit?: () => void
  filled?: boolean
  disabled?: boolean
  testId?: string
}> = ({ text, onSubmit, heading, progress, buttonText, intent, filled, disabled, testId }) => {
  const { t } = useTranslation(['common'])

  const handleSubmit = () => {
    onSubmit?.()
  }

  return (
    <PopoverWithBtns onSubmit={handleSubmit} heading={heading} text={text} submitText={t('yes')} cancelText={t('no')}>
      <Button filled={filled} disabled={disabled} progress={progress} intent={intent} data-test-id={testId}>
        {buttonText}
      </Button>
    </PopoverWithBtns>
  )
}

export default StickyFooterBtn
