import AuthRoutes from 'views/pages/Auth/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import UserRoutes from 'views/pages/User/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import CategoryRoutes from 'views/pages/Categories/CategoryPage/routes'
import FarmerOrderRoutes from 'views/pages/FarmerOrder/routes'
import ProductRoutes from 'views/pages/Product/routes'
import MailingListRoutes from 'views/pages/MailingList/routes'
import CallbackRequestRoutes from 'views/pages/CallBackRequest/routes'

export const routesEventsMap = {
  [AuthRoutes.Login]: 'Signin',
  [AuthRoutes.ResetPassword]: 'ResetPassword',
  [DashboardRoutes.Dashboard]: 'Dashboard',
  [UserRoutes.List]: 'Users',
  [UserRoutes.Add]: 'UsersAdd',
  [UserRoutes.Edit]: 'UsersEdit',
  [CompanyRoutes.List]: 'Companies',
  [CompanyRoutes.Add]: 'CompaniesAdd',
  [CompanyRoutes.Edit]: 'CompaniesEdit',
  [CategoryRoutes.CategoryList]: 'Categories',
  [CategoryRoutes.CategoryAdd]: 'CategoriesAdd',
  [CategoryRoutes.CategoryEdit]: 'CategoriesEdit',
  [FarmerOrderRoutes.List]: 'FarmerOrders',
  [FarmerOrderRoutes.Add]: 'FarmerOrdersAdd',
  [FarmerOrderRoutes.Edit]: 'FarmerOrdersEdit',
  [ProductRoutes.List]: 'Products',
  [ProductRoutes.Add]: 'ProductsAdd',
  [ProductRoutes.Edit]: 'ProductsEdit',
  [MailingListRoutes.List]: 'MailingList',
  [MailingListRoutes.Item]: 'MailingListItem',
  [CallbackRequestRoutes.List]: 'Callback',
  [CallbackRequestRoutes.Item]: 'CallbackItem',
}

export const CollectedRoutes = [
  ...Object.values(AuthRoutes),
  ...Object.values(DashboardRoutes),
  ...Object.values(UserRoutes),
  ...Object.values(CompanyRoutes),
  ...Object.values(CategoryRoutes),
  ...Object.values(FarmerOrderRoutes),
  ...Object.values(ProductRoutes),
  ...Object.values(MailingListRoutes),
  ...Object.values(CallbackRequestRoutes),
]

export const routeToEvent = (route?: keyof typeof routesEventsMap) => {
  return route ? routesEventsMap[route] : ''
}
