import styled, { StyledProps } from 'styled-components'
import { Button, IconBin, Checkbox as CheckboxComponent, Switch as SwitchComponent } from '@agro-club/frontend-shared'

export const FarmerPersonalDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'country middlename'
    'firstname lastname '
    'phone additionalphone'
    'email check-payee-name'
    'canterraid ...'
    'email2 email2';
`

export const CountryArea = styled.div`
  grid-area: country;
`
export const NameArea = styled.div`
  grid-area: firstname;
`
export const LastNameArea = styled.div`
  grid-area: lastname;
`
export const MiddleNameArea = styled.div`
  grid-area: middlename;
`
export const PhoneNumberArea = styled.div`
  grid-area: phone;
`
export const AdditionalPhoneNumberArea = styled.div`
  grid-area: additionalphone;
`
export const EmailArea = styled.div`
  grid-area: email;
`
export const EmailArea2 = styled.div`
  grid-area: email2;

  input {
    max-width: 320px;
  }
`

export const CanterraIdArea = styled.div`
  grid-area: canterraid;
`

export const CheckPayeeNameArea = styled.div`
  grid-area: check-payee-name;
`

export const PasswordSettingsArea = styled.div`
  grid-area: password-settings;
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  margin-left: 10px;
`
export const PermissionsWrapper = styled.div`
  width: 200px;
`

export const RemoveButton = styled(Button)`
  margin-left: auto;
`

export const AddRegionButton = styled.div<StyledProps<{}>>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  transition: 0.2s opacity ease;
  margin-top: 16px;
  &:hover {
    opacity: 0.8;
  }
`

export const ResetPasswordLinkSent = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const ResetPasswordFailed = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.accentDestructive};
`

export const PartnershipBlock = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
`

export const PartnerContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content max-content;
`

export const PartnerBlock = styled.div`
  display: grid;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
`

export const PartnershipButton = styled.button`
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary500};
  cursor: pointer;
  justify-self: start;

  &:focus {
    outline: none;
  }
`

export const FarmerOtherDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  max-width: 725px;
  margin-bottom: 24px;
`
export const RemoveBtn = styled(IconBin)`
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 12px;
  transition: 0.3s fill;

  &:hover {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const Checkbox = styled(CheckboxComponent)`
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary300};
    cursor: pointer;
  }
`

export const Switch = styled(SwitchComponent)`
  width: auto;
  margin-right: 16px;
`

export const Hint = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  margin-top: 16px;
  max-width: 320px;
`

export const CSUAContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
`

export const DocumentsBlock = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
`

export const EmptyList = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const CommentBlock = styled.div`
  width: 480px;
`

export const EmailSwitchArea = styled.div`
  margin-top: 8px;
`
