import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('territoryTarget')

export const TerritoryTargetSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.territoryTarget.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.territoryTarget.totalTargetValues,
  retailerTargetsTotal: (state: AppGlobalState) => state.territoryTarget.totalRetailerTargets,
  farmerOrdersTotal: (state: AppGlobalState) => state.territoryTarget.totalFarmerOrders,
  previousYear: (state: AppGlobalState) => state.territoryTarget.totalFarmerOrdersPreviousYear,
}

export default TerritoryTargetSelectors
