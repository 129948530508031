import React from 'react'
import styled from 'styled-components'
import {
  SectionTable,
  SectionTableBody,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  TableBodyCell,
  TableBodyRow,
} from '@agro-club/frontend-shared'
import { ProgramDTO, ProgramSummaryHistory } from 'modules/domain/programSummary/types'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useTranslation } from 'react-i18next'
import useLangPicker from 'hooks/useLangPicker'

const SectionWrapper = styled.div`
  border-spacing: 0 4px;
  margin-top: 26px;
  padding: 4px 48px;
  min-width: 1000px;
`
const HistoryCellStyled = styled(TableBodyCell)`
  border: none;
  background-color: initial;
  padding: 0 8px 8px;
  color: ${props => props.theme.color.secondary200};
`
const HistoryTableHeadCellStyled = styled(SectionTableHeadCell)`
  padding-left: 8px !important;
`
const Title = styled.div`
  color: ${props => props.theme.color.secondary200};
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 18px;
  padding-left: 8px;
`
const CommonHeaders: React.FC = () => {
  const { t } = useTranslation('programSummary')
  return (
    <SectionTableHead>
      <SectionTableHeadRow>
        <HistoryTableHeadCellStyled>{t('headers.date')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.editor')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.product')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.qualified')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.qualifiedQTY')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.preBate')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.reward')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.netReward')}</HistoryTableHeadCellStyled>
        <HistoryTableHeadCellStyled>{t('headers.comment')}</HistoryTableHeadCellStyled>
      </SectionTableHeadRow>
    </SectionTableHead>
  )
}

type Props = {
  programProducts: Array<ProgramDTO>
}

const Programs: React.FC<Props> = ({ programProducts }) => {
  const { t } = useTranslation('programSummary')
  const getFormatedDate = useDateFormatFn({ withTime: true })
  let history: Array<ProgramSummaryHistory> = []
  programProducts.forEach(program => {
    history = history.concat(program.history)
  })
  const { pick } = useLangPicker(lang => `title_${lang}`)
  return (
    <SectionWrapper>
      <Title>
        {t('labels.historyOf')} {programProducts[0].program_title}
      </Title>
      <SectionTable>
        <CommonHeaders />
        <SectionTableBody>
          {history.map((item, index) => (
            <TableBodyRow key={index}>
              <HistoryCellStyled>{getFormatedDate(item.last_update_dttm)}</HistoryCellStyled>
              <HistoryCellStyled>{item.name_of_changer}</HistoryCellStyled>
              <HistoryCellStyled>{pick(programProducts[0].product_title)}</HistoryCellStyled>
              <HistoryCellStyled>{item.qualified ? 'Qualified' : 'Not Qualified'}</HistoryCellStyled>
              <HistoryCellStyled>{item.qualified_bags}</HistoryCellStyled>
              <HistoryCellStyled>{getPrettyPrice(programProducts[0].pre_bate)}</HistoryCellStyled>
              <HistoryCellStyled>{getPrettyPrice(programProducts[0].reward)}</HistoryCellStyled>
              <HistoryCellStyled>{getPrettyPrice(programProducts[0].net_reward)}</HistoryCellStyled>
              <HistoryCellStyled>{item.notes}</HistoryCellStyled>
            </TableBodyRow>
          ))}
        </SectionTableBody>
      </SectionTable>
    </SectionWrapper>
  )
}

const History: React.FC<Props> = ({ programProducts }) => {
  const { t } = useTranslation('programSummary')
  if (programProducts.length) {
    return <Programs programProducts={programProducts} />
  } else {
    return (
      <SectionWrapper>
        <Title>{t('labels.historyEmpty')}</Title>
        <SectionTable>
          <CommonHeaders />
          <SectionTableBody>
            <TableBodyRow>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
              <HistoryCellStyled>-</HistoryCellStyled>
            </TableBodyRow>
          </SectionTableBody>
        </SectionTable>
      </SectionWrapper>
    )
  }
}

export default History
