import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('retailerTargetSku')

export const RetailerTargetSkuSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.retailerTargetSku.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.retailerTargetSku.totalTargetValues,
  retailerOrdersTotal: (state: AppGlobalState) => state.retailerTargetSku.totalRetailerOrdersSku,
  farmerOrdersTotal: (state: AppGlobalState) => state.retailerTargetSku.totalFarmerOrdersSku,
  previousYear: (state: AppGlobalState) => state.retailerTargetSku.totalFarmerOrdersSkuPreviousYear,
}

export default RetailerTargetSkuSelectors
