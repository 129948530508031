import { all, takeLatest, call, put, select, takeEvery } from 'redux-saga/effects'
import * as managers from './managers'
import CompanySelectors from './selectors'
import { Company, CompanyConfigDTO } from 'modules/domain/company/types'
import CompanyActions from 'modules/domain/company/duck'
import { push } from 'connected-react-router'
import CompaniesRoutes from 'views/pages/Company/routes'
import { generatePath } from 'react-router-dom'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(CompanySelectors.page)
    const filter = yield select(CompanySelectors.filter)
    const sorting = yield select(CompanySelectors.sorting)
    const pageSize = yield select(CompanySelectors.pageSize)

    let response: ListResponse<Company> = yield call(managers.getCompanyList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getCompanyList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(CompanyActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, CompaniesRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(CompanySelectors.page)
    const filter = yield select(CompanySelectors.filter)
    const sorting = yield select(CompanySelectors.sorting)
    const pageSize = yield select(CompanySelectors.pageSize)
    const { data, total_count }: { data: Company[]; total_count: number } = yield call(
      managers.getCompanyList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(CompanyActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.listRequestNextFailed(errType))
  }
}

export const fetchCompany = function*({ payload: id }: ReturnType<typeof CompanyActions.itemRequested>) {
  try {
    const company: Company = yield call(managers.getCompany, id)
    yield put(CompanyActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.itemRequestFailed(id, errType))
  }
}

export const fetchBranchCompanies = function*({
  payload: id,
}: ReturnType<typeof CompanyActions.branchCompaniesRequested>) {
  try {
    const { data } = yield call(managers.getDistributorBranchCompanies, id)
    yield put(CompanyActions.branchCompaniesRequestSucceed(id, data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.branchCompaniesRequestFailed(id, errType))
  }
}

export const addCompany = function*({ payload: { dto, branches } }: ReturnType<typeof CompanyActions.addRequested>) {
  try {
    const { images = [], ...rest } = dto
    const contenders = images.filter(file => file.kind === 'current' || file.kind === 'added')
    const imageItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const filteredImages: string[] = imageItems.filter(img => typeof img === 'string') as string[]
    const company: Company = yield call(managers.addCompany, {
      ...rest,
      logo_url: filteredImages[0] || null,
    })
    if (branches) {
      yield call(managers.updateDistributorBranchCompanies, company.id, branches)
    }
    yield put(CompanyActions.addSucceed(company))
    yield put(push(generatePath(CompaniesRoutes.Edit, { id: company.id })))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.addFailed(errType))
  }
}

export const addCompanyConfig = function*({ payload: { dto } }: ReturnType<typeof CompanyActions.addConfigRequested>) {
  try {
    const config: CompanyConfigDTO = yield call(managers.addCompanyConfig, dto)
    yield put(CompanyActions.addConfigSucceed(config))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.addConfigFailed(errType))
  }
}

export const updateCompanyConfig = function*({
  payload: { dto, config_id },
}: ReturnType<typeof CompanyActions.updateConfigRequested>) {
  try {
    const config: CompanyConfigDTO = yield call(managers.updateCompanyConfig, config_id, dto)
    yield put(CompanyActions.updateConfigSucceed(config))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.updateConfigFailed(dto.company_id, errType))
  }
}

export const updateCompany = function*({
  payload: { id, dto, branches },
}: ReturnType<typeof CompanyActions.updateRequested>) {
  try {
    const { images = [], ...rest } = dto
    const contenders = images.filter(file => file.kind === 'current' || file.kind === 'added')
    const imageItems: (string | File | null)[] = yield all(
      contenders.map(file => {
        switch (file.kind) {
          case 'current':
            return file.file
          case 'added':
            return call(waitUntilFileLoaded, file.id)
        }
        return null
      }),
    )
    const filteredImages: string[] = imageItems.filter(img => typeof img === 'string') as string[]

    const company: Company = yield call(managers.updateCompany, id, {
      ...rest,
      logo_url: filteredImages[0] || null,
    })
    if (branches) {
      yield call(managers.updateDistributorBranchCompanies, id, branches)
      yield put(CompanyActions.branchCompaniesRequested(id))
    }
    yield put(CompanyActions.updateSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.updateFailed(id, errType))
  }
}

export const removeCompany = function*({ payload }: ReturnType<typeof CompanyActions.removeRequested>) {
  try {
    const config = yield select(CompanySelectors.config, payload)

    let configId: string = config?.id
    if (!config?.id) {
      const company: Company = yield call(managers.getCompany, payload)
      configId = company?.config?.id as string
      if (configId) {
        yield call(managers.removeCompanyConfig, configId)
      }
    }

    yield call(managers.removeCompany, payload)
    yield put(CompanyActions.removeSucceed(payload))
    yield put(push(CompaniesRoutes.List))
    yield put(CompanyActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CompanyActions.removeFailed(payload, errType))
  }
}

const CompanySaga = function*() {
  yield all([
    takeEvery(CompanyActions.itemRequested.type, fetchCompany),
    takeLatest(CompanyActions.listRequested.type, fetchList),
    takeLatest(CompanyActions.filterUpdated.type, fetchList),
    takeLatest(CompanyActions.sortingUpdated.type, fetchList),
    takeLatest(CompanyActions.filterHasBeenReset.type, fetchList),
    takeLatest(CompanyActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CompanyActions.listRequestedNext.type, fetchListNext),

    takeLatest(CompanyActions.addRequested.type, addCompany),
    takeLatest(CompanyActions.updateRequested.type, updateCompany),
    takeLatest(CompanyActions.removeRequested.type, removeCompany),

    takeLatest(CompanyActions.addConfigRequested.type, addCompanyConfig),
    takeLatest(CompanyActions.updateConfigRequested.type, updateCompanyConfig),

    takeLatest(CompanyActions.branchCompaniesRequested.type, fetchBranchCompanies),
  ])
}

export default CompanySaga
