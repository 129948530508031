import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { PromoInfo, PromoInfoListRequestSorting, PromoInfoListRequestFilter, PromoInfoDTO } from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: PromoInfoListRequestFilter,
  sorting: PromoInfoListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<PromoInfo>>(endpoints.promoInfo(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<PromoInfo>(endpoints.promoInfo(id))
}

export const addItem = (dto: PromoInfoDTO) => {
  return apiClient.post<PromoInfo>(endpoints.promoInfo(), dto)
}

export const updateItem = (id: string, dto: Partial<PromoInfoDTO>) => {
  return apiClient.put<PromoInfo>(endpoints.promoInfo(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<PromoInfo>(endpoints.promoInfo(id))
}
