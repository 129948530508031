import React, { useMemo } from 'react'
import RegionsModal from 'views/components/PickRegionsForm/RegionsModal'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, TextArea } from '@agro-club/frontend-shared'
import styled from 'styled-components'

const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 18px 24px;
  max-height: 600px;
  max-width: 600px;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
`
const CommentTextAreaStyled = styled.div`
  margin-bottom: 20px;
  width: 100%;
`

type ModalProps = {
  isOpen: boolean
  isShowMod?: boolean
  onChangeComment?: (value: string) => void
  onClose: () => void
  value?: string
}

type FormProps = {
  comment?: string
}

const OrderCancellationReasonModal: React.FC<ModalProps> = ({
  isOpen,
  isShowMod = false,
  onClose,
  onChangeComment,
  value = '',
}) => {
  const { t } = useTranslation(['farmerOrder', 'common'])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        comment: Yup.string().required(),
      }),
    [t],
  )

  const formik = useFormik<FormProps>({
    initialValues: {
      comment: value,
    },
    validationSchema,
    onSubmit: values => {
      if (values?.comment?.trim().length) {
        onChangeComment?.(values.comment.trim())
        onClose()
      }
    },
  })

  return (
    <RegionsModal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <Title>{t('form.sectionHeaders.comment')}</Title>
        <FormWrapper>
          {isShowMod ? (
            value
          ) : (
            <CommentTextAreaStyled>
              <TextArea
                {...formik.getFieldProps('comment')}
                invalid={formik.touched.comment && !!formik.errors.comment}
                placeholder={t('form.placeholders.reason')}
                errorText={formik.errors.comment}
                limit={1000}
                data-test-id={'reject-reason-comment'}
              />
            </CommentTextAreaStyled>
          )}
          {!isShowMod && (
            <div data-test-id={'add-comment-button'}>
              <Button
                intent={'primary'}
                disabled={!!formik.errors.comment}
                onClick={() => {
                  formik.submitForm()
                }}
                filled
              >
                {t('form.apply')}
              </Button>
            </div>
          )}
        </FormWrapper>
      </Wrapper>
    </RegionsModal>
  )
}

export default OrderCancellationReasonModal
