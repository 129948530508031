import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { BasicComponentType, SnackbarState } from './types'

const initialState: SnackbarState = {
  open: false,
  autoHideDuration: 5000,
}

class SnackbarReducer extends ImmerReducer<SnackbarState> {
  open(props: Omit<Partial<SnackbarState>, 'open'>) {
    this.draftState = { ...this.draftState, ...props, open: true }
  }
  close() {
    this.draftState.open = false
  }
  reset() {
    this.draftState = initialState
  }
}

const basicSnackbarActions = createActionCreators(SnackbarReducer)

interface OpenAction {
  readonly type: typeof basicSnackbarActions.open.type
  <ContentType extends BasicComponentType = BasicComponentType>(
    props: Omit<Partial<SnackbarState<ContentType>>, 'open'>,
  ): ReturnType<typeof basicSnackbarActions.open>
}

export const SnackbarActions = {
  ...basicSnackbarActions,
  open: basicSnackbarActions.open as OpenAction,
}
export default SnackbarActions

export const reducer = createReducerFunction(SnackbarReducer, initialState)
