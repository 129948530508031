import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { Progress, ResourceHook } from 'modules/types'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import SeasonActions from './duck'
import SeasonSelectors from './selectors'
import managers from './managers'
import { Season, SeasonListRequestFilter } from './types'

export const useSeasonList: ResourceHook<Season[], void[]> = () => {
  const progress = useSelector(SeasonSelectors.listFetchProgress)
  const list = useSelector(SeasonSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(SeasonActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (!list.length && progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useCurrentSeasonOnce: ResourceHook<
  Season | null,
  [SeasonListRequestFilter | undefined, boolean | undefined]
> = (filter: SeasonListRequestFilter = {}, preventFetch?: boolean) => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [season, setSeason] = useState<Season | null>(null)

  useDidMount(() => {
    if (!season && progress === Progress.IDLE && !preventFetch) {
      setProgress(Progress.WORK)
      managers
        .getCurrentSeason(filter)
        .then(season => {
          setProgress(Progress.SUCCESS)
          setSeason(season)
        })
        .catch(() => {
          setProgress(Progress.ERROR)
        })
    }
  })

  return [progress, season]
}

export const useSeason = createItemByIdOnceHook(SeasonSelectors, SeasonActions.itemRequested)

export const useSeasonById = createItemByIdHook(SeasonSelectors, SeasonActions.itemRequested)
