import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  FarmerOrderListRequestSorting,
  FarmerOrderListRequestFilter,
  FarmerCreateOrderDTO,
  FarmerOrderListResponse,
  FarmerOrderUpdateDTO,
} from './types'
import { FarmerOrder } from 'types/farmerOrder'
import { ListResponse } from 'types/api'

export const getList = (
  filter: FarmerOrderListRequestFilter,
  sorting: FarmerOrderListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<FarmerOrderListResponse>(endpoints.farmerOrder(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<FarmerOrder>(endpoints.farmerOrder(id))
}

export const addItem = (dto: FarmerCreateOrderDTO) => {
  return apiClient.post<FarmerOrder>(endpoints.farmerOrder(), dto)
}

export const updateItem = (id: string, dto: Partial<FarmerOrderUpdateDTO>) => {
  return apiClient.put<FarmerOrder>(endpoints.farmerOrder(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<FarmerOrder>(endpoints.farmerOrder(id))
}

export const ordersCount = async (filter: FarmerOrderListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<FarmerOrder>>(endpoints.farmerOrder('count'), {
    ...filter,
    page_size: 1,
  })
  return response
}
