import styled, { keyframes, StyledProps } from 'styled-components'
import { helpersColor, Checkbox, CheckboxSelect, IconCrossCircle, FormComponents } from '@agro-club/frontend-shared'

export const ContentBody = styled(FormComponents.FormContentBody)`
  max-width: 725px;
`
export const FormContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`

export const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

export const VerticalInputsContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 16px;
`

export const VerticalInputsWrapper = styled(VerticalInputsContainer)`
  grid-template-columns: 256px 256px;
`

export const VerticalFormContainer = styled.div`
  display: flex;
  grid-gap: 16px;
  flex-direction: column;
`

export const VerticalFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 1fr 1fr;
`
export const AboutCountriesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`
export const Grid3Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`
export const Grid2Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const AddButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
  display: inline-flex;
  border: 0;
  background: none;
  width: auto;
  transition: opacity 0.2s ease-in;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const removeOverlayAnimation = keyframes`
  to {
    opacity: 1;
  }
`
export const RemoveOverlay = styled.div`
  position: absolute;
  left: -5px;
  top: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 5px;
  pointer-events: none;
  background-color: ${props => helpersColor.alphaColor(props.theme.color.accentDestructive, 0.2)};
  opacity: 0;
  animation: ${removeOverlayAnimation};
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`

export const OptionsSelectContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 315px;
`

export const OptionsFormsContainer = styled.div`
  position: relative;
  margin-bottom: 5px;

  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export const PreviewBlock = styled.div`
  font-size: 12px;
  color: grey;
`

export const PreviewBlockTitle = styled.div`
  color: grey;
  margin-bottom: 10px;
`

export const PreviewBlockItem = styled.div`
  font-size: 14px;
  margin-top: 5px;
`

export const RequiredCheckbox = styled(Checkbox)`
  margin-top: 3px;
  font-size: 12px;
`

export const MultiSelectOptions = styled(CheckboxSelect)`
  max-width: 315px;
`

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 48px;
`

export const ErrorStyled = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -5px;
`

export const LabeledContainerStyled = styled(FormComponents.LabeledContainer)`
  position: relative;
  padding-bottom: 16px;
`

export const SwitchWrapper = styled.div`
  width: fit-content;
`
