import React from 'react'
import { BasicSelect, useAction } from '@agro-club/frontend-shared'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ChangeLogSelector from 'modules/domain/changeLog/selectors'
import { ChangeLogFilter } from 'modules/domain/changeLog/types'
import { Operation } from 'types/entities'
import ChangeLogActions from 'modules/domain/changeLog/duck'

const operationsOptions = Object.keys(Operation).map(e => {
  return { id: e, title: Operation[e] }
})

const OperationTypeFilter: React.FC = () => {
  const { t } = useTranslation('changeLog')
  const filterValue = useSelector(ChangeLogSelector.filter)
  const filterUpdated = useAction(ChangeLogActions.filterUpdated)

  const getLabel = (item: any) => item.title
  const getValue = (item: any) => item.id

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ChangeLogFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )
  const value = operationsOptions.find(e => e.id === filterValue?.operation)
  return (
    <FilterComponent title={t('headers.operation')}>
      <BasicSelect
        value={value}
        onChange={val => handleFilterChange({ operation: val?.id })}
        placeholder={t('placeholders.operation')}
        width={'200px'}
        variant={'small'}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        options={operationsOptions}
        isClearable
      />
    </FilterComponent>
  )
}

export default OperationTypeFilter
