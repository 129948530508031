import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAction, useBoolean, Button, BaseInput } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'

import InventoryTransactionSelectors from 'modules/domain/inventoryExchangeTransaction/selectors'
import { InventoryItem } from 'modules/domain/inventoryExchange/types'
import TransactionModal from './TransactionModal'
import InventoryTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'

export const TotalQtyField: React.VFC<{ item: InventoryItem }> = ({ item }) => {
  const { t } = useTranslation('inventorySKU')
  const [isModalOpen, openModal, closeModal] = useBoolean(false)
  const addTransactionProgress = useSelector(InventoryTransactionSelectors.addProgress)
  const handleModalFormSubmit = useAction(InventoryTransactionActions.addRequested)

  useEffect(() => {
    if (addTransactionProgress === Progress.SUCCESS) {
      closeModal()
    }
  }, [addTransactionProgress, closeModal])

  return (
    <div>
      <BaseInput label={t('quantity')} value={item.total_qty} disabled style={{ width: '50%' }} />
      <Button intent="minor-action" onClick={openModal}>
        {t('changeQty')}
      </Button>
      {isModalOpen && (
        <TransactionModal
          heading={t('inventoryModal:modalHeading')}
          isOpen={isModalOpen}
          rowData={item}
          progress={addTransactionProgress}
          onClose={closeModal}
          onSubmit={handleModalFormSubmit}
        />
      )}
    </div>
  )
}
