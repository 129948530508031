import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import FarmerTargetSkuActions from 'modules/domain/target2sku/farmerTarget/duck'
import FarmerTargetSkuSelectors from 'modules/domain/target2sku/farmerTarget/selectors'
import RetailerTargetSkuActions from 'modules/domain/target2sku/retailerTarget/duck'
import RetailerTargetSkuSelectors from 'modules/domain/target2sku/retailerTarget/selectors'
import TerritoryTargetSkuActions from 'modules/domain/target2sku/territoryTarget/duck'
import TerritoryTargetSkuSelectors from 'modules/domain/target2sku/territoryTarget/selectors'
import CropTargetSkuActions from 'modules/domain/target2sku/cropTarget/duck'
import CropTargetSkuSelectors from 'modules/domain/target2sku/cropTarget/selectors'
import { endpoints } from 'modules/endpoints'

export const mappedActions = {
  [TargetSkuTypes.Territory]: TerritoryTargetSkuActions,
  [TargetSkuTypes.Retailer]: RetailerTargetSkuActions,
  [TargetSkuTypes.Farmer]: FarmerTargetSkuActions,
  [TargetSkuTypes.Crop]: CropTargetSkuActions,
}

export const mappedSelectors = {
  [TargetSkuTypes.Territory]: TerritoryTargetSkuSelectors,
  [TargetSkuTypes.Retailer]: RetailerTargetSkuSelectors,
  [TargetSkuTypes.Farmer]: FarmerTargetSkuSelectors,
  [TargetSkuTypes.Crop]: CropTargetSkuSelectors,
}

export const mappedDownloadCSVEndpoint = {
  [TargetSkuTypes.Territory]: endpoints.territoryTargetSku('download/csv'),
  [TargetSkuTypes.Retailer]: endpoints.retailerTargetSku('download/csv'),
  [TargetSkuTypes.Farmer]: endpoints.farmerTargetSku('download/csv'),
  [TargetSkuTypes.Crop]: endpoints.cropTargetSku('download/csv'),
}

export const PersistentFilterStateKey = 'new-targets-show-filter'
