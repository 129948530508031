import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import InventoryExchangeActions from './duck'
import InventoryExchangeSelectors from './selectors'
import { InventoryItem } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useInventoryExchangeList: ResourceHook<InventoryItem[], boolean[]> = () => {
  const progress = useSelector(InventoryExchangeSelectors.listFetchProgress)
  const list = useSelector(InventoryExchangeSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(InventoryExchangeActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useInventoryItem: ResourceHook<InventoryItem, [string]> = (id: string) => {
  const meta = useSelector(state => InventoryExchangeSelectors.meta(state, id))
  const item = useSelector(state => InventoryExchangeSelectors.item(state, id))
  const fetchAction = useAction(InventoryExchangeActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}
