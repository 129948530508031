import { Button, Modal } from '@agro-club/frontend-shared'
import { usePreventNavigation, UsePreventNavigationProps } from 'hooks/usePreventNavigation'
import React from 'react'
import styled from 'styled-components'
import { ModalFooter } from 'views/pages/Storefront/Storefront/StorefrontList/styled'

const ModalContent = styled.div`
  margin-bottom: 24px;
`

const ModalHeader = styled.h2`
  margin-bottom: 24px;
  margin-top: 0;
`

export type PreventNavigationModalProps = UsePreventNavigationProps & {
  message: React.ReactNode
  allowButtonContent: React.ReactNode
  cancelButtonContent: React.ReactNode
  header?: React.ReactNode
  onAllowTransition?: () => void
  onClose?: () => void
}

const PreventNavigationModal: React.FC<PreventNavigationModalProps> = ({
  message,
  allowButtonContent,
  cancelButtonContent,
  header,
  onAllowTransition,
  onClose,
  ...props
}) => {
  const { prompt, isOpen, close, allowTransition } = usePreventNavigation(props)

  const handleClose = () => {
    close()
    onClose && onClose()
  }

  const handleAllowTransitionCb = () => {
    allowTransition()
    onAllowTransition && onAllowTransition()
    close()
  }

  return (
    <React.Fragment>
      {prompt}
      <Modal isOpen={isOpen} onClose={close}>
        {header && <ModalHeader>{header}</ModalHeader>}
        <ModalContent>{message}</ModalContent>
        <ModalFooter>
          <Button intent={'primary'} onClick={handleAllowTransitionCb}>
            {allowButtonContent}
          </Button>
          <Button intent={'cancel'} onClick={handleClose}>
            {cancelButtonContent}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default PreventNavigationModal
