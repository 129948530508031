import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/components/OrderProductItems/styles'
import {
  OptionalNumber,
  SectionBody,
  SectionContainer,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTitle,
} from '@agro-club/frontend-shared'
import { ReturnDeclarationSkuItem } from 'modules/domain/returnDeclarationSku/types'
import useLangPicker from 'hooks/useLangPicker'
import { getStandardUnitsQty } from 'views/components/OrderSkuCardItems/helpers'
import ReadOnlyOptions from 'views/components/ReadOnlyOptions/ReadOnlyOptions'

const ReturnItemsReadyOnly: React.FC<{
  title?: string
  sku_items?: ReturnDeclarationSkuItem[]
  invalid?: boolean
  errorText?: string
}> = ({ title, sku_items = [], children }) => {
  const { t } = useTranslation(['farmerOrder', 'returnDeclaration', 'common', 'packageTypes'])
  const { pick } = useLangPicker()

  return (
    <SectionContainer>
      <SectionTitle>{title || t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
      <SectionBody>
        {!!sku_items?.length && (
          <SectionTable data-test-id={'product-items'}>
            <SectionTableHead>
              <SectionTableHeadRow>
                <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                <SectionTableHeadCell>{t('returnDeclaration:form.labels.lotNumber')}</SectionTableHeadCell>
                <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
              </SectionTableHeadRow>
            </SectionTableHead>
            <SectionTableBody>
              {sku_items.map((item, idx) => {
                const cardTitle = `${pick(item.product_card?.subcategory?.title_i18n)} / ${pick(
                  item.product_card?.title_i18n,
                )}`
                const packageItem = item.sku?.params.package
                const itemQty = OptionalNumber(item.quantity)
                const standardUnitsVol = getStandardUnitsQty(itemQty, item.sku?.product?.units, packageItem)

                return (
                  <SectionTableBodyRow key={idx} data-test-id={`product-card-row-${item.product_card_id}`}>
                    <SectionTableBodyCell>
                      <Styled.CardTitle>{cardTitle}</Styled.CardTitle>
                      <>
                        <Styled.PackageType>
                          <b>{t('packageTypes:labels.package')}: </b>
                          {`${pick(packageItem?.title_i18n)}`}
                        </Styled.PackageType>
                        <ReadOnlyOptions options={item.sku?.params.options} />
                      </>
                    </SectionTableBodyCell>
                    <SectionTableBodyCell>
                      <Styled.LotInputWrapper>
                        <Styled.Label>{item.lot_number}</Styled.Label>
                      </Styled.LotInputWrapper>
                    </SectionTableBodyCell>
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                      {item.quantity}
                      <Styled.FormattedQty>{standardUnitsVol}</Styled.FormattedQty>
                    </SectionTableBodyCell>
                  </SectionTableBodyRow>
                )
              })}
            </SectionTableBody>
          </SectionTable>
        )}
      </SectionBody>
      {children}
    </SectionContainer>
  )
}

export default ReturnItemsReadyOnly
