import React from 'react'
import { FilterProps, TableFilter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { Flag } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { CountryCode } from 'libphonenumber-js'
import { COUNTRIES } from 'modules/constants'

export const StyledFlag = styled(Flag)`
  margin-right: 5px;
`

type CountryFilterProps<TShape, TField extends keyof TShape, TDisableMulti> = Omit<
  FilterProps<TShape, TField, CountryCode, TDisableMulti>,
  'options' | 'title'
>

const options = COUNTRIES.map(country => ({
  value: country.id,
  title: (
    <span>
      <StyledFlag code={country.id} />
      {country.title}
    </span>
  ),
}))

export default function CountryFilter<TShape, TField extends keyof TShape, TDisableMulti>(
  props: CountryFilterProps<TShape, TField, TDisableMulti>,
) {
  const { t: tLabels } = useTranslation('labels')

  return <TableFilter options={options} title={tLabels('country')} {...props} />
}
