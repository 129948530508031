import { CountryCode } from 'libphonenumber-js'
import { COUNTRIES } from 'modules/constants'

export const isValidSpainPhoneNumber = (countryCode: CountryCode, value?: string) => {
  if (countryCode === 'ES') {
    const country = COUNTRIES.find(c => c.id === 'ES')
    const phone = value?.replace(country?.phone_prefix as string, '')
    return !!(phone?.startsWith('6') || phone?.startsWith('7'))
  }
  return true
}
