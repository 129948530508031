import { HistoryQtyTierRule } from 'modules/domain/discountRule/types'
import {
  SkuCalculatedTier,
  IncentiveProgramTypes,
  QtyTierRule,
  PercentageTierRule,
  PromocodeTierRule,
} from 'modules/domain/incentiveProgram/types'

export const isQtyTier = (tier: SkuCalculatedTier<any>): tier is SkuCalculatedTier<QtyTierRule> =>
  tier.tier_rule.type === IncentiveProgramTypes.Quantity

export const isPercentageTier = (tier: SkuCalculatedTier<any>): tier is SkuCalculatedTier<PercentageTierRule> =>
  tier.tier_rule.type === IncentiveProgramTypes.Percentage

export const isHistoricTier = (tier: SkuCalculatedTier<any>): tier is SkuCalculatedTier<HistoryQtyTierRule> =>
  tier.tier_rule.type === IncentiveProgramTypes.HistoryQuantity

export const isPromocodeTier = (tier: SkuCalculatedTier<any>): tier is SkuCalculatedTier<PromocodeTierRule> =>
  tier.tier_rule.type === IncentiveProgramTypes.Promocode

export const isMonetaryTier = (
  tier: SkuCalculatedTier<any>,
): tier is SkuCalculatedTier<QtyTierRule | PercentageTierRule | PromocodeTierRule> =>
  isPercentageTier(tier) || isQtyTier(tier) || isHistoricTier(tier) || isPromocodeTier(tier)
