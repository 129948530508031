import React, { useEffect, useMemo } from 'react'
import styled, { StyledProps } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Progress, useAction } from '@agro-club/frontend-shared'
import ConfigActions from 'modules/domain/config/duck'
import { useConfig } from 'modules/domain/config/hooks'

const Wrapper = styled.div`
  display: flex;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.color.onPrimaryDark};
  opacity: 0.48;
  align-items: center;
`

const Title = styled.span`
  margin-right: 24px;
`

const Item = styled.span<StyledProps<{ isActive: boolean }>>`
  height: 24px;

  ${({ theme, isActive }) => ({
    background: isActive ? theme.color.secondary200 : 'transparent',
  })}

  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
`

const LanguageRadio: React.FC = () => {
  const { t, i18n } = useTranslation('common')
  const changeLanguageAction = useAction(ConfigActions.languageChanged)
  const [configProgress] = useConfig()

  const filteredLanguages = useMemo(() => {
    const languages = { ...i18n.services.resourceStore.data }
    delete languages.es
    delete languages.es_SV
    delete languages.ru
    delete languages.uk

    return Object.keys(languages)
      .sort()
      .map(lng => ({ id: lng, title: t(`languages.short.${lng === 'es_SV' ? 'es' : lng}`) }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const language = i18n.language
    if (language !== language && configProgress !== Progress.WORK) {
      i18n.changeLanguage(language)
      changeLanguageAction(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configProgress])

  return (
    <Wrapper>
      <Title>{t('language')}</Title>
      {filteredLanguages.map(lng => (
        <Item
          isActive={i18n.language === lng.id}
          key={lng.id}
          onClick={() => {
            i18n.changeLanguage(lng.id)
            changeLanguageAction(lng.id)
          }}
        >
          {lng.title}
        </Item>
      ))}
    </Wrapper>
  )
}

export default LanguageRadio
