import { GridArea } from '@agro-club/frontend-shared'
import styled from 'styled-components'

export const Area = GridArea.ofType<'address' | 'city' | 'province' | 'country' | 'zip' | 'date'>()

export const AddressContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 480px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'address address'
    'country city'
    'province zip';
`
