import React from 'react'
import { Route as RouterRoute, RouteProps, Redirect } from 'react-router-dom'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'

type Props = { capability?: CAPABILITY; permission?: PERMISSION }

const PermissionWrapper: React.FC<Required<Props> & RouteProps> = ({ capability, permission, ...rest }) => {
  const isPermitted = usePermissions({ capability, permission })
  if (!isPermitted) {
    return <Redirect to={'/'} />
  }
  return <RouterRoute {...rest} />
}

const Route: React.FC<Props & RouteProps> = ({ capability, permission, ...rest }) => {
  if (!capability || !permission) {
    return <RouterRoute {...rest} />
  }
  return <PermissionWrapper capability={capability} permission={permission} {...rest} />
}

export default Route
