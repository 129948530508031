import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { BasicComponentType, ModalState } from './types'

const initialState: ModalState = {
  isOpen: false,
}

class ModalReducer extends ImmerReducer<ModalState> {
  open(props: Omit<Partial<ModalState>, 'isOpen'>) {
    this.draftState = { ...this.draftState, ...props, isOpen: true }
  }
  close() {
    this.draftState.isOpen = false
  }
  reset() {
    this.draftState = initialState
  }
}

const basicModalActions = createActionCreators(ModalReducer)

interface OpenAction {
  readonly type: typeof basicModalActions.open.type
  <ContentType extends BasicComponentType = BasicComponentType>(
    props: Omit<Partial<ModalState<ContentType>>, 'isOpen'>,
  ): ReturnType<typeof basicModalActions.open>
}

export const ModalActions = {
  ...basicModalActions,
  open: basicModalActions.open as OpenAction,
}
export default ModalActions

export const reducer = createReducerFunction(ModalReducer, initialState)
