import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { all, takeLatest } from 'redux-saga/effects'
import StorefrontActions from './duck'
import managers from './managers'
import StorefrontSelectors from './selectors'

const sagas = createBasicSagas({
  managers,
  selectors: StorefrontSelectors,
  actions: StorefrontActions,
})

const StorefrontSaga = function*() {
  yield all([
    takeLatest(StorefrontActions.itemRequested.type, sagas.fetchItem),
    takeLatest(StorefrontActions.listRequested.type, sagas.fetchList),
    takeLatest(StorefrontActions.filterUpdated.type, sagas.fetchList),
    takeLatest(StorefrontActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(StorefrontActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(StorefrontActions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(StorefrontActions.listRequestedNext.type, sagas.fetchListNext),
    takeLatest(StorefrontActions.addRequested.type, sagas.addItem),
    takeLatest(StorefrontActions.updateRequested.type, sagas.updateItem),
    takeLatest(StorefrontActions.removeRequested.type, sagas.removeItem),

    takeLatest(StorefrontActions.addSucceed.type, sagas.fetchList),
    takeLatest(StorefrontActions.updateSucceed.type, sagas.fetchList),
    takeLatest(StorefrontActions.removeSucceed.type, sagas.fetchList),
  ])
}

export default StorefrontSaga
