import React, { memo, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikHook, Input, TextArea, FormComponents } from '@agro-club/frontend-shared'
import styled from 'styled-components'

export type AboutFormProps = {
  title: string
  description: string
  singular: string
  plural: string
}

const FormSection = styled(FormComponents.FormSection)`
  max-width: 400px;
`

const VerticalInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const AboutForm: React.FC<{
  lang: string
  title?: LocalizedValue
  description?: LocalizedValue
  singular?: LocalizedValue
  plural?: LocalizedValue
  useFormik: FormikHook
}> = memo(({ lang, useFormik, title = {}, description = {}, singular = {}, plural = {} }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().required(t('validation:field_required')),
        description: Yup.string().required(t('validation:field_required')),
        singular: Yup.string().required(t('validation:field_required')),
        plural: Yup.string().required(t('validation:field_required')),
      }),
    [t],
  )
  const formik = useFormik<AboutFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      title: title[lang] || '',
      description: description[lang] || '',
      singular: singular[lang] || '',
      plural: plural[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })
  return (
    <VerticalInputContainer>
      <FormSection title={t('form.aboutTitle')}>
        <Input
          {...formik.getFieldProps('title')}
          invalid={formik.touched.title && !!formik.errors.title}
          errorText={formik.errors.title}
          label={t('form.title', { lang })}
          limit={50}
          data-test-id={`title-${lang}`}
          required
        />
        <TextArea
          {...formik.getFieldProps('description')}
          invalid={formik.touched.description && !!formik.errors.description}
          errorText={formik.errors.description}
          label={t('form.description', { lang })}
          data-test-id={`description-${lang}`}
          limit={1000}
          required
        />
      </FormSection>
      <FormSection title={t('form.unitsTitle')}>
        <Input
          {...formik.getFieldProps(`singular`)}
          invalid={formik.touched.singular && !!formik.errors.singular}
          errorText={formik.errors.singular}
          label={t('form.unitsSingularTitle', { lang })}
          title={`1 ${formik.values.singular}`}
          data-test-id={`singular-${lang}`}
          required
        />
        <Input
          {...formik.getFieldProps(`plural`)}
          invalid={formik.touched.plural && !!formik.errors.plural}
          errorText={formik.errors.plural}
          label={t('form.unitsPluralTitle', { lang })}
          title={`12 ${formik.values.plural}`}
          data-test-id={`plural-${lang}`}
          required
        />
      </FormSection>
    </VerticalInputContainer>
  )
})
AboutForm.displayName = 'AboutForm'

export default AboutForm
