import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { DocumentFileState, DocumentItem, DocumentMetadata } from './types'

const dict = (state: AppGlobalState) => state.document.items
const ids = (state: AppGlobalState) => state.document.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): DocumentItem | undefined => state.document.items[id]
const meta = (state: AppGlobalState, documentId: string, userId?: string): DocumentMetadata => {
  const id = userId ? `${userId}-${documentId}` : documentId
  return (
    state.document.meta[id] || {
      id,
      fetchError: null,
      fetchProgress: Progress.IDLE,
      removeError: null,
      removeProgress: Progress.IDLE,
      updateError: null,
      updateProgress: Progress.IDLE,
      downloadError: null,
      downloadProgress: Progress.IDLE,
      printError: null,
      printProgress: Progress.IDLE,
    }
  )
}

const itemFileUrl = (state: AppGlobalState, id: string): DocumentFileState =>
  state.document.files[id] || {
    url: null,
    progress: Progress.IDLE,
  }

const signedFileUrl = (state: AppGlobalState, documentId: string, userId: string): DocumentFileState =>
  state.document.signedFiles[`${documentId}-${userId}`] || {
    url: null,
    progress: Progress.IDLE,
  }

const userDocuments = (state: AppGlobalState, userId: string): DocumentItem[] | undefined =>
  state.document.userDocumentsList[userId]
const userDocumentsListMeta = (state: AppGlobalState, userId: string): DocumentMetadata<DocumentItem[]> =>
  state.document.userDocumentsListMeta[userId] || {
    id: userId,
    fetchError: null,
    fetchProgress: Progress.IDLE,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
    downloadError: null,
    downloadProgress: Progress.IDLE,
    printError: null,
    printProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.document.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.document.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.document.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.document.listFetchErrorDetail
const listFetchNextProgress = (state: AppGlobalState) => state.document.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.document.listFetchNextError
const listFetchNextErrorDetail = (state: AppGlobalState) => state.document.listFetchNextErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: DocumentItem[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.document.filter
const sorting = (state: AppGlobalState) => state.document.sorting
const page = (state: AppGlobalState) => state.document.page
const total = (state: AppGlobalState) => state.document.total
const updateProgress = (state: AppGlobalState) => state.document.updateProgress
const removeProgress = (state: AppGlobalState) => state.document.removeProgress
const addProgress = (state: AppGlobalState) => state.document.addProgress
const hasNext = (state: AppGlobalState) => state.document.total > state.document.ids.length
const pageSize = (state: AppGlobalState) => state.document.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.document.total / state.document.pageSize)

const DocumentSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  userDocuments,
  userDocumentsListMeta,
  itemFileUrl,
  signedFileUrl,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  listFetchNextErrorDetail,
  pages,
}

export default DocumentSelectors
