import { FormikHook, Input } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LocalizedValue } from 'types/entities'
import * as Yup from 'yup'
import * as Styled from './styled'

export type URLFormProps = {
  link_label: string
  link_url: string
}

const validationSchema = Yup.object({
  link_label: Yup.string(),
  link_url: Yup.string(),
})

const URLForm: React.VFC<{
  lang: keyof LocalizedValue
  link_label?: LocalizedValue
  link_url?: LocalizedValue
  useFormik: FormikHook
}> = ({ lang, link_label = {}, link_url = {}, useFormik }) => {
  const { t } = useTranslation(['incentiveProgram', 'common'])

  const formik = useFormik<URLFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      link_label: link_label[lang] || '',
      link_url: link_url[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })

  return (
    <Styled.VerticalLinkContainer data-test-id={`url-form-${lang}`}>
      <Input
        {...formik.getFieldProps('link_label')}
        label={t('form.labels.urlTitle', { lang: `(${lang})` })}
        invalid={formik.touched.link_label && !!formik.errors.link_label}
        errorText={formik.errors.link_label}
        limit={30}
        data-test-id="label"
      />
      <Input
        {...formik.getFieldProps('link_url')}
        label={t('form.labels.urlLink', { lang: `(${lang})` })}
        invalid={formik.touched.link_url && !!formik.errors.link_url}
        errorText={formik.errors.link_url}
        data-test-id="url"
      />
    </Styled.VerticalLinkContainer>
  )
}

export default URLForm
