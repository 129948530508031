import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HouseholdDetailsForm, { FormData } from './HouseholdDetailsForm/HouseholdDetailsForm'
import HouseholdSelectors from 'modules/domain/household/selectors'
import HouseholdsRoutes from 'views/pages/Household/routes'
import HouseholdActions from 'modules/domain/household/duck'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const HouseholdAdd: React.FC = () => {
  const { t } = useTranslation('household')
  const push = useHistoryPush()
  const householdAddAction = useAction(HouseholdActions.addRequested)
  const page = useSelector(HouseholdSelectors.page)

  const handleSubmit = useCallback(
    (fields: FormData) => {
      householdAddAction({
        name: fields.name,
        senior_partner_id: fields.senior_partner_id,
        partners_ids: fields.partners_ids,
      })
    },
    [householdAddAction],
  )

  const goBack = () => push({ route: HouseholdsRoutes.List, query: { page } })
  const progress = useSelector(HouseholdSelectors.addProgress)

  useHelmet({ title: t('addHouseholdMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <HouseholdDetailsForm onCancel={goBack} onSubmit={handleSubmit} progress={progress} />
      </Layout.Content>
    </>
  )
}

export default HouseholdAdd
