import managers from 'modules/domain/storefront/managers'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { makeCancelable } from 'modules/utils/httpClient'
import StorefrontActions from './duck'
import StorefrontSelectors from './selectors'

export const useStorefrontList = createItemListOnceHook(StorefrontSelectors, StorefrontActions.listRequested)
export const useStorefrontItem = createItemByIdOnceHook(StorefrontSelectors, StorefrontActions.itemRequested)
export const useStorefrontItemById = createItemByIdHook(StorefrontSelectors, StorefrontActions.itemRequested)
export const useStorefrontListPreventable = makeCancellableResourceListHook(makeCancelable(managers.getList), true)
export const useCardSkus = makeCancellableResourceListHook(makeCancelable(managers.getCardSkus), true)
