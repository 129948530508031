import * as managers from 'modules/domain/household/managers'
import { Household } from 'modules/domain/household/types'
import React from 'react'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type HouseholdSelectProps = EntitySelectProps<Household>
export const getHouseholdLabel = (item: Household) => item.name
export const getHouseholdValue = (item: Household) => item.id

export const HouseholdSelect: React.VFC<HouseholdSelectProps> = props => {
  return (
    <SelectTestWrapper data-test-id="household-select">
      <EntitySelect
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getHouseholdLabel}
        getOptionValue={getHouseholdValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
