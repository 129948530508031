import React from 'react'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import {
  IncentiveCampaignDetailsForm,
  IncentiveCampaignDetailsFormikProps,
} from './components/IncentiveCampaignDetailsForm'
import IncentiveCampaignSelectors from 'modules/domain/incentiveCampaign/selectors'
import IncentiveCampaignRoutes from './routes'
import IncentiveCampaignActions from 'modules/domain/incentiveCampaign/duck'

const IncentiveProgramAdd: React.VFC = () => {
  const { t } = useTranslation('incentiveCampaign')
  const submitAction = useAction(IncentiveCampaignActions.addRequested)
  const addProgress = useSelector(IncentiveCampaignSelectors.addProgress)

  const handleFormSubmit = (props: IncentiveCampaignDetailsFormikProps) => {
    submitAction(props)
  }

  const page = useSelector(IncentiveCampaignSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: IncentiveCampaignRoutes.List })
  }

  useHelmet({ title: t('addIncentiveCampaignMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <IncentiveCampaignDetailsForm progress={addProgress} onCancel={goBack} onSubmit={handleFormSubmit} />
      </Layout.Content>
    </>
  )
}

export default IncentiveProgramAdd
