import styled, { keyframes, StyledProps } from 'styled-components'
import React from 'react'
import { Button, Flag as FlagComponent } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

type ThemedProps = StyledProps<{}>

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${(props: { noFilters?: boolean }) =>
    props.noFilters ? 'auto max-content' : 'max-content auto max-content'};
  height: 100%;
  overflow: hidden;
`

export const Filters = styled.div`
  position: sticky;
  top: 0;
  border-top: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  background-color: ${({ theme }: ThemedProps) => theme.color.onPrimaryLight};
  display: flex;
  padding: 12px 36px 16px;
  align-items: flex-start;
  gap: 24px;
  z-index: 2;
`

export const SelectWrapper = styled.div`
  width: 290px;
`

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 650px;
  max-width: 970px;
  gap: 24px;
`

export const RegularText = styled.span`
  font-weight: 500;
`

export const SemiBoldText = styled.span`
  font-weight: 600;
`

export const BoldText = styled.span`
  font-weight: 700;
`

export const Flag = styled(FlagComponent)`
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
`

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export const Spinner = () => {
  return (
    <SpinnerWrapper>
      <SpinnerLayout />
    </SpinnerWrapper>
  )
}

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: max-content auto;
`

export const ClearButton = styled(Button)`
  margin-top: 20px;
`

export const LinkButton = styled.div`
  justify-self: flex-start;
  margin-left: 0;
  margin-right: auto;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};

  cursor: pointer;
`

export const ActionButton = styled(Button)`
  margin-right: 16px;
`
