import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { ChangeLogFilter, ChangeLogEntry, ChangeLogSorting } from './types'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

export default {
  ...createBasicManagers<ChangeLogFilter, ChangeLogSorting, ChangeLogEntry, ChangeLogEntry, ChangeLogEntry>(
    endpoints.changeLogList,
  ),
  getCollectionNames: () => apiClient.get<ListResponse<any>>(endpoints.changeLogCollections()),
} as const
