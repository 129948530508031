import { createActionCreators, createReducerFunction } from 'immer-reducer'
import {
  DeclarationSkuCountDto,
  PrintDeclarationSkuDto,
  ReturnDeclarationSku,
  ReturnDeclarationSkuDTO,
  ReturnDeclarationSkuListRequestFilter,
  ReturnDeclarationSkuListRequestSorting,
  ReturnDeclarationSkuState,
} from './types'
import { Progress } from 'modules/types'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'

const initialState: ReturnDeclarationSkuState = {
  ...BasicEntityState,
  printProgress: Progress.IDLE,
  printError: null,
  declarationCountFetchProgress: Progress.IDLE,
  declarationCountFetchError: null,
  declarationCount: null,
}

class ReturnDeclarationSkuReducer extends BasicEntityImmerReducer<
  ReturnDeclarationSku,
  ReturnDeclarationSkuListRequestFilter,
  ReturnDeclarationSkuListRequestSorting,
  ReturnDeclarationSkuDTO,
  ReturnDeclarationSkuDTO,
  ReturnDeclarationSkuState
> {
  printRequested(_dto: PrintDeclarationSkuDto) {
    this.draftState.printProgress = Progress.WORK
    this.draftState.printError = null
  }

  printSucceed() {
    this.draftState.printProgress = Progress.SUCCESS
    this.draftState.printError = null
  }
  printFailed(error: any) {
    this.draftState.printProgress = Progress.ERROR
    this.draftState.printError = error
  }
  countRequested(_dto: DeclarationSkuCountDto) {
    this.draftState.declarationCountFetchProgress = Progress.WORK
    this.draftState.declarationCountFetchError = null
  }
  countRequestedSucceed(count: number) {
    this.draftState.declarationCountFetchProgress = Progress.SUCCESS
    this.draftState.declarationCountFetchError = null
    this.draftState.declarationCount = count
  }
  countRequestedFailed(error: any) {
    this.draftState.declarationCountFetchProgress = Progress.ERROR
    this.draftState.declarationCountFetchError = error
  }
  cleanDeclarationCounter() {
    this.draftState.declarationCountFetchProgress = Progress.IDLE
    this.draftState.declarationCount = null
  }
}

export const ReturnDeclarationSkuActions = createActionCreators(ReturnDeclarationSkuReducer)
export default ReturnDeclarationSkuActions
export const reducer = createReducerFunction(ReturnDeclarationSkuReducer, initialState)
