import React, { useCallback, useMemo } from 'react'
import { useAction, Switch } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'
import { CellProps, Row } from 'react-table'
import { ProductsAvailability } from 'modules/domain/productsAvailability/types'
import { useProductsAvailabilityList } from 'modules/domain/productsAvailability/hooks'
import ProductsAvailabilityActions from 'modules/domain/productsAvailability/duck'
import ProductsAvailabilitySelectors from 'modules/domain/productsAvailability/selectors'
import { generatePath } from 'modules/utils/helpers/generatePath'
import ProductsAvailabilityRoutes from './routes'
import styled from 'styled-components'

const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
`

const DeliveryEnabledCell: React.FC<CellProps<ProductsAvailability, boolean>> = ({ cell, row }) => {
  const changeAvailability = useAction(ProductsAvailabilityActions.updateRequested)
  const { id, ...rest } = { ...row.original }

  return (
    <CenteredCell onClick={e => e.stopPropagation()}>
      <Switch on={cell.value} onClick={value => changeAvailability(id, { ...rest, is_available: value })} />
    </CenteredCell>
  )
}

const ProductsAvailabilityTable = () => {
  const history = useHistory()
  const { t } = useTranslation('productsAvailability')
  const [progress, data = []] = useProductsAvailabilityList()

  const total = useSelector(ProductsAvailabilitySelectors.total)
  const page = useSelector(ProductsAvailabilitySelectors.page)
  const pages = useSelector(ProductsAvailabilitySelectors.pages)
  const pageSize = useSelector(ProductsAvailabilitySelectors.pageSize)

  const listRequested = useAction(ProductsAvailabilityActions.listRequested)

  const columns = useMemo(
    () => [
      {
        id: 'seller_internal_name',
        Header: t('headers.seller'),
        accessor: 'seller.internal_name' as const,
      },
      {
        id: 'distributor_internal_name',
        Header: t('headers.retailer'),
        accessor: 'distributor.internal_name' as const,
      },
      {
        id: 'sku_id',
        Header: t('headers.sku'),
        accessor: 'sku.sku_id' as const,
      },
      {
        Header: t('headers.availability'),
        accessor: 'is_available' as const,
        Cell: DeliveryEnabledCell,
      },
    ],
    [t],
  )

  const handleRowClick = useCallback((row: Row<ProductsAvailability>) => {
    history.push(generatePath(ProductsAvailabilityRoutes.Edit, { id: row.original.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CompoundedTable
      columns={columns}
      data={data}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => listRequested({ page: num })}
      pages={pages}
      onRowClick={handleRowClick}
    />
  )
}

export default ProductsAvailabilityTable
