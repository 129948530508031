import NotificationsListActions from './duck'
import NotificationsListSelectors from './selectors'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'

export const useNotificationsList = createItemListOnceHook(
  NotificationsListSelectors,
  NotificationsListActions.listRequested,
)

export const useNotificationsListItem = createItemByIdHook(
  NotificationsListSelectors,
  NotificationsListActions.itemRequested,
)
