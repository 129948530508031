import React, { memo, useMemo, useState } from 'react'
import * as Yup from 'yup'
import * as Styled from 'views/pages/Product/ProductDetailsForm/styled'
import { AvailableLanguages, LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { remove } from 'ramda'
import { FormikHook, IconCrossCircle, Input } from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

const VerticalInputsContainer = styled(Styled.VerticalInputsContainer)`
  position: relative;
  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export type FeaturesFormProps = {
  features: LocalizedValue[]
}

const FeaturesForm: React.FC<{
  useFormik: FormikHook
  langList: AvailableLanguages[]
  features?: LocalizedValue[]
}> = memo(({ useFormik, langList, features = [] }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const validationSchema = useMemo(() => {
    return Yup.array(Yup.object(createLocalizedValue(() => Yup.string())))
  }, [])
  const formik = useFormik<FeaturesFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      features,
    },
  })

  const handleAddClick = () => {
    formik.setFieldValue('features', [...formik.values.features, createLocalizedValue(() => '')])
  }

  const handleRemoveClick = (idx: number) => {
    formik.setFieldValue('features', remove(idx, 1, formik.values.features))
  }

  return (
    <Styled.FormContainer>
      <Styled.VerticalFormsContainer data-test-id={'features-form'}>
        {formik.values.features.map((feature, idx) => {
          return (
            <VerticalInputsContainer key={idx}>
              <RemoveIcon
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleRemoveClick(idx)}
                data-test-id={'remove-button'}
              />
              {langList.map(lang => {
                const index = idx + 1
                const key = `features[${idx}][${lang}]`
                return (
                  <Input label={t('form.feature', { index, lang })} key={key} {...formik.getFieldProps(key)} required />
                )
              })}
              {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
            </VerticalInputsContainer>
          )
        })}
      </Styled.VerticalFormsContainer>
      <Styled.AddButton data-test-id={'add-feature'} onClick={handleAddClick} type={'button'}>
        {t('form.addMoreFeatures')}
      </Styled.AddButton>
    </Styled.FormContainer>
  )
})

FeaturesForm.displayName = 'FeaturesForm'

export default FeaturesForm
