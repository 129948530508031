import React, { FocusEvent, ChangeEvent, useCallback, useContext, useMemo, useState } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CellProps, Row, useTable } from 'react-table'
import {
  Button,
  FormikHook,
  Input,
  PopoverWithBtns,
  Table,
  TableActionPanel,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  TextArea,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import ReconciliationSelectors from 'modules/domain/reconciliation/selectors'
import { ReconciliationSku } from 'modules/domain/reconciliation/types'
import { calculateNetAdj } from 'modules/domain/reconciliation/domainUtils'
import styled from 'styled-components'
import { FormikContext, useFormik } from 'formik'
import { Progress } from 'modules/types'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { isAgro, Sections } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import ReconciliationActions from 'modules/domain/reconciliation/duck'
import { noop } from 'helpers/noop'

const Wrapper = styled(TComponents.Wrapper)`
  grid-template-rows: auto;
`

const ActionPanelInner = styled.div`
  width: 100%;
  grid-template-columns: max-content max-content;
  display: grid;
  grid-gap: 18px;
`

export const CustomTable = styled(Table)`
  min-width: 1500px;
`

const NetCell: React.FC<CellProps<ReconciliationSku>> = () => {
  const formik = useContext(FormikContext)
  return <div>{calculateNetAdj(formik.values)}</div>
}

const DiffCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.reconciliation.diff}</span>
}

const ShippedCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.reconciliation.shipped}</span>
}

const GrowerCell: React.FC<CellProps<ReconciliationSku>> = ({ row }) => {
  return <span>{row.original.reconciliation.grower}</span>
}

const ProductNameCell: React.FC<CellProps<ReconciliationSku>> = ({ value }) => {
  return <span>{value.en}</span>
}

const InputCell: React.FC<CellProps<ReconciliationSku, number> & { isEditable: boolean }> = ({
  column,
  cell,
  isEditable,
}) => {
  const formik = useContext(FormikContext)
  const { onBlur, value, ...props } = formik.getFieldProps(column.id)
  const [localInputState, setLocalInputState] = useState(value)
  const { key } = cell.getCellProps()

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseInt(e.target.value, 10)
      setLocalInputState(v ?? 0)
      formik.setFieldValue(column.id, v || 0)
    },
    [column.id, formik],
  )

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur(e)
      if (!formik.initialValues[column.id] && !e.target.value) {
        formik.setFieldTouched(column.id, false)
        formik.setFieldValue(column.id, 0)
        setLocalInputState(0)
      }
    },
    [column.id, formik, onBlur],
  )

  return (
    <Input
      {...props}
      id={key as string}
      onBlur={handleBlur}
      data-test-id={cell.getCellProps().key as string}
      onChange={handleChange}
      value={localInputState}
      min={column.min}
      max={column.max}
      type={'number'}
      disabled={!isEditable}
    />
  )
}

const CommentCell: React.FC<CellProps<ReconciliationSku, string> & { isEditable: boolean }> = ({
  cell,
  isEditable,
}) => {
  const formik = useContext(FormikContext)
  const props = formik.getFieldProps('comment')
  return (
    <div data-test-id={cell.getCellProps().key as string}>
      <TextArea {...props} value={props.value || ''} disabled={!isEditable} />
    </div>
  )
}

export const InteractionCell: React.FC<CellProps<ReconciliationSku> & {
  onDelete: (skuId: string) => void
}> = ({ cell, row, onDelete }) => {
  const { t } = useTranslation(['common', 'reconciliation'])
  if (!cell.value) return null

  const { id } = { ...row.original }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <PopoverWithBtns
        onSubmit={() => {
          onDelete(id)
        }}
        heading={t('reconciliation:removeGroupConfirmation')}
        submitText={t('yes')}
        cancelText={t('no')}
      >
        <Button intent="danger" data-test-id="remove-retailer-button" size="small">
          {t('delete')}
        </Button>
      </PopoverWithBtns>
    </div>
  )
}

const ReconciliationRow: React.FC<{
  row: Row<ReconciliationSku>
  useFormik: FormikHook
  idx: number
  isEditable: boolean
}> = ({ row, useFormik, idx, isEditable }) => {
  const { key, ...props } = row.getRowProps()
  const formik = useFormik<ReconciliationSku>({
    initialValues: {
      ...row.original,
      comment: row.original.comment || '',
    },
    enableReinitialize: true,
    onSubmit() {},
  })
  return (
    <TableBodyRow key={key} {...props} data-test-id={`table-row-${idx}`}>
      <FormikContext.Provider value={formik}>
        {row.cells.map(cell => {
          const { key, ...props } = cell.getCellProps()
          return (
            <TableBodyCell {...cell.column} key={key} {...props}>
              {cell.render('Cell', { idx, isEditable })}
            </TableBodyCell>
          )
        })}
      </FormikContext.Provider>
    </TableBodyRow>
  )
}

const ReconciliationListTable: React.FC<{
  handleSubmit: () => Promise<void>
  handleReset: () => void
  formManager: {
    dirty: boolean
    bind: (slotName: string) => typeof useFormik
  }
  progress: Progress
  data: ReconciliationSku[]
  manufacturerId: string
  retailerId: string
}> = ({ handleSubmit, handleReset, formManager, progress, data, manufacturerId, retailerId }) => {
  const { t } = useTranslation('reconciliation')
  const total = data?.length
  const page = 1
  const pages = page
  const pageSize = total
  const { scrollRef } = usePersistentScroll('promocode')
  const updateProgress = useSelector(ReconciliationSelectors.batchUpdateProgress)
  const role = useSelector(AuthSelectors.role)
  const isAdmin = isAgro(role)
  const checkFeatureFlag = useFeatureFlags()
  const isEditable = isAdmin || checkFeatureFlag(generateCustomFeatureFlag(Sections.Reconciliations, 'editingAllowed'))
  const updateSkuAction = useAction(ReconciliationActions.updateSkuRequested)

  const handleSkuDelete = useCallback(
    (skuId: string) => {
      updateSkuAction({ manufacturer_id: manufacturerId, retailer_id: retailerId, sku_id: skuId, isForced: false })
    },
    [manufacturerId, retailerId, updateSkuAction],
  )

  const allColumns = useMemo(
    () => [
      { accessor: 'title' as const, Header: t('headers.product'), Cell: ProductNameCell },
      {
        Header: t('headers.shipped'),
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: ShippedCell,
      },
      {
        Header: t('headers.ordered'),
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: GrowerCell,
      },
      {
        id: 'inv.beginning',
        Header: t('headers.beginning_inv'),
        cellAlign: 'left',
        headerAlign: 'left',
        min: 0,
        Cell: InputCell,
      },

      {
        min: 0,
        id: 'transfers.returns',
        Header: t('headers.returns'),
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: InputCell,
      },
      {
        min: 0,
        id: 'transfers.in',
        Header: t('headers.transfers_in'),
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: InputCell,
      },
      {
        min: 0,
        id: 'transfers.out',
        Header: t('headers.transfers_out'),
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: InputCell,
      },
      {
        id: 'inv.ending',
        Header: t('headers.ending_inv'),
        cellAlign: 'left',
        headerAlign: 'left',
        min: 0,
        Cell: InputCell,
      },
      {
        id: 'adj.dist',
        minWidth: 200,
        Header: t('headers.dist_adj'),
        min: -999999,
        cellAlign: 'left',
        headerAlign: 'left',
        Cell: InputCell,
      },
      {
        id: 'adj.net',
        Header: t('headers.net_adj'),
        Cell: NetCell,
        cellAlign: 'center',
        headerAlign: 'right',
      },
      {
        id: 'adj.diff',
        Header: t('headers.diff'),
        Cell: DiffCell,
        cellAlign: 'center',
        headerAlign: 'right',
      },
      { width: 300, accessor: 'comment' as const, Header: t('headers.comment'), Cell: CommentCell },
      {
        width: '3%',
        accessor: 'is_forced' as const,
        Header: t('headers.interaction'),
        Cell: props => <InteractionCell onDelete={handleSkuDelete} {...props} />,
      },
    ],
    [handleSkuDelete, t],
  )

  const hiddenColumns = useMemo(() => [], [])

  const { columns, rows, prepareRow } = useTable<ReconciliationSku>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data: progress === Progress.SUCCESS ? data : [],
  })

  return (
    <Wrapper>
      <CustomTable
        fixedLayout
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={noop}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell key={column.id} {...column}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            prepareRow(row)
            const { key } = row.getRowProps()
            return (
              <ReconciliationRow
                key={key}
                row={row}
                useFormik={formManager.bind(row.original.id)}
                idx={idx}
                isEditable={isEditable}
              />
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </CustomTable>
      {formManager.dirty && isEditable ? (
        <TableActionPanel onClose={handleReset}>
          <ActionPanelInner>
            <Button
              progress={updateProgress}
              intent={'primary'}
              filled
              onClick={handleSubmit}
              data-test-id={`save-button`}
            >
              {t('common:save')}
            </Button>
            <Button progress={updateProgress} intent={'cancel'} onClick={handleReset}>
              {t('common:reset')}
            </Button>
          </ActionPanelInner>
        </TableActionPanel>
      ) : null}
    </Wrapper>
  )
}

export default ReconciliationListTable
