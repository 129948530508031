import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding: 40px;
`

const Header = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: rgb(168, 184, 202);
`

const StyledLink = styled.a`
  color: ${props => props.theme.color.primary600};
  &:visited {
    color: ${props => props.theme.color.primary600};
  }
`

const UnderMaintenancePage: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <Wrapper>
      <Header>{t('underMaintenance.header')}</Header>
      <Description>
        {t('underMaintenance.descriptionText')}{' '}
        <StyledLink href={'/'}>{t('underMaintenance.descriptionLink')}</StyledLink>
      </Description>
    </Wrapper>
  )
}

export default UnderMaintenancePage
