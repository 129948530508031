import {
  AddToFarmerCartDTO,
  Farmer,
  FarmerDTO,
  FarmerListRequestFilter,
  FarmerListRequestSorting,
  FarmerMetaData,
  FarmerOrderCountRequestFilter,
  FarmerOrderSkuCountRequestFilter,
} from 'modules/domain/farmer/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'
import { ROLES } from 'types/entities'

export const getFarmersList = (
  filter: FarmerListRequestFilter,
  sorting: FarmerListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Farmer>>(endpoints.farmers(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Farmers list fetch error!', err)
    throw err
  }
}

export const getFarmerByPhone = (phone: string) => {
  try {
    return apiClient.get<ListResponse<Farmer>>(endpoints.farmers(), {
      search: phone,
      page: 1,
      page_size: 1,
    })
  } catch (err) {
    console.error('Farmers fetch error!', err)
    throw err
  }
}

export const getCustomersList = (search?: string, filter?: FarmerListRequestFilter, pageSize?: number) => {
  try {
    return apiClient.get<ListResponse<Farmer>>(endpoints.farmers(), {
      ...filter,
      search,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Customers list fetch error!', err)
    throw err
  }
}

export const getFarmer = (id: string) => {
  try {
    return apiClient.get<Farmer>(endpoints.farmers(id))
  } catch (err) {
    console.error('Farmer fetch error!', err)
    throw err
  }
}

export const getFarmerMetaData = (id: string) => {
  try {
    return apiClient.get<FarmerMetaData>(endpoints.farmerMetaData(id))
  } catch (err) {
    console.error('Farmer metadata fetch error!', err)
    throw err
  }
}

export const addFarmer = (farmer: FarmerDTO) => {
  try {
    return apiClient.post<Farmer>(endpoints.farmers(), { ...farmer, role: ROLES.USER })
  } catch (err) {
    console.error('Farmer create error!', err)
    throw err
  }
}

export const addFarmerByRetailer = (farmer: FarmerDTO) => {
  try {
    return apiClient.post<Farmer>(endpoints.farmers(), { ...farmer, role: ROLES.USER })
  } catch (err) {
    console.error('Farmer create error!', err)
    throw err
  }
}

export const updateFarmer = (id: string, farmer: Partial<FarmerDTO>) => {
  return apiClient.put<Farmer>(endpoints.farmers(id), farmer)
}

export const removeFarmer = (id: string) => {
  return apiClient.delete(endpoints.farmers(id))
}

export const farmersCount = async (filter: FarmerListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<Farmer>>(endpoints.farmers(), {
    ...filter,
    page_size: 1,
  })
  return response.total_count
}

export const farmerOrdersCount = async (filter: FarmerOrderCountRequestFilter = {}) => {
  return apiClient.get<ListResponse<number>>(endpoints.farmerOrdersCount(), filter)
}

export const farmerOrdersSkuCount = async (filter: FarmerOrderSkuCountRequestFilter = {}) => {
  return apiClient.get<ListResponse<number>>(endpoints.farmerOrdersSkuCount(), filter)
}

export const addProductToFarmerCart = async (payload: AddToFarmerCartDTO) => {
  return apiClient.post(endpoints.addProductToFarmerCart(), payload)
}
