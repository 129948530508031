import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { DocumentItem, DocumentListRequestSorting, DocumentListRequestFilter, DocumentDTO } from './types'
import { ListResponse } from 'types/api'
import { helpersDownload } from '@agro-club/frontend-shared'

export const getList = (
  filter: DocumentListRequestFilter,
  sorting: DocumentListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<DocumentItem>>(endpoints.document(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<DocumentItem>(endpoints.document(id))
}

export const getItemUrl = async (id: string) => {
  const res = await apiClient.get<ArrayBuffer>(endpoints.documentFileUrl(id), {}, { responseType: 'arraybuffer' })
  return URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
}

export const downloadSignedDocument = async (name: string, documentId: string, userId: string) => {
  const res = await apiClient.get<ArrayBuffer>(
    endpoints.signedDocumentUrl(),
    {
      document_id: documentId,
      user_id: userId,
    },
    { responseType: 'arraybuffer' },
  )
  helpersDownload.downloadBlob(new Blob([res], { type: 'application/pdf' }), {}, name)
  return res
}

export const getSignedDocumentUrl = async (documentId: string, userId: string) => {
  const res = await apiClient.get<ArrayBuffer>(
    endpoints.signedDocumentUrl(),
    {
      document_id: documentId,
      user_id: userId,
    },
    { responseType: 'arraybuffer' },
  )
  return URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
}

export const addItem = (dto: DocumentDTO) => {
  return apiClient.post<DocumentItem>(endpoints.document(), dto)
}

export const updateItem = (id: string, dto: Partial<DocumentDTO>) => {
  return apiClient.put<DocumentItem>(endpoints.document(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<DocumentItem>(endpoints.document(id))
}

export const userDocuments = (userId: string) => {
  return apiClient.get<ListResponse<DocumentItem>>(endpoints.document(), { user_id: userId })
}

export const sendSignRequestToUserEmail = (userId: string, documentId: string) => {
  return apiClient.post(endpoints.documentSendSignRequestToUserEmail(documentId), { user_id: userId })
}

export const needSignRequest = (userId: string, documentId: string) => {
  return apiClient.post(endpoints.documentNeedSign(documentId), { user_id: userId })
}
