import ProductsAvailabilityActions from './duck'
import ProductsAvailabilitySelectors from './selectors'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'

export const useProductsAvailabilityList = createItemListOnceHook(
  ProductsAvailabilitySelectors,
  ProductsAvailabilityActions.listRequested,
)

export const useProductsAvailabilityByIdOnce = createItemByIdOnceHook(
  ProductsAvailabilitySelectors,
  ProductsAvailabilityActions.itemRequested,
)
