import React, { useEffect, useState } from 'react'
import { FilePicker, FilePickerNewFile, ProgressCircle, useAction, useDidMount } from '@agro-club/frontend-shared'
import * as uuid from 'uuid'
import Media, { MediaPlaceholder } from 'views/ui/Media/Media'
import { useSelector } from 'react-redux'
import UploadManagerActions from 'modules/domain/uploadManager/duck'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { imageFileValidator, ValidationError } from 'helpers/imageFileValidator'
import { remove, move } from 'ramda'
import { FileItem, MimeType } from 'views/components/FileManager/types'
import * as Styled from './styled'

const Loader: React.FC<{ percent: number }> = ({ percent }) => {
  return (
    <Styled.LoaderContainer>
      <ProgressCircle radius={30} stroke={6} percent={percent} />
    </Styled.LoaderContainer>
  )
}

const ImagePreview: React.FC<{
  url?: string | File
  onRemove(): void
  fadeIn?: boolean
  uploading?: boolean
  percent?: number
  error?: boolean
}> = ({ url, onRemove, fadeIn, uploading, percent = 0, error = false }) => {
  const [runFadeIn, setFadeIn] = useState(fadeIn)
  const [removing, setRemoving] = useState(false)
  const handleRemove = () => {
    setRemoving(true)
  }

  useEffect(() => {
    let timeout: number
    if (removing) {
      timeout = window.setTimeout(() => {
        onRemove()
      }, 250)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [onRemove, removing])

  useDidMount(() => {
    const timeout = window.setTimeout(() => {
      setFadeIn(false)
    }, 250)
    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <Styled.FadeInContainer running={runFadeIn}>
      <Styled.FadeOutContainer running={removing}>
        <Media url={url} onRemove={handleRemove} />
        {uploading ? <Loader percent={percent} /> : null}
        {error ? <Styled.ErrorContainer /> : null}
      </Styled.FadeOutContainer>
    </Styled.FadeInContainer>
  )
}

const UploadingItem: React.FC<{ file: File; onRemove(): void; id: string }> = ({ file, onRemove, id }) => {
  const uploadingFile = useSelector(UploadManagerSelectors.file(id))
  const cancelUploadAction = useAction(UploadManagerActions.fileUploadCancelled)
  const handleRemove = () => {
    onRemove()
    cancelUploadAction(id)
  }

  return (
    <ImagePreview
      url={file}
      fadeIn={true}
      onRemove={handleRemove}
      uploading={uploadingFile?.status === 'uploading'}
      error={uploadingFile?.status === 'error'}
      percent={uploadingFile?.percent}
    />
  )
}

const MultiMediaSelect: React.FC<{
  items: FileItem[]
  onChange: (items: FileItem[]) => void
  limit?: number
  render?(props: { onClick(): void; addAllowed: boolean }): React.ReactNode
  accept?: string
  title?: string
}> = ({ items, onChange, limit = 3, render, accept = `${MimeType.PNG}, ${MimeType.JPG}`, title }) => {
  const renderItem = (item: FileItem, idx: number) => {
    switch (true) {
      case item.kind === 'current' && typeof item.file === 'string': {
        const onRemove = () => {
          onChange(
            items.map((item, i) => {
              if (i === idx) {
                return {
                  ...item,
                  kind: 'removed',
                }
              }
              return item
            }),
          )
        }
        return <ImagePreview key={`image-preview-${item.id}`} url={item.file} onRemove={onRemove} />
      }
      case item.kind === 'added' && item.file instanceof File: {
        const onRemove = () => {
          onChange(
            remove(
              items.findIndex(i => i === item),
              1,
              items,
            ),
          )
        }
        return (
          <UploadingItem key={`file-preview-${item.id}`} file={item.file as File} onRemove={onRemove} id={item.id} />
        )
      }
      case item.kind === 'invalid' && item.file instanceof File: {
        const onRemove = () => {
          onChange(
            remove(
              items.findIndex(i => i === item),
              1,
              items,
            ),
          )
        }
        return <ImagePreview key={`image-preview-${item.id}`} url={item.file} onRemove={onRemove} error={true} />
      }
      default:
        return null
    }
  }

  const startUploadAction = useAction(UploadManagerActions.fileUploadStarted)
  const handleChange = (files?: FilePickerNewFile[]) => {
    if (!files) {
      return
    }

    const howManyWeCanAdd = limit - items.filter(item => item.kind !== 'removed').length
    if (howManyWeCanAdd > 0) {
      onChange([
        ...items,
        ...files.slice(0, howManyWeCanAdd).map(item => {
          const id = uuid.v4()
          if (item.valid) {
            startUploadAction(id, item.file)
          }
          return {
            file: item.file,
            kind: item.valid ? ('added' as const) : ('invalid' as const),
            id,
            error: item.error as ValidationError,
          }
        }),
      ])
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    onChange(move(result.source.index, result.destination.index, items))
  }

  return (
    <div data-test-id={'multi-media-select'}>
      <Styled.Title>{title}</Styled.Title>
      <FilePicker
        addAllowed={items.filter(item => item.kind !== 'removed').length < limit}
        multiple
        appendMode
        accept={accept}
        onChange={handleChange}
        fileValidator={file => imageFileValidator(file, 2000, 2000, 3e6)}
        render={({ onClick, addAllowed }) => (
          <Styled.Wrapper>
            <Styled.ItemsWrapper>
              {addAllowed ? <MediaPlaceholder onClick={onClick} /> : null}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => {
                    return (
                      <Styled.DropContainer
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                      >
                        {items?.map((item, idx) => {
                          if (item.kind === 'removed') {
                            return null
                          }
                          return (
                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                              {provided => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {renderItem(item, idx)}
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </Styled.DropContainer>
                    )
                  }}
                </Droppable>
              </DragDropContext>
            </Styled.ItemsWrapper>
            {render && render({ onClick, addAllowed })}
          </Styled.Wrapper>
        )}
      />
    </div>
  )
}

export default MultiMediaSelect
