import { EMPTY_ARRAY } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { ProductUnits } from 'modules/domain/product/types'
import { ProductOptions } from 'modules/domain/productOptions/types'
import { DragItemType, MenuOptionType, StorefrontSku } from 'modules/domain/storefront/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Currency, Status } from 'types/entities'
import { useItemDrag } from '../../hooks'
import { NonDraggable } from './StorefrontList'
import RowMenu from './StorefrontRowMenu'
import * as Styled from './styled'
import {
  baseRowCss,
  ProductAvailabilityContainer,
  ProductInStockContainer,
  ProductPriceContainer,
  SkuContainer,
  SkuIdBold,
} from './styled'

const ProductRowContainer = styled.div<{ selected?: boolean; isDragging?: boolean }>`
  ${baseRowCss};
  padding: 12px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.selected ? props.theme.color.primary600 : 'transparent')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  background-color: ${props => (props.selected ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
  opacity: ${props => (props.isDragging ? 0 : 1)};
  cursor: pointer;
  line-height: 18px;
  font-size: 14px;

  &:hover {
    border-color: ${props => props.theme.color.primary600};
    background-color: ${props => props.theme.color.primary25};
  }
`

const Sku: React.FC<{
  onChangeRowMenuOption: (option: MenuOptionType) => void
  packageItem?: PackageTypes
  units?: Omit<ProductUnits, 'singular' | 'plural'>
  productOptionsList?: ProductOptions[]
} & StorefrontSku> = ({
  id,
  sku_id,
  price,
  status,
  is_out_of_stock,
  available_for = EMPTY_ARRAY,
  params: { options },
  product,
  onChangeRowMenuOption,
  packageItem,
  productOptionsList = [],
}) => {
  const { t } = useTranslation('storefront')
  const [ref, dragProps] = useItemDrag(DragItemType.Product, id)
  const { pick } = useLangPicker()
  const { title_i18n: packageTitle, conversion_rate: rate } = { ...packageItem }

  const productOptions = useMemo(
    () =>
      options?.map(({ option_id, params }) => {
        const option = productOptionsList.find(opt => opt.id === option_id)
        return {
          id: option?.id,
          type: t(`productOptions:types.${option?.type}`),
          title: pick(option?.title_i18n),
          params,
        }
      }),
    [options, pick, productOptionsList, t],
  )

  const menuOptions = useMemo(() => {
    return [
      {
        id: MenuOptionType.Edit,
        title: t('rowMenu.edit'),
      },
      {
        id: status === Status.Deleted ? MenuOptionType.Restore : MenuOptionType.Remove,
        title: status === Status.Deleted ? t('rowMenu.restore') : t('rowMenu.remove'),
      },
    ]
  }, [status, t])

  const availabilityString = useMemo(
    () =>
      available_for?.reduce(
        (acc, scope, index) =>
          acc + `${t(`skuForm.availability.${scope}`)}${index < available_for.length - 1 ? ', ' : ''}`,
        '',
      ) ?? '-',
    [available_for, t],
  )

  return (
    <NonDraggable>
      <ProductRowContainer
        ref={ref}
        isDragging={dragProps.isDragging}
        data-test-id={`sku-item-${sku_id}`}
        onClick={() => {
          onChangeRowMenuOption(MenuOptionType.Edit)
        }}
      >
        <div>
          <Styled.Status status={status} />
        </div>
        <SkuContainer>
          <SkuIdBold>{sku_id}</SkuIdBold>
          <div>{pick(product?.title_i18n)}</div>
        </SkuContainer>
        <div>
          {productOptions
            // todo remove filtering by title
            ?.filter(o => !!o.title)
            .map(o => (
              <div key={o.id}>
                {o.type}: {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
              </div>
            ))}
        </div>
        <div>
          <div>{pick(packageTitle)}</div>
          <div>
            x{rate} {rate === 1 ? pick(product?.units?.singular_i18n) : pick(product?.units?.plural_i18n)}
          </div>
        </div>
        <ProductPriceContainer>
          {getPrettyPrice(price || 0, Currency.CAD, {
            currencyDisplay: 'symbol',
          })}
        </ProductPriceContainer>
        <ProductAvailabilityContainer>{availabilityString}</ProductAvailabilityContainer>
        <ProductInStockContainer>
          {is_out_of_stock ? t('common:no') : t('common:yes')}
          <RowMenu onChange={onChangeRowMenuOption} options={menuOptions} />
        </ProductInStockContainer>
      </ProductRowContainer>
    </NonDraggable>
  )
}

export default Sku
