import React from 'react'
import { PREVIEW_WIDTH, PREVIEW_HEIGHT } from './constants'
import { useFileObjectUrl } from '@agro-club/frontend-shared'
import Loader from './Loader'
import * as Styled from './styled'

const PreviewOpenButton: React.FC<{ url: string }> = ({ url }) => {
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Styled.PreviewOpenButton />
    </a>
  ) : null
}

const PdfPreview: React.FC<{
  url: string | File
  uploading?: boolean
  percent?: number
  error?: boolean
  width?: number
  height?: number
  onRemove?(): void
}> = ({ url, uploading, percent = 0, width = PREVIEW_WIDTH, height = PREVIEW_HEIGHT, onRemove, error }) => {
  const { url: fileUrl } = useFileObjectUrl(url)

  return (
    <Styled.PreviewContainer style={{ width: `${width}px` }}>
      <Styled.ButtonsContainer>
        <PreviewOpenButton url={fileUrl as string} />
        <Styled.RemoveButton onClick={onRemove} />
      </Styled.ButtonsContainer>
      <object data={fileUrl} width={String(width)} height={String(height)}>
        <param name="pagemode" value="none" />
        <param name="toolbar" value="0" />
        <param name="statusbar" value="0" />
      </object>
      {uploading ? <Loader percent={percent} /> : null}
      {error ? <Styled.ErrorContainer /> : null}
    </Styled.PreviewContainer>
  )
}

export default PdfPreview
