import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  IconDashboard,
  IconUsers,
  IconCompany,
  IconSettings,
  IconFarmerOrders,
  IconStore,
  IconProducts,
  IconPromocodes,
  IconCategory,
  IconTerritory,
  IconDocument,
  IconFcc,
  IconTarget,
  IconWarehouse,
  MenuContainer,
  MenuItemLink,
  ItemLinkProps,
  IconDeal,
  IconCalendar,
  IconFarmerPlans,
  IconMarketingCampaigns,
  IconSalesTeamPlans,
  Tooltip,
} from '@agro-club/frontend-shared'
import UserRoutes from 'views/pages/User/routes'
import CompaniesRoutes from 'views/pages/Company/routes'
import CategoryRoutes from 'views/pages/Categories/CategoryPage/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CAPABILITY, isPermitted, PERMISSION, useIsPermitted } from 'modules/permissions/permissions'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import TerritoryRoutes from 'views/pages/Territory/routes'
import ReportRoutes from 'views/pages/Reports/routes'
import styled, { StyledProps, ThemeContext } from 'styled-components'
import FarmerRoutes from 'views/pages/Farmer/routes'
import PromocodeRoutes from 'views/pages/Promocode/routes'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import PromoInfoRoutes from 'views/pages/PromoInfo/routes'
import { FeatureFlagModifiers, isAdmin, isAgro, isProducer, ROLES, Sections } from 'types/entities'
import DocumentRoutes from 'views/pages/Document/routes'
import HouseholdsRoutes from 'views/pages/Household/routes'
import DiscountRuleRoutes from 'views/pages/DiscountRule/routes'
import LicenseRoutes from 'views/pages/License/routes'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmSectionAccessString as genFlagName } from 'modules/utils/generateStringHelpers'
import useDebouncedFunction from 'hooks/useDebouncedFunction'
import ReconciliationRoutes from 'views/pages/Reconciliation/routes'
import SeasonRoutes from 'views/pages/Season/routes'
import FarmerPlansRoutes from 'views/pages/FarmerPlans/routes'
import SalesTeamPlansRoutes from 'views/pages/SalesTeamPlans/routes'
import MarketingCampaignsRoutes from 'views/pages/MarketingCampaigns/routes'
import BadgeRoutes from 'views/pages/Badge/routes'
import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import FarmerOrderSkuRoutes from 'views/pages/FarmerOrderSku/routes'
import DistributorOrderSkuRoutes from 'views/pages/DistributorOrderSku/routes'
import useWLFeatureFlags from 'hooks/featureFlags/useWLFeatureFlags'
import NotificationsListRoutes from 'views/pages/NotificationsList/routes'
import AllocationTerritoryRoutes from 'views/pages/AllocationTerritory/routes'
import IncentiveProgramRoutes from 'views/pages/IncentiveCampaign/IncentiveProgram/routes'
import ProgramSummaryRoutes from 'views/pages/ProgramSummary/routes'
import ProductsAvailabilityRoutes from 'views/pages/ProductsAvailability/routes'
import FccOrderSkuRoutes from 'views/pages/FccOrderSku/routes'
import Target2SkuRoutes from 'views/pages/Target2Sku/routes'
import InventoryInTransferRequestSelectors from 'modules/domain/inventoryInTransferRequest/selectors'
import ProductSettingsRoutes from 'views/pages/ProductSettings/routes'
import ChangeLogRoutes from 'views/pages/ChangeLog/routes'

enum OPTION {
  HOME = 'home',
  USERS = 'users',
  FARMERS = 'farmers',
  COMPANIES = 'companies',
  CATEGORIES = 'categories',
  FARMER_ORDERS_SKU = 'farmer_orders_sku',
  DISTRIBUTOR_ORDERS_SKU = 'distributor_orders_sku',
  PRODUCTS = 'products',
  PRODUCT_SETTINGS = 'product_settings',
  BADGES = 'badges',
  UPTAKE = 'uptake',
  TERRITORY = 'territory',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  PROMOCODES = 'promocodes',
  INCENTIVES = 'incentives',
  PROMO_INFO = 'promo_info',
  FCC_ORDERS_SKU = 'fcc_orders_sku',
  DOCUMENTS = 'documents',
  DISCOUNT_RULES = 'discount-rules',
  TARGETS_SKU = 'targets_sku',
  BUSINESS_PLANNING = 'business_planning',
  LICENSES = 'licenses',
  RECONCILIATION = 'reconciliation',
  SEASONS = 'seasons',
  INVENTORY = 'inventory',
  INVENTORY_SKU = 'inventory_sku',
  INVENTORY_REQUESTS = 'Requests',
  INVENTORY_IN_REQUESTS = 'In Requests',
  INVENTORY_OUT_REQUESTS = 'Out Requests',
  INVENTORY_EXCHANGE = 'inventory_exchange',
  FARMER_PLANS = 'farmer_plans',
  SALES_TEAM_PLANS = 'sales_team_plans',
  MARKETING_CAMPAIGNS = 'marketing_campaigns',
  STOREFRONT = 'storefront',
  NOTIFICATIONS_LIST = 'notifications_list',
  ALLOCATION = 'allocation',
  PROGRAM_SUMMARY = 'program_summary',
  PRODUCTS_AVAILABILITY = 'products_availability',
  CHANGE_LOG = 'change_log',
}

export const portalMount = document.getElementById('tooltip-root')

const FarmersItem: React.FC<ItemLinkProps> = ({ children, ...props }) => {
  const count = useSelector(FarmerSelectors.waitingAgreementCount)
  return (
    <MenuItemLink {...props} count={count}>
      {children}
    </MenuItemLink>
  )
}

const RequestItem: React.FC<ItemLinkProps> = ({ children, ...props }) => {
  const count = useSelector(InventoryInTransferRequestSelectors.inRequestsCount)
  return (
    <MenuItemLink {...props} count={count}>
      {children}
    </MenuItemLink>
  )
}

type OptionItem = {
  kind: 'item'
  path?: string | string[]
  paths?: OptionItem[]
  value: OPTION
  key?: string
  icon: React.FC
  capability?: CAPABILITY
  component?: React.FC<ItemLinkProps>
  isHidden?: (
    checkFlagFunc: (flagName: string) => boolean,
    checkWLFlagFunc: (flagName: string) => boolean,
    role?: ROLES,
  ) => boolean
}

type OptionsGroup = {
  kind: 'group'
  children: OptionItem[]
  isHidden?: (
    checkFlagFunc: (flagName: string) => boolean,
    checkWLFlagFunc: (flagName: string) => boolean,
    role?: ROLES,
  ) => boolean
}

const OPTIONS: (OptionItem | OptionsGroup)[] = [
  {
    kind: 'group',
    children: [
      {
        kind: 'item',
        path: DashboardRoutes.Dashboard,
        capability: CAPABILITY.REPORTS,
        value: OPTION.HOME,
        icon: IconDashboard,
      },
    ],
  },
  {
    kind: 'group',
    isHidden: (checkFlag, checkWLFlagFunc, role) =>
      !(
        checkWLFlagFunc(genFlagName(Sections.Storefront, FeatureFlagModifiers.Enabled)) &&
        (isAdmin(role) || checkFlag(genFlagName(Sections.Storefront, FeatureFlagModifiers.Enabled)))
      ),
    children: [
      {
        kind: 'item',
        path: StorefrontRoutes.List,
        capability: CAPABILITY.STOREFRONT,
        value: OPTION.STOREFRONT,
        icon: IconCategory,
        isHidden: (_, _checkWLFlagFunc, role) => !isAdmin(role),
      },
      {
        kind: 'item',
        path: FarmerOrderSkuRoutes.List,
        value: OPTION.FARMER_ORDERS_SKU,
        icon: IconFarmerOrders,
        capability: CAPABILITY.SKU_ORDERS,
        isHidden: (checkFlagFunc, _, role) =>
          !isAgro(role) && !checkFlagFunc(genFlagName(Sections.FarmerOrders, FeatureFlagModifiers.Enabled)),
      },
      {
        kind: 'item',
        value: OPTION.DISTRIBUTOR_ORDERS_SKU,
        icon: IconStore,
        path: DistributorOrderSkuRoutes.List,
        capability: CAPABILITY.DISTRIBUTOR_ORDERS_SKU,
        isHidden: (checkFlagFunc, _, role) =>
          !isAgro(role) && !checkFlagFunc(genFlagName(Sections.RetailerOrders, FeatureFlagModifiers.Enabled)),
      },
      {
        kind: 'item',
        value: OPTION.FCC_ORDERS_SKU,
        icon: IconFcc,
        path: FccOrderSkuRoutes.List,
        capability: CAPABILITY.FCC_ORDERS,
        isHidden: (checkFlag, _, role) =>
          !isAgro(role) && !checkFlag(genFlagName(Sections.FCCReport, FeatureFlagModifiers.Enabled)),
      },
    ],
  },
  {
    kind: 'group',
    children: [
      {
        kind: 'item',
        path: [FarmerRoutes.List, HouseholdsRoutes.List],
        value: OPTION.FARMERS,
        icon: IconUsers,
        capability: CAPABILITY.FARMERS,
        component: FarmersItem,
      },
      {
        kind: 'item',
        path: Target2SkuRoutes.DefaultList,
        value: OPTION.TARGETS_SKU,
        icon: IconTarget,
        capability: CAPABILITY.FARMER_TARGETS,
        isHidden: (checkFlag, _, role) =>
          !(
            isAgro(role) ||
            (checkFlag(genFlagName(Sections.Targets2, FeatureFlagModifiers.Enabled)) &&
              checkFlag(genFlagName(Sections.Storefront, FeatureFlagModifiers.Enabled)))
          ),
      },
      {
        kind: 'item',
        path: SalesTeamPlansRoutes.List,
        value: OPTION.SALES_TEAM_PLANS,
        icon: IconSalesTeamPlans,
        capability: CAPABILITY.SALES_TEAM_PLANS,
      },
      {
        kind: 'item',
        path: FarmerPlansRoutes.List,
        value: OPTION.FARMER_PLANS,
        icon: IconFarmerPlans,
        capability: CAPABILITY.FARMER_PLANS,
      },
      {
        kind: 'item',
        path: MarketingCampaignsRoutes.List,
        value: OPTION.MARKETING_CAMPAIGNS,
        icon: IconMarketingCampaigns,
        capability: CAPABILITY.MARKETING_CAMPAIGNS,
      },
      {
        kind: 'item',
        path: AllocationTerritoryRoutes.List,
        value: OPTION.ALLOCATION,
        icon: IconCategory,
        capability: CAPABILITY.ALLOCATION,
        isHidden: (checkFlag, _, role) =>
          !isAgro(role) && !checkFlag(genFlagName(Sections.Allocation, FeatureFlagModifiers.Enabled)),
      },
    ],
  },
  {
    kind: 'group',
    isHidden: (checkFlag, _, role) =>
      !isAgro(role) && !checkFlag(genFlagName(Sections.InventoryExchange, FeatureFlagModifiers.Enabled)),
    children: [
      {
        kind: 'item',
        path: InventoryExchangeRoutes.Total,
        value: OPTION.INVENTORY_EXCHANGE,
        key: 'inventory_exchange',
        icon: IconWarehouse,
        capability: CAPABILITY.INVENTORY_EXCHANGE,
      },
      // manufacturers and admins see all request on the single screen
      {
        kind: 'item',
        path: InventoryExchangeRoutes.TransferAllRequests,
        value: OPTION.INVENTORY_REQUESTS,
        key: 'inventory_requests',
        icon: IconWarehouse,
        capability: CAPABILITY.INVENTORY_REQUESTS,
        isHidden: (checkFlag, _, role) => {
          return (
            !isAgro(role) &&
            !(isProducer(role) && checkFlag(genFlagName(Sections.InventoryRequests, FeatureFlagModifiers.Enabled)))
          )
        },
      },
      // if a user doesn't have access to `all requests` menu item, show them in/out items
      {
        kind: 'item',
        path: InventoryExchangeRoutes.InRequests,
        value: OPTION.INVENTORY_IN_REQUESTS,
        key: 'inventory_in_requests',
        icon: IconWarehouse,
        capability: CAPABILITY.INVENTORY_IN_REQUESTS,
        component: RequestItem,
        isHidden: (checkFlag, _, role) =>
          isAgro(role) ||
          isProducer(role) ||
          !checkFlag(genFlagName(Sections.InventoryRequests, FeatureFlagModifiers.Enabled)),
      },
      {
        kind: 'item',
        path: InventoryExchangeRoutes.OutRequests,
        value: OPTION.INVENTORY_OUT_REQUESTS,
        key: 'inventory_out_requests',
        icon: IconWarehouse,
        capability: CAPABILITY.INVENTORY_OUT_REQUESTS,
        isHidden: (checkFlag, _, role) =>
          isAgro(role) ||
          isProducer(role) ||
          !checkFlag(genFlagName(Sections.InventoryRequests, FeatureFlagModifiers.Enabled)),
      },
    ],
  },
  {
    kind: 'group',
    children: [
      {
        kind: 'item',
        value: OPTION.SETTINGS,
        icon: IconSettings,
        paths: [
          {
            kind: 'item',
            path: CompaniesRoutes.List,
            value: OPTION.COMPANIES,
            icon: IconCompany,
            capability: CAPABILITY.COMPANIES,
          },
          {
            kind: 'item',
            path: ProductSettingsRoutes.List,
            value: OPTION.PRODUCT_SETTINGS,
            icon: IconSettings,
            capability: CAPABILITY.PRODUCT_SETTINGS,
            isHidden: (checkFlag, _, role) =>
              !checkFlag(genFlagName(Sections.ProductSettings, FeatureFlagModifiers.Enabled)) && !isAgro(role),
          },
          {
            kind: 'item',
            path: BadgeRoutes.List,
            value: OPTION.BADGES,
            icon: IconProducts,
            capability: CAPABILITY.BADGES,
          },
          {
            kind: 'item',
            path: TerritoryRoutes.List,
            value: OPTION.TERRITORY,
            icon: IconTerritory,
            capability: CAPABILITY.TERRITORY,
          },
          {
            kind: 'item',
            path: CategoryRoutes.CategoryList,
            value: OPTION.CATEGORIES,
            icon: IconCategory,
            capability: CAPABILITY.CATEGORIES,
          },
          {
            kind: 'item',
            path: ChangeLogRoutes.List,
            value: OPTION.CHANGE_LOG,
            icon: IconCategory,
            capability: CAPABILITY.CHANGE_LOG,
            isHidden: (checkFlag, _, role) =>
              !(isAgro(role) || checkFlag(genFlagName(Sections.ChangeLog, FeatureFlagModifiers.Enabled))),
          },
          {
            kind: 'item',
            path: PromoInfoRoutes.PromoInfoList,
            value: OPTION.PROMO_INFO,
            icon: IconProducts,
            capability: CAPABILITY.PROMO_INFO,
          },
          {
            kind: 'item',
            path: ReportRoutes.ReportList,
            value: OPTION.REPORTS,
            icon: IconDashboard,
            capability: CAPABILITY.REPORTS_MANAGEMENT,
          },
          {
            kind: 'item',
            path: PromocodeRoutes.List,
            value: OPTION.PROMOCODES,
            icon: IconPromocodes,
            capability: CAPABILITY.PROMOCODES,
          },
          {
            kind: 'item',
            path: DiscountRuleRoutes.List,
            value: OPTION.DISCOUNT_RULES,
            icon: IconPromocodes,
            capability: CAPABILITY.PROMOCODES, // TODO Fine for now. Need to adjust later
          },
          {
            kind: 'item',
            path: ProgramSummaryRoutes.List,
            value: OPTION.PROGRAM_SUMMARY,
            icon: IconPromocodes,
            capability: CAPABILITY.PROGRAM_SUMMARY,
            isHidden: (_, checkWLFlagFunc, role) => !isAgro(role),
          },
          {
            kind: 'item',
            path: IncentiveProgramRoutes.List,
            value: OPTION.INCENTIVES,
            icon: IconPromocodes,
            capability: CAPABILITY.INCENTIVE_PROGRAMS,
            isHidden: (_, checkWLFlagFunc) =>
              !checkWLFlagFunc(genFlagName(Sections.Storefront, FeatureFlagModifiers.Enabled)),
          },
          {
            kind: 'item',
            path: UserRoutes.List,
            value: OPTION.USERS,
            icon: IconUsers,
            capability: CAPABILITY.USERS,
          },
          {
            kind: 'item',
            path: DocumentRoutes.List,
            value: OPTION.DOCUMENTS,
            icon: IconDocument,
            capability: CAPABILITY.DOCUMENTS,
          },
          {
            kind: 'item',
            path: ReconciliationRoutes.Groups,
            value: OPTION.RECONCILIATION,
            icon: IconDeal,
            capability: CAPABILITY.RECONCILIATION,
            isHidden: (checkFlagFunc, _, role) =>
              !isAgro(role) && !checkFlagFunc(genFlagName(Sections.Reconciliations, FeatureFlagModifiers.Enabled)),
          },
          {
            kind: 'item',
            path: SeasonRoutes.List,
            value: OPTION.SEASONS,
            icon: IconCalendar,
            capability: CAPABILITY.SEASONS,
          },
          {
            kind: 'item',
            path: LicenseRoutes.List,
            value: OPTION.LICENSES,
            icon: IconSettings,
            capability: CAPABILITY.LICENSES,
          },
          {
            kind: 'item',
            path: NotificationsListRoutes.List,
            value: OPTION.NOTIFICATIONS_LIST,
            icon: IconDashboard,
            capability: CAPABILITY.LICENSES,
          },
          {
            kind: 'item',
            path: ProductsAvailabilityRoutes.List,
            value: OPTION.PRODUCTS_AVAILABILITY,
            icon: IconProducts,
            capability: CAPABILITY.PRODUCTS_AVAILABILITY,
            isHidden: (checkFlag, _, role) =>
              !isAgro(role) && !checkFlag(genFlagName(Sections.ProductsAvailability, FeatureFlagModifiers.Enabled)),
          },
        ],
      },
    ],
  },
]

const useIsCurrent = () => {
  const location = useLocation()

  return useCallback(
    (path?: string | string[]) => {
      if (Array.isArray(path)) {
        return path.some(str => location.pathname.search(`^${str}[\/?]+|^${str}$`) !== -1)
      } else {
        return path ? location.pathname.search(`^${path}[\/?]+|^${path}$`) !== -1 : false
      }
    },
    [location.pathname],
  )
}

const GroupContainer = styled.div`
  width: calc(100% + 12px);
  left: -12px;
  padding-left: 12px;
  position: relative;
  padding-top: 8px;
  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};

    margin-top: 8px;
  }
  &:empty {
    display: none;
  }
`

const ItemContainer = styled.div`
  margin-top: -8px;
  margin-bottom: -8px;
`

type GroupPropsType = {
  options: OptionItem[]
  compact: boolean
  pageWidth: number
  isHidden?: (
    checkFlagFunc: (flagName: string) => boolean,
    checkWLFlagFunc: (flagName: string) => boolean,
    role?: ROLES,
  ) => boolean
}

const Group: React.FC<GroupPropsType> = ({ options, compact, pageWidth, isHidden }: GroupPropsType) => {
  const checkFeatureFlag = useFeatureFlags()
  const checkWLFeatureFlag = useWLFeatureFlags()

  const role = useSelector(AuthSelectors.role)

  if (isHidden?.(checkFeatureFlag, checkWLFeatureFlag, role || undefined)) {
    return null
  }

  return (
    <GroupContainer>
      {options.map(opt => {
        const notVisibleMenuPointsForProd =
          opt.value.includes[(OPTION.MARKETING_CAMPAIGNS, OPTION.FARMER_PLANS, OPTION.SALES_TEAM_PLANS)] &&
          window.location.hostname !== 'campaign.demo.agro.club'
        if (
          opt?.paths?.every(
            item =>
              !isPermitted(role, item.capability, PERMISSION.R) ||
              item.isHidden?.(checkFeatureFlag, checkWLFeatureFlag, role ?? undefined) ||
              notVisibleMenuPointsForProd,
          )
        ) {
          return null
        }
        return <Item option={opt} key={opt.key ?? opt.value} pageWidth={pageWidth} compact={compact} />
      })}
    </GroupContainer>
  )
}

const SubMenu = styled.div<StyledProps<{ pageWidth: number }>>`
  position: fixed;
  top: 0;
  left: ${({ pageWidth, theme }: StyledProps<{ pageWidth: number }>) =>
    `${pageWidth <= theme.size.minPageWidth ? theme.size.minBarWidth : 236}px`};

  width: ${props => props.theme.size.leftSideBarWidth}px;
  min-height: 100vh;
  max-height: 100vh;
  padding: 20px 12px;

  overflow-y: auto;

  background: #fff;
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.08), -1px 0 0 rgba(0, 0, 0, 0.08);
  z-index: 100;
  &:empty {
    display: none;
  }
`

type ItemPropsType = {
  option: OptionItem
  pageWidth: number
  compact?: boolean
}

const TooltipContent = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Item: React.FC<ItemPropsType> = ({ option, pageWidth, compact }: ItemPropsType) => {
  const checkFeatureFlag = useFeatureFlags()
  const checkWLFeatureFlag = useWLFeatureFlags()

  const isCurrent = useIsCurrent()
  const { t } = useTranslation('menu')
  const isPermitted = useIsPermitted()
  const role = useSelector(AuthSelectors.role)
  const [open, setOpen] = useState(false)
  const subMenuRef = useRef<HTMLDivElement>(null)
  const linkRef = useRef<HTMLDivElement>(null)
  const testId = `menu-${option.value}`
  const [coords, setCoords] = useState({ top: 0, left: 0 })
  const [isOn, setOn] = useState(false)

  useEffect(() => {
    if (open) {
      const keyHandler = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setOpen(false)
        }
      }
      const mouseHandler = (e: MouseEvent) => {
        const el = e.target as HTMLElement
        if (
          el === subMenuRef.current ||
          subMenuRef.current?.contains(el) ||
          linkRef.current === el ||
          linkRef.current?.contains(el)
        ) {
          return
        }
        setOpen(false)
      }
      document.addEventListener('keyup', keyHandler)
      document.addEventListener('mouseup', mouseHandler)
      return () => {
        document.removeEventListener('keyup', keyHandler)
        document.removeEventListener('mouseup', mouseHandler)
      }
    }
  }, [open])

  //TODO refactor
  const notVisibleMenuPointsForProd =
    (option.value === OPTION.MARKETING_CAMPAIGNS ||
      option.value === OPTION.FARMER_PLANS ||
      option.value === OPTION.SALES_TEAM_PLANS) &&
    window.location.hostname !== 'campaign.demo.agro.club'

  if (option.isHidden?.(checkFeatureFlag, checkWLFeatureFlag, role ?? undefined) || notVisibleMenuPointsForProd) {
    return null
  }

  const Wrapper = option.component || MenuItemLink

  const active = isCurrent(option.path)

  const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const widthWithScroll = e.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.clientWidth

    setCoords({
      left: (widthWithScroll && widthWithScroll + (option?.paths ? 0 : rect.x)) || 0,
      top: rect.y + rect.height / 2,
    })
    setOn(true)
  }

  const renderOption = (activeToRender: boolean) => (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          setCoords({ top: 0, left: 0 })
          setOn(false)
        }}
      >
        <Wrapper
          data-test-id={testId}
          icon={option.icon}
          active={activeToRender}
          path={typeof option.path === 'string' ? option.path : option.path?.[0]}
        >
          {!compact ? t(option.value) : null}
        </Wrapper>
      </div>
      {isOn && compact && (
        <Tooltip
          position="right"
          visibleOnHover
          portalProps={{ mount: portalMount as HTMLElement, coords }}
          tooltipContent={<TooltipContent>{t(option.value)}</TooltipContent>}
        />
      )}
    </>
  )

  if (option.path) {
    if (!isPermitted(role, option.capability, PERMISSION.R)) {
      return null
    }
    return (
      <MenuContainer compact={compact}>
        <ItemContainer>{renderOption(active)}</ItemContainer>
      </MenuContainer>
    )
  }

  if (option.paths && option.paths.filter(item => isPermitted(role, item.capability, PERMISSION.R)).length === 0) {
    return null
  }

  if (option.paths) {
    const allChildrenHidden = option.paths?.every(item =>
      !item.isHidden ? true : !!item.isHidden(checkFeatureFlag, checkWLFeatureFlag, role ?? undefined),
    )

    if (allChildrenHidden) return null
  }

  const walkThroughTillFindActive = (list: OptionItem[] = []): boolean => {
    for (const item of list) {
      if (typeof item.path === 'string') {
        if (isCurrent(item.path)) {
          return true
        }
      } else {
        return walkThroughTillFindActive(item.paths)
      }
    }
    return false
  }

  const activeFromTree = walkThroughTillFindActive(option.paths)

  const subMenuJsx = open && (
    <SubMenu ref={subMenuRef} pageWidth={compact ? 72 : pageWidth}>
      {option.paths?.map(item => (
        <Item key={item.value} option={item} pageWidth={pageWidth} />
      ))}
    </SubMenu>
  )

  return (
    <div onClick={() => setOpen(!open)}>
      {subMenuJsx}
      <MenuContainer compact={compact} className={ItemContainer}>
        <ItemContainer>{renderOption(activeFromTree)}</ItemContainer>
      </MenuContainer>
    </div>
  )
}

const MainMenu = ({ compact }: { compact?: boolean }) => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setPageWidth(window.innerWidth)
  }

  const deboundcedFunc = useDebouncedFunction(handleResize, 100)

  useEffect(() => {
    window.addEventListener('resize', deboundcedFunc)
    return () => window.removeEventListener('resize', handleResize)
  }, [deboundcedFunc])

  const themeContext = useContext(ThemeContext)

  return (
    <div>
      {OPTIONS.map((opt, idx) => {
        if (opt.kind === 'group') {
          return (
            <Group
              options={opt.children}
              isHidden={opt.isHidden}
              key={idx}
              compact={compact || pageWidth < themeContext.size.minPageWidth}
              pageWidth={pageWidth}
            />
          )
        }
        return (
          <Item
            option={opt}
            key={opt.value}
            compact={compact || pageWidth < themeContext.size.minPageWidth}
            pageWidth={pageWidth}
          />
        )
      })}
    </div>
  )
}

export default MainMenu
