import { all, call, put, takeLatest } from 'redux-saga/effects'
import ReturnDeclarationSkuActions from './duck'
import managers from './managers'
import { RequestError } from 'modules/errors'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'
import ReturnDeclarationSkuSelectors from './selectors'
import ReturnDeclarationSkuRoutes from 'views/pages/ReturnDeclarationSku/routes'
import { updateLocationQuery } from 'modules/sagaHelpers'

const sagas = createBasicSagas({
  managers,
  selectors: ReturnDeclarationSkuSelectors,
  actions: ReturnDeclarationSkuActions,
  onAddItemSucceed: function*(duplicate, item) {
    if (!duplicate) {
      yield put(push(generatePath(ReturnDeclarationSkuRoutes.Edit, { id: item.id })))
    }
  },
  onRemoveItemSucceed: function*() {
    yield put(push(ReturnDeclarationSkuRoutes.List))
  },
  onUpdateItemSucceed: function*(duplicate) {
    if (duplicate) {
      yield put(push(ReturnDeclarationSkuRoutes.Add))
    }
  },
  onListRequestSucceed: function*({ page }) {
    yield call(updateLocationQuery, ReturnDeclarationSkuRoutes.List, { page })
  },
})

export const printDeclaration = function*({ payload }: ReturnType<typeof ReturnDeclarationSkuActions.printRequested>) {
  try {
    yield call(managers.printDeclaration, payload)
    yield put(ReturnDeclarationSkuActions.printSucceed())
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(ReturnDeclarationSkuActions.printFailed(type))
  }
}

export const fetchDeclarationCount = function*({
  payload,
}: ReturnType<typeof ReturnDeclarationSkuActions.countRequested>) {
  try {
    const declarationCount = yield call(managers.getCount, payload)
    yield put(ReturnDeclarationSkuActions.countRequestedSucceed(declarationCount))
  } catch (err) {
    const { errors } = RequestError.parseError(err)
    yield put(ReturnDeclarationSkuActions.countRequestedFailed(errors))
  }
}

const ReturnDeclarationSkuSaga = function*() {
  yield all([
    takeLatest(ReturnDeclarationSkuActions.listRequested.type, sagas.fetchList),
    takeLatest(ReturnDeclarationSkuActions.filterUpdated.type, sagas.fetchList),
    takeLatest(ReturnDeclarationSkuActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(ReturnDeclarationSkuActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(ReturnDeclarationSkuActions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(ReturnDeclarationSkuActions.listRequestedNext.type, sagas.fetchListNext),

    takeLatest(ReturnDeclarationSkuActions.itemRequested.type, sagas.fetchItem),
    takeLatest(ReturnDeclarationSkuActions.addRequested.type, sagas.addItem),
    takeLatest(ReturnDeclarationSkuActions.updateRequested.type, sagas.updateItem),
    takeLatest(ReturnDeclarationSkuActions.removeRequested.type, sagas.removeItem),

    takeLatest(ReturnDeclarationSkuActions.printRequested.type, printDeclaration),
    takeLatest(ReturnDeclarationSkuActions.countRequested.type, fetchDeclarationCount),
  ])
}

export default ReturnDeclarationSkuSaga
