import { all, call, put, select, takeLatest, fork } from 'redux-saga/effects'
import DistributorOrderActions from './duck'
import DistributorOrderSelectors from './selectors'
import { DistributorOrder } from 'types/distributorOrder'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { ticker, updateLocationQuery, mapFilesToDto } from 'modules/sagaHelpers'
import DistributorOrderRoutes from 'views/pages/DistributorOrder/routes'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import { FarmerOrderStatus } from 'types/farmerOrder'
import { RequestError } from 'modules/errors'
import { COUNTERS_FETCH_DELAY_MS } from 'modules/constants'
import { isDistributor } from 'types/entities'
import * as uuid from 'uuid'
import { changePersistentFilters, getPersistentFilter } from '../../utils/helpers'

const prepareOrder = (order: DistributorOrder): DistributorOrder => {
  order.items.forEach(item => (item.key = uuid.v4()))
  return order
}

export const fetchList = function*(props) {
  try {
    changePersistentFilters(props.type, props.payload)
    let currentPage = yield select(DistributorOrderSelectors.page)
    const filter = yield select(DistributorOrderSelectors.filter)
    const persistentFilters = getPersistentFilter('season_id')
    const filterUpdated = {
      ...filter,
      ...persistentFilters,
    }

    yield put(DistributorOrderActions.filtersUpdatedWithPersistentStorage(filterUpdated))

    const sorting = yield select(DistributorOrderSelectors.sorting)
    const pageSize = yield select(DistributorOrderSelectors.pageSize)

    let response: ListResponse<DistributorOrder> = yield call(
      managers.getList,
      filterUpdated,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filterUpdated, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(DistributorOrderActions.listRequestSucceed(data.map(prepareOrder), total_count, page))

    if (currentPage) {
      yield call(updateLocationQuery, DistributorOrderRoutes.List, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.listRequestFailed(errType))
  }
}

export const fetchListUpdate = function*() {
  try {
    const currentPage = yield select(DistributorOrderSelectors.page)
    const filter = yield select(DistributorOrderSelectors.filter)
    const sorting = yield select(DistributorOrderSelectors.sorting)
    const pageSize = yield select(DistributorOrderSelectors.pageSize)
    const response: ListResponse<DistributorOrder> = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage || 1,
      pageSize,
    )
    const { data, total_count } = response
    yield put(DistributorOrderActions.listUpdateRequestSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.listUpdateRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(DistributorOrderSelectors.page)
    const filter = yield select(DistributorOrderSelectors.filter)
    const sorting = yield select(DistributorOrderSelectors.sorting)
    const pageSize = yield select(DistributorOrderSelectors.pageSize)
    const { data, total_count }: { data: DistributorOrder[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DistributorOrderActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof DistributorOrderActions.itemRequested>) {
  try {
    const order: DistributorOrder = yield call(managers.getItem, id)
    yield put(DistributorOrderActions.itemRequestSucceed(prepareOrder(order)))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({
  payload: [dto, duplicate],
}: ReturnType<typeof DistributorOrderActions.addRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const files = yield mapFilesToDto(filesMeta)
    const order: DistributorOrder = yield call(managers.addItem, { ...rest, files })
    yield put(DistributorOrderActions.addSucceed(prepareOrder(order)))
    if (!duplicate) {
      yield put(push(generatePath(DistributorOrderRoutes.Edit, { id: order.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.addFailed(errType))
  }
}
export const updateItem = function*({
  payload: [id, dto, duplicate],
}: ReturnType<typeof DistributorOrderActions.updateRequested>) {
  try {
    const { files: filesMeta = [], ...rest } = dto
    const files = yield mapFilesToDto(filesMeta)
    const order: DistributorOrder = yield call(managers.updateItem, id, { ...rest, files })
    yield put(DistributorOrderActions.updateSucceed(prepareOrder(order)))
    if (duplicate) {
      yield put(push(DistributorOrderRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof DistributorOrderActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(DistributorOrderActions.removeSucceed(payload))
    yield put(push(DistributorOrderRoutes.List))
    yield put(DistributorOrderActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DistributorOrderActions.removeFailed(payload, errType))
  }
}

export const newOrdersCountFetcher = ticker(function*({ role }) {
  if (isDistributor(role)) {
    return false
  }
  try {
    const count = yield call(managers.ordersCount, { status: FarmerOrderStatus.New })
    yield put(DistributorOrderActions.newOrdersCountRequestSucceed(count))
  } catch (err) {
    console.error(err)
    yield put(DistributorOrderActions.newOrdersCountRequestError())
    const errType = err instanceof RequestError ? err.type : 'unknown'
    if (['forbidden_error', 'unauthorized_error'].includes(errType)) {
      return false
    }
  }

  return true
}, COUNTERS_FETCH_DELAY_MS)

const DistributorOrderSaga = function*() {
  yield all([
    takeLatest(DistributorOrderActions.itemRequested.type, fetchItem),
    takeLatest(DistributorOrderActions.listRequested.type, fetchList),
    takeLatest(DistributorOrderActions.filterUpdated.type, fetchList),
    takeLatest(DistributorOrderActions.sortingUpdated.type, fetchList),
    takeLatest(DistributorOrderActions.filterHasBeenReset.type, fetchList),
    takeLatest(DistributorOrderActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DistributorOrderActions.listRequestedNext.type, fetchListNext),
    takeLatest(DistributorOrderActions.listUpdateRequested.type, fetchListUpdate),

    takeLatest(DistributorOrderActions.addRequested.type, addItem),
    takeLatest(DistributorOrderActions.updateRequested.type, updateItem),
    takeLatest(DistributorOrderActions.removeRequested.type, removeItem),

    fork(newOrdersCountFetcher),
  ])
}

export default DistributorOrderSaga
