import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import * as Styled from 'views/pages/User/UserList/styles'
import CompanyTypeFilter from 'views/components/TableFilters/CompanyTypeFilter'
import CountryFilter from 'views/components/TableFilters/CountryFilter'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import { User, UserListRequestFilter } from 'modules/domain/user/types'
import UserSelectors from 'modules/domain/user/selectors'
import UserActions from 'modules/domain/user/duck'
import { useUserList } from 'modules/domain/user/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ROLES, Status } from 'types/entities'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import { getPermissionLevel, getRoleName } from 'modules/domain/user/helpers'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import {
  AdvancedHeadCell,
  Button,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'

const NameCell: React.FC<CellProps<User>> = ({ cell, column, row }) => {
  return (
    <Styled.NameCell key={column.id}>
      <Styled.Status status={row.values.status} />
      {`${cell.value || ''} ${row.values.last_name || ''}`}
    </Styled.NameCell>
  )
}

const CountryCell: React.FC<CellProps<User>> = ({ cell, column }) => {
  const { t } = useTranslation('common')
  return (
    <div key={column.id}>
      <TComponents.Flag code={cell.value} />
      {cell.value ? t(`country.full.${cell.value}`) : ''}
    </div>
  )
}

const CompanyCell: React.FC<CellProps<User>> = ({ cell }) => {
  return cell.value ? cell.value.internal_name : null
}

const RoleCell: React.FC<CellProps<User>> = ({ cell, column }) => {
  const { t } = useTranslation(['permission', 'role'])

  try {
    const permission = getPermissionLevel(cell.value as ROLES)
    const role = getRoleName(cell.value as ROLES)
    return <div key={column.id}>{`${t(`role:${role}`)} ${permission ? t(permission) : ''}`}</div>
  } catch (e) {
    return <div key={column.id}>{''}</div>
  }
}

const NameColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('user')
  return (
    <div>
      <StatusIcon />
      <span>{t('list.tableHeaders.firstNameLastName')}</span>
    </div>
  )
}

const UsersSortableHeadCell = AdvancedHeadCell<keyof User>()

const UserList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['user', 'labels', 'common'])
  const [progress, data] = useUserList()
  const filterUpdated = useAction(UserActions.filterUpdated)
  const sortingUpdated = useAction(UserActions.sortingUpdated)
  const listRequested = useAction(UserActions.listRequested)
  const filterValue = useSelector(UserSelectors.filter)
  const total = useSelector(UserSelectors.total)
  const pages = useSelector(UserSelectors.pages)
  const page = useSelector(UserSelectors.page)
  const pageSize = useSelector(UserSelectors.pageSize)
  const { company_type, status, country } = filterValue
  const { sort_field, sort_reversed } = useSelector(UserSelectors.sorting)

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: NameColumnHeaderCell,
        accessor: 'first_name' as const,
        Cell: NameCell,
        sortable: true,
      },

      {
        Header: t('list.tableHeaders.phone'),
        accessor: 'phone_number' as const,
      },
      {
        Header: t('list.tableHeaders.email'),
        accessor: 'email' as const,
      },
      {
        Header: t('list.tableHeaders.country'),
        accessor: 'country' as const,
        Cell: CountryCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.company'),
        accessor: 'company' as const,
        sortable: false,
        Cell: CompanyCell,
      },
      {
        Header: t('list.tableHeaders.roleOrPosition'),
        accessor: 'role' as const,
        Cell: RoleCell,
        sortable: true,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof User)[] = useMemo(() => ['last_name', 'status', 'company_type'], [])

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<User>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as User[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<UserListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const statusFilterOptions = useMemo(
    () => [
      {
        value: Status.Active,
        title: t('status.active'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: Status.Inactive,
        title: t('status.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('userList')

  const isFilterApplied = Object.values(filterValue).some(Boolean)
  const [progressCSV, downloadCSV] = useDownload(endpoints.users('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TComponents.FiltersWrapper>
          <CompanyTypeFilter filterValue={{ company_type }} handleFilterChange={handleFilterChange} />
          <CountryFilter filterValue={{ country }} handleFilterChange={handleFilterChange} />
          <TableFilter
            handleFilterChange={handleFilterChange}
            title={t('labels:status')}
            options={statusFilterOptions}
            filterValue={{ status }}
            disableMulti
          />
          <CompanyMultiSelect
            maxWidth="250px"
            isClearable
            label={t('user:form.company')}
            placeholder={t('common:any')}
            onChange={company_list => {
              handleFilterChange({ ...filterValue, company_id: company_list })
            }}
            values={filterValue.company_id}
          />
        </TComponents.FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <UsersSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof User}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </UsersSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default UserList
