import { all, call, put, takeLatest } from 'redux-saga/effects'

import { RequestError } from 'modules/errors'
import RetailerAllocationActions from './duck'
import RetailerAllocationSelectors from './selectors'
import { retailerManagers } from './managers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'

const retailerAllocationSagas = createBasicSagas({
  selectors: RetailerAllocationSelectors,
  actions: RetailerAllocationActions,
  managers: retailerManagers,
})

export const fetchFarmerOrdersProductLimit = function*({
  payload,
}: ReturnType<typeof RetailerAllocationActions.farmerOrdersProductsLimitRequested>) {
  try {
    const { items } = yield call(retailerManagers.farmerOrdersProductLimit, payload)
    yield put(RetailerAllocationActions.farmerOrdersProductsLimitRequestSucceed(items))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(RetailerAllocationActions.farmerOrdersProductsLimitRequestFailed(errType))
  }
}

export const fetchRetailerOrdersProductLimit = function*({
  payload,
}: ReturnType<typeof RetailerAllocationActions.retailerOrdersProductsLimitRequested>) {
  try {
    const { items } = yield call(retailerManagers.retailerOrdersProductLimit, payload)
    yield put(RetailerAllocationActions.retailerOrdersProductsLimitRequestSucceed(items))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(RetailerAllocationActions.retailerOrdersProductsLimitRequestFailed(errType))
  }
}

const RetailerAllocationSaga = function*() {
  yield all([
    takeLatest(RetailerAllocationActions.listRequested.type, retailerAllocationSagas.fetchList),
    takeLatest(RetailerAllocationActions.itemRequested.type, retailerAllocationSagas.fetchItem),
    takeLatest(RetailerAllocationActions.addRequested.type, retailerAllocationSagas.addItem),
    takeLatest(RetailerAllocationActions.updateRequested.type, retailerAllocationSagas.updateItem),
    takeLatest(RetailerAllocationActions.removeRequested.type, retailerAllocationSagas.removeItem),
    takeLatest(RetailerAllocationActions.filterUpdated.type, retailerAllocationSagas.fetchList),
    takeLatest(RetailerAllocationActions.farmerOrdersProductsLimitRequested.type, fetchFarmerOrdersProductLimit),
    takeLatest(RetailerAllocationActions.retailerOrdersProductsLimitRequested.type, fetchRetailerOrdersProductLimit),
    takeLatest(RetailerAllocationActions.addSucceed.type, retailerAllocationSagas.fetchList),
  ])
}

export default RetailerAllocationSaga
