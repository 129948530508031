import PromocodeActions from 'modules/domain/promocode/duck'
import managers from 'modules/domain/promocode/managers'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'

export const usePromocodeList = createItemListOnceHook(PromocodeSelectors, PromocodeActions.listRequested)

export const usePromocodeByIdOnce = createItemByIdOnceHook(PromocodeSelectors, PromocodeActions.itemRequested)

export const usePromocodeById = createItemByIdHook(PromocodeSelectors, PromocodeActions.itemRequested)

export const usePromocodeValidation = (company_id?: string | null) => {
  if (!company_id) {
    return () => {
      return null
    }
  }

  return async (code: string, applied_promocodes?: string[]) => {
    return await managers.checkPromocode({ code, company_id, applied_promocodes })
  }
}
