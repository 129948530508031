import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet } from '@agro-club/frontend-shared'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import Allocation from '../AllocationRetailer/Allocation'
import AllocationRetailerRoutes from '../AllocationRetailer/routes'
import AllocationTerritoryRoutes from '../AllocationTerritory/routes'
import TerritoryAllocationActions from 'modules/domain/allocation/territoryAllocation/duck'

const AllocationPage: React.FC = () => {
  const { t } = useTranslation(['allocation'])
  const { tab } = useParams<{ tab: 'retailer' | 'territories' }>()

  const route = tab === 'retailer' ? AllocationRetailerRoutes : AllocationTerritoryRoutes
  const clearRetailerLimit = useAction(TerritoryAllocationActions.clearRetailerProductLimit)

  useEffect(() => clearRetailerLimit(), [])

  useHelmet({ title: t('title') })

  return (
    <Switch>
      <Route path={route.List}>
        <Allocation activeTab={tab} />
      </Route>
      <Redirect to={AllocationTerritoryRoutes.List} />
    </Switch>
  )
}

export default AllocationPage
