export const saveAccessToken = (accessToken: string): void => localStorage.setItem('access_token', accessToken)
export const getAccessToken = (): string | null => localStorage.getItem('access_token')

export const saveRefreshToken = (accessToken: string): void => localStorage.setItem('refresh_token', accessToken)
export const getRefreshToken = (): string | null => localStorage.getItem('refresh_token')

export const clearToken = (): void => localStorage.removeItem('access_token')
export const clearRefreshToken = (): void => localStorage.removeItem('refresh_token')
export const clearProducer = () => localStorage.removeItem('producer_id')

if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.saveAccessToken = saveAccessToken
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.saveRefreshToken = saveRefreshToken
}
