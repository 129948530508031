import i18n from 'i18n'
import { CompanyType, Permissions, ROLES } from 'types/entities'

export const getRoleName = (role: ROLES): CompanyType | null => {
  if (role === ROLES.USER) {
    return CompanyType.User
  }
  const roleName = role.split(':')[0]
  if (!roleName) {
    return null
  }

  return roleName as CompanyType
}

export const getPermissionLevel = (role: ROLES): Permissions | null => {
  const permission = role.split(':')[1]
  if (!permission) {
    return null
  }

  return permission as Permissions
}

export const getRoleWithPermission = (role?: ROLES) => {
  if (!role) return null

  const roleName = getRoleName(role)
  const translatedRoleName = i18n.t(`role:${roleName}`)
  const permission = getPermissionLevel(role)
  const translatedPermission = i18n.t(`permission:${permission}`)

  return `${translatedRoleName} ${translatedPermission}`
}
