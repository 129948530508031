import React, { useState } from 'react'
import { FormikHook, Input, Banner } from '@agro-club/frontend-shared'
import { HexColorPicker } from 'react-colorful'
import styled from 'styled-components'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'

type StylesFormProps = {
  primaryText?: string
  secondaryText?: string
  primaryColor: string
  secondaryColor: string
  startColor: string
  endColor: string
}

const ColorFieldWrapper = styled.div`
  position: relative;
`

const PickerContainer = styled.div`
  position: absolute;
  margin-top: 8px;
  z-index: ${props => props.theme.zIndex.popup};
`

const ColorField: React.FC<{ label: string; value: string; onChange: (val: string) => void }> = ({
  label,
  value,
  onChange,
}) => {
  const [showPicker, setShowPicker] = useState(false)

  return (
    <ColorFieldWrapper>
      <Input
        label={label}
        value={value}
        onChange={(_, val) => onChange(val)}
        onFocus={() => setShowPicker(true)}
        onBlur={() => setShowPicker(false)}
      />
      {showPicker && (
        <PickerContainer>
          <HexColorPicker color={value} onChange={onChange} />
        </PickerContainer>
      )}
    </ColorFieldWrapper>
  )
}

const StylesForm: React.FC<StylesFormProps & {
  useFormik: FormikHook
}> = ({ startColor, endColor, primaryColor, secondaryColor, primaryText, secondaryText, useFormik }) => {
  const formik = useFormik({
    initialValues: {
      startColor: startColor || '#ebb320',
      endColor: endColor || '#f7901e',
      primaryColor: primaryColor || '#186AEA',
      secondaryColor: secondaryColor || '#889BB1',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
  })

  const { t } = useTranslation('promoInfo')

  return (
    <>
      <Styled.FormRow>
        <ColorField
          label={t('form.labels.startColor')}
          value={formik.values.startColor}
          onChange={(color: string) => formik.setFieldValue('startColor', color)}
        />
        <ColorField
          label={t('form.labels.endColor')}
          value={formik.values.endColor}
          onChange={(color: string) => formik.setFieldValue('endColor', color)}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <ColorField
          label={t('form.labels.primaryColor')}
          value={formik.values.primaryColor}
          onChange={(color: string) => formik.setFieldValue('primaryColor', color)}
        />
        <ColorField
          label={t('form.labels.secondaryColor')}
          value={formik.values.secondaryColor}
          onChange={(color: string) => formik.setFieldValue('secondaryColor', color)}
        />
      </Styled.FormRow>
      <Banner
        styles={{
          startColor: formik.values.startColor,
          endColor: formik.values.endColor,
          primaryColor: formik.values.primaryColor,
          secondaryColor: formik.values.secondaryColor,
        }}
        primaryText={primaryText || 'Banner primary text.'}
        secondaryText={secondaryText || 'Banner secondary text.'}
        size={'large'}
      />
    </>
  )
}

export default StylesForm
