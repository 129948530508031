import React from 'react'
import ReactJson from 'react-json-view'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { SectionBody, SectionContainer, FormComponents } from '@agro-club/frontend-shared'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { useChangeLogByIdOnce } from 'modules/domain/changeLog/hooks'
import { FormText, useHistoryPush } from '@agro-club/frontend-shared'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import styled, { StyledProps } from 'styled-components'
import useDateFormatFn from 'hooks/useDateFormatFn'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import ChangeLogRoutes from '../routes'

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`
export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

const ChangeLogDetail: React.FC = () => {
  const { t } = useTranslation('changeLog')
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const goBack = () => push({ route: ChangeLogRoutes.List })
  const getFormatedDate = useDateFormatFn({ withTime: true })
  const [progress, entry] = useChangeLogByIdOnce(params.id)

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  if (progress === Progress.WORK) {
    return loading()
  }

  const diff = entry?.diff || {}
  const objectId = entry?.obj_id
  const entity = entry?.collection
  const operation = entry?.operation
  const first_name = entry?.user?.first_name || ''
  const email = entry?.user?.email || ''
  let author = `${first_name} ${first_name.length ? '/' : ''} ${email}`
  author = author.trim()
  const lastUpdate = getFormatedDate(entry?.updated_at)
  const createDate = getFormatedDate(entry?.created_at)
  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('itemTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StickyFooterLayout.Wrapper>
          <StickyFooterLayout.Body>
            <Wrapper>
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <FormComponents.FormSection title={t('labels.info')}>
                      {objectId && <FormText label={t('headers.objectId')}>{objectId}</FormText>}
                      {entity && <FormText label={t('headers.entity')}>{entity}</FormText>}
                      {operation && <FormText label={t('headers.operation')}>{operation}</FormText>}
                      {author && <FormText label={t('headers.author')}>{author}</FormText>}
                      {lastUpdate && <FormText label={t('headers.lastUpdate')}>{lastUpdate}</FormText>}
                      {createDate && <FormText label={t('headers.createDate')}>{createDate}</FormText>}
                    </FormComponents.FormSection>
                  </SectionBody>
                </SectionContainer>
              </Column>
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <FormComponents.FormSection title={t('labels.diff')}>
                      <ReactJson src={diff} collapsed />
                    </FormComponents.FormSection>
                  </SectionBody>
                </SectionContainer>
              </Column>
            </Wrapper>
          </StickyFooterLayout.Body>
        </StickyFooterLayout.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ChangeLogDetail
