import { push } from 'connected-react-router'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { generatePath } from 'react-router-dom'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import IncentiveProgramRoutes from 'views/pages/IncentiveCampaign/IncentiveProgram/routes'
import IncentiveProgramActions from './duck'
import managers from './managers'
import IncentiveProgramSelectors from './selectors'

const sagas = createBasicSagas({
  selectors: IncentiveProgramSelectors,
  actions: IncentiveProgramActions,
  managers,
  onAddItemSucceed: function*(_, item) {
    yield put(push(generatePath(IncentiveProgramRoutes.Edit, { id: item.id })))
  },
  onRemoveItemSucceed: function*() {
    yield put(push(IncentiveProgramRoutes.List))
  },
  onListRequestSucceed: function*(response) {
    yield call(updateLocationQuery, IncentiveProgramRoutes.List, { page: response.page })
  },
})

const IncentiveProgramSaga = function*() {
  yield all([
    takeLatest(IncentiveProgramActions.itemRequested.type, sagas.fetchItem),
    takeLatest(IncentiveProgramActions.listRequested.type, sagas.fetchList),
    takeLatest(IncentiveProgramActions.filterUpdated.type, sagas.fetchList),
    takeLatest(IncentiveProgramActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(IncentiveProgramActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(IncentiveProgramActions.sortingHasBeenReset.type, sagas.fetchList),

    takeLatest(IncentiveProgramActions.listRequestedNext.type, sagas.fetchListNext),

    takeLatest(IncentiveProgramActions.addRequested.type, sagas.addItem),
    takeLatest(IncentiveProgramActions.updateRequested.type, sagas.updateItem),
    takeLatest(IncentiveProgramActions.removeRequested.type, sagas.removeItem),
  ])
}

export default IncentiveProgramSaga
