import { MenuOptionType, MenuOption } from 'modules/domain/storefront/types'
import React, { useCallback } from 'react'
import { SimpleSelect } from '@agro-club/frontend-shared'
import * as Styled from './styled'

const DropdownIndicator: React.FC = () => <Styled.RowOptionsButton />

export const RowMenu: React.FC<{ options: MenuOption[]; onChange?: (option: MenuOptionType) => void }> = ({
  options,
  onChange,
}) => {
  const handleChange = useCallback(option => onChange?.(option), [onChange])

  return (
    <Styled.RowMenuContainer
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <SimpleSelect
        menuPlacement="top"
        data-test-id="sku-row-menu"
        customStyles={Styled.customRowMenuStyles}
        options={options}
        onChange={handleChange}
        components={{ DropdownIndicator }}
        placeholder={null}
        minWidth={0}
        isSearchable={false}
      />
    </Styled.RowMenuContainer>
  )
}

export default RowMenu
