import ProgramSummaryActions from './duck'
import ProgramSummarySelectors from './selectors'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { Progress, ResourceHook } from 'modules/types'
import { useSelector } from 'react-redux'
import { useAction, useDidMountWithProgress, usePageQuery } from '@agro-club/frontend-shared'
import { ProgramDTO, ProgramSummaryListEntry } from './types'

export const useProgramSummaryList: ResourceHook<ProgramSummaryListEntry[]> = () => {
  const actualProgress = useSelector(ProgramSummarySelectors.listFetchProgress)
  const list = useSelector(ProgramSummarySelectors.list)
  const filter = useSelector(ProgramSummarySelectors.filter)
  const page = usePageQuery()
  const fetchAction = useAction(ProgramSummaryActions.listRequested, page ? { filter, page } : { filter })
  const progress = useDidMountWithProgress(fetchAction, actualProgress)
  return [progress, list]
}

export const useProgramSummaryPrograms: (id: string) => [Progress, Array<ProgramDTO>] = (id: string) => {
  const actualProgress = useSelector(ProgramSummarySelectors.programsFetchProgress)
  const list = useSelector(ProgramSummarySelectors.programs)
  const fetchAction = useAction(ProgramSummaryActions.programRequested, id)
  const progress = useDidMountWithProgress(fetchAction, actualProgress)
  return [progress, list]
}

export const useProgramSummaryListItem = createItemByIdHook(
  ProgramSummarySelectors,
  ProgramSummaryActions.itemRequested,
)
