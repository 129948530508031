import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { ProductOptions } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.productOptions.items
const ids = (state: AppGlobalState) => state.productOptions.ids

const allDict = (state: AppGlobalState) => state.productOptions.allItems
const allIds = (state: AppGlobalState) => state.productOptions.allIds

const item = (state: AppGlobalState, id?: string): ProductOptions | undefined =>
  id ? state.productOptions.items[id] : undefined
const meta = (state: AppGlobalState, id?: string): EntityMetadata<ProductOptions> =>
  id && state.productOptions.meta[id]
    ? state.productOptions.meta[id]
    : {
        fetchError: null,
        fetchProgress: Progress.IDLE,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }

const itemFetchProgress = (state: AppGlobalState) => state.productOptions.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.productOptions.listFetchProgress

const listAllFetchProgress = (state: AppGlobalState) => state.productOptions.listAllFetchProgress
const listAllFetchError = (state: AppGlobalState) => state.productOptions.listAllFetchError

const listFetchError = (state: AppGlobalState) => state.productOptions.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.productOptions.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.productOptions.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: ProductOptions[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const listAll = createSelector(allDict, allIds, listAllFetchProgress, (allDict, allIds, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: ProductOptions[] = []
  for (const id of allIds) {
    result.push(allDict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.productOptions.filter
const sorting = (state: AppGlobalState) => state.productOptions.sorting
const page = (state: AppGlobalState) => state.productOptions.page
const total = (state: AppGlobalState) => state.productOptions.total
const updateProgress = (state: AppGlobalState) => state.productOptions.updateProgress
const removeProgress = (state: AppGlobalState) => state.productOptions.removeProgress
const addProgress = (state: AppGlobalState) => state.productOptions.addProgress
const hasNext = (state: AppGlobalState) => state.productOptions.total > state.productOptions.ids.length
const pageSize = (state: AppGlobalState) => state.productOptions.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.productOptions.total / state.productOptions.pageSize)

const ProductOptionsSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  listAll,
  listAllFetchProgress,
  listAllFetchError,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default ProductOptionsSelectors
