import { FormText } from '@agro-club/frontend-shared'
import React from 'react'

interface Props {
  label: string
  value: string
}

const FormTextWithDefault = ({ value, label }: Props) => {
  return <FormText label={label}>{value ?? '-'}</FormText>
}

export default FormTextWithDefault
