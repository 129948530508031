import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/User/UserDetailsForm/types'
import { CompanyType, Permissions } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { useMapRoleToPermission } from 'views/pages/User/UserDetailsForm/hooks'
import * as Styled from 'views/pages/User/UserDetailsForm/styled'
import { Segment } from '@agro-club/frontend-shared'

const PermissionSegment: React.FC = () => {
  const formik = useFormikContext<FormFields>()
  const [permissionsList, setPermissionsList] = useState<{ id: Permissions; title: string }[]>([])
  const prevCompanyTypeRef = useRef<CompanyType>()
  const { t } = useTranslation('user')
  const mapRoleToPermissions = useMapRoleToPermission()

  const handleRelatedCompaniesChange = useCallback(
    (permission: Permissions) => {
      if (formik.values.companyType === CompanyType.Producer && permission === Permissions.HEAD) {
        formik.setFieldValue('relatedCompanies', [])
      }
    },
    [formik],
  )

  useEffect(() => {
    if (prevCompanyTypeRef.current !== formik.values.companyType) {
      const list = mapRoleToPermissions[formik.values.companyType as CompanyType]
      if (list.length && -1 === list.findIndex((item: { id: Permissions }) => item.id === formik.values.permission)) {
        formik.setFieldValue('permission', list[0].id)
      } else if (!list.length) {
        formik.setFieldValue('permission', null)
      }
      setPermissionsList(list)
      prevCompanyTypeRef.current = formik.values.companyType

      handleRelatedCompaniesChange(formik.values.permission as Permissions)
    }
  }, [formik, formik.values.companyType, formik.values.permission, mapRoleToPermissions, handleRelatedCompaniesChange])

  if (!formik.values.permission) {
    return null
  }

  return (
    <Styled.PermissionsWrapper>
      <Segment
        label={t('form.permissions')}
        items={permissionsList}
        value={formik.values.permission}
        onChange={(id: Permissions) => {
          formik.setFieldValue('permission', id)
          handleRelatedCompaniesChange(id)
        }}
      />
    </Styled.PermissionsWrapper>
  )
}

export default PermissionSegment
