import React, { FC, ReactNode } from 'react'
import { Modal } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'

type ConfirmPopupPropsType = {
  handleCloseModal: () => void
  isOpen: boolean
  wrapperText?: string
  children: ReactNode
}

export const ConfirmPopupModal: FC<ConfirmPopupPropsType> = ({
  handleCloseModal,
  isOpen,
  children,
  wrapperText,
}: ConfirmPopupPropsType) => {
  const { t } = useTranslation('returnDeclaration')

  return (
    <Modal heading={t('modal.title')} isOpen={isOpen} onClose={handleCloseModal} size={'large'}>
      <Styled.ConfirmPopupMessageWrapper>{wrapperText}</Styled.ConfirmPopupMessageWrapper>
      <Styled.ModalButtonWrapper>{children}</Styled.ModalButtonWrapper>
    </Modal>
  )
}
