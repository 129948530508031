import { all, call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import DocumentActions from './duck'
import DocumentSelectors from './selectors'
import { DocumentItem } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import DocumentRoutes from 'views/pages/Document/routes'
import { EntityMetadata } from 'modules/domain/types'
import { Progress } from 'modules/types'
import FarmerActions from 'modules/domain/farmer/duck'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import UploadManagerActions from 'modules/domain/uploadManager/duck'
import { endpoints } from 'modules/endpoints'
import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'
import { CancelToken } from 'modules/domain/uploadManager/constants'
import { helpersDownload } from '@agro-club/frontend-shared'
import SnackbarActions from '../snackbar/duck'
import i18n from 'i18n'

export const fetchList = function*() {
  try {
    let currentPage = yield select(DocumentSelectors.page)
    const filter = yield select(DocumentSelectors.filter)
    const sorting = yield select(DocumentSelectors.sorting)
    const pageSize = yield select(DocumentSelectors.pageSize)

    let response: ListResponse<DocumentItem> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(DocumentActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, DocumentRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(DocumentSelectors.page)
    const filter = yield select(DocumentSelectors.filter)
    const sorting = yield select(DocumentSelectors.sorting)
    const pageSize = yield select(DocumentSelectors.pageSize)
    const { data, total_count }: { data: DocumentItem[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DocumentActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof DocumentActions.itemRequested>) {
  try {
    const item: DocumentItem = yield call(managers.getItem, id)
    yield put(DocumentActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.itemRequestFailed(id, type, detail))
  }
}

export const fetchItemFileUrl = function*({ payload: id }: ReturnType<typeof DocumentActions.itemFileUrlRequested>) {
  try {
    const res: string = yield call(managers.getItemUrl, id)
    yield put(DocumentActions.itemFileUrlRequestSucceed(id, res))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.itemFileUrlRequestFailed(id, type, detail))
  }
}

export const fetchSignedFileUrl = function*({
  payload: [documentId, userId],
}: ReturnType<typeof DocumentActions.signedFileRequested>) {
  try {
    const res: string = yield call(managers.getSignedDocumentUrl, documentId, userId)
    yield put(DocumentActions.signedFileRequestSucceed(documentId, userId, res))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.signedFileRequestFailed(documentId, userId, type, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof DocumentActions.addRequested>) {
  try {
    const item: DocumentItem = yield call(managers.addItem, dto)
    yield put(DocumentActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DocumentActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof DocumentActions.updateRequested>) {
  try {
    const item: DocumentItem = yield call(managers.updateItem, id, dto)
    yield put(DocumentActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DocumentActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof DocumentActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(DocumentActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.removeFailed(payload, type, detail))
  }
}

export const getDocumentUrl = function*({
  documentId,
  fileName,
  userId,
}: {
  documentId: string
  fileName?: string
  userId?: string
}) {
  if (userId) {
    let userDocuments: DocumentItem[] | undefined = yield select(state =>
      DocumentSelectors.userDocuments(state, userId),
    )
    if (!userDocuments) {
      yield put(DocumentActions.userDocumentsRequested(userId))
      const { error } = yield race({
        error: take(DocumentActions.userDocumentsRequestFailed.type),
        success: take(DocumentActions.userDocumentsRequestSucceed.type),
      })
      if (error) {
        return put(DocumentActions.documentDownloadFailed({ userId, documentId }, error[1]))
      }
      userDocuments = yield select(state => DocumentSelectors.userDocuments(state, userId))
    }

    if (!userDocuments?.find(item => item.id === documentId && item.status === 'signed')) {
      const url = yield call(managers.getItemUrl, documentId)
      return [url, fileName]
    }

    let user = yield select(state => FarmerSelectors.farmer(state, userId))
    if (!user) {
      yield put(FarmerActions.itemRequested(userId))
      const { error } = yield race({
        error: take(FarmerActions.itemRequestFailed.type),
        success: take(FarmerActions.itemRequestSucceed.type),
      })
      if (error) {
        return put(DocumentActions.documentDownloadFailed({ userId, documentId }, error[1]))
      }
      user = yield select(state => FarmerSelectors.farmer(state, userId))
    }
    fileName = [fileName, user.first_name, user.last_name, user.farm_name].filter(Boolean).join(' ')
    const url = yield call(managers.getSignedDocumentUrl, documentId, userId)
    return [url, fileName]
  }

  const url = yield call(managers.getItemUrl, documentId)
  return [url, fileName]
}

export const downloadDocument = function*({
  payload: options,
}: ReturnType<typeof DocumentActions.documentDownloadRequested>) {
  try {
    const [url, fileName] = yield getDocumentUrl(options)
    helpersDownload.downloadUrl(url, { type: 'application/pdf' }, fileName)
    yield put(DocumentActions.documentDownloadSucceed(options))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    const { type } = RequestError.parseError(err)
    yield put(DocumentActions.documentDownloadFailed(options, type))
  }
}

export const printDocument = function*({
  payload: options,
}: ReturnType<typeof DocumentActions.documentPrintRequested>) {
  try {
    const [url] = yield getDocumentUrl(options)
    window.open(url)
    yield put(DocumentActions.documentPrintSucceed(options))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(DocumentActions.documentPrintFailed(options, type))
  }
}

export const fetchUserDocuments = function*({
  payload: userId,
}: ReturnType<typeof DocumentActions.userDocumentsRequested>) {
  try {
    const meta: EntityMetadata<DocumentItem[]> = yield select(state =>
      DocumentSelectors.userDocumentsListMeta(state, userId),
    )
    if (meta.fetchProgress === Progress.WORK) {
      return
    }
    yield put(DocumentActions.userDocumentsRequestAccepted(userId))

    const res: ListResponse<DocumentItem> = yield call(managers.userDocuments, userId)
    yield put(DocumentActions.userDocumentsRequestSucceed(userId, res.data))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DocumentActions.userDocumentsRequestFailed(userId, type, detail))
  }
}

export const sendSignRequestToUserEmail = function*({
  payload: [userId, documentId],
}: ReturnType<typeof DocumentActions.signRequestToUserEmailRequested>) {
  try {
    yield call(managers.sendSignRequestToUserEmail, userId, documentId)
    yield put(DocumentActions.signRequestToUserEmailSucceed(userId, documentId))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(DocumentActions.signRequestToUserEmailFailed(userId, documentId, type))
  }
}

export const needSign = function*({
  payload: [userId, documentId],
}: ReturnType<typeof DocumentActions.needSignRequested>) {
  try {
    yield call(managers.needSignRequest, userId, documentId)
    yield put(DocumentActions.needSignSucceed(userId, documentId))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(
      SnackbarActions.open({
        content: i18n.t('errors.signRequestError'),
      }),
    )
    yield put(DocumentActions.needSignFailed(userId, documentId, type))
  }
}

export const uploadUserDocument = function*({
  payload: [userId, documentId, file],
}: ReturnType<typeof DocumentActions.uploadFarmerDocumentRequested>) {
  try {
    const id = `${userId}-${documentId}`
    yield put(UploadManagerActions.fileUploadStarted(id, file, endpoints.documentFileUpload(userId, documentId)))
    const response: DocumentItem | typeof CancelToken = yield call(waitUntilFileLoaded, id)
    // TODO handle upload cancellation
    if (response === CancelToken) {
      return
    }

    yield put(DocumentActions.uploadFarmerDocumentSucceed(userId, documentId, response))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(DocumentActions.uploadFarmerDocumentFailed(userId, documentId, type))
  }
}

const DocumentSaga = function*() {
  yield all([
    takeEvery(DocumentActions.itemRequested.type, fetchItem),
    takeEvery(DocumentActions.itemFileUrlRequested.type, fetchItemFileUrl),
    takeEvery(DocumentActions.signedFileRequested.type, fetchSignedFileUrl),
    takeEvery(DocumentActions.documentDownloadRequested.type, downloadDocument),
    takeEvery(DocumentActions.documentPrintRequested.type, printDocument),
    takeEvery(DocumentActions.signRequestToUserEmailRequested.type, sendSignRequestToUserEmail),
    takeEvery(DocumentActions.needSignRequested.type, needSign),

    takeEvery(DocumentActions.userDocumentsRequested.type, fetchUserDocuments),
    takeEvery(DocumentActions.uploadFarmerDocumentRequested.type, uploadUserDocument),

    takeLatest(DocumentActions.listRequested.type, fetchList),
    takeLatest(DocumentActions.filterUpdated.type, fetchList),
    takeLatest(DocumentActions.sortingUpdated.type, fetchList),
    takeLatest(DocumentActions.filterHasBeenReset.type, fetchList),
    takeLatest(DocumentActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DocumentActions.listRequestedNext.type, fetchListNext),

    takeLatest(DocumentActions.addRequested.type, addItem),
    takeLatest(DocumentActions.updateRequested.type, updateItem),
    takeLatest(DocumentActions.removeRequested.type, removeItem),
  ])
}

export default DocumentSaga
