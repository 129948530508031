import { AxiosError } from 'axios'
import HttpStatuses from 'http-status-codes'
import {
  BadRequestError,
  UnauthorizedError,
  NotFoundError,
  ValidationError,
  ConflictError,
  ForbiddenError,
} from '../errors'

export const handleApiErrors = (error: AxiosError) => {
  const status = error.response?.status

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.__CANCEL__) {
    return
  }

  switch (status) {
    case HttpStatuses.NOT_FOUND:
      throw new NotFoundError(error)

    case HttpStatuses.BAD_REQUEST:
      throw new BadRequestError(error)

    case HttpStatuses.UNAUTHORIZED:
      throw new UnauthorizedError(error)

    case HttpStatuses.UNPROCESSABLE_ENTITY:
      throw new ValidationError(error)

    case HttpStatuses.FORBIDDEN:
      throw new ForbiddenError(error)

    case HttpStatuses.CONFLICT:
      throw new ConflictError(error)

    default:
      throw new Error('Server error.')
  }
}
