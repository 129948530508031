import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Product, ProductListRequestSorting, ProductListRequestFilter, ProductDTO, ProductListByIdsDTO } from './types'
import { ListResponse } from 'types/api'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const getList = (
  filter: ProductListRequestFilter,
  sorting: ProductListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Product>>(endpoints.product(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getStorefrontProductList = (
  filter: ProductListRequestFilter,
  sorting: ProductListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Product>>(endpoints.storefrontProducts(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getListByInventory = (
  filter: ProductListRequestFilter,
  sorting: ProductListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Product>>(endpoints.productsByInventory(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getListForFilter = (scope?: string) => (
  filter: ProductListRequestFilter,
  sorting: ProductListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Product>>(endpoints.filterSuggestProduct(scope || 'orders'), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<Product>(endpoints.product(id))
}

export const addItem = (dto: ProductDTO) => {
  return apiClient.post<Product>(endpoints.product(), dto)
}

export const updateItem = (id: string, dto: Partial<ProductDTO>) => {
  return apiClient.put<Product>(endpoints.product(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<Product>(endpoints.product(id))
}

export const getListOptions = (
  debouncedSearch: any,
  filter: ProductListRequestFilter,
  pageSize: number,
  availableByInventory?: boolean,
) => {
  const endpoint = availableByInventory ? endpoints.productsByInventory() : endpoints.product()
  return apiClient.get<ListResponse<Product>>(endpoint, {
    ...debouncedSearch,
    ...filter,
    page_size: pageSize,
  })
}

export const getListConditional = (
  debouncedSearch: any,
  filter: ProductListRequestFilter,
  pageSize: number,
  condition: boolean,
) => {
  return condition
    ? apiClient.get<ListResponse<Product>>(endpoints.product(), {
        ...debouncedSearch,
        ...filter,
        page_size: pageSize,
      })
    : Promise.resolve({ data: [], total_count: 0, page: 1 })
}

export const getListByIds = (dto: ProductListByIdsDTO) => {
  return apiClient.get<ListResponse<Product>>(endpoints.product(), dto)
}

export const getItemsByIds = createItemsByIds(true, (ids: string[]) =>
  apiClient.get<ListResponse<Product>>(endpoints.product(), {
    product_id: ids,
  }),
)
