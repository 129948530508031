import React, { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { FormikContext, useFormik } from 'formik'
import { useSelector } from 'react-redux'
import {
  Button,
  Input,
  Switch,
  SectionBody,
  SectionContainer,
  FormComponents,
  TextArea,
} from '@agro-club/frontend-shared'
import * as Styled from 'views/pages/User/UserDetailsForm/styled'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { CompanyType, isAgro, Permissions, ROLES, Status } from 'types/entities'
import { getPermissionLevel, getRoleName } from 'modules/domain/user/helpers'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { FormFields } from 'views/pages/User/UserDetailsForm/types'
import UserTypeSection from 'views/pages/User/UserDetailsForm/UserTypeSelection'
import PasswordBlock from 'views/pages/User/UserDetailsForm/PasswordBlock'
import TerritoryForm from './TerritoryForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { DEFAULT_COUNTRY } from 'modules/constants'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import AuthSelectors from 'modules/domain/auth/selectors'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import { TimezoneSelect } from 'views/components/TimezoneSelect/TimezoneSelect'

export type FormData = Omit<FormFields, 'permission'> & { role: ROLES }

type Props = {
  mode: 'edit' | 'create'
  initialValues?: FormData
  onResetPassword?(): void
  onSubmit(values: FormData): void
  onCancel(): void
  progress?: Progress
  error?: string | null
  isEditableUserRoleAdmin?: boolean
}

const UserDetailsForm: React.FC<Props> = ({
  mode,
  onResetPassword,
  onSubmit,
  progress,
  error,
  onCancel,
  isEditableUserRoleAdmin,
  initialValues: {
    status = Status.Inactive,
    role = ROLES.AGRO_ADMIN,
    firstName = '',
    lastName = '',
    middleName = '',
    email = '',
    canterraId = '',
    phone = '',
    password = '',
    regions = [],
    regions_ids = [],
    countryCode = DEFAULT_COUNTRY,
    company,
    distributor,
    relatedCompanies,
    farmName,
    selfPickup,
    partnership,
    legal_address,
    delivery_address,
    territories = [],
    comment = '',
    timezone,
  } = {},
}) => {
  const { t } = useTranslation(['user', 'common', 'validation'])

  const schema = useMemo(() => {
    let password
    switch (mode) {
      case 'create':
        password = Yup.string()
          .min(6, t('validation:password_length_warning'))
          .required(t('validation:field_required'))
        break
      case 'edit':
        password = Yup.string().min(6, t('validation:password_length_warning'))
        break
    }

    return Yup.object({
      status: Yup.mixed().oneOf([Status.Active, Status.Inactive, Status.Deleted]),
      companyType: Yup.mixed().oneOf([
        CompanyType.Agro,
        CompanyType.Producer,
        CompanyType.Distributor,
        CompanyType.User,
      ]),
      permission: Yup.mixed().oneOf([Permissions.ADMIN, Permissions.MANAGER, Permissions.HEAD, null]),
      firstName: Yup.string().required(t('validation:field_required')),
      lastName: Yup.string().required(t('validation:field_required')),
      middleName: Yup.string(),
      email: Yup.string()
        .required(t('validation:field_required'))
        .email(t('validation:email_invalid')),
      phone: Yup.string()
        .required(t('validation:field_required'))
        .test('is-valid-number', t('validation:invalid_phone_number'), value => isValidPhoneNumber(value || '')),
      countryCode: Yup.string().required(t('validation:field_required')),
      password,
    })
  }, [mode, t])

  const handleSubmit = (formValues: FormFields) => {
    if (!formik.isValid) {
      return
    }
    const {
      territories,
      companyType,
      permission,
      regions,
      distributor,
      relatedCompanies,
      company,
      ...common
    } = formValues
    const result: FormData = {
      ...common,
      companyType,
      // TODO fix this check
      role: companyType === CompanyType.User ? ROLES.USER : (`${companyType}:${permission}` as ROLES),
      territories: [],
      regions_ids,
    }
    switch (formValues.companyType) {
      case CompanyType.Producer:
        result.regions = regions
        result.company = company
        result.relatedCompanies = relatedCompanies
        break
      case CompanyType.Distributor: {
        result.regions = regions
        result.distributor = distributor
        result.relatedCompanies = relatedCompanies
        break
      }
      case CompanyType.User: {
        result.company = null
        result.distributor = null
        result.regions = []
        result.delivery_address = formValues.delivery_address
          ? {
              ...formValues.delivery_address,
              country: 'CA',
            }
          : null
        result.legal_address = formValues.legal_address
          ? {
              ...formValues.legal_address,
              country: 'CA',
            }
          : null
      }
    }

    if (result.role === ROLES.PRODUCER_MANAGER) {
      result.territories = territories
    }

    onSubmit(result)
  }

  const formik = useFormik<FormFields>({
    initialValues: {
      status,
      firstName,
      lastName,
      middleName,
      canterraId,
      email,
      phone,
      password,
      regions,
      countryCode,
      company,
      distributor,
      relatedCompanies,
      companyType: getRoleName(role) || CompanyType.Agro,
      permission: getPermissionLevel(role),
      farmName,
      selfPickup,
      legal_address,
      delivery_address,
      partnership,
      territories,
      comment,
      timezone,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (progress === Progress.ERROR) {
      if (error === 'email_exists') {
        formik.setFieldError('email', t('user:form.errors.email_exists'))
      } else if (error === 'phone_exists') {
        formik.setFieldError('phone', t('user:form.errors.phone_exists'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, error])

  useValidationErrorNotification(formik.submitCount, formik.isValid)
  const authorizedUserRole = useSelector(AuthSelectors.role)
  const isAdminOrManager = isAgro(authorizedUserRole)

  return (
    <FormikContext.Provider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <SectionContainer>
            <SectionBody>
              <UserTypeSection />
              <FormComponents.FormSection title={t('form.personaData')}>
                <Styled.PersonalDataWrapper>
                  <Styled.CountryArea>
                    <CountrySelect
                      value={formik.values.countryCode}
                      onChange={countryCode => formik.setFieldValue('countryCode', countryCode)}
                      label={t('form.country')}
                      invalid={formik.touched.countryCode && !!formik.errors.countryCode}
                      errorText={formik.errors.countryCode}
                      required
                    />
                  </Styled.CountryArea>
                  <Styled.NameArea>
                    <Input
                      label={t('common:firstName')}
                      {...formik.getFieldProps('firstName')}
                      invalid={formik.touched.firstName && !!formik.errors.firstName}
                      errorText={formik.errors.firstName}
                      required
                    />
                  </Styled.NameArea>
                  <Styled.LastNameArea>
                    <Input
                      label={t('common:lastName')}
                      {...formik.getFieldProps('lastName')}
                      invalid={formik.touched.lastName && !!formik.errors.lastName}
                      errorText={formik.errors.lastName}
                      required
                    />
                  </Styled.LastNameArea>
                  <Styled.MiddleNameArea>
                    <Input
                      label={t('common:secondName')}
                      {...formik.getFieldProps('middleName')}
                      invalid={formik.touched.middleName && !!formik.errors.middleName}
                    />
                  </Styled.MiddleNameArea>
                  <Styled.PhoneNumberArea>
                    <PhoneInput
                      label={t('common:phone')}
                      name={'phone'}
                      invalid={formik.touched.phone && !!formik.errors.phone}
                      errorText={formik.errors.phone}
                      phoneNumber={formik.values.phone}
                      onChange={phone => {
                        formik.setFieldValue('phone', phone)
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('phone', true)
                      }}
                      required
                    />
                  </Styled.PhoneNumberArea>
                  <Styled.EmailArea>
                    <Input
                      label={t('common:email')}
                      {...formik.getFieldProps('email')}
                      invalid={formik.touched.email && !!formik.errors.email}
                      errorText={formik.errors.email}
                      required
                    />
                  </Styled.EmailArea>
                  <Styled.CanterraIdArea>
                    <Input
                      label={t('form.canterraId')}
                      {...formik.getFieldProps('canterraId')}
                      invalid={formik.touched.canterraId && !!formik.errors.canterraId}
                      errorText={formik.errors.canterraId}
                    />
                  </Styled.CanterraIdArea>
                  <Styled.TimezoneArea>
                    <TimezoneSelect
                      label={t('form.labels.timezone')}
                      placeholder={''}
                      value={formik.values.timezone || ''}
                      onChange={newValue => formik.setFieldValue('timezone', newValue)}
                    />
                  </Styled.TimezoneArea>
                  <PasswordBlock
                    onResetPassword={onResetPassword}
                    required
                    isEditableUserRoleAdmin={isEditableUserRoleAdmin}
                  />
                </Styled.PersonalDataWrapper>
                {formik.values.permission === Permissions.MANAGER &&
                  formik.values.companyType === CompanyType.Producer &&
                  formik.values.company && (
                    <FormComponents.FormSection title={t('form.territory')}>
                      <TerritoryForm />
                    </FormComponents.FormSection>
                  )}
                {isAdminOrManager && (
                  <FormComponents.FormSection title={t('form.comment')}>
                    <Styled.CommentBlock>
                      <TextArea {...formik.getFieldProps('comment')} limit={1000} />
                    </Styled.CommentBlock>
                  </FormComponents.FormSection>
                )}
                <Switch
                  on={formik.getFieldProps('status').value === Status.Active}
                  onClick={value => {
                    formik.setFieldValue('status', !!value ? Status.Active : Status.Inactive)
                  }}
                  label={t('form.status')}
                  testId={'is-active-switch'}
                />
              </FormComponents.FormSection>
            </SectionBody>
          </SectionContainer>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            filled={true}
            progress={progress}
            disabled={!formik.dirty || progress === Progress.WORK}
            intent={'primary'}
            onClick={formik.submitForm}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikContext.Provider>
  )
}

export default UserDetailsForm
