import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { MailingListEntry, MailingListListRequestSorting, MailingListListRequestFilter, MailingListDTO } from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: MailingListListRequestFilter,
  sorting: MailingListListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<MailingListEntry>>(endpoints.mailingList(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<MailingListEntry>(endpoints.mailingList(id))
}

export const addItem = (dto: MailingListDTO) => {
  return apiClient.post<MailingListEntry>(endpoints.mailingList(), dto)
}

export const updateItem = (id: string, dto: Partial<MailingListDTO>) => {
  return apiClient.put<MailingListEntry>(endpoints.mailingList(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<MailingListEntry>(endpoints.mailingList(id))
}
