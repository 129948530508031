import React, { useState } from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'
import TerritoryRoutes from 'views/pages/Territory/routes'
import TerritoryList from 'views/pages/Territory/TerritoryList'
import TerritoryAdd from 'views/pages/Territory/TerritoryAdd'
import TerritoryEdit from 'views/pages/Territory/TerritoryEdit'
import { FormProps } from 'views/pages/Territory/TerritoryDetailsForm/TerritoryDetailsForm'

const TerritoryPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormProps>()

  return (
    <Switch>
      <Route path={TerritoryRoutes.List} exact>
        <TerritoryList />
      </Route>
      <Route path={TerritoryRoutes.Item}>
        <TerritoryEdit onUpdateInitialState={setInitialState} />
      </Route>
      <Route path={TerritoryRoutes.Add}>
        <TerritoryAdd initialState={initialState} onUpdateInitialState={setInitialState} />
      </Route>
      <Redirect to={TerritoryRoutes.List} />
    </Switch>
  )
}

export default TerritoryPage
