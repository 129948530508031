import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import {
  StorefrontProduct,
  StorefrontProductListRequestFilter,
  StorefrontProductListRequestSorting,
  StorefrontProductDTO,
} from './types'

export default {
  ...createBasicManagers<
    StorefrontProductListRequestFilter,
    StorefrontProductListRequestSorting,
    StorefrontProduct,
    StorefrontProductDTO,
    StorefrontProductDTO
  >(endpoints.storefrontProducts),
}
