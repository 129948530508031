import { AvailableLanguages, LocalizedValue } from 'types/entities'
import React, { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikErrors } from 'formik'
import * as Styled from './styled'
import styled from 'styled-components'
import { remove } from 'ramda'
import { FormikHook, IconCrossCircle, Input } from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

const Grid2Col = styled(Styled.Grid2Col)<{ border: boolean }>`
  position: relative;
  border-radius: 10px;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-gap: 16px;
  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export type AttributesFormProps = {
  attributes: {
    title: LocalizedValue
    value: LocalizedValue
  }[]
}

const AttributesForm: React.FC<{
  testId?: string
  langList: AvailableLanguages[]
  useFormik: FormikHook
  attributes?: {
    title_i18n: LocalizedValue
    value_i18n: LocalizedValue
  }[]
}> = memo(({ useFormik, langList, attributes = [], testId }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const validationSchema = useMemo(() => {
    const validator = (lang: AvailableLanguages) =>
      langList.includes(lang) ? Yup.string().required(t('validation:field_required')) : Yup.string()
    const langValidatorObj = createLocalizedValue(lang => validator(lang))

    return Yup.object({
      attributes: Yup.array(
        Yup.object({
          title: Yup.object(langValidatorObj),
          value: Yup.object(langValidatorObj),
        }),
      ),
    })
  }, [langList, t])

  const initialValues = useMemo(() => {
    return attributes.map(attr => ({
      title: createLocalizedValue(lang => attr.title_i18n[lang] || ''),
      value: createLocalizedValue(lang => attr.value_i18n[lang] || ''),
    }))
  }, [attributes])

  const formik = useFormik<AttributesFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      attributes: initialValues,
    },
  })

  const getError = (
    err: FormikErrors<{ title: LocalizedValue; value: LocalizedValue }> | string | undefined,
    field: 'title' | 'value',
    lang: AvailableLanguages,
  ): string => {
    if (!err) {
      return ''
    }
    if (typeof err === 'string') {
      return err
    }
    return err[field]?.[lang] || ''
  }

  const handleAddClick = () => {
    formik.setFieldValue('attributes', [
      ...formik.values.attributes,
      {
        title: createLocalizedValue(() => ''),
        value: createLocalizedValue(() => ''),
      },
    ])
  }

  const handleRemoveClick = (idx: number) => {
    formik.setFieldValue('attributes', remove(idx, 1, formik.values.attributes))
  }

  return (
    <Styled.FormContainer data-test-id={testId}>
      {formik.values.attributes.map((_, idx) => {
        return (
          <Container key={idx}>
            <RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            {langList.map(lang => {
              const index = idx + 1
              const titleKey = `attributes[${idx}].title[${lang}]`
              const valueKey = `attributes[${idx}].value[${lang}]`
              return (
                <Grid2Col key={titleKey} border={true}>
                  <Input
                    {...formik.getFieldProps(titleKey)}
                    invalid={
                      formik.touched.attributes?.[idx]?.title?.[lang] &&
                      !!getError(formik.errors.attributes?.[idx], 'title', lang)
                    }
                    errorText={getError(formik.errors.attributes?.[idx], 'title', lang)}
                    label={t('form.attributeTitle', { lang, index })}
                    required
                  />
                  <Input
                    {...formik.getFieldProps(valueKey)}
                    invalid={
                      formik.touched.attributes?.[idx]?.value?.[lang] &&
                      !!getError(formik.errors.attributes?.[idx], 'value', lang)
                    }
                    errorText={getError(formik.errors.attributes?.[idx], 'value', lang)}
                    label={t('form.attributeValue', { lang, index })}
                    required
                  />
                </Grid2Col>
              )
            })}
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Container>
        )
      })}
      <Styled.AddButton data-test-id={'add-attribute'} onClick={handleAddClick} type={'button'}>
        {t('form.addMoreAttributes')}
      </Styled.AddButton>
    </Styled.FormContainer>
  )
})

AttributesForm.displayName = 'AttributesForm'

export default AttributesForm
