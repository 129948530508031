import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ProductsAvailabilityTable from './ProductsAvailabilityTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useTranslation } from 'react-i18next'
import ProductsAvailabilityRoutes from './routes'
import { AddButton } from '@agro-club/frontend-shared'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { SkuSelect } from 'views/components/SkuSelect/SkuSelect'
import { CompanyType, Status } from 'types/entities'
import { useAction } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'
import ProductsAvailabilitySelectors from 'modules/domain/productsAvailability/selectors'
import ProductsAvailabilityActions from 'modules/domain/productsAvailability/duck'
import { ProductsAvailabilityListRequestFilter } from 'modules/domain/productsAvailability/types'

const ProductsAvailabilityList = () => {
  const { t } = useTranslation('productsAvailability')
  const filter = useSelector(ProductsAvailabilitySelectors.filter)
  const filterUpdated = useAction(ProductsAvailabilityActions.filterUpdated)

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<ProductsAvailabilityListRequestFilter>) => {
      filterUpdated({ ...filter, ...newFilterValue })
    },
    [filterUpdated, filter],
  )

  const handleSellerFilterChange = useCallback(
    (id?: string) => handleFilterChange({ seller_ids: id, distributor_ids: undefined, sku_ids: undefined }),
    [handleFilterChange],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')}>
            <AddButton to={ProductsAvailabilityRoutes.Add} data-test-id="products-availability-add" />
          </Header.Title>
        </Header.Root>
        <TComponents.Filters>
          <TComponents.SelectWrapper>
            <CompanySelect
              label={t('form.labels.seller')}
              placeholder={t('form.placeholders.seller')}
              value={filter.seller_ids}
              companyType={CompanyType.Producer}
              filter={{ is_seller: true }}
              onChange={handleSellerFilterChange}
              isClearable
            />
          </TComponents.SelectWrapper>
          <TComponents.SelectWrapper>
            <CompanySelect
              label={t('form.labels.retailer')}
              placeholder={t('form.placeholders.retailer')}
              value={filter.distributor_ids}
              companyType={CompanyType.Distributor}
              filter={{ for_producer_id: filter.seller_ids }}
              onChange={id => handleFilterChange({ distributor_ids: id })}
              isClearable
            />
          </TComponents.SelectWrapper>
          <TComponents.SelectWrapper>
            <SkuSelect
              label={t('form.labels.sku')}
              status={Status.Active}
              value={filter.sku_ids}
              sellerId={filter.seller_ids}
              onChange={id => handleFilterChange({ sku_ids: id })}
              isClearable
            />
          </TComponents.SelectWrapper>
        </TComponents.Filters>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper noFilters>
          <ProductsAvailabilityTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ProductsAvailabilityList
