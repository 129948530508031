import {
  Progress,
  SectionTableBodyCell,
  SectionTableBodyRow,
  OptionalNumber,
  NumberInput,
  Input,
} from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { DocumentItem } from 'modules/domain/document/types'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { useCardSkus } from 'modules/domain/storefront/hooks'
import { AvailableFor, SkuOption, StorefrontItem, StorefrontSku } from 'modules/domain/storefront/types'
import { arrToDict } from 'modules/utils/helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import * as Styled from 'views/components/OrderProductItems/styles'
import { Status } from 'types/entities'
import { getStandardUnitsQty } from 'views/components/OrderSkuCardItems/helpers'
import { SkuOptionsMatcher } from 'views/components/OrderSkuCardItems/SkuOptionsMatcher'
import { ReturnDeclarationSkuItem } from 'modules/domain/returnDeclarationSku/types'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/ReturnDeclarationSku/ReturnDeclarationSkuDetailsForm/ReturnDeclarationSkuDetailsForm'

export type ReturnSkuCardRowProps = {
  item: ReturnDeclarationSkuItem
  rowIndex: number
  sellerId?: string
  documents?: DocumentItem[]
  onQtyInputChange: (qty: number) => void
  onPackageTypeChange: (value: string) => void
  onOptionsChange: (value: SkuOption[]) => void
  onSkuMatched: (value?: StorefrontSku) => void
  onRemove: () => void
  skuMatchingErrors: boolean[]
  showInactiveSkus: boolean
  showErrors?: boolean
  hasPrices?: boolean
  skus?: StorefrontItem[]
  onFetchSkuList?: (key: string, skus: StorefrontItem[]) => void
}

const noWrapStyle: React.CSSProperties = { whiteSpace: 'nowrap' }
const SkuOptionsMatcherWrapper = styled.div`
  margin-top: 16px;
`

export const ReturnSkuCardRow: React.FC<ReturnSkuCardRowProps> = ({
  item,
  rowIndex,
  sellerId,
  skuMatchingErrors,
  showInactiveSkus,
  showErrors,
  onQtyInputChange,
  onPackageTypeChange,
  onOptionsChange,
  onSkuMatched,
  onRemove,
  skus: skusFromProps = [],
  onFetchSkuList,
}) => {
  const { pick } = useLangPicker()
  const formik = useFormikContext<FormFields>()
  const [packageType, setPackageType] = useState(item.sku?.params.package_id)
  const itemQty = OptionalNumber(item.quantity)
  const itemMinQty = OptionalNumber(item.sku?.min_qty)
  const itemMaxQty = OptionalNumber(item.sku?.max_qty)
  const card = item.product_card
  const cardId = item.product_card_id
  const cardTitle = `${pick(card?.subcategory?.title_i18n)} / ${pick(card?.title_i18n)}`
  const skuFilter = useMemo(
    () => ({
      status: [Status.Active, Status.Inactive],
    }),
    [],
  )

  const [skuListProgress, skuList] = useCardSkus(!!skusFromProps.length, cardId, skuFilter)

  const skus = useMemo(() => {
    let data = skusFromProps.length ? skusFromProps : skuList
    if (!showInactiveSkus) {
      data = data.filter(sku => sku.status === Status.Active || sku.sku_id === item.sku?.sku_id)
    }

    return data
  }, [item.sku?.sku_id, showInactiveSkus, skuList, skusFromProps])

  useEffect(() => {
    if (skuListProgress === Progress.SUCCESS && skuList.length && !skusFromProps.length) {
      const key = `${sellerId}-${cardId}`
      const scopedSkuList = skuList.filter(sku => sku.available_for?.includes(AvailableFor.DistributorOrder))
      onFetchSkuList?.(key, scopedSkuList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId, skuList, skuListProgress, skusFromProps.length, cardId])

  const packageTypes = useMemo(() => {
    const result: PackageTypes[] = []
    skus.forEach(sku => {
      const founded = result.find(p => p?.id === sku.params.package_id)
      if (!founded) result.push((sku as StorefrontSku).params.package as PackageTypes)
    })

    return result
  }, [skus])

  const packageTypeDict = useMemo(() => arrToDict(packageTypes), [packageTypes])

  const handlePackageTypeChange = useCallback(
    value => {
      onPackageTypeChange(value)
      setPackageType(value)
      if (item.sku?.params.options?.length) {
        onOptionsChange([])
      }
    },
    [item.sku?.params.options?.length, onOptionsChange, onPackageTypeChange],
  )

  const getFormattedQty = useCallback(
    (qty: number) => {
      const packageTypeObj = packageType ? packageTypeDict[packageType] : undefined
      return getStandardUnitsQty(qty, item.sku?.product?.units, packageTypeObj)
    },
    [packageType, packageTypeDict, item.sku?.product?.units],
  )

  const lotMeta = formik.getFieldMeta(`sku_items[${rowIndex}].lot_number`)

  return (
    <SectionTableBodyRow data-test-id={`product-row-${rowIndex}`}>
      <SectionTableBodyCell>
        <Styled.CardTitle>
          {cardTitle}
          <SkuOptionsMatcherWrapper>
            <SkuOptionsMatcher
              availableSku={skus as StorefrontSku[]}
              availablePackageTypes={packageTypes}
              progress={skuListProgress}
              packageType={packageType}
              sku={item.sku}
              onSkuMatched={onSkuMatched}
              onPackageTypeChange={handlePackageTypeChange}
              optionsRequired={!skuMatchingErrors[rowIndex]}
              showErrors={showErrors}
            />
          </SkuOptionsMatcherWrapper>
        </Styled.CardTitle>
      </SectionTableBodyCell>
      <SectionTableBodyCell>
        <Styled.LotInputWrapper>
          <Input
            {...formik.getFieldProps(`sku_items[${rowIndex}].lot_number`)}
            invalid={!!formik.submitCount && !!lotMeta.error}
            errorText={lotMeta.error}
            data-test-id={'lot-number-input'}
            required
          />
        </Styled.LotInputWrapper>
      </SectionTableBodyCell>
      <SectionTableBodyCell style={noWrapStyle}>
        <Styled.OrderQtyCell>
          <NumberInput
            value={itemQty}
            min={itemMinQty}
            max={itemMaxQty}
            onChange={onQtyInputChange}
            size={'small'}
            testId={'qty-input'}
            disabled={!item.sku_id}
            showNothing={!item.sku_id}
            inputStep={0.1}
          />
        </Styled.OrderQtyCell>
        <Styled.FormattedQty>{!!item.sku_id && getFormattedQty(itemQty)}</Styled.FormattedQty>
      </SectionTableBodyCell>
      <SectionTableBodyCell>
        <span data-test-id={'remove-card'}>
          <Styled.RemoveIcon onClick={onRemove} />
        </span>
      </SectionTableBodyCell>
    </SectionTableBodyRow>
  )
}
