import { Button, useAction } from '@agro-club/frontend-shared'
import * as DistributorOrderManagers from 'modules/domain/distributorOrder/managers'
import { DistributorCreateOrderDTO } from 'modules/domain/distributorOrder/types'
import DistributorOrderSkuManagers from 'modules/domain/distributorOrderSku/managers'
import { DistributorOrderSkuDTO } from 'modules/domain/distributorOrderSku/types'
import ModalActions from 'modules/domain/modal/duck'
import { Progress } from 'modules/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DistributorOrderStatus } from 'types/distributorOrder'
import { FarmerOrder } from 'types/farmerOrder'
import { FarmerOrderSku } from 'types/farmerOrderSku'

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
`

const HintContainer = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
`

export type AddRetailerOrderSuggestionContentProps = {
  farmerOrder: FarmerOrder | FarmerOrderSku
}

const getComment = (
  farmerOrder: FarmerOrder | FarmerOrderSku,
): string => `Generated from farmer order ${farmerOrder.slug}.
Customer: ${farmerOrder.customer?.full_name}
`

const getDistributorOrder = (farmerOrder: FarmerOrder): DistributorCreateOrderDTO => ({
  producer_id: farmerOrder.producer_id,
  distributor_id: farmerOrder.distributor_id || '',
  season_id: farmerOrder.season_id || '',
  status: DistributorOrderStatus.New,
  items: farmerOrder.items,
  interaction: {
    confirmed_by_producer: false,
    canceled_by_role: null,
  },
  comment: getComment(farmerOrder),
  files: [],
})

const getDistributorOrderSku = (farmerOrder: FarmerOrderSku): DistributorOrderSkuDTO => ({
  distributor_id: farmerOrder.distributor_id || '',
  producer_id: farmerOrder.producer_id || '',
  sku_items: farmerOrder.sku_items,
  status: DistributorOrderStatus.New,
  season_id: farmerOrder.season_id || '',
  interaction: {
    confirmed_by_producer: false,
    canceled_by_role: null,
  },
  comment: getComment(farmerOrder),
  files: [],
})

const isSkuOrder = (farmerOrder: FarmerOrder | FarmerOrderSku): farmerOrder is FarmerOrderSku =>
  'sku_items' in farmerOrder && farmerOrder.sku_items.length > 0

export const AddRetailerOrderSuggestionContent = ({ farmerOrder }: AddRetailerOrderSuggestionContentProps) => {
  const { t } = useTranslation(['farmerOrder', 'common'])
  const close = useAction(ModalActions.close)

  const [progress, setProgress] = useState(Progress.IDLE)

  const handleAddRetailerOrder = async () => {
    setProgress(Progress.WORK)
    try {
      if (isSkuOrder(farmerOrder)) {
        await DistributorOrderSkuManagers.addItem(getDistributorOrderSku(farmerOrder))
      } else {
        await DistributorOrderManagers.addItem(getDistributorOrder(farmerOrder))
      }
      setProgress(Progress.SUCCESS)
      close()
    } catch (error) {
      setProgress(Progress.ERROR)
    }
  }

  return (
    <>
      {t('form.addRetailerOrderAfterRetailerConfirmation')}
      <HintContainer>
        <sup>*</sup>
        {t('form.addRetailerOrderAfterRetailerConfirmationHint')}
      </HintContainer>
      <ModalButtonWrapper>
        <Button
          intent="primary"
          data-test-id="add-retailer-order-suggestion-button"
          onClick={handleAddRetailerOrder}
          progress={progress}
        >
          {t('common:yes')}
        </Button>
        <Button intent="cancel" onClick={close}>
          {t('common:no')}
        </Button>
      </ModalButtonWrapper>
    </>
  )
}
