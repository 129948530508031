import React, { memo } from 'react'
import { FormText, FormComponents, SectionBody, SectionContainer, Progress } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { useFarmerOrdersTotalCount } from 'modules/domain/farmer/hooks'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

type FarmerOrdersInfoProps = {
  farmerId: string
}

const FarmerOrdersInfo: React.FC<FarmerOrdersInfoProps> = ({ farmerId }) => {
  const { t } = useTranslation('farmer')
  const [progress, ordersTotalCount] = useFarmerOrdersTotalCount(farmerId)

  return (
    <SectionContainer>
      <SectionBody>
        <FormComponents.FormSection title={t('farmerOrders.heading')}>
          {progress !== Progress.WORK ? (
            <FormText label="">
              {t('farmerOrders.totalCount')}: {ordersTotalCount ?? ordersTotalCount}
            </FormText>
          ) : (
            <SpinnerLayout />
          )}
        </FormComponents.FormSection>
      </SectionBody>
    </SectionContainer>
  )
}

export default memo(FarmerOrdersInfo)
