import { Input as InputComponent, useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useLangPicker from 'hooks/useLangPicker'
import AuthSelectors from 'modules/domain/auth/selectors'
import FccOrderSkuActions from 'modules/domain/fccOrderSku/duck'
import { useFccOrderSkuList } from 'modules/domain/fccOrderSku/hooks'
import FccOrderSkuSelectors from 'modules/domain/fccOrderSku/selectors'
import { FccOrderSkuItem } from 'modules/domain/fccOrderSku/types'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'
import styled from 'styled-components'
import { isUsersCompanyBasicDistributor } from 'types/entities'
import { DictWithKey } from 'types/generics'
import { CompoundedTable } from 'views/components/CompoundedTable/CompoundedTable'

const Input = styled(InputComponent)`
  min-width: 70px;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  & input {
    appearance: textfield; /* Firefox */
  }
`

const NoWrap = styled.div`
  white-space: nowrap;
`

const SeedTreatment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PriceCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row: { original }, cell }) => {
  const isPermitted = usePermissions({ capability: CAPABILITY.FCC_ORDERS, permission: PERMISSION.U })
  const [price, setPrice] = useState(cell.value ? parseFloat(cell.value).toFixed(2) : '0.00')
  const addToItemsForUpdate = useAction(FccOrderSkuActions.addToItemsForUpdate)

  const isInit = useRef(true)

  useEffect(() => {
    setPrice(cell.value ? parseFloat(cell.value).toFixed(2) : '0.00')
  }, [cell.value])

  useEffect(() => {
    if (!isInit.current && price !== cell.value) {
      addToItemsForUpdate(original.id, {
        price: price ? price : '0',
      })
    }
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price])

  return (
    <Input
      type="number"
      value={price}
      disabled={!isPermitted}
      onChange={val => {
        setPrice(val.target.value)
      }}
    />
  )
}

const CreditFacilityCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row: { original }, cell }) => {
  const isPermitted = usePermissions({ capability: CAPABILITY.FCC_ORDERS, permission: PERMISSION.U })
  const [facility, setFacility] = useState(cell.value || '')
  const { t } = useTranslation('fccOrderSku')
  const addToItemsForUpdate = useAction(FccOrderSkuActions.addToItemsForUpdate)

  const isInit = useRef(true)

  useEffect(() => {
    if (!isInit.current && facility !== cell.value) {
      addToItemsForUpdate(original.id, {
        credit_facility: facility,
      })
    }
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility])

  useEffect(() => {
    setFacility(cell.value || '')
  }, [cell.value])

  return (
    <Input
      value={facility}
      disabled={!isPermitted}
      onChange={(_, val) => setFacility(val)}
      placeholder={t('list.facilityPlaceholder')}
    />
  )
}

const CategoryCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row }) => {
  return <div>{row.original.sku?.product?.subcategory?.title}</div>
}

const ProductTitleCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row }) => {
  const { pick } = useLangPicker()
  return <div>{pick(row.original.sku?.product?.title_i18n)}</div>
}

const SeedTreatmentCell: React.FC<CellProps<FccOrderSkuItem>> = ({ cell }) => {
  const sku = cell.value
  const seedTreatment = sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
  if (!seedTreatment) return <div key={sku.id}>-</div>

  return (
    <SeedTreatment key={sku.id} title={seedTreatment?.title || '-'}>
      {seedTreatment?.title || '-'}
    </SeedTreatment>
  )
}

const DateCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{getFormatedDate(row.values.order_date)}</div>
    </div>
  )
}

const TotalCell: React.FC<CellProps<FccOrderSkuItem>> = ({ cell, row }) => {
  return (
    <span data-test-id={'totalCell'}>
      {getPrettyPrice(parseFloat(cell.value), row.original.sku?.product?.producer?.currency)}
    </span>
  )
}

const NullableCell: React.FC<CellProps<FccOrderSkuItem>> = ({ cell }) => {
  return cell.value ? cell.value : 0
}

const PackagingCell: React.FC<CellProps<FccOrderSkuItem>> = ({ row }) => {
  return <span>{row.original.sku?.params.package?.title || 'None'}</span>
}

const CustomerCell: React.FC<CellProps<FccOrderSkuItem>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }
  const name = `${cell.value.first_name || ''} ${cell.value.last_name || ''}`
  return (
    <NoWrap>
      <div>{name.trim()}</div>
      <div>{cell.value.farm_name}</div>
      <div>{cell.value.phone_number}</div>
    </NoWrap>
  )
}

const RetailerCell: React.FC<CellProps<FccOrderSkuItem>> = ({ cell, row, column }) => {
  return <span key={column.id}>{(cell.value && row.original.retailer?.internal_name) || ''}</span>
}

const FccOrderSkuTable = () => {
  const { t } = useTranslation(['fccOrderSku', 'labels', 'common'])
  const userCompany = useSelector(AuthSelectors.userCompany)
  const isBasicRetailer = isUsersCompanyBasicDistributor(userCompany)

  const [progress, data = []] = useFccOrderSkuList()

  const listRequested = useAction(FccOrderSkuActions.listRequested)

  const page = useSelector(FccOrderSkuSelectors.page)
  const pageSize = useSelector(FccOrderSkuSelectors.pageSize)
  const pages = useSelector(FccOrderSkuSelectors.pages)
  const total = useSelector(FccOrderSkuSelectors.total)
  const totalCost = useSelector(FccOrderSkuSelectors.totalCost)
  const totalQty = useSelector(FccOrderSkuSelectors.totalQty)
  const totalFinalQty = useSelector(FccOrderSkuSelectors.totalFinalQty)

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.date'),
        accessor: 'order_date' as const,
        Cell: DateCell,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'retailer' as const,
        Cell: RetailerCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.client'),
        accessor: 'farmer' as const,
        Cell: CustomerCell,
      },
      {
        Header: t('list.tableHeaders.category'),
        accessor: 'sku' as const,
        id: 'category',
        Cell: CategoryCell,
      },
      {
        Header: t('list.tableHeaders.package'),
        accessor: 'params' as const,
        id: 'packaging',
        Cell: PackagingCell,
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'sku' as const,
        id: 'product',
        Cell: ProductTitleCell,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'sku' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'quantity' as const,
        Cell: NullableCell,
      },
      {
        Header: t('list.tableHeaders.finalQty'),
        accessor: 'final_qty' as const,
        Cell: NullableCell,
      },
      {
        Header: t('list.tableHeaders.price'),
        accessor: 'price' as const,
        id: 'price',
        Cell: PriceCell,
      },
      {
        Header: t('list.tableHeaders.total'),
        accessor: 'total' as const,
        Cell: TotalCell,
      },
      {
        Header: t('list.tableHeaders.creditFacilityNo'),
        accessor: 'credit_facility' as const,
        Cell: CreditFacilityCell,
      },
    ],
    [t, isBasicRetailer],
  )

  const metaColumns: DictWithKey<React.ReactNode, keyof FccOrderSkuItem> = useMemo(
    () => ({
      quantity: totalQty,
      final_qty: totalFinalQty,
      total: totalCost,
    }),
    [totalQty, totalFinalQty, totalCost],
  )

  const fetchNextItems = num => {
    listRequested({ page: num })
  }

  const { scrollRef } = usePersistentScroll('farmerOrderList')

  return (
    <CompoundedTable
      data={data}
      total={total}
      pages={pages}
      pageSize={pageSize}
      currentPage={page}
      onSetPage={fetchNextItems}
      ref={scrollRef}
      columns={columns}
      progress={progress}
      metaColumns={metaColumns}
    />
  )
}

export default FccOrderSkuTable
