import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetSkuTypes } from 'modules/domain/target2sku/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuTerritory = {
  id: string
  title: string
}

type TerritoryFilter = {
  manufacturer_id?: string
  title?: string
}

type RetailerTerritoryFilter = {
  retailer_id?: string
  title?: string
}

const getRetailerFormList = createGetList<TargetSkuTerritory, RetailerTerritoryFilter>(
  endpoints.target2sku('territories-for-retailer'),
)

const getListForm = createGetList<TargetSkuTerritory, TerritoryFilter>(
  endpoints.target2sku('territories-for-manufacturer'),
)

const getListTerritoryFilter = createGetList<TargetSkuTerritory, TerritoryFilter>(
  endpoints.target2sku('territory/territories'),
)

const getListRetailerFilter = createGetList<TargetSkuTerritory, TerritoryFilter>(
  endpoints.target2sku('retailer/territories'),
)

const getListFarmerFilter = createGetList<TargetSkuTerritory, TerritoryFilter>(
  endpoints.target2sku('farmer/territories'),
)

const getListCropFilter = createGetList<TargetSkuTerritory, TerritoryFilter>(
  endpoints.target2sku('territory-crop/territories'),
)

type TargetSkusTerritorySelectProps = EntitySelectProps<TargetSkuTerritory> & {
  manufacturerId?: string
  retailerId?: string
  valueLabel?: string
  scope?: TargetSkuTypes | 'default' | 'retailer'
}
const scopeManagerMap = {
  [TargetSkuTypes.Farmer]: getListFarmerFilter,
  [TargetSkuTypes.Retailer]: getListRetailerFilter,
  [TargetSkuTypes.Territory]: getListTerritoryFilter,
  [TargetSkuTypes.Crop]: getListCropFilter,
  retailer: getRetailerFormList,
  default: getListForm,
}

const getTerritoryLabel = (item: TargetSkuTerritory) => item.title

const getTerritoryValue = (item: TargetSkuTerritory) => item.id

export const TargetSkuTerritorySelect: React.VFC<TargetSkusTerritorySelectProps> = ({
  manufacturerId,
  isDisabled,
  valueLabel,
  onChange,
  retailerId,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, retailer_id: retailerId }), [
    manufacturerId,
    retailerId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: valueLabel, id: 'fake' } : undefined), [valueLabel])

  const testId = 'target-territory-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getTerritoryLabel}
      getOptionValue={getTerritoryValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={scopeManagerMap[scope]}
      getOptionLabel={getTerritoryLabel}
      getOptionValue={getTerritoryValue}
      onChange={onChange}
      searchParamKey="title"
      data-test-id={testId}
      {...props}
    />
  )
}
