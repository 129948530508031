import { Table, TableBodyCell, TableBodyRow, TableNoData } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import React, { useMemo } from 'react'
import { Column, IdType, Row, useTable } from 'react-table'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompoundedTableHead, CompoundedTableHeadProps } from './CompoundedTableHead'
import { StyledTableHead } from './styles'

export type MetaColumn = {
  accessor: string
  component: React.ReactNode
}

export type CompoundedTableProps<DataItemType extends Object> = Omit<
  CompoundedTableHeadProps<DataItemType>,
  'columns'
> & {
  total: number
  pages: number
  pageSize: number
  currentPage: number
  onSetPage: (page: number) => void
  className?: string
  fixedLayout?: boolean
  //this is because accessor types is bad
  columns: Column<any>[]
  hiddenColumns?: IdType<DataItemType>[]
  data: DataItemType[]
  progress?: Progress
  onRowClick?: (row: Row<DataItemType>) => void
}

export const CompoundedTable = React.forwardRef(
  <DataItemType extends Object>(
    {
      columns: columnsFromProps,
      metaColumns,
      hiddenColumns,
      data,
      onRowClick,
      progress = Progress.IDLE,
      onSortUpdated,
      sortField,
      sortReversed,
      ...tableProps
    }: CompoundedTableProps<DataItemType>,
    ref,
  ) => {
    const { columns, rows, prepareRow } = useTable({
      columns: columnsFromProps,
      initialState: hiddenColumns ? { hiddenColumns } : undefined,
      data,
    })

    const rowComponent = useMemo(() => {
      return rows.map((row, idx) => {
        prepareRow(row)
        const { key, ...props } = row.getRowProps()
        return (
          <TableBodyRow key={key} {...props} onClick={() => onRowClick?.(row)} data-test-id={`table-row-${idx}`}>
            {row.cells.map(cell => {
              const { key, ...props } = cell.getCellProps()
              return (
                <TableBodyCell key={key} {...props} {...cell.column}>
                  {cell.render('Cell')}
                </TableBodyCell>
              )
            })}
          </TableBodyRow>
        )
      })
    }, [onRowClick, prepareRow, rows])

    return (
      <Table data-test-id="table" {...tableProps} ref={ref}>
        <CompoundedTableHead
          columns={columns}
          metaColumns={metaColumns}
          onSortUpdated={onSortUpdated}
          sortField={sortField}
          sortReversed={sortReversed}
        />
        <StyledTableHead hasMetaHead={!!metaColumns}>
          {rowComponent}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={columnsFromProps.length}
            loading={<TComponents.Spinner />}
          />
        </StyledTableHead>
      </Table>
    )
  },
) as <DataItemType extends Object>(
  props: CompoundedTableProps<DataItemType> & { ref?: React.Ref<HTMLElement> },
) => React.ReactElement
