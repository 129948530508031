import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import StorefrontCardTable from 'views/pages/Storefront/StorefrontCard/components/StorefrontCardTable'
import * as Styled from './styled'
import StorefrontCardActions from 'modules/domain/storefrontCard/duck'
import { useSelector } from 'react-redux'
import StorefrontCardSelectors from 'modules/domain/storefrontCard/selectors'
import { StorefrontHeader } from '../components/StorefrontHeader'

const StorefrontCardList: React.FC = () => {
  const { t } = useTranslation(['storefront', 'cards'])
  useHelmet({ title: t('cards:metaTitle') })

  const filterUpdated = useAction(StorefrontCardActions.filterUpdated)
  const filterValue = useSelector(StorefrontCardSelectors.filter)

  const onSearchChange = (val: string) => {
    filterUpdated({ ...filterValue, search: val })
  }

  return (
    <>
      <Layout.Header>
        <StorefrontHeader tab={'cards'} />
        <Styled.SearchInputWrapper>
          <SearchInput
            onChange={onSearchChange}
            value={filterValue.search}
            placeholder={t('cards:searchPlaceholder')}
            data-test-id="card-search"
          />
        </Styled.SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <StorefrontCardTable />
      </Layout.Content>
    </>
  )
}

export default StorefrontCardList
