import React, { useCallback, useState } from 'react'
import { FormikHook, Input, Label, Radio, RadioItem } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { LocalizedValue } from 'types/entities'
import * as Styled from './styled'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'

export type LegalDocsFormProps = {
  cookies_policy: FileItem[]
  privacy_policy: FileItem[]
  user_agreement: FileItem[]
}

enum LegalDocsSelectType {
  Document = 'document',
  Link = 'link',
}

const LegalDocItem: React.FC<{
  legal_doc: FileItem[] | string
  linkLabel: string
  fieldName: string
  handleFilesChange: (items: FileItem[], fieldName: string) => void
}> = ({ legal_doc = '', linkLabel, handleFilesChange, fieldName }) => {
  const { t } = useTranslation('license')
  const [legalDocsSelectType, setLegalDocsSelectType] = useState<LegalDocsSelectType>(
    legal_doc && typeof legal_doc === 'string' ? LegalDocsSelectType.Link : LegalDocsSelectType.Document,
  )

  const onRadioChange = (value: LegalDocsSelectType) => {
    setLegalDocsSelectType(value)
  }

  return (
    <Styled.LegalDocContainer>
      <Label>{linkLabel}</Label>
      <Styled.RadioButtonsContainer>
        <Radio value={legalDocsSelectType} onChange={onRadioChange} direction="column">
          <RadioItem value={LegalDocsSelectType.Document} label={<Label>{t('form.labels.document')}</Label>} />
          <RadioItem value={LegalDocsSelectType.Link} label={<Label>{t('form.labels.link')}</Label>} />
        </Radio>
      </Styled.RadioButtonsContainer>

      {legalDocsSelectType === LegalDocsSelectType.Document ? (
        <FileManager
          items={(legal_doc as FileItem[]) || []}
          onChange={value => handleFilesChange(value, fieldName)}
          limit={1}
        />
      ) : (
        <Styled.LegalDocInputContainer>
          <Input value={legal_doc as string} label={`${linkLabel} ${t('form.labels.link')}`} />
        </Styled.LegalDocInputContainer>
      )}
    </Styled.LegalDocContainer>
  )
}

const LegalDocsForm: React.FC<{
  lang: keyof LocalizedValue
  cookies_policy?: LocalizedValue<FileItem[]>
  privacy_policy?: LocalizedValue<FileItem[]>
  user_agreement?: LocalizedValue<FileItem[]>
  useFormik: FormikHook
}> = ({ lang, cookies_policy = {}, privacy_policy = {}, user_agreement = {}, useFormik }) => {
  const { t } = useTranslation('license')

  const formik = useFormik<LegalDocsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      cookies_policy: cookies_policy[lang] || [],
      privacy_policy: privacy_policy[lang] || [],
      user_agreement: user_agreement[lang] || [],
    },
    enableReinitialize: true,
  })

  const handleFilesChange = useCallback(
    (items: FileItem[], fieldName: string) => {
      formik.setFieldValue(fieldName, items)
    },
    [formik],
  )

  return (
    <Styled.VerticalContainer>
      <Label>{t(`common:langNames.${lang}`)}</Label>
      <Styled.HorizontalLegalDocsContainer>
        <LegalDocItem
          legal_doc={formik.values.cookies_policy}
          linkLabel={t('form.labels.cookiesPolicyLabel')}
          handleFilesChange={handleFilesChange}
          fieldName={'cookies_policy'}
        />
        <LegalDocItem
          legal_doc={formik.values.privacy_policy}
          linkLabel={t('form.labels.privacyPolicyLabel')}
          handleFilesChange={handleFilesChange}
          fieldName={'privacy_policy'}
        />
        <LegalDocItem
          legal_doc={formik.values.user_agreement}
          linkLabel={t('form.labels.userAgreementLabel')}
          handleFilesChange={handleFilesChange}
          fieldName={'user_agreement'}
        />
      </Styled.HorizontalLegalDocsContainer>
    </Styled.VerticalContainer>
  )
}

export default LegalDocsForm
