import useLangPicker from './useLangPicker'

const useCompareStrings = () => {
  const { pick } = useLangPicker()

  return (rowA: any, rowB: any, id: string) => {
    let a, b
    if (typeof rowA.values[id] === 'string') {
      a = (rowA.values[id] as string) || ''
      b = (rowB.values[id] as string) || ''
    } else if (typeof rowA.values[id] === 'object') {
      if (typeof rowA.values[id].title === 'string') {
        a = rowA.values[id].title || ''
        b = rowB.values[id].title || ''
      } else if (typeof rowA.values[id].title === 'object') {
        a = pick(rowA.values[id]?.title) || ''
        b = pick(rowB.values[id]?.title) || ''
      }
    }

    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}

export default useCompareStrings
