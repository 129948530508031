import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import AllocationFilters from '../Allocation/AllocationFilters'
import AllocationHeader from '../Allocation/AllocationHeader'
import AllocationRetailerTable from './AllocationRetailerTable'
import AllocationTerritoryTable from '../AllocationTerritory/AllocationTerritoryTable'
import styled from 'styled-components'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'

const Wrapper = styled(TComponents.Wrapper)`
  overflow: auto;
  grid-template-rows: max-content auto;
`

const AllocationList = ({ activeTab }: { activeTab: 'retailer' | 'territories' }) => {
  return (
    <>
      <Layout.Header>
        <AllocationHeader activeTab={activeTab} />
      </Layout.Header>
      <Layout.Content>
        <Wrapper>
          <AllocationFilters activeTab={activeTab} />
          {activeTab === 'retailer' ? <AllocationRetailerTable /> : <AllocationTerritoryTable />}
        </Wrapper>
      </Layout.Content>
    </>
  )
}

export default AllocationList
