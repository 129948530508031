import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InventoryExchangeRoutes from './routes'
import InventoryTotalTable from './InventoryExchangeTotal/InventoryTable'
import InventoryTransactionTable from './InventoryExchangeTransaction/InventoryTransactionTable'
import InventoryAdd from 'views/pages/InventoryExchange/InventoryExchangeTotal/InventoryAdd'
import InventoryTransactionEdit from './InventoryExchangeTransaction/InventoryTransactionEdit'
import TransferInRequestsTable from './InventoryTransferRequests/TransferInRequestsTable'
import TransferAllRequestsTable from './InventoryTransferRequests/TransferAllRequestsTable'
import TransferOutRequestsTable from './InventoryTransferRequests/TransferOutRequestsTable'
import InventoryTotalDetails from './InventoryExchangeTotal/InventoryTotalDetails'

const InventoryPage: React.FC = () => {
  return (
    <Switch>
      <Route path={InventoryExchangeRoutes.Total} exact>
        <InventoryTotalTable />
      </Route>
      <Route path={InventoryExchangeRoutes.TotalDetails} exact>
        <InventoryTotalDetails />
      </Route>
      <Route path={InventoryExchangeRoutes.TransactionsList} exact>
        <InventoryTransactionTable />
      </Route>
      <Route path={InventoryExchangeRoutes.Add} exact>
        <InventoryAdd />
      </Route>
      <Route path={InventoryExchangeRoutes.Edit} exact>
        <InventoryTransactionEdit />
      </Route>
      <Route path={InventoryExchangeRoutes.TransferAllRequests} exact>
        <TransferAllRequestsTable />
      </Route>
      <Route path={InventoryExchangeRoutes.InRequests} exact>
        <TransferInRequestsTable />
      </Route>
      <Route path={InventoryExchangeRoutes.OutRequests} exact>
        <TransferOutRequestsTable />
      </Route>
      <Redirect to={InventoryExchangeRoutes.Total} />
    </Switch>
  )
}

export default InventoryPage
