import { CheckboxSelect, CountrySelectProps, Flag } from '@agro-club/frontend-shared'
import { CountryCode } from 'libphonenumber-js'
import { COUNTRIES } from 'modules/constants'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const OptionStyled = styled.div`
  display: flex;
  align-items: center;
`

const FlagStyled = styled(Flag)`
  margin-right: 5px;
`

export type CountryMultiSelectProps = Omit<CountrySelectProps, 'value'> & { values?: CountryCode[] }

export const CountryMultiSelect: React.FC<CountryMultiSelectProps> = ({ values = [], list, ...props }) => {
  const { t } = useTranslation('common')
  const options = list || COUNTRIES
  const optionsMapped = useMemo(
    () =>
      options.map(country => ({
        id: country.id,
        title: (
          <OptionStyled>
            <FlagStyled code={country.id} />
            {country.title}
          </OptionStyled>
        ),
      })),
    [options],
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <CheckboxSelect {...props} noneSelected={t('none')} selected={values} options={optionsMapped} />
}
