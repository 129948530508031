import { Farmer } from 'modules/domain/farmer/types'
import React, { useCallback, useRef, useState } from 'react'
import { Progress } from 'modules/types'
import * as Styled from 'views/components/SearchFarmerModal/styled'
import { useCustomersOptions } from 'modules/domain/farmer/hooks'
import { useTranslation } from 'react-i18next'
import { Button, Modal, useKey } from '@agro-club/frontend-shared'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'

type SearchFarmerModalProps = {
  isOpen: boolean
  onClose: () => void
  onSelect: (id: string, farmer: Farmer) => void
  checkIfDisabled?: (farmer: Farmer) => false | string
}

type ResultFormProps = {
  search: string
  onBack: () => void
  onSelect: (id: string, farmer: Farmer) => void
  checkIfDisabled?: (farmer: Farmer) => false | string
}

type FarmerInfoProps = {
  farmer?: Farmer | null
  className?: string
  onClick: () => void
  disabled?: false | string
}

const FarmerInfo: React.FC<FarmerInfoProps> = ({ farmer, className, onClick, disabled }) => {
  const { t } = useTranslation('SearchFarmerModal')
  const { phone_number, email, farm_name, full_name } = farmer || {}
  const legalAddress = [
    farmer?.legal_address?.country,
    farmer?.legal_address?.city,
    farmer?.legal_address?.address,
    farmer?.legal_address?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const isItemDisabled = disabled || farmer?.household_id

  return (
    <Styled.FarmerItem className={className} onClick={isItemDisabled ? undefined : onClick} disabled={!!isItemDisabled}>
      {!!disabled && <Styled.AlreadySelected>{disabled}</Styled.AlreadySelected>}
      {!disabled && !!farmer?.household_name && (
        <Styled.AlreadySelected>{t('participantOf', { household: farmer.household_name })}</Styled.AlreadySelected>
      )}
      {!!full_name && <PreviewContainer.Header>{full_name}</PreviewContainer.Header>}
      {!!farm_name && <PreviewContainer.Subheader>{farm_name}</PreviewContainer.Subheader>}
      {!!phone_number && <PreviewContainer.Subheader>{phone_number}</PreviewContainer.Subheader>}
      {!!email && <PreviewContainer.Link>{email}</PreviewContainer.Link>}
      {!!legalAddress.length && (
        <PreviewContainer.Text style={{ marginTop: '16px', display: 'block' }}>{legalAddress}</PreviewContainer.Text>
      )}
    </Styled.FarmerItem>
  )
}

const SearchForm: React.FC<{ onSubmit: (val: string) => void }> = ({ onSubmit }) => {
  const { t } = useTranslation(['SearchFarmerModal', 'common'])
  const inputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState('')

  const handleSubmit = useCallback(() => {
    onSubmit(searchValue)
  }, [onSubmit, searchValue])

  const handleChange = useCallback(value => {
    setSearchValue(value)
  }, [])

  useKey(inputRef, 'keydown', () => searchValue && onSubmit(searchValue), 'Enter')

  return (
    <Styled.FormWrapper data-test-id={'modal-phone-form'}>
      <Styled.ModalHeading>{t('heading')}</Styled.ModalHeading>
      <Styled.SearchInput
        onChange={handleChange}
        value={searchValue}
        placeholder={t('placeholder')}
        inputRef={inputRef}
        debounceValue={0}
        autoFocus
      />
      <Button
        data-test-id={'next-button'}
        intent={'primary'}
        filled={true}
        onClick={handleSubmit}
        disabled={!searchValue}
      >
        {t('common:search')}
      </Button>
    </Styled.FormWrapper>
  )
}

const ResultForm: React.FC<ResultFormProps> = ({ search, onBack, onSelect, checkIfDisabled }) => {
  const [progress, farmers] = useCustomersOptions(search)
  const { t } = useTranslation(['SearchFarmerModal', 'common'])

  if ([Progress.IDLE, Progress.WORK].includes(progress)) {
    return (
      <Styled.FormWrapper>
        <Styled.Spinner />
      </Styled.FormWrapper>
    )
  }

  return (
    <Styled.FormWrapper data-test-id={'modal-farmer-search-form'}>
      <Styled.BackBtn onClick={onBack} />
      {progress === Progress.SUCCESS && farmers?.length ? (
        <Styled.FarmersList>
          {farmers.map(farmer => (
            <FarmerInfo
              key={farmer.id}
              farmer={farmer}
              onClick={() => onSelect(farmer.id, farmer)}
              disabled={checkIfDisabled && checkIfDisabled(farmer)}
            />
          ))}
        </Styled.FarmersList>
      ) : (
        <Styled.EmptySearchResult>{t('emptySearchResult')}</Styled.EmptySearchResult>
      )}
    </Styled.FormWrapper>
  )
}

const SearchFarmerModal: React.FC<SearchFarmerModalProps> = ({ isOpen, onClose, onSelect, checkIfDisabled }) => {
  const [step, setStep] = useState<'search' | 'result'>('search')
  const [search, setSearch] = useState('')

  const handleSearchSubmit = useCallback(search => {
    setSearch(search)
    setStep('result')
  }, [])

  const handleClose = useCallback(() => {
    setSearch('')
    setStep('search')
    onClose()
  }, [onClose])

  const handleSelect = useCallback(
    (id, farmer) => {
      onSelect(id, farmer)
      handleClose()
    },
    [handleClose, onSelect],
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={'responsive'}>
      {step === 'search' && <SearchForm onSubmit={handleSearchSubmit} />}
      {step === 'result' && (
        <ResultForm
          onSelect={handleSelect}
          onBack={() => setStep('search')}
          search={search}
          checkIfDisabled={checkIfDisabled}
        />
      )}
    </Modal>
  )
}

export default SearchFarmerModal
