import { all, call, delay, put, race, select, take } from 'redux-saga/effects'
import { getLocation, LocationChangeAction, LOCATION_CHANGE, replace } from 'connected-react-router'
import { generatePath, matchPath } from 'react-router'
import { routeToEvent, CollectedRoutes as Routes } from 'analytics/routeToEvent'
import AnalyticEvents from 'analytics/events'
import { getAnalyticsInstance } from './sagaEffects'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ROLES } from 'types/entities'
import { helpersUrl, helpersBrowser } from '@agro-club/frontend-shared'
import { waitUntilFileLoaded } from './domain/uploadManager/sagas'
import { FileData, FileItem } from 'views/components/FileManager/types'

type Param = string | number | undefined

export function* updateLocationQuery(nextLocation: string, query?: Record<string, Param | Param[]>) {
  const currentLocation = yield select(getLocation)
  const match = matchPath(currentLocation.pathname, { path: nextLocation, exact: true })
  if (match) {
    let result = helpersUrl.stringifyUrl({
      url: generatePath(nextLocation, match.params),
      query,
    })
    const oldResult = helpersUrl.stringifyUrl({ url: currentLocation.pathname, query: currentLocation.query })

    // todo remove it when storefront was ready; need for pagination + secret url params
    if (currentLocation.query.secret && !result?.includes(currentLocation.query.secret)) {
      result += `&secret=${currentLocation.query.secret}`
    }

    // prevent unnecessary push
    if (oldResult !== result) {
      yield put(replace(result))
    }
  }
}

export function* locationChangeWatcher() {
  const analyticsInstance = yield call(getAnalyticsInstance)
  const routes = Routes.map(r => r.split('/'))
    .sort((a, z) => z.filter(s => !!s).length - a.filter(s => !!s).length)
    .map(r => r.join('/'))

  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)
    const match = matchPath(action.payload.location.pathname, routes)

    const props: { [key: string]: unknown } = {
      params: match?.params,
      url: match?.url,
    }

    const eventName = routeToEvent(match?.path as never)

    if (eventName) {
      analyticsInstance.track(AnalyticEvents.Page, { ...props, name: eventName })
    }
  }
}

export function ticker(loopBodyGen: (opts: { role: ROLES }) => Generator<any, boolean, any>, timeoutMs: number) {
  return function* tickerLoop(): unknown {
    yield take(AuthActions.initSucceed.type)
    while (1) {
      yield call(helpersBrowser.inactiveBrowserTabLock)
      const isAuthenticated: boolean = yield select(AuthSelectors.isAuthenticated)
      const role: ROLES = yield select(AuthSelectors.role)

      if (!isAuthenticated || !role) {
        break
      }

      const shouldWeContinue = yield call(loopBodyGen, { role })

      if (!shouldWeContinue) {
        break
      }

      const { timeout } = yield race({
        timeout: delay(timeoutMs),
        initError: take(AuthActions.initFailed.type),
        logout: take(AuthActions.signOutRequested.type),
      })

      if (!timeout) {
        break
      }
    }
    yield call(tickerLoop)
  }
}

export const mapFilesToDto = function*(files: FileItem[]) {
  const contenders = files.filter((file: FileItem) => file.kind === 'current' || file.kind === 'added')
  const fileItems: (string | File | null)[] = yield all(
    contenders.map(file => {
      switch (file.kind) {
        case 'current':
          return file.file
        case 'added':
          return call(waitUntilFileLoaded, file.id)
      }
      return null
    }),
  )
  const result = fileItems.filter(item => typeof item === 'string').map(url => ({ url })) as FileData[]

  return result
}

export const getFilesUrls = function*(linkUrl: {}) {
  const linkUrlKeys = Object.keys(linkUrl)

  const filesWithUrls = {}
  for (let i = 0; i < linkUrlKeys.length; i++) {
    const key = linkUrlKeys[i]
    const file = linkUrl[key]
    if (file) {
      const contenders = linkUrl[key].filter(file => file.kind === 'current' || file.kind === 'added')
      const fileItems: (string | File | null)[] = yield all(
        contenders.map(file => {
          switch (file.kind) {
            case 'current':
              return file.file
            case 'added':
              return call(waitUntilFileLoaded, file.id)
          }
          return null
        }),
      )
      filesWithUrls[key] = fileItems.filter(item => typeof item === 'string').map(url => url || '')[0]
    }
  }

  return filesWithUrls
}
