import { canFarmerHaveNoEmail, Farmer, FarmerData } from 'modules/domain/farmer/types'
import React, { useState } from 'react'
import { ModalProps } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import * as Styled from './styled'
import styled from 'styled-components'
import CustomerExists from './CustomerExists'
import PhoneForm from './PhoneForm'
import { AddCustomerForm, FulfillCustomerForm } from 'views/components/CustomerModal/CustomerForm'
import RegionsModal from 'views/components/PickRegionsForm/RegionsModal'
import { useFarmerByPhone } from 'modules/domain/farmer/hooks'
import { CountryCode } from 'libphonenumber-js'
import { useSelector } from 'react-redux'
import ConfigSelectors from 'modules/domain/config/selectors'

type CustomerModalProps = {
  onCustomerSelect: (id: string, customer: Farmer) => void
  hideFarmerInfoChecking?: boolean
}

const CustomerFormWrapper = styled.div`
  padding-top: 18px;
`

const isProfileFulfilled = (profile: Farmer): boolean => {
  const hasName = () => {
    return !!profile.first_name && !!profile.last_name
  }
  const hasPhoneAndEmail = () => {
    return !!profile.phone_number && (!!profile.email || canFarmerHaveNoEmail(profile))
  }
  const hasAddress = () => {
    return !!profile.legal_address
  }

  return hasName() && hasPhoneAndEmail() && hasAddress()
}

export const CustomerForm: React.FC<{
  phone: string
  countryCode: CountryCode
  onBack: () => void
  onSelect: (farmer: FarmerData) => void
  onCancel: () => void
  hideFarmerInfoChecking?: boolean
}> = ({ phone, countryCode, onBack, onSelect, onCancel, hideFarmerInfoChecking = false }) => {
  const [progress, farmer] = useFarmerByPhone(phone)

  if ([Progress.IDLE, Progress.WORK].includes(progress)) {
    return <Styled.Spinner />
  }

  let content: React.ReactNode = null

  if (progress === Progress.SUCCESS && farmer && isProfileFulfilled(farmer)) {
    content = (
      <CustomerExists
        farmer={farmer}
        onSelect={() => onSelect(farmer)}
        onCancel={onCancel}
        hideFarmerInfoChecking={hideFarmerInfoChecking}
      />
    )
  } else if (farmer && !isProfileFulfilled(farmer)) {
    content = (
      <FulfillCustomerForm
        id={farmer.id}
        farmer={farmer}
        phone={phone}
        onCancel={onCancel}
        onSelect={onSelect}
        countryCode={countryCode}
      />
    )
  } else {
    content = <AddCustomerForm phone={phone} countryCode={countryCode} onCancel={onCancel} onSelect={onSelect} />
  }

  return (
    <CustomerFormWrapper data-test-id={'modal-customer-form'}>
      <Styled.BackBtn onClick={onBack} />
      {content}
    </CustomerFormWrapper>
  )
}

const CustomerModal: React.FC<ModalProps & CustomerModalProps> = ({
  isOpen,
  onClose,
  onCustomerSelect,
  hideFarmerInfoChecking = false,
}) => {
  const [phone, setPhone] = useState('')

  const [countryCode, setCountryCode] = useState<CountryCode>('CA')
  const setPhoneAndCountryCode = (phone: string, countryCode: CountryCode) => {
    setPhone(phone)
    setCountryCode(countryCode)
  }

  const wlConfig = useSelector(ConfigSelectors.config)
  const phoneCodes = wlConfig?.phone_codes || []

  return (
    <RegionsModal isOpen={isOpen} onClose={onClose}>
      {!phone ? (
        <PhoneForm onNext={setPhoneAndCountryCode} phoneCodes={phoneCodes} />
      ) : (
        <CustomerForm
          phone={phone}
          countryCode={countryCode}
          onSelect={(farmer: FarmerData) => {
            onCustomerSelect(farmer.id, farmer)
            setPhone('')
            onClose()
          }}
          onBack={() => setPhone('')}
          onCancel={() => {
            onClose()
            setPhone('')
          }}
          hideFarmerInfoChecking={hideFarmerInfoChecking}
        />
      )}
    </RegionsModal>
  )
}

export default CustomerModal
