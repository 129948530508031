import styled from 'styled-components'
import { IconQuestion, FormComponents } from '@agro-club/frontend-shared'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

export const FormSection = styled(FormComponents.FormSection)`
  max-width: 300px;
`

export const IconQuestionStyled = styled(IconQuestion)`
  display: block;
  cursor: pointer;
  width: 20px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.primary600};
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
  position: absolute;
  bottom: 2px;
  left: 60px;
`

export const SwitcherWrapper = styled.div`
  width: fit-content;
  position: relative;
`

export const TooltipContent = styled.div`
  width: 300px;
`
