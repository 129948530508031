import * as managers from 'modules/domain/reconciliation/managers'
import { ReconciliationSku, ReconciliationSkuFilter } from 'modules/domain/reconciliation/types'
import React from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import styled from 'styled-components'
import { useFilterMemo } from 'hooks/useFilterMemo'
import useLangPicker from 'hooks/useLangPicker'

const Wrapper = styled(SelectTestWrapper)`
  min-width: 300px;
`

export const getSkuLabel = (item: ReconciliationSku) => {
  return item.label as string
}
export const getSkuValue = (item: ReconciliationSku) => item.id

export type SkuSelectProps = Omit<EntitySelectProps<ReconciliationSku>, 'getOptionLabel' | 'getOptionValue'> & {
  filter?: ReconciliationSkuFilter
}

export const SkuSelectManually: React.FC<SkuSelectProps> = ({ filter, ...props }) => {
  const memoizedFilter = useFilterMemo<ReconciliationSkuFilter>(filter)
  const { pick } = useLangPicker()

  return (
    <Wrapper data-test-id="sku-select-manually">
      <EntitySelect
        filter={memoizedFilter}
        getEntityById={(id: string) =>
          managers.getSkuById(memoizedFilter?.manufacturer_id as string, memoizedFilter?.retailer_id as string, id)
        }
        getEntityList={managers.getSkus}
        searchParamKey="title"
        getOptionLabel={item => getSkuLabel({ ...item, label: pick(item.title) as string })}
        getOptionValue={getSkuValue}
        {...props}
        menuMaxHeight="220px"
      />
    </Wrapper>
  )
}
