import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  FccOrderListRequestSorting,
  FccOrderListRequestFilter,
  FccOrderUpdateDTO,
  FccOrdersUpdateDTO,
  FccOrdersUpdateResponse,
} from 'modules/domain/fccOrder/types'
import { FarmerOrder } from 'types/farmerOrder'
import { ListResponse } from 'types/api'
import { FccOrderItem } from 'types/fccOrder'

export const getList = (
  filter: FccOrderListRequestFilter,
  sorting: FccOrderListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<FarmerOrder>>(endpoints.farmerOrder('fcc'), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<FarmerOrder>(endpoints.farmerOrder(id))
}

export const updateItem = (order_id: string, dto: Partial<FccOrderUpdateDTO>) => {
  return apiClient.put<FccOrderItem>(endpoints.farmerOrder(`items_by_id/${order_id}/${dto.id}`), {
    price: dto.price,
    seed_treatment_id: dto.seed_treatment_id,
    credit_facility: dto.credit_facility,
  })
}

export const updateItems = (items: Partial<FccOrdersUpdateDTO>[]) => {
  return apiClient.put<FccOrdersUpdateResponse>(endpoints.farmerOrder(`items_by_ids`), {
    fcc_items: items,
  })
}
