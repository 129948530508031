import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'

const basicSelectors = createBasicSelectors('territoryTargetSku')

export const TerritoryTargetSkuSelectors = {
  ...basicSelectors,
  updateCounter: (state: AppGlobalState) => state.territoryTargetSku.updateCounter,
  targetsTotal: (state: AppGlobalState) => state.territoryTargetSku.totalTargetValues,
  retailerTargetsTotal: (state: AppGlobalState) => state.territoryTargetSku.totalRetailerTargets,
  farmerOrdersTotal: (state: AppGlobalState) => state.territoryTargetSku.totalFarmerOrdersSku,
  previousYear: (state: AppGlobalState) => state.territoryTargetSku.totalFarmerOrdersSkuPreviousYear,
}

export default TerritoryTargetSkuSelectors
