import styled, { StyledProps } from 'styled-components'

export const IdCell = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
`

export const Status = styled.span<StyledProps<{ status?: string }>>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  flex-shrink: 0;
  display: inline-block;
  background-color: ${({ theme, status }: StyledProps<{ status?: string }>) => {
    switch (status) {
      case 'active':
        return theme.color.accentApproving
      case 'inactive':
        return theme.color.accentNotify
      case 'deleted':
        return theme.color.accentDestructive
    }
  }};
`

export const DefaultStatus = styled(Status)`
  background-color: transparent;
  border: 1px solid;
  margin-left: 0;
`
export const Spacer = styled.div`
  min-width: 24px;
`
