import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import {
  StorefrontItem,
  StorefrontListRequestFilter,
  StorefrontListRequestSorting,
  StorefrontSidebarFilter,
  StorefrontSkuDTO,
  StorefrontState,
} from './types'

const initialState: StorefrontState = {
  ...BasicEntityState,
  sidebarFilter: {
    status: undefined,
  },
}

class StorefrontReducer extends BasicEntityImmerReducer<
  StorefrontItem,
  StorefrontListRequestFilter,
  StorefrontListRequestSorting,
  StorefrontSkuDTO,
  StorefrontSkuDTO,
  StorefrontState
> {
  sidebarFilterUpdated(filter: StorefrontSidebarFilter) {
    this.draftState.sidebarFilter = filter
  }
}

export const StorefrontActions = createActionCreators(StorefrontReducer)
export default StorefrontActions
export const reducer = createReducerFunction(StorefrontReducer, initialState)
