import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Modal, TextArea, useAction } from '@agro-club/frontend-shared'

import { Progress } from 'modules/types'
import { useSelector } from 'react-redux'
import { InventoryTransferRequest, TransferRequestStatuses } from 'modules/domain/inventoryInTransferRequest/types'
import InventoryOutTransferRequestActions from 'modules/domain/inventoryOutTransferRequest/duck'
import InventoryOutTransferRequestSelectors from 'modules/domain/inventoryOutTransferRequest/selectors'
import RequestModalInfo, { ModalContent } from './RequestModalInfo'

const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`
type RequestModalProps = {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  rowData: InventoryTransferRequest
}

const OutRequestModal: React.FC<RequestModalProps> = ({ rowData, isOpen, setIsOpen }) => {
  const { t } = useTranslation('inventoryTransferRequestModal')
  const updateProgress = useSelector(InventoryOutTransferRequestSelectors.updateProgress)
  const [prevState, setPrevState] = useState(updateProgress)
  const updateRequest = useAction(InventoryOutTransferRequestActions.updateRequested)
  useEffect(() => {
    if (updateProgress === Progress.SUCCESS && prevState !== updateProgress) {
      setIsOpen(false)
    }
    setPrevState(updateProgress)
  }, [updateProgress, setIsOpen, prevState])
  const deleteRequest = () => updateRequest(rowData.id, { status: TransferRequestStatuses.deleted })
  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      heading={t('modalOutHeading')}
      shouldCloseOnEsc={true}
    >
      <ModalContent>
        <RequestModalInfo rowData={rowData} />
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <TextArea label={t('labels.comment')} value={rowData.comment} disabled onChange={() => {}} />
      </ModalContent>

      <ModalFooter>
        <Button
          disabled={rowData.status !== TransferRequestStatuses.active}
          intent={'danger'}
          progress={updateProgress}
          onClick={deleteRequest}
        >
          {t('labels.delete')}
        </Button>
        <Button onClick={() => setIsOpen(false)} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default OutRequestModal
