import React from 'react'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/frontend-shared'
import ProgramSummaryListTable from './ProgramSummaryListTable'
import ProgramSummaryListFilters from './ProgramSummaryListFilters'

const ProgramSummaryList: React.FC = () => {
  const { t } = useTranslation('programSummary')
  useHelmet({ title: t('metaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <ProgramSummaryListFilters />
          <ProgramSummaryListTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default ProgramSummaryList
