import { useAction, useDidMountWithProgress } from '@agro-club/frontend-shared'
import CommentsSelectors from './selectors'
import CommentsActions from './duck'
import { ResourceHook } from 'modules/types'
import { useSelector } from 'react-redux'
import { CommentEntryDTO } from './types'

export const useCommentsList: ResourceHook<CommentEntryDTO[], [string, string]> = (
  collectionName: string,
  objId: string,
) => {
  const actualProgress = useSelector(CommentsSelectors.listFetchProgress)
  const list = useSelector(CommentsSelectors.list)
  const fetchAction = useAction(CommentsActions.listRequested, { collectionName, objId })
  const progress = useDidMountWithProgress(fetchAction, actualProgress)
  return [progress, list]
}
