import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'
import { Season, SeasonDTO, SeasonListRequestFilter, SeasonListRequestSorting } from './types'

const basicManagers = createBasicManagers<
  SeasonListRequestFilter,
  SeasonListRequestSorting,
  Season,
  SeasonDTO,
  SeasonDTO
>(endpoints.season)

export default {
  ...basicManagers,
  getCurrentSeason: async (filter: SeasonListRequestFilter) =>
    (
      await basicManagers.getList(
        { is_current: true, ...filter },
        {
          sort_field: 'title',
        },
        1,
        1,
      )
    ).data[0],
  getItemsByIds: createItemsByIds(false, basicManagers.getItem),
} as const
