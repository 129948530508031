import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import { ListResponse } from 'types/api'
import * as managers from './managers'
import { getFilesUrls, updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import { waitUntilFileLoaded } from 'modules/domain/uploadManager/sagas'

import { License, LicenseDTO } from './types'
import LicenseActions from './duck'
import LicenseSelectors from './selectors'
import LicenseRoutes from 'views/pages/License/routes'
import { FileItem } from 'views/components/FileManager/types'

export const fetchList = function*() {
  try {
    let currentPage = yield select(LicenseSelectors.page)
    const filter = yield select(LicenseSelectors.filter)
    const sorting = yield select(LicenseSelectors.sorting)
    const pageSize = yield select(LicenseSelectors.pageSize)

    let response: ListResponse<License> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response

    yield put(LicenseActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, LicenseRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(LicenseActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(LicenseSelectors.page)
    const filter = yield select(LicenseSelectors.filter)
    const sorting = yield select(LicenseSelectors.sorting)
    const pageSize = yield select(LicenseSelectors.pageSize)
    const { data, total_count }: { data: License[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(LicenseActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(LicenseActions.listRequestNextFailed(errType))
  }
}

const pickFileUrl = (file?: FileItem) => {
  if (!file) {
    return null
  }
  switch (file.kind) {
    case 'current':
      if (typeof file.file === 'string') {
        return file.file
      }
      return null
    case 'added':
      return call(waitUntilFileLoaded, file.id)
  }
  return null
}

export const addItem = function*({ payload: [dto, duplicate] }: ReturnType<typeof LicenseActions.addRequested>) {
  try {
    const { logo_file, footer_logo_file, favicon_files, og_config, images, legal_docs, ...rest } = dto
    const {
      logo_url,
      footer_logo_url,
      favicon_ico_url,
      favicon_svg_url,
      favicon_png_192_url,
      favicon_png_512_url,
      favicon_png_apple_touch_url,
      og_image_url,
      spinner_url,
      cookies_policy,
      privacy_policy,
      user_agreement,
    } = yield all({
      logo_url: pickFileUrl(logo_file),
      footer_logo_url: pickFileUrl(footer_logo_file),
      favicon_ico_url: pickFileUrl(favicon_files?.ico),
      favicon_svg_url: pickFileUrl(favicon_files?.svg),
      favicon_png_192_url: pickFileUrl(favicon_files?.png_192),
      favicon_png_512_url: pickFileUrl(favicon_files?.png_512),
      favicon_png_apple_touch_url: pickFileUrl(favicon_files?.png_apple_touch),
      og_image_url: pickFileUrl(og_config?.og_image_file),
      spinner_url: pickFileUrl(images?.spinner_file),
      cookies_policy: getFilesUrls(legal_docs?.cookies_policy || {}),
      privacy_policy: getFilesUrls(legal_docs?.privacy_policy || {}),
      user_agreement: getFilesUrls(legal_docs?.user_agreement || {}),
    })

    const mappedLicense: LicenseDTO = {
      ...rest,
      logo_url,
      footer_logo_url,
      favicons: {
        ico: favicon_ico_url,
        svg: favicon_svg_url,
        png_192: favicon_png_192_url,
        png_512: favicon_png_512_url,
        png_apple_touch: favicon_png_apple_touch_url,
      },
      og_config: {
        og_title: og_config?.og_title,
        og_description: og_config?.og_description,
        og_image: og_image_url,
      },
      images: {
        spinner_url,
      },
      legal_docs: {
        cookies_policy,
        privacy_policy,
        user_agreement,
      },
    }
    const license: License = yield call(managers.addItem, mappedLicense)
    yield put(LicenseActions.addSucceed(license))
    if (!duplicate) {
      yield put(push(generatePath(LicenseRoutes.Edit, { id: license.id })))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(LicenseActions.addFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof LicenseActions.itemRequested>) {
  try {
    const license: License = yield call(managers.getItem, id)
    yield put(LicenseActions.itemRequestSucceed(license))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(LicenseActions.itemRequestFailed(id, errType))
  }
}

export const updateItem = function*({
  payload: [id, dto, duplicate],
}: ReturnType<typeof LicenseActions.updateRequested>) {
  try {
    const { logo_file, footer_logo_file, favicon_files, og_config, images, legal_docs, ...rest } = dto
    const {
      logo_url,
      footer_logo_url,
      favicon_ico_url,
      favicon_svg_url,
      favicon_png_192_url,
      favicon_png_512_url,
      favicon_png_apple_touch_url,
      og_image_url,
      spinner_url,
      cookies_policy,
      privacy_policy,
      user_agreement,
    } = yield all({
      logo_url: pickFileUrl(logo_file),
      footer_logo_url: pickFileUrl(footer_logo_file),
      favicon_ico_url: pickFileUrl(favicon_files?.ico),
      favicon_svg_url: pickFileUrl(favicon_files?.svg),
      favicon_png_192_url: pickFileUrl(favicon_files?.png_192),
      favicon_png_512_url: pickFileUrl(favicon_files?.png_512),
      favicon_png_apple_touch_url: pickFileUrl(favicon_files?.png_apple_touch),
      og_image_url: pickFileUrl(og_config?.og_image_file),
      spinner_url: pickFileUrl(images?.spinner_file),
      cookies_policy: getFilesUrls(legal_docs?.cookies_policy || {}),
      privacy_policy: getFilesUrls(legal_docs?.privacy_policy || {}),
      user_agreement: getFilesUrls(legal_docs?.user_agreement || {}),
    })

    const mappedLicense: LicenseDTO = {
      ...rest,
      logo_url,
      footer_logo_url,
      favicons: {
        ico: favicon_ico_url,
        svg: favicon_svg_url,
        png_192: favicon_png_192_url,
        png_512: favicon_png_512_url,
        png_apple_touch: favicon_png_apple_touch_url,
      },
      og_config: {
        og_title: og_config?.og_title,
        og_description: og_config?.og_description,
        og_image: og_image_url,
      },
      images: {
        spinner_url,
      },
      legal_docs: {
        cookies_policy,
        privacy_policy,
        user_agreement,
      },
    }
    const license: License = yield call(managers.updateItem, id, mappedLicense)
    yield put(LicenseActions.updateSucceed(license))
    if (duplicate) {
      yield put(push(LicenseRoutes.Add))
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(LicenseActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof LicenseActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(LicenseActions.removeSucceed(payload))
    yield put(push(LicenseRoutes.List))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(LicenseActions.removeFailed(payload, type))
  }
}

// TODO-256
const LicenseSaga = function*() {
  yield all([
    takeLatest(LicenseActions.itemRequested.type, fetchItem),
    takeLatest(LicenseActions.listRequested.type, fetchList),
    takeLatest(LicenseActions.filterUpdated.type, fetchList),
    takeLatest(LicenseActions.listRequestedNext.type, fetchListNext),
    takeLatest(LicenseActions.addRequested.type, addItem),
    takeLatest(LicenseActions.updateRequested.type, updateItem),
    takeLatest(LicenseActions.removeRequested.type, removeItem),
  ])
}

export default LicenseSaga
