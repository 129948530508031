import { MimeType } from './types'

export const isImageByUrl = (url: string) => {
  return url.endsWith('png') || url.endsWith('jpg') || url.endsWith('jpeg')
}

export const isImageByMimeType = (type: string) => {
  return type === MimeType.JPG || type === MimeType.PNG
}

export const isPdfByUrl = (url: string) => {
  return url.endsWith('pdf')
}

export const isPdfByMimeType = (file: string) => {
  return file.endsWith('pdf')
}
