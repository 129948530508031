import styled, { keyframes, StyledProps } from 'styled-components'

export const animation = keyframes`
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(0, 0);
  }
`

export const FadeInContainer = styled.div<StyledProps<{ running?: boolean }>>`
  width: 100%;
  height: 100%;
  position: relative;
  animation-duration: 0.3s;
  animation-name: ${props => (props.running ? animation : null)};
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  animation-play-state: ${props => (props.running ? `running` : 'paused')};
`

export const FadeOutContainer = styled.div<StyledProps<{ running?: boolean }>>`
  width: 100%;
  height: 100%;
  position: relative;
  animation-duration: 0.3s;
  animation-name: ${animation};
  animation-timing-function: ease-in-out;
  animation-play-state: ${props => (props.running ? `running` : 'paused')};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  > *:not(:first-child) {
    margin-left: 16px;
  }
  > * {
    flex-shrink: 0;
  }
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: none;
`

export const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.color.accentDestructive};
  pointer-events: none;
`

export const DropContainer = styled.div<{ isDraggingOver: boolean }>`
  background-color: ${props => (props.isDraggingOver ? props.theme.color.secondary50 : 'transparent')};
  border-radius: 8px;
  box-shadow: ${props => (props.isDraggingOver ? `0 0 20px 5px ${props.theme.color.secondary50}` : 'none')};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 120px);
  width: 100%;
`

export const Title = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 24px;
`
