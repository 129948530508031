import { EntityState } from 'modules/domain/types'
import { LocalizedValue } from 'types/entities'
import { FileItem } from 'views/components/FileManager/types'
import { IncentiveCampaign } from 'modules/domain/incentiveCampaign/types'
import { Season } from 'modules/domain/season/types'
import { StorefrontSku } from 'modules/domain/storefront/types'
import { ProductUnits } from '../product/types'

export type IncentiveProgramState = EntityState<
  IncentiveProgram,
  IncentiveProgramListRequestFilter,
  IncentiveProgramListRequestSorting
>

export type IncentiveProgram = {
  sku_ids: string[]
  campaign_id: string
  is_active: boolean
  id: string
  slug?: string
  title: string
  season_id: string
  start_date?: string
  end_date?: string
  tiers_rules: TierRulesUnion[]
  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue
  description?: string
  description_i18n?: string
  scopes?: IncentiveProgramScope[]
  status?: IncentiveProgramStatus
  incentive_campaign?: IncentiveCampaign
  season?: Season
  units?: ProductUnits
}

export enum IncentiveProgramScope {
  FarmerOrders = 'farmer_orders',
  DistributorOrder = 'distributor_orders',
}

export enum IncentiveProgramStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum IncentiveProgramTypes {
  Quantity = 'quantity',
  Promocode = 'promocode',
  Gift = 'non_monetary',
  Percentage = 'percentage',
  HistoryQuantity = 'history_quantity',
}

export type IncentiveProgramListRequestFilter = {
  search?: string
  scopes?: IncentiveProgramScope
  status?: IncentiveProgramStatus
  season_id?: string
  campaign_id?: string
  tier_type?: IncentiveProgramTypes
  rule_type?: IncentiveProgramTypes
  is_active?: boolean
  apply_for_farmers?: string
  apply_for_distributors?: string
  available_gifts?: string[]
  product_ids?: string[]
}

export type IncentiveProgramListRequestSorting = {
  sort_field?: keyof IncentiveProgram
  sort_reversed?: boolean
}

export type IncentiveProgramDTO = Omit<IncentiveProgram, 'id' | 'is_active'> & { id?: string }

// Discounts

export type HistoricQtyTierRule = {
  type: IncentiveProgramTypes.HistoryQuantity
  amount: string
  historic_season_id: string
  min_qty: number
  max_qty?: number
  min_qty_historic: number
  max_qty_historic?: number
}

export type QtyTierRule = {
  type: IncentiveProgramTypes.Quantity
  amount: string
  min_qty: number
  max_qty?: number
  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue<FileItem[]>
}

export type PromocodeTierRule = {
  type: IncentiveProgramTypes.Promocode
  amount: string
  min_qty: number
  max_qty?: number

  promocode: string

  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue<FileItem[]>
}

export type GiftTierRule = {
  type: IncentiveProgramTypes.Gift
  min_qty: number
  max_qty?: number
  available_gifts: string[]
  limit: number
}

export type PercentageTierRule = {
  amount: string
  type: IncentiveProgramTypes.Percentage
  min_qty?: number
  max_qty?: number
  percent: string
  start_date: string
  end_date: string
}

export type TierRulesUnion = Partial<QtyTierRule | PromocodeTierRule | HistoricQtyTierRule>

export type SkuOrderEntry = {
  sku_id: string
  quantity?: string
  sku?: StorefrontSku
}

export type SkuCalculateDiscountsRequest = {
  seller_id?: string | null
  season_id?: string | null
  promocodes?: string[]
  entries: SkuOrderEntry[]
  at_date?: string
  scopes?: IncentiveProgramScope[]
}

export type SkuCalculatedTier<TierRule = TierRulesUnion> = {
  amount: string
  is_applied: boolean
  qty_to_apply: number
  show?: boolean
  tier_rule: TierRule
  calculated_entries?: SkuCalcEntry[]
}

export type SkuCalculatedDiscountDTO = {
  is_related: boolean
  tiers: SkuCalculatedTier[]
  amount: string
  next_lvl_qty: number
  program?: IncentiveProgram
  gifts?: {
    product_ids: string
    limit: string
  }
  calc_entries: SkuCalcEntry[]
}

export type SkuCalcEntry = {
  sku_id: string
  quantity: number
  seed_treatment_id?: string
  price?: string
  discount?: string
  percent: string
}

export type SkuCalculateDiscountsResponse = {
  total: string
  discounts: SkuCalculatedDiscountDTO[]
}
