import styled from 'styled-components'
import { IconBin } from '@agro-club/frontend-shared'

export const AddWrapper = styled.div`
  max-width: 350px;
`
export const BranchWrapper = styled.div``

export const BranchList = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  grid-gap: 16px;
  margin-top: 24px;
  align-items: center;

  & > div:nth-child(6n + 2),
  & > div:nth-child(6n + 3),
  & > div:nth-child(6n + 4),
  & > div:nth-child(6n + 5) {
    justify-self: center;
  }
`

export const Error = styled.div`
  color: ${props => props.theme.color.accentDestructive};
`

export const Label = styled.div`
  color: ${props => props.theme.color.onPrimaryDark};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
`

export const BranchName = styled.div`
  font-size: 14px;
  padding-right: 16px;
  font-weight: 500;
`

export const AddProducerWrapper = styled.div`
  position: relative;
  margin-top: ${(props: { margin: boolean }) => (props.margin ? '10px' : 0)};
`

export const RemoveBtn = styled(IconBin)`
  fill: ${props => props.theme.color.secondary300};
  cursor: pointer;
  transition: 0.3s fill;

  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`
