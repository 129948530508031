import { ValidationError } from 'helpers/imageFileValidator'

export enum MimeType {
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
  PNG = 'image/png',
}

export type FileItem = {
  file: string | File
  kind: 'current' | 'added' | 'removed' | 'invalid'
  id: string
  error?: ValidationError | null
}

export type FileAuthor = {
  phone_number?: string
  email?: string
  first_name?: string
  second_name?: string
  last_name?: string
  company_id?: string
}

export type FileData = {
  url: string
  author_id?: string
  uploaded_at?: string
  label?: string
  author?: FileAuthor
}

export type FileDTO = Pick<FileData, 'url' | 'label'>
