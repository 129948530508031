import { EntityState } from 'modules/domain/types'

export type NotificationsListState = EntityState<
  NotificationsListEntry,
  NotificationsListRequestFilter,
  NotificationsListRequestSorting
> & {
  statuses: NotificationsListEntryStatus[]
}

export enum NotificationsListEntryStatus {
  FAILED = 'failed',
  SENT = 'sent',
  DELIVERED = 'delivered',
  ACCEPT = 'accept',
}

export type NotificationsListEntryFilterStatuses = {
  id: string
  title: string
  label?: string
  value?: string
}

export type NotificationsListEntry = {
  id: string
  communication_channel: string
  status: NotificationsListEntryStatus
  type: string
  date_send: string
  address_to: string
  created_at: string
  updated_at: string
  author_id: string
  delivered: boolean
  external_id: string
  template_name: string
  parent_id?: string
}

export type NotificationsListRequestFilter = {
  search?: string
  status?: NotificationsListEntryStatus
}

export type NotificationsListRequestSorting = {
  sort_field?: keyof NotificationsListEntry
  sort_reversed?: boolean
}

export type NotificationListDTO = Omit<NotificationsListEntry, 'id'> & {
  id?: string
}
