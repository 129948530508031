import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type ConfirmCardProps = {
  category?: string
  packageType?: string
  product?: string
  quantity?: number
  retailer?: string
  sku?: string
  subcategory?: string
  supplier?: string
}

const Text = styled.p`
  margin: 0 0 8px 0;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  opacity: 0.48;
  white-space: wrap;
`
const BoldText = styled.span`
  font-weight: 700;
  font-size: 18px;
`

const ConfirmCard: React.VFC<ConfirmCardProps> = ({
  category,
  packageType,
  product,
  quantity,
  retailer,
  sku,
  subcategory,
  supplier,
}) => {
  const { t } = useTranslation('inventorySKUTransaction')

  const dataArray = [
    { label: t('form.labels.retailer'), value: retailer },
    { label: t('form.labels.producer'), value: supplier },
    { label: t('form.labels.category'), value: category },
    { label: t('form.labels.subcategory'), value: subcategory },
    { label: t('form.labels.product'), value: product },
    { label: t('form.labels.package'), value: packageType },
    { label: t('form.labels.sku'), value: sku },
  ]

  return (
    <>
      {dataArray.map(item => item.value && <Text>{`${item.label}: ${item.value}`}</Text>)}
      <Text>
        {`${t('form.labels.quantity')}: `}
        <BoldText>{quantity || 0}</BoldText>
      </Text>
    </>
  )
}

export default ConfirmCard
