import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Season } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.season.items
const ids = (state: AppGlobalState) => state.season.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (id?: string) => (state: AppGlobalState): Season | undefined =>
  id && state.season.items[id] ? state.season.items[id] : undefined
const meta = (id?: string) => (state: AppGlobalState): EntityMetadata<Season> =>
  id && state.season.meta[id]
    ? state.season.meta[id]
    : {
        fetchError: null,
        fetchProgress: Progress.IDLE,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }
const itemFetchProgress = (state: AppGlobalState) => state.season.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.season.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.season.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.season.listFetchErrorDetail
const listFetchNextProgress = (state: AppGlobalState) => state.season.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.season.listFetchNextError
const listFetchNextErrorDetail = (state: AppGlobalState) => state.season.listFetchNextErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Season[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.season.filter
const sorting = (state: AppGlobalState) => state.season.sorting
const page = (state: AppGlobalState) => state.season.page
const total = (state: AppGlobalState) => state.season.total
const updateProgress = (state: AppGlobalState) => state.season.updateProgress
const removeProgress = (state: AppGlobalState) => state.season.removeProgress
const addProgress = (state: AppGlobalState) => state.season.addProgress
const hasNext = (state: AppGlobalState) => state.season.total > state.season.ids.length
const pageSize = (state: AppGlobalState) => state.season.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.season.total / state.season.pageSize)
const producerSeasons = (state: AppGlobalState, producerId?: string) =>
  list(state).filter(s => s.company_id === producerId || !producerId)
const producerCurrentSeason = (state: AppGlobalState, producerId?: string) => {
  return producerSeasons(state, producerId).find(s => s.is_current)
}
const currentSeason = createSelector(list, seasons => {
  return seasons.find(s => s.is_current)
})

const SeasonSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  listFetchNextErrorDetail,
  pages,
  currentSeason,
  producerCurrentSeason,
}

export default SeasonSelectors
