import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { BasicEntityState } from 'modules/constants'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import { Status } from 'types/entities'
import {
  StorefrontProduct,
  StorefrontProductListRequestFilter,
  StorefrontProductListRequestSorting,
  StorefrontProductState,
  StorefrontProductDTO,
} from '../storefrontProduct/types'

const initialState: StorefrontProductState = {
  ...BasicEntityState,
  filter: {
    status: Status.Active,
  },
}

class StorefrontProductReducer extends BasicEntityImmerReducer<
  StorefrontProduct,
  StorefrontProductListRequestFilter,
  StorefrontProductListRequestSorting,
  StorefrontProductDTO,
  StorefrontProductDTO,
  StorefrontProductState
> {}

export const StorefrontProductActions = createActionCreators(StorefrontProductReducer)
export default StorefrontProductActions
export const reducer = createReducerFunction(StorefrontProductReducer, initialState)
