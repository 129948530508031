import makeHttpClient, { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  ReturnDeclarationSkuListRequestSorting,
  ReturnDeclarationSkuListRequestFilter,
  ReturnDeclarationSkuDTO,
  PrintDeclarationSkuDto,
  DeclarationSkuCountDto,
  ReturnDeclarationSku,
  ReturnDeclarationCardFilterSuggestItem,
} from './types'
import { ListResponse } from 'types/api'
import { helpersDownload } from '@agro-club/frontend-shared'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { StorefrontCardListRequestFilter, StorefrontCardListRequestSorting } from '../storefrontCard/types'
import { StorefrontSkuFilterSuggestItem } from '../storefront/types'

export default {
  ...createBasicManagers<
    ReturnDeclarationSkuListRequestFilter,
    ReturnDeclarationSkuListRequestSorting,
    ReturnDeclarationSku,
    ReturnDeclarationSkuDTO,
    ReturnDeclarationSkuDTO
  >(endpoints.returnDeclarationSku),
  printDeclaration: async (dto: PrintDeclarationSkuDto) => {
    const printApiClient = makeHttpClient('REACT_APP_PRINT_API_BASE_URL')
    const res = await printApiClient.post<ArrayBuffer>('/return-declaration/print', dto, {
      responseType: 'arraybuffer',
    })

    helpersDownload.downloadBlob(new Blob([res], { type: 'application/pdf' }))
    return res
  },
  getCount: (params: Omit<DeclarationSkuCountDto, 'trigger'>) => {
    return apiClient.get<ListResponse<ReturnDeclarationSku>>(endpoints.returnDeclarationSkuCount(), params)
  },
  getSuggestedSkuItems: (
    filter: ReturnDeclarationSkuListRequestFilter,
    sorting: ReturnDeclarationSkuListRequestSorting,
    page: number,
    pageSize: number,
  ): Promise<ListResponse<StorefrontSkuFilterSuggestItem>> =>
    apiClient.get(endpoints.returnDeclarationSkuSuggest(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    }),
  getSuggestedCardItems: (
    filter: StorefrontCardListRequestFilter,
    sorting: StorefrontCardListRequestSorting,
    page: number,
    pageSize: number,
  ): Promise<ListResponse<ReturnDeclarationCardFilterSuggestItem>> =>
    apiClient.get(endpoints.returnDeclarationSkuCardsSuggest(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    }),
}
