import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { FormikProvider } from 'formik'
import {
  Input,
  FormComponents,
  SectionBody,
  SectionContainer,
  Switch,
  useFormManager,
  helpersSlug,
  RangeDatePicker,
  SimpleSelect,
  Checkbox,
} from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import SlugForm from 'views/components/SlugForm/SlugForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { DiscountRule, DiscountRuleScope, DiscountRuleStatus, TierRulesUnion } from 'modules/domain/discountRule/types'
import styled from 'styled-components'
import { isValid, parseISO } from 'date-fns'
import { Label } from 'views/pages/Company/CompanyDetailsForm/components/BranchesStyles'
import { AvailableLanguages, availableLanguagesList, CompanyType, DiscountTypes, LocalizedValue } from 'types/entities'
import QtyTiersRulesForm from './QtyTiersRulesForm'
import PromocodeTiersRulesForm from './PromocodeTiersRulesForm'
import PercentageTiersRulesForm from 'views/pages/DiscountRule/DiscountRuleDetailsForm/PercentageTiersRulesForm'
import GiftTiersRulesForm from './GiftTiersRulesForm'
import { useProductsOptions } from 'modules/domain/product/hooks'

import ProductNoFetchMultiSelect from 'views/components/ProductNoFetchMultiSelect/ProductNoFetchMultiSelect'
import { Product, ProductListRequestFilter } from 'modules/domain/product/types'
import * as Styled from './styled'
import { createLocalizedValue } from 'helpers/localization'
import { FileItem } from 'views/components/FileManager/types'
import URLForm from './URLForm'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import { DiscountScopesSwitcher } from 'views/pages/DiscountRule/DiscountRuleDetailsForm/DiscountScopesSwitcher'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { useSelector } from 'react-redux'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import HistoryQtyTiersRulesForm from './HistoryQtyTiersRulesForm'
import { Season } from 'modules/domain/season/types'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 510px;
`

const FormRow = styled.span`
  & > ${Label} {
    margin-bottom: 8px;
  }
`

export type FormProps = Omit<DiscountRule, 'description' | 'description_i18n' | 'rule_type' | 'company_name' | 'id'> & {
  type: DiscountTypes
}

type FormikManagerData = {
  common: {
    title: string
    status: DiscountRuleStatus
    type: DiscountTypes
    campaign_name: string
    products_ids: string[]
    producer_id: string
    season_id: string
    start_date: string
    end_date: string
    scopes: DiscountRuleScope[]
    measure?: string
  }
  slug: { slug: string }
  tiers: { tiers_rules: TierRulesUnion[] }
}

const DiscountRuleDetailsForm: React.FC<{
  onSubmit(form: FormProps): void
  onRemove?: () => void
  initialValues?: Partial<
    DiscountRule & {
      type: DiscountTypes
    }
  >
  progress?: Progress
  removeProgress?: Progress
  editing?: boolean
  onCancel(): void
}> = ({
  onSubmit,
  onRemove,
  progress,
  removeProgress,
  editing,
  onCancel,
  initialValues = {
    status: DiscountRuleStatus.Active,
    scopes: [DiscountRuleScope.FarmerOrders],
  },
}) => {
  const { t } = useTranslation(['discountRules', 'common'])
  const { bind, submitAll, dirty, valid, slots } = useFormManager<FormikManagerData>()
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const [seasonStartDate, setSeasonStartDate] = useState<string | null>()
  const [seasonEndDate, setSeasonEndDate] = useState<string | null>()
  const getFormatedDate = useDateFormatFn()

  const validationSchema = useMemo(() => {
    const seasonRangeValidation = t('validation:seasonRangeOut', {
      start: seasonStartDate && getFormatedDate(seasonStartDate),
      end: seasonEndDate ? getFormatedDate(seasonEndDate) : t('validation:endlessSeason'),
    })

    return Yup.object({
      title: Yup.string().required(t('validation:field_required')),
      campaign_name: Yup.string().required(t('validation:field_required')),
      producer_id: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(t('validation:field_required')),
      type: Yup.string().required(t('validation:field_required')),
      ...(seasonStartDate && {
        start_date: Yup.date()
          .nullable()
          .min(seasonStartDate, seasonRangeValidation),
      }),
      ...(seasonEndDate && {
        end_date: Yup.date()
          .nullable()
          .max(seasonEndDate, seasonRangeValidation),
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, seasonStartDate, seasonEndDate])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const formik = bind('common')({ initialValues, onSubmit: () => {}, validationSchema, enableReinitialize: true })

  const [productFilter, setProductFilter] = useState<ProductListRequestFilter>({
    producer_id: formik.values.producer_id,
  })
  const [, allProducts] = useProductsOptions(null, productFilter, 1000)

  const [gifts, setGifts] = useState<Product[]>([])
  const [products, setProducts] = useState<Product[]>([]) // products that are not gifts
  const dateFormat = useDateFormat({ isYearShort: true })

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }

      const tiersRules = forms.tiers?.tiers_rules.map(tier => ({ ...tier, type: forms.common.type }))
      const langProp = (lang: AvailableLanguages, prop?: FileItem[]) =>
        languages.includes(lang) ? prop || '' : undefined

      onSubmit({
        title: forms.common.title || '',
        slug: forms.slug.slug,
        campaign_name: forms.common.campaign_name,
        type: forms.common.type,
        status: forms.common.status,
        producer_id: forms.common.producer_id,
        products_ids: forms.common.products_ids,
        season_id: forms.common.season_id,
        end_date: forms.common.end_date || null,
        start_date: forms.common.start_date || null,
        tiers_rules: tiersRules,
        link_label_i18n: createLocalizedValue(lang => langProp(lang, forms[lang]?.link_label) as string),
        link_url_i18n: createLocalizedValue(lang => langProp(lang, forms[lang]?.link_url) as FileItem[]),
        scopes: forms.common.scopes,
        measure: forms.common.measure,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  useValidationErrorNotification(formik.submitCount, valid)

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [initialValues?.link_url_i18n?.[lang], initialValues?.link_label_i18n?.[lang]].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const setSeasonDateRanges = (season?: Season | null) => {
    setSeasonStartDate(season?.start_date)
    setSeasonEndDate(season?.end_date)
  }

  const isLangActive = (lang: AvailableLanguages) => {
    return languages.includes(lang)
  }

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const handleProductRemove = useCallback(
    (id: string) => {
      formik.setFieldValue(
        'products_ids',
        formik.values.products_ids?.filter(pid => pid !== id),
      )
    },
    [formik],
  )

  const handleProductsChange = useCallback(
    (id: string) => {
      if (formik.values.products_ids?.includes(id)) {
        handleProductRemove(id)
      } else {
        formik.setFieldValue('products_ids', [...(formik.values.products_ids || []), id])
      }
    },
    [formik, handleProductRemove],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if ((isValid(parsedStartDate) && isValid(parsedEndDate)) || (!start && !end)) {
        formik.setFieldValue('start_date', start)
        formik.setFieldValue('end_date', end)
      }
    },
    [formik],
  )

  const handleScopeChange = useCallback(
    (scope: DiscountRuleScope, on: boolean) => {
      const scopes = new Set(formik.values.scopes)
      on ? scopes.add(scope) : scopes.delete(scope)
      formik.setFieldValue('scopes', [...scopes])
    },
    [formik],
  )

  const discountRuleTypes = useMemo(
    () => [
      {
        id: DiscountTypes.Quantity,
        title: t('types.qty'),
      },
      {
        id: DiscountTypes.Promocode,
        title: t('types.promocode'),
      },
      {
        id: DiscountTypes.Gift,
        title: t('types.gift'),
      },
      {
        id: DiscountTypes.Percentage,
        title: t('types.percentage'),
      },
      {
        id: DiscountTypes.HistoryQuantity,
        title: t('types.historyQty'),
      },
    ],
    [t],
  )

  useEffect(() => {
    setGifts((allProducts ?? []).filter(item => item.is_gift))
    setProducts((allProducts ?? []).filter(item => !item.is_gift))
  }, [allProducts])

  useEffect(() => {
    if (productFilter.producer_id !== formik.values.producer_id) {
      setProductFilter({ ...productFilter, producer_id: formik.values.producer_id })
      formik.setFieldValue('products_ids', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.producer_id])

  const isDateSpanInvalid =
    formik.touched.start_date && formik.touched.end_date && !!(formik.errors.start_date || formik.errors.end_date)

  return (
    <FormikProvider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.labels.description')}>
                  <Input
                    {...formik.getFieldProps('title')}
                    data-test-id={'discount-title'}
                    label={t('form.labels.title')}
                    invalid={formik.touched.title && !!formik.errors.title}
                    errorText={formik.errors.title}
                    onChange={(_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
                      formik.setFieldValue('title', value)
                      if (!editing) slots['slug'].setFieldValue('slug', helpersSlug.slugify(value))
                    }}
                    required
                  />
                  <SlugForm
                    showLabel={true}
                    useFormik={bind('slug')}
                    initialValue={initialValues.slug}
                    disabled={!!editing}
                  />
                  <Input
                    {...formik.getFieldProps('campaign_name')}
                    data-test-id={'discount-campaign-name'}
                    label={t('form.labels.campaign')}
                    invalid={formik.touched.campaign_name && !!formik.errors.campaign_name}
                    errorText={formik.errors.campaign_name}
                    required
                  />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.config')}>
                  <CompanySelect
                    label={t('form.labels.producer')}
                    onChange={(id?: string) => {
                      formik.setFieldValue('producer_id', id)
                      formik.setFieldTouched('producer_id')
                    }}
                    value={formik.values.producer_id}
                    companyType={CompanyType.Producer}
                    isClearable
                    isSearchable
                    onMenuClose={() => formik.setFieldTouched('producer_id')}
                    invalid={formik.touched.producer_id && !!formik.errors.producer_id}
                    errorText={formik.errors.producer_id}
                    required
                  />
                  <div data-test-id={'discount-product-select'}>
                    <ProductNoFetchMultiSelect
                      products={products}
                      values={formik.values.products_ids}
                      label={t('form.labels.products')}
                      placeholder={t('form.placeholders.products')}
                      isDisabled={!formik.values.producer_id}
                      onRemove={handleProductRemove}
                      onChange={handleProductsChange}
                    />
                  </div>
                  <div>
                    <Input {...formik.getFieldProps('measure')} label={t('form.labels.measure')} />
                  </div>
                  {formik.values?.status !== DiscountRuleStatus.Deleted && (
                    <Switch
                      on={formik.getFieldProps('status').value === DiscountRuleStatus.Active}
                      onClick={value => {
                        value
                          ? formik.setFieldValue('status', DiscountRuleStatus.Active)
                          : formik.setFieldValue('status', DiscountRuleStatus.Inactive)
                      }}
                      label={t('form.labels.status')}
                      testId={'discount-status'}
                    />
                  )}
                  <SeasonSelect
                    companyId={formik.values.producer_id}
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    value={formik.values.season_id}
                    invalid={!!formik.values.producer_id && formik.touched.season_id && !!formik.errors.season_id}
                    errorText={formik.errors.season_id}
                    onChange={(id, original) => {
                      formik.setFieldValue('season_id', id)
                      formik.setFieldTouched('season_id')
                      if (!editing) return
                      setSeasonDateRanges(original)
                    }}
                    isDisabled={!formik.values.producer_id}
                    required
                  />
                  <FormRow>
                    <Label>{t('form.labels.datePeriod')}</Label>
                    <RangeDatePicker
                      start={formik.values.start_date || ''}
                      end={formik.values.end_date || ''}
                      onChange={handleDateRangeChange}
                      errorText={formik.errors.start_date || formik.errors.end_date}
                      invalid={isDateSpanInvalid}
                      isClearable
                      format={dateFormat}
                    />
                  </FormRow>
                  <DiscountScopesSwitcher scopes={formik.values?.scopes} onChange={handleScopeChange} />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.labels.urlSectionTitle')}>
                  <Styled.LanguagesContainer>
                    <Styled.LanguagesBlock>
                      {availableLanguagesList.map(lang => (
                        <Checkbox
                          label={t(`common:langNames.${lang}`)}
                          isChecked={isLangActive(lang)}
                          value={lang}
                          onChange={handleLangChange}
                          key={lang}
                        />
                      ))}
                    </Styled.LanguagesBlock>
                    {!languages.length && <Styled.Error>{t('validation:language_required')}</Styled.Error>}
                  </Styled.LanguagesContainer>
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <URLForm
                            key={lang}
                            lang={lang}
                            link_label={initialValues.link_label_i18n}
                            link_url={initialValues.link_url_i18n as LocalizedValue<FileItem[]>}
                            useFormik={bind(lang as keyof FormikManagerData)}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                </FormComponents.FormSection>
                {formik.values.producer_id && (
                  <FormComponents.FormSection title={t('form.labels.tiers')}>
                    <div data-test-id={'discount-type'}>
                      <SimpleSelect
                        label={t('form.labels.type')}
                        placeholder={t('form.placeholders.type')}
                        options={discountRuleTypes}
                        value={formik.values.type}
                        invalid={formik.touched.type && !!formik.errors.type}
                        errorText={formik.errors.type}
                        onChange={val => {
                          formik.setFieldValue('type', val)
                          formik.setFieldTouched('type')
                        }}
                        required
                      />
                    </div>
                    {formik.values.type === DiscountTypes.Quantity ? (
                      <QtyTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || [{}]}
                        producerId={formik.values.producer_id}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === DiscountTypes.Promocode ? (
                      <PromocodeTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || [{}]}
                        producerId={formik.values.producer_id}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === DiscountTypes.Gift ? (
                      <GiftTiersRulesForm
                        gifts={gifts}
                        tiers_rules={initialValues.tiers_rules || [{}]}
                        producerId={formik.values.producer_id}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === DiscountTypes.Percentage ? (
                      <PercentageTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || [{}]}
                        useFormik={bind('tiers')}
                      />
                    ) : formik.values.type === DiscountTypes.HistoryQuantity ? (
                      <HistoryQtyTiersRulesForm
                        tiers_rules={initialValues.tiers_rules || [{}]}
                        producerId={formik.values.producer_id}
                        useFormik={bind('tiers')}
                      />
                    ) : null}
                  </FormComponents.FormSection>
                )}
              </SectionBody>
            </SectionContainer>
          </Wrapper>
        </StickyFooterLayout.Body>
        {formik.values?.status !== DiscountRuleStatus.Deleted && (
          <StickyFooterDefaultControls
            onRemove={onRemove}
            onSave={submit}
            onCancel={onCancel}
            saveProgress={progress}
            isSaveDisabled={!dirty || isFilesUploading || progress === Progress.WORK}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues.title })}
          />
        )}
      </StickyFooterLayout.Wrapper>
    </FormikProvider>
  )
}

export default DiscountRuleDetailsForm
