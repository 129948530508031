import { SearchInput, useAction } from '@agro-club/frontend-shared'
import useDebouncedEffect from 'hooks/useDebouncedEffect'
import IncentiveProgramActions from 'modules/domain/incentiveProgram/duck'
import IncentiveProgramSelectors from 'modules/domain/incentiveProgram/selectors'
import {
  IncentiveProgramListRequestFilter,
  IncentiveProgramScope,
  IncentiveProgramStatus,
} from 'modules/domain/incentiveProgram/types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { IncentiveCampaignSelect } from 'views/components/IncentiveCampaignSelect/IncentiveCampaignSelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { SearchInputWrapper } from 'views/pages/Storefront/Storefront/StorefrontList/styled'
import { IncentiveCampaignListHeader } from '../components/IncentiveCampaignListHeader'
import IncentiveProgramTable from './components/IncentiveProgramTable'

const IncentiveProgramList: React.VFC = () => {
  const { t } = useTranslation('incentiveProgram')
  const filterValue = useSelector(IncentiveProgramSelectors.filter)
  const filterUpdated = useAction(IncentiveProgramActions.filterUpdated)

  const [search, setSearch] = useState(filterValue.search)

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<IncentiveProgramListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleSeasonChanged = useCallback((val?: string) => handleFilterChange({ season_id: val }), [
    handleFilterChange,
  ])

  const handleCampaignChanged = useCallback((val?: string) => handleFilterChange({ campaign_id: val }), [
    handleFilterChange,
  ])

  useDebouncedEffect(() => handleFilterChange({ search }), 300, [search])

  const scopeOptions = useMemo(() => {
    return [
      {
        value: IncentiveProgramScope.FarmerOrders,
        title: t('form.labels.farmer'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: IncentiveProgramScope.DistributorOrder,
        title: t('form.labels.distributor'),
        bullet: true,
        color: 'orange' as const,
      },
    ]
  }, [t])

  const statusFilterOptions = useMemo(
    () => [
      {
        value: IncentiveProgramStatus.Active,
        title: t('form.labels.isActive'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: IncentiveProgramStatus.Inactive,
        title: t('form.labels.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
      {
        value: IncentiveProgramStatus.Deleted,
        title: t('form.labels.deleted'),
        bullet: true,
        color: 'red' as const,
      },
    ],
    [t],
  )

  return (
    <>
      <Layout.Header>
        <IncentiveCampaignListHeader tab="incentiveProgram" />
        <SearchInputWrapper data-test-id="search-wrapper">
          <SearchInput onChange={setSearch} value={search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
        <TComponents.Filters>
          <TComponents.FiltersWrapper>
            <div data-test-id={'filters-incentive-program-status'}>
              <TableFilter
                handleFilterChange={handleFilterChange}
                title={t('labels:status')}
                options={statusFilterOptions}
                filterValue={{ status: filterValue.status }}
                disableMulti
              />
            </div>
            <div data-test-id={'filters-incentive-program-scopes'}>
              <TableFilter
                handleFilterChange={handleFilterChange}
                filterValue={{ scopes: filterValue.scopes }}
                options={scopeOptions}
                title={t('form.discountFor')}
                disableMulti
              />
            </div>
            <TComponents.SelectWrapper>
              <IncentiveCampaignSelect
                label={t('form.labels.campaign')}
                placeholder={t('form.placeholders.campaign')}
                value={filterValue.campaign_id}
                onChange={handleCampaignChanged}
                isClearable
                variant="small"
              />
            </TComponents.SelectWrapper>
            <TComponents.SelectWrapper>
              <SeasonSelect
                label={t('form.labels.season')}
                placeholder={t('form.placeholders.season')}
                value={filterValue.season_id}
                onChange={handleSeasonChanged}
                isClearable
                variant="small"
              />
            </TComponents.SelectWrapper>
          </TComponents.FiltersWrapper>
        </TComponents.Filters>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper noFilters>
          <IncentiveProgramTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default IncentiveProgramList
