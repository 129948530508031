import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import BadgeTable from './BadgeList/BadgeTable'
import BadgeAdd from './BadgeList/BadgeAdd'
import BadgeEdit from './BadgeList/BadgeEdit'
import BadgeRoutes from './routes'

const BadgePage: React.FC = () => {
  return (
    <Switch>
      <Route path={BadgeRoutes.List} exact={true}>
        <BadgeTable />
      </Route>
      <Route path={BadgeRoutes.Edit} exact={true}>
        <BadgeEdit />
      </Route>
      <Route path={BadgeRoutes.Add} exact={true}>
        <BadgeAdd />
      </Route>
      <Redirect to={BadgeRoutes.List} />
    </Switch>
  )
}

export default BadgePage
