import React, { useContext } from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { Input } from '@agro-club/frontend-shared'
import { FormikContext } from 'formik'
import { CountrySelect } from '../CountrySelect/CountrySelect'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { CountryCode } from 'libphonenumber-js'
import { DEFAULT_COUNTRY } from 'modules/constants'
import { RegionsSelect } from '../RegionsSelect/RegionsSelect'

type AddressFormPropsType = {
  field: 'legal_address' | 'delivery_address' | 'physical_address'
  requiredFields?: string[]
  disabled?: boolean
  spy?: boolean
}

const AddressForm: React.FC<AddressFormPropsType> = ({
  field,
  requiredFields = [],
  disabled,
  spy,
}: AddressFormPropsType) => {
  const { t } = useTranslation(['farmer', 'common', 'validation'])

  const formik = useContext(FormikContext)

  const defaultCountry = useDetectedCountry()

  const handleChange = (addressField: string) => (e: React.ChangeEvent<HTMLInputElement>, text: string) => {
    if (spy) {
      formik.setValues({
        ...formik.values,
        [field]: {
          ...formik.values[field],
          [addressField]: text || '',
        },
        delivery_address: {
          ...formik.values.delivery_address,
          [addressField]: text || '',
        },
      })
    } else {
      formik.setFieldValue(`${field}.${addressField}`, text || '')
    }
  }

  const handleCountryChange = (value?: CountryCode) => {
    if (spy) {
      formik.setValues({
        ...formik.values,
        [field]: {
          ...formik.values[field],
          country: value,
        },
        delivery_address: {
          ...formik.values.delivery_address,
          country: value,
        },
      })
    } else {
      formik.setFieldValue(`${field}.country`, value)
    }
  }

  type Touched = { address?: boolean; city?: boolean; postal_code?: boolean; region_id?: boolean }
  type Errors = { address?: string; city?: string; postal_code?: string; region_id?: string }
  const fieldTouched: Touched = (formik.touched[field] as Touched) || {}
  const fieldErrors: Errors = (formik.errors[field] as Errors) || {}

  const handleSimpleSelectChange = (addressField: string, value?: string | number): void => {
    if (spy) {
      formik.setValues({
        ...formik.values,
        [field]: {
          ...formik.values[field],
          [addressField]: value,
        },
        delivery_address: {
          ...formik.values.delivery_address,
          [addressField]: value,
        },
      })
    } else {
      formik.setFieldValue(`${field}.${addressField}`, value)
    }
  }

  return (
    <Styled.AddressContainer>
      <Styled.Area area={'address'}>
        <Input
          data-test-id={`${field}-address`}
          {...formik.getFieldProps(`${field}.address`)}
          onChange={handleChange('address')}
          label={t('form.labels.address')}
          disabled={disabled}
          invalid={fieldTouched.address && !!fieldErrors.address}
          errorText={fieldErrors?.address}
          required={requiredFields.includes('address')}
        />
      </Styled.Area>
      <Styled.Area area={'city'}>
        <Input
          data-test-id={`${field}-city`}
          {...formik.getFieldProps(`${field}.city`)}
          onChange={handleChange('city')}
          label={t('form.labels.city')}
          disabled={disabled}
          invalid={fieldTouched.city && !!fieldErrors.city}
          errorText={fieldErrors?.city}
          required={requiredFields.includes('city')}
        />
      </Styled.Area>
      <Styled.Area area={'province'}>
        <RegionsSelect
          testId={`${field}-province-select`}
          {...formik.getFieldProps(`${field}.region_id`)}
          onChange={value => handleSimpleSelectChange('region_id', value)}
          label={t('form.labels.province')}
          invalid={fieldTouched.region_id && !!fieldErrors.region_id}
          errorText={fieldErrors?.region_id}
          placeholder={t('form.placeholders.province')}
          isDisabled={disabled}
          required={requiredFields.includes('province')}
          country={formik.values[field]?.country || defaultCountry}
          isClearable
        />
      </Styled.Area>
      <Styled.Area area={'country'}>
        <CountrySelect
          label={t('form.labels.country')}
          value={formik.getFieldProps(`${field}.country`).value || defaultCountry || DEFAULT_COUNTRY}
          name={`${field}.country`}
          onChange={handleCountryChange}
          isDisabled={disabled}
          required={requiredFields.includes('country')}
        />
      </Styled.Area>
      <Styled.Area area={'zip'}>
        <Input
          data-test-id={`${field}-postal-code`}
          {...formik.getFieldProps(`${field}.postal_code`)}
          label={t('form.labels.zip')}
          onChange={handleChange('postal_code')}
          disabled={disabled}
          invalid={fieldTouched.postal_code && !!fieldErrors.postal_code}
          errorText={fieldErrors?.postal_code}
          required={requiredFields.includes('postal_code')}
        />
      </Styled.Area>
    </Styled.AddressContainer>
  )
}

export default AddressForm
