import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Progress } from 'modules/types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'
import {
  Territory,
  TerritoryDTO,
  TerritoryListRequestFilter,
  TerritoryListRequestSorting,
  TerritoryState,
} from './types'

const initialState: TerritoryState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  listFetchErrorDetail: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  addErrorDetail: null,
  updateErrorDetail: null,
  removeErrorDetail: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class TerritoryReducer extends BasicEntityImmerReducer<
  Territory,
  TerritoryListRequestFilter,
  TerritoryListRequestSorting,
  TerritoryDTO,
  TerritoryDTO,
  TerritoryState
> {}

export const TerritoryActions = createActionCreators(TerritoryReducer)
export default TerritoryActions
export const reducer = createReducerFunction(TerritoryReducer, initialState)
