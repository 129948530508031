import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SimpleSelect, TagList, CustomSelectStyles } from '@agro-club/frontend-shared'

import { Product } from 'modules/domain/product/types'
import useLangPicker from 'hooks/useLangPicker'

type Props = {
  products: Product[]
  onChange: (id: string, product?: Product) => void
  label?: string
  placeholder?: string
  onMenuClose?: () => void
  invalid?: boolean
  errorText?: string
  customStyles?: CustomSelectStyles
  values?: string[]
  onRemove?: (id: string) => void
  isDisabled?: boolean
  name?: string
  isClearable?: boolean
  required?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TagListWrapper = styled.div`
  margin-top: 16px;
`

const TagListStyled = styled(TagList)`
  display: flex;
  flex-direction: row;
`

const ProductNoFetchMultiSelect: React.FC<Props> = ({
  products,
  onChange,
  label,
  placeholder,
  onMenuClose,
  invalid,
  errorText,
  customStyles,
  values,
  onRemove,
  isDisabled,
  name,
  isClearable,
  required,
}) => {
  const { t } = useTranslation('ProductSelect')
  const { pick } = useLangPicker()

  const getProductNameWithSubCategory = useCallback((title: string, subcategory?: string) => {
    if (!subcategory) {
      return title
    } else {
      return `${subcategory} / ${title}`
    }
  }, [])

  const selectOptions = useMemo(() => {
    return products.map(item => ({
      id: item.id,
      title: getProductNameWithSubCategory(pick(item.title_i18n), pick(item.subcategory?.title_i18n)),
    }))
  }, [products, pick, getProductNameWithSubCategory])

  const productTags = useMemo(() => (values ? selectOptions.filter(opt => values.includes(opt.id)) : []), [
    selectOptions,
    values,
  ])

  const remainingOptions = useMemo(
    () => (values ? selectOptions.filter(opt => !values.includes(opt.id)) : selectOptions),
    [selectOptions, values],
  )

  const handleChange = useCallback(
    value => {
      const product = products.find(item => item.id === value)
      onChange(value, product)
    },
    [onChange, products],
  )

  return (
    <Wrapper>
      <SimpleSelect
        onChange={handleChange}
        customStyles={customStyles}
        label={label}
        placeholder={placeholder || t('placeholder')}
        options={remainingOptions}
        onMenuClose={onMenuClose}
        isSearchable
        isDisabled={isDisabled}
        invalid={invalid}
        errorText={errorText}
        aria-label={name}
        isClearable={isClearable}
        required={required}
      />
      <TagListWrapper>
        <TagListStyled list={productTags} onRemove={tag => onRemove && onRemove(tag.id)} />
      </TagListWrapper>
    </Wrapper>
  )
}

export default ProductNoFetchMultiSelect
