import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'

import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'
import InventoryDetailsForm, {
  InventoryFormFields,
} from 'views/pages/InventoryExchange/InventoryDetailsForm/InventoryDetailsForm'
import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import InventoryExchangeTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'
import { RequestSource } from 'modules/domain/inventoryExchangeTransaction/types'

const InventoryAdd: React.FC = () => {
  const { t } = useTranslation('inventoryTransaction')
  const submitAction = useAction(InventoryExchangeTransactionActions.addRequested)
  const page = useSelector(InventoryExchangeSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: InventoryExchangeRoutes.Total })
  }

  const handleFormSubmit = (props: InventoryFormFields) => {
    submitAction(
      {
        retailer_id: props.retailer_id,
        sku_id: props.sku_id,
        quantity: props.quantity,
        comment: props.comment,
      },
      RequestSource.DETAILS_FORM,
    )
  }

  useHelmet({ title: t('addInventoryTransactionMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <InventoryDetailsForm mode={'create'} onSubmit={handleFormSubmit} onCancel={goBack} initialValues={{}} />
      </Layout.Content>
    </>
  )
}

export default InventoryAdd
