import React from 'react'
import { useSelector } from 'react-redux'
import TerritorySelectors from 'modules/domain/territory/selectors'
import TerritoryActions from 'modules/domain/territory/duck'
import * as Header from 'views/ui/Header/Header'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import TerritoryDetailsForm, { FormProps } from 'views/pages/Territory/TerritoryDetailsForm/TerritoryDetailsForm'
import { useTranslation } from 'react-i18next'
import TerritoryRoutes from 'views/pages/Territory/routes'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'

const TerritoryAdd: React.FC<{ initialState?: FormProps; onUpdateInitialState(props: FormProps): void }> = ({
  initialState,
  onUpdateInitialState,
}) => {
  const { t } = useTranslation('territory')
  const push = useHistoryPush()
  const addAction = useAction(TerritoryActions.addRequested)
  const addProgress = useSelector(TerritorySelectors.addProgress)
  const page = useSelector(TerritorySelectors.page)

  const handleSubmit = (form: Required<FormProps>, options: { duplicate: boolean }) => {
    onUpdateInitialState(form)
    addAction(form, options.duplicate)
  }

  const goBack = () => {
    push({ route: TerritoryRoutes.List, query: { page } })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TerritoryDetailsForm
          initialValues={initialState}
          progress={addProgress}
          mode={'create'}
          onSubmit={handleSubmit}
          onCancel={goBack}
        />
      </Layout.Content>
    </>
  )
}

export default TerritoryAdd
