import React from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { FormFields } from 'views/pages/Farmer/FarmerDetailsForm/types'
import AddressForm from 'views/components/AddressForm/AddressForm'
import { useFormikContext } from 'formik'
import { RegionsSelect } from '../RegionsSelect/RegionsSelect'

type AddressFormPropsType = {
  field: 'legal_address' | 'delivery_address'
  disabled?: boolean
  spy?: boolean
}

const FarmerAddressForm: React.FC<AddressFormPropsType> = ({ field, disabled, spy }: AddressFormPropsType) => {
  const { t } = useTranslation(['farmer', 'common', 'validation'])
  const formik = useFormikContext<FormFields>()
  const defaultCountry = useDetectedCountry()
  const handleRetailerRegionsChange = (id?: string | number): void => {
    formik.setFieldValue('retailer_regions_ids', id ? [id] : [])
  }

  return (
    <>
      <AddressForm
        field={field}
        spy={spy}
        disabled={disabled}
        requiredFields={['address', 'city', 'province', 'postal_code']}
      />
      {field === 'legal_address' && (
        <Styled.AddressContainer>
          <Styled.Area>
            <RegionsSelect
              country={formik.values[field]?.country || defaultCountry}
              value={formik.getFieldProps('retailer_regions_ids').value?.[0] || ''}
              name={`${field}.retailer_regions_ids`}
              onChange={id => handleRetailerRegionsChange(id)}
              label={t('form.labels.orderProvinceAndState')}
              placeholder={t('form.placeholders.orderProvince')}
              isDisabled={disabled}
              isClearable
            />
          </Styled.Area>
        </Styled.AddressContainer>
      )}
    </>
  )
}

export default FarmerAddressForm
