import { FormComponents, CheckboxSelect, Tooltip, Button } from '@agro-club/frontend-shared'
import styled from 'styled-components'

export const ContentBody = styled(FormComponents.FormContentBody)`
  max-width: 725px;
`

export const SearchInputWrapper = styled.div`
  width: 50%;
  padding: 18px 24px;
`

export const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

export const Grid2Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export const Grid3Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

export const SkuListSelect = styled(CheckboxSelect)`
  [class^='Placeholder'] {
    top: 6px;
  }
`

export const PreviewBlock = styled.div``

export const SelectedSkusTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
`

export const SelectedSku = styled.div`
  font-size: 14px;
  line-height: 20px;
`

export const OptionsTooltip = styled(Tooltip)`
  display: inline;
  & > div {
    width: 200px;
  }
`

export const Options = styled.span`
  padding-left: 8px;
  cursor: pointer;
  color: ${props => props.theme.color.primary500};
`

export const SkuSelectWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 16px;
  align-items: end;
`

export const AddSkuButton = styled(Button)`
  position: absolute;
  top: 42px;
  right: 0;
`
