import React, { useMemo, useCallback, useState } from 'react'
import { Progress } from 'modules/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useTranslation } from 'react-i18next'
import {
  Button,
  helpersObject,
  SectionBody,
  SectionContainer,
  FormComponents,
  Checkbox,
  Switch,
  Input,
} from '@agro-club/frontend-shared'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { createLocalizedValue } from 'helpers/localization'
import { AvailableLanguages, LocalizedValue, availableLanguagesList, Status } from 'types/entities'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import * as Styled from './styled'
import { PackageTypesDTO } from 'modules/domain/packageTypes/types'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 200px;
`

const StorefrontPackageTypesDetailsForm: React.FC<{
  mode: 'edit' | 'create'
  initialValues?: PackageTypesDTO
  onRemove?(): void
  onSubmit(value: PackageTypesDTO, options?: { dirty?: boolean }): void
  onCancel(): void
  progress?: Progress
  errorText?: string
  removeProgress?: Progress
}> = ({ mode, progress, errorText, removeProgress, onRemove, onSubmit, onCancel, initialValues }) => {
  const { t } = useTranslation(['packageTypes', 'common', 'validation'])

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) => [initialValues?.title_i18n?.[lang]].some(Boolean)
    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })
    if (!result.length) {
      result.push('en')
    }
    return result
  })

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const validationSchema = useMemo(() => {
    const i18nValue = Yup.object(
      createLocalizedValue(lang =>
        lang === 'en' ? Yup.string().required(t('validation:field_required')) : Yup.string(),
      ),
    )

    return Yup.object({
      titleI18n: i18nValue,
      status: Yup.string()
        .required(t('validation:field_required'))
        .oneOf(Object.values(Status)),
      conversionRate: Yup.string().required(t('validation:field_required')),
    })
  }, [t])

  const formik = useFormik({
    initialValues: {
      titleI18n: (initialValues?.title_i18n as LocalizedValue) || '',
      status: initialValues?.status || Status.Active,
      conversionRate: initialValues?.conversion_rate || 1,
      baseUnit: initialValues?.base_unit ? initialValues.base_unit : mode === 'create' ? 'kg' : '',
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleSubmit = useCallback(() => {
    try {
      formik.submitForm()
      if (!formik.isValid) return

      onSubmit(
        helpersObject.nonEmptyFields({
          title_i18n: formik.values.titleI18n,
          status: formik.values.status,
          conversion_rate: formik.values.conversionRate,
          base_unit: formik.values.baseUnit,
        }),
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }, [formik, onSubmit])

  const isEditWarning = progress === Progress.ERROR && errorText
  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <Styled.MainFormContainer data-test-id="package-types-form-container">
              <FormComponents.FormSection title={t('form.title')}>
                <Styled.LabeledContainerStyled label={t('fields.title')}>
                  <Styled.ChooseLanguageBlock>
                    {availableLanguagesList.map((lang, idx: number) => (
                      <Checkbox
                        label={t(`common:langNames.${lang}`)}
                        isChecked={isLangActive(lang)}
                        value={lang}
                        onChange={handleLangChange}
                        key={lang}
                        testId={`package-types-lang-${idx}`}
                      />
                    ))}
                  </Styled.ChooseLanguageBlock>
                  {!languages.length && <Styled.ErrorStyled>{t('validation:language_required')}</Styled.ErrorStyled>}
                </Styled.LabeledContainerStyled>
                <Styled.VerticalFormsContainer>
                  {availableLanguagesList.map(lang => {
                    if (isLangActive(lang)) {
                      const title = formik.getFieldProps(`titleI18n[${lang}]`)
                      const meta = formik.getFieldMeta(`titleI18n[${lang}]`)
                      return (
                        <Input
                          data-test-id={`package-type-title-${lang}`}
                          {...formik.getFieldProps(title)}
                          key={lang}
                          invalid={meta.touched && !!meta.error}
                          errorText={meta.error}
                          label={t(`common:langNames.${lang}`)}
                          required={lang === 'en'}
                        />
                      )
                    }
                    return null
                  })}
                </Styled.VerticalFormsContainer>
                <StyledInput
                  label={t('packageTypes:form.conversionRate')}
                  {...formik.getFieldProps('conversionRate')}
                  invalid={formik.touched.conversionRate && !!formik.errors.conversionRate}
                  errorText={formik.errors.conversionRate}
                  type={'number'}
                  required
                />
                <StyledInput
                  label={t('packageTypes:form.baseUnit')}
                  {...formik.getFieldProps('baseUnit')}
                  invalid={formik.touched.baseUnit && !!formik.errors.baseUnit}
                  errorText={formik.errors.baseUnit}
                />
                <Switch
                  label={t('form.status')}
                  on={formik.values.status === Status.Active}
                  onClick={value => {
                    formik.setFieldValue('status', !!value ? Status.Active : Status.Inactive)
                  }}
                >
                  {formik.values.status === Status.Active ? t('form.active') : t('form.inactive')}
                </Switch>
              </FormComponents.FormSection>
            </Styled.MainFormContainer>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          onClick={handleSubmit}
          intent={'primary'}
          filled
          progress={progress}
          disabled={!formik.dirty || progress === Progress.WORK}
          data-test-id={'save-button'}
        >
          {t('common:save')}
        </Button>
        <Button onClick={onCancel} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues?.title_i18n || '' })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
      {isEditWarning ? (
        <StickyFooterLayout.NotificationWarning>
          <div>{errorText}</div>
        </StickyFooterLayout.NotificationWarning>
      ) : null}
    </StickyFooterLayout.Wrapper>
  )
}

export default StorefrontPackageTypesDetailsForm
