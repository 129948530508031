import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import FarmerList from './FarmerList/FarmerList'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Routes from './routes'
import FarmerActions from 'modules/domain/farmer/duck'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { useSelector } from 'react-redux'
import HouseholdsRoutes from 'views/pages/Household/routes'
import FarmerRoutes from './routes'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const FarmerTable = () => {
  const push = useHistoryPush()
  const filter = useSelector(FarmerSelectors.filter)
  const filterUpdated = useAction(FarmerActions.filterUpdated)
  const { t } = useTranslation(['farmer', 'household'])

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  const metaTitle = t('farmersMetaTitle')

  useHelmet({ title: metaTitle })

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Tab title={t('list.heading')} isActive onClick={() => push({ route: FarmerRoutes.List })}>
            <AddButton to={Routes.Add} />
          </Header.Tab>
          <Header.Tab title={t('household:list.heading')} onClick={() => push({ route: HouseholdsRoutes.List })}>
            <AddButton to={HouseholdsRoutes.Add} />
          </Header.Tab>
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput onChange={handleSearchChange} value={filter.search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <FarmerList />
      </Layout.Content>
    </>
  )
}

export default FarmerTable
