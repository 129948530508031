import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import {
  NotificationListDTO,
  NotificationsListEntry,
  NotificationsListRequestFilter,
  NotificationsListRequestSorting,
} from './types'

export default {
  ...createBasicManagers<
    NotificationsListRequestFilter,
    NotificationsListRequestSorting,
    NotificationsListEntry,
    NotificationListDTO,
    NotificationListDTO
  >(endpoints.notificationsList),
  notificationResend: async (id: string, address_to: string) => {
    return apiClient.post<NotificationsListEntry>(endpoints.notificationResend(), { id, address_to })
  },
} as const
