import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import * as Styled from 'views/pages/Farmer/FarmerList/styles'
import CountryFilter from 'views/components/TableFilters/CountryFilter'
import { Filter, TableFilter } from 'views/components/TableFilters/TableFilters'
import { Farmer, FarmerListRequestFilter, PaperAgreementStatus } from 'modules/domain/farmer/types'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import FarmerActions from 'modules/domain/farmer/duck'
import { useFarmerList } from 'modules/domain/farmer/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Status } from 'types/entities'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import {
  StatusIcon,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  Button,
} from '@agro-club/frontend-shared'
import { HouseholdSelect } from 'views/components/HouseholdSelect/HouseholdSelect'
import styled from 'styled-components'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import FarmStatusSelect from 'views/components/FarmStatusSelect/FarmStatusSelect'

const StyledFilter = styled(Filter)`
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const NameCell: React.FC<CellProps<Farmer>> = ({ cell, column, row }) => {
  return (
    <Styled.NameCell key={column.id}>
      <Styled.Status status={row.values.status} />
      {`${cell.value || ''} ${row.values.last_name || ''}`}
    </Styled.NameCell>
  )
}

const CountryCellInner = styled.div`
  display: flex;
  align-items: center;
`

const CountryCell: React.FC<CellProps<Farmer>> = ({ cell, column }) => {
  const { t } = useTranslation('common')
  return cell.value ? (
    <CountryCellInner key={column.id}>
      <TComponents.Flag code={cell.value} />
      {t(`country.full.${cell.value}`)}
    </CountryCellInner>
  ) : null
}

const NameColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('farmer')
  return (
    <div>
      <StatusIcon />
      <span>{t('list.tableHeaders.firstNameLastName')}</span>
    </div>
  )
}

const FarmersSortableHeadCell = AdvancedHeadCell<keyof Farmer>()

const FarmerList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['farmer', 'labels'])
  const [progress, data] = useFarmerList()
  const filterUpdated = useAction(FarmerActions.filterUpdated)
  const sortingUpdated = useAction(FarmerActions.sortingUpdated)
  const listRequested = useAction(FarmerActions.listRequested)
  const filterValue = useSelector(FarmerSelectors.filter)
  const total = useSelector(FarmerSelectors.total)
  const pages = useSelector(FarmerSelectors.pages)
  const page = useSelector(FarmerSelectors.page)
  const pageSize = useSelector(FarmerSelectors.pageSize)
  const { status, country, paper_agreement, household_id, farm_status } = filterValue
  const { sort_field, sort_reversed } = useSelector(FarmerSelectors.sorting)

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: NameColumnHeaderCell,
        accessor: 'first_name' as const,
        Cell: NameCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.phone'),
        accessor: 'phone_number' as const,
      },
      {
        Header: t('list.tableHeaders.email'),
        accessor: 'email' as const,
      },
      {
        Header: t('list.tableHeaders.country'),
        accessor: 'country' as const,
        Cell: CountryCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.farmName'),
        accessor: 'farm_name' as const,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'prefix' as const,
      },
      {
        Header: t('list.tableHeaders.household'),
        accessor: 'household_name' as const,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof Farmer)[] = useMemo(() => ['last_name', 'status'], [])

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<Farmer>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Farmer[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<FarmerListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const statusFilterOptions = useMemo(
    () => [
      {
        value: Status.Active,
        title: t('status.active'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: Status.Inactive,
        title: t('status.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
      {
        value: Status.Deleted,
        title: t('status.deleted'),
        bullet: true,
        color: 'red' as const,
      },
    ],
    [t],
  )

  const agreementStatusFilterOptions = useMemo(
    () => [
      {
        value: PaperAgreementStatus.Sent,
        title: t('paperAgreementStatus.sent'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: PaperAgreementStatus.Needed,
        title: t('paperAgreementStatus.needed'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('farmerList')
  const [progressCSV, downloadCSV] = useDownload(endpoints.farmers('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const isFilterApplied = Object.values(filterValue).some(Boolean)
  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TComponents.FiltersWrapper>
          <CountryFilter filterValue={{ country }} handleFilterChange={handleFilterChange} />
          <TableFilter
            handleFilterChange={handleFilterChange}
            title={t('labels:status')}
            options={statusFilterOptions}
            filterValue={{ status }}
            disableMulti
          />
          <TableFilter
            handleFilterChange={handleFilterChange}
            title={t('form.labels.paperAgreement')}
            options={agreementStatusFilterOptions}
            filterValue={{ paper_agreement }}
            disableMulti
          />
          <StyledFilter title={t('labels:households')}>
            <HouseholdSelect
              isClearable
              value={household_id}
              onChange={val => handleFilterChange({ household_id: val })}
              placeholder={t('status.any')}
            />
          </StyledFilter>
          <StyledFilter title={t('form.labels.farmStatusSelect')}>
            <FarmStatusSelect
              isClearable
              value={farm_status}
              onChange={(val: string) => handleFilterChange({ farm_status: val })}
              placeholder={t('status.any')}
            />
          </StyledFilter>
        </TComponents.FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <FarmersSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof Farmer}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </FarmersSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default FarmerList
