import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { PackageTypes, PackageTypesListRequestSorting, PackageTypesListRequestFilter, PackageTypesDTO } from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: PackageTypesListRequestFilter,
  sorting: PackageTypesListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<PackageTypes>>(endpoints.packageTypes(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getListAll = () => {
  return apiClient.get<ListResponse<PackageTypes>>(endpoints.packageTypes(), {
    page: 1,
    page_size: 1000,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<PackageTypes>(endpoints.packageTypes(id))
}

export const addItem = (dto: PackageTypesDTO) => {
  return apiClient.post<PackageTypes>(endpoints.packageTypes(), dto)
}

export const updateItem = (id: string, dto: Partial<PackageTypesDTO>) => {
  return apiClient.put<PackageTypes>(endpoints.packageTypes(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<PackageTypes>(endpoints.packageTypes(id))
}
