import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import InventoryInTransferRequestActions from './duck'
import InventoryInTransferRequestSelectors from './selectors'
import { useAction, usePageQuery } from '@agro-club/frontend-shared'
import { InventoryTransferRequest } from './types'
import { useEffect } from 'react'
import AuthSelectors from '../auth/selectors'

export const useInventoryInTransferRequestList: ResourceHook<InventoryTransferRequest[], void[]> = () => {
  const progress = useSelector(InventoryInTransferRequestSelectors.listFetchProgress)
  const list = useSelector(InventoryInTransferRequestSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(InventoryInTransferRequestActions.listRequested, page ? { page } : {})
  const filterUpdated = useAction(InventoryInTransferRequestActions.filterUpdated)
  const filterValue = useSelector(InventoryInTransferRequestSelectors.filter)
  const userCompany = useSelector(AuthSelectors.userCompany)
  useEffect(() => {
    const companyIds = userCompany ? [userCompany.id] : undefined
    if (!companyIds) return
    // retailer_to means after request was approved transfer items to this retailer
    filterUpdated({ ...filterValue, ...{ retailer_from: companyIds } })
    fetchAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdated, userCompany])

  return [progress, list]
}

export const useInventoryAllTransferRequestList: ResourceHook<InventoryTransferRequest[], void[]> = () => {
  const progress = useSelector(InventoryInTransferRequestSelectors.listFetchProgress)
  const list = useSelector(InventoryInTransferRequestSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(InventoryInTransferRequestActions.listRequested, page ? { page } : {})
  useEffect(() => {
    fetchAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [progress, list]
}
