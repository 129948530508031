import { FormComponents, Progress, SectionBody, SectionContainer, SectionTitle } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { useCompanyById } from 'modules/domain/company/hooks'
import { useCalculatedDiscounts } from 'modules/domain/incentiveProgram/hooks'
import {
  IncentiveProgramScope,
  PercentageTierRule,
  PromocodeTierRule,
  QtyTierRule,
  SkuCalculatedDiscountDTO,
  SkuCalculateDiscountsRequest,
  SkuCalculatedTier,
  SkuOrderEntry,
} from 'modules/domain/incentiveProgram/types'
import { arrToDict, getPrettyPrice } from 'modules/utils/helpers'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Currency, SkuProductEntry } from 'types/entities'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { isMonetaryTier } from './helpers'
import { useSkuTotalSum, UseTotalSumProps } from './hooks/useSkuTotalSum'
import { OrderSkuMonetaryDiscount } from './OrderSkuMonetaryDiscount/OrderSkuMonetaryDiscount'
import { OrderSkuPromocodes, OrderSkuPromocodesProps } from './OrderSkuPromocodes/OrderSkuPromocodes'

const DiscountsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled(SectionContainer)<{ isAllowed: boolean }>`
  transition: 0.3s all;
  position: relative;
  ${(props: { isAllowed: boolean }) =>
    props.isAllowed
      ? ''
      : css`
          opacity: 0.8;
          cursor: not-allowed;
        `}
`

const Content = styled.div<{ isAllowed: boolean }>`
  pointer-events: ${(props: { isAllowed: boolean }) => (props.isAllowed ? 'auto' : 'none')};
`

const LearnMore = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin-bottom: 12px;
`

export const Total = styled.div`
  text-align: right;
  width: 100%;
`

export const TotalValue = styled.span`
  font-weight: bold;
`

export type OrderSkuDiscountsProps = {
  entries: SkuProductEntry[]
  sellerId?: string | null
  seasonId?: string | null
  ownerId?: string | null
  scope: IncentiveProgramScope
  isPromocodeDisabled?: boolean
  orderDate?: string
} & Partial<
  Pick<OrderSkuPromocodesProps, 'onPromocodesChange' | 'onSelectPromocode' | 'selectedPromocode' | 'promocodes'>
> &
  UseTotalSumProps

const productEntryToOrderEntry = (productEntry: SkuProductEntry): SkuOrderEntry => ({
  sku_id: productEntry.sku_id || '',
  quantity: productEntry.quantity,
  sku: productEntry.sku,
})

type MappedDiscount = SkuCalculatedDiscountDTO & {
  monetaryTiers: SkuCalculatedTier<QtyTierRule | PercentageTierRule | PromocodeTierRule>[]
  productTitles?: string
}

export const OrderSkuDiscounts: React.VFC<OrderSkuDiscountsProps> = ({
  entries,
  sellerId,
  seasonId,
  ownerId,
  scope,
  onActualTotalNetChange,
  onTotalSumChange,
  onPromocodesChange,
  onSelectPromocode,
  promocodes = [],
  selectedPromocode,
  isPromocodeDisabled,
  orderDate,
}) => {
  const { t } = useTranslation('discount')
  const { pick } = useLangPicker()

  const filteredEntries = useMemo(() => {
    return entries
      .filter(item => !!item.sku_id)
      .map(entry => {
        return { ...entry, price: entry.sku?.price, product_id: entry.sku?.params.product_id || '' }
      })
  }, [entries])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const promocodesForCalc = useMemo(() => promocodes.map(item => item.code), [promocodes.length])

  const entryDict = useMemo(() => arrToDict(filteredEntries, 'sku_id'), [filteredEntries])
  const calcRequest: SkuCalculateDiscountsRequest = useMemo(
    () => ({
      entries: filteredEntries.map(productEntryToOrderEntry),
      seller_id: sellerId,
      season_id: seasonId,
      scopes: [scope],
      promocodes: promocodesForCalc,
      at_date: orderDate,
      owner_id: ownerId || null,
    }),
    [filteredEntries, sellerId, seasonId, scope, promocodesForCalc, orderDate, ownerId],
  )

  const [progress, calcResponse] = useCalculatedDiscounts(calcRequest)

  const [sellerProgress, seller] = useCompanyById(sellerId || undefined)

  useSkuTotalSum({
    entries: filteredEntries,
    seller,
    calcResponse,
    onActualTotalNetChange,
    onTotalSumChange,
  })

  const currency = seller?.currency || Currency.CAD
  const total = calcResponse?.total
  const loading = progress === Progress.WORK || sellerProgress === Progress.WORK

  const [mappedDiscounts, setMappedDiscounts] = useState<MappedDiscount[]>([])

  useEffect(() => {
    if (!filteredEntries.length && mappedDiscounts.length) {
      setMappedDiscounts([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEntries])

  useEffect(() => {
    if (!calcResponse || !filteredEntries.length) return

    const mappedDiscountsTemp: typeof mappedDiscounts = []

    calcResponse.discounts.forEach(item => {
      const tiersTemp: MappedDiscount['monetaryTiers'] = []
      const titles = item.program?.sku_ids
        .filter(id => entryDict[id])
        .map(id => {
          const sku = entryDict[id]?.sku
          return sku?.product?.title
        })

      const productTitles = [...new Set(titles)].join(', ')

      item.tiers.forEach(tier => {
        if (isMonetaryTier(tier)) {
          tiersTemp.push(tier)
        }
      })
      mappedDiscountsTemp.push({ ...item, monetaryTiers: tiersTemp, productTitles })
    })

    setMappedDiscounts(mappedDiscountsTemp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcResponse])

  return (
    <>
      {mappedDiscounts.length ? (
        <Container isAllowed={true}>
          <Content isAllowed={true}>
            <SectionTitle>{t('discounts')}</SectionTitle>
            <SectionBody data-test-id={'order-discounts-container'}>
              {loading ? (
                <SpinnerLayout />
              ) : (
                <>
                  {mappedDiscounts.map((discount, idx) => {
                    const linkUrl = pick(discount.program?.link_url_i18n)
                    const linkTitle = pick(discount.program?.link_label_i18n)
                    const singular = pick(discount.program?.units?.singular_i18n) || t('discount:bag')
                    const plural = pick(discount.program?.units?.plural_i18n) || t('discount:bags')

                    return (
                      <FormComponents.FormSection
                        key={discount.program?.id ?? idx}
                        title={discount.program?.title ?? ''}
                      >
                        <DiscountsSectionWrapper>
                          <OrderSkuMonetaryDiscount
                            title={discount.productTitles}
                            tiers={discount.monetaryTiers}
                            currency={currency}
                            singular={singular}
                            plural={plural}
                          />
                          {linkUrl && linkTitle && (
                            <LearnMore href={linkUrl} target={'blank'}>
                              {linkTitle || t('common:learnMore')}
                            </LearnMore>
                          )}
                        </DiscountsSectionWrapper>
                      </FormComponents.FormSection>
                    )
                  })}
                  {total && (
                    <Total>
                      {t('totalSavings')}: <TotalValue>{getPrettyPrice(parseFloat(total), currency)}</TotalValue>
                    </Total>
                  )}
                </>
              )}
            </SectionBody>
          </Content>
        </Container>
      ) : null}
      {!!(onSelectPromocode && onPromocodesChange) && (
        <Container isAllowed>
          <Content isAllowed>
            <OrderSkuPromocodes
              promocodes={promocodes}
              onPromocodesChange={onPromocodesChange}
              onSelectPromocode={onSelectPromocode}
              companyId={sellerId}
              selectedPromocode={selectedPromocode}
              disabled={isPromocodeDisabled}
            />
          </Content>
        </Container>
      )}
    </>
  )
}
