import React, { useCallback, useEffect } from 'react'
import UserDetailsForm, { FormData } from './UserDetailsForm/UserDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useUser } from 'modules/domain/user/hooks'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import UserSelectors from 'modules/domain/user/selectors'
import UserActions from 'modules/domain/user/duck'
import Routes from './routes'
import { CompanyType, ROLES } from 'types/entities'
import AuthActions from 'modules/domain/auth/duck'
import { useTranslation } from 'react-i18next'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const UserId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const UserEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const updateUserAction = useAction(UserActions.updateRequested)
  const resetPasswordAction = useAction(AuthActions.resetPasswordRequested)
  const page = useSelector(UserSelectors.page)

  const { t } = useTranslation('user')
  const resetPasswordRequestCleanAction = useAction(AuthActions.resetPasswordRequestClean)

  useEffect(() => {
    return () => {
      resetPasswordRequestCleanAction()
    }
  }, [resetPasswordRequestCleanAction])

  const handleSubmit = useCallback(
    (data: FormData) => {
      updateUserAction(params.id, {
        password: data.password || undefined,
        role: data.role,
        phone_number: data.phone,
        status: data.status,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        second_name: data.middleName,
        canterra_id: data.canterraId,
        country: data.countryCode,
        regions_ids: data.regions ? data.regions.map(item => item.id) : [],
        company_id:
          data.companyType === CompanyType.Producer
            ? data.company
            : data.companyType === CompanyType.Distributor
            ? data.distributor
            : null,
        // TODO: add this fields 👇🏼
        // id,
        // company_type,
        related_companies: data.relatedCompanies,
        farm_name: data.farmName,
        self_pickup: data.selfPickup,
        partnership: data.partnership,
        legal_address: data.legal_address,
        delivery_address: data.delivery_address,
        territories_ids: data.territories,
        comment: data.comment,
        timezone: data.timezone,
      })
    },
    [params.id, updateUserAction],
  )

  const [fetchProgress, user] = useUser(params.id)
  const meta = useSelector(state => UserSelectors.meta(state, params.id))

  const handleResetPassword = useCallback(() => {
    if (user && user.email) {
      resetPasswordAction({ email: user.email })
    }
  }, [resetPasswordAction, user])

  const goBack = () => push({ route: Routes.List, query: { page } })

  useHelmet({ title: t('usersMetaTitle') })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !user) {
    return loading()
  }

  const name = [user.first_name, user.last_name, user.second_name].filter(Boolean).join(' ')

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={name} />
          <UserId>{`ID ${user.id}`}</UserId>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserDetailsForm
          onCancel={goBack}
          mode={'edit'}
          error={meta.updateError}
          progress={meta.updateProgress}
          isEditableUserRoleAdmin={user.role === ROLES.AGRO_ADMIN}
          initialValues={{
            companyType: user.company_type,
            company: user.company_type === CompanyType.Producer ? user.company_id : undefined,
            distributor: user.company_type === CompanyType.Distributor ? user.company_id : undefined,
            relatedCompanies:
              user.company_type === CompanyType.Producer || user.company_type === CompanyType.Distributor
                ? user.related_companies
                : [],
            status: user.status,
            lastName: user.last_name || '',
            middleName: user.second_name || '',
            firstName: user.first_name || '',
            email: user.email || '',
            role: user.role,
            phone: user.phone_number || '',
            countryCode: user.country,
            regions: user.regions || [],
            regions_ids: user.regions_ids || [],
            canterraId: user.canterra_id,
            farmName: user.farm_name,
            selfPickup: user.self_pickup,
            partnership: user.partnership,
            legal_address: user.legal_address,
            delivery_address: user.delivery_address,
            territories: user.territories?.map(terr => terr.id) || [],
            comment: user.comment || '',
            timezone: user.timezone,
          }}
          onSubmit={handleSubmit}
          onResetPassword={handleResetPassword}
        />
      </Layout.Content>
    </>
  )
}

export default UserEdit
