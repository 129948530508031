import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { Progress } from 'modules/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'

import InventoryExchange from 'views/pages/InventoryExchange/routes'
import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import { useInventoryItem } from 'modules/domain/inventoryExchange/hooks'
import InventoryTotalDetailsForm from './InventoryTotalDetailsForm'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isDistributor } from 'types/entities'
import { InventoryItem } from 'modules/domain/inventoryExchange/types'
import InventoryExchangeActions from 'modules/domain/inventoryExchange/duck'
import { InventoryFormProps } from './InventoryTotalDetailsForm'
import InventoryTransactionActions from 'modules/domain/inventoryExchange/duck'
import { generatePath } from 'modules/utils/helpers/generatePath'
import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`
const ButtonContainer = styled.div`
  margin-left: 30px;
  align-items: center;
`
const useEditPermission = (item?: InventoryItem) => {
  const profile = useSelector(AuthSelectors.profile)
  let hasEditPermission = false
  if (isDistributor(profile?.role) && item?.retailer_id === profile?.company_id) {
    hasEditPermission = true
  }
  return hasEditPermission
}

const InventoryTotalDetails: React.FC = () => {
  const { t } = useTranslation('inventorySKU')
  const page = useSelector(InventoryExchangeSelectors.page)
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const meta = useSelector(state => InventoryExchangeSelectors.meta(state, params.id))
  const itemUpdateAction = useAction(InventoryExchangeActions.updateRequested)

  const [fetchProgress, item] = useInventoryItem(params.id)
  const hasEditPermission = useEditPermission(item)
  const handleFormSubmit = (dto: InventoryFormProps) => {
    itemUpdateAction(item?.id, item?.retailer_id, item?.sku_id, dto)
  }

  useHelmet({ title: t('detailsPageHeader') })

  const goBack = () => {
    push({ query: { page }, route: InventoryExchange.Total })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const transactionFilterUpdated = useAction(InventoryTransactionActions.filterUpdated)
  const history = useHistory()

  const goToTransactions = () => {
    transactionFilterUpdated({ retailer_id: item?.retailer_id, product_id: item?.sku?.product?.id })
    history.push(generatePath(InventoryExchangeRoutes.TransactionsList))
  }

  const onRemove = useAction(InventoryExchangeActions.removeRequested, params.id)

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !item) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <div style={{ display: 'flex' }}>
            <div>
              <Header.Title size={'small'} compact={true} title={t('detailsPageHeader')} />
              <Id>{`ID ${item.id}`}</Id>
            </div>
            <ButtonContainer>
              <Button intent="primary" onClick={goToTransactions}>
                {t('showTransactions')}
              </Button>
            </ButtonContainer>
          </div>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <InventoryTotalDetailsForm
          mode={hasEditPermission ? 'edit' : 'read'}
          progress={meta.updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          onRemove={onRemove}
          item={item}
        />
      </Layout.Content>
    </>
  )
}

export default InventoryTotalDetails
