import React, { useCallback, useMemo } from 'react'
import * as Header from 'views/ui/Header/Header'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { useTranslation } from 'react-i18next'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CellProps, useTable } from 'react-table'
import { DocumentItem } from 'modules/domain/document/types'
import { useDocumentList } from 'modules/domain/document/hooks'
import { useSelector } from 'react-redux'

import DocumentSelectors from 'modules/domain/document/selectors'
import DocumentActions from 'modules/domain/document/duck'
import DocumentRoutes from 'views/pages/Document/routes'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'

const CreatedAtCell: React.FC<CellProps<DocumentItem>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id}>
      <div>{getFormatedDate(row.values.date)}</div>
    </div>
  )
}

const DocumentList: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation('document')
  const { scrollRef } = usePersistentScroll('mailingList')
  const total = useSelector(DocumentSelectors.total)
  const page = useSelector(DocumentSelectors.page)
  const pages = useSelector(DocumentSelectors.pages)
  const pageSize = useSelector(DocumentSelectors.pageSize)
  const [progress, data = []] = useDocumentList()

  const listRequestAction = useAction(DocumentActions.listRequested)

  const hiddenColumns = useMemo(() => ['id'], [])
  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.name'),
        accessor: 'name' as const,
        sortable: false,
      },
      {
        Header: t('fields.createdAt'),
        accessor: 'date' as const,
        sortable: false,
        Cell: CreatedAtCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<DocumentItem>({
    columns: allColumns,
    data,
    initialState: { hiddenColumns },
  })

  const fetchNextItems = useCallback(
    num => {
      listRequestAction({ page: num })
    },
    [listRequestAction],
  )

  return (
    <TComponents.Wrapper noFilters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: DocumentRoutes.Edit, params: { id: row.original.id.toString() } })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

const DocumentTable: React.FC = () => {
  const { t } = useTranslation('document')

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DocumentList />
      </Layout.Content>
    </>
  )
}

export default DocumentTable
