import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'
import { AppGlobalState } from '../../types'

const sidebarFilter = (state: AppGlobalState) => state.storefront.sidebarFilter

const StorefrontSelectors = {
  ...createBasicSelectors('storefront'),
  sidebarFilter,
}

export default StorefrontSelectors
