import { CountryPhoneInput, CountryPhoneInputProps } from '@agro-club/frontend-shared'
import React from 'react'
import { useSelector } from 'react-redux'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { CountryPhoneCode } from 'modules/domain/collection/types'

type PhoneInputProps = Omit<
  CountryPhoneInputProps,
  'defaultCountryCode' | 'countryOptions' | 'countryOptionsProgress' | 'name'
> &
  Partial<Pick<CountryPhoneInputProps, 'name' | 'defaultCountryCode' | 'countryOptions' | 'countryOptionsProgress'>> & {
    phoneCodes?: CountryPhoneCode[]
  }

const PhoneInput: React.FC<PhoneInputProps> = props => {
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)
  const phoneCodesFetchProgress = useSelector(CollectionSelectors.phoneCodesFetchProgress)
  const detectedCountry = useDetectedCountry()

  return (
    <CountryPhoneInput
      {...props}
      name={'phone-input'}
      defaultCountryCode={props.defaultCountryCode || detectedCountry || phoneCodes[0]?.id}
      countryOptions={(props.phoneCodes?.length && props.phoneCodes) || phoneCodes || []}
      countryOptionsProgress={phoneCodesFetchProgress}
    />
  )
}

export default PhoneInput
