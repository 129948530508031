import styled from 'styled-components'

export const DocumentModalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  padding: 10px;
  overflow-y: auto;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div:not(:first-child) {
    margin-top: 8px;
  }
`
