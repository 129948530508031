import React from 'react'

import { Switch } from 'react-router-dom'
import Route from 'views/components/Route/Route'
import ProgramSummaryRoutes from 'views/pages/ProgramSummary/routes'
import ProgramSummaryDetail from './Detail/ProgramSummaryDetail'
import ProgramSummaryList from './List/ProgramSummaryList'

const ProgramSummaryPage: React.FC = () => {
  return (
    <Switch>
      <Route path={ProgramSummaryRoutes.Edit}>
        <ProgramSummaryDetail />
      </Route>
      <Route path={ProgramSummaryRoutes.List}>
        <ProgramSummaryList />
      </Route>
    </Switch>
  )
}

export default ProgramSummaryPage
