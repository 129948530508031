import managers from 'modules/domain/programSummary/managers'
import { ProgramSummaryCategory } from 'modules/domain/programSummary/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import useLangPicker from 'hooks/useLangPicker'

export const getCategoryLabel = (item: ProgramSummaryCategory, pick) => pick(item.category_title)

export const getCategoryValue = (item: ProgramSummaryCategory) => item.category_id

export const CategorySelect: React.VFC<EntityMultiSelectProps<any>> = ({ ...props }) => {
  const { pick } = useLangPicker(lang => `title_${lang}`)
  return (
    <SelectTestWrapper data-test-id="category-select">
      <EntityMultiSelect
        getEntityByIds={managers.getCategoriesByIds}
        getEntityList={managers.getCategories}
        searchParamKey="title"
        getOptionLabel={item => getCategoryLabel(item, pick)}
        getOptionValue={getCategoryValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
