import { BasicSelect } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { createGetList } from './common'

export type TargetSkuSeedTreatment = {
  title: LocalizedValue
  id: string
}

type TargetSkuSeedTreatmentFilter = {
  product_id: string
  title?: string
}

type TargetSkuSeedTreatmentSelectProps = EntitySelectProps<TargetSkuSeedTreatment> & {
  productId?: string
  valueLabel?: string
}

const getList = createGetList<TargetSkuSeedTreatment, TargetSkuSeedTreatmentFilter>(
  endpoints.target2sku('seed-treatment'),
)

const getValue = (item: TargetSkuSeedTreatment) => item.id

export const TargetSkuSeedTreatmentSelect: React.VFC<TargetSkuSeedTreatmentSelectProps> = ({
  productId = '',
  isDisabled,
  valueLabel,
  onChange,
  ...props
}) => {
  const { pick } = useLangPicker()

  const filter = useMemo(() => ({ product_id: productId }), [productId])
  const fakeValue = useMemo(
    () =>
      valueLabel
        ? {
            title: { en: valueLabel },
            id: 'fake',
          }
        : undefined,
    [valueLabel],
  )

  const getLabel = (item: TargetSkuSeedTreatment) => pick(item.title)

  const testId = 'target-seed-treatment-select'

  return isDisabled ? (
    <BasicSelect
      isDisabled
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
      value={fakeValue}
      data-test-id={testId}
    />
  ) : (
    <EntitySelect
      filter={filter}
      getEntityList={getList}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      onChange={onChange}
      searchParamKey="title"
      data-test-id={testId}
      {...props}
    />
  )
}
