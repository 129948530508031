import React, { useMemo } from 'react'
import { FarmerData } from 'modules/domain/farmer/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { zip } from 'ramda'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'

const Value = styled.span`
  color: ${props => props.theme.color.onPrimaryDark};
`

const Container = styled(PreviewContainer.Container)`
  margin-bottom: 40px;
  width: 306px;
`

const CustomerInfoShort: React.FC<{
  customer?: FarmerData | null
  onCreditOfferAcceptedChange?: (isAccepted: boolean) => void
  className?: string
  onClick?: () => void
  hideFarmerInfoChecking?: boolean
}> = ({ customer, className, onClick, hideFarmerInfoChecking }) => {
  const { t } = useTranslation(['farmer', 'farmerOrder', 'common'])
  const { first_name, last_name, id, phone_number, email, prefix, ordersCount } = customer || {}
  const hideInfo = useMemo(() => !!hideFarmerInfoChecking && !ordersCount, [hideFarmerInfoChecking, ordersCount])

  const legalAddress = [
    customer?.legal_address?.country,
    customer?.legal_address?.city,
    customer?.legal_address?.address,
    customer?.legal_address?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const getName = ({
    first_name,
    last_name,
    placeholder = '-',
  }: {
    first_name?: string
    last_name?: string
    placeholder?: string
  }) => (first_name || last_name ? [first_name, last_name].filter(Boolean).join(' ') : placeholder)

  const idString = hideInfo ? null : (
    <div data-test-id="customer-info-id">
      {zip(['ID', 'Slug_ID'], [id, prefix])
        .filter(([, id]) => !!id)
        .map(arr => arr.join(': '))
        .join(', ')}
    </div>
  )

  return (
    <Container footer={idString} className={className} onClick={onClick}>
      <PreviewContainer.Header>{getName({ first_name, last_name })}</PreviewContainer.Header>
      {!!phone_number && <PreviewContainer.Subheader>{phone_number}</PreviewContainer.Subheader>}
      {!hideInfo && (
        <div data-test-id="customer-info">
          {!!email && <PreviewContainer.Link>{email}</PreviewContainer.Link>}
          {!!legalAddress.length && (
            <PreviewContainer.Text style={{ marginTop: '16px', display: 'block' }}>
              {t('farmerOrder:legalAddress')}
              <Value>{legalAddress}</Value>
            </PreviewContainer.Text>
          )}
        </div>
      )}
    </Container>
  )
}

export default CustomerInfoShort
