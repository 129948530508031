import { AnalyticsInstance } from 'analytics'
import { i18n } from 'i18next'
import { getContext } from 'redux-saga/effects'

export function* getAnalyticsInstance(): Generator<AnalyticsInstance> {
  return yield (getContext('analyticsInstance') as unknown) as AnalyticsInstance
}

export function* getI18nInstance(): Generator<i18n> {
  return yield (getContext('i18n') as unknown) as i18n
}
