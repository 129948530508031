import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import LicenseRoutes from 'views/pages/License/routes'
import LicenseAdd from 'views/pages/License/LicenseAdd'
import LicenseEdit from 'views/pages/License/LicenseEdit'
import LicenseList from 'views/pages/License/LicenseList'
import { LicenseFormInputs } from './LicenseDetailsForm/LicenseDetailsForm'

const LicensePage: React.FC = () => {
  const [initialState, setInitialState] = useState<LicenseFormInputs>()

  return (
    <Switch>
      <Route path={LicenseRoutes.Add}>
        <LicenseAdd initialState={initialState} onUpdateInitialState={setInitialState} />
      </Route>
      <Route path={LicenseRoutes.Edit}>
        <LicenseEdit onUpdateInitialState={setInitialState} />
      </Route>
      <Route path={LicenseRoutes.List}>
        <LicenseList onUpdateInitialState={setInitialState} />
      </Route>
    </Switch>
  )
}

export default LicensePage
