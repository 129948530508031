import { ResourceHook } from 'modules/types'
import { createItemByIdHook } from 'modules/utils/helpers/createItemByIdHook'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'
import { createItemListOnceHook } from 'modules/utils/helpers/createItemListOnceHook'
import { createItemsByIdsOnceHook } from 'modules/utils/helpers/createItemsByIdsOnceHook'

import IncentiveCampaignActions from './duck'
import managers from './managers'
import IncentiveCampaignSelectors from './selectors'
import { IncentiveCampaign } from './types'

export const useIncentiveCampaignList = createItemListOnceHook(
  IncentiveCampaignSelectors,
  IncentiveCampaignActions.listRequested,
)

export const useIncentiveCampaignByIdOnce = createItemByIdOnceHook(
  IncentiveCampaignSelectors,
  IncentiveCampaignActions.itemRequested,
)

export const useIncentiveCampaignById: ResourceHook<IncentiveCampaign, [string]> = createItemByIdHook(
  IncentiveCampaignSelectors,
  IncentiveCampaignActions.itemRequested,
)

export const useIncentiveCampaignByIds = createItemsByIdsOnceHook(false, managers.getItem)
