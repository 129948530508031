import { AddError, EntityState } from 'modules/domain/types'
import { UserDTO } from 'modules/domain/user/types'
import { Progress } from 'modules/types'

export type CommentsState = EntityState<CommentEntryDTO, CommentsFilter, CommentsSorting> & {
  syncProgress: Progress
  syncErrorDetail: string | null
  syncError: AddError | null
}

export enum Collections {
  Orders = 'orders',
}

export type CommentEntryDTO = {
  id: string
  created_at?: string
  updated_at?: string
  comment?: string
  collection: string
  author_id?: string
  author?: UserDTO
  obj_id: string
  comment_changed?: boolean
}

export type CommentEntry = Omit<CommentEntryDTO, 'id'> & { id?: string }

export type CommentsFilter = {}

export type CommentsSorting = {}
