import React from 'react'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { ProductsAvailabilityDetailsForm } from './ProductsAvailabilityDetailsForm'
import ProductsAvailabilitySelectors from 'modules/domain/productsAvailability/selectors'
import ProductsAvailabilityRoutes from './routes'
import ProductsAvailabilityActions from 'modules/domain/productsAvailability/duck'
import { useParams } from 'react-router-dom'
import { useProductsAvailabilityByIdOnce } from 'modules/domain/productsAvailability/hooks'
import { useEditLayout } from 'hooks/useEditLayout'
import { ProductsAvailabilityDTO } from 'modules/domain/productsAvailability/types'

const ProductsAvailabilityEdit: React.FC = () => {
  const { t } = useTranslation('productsAvailability')
  const params = useParams<{ id: string }>()
  const [fetchProgress, data] = useProductsAvailabilityByIdOnce(params.id)
  const submitAction = useAction(ProductsAvailabilityActions.updateRequested)
  const removeAction = useAction(ProductsAvailabilityActions.removeRequested, params.id)
  const updateProgress = useSelector(ProductsAvailabilitySelectors.updateProgress)

  const meta = useSelector(ProductsAvailabilitySelectors.meta(params.id))
  const page = useSelector(ProductsAvailabilitySelectors.page)
  const push = useHistoryPush()

  useHelmet({ title: t('editMetaTitle') })

  const handleSubmit = (props: ProductsAvailabilityDTO) => submitAction(data?.id, props)
  const handleRemove = () => removeAction(params.id)
  const goBack = () => push({ query: { page }, route: ProductsAvailabilityRoutes.List })
  const layout = useEditLayout({
    id: params.id,
    entityName: t('productsAvailability'),
    fetchProgress,
    data,
    fetchError: meta.fetchError,
    goBack,
  })

  if (layout) return layout

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={t('form.editText')} />
          <Header.Id>{`ID ${data?.id ?? ''}`}</Header.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductsAvailabilityDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          initialValues={data}
        />
      </Layout.Content>
    </>
  )
}

export default ProductsAvailabilityEdit
