import { AppGlobalState } from '../../types'

const getConfig = (state: AppGlobalState) => state.config.config
const getConfigFetchProgress = (state: AppGlobalState) => state.config.configFetchProgress

const getLanguage = (state: AppGlobalState) => state.config.language
const getLanguageInitProgress = (state: AppGlobalState) => state.config.languageInitProgress

const ConfigSelectors = {
  config: getConfig,
  fetchProgress: getConfigFetchProgress,
  language: getLanguage,
  languageInitProgress: getLanguageInitProgress,
}

export default ConfigSelectors
