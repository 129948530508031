import styled, { StyledProps } from 'styled-components'
import { FormComponents } from '@agro-club/frontend-shared'

export const VerticalInputsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const VerticalFormsContainer = styled.div`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 300px 300px;
`

export const SettingsBlock = styled(FormComponents.FormSection)`
  max-width: 300px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: 910px;
`

export const LanguagesBlock = styled.div`
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: min-content min-content min-content min-content;
`

export const Error = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -5px;
`
export const LanguagesContainer = styled.div`
  position: relative;
  padding-bottom: 16px;
`
