import React, { memo } from 'react'
import * as Yup from 'yup'
import { Checkbox, FormikHook } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'

export type AdditionalOptions = {
  credit_offer_allowed: boolean
}

const AdditionalOptionsForm: React.FC<{
  additionalOptions?: AdditionalOptions
  useFormik: FormikHook
}> = memo(({ additionalOptions, useFormik }) => {
  const { t } = useTranslation('product')
  const validationSchema = Yup.object({
    credit_offer_allowed: Yup.boolean(),
  })

  const formik = useFormik<AdditionalOptions>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      credit_offer_allowed: additionalOptions?.credit_offer_allowed || false,
    },
    validationSchema,
    enableReinitialize: true,
  })
  return (
    <>
      <Checkbox
        isChecked={formik.values.credit_offer_allowed}
        onChange={(_, isChecked) => {
          formik.setFieldValue('credit_offer_allowed', isChecked)
        }}
        label={t('form.FCC')}
      />
    </>
  )
})
AdditionalOptionsForm.displayName = 'AdditionalOptionsForm'

export default AdditionalOptionsForm
