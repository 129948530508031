import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NotificationsListItem from './NotificationItem'
import NotificationsListList from './NotificationsList'
import NotificationsListRoutes from './routes'

const NotificationsPage = () => {
  return (
    <Switch>
      <Route path={NotificationsListRoutes.List} exact>
        <NotificationsListList />
      </Route>
      <Route path={NotificationsListRoutes.Item}>
        <NotificationsListItem />
      </Route>
    </Switch>
  )
}

export default NotificationsPage
