import { endpoints } from 'modules/endpoints'
import {
  ProductsAvailability,
  ProductsAvailabilityListRequestFilter,
  ProductsAvailabilityListRequestSorting,
  ProductsAvailabilityDTO,
} from './types'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'

export default {
  ...createBasicManagers<
    ProductsAvailabilityListRequestFilter,
    ProductsAvailabilityListRequestSorting,
    ProductsAvailability,
    ProductsAvailabilityDTO,
    ProductsAvailabilityDTO
  >(endpoints.productsAvailabitily),
} as const
