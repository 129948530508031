import React, { useState } from 'react'

import { useHelmet } from '@agro-club/frontend-shared'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StorefrontRoutes from 'views/pages/Storefront/routes'
import StorefrontRoot from 'views/pages/Storefront/Storefront/StorefrontList/StorefrontRoot'
import StorefrontCardList from 'views/pages/Storefront/StorefrontCard/StorefrontCardList'
import StorefrontProductList from 'views/pages/Storefront/StorefrontProducts/StorefrontProductList'
import StorefrontCardAdd from 'views/pages/Storefront/StorefrontCard/StorefrontCardAdd'
import StorefrontCardEdit from 'views/pages/Storefront/StorefrontCard/StorefrontCardEdit'
import StorefrontPackageTypesTable from 'views/pages/Storefront/StorefrontPackageTypes/StorefrontPackageTypesTable'
import StorefrontPackageTypesAdd from 'views/pages/Storefront/StorefrontPackageTypes/StorefrontPackageTypesAdd'
import StorefrontPackageTypesEdit from 'views/pages/Storefront/StorefrontPackageTypes/StorefrontPackageTypesEdit'
import StorefrontProductEdit from './StorefrontProducts/StorefrontProductEdit'
import StorefrontProductAdd from './StorefrontProducts/StorefrontProductAdd'
import { isAgro } from 'types/entities'
import { useRole } from 'modules/permissions/permissions'
import ProductOptionsList from 'views/pages/Product/ProductOptions/ProductOptionsList'
import ProductOptionsEdit from 'views/pages/Product/ProductOptions/ProductOptionsEdit'
import ProductOptionsAdd from 'views/pages/Product/ProductOptions/ProductOptionsAdd'
import { StorefrontProductDTO } from 'modules/domain/storefrontProduct/types'

const StorefrontPage: React.FC = () => {
  const { t } = useTranslation(['storefront'])
  const metaTitle = t('metaTitle')
  useHelmet({ title: metaTitle })
  const role = useRole()
  const [initialProductState, setInitialProductState] = useState<StorefrontProductDTO | undefined>()

  return (
    <Switch>
      <Route path={StorefrontRoutes.List} exact={true}>
        <StorefrontRoot />
      </Route>
      <Route path={StorefrontRoutes.CardsList} exact={true}>
        <StorefrontCardList />
      </Route>
      <Route path={StorefrontRoutes.CardsEdit} exact={true}>
        <StorefrontCardEdit />
      </Route>
      <Route path={StorefrontRoutes.CardsAdd} exact={true}>
        <StorefrontCardAdd />
      </Route>

      <Route path={StorefrontRoutes.ProductsList} exact={true}>
        <StorefrontProductList />
      </Route>
      <Route path={StorefrontRoutes.ProductsEdit} exact={true}>
        <StorefrontProductEdit onUpdateInitialState={setInitialProductState} />
      </Route>
      <Route path={StorefrontRoutes.ProductsAdd} exact={true}>
        <StorefrontProductAdd initialState={initialProductState} onUpdateInitialState={setInitialProductState} />
      </Route>

      <Route path={StorefrontRoutes.PackageTypesList} exact={true}>
        <StorefrontPackageTypesTable />
      </Route>
      <Route path={StorefrontRoutes.PackageTypesEdit} exact={true}>
        <StorefrontPackageTypesEdit />
      </Route>
      <Route path={StorefrontRoutes.PackageTypesAdd} exact={true}>
        <StorefrontPackageTypesAdd />
      </Route>

      {isAgro(role) && (
        <>
          <Route path={StorefrontRoutes.OptionsList} exact={true}>
            <ProductOptionsList />
          </Route>
          <Route path={StorefrontRoutes.OptionsEdit} exact={true}>
            <ProductOptionsEdit />
          </Route>
          <Route path={StorefrontRoutes.OptionsAdd} exact={true}>
            <ProductOptionsAdd />
          </Route>
        </>
      )}
      <Redirect to={StorefrontRoutes.List} />
    </Switch>
  )
}

export default StorefrontPage
