import React, { useCallback } from 'react'
import BadgeDetailsForm from '../BadgeDetailsForm/BadgeDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import BadgeActions from 'modules/domain/badge/duck'
import BadgeSelectors from 'modules/domain/badge/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BadgeRoutes from 'views/pages/Badge/routes'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { BadgeActionWithFiles } from 'modules/domain/badge/types'

const BadgeAdd: React.FC = () => {
  const { t } = useTranslation('badge')
  const push = useHistoryPush()
  const badgeAddAction = useAction(BadgeActions.addRequested)
  const progress = useSelector(BadgeSelectors.addProgress)
  const page = useSelector(BadgeSelectors.page)

  const handleSubmit = useCallback(
    (fields: BadgeActionWithFiles) => {
      badgeAddAction({
        name: fields.name,
        urls: fields.urls,
        status: fields.status,
        properties: fields.properties,
      })
    },
    [badgeAddAction],
  )

  const goBack = useCallback(() => {
    push({ route: BadgeRoutes.List, query: { page } })
  }, [page, push])

  useHelmet({ title: t('addBadgeMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <BadgeDetailsForm onCancel={goBack} onSubmit={handleSubmit} progress={progress} />
      </Layout.Content>
    </>
  )
}

export default BadgeAdd
