import React, { memo } from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { AvailableLanguages, LocalizedValue } from 'types/entities'
import { FormikHook, Input, TextArea } from '@agro-club/frontend-shared'
import { FormikErrors } from 'formik'
import { createLocalizedValue } from 'helpers/localization'

export type QuoteFormProps = {
  text: LocalizedValue
  author: LocalizedValue
  company: LocalizedValue
}

const QuoteForm: React.FC<{
  useFormik: FormikHook
  langList: AvailableLanguages[]
  text?: LocalizedValue
  author?: LocalizedValue
  company?: LocalizedValue
}> = memo(
  ({
    useFormik,
    langList,
    company = createLocalizedValue(() => ''),
    text = createLocalizedValue(() => ''),
    author = createLocalizedValue(() => ''),
  }) => {
    const { t } = useTranslation(['product', 'common', 'validation'])
    const formik = useFormik<QuoteFormProps>({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
      initialValues: {
        company,
        text,
        author,
      },
      enableReinitialize: true,
    })

    const getError = (
      err: FormikErrors<QuoteFormProps> | string | undefined,
      field: 'text' | 'company' | 'author',
      lang: AvailableLanguages,
    ): string => {
      if (!err) {
        return ''
      }
      if (typeof err === 'string') {
        return err
      }
      return err[field]?.[lang] || ''
    }

    return (
      <Styled.VerticalFormsContainer>
        {langList.map(lang => {
          const textKey = `text[${lang}]`
          const authorKey = `author[${lang}]`
          const companyKey = `company[${lang}]`
          return (
            <Styled.VerticalInputsContainer key={lang}>
              <TextArea
                {...formik.getFieldProps(textKey)}
                invalid={formik.touched.text?.[lang] && !!getError(formik.errors, 'text', lang)}
                errorText={getError(formik.errors, 'text', lang)}
                label={t('form.quoteText', { lang })}
                limit={600}
              />
              <Input
                {...formik.getFieldProps(authorKey)}
                invalid={formik.touched.author?.[lang] && !!getError(formik.errors, 'author', lang)}
                errorText={getError(formik.errors, 'author', lang)}
                label={t('form.quoteAuthor', { lang })}
              />
              <Input
                {...formik.getFieldProps(companyKey)}
                invalid={formik.touched.text?.[lang] && !!getError(formik.errors, 'company', lang)}
                errorText={getError(formik.errors, 'company', lang)}
                label={t('form.quoteCompany', { lang })}
              />
            </Styled.VerticalInputsContainer>
          )
        })}
      </Styled.VerticalFormsContainer>
    )
  },
)

export default QuoteForm
