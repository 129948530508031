import { Credentials } from './duck'
import { ProfileDTO, TokensPair, UserProfile } from './types'
import { authClient, apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import Routes from 'views/pages/Auth/routes'

type TokenResponse = {
  access_token: string
  refresh_token: string
}

export const obtainToken = async ({ email, password }: Credentials): Promise<TokensPair> => {
  const response = await authClient.post<TokenResponse>(endpoints.emailLogin(), { email, password })

  return { accessToken: response.access_token, refreshToken: response.refresh_token }
}

export const logout = async (token: string) => {
  return await apiClient.post<string>(endpoints.logout(), { token })
}

export const sendResetEmail = async (email: string): Promise<void> => {
  await authClient.post(endpoints.sendResetEmail(), {
    email,
    base_url: `${window.location.origin}${Routes.SetNewPassword}`,
  })
}

export const resetPassword = async (newPassword: string, token: string): Promise<void> => {
  await authClient.post(endpoints.resetPassword(), { token, new_password: newPassword })
}

export const fetchCurrentUser = async () => {
  return await apiClient.get<UserProfile>(endpoints.profile())
}

export const updateCurrentUser = async (profile: Partial<ProfileDTO>) => {
  return await apiClient.put<UserProfile>(endpoints.profile(), profile)
}
