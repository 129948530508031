import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import DiscountRuleActions from './duck'
import DiscountRuleSelectors from './selectors'
import { DiscountRule } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { getFilesUrls, updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import DiscountRuleRoutes from 'views/pages/DiscountRule/routes'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'

export const fetchList = function*() {
  try {
    let currentPage = yield select(DiscountRuleSelectors.page)
    const filter = yield select(DiscountRuleSelectors.filter)
    const sorting = yield select(DiscountRuleSelectors.sorting)
    const pageSize = yield select(DiscountRuleSelectors.pageSize)

    let response: ListResponse<DiscountRule> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(DiscountRuleActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, DiscountRuleRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DiscountRuleActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(DiscountRuleSelectors.page)
    const filter = yield select(DiscountRuleSelectors.filter)
    const sorting = yield select(DiscountRuleSelectors.sorting)
    const pageSize = yield select(DiscountRuleSelectors.pageSize)
    const { data, total_count }: { data: DiscountRule[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DiscountRuleActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DiscountRuleActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof DiscountRuleActions.itemRequested>) {
  try {
    const item: DiscountRule = yield call(managers.getItem, id)
    yield put(DiscountRuleActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DiscountRuleActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof DiscountRuleActions.addRequested>) {
  try {
    const { link_url_i18n: linkUrl = {} } = dto
    const filesWithUrls = yield call(getFilesUrls, linkUrl)
    const item: DiscountRule = yield call(managers.addItem, { ...dto, link_url_i18n: { ...filesWithUrls } })
    yield put(DiscountRuleActions.addSucceed(item))
    yield put(push(generatePath(DiscountRuleRoutes.Edit, { id: item.id })))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DiscountRuleActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof DiscountRuleActions.updateRequested>) {
  try {
    const { link_url_i18n: linkUrl = {} } = dto
    const filesWithUrls = yield call(getFilesUrls, linkUrl)
    const item: DiscountRule = yield call(managers.updateItem, id, { ...dto, link_url_i18n: { ...filesWithUrls } })
    yield put(DiscountRuleActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DiscountRuleActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof DiscountRuleActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(DiscountRuleActions.removeSucceed(payload))
    yield put(push(DiscountRuleRoutes.List))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DiscountRuleActions.removeFailed(payload, type, detail))
  }
}

const DiscountRuleSaga = function*() {
  yield all([
    takeLatest(DiscountRuleActions.itemRequested.type, fetchItem),
    takeLatest(DiscountRuleActions.listRequested.type, fetchList),
    takeLatest(DiscountRuleActions.filterUpdated.type, fetchList),
    takeLatest(DiscountRuleActions.sortingUpdated.type, fetchList),
    takeLatest(DiscountRuleActions.filterHasBeenReset.type, fetchList),
    takeLatest(DiscountRuleActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DiscountRuleActions.listRequestedNext.type, fetchListNext),

    takeLatest(DiscountRuleActions.addRequested.type, addItem),
    takeLatest(DiscountRuleActions.updateRequested.type, updateItem),
    takeLatest(DiscountRuleActions.removeRequested.type, removeItem),
  ])
}

export default DiscountRuleSaga
