import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import styled from 'styled-components'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const SpinnerWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemLoadingLayout: React.FC<{
  id: string
  onBack?(): void
}> = ({ id, onBack }) => {
  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={onBack}>
          <Id>{`ID ${id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <SpinnerWrap>
          <SpinnerLayout />
        </SpinnerWrap>
      </Layout.Content>
    </>
  )
}

export default ItemLoadingLayout
