import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  DocumentDTO,
  DocumentItem,
  DocumentListRequestFilter,
  DocumentListRequestSorting,
  DocumentMetadata,
  DocumentState,
} from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError, UploadError } from 'modules/domain/types'

const initialState: DocumentState = {
  items: {},
  meta: {},
  files: {},
  signedFiles: {},
  userDocumentsList: {},
  userDocumentsListMeta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class DocumentReducer extends ImmerReducer<DocumentState> {
  private updateDocumentMeta(
    options: {
      documentId: string
      userId?: string
    },
    updateFn: (oldMeta: DocumentMetadata) => Partial<DocumentMetadata>,
  ) {
    const id = options.userId ? `${options.userId}-${options.documentId}` : options.documentId
    if (!this.draftState.meta[id]) {
      this.draftState.meta[id] = {
        id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
        downloadError: null,
        downloadProgress: Progress.IDLE,
        printError: null,
        printProgress: Progress.IDLE,
        signRequestProgress: Progress.IDLE,
        signRequestError: null,
        needSignRequestProgress: Progress.IDLE,
        needSignRequestError: null,
        uploadProgress: Progress.IDLE,
        uploadError: null,
      }
    }
    Object.assign(this.draftState.meta[id], updateFn(this.draftState.meta[id]))
  }

  private updateFarmerDocument(userId: string, documentId: string, item: DocumentItem) {
    if (!this.draftState.userDocumentsList[userId]) {
      this.draftState.userDocumentsList[userId] = [item]
    }
    const i = this.draftState.userDocumentsList[userId].findIndex(doc => doc.id === item.id)
    if (-1 !== i) {
      this.draftState.userDocumentsList[userId][i] = item
    } else {
      this.draftState.userDocumentsList[userId].push(item)
    }
  }

  listRequested(params: { filter?: DocumentListRequestFilter; sorting?: DocumentListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: DocumentItem[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
        downloadError: null,
        downloadProgress: Progress.IDLE,
        printError: null,
        printProgress: Progress.IDLE,
        signRequestProgress: Progress.IDLE,
        signRequestError: null,
        needSignRequestProgress: Progress.IDLE,
        needSignRequestError: null,
        uploadProgress: Progress.IDLE,
        uploadError: null,
        uploadValue: 0,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
      downloadError: null,
      downloadProgress: Progress.IDLE,
      printError: null,
      printProgress: Progress.IDLE,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: DocumentItem) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  itemFileUrlRequested(id: string) {
    this.draftState.files[id] = {
      url: null,
      progress: Progress.WORK,
    }
  }

  itemFileUrlRequestSucceed(id: string, url: string) {
    this.draftState.files[id] = {
      url,
      progress: Progress.SUCCESS,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,immer-reducer/no-optional-or-default-value-params
  itemFileUrlRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.files[id] = {
      url: null,
      progress: Progress.ERROR,
    }
  }

  signedFileRequested(documentId: string, userId: string) {
    this.draftState.signedFiles[`${documentId}-${userId}`] = {
      url: null,
      progress: Progress.WORK,
    }
  }

  signedFileRequestSucceed(documentId: string, userId: string, url: string) {
    this.draftState.signedFiles[`${documentId}-${userId}`] = {
      url,
      progress: Progress.SUCCESS,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,immer-reducer/no-optional-or-default-value-params
  signedFileRequestFailed(documentId: string, userId: string, error: FetchError, errorDetail?: string) {
    this.draftState.signedFiles[`${documentId}-${userId}`] = {
      url: null,
      progress: Progress.ERROR,
    }
  }

  // download
  documentDownloadRequested(options: { documentId: string; fileName?: string; userId?: string }) {
    const meta = {
      downloadProgress: Progress.WORK,
      downloadError: null,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  documentDownloadSucceed(options: { documentId: string; userId?: string }) {
    const meta = {
      downloadProgress: Progress.SUCCESS,
      downloadError: null,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  documentDownloadFailed(options: { documentId: string; userId?: string }, error: FetchError) {
    const meta = {
      downloadProgress: Progress.ERROR,
      downloadError: error,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  // print
  documentPrintRequested(options: { documentId: string; userId?: string }) {
    const meta = {
      printProgress: Progress.WORK,
      printError: null,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  documentPrintSucceed(options: { documentId: string; userId?: string }) {
    const meta = {
      printProgress: Progress.SUCCESS,
      printError: null,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  documentPrintFailed(options: { documentId: string; userId?: string }, error: FetchError) {
    const meta = {
      printProgress: Progress.ERROR,
      printError: error,
    }
    this.updateDocumentMeta(options, () => meta)
  }

  signRequestToUserEmailRequested(userId: string, documentId: string) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      signRequestProgress: Progress.WORK,
      signRequestError: null,
    }))
  }
  signRequestToUserEmailSucceed(userId: string, documentId: string) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      signRequestProgress: Progress.SUCCESS,
      signRequestError: null,
    }))
  }
  signRequestToUserEmailFailed(userId: string, documentId: string, error: FetchError) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      signRequestProgress: Progress.ERROR,
      signRequestError: error,
    }))
  }

  needSignRequested(userId: string, documentId: string) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      needSignRequestProgress: Progress.WORK,
      needSignRequestError: null,
    }))
  }
  needSignSucceed(userId: string, documentId: string) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      needSignRequestProgress: Progress.SUCCESS,
      needSignRequestError: null,
    }))
  }
  needSignFailed(userId: string, documentId: string, error: FetchError) {
    this.updateDocumentMeta({ userId, documentId }, () => ({
      needSignRequestProgress: Progress.ERROR,
      needSignRequestError: error,
    }))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: DocumentDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }
  addSucceed(item: DocumentItem) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
      downloadError: null,
      downloadProgress: Progress.IDLE,
      printError: null,
      printProgress: Progress.IDLE,
      signRequestProgress: Progress.IDLE,
      signRequestError: null,
      needSignRequestProgress: Progress.SUCCESS,
      needSignRequestError: null,
      uploadProgress: Progress.IDLE,
      uploadError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<DocumentDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }
  updateSucceed(item: DocumentItem) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  filterUpdated(filter: DocumentListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: DocumentListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchNextErrorDetail = undefined
  }

  listRequestNextSucceed(list: DocumentItem[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.listFetchNextErrorDetail = undefined
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestNextFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
    this.draftState.listFetchNextErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  userDocumentsRequested(_userId: string) {}

  userDocumentsRequestAccepted(userId: string) {
    const meta = {
      id: userId,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.userDocumentsListMeta[userId] = {
      ...meta,
      ...this.draftState.userDocumentsListMeta[userId],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  userDocumentsRequestSucceed(userId: string, list: DocumentItem[]) {
    this.draftState.userDocumentsListMeta[userId].fetchProgress = Progress.SUCCESS
    this.draftState.userDocumentsListMeta[userId].fetchError = null
    this.draftState.userDocumentsListMeta[userId].fetchErrorDetail = undefined
    this.draftState.userDocumentsList[userId] = list
    list.forEach(doc => {
      this.updateDocumentMeta({ userId, documentId: doc.id }, () => ({
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
        downloadError: null,
        downloadProgress: Progress.IDLE,
        printError: null,
        printProgress: Progress.IDLE,
        uploadError: null,
        uploadProgress: Progress.IDLE,
      }))
    })
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  userDocumentsRequestFailed(userId: string, error: FetchError, errorDetail?: string) {
    this.draftState.userDocumentsListMeta[userId].fetchProgress = Progress.ERROR
    this.draftState.userDocumentsListMeta[userId].fetchError = error
    this.draftState.userDocumentsListMeta[userId].fetchErrorDetail = errorDetail
  }

  uploadFarmerDocumentRequested(userId: string, documentId: string, _file: File) {
    this.updateDocumentMeta(
      {
        userId,
        documentId,
      },
      () => ({ uploadProgress: Progress.WORK, uploadError: null, uploadValue: 0 }),
    )
  }

  uploadFarmerDocumentSucceed(userId: string, documentId: string, item: DocumentItem) {
    this.updateDocumentMeta({ userId, documentId }, () => ({ uploadProgress: Progress.SUCCESS, uploadValue: 100 }))
    this.updateFarmerDocument(userId, documentId, item)
  }

  uploadFarmerDocumentFailed(userId: string, documentId: string, error: UploadError) {
    this.updateDocumentMeta({ userId, documentId }, () => ({ uploadProgress: Progress.ERROR, uploadError: error }))
  }
}

export const DocumentActions = createActionCreators(DocumentReducer)
export default DocumentActions
export const reducer = createReducerFunction(DocumentReducer, initialState)
