import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { CountryCode } from 'libphonenumber-js'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'

export type UptakeState = {
  items: Dict<Uptake>
  meta: Dict<EntityMetadata<Uptake>>
  ids: string[]

  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null

  filter: UptakeListRequestFilter
  sorting: UptakeListRequestSorting
  page: number
  total: number
  pageSize: number

  pendingUptakeFetchProgress: Progress
  pendingUptakeCount: number
}

export enum UptakeStatus {
  NEW = 'new',
  DONE = 'done',
}

export type Uptake = {
  id: string
  name: string
  email_or_phone: string
  status: UptakeStatus
  message: string
  comment?: string
  region?: CountryCode
  created_at: string
  updated_at: string
}

export type UptakeListRequestFilter = {
  search?: string
  status?: UptakeStatus
}

export type UptakeListRequestSorting = {
  sort_field?: keyof Uptake
  sort_reversed?: boolean
}

export type UptakeDTO = Omit<Uptake, 'id'> & { id?: string }
