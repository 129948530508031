import { CompanyType, Permissions } from 'types/entities'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

export const useCompanyList = () => {
  const { t } = useTranslation('company')
  return useMemo(
    () => [
      {
        id: CompanyType.Agro,
        title: t('type.agro'),
      },
      {
        id: CompanyType.Producer,
        title: t('type.producer'),
      },
      {
        id: CompanyType.Distributor,
        title: t('type.distributor'),
      },
    ],
    [t],
  )
}
export const useMapRoleToPermission: () => {
  [key in CompanyType]: {
    id: Permissions
    title: string
  }[]
} = () => {
  const { t } = useTranslation('permission')
  return useMemo(
    () => ({
      [CompanyType.User]: [],
      [CompanyType.Agro]: [
        {
          id: Permissions.ADMIN,
          title: t('admin'),
        },
        {
          id: Permissions.MANAGER,
          title: t('manager'),
        },
      ],
      [CompanyType.Producer]: [
        {
          id: Permissions.HEAD,
          title: t('head'),
        },
        {
          id: Permissions.MANAGER,
          title: t('manager'),
        },
      ],
      [CompanyType.Distributor]: [
        {
          id: Permissions.HEAD,
          title: t('head'),
        },
        {
          id: Permissions.MANAGER,
          title: t('manager'),
        },
      ],
    }),
    [t],
  )
}
