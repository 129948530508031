import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import HouseholdActions from './duck'
import HouseholdSelectors from './selectors'
import { Household } from './types'
import * as managers from 'modules/domain/household/managers'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

import { createItemsByIdsOnceHook } from 'modules/utils/helpers/createItemsByIdsOnceHook'

export const useHouseholdList: ResourceHook<Household[]> = () => {
  const progress = useSelector(HouseholdSelectors.listFetchProgress)
  const list = useSelector(HouseholdSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(HouseholdActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useHousehold: ResourceHook<Household> = (id: string) => {
  const meta = useSelector(state => HouseholdSelectors.meta(state, id))
  const item = useSelector(state => HouseholdSelectors.item(state, id))
  const fetchAction = useAction(HouseholdActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useHouseholdById: ResourceHook<Household> = (id: string) => {
  const meta = useSelector(state => HouseholdSelectors.meta(state, id))
  const item = useSelector(state => HouseholdSelectors.item(state, id))
  const fetchAction = useAction(HouseholdActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useHouseholdsByIds = createItemsByIdsOnceHook(false, managers.getItem)
