import React from 'react'
import { Switch } from 'react-router-dom'
import Route from '../../components/Route/Route'
import ChangeLogRoutes from './routes'
import ChangeLogList from './List/ChangeLogList'
import ChangeLogDetail from './Detail/ChangeLogDetail'

const ChangeLogPage: React.FC = () => {
  return (
    <Switch>
      <Route path={ChangeLogRoutes.Edit}>
        <ChangeLogDetail />
      </Route>
      <Route path={ChangeLogRoutes.List}>
        <ChangeLogList />
      </Route>
    </Switch>
  )
}

export default ChangeLogPage
