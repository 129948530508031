import React, { useCallback, useMemo } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import Routes from './routes'
import { useSelector } from 'react-redux'
import FarmerOrderSkuSelectors from 'modules/domain/farmerOrderSku/selectors'
import { useTranslation } from 'react-i18next'
import FarmerOrderSkuActions from 'modules/domain/farmerOrderSku/duck'
import { FormFields } from 'views/pages/FarmerOrderSku/FarmerOrderSkuDetailsForm'
import FarmerOrderSkuDetailsForm from 'views/pages/FarmerOrderSku/FarmerOrderSkuDetailsForm'
import { CustomFeatureName, isDistributor, ROLES, Sections } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'

import { FarmerOrderStatus, FarmerOrderDeliveryAddress } from 'types/farmerOrder'

const FarmerOrderSkuAdd: React.FC<{
  initialState?: FormFields
  onUpdateInitialState(props: FormFields | undefined): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('farmerOrder')
  const push = useHistoryPush()
  const page = useSelector(FarmerOrderSkuSelectors.page)
  const addProgress = useSelector(FarmerOrderSkuSelectors.addProgress)
  const role = useSelector(AuthSelectors.role)
  const goBack = useCallback(() => {
    onUpdateInitialState(undefined)
    // push({ route: Routes.List, query: page ? { page } : undefined })
    push({ route: Routes.List, query: { page } })
  }, [onUpdateInitialState, page, push])
  const addOrder = useAction(FarmerOrderSkuActions.addRequested)
  const checkFeatureFlag = useFeatureFlags()
  const farmerConfirmRequired = generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired)
  const isFarmerConfirmationEnabled = checkFeatureFlag(farmerConfirmRequired)

  useHelmet({ title: t('addPageTitle') })

  const handleSubmit = useCallback(
    (
      values: FormFields,
      options: { duplicate: boolean; dirty: boolean; isAddRetailerOrderSuggestionEnabled: boolean },
    ) => {
      onUpdateInitialState(undefined)
      const {
        delivery_date,
        desired_delivery_date,
        address,
        city,
        country,
        postal_code,
        producer_id,
        seller_id,
        owner_id,
        region_id,
        ...rest
      } = values
      const deliveryAddress: FarmerOrderDeliveryAddress = {
        desired_delivery_date,
        delivery_date,
        address,
        city,
        country,
        postal_code,
        region_id,
      }

      let key: keyof typeof deliveryAddress
      for (key in deliveryAddress) {
        if (!deliveryAddress[key]) {
          delete deliveryAddress[key]
        }
      }
      const data = {
        delivery_addresses: [deliveryAddress],
        producer_id: producer_id || null,
        seller_id: seller_id || null,
        owner_id: owner_id as string,
        ...rest,
      }
      addOrder(data, options.duplicate, options.isAddRetailerOrderSuggestionEnabled)
    },
    [addOrder, onUpdateInitialState],
  )

  const interactionInitValue = useMemo(() => {
    const interaction = {
      canceled_by_role: initialState?.interaction.canceled_by_role || null,
      confirmed_by_agroclub: !!initialState?.interaction.confirmed_by_agroclub,
      confirmed_by_distributor: initialState?.interaction.confirmed_by_distributor || 'unset',
      confirmed_by_producer: !!initialState?.interaction.confirmed_by_producer,
      confirmed_by_farmer: !!initialState?.interaction.confirmed_by_farmer,
      credit_offer_accepted: !!initialState?.interaction.credit_offer_accepted,
    }

    if (!isFarmerConfirmationEnabled) {
      interaction.confirmed_by_farmer = true
    }

    switch (role) {
      case ROLES.DISTRIBUTOR_HEAD:
      case ROLES.DISTRIBUTOR_MANAGER:
        interaction.confirmed_by_agroclub = true
        interaction.confirmed_by_distributor = 'confirmed'
        interaction.confirmed_by_producer = true
        break
      case ROLES.PRODUCER_HEAD:
      case ROLES.PRODUCER_MANAGER:
      case ROLES.AGRO_ADMIN:
      case ROLES.AGRO_MANAGER:
        interaction.confirmed_by_agroclub = true
        interaction.confirmed_by_producer = true
        break
    }

    return interaction
  }, [initialState, role, isFarmerConfirmationEnabled])

  const initialValues: FormFields = {
    status: initialState?.status || isDistributor(role) ? FarmerOrderStatus.Confirmed : FarmerOrderStatus.New,
    distributor_id: initialState?.distributor_id,
    producer_id: initialState?.producer_id,
    seller_id: initialState?.seller_id,
    comment: initialState?.comment,
    farmer_comment: initialState?.farmer_comment,
    sku_items: initialState?.sku_items || [],
    owner_id: initialState?.owner_id,
    promocodes: initialState?.promocodes || [],
    desired_delivery_date: initialState?.desired_delivery_date,
    delivery_date: initialState?.delivery_date,
    address: initialState?.address,
    city: initialState?.city,
    region_id: initialState?.region_id,
    country: initialState?.country,
    postal_code: initialState?.postal_code,
    interaction: interactionInitValue,
    season_id: initialState?.season_id,
    files: initialState?.files || [],
    comments: [],
    admin_comment: initialState?.admin_comment,
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('addPageTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FarmerOrderSkuDetailsForm
          mode={'create'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          progress={addProgress}
        />
      </Layout.Content>
    </>
  )
}

export default FarmerOrderSkuAdd
