import React, { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTable } from 'react-table'
import {
  AddButton,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHelmet,
  usePersistentScroll,
} from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'

import SeasonRoutes from 'views/pages/Season/routes'
import { useSeasonList } from 'modules/domain/season/hooks'
import SeasonSelectors from 'modules/domain/season/selectors'
import SeasonActions from 'modules/domain/season/duck'
import { Season } from 'modules/domain/season/types'

const SeasonList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['season'])
  const [progress, data = []] = useSeasonList()

  const total = useSelector(SeasonSelectors.total)
  const page = useSelector(SeasonSelectors.page)
  const pages = useSelector(SeasonSelectors.pages)
  const pageSize = useSelector(SeasonSelectors.pageSize)

  const listRequested = useAction(SeasonActions.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.id'),
        accessor: 'id' as const,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
      },
      {
        Header: t('list.tableHeaders.company_name'),
        accessor: 'company_official_name' as const,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<Season>({
    columns: allColumns,
    data: data,
  })

  const { scrollRef } = usePersistentScroll('season')

  useHelmet({ title: t('seasonsMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={SeasonRoutes.Add} />
          </Header.Title>
        </Header.Root>
      </Layout.Header>

      <Layout.Content>
        <TComponents.Wrapper noFilters>
          <Table
            total={total}
            pages={pages}
            pageSize={pageSize}
            currentPage={page}
            onSetPage={fetchNextItems}
            ref={scrollRef}
          >
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(SeasonRoutes.Edit, { id: row.original.id }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default SeasonList
