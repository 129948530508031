import { all, call, put, takeLatest } from 'redux-saga/effects'
import { RequestError } from 'modules/errors'
import { CommentEntryDTO } from './types'
import CommentsActions from './duck'
import managers from './managers'

const fetchList = function*(props) {
  try {
    const { payload } = props
    const response: Array<CommentEntryDTO> = yield call(managers.getList, payload?.collectionName, payload?.objId)
    yield put(CommentsActions.listRequestSucceed(response))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CommentsActions.listRequestFailed(type, detail))
  }
}

const addComment = function*(props) {
  try {
    const { collectionName, objId, comment } = props.payload
    const response: CommentEntryDTO = yield call(managers.addItem, collectionName, objId, comment)
    yield put(CommentsActions.addItemSucceed(response))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CommentsActions.addItemFailed(type, detail))
  }
}

const removeComment = function*(props) {
  try {
    const { collection, obj_id, id } = props.payload
    yield call(managers.removeItem, collection, obj_id, id)
    yield put(CommentsActions.removeSucceed(props.payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CommentsActions.removeFailed(props.payload.commentId, type, detail))
  }
}

const batchCommentsSync = function*(props) {
  try {
    const { oldComments, newComments } = props.payload
    const deletedComments = oldComments.filter(e => !newComments.find(i => i?.id === e.id))
    const callEffects = deletedComments.map(e => call(managers.removeItem, e.collection, e.obj_id, e.id))
    newComments.forEach(e => {
      const isNewComment = !e?.id
      const isUpdatedComment = !isNewComment && e.comment !== oldComments.find(i => i.id === e.id)?.comment
      if (isNewComment) {
        callEffects.push(call(managers.addItem, e.collection, e.obj_id, e.comment))
      } else if (isUpdatedComment) {
        callEffects.push(call(managers.updateItem, e.collection, e.obj_id, e.id, e.comment))
      }
    })
    yield all(callEffects)
    yield put(CommentsActions.batchCommentsSyncSucceed(props.payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CommentsActions.batchCommentsSyncFailed(type, detail))
  }
}

const CommentsSaga = function*() {
  yield all([
    takeLatest(CommentsActions.listRequested.type, fetchList),
    takeLatest(CommentsActions.addItemRequested.type, addComment),
    takeLatest(CommentsActions.removeRequested.type, removeComment),
    takeLatest(CommentsActions.batchCommentsSyncRequested.type, batchCommentsSync),
    takeLatest(CommentsActions.batchCommentsSyncSucceed.type, fetchList),
  ])
}

export default CommentsSaga
