import {
  Promocode,
  PromocodeDTO,
  PromocodeRequestFilter,
  PromocodeRequestSorting,
} from 'modules/domain/promocode/types'
import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'
import { apiClient } from 'modules/utils/httpClient'

const basicManagers = createBasicManagers<
  PromocodeRequestFilter,
  PromocodeRequestSorting,
  Promocode,
  PromocodeDTO,
  PromocodeDTO
>(endpoints.promocode)

export default {
  ...basicManagers,
  checkPromocode: (params: { code: string; company_id: string; applied_promocodes?: string[] }) => {
    return apiClient.get<Promocode>(endpoints.checkPromocode(), params)
  },
  getItemsByIds: createItemsByIds(false, basicManagers.getItem),
} as const
