import { all, takeLatest } from 'redux-saga/effects'
import ProductSettingsActions from './duck'
import ProductSettingsSelectors from './selectors'
import managers from './managers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'

const sagas = createBasicSagas({
  managers,
  selectors: ProductSettingsSelectors,
  actions: ProductSettingsActions,
})

const ProductSettingsSaga = function*() {
  yield all([
    takeLatest(ProductSettingsActions.listRequested.type, sagas.fetchList),
    takeLatest(ProductSettingsActions.filterUpdated.type, sagas.fetchList),
    takeLatest(ProductSettingsActions.sortingUpdated.type, sagas.fetchList),
    takeLatest(ProductSettingsActions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(ProductSettingsActions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(ProductSettingsActions.listRequestedNext.type, sagas.fetchListNext),
    takeLatest(ProductSettingsActions.updateRequested.type, sagas.updateItem),
  ])
}

export default ProductSettingsSaga
