import React from 'react'
import styled from 'styled-components'
import { StorefrontCardForSelect } from './types'
import i18n from 'i18n'

export const OutOfStock = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.secondary200};
`

export const getCardMeta = (card: StorefrontCardForSelect) => {
  let meta: React.ReactNode

  if (card.is_out_of_stock) {
    meta = <OutOfStock>{i18n.t('cards:form.labels.outOfStock')}</OutOfStock>
  }

  return meta
}
