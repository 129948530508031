import { AppGlobalState } from 'modules/types'
import { createBasicSelectors } from 'modules/utils/helpers/createBasicSelectors'
import { createSelector } from 'reselect'
const basicSelectors = createBasicSelectors('fccOrderSku')
const itemsForUpdate = (state: AppGlobalState) => state.fccOrderSku.itemsForUpdate
const isChanged = createSelector(itemsForUpdate, itemsForUpdate => Boolean(Object.keys(itemsForUpdate).length))

export default {
  ...basicSelectors,
  itemsForUpdate,
  isChanged,
  totalCost: (state: AppGlobalState) => state.fccOrderSku.totalCost,
  totalQty: (state: AppGlobalState) => state.fccOrderSku.totalQty,
  totalFinalQty: (state: AppGlobalState) => state.fccOrderSku.totalFinalQty,
}
