import React from 'react'
import * as managers from 'modules/domain/document/managers'
import { DocumentItem } from 'modules/domain/document/types'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type DocumentSelectProps = EntitySelectProps<DocumentItem>

export const getDocumentValue = (document: DocumentItem) => document.id
export const getDocumentLabel = (document: DocumentItem) => document.name

export const DocumentSelect: React.VFC<DocumentSelectProps> = props => {
  return (
    <SelectTestWrapper data-test-id="document-select">
      <EntitySelect
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getDocumentLabel}
        getOptionValue={getDocumentValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
