import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  DistributorOrderActionData,
  DistributorOrderDTO,
  DistributorOrderListRequestFilter,
  DistributorOrderListRequestSorting,
} from './types'
import { DistributorOrder } from 'types/distributorOrder'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { DistributorOrderState } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'

const initialState: DistributorOrderState = {
  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  listUpdateProgress: Progress.IDLE,
  listUpdateError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  newOrdersCount: 0,
  newOrdersCountProgress: Progress.IDLE,
}

class DistributorOrderReducer extends ImmerReducer<DistributorOrderState> {
  listRequested(params: {
    filter?: DistributorOrderListRequestFilter
    sorting?: DistributorOrderListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: DistributorOrder[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }
  listUpdateRequested() {
    this.draftState.listUpdateProgress = Progress.WORK
    this.draftState.listUpdateError = null
  }
  listUpdateRequestSucceed(list: DistributorOrder[], total: number) {
    this.draftState.listUpdateProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
  }
  listUpdateRequestFailed(error: UpdateError) {
    this.draftState.listUpdateProgress = Progress.ERROR
    this.draftState.listUpdateError = error
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: DistributorOrder) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.items[item.id] = item
  }
  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  addRequested(_dto: DistributorOrderActionData, _duplicate: boolean) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
  }
  addSucceed(item: DistributorOrder) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }
  addFailed(error: AddError) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<DistributorOrderDTO>, duplicate: boolean) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
  }
  updateSucceed(item: DistributorOrder) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }
  updateFailed(id: string, error: UpdateError) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
  }

  filterUpdated(filter: DistributorOrderListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  filtersUpdatedWithPersistentStorage(filter: DistributorOrderListRequestFilter) {
    this.draftState.filter = { ...this.draftState.filter, ...filter }
  }

  sortingUpdated(sorting: DistributorOrderListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: DistributorOrder[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
  }
  resetRemoveProgress() {
    this.draftState.removeProgress = Progress.IDLE
  }
  newOrdersCountRequested() {
    this.draftState.newOrdersCountProgress = Progress.WORK
  }
  newOrdersCountRequestSucceed(count: number) {
    this.draftState.newOrdersCountProgress = Progress.SUCCESS
    this.draftState.newOrdersCount = count
  }
  newOrdersCountRequestError() {
    this.draftState.newOrdersCountProgress = Progress.ERROR
  }
}

export const DistributorOrderActions = createActionCreators(DistributorOrderReducer)
export default DistributorOrderActions
export const reducer = createReducerFunction(DistributorOrderReducer, initialState)
