import { FormField, helpersColor, Label, NumberInput, useAction } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { Progress } from 'modules/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CompanyType } from 'types/entities'
import { CompoundedTableEditableForm } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditableRow'
import * as Yup from 'yup'
import { Allocation, AllocationFormFields } from 'modules/domain/allocation/common/types'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import RetailerAllocationSelectors from 'modules/domain/allocation/retailerAllocation/selectors'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { TerritorySelect } from 'views/components/TerritorySelect/TerritorySelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import { isNil } from 'ramda'
import styled from 'styled-components'
import { FormWrapper } from '../Target2/components/styles'
import { useLocation } from 'react-router-dom'
import { Territory } from 'modules/domain/territory/types'
import { Season } from 'modules/domain/season/types'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'
import TerritoryAllocationSelectors from 'modules/domain/allocation/territoryAllocation/selectors'
import TerritoryAllocationActions from 'modules/domain/allocation/territoryAllocation/duck'

const ProductQtyIsMoreThanLimit = styled.div`
  background: ${props => helpersColor.alphaColor(props.theme.color.accentDestructive, 0.15)};
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
`

const UnallocatedAmount = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const AllocationForm: CompoundedTableEditableForm<Allocation> = ({ Buttons, entity }) => {
  const { t } = useTranslation('allocation')

  const location = useLocation()
  const isRetailerTab = location.pathname.includes('retailer')
  const allocationActions = isRetailerTab ? RetailerAllocationActions : TerritoryAllocationActions
  const allocationSelectors = isRetailerTab ? RetailerAllocationSelectors : TerritoryAllocationSelectors

  const [season, setSeason] = useState<Season>()
  const [territories, setTerritories] = useState<Territory[]>([])

  const retailerProductLimit = useSelector(TerritoryAllocationSelectors.retailerProductsLimit)
  const retailerLimitAction = useAction(TerritoryAllocationActions.retailerLimitRequested)
  const retailerProductLimitFetchProgress = useSelector(TerritoryAllocationSelectors.retailerProductLimitFetchProgress)
  const clearRetailerLimit = useAction(TerritoryAllocationActions.clearRetailerProductLimit)

  const addRequested = useAction(allocationActions.addRequested)
  const addProgress = useSelector(allocationSelectors.addProgress)
  const addErrorDetail = useSelector(allocationSelectors.addErrorDetail)

  const updateRequested = useAction(allocationActions.updateRequested)
  const updateProgress = useSelector(allocationSelectors.updateProgress)

  const removeRequested = useAction(allocationActions.removeRequested)
  const removeProgress = useSelector(allocationSelectors.removeProgress)
  const removeErrorDetail = useSelector(allocationSelectors.removeErrorDetail)

  const meta = useSelector(allocationSelectors.meta(entity?.id))
  const isEditable = !entity

  const validationSchema = useMemo(() => {
    return Yup.object({
      distributor_id: isRetailerTab ? Yup.string().required(t('validation:field_required')) : Yup.string(),
      producer_id: Yup.string().required(t('validation:field_required')),
      product_id: Yup.string().required(t('validation:field_required')),
      category_id: Yup.string().required(t('validation:field_required')),
      season_id: isRetailerTab ? Yup.string() : Yup.string().required(t('validation:field_required')),
      territory_id: isRetailerTab ? Yup.string() : Yup.string().required(t('validation:field_required')),
      subcategory_id: Yup.string().required(t('validation:field_required')),
    })
  }, [t, isRetailerTab])

  const formik = useFormik<AllocationFormFields>({
    initialValues: {
      distributor_id: entity?.distributor_id || '',
      producer_id: entity?.producer_id || '',
      season_id: entity?.season_id || '',
      category_id: entity?.product.category_id || '',
      subcategory_id: entity?.product.subcategory_id || '',
      product_id: entity?.product_id || '',
      packaging: entity?.packaging || '',
      territory_id: entity?.distributor?.territories_ids?.[0] || entity?.territory_id || '',
      territory_limit: entity?.territory_limit || 0,
      territory_title: entity?.territory?.title || '',
      quantity: entity?.quantity || 0,
      initialQuantity: entity?.quantity || 0,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (entity?.id) {
        updateRequested(entity.id, formik.values)
      } else {
        addRequested(values, false)
      }
    },
  })

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && !entity) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress])

  useValidationErrorNotification(
    formik.submitCount,
    formik.isValid,
    removeErrorDetail || entity ? meta.updateErrorDetail : addErrorDetail,
  )

  useMemo(() => {
    if (!formik.values.season_id) formik.setFieldValue('season_id', season?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season])

  const territory = useMemo(() => {
    return territories.find(
      item => item.id === formik.values.territory_id || item.id === formik.initialValues.territory_id,
    )
  }, [formik.values.territory_id, territories, formik.initialValues.territory_id])

  useEffect(() => {
    if (
      formik.values.distributor_id &&
      formik.values.season_id &&
      formik.values.product_id &&
      formik.values.territory_id
    ) {
      retailerLimitAction({
        items: [{ product_id: formik.values.product_id }],
        territory_id: formik.values.territory_id,
        season_id: formik.values.season_id,
      })
    }
  }, [
    formik.values.distributor_id,
    formik.values.season_id,
    formik.values.product_id,
    formik.values.territory_id,
    retailerLimitAction,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearRetailerLimit(), [])

  const onProducerChange = (id: string) => {
    formik.resetForm()
    formik.setFieldValue('distributor_id', '')
    formik.setFieldValue('producer_id', id)
  }

  return (
    <>
      <FormWrapper>
        <CompanySelect
          label={t('labels.producer')}
          placeholder={t(t('placeholders.producer'))}
          onChange={value => onProducerChange(value || '')}
          value={formik.values.producer_id}
          companyType={CompanyType.Producer}
          isClearable
          isSearchable
          onMenuClose={() => {
            formik.setFieldTouched('producer_id')
          }}
          invalid={formik.touched.producer_id && !!formik.errors.producer_id}
          errorText={formik.errors.producer_id}
          isDisabled={!isEditable}
        />
        <TerritorySelect
          onChange={value => formik.setFieldValue('territory_id', value)}
          value={formik.values.territory_id}
          companyId={formik.values.producer_id}
          label={t('labels.territory')}
          placeholder={t('placeholders.territory')}
          isDisabled={!formik.values.producer_id || !isEditable}
          isClearable
          isSearchable
          invalid={!!formik.submitCount && !!formik.errors.territory_id}
          errorText={formik.errors.territory_id}
          onLoadList={list => {
            setTerritories(list.data)
          }}
        />
        {isRetailerTab && (
          <CompanySelect
            label={t('labels.distributor')}
            placeholder={t(t('placeholders.distributor'))}
            onChange={value => formik.setFieldValue('distributor_id', value)}
            value={formik.values.distributor_id || undefined}
            companyType={CompanyType.Distributor}
            isClearable
            isSearchable
            onMenuClose={() => {
              formik.setFieldTouched('distributor_id')
            }}
            invalid={formik.touched.distributor_id && !!formik.errors.distributor_id}
            errorText={formik.errors.distributor_id}
            isDisabled={!formik.values.territory_id || !isEditable}
            filter={{ for_producer_id: formik.values.producer_id, territory_id: formik.values.territory_id }}
          />
        )}
        <SeasonSelect
          label={t('labels.season')}
          placeholder={t('placeholders.season')}
          value={formik.values.season_id}
          onChange={id => {
            formik.setFieldValue('season_id', id)
          }}
          isClearable
          invalid={!!formik.values.producer_id && formik.touched.season_id && !!formik.errors.season_id}
          errorText={formik.errors.season_id}
          isDisabled={!formik.values.producer_id || !isEditable}
          companyId={formik.values.producer_id}
          onLoadList={list => {
            const currentSeason = list.data.find(item => item.is_current)
            if (currentSeason) setSeason(currentSeason)
          }}
        />
        <CategorySelect
          filter={{ seller_ids: formik.values.producer_id }}
          label={t('labels.category')}
          onChange={val => formik.setFieldValue('category_id', val)}
          value={formik.values.category_id}
          invalid={!!formik.submitCount && !!formik.errors.category_id}
          errorText={formik.errors.category_id}
          onMenuClose={() => formik.setFieldTouched('category_id')}
          isDisabled={!formik.values.producer_id || !isEditable}
        />
        <SubcategorySelect
          filter={{ seller_ids: formik.values.producer_id, parent_id: formik.values.category_id }}
          label={t('labels.subcategory')}
          onChange={val => formik.setFieldValue('subcategory_id', val)}
          onMenuClose={() => formik.setFieldTouched('subcategory_id')}
          value={formik.values.subcategory_id}
          invalid={!!formik.submitCount && !!formik.errors.subcategory_id}
          errorText={formik.errors.subcategory_id}
          isDisabled={!formik.values.category_id || !isEditable}
        />
        <ProductsSelect
          label={t('labels.product')}
          onChange={val => formik.setFieldValue('product_id', val)}
          onMenuClose={() => formik.setFieldTouched('product_id')}
          filter={{
            producer_id: formik.values.producer_id,
            subcategory: formik.values.subcategory_id || undefined,
            category: formik.values.category_id || undefined,
          }}
          value={formik.values.product_id}
          invalid={!!formik.submitCount && !!formik.errors.product_id}
          errorText={formik.errors.product_id}
          isDisabled={!formik.values.producer_id || !isEditable}
        />
        <FormField
          label={t('labels.quantity')}
          render={() => (
            <NumberInput
              {...formik.getFieldProps('quantity')}
              size={'medium'}
              onChange={value => formik.setFieldValue('quantity', value)}
              min={0}
            />
          )}
        />
      </FormWrapper>
      <UnallocatedAmount>
        {isRetailerTab && !isNil(retailerProductLimit?.[0]?.allowed_quantity) && (
          <>
            <Label>
              {t('unallocatedTerritoryLimitLabel', { territoryName: territory?.title })}
              {retailerProductLimitFetchProgress === Progress.SUCCESS &&
                (!entity?.id
                  ? retailerProductLimit?.[0]?.allowed_quantity - formik.values.quantity
                  : retailerProductLimit?.[0]?.allowed_quantity -
                    (formik.values.quantity - formik.values.initialQuantity))}
            </Label>
            {(!entity?.id
              ? retailerProductLimit?.[0]?.allowed_quantity - formik.values.quantity < 0
              : retailerProductLimit?.[0]?.allowed_quantity - (formik.values.quantity - formik.values.initialQuantity) <
                0) && <ProductQtyIsMoreThanLimit>{t('productQtyIsMoreThanLimit')}</ProductQtyIsMoreThanLimit>}
          </>
        )}
      </UnallocatedAmount>
      <Buttons
        onSubmit={formik.handleSubmit}
        onRemove={entity ? () => removeRequested(entity.id) : undefined}
        submitProgress={isEditable ? updateProgress : addProgress}
        removeProgress={removeProgress}
        createText={t('list.tableButtons.create')}
        updateText={t('list.tableButtons.update')}
        isSubmitDisabled={!formik.dirty}
      />
    </>
  )
}
