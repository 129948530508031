import { Button, useAction } from '@agro-club/frontend-shared'
import ModalActions from 'modules/domain/modal/duck'
import React from 'react'
import styled from 'styled-components'

export const ModalContent = styled.div`
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
`

export const ModalFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export type ModalWithButtonContentProps = {
  modalContent: string
  allowButtonText: string
  cancelButtonText: string
  handlleAllowButtonClick: () => void
  handlleCancelButtonClick?: () => void
}

export const ModalWithButtonContent: React.FC<ModalWithButtonContentProps> = ({
  modalContent,
  allowButtonText,
  cancelButtonText,
  handlleAllowButtonClick,
  handlleCancelButtonClick,
}) => {
  const close = useAction(ModalActions.close)

  return (
    <>
      <ModalContent>{modalContent}</ModalContent>
      <ModalFooter>
        <Button
          data-test-id="modal-button-confirm"
          intent={'primary'}
          onClick={() => {
            handlleAllowButtonClick()
            close()
          }}
        >
          {allowButtonText}
        </Button>
        <Button
          data-test-id="modal-button-cancel"
          intent={'cancel'}
          onClick={() => {
            handlleCancelButtonClick?.()
            close()
          }}
        >
          {cancelButtonText}
        </Button>
      </ModalFooter>
    </>
  )
}
