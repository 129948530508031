import { LocalizedValue } from 'types/entities'
import useLanguage from 'hooks/useLanguage'
import { useCallback } from 'react'

const fallbackSeq: (keyof LocalizedValue)[] = ['en', 'fr', 'es']

const useLangPicker = (getPropKey?: (language: keyof LocalizedValue<string>) => string) => {
  const language = useLanguage()
  const pick = useCallback(
    (prop?: any): string => {
      if (!prop) {
        return ''
      }

      const key = getPropKey ? getPropKey(language) : language

      if (prop[key]) {
        return prop[key] as string
      }

      const fallbackLanguage = fallbackSeq.find(l => (getPropKey ? prop[getPropKey(l)] : prop[l]))
      if (fallbackLanguage) {
        const fallbackLanguageKey = getPropKey ? getPropKey(fallbackLanguage) : fallbackLanguage
        return prop[fallbackLanguageKey] as string
      }
      return ''
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  )

  return { pick, language }
}

export default useLangPicker
