import styled from 'styled-components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCompanyById } from 'modules/domain/company/hooks'
import { Progress } from 'modules/types'
import { useFormikContext } from 'formik'
import { FormFields } from './types'
import { CompanyType } from 'types/entities'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/collection/managers'
import { remove } from 'ramda'
import { SimpleSelect, TagList } from '@agro-club/frontend-shared'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const Wrapper = styled.div`
  max-width: 350px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 350px;
`

const terrListHook = makeCancellableResourceListHook(makeCancelable(managers.fetchTerritories))

const TerritoryForm: React.FC = () => {
  const { t } = useTranslation('user')
  const formik = useFormikContext<FormFields>()
  const [progress, producer] = useCompanyById(formik.values.company || undefined)
  const hookArgs = useMemo(() => ({ company_id: formik.values.company, page_size: 1000 }), [formik.values.company])
  const [, territoryList] = terrListHook(hookArgs)
  const territorySelectOptions = useMemo(() => {
    if (territoryList) {
      const filtered = territoryList.filter(terr => !formik.values.territories.includes(terr.id))
      return filtered.map(terr => ({ id: terr.id, title: terr.title }))
    }
    return []
  }, [formik.values.territories, territoryList])

  const territoryTags = useMemo(() => {
    if (territoryList) {
      return territoryList.filter(terr => formik.values.territories.includes(terr.id))
    }
    return []
  }, [formik.values.territories, territoryList])

  if (progress === Progress.WORK || !producer) {
    return (
      <SpinnerContainer>
        <SpinnerLayout />
      </SpinnerContainer>
    )
  }

  if (producer.company_type !== CompanyType.Producer) {
    return null
  }

  return (
    <Wrapper data-test-id={'territories-select'}>
      <SimpleSelect
        label={producer.internal_name}
        options={territorySelectOptions}
        value={null}
        onChange={(val: string) => {
          const set = new Set(formik.values.territories)
          set.add(val)
          formik.setFieldValue('territories', [...set])
        }}
        placeholder={t('form.selectTerritory')}
      />
      <TagList
        list={territoryTags}
        onRemove={({ id }) => {
          const i = formik.values.territories.indexOf(id)
          if (-1 !== i) {
            formik.setFieldValue('territories', remove(i, 1, formik.values.territories))
          }
        }}
      />
    </Wrapper>
  )
}

export default TerritoryForm
