import { all, call, put, takeLatest } from 'redux-saga/effects'

import { RequestError } from 'modules/errors'
import TerritoryAllocationActions from './duck'
import TerritoryAllocationSelectors from './selectors'
import { territoryManagers } from './managers'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'

const territoryAllocationSagas = createBasicSagas({
  selectors: TerritoryAllocationSelectors,
  actions: TerritoryAllocationActions,
  managers: territoryManagers,
})

export const fetchRetailerProductLimit = function*({
  payload,
}: ReturnType<typeof TerritoryAllocationActions.retailerLimitRequested>) {
  try {
    const { items } = yield call(territoryManagers.territoryProductLimit, payload)
    yield put(TerritoryAllocationActions.retailerLimitRequestSucceed(items))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(TerritoryAllocationActions.retailerLimitRequestFailed(errType))
  }
}
const TerritoryAllocationSaga = function*() {
  yield all([
    takeLatest(TerritoryAllocationActions.listRequested.type, territoryAllocationSagas.fetchList),
    takeLatest(TerritoryAllocationActions.itemRequested.type, territoryAllocationSagas.fetchItem),
    takeLatest(TerritoryAllocationActions.addRequested.type, territoryAllocationSagas.addItem),
    takeLatest(TerritoryAllocationActions.updateRequested.type, territoryAllocationSagas.updateItem),
    takeLatest(TerritoryAllocationActions.removeRequested.type, territoryAllocationSagas.removeItem),
    takeLatest(TerritoryAllocationActions.filterUpdated.type, territoryAllocationSagas.fetchList),
    takeLatest(TerritoryAllocationActions.retailerLimitRequested.type, fetchRetailerProductLimit),
    takeLatest(TerritoryAllocationActions.addSucceed.type, territoryAllocationSagas.fetchList),
  ])
}

export default TerritoryAllocationSaga
