import React from 'react'
import useLangPicker from 'hooks/useLangPicker'
import { useTranslation } from 'react-i18next'
import { PackageTypes, PackageTypesListRequestFilter } from 'modules/domain/packageTypes/types'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import * as managers from 'modules/domain/packageTypes/managers'
import { useFilterMemo } from 'hooks/useFilterMemo'
import { Status } from 'types/entities'

export type PackageTypeEntitySelectProps = EntitySelectProps<PackageTypes> & {
  status?: Status | Status[]
  skuIds?: string[] | string
}

export const getCategoryValue = (packageType: PackageTypes) => packageType.id

export const PackageTypeSelect: React.VFC<PackageTypeEntitySelectProps> = ({
  placeholder,
  status,
  skuIds,
  ...props
}) => {
  const { t } = useTranslation('common')
  const { pick } = useLangPicker()

  const memoizedFilter = useFilterMemo<PackageTypesListRequestFilter>({ status, sku_ids: skuIds })

  const getCategoryLabel = (packageType: PackageTypes) =>
    (packageType.title_i18n ? pick(packageType.title_i18n) : packageType.title) as string

  return (
    <div data-test-id={'package-type-select'}>
      <EntitySelect
        placeholder={placeholder || t('placeholders.packageType')}
        getEntityList={managers.getList}
        getEntityById={managers.getItem}
        filter={memoizedFilter}
        getOptionValue={getCategoryValue}
        getOptionLabel={getCategoryLabel}
        searchParamKey="title"
        {...props}
      />
    </div>
  )
}
