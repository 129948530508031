import React, { useCallback, useState } from 'react'
import { Farmer } from 'modules/domain/farmer/types'
import CustomerInfoShort from './CustomerInfoShort'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import Footer from './Footer'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities'
import { useSelector } from 'react-redux'
import ConfigSelectors from 'modules/domain/config/selectors'
import useLangPicker from 'hooks/useLangPicker'

const CustomerExists: React.FC<{
  farmer: Farmer
  onSelect: () => void
  onCancel: () => void
  hideFarmerInfoChecking: boolean
}> = ({ farmer, onSelect, onCancel, hideFarmerInfoChecking = false }) => {
  const { t } = useTranslation(['farmerOrder', 'common'])

  const wlConfig = useSelector(ConfigSelectors.config)
  const legalDocs = wlConfig?.legal_docs
  const { pick } = useLangPicker()

  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => checkFeatureFlag(flag), [checkFeatureFlag])

  const isConfirmAccuracyEnable = hasAccess(
    generateFieldModifierString(FieldLocation.CustomerModal, FieldNames.ConfirmAccuracy, FeatureFlagModifiers.Enabled),
  )

  const [confirmAccuracy, setConfirmAccuracy] = useState(false)

  const handleChangeConfirmAccuracy = useCallback(() => {
    setConfirmAccuracy(state => !state)
  }, [])

  const privacyLink = legalDocs?.privacy_policy ? pick(legalDocs?.privacy_policy) : t('common:links.privacyPolicy')
  const termsLink = legalDocs?.user_agreement ? pick(legalDocs?.user_agreement) : t('common:links.termsOfUse')

  const confirmAccuracyLabelText = t('form.labels.confirmAccuracy', {
    privacyLink: `<a href="${privacyLink}" target=_blank>${t('labels:privacyPolicy')}</a>`,
    termsLink: `<a href="${termsLink}" target=_blank>${t('labels:termsOfUse')}</a>`,
  })

  return (
    <>
      <Styled.Wrapper>
        <Styled.Title>{t('form.sectionHeaders.customerExists')}</Styled.Title>
        <CustomerInfoShort customer={farmer} onClick={onSelect} hideFarmerInfoChecking={hideFarmerInfoChecking} />
        {isConfirmAccuracyEnable && (
          <Styled.ConfirmAccuracyWrapper>
            <Styled.Checkbox onChange={handleChangeConfirmAccuracy} isChecked={confirmAccuracy} />
            <Styled.ConfirmAccuracyLabel dangerouslySetInnerHTML={{ __html: confirmAccuracyLabelText }} />
          </Styled.ConfirmAccuracyWrapper>
        )}
      </Styled.Wrapper>
      <Footer
        onSubmit={onSelect}
        submitBtnText={t('form.select')}
        onCancel={onCancel}
        cancelBtnText={t('common:cancel')}
        submitDisabled={isConfirmAccuracyEnable && !confirmAccuracy}
      />
    </>
  )
}
export default CustomerExists
