import React from 'react'
import styled from 'styled-components'
import { Chip } from '@agro-club/frontend-shared'
import { useSeasonById } from 'modules/domain/season/hooks'
import { getPrettyDiapason, getPrettyPrice } from 'modules/utils/helpers'
import { HistoricQtyTierRule } from 'modules/domain/incentiveProgram/types'
import { MonetaryRuleProps } from './OrderSkuMonetaryDiscount'

export const DiscountChip = styled(Chip)`
  height: 48px;
  font-size: 14px;
  margin-right: 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HistoricQtyRule: React.VFC<MonetaryRuleProps<HistoricQtyTierRule>> = ({ tier, currency, plural }) => {
  const { is_applied, tier_rule: tierRule } = tier
  const [, season] = useSeasonById(tierRule?.historic_season_id)
  const amountAndQty = `${getPrettyPrice(+tierRule.amount, currency)} | ${getPrettyDiapason(
    tierRule.min_qty_historic,
    tierRule.max_qty_historic,
  )} ${plural}`
  const historicSeasonAndQty = `${season?.title} | ${getPrettyDiapason(tierRule.min_qty, tierRule.max_qty)} ${plural}`

  return (
    <DiscountChip active={is_applied} color="orange">
      <span>{amountAndQty}</span>
      <span>{historicSeasonAndQty}</span>
    </DiscountChip>
  )
}
