import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import {
  CallBackRequest,
  CallBackRequestListRequestSorting,
  CallBackRequestListRequestFilter,
  CallBackRequestDTO,
  CallBackRequestStatus,
} from './types'
import { ListResponse } from 'types/api'

export const getList = (
  filter: CallBackRequestListRequestFilter,
  sorting: CallBackRequestListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<CallBackRequest>>(endpoints.callBackRequest(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => {
  return apiClient.get<CallBackRequest>(endpoints.callBackRequest(id))
}

export const addItem = (dto: CallBackRequestDTO) => {
  return apiClient.post<CallBackRequest>(endpoints.callBackRequest(), dto)
}

export const updateItem = (id: string, dto: Partial<CallBackRequestDTO>) => {
  return apiClient.put<CallBackRequest>(endpoints.callBackRequest(id), dto)
}

export const removeItem = (id: string) => {
  return apiClient.delete<CallBackRequest>(endpoints.callBackRequest(id))
}

export const getPendingCount = async () => {
  const response = await apiClient.get<ListResponse<CallBackRequest>>(endpoints.callBackRequest(), {
    page_size: 1,
    status: CallBackRequestStatus.NEW,
  })
  return response.total_count
}
