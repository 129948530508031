import { createActionCreators, createReducerFunction } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import {
  Promocode,
  PromocodeDTO,
  PromocodeRequestFilter,
  PromocodeRequestSorting,
  PromocodeState,
} from 'modules/domain/promocode/types'
import { Progress } from 'modules/types'
import { BasicEntityImmerReducer } from 'modules/utils/helpers/BasicEntityImmerReducer'

const initialState: PromocodeState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,

  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
  listFetchErrorDetail: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  removeErrorDetail: null,
  addErrorDetail: null,
  updateErrorDetail: null,
}

class PromocodeReducer extends BasicEntityImmerReducer<
  Promocode,
  PromocodeRequestFilter,
  PromocodeRequestSorting,
  PromocodeDTO,
  PromocodeDTO,
  PromocodeState
> {}

export const PromocodeActions = createActionCreators(PromocodeReducer)
export default PromocodeActions
export const reducer = createReducerFunction(PromocodeReducer, initialState)
