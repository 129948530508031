import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import DistributorOrderSkuActions from 'modules/domain/distributorOrderSku/duck'
import DistributorOrderSkuSelectors from 'modules/domain/distributorOrderSku/selectors'
import { useSeasonList } from 'modules/domain/season/hooks'
import { CAPABILITY, PERMISSION, usePermissions, useRole } from 'modules/permissions/permissions'
import { Progress } from 'modules/types'
import {
  generateActionAccessString,
  generateCrmSectionAccessString,
  generateCustomFeatureFlag,
} from 'modules/utils/generateStringHelpers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Actions, FeatureFlagModifiers, isAgro, Sections } from 'types/entities'
import { SpinnerWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import DistributorOrderSkuRoutes from 'views/pages/DistributorOrderSku/routes'
import * as Header from 'views/ui/Header/Header'
import ReturnDeclarationSkuRoutes from '../ReturnDeclarationSku/routes'
import DistributorOrderSkuList from './components/DistributorOrderSkuList'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const DistributorOrderSkuTable: React.VFC = () => {
  const filter = useSelector(DistributorOrderSkuSelectors.filter)
  const filterUpdated = useAction(DistributorOrderSkuActions.filterUpdated)
  const { t } = useTranslation(['distributorOrder', 'returnDeclaration', 'common'])
  const [seasonsProgress] = useSeasonList()

  const checkFeatureFlag = useFeatureFlags()
  const push = useHistoryPush()
  const hasAccess = useCallback(flag => checkFeatureFlag(flag), [checkFeatureFlag])
  const role = useRole()
  const isReturnDeclarationEnabled =
    isAgro(role) || hasAccess(generateCrmSectionAccessString(Sections.ReturnDeclarations, FeatureFlagModifiers.Enabled))
  const isDeclarationCreateRestricted = checkFeatureFlag(
    generateActionAccessString(Sections.ReturnDeclarations, Actions.Create, FeatureFlagModifiers.Restricted),
  )

  const isAddOrderRestricted = hasAccess(
    generateCustomFeatureFlag(Sections.RetailerSkuOrders, 'restrictAddRetailerOrders'),
  )

  const isOrderCreatePermitted =
    usePermissions({
      capability: CAPABILITY.DISTRIBUTOR_ORDERS_SKU,
      permission: PERMISSION.C,
    }) && !isAddOrderRestricted

  useHelmet({ title: t('ordersMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Tab title={`${t('list.heading')}`} isActive>
            {isOrderCreatePermitted && (
              <AddButton data-test-id="distributor-sku-add" to={DistributorOrderSkuRoutes.Add} />
            )}
          </Header.Tab>
          {isReturnDeclarationEnabled && (
            <Header.Tab
              title={`${t('returnDeclaration:list.heading')}`}
              onClick={() => push({ route: ReturnDeclarationSkuRoutes.List })}
            >
              {!isDeclarationCreateRestricted && <AddButton to={ReturnDeclarationSkuRoutes.Add} />}
            </Header.Tab>
          )}
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput
            onChange={(search?: string) => {
              filterUpdated({ ...filter, ...{ search } })
            }}
            value={filter.search}
            placeholder={t('list.searchPlaceholder')}
          />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        {seasonsProgress === Progress.SUCCESS ? (
          <DistributorOrderSkuList />
        ) : (
          <SpinnerWrapper>
            <SpinnerLayout />
          </SpinnerWrapper>
        )}
      </Layout.Content>
    </>
  )
}

export default DistributorOrderSkuTable
