import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import ProductActions from './duck'
import ProductSelectors from './selectors'
import { Product, ProductListRequestParams } from './types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/product/managers'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { ProductListRequestFilter } from 'modules/domain/product/types'
import { createItemByIdOnceHook } from 'modules/utils/helpers/createItemByIdOnceHook'

export const useProductList: ResourceHook<Product[]> = (filter?: ProductListRequestFilter, pageSize?: number) => {
  const progress = useSelector(ProductSelectors.listFetchProgress)
  const list = useSelector(ProductSelectors.list)
  const page = usePageQuery()

  const params: ProductListRequestParams = {}
  if (page) params.page = page
  if (filter) params.filter = filter
  if (pageSize !== undefined) params.pageSize = pageSize

  const fetchAction = useAction(ProductActions.listRequested, params)

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useProduct: ResourceHook<Product, [string | undefined]> = (id?: string) => {
  const meta = useSelector(ProductSelectors.meta(id))
  const product = useSelector(ProductSelectors.item(id))
  const fetchAction = useAction(ProductActions.itemRequested, id)

  useDidMount(() => {
    if (id && (!product || id !== product.id)) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, product]
}

export const useProductById: ResourceHook<Product, [string | undefined]> = (id?: string) => {
  const meta = useSelector(ProductSelectors.meta(id))
  const item = useSelector(ProductSelectors.item(id))
  const fetchAction = useAction(ProductActions.itemRequested, id)

  useEffect(() => {
    if (id && (!item || id !== item.id)) {
      fetchAction()
    }
  }, [item, fetchAction, id])

  return [meta.fetchProgress, item]
}

export const useProductByIdOnce = createItemByIdOnceHook(ProductSelectors, ProductActions.itemRequested)

export const useProductsOptions = makeCancellableResourceListHook(makeCancelable(managers.getListOptions))
export const useProducts = makeCancellableResourceListHook(makeCancelable(managers.getList), true)
export const useProductsConditional = makeCancellableResourceListHook(makeCancelable(managers.getListConditional))
