import React from 'react'
import { RangeDatePicker, useAction } from '@agro-club/frontend-shared'
import { Filter as FilterComponent } from '../../../components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ChangeLogSelector from 'modules/domain/changeLog/selectors'
import { ChangeLogFilter } from 'modules/domain/changeLog/types'
import { isValid, parseISO } from 'date-fns'
import useDateFormat from 'hooks/useDateFormat'
import ChangeLogActions from 'modules/domain/changeLog/duck'

const PeriodFilter: React.FC = () => {
  const { t } = useTranslation('changeLog')
  const filterValue = useSelector(ChangeLogSelector.filter)
  const filterUpdated = useAction(ChangeLogActions.filterUpdated)
  const { from_date, to_date } = filterValue

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ChangeLogFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([from, to]) => {
      const parsedStartDate = parseISO(from)
      const parsedEndDate = parseISO(to)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ from_date: from, to_date: to })
      }
    },
    [handleFilterChange],
  )

  const dateFormat = useDateFormat({ isYearShort: true })
  return (
    <FilterComponent title={t('labels:period')}>
      <RangeDatePicker
        start={from_date || ''}
        end={to_date || ''}
        onChange={handleDateRangeChange}
        onClear={() => handleFilterChange({ from_date: undefined, to_date: undefined })}
        format={dateFormat}
        isClearable
      />
    </FilterComponent>
  )
}

export default PeriodFilter
