export type RefreshSchedule = {
  [key: string]: [string, string]
}

export const refreshSchedule: RefreshSchedule = {
  mon: ['08:00', '19:00'],
  tue: ['08:00', '19:00'],
  wed: ['08:00', '19:00'],
  thu: ['08:00', '19:00'],
  fri: ['08:00', '19:00'],
}

export const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export const checkSchedule = (schedule: RefreshSchedule): boolean => {
  const dayOfWeek = new Date().getDay()
  const day = schedule[weekDays[dayOfWeek]]

  if (!day) return false

  const curTime = new Date()
  const start = new Date()
  const end = new Date()
  const startHours = Number(day[0].split(':')[0])
  const startMins = Number(day[0].split(':')[1])
  const endHours = Number(day[1].split(':')[0])
  const endMins = Number(day[1].split(':')[1])

  start.setHours(startHours)
  start.setMinutes(startMins)
  end.setHours(endHours)
  end.setMinutes(endMins)

  if (curTime.getTime() >= start.getTime() && curTime.getTime() <= end.getTime()) {
    return true
  }

  return false
}
