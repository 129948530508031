import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import InventoryExchangeTransactionActions from './duck'
import InventoryExchangeTransactionSelectors from './selectors'
import { InventoryExchangeTransaction, RequestSource } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import InventoryExchangeRoutes from 'views/pages/InventoryExchange/routes'
import InventoryExchangeActions from '../inventoryExchange/duck'

export const fetchList = function*() {
  try {
    let currentPage = yield select(InventoryExchangeTransactionSelectors.page)
    const filter = yield select(InventoryExchangeTransactionSelectors.filter)
    yield put(InventoryExchangeTransactionActions.filtersUpdatedWithPersistentStorage(filter))

    const sorting = yield select(InventoryExchangeTransactionSelectors.sorting)
    const pageSize = yield select(InventoryExchangeTransactionSelectors.pageSize)

    let response: ListResponse<InventoryExchangeTransaction> = yield call(
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(InventoryExchangeTransactionActions.listRequestSucceed(data, total_count, page))

    // yield call(updateLocationQuery, InventoryTransactionRoutes.List, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.listRequestFailed(type, detail))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(InventoryExchangeTransactionSelectors.page)
    const filter = yield select(InventoryExchangeTransactionSelectors.filter)
    const sorting = yield select(InventoryExchangeTransactionSelectors.sorting)
    const pageSize = yield select(InventoryExchangeTransactionSelectors.pageSize)
    const { data, total_count }: { data: InventoryExchangeTransaction[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(InventoryExchangeTransactionActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.listRequestNextFailed(type, detail))
  }
}

export const fetchItem = function*({
  payload: id,
}: ReturnType<typeof InventoryExchangeTransactionActions.itemRequested>) {
  try {
    const item: InventoryExchangeTransaction = yield call(managers.getItem, id)
    yield put(InventoryExchangeTransactionActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function*({
  payload: [dto, requestSource],
}: ReturnType<typeof InventoryExchangeTransactionActions.addRequested>) {
  try {
    const item: InventoryExchangeTransaction = yield call(managers.addItem, dto)
    yield put(InventoryExchangeTransactionActions.addSucceed(item))
    if (requestSource === RequestSource.DETAILS_FORM) {
      // when a transaction is added via InventoryDetailsForm, we need a redirect to Edit page
      yield put(push(generatePath(InventoryExchangeRoutes.Edit, { id: item.id })))
    } else if (requestSource === RequestSource.MODAL) {
      // when a transaction is added via InventoryModal, we need to update total_qty value in InventoryTotalList
      yield put(InventoryExchangeActions.transactionAddSucceed(item))
    }
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function*({
  payload: [id, dto],
}: ReturnType<typeof InventoryExchangeTransactionActions.updateRequested>) {
  try {
    const item: InventoryExchangeTransaction = yield call(managers.updateItem, id, dto)
    yield put(InventoryExchangeTransactionActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function*({
  payload,
}: ReturnType<typeof InventoryExchangeTransactionActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(InventoryExchangeTransactionActions.removeSucceed(payload))

    const pagePath = generatePath(InventoryExchangeRoutes.TransactionsList)
    yield put(push(pagePath))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(InventoryExchangeTransactionActions.removeFailed(payload, type, detail))
  }
}

const InventoryTransactionSaga = function*() {
  yield all([
    takeLatest(InventoryExchangeTransactionActions.itemRequested.type, fetchItem),
    takeLatest(InventoryExchangeTransactionActions.listRequested.type, fetchList),
    takeLatest(InventoryExchangeTransactionActions.filterUpdated.type, fetchList),
    takeLatest(InventoryExchangeTransactionActions.sortingUpdated.type, fetchList),
    takeLatest(InventoryExchangeTransactionActions.filterHasBeenReset.type, fetchList),
    takeLatest(InventoryExchangeTransactionActions.sortingHasBeenReset.type, fetchList),

    takeLatest(InventoryExchangeTransactionActions.listRequestedNext.type, fetchListNext),

    takeLatest(InventoryExchangeTransactionActions.addRequested.type, addItem),
    takeLatest(InventoryExchangeTransactionActions.updateRequested.type, updateItem),
    takeLatest(InventoryExchangeTransactionActions.removeRequested.type, removeItem),
  ])
}

export default InventoryTransactionSaga
