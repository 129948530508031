import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { apiClient } from 'modules/utils/httpClient'
import {
  FccOrderSkuDTO,
  FccOrderSkuItem,
  FccOrderSkuListRequestFilter,
  FccOrderSkuListRequestSorting,
  FccOrderSkuUpdateDTO,
  FccOrdersSkuUpdateDTO,
  FccOrdersSkuUpdateResponse,
} from './types'

export default {
  ...createBasicManagers<
    FccOrderSkuListRequestFilter,
    FccOrderSkuListRequestSorting,
    FccOrderSkuItem,
    FccOrderSkuDTO,
    FccOrderSkuUpdateDTO
  >(endpoints.fccOrderSku),
  updateItems: (items: Partial<FccOrdersSkuUpdateDTO>[]) => {
    return apiClient.put<FccOrdersSkuUpdateResponse>(endpoints.farmerOrderSku(`items_by_ids`), {
      fcc_sku_items: items,
    })
  },
} as const
