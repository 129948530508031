import React, { useCallback, useState } from 'react'
import { Button, useAction, useHelmet } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ReconciliationGroupsTable from 'views/pages/Reconciliation/ReconciliationGroupsTable/ReconciliationGroupsTable'
import { IconDownload, AddButton, Modal } from '@agro-club/frontend-shared'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import { octopusApiClient } from 'modules/utils/httpClient'
import { useSelector } from 'react-redux'
import ReconciliationSelectors from 'modules/domain/reconciliation/selectors'
import styled from 'styled-components'
import { RetailerSelectManually } from './ReconciliationGroupsTable/RetailerSelectManually'
import ReconciliationActions from 'modules/domain/reconciliation/duck'
import { useCompanyById } from 'modules/domain/company/hooks'

const AddManuallyButton = styled(AddButton)`
  margin-left: 12px;
`

const RetailersModal = styled(Modal)`
  min-height: 330px;
`

const AddRetailerButton = styled(Button)`
  margin-top: 24px;
`

const ReconciliationGroupsPage: React.FC = () => {
  const { t } = useTranslation('reconciliation')
  useHelmet({ title: t('metaTitle') })
  const filterValue = useSelector(ReconciliationSelectors.groupsFilter)
  const filterUpdateAction = useAction(ReconciliationActions.groupsFilterUpdated)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [retailerId, setRetailerId] = useState<string | undefined>(undefined)
  const updateRetailerStatusAction = useAction(ReconciliationActions.retailerStatusUpdateRequested)
  const [, retailer] = useCompanyById(retailerId)

  const [progressCSV, downloadCSV] = useDownload(
    endpoints.programSummaryDownloadCSV(),
    filterValue,
    {},
    octopusApiClient,
  )

  const handleRetailerChange = useCallback(
    (retailer_id: string, producer_id: string) => {
      updateRetailerStatusAction({ manufacturer_id: producer_id, retailer_id, isForced: true })
      filterUpdateAction({ ...filterValue, retailer_id: [retailer_id] })
      setIsModalOpen(false)
      setRetailerId(undefined)
    },
    [filterUpdateAction, filterValue, updateRetailerStatusAction],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')}>
            <Button onClick={downloadCSV} intent={'primary'} size={'small'} filled progress={progressCSV}>
              <IconDownload />
              {t('labels:downloadCsv')}
            </Button>
            <AddManuallyButton
              text={t('addManually')}
              data-test-id={'button-add-retailer-manually'}
              onClick={() => setIsModalOpen(true)}
            />
          </Header.Title>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReconciliationGroupsTable />
      </Layout.Content>
      <RetailersModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size={'medium'}>
        <RetailerSelectManually
          label={t('labels:retailer')}
          placeholder={t('form.placeholders.retailer')}
          filter={{ season_id: filterValue.season_id, to_forced: true }}
          onChange={id => setRetailerId(id)}
          variant="small"
        />
        <AddRetailerButton
          onClick={() => handleRetailerChange(retailerId as string, retailer?.producers_relations[0] || '')}
          intent={'primary'}
          filled
          disabled={!retailerId}
        >
          {t('addRetailer')}
        </AddRetailerButton>
      </RetailersModal>
    </>
  )
}

export default ReconciliationGroupsPage
