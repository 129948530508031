import { RequestError } from 'modules/errors'
import { createBasicSagas } from 'modules/utils/helpers/createBasicSagas'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import actions from './duck'
import managers from './managers'
import selectors from './selectors'
import { FccOrdersSkuUpdateDTO, FccOrdersSkuUpdateResponse } from './types'

const sagas = createBasicSagas({
  managers,
  actions,
  selectors,
})

export const updateItems = function*({ payload: items }: ReturnType<typeof actions.updateItemsRequested>) {
  try {
    const request: FccOrdersSkuUpdateDTO[] = Object.values(items).map(item => {
      const notNullPrice = item.price === null ? '0.00' : item.price
      return {
        order_id: item.order_id || '',
        sku_item_id: item.id,
        price: notNullPrice,
        credit_facility: item.credit_facility,
      }
    })
    const response: FccOrdersSkuUpdateResponse = yield call(managers.updateItems, request)
    yield put(actions.updateItemsSucceed(response))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(actions.updateItemsFailed(type, detail))
  }
}

const OrderSaga = function*() {
  yield all([
    takeLatest(actions.listRequested.type, sagas.fetchList),
    takeLatest(actions.filterUpdated.type, sagas.fetchList),
    takeLatest(actions.sortingUpdated.type, sagas.fetchList),
    takeLatest(actions.filterHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.sortingHasBeenReset.type, sagas.fetchList),
    takeLatest(actions.listRequestedNext.type, sagas.fetchListNext),
    takeLatest(actions.updateItemsSucceed.type, sagas.fetchList),
    takeLatest(actions.updateItemsRequested.type, updateItems),
  ])
}

export default OrderSaga
