import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NumberInput } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { InventoryTransferRequest, TransferRequestStatuses } from 'modules/domain/inventoryInTransferRequest/types'
import RequestStatusColored from './RequestStatus'
import { ChangeableRequestData } from './InRequestModal'
import useDateFormatFn from 'hooks/useDateFormatFn'

export const ModalContent = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`

const Title = styled.span`
  margin: 3px 4px 3px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};
`
const InfoText = styled.span`
  margin: 3px 3px 3px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const TitleAndText = styled.div`
  display: flex;
`

const TitleAndInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr 0.5fr;
  grid-gap: 4px;
  align-items: center;
`

const AdditionalText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
  white-space: nowrap;
`
type Props = {
  rowData: InventoryTransferRequest
  onChangeRequestData?: Dispatch<SetStateAction<ChangeableRequestData>>
  changeableRequestData?: ChangeableRequestData
  isEditable?: boolean
}

const RequestModalInfo: React.VFC<Props> = ({ rowData, onChangeRequestData, changeableRequestData, isEditable }) => {
  const { t } = useTranslation('inventoryTransferRequestModal')
  const { pick } = useLangPicker()

  const price = rowData.price ?? rowData.sku.price
  const getFormatedDate = useDateFormatFn({ timeFormat: 'HH:mm:ss' })

  const dateStr = getFormatedDate(rowData.created_at)

  const onChangeQuantity = (value: number) => {
    onChangeRequestData && onChangeRequestData({ ...changeableRequestData, quantity: value })
  }

  const isRejectCommentVisible = rowData.status === TransferRequestStatuses.rejected && rowData.reject_comment

  return (
    <div>
      <TitleAndText>
        <Title>{`${t('labels.producer')}:`}</Title>
        <InfoText>{rowData.sku?.product?.producer?.official_name}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.company_from')}:`}</Title>
        <InfoText>{rowData.company_from.internal_name}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.company_to')}:`}</Title>
        <InfoText>{rowData.company_to.internal_name}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.product')}:`}</Title>
        <InfoText>{pick(rowData.sku?.product?.title_i18n) || rowData.sku?.product?.title}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.packageType')}:`}</Title>
        <InfoText>{rowData?.sku?.params?.package_title}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.sku')}:`}</Title>
        <InfoText>{rowData.sku.sku_id}</InfoText>
      </TitleAndText>
      {isEditable ? (
        <TitleAndInput>
          <Title>{`${t('labels.quantity')}:`}</Title>
          <NumberInput value={changeableRequestData?.quantity as number} onChange={onChangeQuantity} size="small" />
          {changeableRequestData?.quantity !== +rowData.quantity && (
            <AdditionalText>{t('labels.previousQuantity', { quantity: rowData.quantity })}</AdditionalText>
          )}
        </TitleAndInput>
      ) : (
        <TitleAndText>
          <Title>{`${t('labels.quantity')}:`}</Title>
          <InfoText>{rowData.quantity}</InfoText>
        </TitleAndText>
      )}
      {rowData.quantityAvailable && (
        <TitleAndText>
          <Title>{`${t('labels.quantityAvailable')}:`}</Title>
          <InfoText>{rowData.quantityAvailable}</InfoText>
        </TitleAndText>
      )}
      <TitleAndText>
        <Title>{`${t('labels.price')}:`}</Title>
        <InfoText>{price ?? t('labels.noPriceLabel')}</InfoText>
      </TitleAndText>
      <TitleAndText>
        <Title>{`${t('labels.status')}:`}</Title>
        <InfoText>
          <RequestStatusColored status={rowData.status} />
        </InfoText>
      </TitleAndText>
      {isRejectCommentVisible && (
        <TitleAndText>
          <Title>{`${t('labels.rejectReason')}:`}</Title>
          <InfoText>{rowData.reject_comment}</InfoText>
        </TitleAndText>
      )}
      <TitleAndText>
        <Title>{`${t('labels.createdAt')}:`}</Title>
        <InfoText>{dateStr}</InfoText>
      </TitleAndText>
    </div>
  )
}

export default RequestModalInfo
