import styled, { StyledProps } from 'styled-components'

export type AlignWrapperProps = {
  horizontalAlign: 'right' | 'left' | 'center'
  verticalAlign: 'top' | 'bottom' | 'center'
  gap?: string
}

export const AlignWrapper = styled.div<StyledProps<AlignWrapperProps>>`
  ${props => {
    let result: any = {}
    switch (props.horizontalAlign) {
      case 'left':
        result = {
          display: 'flex',
          marginRight: 'auto',
          marginTop: 'auto',
          justifySelf: 'flex-start',
        }
        break
      case 'right':
        result = {
          display: 'flex',
          marginLeft: 'auto',
          marginTop: 'auto',
          justifySelf: 'flex-end',
        }
        break
      case 'center':
        result = {
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          justifySelf: 'center',
        }
        break
    }
    switch (props.verticalAlign) {
      case 'top':
        result.marginBottom = 'auto'
        result.alignItems = 'flex-start'
        break
      case 'bottom':
        result.marginTop = 'auto'
        result.alignItems = 'flex-end'
        break
      case 'center':
        result.marginBottom = 'auto'
        result.marginTop = 'auto'
        result.alignItems = 'center'
        break
    }
    result.gap = props.gap
    return result
  }}
`
