import { Progress, ResourceHook } from 'modules/types'
import { isNil } from 'ramda'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { FarmerOrderItem } from 'types/farmerOrder'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import { ProductLimit, ProductLimitRequestFilter, RequestDTO } from './common/types'
import { retailerManagers } from './retailerAllocation/managers'

const isSkuItem = (item: FarmerOrderItem | FarmerOrderSkuItem): item is FarmerOrderSkuItem => {
  return (item as FarmerOrderSkuItem).card_id !== undefined
}

export const useProductsLimit: ResourceHook<ProductLimit[], ['distributor' | 'farmer', RequestDTO]> = (
  orderType: 'distributor' | 'farmer',
  params: RequestDTO,
) => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [productsLimit, setProductsLimit] = useState<ProductLimit[]>()

  const fetch = useCallback(
    async (params: ProductLimitRequestFilter) => {
      setProgress(Progress.WORK)
      try {
        const { items } =
          orderType === 'farmer'
            ? await retailerManagers.farmerOrdersProductLimit(params)
            : await retailerManagers.retailerOrdersProductLimit(params)
        setProductsLimit(items)
        setProgress(Progress.SUCCESS)
      } catch (e) {
        setProgress(Progress.ERROR)
      }
    },
    [orderType],
  )

  useEffect(() => {
    if (!params.products.length || !params.distributorId || !params.seasonId) {
      return
    }

    fetch({ items: params.products, distributor_id: params.distributorId, season_id: params.seasonId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.products, params.distributorId, params.seasonId])

  return [progress, productsLimit]
}

export const useProductAllocatedLimit = (item: FarmerOrderItem | FarmerOrderSkuItem, productsLimit: ProductLimit[]) => {
  const productId = isSkuItem(item) ? item.card?.product_id : item.product_id

  return useMemo(() => {
    return productsLimit?.find(product => product.product_id === productId && !isNil(product.allowed_quantity))
  }, [productsLimit, productId])
}

export const useProductQtyAllocatedChecks = (
  item: FarmerOrderItem | FarmerOrderSkuItem,
  itemCommonQty: number,
  itemCommonInitialQty: number,
  mode: 'create' | 'edit',
  productsLimit: ProductLimit[],
) => {
  const productAllocatedLimit = useProductAllocatedLimit(item, productsLimit)

  if (!productAllocatedLimit) return {}

  const isProductQtyMoreThanLimit = productAllocatedLimit && productAllocatedLimit.allowed_quantity < itemCommonQty

  const isProductDifferenceQtyMoreThanLimit =
    productAllocatedLimit && productAllocatedLimit.allowed_quantity < itemCommonQty - itemCommonInitialQty

  const isProductQtyEqualLimit =
    productAllocatedLimit &&
    ((mode === 'create' && productAllocatedLimit.allowed_quantity === itemCommonQty) ||
      (mode === 'edit' && productAllocatedLimit.allowed_quantity === itemCommonQty - itemCommonInitialQty))

  return { isProductQtyMoreThanLimit, isProductDifferenceQtyMoreThanLimit, isProductQtyEqualLimit }
}

export const useMaxQty = (
  item: FarmerOrderItem | FarmerOrderSkuItem,
  mode: 'create' | 'edit',
  itemCommonQty: number,
  itemCommonInitialQty: number,
  isAdmin: boolean,
  productsLimit: ProductLimit[],
) => {
  const qtyNumber = Number(item.quantity)
  const productAllocatedLimit = useProductAllocatedLimit(item, productsLimit)
  const productAllowedQty = productAllocatedLimit?.allowed_quantity
  const itemCommonInitialQtyValue = itemCommonInitialQty || 0
  const commonAndCurrentQtyDiff = itemCommonQty - qtyNumber

  if (isAdmin || isNil(productAllowedQty)) return undefined

  if (productAllowedQty === 0) {
    if (mode === 'create') return 0

    return itemCommonInitialQty - commonAndCurrentQtyDiff || 0
  }

  if (itemCommonQty < productAllowedQty + itemCommonInitialQtyValue)
    return productAllowedQty + itemCommonInitialQtyValue - commonAndCurrentQtyDiff

  if (itemCommonQty === productAllowedQty + itemCommonInitialQtyValue) return qtyNumber

  if (itemCommonQty > productAllowedQty + itemCommonInitialQtyValue) {
    if (productAllowedQty < 0) return 0
    else productAllowedQty + itemCommonInitialQtyValue
  }

  return productAllowedQty
}

export const useQty = (
  mode: 'create' | 'edit',
  itemQty: number,
  isAdmin: boolean,
  isProductDifferenceQtyMoreThanLimit?: boolean,
  isProductQtyMoreThanLimit?: boolean,
  productAllocatedLimit?: ProductLimit,
) => {
  if (isAdmin) return itemQty

  if (
    (mode === 'create' && isProductQtyMoreThanLimit) ||
    (mode === 'edit' && productAllocatedLimit?.is_limit_exceeded) ||
    isProductDifferenceQtyMoreThanLimit
  )
    return productAllocatedLimit?.allowed_quantity || 0

  return itemQty
}
