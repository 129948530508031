import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet } from '@agro-club/frontend-shared'
import IncentiveProgramRoutes from './routes'
import { useIncentiveProgram } from 'modules/domain/incentiveProgram/hooks'
import IncentiveProgramActions from 'modules/domain/incentiveProgram/duck'
import IncentiveProgramSelectors from 'modules/domain/incentiveProgram/selectors'
import IncentiveProgramDetailsForm, {
  IncentiveProgramFormProps,
} from './components/IncentiveProgramDetailsForm/IncentiveProgramDetailsForm'
import { useEditLayout } from 'hooks/useEditLayout'

const IncentiveProgramEdit: React.VFC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [fetchProgress, incentiveProgram] = useIncentiveProgram(params.id)
  const submitAction = useAction(IncentiveProgramActions.updateRequested)
  const updateProgress = useSelector(IncentiveProgramSelectors.updateProgress)
  const removeAction = useAction(IncentiveProgramActions.removeRequested, params.id)
  const removeProgress = useSelector(IncentiveProgramSelectors.removeProgress)
  const { t } = useTranslation('report')

  useHelmet({ title: incentiveProgram ? incentiveProgram.title : t('incentiveProgramMetaTitle') })

  const handleFormSubmit = (props: IncentiveProgramFormProps) => {
    submitAction(incentiveProgram?.id, props)
  }

  const goBack = () => {
    history.push(IncentiveProgramRoutes.List)
  }

  const layout = useEditLayout({
    id: params.id,
    fetchProgress,
    entityName: t('addIncentiveProgramMetaTitle'),
    goBack,
  })

  if (layout || !incentiveProgram) return layout

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={incentiveProgram.title} />
          <Header.Id>{`ID ${incentiveProgram.id}`}</Header.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <IncentiveProgramDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          editing={true}
          onRemove={removeAction}
          removeProgress={removeProgress}
          initialValues={{
            ...incentiveProgram,
            type: incentiveProgram.tiers_rules[0].type,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default IncentiveProgramEdit
