import React, { useState } from 'react'
import * as Styled from './styled'
import { useSelector } from 'react-redux'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import DocumentActions from 'modules/domain/document/duck'
import { UploadingFile } from 'modules/domain/uploadManager/types'
import { useTranslation } from 'react-i18next'
import { Button, FileDropzone, Modal, ProgressCircle, useAction } from '@agro-club/frontend-shared'

const UploadingInfo: React.FC<{ file: UploadingFile; onRetry(): void; onClose(): void }> = ({
  file,
  onRetry,
  onClose,
}) => {
  const { t } = useTranslation('FarmerDocumentUploadModal')
  if (file.status === 'uploading') {
    return <ProgressCircle percent={file.percent} />
  }
  if (file.status === 'complete') {
    return (
      <Styled.UploadCompleteContainer>
        {t('uploadSuccessText')}
        <Styled.ButtonsContainer>
          <Button size={'small'} intent={'action'} onClick={onRetry}>
            {t('uploadOtherFile')}
          </Button>
          <Button size={'small'} intent={'primary'} filled onClick={onClose}>
            {t('uploadDone')}
          </Button>
        </Styled.ButtonsContainer>
      </Styled.UploadCompleteContainer>
    )
  }

  if (file.status === 'error') {
    return (
      <Styled.UploadErrorContainer>
        {t('uploadErrorText')}
        <Button intent={'action'} onClick={onRetry}>
          {t('uploadRetry')}
        </Button>
      </Styled.UploadErrorContainer>
    )
  }

  return null
}

const FarmerDocumentUploadModal: React.FC<{
  userId: string
  documentId: string
  isOpen: boolean
  onClose: () => void
}> = ({ userId, documentId, isOpen, onClose }) => {
  const id = `${userId}-${documentId}`
  const uploadingFileInfo = useSelector(UploadManagerSelectors.file(id))
  const startUploadAction = useAction(DocumentActions.uploadFarmerDocumentRequested)
  const [isRetry, setIsRetry] = useState(false)

  const handleChange = (files: File[]) => {
    const file = files?.[0]
    if (!file) {
      return
    }
    startUploadAction(userId, documentId, file)
    setIsRetry(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'medium'}>
      <Styled.InnerContainer>
        {uploadingFileInfo && !isRetry ? (
          <UploadingInfo file={uploadingFileInfo} onRetry={() => setIsRetry(true)} onClose={onClose} />
        ) : (
          <FileDropzone onDrop={handleChange} accept={'application/pdf'} maxFiles={1} />
        )}
      </Styled.InnerContainer>
    </Modal>
  )
}

export default FarmerDocumentUploadModal
