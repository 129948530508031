import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHelmet, useHistoryPush, SearchInput, useAction } from '@agro-club/frontend-shared'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import ProductSettingsList from './ProductSettingsList'
import Routes from './routes'
import ProductSettingsActions from 'modules/domain/productSettings/duck'
import ProductSettingsSelectors from 'modules/domain/productSettings/selectors'
import styled from 'styled-components'

export const SearchInputWrapper = styled.div`
  width: 50%;
  padding: 18px 24px;
`

const ProductSettingsTable: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation('cards')
  useHelmet({ title: t('metaTitle') })

  const filterUpdated = useAction(ProductSettingsActions.filterUpdated)
  const filterValue = useSelector(ProductSettingsSelectors.filter)

  const onSearchChange = (val: string) => {
    filterUpdated({ ...filterValue, search: val })
  }

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Tab title={t('productSetting')} onClick={() => push({ route: Routes.List })} />
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput
            onChange={onSearchChange}
            value={filterValue.search}
            placeholder={t('searchPlaceholder')}
            data-test-id="card-search"
          />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <ProductSettingsList />
      </Layout.Content>
    </>
  )
}

export default ProductSettingsTable
