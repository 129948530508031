import React from 'react'
import { FormikHook, Input } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { LocalizedValue } from 'types/entities'
import * as Styled from './styled'

export type ContactsFormProps = {
  phone_title: string
  email_title: string
}

const ContactsForm: React.FC<{
  lang: keyof LocalizedValue
  phone_title?: LocalizedValue
  email_title?: LocalizedValue
  useFormik: FormikHook
}> = ({ lang, phone_title = {}, email_title = {}, useFormik }) => {
  const { t } = useTranslation(['license', 'common'])

  const formik = useFormik<ContactsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      phone_title: phone_title[lang] || '',
      email_title: email_title[lang] || '',
    },
    enableReinitialize: true,
  })

  return (
    <Styled.VerticalContainer>
      <Styled.TitleArea>
        <Input
          {...formik.getFieldProps('phone_title')}
          invalid={formik.touched.phone_title && !!formik.errors.phone_title}
          label={t('form.labels.phoneTitle', { lang: `(${lang})` })}
        />
      </Styled.TitleArea>
      <Styled.TitleArea>
        <Input
          {...formik.getFieldProps('email_title')}
          invalid={formik.touched.email_title && !!formik.errors.email_title}
          label={t('form.labels.emailTitle', { lang: `(${lang})` })}
        />
      </Styled.TitleArea>
    </Styled.VerticalContainer>
  )
}

export default ContactsForm
