import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/User/UserDetailsForm/types'
import { CompanyType } from 'types/entities'
import { useCompanyList } from 'views/pages/User/UserDetailsForm/hooks'
import PermissionSegment from 'views/pages/User/UserDetailsForm/PermissionSegment'
import { Permissions } from 'types/entities'
import { SwitchButton, FormComponents } from '@agro-club/frontend-shared'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'
import { CompanyWrapper } from './styled'

const UserTypeSection: React.FC = () => {
  const { t } = useTranslation(['user', 'common', 'validation'])
  const formik = useFormikContext<FormFields>()

  const showCompanySelect = formik.values.companyType === CompanyType.Producer
  const showCompanyMultiSelect = showCompanySelect && formik.values.permission === Permissions.MANAGER
  const showDistributorSelect = formik.values.companyType === CompanyType.Distributor
  const companyTypesList = useCompanyList()

  const handleRelatedCompaniesChange = (ids, companies) => {
    formik.setFieldValue(
      'relatedCompanies',
      companies.map(e => ({ company_id: e.id, company_type: e.company_type })),
    )
  }

  return (
    <FormComponents.FormSection title={t('form.userType')}>
      <SwitchButton
        options={companyTypesList}
        value={formik.values.companyType}
        onChange={id => {
          formik.setFieldValue('companyType', id)
        }}
      />
      <PermissionSegment />
      <CompanyWrapper>
        {showCompanySelect && (
          <CompanySelect
            companyType={CompanyType.Producer}
            label={t('form.company')}
            value={formik.values.company as string}
            onChange={v => {
              formik.setFieldValue('company', v)
              if (formik.values.company !== v) {
                formik.setFieldValue('territories', [])
              }
            }}
            isSearchable
          />
        )}
        {showCompanyMultiSelect && (
          <CompanyMultiSelect
            isSearchable
            isClearable
            label={t('form.extraDistributors')}
            values={formik.values.relatedCompanies?.map(e => e.company_id)}
            onChange={handleRelatedCompaniesChange}
          />
        )}
      </CompanyWrapper>
      <>
        {showDistributorSelect && (
          <>
            <CompanySelect
              companyType={CompanyType.Distributor}
              label={t('form.distributor')}
              value={formik.values.distributor as string}
              isSearchable
              onChange={v => {
                formik.setFieldValue('distributor', v)
                formik.setFieldValue('territories', [])
              }}
              width="430px"
            />
            <CompanyMultiSelect
              isSearchable
              isClearable
              label={t('form.extraDistributors')}
              values={formik.values.relatedCompanies?.map(e => e.company_id)}
              onChange={handleRelatedCompaniesChange}
              width="430px"
            />
          </>
        )}
      </>
    </FormComponents.FormSection>
  )
}

export default UserTypeSection
