import React from 'react'

import { Switch, Route } from 'react-router-dom'
import FarmerPlansRoutes from './routes'
import UnderMaintenancePage from '../UnderMaintenance/UnderMaintenancePage'

const FarmerPlansPage: React.FC = () => {
  return (
    <Switch>
      <Route path={FarmerPlansRoutes.List}>
        <UnderMaintenancePage />
      </Route>
    </Switch>
  )
}

export default FarmerPlansPage
