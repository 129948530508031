enum ProductRoutes {
  List = '/products',
  Edit = '/products/edit/:id',
  Add = '/products/add',
  OptionsList = '/products/options',
  OptionsEdit = '/products/options/edit/:id',
  OptionsAdd = '/products/options/add',
}

export default ProductRoutes
