import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import { Progress, useAction, useDidMount } from '@agro-club/frontend-shared'
import ConfigSelectors from './selectors'
import ConfigActions from './duck'
import { WhiteLabelConfig } from './types'

export const useConfig: ResourceHook<WhiteLabelConfig> = () => {
  const progress = useSelector(ConfigSelectors.fetchProgress)
  const config = useSelector(ConfigSelectors.config)
  const fetchAction = useAction(ConfigActions.configRequested, window.location.hostname)

  useDidMount(() => {
    if (!Object.keys(config).length && progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, config]
}
