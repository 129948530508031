import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import DiscountRuleActions from './duck'
import DiscountRuleSelectors from './selectors'
import { CalculatedDiscountResponse, CalculateParamsDTO, DiscountRule } from './types'
import { Progress, useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { calculateDiscount } from './managers'

export const useDiscountRuleList: ResourceHook<DiscountRule[], void[]> = () => {
  const progress = useSelector(DiscountRuleSelectors.listFetchProgress)
  const list = useSelector(DiscountRuleSelectors.list)
  const filters = useSelector(DiscountRuleSelectors.filter)
  const page = usePageQuery()
  const fetchAction = useAction(DiscountRuleActions.listRequested, {
    page: page ? page : undefined,
    filter: filters,
  })

  useDidMount(fetchAction)

  return [progress, list]
}

export const useDiscountRule: ResourceHook<DiscountRule, [string]> = (id: string) => {
  const meta = useSelector(state => DiscountRuleSelectors.meta(state, id))
  const item = useSelector(state => DiscountRuleSelectors.item(state, id))
  const fetchAction = useAction(DiscountRuleActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useDiscountRuleById: ResourceHook<DiscountRule, [string]> = (id: string) => {
  const meta = useSelector(state => DiscountRuleSelectors.meta(state, id))
  const item = useSelector(state => DiscountRuleSelectors.item(state, id))
  const fetchAction = useAction(DiscountRuleActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useCalculatedDiscounts: ResourceHook<CalculatedDiscountResponse, [CalculateParamsDTO]> = (
  params: CalculateParamsDTO,
) => {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [calculatedDiscountDto, setCalculatedDiscountDto] = useState<CalculatedDiscountResponse>()
  const timerId = useRef(0)

  useEffect(() => {
    if (!params.producer_id || !params.season_id || (params.entries && !params.entries.length)) {
      return
    }

    const fetch = async (params: CalculateParamsDTO) => {
      setProgress(Progress.WORK)
      try {
        const response = await calculateDiscount(params)
        setCalculatedDiscountDto(response)
        setProgress(Progress.SUCCESS)
      } catch (e) {
        setProgress(Progress.ERROR)
      }
    }

    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    timerId.current = window.setTimeout(() => {
      timerId.current = 0
      fetch(params)
    }, 300)
  }, [params])

  return [progress, calculatedDiscountDto]
}
