import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PromocodeSelectors from 'modules/domain/promocode/selectors'
import PromocodeRoutes from 'views/pages/Promocode/routes'
import PromocodeDetailsForm, { FormProps } from 'views/pages/Promocode/PromocodeDetailsForm/PromocodeDetailsForm'
import PromocodeActions from 'modules/domain/promocode/duck'
import { PromocodeType } from 'modules/domain/promocode/types'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'

const PromocodeAdd: React.FC = () => {
  const { t } = useTranslation(['promocode', 'common'])
  const push = useHistoryPush()
  const page = useSelector(PromocodeSelectors.page)
  const addProgress = useSelector(PromocodeSelectors.addProgress)
  const addErrorDetail = useSelector(PromocodeSelectors.addErrorDetail)
  const goBack = () => push({ route: PromocodeRoutes.List, query: { page } })
  const submitAction = useAction(PromocodeActions.addRequested)

  const handleFormSubmit = (formProps: FormProps) => {
    const { type, legal_text_i18n, prompt_i18n } = formProps.params
    switch (type) {
      case PromocodeType.WithComment:
        submitAction({ ...formProps, params: { type, prompt_i18n: prompt_i18n || null } })
        break
      case PromocodeType.WithLegalText:
        submitAction({ ...formProps, params: { type, legal_text_i18n: legal_text_i18n || null } })
        break
      case PromocodeType.Simple:
        submitAction({ ...formProps, params: { type } })
    }
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.headingAdd')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <PromocodeDetailsForm
          onSubmit={handleFormSubmit}
          onCancel={goBack}
          progress={addProgress}
          errorText={addErrorDetail}
        />
      </Layout.Content>
    </>
  )
}

export default PromocodeAdd
