import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Uptake } from './types'
import { EntityMetadata } from 'modules/domain/types'

const dict = (state: AppGlobalState) => state.uptake.items
const ids = (state: AppGlobalState) => state.uptake.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Uptake | undefined => state.uptake.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Uptake> =>
  state.uptake.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.uptake.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.uptake.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.uptake.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.uptake.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.uptake.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Uptake[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.uptake.filter
const sorting = (state: AppGlobalState) => state.uptake.sorting
const page = (state: AppGlobalState) => state.uptake.page
const total = (state: AppGlobalState) => state.uptake.total
const updateProgress = (state: AppGlobalState) => state.uptake.updateProgress
const removeProgress = (state: AppGlobalState) => state.uptake.removeProgress
const addProgress = (state: AppGlobalState) => state.uptake.addProgress
const hasNext = (state: AppGlobalState) => state.uptake.total > state.uptake.ids.length
const pageSize = (state: AppGlobalState) => state.uptake.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.uptake.total / state.uptake.pageSize)
const pendingFetchProgress = (state: AppGlobalState) => state.uptake.pendingUptakeFetchProgress
const pendingCount = (state: AppGlobalState) => state.uptake.pendingUptakeCount

const UptakeSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  pendingFetchProgress,
  pendingCount,
}

export default UptakeSelectors
