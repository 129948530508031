import React, { useCallback, useMemo, useState } from 'react'
import * as Styled from './styled'
import { Currency, FormField, FormikHook, Input, NumberInput } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FieldMetaProps } from 'formik/dist/types'
import { PromocodeTierRule } from 'modules/domain/discountRule/types'
import { useCompanyById } from 'modules/domain/company/hooks'

type DiscountTierRule = Partial<Omit<PromocodeTierRule, 'type'>>

type PromocodeTiersRulesFormProps = {
  tiers_rules: DiscountTierRule[]
  promocode: string
}

const PromocodeTiersRulesForm: React.FC<{
  useFormik: FormikHook
  tiers_rules: DiscountTierRule[]
  producerId: string
}> = ({ tiers_rules, producerId, useFormik }) => {
  const { t } = useTranslation('discountRules')
  const [, producer] = useCompanyById(producerId)
  const promocode = useMemo(() => (tiers_rules.length ? tiers_rules[0].promocode || '' : ''), [tiers_rules])

  const validationSchema = useMemo(() => {
    return Yup.object({
      promocode: Yup.string().required(t('validation:field_required')),
      tiers_rules: Yup.array(
        Yup.object({
          min_qty: Yup.number()
            .min(1, t('validation:numberLessThanMin', { min: 1 }))
            .required(t('validation:field_required')),
          amount: Yup.string().required(t('validation:field_required')),
          promocode: Yup.string().required(),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<PromocodeTiersRulesFormProps>({
    initialValues: {
      tiers_rules,
      promocode,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('tiers_rules', remove(idx, 1, formik.values.tiers_rules))
    },
    [formik],
  )

  const handleAddClick = useCallback(() => {
    formik.setFieldValue('tiers_rules', [...formik.values.tiers_rules, { promocode: formik.values.promocode }])
  }, [formik])

  const handlePromocodeChange = useCallback(
    (_, value) => {
      formik.setFieldValue('promocode', value)

      formik.values.tiers_rules.forEach((_, idx) => {
        formik.setFieldValue(`tiers_rules[${idx}].promocode`, value)
      })
    },
    [formik],
  )

  const handleNumberInputChange = useCallback(
    (field, value) => {
      formik.setFieldValue(field, value)
      formik.setFieldTouched(field)
    },
    [formik],
  )

  return (
    <Styled.ParamsContainer data-test-id={'params-form'}>
      <Styled.Container>
        <Input
          {...formik.getFieldProps('promocode')}
          label={t('form.labels.promocode')}
          placeholder={t('form.placeholders.promocode')}
          onChange={handlePromocodeChange}
          invalid={formik.getFieldMeta('promocode').touched && !!formik.getFieldMeta('promocode').error}
          errorText={formik.getFieldMeta('promocode').error}
        />
      </Styled.Container>
      {formik.values.tiers_rules.map((_, idx) => {
        const minKey = `tiers_rules[${idx}].min_qty`
        const maxKey = `tiers_rules[${idx}].max_qty`
        const amountKey = `tiers_rules[${idx}].amount`

        const getFieldMeta = (field: string): FieldMetaProps<PromocodeTiersRulesFormProps> =>
          formik.getFieldMeta(`tiers_rules[${idx}].${field}`)

        return (
          <Styled.Container key={idx}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <FormField
              invalid={getFieldMeta('min_qty').touched && !!getFieldMeta('min_qty').error}
              errorText={getFieldMeta('min_qty').error}
              label={t('form.labels.minQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(minKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(minKey, value)}
                  min={1}
                />
              )}
              required
            />
            <FormField
              invalid={getFieldMeta('max_qty').touched && !!getFieldMeta('max_qty').error}
              errorText={getFieldMeta('max_qty').error}
              label={t('form.labels.maxQty')}
              render={_ => (
                <NumberInput
                  {...formik.getFieldProps(maxKey)}
                  size={'medium'}
                  onChange={value => handleNumberInputChange(maxKey, value)}
                  min={0}
                />
              )}
            />

            <FormField
              invalid={getFieldMeta('amount').touched && !!getFieldMeta('amount').error}
              errorText={getFieldMeta('amount').error}
              label={t('form.labels.amount')}
              render={_ => (
                <Styled.CurrencyInput
                  {...formik.getFieldProps(amountKey)}
                  value={formik.values.tiers_rules[idx].amount || ''}
                  placeholder={t('form.placeholders.amount')}
                  currency={producer?.currency || Currency.CAD}
                  onChange={val => formik.setFieldValue(amountKey, val)}
                />
              )}
              required
            />
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <Styled.AddButton onClick={handleAddClick} type={'button'}>
        {t('form.addMoreTiers')}
      </Styled.AddButton>
    </Styled.ParamsContainer>
  )
}

export default PromocodeTiersRulesForm
