import { useSelector } from 'react-redux'
import { useAction, useDidMount } from '@agro-club/frontend-shared'

import { ResourceHook } from 'modules/types'

import { License } from './types'
import LicenseActions from './duck'
import LicenseSelectors from './selectors'

export const useLicenseList: ResourceHook<License[], void[]> = () => {
  const progress = useSelector(LicenseSelectors.listFetchProgress)
  const list = useSelector(LicenseSelectors.list)
  const fetchAction = useAction(LicenseActions.listRequested, {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useLicense: ResourceHook<License, [string]> = (id: string) => {
  const progress = useSelector(LicenseSelectors.itemFetchProgress)
  const item = useSelector(state => LicenseSelectors.item(state, id))
  const fetchAction = useAction(LicenseActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [progress, item]
}
