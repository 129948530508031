import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { ReconciliationList } from './types'

const ids = (state: AppGlobalState) => state.reconciliation.ids
const group = (state: AppGlobalState, id: string): ReconciliationList | undefined => state.reconciliation.groups[id]

const groups = (state: AppGlobalState) => state.reconciliation.groups
const groupsFetchProgress = (state: AppGlobalState) => state.reconciliation.groupsFetchProgress
const groupsFetchError = (state: AppGlobalState) => state.reconciliation.groupsFetchError
const groupsFetchErrorDetail = (state: AppGlobalState) => state.reconciliation.groupsFetchErrorDetail
const groupsFilter = (state: AppGlobalState) => state.reconciliation.groupsFilter
const groupsSorting = (state: AppGlobalState) => state.reconciliation.groupsSorting
const groupsPage = (state: AppGlobalState) => state.reconciliation.groupsPage
const groupsTotal = (state: AppGlobalState) => state.reconciliation.groupsTotal
const groupsHasNext = (state: AppGlobalState) => state.reconciliation.groupsTotal > state.reconciliation.ids.length
const groupsPageSize = (state: AppGlobalState) => state.reconciliation.groupsPageSize
const groupsPages = (state: AppGlobalState) =>
  Math.ceil(state.reconciliation.groupsTotal / state.reconciliation.groupsPageSize)

const groupsFetchNextProgress = (state: AppGlobalState) => state.reconciliation.groupsFetchNextProgress
const groupsFetchNextError = (state: AppGlobalState) => state.reconciliation.groupsFetchNextError
const groupsFetchNextErrorDetail = (state: AppGlobalState) => state.reconciliation.groupsFetchNextErrorDetail

const list = createSelector(groups, ids, groupsFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: ReconciliationList[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const batchUpdateProgress = (state: AppGlobalState) => state.reconciliation.batchUpdateProgress
const batchUpdateError = (state: AppGlobalState) => state.reconciliation.batchUpdateError

const ReconciliationSelectors = {
  group,
  list,
  groups,
  groupsFetchProgress,
  groupsFetchError,
  groupsFetchErrorDetail,
  groupsFilter,
  groupsSorting,
  groupsPage,
  groupsTotal,
  groupsHasNext,
  groupsPageSize,
  groupsPages,
  groupsFetchNextProgress,
  groupsFetchNextError,
  groupsFetchNextErrorDetail,
  batchUpdateProgress,
  batchUpdateError,
}

export default ReconciliationSelectors
