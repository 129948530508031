import managers from 'modules/domain/programSummary/managers'
import { ProgramSummaryFarmer } from 'modules/domain/programSummary/types'
import React from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'

export const getFarmerLabel = (item: ProgramSummaryFarmer) =>
  `${item.farmer_name.first_name} ${item.farmer_name.last_name}`

export const getFarmerValue = (item: ProgramSummaryFarmer) => item.farmer_id

export const FarmerSelect: React.VFC<EntityMultiSelectProps<ProgramSummaryFarmer>> = ({ ...props }) => {
  return (
    <SelectTestWrapper data-test-id="farmer-select">
      <EntityMultiSelect
        getEntityByIds={managers.getFarmersByIds}
        getEntityList={managers.getFarmers}
        searchParamKey="name"
        getOptionLabel={getFarmerLabel}
        getOptionValue={getFarmerValue}
        {...props}
      />
    </SelectTestWrapper>
  )
}
