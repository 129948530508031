import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, useAction } from '@agro-club/frontend-shared'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyType } from 'types/entities'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { useSelector } from 'react-redux'
import RetailerAllocationSelectors from 'modules/domain/allocation/retailerAllocation/selectors'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import { TerritorySelect } from 'views/components/TerritorySelect/TerritorySelect'
import styled from 'styled-components'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { AllocationRequestFilter } from 'modules/domain/allocation/common/types'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import AuthSelectors from 'modules/domain/auth/selectors'
import TerritoryAllocationSelectors from 'modules/domain/allocation/territoryAllocation/selectors'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 750px;
  max-width: 970px;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

export const Buttons = styled.div`
  align-items: flex-end;
  margin: 0 16px 16px 0;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
`

export type AllocationFilterProps = {
  activeTab: string
}

const AllocationFilters: React.FC<AllocationFilterProps> = ({ activeTab }) => {
  const { t } = useTranslation('allocation')
  const filterValue = useSelector(RetailerAllocationSelectors.filter)
  const filterUpdated = useAction(RetailerAllocationActions.filterUpdated)
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const userCompany = useSelector(AuthSelectors.userCompany)

  const selectors = activeTab === 'retailer' ? RetailerAllocationSelectors : TerritoryAllocationSelectors
  const { sort_field, sort_reversed } = useSelector(selectors.sorting)

  const handleFilterChange = (newFilterValue: Partial<AllocationRequestFilter>) => {
    filterUpdated({ ...filterValue, ...newFilterValue })
  }

  const endpoint = activeTab === 'retailer' ? endpoints.retailerAllocation : endpoints.territoryAllocation

  const [progressXLSX, downloadXLSX] = useDownload(endpoint(`download/xlsx`), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const [progressCSV, downloadCSV] = useDownload(endpoint(`download/csv`), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('labels.producer')}>
            <CompanySelect
              placeholder={t('placeholders.producer')}
              value={filterValue.producer_id?.[0]}
              onChange={val =>
                handleFilterChange({
                  producer_id: val ? [val] : [],
                })
              }
              companyType={CompanyType.Producer}
              isClearable
              isSearchable
              variant="small"
            />
          </Filter>
          {activeTab !== 'retailer' && (
            <Filter title={t('labels.territory')}>
              <TerritorySelect
                onChange={val => handleFilterChange({ territory_id: val ? [val] : [] })}
                value={filterValue.territory_id?.[0]}
                companyId={filterValue.producer_id?.[0] || (!isAdmin ? userCompany?.id : undefined)}
                variant="small"
                placeholder={t('placeholders.territory')}
                isClearable
                isSearchable
              />
            </Filter>
          )}
          {activeTab === 'retailer' && (
            <Filter title={t('labels.distributor')}>
              <DistributorsMultiSelect
                onChange={val => handleFilterChange({ distributor_id: val })}
                selected={filterValue.distributor_id}
              />
            </Filter>
          )}
          <Filter title={t('labels.season')}>
            <SeasonSelect
              placeholder={t('placeholders.season')}
              value={filterValue.season_id}
              onChange={val => handleFilterChange({ season_id: val })}
              isClearable
              variant="small"
              companyId={filterValue.producer_id}
            />
          </Filter>
          <Filter title={t('labels.product')}>
            <ProductsSelect
              onChange={val => handleFilterChange({ product_id: val ? [val] : [] })}
              value={filterValue.product_id?.[0]}
              placeholder={t('placeholders.product')}
              variant="small"
              isClearable
            />
          </Filter>
        </FiltersWrapper>
        <Buttons>
          <Button onClick={downloadXLSX} intent="primary" filled progress={progressXLSX}>
            {t('labels:downloadXlsx')}
          </Button>
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </Buttons>
      </TComponents.Filters>
    </>
  )
}

export default AllocationFilters
