/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { Logo, Column, Bar, Layout, Page } from 'views/layouts/MainLayout/MainLayout'
import MainMenu, { portalMount } from 'views/components/MainMenu/MainMenu'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  IconSupport,
  MenuItemLink,
  Modal,
  Progress,
  SimpleSelect,
  Tooltip,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalytics } from 'use-analytics'
import useDebouncedFunction from 'hooks/useDebouncedFunction'
import { useConfig } from 'modules/domain/config/hooks'
import ConfigActions from 'modules/domain/config/duck'
import useLanguage from 'hooks/useLanguage'
import { ToggleArrow } from 'views/ui/ToggleArrow/ToggleArrow'
import { useLocalStorage } from 'modules/utils/helpers'
import { useHotkeys } from 'hooks/useHotkeys'

const StyledBarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SupportButton = styled.button`
  padding: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  border: none;
  background: none;
  border-top: 1px solid ${({ theme }) => theme.color.outlineMid};
  width: 100%;
  height: 40px;
  :focus {
    outline: none;
  }
  :hover {
    color: ${({ theme }) => theme.color.primary600};
    cursor: pointer;
  }
`

const StyledBarBottom = styled.div`
  margin-top: auto;
`

const Support = styled.div`
  width: 100%;
`

const SupportTitle = styled.div`
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  margin-top: 24px;
`

const SupportLink = styled.a`
  color: ${({ theme }) => theme.color.primary600};
`

const StyledChangeLanguageContainer = styled.div`
  display: flex;
  margin: 0 auto;

  :first-child {
    width: 90%;
    margin: 0 auto;
  }
`

const ToggleArrowWrapper = styled.div`
  position: absolute;
  z-index: 4;
  top: 68px;
  left: 50%;
  transform: translate(-50%);
`

const ItemLinkContainer = styled.div`
  margin: 0 12px;
`

const StyledIconSupport = styled(IconSupport)`
  padding: 0;
`

const TooltipContent = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const PageWrapper: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation(['common', 'support'])
  const [configProgress, config] = useConfig()
  const [supportModalOpen, setSupportModalOpen] = useState(false)
  const filteredLanguages = useMemo(() => {
    const languages = { ...i18n.services.resourceStore.data }
    delete languages.es
    delete languages.es_SV
    delete languages.ru
    delete languages.uk
    return Object.keys(languages).sort()
  }, [i18n.services.resourceStore.data])

  const [pageWidth, setPageWidth] = useState(window.innerWidth)

  const languages = useMemo(
    () =>
      filteredLanguages.map(lng => ({
        id: lng,
        title: t(`languages.short.${lng === 'es_SV' ? 'es' : lng}`),
      })),
    [filteredLanguages, t],
  )

  const changeLanguageAction = useAction(ConfigActions.languageChanged)
  const language = useLanguage()
  const [languageState, setLanguageState] = useState(language)
  const [isCollapsed, setCollapsed] = useLocalStorage('sidebarMenuCollapsed', false)
  const [coords, setCoords] = useState({ top: 0, left: 0 })
  const [isOn, setOn] = useState(false)

  useEffect(() => {
    if (languageState !== language && configProgress !== Progress.WORK) {
      setLanguageState(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configProgress])

  useEffect(() => {
    if (language !== languageState) {
      i18n.changeLanguage(languageState)
      changeLanguageAction(languageState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageState])

  const { track } = useAnalytics()
  const handleModalOpen = useCallback(() => {
    track('Support')
    setSupportModalOpen(true)
  }, [track])
  const handleModalClose = useCallback(() => setSupportModalOpen(false), [])

  const handleResize = () => {
    setPageWidth(window.innerWidth)
  }

  const deboundcedFunc = useDebouncedFunction(handleResize, 100)

  useEffect(() => {
    window.addEventListener('resize', deboundcedFunc)
    return () => window.removeEventListener('resize', handleResize)
  }, [deboundcedFunc])

  useHotkeys('KeyM', () => setCollapsed(c => !c))

  const themeContext = useContext(ThemeContext)

  const email = useMemo(() => {
    const email = config?.contacts?.email
    return !email ? null : email?.value
  }, [config])

  const phone = useMemo(() => {
    const phone = config?.contacts?.phone
    return !phone ? null : phone?.value
  }, [config])

  const width = pageWidth > themeContext.size.minPageWidth ? (isCollapsed ? 72 : undefined) : 72
  const hasCollapsed = !!width || isCollapsed

  const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const widthWithScroll = e.currentTarget.parentElement?.parentElement?.parentElement?.clientWidth

    setCoords({
      left: (widthWithScroll && widthWithScroll + rect.x) || 0,
      top: rect.y + rect.height / 2,
    })
    setOn(true)
  }, [])

  return (
    <Layout barWidth={pageWidth <= themeContext.size.minPageWidth ? themeContext.size.minBarWidth : undefined}>
      <Column width={width}>
        {pageWidth > themeContext.size.minPageWidth && (
          <ToggleArrowWrapper>
            <Tooltip
              visibleOnHover
              temporary
              tooltipContent={
                <TooltipContent>
                  <span>Ctrl+m for Windows</span>
                  <span>Cmd+m for Mac</span>
                </TooltipContent>
              }
              position={hasCollapsed ? 'right' : 'top'}
            >
              <ToggleArrow rotated={isCollapsed} onClick={() => setCollapsed(c => !c)} />
            </Tooltip>
          </ToggleArrowWrapper>
        )}
        <Logo hideText={hasCollapsed} />
        <Bar>
          <StyledBarContent>
            <MainMenu compact={isCollapsed} />
            <StyledBarBottom>
              <StyledChangeLanguageContainer>
                <SimpleSelect
                  menuPlacement={'top'}
                  minWidth={'56px'}
                  value={languageState}
                  options={languages}
                  onChange={lang => setLanguageState(lang as typeof language)}
                />
              </StyledChangeLanguageContainer>
              <br />
              {!hasCollapsed ? (
                <SupportButton onClick={handleModalOpen}>{t('support')}</SupportButton>
              ) : (
                <ItemLinkContainer
                  onMouseLeave={() => {
                    setCoords({ top: 0, left: 0 })
                    setOn(false)
                  }}
                  onMouseEnter={handleMouseEnter}
                  onClick={handleModalOpen}
                >
                  <MenuItemLink icon={StyledIconSupport} />
                  {isOn && isCollapsed && (
                    <Tooltip
                      visibleOnHover
                      position="right"
                      tooltipContent={<TooltipContent>{t('support')}</TooltipContent>}
                      portalProps={{
                        coords,
                        mount: portalMount as HTMLElement,
                      }}
                    />
                  )}
                </ItemLinkContainer>
              )}
              <Modal isOpen={supportModalOpen} onClose={handleModalClose} size={'medium'}>
                <Support>
                  {(email || phone) && (
                    <>
                      {email && (
                        <>
                          <SupportTitle>{t('support:email')}</SupportTitle>
                          <SupportLink href={`tel:${email}`}>{email}</SupportLink>
                        </>
                      )}

                      {phone && phone.length > 2 && (
                        <>
                          <SupportTitle>{t(`support:phone`)}</SupportTitle>
                          <SupportLink href={`tel:${phone}`}>{phone}</SupportLink>
                        </>
                      )}
                    </>
                  )}
                </Support>
              </Modal>
            </StyledBarBottom>
          </StyledBarContent>
        </Bar>
      </Column>
      <Page>{children}</Page>
    </Layout>
  )
}

export default PageWrapper
