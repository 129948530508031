import { useAction, useDidMount } from '@agro-club/frontend-shared'
import { Entity } from 'modules/domain/types'
import { ResourceHook } from 'modules/types'
import { useSelector } from 'react-redux'
import { SelectorsForListHooks } from './createBasicSelectors'

export const createItemListOnceHook = <EntityType extends Partial<Entity>, FilterType>(
  selectors: SelectorsForListHooks<EntityType, FilterType>,
  fetchActionType: (params: { page?: number; pageSize?: number; filter?: FilterType }) => any,
): ResourceHook<EntityType[], void[]> => () => {
  const progress = useSelector(selectors.listFetchProgress)
  const list = useSelector(selectors.list)
  const filter = useSelector(selectors.filter)
  const page = useSelector(selectors.page)
  const pageSize = useSelector(selectors.pageSize)

  const fetchAction = useAction(fetchActionType, {
    pageSize,
    page,
    filter,
  })

  useDidMount(fetchAction)

  return [progress, list]
}
