import { IconDownload, IconCrossCircle, IconLinkOpener } from '@agro-club/frontend-shared'
import styled, { keyframes, StyledProps } from 'styled-components'

export const HintWrapper = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.color.secondary200};
`

export const HintLink = styled.div`
  line-height: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.primary600};
`

export const DropContainer = styled.div<{ isDraggingOver: boolean; itemsLeft: number }>`
  display: grid;
  width: ${props => (props.itemsLeft === 0 ? `100%` : 'calc(100% - 140px)')};
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 120px);
  border-radius: 8px;
  background-color: ${props => (props.isDraggingOver ? props.theme.color.secondary50 : 'transparent')};
  box-shadow: ${props => (props.isDraggingOver ? `0 0 20px 5px ${props.theme.color.secondary50}` : 'none')};
`

const animation = keyframes`
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(0, 0);
  }
`

export const FadeInContainer = styled.div<StyledProps<{ running?: boolean }>>`
  width: 100%;
  height: 100%;
  position: relative;
  top: 20px;
  animation-duration: 0.3s;
  animation-name: ${props => (props.running ? animation : null)};
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  animation-play-state: ${props => (props.running ? `running` : 'paused')};
`

export const FadeOutContainer = styled.div<StyledProps<{ running?: boolean }>>`
  width: 100%;
  height: 100%;
  position: relative;
  animation-duration: 0.3s;
  animation-name: ${animation};
  animation-timing-function: ease-in-out;
  animation-play-state: ${props => (props.running ? `running` : 'paused')};
`

export const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  top: -5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > * {
    height: 100%;
    transition: opacity 0.1s ease-in;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  > *:not(:first-child) {
    margin-left: 6px;
    width: 16px;
    height: 16px;
  }
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

export const PreviewContainer = styled.div`
  position: relative;

  &:hover {
    ${ButtonsContainer} {
      opacity: 1;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  height: 150px;
  flex-direction: column;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  > *:not(:first-child) {
    margin-left: 16px;
  }
  > * {
    flex-shrink: 0;
    top: 20px;
  }
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  pointer-events: none;
`

export const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.color.accentDestructive};
  pointer-events: none;
`

export const DownloadButton = styled(IconDownload)`
  width: 14px;
  height: 14px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.secondary200};

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
    fill: ${props => props.theme.color.primary600};
  }
`

export const PreviewOpenButton = styled(IconLinkOpener)`
  position: relative;
  top: 2px;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.secondary200};
  & > svg {
    width: 18px;
    height: 18px;
  }
  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
    fill: ${props => props.theme.color.primary600};
  }
`

export const RemoveButton = styled(IconCrossCircle)`
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  fill: ${props => props.theme.color.secondary200};

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
    fill: ${props => props.theme.color.primary600};
  }
`
