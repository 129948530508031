import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import CompanyTable from 'views/pages/Company/CompanyTable'
import CompanyEdit from './CompanyEdit'
import CompanyAdd from './CompanyAdd'
import CompanyRoutes from './routes'

const CompanyPage: React.FC = () => {
  return (
    <Switch>
      <Route path={CompanyRoutes.List} exact={true}>
        <CompanyTable />
      </Route>
      <Route path={CompanyRoutes.Edit} exact={true}>
        <CompanyEdit />
      </Route>
      <Route path={CompanyRoutes.Add} exact={true}>
        <CompanyAdd />
      </Route>
      <Redirect to={CompanyRoutes.List} />
    </Switch>
  )
}

export default CompanyPage
