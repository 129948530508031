import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'
import { CountryPhoneCode, Region, RegionListRequestFilter, RegionListRequestSorting, Territory } from './types'
import { endpoints } from 'modules/endpoints'
import { createItemsByIds } from 'modules/utils/helpers/createItemsByIds'

export const fetchRegions = (
  filter?: RegionListRequestFilter,
  sorting?: RegionListRequestSorting,
  page?: number,
  pageSize?: number,
) => {
  return apiClient.get<ListResponse<Region>>(endpoints.regions(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getRegionById = (id: string) => {
  return apiClient.get<Region>(endpoints.regions(id))
}

export const getRegionsByIds = createItemsByIds(true, (ids: string[]) => {
  return apiClient.get<ListResponse<Region>>(endpoints.regions(), {
    ids,
  })
})

export const fetchTerritories = (...args: any[]) => {
  return apiClient.get<ListResponse<Territory>>(endpoints.territory(), ...args)
}

export const fetchPhoneCodes = () => {
  return apiClient.get<ListResponse<CountryPhoneCode>>(endpoints.phoneCodes(), { page_size: 250 })
}

export const fetchTimezones = (search: string) => {
  return apiClient.get<ListResponse<string>>(endpoints.timezones(), { search_text: search })
}
