import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, Row, useTable } from 'react-table'
import styled from 'styled-components'
import {
  CheckboxTreeSelect,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  SimpleSelect,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
  CustomSelectStyles,
} from '@agro-club/frontend-shared'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import { Company } from 'modules/domain/company/types'

import { StorefrontSku } from 'modules/domain/storefront/types'
import { useProductOptionsWithoutRedux } from 'modules/domain/productOptions/hooks'
import {
  useInventoryExchangeDistributorsOptions,
  useInventoryExchangeProducersOptions,
} from 'modules/domain/company/hooks'
import {
  InventoryTransferRequest,
  InventoryTransferRequestFilter,
  TransferRequestStatuses,
} from 'modules/domain/inventoryInTransferRequest/types'
import AuthSelectors from 'modules/domain/auth/selectors'
import InRequestModal from './RequestModal/InRequestModal'
import OutRequestModal from './RequestModal/OutRequestModal'
import RequestStatusColored from './RequestModal/RequestStatus'
import { generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, Sections } from 'types/entities'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'

export enum PageTypes {
  allRequests = 'allRequests',
  inRequests = 'inRequests',
  outRequests = 'outRequests',
}

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const FiltersFirstRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Filters2RowWrapper = styled.div`
  display: flex;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 20%;
  min-width: 250px;
  flex-grow: 1;
  justify-content: space-between;
`

const ProducerCell: React.FC<CellProps<InventoryTransferRequest, StorefrontSku>> = ({ cell, column }) => {
  return (
    <TComponents.SemiBoldText key={column.id}>
      {cell.value?.product?.producer?.internal_name || ''}
    </TComponents.SemiBoldText>
  )
}

const CompanyCell: React.FC<CellProps<InventoryTransferRequest, Company>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value?.official_name}</TComponents.SemiBoldText>
}

const ProductCell: React.FC<CellProps<InventoryTransferRequest, StorefrontSku>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  let val = ''
  if (cell.value) {
    val = `${pick(cell.value?.product?.title_i18n)}`
  }
  return <div key={column.id}>{val}</div>
}

const SKUCell: React.FC<CellProps<InventoryTransferRequest, StorefrontSku>> = ({ cell, column }) => {
  let val = ''
  if (cell.value) {
    val = cell.value?.sku_id
  }
  return <div key={column.id}>{val}</div>
}

const StatusCell: React.FC<CellProps<InventoryTransferRequest, TransferRequestStatuses>> = ({ cell, column }) => {
  return <RequestStatusColored key={column.id} status={cell.value} />
}

const QuantityCell: React.FC<CellProps<InventoryTransferRequest, number>> = ({ cell, column }) => {
  return <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
}

const PriceCell: React.FC<CellProps<InventoryTransferRequest, string>> = ({ cell }) => {
  const { t } = useTranslation('inventoryTransferRequest')
  const price = cell.value ?? t('notSpecified')
  return <div>{price}</div>
}

const PackageTypeCell: React.FC<CellProps<InventoryTransferRequest>> = ({ cell }) => {
  return <div>{cell.value?.params?.package_title}</div>
}

const CreatedDateCell: React.FC<CellProps<InventoryTransferRequest, string>> = ({ cell, column }) => {
  const getFormatedDate = useDateFormatFn({ timeFormat: 'HH:mm:ss' })
  return <div key={column.id}>{getFormatedDate(cell.value)}</div>
}

const InventoryRow: React.FC<{
  row: Row<InventoryTransferRequest>
}> = ({ row }) => {
  const props = row.getRowProps()
  const profile = useSelector(AuthSelectors.profile)
  const isRequestOwner = row.original.company_to?.id === profile?.company?.id
  const isRequestAuthor = row.original.company_from?.id === profile?.company?.id
  const [isModalOpen, setIsModalOpen] = useState(false)
  const checkFeatureFlag = useFeatureFlags()
  const isDemoFeaturesEnabled = checkFeatureFlag(
    generateCrmSectionAccessString(Sections.InventoryExchange, FeatureFlagModifiers.DemoMode),
  )
  let modalContent: React.ReactNode = null
  if (isModalOpen) {
    if (isRequestAuthor) {
      modalContent = <InRequestModal rowData={row.original} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    } else if (isRequestOwner) {
      modalContent = <OutRequestModal rowData={row.original} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    }
  }
  const tableRowOnClick = () => {
    if (!isDemoFeaturesEnabled || isModalOpen) {
      return
    }
    if (isRequestOwner || isRequestAuthor) {
      setIsModalOpen(true)
      return
    }
  }
  return (
    <TableBodyRow {...props} onClick={tableRowOnClick}>
      {row.cells.map(cell => {
        const { key, ...props } = cell.getCellProps()
        return (
          <TableBodyCell key={key} {...cell.column} {...props}>
            {cell.render('Cell')}
          </TableBodyCell>
        )
      })}
      {modalContent}
    </TableBodyRow>
  )
}

const TransferRequestList: React.FC<{
  pageType: PageTypes
  selectors: any
  actions: any
  progress: Progress
  data: any
}> = ({ pageType, selectors, actions, progress, data }) => {
  const { t } = useTranslation(['inventoryTransferRequest', 'productOptions'])
  const checkFeatureFlag = useFeatureFlags()
  const isDemoFeaturesEnabled = checkFeatureFlag(
    generateCrmSectionAccessString(Sections.InventoryExchange, FeatureFlagModifiers.DemoMode),
  )
  const listRequested = useAction(actions.listRequested)
  const filterUpdated = useAction(actions.filterUpdated)
  const filterValue: InventoryTransferRequestFilter = useSelector(selectors.filter)
  const total: number = useSelector(selectors.total)
  const pages: number = useSelector(selectors.pages)
  const page: number = useSelector(selectors.page)
  const pageSize: number = useSelector(selectors.pageSize)

  const [productOptionsFilter] = useState({})
  const [productOptionsProgress, productOptionsData] = useProductOptionsWithoutRedux(
    productOptionsFilter,
    null,
    1,
    1000,
  )

  const productOptions = useMemo(() => {
    if (!productOptionsData) {
      return []
    }
    return productOptionsData
      .sort((a, b) => a.type.localeCompare(b.type))
      .map(i => ({
        id: i.id,
        title: `${i.title} (${t(`productOptions:types.${i.type}`)})` ?? '',
      }))
  }, [productOptionsData, t])

  const [producersProgress, producers] = useInventoryExchangeProducersOptions()
  const producersOptions = useMemo(() => {
    if (!producers) {
      return []
    }
    return producers.map(i => ({ id: i.id, title: i.official_name ?? '' }))
  }, [producers])

  const [distributorsProgress, distributors] = useInventoryExchangeDistributorsOptions()
  const distributorsOptions = useMemo(() => {
    if (!distributors) {
      return []
    }
    return distributors.map(d => ({
      id: d.id,
      title: d.official_name ?? '',
      hasChildren: !!d.has_branch_company,
      parent: d.head_company_relation?.company_id,
    }))
  }, [distributors])

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('tableHeaders.status'),
        accessor: 'status' as const,
        Cell: StatusCell,
        hidden: !isDemoFeaturesEnabled,
      },
      {
        id: 'producer',
        Header: t('tableHeaders.producer'),
        accessor: 'sku' as const,
        Cell: ProducerCell,
      },
      {
        id: 'company_from',
        Header: t('tableHeaders.company_from'),
        accessor: 'company_from' as const,
        Cell: CompanyCell,
      },
      {
        Header: t('tableHeaders.company_to'),
        accessor: 'company_to' as const,
        Cell: CompanyCell,
      },
      {
        id: 'product',
        Header: t('tableHeaders.product'),
        accessor: 'sku' as const,
        Cell: ProductCell,
      },
      {
        Header: t('tableHeaders.sku'),
        accessor: 'sku' as const,
        Cell: SKUCell,
      },
      {
        id: 'packageType',
        Header: t('tableHeaders.packageType'),
        accessor: 'sku' as const,
        Cell: PackageTypeCell,
      },
      {
        Header: t('tableHeaders.createdAt'),
        accessor: 'created_at' as const,
        Cell: CreatedDateCell,
      },
      {
        Header: t('tableHeaders.quantity'),
        accessor: 'quantity' as const,
        Cell: QuantityCell,
      },
      {
        id: 'price',
        Header: t('tableHeaders.price'),
        accessor: 'price' as const,
        Cell: PriceCell,
      },
    ],
    [isDemoFeaturesEnabled, t],
  )

  const { columns, rows, prepareRow } = useTable<InventoryTransferRequest>({
    columns: visibleColumns,
    data,
    initialState: {},
  })

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<InventoryTransferRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('inventoryTranferRequestList')

  const renderRows = () => {
    if (progress !== Progress.SUCCESS) {
      return null
    }
    return rows.map(row => {
      prepareRow(row)
      const { key } = row.getRowProps()
      return <InventoryRow key={key} row={row} />
    })
  }

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <div>
          <FiltersFirstRowWrapper>
            <Filter title={t('producer')}>
              <SimpleSelect
                customStyles={customSelectStyles}
                onChange={val => handleFilterChange({ producer_id: val ? val.toString() : null })}
                progress={producersProgress}
                value={filterValue.producer_id}
                isClearable
                options={producersOptions}
              />
            </Filter>
            {pageType !== PageTypes.inRequests && (
              <Filter title={t('company_from')}>
                <CheckboxTreeSelect
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  customStyles={customSelectStyles}
                  onChange={val => handleFilterChange({ retailer_from: val ? val : undefined })}
                  progress={distributorsProgress}
                  // retailer_from means items will be moved from the retailer
                  value={filterValue.retailer_from}
                  isClearable
                  options={distributorsOptions}
                />
              </Filter>
            )}
            {pageType !== PageTypes.outRequests && (
              <Filter title={t('company_to')}>
                <CheckboxTreeSelect
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  customStyles={customSelectStyles}
                  onChange={val => handleFilterChange({ retailer_to: val ? val : undefined })}
                  progress={distributorsProgress}
                  // retailer_to means items will be moved to the retailer
                  value={filterValue.retailer_to}
                  isClearable
                  options={distributorsOptions}
                />
              </Filter>
            )}
          </FiltersFirstRowWrapper>
          <Filters2RowWrapper>
            <Filter title={t('labels:product')}>
              <ProductsSelect
                customStyles={customSelectStyles}
                onChange={val => handleFilterChange({ product_id: val })}
                value={filterValue.product_id}
                scope="sku/inventory"
                isClearable
                isFilterSuggest
              />
            </Filter>
            <Filter title={t('activeIngredient')}>
              <SimpleSelect
                customStyles={customSelectStyles}
                onChange={val => handleFilterChange({ product_options_id: val ? [val.toString()] : undefined })}
                progress={productOptionsProgress}
                value={filterValue.product_options_id?.[0]}
                isClearable
                isSearchable
                options={productOptions}
              />
            </Filter>
          </Filters2RowWrapper>
        </div>
      </TComponents.Filters>
      <Table
        fixedLayout
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell key={column.id} {...column}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {renderRows()}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default TransferRequestList
