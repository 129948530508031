import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ProductOptionsActions from './duck'
import ProductOptionsSelectors from './selectors'
import { ProductOptions } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import StorefrontRoutes from 'views/pages/Storefront/routes'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ProductOptionsSelectors.page)
    const filter = yield select(ProductOptionsSelectors.filter)
    const sorting = yield select(ProductOptionsSelectors.sorting)
    const pageSize = yield select(ProductOptionsSelectors.pageSize)
    let response: ListResponse<ProductOptions> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ProductOptionsActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, StorefrontRoutes.OptionsList, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.listRequestFailed(errType))
  }
}

export const fetchAllList = function*() {
  try {
    const response: ListResponse<ProductOptions> = yield call(managers.getListAll)
    const { data } = response
    yield put(ProductOptionsActions.listAllRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.listAllRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ProductOptionsSelectors.page)
    const filter = yield select(ProductOptionsSelectors.filter)
    const sorting = yield select(ProductOptionsSelectors.sorting)
    const pageSize = yield select(ProductOptionsSelectors.pageSize)
    const { data, total_count }: { data: ProductOptions[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ProductOptionsActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ProductOptionsActions.itemRequested>) {
  try {
    const productOptions: ProductOptions = yield call(managers.getItem, id)
    yield put(ProductOptionsActions.itemRequestSucceed(productOptions))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof ProductOptionsActions.addRequested>) {
  try {
    const productOptions: ProductOptions = yield call(managers.addItem, dto)
    yield put(ProductOptionsActions.addSucceed(productOptions))
    yield put(push(generatePath(StorefrontRoutes.OptionsEdit, { id: productOptions.id })))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof ProductOptionsActions.updateRequested>) {
  try {
    const productOptions: ProductOptions = yield call(managers.updateItem, id, dto)
    yield put(ProductOptionsActions.updateSucceed(productOptions))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(ProductOptionsActions.updateFailed(id, type, detail))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof ProductOptionsActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(ProductOptionsActions.removeSucceed(payload))
    yield put(push(StorefrontRoutes.OptionsList))
    yield put(ProductOptionsActions.resetRemoveProgress())
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.removeFailed(payload, errType))
  }
}

const ProductOptionsSaga = function*() {
  yield all([
    takeLatest(ProductOptionsActions.itemRequested.type, fetchItem),
    takeLatest(ProductOptionsActions.listRequested.type, fetchList),
    takeLatest(ProductOptionsActions.listAllRequested.type, fetchAllList),
    takeLatest(ProductOptionsActions.filterUpdated.type, fetchList),
    takeLatest(ProductOptionsActions.sortingUpdated.type, fetchList),
    takeLatest(ProductOptionsActions.filterHasBeenReset.type, fetchList),
    takeLatest(ProductOptionsActions.sortingHasBeenReset.type, fetchList),
    takeLatest(ProductOptionsActions.listRequestedNext.type, fetchListNext),
    takeLatest(ProductOptionsActions.addRequested.type, addItem),
    takeLatest(ProductOptionsActions.updateRequested.type, updateItem),
    takeLatest(ProductOptionsActions.removeRequested.type, removeItem),
  ])
}

export default ProductOptionsSaga
