import { Progress } from 'modules/types'

export const useCombinedProgress = (list: Progress[]): Progress => {
  let result: Progress = Progress.SUCCESS

  loop: for (const progress of list) {
    switch (progress) {
      case Progress.WORK:
      case Progress.IDLE:
      case Progress.ERROR:
        result = progress
        break loop
    }
  }

  return result
}
