import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as managers from 'modules/domain/collection/managers'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { Region, RegionListRequestFilter } from 'modules/domain/collection/types'

export type RegionSelectProps = EntityMultiSelectProps<Region> & {
  country?: RegionListRequestFilter['country']
  parentId?: RegionListRequestFilter['parent_id']
  testId?: string
}

export const getRegionLabel = (region: Region) => region.title
export const getRegionValue = (region: Region) => region.id

const RegionsMultiSelect: React.FC<RegionSelectProps> = ({ testId, country, parentId, placeholder, ...props }) => {
  const { t } = useTranslation('common')
  const memoizedFilter = useMemo(
    () => ({
      country,
      parent_id: parentId,
    }),
    [country, parentId],
  )

  return (
    <SelectTestWrapper data-test-id={testId || 'region-select'}>
      <EntityMultiSelect
        placeholder={placeholder || t('placeholders.regions')}
        getEntityList={managers.fetchRegions}
        getEntityByIds={managers.getRegionsByIds}
        filter={memoizedFilter}
        getOptionLabel={getRegionLabel}
        getOptionValue={getRegionValue}
        searchParamKey="title"
        {...props}
      />
    </SelectTestWrapper>
  )
}

export default RegionsMultiSelect
