import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { EntityMetadata } from 'modules/domain/types'
import { ReturnDeclaration } from 'types/returnDeclaration'

const dict = (state: AppGlobalState) => state.returnDeclaration.items
const ids = (state: AppGlobalState) => state.returnDeclaration.ids
const item = (state: AppGlobalState, id: string): ReturnDeclaration | undefined => state.returnDeclaration.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<ReturnDeclaration> =>
  state.returnDeclaration.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.returnDeclaration.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.returnDeclaration.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.returnDeclaration.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.returnDeclaration.listFetchErrorDetail
const listFetchNextProgress = (state: AppGlobalState) => state.returnDeclaration.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.returnDeclaration.listFetchNextError
const listFetchNextErrorDetail = (state: AppGlobalState) => state.returnDeclaration.listFetchNextErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: ReturnDeclaration[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.returnDeclaration.filter
const sorting = (state: AppGlobalState) => state.returnDeclaration.sorting
const page = (state: AppGlobalState) => state.returnDeclaration.page
const total = (state: AppGlobalState) => state.returnDeclaration.total
const updateProgress = (state: AppGlobalState) => state.returnDeclaration.updateProgress
const removeProgress = (state: AppGlobalState) => state.returnDeclaration.removeProgress
const addProgress = (state: AppGlobalState) => state.returnDeclaration.addProgress
const printProgress = (state: AppGlobalState) => state.returnDeclaration.printProgress
const hasNext = (state: AppGlobalState) => state.returnDeclaration.total > state.returnDeclaration.ids.length
const pageSize = (state: AppGlobalState) => state.returnDeclaration.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.returnDeclaration.total / state.returnDeclaration.pageSize)
const declarationCountFetchProgress = (state: AppGlobalState) => state.returnDeclaration.declarationCountFetchProgress
const declarationCountFetchError = (state: AppGlobalState) => state.returnDeclaration.declarationCountFetchError
const declarationCount = (state: AppGlobalState) => state.returnDeclaration.declarationCount

const ReturnDeclarationSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  printProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  listFetchNextErrorDetail,
  pages,
  declarationCountFetchProgress,
  declarationCountFetchError,
  declarationCount,
}

export default ReturnDeclarationSelectors
