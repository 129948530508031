import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AllocationList from './AllocationList'
import AllocationRetailerRoutes from './routes'
import AllocationTerritoryRoutes from '../AllocationTerritory/routes'

const Allocation = ({ activeTab }: { activeTab: 'retailer' | 'territories' }) => {
  const route = activeTab === 'retailer' ? AllocationRetailerRoutes : AllocationTerritoryRoutes

  return (
    <Switch>
      <Route path={route.List} exact>
        <AllocationList activeTab={activeTab} />
      </Route>
    </Switch>
  )
}

export default Allocation
