import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'

const AddHint: React.FC<{ onClick(): void; limit: number }> = ({ onClick, limit }) => {
  const { t } = useTranslation('FileManagerForm')
  return (
    <Styled.HintWrapper>
      <Styled.HintLink onClick={onClick}>{t('addFile')}</Styled.HintLink>
      {t('addMediaDescription1')}
      <br />
      {t('addMediaDescription2')}
      <br />
      {t('addMediaDescription3')}
      <br />
      {t('addMediaDescription4', { limit, files: limit > 1 ? t('files') : t('file') })}
    </Styled.HintWrapper>
  )
}

export default AddHint
