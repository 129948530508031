import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import ReportActions from './duck'
import ReportSelectors from './selectors'
import { Report } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'
import ReportRoutes from 'views/pages/Reports/routes'
import { generatePath } from 'react-router-dom'
import { updateLocationQuery } from 'modules/sagaHelpers'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ReportSelectors.page)
    const filter = yield select(ReportSelectors.filter)
    const sorting = yield select(ReportSelectors.sorting)
    const pageSize = yield select(ReportSelectors.pageSize)

    let response: ListResponse<Report> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ReportActions.listRequestSucceed(data, total_count, page))

    if (currentPage) {
      yield call(updateLocationQuery, ReportRoutes.ReportList, { page: currentPage })
    }
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ReportSelectors.page)
    const filter = yield select(ReportSelectors.filter)
    const sorting = yield select(ReportSelectors.sorting)
    const pageSize = yield select(ReportSelectors.pageSize)
    const { data, total_count }: { data: Report[]; total_count: number } = yield call(
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ReportActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ReportActions.itemRequested>) {
  try {
    const item: Report = yield call(managers.getItem, id)
    yield put(ReportActions.itemRequestSucceed(item))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.itemRequestFailed(id, errType))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof ReportActions.addRequested>) {
  try {
    const item: Report = yield call(managers.addItem, dto)
    yield put(push(generatePath(ReportRoutes.ReportEdit, { id: item.id })))
    yield put(ReportActions.addSucceed(item))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.addFailed(errType))
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof ReportActions.updateRequested>) {
  try {
    const item: Report = yield call(managers.updateItem, id, dto)
    yield put(ReportActions.updateSucceed(item))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.updateFailed(id, errType))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof ReportActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(push(ReportRoutes.ReportList))
    yield put(ReportActions.removeSucceed(payload))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ReportActions.removeFailed(payload, errType))
  }
}

const ReportSaga = function*() {
  yield all([
    takeLatest(ReportActions.itemRequested.type, fetchItem),
    takeLatest(ReportActions.listRequested.type, fetchList),
    takeLatest(ReportActions.filterUpdated.type, fetchList),
    takeLatest(ReportActions.sortingUpdated.type, fetchList),
    takeLatest(ReportActions.filterHasBeenReset.type, fetchList),
    takeLatest(ReportActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ReportActions.listRequestedNext.type, fetchListNext),

    takeLatest(ReportActions.addRequested.type, addItem),
    takeLatest(ReportActions.updateRequested.type, updateItem),
    takeLatest(ReportActions.removeRequested.type, removeItem),
  ])
}

export default ReportSaga
