import { Dict } from 'types/generics'
import { AvailableLanguages, Currency, LocalizedValue } from 'types/entities'
import { ListResponse } from 'types/api'
import {
  GlobalFiltersFieldsType,
  PersistentFiltersFieldsType,
  Progress,
  ResourceHook,
  ResourceHookWithTotal,
  ResourceHookWithTotalPreventable,
} from 'modules/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { STORAGE_KEYS } from '../constants'
import FarmerOrderActions from '../domain/farmerOrder/duck'
import DistributorOrderActions from '../domain/distributorOrder/duck'
import ProductActions from '../domain/product/duck'
import ReturnDeclarationActions from '../domain/returnDeclaration/duck'
import TerritoryActions from '../domain/territory/duck'
import { ReconciliationGroupComment } from 'modules/domain/reconciliation/types'
import ReconciliationActions from '../domain/reconciliation/duck'
import InventoryExchangeActions from 'modules/domain/inventoryExchange/duck'
import InventoryExchangeTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'
import InventoryInTransferRequestActions from 'modules/domain/inventoryInTransferRequest/duck'
import InventoryOutTransferRequestActions from 'modules/domain/inventoryOutTransferRequest/duck'
import FccOrderActions from 'modules/domain/fccOrder/duck'
import FccOrderSkuActions from 'modules/domain/fccOrderSku/duck'

import StorefrontActions from 'modules/domain/storefront/duck'
import FarmerOrderSkuActions from 'modules/domain/farmerOrderSku/duck'
import DistributorOrderSkuActions from 'modules/domain/distributorOrderSku/duck'

type GetKeyFn<T extends {}> = (key: T) => string

export function arrToDict<T extends { id?: string }>(arr: T[], key: keyof T | GetKeyFn<T> = 'id'): Dict<T> {
  const result: Dict<T> = {}
  for (const item of arr) {
    if (typeof key === 'string' && typeof item[key] === 'string') result[item[key] as any] = item
    if (typeof key === 'function') {
      const keyVal = key(item)
      result[keyVal] = item
    }
  }

  return result
}

export function groupBy<TItem>(xs: TItem[], key: string): { [key: string]: TItem[] } {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function getIds<T extends { id: string }>(arr: T[]): string[] {
  return arr.map(item => item.id)
}

export const getLocaleByCurrency = (currency: Currency = Currency.CAD): string => {
  let locale = 'en-CA'

  switch (currency) {
    case Currency.CAD:
    case Currency.EUR:
      locale = 'en-CA'
      break
    case Currency.USD:
      locale = 'en-US'
      break
    case Currency.RUB:
      locale = 'ru-RU'
      break
    default:
      break
  }

  return locale
}

export const getPrettyPrice = (
  price: number | string,
  currency: Currency = Currency.CAD,
  options?: Intl.NumberFormatOptions,
) => {
  if (Number.isNaN(price) || !currency || !price) {
    return '-'
  }

  const locale = getLocaleByCurrency(currency)
  const priceNormalized = typeof price === 'string' ? parseFloat(price) : price

  return priceNormalized.toLocaleString(locale, {
    currency: currency || Currency.CAD,
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  })
}

export const numberToPrecision = (qty: number | string, precision = 1) => {
  let normalized = qty
  if (typeof normalized === 'string') normalized = Number(qty)
  return Number(normalized.toFixed(precision))
}

export const getPrettyDiapason = (from?: string | number, to?: string | number) => {
  let result = ''
  if (from) {
    result += from
  }

  if (to) {
    result += `- ${to}`
  }

  return result
}

export function makeCancellableResourceListHook<T extends any>(
  handler: (...args: any[]) => [Promise<ListResponse<T>>, () => void],
  isPreventable = false,
): typeof isPreventable extends true ? ResourceHookWithTotalPreventable<T[]> : ResourceHookWithTotal<T[]> {
  return (...args: Parameters<typeof handler>) => {
    const [options, setOptions] = useState<ListResponse<T>['data']>([])
    const [progress, setProgress] = useState(Progress.IDLE)
    const [total, setTotal] = useState(0)
    const mounted = useRef(true)
    const prevArgs = useRef<Parameters<typeof handler>>(null)

    const argsNormalized = useMemo(() => {
      if (!isPreventable) return args
      const result = [...args]
      result.splice(0, 1)
      return result
    }, [args])

    useEffect(
      () => () => {
        mounted.current = false
      },
      [],
    )

    useEffect(() => {
      const prevent = isPreventable && args[0]
      if (prevent) return

      if (prevArgs.current !== null) {
        if (prevArgs.current.length === 0 && argsNormalized.length === 0) {
          return
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore readonly property, lolwhat?
      prevArgs.current = argsNormalized

      let cancelToken: () => void
      const fetchData = async () => {
        if (mounted.current) {
          setProgress(Progress.WORK)
        }
        try {
          const [promise, cancel] = handler(...argsNormalized)
          cancelToken = cancel
          const result = await promise
          if (mounted.current) {
            // todo API https://agroclub.atlassian.net/secure/RapidBoard.jspa?rapidView=14&modal=detail&selectedIssue=ENT-466 should return data: [] instead if []
            setOptions(Array.isArray(result) ? result : result.data)
            setTotal(result.total_count)
            setProgress(Progress.SUCCESS)
          }
        } catch (e) {
          if (mounted.current) {
            setProgress(Progress.ERROR)
          }
        }
      }

      fetchData()
      return () => {
        if (!prevent && cancelToken) cancelToken()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, argsNormalized)

    return [progress, options, total] as const
  }
}

export function makeCancellableResourceHook<T extends any>(
  handler: (...args: any[]) => [Promise<T>, () => void],
): ResourceHook<T> {
  return (...args: Parameters<typeof handler>) => {
    const [options, setOptions] = useState<T>()
    const [progress, setProgress] = useState(Progress.IDLE)
    const mounted = useRef(true)
    const prevArgs = useRef<Parameters<typeof handler>>(null)

    useEffect(
      () => () => {
        mounted.current = false
      },
      [],
    )

    useEffect(() => {
      if (prevArgs.current !== null) {
        if (prevArgs.current.length === 0 && args.length === 0) {
          return
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore readonly property, lolwhat?
      prevArgs.current = args

      let cancelToken: () => void
      const fetchData = async () => {
        if (mounted.current) {
          setProgress(Progress.WORK)
        }
        try {
          const [promise, cancel] = handler(...args)
          cancelToken = cancel
          const result = await promise
          if (mounted.current) {
            setOptions(result)
            setProgress(Progress.SUCCESS)
          }
        } catch (e) {
          if (mounted.current) {
            setProgress(Progress.ERROR)
          }
        }
      }

      fetchData()
      return () => {
        cancelToken && cancelToken()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args)

    return [progress, options] as const
  }
}

export const getLocalData = (key: string): null | GlobalFiltersFieldsType | ReconciliationGroupComment => {
  const value = localStorage.getItem(key)
  if (!value) {
    return null
  }
  return JSON.parse(value)
}

export const savePersistentFilter = <Scope extends keyof PersistentFiltersFieldsType = 'global'>(
  value: Partial<PersistentFiltersFieldsType[Scope]>,
  scope: Scope = 'global' as Scope,
): void => {
  const data = getLocalData(STORAGE_KEYS.FILTERS) as PersistentFiltersFieldsType | null
  let updatedData: Partial<PersistentFiltersFieldsType> = {}
  if (data) {
    updatedData = {
      ...data,
      [scope]: {
        ...data[scope],
        ...value,
      },
    }
  } else {
    updatedData = {
      [scope]: value,
    }
  }
  localStorage.setItem(STORAGE_KEYS.FILTERS, JSON.stringify(updatedData))
}

export const getPersistentFilter = <Scope extends keyof PersistentFiltersFieldsType = 'global'>(
  key: keyof GlobalFiltersFieldsType,
  scope: Scope = 'global' as Scope,
): undefined | GlobalFiltersFieldsType => {
  const data = getLocalData(STORAGE_KEYS.FILTERS) as PersistentFiltersFieldsType | null
  if (!data || !data[scope]) {
    return undefined
  }

  return { [key]: data[scope][key] }
}

export const getAllPersistentFilters = <Scope extends keyof PersistentFiltersFieldsType = 'global'>(
  scope: Scope = 'global' as Scope,
): undefined | PersistentFiltersFieldsType[Scope] => {
  const data = getLocalData(STORAGE_KEYS.FILTERS) as PersistentFiltersFieldsType | null
  if (!data || !data[scope]) {
    return undefined
  }
  return data[scope]
}

const cleanPersistentFilters = (): void => {
  localStorage.removeItem(STORAGE_KEYS.FILTERS)
}

export const changePersistentFilters = (type: string, filter: GlobalFiltersFieldsType): void => {
  switch (type) {
    case FarmerOrderActions.filterUpdated.type:
    case ReturnDeclarationActions.filterUpdated.type:
    case DistributorOrderSkuActions.filterUpdated.type:
    case FarmerOrderSkuActions.filterUpdated.type:
    case DistributorOrderActions.filterUpdated.type:
      savePersistentFilter({ season_id: filter.season_id })
      break
    case ProductActions.filterUpdated.type:
    case TerritoryActions.filterUpdated.type:
      savePersistentFilter({ producer_id: filter.producer_id })
      break
    case ReconciliationActions.groupsFilterUpdated.type:
    case InventoryExchangeActions.filterUpdated.type:
    case InventoryExchangeTransactionActions.filterUpdated.type:
    case InventoryInTransferRequestActions.filterUpdated.type:
    case InventoryOutTransferRequestActions.filterUpdated.type:
    case StorefrontActions.filterUpdated.type:
    case FccOrderActions.filterUpdated.type:
    case FccOrderSkuActions.filterUpdated.type:
      savePersistentFilter({ season_id: filter.season_id, producer_id: filter.producer_id }, 'global')
      break
    case FarmerOrderActions.filterHasBeenReset.type:
    case FarmerOrderSkuActions.filterHasBeenReset.type:
    case DistributorOrderActions.filterHasBeenReset.type:
    case ProductActions.filterHasBeenReset.type:
    case ReturnDeclarationActions.filterHasBeenReset.type:
    case TerritoryActions.filterHasBeenReset.type:
    case ReconciliationActions.filterHasBeenReset.type:
    case InventoryExchangeActions.filterHasBeenReset.type:
    case InventoryExchangeTransactionActions.filterHasBeenReset.type:
    case InventoryInTransferRequestActions.filterHasBeenReset.type:
    case InventoryOutTransferRequestActions.filterHasBeenReset.type:
    case FccOrderActions.filterHasBeenReset.type:
    case FccOrderSkuActions.filterHasBeenReset.type:
      cleanPersistentFilters()
      break
  }
}

export function getLocaleFromBrowser(): string {
  if (navigator.languages !== undefined) return navigator.languages[0]
  else return 'en-CA'
}

export const isLangActive = (lang: AvailableLanguages, langs: (keyof LocalizedValue<string>)[]) => {
  return langs.includes(lang)
}

export function useLocalStorage(key: string, initialValue: unknown) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: unknown) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  return [storedValue, setValue]
}
