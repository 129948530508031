import React from 'react'
import { Route, Switch } from 'react-router-dom'

import DocumentRoutes from 'views/pages/Document/routes'
import DocumentTable from 'views/pages/Document/DocumentTable'
import DocumentItem from 'views/pages/Document/DocumentItem'

const DocumentPage: React.FC = () => {
  return (
    <Switch>
      <Route path={DocumentRoutes.List} exact={true}>
        <DocumentTable />
      </Route>
      <Route path={DocumentRoutes.Edit} exact={true}>
        <DocumentItem />
      </Route>
    </Switch>
  )
}

export default DocumentPage
