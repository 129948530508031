import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { DiscountTypes, LocalizedValue } from 'types/entities'
import i18n from 'i18n'
import { FileItem } from 'views/components/FileManager/types'
import { Product } from 'modules/domain/product/types'

export type DiscountRuleState = {
  items: Dict<DiscountRule>
  meta: Dict<EntityMetadata<DiscountRule>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  listFetchNextErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof DiscountRule, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: DiscountRuleListRequestFilter
  sorting: DiscountRuleListRequestSorting
  page: number
  total: number
  pageSize: number
}

export type QtyTierRule = {
  type: DiscountTypes.Quantity
  amount: string
  min_qty: number
  max_qty?: number
  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue<FileItem[]>
  measure?: string
}

export type PromocodeTierRule = {
  type: DiscountTypes.Promocode
  amount: string
  min_qty: number
  max_qty?: number

  promocode: string

  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue<FileItem[]>
  measure?: string
}

export type GiftTierRule = {
  type: DiscountTypes.Gift
  min_qty: number
  max_qty?: number
  available_gifts: string[]
  limit: number
}

export type PercentageTierRule = {
  amount: string
  type: DiscountTypes.Percentage
  min_qty?: number
  max_qty?: number
  percent: string
  start_date: string
  end_date: string
  measure?: string
}

export type HistoryQtyTierRule = {
  amount: string
  type: DiscountTypes.HistoryQuantity
  min_qty?: number
  max_qty?: number
  min_qty_historic: number
  max_qty_historic: number
  historic_season_id: string
}

export type TierRulesUnion = Partial<
  QtyTierRule | PromocodeTierRule | GiftTierRule | PercentageTierRule | HistoryQtyTierRule
>

// TODO-995 only tiers with 'amount' prop will work
export type TierRulesWithAmountUnion = Partial<
  QtyTierRule | PromocodeTierRule | PercentageTierRule | HistoryQtyTierRule
>

export type DiscountRule = {
  id: string
  producer_id: string
  campaign_name: string
  slug?: string
  company_name: string
  products_ids: string[]
  title: string
  season_id: string
  start_date: string | null
  end_date: string | null
  rule_type?: DiscountTypes
  tiers_rules: TierRulesUnion[]
  link_label_i18n?: LocalizedValue
  link_url_i18n?: LocalizedValue<FileItem[] | string>
  description?: string
  description_i18n?: string
  scopes?: DiscountRuleScope[]
  status?: DiscountRuleStatus
  measure?: string
}

export type CalculateParamsDTO = {
  producer_id?: string
  season_id?: string
  promocodes?: string[]
  entries: {
    product_id: string
    quantity: number
    seed_treatment_id?: string
    price?: string
    product?: Product
  }[]
  at_date?: string
  scopes?: DiscountRuleScope[]
}

export type CalculatedTier = {
  amount: string
  is_applied: boolean
  qty_to_apply: number
  show?: boolean
  tier_rule: TierRulesWithAmountUnion // TODO-995
}

export type CalculatedDiscountDTO = {
  is_related: boolean
  rule: DiscountRule
  tiers: CalculatedTier[]
  amount: string
  next_lvl_qty: number
}

export type CalculatedDiscountResponse = {
  total: string
  discounts: CalculatedDiscountDTO[]
}

export enum DiscountRuleScope {
  FarmerOrders = 'farmer_orders',
  DistributorOrder = 'distributor_orders',
}

export enum DiscountRuleStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export type DiscountRuleListRequestFilter = {
  search?: string
  scopes?: DiscountRuleScope
  status?: DiscountRuleStatus
  season_id?: string
}

export type DiscountRuleListRequestSorting = {
  sort_field?: keyof DiscountRule
  sort_reversed?: boolean
}

export type DiscountRuleDTO = Omit<DiscountRule, 'id' | 'company_name'> & { id?: string }

export const DiscountRuleTypeNames = {
  [DiscountTypes.Promocode]: i18n.t('discountRules:types.promocode'),
  [DiscountTypes.Quantity]: i18n.t('discountRules:types.qty'),
  [DiscountTypes.Gift]: i18n.t('discountRules:types.gift'),
  [DiscountTypes.Percentage]: i18n.t('discountRules:types.percentage'),
}
