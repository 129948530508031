import { useAction } from '@agro-club/frontend-shared'
import { Entity } from 'modules/domain/types'
import { ResourceHook } from 'modules/types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SelectorsForNuclearHooks } from './createBasicSelectors'

export const createItemByIdOnceHook = <EntityType extends Partial<Entity>>(
  selectors: SelectorsForNuclearHooks<EntityType>,
  fetchActionType: (id: string) => any,
): ResourceHook<EntityType, [string | undefined]> => (id?: string) => {
  const meta = useSelector(selectors.meta(id))
  const item = useSelector(selectors.item(id))
  const fetchAction = useAction(fetchActionType, id)
  const fetched = useRef(false)

  useEffect(() => {
    if ((!item || item.id !== id) && id !== 'false' && id && !fetched.current) {
      fetchAction()
      fetched.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return [meta.fetchProgress, item]
}
