import React, { useCallback, useMemo, useState } from 'react'

import { ReportFields, ReportParams } from 'modules/domain/report/types'
import * as Styled from './styled'
import { FormikHook, Input, SimpleSelect, useDidMount } from '@agro-club/frontend-shared'
import * as managers from 'modules/domain/report/managers'
import { useTranslation } from 'react-i18next'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FieldMetaProps } from 'formik/dist/types'

type ParamsFormProps = {
  params: ReportParams[]
}

const useParamsFields = () => {
  const [fields, setFields] = useState<ReportFields>()

  useDidMount(() => {
    const fetch = async () => {
      const response = await managers.getReportFields()
      setFields(response)
    }

    fetch()
  })

  return fields
}

const ParamsForm: React.FC<{
  useFormik: FormikHook
  params: ReportParams[]
}> = ({ params, useFormik }) => {
  const { t } = useTranslation('report')

  const validationSchema = useMemo(() => {
    return Yup.object({
      params: Yup.array(
        Yup.object({
          name: Yup.string().required(t('validation:field_required')),
          source_entity: Yup.string().nullable(),
          source_field: Yup.string().when('source_entity', {
            is: value => !!value,
            then: Yup.string().required(t('validation:field_required')),
            otherwise: Yup.string().nullable(),
          }),
          value: Yup.string().when('source_entity', {
            is: value => !value,
            then: Yup.string().required(t('validation:field_required')),
            otherwise: Yup.string().nullable(),
          }),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<ParamsFormProps>({
    initialValues: {
      params,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  })
  const paramsFields = useParamsFields()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleRemoveClick = useCallback(
    (idx: number) => {
      formik.setFieldValue('params', remove(idx, 1, formik.values.params))
    },
    [formik],
  )

  const handleAddClick = useCallback(() => {
    formik.setFieldValue('params', [...formik.values.params, {}])
  }, [formik])

  const handleChangeEntity = useCallback(
    (key, value) => {
      formik.setFieldValue(`params.[${key}].source_entity`, value)

      if (value === null) {
        formik.setFieldValue(`params.[${key}].value`, '')
        formik.setFieldValue(`params.[${key}].source_field`, null)
      } else {
        formik.setFieldValue(`params.[${key}].value`, null)
        formik.setFieldValue(`params.[${key}].source_field`, '')
      }
    },
    [formik],
  )

  const entitiesOptions = useMemo(
    () =>
      paramsFields
        ? [{ id: null, title: 'None' }, ...Object.keys(paramsFields).map(key => ({ title: key, id: key }))]
        : [],
    [paramsFields],
  )

  return (
    <Styled.ParamsContainer data-test-id={'params-form'}>
      {formik.values.params.map((p, idx) => {
        const nameKey = `params[${idx}].name`
        const fieldKey = `params[${idx}].source_field`
        const valueKey = `params[${idx}].value`

        const selectedEntity = p.source_entity
        const fieldOptions =
          paramsFields && paramsFields[`${selectedEntity}`]
            ? paramsFields[`${selectedEntity}`].map(field => ({ title: field, id: field }))
            : []

        const getFieldMeta = (field: string): FieldMetaProps<ParamsFormProps> =>
          formik.getFieldMeta(`params[${idx}].${field}`)

        return (
          <Styled.Container key={idx}>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredIndex(idx)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleRemoveClick(idx)}
              data-test-id={'remove-button'}
            />
            <Input
              {...formik.getFieldProps(nameKey)}
              label={t('form.labels.paramName')}
              placeholder={t('form.placeholders.enterParamKey')}
              invalid={getFieldMeta('name').touched && !!getFieldMeta('name').error}
              errorText={getFieldMeta('name').error}
              required
            />
            <SimpleSelect
              value={selectedEntity || null}
              options={entitiesOptions}
              onChange={value => handleChangeEntity(idx, value)}
              label={t('form.labels.sourceEntity')}
              placeholder={t('form.placeholders.chooseEntity')}
            />
            {selectedEntity ? (
              <SimpleSelect
                value={p.source_field}
                options={fieldOptions}
                onChange={value => formik.setFieldValue(fieldKey, value)}
                onMenuClose={() => formik.setFieldTouched(`params[${idx}].source_field`)}
                label={t('form.labels.sourceField')}
                isDisabled={!selectedEntity}
                placeholder={t('form.placeholders.chooseField')}
                invalid={getFieldMeta('source_field').touched && !!getFieldMeta('source_field').error}
                errorText={getFieldMeta('source_field').error}
                required
              />
            ) : (
              <Input
                {...formik.getFieldProps(valueKey)}
                label={t('form.labels.value')}
                placeholder={t('form.placeholders.enterValue')}
                invalid={getFieldMeta('value').touched && !!getFieldMeta('value').error}
                errorText={getFieldMeta('value').error}
                required
              />
            )}
            {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
          </Styled.Container>
        )
      })}
      <Styled.AddButton onClick={handleAddClick} type={'button'}>
        {t('form.addMoreParams')}
      </Styled.AddButton>
    </Styled.ParamsContainer>
  )
}

export default ParamsForm
