import { useMemo } from 'react'

export const useFilterMemo = <FilterType>(filter: FilterType | undefined): FilterType | undefined => {
  const filterFlatArray = Object.values(filter || {}).reduce(
    (acc: Array<unknown>, value) => [...acc, ...(Array.isArray(value) ? value : [value])],
    [],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => filter, filterFlatArray)
}
