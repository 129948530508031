import React, { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, useTable } from 'react-table'
import {
  AddButton,
  SearchInput,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHelmet,
  usePersistentScroll,
} from '@agro-club/frontend-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'

import { useLicenseList } from 'modules/domain/license/hooks'
import LicenseRoutes from 'views/pages/License/routes'
import LicenseSelectors from 'modules/domain/license/selectors'
import LicenseActions from 'modules/domain/license/duck'
import { CompanyType, License, LicenseListRequestFilter } from 'modules/domain/license/types'
import { LicenseFormInputs } from 'views/pages/License/LicenseDetailsForm/LicenseDetailsForm'
import { Filter } from 'views/components/TableFilters/styles'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import styled from 'styled-components'

const CompanyCell: React.FC<CellProps<CompanyType[]>> = ({ value }) => {
  if (!value) return null
  return <span>{value.map(e => e.official_name).join(', ')}</span>
}

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const LicenseList: React.FC<{ onUpdateInitialState(props: LicenseFormInputs | undefined): void }> = ({
  onUpdateInitialState,
}) => {
  const history = useHistory()
  const { t } = useTranslation(['license', 'labels'])
  const [progress, data = []] = useLicenseList()

  const total = useSelector(LicenseSelectors.total)
  const page = useSelector(LicenseSelectors.page)
  const pages = useSelector(LicenseSelectors.pages)
  const pageSize = useSelector(LicenseSelectors.pageSize)
  const filterUpdated = useAction(LicenseActions.filterUpdated)
  const filterValue = useSelector(LicenseSelectors.filter)

  const listRequested = useAction(LicenseActions.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleSearchChange = useCallback(
    (search?: string) => {
      filterUpdated({ ...filterValue, search })
    },
    [filterUpdated, filterValue],
  )

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<LicenseListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.id'),
        accessor: 'id' as const,
      },
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
      },
      {
        Header: t('list.tableHeaders.company_name'),
        accessor: 'company_official_names' as const,
        Cell: CompanyCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<License>({
    columns: allColumns,
    data: data,
  })

  const { scrollRef } = usePersistentScroll('license')

  useHelmet({ title: t('licensesMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={LicenseRoutes.Add} onClick={() => onUpdateInitialState(undefined)} />
          </Header.Title>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filterValue.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Filters>
          <Filter>
            <CountrySelect
              width={220}
              isClearable
              label={t('labels:country')}
              onChange={newValue => handleFilterChange({ default_country_code: newValue })}
              value={filterValue.default_country_code}
            />
          </Filter>
          <Filter>
            <CompanySelect
              filter={{ is_seller: true }}
              width={220}
              label={t('labels:seller')}
              onChange={newValue => handleFilterChange({ company_id: newValue })}
              value={filterValue.company_id}
              isClearable
            />
          </Filter>
        </TComponents.Filters>
        <TComponents.Wrapper noFilters>
          <Table
            total={total}
            pages={pages}
            pageSize={pageSize}
            currentPage={page}
            onSetPage={fetchNextItems}
            ref={scrollRef}
          >
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(LicenseRoutes.Edit, { id: row.original.id }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default LicenseList
